<template>
  <div>
    <button class="btn" @click="addItem">Add Contact</button>
    <br/>
    <br/>

    <div class="row" v-if="preFiltered && preFiltered.Account_Customer_Code__c">
      <div class="col-sm-12 col-md-4">
        <div class="form-group">
          <label>Filter by Site</label>
          <select-field :options="woSiteOptions" :option-display-keys="['Ship_To_Name', 'site_address']" :option-val="'sfId'" v-model="filterBySite"/>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="content">
        <bh-table :table-settings="tableSettings" @edit-item="editItem"/>
      </div>
    </div>
    <waiting-spinner/>
  </div>
</template>
<script>
import store from 'store'
import {mapGetters} from 'vuex'
import appFuncs from 'appFuncs'
import BhTable from 'components/UIComponents/BhTable'
import CustomerContactsAddEdit from 'src/components/Dashboard/Contacts/CustomerContactsAddEdit.vue'
import SelectField from 'components/UIComponents/SelectField'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'CustomerContactList',

  components: {
    BhTable,
    SelectField,
    WaitingSpinner
  },

  props: {
    componentData: {
      type: Object,
      default() {
        return {}
      }
    },
    preFiltered: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      filterBySite: 'all',
      filteredContacts: [],
      tableSettings: {},
      urls: store.getters.urls,
      fieldDefaults: {}, // for add item functionality
      fields: {
        Name: {
          name: 'Name',
          required: true
        },
        Account_Name__c: {
          name: 'Company'
        },
        Title: {
          name: 'Title',
          wide: 1
        },
        woSiteName: {
          name: 'Site'
        },
        Phone: {
          name: 'Direct Phone'
        },
        Phone_Extension__c: {
          name: 'Direct Phone Extension'
        },
        MobilePhone: {
          name: 'Mobile Phone'
        },
        Email: {
          name: 'Email'
        },
        Status: {
          name: 'Status'
        },
        Account_Customer_Code__c: {}
      }
    }
  },

  computed: {
    ...mapGetters(['customerContacts', 'woSites']),

    preSelected() {
      if (this.componentData && this.componentData.fieldDefaults && this.componentData) {
        return this.componentData.fieldDefaults
      } else {
        return {}
      }
    },

    woSiteOptions() {
      let options = [{Ship_To_Name: 'Show All', sfId: 'all'}]

      if (this.preFiltered.Account_Customer_Code__c) {
        let siteMatches = this.woSites.filter(itm => {
          return itm.Ship_To_Customer_Code === this.preFiltered.Account_Customer_Code__c
        })
        options = options.concat(siteMatches)
      }
      return options
    }
  },

  methods: {
    editItem(editId) {
      this.$Modal({
        parent: this,
        name: 'customer-contact-add-edit', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        title: '',
        component: CustomerContactsAddEdit,
        props: {
          editId
        }
      })
    },

    addItem() {
      this.$Modal({
        parent: this,
        name: 'customer-contact-add-edit', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        title: '',
        component: CustomerContactsAddEdit,
        props: {
          preSelected: this.preSelected
        }
      })
    },

    async getData() {
      // fetches all need simulaneously
      const getContacts = this.$store.dispatch('getCustomerContacts')
      const getCustomers = this.$store.dispatch('getCustomers')
      const getWOSites = this.$store.dispatch('getWOSites')

      this.$bus.$emit('setWaiting', {name: 'getData', message: 'Getting Data'})
      await Promise.all([getContacts, getCustomers, getWOSites])
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getData')
        })

      this.setTable()
    },

    setTable() {
      this.filteredContacts = []
      let contacts = [...this.customerContacts]

      for (var i = 0; i < contacts.length; i++) {
        // if preFiltered with customer code, do filtering related to customer
        if (this.preFiltered && this.preFiltered.Account_Customer_Code__c) {
          if (contacts[i].Account_Customer_Code__c !== this.preFiltered.Account_Customer_Code__c) {
            continue
          }

          if (this.filterBySite !== 'all' && this.filterBySite !== contacts[i].Account_Site__c) {
            continue
          }
        }

        // set id for table
        contacts[i].id = contacts[i].Id

        // get and assign site name contact is assigned to for list view
        for (var woi = 0; woi < this.woSites.length; woi++) {
          if (this.woSites[woi].sfId && this.woSites[woi].sfId === contacts[i].Account_Site__c) {
            contacts[i].woSiteName = this.woSites[woi].Ship_To_Name
            break
          }
        }

        contacts[i].class = contacts[i].Inactive__c ? 'orange' : ''

        this.filteredContacts.push(contacts[i])
      } // end of contacts loop

      this.tableSettings = {}
      this.tableSettings.fields = this.fields
      this.tableSettings.tableData = this.filteredContacts

      if (this.preFiltered && this.preFiltered.Account_Customer_Code__c) {
        this.tableSettings.hideSearch = true
      }
    }
  },

  mounted() {
    this.getData()

    // update item list on custom change event
    this.$bus.$on('bhUpdate', () => {
      this.setTable()
    })
  },

  watch: {
    filterBySite() {
      this.setTable()
    }
  }
}
</script>
