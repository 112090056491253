<template>
  <div>
    <div class="tab-section border-bottom">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{ active: tab == 'Overall' }]">
          <a href="#" @click="tab = 'Overall'"> Overall </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'Estimators' }]">
          <a href="#" @click="tab = 'Estimators'"> Estimators </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'Sales Tracking' }]">
          <a href="#" @click="tab = 'Sales Tracking'"> Sales Tracking </a>
        </li>
      </ul>

      <div :class="[{ Main: tab == 'Overall' }, 'tab-content']">
        <div class="clearfix">
          <div v-if="tab == 'Overall'">
            <quote-totals />
          </div>
        </div>
      </div>

      <div class="row" v-if="tab === 'Estimators' || tab === 'Sales Tracking'">
        <div class="col-sm-12 col-md-6 col-lg-3 Xpull-right" style="margin-left: 5px">
          <label>Fiscal Year</label>
          <select-field :options="yearOptions" v-model="fiscal_year" :option-display-keys="['name']" :option-val="'val'" @select-item="val => changeFiscalYear(val)" />
          <br />
        </div>
      </div>

      <div :class="[{ Main: tab == 'Estimators' }, 'tab-content']">
        <div class="clearfix">
          <div v-if="tab == 'Estimators'">
            <div class="sh-widget card wide-medium">
              <div class="content">
                <div class="top-section">
                  <div class="">
                    <h4>Quotes in Progress</h4>
                  </div>
                </div>
                <div class="table-container show-scrollbar">
                  <table>
                    <tr>
                      <th><label>Lead Estimator</label></th>
                      <th><label>Qty</label></th>
                      <th><label>Value</label></th>
                    </tr>
                    <tr v-for="qr in qip">
                      <td>{{ qr.Employee_Name }}</td>
                      <td>{{ qr.quotes_progress }}</td>
                      <td>{{ qr.quotes_progress_val }}</td>
                    </tr>
                    <tr>
                      <td><strong>Totals:</strong></td>
                      <td>
                        <strong>{{ quoteTotals.total_in_progress }}</strong>
                      </td>
                      <td>
                        <strong>${{ quoteProgressTotal }}</strong>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="sh-widget card wide-medium">
              <div class="content">
                <div class="top-section">
                  <div class="">
                    <h4>Quotes Sent and Pending</h4>
                  </div>
                </div>
                <div class="table-container show-scrollbar">
                  <table>
                    <tr>
                      <th><label>Lead Estimator</label></th>
                      <th><label>Qty</label></th>
                      <th><label>Value</label></th>
                    </tr>
                    <tr v-for="qs in qsent">
                      <td>{{ qs.Employee_Name }}</td>
                      <td>{{ qs.quotes_sent_pending }}</td>
                      <td>{{ qs.quotes_sent_pending_val }}</td>
                    </tr>
                    <tr>
                      <td><strong>Totals:</strong></td>
                      <td>
                        <strong>{{ quoteTotals.total_sent_pending }}</strong>
                      </td>
                      <td>
                        <strong>${{ quotePendingTotal }}</strong>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div v-if="tab == 'Sales Tracking'">
            <div class="sh-widget card wide-medium">
              <div class="content">
                <div class="top-section">
                  <div class="">
                    <h4>Quotes in Progress</h4>
                  </div>
                </div>
                <div class="table-container show-scrollbar">
                  <table>
                    <tr>
                      <th><label>Person</label></th>
                      <th><label>Qty</label></th>
                      <th><label>Value</label></th>
                    </tr>
                    <tr v-for="qr in qsProg">
                      <td>{{ qr.Employee_Name }}</td>
                      <td>{{ qr.quotes_progress }}</td>
                      <td>{{ qr.quotes_progress_val_dol }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="sh-widget card wide-medium">
              <div class="content">
                <div class="top-section">
                  <div class="">
                    <h4>Quotes Sent and Pending</h4>
                  </div>
                </div>
                <div class="table-container show-scrollbar">
                  <table>
                    <tr>
                      <th><label>Person</label></th>
                      <th><label>Qty</label></th>
                      <th><label>Value</label></th>
                    </tr>
                    <tr v-for="qr in qsPending">
                      <td>{{ qr.Employee_Name }}</td>
                      <td>{{ qr.quotes_sent_pending }}</td>
                      <td>{{ qr.quotes_sent_pending_val_dol }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="sh-widget card wide-medium">
              <div class="content">
                <div class="top-section">
                  <div class="">
                    <h4>Quotes Won</h4>
                  </div>
                </div>
                <div class="table-container show-scrollbar">
                  <table>
                    <tr>
                      <th><label>Person</label></th>
                      <th><label>Quotes Won</label></th>
                      <th><label>Quotes Won (total value)</label></th>
                    </tr>
                    <tr v-for="qr in qsWon">
                      <td>{{ qr.Employee_Name }}</td>
                      <td>{{ qr.quotes_won }}</td>
                      <td>{{ qr.quotes_won_val_dol }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="sh-widget card wide-medium">
              <div class="content">
                <div class="top-section">
                  <div class="">
                    <h4>Quotes Lost</h4>
                  </div>
                </div>
                <div class="table-container show-scrollbar">
                  <table>
                    <tr>
                      <th><label>Person</label></th>
                      <th><label>Quotes Lost</label></th>
                      <th><label>Quotes Lost (total value)</label></th>
                    </tr>
                    <tr v-for="qr in qsLost">
                      <td>{{ qr.Employee_Name }}</td>
                      <td>{{ qr.quotes_lost }}</td>
                      <td>{{ qr.quotes_lost_val_dol }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'
import quoteFuncs from 'mixins/quoteFuncs'
import navTabs from 'mixins/navTabs'

import dateFormat from 'dateformat'
import SelectField from 'components/UIComponents/SelectField'
import QuoteTotals from 'components/Dashboard/Quotes/QuoteTotals'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  mixins: [navTabs, quoteFuncs],

  components: {
    SelectField,
    QuoteTotals,
    WaitingSpinner
  },

  data() {
    var self = this
    return {
      tab: 'Overall',
      fiscal_year: null,
      quoterData: [],
      sellsData: [],
      quoteTotals: {},
      qip: [],
      qsent: [],
      qipSales: [],
      tableSettings1: {},
      tableSettings2: {},
      fields: {
        Employee_Name: {
          name: 'Lead Estimator'
        },
        quotes_progress: {
          name: 'Quotes in Progress'
        },
        quotes_progress_val: {
          name: 'Value'
        }
      },
      fields2: {
        Employee_Name: {
          name: 'Lead Estimator'
        },
        quotes_sent_pending: {
          name: 'Quotes Sent and Pending'
        },
        quotes_sent_pending_val: {
          name: 'Value'
        }
      }
    }
  },

  computed: {
    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },

    quoteProgressTotal() {
      return appFuncs.formatNumber(this.quoteTotals.total_in_progress_val)
    },

    quotePendingTotal() {
      return appFuncs.formatNumber(this.quoteTotals.total_sent_pending_val)
    },

    yearOptions() {
      return this.getQuoteYears()
    },

    qsProg() {
      const qs = JSON.parse(JSON.stringify(this.qipSales))
      return qs.sort((a, b) => {
        return b.quotes_progress_val - a.quotes_progress_val
      })
    },

    qsPending() {
      const qs = JSON.parse(JSON.stringify(this.qipSales))
      return qs.sort((a, b) => {
        return b.quotes_sent_pending_val - a.quotes_sent_pending_val
      })
    },

    qsWon() {
      const qs = JSON.parse(JSON.stringify(this.qipSales))
      return qs.sort((a, b) => {
        return b.quotes_won_val - a.quotes_won_val
      })
    },

    qsLost() {
      const qs = JSON.parse(JSON.stringify(this.qipSales))
      return qs.sort((a, b) => {
        return b.quotes_lost_val - a.quotes_lost_val
      })
    }
  },

  methods: {
    changeFiscalYear(val) {
      this.$store.commit('quoteFilterFiscalYear', val)
      this.getData(0)
    },

    getData(refresh) {
      // 0 to refresh
      let params = {
        fiscal_year: this.$store.getters.quoteFilterFiscalYear
      }

      appFuncs.stdFetchStore(
        'get_quote_totals_by_user',
        'Fetching Quote Report',
        result => {
          if (result.status === 'success') {
            this.quoterData = result.data.quoters
            this.sellsData = result.data.sales_leads
            this.quoteTotals = result.data.totals
            this.setTableData()
          }
        },
        refresh,
        null,
        false,
        params
      )
    },

    setTableData() {
      let qip = JSON.parse(JSON.stringify(this.quoterData)) // quotes in progress
      qip = qip.filter(itm => {
        return itm.quotes_progress > 0
      })
      qip = qip.sort((a, b) => {
        return b.quotes_progress_val - a.quotes_progress_val
      })
      for (var i = qip.length - 1; i >= 0; i--) {
        qip[i].quotes_progress_val = appFuncs.formatNumber(qip[i].quotes_progress_val, '$')
      }
      this.qip = qip

      let qsent = JSON.parse(JSON.stringify(this.quoterData)) //  quotes sent
      qsent = qsent.filter(itm => {
        return itm.quotes_sent_pending > 0
      })
      qsent = qsent.sort((a, b) => {
        return b.quotes_sent_pending_val - a.quotes_sent_pending_val
      })
      for (var i = qsent.length - 1; i >= 0; i--) {
        qsent[i].quotes_sent_pending_val = appFuncs.formatNumber(qsent[i].quotes_sent_pending_val, '$')
      }
      this.qsent = qsent

      let qipSales = JSON.parse(JSON.stringify(this.sellsData)) // quotes in progress
      // qipSales = qipSales.filter((itm) => {
      //   return itm.quotes_progress > 0;
      // });
      // qipSales = qipSales.sort((a, b) => {
      //   return b.quotes_won_val - a.quotes_won_val;
      // });

      for (var i = qipSales.length - 1; i >= 0; i--) {
        qipSales[i].quotes_progress_val_dol = appFuncs.formatNumber(qipSales[i].quotes_progress_val, '$')
        qipSales[i].quotes_sent_pending_val_dol = appFuncs.formatNumber(qipSales[i].quotes_sent_pending_val, '$')
        qipSales[i].quotes_won_val_dol = appFuncs.formatNumber(qipSales[i].quotes_won_val, '$')
        qipSales[i].quotes_lost_val_dol = appFuncs.formatNumber(qipSales[i].quotes_lost_val, '$')
      }
      this.qipSales = qipSales
    }
  },

  mounted() {
    this.$store.commit('quoteFilterStatus', '')
    this.$store.commit('quoteFilterEmployee', '')
    this.$store.commit('quoteFilterQuotedBy', '')
    this.$store.commit('quoteFilterSalesLead', '')
    this.$store.commit('quoteFilterCustomer', '')
    this.$store.commit('quoteFilterDepartment', '')
    this.$store.commit('quoteFilterCaptain', '')
    this.$store.commit('quoteFilterEmployeeAny', [])

    if (this.isAdmin) {
      this.tab = 'Overall'
    }

    this.fiscal_year = store.getters.fiscalYear
    this.getData(1)
  },

  watch: {}
}
</script>

<style lang="scss" scoped>
.sh-widget {
  margin: 5px !important;
}

.table-container {
  max-height: 220px;
  overflow-x: hidden;
  overflow-y: scroll;

  label {
    font-size: 13px;
    font-weight: bold;
  }
}
</style>
