<template>
  <div class="">
    <set-view-as-employee v-if="isAdmin" />
    <br />

    <!-- needs to support providing job/wo number <div v-if="isPM">
      <button :class="'btn'" @click="addTask">Add Task</button>
      <br /><br />
    </div> -->

    <div class="tab-section white">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{ active: tab == 'my' }]">
          <a href="#" @click="tab = 'my'"> My Tasks </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'all' }]" v-if="isAdmin">
          <a href="#" @click="tab = 'all'"> All Tasks </a>
        </li>
      </ul>

      <div :class="['card', { Main: tab == 'my' }]">
        <div class="content">
          <div class="row report-filters">
            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Billing Task</label>
              <select-field
                :option-display-keys="['name']"
                :option-seperator="' '"
                :option-val="'value'"
                v-model="filterTaskType"
                :allow-clear="true"
                :options="billingTasks"
                @selectItem="setTable()"
              />
            </div>
            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Approval Status</label>
              <select-field
                :option-display-keys="['name']"
                :option-seperator="' '"
                :option-val="'value'"
                v-model="filterApprovalStatus"
                :allow-clear="true"
                :options="approvalStatuses"
                @selectItem="setTable()"
              />
            </div>
            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Billing Task Status</label>
              <select-field
                :option-display-keys="['name']"
                :option-seperator="' '"
                :option-val="'value'"
                v-model="filterBillingTaskStatus"
                :allow-clear="true"
                :options="billingTaskStatusOptions"
                @selectItem="setTable()"
              />
            </div>
            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Billing Status</label>
              <select-field :option-display-keys="['name']" :option-val="'value'" v-model="filterTaskStatus" :allow-clear="true" :options="statusOptions" @selectItem="setTable()" />
            </div>

            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Customer</label>
              <select-field
                :options="customers"
                :option-display-keys="['Name']"
                :option-val="'Customer_Code'"
                @selectItem="(clearAllFilters('filterCustomer'), setTable())"
                v-model="filterCustomer"
                :allow-clear="true"
              />
            </div>
            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Project Lead</label>
              <select-field
                :options="employeesWithRoleFlags"
                :option-display-keys="['Employee_Name']"
                :option-val="'Employee_Code'"
                v-model="filterPM"
                :filter-on-key-val="{ key: 'hasPMRole', val: 'yes' }"
                :allow-clear="true"
                @selectItem="setTable()"
              />
            </div>
          </div>

          <div class="row report-filters">
            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Captain</label>
              <select-field
                :options="employeesWithRoleFlags"
                :option-display-keys="['Employee_Name']"
                :option-val="'Employee_Code'"
                v-model="filterCaptain"
                :filter-on-key-val="{ key: 'hasCaptainRole', val: 'yes' }"
                :allow-clear="true"
                @selectItem="setTable()"
              />
            </div>

            <div class="col-sm-12 col-md-3 col-lg-2">
              <region-filter v-model="filterRegion" @selectItem="setTable()" />
            </div>

            <!-- <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Stage</label>
              <select-field :options="jobStages" :label-text="'Stage'" :option-display-keys="['description']" :option-val="'code'" v-model="filterStage" :allow-clear="true" @selectItem="setTable()" />
            </div>

            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Department</label>
              <select-field :options="costCenters" :option-display-keys="['name']" :option-val="'value'" v-model="filterCostCenter" :allow-clear="true" @selectItem="setTable()" />
            </div> -->

            <div class="col-sm-12 col-md-3 col-lg-2" v-if="tab === 'all'">
              <label>Assigned To</label>
              <select-field :options="assignedToList" :option-display-keys="['Employee_Name']" :option-val="'Employee_Code'" v-model="filterAssignedTo" :allow-clear="true" @selectItem="setTable()" />
            </div>

            <div class="col-sm-12 col-md-3 col-lg-2">
              <label>Jobs/WOs</label>
              <select-field
                :options="[
                  { name: 'Jobs', value: 'jobs' },
                  { name: 'WOs', value: 'wos' }
                ]"
                :option-display-keys="['name']"
                :option-val="'value'"
                v-model="filterType"
                :allow-clear="true"
                @selectItem="setTable()"
              />
            </div>
            <div v-if="filterType === 'jobs' && tab !== 'my'" class="col-sm-12 col-md-3 col-lg-2">
              <label>Jobs</label>
              <select-field
                v-model="filterJob"
                :options="jobAssemble"
                :option-display-keys="['Job_Number', 'Name', 'Job_Description']"
                :option-val="'Job_Number'"
                :empty-option-text="'No items.'"
                @selectItem="(clearAllFilters('filterJob'), setTable())"
                :allow-clear="true"
              />
            </div>
            <div v-if="filterType === 'wos' && tab !== 'my'" class="col-sm-12 col-md-3 col-lg-2">
              <label>WOs</label>
              <select-field
                v-model="filterWO"
                :options="woAssemble"
                :option-display-keys="['WO_Number', 'Name', 'Summary_Description']"
                :option-val="'WO_Number'"
                :empty-option-text="'No items.'"
                @selectItem="(clearAllFilters('filterWO'), setTable())"
                :allow-clear="true"
              />
            </div>
          </div>

          <!-- <button class="btn btn-sm" @click="downloadCSV">Download CSV</button> -->

          <p v-if="errorText" class="text-danger">{{ errorText }}</p>

          <tabulator-table
            ref="billingListTable"
            :table-data="tableData"
            :table-columns="tableColumns"
            table-fit="fitColumns"
            :table-condensed="false"
            empty-text="(none)"
            :data-tree="false"
            :row-formatter="rowFormatter"
            @colClick="colClick"
            Xmax-table-height="500px"
          />
        </div>
      </div>
    </div>

    <waiting-spinner />
  </div>
</template>
<script>
import store from 'store'
import navTabs from 'mixins/navTabs'
import appFuncs from 'appFuncs'

import dateFormat from 'dateformat'
import { mapGetters } from 'vuex'
import billingFuncs from 'mixins/billingFuncs'
import Checkbox2 from 'components/UIComponents/Checkbox'
import SelectField from 'components/UIComponents/SelectField'
import SelectFieldMulti from 'components/UIComponents/SelectFieldMulti'
import SetViewAsEmployee from 'components/Dashboard/Widgets/SetViewAsEmployee'
import RegionFilter from 'components/Dashboard/RegionFilter'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import JobFuncs from 'mixins/JobFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    var self = this
    return {
      tab: 'my',
      eid: store.getters.userAuthedData.eid,
      data: [
        {
          id: 1,
          wo_number: null,
          job_number: 25625,
          task_type: 'DI',
          task_assignment: 151,
          status: 'O',
          date_create: 1718379632,
          date_due: 1718829632,
          parent_id: null,
          approval_status: 'P',
          captain: 12,
          po_number: '',
          customer: 'SE',
          region: 'location_sheridan',
          cost_center: null,
          site_id: 353,
          jobwo_status: '4',
          date_ordered: '2024-04-17 00:00:00',
          last_billed_date: null,
          contact_name: 'Andrew Test Rendall',
          pm: 108,
          stage: null,
          sign_off: '',
          last_status_note: null,
          jobwo_description: 'Test Job'
        }
      ],
      errorText: '',

      initialFilters: {
        filterJob: '',
        filterWO: '',
        filterCustomer: '',
        filterCaptain: '',
        filterPM: '',
        filterRegion: null,
        filterStage: '',
        filterCostCenter: '',
        filterMissingPO: 'no'
      },

      filterTaskStatus: 'O',
      filterTaskType: null,
      filterApprovalStatus: null,
      filterType: '',
      filterAssignedTo: null,
      filterPM: null,
      filterCaptain: null,
      filterPO: null,
      filterCustomer: null,
      filterRegion: null,
      filterStage: null,
      filterCostCenter: null,
      filterBillingTaskStatus: null,
      filterJob: '',
      filterWO: '',
      sortOption: '',
      sortOrder: 'desc',
      tooManyResults: false
    }
  },

  props: {},

  components: {
    SelectField,
    SetViewAsEmployee,
    RegionFilter,
    TabulatorTable,
    WaitingSpinner
  },

  mixins: [navTabs, billingFuncs, JobFuncs],

  computed: {
    ...mapGetters(['jobAssemble', 'woAssemble', 'userAuthedData', 'employees', 'regions', 'woPriorityDescriptions', 'urls', 'customers', 'woSites', 'customerContacts']),

    isAdmin() {
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPM() {
      return this.userAuthedData.user_role.indexOf('pm') !== -1
    },

    isCaptain() {
      return this.isAdmin || this.userAuthedData.employee_role === 'captain'
    },

    isPMp() {
      return this.isPM && this.userAuthedData.user_role.indexOf('P') !== -1
    },

    assignedToList() {
      const employees = [...this.employees]
      employees.unshift({
        Employee_Code: 'unassigned',
        Employee_Name: 'Unassigned'
      })
      return employees
    },

    tableColumns() {
      return [
        {
          field: 'id',
          visible: false
        },
        {
          title: 'Task',
          field: 'task',
          width: '200',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: '',
          field: 'task2',
          width: '200',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: 'Task Dates',
          field: 'taskDates',
          width: '150',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: '',
          field: 'taskDates2',
          width: '150',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: 'Job Lead / Captain / PO',
          field: 'owner',
          width: '150',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: 'Job/WO Number/Description/Status Note',
          field: 'details',
          width: '250',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: 'Customer / Location / Contact',
          field: 'contact',
          width: '250',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: 'Job Status / Region',
          field: 'status',
          width: '150',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: '',
          field: 'status2',
          width: '200',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: 'Job Dates',
          field: 'dates',
          width: '150',
          headerSort: false,
          formatter: 'html'
        },
        {
          title: '',
          field: 'dates2',
          width: '150',
          headerSort: false,
          formatter: 'html'
        }
      ]
    },

    employeesWithRoleFlags() {
      return this.employees
        .filter(itm => itm.Employment_Status === 'A')
        .map(itm => {
          if (Array.isArray(itm.job_roles)) {
            itm.hasPMRole = itm.job_roles.includes('pm') ? 'yes' : 'no'
            itm.hasCaptainRole = itm.job_roles.includes('captain') ? 'yes' : 'no'

            // // TEMP add captain role to all employees if not set
            // if (!itm.job_roles.length) {
            //   itm.hasCaptainRole = 'yes'
            //   itm.hasPMRole = 'yes'
            // }
          }
          return itm
        })
    },

    tableData() {
      let data = this.data
      let myItems = []
      let filtered = []

      data = JSON.parse(JSON.stringify(data))

      filtered = data.flatMap(itm => {
        const pm = this.employees.find(e => e.Employee_Code == itm.pm)
        const pmName = pm ? pm.Employee_Name : ''

        const captain = this.employees.find(e => e.Employee_Code == itm.captain)
        const captainName = captain ? captain.Employee_Name : ''

        const salesPerson = this.employees.find(e => e.Employee_Code == itm.sales_person)
        const salesPersonName = salesPerson ? salesPerson.Employee_Name : ''

        return [
          {
            taskId: itm.id,
            id: this.randomCharacterString(12),
            task: `<span class="underlined is-link"><strong>${this.getTaskName(itm.task_type)}</strong></span>`,
            task2: `<span class="underlined is-link"><strong>${this.getTaskStatusName(itm.billing_task_status)}</strong></span>`,
            taskDates: 'Date Created',
            taskDates2: itm.date_create ? this.formatDate(itm.date_create) : '—',
            owner: this.getEmployeeName(itm.pm),
            details: itm.job_number ? `Job: ${itm.job_number}` : `WO: ${itm.wo_number}`,
            contact: this.getCustomerName(itm.customer),
            status: 'Project Status',
            status2: this.getJobWOStatus(itm.jobwo_status),
            dates: 'Date Ordered',
            dates2: itm.date_ordered ? this.formatDate(itm.date_ordered) : '—',
            class: 'first-row'
          },
          {
            taskId: itm.id,
            id: this.randomCharacterString(12),
            task: 'Assigned to:',
            task2:
              !itm.task_assignment || itm.task_assignment === 'unassigned'
                ? '<span class="text-danger is-link underlined">Unassigned</span>'
                : `<span class="is-link underlined">${this.getEmployeeName(itm.task_assignment)}</span>`,
            taskDates: 'Days Old',
            taskDates2: itm.date_create ? this.getDaysOld(itm.date_create) : '—',
            owner: this.getEmployeeName(itm.captain),
            details: itm.jobwo_description,
            contact: this.getSiteAddress(itm.site_id),
            status: 'Sign Off Status',
            status2: itm.sign_off === 'yes' ? 'Yes' : 'No',
            dates: 'Last Work Date',
            dates2: itm.last_work_date ? this.formatDate(itm.last_work_date) : '—',
            class: 'second-row'
          },
          {
            taskId: itm.id,
            id: this.randomCharacterString(12),
            task: 'Instructions:',
            task2: itm.billing_instructions ? itm.billing_instructions.substring(0, 80) + (itm.billing_instructions.length > 100 ? '...' : '') : '',
            taskDates: 'Sign Off Date',
            taskDates2: itm.sign_off_date ? this.formatDate(itm.sign_off_date) : '—',
            owner: itm.po_number ? `PO: ${itm.po_number}` : `<span class="underlined is-link">Add</span>`,
            details: itm.last_status_note,
            contact: itm.contact_name,
            status: 'Region',
            status2: this.getRegionName(itm.region),
            dates: 'Date Completed',
            dates2: itm.date_completed ? this.formatDate(itm.date_completed) : '—',
            class: 'third-row'
          }
        ]
      })

      return filtered
    }
  },

  methods: {
    setTable() {
      this.getTasks()
    },

    getEmployeeName(code) {
      const emp = this.employees.find(itm => itm.Employee_Code == code)
      return emp ? emp.Employee_Name : code
    },

    getCustomerName(code) {
      const cust = this.customers.find(itm => itm.Customer_Code == code)
      return cust ? cust.Name : code
    },

    getSiteAddress(code) {
      const site = this.woSites.find(itm => itm.Ship_To_ID == code)
      return site ? `${site.site_address}, ${site.site_city}, ${site.site_province} ${site.Ship_To_Zip_Code}` : code
    },

    getJobWOStatus(code) {
      const jobStages = this.jobStages
      const woStages = this.woPriorityDescriptions
      let status = jobStages.find(itm => itm.stage_code == code)
      if (status) {
        return status.description
      }
      status = woStages.find(itm => itm.code == code)
      if (status) {
        return status.description
      }
      return code
    },

    getRegionName(code) {
      const region = this.regions.find(itm => itm.id == code)
      return region ? region.name : code
    },

    clearAllFilters(not) {
      const filterList = ['filterJob', 'filterWO', 'filterCustomer', 'filterCaptain', 'filterPM', 'filterRegion', 'filterStage', 'filterCostCenter', 'filterMissingPO', 'filterBillingTaskStatus']
      filterList.forEach(filter => {
        if (filter !== not) {
          this[filter] = this.initialFilters[filter]
        }
      })
    },

    rowFormatter(row) {
      var data = row.getData()
      let rowEl = row.getElement()
      // const firstRow = data.class === 'first'

      let updateRow = false
      let rowEntry = null

      // const rowIndex = row.getPosition()
      // console.log(rowIndex)
      // if (rowIndex == 0) {
      //   row.freeze()
      // }

      // const parentRow = data._children && data._children.length ? data : null
      // if (firstRow) {
      row.getElement().classList.add(data.class)

      // if (data.class === 'first-row') {
      //   row.getCell('title').setValue(`<span class="is-link text-blue underlined"><strong>${data.title}</strong></span>`)
      //   row.getCell('status2').setValue(`<span class="is-link underlined">${data.status2}</span>`)
      // }

      // if (data.class === 'second-row') {
      //   row.getCell('status2').setValue(`<span class="is-link underlined">${data.status2}</span>`)
      // }

      if (data.class === 'third-row') {
        // row.getCell('title').setValue(`<span class="is-link underlined">${data.title}</strong>`)
        // row.getCell('title').getElement().cssClass('is-link')
      }

      return

      if (data.class === 'fourth-row') {
        // "so the formula should be that POSITVE underbilling is showing as RED and negative is GREEN. that is how it is for jobs correct? " https://teams.microsoft.com/l/message/19:23bb520e-7da3-4db7-a1a0-a44c0100d018_f182ec0e-e4b4-4b92-944f-7af4dd328618@unq.gbl.spaces/1717674845551?context=%7B%22contextType%22%3A%22chat%22%7D
        if (parseFloat(data.costing2) > 0) {
          row.getCell('costing2').setValue(`<span class="text-danger">(${this.formatNumber(data.costing2, 1)})</strong>`)
        } else {
          row.getCell('costing2').setValue(`<span class="text-success">(${this.formatNumber(data.costing2, 1)})</strong>`)
        }

        let lastStatusNoteDateVal = ''
        if (data.status2) {
          const currentDate = new Date()
          const lastStatusNoteDate = data.status2
            ? !isNaN(data.status2)
              ? data.status2.toString().length === 10
                ? new Date(data.status2 * 1000)
                : new Date(data.status2)
              : new Date(data.status2)
            : null
          const statusNotesDaysOld = lastStatusNoteDate ? Math.floor((currentDate - lastStatusNoteDate) / (1000 * 60 * 60 * 24)) : null
          let statusNotesClass = ''
          if (statusNotesDaysOld !== null) {
            if (statusNotesDaysOld <= 14) {
              statusNotesClass = 'text-success'
            } else if (statusNotesDaysOld <= 28) {
              statusNotesClass = 'text-warning'
            } else {
              statusNotesClass = 'text-danger'
            }
          }
          lastStatusNoteDateVal = `<span class="${statusNotesClass}">${data.status2 ? this.formatDate(data.status2) : '<span class="underline">None</span>'}</span>`
        } else {
          lastStatusNoteDateVal = '<span class="underline">None</span>'
        }
        row.getCell('status2').setValue(lastStatusNoteDateVal)
      }

      // const costCenterNumber = data.cost_center
      // const costCenter = this.costCenters.find(cc => cc.value == costCenterNumber)
      // row.updateRow({
      //   cost_center_name: 'taco'
      // })

      // }
    },

    colClick(obj) {
      console.log(obj)
      const rowNum = (obj.row || {}).class
      const col = (obj.cell || {}).field

      const taskId = (obj.row || {}).taskId
      const type = (obj.row || {}).job_or_wo
      const poNumber = (obj.row || {}).po_number

      if (rowNum === 'first-row' && (col === 'task' || col === 'task2')) {
        this.$router.push({ path: `/app/billing-list/main/${taskId}` })
        this.openTask(taskId)
      }

      if ((rowNum === 'first-row' || rowNum === 'second-row') && col === 'status2') {
        const id = itemNumber
        const type = obj.row.job_or_wo
      }

      if (rowNum === 'second-row' && col === 'task2') {
        this.openSetAssingedTo(taskId)
      }

      if (rowNum === 'third-row') {
        if (col === 'title') {
        }
      }

      if (rowNum === 'fourth-row' && col === 'title') {
      }
    },

    getTasks() {
      this.data = []
      this.errorText = ''

      if (this.tab === 'all') {
        const minAllFilters = [
          'filterApprovalStatus',
          'filterType',
          'filterAssignedTo',
          'filterPM',
          'filterCaptain',
          'filterPO',
          'filterCustomer',
          'filterTaskType',
          'filterBillingTaskStatus'
          // 'filterStage',
          // 'filterCostCenter'
        ]

        const hasAtLeastOneFilter = minAllFilters.some(filter => this[filter] !== null && this[filter] !== '')

        if (!hasAtLeastOneFilter) {
          this.errorText = 'Please select additional filtering.'
          return
        }
      } else {
        this.filterAssignedTo = this.viewAsEmployeeId || this.eid
      }

      const params = {
        action: 'get_billing_task_report',
        filters: {
          status: this.filterStatus,
          task_status: this.filterTaskStatus,
          task_type: this.filterTaskType,
          approval_status: this.filterApprovalStatus,
          filter_type: this.filterType,
          filter_job: this.filterJob,
          filter_wo: this.filterWO,
          assigned_to: this.filterAssignedTo,
          pm: this.filterPM,
          captain: this.filterCaptain,
          po: this.filterPO,
          customer: this.filterCustomer,
          region: this.filterRegion,
          stage: this.filterStage,
          cost_center: this.filterCostCenter,
          billing_task_status: this.filterBillingTaskStatus
        }
      }

      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Getting Tasks'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.data = data
        })
        .catch(err => {
          console.log(err)
          this.$snack.open(err.message || 'Problem getting tasks', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    addTask(obj) {
      // if no billing tasks yet open add new
      this.$Modal({
        parent: this,
        name: 'BillingTaskAdd',
        size: 'md',
        title: `Add Billing Task`,
        component: () => import('src/components/Dashboard/Billing/BillingTaskAdd.vue'),
        props: {
          Job_Number: obj.Job_Number,
          WO_Number: obj.WO_Number,
          openBillingTasks: true
        }
      })
    },

    openTask(id) {
      this.$Modal({
        parent: this,
        name: 'BillingTask', // used for closing specific modal programmatically
        size: 'xxl', // sm, md, lg, xl
        title: '',
        contentClasses: 'smoke-bg',
        hideClose: true,
        component: () => import('src/components/Dashboard/Billing/BillingTask.vue'),
        props: {
          id
        }
      })
    },

    openSetAssingedTo(id) {
      this.$Modal({
        parent: this,
        name: 'setAssignedTo',
        size: 'md', // sm, md, lg, xl
        title: 'Set Assigned To',
        component: () => import('src/components/Dashboard/Billing/SetAssignedTo.vue'),
        props: {
          id
        }
      })
    },

    handleModalClose(id) {
      const { taskType, taskId } = this.$route.params
      if (id === 'BillingTask' && taskType && taskId) this.$router.push({ path: '/app/billing-list' })
    }
  },

  mounted() {
    const employees = this.$store.dispatch('getEmployees')
    const customers = this.$store.dispatch('getCustomers')
    const regions = this.$store.dispatch('getRegions')
    const costCenters = this.$store.dispatch('getJobCostCenters')
    const sites = this.$store.dispatch('getWOSites')
    const contacts = this.$store.dispatch('getCustomerContacts')

    if (this.$route.query.show === 'waiting-pm-approval') {
      this.filterBillingTaskStatus = 'waiting-pm-approval'
      this.$router.replace({ query: {} })
    } else if (this.$route.query.show === 'waiting-captain-approval') {
      this.filterBillingTaskStatus = 'waiting-captain-approval'
      this.$router.replace({ query: {} })
    }

    this.$bus.$on('updateTask', this.getTasks)

    const { taskType, taskId } = this.$route.params

    if (taskType && taskId) {
      if (this.isAdmin) this.openTask(taskId)
      else this.$router.push({ path: `/app/tasks/${taskType}/${taskId}` })
    }

    this.$bus.$on('modalClose', this.handleModalClose)

    this.$bus.$on('subTaskUpdated', this.getTasks)

    // const wos = this.$store.dispatch('getWoAssemble')
    // const jobs = this.$store.dispatch('getJobAssemble')
    // this.getTasks()
  },

  watch: {
    tab: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal === 'my') {
            this.filterWO = ''
            this.filterJob = ''
          } else {
            this.data = []
            this.filterAssignedTo = 'unassigned'
          }
          this.getTasks()
        }
      }
    },

    viewAsEmployeeId: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.filterAssignedTo = newVal
          this.getTasks()
        }
      }
    },

    filterType(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === 'jobs') {
          this.$store.dispatch('getJobAssemble', ['A']) // active only
        } else if (newVal === 'wos') {
          this.$store.dispatch('getWoAssemble')
        }
      }
    }
  },

  beforeDestroy() {
    this.$bus.$off('updateTask', this.getTasks)
    this.$bus.$off('modalClose', this.handleModalClose)
    this.$bus.$off('subTaskUpdated', this.getTasks)
  }
}
</script>

<style lang="scss" scoped>
// @import "src/assets/sass/paper/_variables.scss";

/* Custom styles for larger screens */
.filter-row {
  font-size: 0; /* To remove whitespace between inline-block elements */
  margin-bottom: 10px;
}

.inline-block-row {
  display: inline-block;
  vertical-align: top;
  font-size: 16px; /* Reset font size */
  width: auto; /* Allow width to fit content */
  float: none; /* Override Bootstrap's float */
}

/* Bootstrap's styles will take over for smaller screens (col-sm-6) */
@media (max-width: 767px) {
  .inline-block-row {
    display: block;
    width: 100%;
  }
}

.report-filters {
  > div {
    margin-bottom: 5px;
  }
}
</style>

<style lang="scss">
// .tabulator-header {
//   position: -webkit-sticky; /* For Safari */
//   position: sticky;
//   top: 0;
//   z-index: 10; /* Ensure it is above other elements */
//   background: white; /* Optional: to ensure the header has a background */
// }

.tabulator-table {
  .tabulator-row {
    &.first-row {
      border-top: 2px solid #000;
      background: #eee;
    }

    &.second-row {
      background: #fefefe;
    }

    &.third-row {
      background: #eee;
    }

    &.fourth-row {
      background: #fefefe;
    }
  }

  [tabulator-field='title'] {
  }
}
</style>
