
<div>
  <div
    class="matches-container"
    v-if="matches.length"
  >
    <p><strong>Existing Matches</strong></p>
    <ul>
      <li
        v-for="(match, mi) in matches"
        :key="mi"
        @click="$bus.$emit('select-show-customer-contact', match.Id)"
      >
        <span
          v-if="match.FirstName"
          v-html="match.FirstName"
          class="is-link underlined"
        /> <span
        v-if="match.LastName"
        v-html="match.LastName"
        class="is-link underlined"
      /><span
        v-if="match.Email"
        v-html="', ' + match.Email"
      /><span
        v-if="match.Phone"
        v-html="', ' + match.Phone"
      /><span
        v-if="match.Title"
        v-html="' (' + match.Title + ')'"
      />
      </li>
    </ul>
  </div>
</div>
