
<!-- prettier-ignore -->
<div class="pdf-template" id="pdf-template">
  <div class="">
    <div class="header" id="pdfReady">
      <div class="row row-no-gutters" style="height:107px">
        <div class="col-xs-4">
          <div>
            <img :src="`${urls.frontend}static/img/${logoFile}`">
          </div>
        </div>
        <div class="col-xs-4" style="padding-top:5px">
          <p v-html="regionAddress"/>
        </div>
      </div>
      <div class="header-bottom col-xs-12"/>
    </div>

    <section class="section has-border">
      <div class="row">
        <div class="col-xs-6">
          <h3 style="margin:5px 0"><strong>PO #: {{ formulatedPONumber }}</strong></h3>
        </div>
        <div class="col-xs-6">

        </div>

      </div>
    </section>

    <section class="section has-border">
      <div class="row">
        <div class="col-xs-6 border-right" style="min-height:120px">
          <label>To:</label><br>
          {{ data.Vendor_Name }} <br>
          Attention: <br>
          <span v-if="data.Address_1">{{ data.Address_1 }}<br></span>
          <span v-if="data.Address_2">{{ data.Address_2 }}<br></span>
          <span v-if="data.Address_3">{{ data.Address_3 }}<br></span>
        </div>
        <div class="col-xs-6" style="min-height:120px">
          <label>Ship To:</label><br>
          {{ data.Ship_Name }} <br>
          {{ data.Ship_Address_1 }} <br>
          {{ data.Ship_City }}, {{ data.Ship_State }}, {{ data.Ship_Zip_Code }} <br>
        </div>
      </div>
    </section>

    <section class="section has-border">
      <div class="row">
        <div class="col-xs-6 border-right">
          <label>Order Date:</label> <span v-html="formatDate(data.PO_Date_List1)"></span>
        </div>
        <div class="col-xs-6">
          <label>Expected Delivery Date:</label> <span v-html="formatDate(data.PO_Date_List2)"></span>
        </div>
      </div>
    </section>

    <section class="section has-border">
      <div class="row has-border">
        <div class="col-xs-6 border-right">

          <div class="row">
            <div class="col-xs-6">
              <div style="padding:0 10px">
                <checkbox2 v-model="data.Ship_Terms" :true-val="'1'" :false-val="'2'" disabled>Ship Complete</checkbox2>
              </div>
            </div>
            <div class="col-xs-6">
              <div style="padding:0 10px">
                <checkbox2 v-model="data.Ship_Terms" :true-val="'2'" :false-val="'1'" disabled>Ship Partial</checkbox2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-6">
          <label>Job Desc:</label> {{ data.Job_Name }}
        </div>
      </div>
    </section>

    <p style="margin:20px 0 5px">Please supply the following goods subject to the conditions specified below:</p>

    <section class="section bom-list">
      <div class="bom-table">
        <div class="header-row table-row">
          <div>Qty</div>
          <div>Part #</div>
          <div>Desription</div>
          <div>Unit Price</div>
          <div>Extended Cost</div>
        </div>
        <div
          v-for="(s, si) in data.details"
          :key="si"
          class="table-number-content-parent table-row"
        >
          <div>{{ s.PO_Quantity_List1 }}</div>
          <div>{{ stripPart(s.Item_Code) }}</div>
          <div>{{ s.Item_Description }}</div>
          <div>{{ formatNumber(s.Item_Price, 1) }}</div>
          <div>{{ formatNumber(s.Line_Extension_List1, 1) }}</div>
        </div>
        <div>
          <div></div>
          <div></div>
          <div></div>
          <div><strong>Net</strong></div>
          <div><strong>{{ formatNumber(bomTotal, 1) }}</strong></div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="row">
        <div class="col-xs-12" style="font-size:13px;padding:5px 15px">
          Deliver no goods without a purchase order. Acknowledge receipt of this order, specifying prices. Make no substitutions or changes without authority from {{ companyName
          }} We reserve the right to cancel this order if this shipment is not made as promised. This order must not be billed at higher prices than quoted. Electrical components
          must be CA certified.
        </div>
      </div>
    </section>

    <section class="section" style="padding:20px 0">
      <div class="row">
        <div class="col-xs-2" style="font-size:13px;padding:5px 15px">
          <label>Ordered By:</label>
        </div>
        <div class="col-xs-10">
          ____________________________________________ {{ data.Ordered_By }} ___________________________________________
        </div>
      </div>
      <div class="row">
        <div class="col-xs-2" style="font-size:13px;padding:5px 15px">
          <label>Date:</label>
        </div>
        <div class="col-xs-10">
          {{ templateDate }}
        </div>
      </div>
    </section>

    <button
      class="btn not-pdf"
      @click="sendEmail()"
      v-html="'Email to Vendor'"
    />


    <div v-if="pdf" class="footer" style="margin-bottom:1px">
      <div class="footer-inner">
        <div class="footer-left">
          {{ data.template_date }}
        </div>
        <div class="footer-center">
          {{ companyName }} Ltd.
        </div>
        <div class="footer-right">
          <div class="page-number">
            <p>Page 1 of 1</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner/>
</div>
