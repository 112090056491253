<template>
  <vue-timepicker :input-class="inputClass" :format="'H:mm'" :minute-interval="15" :value="hoursObj" @change="selectTime" :readonly="readonly"></vue-timepicker>
</template>

<script>
import VueTimepicker from 'components/UIComponents/VueTimepicker/src/vue-timepicker.vue'

export default {
  components: {
    VueTimepicker
  },

  props: {
    value: String,
    inputClass: {
      type: Array,
      default: function () {
        return ['form-control', 'normal-text']
      }
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selected: ''
    }
  },

  computed: {
    hoursObj() {
      var obj = {}
      if (this.value) {
        obj = this.value.split('.')
      }
      var hoursObj = { H: '0', mm: '00' }
      if (obj[0]) {
        hoursObj.H = obj[0]
      }
      if (obj[1]) {
        hoursObj.mm = this.fractionToMin(obj[1])
      }
      return hoursObj
    }
  },

  methods: {
    fractionToMin(fraction) {
      var mins = ''
      switch (fraction) {
        case '25':
          mins = '15'
          break
        case '5':
          mins = '30'
          break
        case '75':
          mins = '45'
          break
        default:
          mins = fraction
      }
      return mins
    },

    minToFraction(min) {
      var fraction = ''
      switch (min) {
        case '15':
          fraction = '25'
          break
        case '30':
          fraction = '5'
          break
        case '45':
          fraction = '75'
          break
        default:
          fraction = min
      }
      return fraction
    },

    selectTime(obj) {
      if (obj && obj.data) {
        if (!obj.data.mm) {
          obj.data.mm = '00'
        }
        var val = obj.data.H + '.' + this.minToFraction(obj.data.mm)
        this.$emit('input', val)
      }
    }
  }
}
</script>
