
<div class="max-width-large">
  <br />
  <button class="btn" @click="addItem">Add Subcontractor</button>
  <br />
  <br />

  <alert />
  <bh-table :table-settings="tableSettings" @edit-item="editItem" />

  <waiting-spinner :wait="wait" />
</div>
