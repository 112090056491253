
<div style="height: 100%">
  <simple-header />
  <div style="padding: 10px 30px">
    <div class="row material-lists-component">
      <div class="col-sm-12">
        <h3>
          <strong><span v-text="listType === 'assembly' ? 'BOMs' : 'Material Lists'"></span></strong>
        </h3>

        <p class="text-info text-medium" v-if="listType === 'assembly'">These are groups of materials and can be added as an assembly into a Quote or RFP quotation.</p>
        <p class="text-info text-medium" v-else>These are collections of materials for individual use within quotes.</p>

        <p>
          Click on a list to open or <span class="is-link underlined" @click="openList"><strong>Add New</strong></span>
        </p>
        <material-groups-list @openMaterialGroupList="openList" :show-author="true" :list-type="listType" />
      </div>
    </div>
  </div>
</div>
