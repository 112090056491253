
<div class="tab-section white">
  <div class="content">
    <div class="card Main" style="padding: 15px">
      <div class="row" style="margin-bottom: 10px">
        <div class="col-sm-6 col-md-3">
          <div class="form-group">
            <label>Status</label>
            <select-field
              :options="statuses"
              :option-display-keys="['name']"
              :option-val="'id'"
              v-model="status"
              :default-value="status"
              placeholder="Select Status"
              :allow-clear="true"
              @selectItem="selectStatus"
            />
          </div>
        </div>
      </div>
      <div class="">
        <alert :alert="alert" />
        <bh-table :table-settings="tableSettings" @edit-item="editItem" />
        <waiting-spinner :wait="wait" />
      </div>
    </div>
  </div>

  <waiting-spinner />
</div>
