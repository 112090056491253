<template>
  <div class="max-width-xlarge">
    <div class="tab-section white">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{ active: tab === 'Main' }]">
          <a href="#" @click="changeTab('Main')"> Main </a>
        </li>
        <li
          role="presentation"
          :class="[
            { active: tab === 'Information' },
            { 'requires-item': !checkMetWoRequirement(['wo_completed_notes', 'wo_extras_notes', 'wo_internal_notes', 'wo_recommendation_notes', 'wo_work_ordered_notes']) }
          ]"
        >
          <a href="#" @click="changeTab('Information')"> Info </a>
        </li>
        <li role="presentation" :class="[{ active: tab === 'Files' }, { 'requires-item': !checkMetWoRequirement(['preWorkPhotos', 'postWorkPhotos']) }]" v-if="isPM">
          <a href="#" @click="changeTab('Files')"> Doc's and Photos </a>
        </li>
        <li role="presentation" :class="[{ active: tab === 'Labor' }]">
          <a href="#" @click="changeTab('Labor')"> Labor </a>
        </li>
        <li role="presentation" :class="[{ active: tab === 'Other Charges' }]">
          <a href="#" @click="changeTab('Other Charges')"> Other Charges </a>
        </li>
        <li role="presentation" :class="[{ active: tab === 'Contacts' }]">
          <a href="#" @click="changeTab('Contacts')"> Contacts </a>
        </li>
        <li role="presentation" :class="[{ active: tab === 'Purchasing' }]">
          <a href="#" @click="changeTab('Purchasing')"> POs </a>
        </li>
        <li role="presentation" :class="[{ active: tab === 'PM' }]">
          <a href="#" @click="changeTab('PM')"> PM </a>
        </li>
        <!-- <li v-if="isAdmin || isWOPM" role="presentation" :class="[{active: tab === 'PM'}]">
          <a href="#" @click="changeTab('PM')">
            PM
          </a>
        </li> -->
      </ul>

      <div :class="['card', tab]">
        <div class="content Xtab-content">
          <div v-if="tab == 'Main'">
            <wo-add-edit v-if="woid" :woid="woid" :editable="isEditable" @selectedCustomer="woCustomer" :is-finished="isFinished" :is-locked="isLocked" :key="woid" />
          </div>

          <div v-else-if="tab == 'Information'" :class="['tab', tab]">
            <wo-info :read-only="isLocked" :woid="woid" :customer-code="wo.Bill_Customer_Code" />
            <p v-if="isLocked">
              <i>Notes cannot be changed when WO in Locked status.</i>
            </p>
          </div>

          <div v-else-if="tab == 'Files'" :class="['tab', tab]">
            <wo-files :woid="woid" />
          </div>

          <div v-else-if="tab == 'Other Charges'" :class="['tab', tab]">
            <wo-other-charges :woid="woid" :read-only="isLocked" />
          </div>

          <div v-else-if="tab == 'Labor'" :class="['tab', 'labor', 'tab-labor', tab]">
            <job-wo-labor type="WO" :itm-id="parseInt(woid)" :is-locked="Boolean(isLocked)" :allow-download="true" />
          </div>

          <div v-else-if="tab == 'Contacts'" :class="['tab', 'contacts', 'tab-contacts', tab]">
            <wo-contacts :woid="woid" />
          </div>

          <div v-else-if="tab == 'Purchasing'" :class="['tab', 'purchasing', 'tab-purchasing', tab]">
            <wo-pos :itm-code="woid" :wo="wo" :is-locked="isLocked" />
          </div>

          <div v-else-if="tab == 'PM'" :class="['tab', 'pm', 'tab-pm', tab]">
            <wo-wip-details :input-wo-id="woid" :is-locked="isLocked" ref="woWipDetails" />
          </div>

          <div v-if="tab === 'Costing'">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div :class="'detail-item'">
                  <label>Labor Cost</label>
                  <p>{{ formatNumber(WOPMDetails.labor_Cost, 1) }}</p>
                </div>

                <div :class="'detail-item'">
                  <label>Materials Cost</label>
                  <p>{{ formatNumber(WOPMDetails.materials_cost, 1) }}</p>
                </div>

                <div :class="'detail-item'">
                  <label>Other Cost Total</label>
                  <p>{{ formatNumber(WOPMDetails.other_cost_total, 1) }}</p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div :class="'detail-item'">
                  <label>WO Hours</label>
                  <p>{{ formatNumber(WOPMDetails.WO_Hours) }}</p>
                </div>

                <div :class="'detail-item'">
                  <label>Total Billed</label>
                  <p>{{ formatNumber(WOPMDetails.total_billed, 1) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button v-if="(isAdmin || !isSigned) && isLoaded" class="btn" @click="getOpenWoSignOffUrl()" v-html="'WO Sign Off'" />

    <button v-if="isLocked && isLoaded && canUnlockWOs" class="btn" @click="unLockWO()" v-html="'Unlock'" />

    <a class="btn" v-if="ticketUrl" :href="ticketUrl" target="'_blank" v-html="'Customer Ticket'" />

    <!-- <p v-if="isLocked" class="text-left">
      <i>WO locked (Stage not TBS or In Progress)</i>
    </p> -->

    <waiting-spinner />
    <!-- covers all on this page -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import WOFuncs from 'mixins/WOFuncs'
import NavTabs from 'mixins/navTabs'
import WoFiles from 'components/Dashboard/JobWo/WOFiles'
import WoInfo from 'components/Dashboard/JobWo/WOInfo'
import WoOtherCharges from 'components/Dashboard/JobWo/WOOtherCharges'
import WoContacts from 'components/Dashboard/JobWo/WOContacts'
import WoAddEdit from 'components/Dashboard/JobWo/WOAddEdit'
import WoPos from 'components/Dashboard/JobWo/WOPOs'
import WoWipDetails from 'src/components/Dashboard/JobWo/WOWIPDetails'
import WOAdminOptions from 'src/components/Dashboard/JobWo/WOAdminOptions.vue'
import JobWoLabor from 'components/Dashboard/JobWo/JobWOLabor'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import sanitizeHtml from 'sanitize-html'

export default {
  data() {
    return {
      tab: 'Main',
      wait: {},
      wo: {},
      woid: this.$route.params.id,
      woSignOff: '',
      WOPMDetails: {},
      tabsInfo: [{ name: 'Internal Notes' }, { name: 'Work Ordered' }, { name: 'Work Order Notes' }, { name: 'Work Order Extras' }, { name: 'Recommendations' }, { name: 'Work Completed' }],
      laborTableSettings: {
        fields: {
          Name: {
            name: 'Employee Name'
          },
          Work_Date: {
            name: 'Work Date'
          },
          Hours: {
            name: 'Hours'
          },
          Pay_Type: {
            name: 'Pay Type'
          },
          Posted: {}
        },
        tableData: [],
        hideSearch: true,
        noItemEdit: true
      },
      laborTableData: [],
      isLoaded: false,
      timeCardEntries: {}
    }
  },

  components: {
    WoFiles,
    WoInfo,
    WoAddEdit,
    WoContacts,
    WoOtherCharges,
    WoPos,
    JobWoLabor,
    WoWipDetails,
    WaitingSpinner
  },

  mixins: [NavTabs, WOFuncs],

  computed: {
    ...mapGetters(['woLaborHours', 'employees']),

    userID() {
      return this.$store.getters.userAuthedData.eid
    },

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPM() {
      return this.$store.getters.userAuthedData.user_role.indexOf('pm') !== -1
    },

    canUnlockWOs() {
      return this.isPM && this.$store.getters.userAuthedData.user_role.indexOf('U') !== -1
    },

    isEditor() {
      return this.$store.getters.userAuthedData.user_role.indexOf('editor') !== -1
    },

    isWOPM() {
      if (this.userID == this.wo.PM) {
        return true
      }
      return false
    },

    isWOTech() {
      if (this.userID == this.wo.tech_main) {
        return true
      }

      if (this.wo.techs && this.wo.techs.length && this.wo.techs.indexOf(this.userID.toString()) !== -1) {
        return true
      }
      return false
    },

    isEditable() {
      if (!this.woid || (!this.isFinished && !this.isLocked)) {
        return true
      }
      return false
    },

    ticketUrl() {
      // if (process.env.NODE_ENV !== 'development') {
      //   return '';
      // }

      if (this.wo.ticket_link) {
        return this.$store.getters.urls.frontend + '#/customer-ticket/wo/' + this.wo.ticket_link
      } else {
        return ''
      }
    },

    isLocked() {
      const unlockedPriorityCodes = [1, 2]
      if (unlockedPriorityCodes.includes(parseInt(this.wo.Priority_Code))) return false

      return true
    },

    isSigned() {
      const signedCodes = [13, 4]
      if (signedCodes.includes(parseInt(this.wo.Priority_Code))) return true
      return false
    }
  },

  methods: {
    fieldNotEmpty(str) {
      if (str && typeof str !== 'object') {
        return true
      } else {
        return false
      }
    },

    formatNumber(num, dollar) {
      return appFuncs.formatNumber(num, dollar)
    },

    getWOPMDetails() {
      var data = {
        action: 'get_wo_pm_details',
        wo_number: this.woid
      }

      this.$bus.$emit('setWaiting', {
        name: data.action,
        message: 'Fetching Work Order PM Details'
      })

      appFuncs.ajax_request(
        this.$store.getters.sherAuth,
        data,
        result => {
          this.$bus.$emit('stopWaiting', data.action)
          if (result.status === 'success') {
            let WOPMDetails = result.data.find(itm => {
              return itm.WO_Number == this.woid
            })
            // keep as object if undefined
            if (WOPMDetails) {
              this.WOPMDetails = WOPMDetails
            } else {
              this.$snack.open('There has been a problem retrieving Work Order PM data', 'error')
            }
          } else {
            this.$snack.open(result.message, 'error')
          }
        },
        1,
        this.$store.getters.urls.mssql + 'ajax.php'
      )
    },

    woCustomer(obj) {
      this.wo.Bill_Customer_Code = obj.Customer_Code || this.wo.Bill_Customer_Code
    },

    openWOAdminOptions() {
      this.$Modal({
        parent: this,
        name: 'wo-admin-options', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        title: 'Work Order Options',
        component: WOAdminOptions,
        props: {
          woid: this.woid,
          tab: 'Main',
          woSignOffTemplateLink: this.woSignOff
        }
      })
    },

    openWOWipMain() {
      this.$Modal({
        parent: this,
        name: 'WOWIPDetails', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        component: WOWIPDetails,
        props: {
          inputWoId: this.woid,
          tab: 'Main'
        }
      })
    },

    openCustomerUpdatePrompt() {
      const notifications = this.wo.notifications
      if (!notifications) {
        return
      }

      let hasNotifyable = false
      for (var i = notifications.length - 1; i >= 0; i--) {
        if (notifications[i].types && notifications[i].types.indexOf('general') !== -1) {
          hasNotifyable = true
          break
        }
      }

      if (hasNotifyable) {
        this.$bus.$emit('modalOpen', {
          component: () => import('src/components/Dashboard/JobWo/WONotifyCustomerSend'),
          size: 'modal-md',
          name: 'wo-notify-customer-send',
          title: 'Send Customer WO Update Notification',
          classes: 'gray-bg',
          componentData: {
            component: 'wo-notify-customer-send',
            woid: this.woid
          }
        })
      }
    },

    unLockWO() {
      if (!this.canUnlockWOs) {
        return
      }

      if (!confirm('Unlocking this Work Order will allow changes and re-enable signoff.')) {
        return
      }

      let data = {
        action: 'wo_status_update',
        wo_number: this.woid,
        priority_code: 2
      }

      this.$bus.$emit('setWaiting', { name: data.action, message: 'Unlocking WO' })
      appFuncs
        .shRequest(data)
        .then(() => {
          // trigger child components to reload
          this.$bus.$emit('refreshWODetailsBase', 1)
          this.loadData()

          if (this.$refs.woWipDetails) {
            this.$refs.woWipDetails.loadData(this.woid)
          }
        })
        .catch(res => {
          this.$snack.open('Problem unlocking WO please try again later.', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', data.action)
        })
    },

    getOpenWoSignOffUrl() {
      if (!this.woid) {
        return
      }
      this.woSignOff = this.wo.sign_off_link
      this.openWOAdminOptions()
    },

    loadData(refresh) {
      return new Promise(async resolve => {
        if (!this.woid) {
          resolve()
          return
        }

        this.$bus.$emit('setWaiting', { name: 'getWOWip', message: 'Getting WO Data' })

        const params = {
          action: 'assemble_wip_wo_data',
          woNumber: this.woid
        }

        appFuncs
          .shRequest(params)
          .then(data => {
            this.wo = data[0]
            this.isLoaded = true
          })
          .catch(err => {
            this.$snack.open('Problem refreshing WO data, please close and try again', 'error')
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'getWOWip')
          })
        resolve()
      })
    },

    showCreateBillingTask() {
      this.$Modal({
        parent: this,
        name: 'BillingTaskAdd',
        size: 'md',
        title: `Add Billing Task`,
        component: () => import('src/components/Dashboard/Billing/BillingTaskAdd.vue'),
        props: {
          WO_Number: this.woid,
          setTaskType: 'BC',
          setAssignedTo: null
        }
      })
    },

    listenSelectWo(woNumber) {
      this.$router.push({ path: `/app/wo-details/${woNumber}` })
      this.woid = woNumber
    }
  },

  mounted() {
    this.$store.dispatch('getEmployees')
    this.loadData()

    this.$bus.$on('itemCommentUpdate', obj => {
      if (obj && obj.noteTable) {
        const notifyNotes = ['WOCompletedNotes']
        if (notifyNotes.indexOf(obj.noteTable) !== -1) {
          this.openCustomerUpdatePrompt()
        }
      }
    })

    this.$bus.$on('filesUploaded', obj => {
      if (obj && obj.name) {
        const notifyNames = ['Pre-Work Photos', 'Post-Work Photos']
        if (notifyNames.indexOf(obj.name) !== -1) {
          this.openCustomerUpdatePrompt()
        }
      }
    })

    this.$bus.$on(['updateWOAssemble', 'refreshWODetailsBase'], this.loadData)
    this.$bus.$on('selectSetWo', this.listenSelectWo)
    this.getWoRequirementItems(true)
  },

  watch: {
    tab() {
      if (this.tab === 'PM') {
        // this.getWOPMDetails();
      }
    }
  },

  beforeDestroy() {
    this.$bus.$off(['updateWOAssemble', 'refreshWODetailsBase'], this.loadData)
    this.$bus.$off('selectSetWo', this.listenSelectWo)
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

hr {
  margin: 20px 0;
}

.content {
  padding: 30px !important;
  position: relative;
}

.tab {
  &.contacts h5 {
    margin: 0 0 10px;
    font-size: 18px;
  }
}

p {
  min-height: 14px;
}

.detail-item--edit {
  position: relative;
}

.tab {
  //min-height:400px;
  position: relative;
}

.sh-widget-container {
  overflow: scroll;
}

.sh-widget {
  max-width: 100%;
  min-width: 600px;

  &.table-container {
    background: #fff;
    min-width: 600px;
  }
  .empty-result {
    padding: 5px;
    font-style: italic;
    font-size: 15px;
  }

  td {
    padding: 5px 10px;
  }

  tr {
    .wide {
      width: 250px;
    }

    @media (min-width: 700px) {
      .narrow {
        width: 80px;
      }

      .medium {
        width: 150px;
      }
      .wide {
        width: 500px;
      }
    }

    &.orange {
      background: $input-orange;

      td {
        border-color: darken($input-orange, 4%);
      }
    }

    &.totals-row {
      font-weight: bolder;

      td {
        border: none;

        &.not-empty {
          border: 1px solid $light-gray;
        }
      }
    }

    .empty {
      padding: 3px;
    }
  }
}
</style>
