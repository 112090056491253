
<div class="select-field" v-click-outside="closeField">
  <input
    type="text"
    :class="[
      'form-control',
      'box-add-item',
      {
        'select-field': !inline
      },
      { 'no-bottom-radius': inline },
      fieldClasses
    ]"
    v-model="fieldVal"
    readonly
    @click="openDropdown"
    :placeholder="placeholder"
    :disabled="disabled"
  />
  <div v-if="(showOptions || inline) && !disabled" class="option-box-parent">
    <div :class="['option-box', { inline: inline }, { toggle: !inline }]">
      <input v-if="compOptions && compOptions.length > 6" type="text" :class="['form-control', 'search-field', 'has-danger']" v-model="searchFilter" placeholder="Filter" />

      <div v-if="allowClear && Object.keys(selectedObj).length > 0" class="option-items show-scrollbar" style="background: none; padding: 0">
        <div class="option-item empty-option-item" @click="doClear">
          <span><span v-html="typeof allowClear === 'string' ? allowClear : 'CLEAR'" /></span>
        </div>
      </div>

      <div class="option-items show-scrollbar">
        <div v-if="showOptionItems">
          <div v-for="(optionItem, key) in filteredItems" :key="key" class="option-item" @click="userSelectEl(optionItem)">
            <div v-if="formatOptionDisplay(optionItem)" v-html="formatOptionDisplay(optionItem)" />
          </div>
        </div>
        <div v-else-if="compOptions && compOptions.length">
          <i v-if="hideOptions && searchFilter.length < 3">Search for an item</i>
          <i v-else>No results</i>
        </div>
        <div v-else>
          <i>{{ emptyOptionText }}</i>
        </div>
      </div>
      <div v-if="allowAddSimpleItem" class="add-simple-item-container">
        <input class="form-control" type="text" v-model="addSimple" :placeholder="'Add ' + (itemName ? itemName : 'Item')" />
        <button class="btn" @click="addSimpleVal" v-html="'Add'" />
      </div>
      <span v-if="allowAddItem" class="add-new" @click="$emit('addItem')">Add New</span>
    </div>
  </div>
</div>
