<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div :class="['group-container', { 'separate-section': separateSection }]" v-if="singleField">
          <table>
            <thead>
              <tr>
                <th>{{ label }}</th>
                <th class="control-column"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="form-group">
                    <vue-numeric
                      v-if="options.length && valueType === 'dollar'"
                      currency="$"
                      separator=","
                      :class="['form-control']"
                      :precision="2"
                      v-model="options[0].value"
                      inputmode="decimal"
                      :read-only="detailValue"
                    />
                    <input type="text" v-else-if="options.length" v-model="options[0].value" class="form-control fake-readonly" @click="editItem(options[0])" :readonly="detailValue" />
                  </div>
                </td>
                <td class="control-column">
                  <div class="controls">
                    <span v-if="hasChange(options[0])" @click="saveOption(options[0])" class="edit-save is-link">
                      <i class="ti-check" />
                    </span>
                    <span v-if="hasChange(options[0])" @click="cancelChange(options[0])" class="edit-cancel is-link">
                      <i class="ti-na" />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div :class="['group-container', { 'separate-section': separateSection }]" v-else>
          <table>
            <thead>
              <tr>
                <th>{{ label }}</th>
                <th v-if="showValue">Value</th>
                <th class="default-column" v-if="showDefault">Default</th>
                <th class="control-column"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="option in sortedOptions" :key="option.id">
                <td class="key-column">
                  <div class="form-group">
                    <vue-numeric v-if="valueType === 'dollar'" currency="$" separator="," :class="['form-control']" :precision="2" v-model="option.name" inputmode="decimal" :readonly="detailValue" />
                    <input type="text" v-else v-model="option.name" class="form-control fake-readonly" @click="editItem(option)" :readonly="detailValue" />
                  </div>
                </td>
                <td v-if="showValue" class="value-column">
                  <div class="form-group">
                    <input type="text" v-model="option.value" class="form-control fake-readonly" @click="editItem(option)" :readonly="true" />
                  </div>
                </td>
                <td class="default-column" v-if="showDefault">
                  <checkbox2 v-model="option.default" :true-val="'yes'" :false-val="'no'" @change="setDefault(option)" />
                </td>
                <td class="control-column">
                  <div class="controls">
                    <span v-if="hasChange(option)" @click="saveOption(option)" class="edit-save is-link">
                      <i class="ti-check" />
                    </span>
                    <span v-if="hasChange(option)">
                      <span @click="cancelChange(option)" class="edit-cancel is-link">
                        <i class="ti-na" />
                      </span>
                    </span>
                    <span v-else>
                      <span @click="moveItemUp(option)" class="edit-move is-link">
                        <i class="ti-angle-up" />
                      </span>
                      <span @click="moveItemDown(option)" class="edit-move is-link">
                        <i class="ti-angle-down" />
                      </span>
                    </span>
                    <span @click="deleteItem(option)" class="edit-delete is-link" v-if="allowDelete">
                      <i class="ti-close" />
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <button class="btn btn-sm" @click="addOption">Add Option</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import SelectField from 'components/UIComponents/SelectField'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import SelectFieldMulti from 'components/UIComponents/SelectFieldMulti'
import DatePicker from 'components/UIComponents/DatePicker'
import commonFuncs from 'mixins/commonFuncs'
import Checkbox2 from 'components/UIComponents/Checkbox'
import VueNumeric from 'vue-numeric'
import editComponentDefault from 'components/UIComponents/AdminOptionFieldDefaultEditor.vue'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'AdminOptionField',
  data() {
    return {
      options: [],
      loadedData: [],
      editRef: null,
      editComponentDefault
    }
  },

  components: {
    Checkbox2,
    VueNumeric,
    WaitingSpinner
  },

  props: {
    label: {
      type: String,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    valueType: {
      type: String,
      default: 'text'
    },
    singleField: {
      type: Boolean,
      default: false
    },
    detailValue: {
      type: Boolean,
      default: false
    },
    separateSection: {
      type: Boolean,
      default: true
    },
    showValue: {
      type: Boolean,
      default: false
    },
    editComponent: {
      type: Object,
      default: () => {
        return editComponentDefault
      }
    },
    editComponentName: {
      // needed for closing specific modal programmatically
      type: String,
      default: 'EditAdminOptionValue'
    },
    showDefault: {
      type: Boolean,
      default: true
    },
    allowDelete: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters(['userAuthedData']),

    sortedOptions() {
      return this.options
    }
  },

  methods: {
    addOption() {
      let highestSort = this.options[this.options.length - 1].sort

      this.options.push({
        id: this.randomCharacterString(10),
        name: '',
        default: this.options.length ? 'no' : 'yes',
        sort: highestSort + 1
      })
    },

    setDefault(obj) {
      // find current defaults and se to no
      var currentDefaults = this.options.filter(o => o.default === 'yes')
      for (let i = 0; i < currentDefaults.length; i++) {
        if (currentDefaults[i].id === obj.id) continue
        currentDefaults[i].default = 'no'
        this.saveOption(currentDefaults[i])
      }
      this.saveOption(obj)
    },

    hasChange(obj) {
      if (!this.loadedData.length) return false
      let loadedOption = this.loadedData.find(o => o.id === obj.id)
      if (loadedOption) {
        return JSON.stringify(loadedOption) !== JSON.stringify(obj)
      } else {
        return true
      }
    },

    cancelChange(obj) {
      let loadedOption = this.loadedData.find(o => o.id === obj.id)
      if (loadedOption) {
        let index = this.options.findIndex(o => o.id === obj.id)
        this.options[index].name = loadedOption.name
        this.options[index].value = loadedOption.value || ''
      }
    },

    editItem(obj) {
      if (!this.detailValue) return

      this.$Modal({
        parent: this,
        name: this.editComponentName, // used for closing specific modal programmatically
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: this.editComponent,
        contentClasses: 'xsmoke-bg',
        props: {
          obj,
          singleField: this.singleField,
          editRef: this.editRef
        }
      })
    },

    saveOption(obj) {
      var params = {
        action: 'upsert_global_option',
        id: obj.id,
        name: obj.name,
        text_value: obj.value || '',
        default: obj.default,
        group: this.field,
        sort: obj.sort
      }

      this.$bus.$emit('setWaiting', { name: 'update_quote', message: 'Updating quote' })
      appFuncs
        .shHttp(params)
        .then(res => {
          this.$snack.open('Updated', 'success')
          // update loadedData
          let localObj = this.options.findIndex(o => o.id === obj.id)
          if (localObj > -1) {
            this.options[localObj] = JSON.parse(JSON.stringify(obj))
          }
          let loadedOption = this.loadedData.findIndex(o => o.id === obj.id)
          if (loadedOption > -1) {
            this.loadedData[loadedOption] = JSON.parse(JSON.stringify(obj))
          } else {
            this.loadedData.push(JSON.parse(JSON.stringify(obj)))
          }
          this.options = [...this.options]

          this.options.sort((a, b) => {
            return a.sort - b.sort
          })

          this.loadedData = [...this.loadedData]
        })
        .catch(err => {
          this.$snack.open(err.message || 'Problem updating quote', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'update_quote')
        })
    },

    deleteItem(obj) {
      if (!this.allowDelete) return

      if (obj.default === 'yes') {
        this.$snack.open('Cannot delete default option', 'warning')
        return
      }

      if (!confirm('Deleting options is permanent, are you sure?')) return

      var params = {
        action: 'delete_global_option',
        obj
      }

      this.$bus.$emit('setWaiting', { name: 'delete_global_option', message: 'Deleting' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.$snack.open('Deleted', 'success')
          let option = this.options.findIndex(o => o.id === obj.id)
          if (option > -1) {
            this.options.splice(option, 1)
          }
          let loadedOption = this.loadedData.findIndex(o => o.id === obj.id)
          if (loadedOption > -1) {
            this.loadedData.splice(loadedOption, 1)
          }
        })
        .catch(err => {
          this.$snack.open(err.message || 'Problem deleting option', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'delete_global_option')
        })
    },

    moveItemUp(option) {
      let options = this.options
      let index = options.findIndex(o => o.id === option.id)
      if (index === 0) return
      let temp = options[index - 1].sort
      options[index - 1].sort = options[index].sort
      options[index].sort = temp

      this.options = [...options]
      this.saveOption(options[index - 1])
      this.saveOption(options[index])
    },

    moveItemDown(option) {
      let options = this.options
      let index = options.findIndex(o => o.id === option.id)
      if (index === options.length - 1) return
      let temp = options[index + 1].sort
      options[index + 1].sort = options[index].sort
      if (options[index].sort === temp) {
        options[index].sort = temp + 1
      }
      options[index].sort = temp
      this.options = [...options]
      this.saveOption(options[index + 1])
      this.saveOption(options[index])
    },

    // for all options, make sure no diplicate sort values, save each only if sort value is changed
    fixSort() {
      let options = [...this.options]
      options.sort((a, b) => {
        return a.sort - b.sort
      })
      let sort = 1
      for (let i = 0; i < options.length; i++) {
        if (options[i].sort !== sort) {
          options[i].sort = sort
          // this.saveOption(options[i]);
        }
        sort++
      }
    },

    getData() {
      var params = {
        action: 'get_global_options',
        group: this.field
      }

      this.$bus.$emit('setWaiting', { name: 'get_global_options', message: 'Getting Options' })
      appFuncs
        .shHttp(params)
        .then(res => {
          this.options = res.data && res.data.length ? res.data : [{ id: this.randomCharacterString(12), name: '', value: '', default: 'yes', sort: 0, group: this.field }]
          // make sure each option has a blank value if not set

          for (let i = 0; i < this.options.length; i++) {
            this.options[i].value = this.valueType === 'dollar' ? parseFloat(this.options[i].value || 0) : this.options[i].value || ''
            this.options[i].sort = this.options[i].sort || i
          }

          this.options.sort((a, b) => {
            return a.sort - b.sort
          })

          this.fixSort()

          this.loadedData = JSON.parse(JSON.stringify(this.options))
        })
        .catch(err => {
          this.$snack.open(err.message || 'Problem getting setup options', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'get_global_options')
        })
    },

    listenSaveAdminOption(obj) {
      if (obj.editRef === this.editRef) {
        this.saveOption(obj)
      }
    }
  },

  mounted() {
    this.getData()
    this.$bus.$on('saveAdminOption', this.listenSaveAdminOption)
    this.editRef = this.randomCharacterString(20)
  },

  beforeDestroy() {
    this.$bus.$off('saveAdminOption', this.listenSaveAdminOption)
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.group-container {
  &.separate-section {
    border: 0px solid $light-gray;
    // padding: 10px;
    margin: 10px 0;
    max-width: 800px;
  }

  h4 {
    margin: 5px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;

  th {
    padding: 5px;
  }

  td {
    padding: 5px;

    .form-group {
      margin: 0 !important;
    }

    &.key-column {
      width: calc(100% - 200px);
    }

    &.value-column {
      width: calc(100% - 200px);
    }

    &.default-column {
      width: 2px;
    }

    &.control-column {
      width: 110px;
    }

    .controls i {
      font-size: 12px !important;
    }
  }
}
</style>
