
<div>
  <div class="row">
    <div class="col-sm-12">
      <div class="new-request">
        <div class="tab-section white">
          <ul class="nav nav-tabs">
            <li role="presentation" :class="[{ active: tab == 'Request' }]">
              <a href="#" @click="tab = 'Request'"> Request </a>
            </li>
            <li role="presentation" :class="[{ active: tab == 'stats' }]">
              <a href="#" @click="tab = 'stats'"> Used/Earned </a>
            </li>
            <li role="presentation" :class="[{ active: tab == 'Instructions' }]">
              <a href="#" @click="tab = 'Instructions'"> Instructions </a>
            </li>
          </ul>

          <div :class="[{ 'first-active': tab == 'Request' }, 'tab', tab, 'tab-content', 'card']">
            <div class="content">
              <div v-if="tab == 'Request'">
                <!-- <div class="form-group">
                  <label for="note_text">Email Address</label>
                  <input type="text" class="form-control" v-model="email">
                </div> -->
                <div class="row">
                  <div class="column col-sm-12 col-md-6">
                    <div class="form-group">
                      <label>First Day Off</label>
                      <date-picker v-model="first_off" :minDate="minDateFirst" :format="'date'" :preFilled="false"></date-picker>
                    </div>
                  </div>
                  <div class="column col-sm-12 col-md-6">
                    <div class="form-group">
                      <label>Last Day Off</label>
                      <date-picker v-model="last_off" :minDate="minDateLast" :format="'date'" :preFilled="false"></date-picker>
                    </div>
                  </div>
                </div>

                <label>Vacation Request Reason:</label>
                <ul class="form-group">
                  <li>
                    <input type="radio" id="reason1" v-model="reason" value="Vacation" />
                    <label for="reason1">Vacation</label>
                  </li>
                  <li>
                    <input type="radio" id="reason2" v-model="reason" value="Medical or Family Emergency" />
                    <label for="reason2">Medical or Family Emergency</label>
                  </li>
                  <li>
                    <input type="radio" id="reason3" v-model="reason" value="Other" />
                    <label for="reason3">Other</label>
                  </li>
                </ul>

                <p class="text-center">
                  <checkbox v-model="readInstructions"> I've read and understand the instructions.</checkbox>
                </p>

                <button type="submit" class="btn" v-on:click="sendForm()">Send Request</button>
              </div>

              <div v-if="tab == 'stats'" :class="['tab', tab, 'tab-content', '']">
                <div class="content show-scrollbars">
                  <bh-table :table-settings="tableSettingsStats" />
                </div>
              </div>

              <div class="instructions" v-if="tab == 'Instructions'">
                <p>Use this form to request time off for any reason. If you have multiple blocks of time to request off (separated by a day where you are working), submit them each individually.</p>

                <p>
                  We will do our best to accommodate all vacation requests and dates requested however, each request is subject to review and approval. If you request time off with less than two
                  weeks notice there is a greater chance it may be rejected due to prior scheduling or job requirements. Time requested with two weeks or more notice will most often be approved but
                  we must ensure first that there are not too many people off at any one time and that we will be able to satisfy job labor needs.
                </p>

                <p>Do not book vacation without prior approval first as there is always a possibility that the dates requested off will not be approved.</p>

                <p>Vacation requests will be approved or rejected with alternate dates within five (5) working days.</p>

                <p>If you have any questions, contact the office: 905-625-0265</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4>All Requests</h4>
      <div class="card">
        <div class="content">
          <div class="row" style="margin-bottom: 30px" v-if="canManage">
            <div class="col-md-3">
              <label>Status</label>
              <select-field :options="statuses" :label-text="'Status'" :option-display-keys="['name']" :option-val="'name'" v-model="status" @input="changeStatus()" />
            </div>
            <div class="col-md-3">
              <label>Select Employee</label>
              <select-field-dynamic
                :options="filteredEmployees"
                :option-display-keys="['Employee_Name']"
                :option-val="'id'"
                v-model="fetchEmployeeId"
                @selectItem="changeStatus()"
                :preselect-single-option="false"
                :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                label="Employee"
                :allow-clear="canManage"
              />
            </div>
            <div class="col-md-3">
              <label>Year</label>
              <select-field :options="years" :label-text="'Year'" :option-display-keys="['years']" :option-val="'years'" v-model="year" @input="changeStatus()" :allow-clear="true" />
            </div>
          </div>
          <bh-table :table-settings="tableSettings" @edit-item="editRequest" />
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner :wait="wait" />
</div>
