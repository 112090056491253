<template>
  <div>
    <div class="login-form container">
      <div class="welcome-logo">
        <img :src="urls.frontend + 'static/img/logo.jpg'" />
      </div>

      <div class="note-form-inner">
        <form id="login-form" @submit.prevent="login_employee">
          <br />
          <div class="form-group">
            <label for="employee_code">Login</label>
            <input type="text" class="form-control" name="employee_code" v-model="formVals.employee_id" />
          </div>

          <div class="form-group">
            <label for="employee_pw">Password</label>
            <input type="password" class="form-control" name="employee_pw" v-model="formVals.employee_pw" autocomplete="off" />
          </div>

          <div>
            <div class="button-container">
              <button class="btn btn-primary" @click="login_employee">Log In</button>
            </div>
          </div>

          <waiting-spinner />
        </form>
        <div class="login-options-container">
          <a href="#" class="password-reset" @click="requestPassword()">Retrieve Login ID/Password?</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appFuncs from 'appFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import $ from 'jquery'

export default {
  components: { WaitingSpinner },
  data() {
    return {
      noteType: 'Job',
      activeTab: 'login',
      formVals: {
        employee_id: '',
        employee_pw: '',
        employee_pw_confirm: ''
      }
    }
  },

  computed: {
    urls() {
      return this.$store.getters.urls
    },

    userAuthedData() {
      return this.$store.getters.userAuthedData
    },

    noteInnerClasses() {
      var classes = 'note-form-inner '
      classes = classes + this.activeTab
      return classes
    }
  },

  methods: {
    login_employee() {
      this.$store.commit('waiting', { status: 1, message: 'Waiting for login server' })
      var data = this.formVals
      data.action = 'login_employee'
      $.ajax({
        url: this.urls.backend + 'ajax.php',
        type: 'POST',
        data: $.param(data),
        dataType: 'json',
        success: result => {
          this.$store.commit('waiting', { status: 0 })
          if (result['status'] === 'success') {
            if (result['jwt']) {
              var obj = {
                jwt: result['jwt'],
                eid: result['eid'],
                user_login: result['user_login'],
                expires: result['expires']
              }

              if (typeof result.params.pass_type !== 'undefined' && result.params.pass_type === 'given') {
                obj['pw'] = 'reset'
              }

              this.$store.dispatch('setSherAuth', obj)
              this.$store.dispatch('setUserAuthedData', {
                user_login: result.user_login,
                eid: result.eid,
                fname: result.data.First_Name,
                mname: result.data.Middle_Name,
                lname: result.data.Last_Name,
                user_role: result.user_role,
                employee_role: result.employee_role
              })

              console.log('force reload from login success')
              location.reload(true)

              // clear form
              this.formVals = {
                employee_pw: '',
                employee_pw_confirm: ''
              }
            }
          } else {
            this.$snack.open(result.message, 'error')
          }
        },
        error: (e, status) => {
          this.$store.commit('waiting', { status: 0 })
          console.log(status)
          console.log(e)
          this.$snack.open('Problems fetching login data, please try again now or later.', 'error')
        }
      })
    },

    changeTab(tab, $event) {
      $event.preventDefault()
      this.activeTab = tab
    },

    requestPassword() {
      var modalObj = {
        trigger: 1,
        title: 'Login/Password Request',
        content: 'Complete the form to request login credentials.',
        component: () => import('src/components/GeneralViews/RequestPassword')
      }
      this.$store.commit('modal', modalObj)
    }
  },

  mounted() {
    this.$store.dispatch('setUserAuthedData')
    if (this.$store.getters.userAuthedData && this.$store.getters.userAuthedData.eid) {
      this.$router.push({ path: '/app' })
    }
  }
}
</script>

<style lang="scss" scoped>
#login-form {
  position: relative;
  margin-top: 0%;
}

.login-form {
  max-width: 550px;
  margin: 5% auto 0;

  .button-container {
    text-align: center;

    .btn {
      min-width: 180px;
    }
  }
}

.login-options-container {
  text-align: center;
  padding: 5px;
  font-size: 12px;

  a {
    color: #555;
  }
}
</style>
