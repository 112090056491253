
<div>
  <form ref="pdfForm" method="GET" :action="`${$store.getters.urls.backend}download.php`" target="_blank" style="display: none">
    <input ref="file" type="hidden" name="file" value="" />
    <input ref="mode" type="hidden" name="mode" value="" />
  </form>

  <div v-if="displayType === 'link'">
    <span>{{ fileName }}</span>
    <a href="" @click.prevent="downloadPDF" class="label-link ti-download" title="Download PDF"></a>
    <a href="" @click.prevent="previewPDF" class="label-link ti-eye" title="Preview PDF"></a>
  </div>
  <div v-else class="button-group">
    <button class="btn" @click="downloadPDF" :disabled="processing">
      <span>Download PDF</span>
      <span v-if="processing" class="spinner"></span>
    </button>
    <button class="btn btn-info" @click="previewPDF" :disabled="processing">
      <span>Preview PDF</span>
      <span v-if="processing" class="spinner"></span>
    </button>
  </div>
  <waiting-spinner />
</div>
