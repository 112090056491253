<template>
  <div id="chart" class="chart-container"></div>
</template>

<script>
import ApexCharts from 'apexcharts'
import commonFuncs from 'mixins/commonFuncs'

export default {
  name: 'DonutChart',
  mixins: [commonFuncs],
  props: {
    series: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: true
    },
    dataHours: {
      type: Array,
      required: true
    }
  },
  mounted() {
    this.renderChart()
  },
  watch: {
    dataHours: {
      handler(value) {
        if (this.chart) {
          this.chart.destroy()
        }
        this.renderChart()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    renderChart() {
      const _this = this

      const total = this.dataHours.reduce((sum, e) => {
        // Only sum specific categories: Billable, Unbillable, Quoting, Safety
        // const allowedTypes = ['Billable', 'Unbillable', 'Quoting', 'Safety']
        // if (allowedTypes.includes(e.item)) {
        //   return sum + e.qty
        // }
        return sum + e.qty
      }, 0)

      const options = {
        chart: {
          type: 'donut',
          height: 270,
          brush: {
            autoScaleYaxis: true
          }
        },
        series: total ? this.dataHours.map(e => e.qty) : [1],
        labels: total ? this.dataHours.map(e => e.item) : ['No Data'],
        colors: total ? this.dataHours.map(e => e.legendColor) : ['#c4c4c4'],
        legend: {
          show: false
        },
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          },
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        dataLabels: {
          enabled: total ? true : false,
          style: {
            colors: ['#66615b'],
            fontSize: '14px'
          },
          dropShadow: {
            enabled: false
          },
          formatter: function (val, opts) {
            return _this.dataHours[opts.seriesIndex].percentTotal + '%'
          }
        },
        tooltip: {
          enabled: total ? true : false,
          theme: 'light',
          y: {
            formatter: function (val, opts) {
              return _this.dataHours[opts.seriesIndex].percentTotal + '%'
            }
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: 40
            },
            customScale: 0.9,
            donut: {
              size: '70%',
              labels: {
                show: true,
                total: {
                  show: true,
                  formatter: function (val, opts) {
                    return _this.formatNumber(total, true, true) + ' hrs'
                  }
                },
                value: {
                  formatter: function (val, opts) {
                    return val + ' hrs'
                  }
                },
                name: {
                  show: false
                }
              }
            }
          }
        }
      }

      if (this.$el) {
        this.chart = new ApexCharts(this.$el, options)
        this.chart.render()
      }
    }
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.destroy()
    }
  }
}
</script>

<style lang="scss">
.apexcharts-datalabels {
  transform: translateY(6px);
}
</style>
