<template>
  <component :is="selectFieldComponentToRender" v-bind="$attrs" v-on="$listeners" ref="selectField" />
</template>

<script>
import SelectField from 'components/UIComponents/SelectField'
import SelectFieldFull from 'components/UIComponents/SelectFieldFull'

export default {
  data() {
    return {}
  },

  computed: {
    selectFieldComponentToRender() {
      if (this.isMobile) {
        return SelectFieldFull
      } else {
        return SelectField
      }
    }
  },

  methods: {
    selectEl(item) {
      this.$refs.selectField.selectEl(item)
    },
    selectItem(itm, obj) {
      this.$refs.selectField.selectItem(itm, obj)
    }
  }
}
</script>
