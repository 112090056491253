<template>
  <div class="tab-section white">

    <form id="post-job-note-form">

      <ul class="nav nav-tabs">
        <li role="presentation" v-bind:class="[{active: activeTab == 'job'}]"><a href="#" v-on:click="changeTab('job', $event)">Add Job Note</a></li>
        <li role="presentation" v-bind:class="[{active: activeTab == 'wo'}]"><a href="#" v-on:click="changeTab('wo', $event)">Add Work Order Note</a></li>
      </ul>

      <div :class="['card', {'Main':activeTab == 'job'}]">
        <div class="content">

          <alert/>

          <div v-if="activeTab === 'job'">
            <div v-if="jobSelected" class="job-search-details clearfix">
              <div>
                <label>Customer Name:</label>
                <h4><strong><span v-html="job.Customer_Name"></span></strong></h4>
                <label>Job Description:</label>
                <p><span v-html="job.Job_Description"></span></p>
              </div>
              <button class="btn pull-right" v-on:click="showJobNotes($event)">Show Notes</button>
            </div>
          </div>
          <div v-else-if="activeTab === 'wo' " class="form-group">
            <!--  customer_name, Summary_Description -->
            <div v-if="WOSelected && workOrder.customer_name" class="job-search-details clearfix">
              <div>
                <label>Customer Name:</label>
                <h4><strong><span v-html="workOrder.customer_name"></span></strong></h4>
                <label>Job Description:</label>
                <p><span v-html="workOrder.Summary_Description"></span></p>
              </div>
              <button class="btn pull-right" v-on:click="showWONotes($event)">Show Notes</button>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div v-if="activeTab === 'job'">
                <div class="form-group">
                  <label for="job_number">Job Number<span class="required-star">*</span></label>
                  <div class="job-number-input-group">
                    <div class="job-number-input">
                      <select-field
                        :options="jobInfo && jobInfo.data ? jobInfo.data : []"
                        :option-display-keys="['Job_Number', 'Customer_Name', 'Job_Description']"
                        :option-val="'Job_Number'"
                        v-model="formVals.job_number"
                        :placeholder-text="'Select Jobs'"
                        :preselect-single-option="false"
                        item-name="Jobs"
                      />
                    </div>
                    <button class="btn btn-small job-search-button" v-on:click="showJobInfo($event)">Search</button>
                  </div>
                </div>
              </div>
              <div v-else-if="activeTab === 'wo' " class="form-group">
                <div class="form-group">
                  <label for="job_number">Work Order Number<span class="required-star">*</span></label>
                  <div class="job-number-input-group">
                    <div class="job-number-input">
                      <select-field
                        :options="WOInfo && WOInfo.data ? WOInfo.data : []"
                        :option-display-keys="['WO_Number', 'customer_name', 'Summary_Description']"
                        :option-val="'WO_Number'"
                        v-model="formVals.work_order_number"
                        :placeholder-text="'Select WO'"
                        :preselect-single-option="false"
                        item-name="WO"
                      />
                    </div>
                    <button class="btn btn-small job-search-button" v-on:click="showWoInfo($event)">Search</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="note_text">Hours<span class="required-star">*</span></label>
                <hours-picker v-model="formVals.hours"></hours-picker>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label>Date<span class="required-star">*</span></label>
                <date-picker v-model="formVals.date" :preFilled="true"></date-picker>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="inline-radio-container">
                <div><label>Note Category<span class="required-star">*</span></label></div>
                <div class="inline-radios">
                  <label for="radio-log" v-bind:class="['radio-inline', {'selected': formVals.category == 'log'}]">
                    <input type="radio" v-model="formVals.category" value="log" id="radio-log">Daily Log
                  </label>
                  <label for="radio-extras" v-bind:class="['radio-inline', {'selected': formVals.category == 'extras'}]">
                    <input type="radio" v-model="formVals.category" value="extras" id="radio-extras">Extras Log
                  </label>
                  <label for="radio-delays" v-bind:class="['radio-inline', {'selected': formVals.category == 'delays'}]">
                    <input type="radio" v-model="formVals.category" value="delays" id="radio-delays">Internal Notes
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- <div v-if="noteInstructions" class="note-instructions" v-html="noteInstructions"></div> -->

          <div class="form-group">
            <label for="note_text">Note Text<span class="required-star">*</span></label>
            <textarea class="form-control" id="note_text" name="note_text" rows="6" v-model="formVals.note_text" v-bind:placeholder="noteInstructions"></textarea>
          </div>

          <br>
          <button type="submit" class="btn btn-primary" v-on:click="postNote($event)">Add Note</button>
          <br>
          <br>
        </div>
      </div> <!-- end main card -->

      <waiting-spinner/>

    </form>

    <div class="about-notes">
      <p>Describe in good detail the work completed during the selected date. Recommended time to write a good journal entry 5-10 minutes. Proper grammar, spelling, and overall
        professionalism do matter, this log will be used as supporting documentation for billing so the customer will see what you write.</p>
    </div>

  </div>
</template>
<script>

import store from 'store';
import appFuncs from 'appFuncs';
import navTabs from 'mixins/navTabs';
import DatePicker from 'components/UIComponents/DatePicker';
import HoursPicker from 'components/UIComponents/HoursPicker';
import SelectField from 'components/UIComponents/SelectField';
import Alert from 'components/UIComponents/Alert';
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {
      urls: store.getters.urls,
      activeTab: 'job',
      jobNumbers: {
        message: '',
        success: '',
        data: ''
      },
      woNumbers: {
        message: '',
        success: '',
        data: '',
      },
      formVals: {
        job_number: '',
        work_order_number: '',
        hours: null,
        employee: '',
        note_text: '',
        category: 'log',
        date: null,
      },
      job: {},
      workOrder: {},
      jobInfo: null,
      jobSelected: null,
      WOInfo: null,
      WOSelected: null,
    }
  },

  mixins: [
    navTabs,
  ],

  components: {
    DatePicker,
    HoursPicker,
    SelectField,
    Alert,
    WaitingSpinner
  },

  computed: {
    userAuthedData() {
      return store.getters.userAuthedData;
    },

    noteInstructions() {
      switch (this.formVals.category) {
        case'log':
          return 'This is where we input the days events for what you do during commissioning stages of jobs or for T&M jobs in detail. This is used to justify the billing for the commissioning stage of projects or T&M work that is billed by the hour.';
          break;
        case 'extras':
          return 'This is where we input "Extras" relating to issues beyond our control (for quoted jobs)';
          break;
        case 'delays':
          return 'This is where we input "Delays" caused by Sheridan (for quoted jobs)';
          break;
      }
    }

  },

  methods: {

    getJobs() {
      var jobInfo = this.jobInfo;
      if (jobInfo && jobInfo.data) {
        // reduce to array of only Job numbers
        var jobNumbers = [];
        for (var i = jobInfo.data.length - 1; i >= 0; i--) {
          jobNumbers.push(jobInfo.data[i]['Job_Number']);
        }
        this.jobNumbers.status = 'success';
        this.jobNumbers.data = jobNumbers;
      }
    },

    getWOs() {
      var WOInfo = this.WOInfo;
      if (WOInfo && WOInfo.data) {
        // reduce to array of only WO numbers
        var woNumbers = [];
        for (var i = WOInfo.data.length - 1; i >= 0; i--) {
          woNumbers.push(WOInfo.data[i]['WO_Number']);
        }
        this.woNumbers.status = 'success';
        this.woNumbers.data = woNumbers;
      }
    },

    // Jobs and WOs
    postNote(e) {
      e.preventDefault();

      var self = this;
      var form = $('#post-job-note-form');

      var data = {};

      if (self.activeTab === 'job') {
        data.job_number = self.formVals.job_number;
        data.note_type = 'job';
      } else if (self.activeTab === 'wo') {
        data.job_number = self.formVals.work_order_number;
        data.note_type = 'wo';
      }

      // temp validation helper - make any non time value null for current server validation
      if (!parseInt(this.formVals.hours.replace('.', ''))) {
        this.formVals.hours = null;
      }

      data.action = 'post_job_notes';
      data.employee = self.formVals.employee;
      data.note_text = self.formVals.note_text;
      data.category = self.formVals.category;
      data.hours = self.formVals.hours;
      data.date = self.formVals.date;

      store.commit('waiting', {status: 1, message: 'Posting note'});

      appFuncs.ajax_request(store.getters.sherAuth, data, function (result) {
        store.commit('waiting', {status: 0});
        if (result.status === 'success') {
          // clear form vals
          self.formVals.hours = '';
          self.formVals.note_text = '';
          self.formVals.category = '';
          self.formVals.date = null;

          var alertVals = {
            show: 1,
            message: result.message,
            state: 'success',
            dismiss: 'auto'
          };
          store.commit('alerts', alertVals);
        } else {
          var alertVals = {
            show: 1,
            message: result.message,
            state: 'danger',
            dismiss: 'auto'
          };
          store.commit('alerts', alertVals);
        }
      });
    },

    changeTab(tab, $event) {
      $event.preventDefault();
      this.activeTab = tab;
      this.checkFetchJobWOInfo();
      store.commit('alerts', {});
    },

    checkFetchJobWOInfo() {
      if (this.activeTab === 'job') {
        if (!this.jobNumbers.data) {
          appFuncs.getJobInfo((result) => {
            this.jobInfo = result;
          });
        }
      } else if (this.activeTab === 'wo') {
        if (!this.woNumbers.data) {
          appFuncs.getWOInfo((result) => {
            this.WOInfo = result;
          });
        }
      }
    },

    showJobInfo(e) {
      e.preventDefault();
      this.$bus.$emit('modalOpen', {
        title: 'Search for a Job Number',
        content: 'Click a job number to select.',
        classes: 'gray-bg',
        name: 'get-job-info',
        size: 'modal-lg',
        component: 'get-job-info',
        componentData: {
          jobInfo: this.jobInfo.data
        }
      });

    },

    showWoInfo(e) {
      e.preventDefault();
      this.$bus.$emit('modalOpen', {
        title: 'Search for a Work Order',
        content: 'Click a WO number to select.',
        classes: 'gray-bg',
        name: 'get-wo-info',
        size: 'modal-lg',
        component: 'get-wo-info',
        componentData: {
          WOInfo: this.WOInfo.data
        }
      });
    },

    showJobNumberDetails() {
      if (this.jobSelected) {
        var jobInfo = this.jobInfo;
        if (jobInfo && jobInfo.data) {
          var job = jobInfo.data.filter((item) => {
            return item.Job_Number === this.jobSelected;
          });
          if (job) {
            this.job = job[0];
          }
        }
      }
    },

    showWONumberDetails() {
      if (this.WOSelected) {
        var WOInfo = this.WOInfo;
        if (WOInfo && WOInfo.data) {
          var workOrder = WOInfo.data.filter((item) => {
            return item.WO_Number === this.WOSelected;
          });
          if (workOrder.length) {
            this.workOrder = workOrder[0];
          }
        }
      }
    },

    showJobNotes(e) {
      e.preventDefault();
      this.$bus.$emit('modalOpen', {
        title: 'Job Note Log',
        classes: 'gray-bg',
        name: 'job-note-log',
        size: 'modal-xl',
        component: () => import('src/components/Dashboard/Jobs/ShowJobNotes'),
        componentData: {
          job: this.job,
          noteType: this.activeTab,
        }
      });

    },

    showWONotes(e) {
      e.preventDefault();
      this.$bus.$emit('modalOpen', {
        title: 'Work Order Note Log',
        classes: 'gray-bg',
        name: 'job-note-log',
        size: 'modal-xl',
        component: () => import('src/components/Dashboard/Jobs/ShowJobNotes'),
        componentData: {
          job: this.workOrder,
          noteType: this.activeTab,
        }
      });
    }

  }, // end of methods


  mounted() {
    this.formVals.employee = this.userAuthedData.fname + ' ' + this.userAuthedData.lname;
    this.checkFetchJobWOInfo();
    this.showJobNumberDetails();
    this.showWONumberDetails();

    this.$bus.$on('selectJobNumber', (number) => {
      this.formVals.job_number = number;
    });

    this.$bus.$on('selectWONumber', (number) => {
      this.formVals.work_order_number = number;
    });
  },

  watch: {

    // add logged in employee to field data
    userAuthedData(uad) {
      this.formVals.employee = uad.fname + ' ' + uad.lname;
    },
    // trigger job/WO form select update on form change
    'formVals.job_number': function (val) {
      this.jobSelected = val;
      this.showJobNumberDetails();
    },
    'formVals.work_order_number': function (val) {
      this.WOSelected = val;
      this.showWONumberDetails();
    },
    jobInfo() {
      this.getJobs();
      this.showJobNumberDetails();
    },
    WOInfo() {
      this.getWOs();
      this.showWONumberDetails();
    },
  }
}


</script>
<style lang="scss">

.note-form-inner {
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #eae9e5;
  position: relative;
}

.note-form-inner.job_number {
  border-top-left-radius: 0;
}


.job-search-details {
  border-left: 5px solid #555;
  padding: 20px 150px 20px 20px;
  margin: 0 0 25px;
  position: relative;
  background: #f5f5f5;
  border-radius: 0 5px 5px 0;

  > * {
    display: inline-block;
  }

  h4 {
    margin: 0 0 20px;
    font-size: 18px;
    font-weight: normal;

    strong {
      font-weight: bolder;
    }
  }

  p {
    margin: 0;
    font-size: 16px;
    font-weight: bolder;
  }

  button {
    margin-top: 2px;
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -20px;
  }
}

.job-number-input-group {
  position: relative;

  .job-number-input {
    margin-right: 90px;
  }

  .job-search-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
    height: 40px;
    font-size: 13px;
    padding: 0;
    line-height: 36px;

    &:hover {
      opacity: 1;
    }
  }
}


.about-notes {
  max-width: 1200px;
  margin: 30px auto 30px;
  text-align: center;

  p {
    font-size: 15px;
    //font-weight:bolder;
  }
}


@media(max-width: 600px) {
  .job-search-details {

    > * {
      display: block;
    }

    button {
      float: none !important;
      margin-top: 10px;
    }
  }
}


</style>
