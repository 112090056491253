import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes/routes';

const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue;
vm.use(VueRouter);
// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  // mode: 'history',
});

export default router
