<template>
  <div :class="['sh-widget', 'wide-xl', 'card', { 'max-min-height': tableSettings.tableData.length > 1 }]">
    <div class="content">
      <h3>Time Card Approvals</h3>
      <div class="content-inner show-scrollbars">
        <bh-table :table-settings="tableSettings" @edit-item-obj="selectPmForFilter" />
        <waiting-spinner :contained="true" />
      </div>
    </div>
  </div>
</template>

<script>
import store from 'store'
import appFuncs from 'appFuncs'
import { mapGetters } from 'vuex'
import BhTable from 'components/UIComponents/BhTable'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {
      tableData: []
    }
  },

  mixins: [],

  components: {
    BhTable,
    WaitingSpinner
  },

  computed: {
    ...mapGetters(['employees', 'userAuthedData']),

    isAdmin() {
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPM() {
      return this.userAuthedData.user_role.indexOf('pm') !== -1
    },

    isPayrollUser() {
      const employee = this.employees.find(itm => {
        return parseInt(itm.Employee_Code) == parseInt(this.userAuthedData.eid)
      })
      return employee && employee.payroll_admin_user === 'yes'
    },

    filtered() {
      let elements = this.tableData
      for (const i in elements) {
        let employee = this.employees.find(itm => {
          return itm.Employee_Code == elements[i].pm_code
        })
        elements[i].employee_name = employee ? employee.Employee_Name : ''
      }

      if (!this.isPayrollUser) {
        elements = elements.filter(itm => {
          return itm.pm_code == this.userAuthedData.eid
        })
      }
      return elements
    },

    tableSettings() {
      return {
        tableData: this.filtered,
        fields: {
          employee_name: {
            name: 'Name',
            class: 'narrower'
          },
          entries: {
            name: 'Count',
            class: 'narrower'
          }
        },
        hideAllSearch: true,
        hideTip: true,
        tableLayout: 'auto'
      }
    }
  },

  methods: {
    getEntries() {
      return new Promise((resolve, reject) => {
        let action = 'get_approvals_on_base_of_pms'

        let data = {
          action
        }

        this.tableData = []

        store.commit('waiting', { status: 1, message: 'Getting Entries' })
        appFuncs.ajax_request(store.getters.sherAuth, data, result => {
          if (result.status === 'success') {
            for (const element of result.data) {
              this.tableData.push(element)
            }
            resolve()
          } else {
            reject()
            this.$snack.open('Problem getting entries', 'error')
          }
          store.commit('waiting', { status: 0 })
        })
      })
    },
    selectPmForFilter(obj) {
      this.$emit('filter-pm', obj.pm_code)
    }
  },

  mounted() {
    this.$bus.$on('JobWOPOEdit', this.listenPOEdit)
    this.getEntries()
  },

  beforeDestroy() {
    this.$bus.$off('JobWOPOEdit', this.listenPOEdit)
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.sh-widget {
  @media (min-width: 900px) {
    // &.max-min-height {
    height: 370px;
    margin-bottom: 40px;
    // }
  }

  .content {
    width: 700px;
    max-width: 100%;
    padding: 10px 20px 0 !important;
  }
}

h3 {
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;

  i {
    color: $primary-color;
    float: right;
  }
}

.content-inner {
  overflow: scroll !important;
  max-height: 310px;
}
</style>

<style lang="scss">
@import 'src/assets/sass/paper/_variables.scss';

.my-jobs-widget .no-po .po_number {
  // background: $input-red;
}
</style>
