<template>
  <div :class="{ 'has-clear': hasClear }">
    <v-date-picker
      :mode="mode"
      :input-props="{ class: inputClasses, readonly: 'true', disabled: disabled }"
      :value="selectedDate"
      :formats="computedFormats"
      :themeStyles="datepickerStyles"
      :tint-color="'#b0cdeb'"
      :attributes="attributes"
      :select-attribute="selAttrs"
      :pane-width="320"
      :is-expanded="true"
      :popover-visibility="isSelectable"
      :is-inline="isInline"
      :class="['custom-date-picker']"
      :min-date="minDateFormatted"
      :max-date="maxDateFormatted"
      @input="dateSelected"
    />
    <span :class="'input-clear'" v-if="hasClear" @click="setDate(1)">
      <i class="ti ti-close"></i>
    </span>
  </div>
</template>

<script>
import moment from 'moment-timezone'

moment.tz.setDefault('America/Toronto')

import dateFormat from 'dateformat'

export default {
  props: {
    value: [String, Date, Array, Number],
    preFilled: {
      type: Boolean,
      default: true
    },
    format: {
      type: String,
      default: 'string',
      validator: value => ['string', 'date', 'unix'].includes(value)
    },
    dateFormat: {
      type: String,
      default: ''
    },
    minDate: [String, Date, Number],
    maxDate: [String, Date, Number],
    disabled: {
      type: Boolean,
      default: false
    },
    isInline: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Array,
      default: () => []
    },
    hasClear: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'single'
    },
    attributes: {
      type: Array,
      default: () => [
        {
          key: 'today',
          highlight: {
            borderColor: '#e7e7e7',
            borderWidth: '1'
          },
          dates: moment().utcOffset(-5, true).toDate()
        }
      ]
    },
    selAttrs: {
      type: Object,
      default: () => ({
        contentStyle: {
          color: '#555',
          highlight: '#dae0fe'
        }
      })
    }
  },

  data() {
    return {
      selectedDate: null,
      datepickerStyles: {
        dayContent: {
          fontSize: '1.6rem',
          height: '38px'
        },
        dayCell: {
          height: '38px'
        },
        headerTitle: {
          fontSize: '1.6rem'
        }
      },
      formats: { input: ['MMM DD, YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY'] }
    }
  },

  computed: {
    computedFormats(){
      if (this.dateFormat != '') {
        this.formats.input[0] = this.dateFormat
      }
      return this.formats;
    },
    today() {
      return moment().utcOffset(-5, true).toDate()
    },

    isSelectable() {
      return this.disabled ? '' : 'focus'
    },

    inputClasses() {
      const classes = ['form-control']
      if (this.isSelectable) {
        classes.push('enable', 'fake-readonly')
      }
      return classes.concat(this.classes)
    },

    minDateFormatted() {
      return this.formatDateProp(this.minDate)
    },

    maxDateFormatted() {
      return this.formatDateProp(this.maxDate)
    }
  },

  methods: {
    dateSelected(val) {
      this.selectedDate = val
      const formattedValue = this.formatOutputDate(val)
      this.$emit('input', formattedValue)
      this.$emit('selectDate', formattedValue)
    },

    formatDateProp(dateVal) {
      if (!dateVal) return null
      return moment(dateVal).utcOffset(-5, true).toDate()
    },

    formatOutputDate(val) {
      if (Array.isArray(val)) {
        return val.map(date => this.formatSingleDate(date))
      }
      return this.formatSingleDate(val)
    },

    formatSingleDate(date) {
      if (!date) return this.format === 'unix' ? null : ''
      switch (this.format) {
        case 'string':
          return dateFormat(date, 'yyyy-mm-dd')
        case 'date':
          return date
        case 'unix':
          return moment(date).unix()
      }
    },

    setDate(clear) {
      if (Array.isArray(this.value)) {
        if (!this.value.length && this.preFilled) {
          this.selectedDate = [moment().utcOffset(-5, true).toDate()]
        } else if (clear || !this.value.length) {
          this.selectedDate = []
        } else {
          this.selectedDate = this.value.map(date => this.parseInputDate(date))
        }
      } else {
        if (!this.value && this.preFilled) {
          this.selectedDate = moment().utcOffset(-5, true).toDate()
          this.dateSelected(this.selectedDate)
        } else if (clear || !this.value) {
          this.selectedDate = null
          this.$emit('input', this.format === 'unix' ? null : '')
        } else {
          this.selectedDate = this.parseInputDate(this.value)
        }
      }
    },

    parseInputDate(date) {
      if (this.format === 'unix') {
        return moment.unix(date).toDate()
      }
      return moment(date).toDate()
    }
  },

  mounted() {
    this.setDate()
  },

  watch: {
    value() {
      this.setDate()
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/sass/paper/_variables.scss';

.custom-date-picker {
  // > div {
  //   width: 280px !important;
  //   min-width: 280px !important;
  // }
}

.has-clear {
  font-size: 0;

  .custom-date-picker {
    width: calc(100% - 30px);
    display: inline-block;
    vertical-align: middle;

    .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .input-clear {
    display: inline-block;
    text-align: center;
    width: 30px;
    height: 40px;
    margin: 0;
    vertical-align: middle;
    font-size: 14px;
    line-height: 40px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: $smoke-bg;
    cursor: pointer;
  }
}
</style>
