<template>
  <div class="header-notes-container">
    <div class="header-buttons">
      <div class="header-edit">
        <div class="header-input">
          <input type="text" v-model="headerNameEdit" class="header-field" :readonly="readonly || readonlyHeader" @input="resizeAllHeaderFields" />
        </div>
        <span class="control-buttons" v-if="!readonly && !readonlyHeader && sortable">
          <i class="ti-angle-down is-link" @click="$emit('moveDown')" v-if="headerNameEdit === headerName" />
          <i class="ti-angle-up is-link" @click="$emit('moveUp')" v-if="headerNameEdit === headerName" />
        </span>
        <span class="control-buttons action-buttons">
          <i class="ti-check edit-save" @click="$emit('saveHeaderName', headerNameEdit)" v-if="headerNameEdit !== headerName" />
          <i class="ti-na edit-cancel" @click="headerNameEdit = headerName" v-if="headerNameEdit !== headerName" />
        </span>
      </div>

      <button v-if="showButtons && !readonly && !readonlyHeader" class="btn btn-no-radius btn-sm btn-pip btn-blue" @click="$emit('addCustomItem')">Custom Item</button>

      <button v-if="showButtons && !readonly && !readonlyHeader" class="btn btn-no-radius btn-sm btn-pip btn-blue" @click="$emit('addFileItem')">Insert from File</button>

      <button v-if="!readonly && !readonlyHeader" class="btn btn-no-radius btn-sm btn-pip btn-blue" @click="$emit('deleteHeader')">Delete Header</button>

      <!-- <button v-if="!readonly && !readonlyHeader && !showTable" class="btn btn-no-radius btn-sm btn-pip btn-blue" @click="addTable = true">Add Table</button> -->
    </div>
    <div class="note-template-items" :data-h="headerId" ref="noteTemplateItems">
      <div class="note-template-item-section" v-for="(s, si) in noteSystems" :key="si">
        <div class="note-template-item" v-if="s.notes && s.notes.length">
          <div class="note-desc">
            <span v-if="notesHaveSystems(s)">
              <strong>{{ s.number }}: {{ s.name }}</strong>
            </span>
            <span v-else>
              <strong>Description</strong>
            </span>
          </div>
          <div class="note-control-buttons" />
        </div>

        <div v-for="(n, i) in s.notes" :key="i" class="note-template-item">
          <div class="note-add">
            {{ n.sl }}
          </div>
          <div class="note-desc">
            <!-- <input type="text" v-model="n.t" @focus="n.editing = true"> -->
            <RichTextInline
              :content="n.t"
              @update:content="n.t = $event"
              @setEditing="setEditing(n)"
              @save="doneRichTextEditing(n)"
              :readonly="readonly"
              :data-n="n.sl"
              :data-s="n.si"
              @keyup:enter="addItemBelow($event, s, n)"
            />
          </div>
          <div class="note-control-buttons">
            <span v-if="!readonly" class="control-buttons">
              <span v-if="!hasAnyEdits()">
                <i class="ti-angle-down is-link" @click="$emit('moveNoteDown', { si: n.si, sl: n.sl, index: i })" />
                <i class="ti-angle-up is-link" @click="$emit('moveNoteUp', { si: n.si, sl: n.sl, index: i })" />
              </span>
              <i class="ti-close edit-delete" @click="deleteNote(n)" />
            </span>
          </div>
        </div>
      </div>
      <table-editor v-if="showTable" ref="tableEditor" style="margin-bottom: 10px" :table-content="tableContent" />
      <div v-else-if="tableContent" class="table-content-read-only">
        <div v-html="tableContent" />
      </div>
      <button v-if="showTable && !readonlyHeader" class="btn btn-no-radius btn-sm btn-pip btn-blue" @click="saveTableNote()">Save</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import TableEditor from 'components/UIComponents/TableEditor'
import RichTextInline from 'components/UIComponents/RichTextInline.vue'

export default {
  name: 'QuoteWriteUpNoteHeader',
  data() {
    return {
      headerNameEdit: '',
      // noteSystems: []
      lettersArray: [],
      editing: [],
      richTextEditing: [],
      editingContent: '',
      addTable: false
    }
  },

  props: {
    headerId: {
      type: [String, Number],
      required: true
    },
    headerName: {
      type: String,
      default: ''
    },
    notes: {
      type: Array,
      required: true
    },
    tableContent: {
      type: String,
      default: ''
    },
    showButtons: {
      type: Boolean,
      required: false
    },
    actionHtml: {
      type: String,
      default: ''
    },
    systems: {
      type: Array,
      default: function () {
        return []
      }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    readonlyHeader: {
      type: Boolean,
      default: false
    },
    sortable: {
      type: Boolean,
      default: true
    }
  },

  components: {
    TableEditor,
    RichTextInline
  },

  computed: {
    noteSystems() {
      const systems = Array.isArray(this.systems) ? this.systems : []
      const notes = Array.isArray(this.notes) ? this.notes : []

      // add note list under blank system
      let ns = [{ notes: [] }]

      // add systems to note list
      for (let si = 0; si < systems.length; si++) {
        ns.push({
          si: systems[si].id,
          name: systems[si].name,
          number: systems[si].number,
          notes: []
        })
      }

      // for each note, move to note list within system list
      for (let ni = 0; ni < notes.length; ni++) {
        const sysId = notes[ni].si
        if (!sysId) {
          ns[0].notes.push(notes[ni])
        } else {
          const nsi = ns.findIndex(itm => itm.si == sysId)
          if (nsi !== -1) {
            ns[nsi].notes.push(notes[ni])
          }
        }
      }
      return ns
    },

    showTable() {
      if ((this.addTable || this.tableContent) && !this.readonly) {
        return true
      }
      return false
    },

    hasTableContent() {
      if (typeof this.tableContent === 'string' && this.tableContent.length > 0) {
        return true
      }
      return false
    }
  },

  methods: {
    hasAnyEdits() {
      const hasEdits = this.richTextEditing.length > 0 || !this.isObjEmpty(this.richTextEditing)
      return hasEdits
    },

    hasAnyEditsBeforeUnload() {
      const hasEdits = this.editing.length > 0 || !this.isObjEmpty(this.editing)
      return hasEdits
    },

    setEditing(n) {
      let identifier = n.si + n.sl + n.sn
      let existingIndex = this.editing.indexOf(identifier)
      if (existingIndex === -1) {
        this.editing.push(identifier)
        this.editingContent = n.t
      }

      let existingIndex2 = this.richTextEditing.indexOf(identifier)
      if (existingIndex2 === -1) {
        this.richTextEditing.push(identifier)
      }
    },

    isEditing(n) {
      let identifier = n.si + n.sl + n.sn
      let existingIndex = this.editing.indexOf(identifier)
      if (existingIndex !== -1) {
        return true
      }
      return false
    },

    doneEditing(n) {
      let identifier = n.si + n.sl + n.sn
      let existingIndex = this.editing.indexOf(identifier)
      if (existingIndex !== -1) {
        this.editing.splice(existingIndex, 1)
      }
    },

    doneRichTextEditing(n) {
      let identifier = n.si + n.sl + n.sn
      if (!/^<\s*\w+[^>]*>/.test(this.editingContent)) {
        this.editingContent = '<p>' + this.editingContent + '</p>'
      }

      if (this.editingContent === n.t) {
        this.doneEditing(n)
      }

      let existingIndex = this.richTextEditing.indexOf(identifier)
      if (existingIndex !== -1) {
        this.richTextEditing.splice(existingIndex, 1)
      }
    },

    deleteNote(n) {
      this.$bus.$emit('saveRichTextContent')
      this.$emit('deleteNote', n)
    },

    notesHaveSystems(s) {
      s = Array.isArray(s.notes) ? s.notes : []
      let hasSystems = false
      for (let si = 0; si < s.length; si++) {
        if (s[si].si) {
          hasSystems = true
          break
        }
      }
      return hasSystems
    },

    saveAll() {
      for (let i = 0; i < this.noteSystems.length; i++) {
        for (let nsi = 0; nsi < this.noteSystems[i].notes.length; nsi++) {
          const n = this.noteSystems[i].notes[nsi]
          let identifier = n.si + n.sl + n.sn
          if (this.editing.indexOf(identifier) !== -1) {
            this.saveNote(n)
          }
        }
      }
      this.saveTableNote(false)
    },

    saveNote(n, i) {
      this.$emit('updateNote', n)
      this.$forceUpdate()
      this.doneEditing(n)
    },

    cancelEdit(n) {
      this.$emit('cancelNoteEdit', n)
      this.$forceUpdate()
      this.doneEditing(n)
    },

    adjustAllTextAreas() {
      this.$nextTick(() => {
        const inputs = this.$refs.noteDescInput
        if (!inputs) return

        const processInput = input => {
          input.style.height = 'auto'
          if (input.value && input.scrollHeight) {
            input.style.height = `${input.scrollHeight}px`
            input.style.overflowY = 'hidden'
          } else {
            input.style.height = '40px'
            input.style.overflowY = 'hidden'
          }
        }

        if (Array.isArray(inputs)) {
          inputs.forEach(processInput)
        } else {
          processInput(inputs)
        }
      })
    },

    resizeAllHeaderFields() {
      setTimeout(() => {
        const inputs = document.querySelectorAll('.header-field')
        for (let i = 0; i < inputs.length; i++) {
          const exactWidth = this.getWidthOfText(inputs[i].value, getComputedStyle(inputs[i]).font)
          inputs[i].style.width = exactWidth + +20 + 'px'
        }
      }, 30)
    },

    getWidthOfText(text, font) {
      const element = document.createElement('div')
      document.body.appendChild(element)
      element.style.position = 'absolute'
      element.style.left = '-9999px'
      element.style.top = '-9999px'
      element.style.font = font
      element.innerText = text

      const width = element.clientWidth
      document.body.removeChild(element)
      return width
    },

    addItemBelow(e, s, n) {
      let notes = Array.isArray(s.notes) ? s.notes : []
      if (e.altKey) {
        this.$emit('addCustomItemBelow', { s, n })
      }
    },

    saveTableNote(doSave = true) {
      const tableEditor = this.$refs.tableEditor
      if (tableEditor) {
        const content = this.$refs.tableEditor.getContent()
        this.$emit('saveTableNote', { content, doSave })
      }
    }
  },

  mounted() {
    this.resizeAllHeaderFields()
    this.adjustAllTextAreas()

    this.$bus.$on('QuoteWriteUpHeaderChange', this.resizeAllHeaderFields)
    this.$bus.$on('QuoteEditChangeTab', this.adjustAllTextAreas)
    this.$bus.$on('saveAllQuoteWriteUpNotes', this.saveAll)

    this.$bus.$on('updatedQuoteMaterialList', tab => {
      this.editing = []
    })
  },

  beforeDestroy() {
    this.$bus.$off('QuoteWriteUpHeaderChange', this.resizeAllHeaderFields)
    this.$bus.$off('QuoteEditChangeTab', this.adjustAllTextAreas)
    this.$bus.$off('saveAllQuoteWriteUpNotes', this.saveAll)
  },

  watch: {
    notes() {
      this.resizeAllHeaderFields()
      this.adjustAllTextAreas()
    },

    tableContent() {
      //this.setTableNote();
    },

    headerName: {
      handler(newVal) {
        this.headerNameEdit = newVal
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.header-notes-container {
  padding: 0; //px 0 10px 15px;
  margin-bottom: 10px;
  transition: 0.2s linear box-shadow;

  &:has(.editor-wrapper:focus-within) {
    // box-shadow: 3px 3px 12px 4px rgba(204, 197, 185, 0.5);
    // border-radius: 5px;
    // padding: 15px;
  }
}

.header-edit {
  .header-input {
    display: inline-block;
    max-width: calc(100% - 70px);
  }

  .header-field,
  .header-field[readonly] {
    font-size: 1.3em;
    font-weight: bold;
    text-decoration: underline;
    border: none;
    // width: calc(100% - 125px);
    background: none;
    color: $font-color !important;
    margin-bottom: 10px;
    display: inline-block;
    width: 0;
    max-width: 100%;
  }

  .control-buttons {
    text-align: right;
    display: inline-block;
    padding-left: 20px;

    .ti-angle-down,
    .ti-angle-up {
      padding: 0 3px;
    }

    &.action-buttons {
      float: right;
    }
  }
}

.header-buttons {
  max-width: 1400px;

  h4 {
    text-decoration: underline;
  }
}

.note-template-items {
  font-size: 0;
  color: $text-color;
  margin-bottom: 20px;

  .note-template-item-section {
    margin-bottom: 20px;
  }

  .note-template-item {
    font-size: 14px;
    // border: 1px solid;
    border-bottom: 0;
    padding: 2px 0;

    &:last-child {
      // border-bottom: 1px solid;
    }

    > div {
      display: inline-block;
      margin: 3px;
      vertical-align: top;
    }

    .note-add {
      width: 15px;
      vertical-align: middle;
      text-transform: uppercase;
      padding: 0px 2px;

      i {
        font-size: 16px;
      }
    }

    .note-desc {
      width: calc(100% - 185px);
      // border-left: 1px solid;
      input,
      input[readonly],
      textarea,
      textarea[readonly] {
        width: 100%;
        // background: none!important;
        // border: none;
        // padding: 5px;
        // height: 16px;
        font-size: 14px;
        color: $text-color !important;

        &:focus,
        &:active {
          outline: none;
        }
      }

      ::placeholder {
        //color: #ccc;
        font-style: italic;
      }
    }

    .note-control-buttons {
      width: 95px;
      padding: 0px 0;

      .control-buttons {
        float: right;

        i {
          width: 20px;
          height: 20px;
          font-size: 10px;
          line-height: 20px;
        }

        .ti-angle-down,
        .ti-angle-up {
          padding: 0 3px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.table-content-read-only {
  font-size: 14px;
  width: calc(100% - 185px);
  max-width: 100%;
  margin-left: 20px !important;

  table {
    width: 100%;
    border-collapse: collapse;
    min-width: 500px;
    table-layout: auto;

    tr {
      td {
        padding: 5px;
        border: 1px solid #ccc;
        text-align: left;
      }
    }

    tr:nth-child(even) {
      background-color: #f1f1f1;
    }
  }
}
</style>
