
<div id="aging-page">
  <div class="row">
    <div class="col col-sm-12 col-md-5">
      <div class="sh-widget card wide-medium">
        <div class="content">
          <div class="top-section">
            <div class="">
              <h4>Accounts Receivables</h4>
            </div>
          </div>
          <div class="table-container show-scrollbar">
            <table>
              <thead>
                <tr>
                  <th><label>Dates</label></th>
                  <th><label>% of AR</label></th>
                  <th><label>$ of AR</label></th>
                </tr>
              </thead>
              <tr>
                <td>Current</td>
                <td>{{ formatNumber(arCustomerTotals.currentp) }}%</td>
                <td>{{ formatNumber(arCustomerTotals.current, 1) }}</td>
              </tr>
              <tr>
                <td>1-30 days</td>
                <td>{{ formatNumber(arCustomerTotals.days30p) }}%</td>
                <td>{{ formatNumber(arCustomerTotals.days30, 1) }}</td>
              </tr>
              <tr>
                <td>31-60 days</td>
                <td>{{ formatNumber(arCustomerTotals.days60p) }}%</td>
                <td>{{ formatNumber(arCustomerTotals.days60, 1) }}</td>
              </tr>
              <tr>
                <td>61-90 days</td>
                <td>{{ formatNumber(arCustomerTotals.days90p) }}%</td>
                <td>{{ formatNumber(arCustomerTotals.days90, 1) }}</td>
              </tr>
              <tr>
                <td>>90 days</td>
                <td>{{ formatNumber(arCustomerTotals.daysOver90p) }}%</td>
                <td>{{ formatNumber(arCustomerTotals.daysOver90, 1) }}</td>
              </tr>
              <tr>
                <td>Retention</td>
                <td>{{ formatNumber(arCustomerTotals.retentionp) }}%</td>
                <td>{{ formatNumber(arCustomerTotals.retention, 1) }}</td>
              </tr>
              <tr></tr>
              <tr>
                <td>Total:</td>
                <td>100%</td>
                <td>{{ formatNumber(arCustomerTotals.totalOwing, true) }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="sh-widget card wide-medium">
        <div class="content">
          <div class="top-section">
            <div class="">
              <h4>Accounts Payable</h4>
            </div>
          </div>
          <div class="table-container show-scrollbar">
            <table>
              <thead>
                <tr>
                  <th><label>Dates</label></th>
                  <th><label>% of AP</label></th>
                  <th><label>$ of AP</label></th>
                </tr>
              </thead>
              <tr>
                <td>Unapproved</td>
                <td>{{ formatNumber(apCustomerTotals.unapprovedp) }}%</td>
                <td>{{ formatNumber(unapprovedInvoiceTotal, 1) }}</td>
              </tr>
              <tr>
                <td>Current</td>
                <td>{{ formatNumber(apCustomerTotals.currentp) }}%</td>
                <td>{{ formatNumber(apCustomerTotals.current, 1) }}</td>
              </tr>
              <tr>
                <td>1-30 days</td>
                <td>{{ formatNumber(apCustomerTotals.days30p) }}%</td>
                <td>{{ formatNumber(apCustomerTotals.days30, 1) }}</td>
              </tr>
              <tr>
                <td>31-60 days</td>
                <td>{{ formatNumber(apCustomerTotals.days60p) }}%</td>
                <td>{{ formatNumber(apCustomerTotals.days60, 1) }}</td>
              </tr>
              <tr>
                <td>61-90 days</td>
                <td>{{ formatNumber(apCustomerTotals.days90p) }}%</td>
                <td>{{ formatNumber(apCustomerTotals.days90, 1) }}</td>
              </tr>
              <tr>
                <td>>90 days</td>
                <td>{{ formatNumber(apCustomerTotals.daysOver90p) }}%</td>
                <td>{{ formatNumber(apCustomerTotals.daysOver90, 1) }}</td>
              </tr>
              <tr>
                <td>Retention</td>
                <td>{{ formatNumber(apCustomerTotals.retentionp) }}%</td>
                <td>{{ formatNumber(apCustomerTotals.retention, 1) }}</td>
              </tr>
              <tr>
                <td>Total:</td>
                <td>100%</td>
                <td>{{ formatNumber(apCustomerTotals.totalOwing, true) }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="sh-widget card wide-large">
        <div class="content">
          <div class="top-section">
            <div class="">
              <h4>AR - AP Status</h4>
            </div>
          </div>
          <div class="table-container show-scrollbar">
            <table>
              <thead>
                <tr>
                  <th><label>AR</label></th>
                  <th><label>AP</label></th>
                  <th><label>Unapproved AP</label></th>
                  <th><label>WIP</label></th>
                  <th><label>Net</label></th>
                </tr>
              </thead>
              <tr>
                <td>{{ formatNumber(arCustomerTotals.totalOwing, 1) }}</td>
                <td>{{ formatNumber(apCustomerTotals.totalOwing, 1) }}</td>
                <td>{{ formatNumber(unapprovedInvoiceTotal, 1) }}</td>
                <td>{{ formatNumber(wipTotal, 1) }}</td>
                <td>{{ formatNumber(arCustomerTotals.totalOwing - unapprovedInvoiceTotal - apCustomerTotals.totalOwing + wipTotal, 1) }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col col-sm-12 col-md-7">
      <customer-top-cash-flow v-if="customers.length" />
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="sh-widget card wide-xl top-account-table">
        <div class="content">
          <div class="top-section">
            <div class="">
              <h4>Top Accounts Receivable Top</h4>
            </div>
          </div>
          <div class="table-container show-scrollbar">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th><label>AR Top Account by Customer</label></th>
                  <th @click="setArTopAccounts('totalOwing')" :class="[{ sorted: sortBy == 'totalOwing' }, 'clickable']"><label>Total Owing</label></th>
                  <th @click="setArTopAccounts('retention')" :class="[{ sorted: sortBy == 'retention' }, 'clickable']"><label>Retention</label></th>
                  <th><label>Retention % of Total</label></th>
                  <th @click="setArTopAccounts('total')" :class="[{ sorted: sortBy == 'total' }, 'clickable']"><label>Non Retention</label></th>
                  <th><label>Non Retention % of Total</label></th>
                  <th @click="setArTopAccounts('current')" :class="[{ sorted: sortBy == 'current' }, 'clickable']"><label>Current Owing</label></th>
                  <th><label>Current %</label></th>
                  <th @click="setArTopAccounts('days30')" :class="[{ sorted: sortBy == 'days30' }, 'clickable']"><label>1-30 DAYS</label></th>
                  <th><label>1-30 DAYS %</label></th>
                  <th @click="setArTopAccounts('days60')" :class="[{ sorted: sortBy == 'days60' }, 'clickable']"><label>31-60 DAYS</label></th>
                  <th><label>31-60 DAYS %</label></th>
                  <th @click="setArTopAccounts('days90')" :class="[{ sorted: sortBy == 'days90' }, 'clickable']"><label>61-90 DAYS</label></th>
                  <th><label>61-90 DAYS %</label></th>
                  <th @click="setArTopAccounts('daysOver90')" :class="[{ sorted: sortBy == 'daysOver90' }, 'clickable']"><label>>90 DAYS</label></th>
                  <th><label>>90 DAYS %</label></th>
                  <!-- <th @click="setArTopAccounts('daysOver30')" :class="[{ sorted : sortBy == 'daysOver30'}, 'clickable']"><label>$ Total <br>31+ Days</label></th>
                <th><label>% Total <br>31+ Days</label></th> -->
                </tr>
              </thead>

              <tr v-for="(tat, i) in topAccountTotals" :key="i" @click="openDetails(tat)" class="is-link">
                <td>{{ i + 1 }}</td>
                <td>{{ tat.Name }}</td>
                <td>{{ formatNumber(tat.owing.totalOwing, 1) }}</td>
                <td>{{ formatNumber(tat.owing.retention, 1) }}</td>
                <td>{{ formatNumber(tat.owing.retentionp) }}%</td>
                <td>{{ formatNumber(tat.owing.total, 1) }}</td>
                <td>{{ formatNumber(tat.owing.totalp) }}%</td>
                <td>{{ formatNumber(tat.owing.current, 1) }}</td>
                <td>{{ formatNumber(tat.owing.currentp) }}%</td>
                <td>{{ formatNumber(tat.owing.days30, 1) }}</td>
                <td>{{ formatNumber(tat.owing.days30p) }}%</td>
                <td>{{ formatNumber(tat.owing.days60, 1) }}</td>
                <td>{{ formatNumber(tat.owing.days60p) }}%</td>
                <td>{{ formatNumber(tat.owing.days90, 1) }}</td>
                <td>{{ formatNumber(tat.owing.days90p) }}%</td>
                <td>{{ formatNumber(tat.owing.daysOver90, 1) }}</td>
                <td>{{ formatNumber(tat.owing.daysOver90p) }}%</td>
                <!-- <td>{{ formatNumber(tat.owing.daysOver30, 1) }}</td>
                <td>{{ formatNumber(tat.owing.daysOver30p) }}%</td> -->
              </tr>

              <tr v-if="'cats' === 'cats'">
                <td>11</td>
                <td>Others</td>
                <td>{{ formatNumber(otherAccountTotals.totalOwing, 1) }}</td>
                <td>{{ formatNumber(otherAccountTotals.retention, 1) }}</td>
                <td>{{ formatNumber(otherAccountTotals.retentionp) }}%</td>
                <td>{{ formatNumber(otherAccountTotals.total, 1) }}</td>
                <td>{{ formatNumber(otherAccountTotals.totalp) }}%</td>
                <td>{{ formatNumber(otherAccountTotals.current, 1) }}</td>
                <td>{{ formatNumber(otherAccountTotals.currentp) }}%</td>
                <td>{{ formatNumber(otherAccountTotals.days30, 1) }}</td>
                <td>{{ formatNumber(otherAccountTotals.days30p) }}%</td>
                <td>{{ formatNumber(otherAccountTotals.days60, 1) }}</td>
                <td>{{ formatNumber(otherAccountTotals.days60p) }}%</td>
                <td>{{ formatNumber(otherAccountTotals.daysOver90, 1) }}</td>
                <td>{{ formatNumber(otherAccountTotals.days90p) }}%</td>
                <td>{{ formatNumber(otherAccountTotals.daysOver90, 1) }}</td>
                <td>{{ formatNumber(otherAccountTotals.daysOver90p) }}%</td>
                <!-- <td>{{ formatNumber(otherAccountTotals.daysOver30, 1) }}</td>
                <td>{{ formatNumber(otherAccountTotals.daysOver30p) }}%</td> -->
              </tr>

              <tr>
                <td></td>
                <td>Total:</td>
                <td>{{ formatNumber(arCustomerTotals.totalOwing, 1) }}</td>
                <td>{{ formatNumber(arCustomerTotals.retention, 1) }}</td>
                <td>100%</td>
                <td>{{ formatNumber(arCustomerTotals.total, 1) }}</td>
                <td>100%</td>
                <td>{{ formatNumber(arCustomerTotals.current, 1) }}</td>
                <td>100%</td>
                <td>{{ formatNumber(arCustomerTotals.days30, 1) }}</td>
                <td>100%</td>
                <td>{{ formatNumber(arCustomerTotals.days60, 1) }}</td>
                <td>100%</td>
                <td>{{ formatNumber(arCustomerTotals.days90, 1) }}</td>
                <td>100%</td>
                <td>{{ formatNumber(arCustomerTotals.daysOver90, 1) }}</td>
                <td>100%</td>
                <!-- <td>{{ formatNumber(arCustomerTotals.daysOver30, 1) }}</td>
                <td>100%</td> -->
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="sh-widget card wide-xl top-account-table">
        <div class="content">
          <div class="top-section">
            <div class="">
              <h4>Top Accounts Payable</h4>
            </div>
          </div>
          <div class="table-container show-scrollbar">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th><label>AP Top Account by Vendor</label></th>
                  <th @click="setApTopAccounts('totalOwing')" :class="[{ sorted: sortBy == 'totalOwing' }, 'clickable']"><label>Total Owing</label></th>
                  <th @click="setApTopAccounts('retention')" :class="[{ sorted: sortBy == 'retention' }, 'clickable']"><label>Retention</label></th>
                  <th><label>Retention % of Total</label></th>
                  <th @click="setApTopAccounts('total')" :class="[{ sorted: sortBy == 'total' }, 'clickable']"><label>Non Retention</label></th>
                  <th><label>Non Retention % of Total</label></th>
                  <th @click="setApTopAccounts('current')" :class="[{ sorted: sortBy == 'current' }, 'clickable']"><label>Current Owing</label></th>
                  <th><label>Current %</label></th>
                  <th @click="setApTopAccounts('days30')" :class="[{ sorted: sortBy == 'days30' }, 'clickable']"><label>1-30 DAYS</label></th>
                  <th><label>1-30 DAYS %</label></th>
                  <th @click="setApTopAccounts('days60')" :class="[{ sorted: sortBy == 'days60' }, 'clickable']"><label>31-60 DAYS</label></th>
                  <th><label>31-60 DAYS %</label></th>
                  <th @click="setApTopAccounts('days90')" :class="[{ sorted: sortBy == 'days90' }, 'clickable']"><label>61-90 DAYS</label></th>
                  <th><label>61-90 DAYS %</label></th>
                  <th @click="setApTopAccounts('daysOver90')" :class="[{ sorted: sortBy == 'daysOver90' }, 'clickable']"><label>>90 DAYS</label></th>
                  <th><label>>90 DAYS %</label></th>
                  <!-- <th @click="setArTopAccounts('daysOver30')" :class="[{ sorted : sortBy == 'daysOver30'}, 'clickable']"><label>$ Total <br>31+ Days</label></th>
                <th><label>% Total <br>31+ Days</label></th> -->
                </tr>
              </thead>

              <tr v-for="(tat, i) in topApAccountTotals" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ tat.Vendor_Name }}</td>
                <td>{{ formatNumber(tat.owing.totalOwing, 1) }}</td>
                <td>{{ formatNumber(tat.owing.retention, 1) }}</td>
                <td>{{ formatNumber(tat.owing.retentionp) }}%</td>
                <td>{{ formatNumber(tat.owing.total, 1) }}</td>
                <td>{{ formatNumber(tat.owing.totalp) }}%</td>
                <td>{{ formatNumber(tat.owing.current, 1) }}</td>
                <td>{{ formatNumber(tat.owing.currentp) }}%</td>
                <td>{{ formatNumber(tat.owing.days30, 1) }}</td>
                <td>{{ formatNumber(tat.owing.days30p) }}%</td>
                <td>{{ formatNumber(tat.owing.days60, 1) }}</td>
                <td>{{ formatNumber(tat.owing.days60p) }}%</td>
                <td>{{ formatNumber(tat.owing.days90, 1) }}</td>
                <td>{{ formatNumber(tat.owing.days90p) }}%</td>
                <td>{{ formatNumber(tat.owing.daysOver90, 1) }}</td>
                <td>{{ formatNumber(tat.owing.daysOver90p) }}%</td>
                <!-- <td>{{ formatNumber(tat.owing.daysOver30, 1) }}</td>
                <td>{{ formatNumber(tat.owing.daysOver30p) }}%</td> -->
              </tr>

              <tr v-if="'cats' === 'cats'">
                <td>11</td>
                <td>Others</td>
                <td>{{ formatNumber(otherApAccountTotals.totalOwing, 1) }}</td>
                <td>{{ formatNumber(otherApAccountTotals.retention, 1) }}</td>
                <td>{{ formatNumber(otherApAccountTotals.retentionp) }}%</td>
                <td>{{ formatNumber(otherApAccountTotals.total, 1) }}</td>
                <td>{{ formatNumber(otherApAccountTotals.totalp) }}%</td>
                <td>{{ formatNumber(otherApAccountTotals.current, 1) }}</td>
                <td>{{ formatNumber(otherApAccountTotals.currentp) }}%</td>
                <td>{{ formatNumber(otherApAccountTotals.days30, 1) }}</td>
                <td>{{ formatNumber(otherApAccountTotals.days30p) }}%</td>
                <td>{{ formatNumber(otherApAccountTotals.days60, 1) }}</td>
                <td>{{ formatNumber(otherApAccountTotals.days60p) }}%</td>
                <td>{{ formatNumber(otherApAccountTotals.daysOver90, 1) }}</td>
                <td>{{ formatNumber(otherApAccountTotals.days90p) }}%</td>
                <td>{{ formatNumber(otherApAccountTotals.daysOver90, 1) }}</td>
                <td>{{ formatNumber(otherApAccountTotals.daysOver90p) }}%</td>
                <!-- <td>{{ formatNumber(otherApAccountTotals.daysOver30, 1) }}</td>
                <td>{{ formatNumber(otherApAccountTotals.daysOver30p) }}%</td> -->
              </tr>

              <tr>
                <td></td>
                <td>Total:</td>
                <td>{{ formatNumber(apCustomerTotals.totalOwing, 1) }}</td>
                <td>{{ formatNumber(apCustomerTotals.retention, 1) }}</td>
                <td>100%</td>
                <td>{{ formatNumber(apCustomerTotals.total, 1) }}</td>
                <td>100%</td>
                <td>{{ formatNumber(apCustomerTotals.current, 1) }}</td>
                <td>100%</td>
                <td>{{ formatNumber(apCustomerTotals.days30, 1) }}</td>
                <td>100%</td>
                <td>{{ formatNumber(apCustomerTotals.days60, 1) }}</td>
                <td>100%</td>
                <td>{{ formatNumber(apCustomerTotals.days90, 1) }}</td>
                <td>100%</td>
                <td>{{ formatNumber(apCustomerTotals.daysOver90, 1) }}</td>
                <td>100%</td>
                <!-- <td>{{ formatNumber(apCustomerTotals.daysOver30, 1) }}</td>
                <td>100%</td> -->
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner />
</div>
