<template>
  <div v-if="spin" class="load-spinner-container not-pdf">
    <div>
      <div :class="['loader-overlay', { contained: contained }]">
        <div class="loading-spinner">
          <div class="loader loader2">
            <div>
              <div>
                <div>
                  <div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="message">{{ spin }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from 'store'
import appFuncs from 'appFuncs'

export default {
  name: 'waiting-spinner',
  data() {
    return {
      waitC: []
    }
  },

  props: {
    wait: {
      type: Object,
      default() {
        return {
          message: ''
        }
      }
    },
    contained: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    waitCing() {
      return store.getters.waiting
    },

    spin() {
      if (this.wait.message) {
        return this.wait.message
      }
      // set spin to waitCing or waitC
      if (this.waitC.length) {
        let last = this.waitC[this.waitC.length - 1]
        return last.message
      }
      if (this.waitCing.status && this.waitCing.message) {
        return this.waitCing.message
      }
      return ''
    }
  },

  mounted() {
    this.$bus.$on('setWaiting', data => {
      let existing = this.waitC.find(itm => {
        return itm.name === data.name
      })
      if (!existing) {
        this.waitC.push({ name: data.name, message: data.message })
      }
    })

    this.$bus.$on('stopWaiting', name => {
      let i = this.waitC.findIndex(itm => {
        return itm.name === name
      })
      if (i !== -1) {
        this.waitC.splice(i, 1)
      }
    })
  },

  watch: {
    spin() {
      //appFuncs.scrollTop();
    }
  }
}
</script>
<style lang="scss">
.waiting-container {
  position: relative;
}

.load-spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(230, 230, 230, 0.75);
  z-index: 100;

  &.contained {
    position: absolute !important;
  }
}

.loader-overlay:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
}

/*
https://codepen.io/Siddharth11/pen/xbGrpG
*/

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-spinner {
  position: absolute;
  width: 200px;
  height: 120px;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -60px;
  text-align: center;
  z-index: 100;
}

.loading-spinner:before {
  content: '';
  display: block;
  width: 1px;
  height: 1px;
  box-shadow: 0 0 300px 220px rgba(232, 232, 232, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .loading-spinner {
    top: 20%;
  }
}

.loading-spinner .loader {
  width: 75px;
  height: 75px;
  display: block;
  overflow: hidden;
  margin: 0 auto 5px;
}

.loading-spinner .loader div {
  height: 100%;
}

/*loader 2  */
.loading-spinner .loader2,
.loading-spinner .loader2 div {
  border-radius: 50%;
  padding: 4px;
  border: 2px solid transparent;
  -webkit-animation: rotate linear 3.5s infinite;
  animation: rotate linear 3.5s infinite;
  border-top-color: #ed2027;
  border-left-color: rgba(129, 111, 111, 0);
  border-right-color: rgba(129, 111, 111, 0);
}

.loading-spinner .loader div:hover {
  /* -webkit-animation-play-state: paused;
    animation-play-state: paused;
     */
}

.loading-spinner .loader,
.loading-spinner .loader * {
  will-change: transform;
}

.sh-widget .message {
  display: none;
}
</style>
