<template>
  <div>
    <alert/>

    <iframe class="responsive-table-embed"
            src="https://docs.google.com/forms/d/e/1FAIpQLSdN3cLpAkt1vnO91nX4j_TDCE1CB3UKAjc_KmYRH2rQA8l-Ew/viewform?c=0&w=1&amp;single=true&amp;widget=true&amp;headers=false"
    ></iframe>

    <waiting-spinner/>

  </div>
</template>
<script>

import store from 'store';
import Alert from 'components/UIComponents/Alert';
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  components: {
    Alert,
    WaitingSpinner
  },
  data() {
    return {
      urls: store.getters.urls,
    }
  },

  computed: {},
  methods: {},
  watch: {}
}

</script>

<style lang="scss" scoped>

.responsive-table-embed {
  width: 100%;
  max-width: 100%;
  min-height: 900px;
  border: 0px solid #555;
}

</style>
