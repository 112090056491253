<template>
  <div>
    <form ref="pdfForm" method="GET" :action="`${$store.getters.urls.backend}download.php`" target="_blank" style="display: none">
      <input ref="file" type="hidden" name="file" value="" />
      <input ref="mode" type="hidden" name="mode" value="" />
    </form>

    <div v-if="displayType === 'link'">
      <span>{{ fileName }}</span>
      <a href="" @click.prevent="downloadPDF" class="label-link ti-download" title="Download PDF"></a>
      <a href="" @click.prevent="previewPDF" class="label-link ti-eye" title="Preview PDF"></a>
    </div>
    <div v-else class="button-group">
      <button class="btn" @click="downloadPDF" :disabled="processing">
        <span>Download PDF</span>
        <span v-if="processing" class="spinner"></span>
      </button>
      <button class="btn btn-info" @click="previewPDF" :disabled="processing">
        <span>Preview PDF</span>
        <span v-if="processing" class="spinner"></span>
      </button>
    </div>
    <waiting-spinner />
  </div>
</template>

<script>
import appFuncs from 'appFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'DownloadMergePDF',
  props: {
    path: {
      type: [String, Array],
      required: true
    },
    fileName: {
      type: String,
      required: true
    },
    displayType: {
      type: String,
      default: 'button'
    }
  },
  data() {
    return {
      processing: false
    }
  },
  components: {
    WaitingSpinner
  },
  methods: {
    async mergePDFs() {
      this.$bus.$emit('setWaiting', { name: 'mergePDFs', message: 'Merging PDFs' })
      this.processing = true
      try {
        const response = await appFuncs.shRequest({
          action: 'merge_pdfs_and_generate_download_link',
          path: this.path,
          filename: this.fileName
        })
        this.$refs.file.value = response.fileName
        this.$emit('file', response)
        return response
      } catch (error) {
        console.log('Error:', error)
        this.$snack.open(error.message || 'Error merging PDFs', 'error')
        throw error
      } finally {
        this.processing = false
        this.$bus.$emit('stopWaiting', 'mergePDFs')
      }
    },

    async downloadPDF() {
      await this.mergePDFs()
      this.$refs.mode.value = 'download'
      this.$refs.pdfForm.submit()
    },

    async previewPDF() {
      await this.mergePDFs()
      this.$refs.mode.value = 'preview'
      this.$refs.pdfForm.submit()
    }
  }
}
</script>

<style scoped>
.button-group {
  display: flex;
  gap: 10px;
}

button.btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 7px;
}
/* Spinner styles */
span {
  display: inline-block;
}
.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #66615b;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Keyframes for the spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.label-link {
  margin-left: 10px;
  cursor: pointer;
  display: inline-block;
  border-radius: 4px;
  transition: all 0.2s ease;
  font-size: 16px;
}
</style>
