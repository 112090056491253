<template>
  <div class="form-group" v-click-outside="closeField">
    <label> {{ label }}<span v-if="isRequired" class="required-star">*</span> </label>
    <input v-if="!noInput" type="text" class="form-control box-add-item select-field" v-model="selected.Name" :disabled="disabled" readonly @click="openDropdown" :placeholder="searchText" readonly />
    <p v-else v-html="selected.Name" />
    <div v-if="showOptions" class="option-box-parent">
      <div class="option-box">
        <input type="text" class="form-control company-filter" v-model="companyFilter" placeholder="Search" />
        <div class="option-items show-scrollbar">
          <div v-if="allowClear" class="option-item empty-option-item" @click="selectEl('')">
            <span><span v-html="typeof allowClear === 'string' ? allowClear : 'CLEAR'" /></span>
          </div>
          <div v-for="(customer, key) in filteredCustomers" :key="key" :class="'option-item'" @click="selectEl(customer.Customer_Code)">
            {{ customer.Name }}
          </div>
          <div v-if="!customers || !customers[0]">
            <i>Fetching customers...</i>
          </div>
          <div v-if="customers && customers[0] && !filteredCustomers[0]">
            <i>No results</i>
          </div>
        </div>
        <span v-if="allowAddNew" class="add-new" @click="addNewCustomer"> Add New </span>
      </div>
    </div>
  </div>
</template>
<script>
import appFuncs from 'appFuncs'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      compField: '',
      showOptions: false,
      companyFilter: ''
    }
  },

  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    value: {
      type: String,
      default: ''
    },
    noInput: {
      default: false,
      type: Boolean
    },
    label: {
      default: 'Customer',
      type: String
    },
    searchText: {
      default: 'Select',
      type: String
    },
    required: {
      default: true,
      type: Boolean
    },
    allowAddNew: {
      type: Boolean,
      default: true
    },
    allowClear: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['customers']),

    selected: {
      get() {
        let obj = this.customers.find(itm => {
          return itm.Customer_Code == this.value
        })
        return obj || {}
      },
      set(val) {}
    },

    isRequired() {
      if (this.required && !this.disabled && !this.noInput) {
        return true
      }
      return false
    },

    filteredCustomers() {
      const allCustomers = [...this.customers]
      let customers = []

      for (let i = 0; i < allCustomers.length; i++) {
        if (allCustomers[i].Name === 'undefined') continue
        if (allCustomers[i].Status !== 'Active') continue

        if (this.companyFilter) {
          let hasNameMatch = String(allCustomers[i].Name).toLowerCase().indexOf(String(this.companyFilter).toLowerCase()) !== -1
          let hasCCMatch = String(allCustomers[i].Customer_Code).toLowerCase().indexOf(String(this.companyFilter).toLowerCase()) !== -1
          if (!hasNameMatch && !hasCCMatch) {
            continue
          }
        }

        customers.push(allCustomers[i])
      }

      return customers.map(itm => {
        return {
          Name: itm.Name,
          Customer_Code: itm.Customer_Code
        }
      })
    }
  },

  methods: {
    getData(refresh) {
      this.$bus.$emit('setWaiting', { name: 'get_customers', message: 'Getting Customers' })
      this.$store.dispatch('getCustomers', refresh).then(() => {
        this.$bus.$emit('stopWaiting', 'get_customers')
      })
    },

    selectEl(cc) {
      const match = this.customers.find(itm => {
        return itm.Customer_Code == cc
      })
      if (match) {
        this.selected = match
      }
      this.showOptions = false
      this.$emit('input', cc)
      this.$bus.$emit('customer-select-field', cc)

      this.$nextTick(() => {
        const input = this.$el.querySelector('input')

        if (input) {
          input.focus()
        }
      })
    },

    closeField() {
      this.showOptions = false
    },

    addNewCustomer() {
      this.$Modal({
        parent: this,
        name: 'add-customer', // used for closing specific modal programmatically
        title: 'Add Customer',
        size: 'xl', // sm, md, lg, xl
        component: () => import('src/components/Dashboard/Contacts/AddCustomer'),
        props: {
          customerCode: (this.preFiltered || {}).Ship_To_Customer_Code || ''
        }
      })
    },

    openDropdown() {
      this.showOptions = !this.showOptions
      this.$nextTick(() => {
        const searchInput = this.$el.querySelector('.company-filter')

        if (searchInput) {
          searchInput.focus()
        }
      })
    }
  },

  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.form-control input {
  padding: 10px;
  display: block;
}

input.select {
  -webkit-appearance: menulist-button;
  text-indent: 10px;
}

.box-add-item {
  cursor: pointer !important;

  &[disabled] {
    cursor: not-allowed !important;
  }
}

.option-box-parent {
  position: relative;
  z-index: 999999;

  .option-box {
    width: 100%;
    position: absolute;
    background: #fff;
    padding: 15px;
    top: -2px;
    box-shadow: 4px 7px 20px 2px rgba(0, 0, 0, 0.08);
    border-radius: 0 0 5px 5px;

    .company-filter {
      margin-bottom: 10px;
    }

    .add-new {
      font-weight: bold;
      cursor: pointer;
      padding: 10px 0 0;
      text-decoration: underline;
    }

    .option-items {
      max-height: 200px;
      overflow-y: scroll;
      background: #f5f5f5;
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 10px;

      .option-item {
        padding: 4px 0;
        color: #555;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }
      }

      .empty-option-item {
        background: #eee;
        border: 1px solid #ddd;
        border-radius: 2px;
        padding: 5px;
        margin-bottom: 5px;

        span {
          font-style: italic;
          letter-spacing: 0.5px;
          font-weight: bold;
        }

        &:after {
          content: '\f057';
          font-family: 'Font Awesome 5 Free';
          float: right;
          font-weight: 900;
        }
      }
    }

    .option-box-add {
      .option-box-add-container {
        position: relative;
        margin-bottom: 10px;

        .option-box-add-input-container {
          margin-right: 70px;

          input {
          }
        }
      }

      button {
        width: 60px;
        position: absolute;
        top: 0;
        height: 40px;
        display: block;
        right: 0;
        padding: 0;
        // border-radius:5px;
      }
    }
  }
}
</style>
