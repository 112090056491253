<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div :class="['card']">
          <div class="content">
            <bh-table :table-settings="tableSettings" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import appFuncs from 'appFuncs';
import { mapGetters } from 'vuex'
import BhTable from 'components/UIComponents/BhTable'
import appFuncs from 'appFuncs'

export default {
  data() {
    return {
      apData: [],
      tableSettings: {
        tableData: [],
        fields: {
          Name: {
            name: 'Name'
          },
          current: {
            name: 'Current'
          },
          _30Days: {
            name: '< 30 days'
          },
          _60Days: {
            name: '< 60 days'
          },
          _90Days: {
            name: '< 90 days'
          },
          over90Days: {
            name: '> 90 days'
          }
        },
        sortCol: 'Name',
        sortColReverse: false
      }
    }
  },

  components: {
    BhTable
  },

  computed: {
    ...mapGetters(['vendors'])
  },

  methods: {
    fetchData() {
      ;(async () => {
        this.$bus.$emit('setWaiting', { name: 'fetchARSummaryItems', message: 'Getting data' })

        // fetch all items at same time, do stuff after all fetched
        await Promise.all([
          (async () =>
            this.$store.dispatch('getVendors').catch(res => {
              this.$snack.open('Problem getting Spectrum customer info', 'error')
            }))(),
          (async () =>
            appFuncs.shRequest({ action: 'get_accounts_payable' }).catch(res => {
              this.$snack.open('Problem getting receivable items', 'error')
              console.log(res)
            }))()
        ]).then(arr => {
          if (Array.isArray(arr[1])) {
            this.apData = arr[1]
          }
        })

        this.$bus.$emit('stopWaiting', 'fetchARSummaryItems')

        if (!this.apData.length || !this.vendors.length) {
          this.$snack.open('Problem fetching some data', 'error')
          return
        }

        let tableData = []
        let hasItm = false
        for (var ci = 0; ci < this.vendors.length; ci++) {
          let customerData = {
            id: ci + 1, // for BHtable
            Vendor_Code: this.vendors[ci].Vendor_Code,
            Name: this.vendors[ci].Vendor_Name,
            over90Days: 0,
            _90Days: 0,
            _60Days: 0,
            _30Days: 0,
            current: 0
          }

          // const wret = this.apData.filter(itm => {
          //   return itm.Retention;
          // })
          // console.log(wret)

          for (var ari = 0; ari < this.apData.length; ari++) {
            if (this.vendors[ci].Vendor_Code == this.apData[ari].Vendor_Code) {
              const amt = parseFloat(this.apData[ari].Invoice_Amount - this.apData[ari].Payment_Total - this.apData[ari].Retention)

              if (this.getDays(this.apData[ari].DUE_DATE) > 90) {
                customerData.over90Days += amt
              } else if (this.getDays(this.apData[ari].DUE_DATE) > 60) {
                customerData._90Days += amt
              } else if (this.getDays(this.apData[ari].DUE_DATE) > 30) {
                customerData._60Days += amt
              } else if (this.getDays(this.apData[ari].DUE_DATE) > 0) {
                customerData._30Days += amt
              } else {
                customerData.current += amt
              }
            }
          }

          if (customerData.over90Days > 0 || customerData._90Days > 0 || customerData._60Days > 0 || customerData._30Days > 0 || customerData.current) {
            customerData.current = this.formatNumber(customerData.current)
            customerData._30Days = this.formatNumber(customerData._30Days)
            customerData._60Days = this.formatNumber(customerData._60Days)
            customerData._90Days = this.formatNumber(customerData._90Days)
            customerData.over90Days = this.formatNumber(customerData.over90Days)
            tableData.push(customerData)
          }
        }
        this.tableSettings.tableData = tableData
      })()
    }
  },

  mounted() {
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped></style>
