<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div :class="['group-container', { 'separate-section': separateSection }]" v-for="(element, index) in elements" :key="index">
          <table>
            <thead>
              <tr>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(subField, i) in element.fields" :key="i">
                <td>
                  <div class="form-group">
                    <label>{{ subField.label }}</label>
                    <vue-numeric v-if="subField.type === 'dollar'" currency="$" separator="," :class="['form-control']" :precision="2" v-model="element.value" inputmode="decimal" />
                    <input type="text" v-else v-model="subField.value" class="form-control" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <button class="btn btn-sm" @click="saveItem(element)">Save</button>
        </div>
        <button class="btn" @click="addOption">Add Option</button>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import SelectField from 'components/UIComponents/SelectField'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import SelectFieldMulti from 'components/UIComponents/SelectFieldMulti'
import DatePicker from 'components/UIComponents/DatePicker'
import commonFuncs from 'mixins/commonFuncs'
import Checkbox2 from 'components/UIComponents/Checkbox'
import VueNumeric from 'vue-numeric'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'AdminGroupedFields',
  data() {
    return {
      elements: [],
      options: [],
      loadedData: []
    }
  },

  components: {
    Checkbox2,
    VueNumeric,
    WaitingSpinner
  },

  props: {
    label: {
      type: String,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    valueType: {
      type: String,
      default: 'text'
    },
    singleField: {
      type: Boolean,
      default: false
    },
    detailValue: {
      type: Boolean,
      default: false
    },
    separateSection: {
      type: Boolean,
      default: true
    },
    fields: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters(['userAuthedData']),

    sortedOptions() {
      return this.options
    }
  },

  methods: {
    addOption() {
      if (this.elements.length) {
        let highestSort = this.elements[this.elements.length - 1].sort
      }
      let highestSort = 0
      let fields = this.fields.map(itm => {
        return {
          label: itm.label,
          name: itm.name,
          type: itm.type,
          value: ''
        }
      })

      this.elements.push({
        id: this.randomCharacterString(12),
        group: this.field,
        sort: highestSort + 1,
        fields
      })
    },

    setDefault(obj) {
      // find current defaults and se to no
      var currentDefaults = this.options.filter(o => o.default === 'yes')
      for (let i = 0; i < currentDefaults.length; i++) {
        if (currentDefaults[i].id === obj.id) continue
        currentDefaults[i].default = 'no'
        this.saveOption(currentDefaults[i])
      }
      this.saveOption(obj)
    },

    hasChange(obj) {
      if (!this.loadedData.length) return false
      let loadedOption = this.loadedData.find(o => o.id === obj.id)
      if (loadedOption) {
        return JSON.stringify(loadedOption) !== JSON.stringify(obj)
      } else {
        return true
      }
    },

    cancelChange(obj) {
      let loadedOption = this.loadedData.find(o => o.id === obj.id)
      if (loadedOption) {
        let index = this.options.findIndex(o => o.id === obj.id)
        this.options[index].name = loadedOption.name
        this.options[index].value = loadedOption.value
      }
    },

    saveItem(element) {
      // todo add validation that the data conforms to the fields
      var params = {
        action: 'upsert_global_option',
        group: element.group,
        id: element.id,
        value: element.fields,
        sort: element.sort
      }

      this.$bus.$emit('setWaiting', {
        name: 'upsert_global_option',
        message: 'Updating option'
      })

      appFuncs
        .shHttp(params)
        .then(res => {
          this.$snack.open('Updated', 'success')
        })
        .catch(err => {
          this.$snack.open(err.message || 'Problem updating option', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'upsert_global_option')
        })
    },

    deleteItem(obj) {
      if (obj.default === 'yes') {
        this.$snack.open('Cannot delete default option', 'warning')
        return
      }

      if (!confirm('Deleting options is permanent, are you sure?')) return

      var params = {
        action: 'delete_global_option',
        obj
      }

      this.$bus.$emit('setWaiting', {
        name: 'delete_global_option',
        message: 'Deleting'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.$snack.open('Deleted', 'success')
          let option = this.options.findIndex(o => o.id === obj.id)
          if (option > -1) {
            this.options.splice(option, 1)
          }
          let loadedOption = this.loadedData.findIndex(o => o.id === obj.id)
          if (loadedOption > -1) {
            this.loadedData.splice(loadedOption, 1)
          }
        })
        .catch(err => {
          this.$snack.open(err.message || 'Problem deleting option', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'delete_global_option')
        })
    },

    moveItemUp(option) {
      let options = this.options
      let index = options.findIndex(o => o.id === option.id)
      if (index === 0) return
      let temp = options[index - 1].sort
      options[index - 1].sort = options[index].sort
      options[index].sort = temp

      this.options = [...options]
      this.saveOption(options[index - 1])
      this.saveOption(options[index])
    },

    moveItemDown(option) {
      let options = this.options
      let index = options.findIndex(o => o.id === option.id)
      if (index === options.length - 1) return
      let temp = options[index + 1].sort
      options[index + 1].sort = options[index].sort
      if (options[index].sort === temp) {
        options[index].sort = temp + 1
      }
      options[index].sort = temp
      this.options = [...options]
      this.saveOption(options[index + 1])
      this.saveOption(options[index])
    },

    // for all options, make sure no diplicate sort values, save each only if sort value is changed
    fixSort() {
      let options = [...this.options]
      options.sort((a, b) => {
        return a.sort - b.sort
      })
      let sort = 1
      for (let i = 0; i < options.length; i++) {
        if (options[i].sort !== sort) {
          options[i].sort = sort
          // this.saveOption(options[i]);
        }
        sort++
      }
    },

    getData() {
      var params = {
        action: 'get_global_options',
        group: this.field
      }

      this.$bus.$emit('setWaiting', {
        name: 'get_global_options',
        message: 'Getting Options'
      })
      appFuncs
        .shHttp(params)
        .then(res => {
          let elements = Array.isArray(res.data) ? res.data : []
          this.elements = elements.map(itm => {
            return {
              id: itm.id,
              group: itm.group,
              sort: itm.sort,
              fields: JSON.parse(itm.value)
            }
          })

          this.elements.sort((a, b) => {
            return a.sort - b.sort
          })

          // this.fixSort();
          // this.loadedData = JSON.parse(JSON.stringify(this.options));
        })
        .catch(err => {
          this.$snack.open(err.message || 'Problem getting setup options', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'get_global_options')
        })
    }
  },

  mounted() {
    this.getData()
  },

  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.group-container {
  &.separate-section {
    border: 1px solid $light-gray;
    padding: 0 10px 10px;
    margin: 10px 0;
    max-width: 800px;
  }

  .btn {
    margin-top: 10px;
  }

  h4 {
    margin: 5px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;

  th {
    padding: 5px;
  }

  td {
    padding: 5px;

    .form-group {
      margin: 0 !important;
    }
  }
}
</style>
