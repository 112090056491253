<template>
  <div>
    <tabulator-table
      table-id="start_finish_date_changes"
      :table-data="tableData"
      :table-columns="tableColumns"
      :table-condensed="false"
      empty-text="(none)"
    />
    <waiting-spinner />
  </div>
</template>
<script>
import appFuncs from 'appFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import moment from 'moment'

export default {
  data() {
    return {
      obj: []
    }
  },

  components: {
    WaitingSpinner,
    TabulatorTable
  },

  props: {
    objNumber: {
      type: [String, Number],
      required: true
    },
    type: {
      type: String,
      default: 'Job'
    }
  },

  computed: {
    tableColumns() {
      return [
        {
          title: 'Date',
          field: 'timestamp',
          width: '20%',
          formatter: cell => {
            const value = cell.getValue();
            return moment.unix(value).format('MM/DD/YYYY');
          }
        },
        {
          title: 'User',
          field: 'employee_name',
          width: '20%',
        },
        {
          title: 'Start Date',
          field: 'start_date',
          width: '20%',
          formatter: cell => {
            const value = cell.getValue();
            return moment.utc(value).format('MM/DD/YYYY');
          }
        },
        {
          title: 'End Date',
          field: 'end_date',
          width: '20%',
          formatter: cell => {
            const value = cell.getValue();
            return moment.utc(value).format('MM/DD/YYYY');
          }
        },
        {
          title: 'Project Status Notes',
          field: 'reason',
          width: '40%',
        },
      ]
    },
    tableData() {
      if (Array.isArray(this.obj.date_change_log)) {
        this.obj.date_change_log.shift();
      }
      return this.obj.date_change_log?.sort((a, b) => b.timestamp - a.timestamp) || [];
    }
  },

  methods: {
    async getJob() {
      this.$bus.$emit('setWaiting', {name: 'get_job_main_app_data', message: 'Getting Date Change Logs'});
      appFuncs
        .shRequest({
          action: 'get_job_main_app_data',
          job_number: this.objNumber
        })
        .then(data => {
          this.obj = data
        })
        .catch(err => {
          this.$snack.open(err.message || 'Problem getting Job data', 'error')
        }).finally(() => {
          this.$bus.$emit('stopWaiting', 'get_job_main_app_data');
        });
    },
    async getWO() {
      this.$bus.$emit('setWaiting', {name: 'get_wo_main_app_data', message: 'Getting Date Change Logs'});
      appFuncs
        .shRequest({
          action: 'get_wo_main_app_data',
          wo_number: this.objNumber
        })
        .then(data => {
          this.obj = data[0] || []
        })
        .catch(err => {
          this.$snack.open(err.message || 'Problem getting WO data', 'error')
        }).finally(() => {
          this.$bus.$emit('stopWaiting', 'get_wo_main_app_data');
        });
    },
  },

  async mounted() {
    if (this.type === 'WO') {
      await this.getWO()
    } else {
      await this.getJob()
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
