<template>
  <div class="sh-widget wide-large card my-jobs-widget">
    <div class="content">
      <h3>
        Missing POs ({{ count }})
        <!-- <router-link :to="{ name:'Jobs' }" class="ti-new-window is-link" tag="i" /> -->
      </h3>
      <div class="content-inner show-scrollbars">
        <bh-table :table-settings="tableSettings" :style="{ maxWidth: '650px' }" @colClick="goToItem" />
        <waiting-spinner :contained="true" />
      </div>
    </div>
  </div>
</template>

<script>
import store from 'store'
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import jobFuncs from 'mixins/JobFuncs'
import woFuncs from 'mixins/WOFuncs'
import BhTable from 'components/UIComponents/BhTable'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {}
  },

  mixins: [jobFuncs, woFuncs],

  components: {
    BhTable,
    WaitingSpinner
  },

  computed: {
    ...mapGetters(['jobAssemble', 'woAssemble', 'userAuthedData']),

    filteredJobs() {
      let jobs = JSON.parse(JSON.stringify(this.myJobs))
      let filteredJobs = []

      for (let i = 0; i < jobs.length; i++) {
        let stage = parseInt(jobs[i].Stage)
        if (stage === 1 || stage === 2) {
          if (!jobs[i].po_number) {
            jobs[i].po_number = '<span class="has-link text-danger underlined">Add</span>'

            // standardize props
            jobs[i].Item_Number = `Job ${jobs[i].Job_Number}`
            jobs[i].Customer_Code = jobs[i].Customer_Code
            jobs[i].po_number = jobs[i].po_number
            jobs[i].description = jobs[i].Job_Description
            jobs[i].stage = jobs[i].Stage_Description
            filteredJobs.push(jobs[i])
          }
        }
      }
      return filteredJobs
    },

    filteredWOs() {
      let wos = JSON.parse(JSON.stringify(this.myWOs))
      let filteredWOs = []

      for (let i = 0; i < wos.length; i++) {
        let code = parseInt(wos[i].Priority_Code)
        if (code === 1 || code === 2) {
          if (!wos[i].Customer_PO_Number) {
            wos[i].Customer_PO_Number = '<span class="has-link text-danger underlined">Add</span>'

            // standardize props
            wos[i].Item_Number = `WO ${wos[i].WO_Number}`
            wos[i].Customer_Code = wos[i].Bill_Customer_Code
            wos[i].po_number = wos[i].Customer_PO_Number
            wos[i].description = wos[i].Summary_Description
            wos[i].stage = wos[i].Priority_Description

            filteredWOs.push(wos[i])
          }
        }
      }
      return filteredWOs
    },

    filtered() {
      // combine filtered jobs and filtered WOs
      let filtered = [...this.filteredJobs, ...this.filteredWOs]
      return filtered
    },

    count() {
      return this.filtered && this.filtered.length ? this.filtered.length : 0
    },

    isPM() {
      return this.userAuthedData.user_role.indexOf('pm') !== -1
    },

    tableSettings() {
      return {
        tableData: this.filtered,
        fields: {
          Item_Number: {
            name: 'Job/WO',
            class: 'bold narrower'
          },
          Customer_Code: {
            name: 'Customer',
            class: 'narrow'
          },
          description: {
            name: 'Description'
          },
          stage: {
            name: 'Stage',
            noFilter: 1
          },
          po_number: {
            name: 'PO Number',
            noFilter: 1,
            class: 'underlined'
          }
        },
        hideAllSearch: true,
        hideTip: true,
        tableLayout: 'auto'
      }
    }
  },

  methods: {
    goToItem(obj) {
      const id = obj.itm.Item_Number
      const obj2 = Object.assign({}, obj.itm)
      if (this.isPM && obj.col == 'po_number') {
        obj2.po_number = ''
        obj2.Customer_PO_Number = ''
        this.editPONumber(obj2)
      } else if (obj2['Job_Number']) {
        this.$router.push(`/app/job-details/${obj2['Job_Number']}`)
      } else if (obj2['WO_Number']) {
        this.$router.push(`/app/wo-details/${obj2['WO_Number']}`)
      }
    },

    editPONumber(obj) {
      this.$bus.$emit('modalOpen', {
        title: 'Edit PO Number',
        classes: 'gray-bg',
        name: 'JobWOPOEdit',
        title: '',
        size: 'modal-md',
        component: () => import('src/components/Dashboard/JobWo/JobWoPoEdit.vue'),
        componentData: {
          obj
        }
      })
    },

    listenPOEdit(obj) {
      if (!obj.po) return

      if (obj.Job_Number) {
        for (var i = 0; i < this.myJobs.length; i++) {
          if (this.myJobs[i].Job_Number == obj.Job_Number) {
            this.myJobs[i].po_number = obj.po
            break
          }
        }
      } else if (obj.WO_Number) {
        for (var i = 0; i < this.myWOs.length; i++) {
          if (this.myWOs[i].WO_Number == obj.WO_Number) {
            this.myWOs[i].po_number = obj.po
            break
          }
        }
      }
    }
  },

  mounted() {
    this.$bus.$on('JobWOPOEdit', this.listenPOEdit)
  },

  beforeDestroy() {
    this.$bus.$off('JobWOPOEdit', this?.listenPOEdit)
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.sh-widget {
  width: 700px;
  max-width: 100%;
  max-height: 370px;
  overflow: hidden;

  @media (min-width: 900px) {
    height: 370px;
  }
}

h3 {
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;

  i {
    color: $primary-color;
    float: right;
  }
}

.content-inner {
  overflow: scroll !important;
  max-height: 310px;
}
</style>

<style lang="scss">
@import 'src/assets/sass/paper/_variables.scss';

.my-jobs-widget .no-po .po_number {
  // background: $input-red;
}
</style>
