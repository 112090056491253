<template>
  <div>
    <div :class="['modal-container']" :style="{zIndex: 2 + index}" v-for="(modal, index) in modals">
      <div v-if="modalVisible" class="modal-underlay"/>

      <div :class="['modal-multi modal', modal.name]">
        <div :class="[classes, modal.size]" role="document">
          <div class="close-icon" v-if="!modal.hideClose" @click="closeModal(modal)" type="button">
            <span aria-hidden="true">&times;</span>
          </div>
          <div :class="contentClasses">
            <div class="modal-body">
              <h4 v-if="modal.title" class="modal-title" id="siteModalLabel">
                {{ modal.title }}
              </h4>
              <div v-html="modal.content"/>
              <div class="button-container">
                <button v-for="button in modal.buttons" @click="modalFunction(button.context, button.buttonFunc, button.buttonParams)" type="button" class="btn btn-primary">
                  {{ button.buttonText }}
                </button>
                <router-link v-if="modal.route" :to="modal.route" type="button" class="btn btn-primary">
                  {{ modal.buttonText }}
                </router-link>
              </div>

              <div v-if="modal.component">
                <!-- NEW is binding all modal componentData as individual props :component-data left for support-->
                <component :is="modal.component" :component-data="modal.componentData" v-bind="modal.componentData"/>
              </div>
            </div>
          </div>
          <!-- <button v-if="!modal.hideClose" @click="closeModal(modal)" type="button" class="close ti ti-close" aria-label="Close" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import store from 'store';
import appFuncs from 'appFuncs';
import {mapGetters} from 'vuex';

export default {
  name: 'ModalMulti',
  data() {
    return {
      modals: [],
      modalVisible: false,
      name: 'ModalMulti',
    };
  },

  computed: {
    classes() {
      var classes = 'modal-dialog';
      return classes;
    },

    contentClasses() {
      var classes = 'modal-content';
      return classes;
    }
  },

  methods: {
    closeModal(modal) {

      store.commit('alerts', {show: 0});

      if (typeof modal === 'object') {
        modal = modal.name;
      }

      for (var i = this.modals.length - 1; i >= 0; i--) {
        if (this.modals[i]['name'] === modal) {
          this.modals.splice(i, 1);
        }
      }

      this.$bus.$emit('modalClosed', {name: modal});

    },

    // this needs to be re-tested/fixed when needed
    modalFunction(context, buttonFunc, buttonParams) {
      appFuncs.triggerModalFunction(context, buttonFunc, buttonParams);
    },

  },

  mounted() {

    var self = this;
    this.$bus.$on('modalOpen', (modal) => {
      self.modals.push(modal);
      store.commit('alerts', {show: 0});
    });

    this.$bus.$on('modalClose', (modal) => {
      self.closeModal(modal);
      store.commit('alerts', {show: 0});
    });

    this.$bus.$on('navigated', () => {
      self.modals = [];
    });

    window.addEventListener('keyup', function (event) {
      if (event.keyCode == 27) {
        var lastModal = self.modals.length;
        if (lastModal) {
          self.closeModal(self.modals[0]);
        }
      }
    });

  },

  beforeDestroy() {
    //  console.log('destroy modal multi');
  },

  watch: {
    modals() {
      if (this.modals.length) {
        this.modalVisible = true;
      } else {
        this.modalVisible = false;
      }
    }
  }
};


/*

  Trigger Example:

  this.$bus.$emit('modalOpen', {
    name: 'yermim-modal',
    title: 'Add a Quote',
    size: 'modal-sm',
    classes: 'super-modal',
    content: 'Add a new quote',
    route: 'my-quotes',
    buttonText: 'My Quotes',
    component: 'quote-edit',
  });

*/

</script>
<style lang="scss">

@import "src/assets/sass/paper/_variables.scss";

.modal-dialog {
  @media(max-width: 991px) {
    margin: 2% auto !important;
  }
}

.modal-body {
  padding: 30px !important;
  @media(max-width: 991px) {
    padding: 10px !important;
  }
}

.modal-container {
  position: relative;
  z-index: 99
}

.modal-underlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal-multi {
  display: block !important;

}

.modal {

  max-height: 90%;
  overflow-y: scroll !important;

  @media(max-width: 991px) {
    max-height: 96%;
  }

  .modal-dialog {
    border-radius: 5px;
  }

  .modal-full {
    max-width: 2000px;
    width: 96%;
  }

  .modal-xl {
    max-width: 1400px;
    width: 96%;
  }

  .modal-l {
    max-width: 900px;
    width: 96%;
  }

  .modal-content {
    padding: 15px;
    padding: 0;

    h4 {
      margin-bottom: 10px;
    }

    .button-container {
      margin-top: 15px;

      .btn {
        margin-right: 5px;
      }
    }

    @media(max-width: 991px) {
      padding: 0;
    }
  }
}

</style>
