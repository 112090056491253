
<div>

  <button
    class="btn btn-sm"
    @click="$refs.quoteReportList.exportCSV('quote-report-list')"
    style="margin:5px 0"
  >
    Download CSV
  </button>

  <!-- <p v-if="data.length >= 200">
    <i>Showing first 200 results</i>
  </p> -->

  <tabulator-table
    :table-data="reducedData"
    :table-columns="tableColumns"
    table-fit="fitColumns"
    :table-condensed="false"
    empty-text="(none)"
    ref="quoteReportList"
    :table-bordered="true"
    :progressive-render="true"
    @colClick="$emit('colClick', $event)"
  />

  <waiting-spinner/>
</div>
