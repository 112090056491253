<template>
  <div class="material-lists-component">
    <h3><strong>RFPs</strong></h3>

    <p class="text-info text-medium">These are lists of materials to streamline obtaining material pricing from one or more vendors.</p>

    <p>
      Click on a list to select or <span class="is-link underlined" @click="openList()"><strong>Add New</strong></span>
      <span style="display: none"
        >Add materials to list by first selecting from
        <span
          class="is-link underlined"
          @click="
            $router.push({ name: 'Materials Admin' })
            $bus.$emit('modalClose', 'RFPLists')
          "
          >main list</span
        ></span
      >
    </p>

    <p v-if="selectedMaterials.length">
      <i
        >There are <strong>{{ selectedMaterials.length }}</strong> material items selected, add them to a list by selecting the plus icon within a list row.</i
      >
    </p>

    <div class="row" style="padding: 10px 0">
      <div class="col-sm-12 col-md-4">
        <div class="form-group">
          <label>List Author</label>
          <select-field
            :options="employees"
            :option-display-keys="['Employee_Name']"
            :option-val="'Employee_Code'"
            v-model="author"
            :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
            allow-clear="All"
          />
        </div>
      </div>
      <div class="col-sm-12 col-md-4">
        <label>Quote Number</label>
        <select-field :options="quoteData" :option-display-keys="['quote_number']" :option-val="'quote_number'" v-model="quoteNumber" allow-clear="Any" />
      </div>
      <div class="col-sm-12 col-md-4">
        <div class="form-group">
          <label>RFP Status</label>
          <select-field
            :options="[
              { name: 'Active', val: 'active' },
              { name: 'Sent', val: 'sent' },
              { name: 'Deleted', val: 'deleted' }
            ]"
            :option-display-keys="['name']"
            :option-val="'val'"
            v-model="rfpStatus"
            @selectItem="getLists"
            allow-clear="Any"
          />
        </div>
      </div>
    </div>

    <div class="">
      <tabulator-table :table-data="filteredLists" :table-columns="tableColumns" @colClick="tableClick" table-fit="fitColumns" :table-condensed="false" empty-text="(none)" :table-striped="true" />
    </div>
    <waiting-spinner :wait="wait" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import SelectField from 'components/UIComponents/SelectField'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import moment from 'moment'

export default {
  name: 'RFPLists',
  data() {
    return {
      wait: { message: '' },
      materialLists: [],
      type: '',
      author: '',
      quoteNumber: '',
      rfpStatus: 'active'
    }
  },

  computed: {
    ...mapGetters(['employees', 'quoteData', 'vendors']),

    tableColumns() {
      return [
        {
          field: 'id',
          visible: false,
          frozen: true
        },
        {
          title: 'RFP #',
          field: 'rfq_number',
          sorter: 'string',
          frozen: true,
          width: 100,
          cssClass: 'no-horo-border'
        },
        {
          title: 'Add to List',
          field: 'addToList',
          frozen: true,
          titleFormatter: val => {
            return ''
          },
          formatter: val => {
            return '<i class="fa fa-plus-circle" style="font-size:16px" />'
          },
          width: 26,
          minWidth: 26,
          headerSort: false,
          visible: this.selectedMaterials.length,
          cssClass: 'clickable no-horo-padding no-horo-border'
        },
        // {
        //   title: 'Edit',
        //   field: 'edit',
        //   frozen: true,
        //   titleFormatter: (val) => { return ''; },
        //   formatter: (val) => {
        //     return '<i class="ti ti-pencil" style="font-size:18px" />';
        //   },
        //   width: 26,
        //   minWidth: 40,
        //   headerSort: false,
        //   cssClass: "clickable no-horo-padding"
        // },
        {
          title: 'RFP Desc',
          field: 'name',
          sorter: 'string',
          width: 200,
          cssClass: 'no-horo-border'
        },
        {
          title: 'Quote #',
          field: 'quote_number',
          sorter: 'string',
          width: 200
        },
        {
          title: 'Quote Desc',
          field: 'quote_desc',
          sorter: 'string'
        },
        // {
        //   title: 'Date Added',
        //   field: 'added_Date',
        //   sorter: 'string',
        // },
        {
          title: 'Author',
          field: 'added_by',
          sorter: 'string',
          formatter: val => {
            const employeeCode = val.getValue()
            const obj = this.employees.find(itm => {
              return itm.Employee_Code == employeeCode
            })
            return obj && obj.Employee_Name ? obj.Employee_Name : ''
          }
        },
        {
          title: 'Vendors',
          field: 'vendor_list',
          sorter: 'string',
          cssClass: 'no-horo-border'
        },
        {
          title: 'Status',
          field: 'status',
          sorter: 'string',
          width: 100,
          formatter: val => {
            let out = ''
            switch (val.getValue()) {
              case 'active':
                out = 'Active'
                break
              case 'sent':
                out = 'Sent'
                break
              case 'deleted':
                out = 'Deleted'
                break
            }
            return out
          }
        },
        {
          title: 'Date Added',
          field: 'date_added',
          sorter: 'date',
          formatter: 'datetime',
          formatterParams: {
            inputFormat: 'X',
            outputFormat: 'MMM Do YYYY'
          }
        }
        // {
        //   title: 'Materials Added',
        //   field: 'materials_added',
        //   sorter: 'number',
        // },
        // {
        //   title: 'Vendors',
        //   field: 'vendor_code',
        //   sorter: 'string',
        // },
        // {
        //   title: 'Job Number',
        //   field: 'job_number',
        //   sorter: 'string',
        // },
        // {
        //   title: 'WO Number',
        //   field: 'wo_number',
        //   sorter: 'string',
        // },
      ]
    },

    filteredLists() {
      let lists = JSON.parse(JSON.stringify(this.materialLists))
      if (this.type) {
        lists = lists.filter(itm => {
          return itm.type === this.type
        })
      }

      if (this.author) {
        lists = lists.filter(itm => {
          return itm.added_by == this.author
        })
      }
      return lists
    }
  },

  props: {
    selectedMaterials: {
      type: Array,
      default: function () {
        return []
      }
    },
    listType: {
      type: String,
      default: ''
    }
  },

  components: {
    TabulatorTable,
    SelectField,
    WaitingSpinner
  },

  methods: {
    openList(id) {
      // doing in loop to preseve material_list arr
      let materialList = {}
      for (var i = 0; i < this.materialLists.length; i++) {
        if (this.materialLists[i].id === id) {
          materialList = Object.assign(this.materialLists[i])
        }
      }

      this.$bus.$emit('modalClose', 'RFPLists')

      this.$Modal({
        parent: this,
        name: 'RFPAddEdit2',
        size: 'xxl', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Materials/RFPAddEdit2'),
        props: {
          rfpId: id,
          materialList,
          addMaterials: [] // experimenting with removing this.selectedMaterials.length ?  this.selectedMaterials : []
        }
      })
    },

    tableClick(obj) {
      // listen to table column clicks and trigger actions
      let id = ((obj || {}).cell || {}).id || 0
      let field = ((obj || {}).cell || {}).field || 0

      //  if (field === 'edit' || field === 'rfq_number') {
      this.openList(id)
      //  }
      if (field === 'addToList') {
        this.openList(id)
      }
    },

    getLists() {
      const params = {
        action: 'get_rfps_by_conditions',
        conditionList: []
      }

      if (this.quoteNumber) {
        params.conditionList.push({
          column: 'quote_number',
          value: this.quoteNumber
        })
      }

      if (this.rfpStatus) {
        params.conditionList.push({
          column: 'status',
          value: this.rfpStatus
        })
      }

      this.$bus.$emit('setWaiting', { name: 'getLists', message: 'Getting Material Lists' })
      appFuncs
        .shHttp(params)
        .then(res => {
          if (res && res.status === 'success') {
            let lists = res.data
            for (var i = 0; i < lists.length; i++) {
              lists[i].materials_added = lists[i].material_list && lists[i].material_list.length ? lists[i].material_list.length : 0
              lists[i].rfq_number = 'RFP' + lists[i].id
              lists[i].vendor_list = ''
              for (let vi = 0; vi < this.vendors.length; vi++) {
                if ((Array.isArray(lists[i].vendor_code) ? lists[i].vendor_code : []).indexOf(this.vendors[vi].Vendor_Code) !== -1) {
                  lists[i].vendor_list += (lists[i].vendor_list ? ', ' : '') + this.vendors[vi].Vendor_Name
                }
              }
              lists[i].quote_desc = (this.quoteData.find(itm => itm.quote_number === lists[i].quote_number) || {}).description
            }
            this.materialLists = lists
          } else {
            this.$snack.open(res.message || 'Problem getting material lists', 'error')
          }
        })
        .catch(res => {
          this.$snack.open(res.message || 'Problem getting material lists', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getLists')
        })
    }
  },

  mounted() {
    ;(async () => {
      // make sure all quotes are shown
      this.$store.commit('quoteFilterStatus', [])
      const quoteFetch = this.$store.dispatch('getQuotes')
      const vendorFetch = this.$store.dispatch('getVendors')
      const employeeFetch = this.$store.dispatch('getEmployees')
      this.$bus.$emit('setWaiting', { name: 'getData', message: 'Fetching Data' })
      await Promise.all([quoteFetch, vendorFetch, employeeFetch]).then(() => {
        if (!this.author) {
          var currentUser = this.$store.getters.userAuthedData.eid
          for (var i = this.employees.length - 1; i >= 0; i--) {
            if (this.employees[i]['Employee_Code'] == currentUser) {
              this.author = this.employees[i]['Employee_Code']
              break
            }
          }
        }
        this.$bus.$emit('stopWaiting', 'getData')
        this.getLists()
      })
    })()

    this.$bus.$on(['rfpUpdated'], this.getLists)
  },

  beforeDestroy() {
    this.$bus.$off(['rfpUpdated'], this.getLists)
  },

  watch: {
    quoteNumber(val) {
      this.getLists()
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/sass/paper/_variables.scss';
</style>
