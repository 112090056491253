
<div>
  <div>
    <tabulator-table
      ref="categoryTable"
      :table-data="filteredCategories"
      :table-columns="categoryColumns"
      @colClick="categoryClick"
      table-fit="fitColumns"
      :table-condensed="false"
      empty-text="(none)"
    />
    <div v-if="editCategories && isMaterialsAdmin" class="category-add-container">
      <div class="form-group">
        <input type="text" class="form-control" v-model="newCategoryName" @keyup.enter="upsertCategory()" placeholder="Add Category" />
        <span class="is-link" @click="upsertCategory()">
          <i class="fa fa-plus" />
        </span>
      </div>
    </div>
  </div>
  <waiting-spinner :wait="wait" />
</div>
