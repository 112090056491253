
<div class="max-width-xlarge">
  <div class="tab-section white">
    <ul class="nav nav-tabs">
      <li role="presentation" :class="[{ active: tab === 'Main' }]" v-if="jobId">
        <a href="#" @click="tab = 'Main'"> Main </a>
      </li>
      <li role="presentation" :class="[{ active: tab == 'Job Extras' }]" v-if="jobId">
        <a href="#" @click="tab = 'Job Extras'">
          <span v-if="job.Price_Method_Code === 'F'">Job Extras</span>
          <span v-else>T&amp;M Sign Off</span>
        </a>
      </li>
      <li
        role="presentation"
        v-if="jobId"
        :class="[
          { active: tab === 'Information' },
          {
            'requires-item': missingSignoffRequirements.indexOf('Scope of Work Notes') !== -1 || missingSignoffRequirements.indexOf('Work Completed Notes') !== -1
          }
        ]"
      >
        <a href="#" @click="tab = 'Information'"> Info </a>
      </li>
      <li role="presentation" :class="[{ active: tab === 'Phases' }]" v-if="jobId">
        <a href="#" @click="tab = 'Phases'"> Job Analysis </a>
      </li>
      <li role="presentation" :class="[{ active: tab === 'Files' }]" v-if="jobId && isPM">
        <a href="#" @click="tab = 'Files'"> Doc's and Photos </a>
      </li>
      <li role="presentation" :class="[{ active: tab === 'Labor' }]" v-if="jobId">
        <a href="#" @click="tab = 'Labor'"> Labor </a>
      </li>
      <li role="presentation" :class="[{ active: tab === 'Contacts' }]" v-if="jobId">
        <a href="#" @click="tab = 'Contacts'"> Other Job Contacts </a>
      </li>
      <li role="presentation" :class="[{ active: tab === 'Purchasing' }]" v-if="jobId">
        <a href="#" @click="tab = 'Purchasing'"> POs </a>
      </li>
      <!-- <li role="presentation" :class="[{active: tab === 'Costing'}]">
        <a href="#" @click="tab = 'Costing'">
          Costing
        </a>
      </li> -->
      <li role="presentation" :class="[{ active: tab === 'PM' }]" v-if="jobId">
        <a href="#" @click="tab = 'PM'"> PM </a>
      </li>
    </ul>

    <div :class="['card', tab]">
      <div class="content max-width-large">
        <div v-if="tab == 'Main'">
          <div class="row">
            <div class="col-sm-12">
              <alert
                :alert="{
                  show: !this.jobPhases.length && fetchedJobPhases && jobId,
                  message: 'Please add phases to complete job setup',
                  state: 'warning',
                  dismiss: 'none'
                }"
              />
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="detail-item form-group" v-if="job.Job_Number">
                <label>Job Number</label>

                <!-- <select-field
                  v-model="jobId"
                  :options="jobAssemble"
                  :option-display-keys="['Job_Number', 'Name', 'Job_Description']"
                  :option-val="'Job_Number'"
                  :empty-option-text="'No items.'"
                  @selectItem="setGetJob"
                /> -->

                <div class="job-number-wrapper is-link" @click="selectJob">
                  <h4>{{ job.Job_Number }}</h4>
                  <i class="ti-angle-down"></i>
                </div>
              </div>

              <div class="detail-item form-group">
                <label for="job_number">Quote Number</label>&nbsp;&nbsp;<span v-if="quoteLink" class="ti ti-new-window is-link" @click="openQuote(true)" />
                <input type="text" class="form-control" :value="quoteNumber" :readonly="true" :disabled="!editable" @click="selectJobQuoteNumber" />
                <p v-if="this.job.job_quote_url" style="padding: 5px 0" class="is-link underlined" @click="openQuotePdf">View Quote PDF</p>
              </div>

              <div class="detail-item form-group">
                <label>Job Name</label><span v-if="editable" class="required-star">*</span>
                <input type="text" class="form-control" :disabled="!editable" :value="decode(job.Job_Description)" @input="job.Job_Description = $event.target.value" />
              </div>

              <!-- <div class="detail-item form-group">
                <label>Cost Center</label><span v-if="editable" class="required-star">*</span>
                <select-field :options="costCenters" :option-display-keys="['name']" :option-val="'value'" v-model="job.Cost_Center" :disabled="(!editable || jobId) ? true : false" />
              </div> -->

              <div class="form-group">
                <label>Division</label><span class="required-star">*</span>
                <select-field
                  :options="divisionCostGroupOptions"
                  :option-display-keys="['name']"
                  :option-val="'value'"
                  v-model="job.division_cost_group"
                  :disabled="!editable"
                  :allow-clear="true"
                  :empty-option-text="'None'"
                  @selectItem="job.Cost_Center = ''"
                />
              </div>

              <div class="form-group">
                <label>Department</label><span class="required-star">*</span>
                <select-field
                  :options="costCentersByDivision"
                  :option-display-keys="['Name']"
                  :option-val="'Code'"
                  v-model="job.Cost_Center"
                  :disabled="!editable"
                  :allow-clear="true"
                  :empty-option-text="'None'"
                />
              </div>

              <div class="detail-item form-group">
                <label>Customer</label><span class="required-star">*</span>
                <select-field
                  :options="customers"
                  :option-display-keys="['Name']"
                  :option-val="'Customer_Code'"
                  v-model="job.Customer_Code"
                  :empty-option-text="'No Customers Found'"
                  :filter-on-key-val="{ key: 'Status', val: 'Active' }"
                  :disabled="(jobId || setFromQuote) && job.Customer_Code ? true : false"
                  :fake-disabled="false"
                />
                <br />
                <button :class="['btn', 'btn-sm', 'btn-orange']" @click="openCustomerWarnings" v-html="'Warnings'" v-if="show_warnings" />
              </div>

              <div class="detail-item form-group">
                <label>Site</label><span class="required-star">*</span>
                <select-field
                  :options="filteredSites"
                  :option-display-keys="['Name', 'site_address']"
                  :option-val="'Ship_To_ID'"
                  v-model="job.WO_Site"
                  :empty-option-text="'No sites found'"
                  :filter-on-key-val="{ key: 'Status', val: 'A' }"
                  :disabled="jobId && loadedJob.WO_Site ? true : false"
                />
              </div>

              <div v-if="selectedSite" class="detail-item form-group">
                <label>Site Location</label>
                <p>
                  <a v-if="selectedSite" target="_blank" :href="'https://maps.google.com/?q=' + selectedSite.site_address + ' ' + selectedSite.site_city + '' + selectedSite.Ship_To_Zip_Code">
                    {{ selectedSite.site_address }}, {{ selectedSite.site_city }}, {{ selectedSite.Ship_To_Zip_Code }}</a
                  >
                </p>
              </div>
              <div class="detail-item form-group">
                <label>PO Number</label
                ><!-- <span v-if="editable" class="required-star">*</span> -->
                <br />
                <button v-if="jobId" class="btn btn-sm" @click="requestPO">Request Via Email</button>
                <input :disabled="!editable" type="text" :class="['form-control', { invalid: jobId && !job.po_number }]" v-model="job.po_number" />
                <div v-if="editable" style="padding-top: 10px">
                  <!-- <file-upload ref="poUploadComponent" :wait-for-trigger="jobId ? false : true" label="" :path="'job-uploads/' + jobId + '/'" :subdir="'po'" :zip-depth="-1" name="PO" :simple-upload="false" :upload-data="uploadData" @fileAdded="poFileAdded" /> -->
                  <job-po-upload ref="jobPOUpload" :job-id="jobId || ''" />
                </div>
              </div>

              <div class="detail-item form-group">
                <label>Price Type</label><span v-if="editable" class="required-star">*</span>
                <select-field
                  :options="globalOptions['payment_price_type']"
                  :option-display-keys="['name']"
                  :option-separator="' '"
                  :option-val="'value'"
                  v-model="job.Price_Method_Code"
                  :disabled="!editable || jobId"
                  :allow-clear="true"
                />
              </div>

              <div class="detail-item form-group" v-if="isPM">
                <label v-if="job.Price_Method_Code !== 'T'">Contract Price</label>
                <label v-else>T&amp;M Price</label>
                <span v-if="editable" class="required-star">*</span>
                <!-- <input :disabled="!editable || jobId" type="text" :class="['form-control']" v-model="job.Original_Contract" /> -->
                <vue-numeric
                  currency="$"
                  separator=","
                  :class="['form-control']"
                  :precision="2"
                  :read-only-class="'form-control readonly'"
                  v-model="job.Original_Contract"
                  :disabled="!editable || jobId || (setFromQuote && job.Original_Contract) ? true : false"
                />
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="detail-item form-group">
                <customer-contact-select-field
                  :customer-code="job.Customer_Code"
                  :label="'Requested By'"
                  :default="job.Owner_Name"
                  v-model="job.Owner_Name"
                  v-show="editable || allowRequestedByChange"
                  :required="true"
                  :output-name="true"
                  @customerContactSelect="setOwner"
                />
                <div v-if="!editable && !allowRequestedByChange">
                  <label>Requested By</label>
                  <input type="text" class="fake-readonly form-control" :value="job.Owner_Name" readonly @click="requestedByPreChange()" />
                </div>
              </div>

              <div class="detail-item form-group">
                <label>Other Job Contacts</label>
                <select-field-multi
                  :options="filteredContacts"
                  :option-display-keys="['Name']"
                  :option-val="'Id'"
                  v-model="job.job_contacts"
                  :disabled="!editable"
                  :allow-clear="true"
                  @addItem="addJobContacts"
                />
              </div>

              <div class="detail-item form-group">
                <label>Create Date</label><span v-if="editable" class="required-star">*</span>
                <date-picker disabled v-model="job.Create_Date" :pre-filled="true" />
              </div>

              <div class="detail-item form-group">
                <label v-if="job.project_stage === 'not-started'">Expected Start Date</label>
                <label v-else>Start Date</label>
                <span v-if="editable" class="required-star">*</span>
                <input
                  v-if="job.id && job.Est_Start_Date"
                  :value="formatDate(job.Est_Start_Date)"
                  class="form-control fake-readonly fake-disabled"
                  readonly
                  @click="editRequiredDate"
                  :disabled="!editable"
                />
                <date-picker v-else :disabled="!editable" v-model="job.Est_Start_Date" :pre-filled="false" />
              </div>

              <div class="detail-item form-group">
                <label>Expected Finished Date</label><span v-if="editable" class="required-star">*</span>
                <input
                  v-if="job.id && job.Est_Complete_Date"
                  :value="formatDate(job.Est_Complete_Date)"
                  class="form-control fake-readonly fake-disabled"
                  readonly
                  @click="editRequiredDate"
                  :disabled="!editable"
                />
                <date-picker v-else :disabled="!editable" v-model="job.Est_Complete_Date" :pre-filled="false" />
              </div>

              <div class="detail-item form-group">
                <label>Project Lead</label><span v-if="editable" class="required-star">*</span>
                <select-field
                  :options="employeesWithRoleFlags"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="job.Project_Manager"
                  :disabled="!editable"
                  :filter-on-key-val="{ key: 'hasPMRole', val: 'yes' }"
                />
              </div>

              <div class="detail-item form-group">
                <label>Sales Person</label>
                <select-field
                  :options="pmUsers"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="job.sales_person"
                  :disabled="!editable"
                  :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                />
              </div>

              <div class="detail-item form-group">
                <label>Captain</label><span v-if="allowCaptainEdit" class="required-star">*</span>
                <select-field
                  :options="employeesWithRoleFlags"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="job.Superintendent"
                  :disabled="!allowCaptainEdit"
                  :filter-on-key-val="{ key: 'hasCaptainRole', val: 'yes' }"
                />
              </div>

              <div class="detail-item form-group">
                <label>Estimator</label><span v-if="editable && !(quoteNumber && job.Estimator)" class="required-star">*</span>
                <select-field
                  :options="pmUsers"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="job.Estimator"
                  :disabled="!editable && quoteNumber && job.Estimator"
                  :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                />
              </div>

              <div class="detail-item form-group">
                <label>Techs</label>
                <select-field-multi
                  :options="employees"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="job.techs"
                  :disabled="!editable"
                  :placeholder-text="'Add Tech'"
                  :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                  :label="'Techs'"
                />
              </div>

              <div class="detail-item form-group">
                <span class="inline-checkbox">
                  <checkbox2 v-model="job.progress_invoice" true-val="yes" false-val="no" :disabled="!editable || !canEditDrawRequest">Draw Request - Progress Invoice</checkbox2>
                </span>
              </div>

              <div class="detail-item form-group">
                <label>Billing Agent</label>
                <select-field
                  :options="billingEmployees"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="job.billing_employee"
                  :disabled="!editable"
                  :placeholder-text="'Select'"
                  :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                  :label="'Techs'"
                />
              </div>

              <div class="detail-item form-group">
                <label>Deposit Amount</label>
                <select-field :options="globalOptions['deposit_amount']" :option-display-keys="['name']" :option-separator="' '" :option-val="'id'" :value="job.deposit_percent" :disabled="true" />
              </div>
            </div>
          </div>

          <br />

          <!-- <button v-if="!jobId" :class="['btn']" @click="addJob" v-html="'Add Job'" /> -->
          <button v-if="jobId && (editable || allowRequestedByChange) && isPM" :class="['btn', { pulse: hasUnsaved }, { 'btn-primary': hasUnsaved }]" @click="updateJob()" v-html="'Update'" />
          <button v-if="!jobId && isPM" class="btn" @click="addJob">Add Job</button>
        </div>

        <div v-else-if="tab == 'Job Extras'">
          <job-extras :job="job" :job-number="parseInt(jobId)" :is-locked="isLocked" />
          <!-- readonly is set by isLocked by parent -->
        </div>

        <div v-else-if="tab == 'Information'">
          <job-info :job-id="jobId" :job="job" :read-only="isLocked" :missing-signoff-requirements="missingSignoffRequirements" :quote-number="quoteNumber" />
        </div>

        <div v-else-if="tab == 'Phases'" :class="'phases-tab'">
          <job-phases-numbers-details :job-id="jobId" :job="job" :is-locked="isLocked" :show-details-tab="true" :show-restricted="isJobPMCaptain" />

          <!-- <job-phases-summary :job-id="jobId" :job="job" :phase-data="phaseData" :is-locked="isLocked" /> -->

          <!-- <div class="tab-section outline white">
            <ul class="nav nav-tabs">
              <li role="presentation" :class="[{active: phaseTab == 'Details'}]">
                <a href="#" @click="phaseTab = 'Details'">
                  Details
                </a>
              </li>
              <li role="presentation" :class="[{active: phaseTab == 'Summary'}]">
                <a href="#" @click="phaseTab = 'Summary'">
                  Summary
                </a>
              </li>
            </ul>
            <div :class="[{'first-active':phaseTab == 'Summary'}, 'tab', phaseTab, 'tab-content']">
              <div v-if="phaseTab === 'Details'">
                <job-phases-details :job-id="jobId" :job="job" :phase-data="phaseData" :is-locked="isLocked" />
              </div>
              <div v-if="phaseTab === 'Summary'">
                <job-phases-summary :job-id="jobId" :job="job" :phase-data="phaseData" :is-locked="isLocked" />
              </div>
            </div>
          </div> -->
        </div>

        <div v-else-if="tab == 'Files'">
          <job-files :job-id="jobId" />
        </div>

        <div v-else-if="tab == 'Purchasing'" :class="['tab', 'purchasing', 'tab-purchasing', tab]">
          <job-pos :itm-code="jobId" :job="job" :is-locked="isLocked" />
        </div>

        <div v-else-if="tab == 'Contacts'" :class="['tab', 'contacts']">
          <job-contacts :jobid="parseInt(jobId)" />
          <!--            <bh-table-->
          <!--              :table-settings="contactsTableSettings"-->
          <!--              @edit-item="editContact"-->
          <!--            />-->
        </div>

        <div v-else-if="tab == 'Labor'" :class="['tab', 'labor', 'tab-labor', tab]">
          <job-wo-labor type="Job" :itm-id="parseInt(jobId)" :is-locked="Boolean(isLocked)" :allow-download="true" />
        </div>

        <div v-else-if="tab == 'PM'" :class="['tab', 'labor', 'tab-labor', tab]">
          <job-wip-details :input-job-id="parseInt(jobId)" :job-data="job" :is-locked="isLocked" ref="jobwipdetails" />
        </div>
      </div>
    </div>

    <a
      v-if="jobSignOff && (!isSigned || isAdmin)"
      :disabled="missingSignoffRequirements.length || job.status !== 'A' ? 'disabled' : false"
      class="btn"
      v-html="'Job Sign Off'"
      :href="$store.getters.urls.frontend + '#/job-signoff/' + this.jobSignOff"
      target="_blank"
    />

    <a class="btn" v-if="ticketUrl" :href="ticketUrl" target="'_blank" v-html="'Customer Ticket'" />
    <a v-if="isPM && isLocked" class="btn" v-html="'Unlock'" @click="unLockJob" />
    <!--
    <a
      v-if="isPM"
      class="btn"
      v-html="'Phases'"
      @click="jobPhases"
    /> -->

    <!-- <p v-if="isLocked" class="text-left">
      <i>Job locked (Stage not TBS or In Progress)</i>
    </p> -->

    <p v-if="job.status === 'I'">
      <i><strong>Job is marked Inactive, if this is a new job it may be awaiting approval.</strong></i>
    </p>
    <p v-else-if="missingSignoffRequirements.length">
      <i
        >Sign off requirements not yet met: <strong class="text-danger">{{ missingSignoffRequirements.join(', ') }}</strong></i
      >
    </p>
  </div>
  <waiting-spinner />
</div>
