
<div class="">
  <set-view-as-employee v-if="isAdmin" />
  <br />

  <!-- needs to support providing job/wo number <div v-if="isPM">
    <button :class="'btn'" @click="addTask">Add Task</button>
    <br /><br />
  </div> -->

  <div class="tab-section white">
    <ul class="nav nav-tabs">
      <li role="presentation" :class="[{ active: tab == 'my' }]">
        <a href="#" @click="tab = 'my'"> My Tasks </a>
      </li>
      <li role="presentation" :class="[{ active: tab == 'all' }]" v-if="isAdmin">
        <a href="#" @click="tab = 'all'"> All Tasks </a>
      </li>
    </ul>

    <div :class="['card', { Main: tab == 'my' }]">
      <div class="content">
        <div class="row report-filters">
          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Billing Task</label>
            <select-field
              :option-display-keys="['name']"
              :option-seperator="' '"
              :option-val="'value'"
              v-model="filterTaskType"
              :allow-clear="true"
              :options="billingTasks"
              @selectItem="setTable()"
            />
          </div>
          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Approval Status</label>
            <select-field
              :option-display-keys="['name']"
              :option-seperator="' '"
              :option-val="'value'"
              v-model="filterApprovalStatus"
              :allow-clear="true"
              :options="approvalStatuses"
              @selectItem="setTable()"
            />
          </div>
          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Billing Task Status</label>
            <select-field
              :option-display-keys="['name']"
              :option-seperator="' '"
              :option-val="'value'"
              v-model="filterBillingTaskStatus"
              :allow-clear="true"
              :options="billingTaskStatusOptions"
              @selectItem="setTable()"
            />
          </div>
          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Billing Status</label>
            <select-field :option-display-keys="['name']" :option-val="'value'" v-model="filterTaskStatus" :allow-clear="true" :options="statusOptions" @selectItem="setTable()" />
          </div>

          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Customer</label>
            <select-field
              :options="customers"
              :option-display-keys="['Name']"
              :option-val="'Customer_Code'"
              @selectItem="(clearAllFilters('filterCustomer'), setTable())"
              v-model="filterCustomer"
              :allow-clear="true"
            />
          </div>
          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Project Lead</label>
            <select-field
              :options="employeesWithRoleFlags"
              :option-display-keys="['Employee_Name']"
              :option-val="'Employee_Code'"
              v-model="filterPM"
              :filter-on-key-val="{ key: 'hasPMRole', val: 'yes' }"
              :allow-clear="true"
              @selectItem="setTable()"
            />
          </div>
        </div>

        <div class="row report-filters">
          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Captain</label>
            <select-field
              :options="employeesWithRoleFlags"
              :option-display-keys="['Employee_Name']"
              :option-val="'Employee_Code'"
              v-model="filterCaptain"
              :filter-on-key-val="{ key: 'hasCaptainRole', val: 'yes' }"
              :allow-clear="true"
              @selectItem="setTable()"
            />
          </div>

          <div class="col-sm-12 col-md-3 col-lg-2">
            <region-filter v-model="filterRegion" @selectItem="setTable()" />
          </div>

          <!-- <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Stage</label>
            <select-field :options="jobStages" :label-text="'Stage'" :option-display-keys="['description']" :option-val="'code'" v-model="filterStage" :allow-clear="true" @selectItem="setTable()" />
          </div>

          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Department</label>
            <select-field :options="costCenters" :option-display-keys="['name']" :option-val="'value'" v-model="filterCostCenter" :allow-clear="true" @selectItem="setTable()" />
          </div> -->

          <div class="col-sm-12 col-md-3 col-lg-2" v-if="tab === 'all'">
            <label>Assigned To</label>
            <select-field :options="assignedToList" :option-display-keys="['Employee_Name']" :option-val="'Employee_Code'" v-model="filterAssignedTo" :allow-clear="true" @selectItem="setTable()" />
          </div>

          <div class="col-sm-12 col-md-3 col-lg-2">
            <label>Jobs/WOs</label>
            <select-field
              :options="[
                { name: 'Jobs', value: 'jobs' },
                { name: 'WOs', value: 'wos' }
              ]"
              :option-display-keys="['name']"
              :option-val="'value'"
              v-model="filterType"
              :allow-clear="true"
              @selectItem="setTable()"
            />
          </div>
          <div v-if="filterType === 'jobs' && tab !== 'my'" class="col-sm-12 col-md-3 col-lg-2">
            <label>Jobs</label>
            <select-field
              v-model="filterJob"
              :options="jobAssemble"
              :option-display-keys="['Job_Number', 'Name', 'Job_Description']"
              :option-val="'Job_Number'"
              :empty-option-text="'No items.'"
              @selectItem="(clearAllFilters('filterJob'), setTable())"
              :allow-clear="true"
            />
          </div>
          <div v-if="filterType === 'wos' && tab !== 'my'" class="col-sm-12 col-md-3 col-lg-2">
            <label>WOs</label>
            <select-field
              v-model="filterWO"
              :options="woAssemble"
              :option-display-keys="['WO_Number', 'Name', 'Summary_Description']"
              :option-val="'WO_Number'"
              :empty-option-text="'No items.'"
              @selectItem="(clearAllFilters('filterWO'), setTable())"
              :allow-clear="true"
            />
          </div>
        </div>

        <!-- <button class="btn btn-sm" @click="downloadCSV">Download CSV</button> -->

        <p v-if="errorText" class="text-danger">{{ errorText }}</p>

        <tabulator-table
          ref="billingListTable"
          :table-data="tableData"
          :table-columns="tableColumns"
          table-fit="fitColumns"
          :table-condensed="false"
          empty-text="(none)"
          :data-tree="false"
          :row-formatter="rowFormatter"
          @colClick="colClick"
          Xmax-table-height="500px"
        />
      </div>
    </div>
  </div>

  <waiting-spinner />
</div>
