<template>
  <div class="tab-section white">
    <div class="content">
      <div class="card Main" style="padding: 15px">
        <div class="row" style="margin-bottom: 10px">
          <div class="col-sm-6 col-md-3">
            <div class="form-group">
              <label>Status</label>
              <select-field
                :options="statuses"
                :option-display-keys="['name']"
                :option-val="'id'"
                v-model="status"
                :default-value="status"
                placeholder="Select Status"
                :allow-clear="true"
                @selectItem="selectStatus"
              />
            </div>
          </div>
        </div>
        <div class="">
          <alert :alert="alert" />
          <bh-table :table-settings="tableSettings" @edit-item="editItem" />
          <waiting-spinner :wait="wait" />
        </div>
      </div>
    </div>

    <waiting-spinner />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BhTable from 'components/UIComponents/BhTable'
import EmployeeEdit from 'src/components/Dashboard/Contacts/EmployeeEdit.vue'
import Alert from 'components/UIComponents/Alert'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import SelectField from 'components/UIComponents/SelectField.vue'
import dateFormat from 'dateformat'
import appFuncs from 'appFuncs'

export default {
  components: { SelectField, BhTable, Alert, WaitingSpinner },

  props: {
    region: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      statuses: [
        { id: 'A', name: 'Active' },
        { id: 'I', name: 'Inactive' }
      ],
      status: 'A',

      alert: {},
      wait: { message: '' },
      employeeList: [], // component local
      allData: [],
      tableSettings: {
        tableData: [],
        fields: {
          Employee_Code: {
            type: 'text',
            hiddenView: 1
          },
          Employee_Name: {
            name: 'Employee Name'
          },
          Cycle_Star_End: {
            name: 'Cycle Start/End Date'
          },
          Boot_Amt_Spent: {
            name: 'Boot Amount Spent'
          },
          extra_boot_allowance: {
            name: 'Boot Extra Allow'
          },
          Tool_Amt_Spent: {
            name: 'Boot Amount Spent'
          },
          extra_tool_allowance: {
            name: 'Tool Extra Allow'
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters(['employees', 'regions', 'appOptions', 'expenses'])
  },

  watch: {
    status() {
      this.statusFilter()
    }
  },

  methods: {
    statusFilter() {
      this.tableSettings.tableData = this.allData.filter(itm => {
        return itm.Employment_Status === this.status
      })
    },
    async getData() {
      this.wait.message = 'Getting employee list'
      await this.$store.dispatch('getAppOptions')
      this.$store
        .dispatch('getEmployees')
        .then(async () => {
          this.employeeList = [...this.employees]
          let elements = []
          for (const element of this.employeeList) {
            element.extra_boot_allowance = this.formatNumber(element.extra_boot_allowance, 1, false)
            element.extra_tool_allowance = this.formatNumber(element.extra_tool_allowance, 1, false)

            let start_date = ''
            let end_date = ''
            if (element.hire_date != undefined && element.hire_date != '' && element.hire_date != null) {
              let res = this.calculateCycleDates(element.hire_date, this.appOptions.expense_cycle_months)
              end_date = `- ${this.formatDate(res.end_date)}`
              start_date = this.formatDate(res.start_date)
            }
            element.Cycle_Star_End = `${start_date} ${end_date}`

            let boot_amount = 0
            let tool_amount = 0
            if (element.expenses != undefined) {
              for (const ele of element.expenses) {
                if (ele.GL_Distribution_Account == 6084) {
                  boot_amount += Number.parseInt(ele.Payment_Amount)
                }
                if (ele.GL_Distribution_Account == 6081) {
                  tool_amount += Number.parseInt(ele.Payment_Amount)
                }
              }
            }

            element.Boot_Amt_Spent = boot_amount
            element.Tool_Amt_Spent = tool_amount

            elements.push(element)
          }
          this.allData = elements
          this.statusFilter()
        })
        .catch(res => {
          this.alert = {
            show: 1,
            message: res.message || 'Problem getting employee data',
            state: 'danger',
            dismiss: 'manual'
          }
        })
        .finally(() => {
          this.wait.message = ''
        })
    },
    editItem(employeeCode) {
      this.$Modal({
        parent: this,
        name: 'EmployeeEdit',
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: EmployeeEdit,
        props: {
          employeeCode
        }
      })
    },
    selectStatus(status) {},
    async getExpense(vendor_code) {}
  },

  mounted() {
    this.getData()
    this.$bus.$on('EmployeeEdit', () => {
      this.getData()
    })

    this.$bus.$on('openNumber', obj => {
      location.href = `tel:${obj.Employee_Mobile_Phone}`
    })

    this.$bus.$on('openEmail', obj => {
      location.href = `mailto:${obj.Employee_Email}`
    })
  }
}
</script>

<style lang="scss" scoped></style>
