
<div class="form-group" v-click-outside="closeField">
  <label> {{ label }}<span v-if="isRequired" class="required-star">*</span> </label>
  <input v-if="!noInput" type="text" class="form-control box-add-item select-field" v-model="selected.Name" :disabled="disabled" readonly @click="openDropdown" :placeholder="searchText" readonly />
  <p v-else v-html="selected.Name" />
  <div v-if="showOptions" class="option-box-parent">
    <div class="option-box">
      <input type="text" class="form-control company-filter" v-model="companyFilter" placeholder="Search" />
      <div class="option-items show-scrollbar">
        <div v-if="allowClear" class="option-item empty-option-item" @click="selectEl('')">
          <span><span v-html="typeof allowClear === 'string' ? allowClear : 'CLEAR'" /></span>
        </div>
        <div v-for="(customer, key) in filteredCustomers" :key="key" :class="'option-item'" @click="selectEl(customer.Customer_Code)">
          {{ customer.Name }}
        </div>
        <div v-if="!customers || !customers[0]">
          <i>Fetching customers...</i>
        </div>
        <div v-if="customers && customers[0] && !filteredCustomers[0]">
          <i>No results</i>
        </div>
      </div>
      <span v-if="allowAddNew" class="add-new" @click="addNewCustomer"> Add New </span>
    </div>
  </div>
</div>
