
<div>
  <h4 v-if="editIdMutate">Edit Customer Contact</h4>
  <h4 v-else>Add Customer Contact</h4>

  <div class="row">
    <div class="col-sm-12">
      <customer-contacts-output-matches
        :first-name="formVals.FirstName"
        :last-name="formVals.LastName"
        :email="formVals.Email"
        :phone-direct="formVals.Phone"
        :phone-mobile="formVals.MobilePhone"
        :currently-selected-id="formVals.Id"
      />
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-12">
      <customer-select-field v-model="formVals.Account_Customer_Code__c" />

      <div class="form-group">
        <label> Customer Code<span class="required-star"> * </span> </label>
        <input type="text" class="form-control" v-model="formVals.Account_Customer_Code__c" readonly />
      </div>

      <div class="form-group">
        <label>Site</label><span class="required-star">*</span>
        <select-field
          :options="woSitesFiltered"
          :option-display-keys="['Ship_To_Name', 'site_address']"
          :option-val="'sfId'"
          v-model="formVals.Account_Site__c"
          @change="checkWOSiteChanged"
          :filter-on-key-val="{ key: 'Status', val: 'A' }"
        />
        <p v-if="woSiteMissingSFId" class="text-danger" style="padding: 10px 5px">This Site cannot be assigned due to missing reference in Salesforce.</p>
      </div>

      <div class="form-group">
        <label>Status</label>
        <input type="text" class="form-control" v-model="formVals.Status" readonly />
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label> First Name<span class="required-star"> * </span> </label>
        <input type="text" class="form-control" v-model="formVals.FirstName" />
      </div>

      <div class="form-group">
        <label> Last Name<span class="required-star"> * </span> </label>
        <input type="text" class="form-control" v-model="formVals.LastName" />
      </div>

      <div class="form-group">
        <label>Title</label>
        <input type="text" class="form-control" v-model="formVals.Title" />
      </div>

      <div class="form-group">
        <label>Direct Phone</label>
        <input type="text" class="form-control" v-model="formVals.Phone" v-mask="chooseMask(formVals.Phone)" />
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label>Direct Phone Ext</label>
        <input type="text" class="form-control" v-model="formVals.Phone_Extension__c" />
      </div>

      <div class="form-group">
        <label>Mobile Phone</label>
        <input type="text" class="form-control" v-model="formVals.MobilePhone" v-mask="chooseMask(formVals.MobilePhone)" />
      </div>

      <div class="form-group">
        <label>Email</label>
        <input type="text" class="form-control" v-model="formVals.Email" />
      </div>

      <div class="form-group">
        <label>Role</label><span class="required-star">*</span>
        <select-field :options="roles" :option-display-keys="['name']" :option-val="'name'" v-model="formVals.Contact_Role__c" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-4">
      <div class="form-group">
        <span class="inline-checkbox">
          <checkbox2 v-model="formVals.AR_Contact__c" :true-val="true" :false-val="false">Default AR Contact</checkbox2>
        </span>
      </div>
    </div>
  </div>

  <br /><br />

  <button type="submit" class="btn" @click="save" v-if="contactSFAccount && contactSFAccount.sfId">Save</button>
  <p v-else-if="formVals.Account_Customer_Code__c" class="text-danger text-medium">
    Cannot add/update contact due to missing reference for selected company in Salesforce. If this customer was just added, please wait a few minutes and try again.
  </p>

  <button v-if="action == 'edit' && !formVals.Inactive__c" type="submit" class="btn" @click="updateStatus(true)">Mark Inactive</button>

  <button v-if="action == 'edit' && formVals.Inactive__c" type="submit" class="btn" @click="updateStatus(false)">Mark Active</button>

  <waiting-spinner />
</div>
