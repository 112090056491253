
<div class="sh-widget card">
  <div class="content">
    <h4>Jobs Waiting for Signature</h4>
    <table @click="gotoTable">
      <tr>
        <th>Age (Days)</th>
        <th>Jobs</th>
      </tr>
      <tr>
        <td>0 - 3</td>
        <td :class="[{ green: jobAge1 }]">
          {{ jobAge1 }}
        </td>
      </tr>
      <tr>
        <td>4 - 5</td>
        <td :class="[{ yellow: jobAge2 }]">
          {{ jobAge2 }}
        </td>
      </tr>
      <tr>
        <td>5 +</td>
        <td :class="[{ red: jobAge3 }]">
          {{ jobAge3 }}
        </td>
      </tr>
    </table>
    <waiting-spinner :contained="true" />
  </div>
</div>
