<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <button
          :class="'btn'"
          @click="addEditItem(0)"
          v-html="'Add New'"
        />
        <bh-table :table-settings="tableSettings" @edit-item="addEditItem"/>
      </div>
    </div>
    <waiting-spinner :wait="wait"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import BhTable from 'components/UIComponents/BhTable';
import VendorContactsAddEdit from 'components/Dashboard/Contacts/VendorContactsAddEdit';
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'VendorContactList2',
  data() {
    return {
      wait: {
        message: ''
      },
      tableSettings: {
        tableData: [],
        fields: {
          'Vendor_Code': {
            name: 'Vendor Code',
            hidden: 1
          },
          'Vendor_Name': {
            name: 'Vendor Name',
            style: "maxWidth:200px;minWidth:200px"
          },
          'Name': {
            name: 'Name',
            style: "maxWidth:200px;minWidth:200px"
          },
          'Title': {
            name: 'Title',
            style: "maxWidth:200px;minWidth:200px"
          },
          'Mobile': {
            name: 'Mobile'
          },
          'Email': {
            name: 'Email'
          },
          'Direct_Line': {
            name: 'Direct Line'
          },
          'Extension': {
            name: 'Ext'
          },
          'Attributes': {
            name: 'Attributes'
          },
          'APCheck': {
            name: 'AP',
          }
        },
        tableLayout: 'auto',
        noItemEdit: false,
        hideSearch: false,
        preFiltered: ''
      },
    };
  },

  components: {
    BhTable,
    WaitingSpinner
  },

  props: {
    selectedVendor: {
      type: [String, Number],
      default: ''
    }
  },

  computed: {
    ...mapGetters([
      'vendors',
      'vendorContacts'
    ]),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1;
    },
  },

  methods: {
    addEditItem(id) {
      this.$Modal({
        parent: this,
        name: 'VendorContactsAddEdit', // used for closing specific modal programmatically
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: VendorContactsAddEdit,
        props: {
          id,
          selectedVendor: this.selectedVendor
        }
      });
    },

    getContacts(refresh) {
      this.$bus.$emit('setWaiting', {name: 'getVendorContacts', message: 'Getting Vendor Contacts'});
      this.$store.dispatch('getVendorContacts', refresh).then(() => {
        for (var i = this.vendorContacts.length - 1; i >= 0; i--) {
          this.vendorContacts[i].Name = this.vendorContacts[i].First_Name + ' ' + this.vendorContacts[i].Last_Name;
          this.vendorContacts[i].APCheck = this.vendorContacts[i].AP == 1 ? '<i data-filterby="a" class="ti-check text-success" style="font-size:larger"></i>' : '<span data-filterby="b"></span>';
        }
        this.tableSettings.tableData = this.vendorContacts;
        this.$bus.$emit('bhUpdate');
      }).finally(() => {
        this.$bus.$emit('stopWaiting', 'getVendorContacts');
      });
    }
  },

  mounted() {
    this.$bus.$emit('setWaiting', {name: 'getVendors', message: 'Getting Vendors'});
    this.$store.dispatch('getVendors').then(() => {
    }).catch((res) => {
      this.$snack.open('Problem getting vendor data', 'error');
    }).finally(() => {
      this.$bus.$emit('stopWaiting', 'getVendors');
    });
    this.getContacts();
    this.$bus.$on('vendorContactUpdate', () => {
      this.getContacts('refresh');
    });

    this.tableSettings.preFiltered = {
      Vendor_Code: this.selectedVendor
    };
  },

};

</script>

<style lang="scss" scoped>

.btn {
  margin: 10px 0 20px;
}

</style>
