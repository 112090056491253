<template>
  <div>
    <div class="sh-widget wide-full card">
      <div class="">
        <div class="content">
          <div class="widget-header">
            <h4>Hours table ({{ count }})</h4>
            <button class="btn btn-sm" @click="downloadCSV">Download CSV</button>
          </div>
          <div class="content-inner show-scrollbars">
            <tabulator-table :table-data="tableDataHours" :table-columns="tableColumnsHours" :table-condensed="false"
              empty-text="No results" :selectable="false" ref="hoursDataTable" max-table-height="600px"
              :table-striped="true" :initial-sort="initialSort" />
          </div>
        </div>
      </div>
      <waiting-spinner :contained="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import snack from 'components/UIComponents/Snackbar'
import appFuncs from 'appFuncs'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
export default {
  components: {
    TabulatorTable,
    WaitingSpinner
  },

  data() {
    return {
      tableDataHours: [],
      initialSort: [
        { column: 'Employee_Name', dir: 'asc' },
      ]
    }
  },

  computed: {
    ...mapGetters(['viewAsEmployeeId', 'fiscalYear', 'selectedDepartment']),

    isAdmin() {
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    },

    tableColumnsHours() {
      return [
        {
          title: 'Employee Name',
          field: 'Employee_Name',
          width: 160,
          headerSort: true,
          cssClass: 'is-link',
          formatter: row => {
            const data = row.getData()
            return `<div style="display: flex; gap: 20px;">
              <div>${data.Employee_Name.trim()}</div>
            </div>`
          }
        },
        {
          title: 'Safety Hours',
          field: 'Safety_Hours',
          width: 180,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Safety_Hours)}`
        },
        {
          title: 'Safety Costs',
          field: 'Safety_Costs',
          width: 180,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Safety_Costs, 2)}`
        },
        {
          title: 'Quoting Hours',
          field: 'Quoting_Hours',
          width: 180,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Quoting_Hours)}`
        },
        {
          title: 'Quoting Costs',
          field: 'Quoting_Costs',
          width: 180,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Quoting_Costs, 2)}`
        },
        {
          title: 'Unbillable Hours',
          field: 'Unbillable_Hours',
          width: 180,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Unbillable_Hours)}`
        },
        {
          title: 'Unbillable Costs',
          field: 'Unbillable_Costs',
          width: 180,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Unbillable_Costs, 2)}`
        },
        {
          title: 'Billable Hours',
          field: 'Billable_Hours',
          width: 180,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Billable_Hours)}`
        },
        {
          title: 'Billable Costs',
          field: 'Billable_Costs',
          width: 180,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Billable_Costs, 2)}`
        },
        {
          title: 'Total Hours',
          field: 'Total_Hours',
          width: 140,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Total_Hours)}`
        },
        {
          title: 'Quoting Unbillable Hours',
          field: 'Quoting_Unbillable_Hours',
          width: 240,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Quoting_Unbillable_Hours)}`
        },
        {
          title: 'Total Labor Cost',
          field: 'Total_Labor_Cost',
          width: 220,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Total_Labor_Cost, 2)}`
        },
        {
          title: 'Labour Burden',
          field: 'Labour_Burden',
          width: 180,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Labour_Burden, 2)}`
        },
        {
          title: 'Vacation Hours',
          field: 'Vacation_Hours',
          width: 180,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Vacation_Hours)}`
        },
        {
          title: 'Holiday Hours',
          field: 'Holiday_Hours',
          width: 140,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Holiday_Hours)}`
        },
        {
          title: 'Sick Hours',
          field: 'Sick_Hours',
          width: 180,
          headerSort: true,
          formatter: row => `${this.formatNumber(row.getData().Sick_Hours)}`
        },
        {
          title: 'Region',
          field: 'Region',
          width: 100,
          headerSort: true
        }
      ]
    },

    count() {
      return this.tableDataHours && this.tableDataHours.length ? this.tableDataHours.length : 0
    },

    isPM() {
      return this.userAuthedData.user_role.indexOf('pm') !== -1
    }
  },

  methods: {
    async getHoursStats() {
      try {
        if (!this.fiscalYear) {
          return
        }

        if (!this.isAdmin && !this.viewAsEmployeeId) {
          return
        }

        this.$bus.$emit('setWaiting', { name: 'getHoursStats', message: 'Getting hours stats...' })
        const res = await appFuncs.shHttp({
          action: 'get_employee_hours_data',
          employee_code: this.viewAsEmployeeId,
          fiscalYear: this.fiscalYear,
          cost_group_code: this.selectedDepartment
        })
        this.tableDataHours = res.data
        this.redrawHoursTable()
      } catch (error) {
        snack.open('Problem getting hours stats', 'error')
      } finally {
        this.$bus.$emit('stopWaiting', 'getHoursStats')
      }
    },

    async getData() {
      this.getHoursStats()
    },

    redrawHoursTable() {
      if (this.$refs.hoursDataTable?.table) {
        this.$refs.hoursDataTable.updateTable()
      }
    },

    downloadCSV() {
      if (this.$refs.hoursDataTable) {
        this.$refs.hoursDataTable.exportCSV('HoursData')
      }
    }
  },

  mounted() { },

  watch: {
    viewAsEmployeeId: {
      handler() {
        this.getData()
      },
      immediate: true
    },
    fiscalYear: {
      handler() {
        this.getData()
      },
      immediate: true
    },
    selectedDepartment: {
      handler() {
        this.getData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.sh-widget {
  max-width: 100%;

  overflow: hidden;

  .content {
    height: 100%;

    .widget-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        margin: 10px 0;
        font-size: 18px;
        font-weight: bold;

        i {
          color: $primary-color;
          float: right;
        }
      }
    }

    .content-inner {
      overflow: scroll !important;
      height: calc(100% - 46px);
    }
  }

  @media (min-width: 900px) {}
}
</style>
