
<div class="checkbox-container" :class="[classes]" @click="toggle()">
  <div class="checkbox-group" :style="checked ? color && `background-color: ${color}; border-color: ${color};` : null">
    <div class="checkbox-ripple-container">
      <input type="checkbox" :id="id || uniqueId" :name="name" :value="checkboxValue" :disabled="disabled" :required="required" :color="color" :checked="checked"/>
    </div>
  </div>
  <label class="checkbox_label" :for="id || uniqueId" @click.prevent>
    <slot/>
  </label>
</div>
