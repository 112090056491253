
<div>
  <div class="medium">
    <div class="content">
      <div class="time-entry-form">
        <div clas="Xrow" v-if="!isPosted && approved === 'yes'">
          <div class="Xcol-sm-12">
            <p class="text-success">This entry has been approved and is no longer editable.</p>
          </div>
          <br />
        </div>
        <div clas="Xrow" v-if="approved === 'deleted' || approved === 'no'">
          <div class="Xcol-sm-12">
            <p class="text-danger">This entry has been rejected and is no longer editable.</p>
          </div>
          <br />
        </div>
        <div clas="Xrow" v-else-if="isPosted">
          <div class="Xcol-sm-12">
            <p class="text-danger">This entry has been sent to Spectrum and can no longer be edited from here.</p>
          </div>
          <br />
        </div>
        <div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label>Employee</label>
                <select-field
                  :options="filteredEmployees"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="formVals.employee_code"
                  :allow-clear="true"
                  :preselect-single-option="false"
                  :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                  @selectItem="selectEmployee"
                  :disabled="locked || formVals.id"
                />
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label>Subcontractor (Job Only)</label>
                <select-field
                  :options="subcontractors"
                  :option-display-keys="['Name']"
                  :option-val="'id'"
                  v-model="formVals.subcontractor_code"
                  :disabled="formVals.department !== 'JOB' || locked || formVals.id"
                  :allow-clear="true"
                  :allow-add-item="true"
                  @addItem="addSubcontractor"
                  :preselect-single-option="false"
                  @selectItem="selectSubcontractor"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label>Entry Date(s)</label>
                <date-picker
                  v-model="selectedDates"
                  :min-date="minMaxDates.minDate"
                  :max-date="minMaxDates.maxDate"
                  :format="'date'"
                  :is-inline="false"
                  :pre-filled="false"
                  :mode="'multiple'"
                  ref="datePicker"
                  :disabled="locked"
                  :attributes="dateSelectAttrs"
                  :sel-attrs="{
                    contentStyle: { color: '#555', highlight: '#dae0fe' }
                  }"
                />
              </div>
              <p v-if="warningMessage" class="alert alert-warning">
                {{ warningMessage }}
              </p>
            </div>
            <!-- <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <label>Non Payroll Entry</label>
                <checkbox v-model="formVals.non_payroll" :disabled="formVals.department !== 'JOB' || (formVals.subcontractor_code ? true : false) || locked" />
              </div>
            </div> -->
          </div>

          <div class="inline-radio-container">
            <label>Department:</label>
            <div class="inline-radios">
              <label v-if="isDefaultDepartmentAdmin" :class="['radio-inline', { selected: formVals.department == 'ADMIN' }]">
                ADMIN
                <input type="radio" class="border-input" v-model="formVals.department" value="ADMIN" :disabled="locked || !authorEditing" />
              </label>
              <label v-if="!isDefaultDepartmentAdmin" :class="['radio-inline', { selected: formVals.department == 'JOB' }]">
                JOB
                <input type="radio" class="border-input" v-model="formVals.department" value="JOB" :disabled="locked || !authorEditing" />
              </label>

              <label v-if="!isDefaultDepartmentAdmin" :class="['radio-inline', { selected: formVals.department == 'WO' }]">
                WO
                <input type="radio" class="border-input" v-model="formVals.department" value="WO" :disabled="locked || !authorEditing" />
              </label>

              <label :class="['radio-inline', { selected: formVals.department == 'O' }]">
                Other
                <input type="radio" class="border-input" v-model="formVals.department" value="O" :disabled="locked || !authorEditing" />
              </label>
            </div>
            <div class="inline-radios">
              <label v-if="!isDefaultDepartmentAdmin" :class="['radio-inline', { selected: formVals.department == 'Unbillable' }]">
                Unbillable
                <input type="radio" class="border-input" v-model="formVals.department" value="Unbillable" :disabled="locked || !authorEditing" />
              </label>

              <label v-if="!isDefaultDepartmentAdmin" :class="['radio-inline', { selected: formVals.department == 'Safety' }]">
                Safety
                <input type="radio" class="border-input" v-model="formVals.department" value="Safety" :disabled="locked || !authorEditing" />
              </label>

              <label :class="['radio-inline', { selected: formVals.department == 'Quoting' }]">
                Quoting
                <input type="radio" class="border-input" v-model="formVals.department" value="Quoting" :disabled="locked || !authorEditing" />
              </label>

              <label v-if="isDefaultDepartmentAdmin" :class="['radio-inline', { selected: formVals.department == 'JOB' }]">
                JOB
                <input type="radio" class="border-input" v-model="formVals.department" value="JOB" :disabled="locked || !authorEditing" />
              </label>

              <label v-if="isDefaultDepartmentAdmin" :class="['radio-inline', { selected: formVals.department == 'WO' }]">
                WO
                <input type="radio" class="border-input" v-model="formVals.department" value="WO" :disabled="locked || !authorEditing" />
              </label>
            </div>
          </div>

          <div v-if="formVals.department == 'O'" class="inline-radio-container">
            <div class="inline-radios">
              <label :class="['radio-inline', { selected: formVals.departmentOther == 'V' }]">
                Vacation
                <input type="radio" class="border-input" v-model="formVals.departmentOther" value="V" :disabled="locked || !authorEditing" />
              </label>

              <label :class="['radio-inline', { selected: formVals.departmentOther == 'H' }]">
                Stat Holiday
                <input type="radio" class="border-input" v-model="formVals.departmentOther" value="H" :disabled="locked || !authorEditing" />
              </label>

              <label :class="['radio-inline', { selected: formVals.departmentOther == 'S' }]">
                Sick
                <input type="radio" class="border-input" v-model="formVals.departmentOther" value="S" :disabled="locked || !authorEditing" />
              </label>

              <label :class="['radio-inline', { selected: formVals.departmentOther == 'B' }]">
                Bereavement
                <input type="radio" class="border-input" v-model="formVals.departmentOther" value="B" :disabled="locked || !authorEditing" />
              </label>
            </div>
          </div>
          <div v-if="['JOB', 'WO', 'ADMIN'].includes(formVals.department)" class="inline-radio-container">
            <label>Pay Type:</label>
            <div class="inline-radios">
              <label :class="['radio-inline', 'wide', { selected: formVals.pay_type == 'R' }]">
                Regular
                <input type="radio" class="border-input" v-model="formVals.pay_type" value="R" :disabled="locked" />
              </label>

              <label :class="['radio-inline', { selected: formVals.pay_type == 'O', wide: formVals.department === 'ADMIN' }]">
                Over Time
                <input type="radio" class="border-input" v-model="formVals.pay_type" value="O" :disabled="locked" />
              </label>

              <label v-if="formVals.department !== 'ADMIN'" :class="['radio-inline', { selected: formVals.pay_type == 'D' }]">
                Double Time
                <input type="radio" class="border-input" v-model="formVals.pay_type" value="D" :disabled="locked" />
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col col-sm-12 col-md-4" v-if="formVals.department && !['O', 'Unbillable', 'Safety', 'ADMIN'].includes(formVals.department)">
              <div class="form-group">
                <label>Filter by Customer</label>
                <select-field :options="customers" :option-display-keys="['Name']" :option-val="'Customer_Code'" v-model="selectedCustomer" :allow-clear="true" />
              </div>
            </div>

            <div class="col col-sm-12 col-md-2" v-if="formVals.department == 'Quoting'">
              <label>My Quotes</label>
              <checkbox2 v-model="assignedQuotes"></checkbox2>
            </div>

            <div class="col col-sm-12 col-md-6">
              <div v-if="formVals.department == 'JOB'" class="form-group">
                <label>Job:</label>
                <div class="job-number-input-group">
                  <!-- <div class="job-number-input"> -->

                  <select-field
                    v-model="formVals.job_number"
                    :options="filteredJobs"
                    :option-display-keys="['Job_Number', 'Name', 'Job_Description']"
                    :option-val="'Job_Number'"
                    :empty-option-text="'No items.'"
                    :inline="false && inlineSelectItems && !formVals.job_number"
                    :disabled="locked || !authorEditing"
                  />

                  <!-- <select class="form-control" name="job_number" id="job_number" v-model="formVals.job_number">
                      <option selected disabled>Please Select</option>
                      <option v-for="job in jobInfo.data" v-bind:value="job.Job_Number"><span v-html="job.Job_Number + ' - ' + job.Customer_Name + ' - ' + job.Job_Description"></span></option>
                      <option v-if="!jobNumbers.success">{{jobNumbers.message}}</option>
                    </select> -->

                  <!--  </div>
                  <button class="btn btn-small job-search-button" v-on:click="showJobInfo($event)">Search</button> -->
                </div>
              </div>

              <div v-if="formVals.department == 'WO'" class="form-group">
                <label for="job_number">WO</label>
                <div class="job-number-input-group">
                  <!-- <div class="job-number-input"> -->
                  <select-field
                    v-model="formVals.work_order_number"
                    :options="filteredWOs"
                    :option-display-keys="['WO_Number', 'customer_name', 'Summary_Description']"
                    :option-val="'WO_Number'"
                    :empty-option-text="'No items.'"
                    :inline="false && inlineSelectItems && !formVals.work_order_number"
                    :disabled="locked || !authorEditing"
                  />
                  <!-- </div>
                  <button class="btn btn-small job-search-button" v-on:click="showWoInfo($event)">Search</button> -->
                </div>
              </div>

              <div v-if="formVals.department == 'Quoting' || isQuotingJobNumber" class="form-group">
                <div class="form-group">
                  <label>Quote Fiscal Year</label>
                  <select-field :options="getQuoteYears()" v-model="showFiscalYear" :option-display-keys="['name']" :option-val="'val'" @selectItem="val => changeFiscalYear(val)" />
                </div>
                <div class="form-group">
                  <label>Quote Number</label><span class="required-star">*</span>
                  <select-field
                    v-model="formVals.quote_number"
                    :options="filteredQuotes"
                    :option-display-keys="['quote_number', 'customer', 'description']"
                    :option-val="'quote_number'"
                    :empty-option-text="'No items.'"
                    :disabled="locked || !authorEditing"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="['Unbillable', 'Safety', 'JOB'].includes(formVals.department) && formVals.job_number" class="form-group">
            <label>Phase:</label>
            <select-field
              v-model="formVals.phase"
              :label-text="'Phase:'"
              :options="phases"
              :option-display-keys="['Phase_Code', 'Description']"
              :option-val="'Phase_Code'"
              :empty-option-text="'No items.'"
              :disabled="locked || !authorEditing"
              :filter-on-key-val="filterPhaseStatuses"
            />
          </div>

          <div class="form-group">
            <label>Hours</label>
            <hours-picker v-model="formVals.hours" :readonly="locked" />
          </div>

          <div class="form-group" v-if="!['H', 'V'].includes(formVals.departmentOther)">
            <label>Message</label>
            <textarea v-if="!locked && authorEditing" v-model="formVals.message" :readonly="locked || !authorEditing" rows="8" class="form-control" />
            <!-- <wysiwyg
              v-if="!locked && authorEditing"
              v-model="formVals.message"
              v-click-outside="noteChange"
              :placeholder="'Short description of activity, note that this can be customer facing'"
              :use-plain-text="true"
              :read-only="locked || !authorEditing"
            /> -->
            <textarea v-else class="form-control" v-model="formVals.message" readonly disabled rows="8" />
          </div>
        </div>
      </div>

      <div class="action-buttons">
        <button v-if="!locked" class="btn" @click="addEntry()">
          <span v-if="editing"> Update Entry </span>
          <span v-else> Add Entry </span>
        </button>
        <button class="btn" @click="deleteEntry()" v-if="editId && (authorEditing || isAdmin) && (!locked || isAdmin)">Delete Entry</button>
      </div>
    </div>
  </div>
  <waiting-spinner />
</div>
