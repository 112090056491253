
<div class="form-group">
  <label v-if="isShowLabel">View Department</label>
  <select-field
    :options="emCostGroupsData"
    :option-display-keys="['Cost_Group_Description']"
    :option-val="'Cost_Group_Code'"
    v-model="selectedValue"
    @selectItem="selectDepartment"
    :allow-clear="true"
  />
</div>
