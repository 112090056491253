<template>
  <div class="page-container">
    <div class="row">
      <div class="col-sm-12">

        <div class="row top-row" style="padding: 20px 0px 0;">
          <div class="col-sm-12">
            <h3 style="display:inline-block;"><span class="quote-number">{{ quoteNumber }}</span></h3>
            <button
              class="btn"
              @click="save"
              style="float: right;margin-top:20px"
            >
              Save
            </button>
          </div>
        </div>

        <hr>

        <quote-write-up
          ref="quoteWriteUpComponent"
          v-if="!readOnly"
          :quote-material-list-id="quoteMaterialListId"
          :readonly="false"
        />
      </div>
      <waiting-spinner/>
    </div>
  </div>
</template>
<script>
import appFuncs from "appFuncs";
import {mapGetters} from "vuex";
import QuoteWriteUp from 'components/Dashboard/Quotes/QuoteWriteUp';
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  components: {
    QuoteWriteUp,
    WaitingSpinner
  },

  data() {
    return {
      quoteId: null,
      readOnly: true
    };
  },

  computed: {
    ...mapGetters([
      'quoteMaterialList',
      'userAuthedData'
    ]),

    quoteNumber() {
      return ((this.quoteMaterialList || {}).materialList || {}).quote_number;
    }
  },

  props: {
    quoteMaterialListId: {
      type: [Number, String],
      required: true
    }
  },

  methods: {
    getMaterialsList() {
      return new Promise(async (resolve, reject) => {
        if (!this.quoteMaterialListId) {
          reject(new Error('No quote material list id'));
          return;
        }
        this.$bus.$emit('setWaiting', {name: 'getQuoteMaterialList', message: 'Fetching Data'});
        await this.$store.dispatch('getQuoteMaterialList', {id: this.quoteMaterialListId}).finally(() => {
          this.$bus.$emit('stopWaiting', 'getQuoteMaterialList');
          this.quoteId = JSON.parse(JSON.stringify(this.quoteMaterialList.materialList.quote_id || null));
        });
        this.$bus.$emit('stopWaiting', 'getMaterialList');
        resolve();
      });
    },

    userIsEditing() {
      // check if user is editing and set read only to true, if not close window
      return new Promise(async (resolve, reject) => {
        var params = {
          action: 'is_page_active',
          pageType: 'quote',
          id: this.quoteId
        };
        try {
          let res = await appFuncs.shHttp(params);
          if (res.active == this.userAuthedData.eid) {
            this.readOnly = false;
          } else {
            window.close();
          }
          resolve();
        } catch (e) {
          console.log('problem getting page active', e);
          Bugsnag.notify(e);
          reject();
        }
      });
    },

    save() {
      if (this.$refs.quoteWriteUpComponent) {
        this.$refs.quoteWriteUpComponent.saveAllNotes().catch((e) => {
          console.log('problem saving notes', e);
        });
      }
    }
  },

  async mounted() {
    await this.getMaterialsList();
    this.userIsEditing();
  }

};
</script>

<style scoped lang="scss">
.page-container {
  padding: 30px 10px;
}
</style>

