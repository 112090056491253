
<div>
  <div :class="['modal-container']" :style="{zIndex: 2 + index}" v-for="(modal, index) in modals">
    <div v-if="modalVisible" class="modal-underlay"/>

    <div :class="['modal-multi modal', modal.name]">
      <div :class="[classes, modal.size]" role="document">
        <div class="close-icon" v-if="!modal.hideClose" @click="closeModal(modal)" type="button">
          <span aria-hidden="true">&times;</span>
        </div>
        <div :class="contentClasses">
          <div class="modal-body">
            <h4 v-if="modal.title" class="modal-title" id="siteModalLabel">
              {{ modal.title }}
            </h4>
            <div v-html="modal.content"/>
            <div class="button-container">
              <button v-for="button in modal.buttons" @click="modalFunction(button.context, button.buttonFunc, button.buttonParams)" type="button" class="btn btn-primary">
                {{ button.buttonText }}
              </button>
              <router-link v-if="modal.route" :to="modal.route" type="button" class="btn btn-primary">
                {{ modal.buttonText }}
              </router-link>
            </div>

            <div v-if="modal.component">
              <!-- NEW is binding all modal componentData as individual props :component-data left for support-->
              <component :is="modal.component" :component-data="modal.componentData" v-bind="modal.componentData"/>
            </div>
          </div>
        </div>
        <!-- <button v-if="!modal.hideClose" @click="closeModal(modal)" type="button" class="close ti ti-close" aria-label="Close" /> -->
      </div>
    </div>
  </div>
</div>
