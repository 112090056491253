<template>
  <div>
    <div class="tab-section outline white">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{ active: tab == 'Contact Info' }]">
          <a href="#" @click="tab = 'Contact Info'"> Contact Info </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'Setup' }]" v-if="isAdmin">
          <a href="#" @click="tab = 'Setup'"> Office Setup </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'Department' }]" v-if="isAdmin">
          <a href="#" @click="tab = 'Department'"> Department Settings </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'Settings' }]" v-if="isAdmin">
          <a href="#" @click="tab = 'Settings'"> Settings </a>
        </li>
      </ul>
      <div :class="['tab-content', { 'first-active': tab === 'Contact Info' }]">
        <div class="tab-content-inner">
          <div v-if="tab == 'Contact Info'">
            <div class="row" v-if="employee.Employee_Code">
              <div class="columns col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Name</label>
                  <input class="form-control" type="text" v-model="employee.Employee_Name" readonly />
                </div>
                <div class="form-group">
                  <label>Mobile Phone</label>
                  <input class="form-control" type="text" v-model="employee.Employee_Mobile_Phone" v-mask="'###-###-####'" />
                </div>
                <div class="form-group">
                  <label>Email</label>
                  <input class="form-control" type="text" v-model="employee.Employee_Email" />
                </div>
                <div class="form-group">
                  <label>Assigned Hub</label>
                  <select-field
                    :options="hubs"
                    :option-display-keys="['name']"
                    :option-val="'val'"
                    v-model="employeeDetails.assigned_hub"
                    :empty-option-text="'Fetching items...'"
                    :label="'Assigned Hub'"
                    disabled
                  />
                </div>
              </div>
              <div class="columns col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Title</label>
                  <input class="form-control" type="text" v-model="employee.Title" />
                </div>
                <div class="form-group">
                  <label>Location</label>
                  <input class="form-control" type="text" v-model="employee.Employee_Location" />
                </div>
                <div class="form-group">
                  <label>Direct Phone</label>
                  <input class="form-control" type="text" v-model="employee.Employee_Extension" v-mask="'###-###-####'" />
                </div>
                <div class="form-group">
                  <label>Employee Code</label>
                  <input class="form-control" type="text" v-model="employee.Employee_Code" readonly />
                </div>
              </div>
            </div>
            <div class="button-container">
              <button class="btn" @click="updateData">Update</button>
            </div>
          </div>

          <div v-if="tab == 'Setup' && employee.Employee_Code && isAdmin" class="setup-tab">
            <div class="row">
              <div class="columns col-sm-12 col-md-6">
                <h4>General</h4>
                <div class="form-group">
                  <label>Default Region</label><span class="required-star">*</span>
                  <select-field
                    v-model="employeeDetails.default_region"
                    :options="costCenterRegions"
                    :option-display-keys="['name']"
                    :option-val="'value'"
                    :empty-option-text="'Fetching items...'"
                    :label="'Default Region'"
                  />
                </div>
                <div class="form-group">
                  <label>Assigned Hub</label><span class="required-star">*</span>
                  <select-field
                    :options="hubs"
                    :option-display-keys="['name']"
                    :option-val="'val'"
                    v-model="employeeDetails.assigned_hub"
                    :empty-option-text="'Fetching items...'"
                    :label="'Assigned Hub'"
                  />
                </div>
                <div class="form-group">
                  <label>Role</label>
                  <select-field
                    v-model="employeeDetails.role"
                    :options="[
                      { Name: 'Captain', Code: 'captain' },
                      { Name: 'Project Manager', Code: 'pm' }
                    ]"
                    :option-display-keys="['Name']"
                    :option-val="'Code'"
                    :empty-option-text="'Fetching items...'"
                    :label="'User Role'"
                    :allow-clear="true"
                  />
                </div>
                <div class="form-group">
                  <label>Supervisior</label>
                  <select-field
                    :options="employees"
                    :option-display-keys="['Employee_Name']"
                    :option-val="'Employee_Code'"
                    v-model="employeeDetails.supervisor"
                    :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                  />
                </div>
                <h4>Fleet</h4>
                <div class="form-group">
                  <label>Assigned Vehicle</label><span class="required-star">*</span>
                  <select-field
                    v-model="employeeDetails.default_vehicle"
                    :options="equipmentList"
                    :option-display-keys="['Name']"
                    :option-val="'Code'"
                    :empty-option-text="'Fetching items...'"
                    :label="'Equipment'"
                  />
                </div>

                <h4>Billing Module</h4>

                <div class="form-group">
                  <span class="inline-checkbox">
                    <checkbox2 v-model="employeeDetails.billing_employee" true-val="yes" false-val="no">Billing Employee</checkbox2>
                  </span>
                </div>

                <div class="form-group">
                  <label>AR Routing Code</label>
                  <select-field
                    :options="billingRoutingCodes"
                    :option-display-keys="['routing_code', 'routing_desc']"
                    :option-val="'routing_code'"
                    v-model="employeeDetails.billing_routing_code"
                    :placeholder-text="'Add Routing Code'"
                    :preselect-single-option="false"
                    item-name="Billing Routing Code"
                  />
                </div>

                <div class="form-group">
                  <label>Billing Task Requiring Approval</label>
                  <select-field-multi
                    :options="billingTaskTypes"
                    :option-display-keys="['name']"
                    :option-val="'id'"
                    v-model="employeeDetails.billing_tasks_requiring_approval"
                    placeholder-text="Set Billing Task Requiring Approval"
                    :preselect-single-option="false"
                    item-name="Billing Task Type"
                  />
                </div>

                <h4>Quoting Module</h4>
                <div class="form-group">
                  <label>Quote Max Value</label>
                  <vue-numeric currency="$" separator="," :class="['form-control']" :precision="2" :read-only-class="'form-control readonly'" v-model="employeeDetails.quote_max_value" />
                </div>

                <div class="form-group">
                  <label>Quote Routing Code</label>
                  <select-field
                    :options="billingRoutingCodes"
                    :option-display-keys="['routing_code', 'routing_desc']"
                    :option-val="'routing_code'"
                    v-model="employeeDetails.quote_routing_code"
                    :placeholder-text="'Add Routing Code'"
                    :preselect-single-option="false"
                    item-name="Quote Routing Code"
                  />
                </div>
              </div>

              <div class="columns col-sm-12 col-md-6">
                <h4>Accounts Payable</h4>
                <div class="form-group">
                  <label>Assigned Vendor Code</label>
                  <select-field
                    :options="vendors"
                    :option-display-keys="['Vendor_Name']"
                    :option-val="'Vendor_Code'"
                    v-model="employeeDetails.default_vendor_code"
                    placeholder-text="Assign Vendor Code"
                    :label="'Vendor'"
                    :filter-on-key-val="{ key: 'Status', val: 'A' }"
                  />
                </div>
                <div class="form-group">
                  <label>Corp CC Account</label><span class="required-star">*</span>
                  <select-field
                    v-model="employeeDetails.corp_cc_code"
                    :options="corpCreditCardAccounts"
                    :option-display-keys="['Bank_Account_Name', 'Bank_Account_Code']"
                    :option-val="'Bank_Account_Code'"
                    :empty-option-text="'Fetching items...'"
                    :label="'Corp Credit Card'"
                    @selectItem="employeeDetails.sub_cc_code = ''"
                  />
                </div>
                <div class="form-group">
                  <label>Corp Credit Card</label><span class="required-star">*</span>
                  <select-field
                    v-model="employeeDetails.sub_cc_code"
                    :options="subCreditCardAccounts"
                    :option-display-keys="['Card_Number', 'Card_Description']"
                    :option-val="'Card_Number'"
                    :empty-option-text="'Fetching items...'"
                    :label="'Company Card'"
                  />
                </div>
                <div class="form-group">
                  <label>Default Routing Code</label>
                  <select-field
                    :options="routingCodes"
                    :option-display-keys="['Routing_Desc', 'Routing_Code']"
                    :option-val="'Routing_Code'"
                    v-model="employeeDetails.routing_code1"
                    placeholder-text="Assign Routing Code"
                    :label="'Routing Code'"
                  />
                </div>
                <div class="form-group">
                  <label>Dollar Invoice Limit</label>
                  <vue-numeric currency="$" separator="," :class="['form-control']" :precision="2" :read-only-class="'form-control readonly'" v-model="employeeDetails.routing_limit" />
                </div>
                <div class="form-group">
                  <label>Default Routing if Over Limit</label>
                  <select-field
                    :options="routingCodes"
                    :option-display-keys="['Routing_Desc', 'Routing_Code']"
                    :option-val="'Routing_Code'"
                    v-model="employeeDetails.routing_code2"
                    placeholder-text="Assign Routing Code"
                    :label="'Routing Code'"
                  />
                </div>
                <div class="form-group">
                  <label>AP Invoice Approval Routing Codes</label>
                  <select-field-multi
                    :options="routingCodes"
                    :option-display-keys="['Routing_Desc', 'Routing_Code']"
                    :option-val="'Routing_Code'"
                    v-model="employeeDetails.assigned_routing_codes"
                    :placeholder-text="'Add Routing Code'"
                    :preselect-single-option="false"
                    item-name="Routing Code"
                  />
                </div>
              </div>

              <div class="columns col-sm-12 col-md-6">
                <h4>Payroll</h4>
                <div class="form-group">
                  <label>Default Department</label><span class="required-star">*</span>
                  <select-field
                    :options="emCostGroups"
                    :option-display-keys="['Cost_Group_Description']"
                    :option-val="'Cost_Group_Code'"
                    v-model="employeeDetails.default_department"
                    :empty-option-text="'Fetching items...'"
                    :label="'Default Department'"
                  />
                </div>
                <div v-if="employeeDetails.default_department === 'ADMIN'" class="form-group">
                  <label>Department Code</label><span class="required-star">*</span>
                  <select-field
                    :options="prDepartment"
                    :option-display-keys="['Description']"
                    :option-val="'Department_Code'"
                    v-model="employeeDetails.department_code"
                    :empty-option-text="'Fetching items...'"
                    :label="'Default Code'"
                  />
                </div>
                <div class="form-group">
                  <label>Wage Code</label><span class="required-star">*</span>
                  <select-field
                    v-model="employeeDetails.wage_code"
                    :options="wageCodesDataFormatted"
                    :option-display-keys="['Name']"
                    :option-val="'id'"
                    :empty-option-text="'Fetching items...'"
                    :label="'Wage Codes'"
                  />
                </div>
                <div class="form-group">
                  <label>Default Cost Center</label><span class="required-star">*</span>
                  <select-field
                    :options="regionCostCenters"
                    :option-display-keys="['name']"
                    :option-val="'value'"
                    v-model="employeeDetails.default_cost_center"
                    :empty-option-text="'Fetching items...'"
                    :label="'Default Cost Center'"
                    :disabled="!employeeDetails.default_region"
                  />
                </div>
                <div class="form-group">
                  <span class="inline-checkbox">
                    <checkbox2 v-model="employeeDetails.payroll_admin_user" true-val="yes" false-val="no"> Payroll Admin User</checkbox2>
                  </span>
                </div>
              </div>
            </div>

            <div class="button-container">
              <button class="btn" @click="updateUDFData">Update</button>
            </div>
          </div>

          <div v-if="tab == 'Department'">
            <div class="row">
              <div class="columns col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Security</label>
                  <select-field-multi
                    :options="emCostGroups"
                    :option-display-keys="['Cost_Group_Description']"
                    :option-val="'Cost_Group_Code'"
                    v-model="employeeDetails.em_cost_groups"
                    placeholder-text="Department Access"
                    :preselect-single-option="false"
                    item-name="Department Access"
                  />
                </div>
              </div>
            </div>
            <div class="button-container">
              <button class="btn" @click="updateUDFData">Update</button>
            </div>
          </div>

          <div v-if="tab == 'Settings' && employee.Employee_Code && isAdmin">
            <div class="row">
              <div class="columns col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Hire Date</label>
                  <date-picker v-model="employeeDetails.hire_date" :maxDate="todayDate" :format="'date'" :preFilled="false"></date-picker>
                </div>
              </div>
              <div class="columns col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Extra Boot Allowance</label>
                  <vue-numeric currency="$" separator="," :class="['form-control']" :precision="2" :read-only-class="'form-control readonly'" v-model="employeeDetails.extra_boot_allowance" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="columns col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Extra Tool Allowance</label>
                  <vue-numeric currency="$" separator="," :class="['form-control']" :precision="2" :read-only-class="'form-control readonly'" v-model="employeeDetails.extra_tool_allowance" />
                </div>
              </div>

              <div class="columns col-sm-12 col-md-6">
                <div class="form-group">
                  <span class="inline-checkbox">
                    <checkbox2 v-model="employeeDetails.cba_employee" true-val="yes" false-val="no"> CBA employee</checkbox2>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="columns col-sm-12 col-md-6">
                <div class="form-group">
                  <span class="inline-checkbox">
                    <checkbox2 v-model="employeeDetails.non_cba_contracting_employee" true-val="yes" false-val="no"> Non CBA Contracting Employee</checkbox2>
                  </span>
                </div>
              </div>
              <div class="columns col-sm-12 col-md-6">
                <div class="form-group">
                  <span class="inline-checkbox">
                    <checkbox2 v-model="employeeDetails.automation_employee" true-val="yes" false-val="no"> Automation Employee</checkbox2>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="columns col-sm-12 col-md-6">
                <div class="form-group">
                  <span class="inline-checkbox">
                    <checkbox2 v-model="employeeDetails.estimating_employee" true-val="yes" false-val="no"> Estimating Employee</checkbox2>
                  </span>
                </div>
              </div>
              <div class="columns col-sm-12 col-md-6">
                <div class="form-group">
                  <span class="inline-checkbox">
                    <checkbox2 v-model="employeeDetails.sales_employee" true-val="yes" false-val="no"> Sales Employee</checkbox2>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="columns col-sm-12 col-md-6">
                <div class="form-group">
                  <span class="inline-checkbox">
                    <checkbox2 v-model="employeeDetails.administration_employee" true-val="yes" false-val="no"> Administration Employee</checkbox2>
                  </span>
                </div>
              </div>
            </div>

            <div class="button-container">
              <button class="btn" @click="updateUDFData">Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner :wait="wait" />
  </div>
</template>
<script>
import appFuncs from 'appFuncs'
import { mapGetters } from 'vuex'
import SelectField from 'components/UIComponents/SelectField'
import VueNumeric from 'vue-numeric'
import SelectFieldMulti from 'components/UIComponents/SelectFieldMulti'
import Checkbox2 from 'components/UIComponents/Checkbox'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import moment from 'moment/moment'
import DatePicker from 'components/UIComponents/DatePicker'

export default {
  data() {
    return {
      employee: {},
      wait: {
        message: ''
      },
      tab: 'Contact Info',
      employeeUDFs: [],
      employeeDetails: {
        default_vendor_code: null,
        corp_cc_code: null,
        default_vehicle: null,
        routing_code1: null,
        routing_limit: 0,
        routing_code2: null,
        supervisor: null,
        billing_routing_code: null,
        quote_routing_code: null,
        quote_max_value: 0,
        role: null,
        assigned_routing_codes: [],
        payroll_admin_user: 'no',
        billing_employee: 'no',
        default_region: null,
        wage_code: null,
        assigned_hub: null,
        hire_date: null,
        extra_boot_allowance: 0,
        extra_tool_allowance: 0,
        cba_employee: 'no',
        non_cba_contracting_employee: 'no',
        automation_employee: 'no',
        estimating_employee: 'no',
        sales_employee: 'no',
        administration_employee: 'no',
        billing_tasks_requiring_approval: [],
        em_cost_groups: [],
        default_department: '',
        department_code: '',
        default_cost_center: ''
      },
      paymentMethods: [],
      equipmentList: [],
      routingCodes: [],
      wageCodesData: [],
      hubs: [
        { name: 'London: 594 Newbold Street, London', val: 'london' },
        { name: 'Hamilton: 589 Barton Street, Stoney Creek', val: 'hamilton' },
        { name: 'Cambridge: 2401 Fountain Street South #901, Ayr', val: 'cambridge' },
        { name: 'Mississauga: 966 Pantera Drive, Mississauga', val: 'missisauga' },
        { name: 'Vaughan: Wonderland (Highway 400 – Major Mackenzie Drive)', val: 'vaughan' },
        { name: 'Toronto East: 401/Port Union Road Carpool Lot (MTO Carpool Lot, Toronto)', val: 'tor_east' }
      ],
      billingTaskTypes: [],
      billingRoutingCodes: []
    }
  },

  components: {
    SelectField,
    VueNumeric,
    SelectFieldMulti,
    Checkbox2,
    WaitingSpinner,
    DatePicker
  },

  computed: {
    ...mapGetters(['employees', 'vendors', 'configuredCostCenters', 'emCostGroups', 'prDepartment']),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },

    costCenterRegions() {
      let costCenters = Array.isArray(this.configuredCostCenters) ? this.configuredCostCenters : null
      if (!costCenters) return []
      return costCenters.map(itm => {
        return {
          name: itm.name,
          value: itm.id
        }
      })
    },

    regionCostCenters() {
      let costCenters = Array.isArray(this.configuredCostCenters) ? this.configuredCostCenters : []
      let setRegion = this.employeeDetails.default_region
      costCenters = costCenters.find(itm => {
        return itm.id === setRegion
      })
      return costCenters ? costCenters.cost_centers : []
    },

    wageCodesDataFormatted() {
      const formatted = this.wageCodesData.map(itm => {
        const json = JSON.parse(itm.value)
        const name = Array.isArray(json) ? json.find(itm => itm.name === 'name') : []
        return {
          Name: name ? name.value : '',
          id: itm.id,
          sort: itm.sort || 0
        }
      })
      return formatted.sort((a, b) => a.sort - b.sort)
    },
    todayDate() {
      return moment(this.today).toDate()
    },

    corpCreditCardAccounts() {
      const uniqueCodes = this.paymentMethods.reduce((acc, curr) => {
        if (!acc.find(item => item.Bank_Account_Code === curr.Bank_Account_Code)) {
          acc.push({ Bank_Account_Code: curr.Bank_Account_Code, Bank_Account_Name: curr.Bank_Account_Name })
        }
        return acc
      }, [])
      return uniqueCodes
    },

    subCreditCardAccounts() {
      return this.paymentMethods.filter(item => {
        return item.Bank_Account_Code == this.employeeDetails.corp_cc_code
      })
    }
  },

  props: {
    employeeCode: {
      type: [String, Number],
      required: true
    }
  },

  methods: {
    setData() {
      let employee = this.employees.find(itm => {
        return itm.Employee_Code == this.employeeCode
      })
      this.employee = JSON.parse(JSON.stringify(employee))

      // set these fields using udfs aleady added to employee data
      this.employeeDetails.default_vendor_code = this.employee.default_vendor_code
      this.employeeDetails.corp_cc_code = this.employee.corp_cc_code
      this.employeeDetails.sub_cc_code = this.employee.sub_cc_code
      this.employeeDetails.default_vehicle = this.employee.default_vehicle
      this.employeeDetails.routing_code1 = this.employee.routing_code1
      this.employeeDetails.routing_limit = this.employee.routing_limit
      this.employeeDetails.routing_code2 = this.employee.routing_code2
      this.employeeDetails.supervisor = this.employee.supervisor
      this.employeeDetails.quote_max_value = this.employee.quote_max_value
      this.employeeDetails.role = this.employee.role
      this.employeeDetails.assigned_routing_codes = this.employee.assigned_routing_codes
      this.employeeDetails.payroll_admin_user = this.employee.payroll_admin_user
      this.employeeDetails.billing_employee = this.employee.billing_employee
      this.employeeDetails.default_region = this.employee.default_region
      this.employeeDetails.wage_code = this.employee.wage_code
      this.employeeDetails.default_department = this.employee.default_department
      this.employeeDetails.department_code = this.employee.department_code
      this.employeeDetails.assigned_hub = this.employee.assigned_hub
      this.employeeDetails.billing_tasks_requiring_approval = this.employee.billing_tasks_requiring_approval
      this.employeeDetails.em_cost_groups = this.employee.em_cost_groups
      this.employeeDetails.billing_routing_code = this.employee.billing_routing_code
      this.employeeDetails.quote_routing_code = this.employee.quote_routing_code
      this.employeeDetails.default_cost_center = this.employee.default_cost_center

      let momentDate = moment.unix(this.employee.hire_date) // Convert Unix timestamp to moment object

      this.employeeDetails.hire_date = momentDate.toDate()
      this.employeeDetails.extra_boot_allowance = this.employee.extra_boot_allowance
      this.employeeDetails.extra_tool_allowance = this.employee.extra_tool_allowance
      this.employeeDetails.cba_employee = this.employee.cba_employee
      this.employeeDetails.non_cba_contracting_employee = this.employee.non_cba_contracting_employee
      this.employeeDetails.automation_employee = this.employee.automation_employee
      this.employeeDetails.estimating_employee = this.employee.estimating_employee
      this.employeeDetails.sales_employee = this.employee.sales_employee
      this.employeeDetails.administration_employee = this.employee.administration_employee
    },

    updateData() {
      this.employee.Employee_Extension = this.stripToNumber(this.employee.Employee_Extension)
      this.employee.Employee_Mobile_Phone = this.stripToNumber(this.employee.Employee_Mobile_Phone)

      // this is employee personal phone which not using here, doing this to fix formatting for exisiting entries
      this.employee.Phone = this.stripToNumber(this.employee.Phone)

      this.wait.message = 'Updating Employee data'
      this.$store
        .dispatch('updateEmployee', this.employee)
        .then(res => {
          this.$snack.open('Employee data updated.')
          this.$bus.$emit('EmployeeEdit')
          setTimeout(() => {
            this.$bus.$emit('modalClose', 'EmployeeEdit')
          }, 2000)
        })
        .catch(res => {
          this.$snack.open(res.message || 'Problem updating employee data.', 'error')
        })
        .finally(() => {
          this.wait.message = ''
        })
    },

    getRoutingCodes() {
      return new Promise((resolve, reject) => {
        var params = {
          action: 'get_employee_routing_codes'
        }

        this.$bus.$emit('setWaiting', { name: params.action, message: 'Fetching routing codes' })
        appFuncs
          .shHttp(params)
          .then(result => {
            if (result.status === 'success') {
              this.routingCodes = result.data
              resolve()
            } else {
              this.$snack.open(result.message || 'Problem fetching routing codes', 'error')
              reject()
            }
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', params.action)
          })
      })
    },

    getBillingRoutingCodes() {
      var params = {
        action: 'get_routing_codes'
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Getting Billing Routing Codes' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.billingRoutingCodes = data
        })
        .catch(err => {
          console.log(err)
          snack.open(err.message || 'Problem fetching billing routing codes', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    getEquipment() {
      return new Promise((resolve, reject) => {
        var params = {
          action: 'get_equipment'
        }

        this.$bus.$emit('setWaiting', { name: params.action, message: 'Fetching equipment' })
        appFuncs
          .shHttp(params)
          .then(result => {
            if (result.status === 'success') {
              let raw = result.data
              let formatted = []
              for (let i = 0; i < raw.length; i++) {
                if (raw[i].Equipment_Type == '2' || raw[i].Equipment_Status !== 'A') continue
                let desc = typeof raw[i].Description === 'string' && raw[i].Description ? raw[i].Description : ''
                let license = typeof raw[i].License_Number === 'string' && raw[i].License_Number ? ' - ' + raw[i].License_Number : ''
                let model = typeof raw[i].Equipment_Model === 'string' && raw[i].Equipment_Model ? ' ' + raw[i].Equipment_Model : ''
                let make = typeof raw[i].Equipment_Make === 'string' && raw[i].Equipment_Make ? ' - ' + raw[i].Equipment_Make : ''
                let year = typeof raw[i].Year === 'string' && raw[i].Year ? ' - ' + raw[i].Year : ''
                formatted.push({
                  Name: desc + license + make + model + year,
                  Code: raw[i].Equipment_Code
                })
              }
              this.equipmentList = formatted
              resolve()
            } else {
              this.$snack.open(result.message || 'Problem fetching equipment', 'error')
              reject()
            }
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', params.action)
          })
      })
    },

    getPaymentMethods() {
      return new Promise((resolve, reject) => {
        // get company credit cards (payment methods
        var params = {
          action: 'get_company_credit_cards'
        }

        this.$bus.$emit('setWaiting', { name: params.action, message: 'Getting Payment Types' })
        appFuncs
          .shHttp(params)
          .then(result => {
            if (result.status === 'success') {
              this.paymentMethods = result.data
              resolve()
            } else {
              this.$snack.open(result.message || 'Problem fetching equipment', 'error')
              reject()
            }
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', params.action)
          })
      })
    },

    updateUDFData() {
      const data = this.employeeDetails
      data.employee_code = this.employee.Employee_Code
      let date = moment(this.employeeDetails.hire_date)
      data.hire_date = date.unix()

      let params = {
        action: 'upsert_employee_app_data',
        data
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Updating Employee details' })
      appFuncs
        .shHttp(params)
        .then(async result => {
          if (result.status === 'success') {
            this.$snack.open('Employee details successfully updated', 'success')
            await this.$store.dispatch('getEmployees', true)
            this.setData()
          } else {
            this.$snack.open(result.message || 'Problem updating employee details', 'error')
          }
        })
        .catch(e => {
          this.$snack.open(e.message || 'Problem updating employee details', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    async getOfficeSetupData() {
      const vendors = this.$store.dispatch('getVendors')
      const emCostGroups = this.$store.dispatch('getEmCostGroups')
      const prDepartment = this.$store.dispatch('getPrDepartment')
      const paymentMethods = this.getPaymentMethods()
      const equipment = this.getEquipment()
      const routingCodes = this.getRoutingCodes()
      await Promise.all([vendors, paymentMethods, equipment, routingCodes, emCostGroups, prDepartment])
      this.setData()
    },

    getWageCodes() {
      return new Promise((resolve, reject) => {
        var params = {
          action: 'get_global_options',
          group: 'wage_codes'
        }
        this.$bus.$emit('setWaiting', {
          name: `get_wage_codes`,
          message: 'Getting Wage Codes'
        })
        appFuncs
          .shRequest(params)
          .then(data => {
            this.wageCodesData = data
            resolve()
          })
          .catch(err => {
            this.$snack.open(err.message || 'Problem getting wage codes', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', `get_wage_codes`)
          })
      })
    },

    getBillingTaskOptions(group) {
      var params = {
        action: 'get_global_options',
        group: 'Billing'
      }
      this.$bus.$emit('setWaiting', {
        name: `get_global_options ${group}`,
        message: 'Getting Options'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.billingTaskTypes = data
        })
        .catch(err => {
          this.$snack.open(err.message || 'Problem getting quote option', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', `get_global_options ${group}`)
        })
    }
  },

  async mounted() {
    this.$bus.$emit('setWaiting', { name: 'getEmployees', message: 'Fetching employee data' })
    this.$store
      .dispatch('getEmployees')
      .then(() => {
        this.setData()
      })
      .catch(e => {
        this.$snack.open(e.message || 'Problem getting Employees', 'error')
      })
      .finally(() => {
        this.$bus.$emit('stopWaiting', 'getEmployees')
      })
    this.$store.dispatch('getConfiguredCostCenters').catch(e => {
      this.$snack.open(e.message || 'Problem getting Configured Cost Centers', 'error')
    })

    this.getWageCodes()
  },

  watch: {
    tab() {
      if (this.tab === 'Setup') {
        this.getOfficeSetupData()
        this.getBillingTaskOptions()
        this.getBillingRoutingCodes()
      }
    },

    'employeeDetails.default_region': {
      handler: function (newVal, oldVal) {
        if (oldVal && newVal !== oldVal) {
          this.employeeDetails.default_cost_center = null
        }
      }
    },

    'employeeDetails.default_department': {
      handler: function (newVal) {
        if (newVal !== 'ADMIN') {
          this.employeeDetails.department_code = ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card .content {
  padding: 15px;
}

.button-container {
  padding: 0 10px 10px;
}

.tab-content {
  margin-bottom: 0 !important;
}

.setup-tab {
  h4 {
    text-decoration: underline;
  }
}
</style>
