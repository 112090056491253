
<div>
  <div class="row">
    <div class="col-md-12">
      <div class="tab-section outline white">
        <ul class="nav nav-tabs">
          <li role="presentation" :class="[{ active: tab == 'Main' }]">
            <a href="#" @click="tab = 'Main'"> Main </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Billing' }]">
            <a href="#" @click="tab = 'Billing'"> Billing </a>
          </li>
        </ul>
        <div :class="['tab-content', { 'first-active': tab === 'Main' }]">
          <div class="tab-content-inner">
            <div v-if="tab == 'Main'">
              <div class="row">
                <div class="col-sm-12 col-md-12" style="position: relative">
                  <!--
                  <div :class="'detail-item'">
                    <h3>#{{ woData.WO_Number }}</h3>
                  </div>

                  <div class="wo-nav">
                    <i v-if="prevWO" class="ti-angle-left" @click="loadData(prevWO)"></i>
                    <i v-if="nextWO" class="ti-angle-right" @click="loadData(nextWO)"></i>
                  </div> -->
                </div>
                <div class="col-sm-12 col-md-4">
                  <div :class="'detail-item'">
                    <label>Customer</label>
                    <p>{{ woData.customer_name }}</p>
                  </div>

                  <div :class="'detail-item'">
                    <label>Description</label>
                    <p>{{ woData.Summary_Description }}</p>
                  </div>

                  <div :class="'detail-item'" v-if="employees && employees.length">
                    <label>Techs</label>
                    <ul class="wo-techs">
                      <li v-for="tech in woData.techs">
                        <span v-html="getEmployeeName(tech)" />
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div :class="'detail-item'">
                    <label>Project Stage</label>
                    <input type="text" class="form-control fake-readonly is-link" readonly :value="projectStageName" @click="projectUpdate()" />
                    <!-- <select-field
                      :options="projectStages"
                      :label-text="'Project Stage'"
                      :option-display-keys="['description']"
                      option-val="id"
                      v-model="woData.project_stage"
                      :disabled="!editable"
                    /> -->
                    <!-- <p v-if="isLocked" style="padding: 5px"><i>PM can unlock using button below WO Main tab</i></p> -->
                  </div>

                  <div :class="'detail-item'">
                    <label>Sign Off Stage</label>
                    <select-field
                      :options="signOffStages"
                      :label-text="'Sign Off Status'"
                      :option-display-keys="['description']"
                      option-val="id"
                      v-model="woData.sign_off_stage"
                      :read-only="woData.project_stage !== 'finished'"
                    />
                  </div>

                  <!-- <div :class="'detail-item'">
                    <label>Dispatch Status</label>
                    <select-field
                      :options="woDispatchStatuses"
                      :label-text="'Dispatch Status'"
                      :option-display-keys="['description']"
                      :option-val="'code'"
                      v-model="woData.Dispatch_Status_Code"
                      :Xdisabled="!editable"
                      disabled
                    />
                  </div>

                  <div :class="'detail-item'">
                    <label>Stage</label>
                    <select-field
                      :options="filteredPriorityCodes"
                      :label-text="'Stage'"
                      :option-display-keys="['description']"
                      :option-val="'code'"
                      v-model="woData.Priority_Code"
                      :filter-on-key-val="{ key: 'enabled', val: 'yes' }"
                      disabled
                    />
                    <p v-if="isLocked" style="padding: 5px"><i>PM can unlock using button below WO Main tab</i></p>
                  </div> -->

                  <div :class="'detail-item'">
                    <label
                      >Last Worked Date
                      <span
                        style="padding-left: 3px"
                        class="ti-info-alt"
                        v-tooltip="{
                          content: 'This is the last day an hour was occurred',
                          placement: 'bottom',
                          hideOnTargetClick: false,
                          delay: { show: 200, hide: 2500 },
                          offset: 5,
                          classes: ['wide']
                        }"
                      />
                    </label>
                    <input type="text" :value="formatDate(woLaborData.entry_date)" :disabled="true" class="fake-disabled form-control" />
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <label>Service Report Send Log</label>
                  <service-report-send-log :raw-log="Array.isArray(woData.report_send_log) ? woData.report_send_log : []" />
                  <p style="padding: 5px">
                    <i>While in "Report Sent" stage, sign off request will be automatically re-sent once per week to all recipents the report was last sent to.</i>
                  </p>

                  <div class="detail-item" v-if="!woRecieved">
                    <label>Sign Off Link</label>
                    <input class="form-control fake-readonly" type="text" readonly v-model="woData.sign_off_link" @click="(copyText(woData.sign_off_link), $snack.open('Copied'))" />
                  </div>

                  <div class="detail-item" v-if="!woRecieved && woData.Dispatch_Status_Code == 7 && Array.isArray(woData.report_send_log) && woData.report_send_log.length > 0">
                    <button class="btn" @click="resendSignOffLink">Resend Sign Off Link</button>
                  </div>
                </div>
              </div>

              <h5>Accounts Receivable (Billing) Routing Code</h5>
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <label>Routing Code</label>
                    <select-field
                      :options="billingRoutingCodes"
                      :option-display-keys="['routing_code', 'routing_description']"
                      :option-val="'routing_code'"
                      v-model="woData.billing_routing_code"
                      placeholder-text="Assign Routing Code"
                      :label="'Routing Code'"
                      :disabled="!isPM && !isAdmin"
                    />
                  </div>
                </div>
              </div>

              <br />
              <div class="row">
                <div class="col-sm-12">
                  <div :class="'detail-item'">
                    <alert :alert="{ show: woData.po_request_muted_by_customer == 'yes', message: 'Customer has muted PO Email Requests for this WO', state: 'warning', dismiss: 'none' }" />
                    <checkbox2 v-model="woData.auto_email_request" true-val="yes" false-val="no" :disabled="!isPM"> Enable Auto PO Request Email </checkbox2>
                  </div>
                </div>
              </div>
              <span>
                <button class="btn btn-blue" @click="updateWO()">Update</button>
                <!-- <button class="btn" @click="updateWO('close')">
                  Update & Close
                </button> -->
              </span>

              <button v-if="!woRecieved" class="btn" v-html="'Download PDF'" @click="generatePDF" />
              <!-- <p v-if="isLocked"><i>Work Order is locked</i></p> -->

              <button v-if="isAdmin" class="btn btn-outline-red" @click="deleteWO()">Delete</button>

              <hr />
              <h5>Date Change History</h5>
              <date-change-log v-if="woData.WO_Number" :obj-number="woData.WO_Number" type="WO" />
              <hr />

              <h5>Status Notes</h5>
              <item-comments
                v-if="woId"
                :table="'WOStatusNotes'"
                :item-id="woId"
                :label-text="''"
                :spectrum-single-note="false"
                :post-spectrum-name="'STATUS NOTES'"
                :post-spectrum-type="'wo'"
                ref="woStatusNotes"
                :read-only="true"
              />

              <br />
              <button class="btn" @click="projectUpdate()">Add Project Update</button>
            </div>
            <div v-if="tab == 'Billing'">
              <billing-tasks :wo-number="woData.WO_Number" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner />
</div>
