<template>
  <div class="form-group">
    <label v-if="isShowLabel">View Department</label>
    <select-field
      :options="emCostGroupsData"
      :option-display-keys="['Cost_Group_Description']"
      :option-val="'Cost_Group_Code'"
      v-model="selectedValue"
      @selectItem="selectDepartment"
      :allow-clear="true"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectField from 'components/UIComponents/SelectField'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    isShowLabel: {
      type: Boolean,
      default: false
    },
    employeeId: String
  },

  data() {
    return {
      selectedValue: this.value,
      emCostGroupsData: []
    }
  },

  components: {
    SelectField
  },

  computed: {
    ...mapGetters(['emCostGroups', 'employees', 'userAuthedData', 'selectedDepartment', 'viewAsEmployeeId']),

    isAdmin() {
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    }
  },

  methods: {
    selectDepartment(val) {
      if (this.isAdmin) {
        this.$store.commit('viewAsEmployeeId', null)
      }
      this.$emit('input', val)
      this.$store.commit('selectedDepartment', val)
    },

    setEmCostGroupsData() {
      // if (this.isAdmin) {
      //   this.emCostGroupsData = this.emCostGroups
      //   return
      // }

      // change to allow departments selection based on logged in user, not View As Employee selection
      // const useEmployee = this.viewAsEmployeeId ? this.viewAsEmployeeId : this.userAuthedData.eid
      const useEmployee = this.userAuthedData.eid

      const employee = this.employees.find(emp => emp.Employee_Code == useEmployee)
      const employeeEmCostGroups = employee?.em_cost_groups ? employee.em_cost_groups : []

      const data = this.emCostGroups.filter(_group => employeeEmCostGroups.indexOf(_group.Cost_Group_Code) !== -1)

      this.emCostGroupsData = data
    }
  },

  async mounted() {
    await this.$store.dispatch('getEmployees')
    await this.$store.dispatch('getEmCostGroups')
    this.setEmCostGroupsData()
  },

  watch: {
    selectedDepartment(val) {
      this.selectedValue = val
    },
    viewAsEmployeeId(val) {
      this.setEmCostGroupsData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

h3 {
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;

  i {
    color: $primary-color;
    float: right;
  }
}

.content-inner {
  overflow: scroll !important;
  max-height: 300px;
  // max-width:600px;
}
</style>
