
<div class="pdf-template" id="pdf-template">
  <div class="" v-if="data.length">
    <div class="header" id="pdfReady">
      <div class="row row-no-gutters" style="height: 107px">
        <div class="col-xs-4">
          <div>
            <img :src="`${urls.frontend}static/img/${logoFile}`"/>
          </div>
        </div>
        <div class="col-xs-4">
          <p v-html="regionAddress"/>
        </div>
      </div>
      <div class="header-bottom col-xs-12"/>
    </div>
    <section class="section main-section">
      <div class="section report-table">
        <div class="row header-row">
          <div class="col-sm-2">Job/WO Number / Description / PO / Status Note</div>
          <div class="col-sm-2">Job Status</div>
          <div class="col-sm-2">Customer / Location / Contact</div>
          <div class="col-sm-2">Division / Region / Project Lead / Captain</div>
          <div class="col-sm-2">Costing</div>
          <div class="col-sm-2">Dates</div>
        </div>
        <div v-for="(group, groupIndex) in groupedTableData" :key="groupIndex">
          <section class="section">
            <div v-for="item in group" :key="item.id" :class="['row', item.class]">
              <div class="col-sm-2" v-html="item.title"></div>
              <div class="col-sm-1" v-html="item.status"></div>
              <div class="col-sm-1" v-html="item.status2"></div>
              <div class="col-sm-2" v-html="item.customer"></div>
              <div class="col-sm-2" v-html="item.meta"></div>
              <div class="col-sm-1" v-html="item.costing"></div>
              <div class="col-sm-1" v-html="item.costing2"></div>
              <div class="col-sm-1" v-html="item.dates"></div>
              <div class="col-sm-1" v-html="item.dates2"></div>
            </div>
          </section>
        </div>
      </div>
    </section>
    <div v-if="pdf" class="footer" style="margin-bottom: 1px">
      <div class="footer-inner">
        <div class="footer-left">
          {{ data.template_date }}
        </div>
        <div class="footer-center">{{ companyName }} Ltd.</div>
        <div class="footer-right">
          <div class="page-number">
            <p>Page 1 of 1</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner/>
</div>
