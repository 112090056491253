<template>
  <div>
    <div class="medium" style="max-width:400px" v-show="!showExpenseDialog">
      <button type="button" @click="close()" class="close close-link" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="content">
        <div class="" style="padding:8px">
          <div class="upper">
            <h4 style="margin-top:15px;font-weight:normal">Add Expenses</h4>
          </div>
          <div class="add-fields">
            <div class="row">
              <div class="col-sm-12">

                <div class="form-group">
                  <label>Added By</label><span class="required-star">*</span>
                  <select-field-dynamic
                    :options="employees"
                    :option-display-keys="['Employee_Name']"
                    :option-val="'Employee_Code'"
                    v-model="formVals.added_by"
                    :item-name="'Employee'"
                    :label="'Added By'"
                    :filter-on-key-val="{key: 'Employment_Status', val: 'A'}"
                  />
                </div>

                <div class="form-group">
                  <label>Date</label><span class="required-star">*</span>
                  <p v-if="!formVals.date" class="text-danger">Please select date of transaction</p>
                  <date-picker
                    v-model="formVals.date"
                    :format="'string'"
                    :is-inline="false"
                    :pre-filled="false"
                    ref="datePicker"
                  />
                </div>

                <div class="form-group">
                  <label>Payment Method</label><span class="required-star">*</span>
                  <select-field-dynamic
                    v-model="formVals.payment_method"
                    :options="[{ name: 'Personal Card', value: 'personal' }, { name: 'Company Credit Card', value: 'company_credit_card' }]"
                    :option-display-keys="['name']"
                    :option-val="'value'"
                    :empty-option-text="'Fetching items...'"
                    :label="'Payment Method'"
                  />
                </div>

                <div
                  v-if="formVals.payment_method === 'company_credit_card'"
                  class="form-group"
                >
                  <label>Vendor</label><span class="required-star">*</span>
                  <select-field-dynamic
                    :options="vendors"
                    :option-display-keys="['Vendor_Name']"
                    :option-val="'Vendor_Code'"
                    v-model="formVals.vendor_code"
                    placeholder-text="Select a Vendor"
                    :label="'Vendor'"
                    :filter-on-key-val="{key: 'Status', val: 'A'}"
                  />
                </div>

                <label>Listing of Receipt Expenses</label>
                <div class="expenses-table">
                  <!-- <bh-table
                    :table-settings="expenseTableSettings"
                    @edit-item-obj="expenseTableEdit"
                  /> -->

                  <tabulator-table
                    :table-data="expensesList"
                    :table-columns="tableColumns"
                    table-fit="fitColumns"
                    :table-condensed="false"
                    empty-text="(none)"
                    :ref="'expenseTable'"
                    :max-table-height="'300px'"
                    @colClick="tableClick"
                  />

                </div>

                <label>Add Expense</label>
                <div class="btn-group">
                  <button class="btn btn-no-radius btn-blue btn-pip-lg" @click="showExpenseItem('job')">Job</button>
                  <button class="btn btn-no-radius btn-blue btn-pip-lg" @click="showExpenseItem('wo')">WO</button>
                  <button class="btn btn-no-radius btn-blue btn-pip-lg" @click="showExpenseItem('truck')">Work Truck</button>
                  <button class="btn btn-no-radius btn-blue btn-pip-lg" @click="showExpenseItem('personal')">Personal</button>
                </div>
                <br><br>

                <div class="form-group">
                  <label>Sub Total</label>
                  <vue-numeric
                    currency="$"
                    separator=","
                    :class="['form-control']"
                    :precision="2"
                    :read-only-class="'form-control readonly'"
                    :value="subTotalTotal"
                    read-only
                  />
                </div>

                <div class="form-group">
                  <label>Sales Tax</label><span class="required-star">*</span>
                  <vue-numeric
                    currency="$"
                    separator=","
                    :class="['form-control']"
                    :precision="2"
                    :read-only-class="'form-control'"
                    v-model="formVals.sales_tax"
                    inputmode="decimal"
                  />
                </div>

                <div class="form-group">
                  <label>Total</label>
                  <vue-numeric
                    currency="$"
                    separator=","
                    :class="['form-control']"
                    :precision="2"
                    :read-only-class="'form-control readonly'"
                    :value="formVals.sales_tax + subTotalTotal"
                    read-only
                  />
                </div>
              </div>
            </div>

            <div class="form-group dropzone-receipt-file-uploader">
              <label>Receipt File</label>
              <form class="dropzone" id="receiptDrop"></form>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <br></br>
                <button
                  class="btn btn-no-radius btn-full-width btn-pip-lg"
                  @click="save"
                >
                  Submit
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <add-expense-item
      v-if="showExpenseDialog"
      :expense-type-prop="showExpenseDialog"
      :expense-data="expenseData"
      :employee-details="employeeDetails"
      @cancel="showExpenseDialog = null"
      @add="addExpenseItem"
    />

    <waiting-spinner/>

  </div>
</template>
<script>

import store from 'store';
import {mapGetters} from 'vuex';
import appFuncs from 'appFuncs';
import DatePicker from 'components/UIComponents/DatePicker';
import SelectFieldDynamic from 'components/UIComponents/SelectFieldDynamic';
import WOFuncs from 'mixins/WOFuncs';
import JobFuncs from 'mixins/JobFuncs';
import VueNumeric from 'vue-numeric';
import AddExpenseItem from './AddExpenseItem.vue';
import BhTable from 'components/UIComponents/BhTable';
import Dropzone from 'dropzone';
import TabulatorTable from 'components/UIComponents/TabulatorTable';
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  components: {
    DatePicker,
    SelectFieldDynamic,
    VueNumeric,
    AddExpenseItem,
    BhTable,
    TabulatorTable,
    WaitingSpinner
  },

  data() {
    return {
      formVals: {
        sales_tax: 0,
        date: null,
        vendor_code: null,
        company_card: null,
        added_by: null,
        receipts: [],
        company_credit_card_code: null,
        remarks: '', // not sure if this gets into Spectrum
      },
      phases: [],
      expenseType: null,
      fetchedPhaseJobNumber: null,
      expensesList: [],
      showExpenseDialog: null,
      expenseItemTitle: '',
      expenseItems: [],
      equipment: [],
      equipmentList: [],
      paymentMethods: [],
      dzObj: null,
      employeesDetails: [],
      subTotalTotal: 0,
      lastAdded:[]
    };
  },

  props: {},

  mixins: [
    WOFuncs,
    JobFuncs
  ],

  computed: {
    ...mapGetters([
      'employees',
      'woAssemble',
      'jobAssemble',
      'sherAuth',
      'appOptions',
      'vendors'
    ]),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1;
    },

    isPM() {
      return this.$store.getters.userAuthedData.user_role.indexOf('pm') !== -1;
    },

    expenseData() {
      return [
        {
          code: 'jobwo',
          name: 'Select',
          categories: [],
          types: []
        },
        {
          code: 'job',
          name: 'Job Expense',
          types: [
            {
              Name: 'Travel Expenses',
              Code: 5080,
              Cost_Type: 'Q',
              id: 1
            },
            {
              Name: 'Job/WO CBA Travel',
              Code: 5080,
              id: 2,
              Cost_Type: 'Q'
            },
            {
              Name: 'Material',
              Code: 5020,
              id: 3,
              Cost_Type: 'M'
            },
            {
              Name: 'Tool',
              Code: 5021,
              Cost_Type: 'T',
              id: 4
            },
            {
              Name: 'Per Diem',
              Code: 5080,
              Cost_Type: 'Q',
              id: 5
            },]
        },
        {
          code: 'wo',
          name: 'WO Expense',
          types: [
            {
              Name: 'Travel Expenses',
              Code: 5520,
              Cost_Type: 'Q',
              id: 1
            },
            {
              Name: 'Job/WO CBA Travel',
              Code: 5520,
              Cost_Type: 'Q',
              id: 2
            },
            {
              Name: 'Material',
              Code: 5515,
              Cost_Type: 'M',
              id: 3
            },
            {
              Name: 'Tool',
              Code: 5520,
              Cost_Type: 'T',
              id: 4
            },
            {
              Name: 'Per Diem',
              Code: 5520,
              Cost_Type: 'Q',
              id: 5
            },
          ]
        },
        {
          code: 'truck',
          name: 'Work Truck',
          types: [],

          // todo need access to types here

          HCtypes: [{
            Name: 'Fuel',
            Code: 6020,
            Cost_Category: 1,
            Equipment_Category: 'FUEL'
          },
            {
              Name: 'General Upkeep',
              Code: 6030,
              Cost_Category: 7,
              Equipment_Category: 'GENU'
            },
            {
              Name: 'Lube and Oil',
              Code: 6030,
              Cost_Category: 2,
              Equipment_Category: 'LUBE'
            },
            {
              Name: 'Repairs, Upgrade, Other',
              Code: 6030,
              Cost_Category: 4,
              Equipment_Category: 'MAIN'
            },
            {
              Name: 'Parking',
              Code: 6010,
              Cost_Category: 5,
              Equipment_Category: 'PARK'
            },
            {
              Name: '407 or Tolls',
              Code: 6000,
              Cost_Category: 6,
              Equipment_Category: 'TOLL'
            }],
          equipmentList: this.equipmentList
        },
        {
          code: 'personal',
          name: 'Personal',
          types: [
            {
            Name: 'Small Tools',
            Code: 6080,
            type: 1
            },
            {
              Name: 'Tool Allowance',
              Code: 6081,
              type: 13
            },
            {
              Name: 'Boot Allowance',
              Code: 6084,
              type: 12
            },
            {
              Name: 'Uniform',
              Code: 6085,
              type: 2
            },
            {
              Name: 'Safety PPE',
              Code: 6049,
              type: 3
            },
            {
              Name: 'Fuel',
              Code: 6021,
              type: 4
            },
            {
              Name: '407',
              Code: 6021,
              type: 5
            },
            {
              Name: 'Meals and Entertainment',
              Code: 7320,
              type: 6
            },
            {
              Name: 'Travel Expense',
              Code: 7025,
              type: 7
            },
            {
              Name: 'General Computer Expenses',
              Code: 7340,
              type: 8
            },
            {
              Name: 'Office Supplies',
              Code: 7330,
              type: 9
            },
            {
              Name: 'Shop Supplies',
              Code: 6070,
              type: 10
            },
            {
              Name: 'Automation Software and Cables',
              Code: 6065,
              type: 11
            }]
        }];
    },

    expenseTableSettings() {
      return {
        tableData: this.expensesList,
        hideAllSearch: true,
        hideTip: true,
        fields: {
          fullDesc: {
            name: 'Expense Type'
          },
          sub_total: {
            name: 'Sub Total'
          },
          description: {
            name: 'Description',
            class: 'wide-medium'
          },
        }
      }
    },

    employeeDetails() {
      return this.employeesDetails.length ? this.employeesDetails.find(itm => itm.Employee_Code == this.formVals.added_by) : {};
    },

    tableColumns() {
      return [
        {
          title: 'Expense Type',
          field: 'fullDesc',
          width: 150,
          responsive: 0,
          formatter: 'textarea'
        },
        {
          title: 'Sub Total',
          field: 'sub_total',
          width: 110,
          responsive: 0
        },
        {
          title: 'Details',
          field: 'description',
          width: 150,
          responsive: 0,
          formatter: 'textarea'
        },
        {
          title: '',
          field: 'delete',
          titleFormatter: (val) => {
            return '';
          },
          formatter: (val) => {
            // return '<i class="ti ti-close" />';
            return '<span type="button" style="width:100%;font-size:28px;line-height:20px" class="close close-link"><span>×</span></span>';
          },
          width: 60,
          minWidth: 60,
          headerSort: false,
          cssClass: "clickable no-horo-padding no-horo-border",
          frozen: true
        },
        {
          field: 'gl_code',
          visible: false
        },
        {
          field: 'Job_Number',
          visible: false
        },
        {
          field: 'Phase_Code',
          visible: false
        },
        {
          field: 'Cost_Type',
          visible: false
        },
        {
          field: 'Equipment_Code',
          visible: false
        },
        {
          field: 'Equipment_Category',
          visible: false
        },
        {
          field: 'remarks',
          visible: false
        }
      ];
    }
  },

  methods: {
    close() {
      this.$router.go(-1);
    },

    setSubTotal() {
      let table = this.$refs.expenseTable;
      if (!table) return;
      let tableData = table.table.getData();
      let total = 0;
      for (let i = 0; i < tableData.length; i++) {
        total += parseFloat(tableData[i].sub_total);
      }
      this.subTotalTotal = total;
      this.formVals.sales_tax = total * 0.13;
    },

    expenseTableEdit() {
    },

    showExpenseItem(code) {
      this.showExpenseDialog = code;
    },

    addExpenseItem(expense) {

      console.log(expense)

      this.showExpenseDialog = null;
      expense.id = this.randomCharacterString(12);
      let expenseObj = this.expenseData.find(itm => itm.code === expense.code);
      let expenseType = null;

      if (expense.code === 'personal') {
        expenseType = expenseObj.types.find(itm => itm.type === expense.type);
        expense.gl_code = expenseType.Code;
      } else {
        expenseType = expenseObj.types.find(itm => itm.Code === expense.type);
        expense.gl_code = expense.type;
      }

      if (expense.code === 'job') {
        expense.fullDesc = `${expenseObj.name} - ${expense.name} - Job ${expense.Job_Number}`;
      } else if (expense.code === 'wo') {
        expense.fullDesc = `${expenseObj.name} - ${expense.name} - WO ${expense.WO_Number}`;
      } else if (expense.code === 'truck') {
        expense.fullDesc = `${expenseObj.name} - ${expense.Equipment_Category_Description}`;
      } else if (expense.code === 'personal') {
        expense.fullDesc = `${expenseObj.name} - ${expenseType.Name}`;
      } else {
        expense.fullDesc = `${expenseObj.name}`;
      }

      this.$refs.expenseTable.table.addRow(expense);

      this.setSubTotal();
    },

    getEquipment() {
      var params = {
        action: 'get_equipment'
      };

      this.$bus.$emit('setWaiting', {name: params.action, message: 'Fetching equipment'});
      appFuncs.shHttp(params).then(result => {
        if (result.status === 'success') {
          let raw = result.data;
          let formatted = [];
          for (let i = 0; i < raw.length; i++) {
            if (raw[i].Equipment_Type == '2' || raw[i].Equipment_Status !== 'A') continue;
            let desc = typeof raw[i].Description === 'string' && raw[i].Description ? raw[i].Description : '';
            let license = typeof raw[i].License_Number === 'string' && raw[i].License_Number ? ' - ' + raw[i].License_Number : '';
            let model = typeof raw[i].Equipment_Model === 'string' && raw[i].Equipment_Model ? ' ' + raw[i].Equipment_Model : '';
            let make = typeof raw[i].Equipment_Make === 'string' && raw[i].Equipment_Make ? ' - ' + raw[i].Equipment_Make : '';
            let year = typeof raw[i].Year === 'string' && raw[i].Year ? ' - ' + raw[i].Year : '';
            formatted.push({
              Name: desc + license + make + model + year,
              Code: raw[i].Equipment_Code,
            });
          }
          this.equipmentList = formatted;
        } else {
          this.$snack.open(result.message || 'Problem fetching equipment', 'error');
        }
      }).finally(() => {
        this.$bus.$emit('stopWaiting', params.action);
      });
    },

    getPaymentMethods() {
      var params = {
        action: 'get_company_credit_cards'
      };

      this.$bus.$emit('setWaiting', {name: params.action, message: 'Getting Payment Types'});
      appFuncs.shHttp(params).then(result => {
        if (result.status === 'success') {
          this.paymentMethods = result.data;
        } else {
          this.$snack.open(result.message || 'Problem fetching equipment', 'error');
        }
      }).finally(() => {
        this.$bus.$emit('stopWaiting', params.action);
      });
    },

    getDataUrl(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function (event) {
          var base64String = event.target.result;
          var fileName = file.name;
          resolve(base64String);
        };
        reader.readAsDataURL(file);
      })
    },

    async getUploadedFiles() {
      return new Promise(async resolve => {
        let files = this.dzObj.getAcceptedFiles();
        let formattedFiles = [];
        for (let i = 0; i < files.length; i++) {
          let dataUrl = await this.getDataUrl(files[i]);
          // var data = dataUrl.replace(/^data:image\/\w+;base64,/, "");
          let data = dataUrl.split('base64,').pop();
          let type = files[i].type.split('/').pop();
          formattedFiles.push({
            Image_Type: type,
            Image_File: data
          });
        }
        resolve(formattedFiles);
      });
    },

    getExpensesSubTotal() {

    },

    async save() {

      let td = this.$refs.expenseTable.table.getData();

      let requiredFields = ['payment_method', 'date', 'added_by'];
      let valid = true;
      for (let i = 0; i < requiredFields.length; i++) {
        if (!this.formVals[requiredFields[i]]) {
          valid = false;
          break;
        }
      }
      if (!valid) {
        this.$snack.open('One or more required fields are missing', 'warning');
        return;
      }

      if (this.formVals.payment_method === 'company_credit_card') {
        if (!this.formVals.vendor_code) {
          this.$snack.open('Vendor is required if using company credit card', 'warning');
          return;
        }
      }


      let tableData = this.$refs.expenseTable.table.getData();
      this.formVals.entries = Array.isArray(tableData) ? tableData : [];
      const needsReceipts = !this.formVals.entries.every(itm => itm.name === 'Per Diem' || itm.name === 'Job/WO CBA Travel');

      if (needsReceipts) {
        let files = await this.getUploadedFiles();
        if (!files.length) {
          this.$snack.open('Please add one or more receipt files', 'warning');
          return;
        }
        this.formVals.receipts = files;
      }

      if (!this.formVals.entries.length) {
        this.$snack.open('Please add one or more expenses', 'warning');
        return;
      }

      let addedByEmployee = this.employees.find(itm => itm.Employee_Code == this.formVals.added_by);
      this.formVals.added_by_name = addedByEmployee ? addedByEmployee.Employee_Name : '';

      switch (addedByEmployee.default_region) {
        case 'location_hardie':
          this.formVals.cost_center = '0001';
          break;
        default:
          // sheridan
          this.formVals.cost_center = '0000';
          break;
      }

      this.formVals.employee_vendor_code = this.employeeDetails ? this.employeeDetails.vendor_code : '';
      if (!this.formVals.employee_vendor_code && this.formVals.payment_method === 'personal') {
        this.$snack.open('Unable to add personal expense due to employee vendor code missing. Contact office in order to request assignment', 'warning');
        return;
      }

      // Update gl_code, this overcomes issue where select field value is the same GL code for different items
      // this.formVals.entries = this.formVals.entries.map(entry => {
      //   if (entry.gl_code === 'CBATJOB') {
      //     entry.gl_code = '5080';
      //   } else if (entry.gl_code === 'CBATWO') {
      //     entry.gl_code = '5520';
      //   }
      //   return entry;
      // });

      var data = {
        action: 'format_post_expenese_entry',
        data: this.formVals,
      };

      this.$bus.$emit('setWaiting', {name: 'format_post_expenese_entry', message: 'Saving'});
      appFuncs.shHttp(data).then(result => {
        if (result.status === 'success') {
          this.$snack.open('Entry added successfully', 'success');
          this.expensesList = [];
          this.$refs.expenseTable.table.clearData();
          this.setSubTotal();
          this.formVals.sales_tax = 0;
          this.dzObj.removeAllFiles(true);
          this.$bus.$emit('added-expense');
          this.close();
        } else {
          this.$snack.open(result.message || 'Expenses could not be added, please try again later', 'error');
        }
      }).finally(() => {
        this.$bus.$emit('stopWaiting', 'format_post_expenese_entry');
      });
    },

    getVendors() {
      this.$bus.$emit('setWaiting', {name: 'getVendors', message: 'Getting Vendors'});
      this.$store.dispatch('getVendors').then(() => {
      }).finally(() => {
        this.$bus.$emit('stopWaiting', 'getVendors');
      });
    },

    setFileUploader() {
      this.dzObj = new Dropzone('#receiptDrop', {
        url: 'na',
        autoProcessQueue: false,
        acceptedFiles: 'image/*,application/pdf',
        dictDefaultMessage: 'Add receipt image file(s)',
        addRemoveLinks: true
      });
    },

    getEmployeeUdfDetails() {
      var params = {
        action: 'get_employee_app_data'
      };

      this.$bus.$emit('setWaiting', {name: params.action, message: 'Getting Employee details'});
      appFuncs.shHttp(params).then(res => {
        if (res.status === 'success') {
          let employeesDetails = [];
          for (let i = 0; i < res.data.length; i++) {
            employeesDetails.push({
              Employee_Code: res.data[i].employee_code,
              vendor_code: res.data[i].default_vendor_code,
              default_vehicle: res.data[i].default_vehicle,
            });

          }
          this.employeesDetails = employeesDetails;
        } else {
          this.$snack.open(res.message || 'Problem getting employee details', 'error');
        }
        this.$bus.$emit('stopWaiting', params.action);
      }).catch((e) => {
        this.$snack.open(e.message || 'Problem getting employee details', 'error');
        this.$bus.$emit('stopWaiting', params.action);
      });
    },

    tableClick(obj) {
      let id = ((obj || {}).cell || {}).id || 0;
      let field = ((obj || {}).cell || {}).field || 0;
      if (field === 'delete') {
        this.$refs.expenseTable.table.deleteRow(id);
        this.setSubTotal();
      }
    },
  },

  mounted() {
    this.$bus.$emit('setWaiting', {name: 'getEmployees', message: 'Getting Employees'});
    this.$store.dispatch('getEmployees').finally(() => {
      this.$bus.$emit('stopWaiting', 'getEmployees');
    });

    this.getEquipment();

    this.formVals.added_by = this.sherAuth.eid;

    this.getVendors();

    this.setFileUploader();

    this.getEmployeeUdfDetails();

  },

  watch: {
    'formVals.payment_method': function (val) {
      if (val === 'company_credit_card') {
        this.getPaymentMethods();
      }
    }
  }
};

</script>

<style lang="scss" scoped>

@import "src/assets/sass/paper/_variables.scss";

.close-link {
  position: relative;
  top: 7px;
  right: 10px;
  z-index: 999;
  font-size: 36px;
}

.upper {
  border-bottom: 1px solid $light-gray;
  margin-bottom: 10px;
}

.btn {
  padding-top: 15px;
  padding-bottom: 15px;
}

.expenses-table {
  margin: 20px 0;
}

.dropzone {
  border: 2px dashed #ababab;
  min-height: 70px;
  border-radius: 5px;
  background: transparent;
  padding: 5px;
  margin-bottom: 5px;
}

</style>

<style>
.dropzone-receipt-file-uploader .dz-preview .dz-progress {
  display: none !important;
}

.dz-remove {
  color: red !important;
}

</style>
