<template>
  <div>
    <div class="row">
      <div class="col col-sm-12">
        <div :id="dzId" class="dropzone" style="margin-bottom: 5px" />
      </div>
    </div>
    <div class="row">
      <div class="col col-sm-12 po-file-container">
        <span v-for="(po, i) in docs" :key="i">
          <span class="has-link doc-item" @click="openUrlIf200(po.externalFileLocation)">{{ po.Image_Description }}</span>
        </span>
      </div>
      <div class="col col-sm-12">
        <button class="btn btn-sm" @click="uploadFile" v-html="'Upload'" :disabled="!jobId" style="margin: 5px 0" />
      </div>
    </div>
  </div>
</template>
<script>
import NavTabs from 'mixins/navTabs'
import appFuncs from 'appFuncs'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import SelectField from 'components/UIComponents/SelectField'

import Dropzone from 'dropzone'
import 'dropzone/dist/dropzone.css'

Dropzone.autoDiscover = false

export default {
  data() {
    return {
      jobError: false,
      tab: 'Plans',
      transactions: [],
      dropzoneSet: false,
      dzObj: null,
      successfulUploads: 0,
      fileCount: 0,
      typeGroup: 'job',
      uploadedDocs: [],
      docs: []
    }
  },

  props: {
    jobId: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    transactionUploadData() {
      return {
        Job_Number: this.jobId,
        Image_Description: 'PO',
        Drawer: 'PURCHASE ORDER',
        Transaction_ID: 'NEW_po123',
        File_Name: 'po',
        Reference_Num: 123,
        Topic: 'PO file'
      }
    },

    dzId() {
      return this.randomCharacterString(12)
    }
  },

  methods: {
    getTransactions() {
      return new Promise((resolve, reject) => {
        if (!this.jobId) {
          reject()
          return
        }
        var data = {
          action: 'get_all_job_transactions',
          job_number: this.jobId
        }

        // this.$bus.$emit('setWaiting', { name: data.action, message: 'Getting transactions' })

        appFuncs
          .shRequest(data)
          .then(data => {
            // @todo filter to po transaction

            this.transactions = data

            const exitingPOTrans = this.transactions.find(itm => {
              return itm.Transaction_Description === 'PO'
            })
            if (exitingPOTrans) {
              this.transactionUploadData.Transaction_ID = exitingPOTrans.ProjectLogTran_Id
              this.transactionUploadData.Reference_Num = exitingPOTrans.Reference_Num
            }
            resolve()
          })
          .catch(res => {
            console.log(res)
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', data.action)
          })
      })
    },

    openUrlIf200(url) {
      fetch(url)
        .then(response => {
          if (response.status === 200) {
            window.open(url, '_blank')
          } else {
            this.$snack.open('This file must be retrived within Spectrum', 'warning')
          }
        })
        .catch(error => {
          console.error('Failed to fetch URL:', error)
          this.$snack.open('This file must be retrived within Spectrum', 'warning')
        })
    },

    setDropzone() {
      if (this.dzObj) return
      var id = 'div#' + this.dzId
      this.dzObj = new Dropzone(id, {
        url: this.$store.getters.urls.backend + 'upload-job-file.php',
        params: {
          name: this.dzId
        },
        parallelUploads: 1,
        // uploadMultiple: true, // creates server error currently
        createImageThumbnails: false,
        dictDefaultMessage: 'Drop or click to upload',
        autoProcessQueue: false,
        addRemoveLinks: true
        // clickable: this.useSimpleUpload ? true : Dropzone.defaultOptions.clickable
        // acceptedFiles: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,application/vnd.ms-powerpoint,application/pdf,image/png,application/rtf,text/plain,application/zip,text/csv,image/bmp,application/epub+zip,video/mpeg,audio/wav,audio/webm,video/webm,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-outlook,application/x-hwp,application/octet-stream,application/x-hwp,application/CDFV2-unknown,message/rfc822',
      })

      this.dzObj.on('addedfile', (file, xhr, formData) => {
        this.fileCount++
        const fileName = file.name.split('.').slice(0, -1).join('.')
        this.transactionUploadData.Image_Description = fileName
      })

      // this is called for each file added
      this.dzObj.on('sending', (file, xhr, formData) => {
        formData.append('path', 'job-uploads/' + this.jobId + '/')
        formData.append('subdir', 'PURCHASE ORDER')
        formData.append('jwt', this.$store.getters.sherAuth.jwt)
        formData.append('user_login', this.$store.getters.sherAuth.user_login)
        formData.append('expires', this.$store.getters.sherAuth.expires)
        formData.append('type_group', this.typeGroup)

        for (let key in this.transactionUploadData) {
          formData.append(`upload_data[${key}]`, this.transactionUploadData[key])
        }
      })

      this.dzObj.on('complete', file => {
        if (this.dzObj.getFilesWithStatus(Dropzone.QUEUED).length > 0) {
          this.dzObj.processQueue() // Process the next file in queue.
        } else {
          this.$bus.$emit('stopWaiting', 'uploadFile')
        }
      })

      this.dzObj.on('success', file => {
        // get the transaction id and ref from the response
        const resp = ((file || {}).xhr || {}).response || null
        const json = JSON.parse(resp)
        const addedTransId = json && json.data ? json.data.Transaction_ID : null
        const addedTransRef = json && json.data ? json.data.Transaction_Reference_Num : null

        // set the transaction id and ref to the selected transaction so that subsequent uploads will use the same transaction. Added to support adding new transaction with files
        if (addedTransId && addedTransRef) {
          this.transactionUploadData.Transaction_ID = addedTransId
          this.transactionUploadData.Reference_Num = addedTransRef
        }

        if (this.dzObj.getFilesWithStatus(Dropzone.QUEUED).length === 0) {
          this.getUploadedDocs()
          this.dzObj.removeAllFiles(true)
          this.$snack.open('File uploaded', 'success')
        }
      })

      this.dzObj.on('error', (file, error, xhr) => {
        // error = typeof error === 'string' ? error : 'Problem uploading file, please try again';
        console.log(file, error, xhr)
        this.$snack.open('File not uploaded', 'warning')
        this.fileCount--
        this.getUploadedDocs()
        this.dzObj.removeAllFiles(true)
      })

      this.dropzoneSet = true
    },

    getUploadedDocs() {
      return new Promise((resolve, reject) => {
        if (!this.jobId) {
          return
        }
        var data = {
          action: 'get_job_documents',
          job_number: this.jobId
        }

        // this.$bus.$emit('setWaiting', { name: data.action, message: 'Getting docs' })

        appFuncs
          .shRequest(data)
          .then(data => {
            this.uploadedDocs = data
            this.docs = this.uploadedDocs.filter(itm => {
              return itm.Drawer === 'PURCHASE ORDER'
            })
            resolve()
          })
          .catch(res => {
            console.log(res)
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', data.action)
          })
      })
    },

    uploadFile() {
      return new Promise(resolve => {
        if (this.dzObj.getFilesWithStatus(Dropzone.QUEUED).length === 0) {
          resolve()
          return
        }
        this.dzObj.processQueue()
        this.$bus.$emit('setWaiting', { name: 'uploadFile', message: 'Uploading file' })
        this.dzObj.on('complete', file => {
          if (this.dzObj.getFilesWithStatus(Dropzone.QUEUED).length === 0) {
            resolve()
          }
        })
      })
    }
  },

  mounted() {
    this.setDropzone()
    if (this.jobId) {
      this.getTransactions()
      this.getUploadedDocs()
    }
  },

  watch: {
    jobId() {
      if (this.jobId) {
        this.getTransactions()
        this.getUploadedDocs()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.po-file-container {
  .doc-item {
    padding: 3px 4px;
    border: 1px solid;
    border-radius: 5px;
    margin: 5px 5px 5px 0;
    cursor: pointer;
    display: inline-block;
  }
}

#download-form {
  display: none;
}

.dropzone {
  padding: 10px !important;
}

.file-upload-container {
  padding: 0px 0 0;
  position: relative;
  min-height: 60px;
  z-index: 1;

  h4 {
    font-size: 16px;
    margin: 5px;
  }

  .file-upload-inner {
    padding: 0px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin: 5px 0 0;
  }

  ul {
    padding: 0;
    list-style-position: inside;
    margin: 0 0 5px;
    display: block;

    li {
      display: inline-block;
      border: 1px solid #9c9c9c;
      padding: 3px 8px;
      margin: 3px 6px 3px 0px;
      border-radius: 5px;
      position: relative;

      .file-delete {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        right: -8px;
        top: -6px;
        text-align: center;
        vertical-align: middle;
        line-height: 12px;
        cursor: pointer;
        background: #ff4343;
        display: none;

        &:hover {
          display: block;
        }

        i {
          font-size: 7px;
          color: #fff;
        }
      }

      &:hover {
        border-color: #555;

        .file-delete {
          display: block;
        }

        a {
          color: #333;
        }
      }
    }
  }

  .label-link {
    float: right;
    font-size: 14px;
    margin-top: 4px;
    margin: 0 5px 0 10px;
  }

  a {
    cursor: pointer;
    color: #555;
  }

  .no-files-text {
    font-size: 12px;
  }
}

.dropzone {
  border: 2px dashed #ababab;
  min-height: 70px;
  border-radius: 5px;
  background: transparent;
  padding: 5px;
  margin-bottom: 5px;
  position: relative;
  z-index: 1;
}

.area-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.display-as-button {
  margin: 10px 0 !important;

  > div,
  .dz-preview {
    display: none !important;
    visibility: hidden !important;
    margin: 10px 0 !important;
  }

  &.dz-drag-hover {
    border: 2px dashed #ababab !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    display: block;
    border-radius: 5px;
  }
}
</style>
