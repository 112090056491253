<template>
  <div>
    <div class="max-width-xlarge">
      <div class="row">
        <div class="col-md-3 col-lg-2" v-show="isAdmin">
          <set-view-as-employee />
        </div>
        <div class="col-md-3 col-lg-2">
          <select-department-as-employee :value="selectedDepartment" :is-show-label="true" :employee-id="viewAsEmployeeId" @selectItem="clearViewAsEmployee" />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <widget-projects-by-department v-if="isViewAsEmployeePM" />
          <widget-quotes-by-department v-if="isViewAsEmployeePM" />
          <widget-billings-by-department v-if="isViewAsEmployeePM" />
          <widget-hours-tracker :employee-id="viewAsEmployeeId" :show-department-selector="false" />
        </div>
      </div>

      <widget-my-missing-pos />
      <!-- <WidgetPmApprovals v-if="isPM || isAdmin" @filter-pm="routeTimeApprovals" /> -->

      <widget-my-jobs />
      <widget-jobs-w-os-report-sent />
      <widget-my-wos />
      <widget-my-quotes v-if="isPM || isAdmin" :show-statuses="['Quote in Progress', 'Re-Opened - Not Sent']" />
      <widget-my-quotes v-if="isPM || isAdmin" :show-statuses="['Quote in Progress', 'Re-Opened - Not Sent']" :filter="'overdue'" />
      <!-- <widget-my-wo-status-notes /> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import WidgetMyJobSignatures from 'components/Dashboard/Widgets/WidgetMyJobSignatures'
import WidgetMyWoSignatures from 'components/Dashboard/Widgets/WidgetMyWOSignatures'
import WidgetMyWoStatusNotes from 'components/Dashboard/Widgets/WidgetMyWOStatusNotes'
import WidgetMyWos from 'components/Dashboard/Widgets/WidgetMyWOs'
import WidgetMyJobs from 'components/Dashboard/Widgets/WidgetMyJobs'
import WidgetMyQuotes from 'components/Dashboard/Widgets/WidgetMyQuotes2'
import WidgetMyMissingPos from 'components/Dashboard/Widgets/WidgetMyMissingPOs'
import SetViewAsEmployee from 'components/Dashboard/Widgets/SetViewAsEmployee'
import WidgetJobsWOsReportSent from './Dashboard/Widgets/WidgetJobsWOsReportSent.vue'
import WidgetPmApprovals from 'components/Dashboard/Widgets/WidgetPmApprovals.vue'
import SelectDepartmentAsEmployee from 'components/Dashboard/Widgets/SelectDepartmentAsEmployee.vue'
import WidgetQuotesByDepartment from 'components/Dashboard/Widgets/WidgetQuotesByDepartment.vue'
import WidgetBillingsByDepartment from 'components/Dashboard/Widgets/WidgetBillingsByDepartment.vue'
import WidgetProjectsByDepartment from 'components/Dashboard/Widgets/WidgetProjectsByDepartment.vue'
import WidgetHoursTracker from 'components/Dashboard/Widgets/WidgetHoursTracker.vue'

export default {
  components: {
    WidgetMyJobSignatures,
    WidgetMyWoSignatures,
    WidgetMyWoStatusNotes,
    WidgetMyMissingPos,
    WidgetMyWos,
    WidgetMyJobs,
    WidgetMyQuotes,
    SetViewAsEmployee,
    WidgetJobsWOsReportSent,
    WidgetPmApprovals,
    SelectDepartmentAsEmployee,
    WidgetQuotesByDepartment,
    WidgetBillingsByDepartment,
    WidgetProjectsByDepartment,
    WidgetHoursTracker
  },

  data() {
    return {
      msg: 'Welcome to the App Interface, select an action from the main menu.'
    }
  },

  computed: {
    ...mapGetters(['userAuthedData', 'selectedDepartment', 'viewAsEmployeeId', 'employees']),

    isAdmin() {
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPM() {
      return this.userAuthedData.user_role.indexOf('pm') !== -1
    },

    isViewAsEmployeePM() {
      if (!this.viewAsEmployeeId) {
        return false
      }

      const employee = this.employees.find(e => e.id == this.viewAsEmployeeId)
      return employee?.roles?.indexOf('pm') !== -1
    }
  },

  methods: {
    async getData() {
      this.$bus.$emit('setWaiting', {
        name: 'getJobAssemble',
        message: 'Getting Data'
      })
      const jobs = await this.$store.dispatch('getJobAssemble', {
        not_status: 'C'
      })
      const wos = await this.$store.dispatch('getWoAssemble')
      Promise.all([jobs, wos])
        .then(() => {
          this.$bus.$emit('stopWaiting', 'getJobAssemble')
        })
        .catch(err => {
          this.$bus.$emit('stopWaiting', 'getJobAssemble')
          this.$snack.open(err.message || 'Problem getting data', 'error')
          console.log(err)
        })
    },

    routeTimeApprovals(pm_code) {
      this.$router.push(`/app/time-approval/${pm_code}`)
    },

    clearViewAsEmployee(val) {
      console.log('clearViewAsEmployee', val)
      if (this.isAdmin) {
        this.$store.commit('viewAsEmployeeId', null)
      }
    }
  },

  mounted() {
    this.$store.commit('selectedDepartment', null)
    this.$bus.$on('signLinkSent', this.getData)
    this.getData()
    this.$store.dispatch('getEmployees')
  },

  beforeDestroy() {
    this.$bus.$off('signLinkSent', this.getData)
  }
}
</script>

<style scoped>
.sh-widget {
  width: 700px;
  max-width: 100%;
  max-height: 370px;
  overflow: hidden;

  @media (min-width: 900px) {
    /* height: 370px; */
  }
}
</style>
