<template>
  <!-- <div
    :style="{ overflowY: 'scroll', minHeight: '50px', maxHeight: maxTableHeight }"
    :class="['show-scrollbars', { 'table-condensed': tableCondensed }, { 'table-striped': tableStriped }, { 'table-bordered': tableBordered }]"
    :id="tableId"
  /> -->

  <div class="table-tiles">
    <div v-for="(row, rowIndex) in data" :key="'row-' + rowIndex" class="card card-light">
      <div class="content">
        <div v-for="(col, colIndex) in tableColumns" :key="'col-' + colIndex + '-' + rowIndex">
          <div v-if="row[col.field] && col.displayType === 'header'">
            <div class="header-row">
              <div class="tab-header">
                <span v-if="!col.hideName(col.field, row)">{{ col.title || col.value }}</span> {{ callFormatter(row, col) }}
              </div>
              <div v-if="selectable" class="selectable-row">
                <checkbox2 v-model="row.selected" :true-val="'yes'" :false-val="'no'" />
              </div>
            </div>
          </div>
        </div>
        <div @click="$emit('colClick', row)">
          <div v-for="(col, colIndex) in tableColumns" :key="'col3-' + colIndex + '-' + rowIndex">
            <div v-if="row[col.field] && !col.displayType && col.visible !== false">
              <strong v-if="!col.hideName(col.field, row)">{{ col.title || col.value }}: </strong> {{ callFormatter(row, col) }}
            </div>
          </div>
          <div v-for="(col, colIndex) in tableColumns" :key="'col2-' + colIndex + '-' + rowIndex">
            <div v-if="row[col.field] && col.displayType === 'lowerRight'">
              <div style="text-align: right; font-weight: bold; border-top: 1px solid #ccc; margin-top: 5px; padding-top: 5px" class="text-blue">
                <span v-if="!col.hideName(col.field, row)">{{ col.title || col.value }}</span> {{ callFormatter(row, col) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox2 from 'components/UIComponents/Checkbox'

export default {
  name: 'TileTables',
  data() {
    return {
      tableId: '',
      table: null,
      lastEditLocation: {
        row: 0,
        col: null
      },
      selectedRows: [],
      allSelected: false,
      data: []
    }
  },

  components: {
    Checkbox2
  },

  props: {
    tableData: {
      type: Array,
      required: true
    },
    tableColumns: {
      type: Array,
      required: true
    },
    allowNewRow: {
      type: Boolean,
      default: false
    },
    inlineEditRowValidate: {
      type: Array,
      default: function () {
        return []
      }
    },
    tableHeight: {
      type: [Number, String, Object],
      default: '100%'
    },
    maxTableHeight: {
      type: [String, Number],
      default: '100%'
    },
    tableFit: {
      type: String,
      default: 'fitData'
    },
    tableCondensed: {
      type: Boolean,
      default: false
    },
    tableStriped: {
      type: Boolean,
      default: false
    },
    tableBordered: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    emptyText: {
      type: String,
      default: '(empty)'
    },
    initialSort: {
      type: Array,
      default: function () {
        return []
      }
    },
    rowFormatter: {
      type: Function,
      default: function () {}
    },
    responsiveLayout: {
      type: String,
      default: ''
    },
    dataTree: {
      type: Boolean,
      default: false
    },
    headerVisible: {
      type: Boolean,
      default: true
    },
    dataTreeStartExpanded: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    // selectedRows() {
    //   return this.tableData.filter(row => row.selected === 'yes')
    // }
  },

  methods: {
    updateCol(id, col, val, rowData) {
      id = id === 'new' ? 0 : id
      this.$emit('updateCol', { id, col, val, rowData })
    },

    // inSelectedRows(id) {
    //   let obj = this.selectedRows.find(row => row.id == id)
    //   let res = obj && obj.selected === 'yes' ? 'yes' : 'no'
    //   console.log(res)
    //   return res
    // },

    // setSelected(id, isSelected) {
    //   // Find the row by ID and update its selected state
    //   const rowIndex = this.data.findIndex(row => row.id === id)
    //   if (rowIndex !== -1) {
    //     this.tableData[rowIndex].selected = isSelected ? 'yes' : 'no'
    //   }
    // },

    callFormatter(row, col) {
      if (col.formatter && typeof col.formatter === 'function') {
        // Our provided formatter expects an object with a getValue method
        return col.formatter({ getValue: () => row[col.field] })
      }

      if (col.mutator && typeof col.mutator === 'function') {
        return col.mutator(row[col.field], row)
      }
      // If no formatter, return the original value
      return row[col.field]
    },

    setTable() {},

    addRow(data) {},

    getSelectedData() {
      return this.data.filter(row => row.selected === 'yes')
    },

    cellClick(e, cell) {
      const rowData = cell.getData()
      const rowIndex = cell?.getRow()?.getIndex()
      let obj = {
        id: rowData ? rowData.id : null,
        field: cell.getField(),
        value: cell.getValue(),
        rowIndex
      }
      this.$emit('colClick', { cell: obj, row: rowData })
    },

    editColumn(data) {
      const rowData = data.getData()
      let id = rowData ? rowData.id : null
      const field = data.getField()
      const value = data.getValue()

      for (var i = 0; i < this.inlineEditRowValidate.length; i++) {
        if (field === this.inlineEditRowValidate[i]) {
          if (!value) {
            this.$snack.open('Column cannot be empty', 'warning')
            return
          }
        }
      }

      this.lastEditLocation = {
        row: id,
        col: field
      }

      if (!id) {
        this.$snack.open('Problem updating data, please reload to try again.', 'warning')
        return
      }
      this.updateCol(id, field, value, rowData)
    },

    updateTable() {
      if (this.table) {
        this.lastEditLocation = {}
        this.table.setData(this.tableData)
        this.table.setSort(this.initialSort)
      }
    },

    checkAddRow() {},

    listenSelectAll() {
      this.allSelected = !this.allSelected
      for (let i = 0; i < this.data.length; i++) {
        this.data[i].selected = this.allSelected ? 'yes' : 'no'
      }
    }
  },

  mounted() {
    this.$bus.$on('tab-tiles-select-all-toggle', this.listenSelectAll)
  },

  watch: {
    tableData: {
      handler() {
        this.data = this.tableData.map(row => {
          return {
            ...row,
            selected: 'no'
          }
        })
      },
      deep: true
    }
  },

  beforeDestroy() {
    this.$bus.$off('tab-tiles-select-all-toggle', this.listenSelectAll)
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.card-light {
  // background: #f5f5f5 !important;
  border: 1px solid #ddd;
}

.header-row {
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  padding-bottom: 5px;

  .tab-header {
    font-size: 15px;
    font-weight: bold;
    width: calc(100% - 26px);
    display: inline-block;
  }

  .selectable-row {
    width: 22px;
    display: inline-block;

    .checkbox-container {
      margin: 0;
    }
  }
}
</style>
