<template>
  <div>
    <div class="">
      <div v-if="showTitle">
        <h4 v-if="jobNumber">
          <strong>Job {{ jobNumber }}</strong>
        </h4>
        <h4 v-if="woNumber">
          <strong>WO {{ woNumber }}</strong>
        </h4>
      </div>

      <div class="row" style="padding: 20px 0 0">
        <div class="col-sm-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Billing Task Status</label>
            <select-field :option-display-keys="['name']" :option-val="'value'" v-model="billingTaskStatus" :allow-clear="true" :options="billingTaskStatusOptions" />
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Billing Status</label>
            <select-field :option-display-keys="['name']" :option-val="'value'" v-model="taskStatus" :allow-clear="true" :options="statusOptions" />
          </div>
        </div>
      </div>
      <div class="row" style="">
        <div class="col-sm-12">
          <tabulator-table
            :table-data="filteredTasks"
            :table-columns="tableColumns"
            table-fit="fitColumns"
            :table-condensed="false"
            empty-text="(none)"
            @colClick="colClick"
            :rowFormatter="rowFormatter"
            :initial-sort="[
              {
                column: 'date_due',
                dir: 'desc'
              }
            ]"
          />
        </div>
      </div>
      <div class="row" style="padding: 20px 0 0">
        <div class="col-sm-12">
          <div>
            <button class="btn" @click="addTask">Add Task</button>
            <!-- <button class="btn btn-danger" @click="$bus.$emit('modalClose', 'BillingTaskAdd')">Cancel</button>
          <button class="btn" @click="viewTasks">View Tasks</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import billingFuncs from 'mixins/billingFuncs'

export default {
  name: 'BillingTasks',
  data() {
    return {
      billingInstructions: '',
      approvalStatus: '',
      taskStatus: 'O',
      taskType: null,
      taskAssignment: null,
      billingTaskStatus: null,
      data: []
    }
  },

  components: {
    SelectField: () => import('components/UIComponents/SelectField'),
    TabulatorTable: () => import('components/UIComponents/TabulatorTable')
  },

  props: {
    woNumber: {
      type: [String, Number],
      default: null
    },
    jobNumber: {
      type: [String, Number],
      default: null
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },

  mixins: [billingFuncs],

  computed: {
    ...mapGetters(['employees', 'vendorContacts', 'urls', 'vendors']),

    assignList() {
      return []
    },

    tableColumns() {
      return [
        {
          field: 'id',
          visible: false
        },
        {
          title: 'Billing Task',
          field: 'task_type',
          width: 200,
          cssClass: 'is-link',
          formatter: cell => {
            const value = cell.getValue()
            const task = this.billingTasks.find(emp => emp.value == value)
            return task ? task.name : value
          }
        },
        {
          title: 'Billing Instructions',
          field: 'billing_instructions',
          width: 400,
          cssClass: 'is-link'
        },
        {
          title: 'Billing Agent',
          field: 'task_assignment',
          width: 200,
          formatter: cell => {
            const value = cell.getValue()
            const employee = this.employees.find(emp => emp.Employee_Code == value)
            return employee ? employee.Employee_Name : value
          }
        },
        {
          title: 'Billing Status',
          field: 'status',
          width: 120,
          formatter: cell => {
            const value = cell.getValue()
            const statusOption = this.statusOptions.find(option => option.value === value)
            return statusOption ? statusOption.name : value
          }
        },
        {
          title: 'Billing Task Status',
          field: 'status',
          width: 120,
          formatter: cell => {
            const value = cell.getValue()
            const billingTaskStatus = this.billingTaskStatusOptions.find(option => option.value === value)
            return billingTaskStatus ? billingTaskStatus.name : value
          }
        },
        {
          title: 'Create Date',
          field: 'date_create',
          width: 150,
          formatter: cell => {
            const value = cell.getValue()
            return this.formatDate(value)
          }
        },
        {
          title: 'Due Date',
          field: 'date_due',
          width: 150,
          formatter: cell => {
            const value = cell.getValue()
            return this.formatDate(value)
          }
        },
        {
          title: 'Days Overdue',
          field: 'days_overdue',
          width: 150
        }
      ]
    },

    filteredTasks() {
      let data = Array.isArray(this.data) ? JSON.parse(JSON.stringify(this.data)) : []
      data = data.filter(itm => {
        const taskStatusMatch = !this.taskStatus || itm.status === this.taskStatus
        const billingTaskStatusMatch = !this.billingTaskStatus || itm.status === this.billingTaskStatus
        return taskStatusMatch && billingTaskStatusMatch
      })

      return data.map(itm => ({
        ...itm,
        days_overdue:
          itm.date_due && itm.status === 'O'
            ? moment(new Date())
                .startOf('day')
                .diff(moment(itm.date_due * 1000).startOf('day'), 'days')
            : ''
      }))
    }
  },

  methods: {
    rowFormatter(row) {
      const data = row.getData()
      const daysOverdue = data.days_overdue
      const rowEl = row.getElement()

      // Remove existing color classes
      rowEl.classList.remove('danger-color', 'warning-color', 'success-color')

      if (daysOverdue !== '' && data.status === 'O') {
        const days = Number(daysOverdue)
        if (days > 0) {
          rowEl.classList.add('danger-color')
        } else if (days === 0) {
          rowEl.classList.add('warning-color')
        } else {
          rowEl.classList.add('success-color')
        }
      }
    },

    colClick(obj) {
      const col = ((obj || {}).cell || {}).field || null
      const phaseCode = ((obj || {}).row || {}).Phase_Code || null
      const costType = ((obj || {}).row || {}).Cost_Type || null
      const id = ((obj || {}).row || {}).id || null

      if (col === 'task_type' || col === 'billing_instructions') {
        this.openTask(id)
      }
    },

    openTask(id) {
      this.$Modal({
        parent: this,
        name: 'BillingTask', // used for closing specific modal programmatically
        size: 'xxl', // sm, md, lg, xl
        title: '',
        contentClasses: 'smoke-bg',
        hideClose: true,
        component: () => import('src/components/Dashboard/Billing/BillingTask.vue'),
        props: {
          id
        }
      })
    },

    getTasks() {
      if (!this.woNumber && !this.jobNumber) {
        this.$snack.open('Job or WO must be selected to fetch', 'error')
        return
      }

      const params = {
        action: 'get_billing_tasks',
        WO_Number: this.woNumber,
        Job_Number: this.jobNumber
      }

      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Getting Tasks'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.data = data
        })
        .catch(err => {
          console.log(err)
          this.$snack.open(err.message || 'Problem getting tasks', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    addTask() {
      this.$bus.$emit('modalClose', 'BillingTaskAdd')
      this.$bus.$emit('modalClose', 'BillingTasks')
      this.$Modal({
        parent: this,
        name: 'BillingTaskAdd', // used for closing specific modal programmatically
        size: 'lg', // sm, md, lg, xl
        title: `Add Billing Task`,
        component: () => import('src/components/Dashboard/Billing/BillingTaskAdd.vue'),
        props: {
          WO_Number: this.woNumber,
          Job_Number: this.jobNumber
        }
      })
    }
  },

  async mounted() {
    this.$store.dispatch('getEmployees')
    this.getTasks()
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';
</style>

<style lang="scss">
.signature-content {
  img {
    margin: 10px 0;
  }
}
</style>
