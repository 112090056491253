
<div>
  <div class="content">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label for="po_report_send_month_day">Auto Send Month Day</label>
          <p class="sub-label">
            Number from 1-29
          </p>
          <input type="number" class="form-control" name="po_report_send_month_day" v-model="appOptions.po_report_send_month_day" rows="5">
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <span class="inline-checkbox">
            <checkbox v-model="appOptions.po_report_enable_auto_send">
              Enable Report Auto Send
            </checkbox>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label for="po_report_default_text">Report Default Text</label>
          <textarea
            class="form-control"
            name="po_report_default_text"
            rows="5"
            :value="decode(appOptions.po_report_default_text)"
            @input="appOptions.po_report_default_text = encode($event.target.value, 'stripTags')"
          />
        </div>
        <div class="form-group">
          <label for="po_report_email_text">Report Email Text</label>
          <textarea
            class="form-control"
            name="po_report_email_text"
            rows="5"
            :value="decode(appOptions.po_report_email_text)"
            @input="appOptions.po_report_email_text = encode($event.target.value, 'stripTags')"
          />
        </div>
        <input type="submit" class="btn" @click="update()" value="Update">
      </div>
    </div>
    <waiting-spinner/>
  </div>
</div>
