<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      billingTasks: [
        { name: 'Deposit Invoice', value: 'DI' },
        { name: 'Progress Invoice', value: 'PI' },
        { name: 'Bill Complete', value: 'BC' },
        { name: 'Bill Extras', value: 'BE' },
        { name: 'Bill Retention', value: 'BR' }
        // { name: 'Move Hours', value: 'MH' }
      ],
      statusOptions: [
        { name: 'Open', value: 'O' },
        { name: 'Closed', value: 'C' },
        { name: 'Deleted', value: 'D' }
      ],
      subTaskOptions: [
        { name: 'Invoice Approval', value: 'IA' },
        { name: 'Feedback Request', value: 'FR' },
        { name: 'Upload Draw Approval', value: 'UDA' },
        { name: 'Request Files', value: 'RF' },
        { name: 'Missing PO', value: 'MPO' },
        { name: 'Sign Off Missing', value: 'SOM' }

        // { name: 'General File Upload', value: 'GFU' }
      ],
      // billingSubTaskStatusOptions: [
      //   { name: 'Not Started', value: 'NS' },
      //   { name: 'Preparing Invoice', value: 'PI' },
      //   { name: 'Revising Invoice', value: 'RI' },
      //   { name: 'Moving hours', value: 'MH' },
      //   { name: 'Adding Extras', value: 'AE' },
      //   { name: 'Waiting on PM or Captain Approval', value: 'WAPP' },
      //   { name: 'Waiting on Info from PM/Captain', value: 'WINFO' },
      //   { name: 'Waiting on PO', value: 'WOP' },
      //   { name: 'Complete', value: 'C' }
      // ],
      billingTaskStatusOptions: [
        { name: 'None - Initial Creation', value: 'not-started' },
        { name: 'No Billing Agent Assigned', value: 'no-billing-agent-assigned' },
        { name: 'Processing', value: 'processing' },
        { name: 'Rework', value: 'rework' },
        { name: 'Waiting on Info', value: 'waiting-info' },
        { name: 'Waiting on Approval', value: 'waiting-pm-approval' },
        // { name: 'Waiting on Captain Approval', value: 'waiting-captain-approval' },
        { name: 'Approved - Sent for PO', value: 'sent-po' },
        { name: 'Approved - Ready to Send/Upload', value: 'approved' },
        { name: 'Complete - Unposted', value: 'complete-unposted' },
        { name: 'Completed Posted', value: 'complete-posted' },
        { name: 'Cancelled', value: 'Cancelled' }
      ],
      approvalStatuses: [
        { name: 'Pending', value: 'P' },
        { name: 'Waiting on Approval', value: 'waiting-pm-approval' },
        // { name: 'Waiting on Captain Approval', value: 'waiting-captain-approval' },
        { name: 'Approved', value: 'A' },
        { name: 'Edits Required', value: 'ER' },
        { name: 'Rejected - Other', value: 'R' },
        { name: 'Sent', value: 'S' },
        { name: 'Approved - Sent for PO', value: 'sent-po' },
        { name: 'Approved - Ready to Send/Upload', value: 'approved' },
        { name: 'Complete - Unposted', value: 'complete-unposted' },
        { name: 'Completed Posted', value: 'complete-posted' }
      ]
    }
  },

  computed: {
    ...mapGetters(['employees', 'customers']),

    billingEmployees() {
      const employees = this.employees.filter(emp => emp.billing_employee === 'yes')
      return employees
      return [{ Employee_Code: '', Employee_Name: 'Unassigned', Employment_Status: 'A' }, ...employees]
    },

    isBillingUser() {
      return this.billingEmployees.find(emp => emp.Employee_Code == this.userAuthedData.eid) ? true : false
    },

    customerRequiresPO() {
      const customer = this.customers.find(c => c.Customer_Code === this.relatedJobWO.customer_code)
      return customer && customer.does_not_require_po !== 'yes' /// && customer.billing_requires_portal_upload !== 'yes'
    },

    approveOptions() {
      if (!this.relatedJobWO) return []
      const options = []
      const customer = Array.isArray(this.customers) ? this.customers.find(c => c.Customer_Code == this.relatedJobWO?.customer_code) : null
      if (!customer) return []

      const requiresPortalUpload = customer && customer.billing_requires_portal_upload === 'yes' ? true : false
      const billingRequiresPO = false // not using now // customer && customer.billing_requires_po === 'yes' ? true : false
      const hasPO = this.relatedJobWO?.po_number ? true : false

      if (billingRequiresPO && !hasPO) {
        options.push('po')
      } else if (requiresPortalUpload) {
        options.push('portal')
      } else {
        options.push('send')
      }

      return options
    }
  },

  methods: {
    getTaskName(code) {
      const task = this.billingTasks.find(task => task.value === code)
      return task ? task.name : ''
    },
    getTaskStatusName(code) {
      const task = this.billingTaskStatusOptions.find(task => task.value === code)
      return task ? task.name : ''
    }
  }
}
</script>
