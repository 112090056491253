
<div id="alert-main" class="alert-main">
  <div v-if="alerts.show" class="card">
    <div :class="classes" role="alert">
      <span v-html="alerts.message"/>
      <button v-if="!alerts.dismiss || alerts.dismiss === 'manual'" type="button" class="close" @click="closeAlert('x')" aria-label="Close">
        <i class="ti-close"/>
      </button>
    </div>
  </div>
</div>
