<template>
  <div class="form-group" v-click-outside="closeField">
    <label>{{ label }}</label
    ><span v-if="required && !disabled" class="required-star">*</span>
    <div v-if="customerCode">
      <input type="text" class="form-control box-add-item select-field" :value="contact ? contact.Name : ''" @click="openDropdown" readonly :disabled="disabled" />
      <div v-if="showOptions" class="option-box-parent">
        <div class="option-box">
          <input type="text" class="form-control company-filter" v-model="companyFilter" placeholder="Search" />
          <div class="option-items">
            <div v-if="allowClear" class="option-item empty-option-item" @click="selectItem('')">
              <span><span v-html="typeof allowClear === 'string' ? allowClear : 'CLEAR'" /></span>
            </div>
            <div v-for="(cc, key) in filteredContacts" :class="'option-item'" @click="selectItem(cc.sfId)" :key="key">
              {{ cc.Name }}
              <i class="ti ti-pencil pull-right edit-item" @click="editContact(cc.sfId)" />
            </div>
            <div v-if="!customerContacts.length">
              <i>Fetching contacts...</i>
            </div>
            <div v-else-if="!filteredContacts.length">
              <i>No results</i>
            </div>
          </div>
          <span class="add-new" @click="addNewContact" v-if="allowAddNew"> Add New </span>
        </div>
      </div>
    </div>
    <input v-else type="text" class="form-control" value="Please select a Customer" disabled />
  </div>
</template>
<script>
import appFuncs from 'appFuncs'
import { mapGetters } from 'vuex'
import CustomerContactsAddEdit from 'src/components/Dashboard/Contacts/CustomerContactsAddEdit.vue'

export default {
  data() {
    return {
      showOptions: false,
      companyFilter: ''
    }
  },

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    customerCode: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Customer'
    },
    default: {
      type: [String, Number],
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    outputName: {
      type: Boolean,
      default: false
    },
    allowAddNew: {
      type: Boolean,
      default: true
    },
    allowClear: {
      type: [Boolean, String],
      default: false
    }
  },

  computed: {
    ...mapGetters(['customerContacts']),

    contact: {
      get() {
        if (!this.value) return
        let match = this.customerContacts.find(itm => {
          return itm.sfId == this.value
        })
        if (!match) {
          match = this.customerContacts.find(itm => {
            return itm.MYS_ID__c == this.value
          })
        }
        if (!match) {
          match = appFuncs.contactNameToAppId(this.value, this.customerContacts, true)
        }
        return match
      },

      set(val) {
        if (val && val.sfId) {
          this.selectItem(val.sfId)
        }
      }
    },

    customerCodeContacts() {
      if (this.customerCode) {
        return this.customerContacts.filter(itm => {
          return itm.Account_Customer_Code__c == this.customerCode
        })
      }
      return this.customerContacts
    },

    filteredContacts() {
      if (this.companyFilter) {
        return this.customerCodeContacts.filter(itm => {
          let name = itm.Name || ''
          return name.toLowerCase().indexOf(String(this.companyFilter).toLowerCase()) !== -1
        })
      }
      return this.customerCodeContacts
    }
  },

  methods: {
    getData(refresh) {
      this.$bus.$emit('setWaiting', { name: 'cc', message: 'Getting Customer Contacts' })
      this.$store
        .dispatch('getCustomerContacts', refresh)
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'cc')
        })
        .catch(err => {
          console.log('error getting customer contacts', err)
        })
    },

    // needs to be method for v-click-outside
    closeField() {
      this.showOptions = false
    },

    addNewContact() {
      this.$Modal({
        parent: this,
        name: 'customer-contact-add-edit', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        component: CustomerContactsAddEdit,
        props: {
          component: 'customer-contact-add-edit',
          preSelected: {
            Account_Customer_Code__c: this.customerCode || '',
            Company: this.companyName || ''
          }
        }
      })
    },

    editContact(editId) {
      this.$Modal({
        parent: this,
        name: 'customer-contact-add-edit', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        component: CustomerContactsAddEdit,
        props: {
          component: 'customer-contact-add-edit',
          editId
        }
      })
    },

    selectItem(sfId) {
      if (!sfId && !this.value) {
        this.$emit('input', '')
        this.$emit('customerContactSelect', '')
        return
      }
      this.showOptions = false
      if (sfId) {
        let match = this.customerContacts.find(itm => {
          return itm.sfId == sfId
        })
        if (match) {
          let output = this.outputName ? match.Name : sfId
          this.customer = match || {}
          this.$emit('input', output)
          this.$emit('customerContactSelect', match)
        } else {
          this.$emit('input', this.value)
          this.$emit('customerContactSelect', { Name: this.value })
        }
        return
      }

      if (this.customerCodeContacts.length === 1) {
        if (this.outputName) {
          this.$emit('input', this.customerCodeContacts[0]['Name'])
          this.$emit('customerContactSelect', this.customerCodeContacts[0])
        } else {
          let contactId = this.customerCodeContacts[0][sfId]
          if (contactId) {
            this.$emit('input', this.customerCodeContacts[0][sfId])
            this.$emit('customerContactSelect', this.customerCodeContacts[0])
          }
        }
        return
      }

      this.$nextTick(() => {
        const input = this.$el.querySelector('input')

        if (input) {
          input.focus()
        }
      })
    },

    openDropdown() {
      this.showOptions = !this.showOptions
      this.$nextTick(() => {
        const searchInput = this.$el.querySelector('.company-filter')

        if (searchInput) {
          searchInput.focus()
        }
      })
    }
  },

  async mounted() {
    //this.getData();

    this.$bus.$emit('setWaiting', { name: 'cc', message: 'Getting Customer Contacts' })
    await this.$store
      .dispatch('getCustomerContacts')
      .then(() => {
        this.selectItem()
      })
      .catch(err => {
        console.log('error getting customer contacts', err)
      })
    this.$bus.$emit('stopWaiting', 'cc')

    if (this.value) {
      this.selectItem(this.value)
    }

    this.$bus.$on('customer-select-field', cc => {
      this.selectItem(0)
    })
  },

  watch: {
    value(sfId) {
      if (sfId) {
        //this.selectItem(sfId);
      }
    },
    customerCode(newVal, oldVal) {
      // if oldVal (not just setting first loaded val) send 0 in order to clear input if no other matches
      if (oldVal) {
        //this.selectItem(0);
        this.selectItem(this.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-control input {
  padding: 10px;
  display: block;
}

input.select {
  -webkit-appearance: menulist-button;
  text-indent: 10px;
}

.box-add-item {
  cursor: pointer !important;

  &[disabled] {
    cursor: not-allowed !important;
  }
}

.option-box-parent {
  position: relative;
  z-index: 999999;

  .option-box {
    width: 100%;
    position: absolute;
    background: #fff;
    padding: 15px;
    top: -2px;
    box-shadow: 4px 7px 20px 2px rgba(0, 0, 0, 0.08);
    border-radius: 0 0 5px 5px;

    .company-filter {
      margin-bottom: 10px;
    }

    .add-new {
      font-weight: bold;
      cursor: pointer;
      padding: 10px 0 0;
      text-decoration: underline;
    }

    .option-items {
      max-height: 200px;
      overflow-y: scroll;
      background: #f5f5f5;
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 10px;

      .option-item {
        padding: 4px 0;
        color: #555;
        cursor: pointer;

        .edit-item {
          opacity: 0;
        }

        &:hover {
          font-weight: bold;

          & .edit-item {
            opacity: 1;
          }
        }
      }
    }

    .empty-option-item {
      background: #eee;
      border: 1px solid #ddd;
      border-radius: 2px;
      padding: 5px;
      margin-bottom: 5px;

      span {
        font-style: italic;
        letter-spacing: 0.5px;
        font-weight: bold;
      }

      &:after {
        content: '\f057';
        font-family: 'Font Awesome 5 Free';
        float: right;
        font-weight: 900;
      }
    }

    .option-box-add {
      .option-box-add-container {
        position: relative;
        margin-bottom: 10px;

        .option-box-add-input-container {
          margin-right: 70px;

          input {
          }
        }
      }

      button {
        width: 60px;
        position: absolute;
        top: 0;
        height: 40px;
        display: block;
        right: 0;
        padding: 0;
        // border-radius:5px;
      }
    }
  }
}
</style>
