<template>
  <div>

    <div class="message-container">
      <h3>The page you requested requires permission not currently assigned to your user role.</h3>
      <router-link to="/app">Return to Dashboard</router-link>
    </div>

  </div>
</template>
<script>


export default {

  data() {
    return {}
  },
}

</script>

<style lang="scss" scoped>

.message-container {
  padding: 10% 3%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  font-size: 18px;

  h3 {
    font-size: 26px;
  }
}


</style>
