
<div class="customer-ticket" id="customer-ticket">
  <div class="container">
    <div class="row header" v-if="job.Job_Number" id="pdfReady">
      <div class="col-xs-12 col-sm-4">
        <router-link to="/">
          <img :src="`${urls.frontend}static/img/${logoFile}`" />
        </router-link>
      </div>
      <div class="col-xs-12 col-sm-4">
        <p v-html="regionAddress" />
      </div>
      <div class="col-xs-12 col-sm-4 text-right">
        <h4>
          <span v-if="jobExtra.type === 'tm' || jobExtra.type === 'extra tm'"> T&amp;M Job Sign Off </span>
          <span v-else-if="jobExtra.type === 'other'"> Job Other </span>
          <span v-else> Job Extra </span>
          <br />
          <strong> #<span v-html="jobExtraNumber" /> </strong>
        </h4>
      </div>
    </div>

    <div class="row wo-info" v-if="job.Job_Number">
      <div class="col-sm-12 col-md-6">
        <table class="wo-item-list border-bottom">
          <tr>
            <td class="col-medium">
              <strong>Customer Name</strong>
            </td>
            <td>
              <span v-html="job.Customer_Name" />
            </td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Job Number</strong>
            </td>
            <td>
              <span v-html="job.Job_Number" />
            </td>
          </tr>
          <tr></tr>

          <tr>
            <td class="col-medium">
              <strong>Site Address</strong>
            </td>
            <td>
              <span v-if="job.Address_1" v-html="job.Address_1" />
              <br />
              <span v-if="job.City" v-html="job.City" /><span v-if="job.State" v-html="', ' + job.State" /><span v-if="job.Zip_Code" v-html="', ' + job.Zip_Code" />
            </td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Signed Date</strong>
            </td>
            <td>
              <span v-html="dateFromUnix(signOffDate)" />
            </td>
          </tr>
        </table>
        <p class="safety-text">Safety First - Every Time - No Shortcuts</p>
      </div>

      <div class="col-sm-12 col-md-6">
        <table class="wo-item-list">
          <tr>
            <td class="col-medium">
              <strong>Quote Number</strong>
            </td>
            <td>
              <span v-html="jobExtra.quote_number" />
            </td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Customer PO</strong>
            </td>
            <td>
              <span v-if="jobExtra.po">
                {{ jobExtra.po }}
              </span>
              <span v-else>
                {{ job.Contract_Number }}
              </span>
            </td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Requested By</strong>
            </td>
            <td>
              <span v-html="job.Owner_Name" />
            </td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Serviced By</strong>
            </td>
            <td>
              <span v-html="job.Project_Manager_Name" />
            </td>
          </tr>
        </table>
      </div>
    </div>

    <section class="row" v-if="jobExtra.description">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th>Work Summary</th>
          </tr>
          <tr>
            <td v-html="getPlainText(jobExtra.description)" />
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="job_extras_notes.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Work Completed</th>
          </tr>
          <tr v-for="(note, i) in job_extras_notes" :key="i">
            <td class="col-medium">
              <span v-html="note.nice_date" />
            </td>
            <td v-html="getPlainText(note.note_text)" />
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="!hideDetails && !hideLaborDetails && timeEntries && timeEntries.length">
      <div class="col-sm-12">
        <table v-if="totalLaborHours >= 4 || showLaborLog" class="wo-item-list time-log">
          <tr>
            <th class="col-narrow">Date</th>
            <th v-if="showLaborLog">Time Log</th>
            <th>Employee</th>
            <th v-if="showLaborHours">Hours</th>
            <th v-if="showLaborHours">Type</th>
          </tr>
          <tr v-for="(entry, i) in timeEntries" :key="i">
            <td class="col-medium" v-html="entry.entry_date" />
            <td v-if="showLaborLog" v-html="getPlainText(entry.message)" />
            <td v-html="entry.employee_name" />
            <td v-if="showLaborHours" v-html="entry.hours" />
            <td v-if="showLaborHours" v-html="entry.hour_term" />
          </tr>
        </table>
        <table v-if="!showLaborHours" class="wo-item-list time-log">
          <tr>
            <th>Labor</th>
          </tr>
          <tr>
            <td>Standard Service Charge</td>
          </tr>
        </table>
      </div>
    </section>
    <section class="row" v-else-if="!hideDetails && hideLaborDetails && timeEntriesSummary && timeEntriesSummary.length">
      <div class="col-sm-12">
        <table v-if="totalLaborHours >= 4 || showLaborLog" class="wo-item-list time-log">
          <tr>
            <th class="col-narrow">Date</th>
            <th v-if="showLaborLog">Time Log</th>
            <th>Employee</th>
            <th v-if="showLaborHours">Hours</th>
          </tr>
          <tr v-for="(entry, i) in timeEntriesSummary" :key="i">
            <td class="col-medium" v-html="entry.entry_date" />
            <td v-html="entry.employee_name" />
            <td v-if="showLaborLog" v-html="getPlainText(entry.message)" />
            <td v-if="showLaborHours" v-html="entry.hours" />
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="!hideDetails && job_materials_notes.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Materials</th>
          </tr>
          <tr v-for="(note, i) in job_materials_notes" :key="i">
            <td class="col-medium">
              <span v-html="note.nice_date" />
            </td>
            <td v-html="getPlainText(note.note_text)" />
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="!hideDetails && jobExtra.other_charges_list && jobExtra.other_charges_list.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Item #</th>
            <th>Other Charges</th>
          </tr>
          <tr v-for="(entry, i) in jobExtra.other_charges_list" :key="i">
            <td v-html="i + 1" />
            <td v-html="getPlainText(entry.Description)" />
          </tr>
        </table>
      </div>
    </section>

    <section v-if="jobExtra.id">
      <table class="wo-item-list wo-checklist">
        <tr>
          <td><label>Complete</label></td>
          <td class="narrow">
            <i class="ti ti-check" />
          </td>
          <td><label>Quotation</label></td>
          <td class="narrow">
            <i class="ti ti-check" />
          </td>
          <td><label>Recommendation</label></td>
          <td class="narrow">
            <i class="ti ti-check" />
          </td>
          <td><label>Safety</label></td>
          <td class="narrow">
            <i class="ti ti-check" />
          </td>
        </tr>
      </table>
    </section>

    <section>
      <table class="wo-item-list border-bottom" v-if="jobExtra.id">
        <tr>
          <td>
            <p class="satisfaction-text">If for any reason our service did not exceed your expectations please contact us.</p>
          </td>
          <td>
            <div class="signature-display-container">
              <p><label>Customer Signature</label></p>
              <div v-if="jobExtra.id">
                <div class="existing-signature" v-if="existingSig">
                  <img :src="this.urls.mssql + 'job-extra-signatures/' + existingSig" />
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </section>

    <div v-if="pdf" class="footer" style="margin-bottom: 20px">
      <div class="footer-inner">
        <div class="page-number">
          <p>Page 1 of 1</p>
        </div>
      </div>
    </div>

    <section class="not-pdf">
      <div v-if="job.Job_Number">
        <div>
          <button v-if="!isLocked && (jobExtra.status === 'finished' || ['report sent', 'unresponsive'].includes(jobExtra.status))" class="btn not-pdf" @click="showStartSign()" v-html="'Sign Off'" />
          <button
            v-if="!isLocked && sherAuth.eid && (jobExtra.status === 'finished' || ['report sent', 'unresponsive'].includes(jobExtra.status))"
            class="btn not-pdf"
            @click="showSignOffSend()"
            v-html="'Email Sign Off Link'"
          />
          <button v-if="signed && isLocked" class="btn not-pdf" @click="downloadSigned()" v-html="'Download PDF'" />
          <button v-if="isAdmin && existingSig" class="btn not-pdf" @click="saveEmailPDF()" v-html="'PDF Re-Generate'" />
        </div>
      </div>
    </section>
  </div>

  <br />
  <br />
  <br />
  <br />

  <waiting-spinner :wait="wait" />
</div>
