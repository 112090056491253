
<div :class="['modal', 'fade', { 'modal-float': modalFloat }]" tabindex="-1" role="dialog" aria-labelledby="siteModalLabel" data-keyboard="false" data-backdrop="static">
  <div class="modal-underlay" v-if="!modalFloat" />
  <div :class="{ 'center-vertically': centerVertically }">
    <div :class="['modal-dialog', modalSize]" role="document" :style="{ minHeight: minHeight + 'px' }">
      <div :class="['modal-content', contentClasses]">
        <div :class="['modal-body']">
          <div>
            <div class="close-icon" v-if="!hideClose" type="button" @click="close()">
              <span aria-hidden="true">&times;</span>
            </div>
            <h4 v-if="title">{{ title }}</h4>
            <div v-if="content" v-html="content" />
            <component v-else :is="component" v-bind="props" @custom-event="customEvent" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
