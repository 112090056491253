<script>
import store from 'store'
import { mapGetters, mapState } from 'vuex'
import appFuncs from 'appFuncs'
import EventBus from 'eventbus'

export default {
  data() {
    return {
      filterEmployee: '',
      filterStatus: '',
      quoteStatusOptions: [
        { name: 'All' },
        { name: 'Quote in Progress' },
        { name: 'Sent and Pending' },
        { name: 'Re-Opened - Not Sent' },
        { name: 'On Hold - May Proceed at a Later Date' },
        { name: 'On Hold' },
        { name: 'Closed' },
        { name: 'Cancelled - Project Did Not Proceed' },
        { name: 'Cancelled - No Customer Response or Feedback' },
        { name: 'Cancelled - New Quote Created' },
        { name: 'Cancelled - Quote Turned into Job or WO' },
        { name: 'Cancelled - Did Not Quote' },
        { name: 'Cancelled - Other' },
        { name: 'Won' },
        { name: 'Lost' }
      ],
      quoteDepartments: [
        { name: 'Contracting' },
        { name: 'Contracting - Labour Supply' },
        { name: 'Automation' },
        { name: 'Control Panels' },
        { name: 'Water Works' },
        { name: 'GC - Industrial' },
        { name: 'GC - Base Building' },
        { name: 'Data Centers' },
        { name: 'Technical Services' },
        { name: 'Budgeting' },
        { name: 'Change Orders' }
        // { name:'Waste Water' }
      ],
      allQuotes: []
    }
  },

  computed: {
    ...mapGetters(['userAuthedData', 'quoteData', 'viewAsEmployeeId']),

    ...mapState([
      'quoteFilterFiscalYear',
      'quoteFilterStatus',
      'quoteFilterEmployee',
      'quoteFilterEmployeeAny',
      'quoteFilterQuotedBy',
      'quoteFilterCaptain',
      'quoteFilterCustomer',
      'quoteFilterDepartment',
      'quoteFilterSalesPerson',
      'quoteFilterShowCancelled'
    ]),

    isAdmin() {
      return ((this.$store.getters.userAuthedData || {}).user_role || []).indexOf('admin') !== -1
    },

    fiscalYears() {
      return this.getQuoteYears()
    },

    myQuotes() {
      let filtered = []
      let all = this.filterQuoteVersions(this.quoteData)
      for (let i = 0; i < all.length; i++) {
        if (Array.isArray(all[i].quoted_by_id)) {
          if (!all[i].quoted_by_id.includes(this.viewAsEmployeeId) && all[i].sales_lead_id != this.viewAsEmployeeId && all[i].captain != this.viewAsEmployeeId) continue
        } else if (all[i].captain != this.viewAsEmployeeId && all[i].quoted_by_id != this.viewAsEmployeeId && all[i].sales_lead_id != this.viewAsEmployeeId) continue
        filtered.push(all[i])
      }
      return filtered
    },

    myQuoteIds() {
      return this.myQuotes.map(itm => {
        return itm.id
      })
    }
  },

  watch: {
    quoteData: {
      handler(value) {
        let filtered = []
        let all = this.filterQuoteVersions(value)

        for (let i = 0; i < all.length; i++) {
          if (this.quoteFilterEmployee) {
            if (Array.isArray(all[i].quoted_by_id)) {
              if (all[i].captain != this.quoteFilterEmployee && !all[i].quoted_by_id.includes(this.quoteFilterEmployee) && all[i].sales_lead_id != this.quoteFilterEmployee) continue
            } else if (all[i].captain != this.quoteFilterEmployee && all[i].quoted_by_id != this.quoteFilterEmployee && all[i].sales_lead_id != this.quoteFilterEmployee) continue
          }

          if (this.quoteFilterCaptain) {
            if (String(all[i].captain) !== String(this.quoteFilterCaptain)) continue
          }

          if (this.quoteFilterQuotedBy) {
            if (String(all[i].quoted_by_id) !== String(this.quoteFilterQuotedBy)) continue
          }

          if (this.quoteFilterSalesPerson) {
            if (String(all[i].sales_lead_id) !== String(this.quoteFilterSalesPerson)) continue
          }

          if (!this.quoteFilterShowCancelled) {
            if (all[i].status.toLowerCase().includes('cancelled')) continue
          }

          if (Array.isArray(this.quoteFilterEmployeeAny) && this.quoteFilterEmployeeAny.length) {
            let quoteFilterEmployeeAny = this.quoteFilterEmployeeAny.map(String)
            const searchFields = ['captain', 'quoted_by_id', 'sales_lead_id']
            let hasUser = false
            for (let j = 0; j < searchFields.length; j++) {
              if (quoteFilterEmployeeAny.includes(String(all[i][searchFields[j]]))) {
                hasUser = true
                break
              }
            }
            if (!hasUser) continue
          }

          if (Array.isArray(this.quoteFilterStatus) && this.quoteFilterStatus.length) {
            if (!this.quoteFilterStatus.includes(all[i].status)) continue
          }

          if (typeof this.quoteFilterStatus === 'string' && this.quoteFilterStatus) {
            if (['Won', 'Lost'].includes(this.quoteFilterStatus)) {
              if (this.quoteFilterStatus !== all[i].won_lost) continue
            } else if (this.quoteFilterStatus === 'All') {
            } // do nothing
            else if (this.quoteFilterStatus !== all[i].status) continue
          }

          if (this.quoteFilterCustomer) {
            if (this.quoteFilterCustomer != all[i].customer_code) continue
          }

          if (this.quoteFilterDepartment) {
            if (this.quoteFilterDepartment != all[i].job_type) continue
          }

          filtered.push(all[i])
        }

        this.allQuotes = filtered
      },
      immediate: true
    }
  },

  methods: {
    filterQuoteVersions(quotes) {
      var topVersions = []
      // get an array list of all quotes excluding revisions
      var incrNums = this.getQuoteIncrNumbs(quotes)

      for (var i = incrNums.length - 1; i >= 0; i--) {
        // get all versions of this quote number
        var versions = this.getQuoteVersions(incrNums[i], quotes)

        // find the one to show from all versions
        var topVersion = this.findTopQuoteVersion(versions)
        topVersions.push(topVersion)
      }
      return topVersions
    },

    getQuoteIncrNumbs(quotes) {
      var incrs = []
      for (var i = quotes.length - 1; i >= 0; i--) {
        if (incrs.indexOf(quotes[i]['quote_incr']) == -1) {
          incrs.push(quotes[i]['quote_incr'])
        }
      }
      return incrs
    },

    getQuoteYears() {
      let max = parseInt(store.getters.fiscalYear)
      let years = [{ name: 'All', val: 'all' }]
      while (max >= 2018) {
        years.push({ name: max.toString(), val: max.toString() })
        max--
      }
      return years
    },

    getQuoteVersions(incr, quotes) {
      var versions = []
      for (var i = quotes.length - 1; i >= 0; i--) {
        if (quotes[i]['quote_incr'] == incr) {
          versions.push(quotes[i])
        }
      }
      return versions
    },

    findTopQuoteVersion(versions) {
      var topVersion, lastRevision

      // need to re-write this to check all results for prioritized status won or lost then if no results search for use this verison then last revision.

      topVersion = versions.find(itm => {
        return itm.won_lost === 'Won' || itm.won_lost === 'Lost'
      })

      if (topVersion) return topVersion

      topVersion = versions.find(itm => {
        return itm.use_this_revision === 'yes'
      })

      if (topVersion) return topVersion

      for (var i = versions.length - 1; i >= 0; i--) {
        // else find last revision
        var revision = parseInt(versions[i]['revision'])
        if (!lastRevision || revision > parseInt(lastRevision['revision'])) {
          lastRevision = versions[i]
        }
      }

      return lastRevision
    },

    getQuoteVersionsById(id, quotes) {
      if (!id) return []
      var idQuote = quotes.find(item => {
        return item['id'] == id
      })

      if (!idQuote) {
        return
      }
      var incrNum = idQuote['quote_incr']
      var versions = this.getQuoteVersions(incrNum, quotes)
      return versions
    },

    convertMaterialPrice(basedOn, materialUnit, quotedUnit, quotedPrice) {
      materialUnit = materialUnit ? materialUnit.toString().toLowerCase() : ''
      quotedUnit = quotedUnit ? quotedUnit.toString().toLowerCase() : ''

      if (!['Len', 'Cnt'].includes(basedOn)) return 0
      if (!['e', 'c', 'm'].includes(materialUnit)) return 0
      if (basedOn === 'Len' && !['1000m', 'km', '100ft', '100m', '10m', '10ft', 'ft', 'm', '1000ft'].includes(quotedUnit)) return 0
      if (basedOn === 'Cnt' && !['e', '1', '10', '100', '1000'].includes(String(quotedUnit))) return 0

      let price = 0
      let ppfoot = 0
      let pe = 0

      switch (quotedUnit) {
        case 'km':
        case '1000m':
          ppfoot = quotedPrice / 1000 / 3.28084
          break
        case '100m':
          ppfoot = quotedPrice / 100 / 3.28084
          break
        case '10m':
          ppfoot = quotedPrice / 10 / 3.28084
          break
        case 'm':
          ppfoot = quotedPrice / 3.28084
          break
        case '1000ft':
          ppfoot = quotedPrice / 1000
          break
        case '100ft':
          ppfoot = quotedPrice / 100
          break
        case '10ft':
          ppfoot = quotedPrice / 10
          break
        case 'ft':
          ppfoot = quotedPrice
          break
        case 'e':
        case '1':
          pe = quotedPrice
          break
        case '10':
          pe = quotedPrice / 10
          break
        case '100':
          pe = quotedPrice / 100
          break
        case '1000':
          pe = quotedPrice / 1000
          break
      }

      if (basedOn === 'Len') {
        switch (materialUnit) {
          case 'e':
            price = ppfoot
            break
          case 'c':
            price = ppfoot * 100
            break
          case 'm':
            price = ppfoot * 1000
            break
        }
      } else if (basedOn === 'Cnt') {
        switch (materialUnit) {
          case 'e':
            price = pe
            break
          case 'c':
            price = pe * 100
            break
          case 'm':
            price = pe * 1000
            break
        }
      }

      return Math.round(price * 10000) / 10000
    },

    formatMaterialUnit(unit) {
      if (!unit || typeof unit !== 'string') return ''
      switch (unit.toLowerCase()) {
        case 'c':
          return 'C (100)'
          break
        case 'm':
          return 'M (1000)'
          break
        case 'e':
          return 'Each (1)'
          break
      }
    }
  },

  mounted() {
    // listen to event bus to set view employee

    if (this.isAdmin) {
      this.$bus.$on('setViewAsEmployeeId', val => {
        this.$store.commit('viewAsEmployeeId', val)
      })
    }
  }
}
</script>
