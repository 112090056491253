<template>
  <div class="wo-files">
    <div v-if="!woError">
      <div class="">
        <div :class="['tab-section', 'white', { outline: isOutlined }]">
          <ul :class="['nav', 'nav-tabs', { 'always-pilled': stayPilled }]">
            <li v-if="isVisible('Plans')" role="presentation" :class="[{ active: tab == 'Plans' }]">
              <a href="#" @click="tab = 'Plans'"> Plans, Drawings, Other Info </a>
            </li>
            <li v-if="isVisible('PO')" role="presentation" :class="[{ active: tab == 'PO' }]">
              <a href="#" @click="tab = 'PO'"> PO Files </a>
            </li>
            <li v-if="isVisible('Pre-Work')" role="presentation" :class="[{ active: tab == 'Pre-Work' }, { 'requires-item': !checkMetWoRequirement('preWorkPhotos') }]">
              <a href="#" @click="tab = 'Pre-Work'"> Pre-Work Photos </a>
            </li>
            <li v-if="isVisible('Post-Work')" role="presentation" :class="[{ active: tab == 'Post-Work' }, { 'requires-item': !checkMetWoRequirement('postWorkPhotos') }]">
              <a href="#" @click="tab = 'Post-Work'"> Post-Work Photos </a>
            </li>
            <li v-if="isVisible('Sign Off')" role="presentation" :class="[{ active: tab == 'Sign Off' }]">
              <a href="#" @click="tab = 'Sign Off'"> Signed WOs </a>
            </li>
          </ul>
          <div :class="[{ 'first-active': tab === 'Plans' }, 'tab-content']">
            <div v-show="tab === 'Plans'" class="first-active">
              <file-upload :label="''" :path="'wo-uploads/' + woid + '/'" :subdir="'plans'" :zip-depth="-1" :name="'Plans'" :can-delete="true" />
            </div>
            <div v-show="tab === 'Pre-Work'">
              <file-upload
                :label="''"
                :path="'wo-uploads/' + woid + '/'"
                :subdir="'pre-work'"
                :zip-depth="-1"
                :name="'Pre-Work Photos'"
                @updated="getWoRequirementItems('refresh')"
                :can-delete="true"
              />
            </div>
            <div v-show="tab === 'PO'">
              <file-upload :label="''" :path="'wo-uploads/' + woid + '/'" :subdir="'po'" :zip-depth="-1" :name="'PO Files'" :can-delete="true" :Xupload-data="uploadData" />
            </div>
            <div v-show="tab === 'Post-Work'">
              <file-upload
                :label="''"
                :path="'wo-uploads/' + woid + '/'"
                :subdir="'post-work'"
                :zip-depth="-1"
                :name="'Post-Work Photos'"
                @updated="getWoRequirementItems('refresh')"
                :can-delete="true"
              />
            </div>
            <div v-show="tab === 'Sign Off'">
              <file-upload :label="''" :path="'wo-uploads/' + woid + '/'" :subdir="'sign-off'" :zip-depth="-1" :name="'Sign Off'" :can-delete="false" />
            </div>
            <div v-if="!tab">
              <p>Select a file category</p>
            </div>
          </div>
        </div>
      </div>
      <button v-if="inSequence" class="btn" @click="next" v-html="'Next'" />
    </div>
    <p v-else>Problem loading WO Data, please close and continue from WO Details pages.</p>
  </div>
</template>
<script>
import FileUpload from 'components/UIComponents/FileUpload'
import NavTabs from 'mixins/navTabs'
import WOFuncs from 'mixins/WOFuncs'
import appFuncs from 'appFuncs'

export default {
  data() {
    return {
      woError: false,
      tab: ''
    }
  },

  props: {
    woid: {
      type: [Number, String],
      required: true
    },
    inSequence: {
      type: Boolean,
      default: false
    },
    showTabs: {
      type: Array,
      default: function () {
        return []
      }
    },
    stayPilled: {
      type: Boolean,
      default: false
    },
    isOutlined: {
      type: Boolean,
      default: true
    },
    wo: {
      type: Object,
      required: true
    }
  },

  components: {
    FileUpload
  },

  mixins: [NavTabs, WOFuncs],

  computed: {
    uploadData() {
      return {
        WO_Number: this.woid,
        Image_Type: 'PDF',
        Image_Description: 'PO Image',
        Category: 'WO',
        Drawer: 'PURCHASE ORDER'
      }
    }
  },

  methods: {
    next() {
      this.$bus.$emit('modalClose', 'wo-files')
      this.$bus.$emit('modalOpen', {
        component: () => import('src/components/Dashboard/JobWo/WOInfo'),
        size: 'modal-lg',
        name: 'wo-info',
        title: 'Add Notes',
        classes: 'gray-bg',
        componentData: {
          component: 'wo-info',
          woid: this.woid,
          inSequence: true,
          wo: this.wo
        }
      })
    },

    isVisible(name) {
      if (this.showTabs.length) {
        return this.showTabs.indexOf(name) !== -1
      }
      return true
    }
  },

  mounted() {
    if (!this.woid) {
      this.woError = true
    }

    if (!this.showTabs.length) {
      this.tab = 'Plans'
    }

    if (appFuncs.isEmpty(this.woRequirementItems)) {
      this.getWoRequirementItems()
    }
  }
}
</script>

<style lang="scss">
.wo-files {
  .file-upload-container {
  }

  .dropzone {
    //  min-height:200px!important;
    padding: 30px 30px !important;
  }
}
</style>
