
<div class="tab-section white">

  <form id="post-job-note-form">

    <ul class="nav nav-tabs">
      <li role="presentation" v-bind:class="[{active: activeTab == 'job'}]"><a href="#" v-on:click="changeTab('job', $event)">Add Job Note</a></li>
      <li role="presentation" v-bind:class="[{active: activeTab == 'wo'}]"><a href="#" v-on:click="changeTab('wo', $event)">Add Work Order Note</a></li>
    </ul>

    <div :class="['card', {'Main':activeTab == 'job'}]">
      <div class="content">

        <alert/>

        <div v-if="activeTab === 'job'">
          <div v-if="jobSelected" class="job-search-details clearfix">
            <div>
              <label>Customer Name:</label>
              <h4><strong><span v-html="job.Customer_Name"></span></strong></h4>
              <label>Job Description:</label>
              <p><span v-html="job.Job_Description"></span></p>
            </div>
            <button class="btn pull-right" v-on:click="showJobNotes($event)">Show Notes</button>
          </div>
        </div>
        <div v-else-if="activeTab === 'wo' " class="form-group">
          <!--  customer_name, Summary_Description -->
          <div v-if="WOSelected && workOrder.customer_name" class="job-search-details clearfix">
            <div>
              <label>Customer Name:</label>
              <h4><strong><span v-html="workOrder.customer_name"></span></strong></h4>
              <label>Job Description:</label>
              <p><span v-html="workOrder.Summary_Description"></span></p>
            </div>
            <button class="btn pull-right" v-on:click="showWONotes($event)">Show Notes</button>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div v-if="activeTab === 'job'">
              <div class="form-group">
                <label for="job_number">Job Number<span class="required-star">*</span></label>
                <div class="job-number-input-group">
                  <div class="job-number-input">
                    <select-field
                      :options="jobInfo && jobInfo.data ? jobInfo.data : []"
                      :option-display-keys="['Job_Number', 'Customer_Name', 'Job_Description']"
                      :option-val="'Job_Number'"
                      v-model="formVals.job_number"
                      :placeholder-text="'Select Jobs'"
                      :preselect-single-option="false"
                      item-name="Jobs"
                    />
                  </div>
                  <button class="btn btn-small job-search-button" v-on:click="showJobInfo($event)">Search</button>
                </div>
              </div>
            </div>
            <div v-else-if="activeTab === 'wo' " class="form-group">
              <div class="form-group">
                <label for="job_number">Work Order Number<span class="required-star">*</span></label>
                <div class="job-number-input-group">
                  <div class="job-number-input">
                    <select-field
                      :options="WOInfo && WOInfo.data ? WOInfo.data : []"
                      :option-display-keys="['WO_Number', 'customer_name', 'Summary_Description']"
                      :option-val="'WO_Number'"
                      v-model="formVals.work_order_number"
                      :placeholder-text="'Select WO'"
                      :preselect-single-option="false"
                      item-name="WO"
                    />
                  </div>
                  <button class="btn btn-small job-search-button" v-on:click="showWoInfo($event)">Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <label for="note_text">Hours<span class="required-star">*</span></label>
              <hours-picker v-model="formVals.hours"></hours-picker>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <label>Date<span class="required-star">*</span></label>
              <date-picker v-model="formVals.date" :preFilled="true"></date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="inline-radio-container">
              <div><label>Note Category<span class="required-star">*</span></label></div>
              <div class="inline-radios">
                <label for="radio-log" v-bind:class="['radio-inline', {'selected': formVals.category == 'log'}]">
                  <input type="radio" v-model="formVals.category" value="log" id="radio-log">Daily Log
                </label>
                <label for="radio-extras" v-bind:class="['radio-inline', {'selected': formVals.category == 'extras'}]">
                  <input type="radio" v-model="formVals.category" value="extras" id="radio-extras">Extras Log
                </label>
                <label for="radio-delays" v-bind:class="['radio-inline', {'selected': formVals.category == 'delays'}]">
                  <input type="radio" v-model="formVals.category" value="delays" id="radio-delays">Internal Notes
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- <div v-if="noteInstructions" class="note-instructions" v-html="noteInstructions"></div> -->

        <div class="form-group">
          <label for="note_text">Note Text<span class="required-star">*</span></label>
          <textarea class="form-control" id="note_text" name="note_text" rows="6" v-model="formVals.note_text" v-bind:placeholder="noteInstructions"></textarea>
        </div>

        <br>
        <button type="submit" class="btn btn-primary" v-on:click="postNote($event)">Add Note</button>
        <br>
        <br>
      </div>
    </div> <!-- end main card -->

    <waiting-spinner/>

  </form>

  <div class="about-notes">
    <p>Describe in good detail the work completed during the selected date. Recommended time to write a good journal entry 5-10 minutes. Proper grammar, spelling, and overall
      professionalism do matter, this log will be used as supporting documentation for billing so the customer will see what you write.</p>
  </div>

</div>
