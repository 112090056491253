<template>
  <div class="customer-ticket">
    <div class="container" v-if="job.Job_Number">
      <div class="row header">
        <div class="col-xs-12 col-sm-4">
          <router-link to="/">
            <img :src="`${urls.frontend}static/img/${logoFile}`" />
          </router-link>
        </div>
        <div class="col-xs-12 col-sm-8 text-right">
          <h4>
            Job Order:<strong> #{{ job.Job_Number }}</strong>
          </h4>
          <h5>
            Office: <strong>{{ regionTel }}</strong>
          </h5>
        </div>
      </div>

      <div class="row" v-if="job.Customer_Name">
        <div class="col-sm-12">
          <div class="ticket-section">
            <div class="ticket-section--top">
              <label><strong>General</strong></label>
            </div>
            <div class="row ticket-fields">
              <div class="col-sm-12 col-md-6">
                <div class="ticket-field">
                  <label>Customer:</label>
                  <p>{{ job.Customer_Name }}</p>
                </div>
                <div class="ticket-field">
                  <label>Site Address:</label>
                  <p>
                    {{ woSiteAddress }}
                  </p>
                </div>
                <div class="ticket-field">
                  <label>Job Description:</label>
                  <p>{{ job.Job_Description }}</p>
                </div>

                <div class="ticket-field form-group" style="margin-top: 20px">
                  <label>Upload PO file:</label>
                  <file-upload :path="`job-uploads/${job.Job_Number}/`" subdir="po" :auth-code="authCode" :upload-data="uploadData"/>
                </div>
                <div class="ticket-field form-group">
                  <label>Customer PO:</label>
                  <input type="text" v-model="job.po_number" class="form-control" style="max-width: 300px" />
                </div>
                <div :class="'detail-item'">
                  <checkbox2 v-model="job.po_request_muted_by_customer" :true-val="'yes'" :false-val="'no'"> Mute PO Request</checkbox2>
                </div>
                <button @click="updateCustomerProvidedData" class="btn btn-sm" style="margin-top:10px">Save</button>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="ticket-field">
                  <label>Project Lead:</label>
                  <p>{{ job.Project_Manager_Name }}</p>
                </div>
                <div class="ticket-field">
                  <label>Sales Person:</label>
                  <p>{{ pmUser }}</p>
                </div>
                <div class="ticket-field">
                  <label>Quote #:</label>
                  <p>{{ job.quote_number }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="job.Customer_Name">
        <div class="col-sm-12">
          <div class="ticket-section">
            <div class="ticket-section--top">
              <label><strong>Status Details</strong></label>
            </div>
            <div class="row ticket-fields">
              <div class="col-sm-12 col-md-6">
                <div class="ticket-field">
                  <label>Ordered Date:</label>
                  <p v-html="job.Create_Date ? formatDate(job.Create_Date, 1) : 'TBD'" />
                </div>
                <div class="ticket-field">
                  <label>Date Requested:</label>
                  <p v-html="job.Est_Start_Date ? formatDate(job.Est_Start_Date, 1) : 'TBD'" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="ticket-field">
                  <label>Finished Date:</label>
                  <p v-html="job.Complete_Date ? formatDate(job.Complete_Date, 1) : 'TBD'" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="job.Job_Number">
        <div class="col-sm-12" v-for="(note, k) in notes" :key="k">
          <div class="ticket-section" v-if="note.note.length">
            <div class="ticket-section--top">
              <label
                ><strong>{{ note.title }}</strong></label
              >
            </div>
            <div class="row ticket-fields">
              <div class="col-sm-12">
                <div class="ticket-field">
                  <ul class="ticket-notes show-scrollbar" v-if="note.note.length">
                    <li v-for="(n, i) in note.note" :key="i">
                      <p class="note-meta">
                        <strong>{{ n.author_name }} - {{ n.nice_date }}</strong>
                      </p>
                      <p v-html="getPlainText(n.note_text)" class="note-text" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="job.Job_Number && preWorkPhotos && preWorkPhotos.length">
        <div class="col-sm-12">
          <div class="ticket-section">
            <div class="ticket-section--top">
              <label><strong>Pre-Work Photos</strong></label>
            </div>
            <div class="row ticket-fields">
              <div class="col-sm-12">
                <ul class="photos" v-if="preWorkPhotos.length">
                  <li v-for="(photo, p) in preWorkPhotos" :key="p">
                    <a :href="photo.a_attr.href" target="_blank" class="img-container" :style="{ backgroundImage: `url('${photo.a_attr.href}')` }" />
                  </li>
                </ul>
                <p v-else>
                  <span class="empty">(empty)</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="job.Job_Number && postWorkPhotos && postWorkPhotos.length">
        <div class="col-sm-12">
          <div class="ticket-section">
            <div class="ticket-section--top">
              <label><strong>Post-Work Photos</strong></label>
            </div>
            <div class="row ticket-fields">
              <div class="col-sm-12">
                <ul class="photos" v-if="postWorkPhotos.length">
                  <li v-for="(photo, pt) in postWorkPhotos" :key="pt">
                    <a :href="photo.a_attr.href" target="_blank" class="img-container" :style="{ backgroundImage: `url('${photo.a_attr.href}')` }" />
                  </li>
                </ul>
                <p v-else>
                  <span class="empty">(empty)</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="job.Job_Number && signed && signed.length">
        <div class="col-sm-12">
          <div class="ticket-section">
            <div class="ticket-section--top">
              <label><strong>Service Sign-Offs</strong></label>
            </div>
            <div class="row ticket-fields">
              <div class="col-sm-12">
                <ul class="photos" v-if="signed.length">
                  <li v-for="(sjob, pt) in signed" :key="pt">
                    <a :href="sjob.a_attr.href" target="_blank" :class="'pdf-icon'">
                      <img :src="'static/img/pdf-icon.svg'" />
                      {{ sjob.text }}
                    </a>
                  </li>
                </ul>
                <p v-else>
                  <span class="empty">(empty)</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>
<script>

import appFuncs from 'appFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import FileUpload from 'components/UIComponents/FileUpload.vue'
import JobFuncs from 'mixins/JobFuncs'
import Checkbox2 from 'components/UIComponents/Checkbox.vue'

export default {
  components: { WaitingSpinner, FileUpload, Checkbox2 },
  data() {
    return {
      job: {},
      pmUser:'',
      woSiteAddress:'',
      contact: {
        Email: '',
        PhoneMobile: ''
      },
      notes: [],
      customerEdit: false,
      preWorkPhotos: [],
      postWorkPhotos: [],
      emailNotifications: null,
      textNotifications: null,
      signed: [],
    }
  },

  props: {
    authCode: {
      type: String,
      required: true
    }
  },

  mixins: [JobFuncs],

  computed: {

    uploadData() {
      return {
        Job_Number: this.job.Job_Number
      }
    },

    urls() {
      return this.$store.getters.urls
    },

    companyName() {
      const vars = this.getRegionVars(this.job.region)
      return vars.companyName
    },

    logoFile() {
      const vars = this.getRegionVars(this.job.region)
      return vars.logoFile
    },

    regionTel() {
      const vars = this.getRegionVars(this.job.region)
      return vars.tel
    },

  },

  methods: {
    async getData() {
      if (!this.authCode) {
        return
      }

      const params = {
        action: 'get_customer_job_ticket',
        auth_code: this.authCode,
        urldecode: 'false'
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Getting Data' })

      appFuncs
        .shRequest(params, 1)
        .then(data => {
          this.job = data.job
          this.woSiteAddress = data.woSiteAddress
          this.pmUser = data.employee
          this.contact = data.contact_data || {}
          this.notes = [] // clear notes to prevent duplicates when calling get data again.
          this.notes.push({ title: 'Job Other Details', note: ((data.notes || {}).job_other_details_notes || {}).data || [] })
          this.notes.push({ title: 'Job Extras', note: ((data.notes || {}).job_extras_notes || {}).data || [] })
          this.notes.push({ title: 'Recommendations', note: ((data.notes || {}).job_recommendations_notes || {}).data || [] })
          this.preWorkPhotos = (data.preWorkPhotos || []).data || []
          this.postWorkPhotos = (data.postWorkPhotos || []).data || []
          this.signed = (data.signed || []).data || []
          this.setUserOptions()
        })
        .catch(e => {
          this.$snack.open('Problem retrieving Job data, please try again now or later.', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })

    },

    setUserOptions() {
      this.emailNotifications = false
      if (this.job.notifications_email && this.job.notifications_email.length && this.contact.Id) {
        if (this.job.notifications_email.indexOf(this.contact.Id.toString()) !== -1) {
          this.emailNotifications = true
        }
        if (this.contact.MYS_ID__c && this.job.notifications_email.indexOf(this.contact.MYS_ID__c.toString()) !== -1) {
          this.emailNotifications = true
        }
      }

      this.textNotifications = false
      if (this.job.notifications_text && this.job.notifications_text.length && this.contact.Id) {
        if (this.job.notifications_text.indexOf(this.contact.Id.toString()) !== -1) {
          this.textNotifications = true
        }
        if (this.contact.MYS_ID__c && this.job.notifications_text.indexOf(this.contact.MYS_ID__c.toString()) !== -1) {
          this.textNotifications = true
        }
      }
    },

    updateCustomerProvidedData() {
      const params = {
        action: 'update_customer_provided_data',
        auth_code: this.authCode,
        data: {
          job_number: this.job.Job_Number,
          po_number: this.job.po_number,
          po_request_muted_by_customer: this.job.po_request_muted_by_customer,
          from: 'customer_ticket',
          type: 'job'
        }
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Updating customer provided data' })

      appFuncs
        .shRequest(params)
        .then(() => {
          this.$snack.open('Updated')
        })
        .catch(e => {
          this.$snack.open('Problem saving data, please try again later', 'warning')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    getPlainText(text) {
      return appFuncs.getPlainText(text)
    }
  },

  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.header {
  padding: 40px 0 30px;

  img {
    width: 100%;
    max-width: 300px;

    @media (max-width: 767px) {
      margin: 0 auto;
      display: block;
    }
  }

  h4 {
    margin: 20px 0 0;
  }

  h5 {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    > .col-xs-12 {
      text-align: center !important;
    }
  }
}

.customerEdit {
  cursor: pointer;
}

.wo-status-container {
  h3 {
    display: inline-block;
  }

  .wo-status {
    padding: 10px 15px;
    text-align: right;
    font-size: 16px;
    float: right;

    span {
      text-transform: uppercase;
      font-weight: bold;

      &.green {
        color: $success-color;
      }

      &.yellow {
        color: $general-yellow;
      }

      &.red {
        color: $general-red;
      }
    }
  }
}

.ticket-section {
  background: $smoke-bg;
  padding: 20px;
  margin-bottom: 20px;

  &.border-left {
    border-left: 5px solid $dark-gray;
  }

  label {
    display: block;
  }

  &--top {
    border-bottom: 1px solid $grey-border-color;
    margin-bottom: 15px;
    padding-bottom: 5px;

    h5 {
      font-size: 14px;
      margin: 0 0 5px;
    }
  }

  h3 {
    font-size: 18px;
    margin: 10px 0;
  }

  .form-control {
    border: 1px solid;
    background: #fff;
  }

  .checkbox-container .checkbox-group {
    background-color: #fff;
  }

  ul.ticket-notes {
    padding: 0;
    max-height: 300px;
    width: 100%;
    overflow-y: scroll;

    li {
      display: block;

      .note-text {
        border-left: 3px solid $grey-border-color;
        padding: 2px 5px;
        margin-left: 5px;
      }
    }
  }

  .photos {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 5px 0 0;
      width: 175px;
      position: relative;

      .img-container {
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        position: relative;
        background-size: cover;
      }

      @media (max-width: 991px) {
        width: calc(33% - 5px);
      }
      @media (max-width: 767px) {
        width: calc(50% - 5px);
      }
    }
  }

  .empty {
    font-style: italic;
  }
}

.pdf-icon {
  width: 100px;
  height: 100px;
  display: block;
  //background-image:url('/static/img/pdf-icon.svg');
  //background-size:contain;
  margin: 5px;
}
</style>

<style lang="scss">
.ticket-section {
  .checkbox-container .checkbox-group {
    background: #fff;
  }
}
</style>
