
<div class="material-lists-component">
  <h3><strong>RFPs</strong></h3>

  <p class="text-info text-medium">These are lists of materials to streamline obtaining material pricing from one or more vendors.</p>

  <p>
    Click on a list to select or <span class="is-link underlined" @click="openList()"><strong>Add New</strong></span>
    <span style="display: none"
      >Add materials to list by first selecting from
      <span
        class="is-link underlined"
        @click="
          $router.push({ name: 'Materials Admin' })
          $bus.$emit('modalClose', 'RFPLists')
        "
        >main list</span
      ></span
    >
  </p>

  <p v-if="selectedMaterials.length">
    <i
      >There are <strong>{{ selectedMaterials.length }}</strong> material items selected, add them to a list by selecting the plus icon within a list row.</i
    >
  </p>

  <div class="row" style="padding: 10px 0">
    <div class="col-sm-12 col-md-4">
      <div class="form-group">
        <label>List Author</label>
        <select-field
          :options="employees"
          :option-display-keys="['Employee_Name']"
          :option-val="'Employee_Code'"
          v-model="author"
          :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
          allow-clear="All"
        />
      </div>
    </div>
    <div class="col-sm-12 col-md-4">
      <label>Quote Number</label>
      <select-field :options="quoteData" :option-display-keys="['quote_number']" :option-val="'quote_number'" v-model="quoteNumber" allow-clear="Any" />
    </div>
    <div class="col-sm-12 col-md-4">
      <div class="form-group">
        <label>RFP Status</label>
        <select-field
          :options="[
            { name: 'Active', val: 'active' },
            { name: 'Sent', val: 'sent' },
            { name: 'Deleted', val: 'deleted' }
          ]"
          :option-display-keys="['name']"
          :option-val="'val'"
          v-model="rfpStatus"
          @selectItem="getLists"
          allow-clear="Any"
        />
      </div>
    </div>
  </div>

  <div class="">
    <tabulator-table :table-data="filteredLists" :table-columns="tableColumns" @colClick="tableClick" table-fit="fitColumns" :table-condensed="false" empty-text="(none)" :table-striped="true" />
  </div>
  <waiting-spinner :wait="wait" />
</div>
