<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'

export default {
  data() {
    return {
      quoteNumber: '',
      jobRequiresDetailedNotes: null,
      costTypeNames: [
        // { code: 'B', name: 'Labor Burden' }, // not to be selected or visible, sometimes combined w Labor
        { code: 'L', name: 'Labour' },
        { code: 'M', name: 'Material' },
        { code: 'E', name: 'Lift Rental' },
        { code: 'P', name: 'Permits, Insp.' },
        { code: 'T', name: 'Tool Rentals' },
        { code: 'C', name: 'Contract Labor' },
        { code: 'S', name: 'Subcontractor' },
        { code: 'O', name: 'Other' },
        { code: 'Q', name: 'Travel Expenses' },
        { code: 'V', name: 'Service Vehicle' },
        { code: 'X', name: 'Overhead Alloc' },
        { code: 'Z', name: 'Equip Allocated' }
        // { code: 'B', name: 'Labor Burden' }
      ],
      statusNames: [
        { code: 'A', name: 'Active' },
        { code: 'I', name: 'Inactive' },
        { code: 'C', name: 'Complete' }
      ],
      fetchedJobPhases: false,
      jobPhases: [],
      costCenters: [
        // use view eventually ? z_cost_centers
        // { name: 'Corporate', value: '0000' },
        { name: 'Contracting and Large Projects', value: '1000' },
        { name: 'Automation', value: '2000' },
        // { name: 'Panel Shop', value: '3000' },
        // { name: 'Sherd-It Build and Supply', value: '4000' },
        { name: 'Engineering Services & PM', value: '5000' },
        { name: 'Small Projects and Sevice', value: '6000' },
        { name: 'Labor Support', value: '7000' },
        { name: 'Water Group', value: '8000' }
      ],
      jobStages: [
        { code: '1', description: 'TBS' },
        { code: '2', description: 'In Progress' },
        { code: '7', description: 'On Hold' },
        { code: '6', description: 'Cancelled' },
        { code: '3', description: 'Need Signature' },
        { code: '5', description: 'Report Sent' },
        { code: '12', description: 'Unresponsive - No Sign Off' },
        { code: '13', description: 'Signed' },
        { code: '4', description: 'Ok to Bill' }
      ]
    }
  },

  computed: {
    ...mapGetters(['userAuthedData', 'jobAssemble', 'viewAsEmployeeId']),

    isAdmin() {
      if (!this.userAuthedData?.user_role) {
        return false
      }
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    },

    myJobs() {
      const viewAsEmployeeId = this.viewAsEmployeeId

      let myJobs = this.jobAssemble.filter(itm => {
        // if employee is in techs array
        if (Array.isArray(itm.techs) && itm.techs.map(Number).includes(Number(viewAsEmployeeId))) {
          return true
        }

        if (itm.Project_Manager == viewAsEmployeeId || itm.Superintendent == viewAsEmployeeId) {
          return true
        }
      })
      return myJobs
    },

    jobIds() {
      return this.jobAssemble.map(itm => {
        return parseInt(itm.Job_Number)
      })
    },

    myJobIds() {
      let myJobs = this.myJobs.map(itm => {
        return parseInt(itm.Job_Number)
      })

      if (!myJobs.length) {
        // return non existing array val to avoid empty filtering
        return ['NONE']
      }
      return myJobs
    },

    jobExtraStatusOptions() {
      return [
        {
          name: 'Open',
          value: 'open'
        },
        {
          name: 'Finished',
          value: 'finished'
        },
        {
          name: 'Signed Off',
          value: 'signed'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        },
        {
          name: 'Deleted',
          value: 'deleted'
        },
        {
          name: 'Report Sent',
          value: 'report sent'
        },
        {
          name: 'Unresponsive - No Sign Off',
          value: 'unresponsive'
        }
      ]
    }
  },

  methods: {
    validateForSignoff(jobNumber) {
      return new Promise(async resolve => {
        if (!jobNumber) {
          resolve({ status: 'fail', message: 'Missing job number' })
        }
        let missingRequirements = []

        const JobDescriptionNotes = appFuncs
          .shRequest({
            action: 'get_item_notes',
            table: 'JobDescriptionNotes',
            item_id: jobNumber
          })
          .then(data => {
            if (data && data.length < 1) {
              missingRequirements.push('Scope of Work Notes')
            }
          })

        const JobCompletedNotes = appFuncs
          .shRequest({
            action: 'get_item_notes',
            table: 'JobCompletedNotes',
            item_id: jobNumber
          })
          .then(data => {
            if (data && data.length < 1) {
              missingRequirements.push('Work Completed Notes')
            }
          })

        await Promise.all([JobDescriptionNotes, JobCompletedNotes]).catch(err => {
          resolve({ status: 'fail' }, err)
          return
        })

        resolve({ status: 'success', missingRequirements })
      })
    },

    getJobExtraStatusName(statusId) {
      let statusName = ''
      switch (statusId) {
        case 'signed':
          statusName = 'Signed Off'
          break
        case 'finished':
          statusName = 'Finished'
          break
        case 'cancelled':
          statusName = 'Cancelled'
          break
        case 'deleted':
          statusName = 'Deleted'
          break
        case 'report sent':
          statusName = 'Report Sent'
          break
        default:
          statusName = 'Open'
          break
      }
      return statusName
    },

    checkSetJobItems() {
      if (this.jobId && this.jobAssemble.length) {
        const job = this.jobAssemble.find(itm => {
          return itm.Job_Number == this.jobId
        })
        if (job) {
          const requireDetailedNotesSetting = ((job.admin_options || {}).other || {}).require_detailed_notes
          const jobType = job.Price_Method_Code
          if (requireDetailedNotesSetting === 'true') {
            this.jobRequiresDetailedNotes = true
            // if require_detailed_notes is not set and job price type is T&M, set to true for default.
          } else if (requireDetailedNotesSetting !== 'false' && jobType === 'T') {
            this.jobRequiresDetailedNotes = true
          } else {
            this.jobRequiresDetailedNotes = false
          }
          // console.log(jobType)
          // console.log(this.jobRequiresDetailedNotes )
        }
      }
    },

    extractCostCenters(data) {
      // Use a Set to store unique Cost_Center values
      const uniqueCostCenters = new Set()
      // Iterate over the data array and add each Cost_Center value to the Set
      data.forEach(item => {
        if (item.Cost_Center) {
          uniqueCostCenters.add(item.Cost_Center)
        }
      })
      // Convert the Set of unique Cost_Center values to the desired format
      const result = [...uniqueCostCenters].map(costCenter => {
        return { val: String(costCenter).padStart(4, '0') }
      })

      // Sort the result array in ascending order
      result.sort((a, b) => {
        if (a.val < b.val) return -1
        if (a.val > b.val) return 1
        return 0
      })
      return result
    },

    getJobPhases() {
      return new Promise((resolve, reject) => {
        var params = { action: 'get_job_phases_all', job_number: parseInt(this.jobId) }
        this.$bus.$emit('setWaiting', { name: 'get_job_phase', message: 'Getting Job Phases' })

        appFuncs
          .shRequest(params)
          .then(data => {
            for (let i = 0; i < data.length; i++) {
              data[i].id = this.randomCharacterString(12)
              let costName = this.costTypeNames.find(itm => {
                return itm.code == data[i].Cost_Type
              })
              data[i].Cost_Type_Name = costName && costName.name ? costName.name : data[i].Cost_Type
              let statusName = this.statusNames.find(itm => {
                return itm.code == data[i].Status_Code
              })
              data[i].Status_Name = statusName && statusName.name ? statusName.name : data[i].Status_Code
              data[i].Loaded_Status_Code = JSON.parse(JSON.stringify(data[i].Status_Code || ''))
              data[i].editLink = '<span class="is-link underlined">Edit</span>'
              data[i].laborHours = data[i].Estimated_Hours
              data[i].originalCost = data[i].Current_Estimate_Dollars // Projected_Dollars
              data[i].currentHours = data[i].JTD_Hours
              data[i].currentCost = parseFloat(data[i].JTD_Actual_Dollars)
              data[i].costVariance = parseFloat(data[i].JTD_Actual_Dollars) - parseFloat(data[i].Current_Estimate_Dollars)
            }

            for (let i = 0; i < data.length; i++) {
              if (data[i].Cost_Type === 'B') {
                const phaseNumber = data[i].Phase_Code
                const matchingPhase = data.findIndex(item => item.Cost_Type === 'L' && item.Phase_Code === phaseNumber)
                if (matchingPhase !== -1) {
                  data[matchingPhase].currentCost += parseFloat(data[i].JTD_Actual_Dollars)
                }
              }
            }

            data = data.filter(itm => {
              return itm.Cost_Type && itm.Cost_Type !== 'B'
            })
            this.jobPhases = data
            this.fetchedJobPhases = true
            resolve()
          })
          .catch(err => {
            if (!silent) {
              this.$snack.open(err.message || 'Problem getting phases', 'error')
            }
            reject(err)
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'get_job_phase')
          })
      })
    }
  },

  watch: {
    jobId: {
      handler() {
        this.checkSetJobItems()
      },
      immediate: true
    },
    jobAssemble: {
      handler() {
        this.checkSetJobItems()
      },
      immediate: true
    }
  }
}
</script>
