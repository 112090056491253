
<div class="">
  <set-view-as-employee v-if="isAdmin" />
  <br />

  <div class="row">
    <div class="col-sm-12">
      <div class="sh-widget card wide-large" style="margin-bottom: 20px !important" v-if="isPMp">
        <div class="content">
          <h4>My Jobs Summary</h4>
          <div class="table-container show-scrollbar">
            <table>
              <thead>
                <tr>
                  <th><label>Under Billings</label></th>
                  <th><label>Net Cash Flow</label></th>
                  <th><label>Total Contract Value</label></th>
                  <th><label>Billings Remaining</label></th>
                </tr>
              </thead>
              <tr>
                <td>{{ formatNumber(jobsTotal.Under_Over_Billing, 1) }}</td>
                <td :class="jobsTotal.Net_Cash_Flow > 0 ? 'green' : 'red'">
                  {{ formatNumber(jobsTotal.Net_Cash_Flow, 1) }}
                </td>
                <td>{{ formatNumber(jobsTotal.Total_Contract_Amount, 1) }}</td>
                <td>{{ formatNumber(jobsTotal.Billings_Remaining, 1) }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="isPM">
    <button :class="'btn'" @click="addJob">Add New</button>
    <br /><br />
  </div>

  <div class="tab-section white">
    <ul class="nav nav-tabs">
      <li role="presentation" :class="[{ active: tab == 'My Jobs' }]">
        <a href="#" @click=";(tab = 'My Jobs'), setTable()"> My Jobs </a>
      </li>
      <li role="presentation" :class="[{ active: tab == 'All Jobs' }]" v-if="isPM">
        <a href="#" @click=";(tab = 'All Jobs'), setTable()"> All Jobs </a>
      </li>
    </ul>

    <div :class="['card', { Main: tab == 'My Jobs' }]">
      <div class="content">
        <div class="row filter-row no-gutter">
          <div class="inline-block-row col-sm-6">
            <span class="inline-checkbox">
              <checkbox2 v-model="jobOpenShow" @change="setTable()"> Show Active</checkbox2>
            </span>
            <span class="inline-checkbox">
              <checkbox2 v-model="jobFinishedShow" @change="setTable()"> Show Inactive</checkbox2>
            </span>
            <span class="inline-checkbox">
              <checkbox2 v-model="jobAllShow"> Show Complete</checkbox2>
            </span>
            <span class="inline-checkbox">
              <checkbox2 @change="setTable()" @changeValue="setMissingPo"> Show Missing PO's</checkbox2>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3">
            <label>Department</label>
            <select-field :options="costCenters" :option-display-keys="['name']" :option-val="'value'" v-model="costCenter" :allow-clear="true" @change="setTable()" />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3">
            <label>Stage</label>
            <select-field :options="[...projectStages, ...signOffStages]" :option-display-keys="['description']" :option-val="'id'" v-model="stageCode" :allow-clear="true" @change="setTable()" />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3">
            <label>Note Status</label>
            <select-field
              :options="[{ name: 'Current' }, { name: 'Stale' }, { name: 'Outdated' }, { name: 'None' }]"
              :label-text="'Note Status'"
              :option-display-keys="['name']"
              :option-val="'name'"
              v-model="noteStatusFilter"
              :allow-clear="true"
              @change="setTable()"
            />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3">
            <region-filter @select-region="selectRegion" />
          </div>
        </div>
        <br />
        <bh-table :table-settings="tableSettings" @colClick="colClick" />
      </div>
    </div>
  </div>
  <waiting-spinner />
</div>
