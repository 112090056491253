
<div>
  <div v-for="(parent, index) in parents" :key="index" class="parent">
    <h3>Parent Element {{ index + 1 }}</h3>
    <div v-for="(sub, subIndex) in parent.subElements" :key="subIndex">
      <FormulateInput
        :type="sub.type"
        :label="sub.label"
        :validation="sub.validation"
        v-model="sub.value"
      />
    </div>
    <button @click="deleteParent(index)">Delete Parent</button>
  </div>
  <button @click="addParent">Add Parent</button>
  <button @click="save">Save</button>
</div>
