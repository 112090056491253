<template>
  <div>
    <div class="test-section">
      <!-- <h3>Rich Text Editor Test</h3> -->
      <div class="editor-stack">
        <div class="editor-item">
          <h4>Notes</h4>
          <RichTextInline :content="notesContent" @update:content="notesContent = $event" @save="handleSave('notes', $event)" />
          <RichTextInline :content="descriptionContent" @update:content="descriptionContent = $event" @save="handleSave('description', $event)" />
          <RichTextInline :content="commentsContent" @update:content="commentsContent = $event" @save="handleSave('comments', $event)" />
        </div>
      </div>

      <!-- <div class="content-preview">
        <h4>Saved Content:</h4>
        <div class="preview-box">
          <div v-html="savedContent"></div>
        </div>
      </div> -->
    </div>

    <!-- <hr />

    <button @click="showConfirm()">Show</button>
    <DownloadMergePDF path="wo-uploads/11825/sign-off" fileName="upload_now" />

    <button @click="requestEmail">Request wo po email</button> -->
  </div>
</template>

<script>
import store from 'store'
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import BillingTask from 'components/Dashboard/Billing/BillingTasks'
import BillingList from 'components/Dashboard/Billing/BillingList'
import FileUploadMultiViewer from 'components/UIComponents/FileUploadMultiViewer.vue'
import DownloadMergePDF from 'components/UIComponents/DownloadMergePDF.vue'
import RichTextInline from 'components/UIComponents/RichTextInline.vue'
import sanitizeHtml from 'sanitize-html'

import openConfirmDialog from 'src/confirmDialog'

export default {
  data() {
    return {
      notesContent: '<h1>Header 1</h1><p>Add important project notes here. <strong>Click to edit!</strong></p>',
      descriptionContent: '<p>This is where you can add a detailed description. <strong>Click to edit!</strong></p>',
      commentsContent: '<p>Add your comments and feedback here. <strong>Click to edit!</strong></p>'
    }
  },

  components: {
    WaitingSpinner,
    BillingTask,
    BillingList,
    FileUploadMultiViewer,
    DownloadMergePDF,
    RichTextInline
  },

  computed: {
    ...mapGetters([]),
    viewerConfig() {
      return [
        {
          name: 'Customer Invoice',
          label: 'Customer Invoice Files',
          path: 'invoice',
          subdir: 'pov',
          zipDepth: -1,
          drawer: 'drawer'
        },
        {
          name: 'Customer Banking',
          label: 'Customer Banking Files',
          path: 'banking',
          subdir: 'pob',
          drawer: 'drawer',
          zipDepth: -1
        }
      ]
    }
  },

  methods: {
    handleSave(type, content) {
      console.log(`${type} content saved:`, content)
      // Here you could handle saving to different locations based on type
    },

    async showConfirm() {
      const result = await openConfirmDialog({
        title: 'Confirm Action',
        html: 'Are you sure you want to proceed?',
        confirmButton: {
          text: 'OK',
          cb: () => {}
        },
        modalName: 'confirmDialog',
        hideCancel: true
      })

      const confirmed = result ? true : false

      if (confirmed) {
        console.log('user confirmed')
      } else {
        console.log('user cancelled')
      }
    },

    async requestEmail() {
      console.log('request eamil')
      // const data = { action: 'send_po_request_emails', po_type: 'wo' }
      const data = { action: 'send_po_request_emails', po_type: 'wo', woJobNumber: '11852' }
      // const data = { action: 'send_po_request_emails', woJobNumber: '1646', po_type: 'job' }
      // const data = { action: 'send_po_request_emails', po_type: 'job' }
      appFuncs.ajax_request(store.getters.sherAuth, data, function (result) {
        if (result.status === 'success') {
          console.log(result, 'success')
        } else {
          console.log(result, 'error')
        }
      })
    }
  },

  mounted() {}
}
</script>

<style lang="scss" scoped>
.test-section {
  max-width: 800px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
}

.editor-stack {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.editor-item {
  h4 {
    margin-bottom: 8px;
    font-size: 14px;
    color: #666;
    font-weight: 600;
  }
}

:deep(.rich-text-inline) {
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;

  .formatted-content {
    padding: 12px;
  }
}

.content-preview {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ddd;

  .preview-box {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #f9f9f9;
    min-height: 100px;
  }
}
</style>
