<template>
  <div class="sh-widget wide-large card my-jobs-widget">
    <div class="content">
      <h3>
        JOB/WO Pending Customer Signoff ({{ count }})
        <!-- <router-link :to="{ name:'Jobs' }" class="ti-new-window is-link" tag="i" /> -->
      </h3>
      <div class="content-inner show-scrollbars">
        <bh-table :table-settings="tableSettings" :style="{ maxWidth: '650px' }" @colClick="goToItem" />
        <waiting-spinner :contained="true" />
      </div>
    </div>
  </div>
</template>

<script>
import store from 'store'
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import jobFuncs from 'mixins/JobFuncs'
import woFuncs from 'mixins/WOFuncs'
import BhTable from 'components/UIComponents/BhTable'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {
      jobData: [],
      woData: []
    }
  },

  mixins: [jobFuncs, woFuncs],

  components: {
    BhTable,
    WaitingSpinner
  },

  computed: {
    ...mapGetters(['jobAssemble', 'woAssemble', 'userAuthedData']),

    isPM() {
      return this.userAuthedData.user_role.indexOf('pm') !== -1
    },

    filteredJobs() {
      let jobs = JSON.parse(JSON.stringify(this.myJobs))
      let filteredJobs = []

      for (let i = 0; i < jobs.length; i++) {
        let stage = parseInt(jobs[i].Stage)
        if (stage === 5 && jobs[i].status === 'A') {
          // standardize props
          jobs[i].name = `Job ${jobs[i].Job_Number}`
          jobs[i].Item_Number = jobs[i].Job_Number
          jobs[i].Customer_Code = jobs[i].Customer_Code
          jobs[i].po_number = jobs[i].po_number
          jobs[i].description = jobs[i].Job_Description
          jobs[i].stage = jobs[i].Stage_Description
          jobs[i].type = 'Job'
          filteredJobs.push(jobs[i])
        }
      }
      return filteredJobs
    },

    filteredWOs() {
      let wos = JSON.parse(JSON.stringify(this.myWOs))
      let filteredWOs = []

      for (let i = 0; i < wos.length; i++) {
        let code = parseInt(wos[i].Priority_Code)
        if (code === 10) {
          // standardize props
          wos[i].Item_Number = wos[i].WO_Number
          wos[i].name = `WO ${wos[i].WO_Number}`
          wos[i].Customer_Code = wos[i].Bill_Customer_Code
          wos[i].po_number = wos[i].Customer_PO_Number
          wos[i].description = wos[i].Summary_Description
          wos[i].stage = wos[i].Priority_Description
          wos[i].type = 'WO'

          filteredWOs.push(wos[i])
        }
      }
      return filteredWOs
    },

    filtered() {
      // combine filtered jobs and filtered WOs
      let filtered = JSON.parse(JSON.stringify([...this.filteredJobs, ...this.filteredWOs]))

      const today = new Date()

      for (let i = 0; i < filtered.length; i++) {
        filtered[i].stage = filtered[i].stage
        if (filtered[i].First_Report_Send_Date) {
          filtered[i].stage += ` <br><span class="text-danger">(${filtered[i].First_Report_Send_Date})</span>`
        }
        filtered[i].openPM = `<span class="has-link text-danger underlined"><i class="ti-new-window" /></span>`

        filtered[i].days_waiting = appFuncs.daysBetweenDates(filtered[i].First_Report_Send_Date, today)
      }

      // sort by Last_Cost_Date_formatted after stripping to number
      filtered.sort((a, b) => {
        const aDate = a.First_Report_Send_Date ? parseInt(a.First_Report_Send_Date.replace(/\D/g, '')) : 0
        const bDate = b.First_Report_Send_Date ? parseInt(b.First_Report_Send_Date.replace(/\D/g, '')) : 0
        if (aDate === bDate) {
          return 0
        }
        return aDate < bDate ? -1 : 1
      })

      return filtered
    },

    count() {
      return this.filtered && this.filtered.length ? this.filtered.length : 0
    },

    tableSettings() {
      return {
        tableData: this.filtered,
        fields: {
          name: {
            name: 'Job/WO',
            class: 'bold narrower'
          },
          Customer_Code: {
            name: 'Customer',
            class: 'narrow'
          },
          description: {
            name: 'Description'
          },
          // stage: {
          //   name: 'Stage',
          //   noFilter: 1,
          // },
          First_Report_Send_Date: {
            name: 'First Sent',
            noFilter: 1
          },
          // Last_Report_Send_Date: {
          //   name: 'Last Sent',
          //   noFilter: 1,
          // },
          days_waiting: {
            name: 'Days Waiting',
            noFilter: 1,
            class: 'narrow'
          },
          openPM: {
            name: 'Resend',
            noFilter: 1,
            class: 'narrow'
          }
        },
        hideAllSearch: true,
        hideTip: true,
        tableLayout: 'auto'
      }
    }
  },

  methods: {
    goToItem(obj) {
      const id = obj.itm.Item_Number
      if (obj.itm.type == 'WO') {
        if (this.isPM && obj.col == 'openPM') {
          this.showPmWindow('WO', obj.itm)
          return
        }
        this.$router.push('/app/wo-details/' + id)
        return
      } else {
        if (this.isPM && obj.col == 'openPM') {
          this.showPmWindow('Job', obj.itm)
          return
        }
        this.$router.push('/app/job-details/' + id)
      }
    },

    editPONumber(obj) {
      this.$bus.$emit('modalOpen', {
        title: 'Edit PO Number',
        classes: 'gray-bg',
        name: 'JobWOPOEdit',
        title: '',
        size: 'modal-md',
        component: () => import('src/components/Dashboard/JobWo/JobWoPoEdit.vue'),
        componentData: {
          obj
        }
      })
    },

    showPmWindow(type, obj) {
      if (type === 'WO') {
        this.$Modal({
          parent: this,
          name: 'WOWIPDetails', // used for closing specific modal programmatically
          size: 'xl', // sm, md, lg, xl
          hideClose: false,
          component: () => import('src/components/Dashboard/JobWo/WOWIPDetails.vue'),
          props: {
            inputWoId: obj.id,
            tab: 'Main'
          }
        })
      } else {
        this.$Modal({
          parent: this,
          name: 'JobIPDetails', // used for closing specific modal programmatically
          size: 'xl', // sm, md, lg, xl
          hideClose: false,
          component: () => import('src/components/Dashboard/JobWo/JobWIPDetails.vue'),
          props: {
            inputJobId: obj.id,
            tab: 'Main'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.sh-widget {
  width: 700px;
  max-width: 100%;
  max-height: 370px;
  overflow: hidden;

  @media (min-width: 900px) {
    height: 370px;
  }
}

h3 {
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;

  i {
    color: $primary-color;
    float: right;
  }
}

.content-inner {
  overflow: scroll !important;
  max-height: 310px;
}
</style>

<style lang="scss">
@import 'src/assets/sass/paper/_variables.scss';

.my-jobs-widget .no-po .po_number {
  // background: $input-red;
}
</style>
