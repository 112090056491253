
<div>
  <div class="content">
    <div class="card">
      <h5>Edit Region Cost Center</h5>
      <div v-if="!singleField" class="form-group name-group">
        <label>Region Name</label>
        <input type="text" v-model="localObj.name" class="form-control"/>
        <span @click="clearName()" class="edit-delete is-link">
          <i class="ti-close"/>
        </span>
      </div>
      <div class="form-group value-group">
        <label>Add Cost Centers</label>
        <input type="text" v-model="localObj.value" class="form-control" readonly @click="SelectConfiguredCostCenter('Code')"/>
        <span @click="clearValue()" class="edit-delete is-link">
          <i class="ti-close"/>
        </span>
      </div>
      <button class="btn btn-success" @click="saveItem">Save</button>
      <button class="btn btn-danger" @click="closeModal">Cancel</button>
    </div>
  </div>
</div>
