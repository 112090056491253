
<div :class="{ 'has-clear': hasClear }">
  <v-date-picker
    :mode="mode"
    :input-props="{ class: inputClasses, readonly: 'true', disabled: disabled }"
    :value="selectedDate"
    :formats="computedFormats"
    :themeStyles="datepickerStyles"
    :tint-color="'#b0cdeb'"
    :attributes="attributes"
    :select-attribute="selAttrs"
    :pane-width="320"
    :is-expanded="true"
    :popover-visibility="isSelectable"
    :is-inline="isInline"
    :class="['custom-date-picker']"
    :min-date="minDateFormatted"
    :max-date="maxDateFormatted"
    @input="dateSelected"
  />
  <span :class="'input-clear'" v-if="hasClear" @click="setDate(1)">
    <i class="ti ti-close"></i>
  </span>
</div>
