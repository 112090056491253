
<div>
  <div class="row">
    <div class="col-sm-12">
      <h4>Summary</h4>
      <tabulator-table
        style="max-width: 330px"
        :table-data="summaryData"
        :table-columns="tableColumnsSummary"
        table-fit="fitColumns"
        :table-condensed="false"
        empty-text="(none)"
        ref="reportTable"
        :table-bordered="true"
        :header-visible="false"
      />
    </div>
  </div>
  <br/>
  <h4>Totals</h4>
  <div class="row">
    <div class="col-sm-12 col-md-3 col-lg-2">
      <div class="form-group">
        <label>Lead Estimator:</label>
        <select-field
          :options="quoteEmployeeList"
          :option-display-keys="['Employee_Name']"
          :option-val="'Employee_Code'"
          v-model="quotedByFilter"
          :allow-clear="true"
          :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
        />
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-2">
      <div class="form-group">
        <label>Bid Captain:</label>
        <select-field
          :options="quoteEmployeeList"
          :option-display-keys="['Employee_Name']"
          :option-val="'Employee_Code'"
          v-model="bidCaptainFilter"
          :allow-clear="true"
          :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
        />
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-2">
      <div class="form-group">
        <label>Sales Lead:</label>
        <select-field
          :options="quoteEmployeeList"
          :option-display-keys="['Employee_Name']"
          :option-val="'Employee_Code'"
          v-model="salesLeadFilter"
          :allow-clear="true"
          :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
        />
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-2">
      <customer-select-field v-model="filterCustomer" :allow-add-new="false" :allow-clear="true" :required="false"/>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-2">
      <customer-contact-select-field
        :customer-code="filterCustomer"
        :label="'Customer Contact'"
        v-model="filterCustomerContact"
        :required="false"
        :output-name="false"
        :allow-clear="true"
        :allow-add-new="false"
      />
    </div>
    <div class="col-sm-12 col-md-3 col-lg-2">
      <div class="form-group">
        <label>Fiscal Year:</label>
        <select-field :options="fiscalYears" v-model="showFiscalYear" :option-display-keys="['name']" :option-val="'val'" @selectItem="val => changeFiscalYear(val)"/>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-2">
      <div class="form-group">
        <label>Department</label>
        <select-field :options="departments" v-model="departmentFilter" :option-display-keys="['name']" :option-val="'name'" :allow-clear="true"/>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-3">
      <div class="form-group">
        <checkbox2 :true-val="true" :false-val="false" v-model="showPending" @change="filterData()">Show Sent and Pending Quotes</checkbox2>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-3">
      <div class="form-group">
        <checkbox2 :true-val="true" :false-val="false" v-model="showCancelled" @change="filterData()">Show Cancelled</checkbox2>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-3">
      <div class="form-group">
        <checkbox2 :true-val="true" :false-val="false" v-model="showOnHold" @change="filterData()">Show On Hold</checkbox2>
      </div>
    </div>
  </div>

  <button class="btn btn-sm" @click="$refs.reportTable.exportCSV('quote-report-summary')" style="margin: 5px 0">Download CSV</button>

  <tabulator-table :table-data="data" :table-columns="tableColumns" table-fit="fitColumns" :table-condensed="false" empty-text="(none)" ref="reportTable" :table-bordered="true"/>

  <h4>Details</h4>

  <div class="row">
    <div class="col-sm-12">
      <quote-report-list :data="resultData" @colClick="colClick"/>
    </div>
  </div>

  <waiting-spinner/>
</div>
