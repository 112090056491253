<template>
  <div>
    <div class="medium">
      <div class="content">
        <div class="time-entry-form">
          <div clas="Xrow" v-if="!isPosted && approved === 'yes'">
            <div class="Xcol-sm-12">
              <p class="text-success">This entry has been approved and is no longer editable.</p>
            </div>
            <br />
          </div>
          <div clas="Xrow" v-if="approved === 'deleted' || approved === 'no'">
            <div class="Xcol-sm-12">
              <p class="text-danger">This entry has been rejected and is no longer editable.</p>
            </div>
            <br />
          </div>
          <div clas="Xrow" v-else-if="isPosted">
            <div class="Xcol-sm-12">
              <p class="text-danger">This entry has been sent to Spectrum and can no longer be edited from here.</p>
            </div>
            <br />
          </div>
          <div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Employee</label>
                  <select-field
                    :options="filteredEmployees"
                    :option-display-keys="['Employee_Name']"
                    :option-val="'Employee_Code'"
                    v-model="formVals.employee_code"
                    :allow-clear="true"
                    :preselect-single-option="false"
                    :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                    @selectItem="selectEmployee"
                    :disabled="locked || formVals.id"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Subcontractor (Job Only)</label>
                  <select-field
                    :options="subcontractors"
                    :option-display-keys="['Name']"
                    :option-val="'id'"
                    v-model="formVals.subcontractor_code"
                    :disabled="formVals.department !== 'JOB' || locked || formVals.id"
                    :allow-clear="true"
                    :allow-add-item="true"
                    @addItem="addSubcontractor"
                    :preselect-single-option="false"
                    @selectItem="selectSubcontractor"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Entry Date(s)</label>
                  <date-picker
                    v-model="selectedDates"
                    :min-date="minMaxDates.minDate"
                    :max-date="minMaxDates.maxDate"
                    :format="'date'"
                    :is-inline="false"
                    :pre-filled="false"
                    :mode="'multiple'"
                    ref="datePicker"
                    :disabled="locked"
                    :attributes="dateSelectAttrs"
                    :sel-attrs="{
                      contentStyle: { color: '#555', highlight: '#dae0fe' }
                    }"
                  />
                </div>
                <p v-if="warningMessage" class="alert alert-warning">
                  {{ warningMessage }}
                </p>
              </div>
              <!-- <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Non Payroll Entry</label>
                  <checkbox v-model="formVals.non_payroll" :disabled="formVals.department !== 'JOB' || (formVals.subcontractor_code ? true : false) || locked" />
                </div>
              </div> -->
            </div>

            <div class="inline-radio-container">
              <label>Department:</label>
              <div class="inline-radios">
                <label v-if="isDefaultDepartmentAdmin" :class="['radio-inline', { selected: formVals.department == 'ADMIN' }]">
                  ADMIN
                  <input type="radio" class="border-input" v-model="formVals.department" value="ADMIN" :disabled="locked || !authorEditing" />
                </label>
                <label v-if="!isDefaultDepartmentAdmin" :class="['radio-inline', { selected: formVals.department == 'JOB' }]">
                  JOB
                  <input type="radio" class="border-input" v-model="formVals.department" value="JOB" :disabled="locked || !authorEditing" />
                </label>

                <label v-if="!isDefaultDepartmentAdmin" :class="['radio-inline', { selected: formVals.department == 'WO' }]">
                  WO
                  <input type="radio" class="border-input" v-model="formVals.department" value="WO" :disabled="locked || !authorEditing" />
                </label>

                <label :class="['radio-inline', { selected: formVals.department == 'O' }]">
                  Other
                  <input type="radio" class="border-input" v-model="formVals.department" value="O" :disabled="locked || !authorEditing" />
                </label>
              </div>
              <div class="inline-radios">
                <label v-if="!isDefaultDepartmentAdmin" :class="['radio-inline', { selected: formVals.department == 'Unbillable' }]">
                  Unbillable
                  <input type="radio" class="border-input" v-model="formVals.department" value="Unbillable" :disabled="locked || !authorEditing" />
                </label>

                <label v-if="!isDefaultDepartmentAdmin" :class="['radio-inline', { selected: formVals.department == 'Safety' }]">
                  Safety
                  <input type="radio" class="border-input" v-model="formVals.department" value="Safety" :disabled="locked || !authorEditing" />
                </label>

                <label :class="['radio-inline', { selected: formVals.department == 'Quoting' }]">
                  Quoting
                  <input type="radio" class="border-input" v-model="formVals.department" value="Quoting" :disabled="locked || !authorEditing" />
                </label>

                <label v-if="isDefaultDepartmentAdmin" :class="['radio-inline', { selected: formVals.department == 'JOB' }]">
                  JOB
                  <input type="radio" class="border-input" v-model="formVals.department" value="JOB" :disabled="locked || !authorEditing" />
                </label>

                <label v-if="isDefaultDepartmentAdmin" :class="['radio-inline', { selected: formVals.department == 'WO' }]">
                  WO
                  <input type="radio" class="border-input" v-model="formVals.department" value="WO" :disabled="locked || !authorEditing" />
                </label>
              </div>
            </div>

            <div v-if="formVals.department == 'O'" class="inline-radio-container">
              <div class="inline-radios">
                <label :class="['radio-inline', { selected: formVals.departmentOther == 'V' }]">
                  Vacation
                  <input type="radio" class="border-input" v-model="formVals.departmentOther" value="V" :disabled="locked || !authorEditing" />
                </label>

                <label :class="['radio-inline', { selected: formVals.departmentOther == 'H' }]">
                  Stat Holiday
                  <input type="radio" class="border-input" v-model="formVals.departmentOther" value="H" :disabled="locked || !authorEditing" />
                </label>

                <label :class="['radio-inline', { selected: formVals.departmentOther == 'S' }]">
                  Sick
                  <input type="radio" class="border-input" v-model="formVals.departmentOther" value="S" :disabled="locked || !authorEditing" />
                </label>

                <label :class="['radio-inline', { selected: formVals.departmentOther == 'B' }]">
                  Bereavement
                  <input type="radio" class="border-input" v-model="formVals.departmentOther" value="B" :disabled="locked || !authorEditing" />
                </label>
              </div>
            </div>
            <div v-if="['JOB', 'WO', 'ADMIN'].includes(formVals.department)" class="inline-radio-container">
              <label>Pay Type:</label>
              <div class="inline-radios">
                <label :class="['radio-inline', 'wide', { selected: formVals.pay_type == 'R' }]">
                  Regular
                  <input type="radio" class="border-input" v-model="formVals.pay_type" value="R" :disabled="locked" />
                </label>

                <label :class="['radio-inline', { selected: formVals.pay_type == 'O', wide: formVals.department === 'ADMIN' }]">
                  Over Time
                  <input type="radio" class="border-input" v-model="formVals.pay_type" value="O" :disabled="locked" />
                </label>

                <label v-if="formVals.department !== 'ADMIN'" :class="['radio-inline', { selected: formVals.pay_type == 'D' }]">
                  Double Time
                  <input type="radio" class="border-input" v-model="formVals.pay_type" value="D" :disabled="locked" />
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col col-sm-12 col-md-4" v-if="formVals.department && !['O', 'Unbillable', 'Safety', 'ADMIN'].includes(formVals.department)">
                <div class="form-group">
                  <label>Filter by Customer</label>
                  <select-field :options="customers" :option-display-keys="['Name']" :option-val="'Customer_Code'" v-model="selectedCustomer" :allow-clear="true" />
                </div>
              </div>

              <div class="col col-sm-12 col-md-2" v-if="formVals.department == 'Quoting'">
                <label>My Quotes</label>
                <checkbox2 v-model="assignedQuotes"></checkbox2>
              </div>

              <div class="col col-sm-12 col-md-6">
                <div v-if="formVals.department == 'JOB'" class="form-group">
                  <label>Job:</label>
                  <div class="job-number-input-group">
                    <!-- <div class="job-number-input"> -->

                    <select-field
                      v-model="formVals.job_number"
                      :options="filteredJobs"
                      :option-display-keys="['Job_Number', 'Name', 'Job_Description']"
                      :option-val="'Job_Number'"
                      :empty-option-text="'No items.'"
                      :inline="false && inlineSelectItems && !formVals.job_number"
                      :disabled="locked || !authorEditing"
                    />

                    <!-- <select class="form-control" name="job_number" id="job_number" v-model="formVals.job_number">
                        <option selected disabled>Please Select</option>
                        <option v-for="job in jobInfo.data" v-bind:value="job.Job_Number"><span v-html="job.Job_Number + ' - ' + job.Customer_Name + ' - ' + job.Job_Description"></span></option>
                        <option v-if="!jobNumbers.success">{{jobNumbers.message}}</option>
                      </select> -->

                    <!--  </div>
                    <button class="btn btn-small job-search-button" v-on:click="showJobInfo($event)">Search</button> -->
                  </div>
                </div>

                <div v-if="formVals.department == 'WO'" class="form-group">
                  <label for="job_number">WO</label>
                  <div class="job-number-input-group">
                    <!-- <div class="job-number-input"> -->
                    <select-field
                      v-model="formVals.work_order_number"
                      :options="filteredWOs"
                      :option-display-keys="['WO_Number', 'customer_name', 'Summary_Description']"
                      :option-val="'WO_Number'"
                      :empty-option-text="'No items.'"
                      :inline="false && inlineSelectItems && !formVals.work_order_number"
                      :disabled="locked || !authorEditing"
                    />
                    <!-- </div>
                    <button class="btn btn-small job-search-button" v-on:click="showWoInfo($event)">Search</button> -->
                  </div>
                </div>

                <div v-if="formVals.department == 'Quoting' || isQuotingJobNumber" class="form-group">
                  <div class="form-group">
                    <label>Quote Fiscal Year</label>
                    <select-field :options="getQuoteYears()" v-model="showFiscalYear" :option-display-keys="['name']" :option-val="'val'" @selectItem="val => changeFiscalYear(val)" />
                  </div>
                  <div class="form-group">
                    <label>Quote Number</label><span class="required-star">*</span>
                    <select-field
                      v-model="formVals.quote_number"
                      :options="filteredQuotes"
                      :option-display-keys="['quote_number', 'customer', 'description']"
                      :option-val="'quote_number'"
                      :empty-option-text="'No items.'"
                      :disabled="locked || !authorEditing"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="['Unbillable', 'Safety', 'JOB'].includes(formVals.department) && formVals.job_number" class="form-group">
              <label>Phase:</label>
              <select-field
                v-model="formVals.phase"
                :label-text="'Phase:'"
                :options="phases"
                :option-display-keys="['Phase_Code', 'Description']"
                :option-val="'Phase_Code'"
                :empty-option-text="'No items.'"
                :disabled="locked || !authorEditing"
                :filter-on-key-val="filterPhaseStatuses"
              />
            </div>

            <div class="form-group">
              <label>Hours</label>
              <hours-picker v-model="formVals.hours" :readonly="locked" />
            </div>

            <div class="form-group" v-if="!['H', 'V'].includes(formVals.departmentOther)">
              <label>Message</label>
              <textarea v-if="!locked && authorEditing" v-model="formVals.message" :readonly="locked || !authorEditing" rows="8" class="form-control" />
              <!-- <wysiwyg
                v-if="!locked && authorEditing"
                v-model="formVals.message"
                v-click-outside="noteChange"
                :placeholder="'Short description of activity, note that this can be customer facing'"
                :use-plain-text="true"
                :read-only="locked || !authorEditing"
              /> -->
              <textarea v-else class="form-control" v-model="formVals.message" readonly disabled rows="8" />
            </div>
          </div>
        </div>

        <div class="action-buttons">
          <button v-if="!locked" class="btn" @click="addEntry()">
            <span v-if="editing"> Update Entry </span>
            <span v-else> Add Entry </span>
          </button>
          <button class="btn" @click="deleteEntry()" v-if="editId && (authorEditing || isAdmin) && (!locked || isAdmin)">Delete Entry</button>
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>
<script>
import store from 'store'
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import DatePicker from 'components/UIComponents/DatePicker'
import SelectField from 'components/UIComponents/SelectField'
import HoursPicker from 'components/UIComponents/HoursPicker'

import sanitizeHtml from 'sanitize-html'
import WOFuncs from 'mixins/WOFuncs'
import JobFuncs from 'mixins/JobFuncs'
import quoteFuncs from 'mixins/quoteFuncs'
import Checkbox2 from 'components/UIComponents/Checkbox'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import moment from 'moment'
import dateFormat from 'dateformat'

export default {
  props: ['componentData'],

  components: {
    DatePicker,
    SelectField,
    HoursPicker,
    Checkbox2,
    WaitingSpinner
  },

  data() {
    return {
      editing: false,
      posted: false, // indicates posted to spectrum already and uneditable
      woList: [],
      jobList: [],
      formVals: {
        id: null,
        employee_code: '',
        subcontractor_code: '',
        pay_type: 'R',
        work_order_number: '',
        job_number: '',
        jobFilter: '',
        date: null,
        phase: '',
        department: '',
        departmentOther: '',
        hours: '',
        message: '',
        non_payroll: false
      },
      showFiscalYear: null,
      phases: [],
      approved: '',
      jobInfo: {},
      entries: [],
      datepickerStyles: {
        dayContent: {
          fontSize: '1.6rem',
          height: '38px'
        },
        dayCell: {
          height: '38px'
        },
        headerTitle: {
          fontSize: '1.6rem'
        }
      },
      minMaxDates: {
        minDate: null,
        maxDate: null
      },
      noteText: '',
      selectedDates: [],
      departmentJobNumber: '',
      hardieQuotingJobNummber: '',
      warningMessage: '',
      selectedCustomer: null,
      assignedQuotes: true
    }
  },

  props: {
    editId: {
      type: [String, Number],
      default: ''
    },
    department: {
      type: [String, Number],
      default: ''
    },
    wo_number: {
      type: [String, Number],
      default: ''
    },
    job_number: {
      type: [String, Number],
      default: ''
    },
    inlineSelectItems: {
      type: Boolean,
      default: true
    },
    selectedPhase: {
      type: [String, Number],
      default: ''
    }
  },

  mixins: [WOFuncs, JobFuncs, quoteFuncs],

  computed: {
    ...mapGetters(['employees', 'subcontractors', 'sherAuth', 'appOptions', 'quoteData', 'customers', 'fiscalYear']),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPosted() {
      return this.posted && this.posted !== 'No' ? true : false
    },

    isPM() {
      return this.$store.getters.userAuthedData.user_role.indexOf('pm') !== -1
    },

    isOriginalAuthor() {
      return this.$store.getters.userAuthedData.eid === this.formVals.employee_code
    },

    locked() {
      return this.isPosted || this.approved ? true : false
    },

    authorEditing() {
      return true // just let anyone edit anything :)
      if (!this.formVals.id) return true // new entry not editing existing
      return this.formVals.id && this.isOriginalAuthor
    },

    // required for WO Funcs Mixin
    woid() {
      return this.formVals && this.formVals.work_order_number ? this.formVals.work_order_number : null
    },

    // required for JobFuncs Mixin
    jobId() {
      return this.formVals && this.formVals.job_number ? this.formVals.job_number : null
    },

    isPayrollUser() {
      const employee = this.employees.find(itm => {
        return parseInt(itm.Employee_Code) == parseInt(this.sherAuth.eid)
      })
      if (!employee) return false
      return employee && employee.payroll_admin_user === 'yes'
    },

    isDefaultDepartmentAdmin() {
      const employee = this.employees.find(itm => {
        return parseInt(itm.Employee_Code) == this.formVals.employee_code
      })
      if (!employee) return false
      return employee && employee.default_department === 'ADMIN'
    },

    employeeRegionId() {
      const employee = this.employees.find(itm => {
        return parseInt(itm.Employee_Code) == parseInt(this.sherAuth.eid)
      })
      if (!employee) return false
      return employee && employee.default_region
    },

    filterPhaseStatuses() {
      if (this.formVals.id) {
        return {}
      }
      return {
        key: 'Status_Code',
        val: 'A'
      }
    },

    isQuotingJobNumber() {
      const sh = this.formVals.job_number == this.departmentJobNumber
      return this.formVals.job_number && sh && ['Quoting', 'JOB'].includes(this.formVals.department)
    },

    dateSelectAttrs() {
      const minMaxDates = this.formatMinMaxDates()

      return [
        {
          key: 'today',
          highlight: {
            height: '20px',
            color: '#555',
            backgroundColor: '#dae0fe'
          },
          contentStyle: {
            color: '#555'
            // fontWeight:'bold',
          },

          dates: [{ start: minMaxDates.minDate, span: 14 }]
        }
      ]
    },

    filteredEmployees() {
      if (this.isPM) {
        return this.employees
      } else {
        return this.employees.filter(itm => {
          return itm.Employee_Code == this.sherAuth.eid
        })
      }
    },

    filteredJobs() {
      let jobs = this.jobList.slice() // clone the input array
      if (this.selectedCustomer) {
        jobs = jobs.filter(itm => {
          return itm.Customer_Code == this.selectedCustomer
        })
      }
      // sort by Job_Number desc
      jobs.sort((a, b) => b.Job_Number - a.Job_Number)
      return jobs
    },

    filteredWOs() {
      let wos = this.woList.slice() // clone the input array
      if (this.selectedCustomer) {
        wos = wos.filter(itm => {
          return itm.Bill_Customer_Code == this.selectedCustomer
        })
      }
      // sort by WO_Number desc
      wos.sort((a, b) => b.WO_Number - a.WO_Number)
      return wos
    },

    filteredQuotes() {
      let quotes = this.quoteData.slice() // clone the input array
      if (this.selectedCustomer) {
        quotes = quotes.filter(itm => {
          return itm.customer_code == this.selectedCustomer
        })
      }
      if (this.assignedQuotes) {
        quotes = quotes.filter(itm => {
          return this.myQuoteIds.includes(itm.id)
        })
      }
      // sort by id desc
      quotes.sort((a, b) => b.quote_incr - a.quote_incr)
      return quotes
    }
  },

  methods: {
    formatMinMaxDates() {
      var date = new Date()

      var prevSunday = new Date(date)
      prevSunday.setDate(prevSunday.getDate() - ((prevSunday.getDay() + 7) % 7))

      var weekNo = moment(prevSunday).format('W')

      /*
          A bit whacky but works for year week pay period change, maybe change back next year to just:
          if (weekNo % 2 !== 0) {
              prevSunday = moment(prevSunday).subtract(1, 'week').toDate();
            }
          */

      if (date.getFullYear() === 2020 || weekNo == 53) {
        if (weekNo % 2 !== 0) {
          prevSunday = moment(prevSunday).subtract(1, 'week').toDate()
        }
      } else {
        if (weekNo % 2 === 0) {
          prevSunday = moment(prevSunday).subtract(1, 'week').toDate()
        }
      }

      var endDate = moment(prevSunday).add(13, 'days').toDate()

      return {
        minDate: prevSunday,
        maxDate: endDate,
        formatted: dateFormat(prevSunday, 'mediumDate') + ' - ' + dateFormat(endDate, 'mediumDate')
      }
    },

    addEntry() {
      if (this.locked) {
        return
      }

      var error = ''
      if (!this.formVals.employee_code && !this.formVals.subcontractor_code) {
        error = 'Please select an Employee or Subcontractor'
      } else if (!this.selectedDates || !this.selectedDates.length) {
        error = 'Please select an Entry Date'
      } else if (!this.formVals.department) {
        error = 'Please select a Department'
      } else if (this.formVals.department == 'WO' && !this.formVals.work_order_number) {
        error = 'Please select a WO'
      } else if (this.formVals.department == 'JOB' && !this.formVals.job_number) {
        error = 'Please select a Job'
      } else if (['Unbillable', 'Safety', 'JOB'].includes(this.formVals.department) && !this.formVals.phase) {
        error = 'Please select a Phase'
      } else if (this.formVals.department == 'O' && !this.formVals.departmentOther) {
        error = 'Please select a type of other Department'
      } else if (!this.formVals.hours || this.formVals.hours == '0.00') {
        error = 'Hours cannot be 0'
      } else if (this.formVals.department !== 'ADMIN' && !['H', 'V'].includes(this.formVals.departmentOther) && (!this.formVals.message || this.formVals.message.length < 10)) {
        error = 'Message should be over 10 characters'
      } else if (
        this.formVals.department !== 'ADMIN' &&
        !['H', 'V'].includes(this.formVals.departmentOther) &&
        (this.woRequiresDetailedNotes || this.jobRequiresDetailedNotes) &&
        this.formVals.message.length < 40
      ) {
        error = 'Detailed work descriptions are required by the customer. Please include important details about what you did today and remember the customer will see what you write.'
      }

      if (error) {
        this.$snack.open(error, 'warning')
        return
      }

      // update vals for holiday or vacation
      if (this.formVals.department == 'O') {
        if (this.formVals.departmentOther == 'H') {
          this.formVals.department = 'JOB VH'
          this.formVals.pay_type = 'H'
        } else if (this.formVals.departmentOther == 'V') {
          this.formVals.department = 'JOB VH'
          this.formVals.pay_type = 'V'
        } else if (this.formVals.departmentOther == 'S') {
          this.formVals.department = 'SB'
          this.formVals.pay_type = 'S'
        } else if (this.formVals.departmentOther == 'B') {
          this.formVals.department = 'SB'
          this.formVals.pay_type = 'H'
        }
      }

      // only send job or wo number if corresponding department has been selected
      let jobNumber = ['Quoting', 'Unbillable', 'Safety', 'JOB'].includes(this.formVals.department) ? this.formVals.job_number : ''
      let WONumber = this.formVals.department === 'WO' ? this.formVals.work_order_number : ''

      if (this.formVals.employee_code && this.formVals.subcontractor_code) {
        this.$snack.open('Please select either Employee OR Subcontractor', 'warning')
        return
      }

      // make sure subcontractor has S designator in code
      if (this.formVals.subcontractor_code) {
        if (this.formVals.subcontractor_code.toString().substring(0, 1) !== 'S') {
          this.formVals.subcontractor_code = `S${this.formVals.subcontractor_code}`
        }
      }

      let employee_code = this.formVals.employee_code || this.formVals.subcontractor_code

      let selectedDatesFormatted = []
      for (let i = 0; i < this.selectedDates.length; i++) {
        selectedDatesFormatted.push(dateFormat(this.selectedDates[i], 'yyyy-mm-dd'))
      }

      if (selectedDatesFormatted.length > 1 && !confirm(`You are about to add ${selectedDatesFormatted.length} entries, continue?`)) {
        return
      }

      if (this.formVals.employee_code && this.formVals.non_payroll && !confirm('Confirm adding employee time entry as non payroll type?')) {
        return
      }

      if (this.formVals.job_number && this.isQuotingJobNumber && !this.formVals.quote_number) {
        this.$snack.open('Please select quote', 'warning')
        return
      }

      var data = {
        action: 'employee_post_time',
        department_code: this.formVals.department,
        job_number: jobNumber,
        phase_code: this.formVals.phase,
        pay_type: this.formVals.pay_type,
        date: selectedDatesFormatted,
        hours: this.formVals.hours,
        message: this.formVals.message,
        employee_code,
        wo_number: WONumber,
        non_payroll: this.formVals.non_payroll ? 'true' : 'false', // send str
        quote_number: this.formVals.quote_number,
        quote_incr: this.formVals.quote_incr
      }

      if (this.editing) {
        data.id = this.editing
      }

      // trim any whitespace from each prop
      for (var key in data) {
        if (!data.hasOwnProperty(key)) continue
        if (typeof data[key] === 'string') {
          data[key] = data[key].trim()
        }
      }

      this.$store.commit('waiting', {
        status: 1,
        message: this.editing ? 'Updating entry' : 'Adding entry'
      })
      appFuncs.ajax_request(store.getters.sherAuth, data, result => {
        this.$store.commit('waiting', { status: 0 })
        if (result.status === 'success') {
          this.formVals = {
            pay_type: 'R',
            work_order_number: '',
            job_number: '',
            phase: '',
            department: '',
            departmentOther: '',
            date: [new Date()]
          }
          this.$snack.open(result.message)
          this.$bus.$emit('updateWOAssemble')
          this.$bus.$emit('add-update-time-entry')
          this.$bus.$emit('modalClose', 'add-time-entry-duplicated')
        } else {
          this.$snack.open(result.message || 'Problem adding one or more time entries', 'error')
        }
      })
    },

    checkDuplicateHours() {
      if (this.isPosted) return
      let employee_code = this.formVals.subcontractor_code ? `S${this.formVals.subcontractor_code}` : this.formVals.employee_code
      this.warningMessage = ''

      let selectedDatesFormatted = []
      for (let i = 0; i < this.selectedDates.length; i++) {
        selectedDatesFormatted.push(dateFormat(this.selectedDates[i], 'yyyy-mm-dd'))
      }

      if (!selectedDatesFormatted || !selectedDatesFormatted.length || !employee_code) return

      const params = {
        action: 'employee_check_has_full_day_time_entries',
        employee_code,
        entry_dates: selectedDatesFormatted
      }

      appFuncs
        .shRequest(params)
        .then(data => {
          if (data && data.length) {
            this.warningMessage = 'Please note there are already 8 hours entered for one or more selected dates. Please make sure this is not a duplicate entry'
          }
        })
        .catch(res => {
          console.log(res)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    deleteEntry() {
      if (!confirm("Are you sure you'd like to delete this entry?")) {
        return
      }
      if (!this.editId) {
        this.$snack.open('Entry cannot be deleted, please reload to try again.', 'error')
      }
      const params = {
        action: 'delete_timecard_entry',
        id: this.editId
      }

      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Deleting Entry'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.$bus.$emit('modalClose', 'add-time-entry')
          this.$bus.$emit('add-update-time-entry')
          this.$snack.open('Entry Deleted Successfully')
        })
        .catch(res => {
          console.log(res)
          this.$snack.open(res.message || 'Problem deleting entry, please reload to try again.', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
          this.$bus.$emit('updateWOAssemble')
        })
    },

    async getEntry(id) {
      var params = {
        action: 'get_time_entry',
        id: id
      }

      let entry
      this.$bus.$emit('setWaiting', { name: params.action, message: 'Getting Entry' })
      try {
        entry = await appFuncs.shRequest(params)
      } catch (err) {
        console.log(err)
        this.$snack.open('Problem getting entry', 'error')
        return
      } finally {
        this.$bus.$emit('stopWaiting', params.action)
      }

      var rd = entry

      if (rd.approved) {
        this.approved = rd.approved
      }

      if (rd.posted && rd.posted !== 'No') {
        this.posted = true
      }

      // translate department codes back to formVals
      if (rd.department_code == 'JOB' || rd.department_code == 'WO') {
        this.$store.dispatch('getCustomers')

        this.formVals.department = rd.department_code
      } else if (rd.department_code == 'JOB VH') {
        this.formVals.department = 'O'
        if (rd.pay_type == 'H') {
          this.formVals.departmentOther = 'H'
        } else if (rd.pay_type == 'V') {
          this.formVals.departmentOther = 'V'
        }
      } else if (rd.department_code == 'SB') {
        this.formVals.department = 'O'
        if (rd.pay_type == 'S') {
          this.formVals.departmentOther = 'S'
        } else if (rd.pay_type == 'H') {
          this.formVals.departmentOther = 'B'
        }
      } else {
        this.formVals.department = rd.department_code
      }

      try {
        await this.checkFetchJobWOInfo()
      } catch (err) {
        console.log(err)
        return
      }
      this.selectedDates = [moment(rd.entry_date).toDate()]
      this.formVals.id = rd.id
      this.formVals.message = rd.message
      this.formVals.job_number = rd.job_number
      this.formVals.work_order_number = rd.wo_number
      this.formVals.phase = rd.phase_code
      this.entryPhase = rd.phase_code

      this.formVals.pay_type = rd.pay_type
      this.formVals.hours = rd.hours
      this.formVals.non_payroll = rd.non_payroll === 'true' ? true : false
      this.formVals.quote_number = rd.quote_number ? rd.quote_number : ''
      this.formVals.quote_incr = rd.quote_incr ? rd.quote_incr : ''

      if (rd.quote_fiscal_year) {
        this.changeFiscalYear(rd.quote_fiscal_year)
      }

      const subcontractorCode = rd.employee_code.toString().substring(0, 1) === 'S' ? rd.employee_code.toString().substring(1) : ''
      if (subcontractorCode) {
        this.formVals.subcontractor_code = subcontractorCode
      } else {
        this.formVals.employee_code = rd.employee_code
      }
    },

    checkFetchJobWOInfo() {
      return new Promise(async (resolve, reject) => {
        this.formVals.quote_number = ''
        this.formVals.job_number = ''

        if (this.formVals.department == 'JOB') {
          // get all jobs incase some recently closed
          this.$bus.$emit('setWaiting', { name: 'getJobAssemble', message: 'Getting Jobs' })
          try {
            await this.getJobList()
          } catch (err) {
            console.log(err || 'Problem getting Job')
            reject()
            return
          } finally {
            this.$bus.$emit('stopWaiting', 'getJobAssemble')
          }

          this.formVals.subcontractor_code = ''
          this.formVals.non_payroll = false
        } else if (this.formVals.department == 'WO') {
          await this.getWOList()
        } else if (['Unbillable', 'Safety'].includes(this.formVals.department)) {
          try {
            await this.setJobNumber()
            this.formVals.job_number = this.departmentJobNumber
          } catch(err) {
            reject()
            return
          }
        }
        resolve()
      })
    },

    showJobInfo(e) {
      e.preventDefault()
      this.$bus.$emit('modalOpen', {
        title: 'Search for a Job Number',
        content: 'Click a job number to select.',
        classes: 'gray-bg',
        name: 'get-job-info',
        size: 'modal-lg',
        component: 'get-job-info',
        componentData: {
          jobInfo: this.jobInfo.data
        }
      })
    },

    showWoInfo(e) {
      e.preventDefault()
      this.$bus.$emit('modalOpen', {
        title: 'Search for a Work Order',
        content: 'Click a WO number to select.',
        classes: 'gray-bg',
        name: 'get-wo-info',
        size: 'modal-lg',
        component: 'get-wo-info',
        componentData: {
          WOInfo: this.woAssemble
        }
      })
    },

    getJobPhases() {
      if (this.formVals && this.formVals.job_number) {
        if (['Unbillable', 'Safety'].includes(this.formVals.department)) this.formVals.phase = ''

        this.$bus.$emit('setWaiting', { name: 'get_job_phases_all', message: 'Getting Job Phases' })
        var data = {
          action: 'get_job_phases_all',
          job_number: this.formVals.job_number
        }

        appFuncs.ajax_request(store.getters.sherAuth, data, result => {
          this.$bus.$emit('stopWaiting', 'get_job_phases_all')
          if (result.status === 'success') {
            // use only L cost type
            this.phases = result.data.filter(itm => {
              return itm && itm.Cost_Type === 'L'
            })

            if (this.entryPhase) {
              this.formVals.phase = this.entryPhase
            }

            if (!this.phases || !this.phases.length) {
              this.$snack.open('No Job Phase available, please notify admin to post time to this job.', 'error')
            }
            this.selectSinglePhase()
          }
        })
      }
    },

    selectSinglePhase() {
      // automatically select phase if there is only one avail
      if (this.phases && this.phases.length === 1) {
        this.formVals.phase = this.phases[0].Phase_Code
      }
    },

    getMinMaxDates() {
      if (!this.isPayrollUser) {
        this.minMaxDates = this.formatMinMaxDates()
      }
    },

    noteChange() {
      if (!this.formVals.message) {
        return
      }
      this.formVals.message = sanitizeHtml(this.formVals.message, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['u'])
      })
      // this.formVals.message = sanitizeHtml(this.formVals.message, {
      //   allowedTags: []
      // });
    },

    addSubcontractor() {
      this.$Modal({
        parent: this,
        name: 'addSubcontractor', // used for closing specific modal programmatically
        title: 'Add Subcontractor',
        size: 'xl', // sm, md, lg, xl
        component: () => import('src/components/Dashboard/Jobs/BhTableAddEdit.vue'),
        props: {
          componentData: {
            fields: {
              Name: {
                name: 'Name',
                required: true
              },
              Company: {
                name: 'Company',
                required: true
              },
              'Phone Number': {
                name: 'Mobile Number',
                use: 'phone'
              },
              'Direct Line': {
                use: 'phone'
              },
              'Email Address': {
                name: 'Email Address'
              },
              Position: {}
            },
            itemType: 'Subcontractor',
            dbTable: 'SubcontactorList',
            modalName: 'addSubcontractor' // legacy
          }
        }
      })
    },

    changeFiscalYear(val) {
      this.showFiscalYear = val
      this.getQuotes()
    },

    getQuotes() {
      return new Promise(async (resolve, reject) => {
        this.$store.commit('quoteFilterFiscalYear', this.showFiscalYear)
        this.$store.commit('quoteFilterStatus', '')
        this.$store.commit('quoteFilterEmployee', '')
        this.$store.commit('quoteFilterCustomer', '')
        this.$store.commit('quoteFilterDepartment', '')
        this.$store.commit('quoteFilterQuotedBy', '')
        this.$store.commit('quoteFilterSalesLead', '')

        this.$bus.$emit('setWaiting', { name: 'getQuotes', message: 'Getting Quotes' })
        try {
          await this.$store.dispatch('getQuotes', 1)
        } catch (err) {
          reject(err)
          return
        } finally {
          this.$bus.$emit('stopWaiting', 'getQuotes')
        }
        resolve()
      })
    },

    checkForemanEntrySetting() {
      let job = this.jobAssemble.find(itm => itm.Job_Number == this.formVals.job_number)
      let jobForemanEntryWarn = (((job || {}).admin_options || {}).other || {}).using_foreman_time_entry == 'true'
      if (jobForemanEntryWarn) {
        this.$snack.open('This job has foreman time entry enabled. Please make sure you need to be inputting this time', 'warning')
      }
    },

    selectEmployee() {
      if (this.formVals.employee_code) {
        this.formVals.subcontractor_code = ''
        this.formVals.non_payroll = false
        this.checkDuplicateHours()
      }
    },

    selectSubcontractor() {
      if (this.formVals.subcontractor_code) {
        this.formVals.non_payroll = true
        this.formVals.employee_code = ''
        this.checkDuplicateHours()
      }
    },

    async setJobNumber() {
      if (!this.formVals.employee_code) return
      this.$bus.$emit('setWaiting', { name: 'getDepartmentJob', message: 'Getting Data' })
      const params = {
        action: 'get_department_job',
        employeeCode: this.formVals.employee_code,
        department: this.formVals.department
      }
      await appFuncs
        .shRequest(params)
        .then(data => {
          this.departmentJobNumber = data.Job_Number
        })
        .catch(err => {
          this.departmentJobNumber = ''
          if (this.formVals.department !== 'JOB') {
            this.$snack.open(err?.message || 'Problem getting data', 'error')
          }
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getDepartmentJob')
        })
    },

    getJobList() {
      return new Promise(async (resolve, reject) => {
        const params = {
          action: 'getJobList',
          notStatus: 'C',
          exceptJobUseTypes: ['Quoting', 'Unbillable', 'Safety']
        }

        this.$bus.$emit('setWaiting', { name: 'getJobList', message: 'Getting Job List' })
        appFuncs
          .shRequest(params)
          .then(data => {
            this.jobList = data
            resolve()
          })
          .catch(err => {
            this.$snack.open(err || 'Problem getJobList entries', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'getJobList')
          })
      })
    },

    getWOList() {
      return new Promise(async (resolve, reject) => {
        const params = {
          action: 'getWOList',
          notStatus: 'C'
        }

        this.$bus.$emit('setWaiting', { name: 'getWOList', message: 'Getting WO List' })
        appFuncs
          .shRequest(params)
          .then(data => {
            this.woList = data
            resolve()
          })
          .catch(err => {
            this.$snack.open(err || 'Problem getWOList entries', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'getWOList')
          })
      })
    }
  },

  mounted() {
    this.showFiscalYear = this.fiscalYear
    this.entryPhase = this.formVals.selectedPhase

    if (this.editId) {
      this.editing = this.editId
      this.getEntry(this.editing)
      this.assignedQuotes = false
    } else {
      this.formVals.employee_code = this.$store.getters.userAuthedData.eid
    }

    if (this.department) {
      this.formVals.department = this.department
    }

    if (this.wo_number) {
      this.formVals.work_order_number = this.wo_number
    }

    if (this.job_number) {
      this.formVals.job_number = this.job_number
    }
    if (this.entryPhase) {
      this.formVals.phase = this.entryPhase
    }

    this.$bus.$on('selectJobNumber', number => {
      this.formVals.job_number = number
    })

    this.$bus.$on('selectWONumber', number => {
      this.formVals.work_order_number = number
    })

    this.getMinMaxDates()

    this.$bus.$emit('setWaiting', {
      name: 'getEmployees',
      message: 'Getting Employees'
    })
    this.$store.dispatch('getEmployees').then(() => {
      this.$bus.$emit('stopWaiting', 'getEmployees')
    })

    this.$store.dispatch('getSubcontractors', true)

    this.$bus.$on('bhUpdate', () => {
      this.$store.dispatch('getSubcontractors', true)
    })
  },

  watch: {
    'formVals.employee_code': function (newVal, oldVal) {
      if (['Quoting', 'Unbillable', 'Safety', 'JOB'].includes(this.formVals.department) && oldVal) {
        this.checkFetchJobWOInfo()
      }
    },

    'formVals.department': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === 'Quoting') {
          this.getQuotes()
        } else {
          this.formVals.departmentOther = ''
          this.checkFetchJobWOInfo()
        }
      }
    },

    'formVals.job_number': {
      handler(newVal, oldVal) {
        if (this.formVals.department === 'JOB') {
          this.checkForemanEntrySetting()
          if (this.isQuotingJobNumber) {
            this.getQuotes()
          }
        }

        if (['Unbillable', 'Safety', 'JOB'].includes(this.formVals.department)) {
          this.phases = []
          this.getJobPhases()

          // clear phase only if val existed and is updated - prevents from clearing when first loaded
          if (newVal && oldVal && newVal != oldVal) {
            this.formVals.phase = ''
          }
          if (newVal) {
            this.formVals.work_order_number = ''
          }
        }
      },
      immediate: true
    },

    'formVals.work_order_number': function (val) {
      if (val) {
        this.formVals.job_number = ''
        this.formVals.non_payroll = false
      }
    },

    'formVals.message': function (newVal, oldVal) {
      // strip html tags from newVal
      newVal = newVal.replace(/<[^>]*>?/g, '')
      if (newVal.length > 1000) {
        this.formVals.message = oldVal
      }
      this.formVals.message = newVal
    },

    selectedDates(newVal, oldVal) {
      this.checkDuplicateHours()
      if (newVal && newVal.length > 1) {
        if (this.editId) {
          // dates get sorted by date, so need to find the new val by comparison
          this.selectedDates = oldVal
          this.$snack.open('Please ensure only one date is selected when editing entry.', 'warning')
          this.$refs.datePicker.setDate()
        } else if (!this.isPM) {
          this.selectedDates = oldVal
          this.$snack.open('Please ensure only one date is selected.', 'warning')
          this.$refs.datePicker.setDate()
        }
      }
    },

    'formVals.quote_number': function (newVal, oldVal) {
      const quote = this.quoteData.find(itm => itm.quote_number == newVal)
      if (quote) {
        this.formVals.quote_incr = quote.quote_incr
      } else {
        this.formVals.quote_incr = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.time-entry-form {
  h4 {
    font-size: 16px;
  }
}

.item-list-container {
  max-height: 220px;
  overflow-y: scroll;

  .item-list-inner {
    background: $smoke-bg;
    border-radius: $border-radius-base;
    padding: 5px;

    .list-item {
      display: block;
    }

    p {
      padding: 5px;
      margin: 0;

      &.selected {
        font-weight: bold;
      }
    }
  }
}

.job-search-button {
  float: right;
  text-decoration: underline;
  padding: 0 5px;
  color: $font-color;
}
</style>
