
<div class="customer-ticket">
  <div class="container" v-if="job.Job_Number">
    <div class="row header">
      <div class="col-xs-12 col-sm-4">
        <router-link to="/">
          <img :src="`${urls.frontend}static/img/${logoFile}`" />
        </router-link>
      </div>
      <div class="col-xs-12 col-sm-8 text-right">
        <h4>
          Job Order:<strong> #{{ job.Job_Number }}</strong>
        </h4>
        <h5>
          Office: <strong>{{ regionTel }}</strong>
        </h5>
      </div>
    </div>

    <div class="row" v-if="job.Customer_Name">
      <div class="col-sm-12">
        <div class="ticket-section">
          <div class="ticket-section--top">
            <label><strong>General</strong></label>
          </div>
          <div class="row ticket-fields">
            <div class="col-sm-12 col-md-6">
              <div class="ticket-field">
                <label>Customer:</label>
                <p>{{ job.Customer_Name }}</p>
              </div>
              <div class="ticket-field">
                <label>Site Address:</label>
                <p>
                  {{ woSiteAddress }}
                </p>
              </div>
              <div class="ticket-field">
                <label>Job Description:</label>
                <p>{{ job.Job_Description }}</p>
              </div>

              <div class="ticket-field form-group" style="margin-top: 20px">
                <label>Upload PO file:</label>
                <file-upload :path="`job-uploads/${job.Job_Number}/`" subdir="po" :auth-code="authCode" :upload-data="uploadData"/>
              </div>
              <div class="ticket-field form-group">
                <label>Customer PO:</label>
                <input type="text" v-model="job.po_number" class="form-control" style="max-width: 300px" />
              </div>
              <div :class="'detail-item'">
                <checkbox2 v-model="job.po_request_muted_by_customer" :true-val="'yes'" :false-val="'no'"> Mute PO Request</checkbox2>
              </div>
              <button @click="updateCustomerProvidedData" class="btn btn-sm" style="margin-top:10px">Save</button>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="ticket-field">
                <label>Project Lead:</label>
                <p>{{ job.Project_Manager_Name }}</p>
              </div>
              <div class="ticket-field">
                <label>Sales Person:</label>
                <p>{{ pmUser }}</p>
              </div>
              <div class="ticket-field">
                <label>Quote #:</label>
                <p>{{ job.quote_number }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="job.Customer_Name">
      <div class="col-sm-12">
        <div class="ticket-section">
          <div class="ticket-section--top">
            <label><strong>Status Details</strong></label>
          </div>
          <div class="row ticket-fields">
            <div class="col-sm-12 col-md-6">
              <div class="ticket-field">
                <label>Ordered Date:</label>
                <p v-html="job.Create_Date ? formatDate(job.Create_Date, 1) : 'TBD'" />
              </div>
              <div class="ticket-field">
                <label>Date Requested:</label>
                <p v-html="job.Est_Start_Date ? formatDate(job.Est_Start_Date, 1) : 'TBD'" />
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="ticket-field">
                <label>Finished Date:</label>
                <p v-html="job.Complete_Date ? formatDate(job.Complete_Date, 1) : 'TBD'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="job.Job_Number">
      <div class="col-sm-12" v-for="(note, k) in notes" :key="k">
        <div class="ticket-section" v-if="note.note.length">
          <div class="ticket-section--top">
            <label
              ><strong>{{ note.title }}</strong></label
            >
          </div>
          <div class="row ticket-fields">
            <div class="col-sm-12">
              <div class="ticket-field">
                <ul class="ticket-notes show-scrollbar" v-if="note.note.length">
                  <li v-for="(n, i) in note.note" :key="i">
                    <p class="note-meta">
                      <strong>{{ n.author_name }} - {{ n.nice_date }}</strong>
                    </p>
                    <p v-html="getPlainText(n.note_text)" class="note-text" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="job.Job_Number && preWorkPhotos && preWorkPhotos.length">
      <div class="col-sm-12">
        <div class="ticket-section">
          <div class="ticket-section--top">
            <label><strong>Pre-Work Photos</strong></label>
          </div>
          <div class="row ticket-fields">
            <div class="col-sm-12">
              <ul class="photos" v-if="preWorkPhotos.length">
                <li v-for="(photo, p) in preWorkPhotos" :key="p">
                  <a :href="photo.a_attr.href" target="_blank" class="img-container" :style="{ backgroundImage: `url('${photo.a_attr.href}')` }" />
                </li>
              </ul>
              <p v-else>
                <span class="empty">(empty)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="job.Job_Number && postWorkPhotos && postWorkPhotos.length">
      <div class="col-sm-12">
        <div class="ticket-section">
          <div class="ticket-section--top">
            <label><strong>Post-Work Photos</strong></label>
          </div>
          <div class="row ticket-fields">
            <div class="col-sm-12">
              <ul class="photos" v-if="postWorkPhotos.length">
                <li v-for="(photo, pt) in postWorkPhotos" :key="pt">
                  <a :href="photo.a_attr.href" target="_blank" class="img-container" :style="{ backgroundImage: `url('${photo.a_attr.href}')` }" />
                </li>
              </ul>
              <p v-else>
                <span class="empty">(empty)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="job.Job_Number && signed && signed.length">
      <div class="col-sm-12">
        <div class="ticket-section">
          <div class="ticket-section--top">
            <label><strong>Service Sign-Offs</strong></label>
          </div>
          <div class="row ticket-fields">
            <div class="col-sm-12">
              <ul class="photos" v-if="signed.length">
                <li v-for="(sjob, pt) in signed" :key="pt">
                  <a :href="sjob.a_attr.href" target="_blank" :class="'pdf-icon'">
                    <img :src="'static/img/pdf-icon.svg'" />
                    {{ sjob.text }}
                  </a>
                </li>
              </ul>
              <p v-else>
                <span class="empty">(empty)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner />
</div>
