
<div class="row">
  <div class="col-md-8">
    <div class="card">
      <div class="content">
        <h3>Reset Password</h3>
        <reset-password/>
        <br>
      </div>
    </div>
  </div>
</div>
