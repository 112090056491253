
 <div class="wo-contacts">
   <div v-if="!woError">
     <h5>Contacts Assigned to Work Order</h5>

     <br />
     <button v-if="inSequence" class="btn" @click="next" v-html="'Next'" />
     <br />

     <bh-table :table-settings="contactsAssigned" @item-checked="itemChecked" @edit-item="editContact" />

     <hr />
     <br />

     <h5>All Contacts <span class="fa fa-plus-circle is-link" @click="editContact()" /></h5>

     <div class="row">
       <div class="col-sm-12 col-md-4">
         <div class="form-group">
           <label>Filter by Site</label>
           <select-field
             :options="woSiteOptions"
             :option-display-keys="['Ship_To_Name', 'site_address']"
             :option-val="'Ship_To_ID'"
             v-model="filterBySite"
             :filter-on-key-val="{ key: 'Status', val: 'A' }"
           />
         </div>
       </div>
     </div>

     <bh-table :table-settings="contactsAll" @item-checked="itemChecked" @edit-item="editContact" />

     <br />

     <!-- <button
       class="btn"
       @click="updateWO"
       v-html="'Update'"
     />
-->
     <button v-if="inSequence" class="btn" @click="next" v-html="'Next'" />
   </div>
   <p v-else>Problem loading WO Data, please close and continue from WO Details pages.</p>

   <waiting-spinner :container="true" />
 </div>
