<template>
  <div class="navbar-container">
    <nav class="navbar navbar-default">
      <div class="container-fluid">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle" :class="{ toggled: $sidebar.showSidebar }" @click="toggleSidebar">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar bar1"></span>
            <span class="icon-bar bar2"></span>
            <span class="icon-bar bar3"></span>
          </button>
          <span class="navbar-brand">{{ routeName }}</span>

          <!-- <a v-if="!['Work Order', 'Job', 'Jobs', 'Work Orders'].includes($route.name)" class="navbar-brand">{{ routeName }}</a>
          <div v-else class="navbar-brand-wrapper" @click="selectJobWO($route.name)">
            <a class="navbar-brand">{{ routeName }}</a>
            <i class="ti-angle-down is-link"></i>
          </div> -->
        </div>
        <div class="navbar-right-menu">
          <ul class="nav navbar-nav navbar-right">
            <li v-if="usersName">
              <span>Welcome, {{ usersName }}</span>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- <div class="refresh-button">
      <div :class="['refresh-button-inner', { spin: deletingStorage }]">
        <i class="ti-reload" @click="deleteStorage" :title="'Clear Saved Data'"></i>
      </div>
    </div> -->
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'

export default {
  computed: {
    env() {
      return process.env.NODE_ENV
    },

    routeName() {
      const { name } = this.$route
      const itmId = (this.$route.params || {}).id || null

      if (name === 'Work Order' && itmId) {
        return this.capitalizeFirstLetter(name) + ' #' + itmId
      }

      if (name === 'Job' && itmId) {
        return this.capitalizeFirstLetter(name) + ' #' + itmId
      }

      return this.capitalizeFirstLetter(name)
    },

    usersName() {
      var userAuthedData = store.getters.userAuthedData
      if (userAuthedData) {
        return userAuthedData.fname + ' ' + userAuthedData.mname + ' ' + userAuthedData.lname
      }
      return ''
    }
  },
  data() {
    return {
      activeNotifications: false,
      deletingStorage: false
    }
  },
  methods: {
    selectJobWO(name) {
      const type = name === 'Job' || name === 'Jobs' ? 'Job' : 'WO'
      this.$Modal({
        parent: this,
        name: 'job-wo-select', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        title: `Select ${type}`,
        component: () => import('components/Dashboard/JobWo/JobWOSearch.vue'),
        props: {
          type
        }
      })
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown() {
      this.activeNotifications = false
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    deleteStorage() {
      return
      this.deletingStorage = true
      setTimeout(() => {
        this.deletingStorage = false
      }, 1000)
      let sherAuth = appFuncs.storageGet('sherAuth')
      let userAuthedData = appFuncs.storageGet('userAuthedData')
      localStorage.clear()
      sessionStorage.clear()
      appFuncs.storageSave('sherAuth', sherAuth, 3600)
      appFuncs.storageSave('userAuthedData', userAuthedData, 3600)

      var alertVals = {
        show: 1,
        message: 'Browser data storage successfully cleared',
        state: 'success',
        dismiss: 'auto'
      }
      store.commit('alerts', alertVals)

      setTimeout(() => {
        location.reload()
      }, 1500)
    }
  }
}
</script>
<style lang="scss">
.navbar-container {
  position: relative;
  z-index: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.refresh-button {
  padding: 10px 15px 0;

  .refresh-button-inner {
    width: 15px;
    float: right;

    &.spin {
      animation: spin 1s infinite;
    }
  }

  i {
    cursor: pointer;
    display: block;
  }
}
</style>
