<template>
  <div>
    <div class="content">
      <div class="card">
        <h5>Edit Cost Center</h5>
        <div v-if="!singleField" class="form-group name-group">
          <label>Department Name</label>
          <input type="text" v-model="localObj.name" class="form-control"/>
          <span @click="clearName()" class="edit-delete is-link">
            <i class="ti-close"/>
          </span>
        </div>
        <div class="form-group value-group">
          <label>Add Cost Centers</label>
          <input type="text" v-model="localObj.value" class="form-control" readonly @click="showJobCostCenters('Code')"/>
          <span @click="clearValue()" class="edit-delete is-link">
            <i class="ti-close"/>
          </span>
        </div>

        <div v-if="localObj.id && !isNew">
          <admin-option-field
            :field="`sub_cost_centers_${localObj.id}`"
            :label="'Sub Cost Center'"
            :detail-value="true"
            :show-value="true"
            :show-default="false"
            :allow-delete="true"
            :edit-component="SubCostCenterEditComponent"
          />
        </div>

        <br/><br/>

        <button class="btn btn-success" @click="saveItem">Save</button>
        <button class="btn btn-danger" @click="closeModal">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import AdminOptionField from 'components/UIComponents/AdminOptionField'
import SubCostCenterEditComponent from 'components/UIComponents/AdminOptionFieldSubCostCenterEditor.vue'

export default {
  name: 'AdminOptionFieldCostCenterEditor',
  data() {
    return {
      localObj: {
        name: '',
        value: '',
        editRef: '',
        id: null
      },
      SubCostCenterEditComponent,
      isNew: false
    }
  },

  props: {
    obj: {
      type: Object,
      required: true
    },
    singleField: {
      type: Boolean,
      default: false
    },
    editRef: {
      type: String,
      required: true
    }
  },

  components: {
    AdminOptionField
  },

  methods: {
    saveItem() {
      if (!this.singleField && !this.localObj.name) {
        this.$snack.open('Field name cannot be empty', 'warning')
        return
      }
      if (!this.localObj.value) {
        this.$snack.open('Field value cannot be empty', 'warning')
        return
      }
      this.$bus.$emit('saveAdminOption', this.localObj)
      this.closeModal()
    },

    closeModal() {
      this.$bus.$emit('modalClose', 'AdminOptionFieldCostCenterEditor')
    },

    showJobCostCenters(context) {
      this.$Modal({
        parent: this,
        name: 'select-cost-centers',
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        title: 'Select Cost Center',
        component: () => import('components/UIComponents/SelectCostCenter.vue'),
        props: {
          context,
          parentRef: 'AdminOptionFieldCostCenterEditor'
        }
      })
    },

    clearValue() {
      let trimmedValue = this.localObj.value.trim()

      if (!trimmedValue) {
        this.localObj.value = ''
        return
      }

      let items = trimmedValue
        .split(',')
        .map(item => item.trim())
        .filter(item => item) // This makes empty strings disappear.

      if (items.length === 0) {
        this.localObj.value = ''
        return
      }

      // remove the last item
      items.pop()

      this.localObj.value = items.join(', ')
    },

    clearName() {
      this.localObj.name = ''
    },

    listenSelectCostCenter(obj) {
      const ref = obj.parentRef
      if (ref !== 'AdminOptionFieldCostCenterEditor') return
      const context = obj.context
      if (context === 'Name') {
        this.localObj.name = obj.Name
      } else {
        if (!this.localObj.value) {
          this.localObj.value = obj.Code.toString()
          return
        }
        this.updateValue(obj)
      }
    },

    updateValue(obj) {
      // Ensuring our value is treated as a string, even if it decided to wake up as something else today
      let currentValue = this.localObj.value.toString().trim()

      // Splitting by comma, trimming spaces, and filtering out empty strings - like a bouncer at a club
      let items = currentValue
        .split(',')
        .map(item => item.trim())
        .filter(item => item)

      // Convert our obj.Code to a string, just in case it's got identity issues
      let newCode = obj.Code.toString().trim()

      // If our newCode isn't already part of our exclusive array club, let it join the party
      if (!items.includes(newCode)) {
        // Adding our newCode to the array if it's not empty, otherwise just set it as the first guest
        items.push(newCode)
        // And now, for the grand finale, we bring everyone back together into a string
        this.localObj.value = items.join(', ')
      }
    }
  },

  mounted() {
    this.localObj = {
      name: this.obj.name,
      value: this.obj.value,
      editRef: this.editRef,
      id: this.obj.id
    }
    this.$bus.$on('selectCostCenter', this.listenSelectCostCenter)

    if (this.obj.name && this.obj.value) {
      this.isNew = false
    } else {
      this.isNew = true
    }
  },

  beforeDestroy() {
    this.$bus.$off('selectCostCenter', this.listenSelectCostCenter)
  }
}
</script>

<style scoped lang="scss">
.value-group,
.name-group {
  input {
    width: calc(100% - 40px);
    display: inline-block;
  }

  .edit-delete {
    cursor: pointer;
    margin-left: 5px;
    display: inline-block;
  }
}
</style>
