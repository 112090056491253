
<div>
  <div class="service-request-send-container">
    <div v-if="serviceReportLog.length">
      <div v-for="(log, ltk) in serviceReportLog" :key="ltk">
        <ul v-if="log.itms && log.time">
          <p>
            <strong>{{ dateFromUnix(log.time, 'show date') }}</strong>
          </p>
          <li v-for="(logItms, li) in log.itms" :key="li">
            <span v-if="logItms.name">{{ logItms.name }}</span>
            <span v-if="logItms.email">{{ logItms.email }}</span>
          </li>
        </ul>
      </div>
    </div>
    <p v-else>(empty)</p>
  </div>
</div>
