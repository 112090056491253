
<div>
  <div class="add-exoense-item-container">
    <div class="row upper row-no-gutters">
      <div class="col-xs-8">
        <h4>{{ expenseObj.name }}</h4>
      </div>
      <div class="col-xs-4 cancel-container">
        <a @click="cancel">Cancel</a>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="btn-group" v-if="expenseType === 'jobwo'">
          <button class="btn btn-no-radius btn-blue btn-pip-lg" @click="expenseType = 'job'">Job</button>
          <button class="btn btn-no-radius btn-blue btn-pip-lg" @click="expenseType = 'wo'">Work Order</button>
          <br /><br /><br />
        </div>

        <div v-else>
          <div v-if="expenseType === 'wo'">
            <div class="form-group">
              <label>Work Order</label><span class="required-star">*</span>
              <select-field-dynamic
                v-model="expense.WO_Number"
                :options="wos"
                :option-display-keys="['WO_Number', 'customer_name', 'Summary_Description']"
                :option-val="'WO_Number'"
                :empty-option-text="'Fetching items...'"
                :label="'Work Orders'"
              />
            </div>
          </div>

          <div v-if="expenseType === 'job'">
            <div class="form-group">
              <label>Job Number</label><span class="required-star">*</span>
              <select-field-dynamic
                v-model="expense.Job_Number"
                :options="jobAssemble"
                :option-display-keys="['Job_Number', 'Name', 'Job_Description']"
                :option-val="'Job_Number'"
                :empty-option-text="'Fetching items...'"
                :label="'Job Numbers'"
              />
            </div>
          </div>

          <div v-if="expenseType === 'truck'">
            <div class="form-group">
              <label>Equipment</label><span class="required-star">*</span>
              <select-field-dynamic
                v-model="expense.Equipment_Code"
                :options="expenseObj.equipmentList"
                :option-display-keys="['Name']"
                :option-val="'Code'"
                :empty-option-text="'Fetching items...'"
                :label="'Equipment'"
              />
            </div>
          </div>

          <div v-if="expenseType === 'truck'">
            <div class="form-group">
              <label>Expense Type</label><span class="required-star">*</span>
              <select-field-dynamic
                v-model="expense.cost_category_code"
                :options="truckExpenseTypes"
                :option-display-keys="['Cost_Category_Description']"
                :option-val="'Cost_Category_Code'"
                :empty-option-text="'Fetching items...'"
                :label="'Expense Type'"
              />
            </div>
          </div>

          <div v-else-if="expenseType === 'personal'" class="form-group">
            <label>Expense Type</label><span class="required-star">*</span>
            <!-- <div class="row" v-if="expense.type == 13 || expense.type == 12">
              <div class="col-md-12 text-right">Eligible Amount: {{ formatNumber(eligible_amount, 1) }}</div>
            </div> -->
            <select-field-dynamic
              v-model="expense.type"
              :options="personalExpenseTypes"
              :option-display-keys="['Name']"
              :option-val="'type'"
              :empty-option-text="'Fetching items...'"
              :label="'Expense Type'"
              @selectItem="setPerDiemDates"
            />
            <p class="text-danger" style="padding: 4px 0" v-if="expense.type == 5">This is never to be used for Job or WO based travel</p>
            <p class="text-danger" style="padding: 4px 0" v-if="expense.type == 7">This is not for Per Diems. If wanting Per Diem please use Job or WO entry screen</p>
          </div>

          <div v-else class="form-group">
            <label>Expense Type</label><span class="required-star">*</span>
            <select-field-dynamic
              v-model="selectedExpenseType"
              :options="expenseObj.types"
              :option-display-keys="['Name']"
              :option-val="'id'"
              :empty-option-text="'Fetching items...'"
              :label="'Expense Type'"
              @selectItem="setExpenseName"
            />
          </div>

          <div v-if="expenseType !== 'personal' && expenseName === 'Per Diem'">
            <div class="form-group">
              <label>Start Date</label><span class="required-star">*</span>
              <date-picker
                v-model="expense.startDate"
                :format="'string'"
                :is-inline="false"
                :pre-filled="false"
                ref="datePicker"
                @selectDate="setPerDiemDates"
                :min-date="minMaxDates.minDate"
                :max-date="minMaxDates.maxDate"
              />
            </div>

            <div class="form-group">
              <label>End Date</label><span class="required-star">*</span>
              <date-picker
                v-model="expense.endDate"
                :format="'string'"
                :is-inline="false"
                :pre-filled="false"
                ref="datePicker"
                @selectDate="setPerDiemDates"
                :min-date="minMaxDates.minDate"
                :max-date="minMaxDates.maxDate"
              />
            </div>
          </div>

          <div v-if="expenseName === 'Job/WO CBA Travel'" class="form-group">
            <div class="form-group">
              <label>Trip Dates</label><span class="required-star">*</span>
              <p>One date per round trip</p>
              <date-picker
                v-model="expense.tripDates"
                :format="'string'"
                :is-inline="false"
                :pre-filled="false"
                ref="datePicker"
                @selectDate="setTripDates"
                :min-date="minMaxDates.minDate"
                :max-date="minMaxDates.maxDate"
                :mode="'multiple'"
              />
            </div>
          </div>

          <div class="form-group" v-if="expenseName === 'Job/WO CBA Travel'">
            <label>KM Distance</label><span class="required-star">*</span>
            <select-field-dynamic
              v-model="travelDistance"
              :options="[
                { name: '60 km - 120 km', val: 50.0 },
                { name: '120 km - 200 km', val: 55.0 },
                { name: '200 km - 400 km', val: 60.0 },
                { name: '400 km and more', val: 65.0 }
              ]"
              :option-display-keys="['name']"
              :option-val="'val'"
              :empty-option-text="'Fetching items...'"
              :label="'KM Distance'"
              @selectItem="setTripDates"
            />
          </div>

          <div v-if="expenseName === 'Job/WO CBA Travel' || expenseName === 'Per Diem'" class="form-group">
            <label>Qty</label><span class="required-star">*</span>
            <select-field-dynamic
              v-model="expense.qty"
              :options="[{ val: 1 }, { val: 2 }, { val: 3 }, { val: 4 }, { val: 5 }, { val: 6 }, { val: 7 }, { val: 8 }, { val: 9 }, { val: 10 }, { val: 11 }, { val: 12 }, { val: 13 }, { val: 14 }]"
              :option-display-keys="['val']"
              :option-val="'val'"
              :empty-option-text="'Fetching items...'"
              :label="'Qty'"
              disabled
            />
          </div>

          <div v-if="expense.Job_Number && expense.type && !costTypePhases.length">
            <p class="text-danger">There are no phases available for this Job Number and Expense Type</p>
          </div>
          <div v-if="expense.Job_Number" class="form-group">
            <label>Phase</label><span class="required-star">*</span>
            <select-field-dynamic
              v-model="expense.Phase_Code"
              :options="costTypePhases"
              :option-display-keys="['Phase_Code', 'Description']"
              :option-val="'Phase_Code'"
              :empty-option-text="'Fetching items...'"
              :label="'Job Numbers'"
              :disabled="!costTypePhases.length"
            />
          </div>
        </div>

        <div class="form-group">
          <label>Description</label><span class="required-star">*</span>
          <input type="text" class="form-control" v-model="expense.description" :disabled="expenseName === 'Per Diem' || expenseName === 'Job/WO CBA Travel'" />
        </div>

        <div class="form-group">
          <label>Sub Total</label><span class="required-star">*</span>
          <!-- <p v-if="calculatedAmount >= eligible_amount && (expense.type == 13 || expense.type == 12)" class="text-danger" style="padding: 10px 0">
            Please note that entered Sub total is higher than Eligible amount.
          </p> -->
          <vue-numeric
            currency="$"
            separator=","
            :class="['form-control']"
            :precision="2"
            v-model="expense.sub_total"
            inputmode="decimal"
            :disabled="expenseName === 'Per Diem' || expenseName === 'Travel Expense' || expenseName === 'Job/WO CBA Travel'"
          />

          <p v-if="expenseType === 'truck' && expense.Equipment_Category === 'FUEL'" class="text-danger" style="padding: 10px 0">
            Please note that the fuel dollar value entered will automatically have HST subtracted then re-added as totalled within itemized list."
          </p>
        </div>

        <div class="row button-row">
          <div class="col-sm-12">
            <div class="btn-group">
              <button class="btn btn-no-radius btn-blue btn-pip-lg" @click="save">Save &amp; Continue</button>
              <!-- <button
                class="btn btn-no-radius btn-blue btn-pip-lg"
                @click="saveAdd"
              >
                Add Another
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner />
</div>
