
<div class="form-group" style="max-width: 300px">
  <label>View as Employee</label>
  <select-field
    :options="employeeOptions"
    :option-display-keys="['Employee_Name']"
    :option-val="'Employee_Code'"
    v-model="employee"
    @selectItem="selectEmployee"
    :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
    :allow-clear="isAdmin"
  />
</div>
