
<div>
  <div class="sh-widget wide-full card">
    <div class="">
      <div class="content">
        <div class="widget-header">
          <h4>Hours table ({{ count }})</h4>
          <button class="btn btn-sm" @click="downloadCSV">Download CSV</button>
        </div>
        <div class="content-inner show-scrollbars">
          <tabulator-table :table-data="tableDataHours" :table-columns="tableColumnsHours" :table-condensed="false"
            empty-text="No results" :selectable="false" ref="hoursDataTable" max-table-height="600px"
            :table-striped="true" :initial-sort="initialSort" />
        </div>
      </div>
    </div>
    <waiting-spinner :contained="true" />
  </div>
</div>
