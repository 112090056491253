
<!-- <div
  :style="{ overflowY: 'scroll', minHeight: '50px', maxHeight: maxTableHeight }"
  :class="['show-scrollbars', { 'table-condensed': tableCondensed }, { 'table-striped': tableStriped }, { 'table-bordered': tableBordered }]"
  :id="tableId"
/> -->

<div class="table-tiles">
  <div v-for="(row, rowIndex) in data" :key="'row-' + rowIndex" class="card card-light">
    <div class="content">
      <div v-for="(col, colIndex) in tableColumns" :key="'col-' + colIndex + '-' + rowIndex">
        <div v-if="row[col.field] && col.displayType === 'header'">
          <div class="header-row">
            <div class="tab-header">
              <span v-if="!col.hideName(col.field, row)">{{ col.title || col.value }}</span> {{ callFormatter(row, col) }}
            </div>
            <div v-if="selectable" class="selectable-row">
              <checkbox2 v-model="row.selected" :true-val="'yes'" :false-val="'no'" />
            </div>
          </div>
        </div>
      </div>
      <div @click="$emit('colClick', row)">
        <div v-for="(col, colIndex) in tableColumns" :key="'col3-' + colIndex + '-' + rowIndex">
          <div v-if="row[col.field] && !col.displayType && col.visible !== false">
            <strong v-if="!col.hideName(col.field, row)">{{ col.title || col.value }}: </strong> {{ callFormatter(row, col) }}
          </div>
        </div>
        <div v-for="(col, colIndex) in tableColumns" :key="'col2-' + colIndex + '-' + rowIndex">
          <div v-if="row[col.field] && col.displayType === 'lowerRight'">
            <div style="text-align: right; font-weight: bold; border-top: 1px solid #ccc; margin-top: 5px; padding-top: 5px" class="text-blue">
              <span v-if="!col.hideName(col.field, row)">{{ col.title || col.value }}</span> {{ callFormatter(row, col) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
