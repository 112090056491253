
<div>
  <div class="content">
    <div class="card">
      <h5>Edit Cost Center</h5>
      <div v-if="!singleField" class="form-group name-group">
        <label>Department Name</label>
        <input type="text" v-model="localObj.name" class="form-control"/>
        <span @click="clearName()" class="edit-delete is-link">
          <i class="ti-close"/>
        </span>
      </div>
      <div class="form-group value-group">
        <label>Add Cost Centers</label>
        <input type="text" v-model="localObj.value" class="form-control" readonly @click="showJobCostCenters('Code')"/>
        <span @click="clearValue()" class="edit-delete is-link">
          <i class="ti-close"/>
        </span>
      </div>

      <div v-if="localObj.id && !isNew">
        <admin-option-field
          :field="`sub_cost_centers_${localObj.id}`"
          :label="'Sub Cost Center'"
          :detail-value="true"
          :show-value="true"
          :show-default="false"
          :allow-delete="true"
          :edit-component="SubCostCenterEditComponent"
        />
      </div>

      <br/><br/>

      <button class="btn btn-success" @click="saveItem">Save</button>
      <button class="btn btn-danger" @click="closeModal">Cancel</button>
    </div>
  </div>
</div>
