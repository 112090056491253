
<div class="file-upload-container">
  <div class="row">
    <div :class="'col-sm-12'">
      <div v-for="config in updatedViewerConfig" :key="config.dzId" class="col-sm-4">
        <div v-if="showFileUpload">
          <label v-if="config.label || config.name">{{ config.label || config.name }}</label
          ><span v-if="config.required" class="required-star">*</span>

          <span v-if="existingFiles && existingFiles.length >= 1 && !readonly">
            <a href="#" class="label-link ti-download" @click="triggerDownload(config)" />
            <a v-if="hasViewable" href="#" class="label-link ti-eye" @click="showPictures" />
          </span>
          <p v-if="config.subText" style="margin: 0; padding: 0">
            <i>{{ config.subText }}</i>
          </p>
        </div>
        <div v-if="!readonly && showFileUpload" class="file-upload-inner" style="margin-bottom: 80px">
          <div :id="config.dzId" :ref="`dzref:${config.dzId}`" class="dropzone" />
          <input
            v-if="useComment"
            type="text"
            v-model="fileComment[config.dzId]"
            @input="updateButtonState(config.dzId)"
            class="form-control"
            style="margin-top: 24px"
            placeholder="Add a comment for new file"
          />
          <button class="btn btn-sm pull-right" style="margin: 10px 0" @click="processQueue(config)" :disabled="!buttonStates[config.dzId]">Upload File</button>
        </div>
      </div>
      <div style="margin: 30px 0">
        <tabulator-table
          :table-data="existingFiles"
          :table-columns="tableColumns"
          table-fit="fitColumns"
          :table-condensed="false"
          empty-text="(none)"
          @colClick="fileTableClick"
          max-table-height="300px"
        />
      </div>
    </div>

    <div class="col-sm-12">
      <component :is="portalName ? 'portal' : 'div'" :to="portalName">
        <div class="file-viewer">
          <div v-if="fileIsSelected">
            <iframe v-if="selectedFile.type === 'application/pdf'" :src="selectedFile.url" width="100%" height="600" />
            <div v-else-if="fileErrorText" class="text-danger">
              {{ fileErrorText }}
            </div>
            <img v-else-if="selectedFile.type && selectedFile.type.startsWith('image/')" :src="selectedFile.url" alt="Selected Image" />
            <div v-else-if="selectedFile.type === 'text/csv'" v-html="csvContent" />
            <div class="file-viewer-links" v-if="selectedFile && selectedFile.url">
              <span class="is-link" @click="openInNewTab">
                <i class="ti-new-window" style="padding: 0 3px 3px 3px" />
              </span>
              <span class="is-link" @click="closePreview">
                <i class="ti-na" style="padding: 3px" />
              </span>
            </div>
          </div>
          <div v-else class="empty-file-container">
            <p class="no-file-message">No file selected</p>
          </div>
        </div>
      </component>
    </div>
    <div v-html="downloadForm" />
    <waiting-spinner />
  </div>
</div>
