<template>
  <div class="file-upload-container">
    <div class="row">
      <div v-if="useSimpleUpload && !readonly" class="col-sm-12">
        <!-- <div class="btn btn-sm display-as-button" :id="dzId">Upload File</div> -->
        <button class="btn btn-sm" style="margin: 10px 0" @click="useSimpleUpload = false">Upload File</button>
      </div>
      <div v-else :class="fileIsSelected ? 'col-sm-12 col-md-8' : 'col-sm-12'">
        <label v-if="label || (existingFiles.length && !readonly)">{{ label }}</label>
        <span v-if="existingFiles && existingFiles.length >= 1 && !readonly">
          <a href="#" class="label-link ti-download" @click="triggerDownload()" v-if="!isUnauth" />
          <a v-if="hasViewable" href="#" class="label-link ti-eye" @click="showPictures" />
        </span>
        <div class="file-upload-inner">
          <div :id="dzId" class="dropzone" v-if="!readonly" />
          <ul v-if="existingFiles && existingFiles.length >= 1">
            <li v-for="(mainFile, mfk) in existingFiles" :key="mfk">
              <a v-if="mainFile && mainFile.a_attr && showPreview" href="javascript:void(0)" @click="selectFile(mainFile)">{{ mainFile.text }}</a>
              <a v-else-if="mainFile && mainFile.a_attr" :href="mainFile.a_attr.href" target="_blank">{{ mainFile.text }}</a>
              <span :class="['file-delete', showDeleteIcon ? 'show-delete-icon' : '']" v-if="allowDelete" @click="fileDelete(mainFile)">
                <i class="ti-close" />
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="file-viewer col-sm-12 col-md-4" v-if="fileIsSelected">
        <iframe v-if="selectedFile.type === 'application/pdf'" :src="selectedFile.url" width="100%" height="600" />
        <div v-else-if="fileErrorText" class="text-danger">
          {{ fileErrorText }}
        </div>
        <img v-else-if="selectedFile.type && selectedFile.type.startsWith('image/')" :src="selectedFile.url" alt="Selected Image" />
        <div v-else-if="selectedFile.type === 'text/csv'" v-html="csvContent" />
        <div class="file-viewer-links" v-if="selectedFile && selectedFile.url">
          <span class="is-link" @click="openInNewTab">
            <i class="ti-new-window" style="padding: 0 3px 3px 3px" />
          </span>
          <span class="is-link" @click="closePreview">
            <i class="ti-na" style="padding: 3px" />
          </span>
        </div>
      </div>
      <div v-html="downloadForm" />
    </div>
  </div>
</template>

<script>
import store from 'store'
import appFuncs from 'appFuncs'
import Dropzone from 'dropzone'
import 'dropzone/dist/dropzone.css'
import GalleryPictures from 'src/components/GeneralViews/GalleryPictures.vue'
import $ from 'jquery'

Dropzone.autoDiscover = false

export default {
  data() {
    return {
      uploadUrl: store.getters.urls.backend + 'file-uploader.php',
      existingFiles: [],
      fileCount: 0,
      successfulUploads: 0,
      dzObj: null,
      downloadForm: '',
      fileName: '',
      dzId: '',
      dropzoneSet: false,
      hasViewable: false,
      allowDelete: false,
      useSimpleUpload: false,
      selectedFile: {
        name: '',
        url: '',
        type: ''
      },
      csvContent: '',
      fileErrorText: ''
    }
  },

  props: {
    path: {
      type: String,
      required: true
    },

    subdir: {
      type: String,
      default: ''
    },

    zipDepth: {
      type: [String, Number],
      default: 0
    },

    label: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      default: ''
    },

    canDelete: {
      type: [Boolean, null],
      default: null
    },

    typeGroup: {
      type: String,
      default: ''
    },

    simpleUpload: {
      type: Boolean,
      default: false
    },

    uploadData: {
      type: Object,
      default: () => {
        return {}
      }
    },

    waitForTrigger: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    showPreview: {
      type: Boolean,
      default: false
    },
    authCode: {
      type: String,
      default: ''
    }
  },

  computed: {
    isAdmin() {
      return store.getters.userAuthedData.user_role?.indexOf('admin') !== -1
    },

    computedPath() {
      return this.path
    },

    fileIsSelected() {
      return this.showPreview && this.selectedFile && this.selectedFile.name !== ''
    },
    isUnauth() {
      return !!(!store.getters.sherAuth?.jwt && this.authCode)
    },
    showDeleteIcon() {
      return !!store.getters.sherAuth?.jwt
    }
  },

  methods: {
    closePreview() {
      this.selectedFile = {
        name: '',
        url: '',
        type: ''
      }
    },

    get_uploaded_files() {
      const data = {
        action: 'get_directory_contents',
        dir: this.computedPath,
        subdir: this.subdir
      }

      if (!!(!store.getters.sherAuth?.jwt && this.$route.params.authCode)) {
        data.action = 'get_directory_contents_unauth'
        data.auth_code = this.$route.params.authCode
      }

      appFuncs.ajax_request(store.getters.sherAuth, data, result => {
        this.$store.commit('waiting', {})
        if (result.status === 'success') {
          let data = result.data || []
          this.existingFiles = data.filter(itm => {
            return itm.a_attr
          })
        } else {
          this.$snack.open('Problem fetching some uploaded files', 'error')
        }
      })
    },

    openInNewTab() {
      window.open(this.selectedFile.url, '_blank')
    },

    processQueue() {
      if (this.dzObj) {
        this.dzObj.processQueue() // Manually starts the upload process.
      }
    },

    setDropzones() {
      var self = this
      if (this.readonly) {
        this.dropzoneSet = true
        self.get_uploaded_files()
        return
      }
      if (this.dzObj) return
      var id = 'div#' + self.dzId
      this.dzObj = new Dropzone(id, {
        maxFiles: this.isUnauth ? 3 : null,
        url: this.uploadUrl,
        params: {
          name: self.dzId,
          auth_code: this.isUnauth ? this.authCode : '',
          from: this.$route.path.includes('customer-ticket') ? 'customer_ticket' : ''
        },
        parallelUploads: 10,
        // uploadMultiple: true, // creates server error currently
        createImageThumbnails: false,
        dictDefaultMessage: 'Drop or click to upload',
        autoProcessQueue: this.computedPath && !this.waitForTrigger ? true : false
        // clickable: this.useSimpleUpload ? true : Dropzone.defaultOptions.clickable
        // acceptedFiles: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,application/vnd.ms-powerpoint,application/pdf,image/png,application/rtf,text/plain,application/zip,text/csv,image/bmp,application/epub+zip,video/mpeg,audio/wav,audio/webm,video/webm,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-outlook,application/x-hwp,application/octet-stream,application/x-hwp,application/CDFV2-unknown,message/rfc822',
      })

      this.dzObj.on('maxfilesexceeded', file => {
        this.removeFile(file)
        this.$snack.open('You can only upload 3 files at a time', 'warning')
      })

      this.dzObj.on('sending', (file, xhr, formData) => {
        formData.append('path', self.computedPath)
        formData.append('subdir', self.subdir)
        formData.append('jwt', store.getters.sherAuth.jwt)
        formData.append('user_login', store.getters.sherAuth.user_login)
        formData.append('expires', store.getters.sherAuth.expires)
        formData.append('type_group', this.typeGroup)

        for (let key in this.uploadData) {
          formData.append(`upload_data[${key}]`, this.uploadData[key])
        }

        // var name = formData.get('name');
        // if(name === 'dropzone-this-version'){
        //   formData.append("quote_version", self.quoteRevision);
        // }
      })

      this.dzObj.on('success', file => {
        self.successfulUploads++
        if (self.successfulUploads == self.fileCount) {
          this.dzObj.removeAllFiles(true)
          self.get_uploaded_files()
          self.successfulUploads = 0
          self.fileCount = 0
          self.$bus.$emit('filesUploaded', {
            name: self.name
          })
          self.$emit('updated')
          self.$emit('file-uploaded')
          if (this.useSimpleUpload) {
            this.$snack.open('File Uploaded', 'success')
          }
        } else {
          this.$snack.open('Problem uploading file, please try again', 'error')
        }
      })

      this.dzObj.on('error', (file, error, xhr) => {
        // error = typeof error === 'string' ? error : 'Problem uploading file, please try again';
        console.log(error)
        if (error.includes('file_limit_exceeded')) {
          this.$snack.open('Exceeded the maximum file upload limit of 3', 'warning')
          return
        }
        this.$snack.open('File not uploaded', 'warning')
        this.fileCount--
      })

      this.dzObj.on('addedfile', file => {
        this.$emit('fileAdded', file)
        self.fileCount++
      })

      this.dropzoneSet = true

      self.get_uploaded_files()
    },

    fileDelete(obj) {
      var self = this

      if (!this.allowDelete) {
        return false
      }

      if (!confirm('Are you sure you\'d like to delete "' + obj.text + '" ?')) {
        return
      }

      var data = {
        action: 'delete_file_aws',
        file_location: obj.location
      }

      appFuncs.ajax_request(store.getters.sherAuth, data, function (result) {
        if (result.status === 'success') {
          self.$emit('updated')
          self.get_uploaded_files()
        } else {
          this.$snack.open(result.message, 'error')
        }
      })
    },

    showPictures() {
      this.$Modal({
        parent: this,
        name: 'gallery-pictures', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        component: GalleryPictures,
        props: {
          pictures: this.existingFiles
        }
      })
    },

    triggerDownload(dir) {
      // using dynamic form to send POST vals and download in new tab
      var path = this.computedPath + this.subdir
      var form = '<form id="download-form-' + this.dzId + '" method="POST" action="' + store.getters.urls.backend + 'download-file.php" target="_blank">'
      form += '<input type="hidden" name="action" value="zip_folder_aws">'
      form += '<input type="hidden" name="dirpath" value="' + encodeURI(path) + '">'
      form += '<input type="hidden" name="filename" value="' + encodeURI(this.fileName) + '">'
      form += '<input type="hidden" name="depth" value="' + this.zipDepth + '">' // -1 gets all nested directories, 0 files but no dirs
      form += '<input type="hidden" name="jwt" value="' + store.getters.sherAuth.jwt + '">'
      form += '</form>'
      this.downloadForm = form

      setTimeout(() => {
        $('#download-form-' + this.dzId).submit()
        this.downloadForm = null
      }, 500)
    },

    startDropzone() {
      this.dzId = 'dz_' + this.randomCharacterString(10) //this.subdir;
      var fileName = this.computedPath + this.subdir
      this.fileName = fileName.replace(/\//g, '_')
    },

    getMimeType(fileName) {
      const extension = fileName.split('.').pop().toLowerCase()
      const mimeTypes = {
        pdf: 'application/pdf',
        csv: 'text/csv',
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png'
        // Add more mappings as needed
      }
      return mimeTypes[extension] || ''
    },

    selectFile(file) {
      const type = this.getMimeType(file.text)
      this.selectedFile = {
        name: file.text,
        url: file.a_attr.href,
        type
      }
      this.fileErrorText = ''
      if (!type) {
        this.fileErrorText = 'Unsupported file type for preview'
        return
      }
      if (this.selectedFile.type === 'text/csv') {
        this.loadCSV(file)
      }
    },

    async loadCSV(file) {
      try {
        const response = await fetch(file.url)
        if (!response.body) {
          throw new Error('ReadableStream not supported in this environment')
        }

        const reader = response.body.getReader()
        const decoder = new TextDecoder('utf-8')
        let receivedLength = 0
        let chunks = []
        const maxLength = 100000

        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }

          chunks.push(value)
          receivedLength += value.length

          if (receivedLength > maxLength) {
            this.fileErrorText = 'File too large to display'
            console.log('Error:', this.fileErrorText) // Debugging statement
            return
          }
        }

        // If the file is not too large, download the entire file content
        const response2 = await fetch(file.url)
        const responseText = await response2.text()
        console.log('Raw CSV text:', responseText) // Debugging statement
        this.csvContent = this.csvToHtml(responseText)
      } catch (error) {
        console.error('Error loading CSV:', error)
        this.fileErrorText = 'Error loading CSV file'
        this.selectedFile = {
          name: '',
          url: '',
          type: ''
        }
      }
    },

    csvToHtml(csv) {
      const rows = csv.split('\n')
      let html = '<table>'
      rows.forEach((row, index) => {
        const cells = row.split(',')
        html += '<tr>'
        cells.forEach(cell => {
          html += index === 0 ? `<th>${cell}</th>` : `<td>${cell}</td>`
        })
        html += '</tr>'
      })
      html += '</table>'
      return html
    }
  },

  mounted() {
    this.useSimpleUpload = this.simpleUpload

    if (!this.useSimpleUpload) {
      this.startDropzone()
    }
    // allow delete if admin and can delete not set OR canDelete value, if canDelete is set than use bool set val
    this.allowDelete = (this.canDelete === null && this.isAdmin) || this.canDelete

    if (this.readonly) {
      this.setDropzones()
    }
  },

  updated() {
    // doing this here to ensure that template has updated dropzone dynamic ids
    if (!this.useSimpleUpload && this.dzId && !this.dropzoneSet) {
      this.setDropzones()
    }
  },

  watch: {
    useSimpleUpload: {
      handler: function (val, oldVal) {
        if (!val) {
          this.startDropzone()
        }
      },
      immediate: true
    },

    existingFiles(files) {
      this.hasViewable = false
      var viewTypes = ['png', 'jpg', 'jpeg', 'gif']
      for (var i = files.length - 1; i >= 0; i--) {
        if (files[i]['type'] && viewTypes.indexOf(files[i]['type']) !== -1) {
          this.hasViewable = true
          break
        }
      }
    },

    path() {
      this.get_uploaded_files()
    }
  },

  beforeDestroy() {
    if (this.dzObj && typeof this.dzObj === 'object') {
      this.dzObj.disable()
    }
  }
}
</script>

<style lang="scss">
.dz-message {
  margin: 20px 10px !important;
  font-size: 12px;
  text-align: center;
}

.dz-progress {
  display: none !important;
}

.dz-error-mark g {
  fill: red !important;
}
</style>

<style lang="scss" scoped>
#download-form {
  display: none;
}

.file-upload-container {
  padding: 0px 0 0;
  position: relative;
  // min-height: 60px;
  z-index: 1;

  h4 {
    font-size: 16px;
    margin: 5px;
  }

  .file-upload-inner {
    padding: 0px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin: 5px 0 0;
  }

  ul {
    padding: 0;
    list-style-position: inside;
    margin: 0 0 5px;
    display: block;

    li {
      display: inline-block;
      border: 1px solid #9c9c9c;
      padding: 3px 8px;
      margin: 3px 6px 3px 0px;
      border-radius: 5px;
      position: relative;

      .file-delete {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        right: -8px;
        top: -6px;
        text-align: center;
        vertical-align: middle;
        line-height: 12px;
        cursor: pointer;
        background: #ff4343;
        display: none;

        &.show-delete-icon {
          &:hover {
            display: block;
          }
          i {
            font-size: 7px;
            color: #fff;
          }
        }
      }

      &:hover {
        border-color: #555;

        .file-delete {
          &.show-delete-icon {
            display: block;
          }
        }

        a {
          color: #333;
        }
      }
    }
  }

  .label-link {
    float: right;
    font-size: 14px;
    margin-top: 4px;
    margin: 0 5px 0 10px;
  }

  a {
    cursor: pointer;
    color: #555;
  }

  .no-files-text {
    font-size: 12px;
  }
}

.dropzone {
  border: 2px dashed #ababab;
  min-height: 70px;
  border-radius: 5px;
  background: transparent;
  padding: 5px;
  margin-bottom: 5px;
}

.area-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.display-as-button {
  margin: 10px 0 !important;

  > div,
  .dz-preview {
    display: none !important;
    visibility: hidden !important;
    margin: 10px 0 !important;
  }

  &.dz-drag-hover {
    border: 2px dashed #ababab !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    display: block;
    border-radius: 5px;
  }
}
</style>

<style lang="scss">
.display-as-button {
  margin: 10px 0 !important;

  .dz-preview {
    display: none !important;
    visibility: hidden !important;
    margin: 10px 0 !important;
  }
}

.file-viewer {
  img {
    width: 100%;
  }
  table {
    margin: 20px 0;
    border: 1px solid;
    border-bottom: 0;
    text-align: left;
    tr {
      border-top: 1px solid;
    }
    td,
    th {
      padding: 2px 5px;
    }
  }
}

.file-viewer-links {
  margin-top: 20px;
  span {
    display: inline-block;
  }
}
</style>
