
<div class="customer-ticket" id="customer-ticket">
  <div class="container">
    <div class="row header" v-if="wo.WO_Number">
      <div class="col-xs-12 col-sm-4">
        <router-link to="/">
          <img :src="`${urls.frontend}static/img/${logoFile}`" />
        </router-link>
      </div>
      <div class="col-xs-12 col-sm-4">
        <p v-html="regionAddress" />
      </div>
      <div class="col-xs-12 col-sm-4 text-right">
        <h4>
          Work Order<br />
          <strong> #<span v-html="wo.WO_Number" /> </strong>
        </h4>
        <!-- using woLoaded id for PDF generating start flag -->
      </div>
    </div>
    <!--
    <div class="row" v-if="woStatus">
      <div class="col-sm-12 wo-status-container">
        <span v-if="woStatus" class="wo-status">
          Status: <span :class="woStatusClass">{{ woStatus }}</span>
        </span>
      </div>
    </div>
    -->
    <div class="row wo-info" v-if="wo.customer_name">
      <div class="col-sm-12 col-md-6">
        <table class="wo-item-list border-bottom">
          <tr>
            <td class="col-medium">
              <strong>Customer Name</strong>
            </td>
            <td>
              <span v-html="wo.customer_name" />
            </td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Site Address</strong>
            </td>
            <td>
              <span v-if="wo.site_address" v-html="wo.site_address" />
              <br />
              <span v-if="wo.site_city" v-html="wo.site_city" /><span v-if="wo.site_province" v-html="', ' + wo.site_province" /><span
                v-if="wo.site_postal_code"
                v-html="', ' + wo.site_postal_code"
              />
            </td>
          </tr>
        </table>
        <p class="safety-text">Safety First - Every Time - No Shortcuts</p>
      </div>

      <div class="col-sm-12 col-md-6" v-if="wo.WO_Number" id="pdfReady">
        <table class="wo-item-list">
          <tr>
            <td class="col-medium">
              <strong>Date</strong>
            </td>
            <td v-if="wo.Finished_Date">{{ formatDate(wo.Finished_Date) }}</td>
            <td v-else>{{ formatDate(new Date()) }}</td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Customer PO</strong>
            </td>
            <td>
              <span v-html="wo.Customer_PO_Number" />
            </td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Requested By</strong>
            </td>
            <td>
              <span v-html="wo.Bill_Contract" />
            </td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Serviced By</strong>
            </td>
            <td>
              <span v-html="wo.Lead_Name" />
            </td>
          </tr>
        </table>
      </div>
    </div>

    <section class="row" v-if="wo_notes && wo_notes.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Scope of Work</th>
          </tr>
          <tr v-for="(note, i) in wo_notes" :key="i">
            <td class="col-medium">
              <span v-html="note.nice_date" />
            </td>
            <td v-html="getPlainText(note.note_text)" />
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="wo_work_ordered_notes && wo_work_ordered_notes.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Scope Notes</th>
          </tr>
          <tr v-for="(note, i) in wo_work_ordered_notes" :key="i">
            <td class="col-medium">
              <span v-html="note.nice_date" />
            </td>
            <td v-html="getPlainText(note.note_text)" />
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="wo_completed_notes && wo_completed_notes.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Work Completed Notes</th>
          </tr>
          <tr v-for="(note, i) in wo_completed_notes" :key="i">
            <td class="col-medium">
              {{ note.nice_date }}
            </td>
            <td v-html="getPlainText(note.note_text)" />
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="!isQuote && timeEntries && timeEntries.length">
      <div class="col-sm-12">
        <table v-if="totalLaborHours >= 4 || showLaborLog" class="wo-item-list time-log">
          <tr>
            <th class="col-narrow">Date</th>
            <th v-if="showLaborLog">Time Log</th>
            <th>Employee</th>
            <th v-if="totalLaborHours >= 4">Hours</th>
            <th v-if="totalLaborHours >= 4">Type</th>
          </tr>
          <tr v-for="(entry, i) in timeEntries" :key="i">
            <td class="col-medium" v-html="entry.entry_date" />
            <td v-if="showLaborLog" v-html="getPlainText(entry.message)" />
            <td v-html="entry.employee_name" />
            <td v-if="totalLaborHours >= 4" v-html="entry.hours" />
            <td v-if="totalLaborHours >= 4" v-html="entry.hour_term" />
          </tr>
        </table>

        <table v-if="totalLaborHours < 4" class="wo-item-list time-log">
          <tr>
            <th>Labor</th>
          </tr>
          <tr>
            <td>Standard Service Charge</td>
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="wo_materials && wo_materials.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Materials</th>
          </tr>
          <tr v-for="(note, i) in wo_materials" :key="i">
            <td class="col-medium">
              {{ note.nice_date }}
            </td>
            <td v-html="getPlainText(note.note_text)" />
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="otherCharges && otherCharges.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Item #</th>
            <th>Other Charges</th>
          </tr>
          <tr v-for="(entry, i) in otherCharges" :key="i">
            <td v-html="i + 1" />
            <td v-html="getPlainText(entry.Description)" />
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="wo_recommendation_notes.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Recommendations</th>
          </tr>
          <tr v-for="(note, i) in wo_recommendation_notes" :key="i">
            <td class="col-medium">
              {{ note.nice_date }}
            </td>
            <td v-html="getPlainText(note.note_text)" />
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="wo_extras_notes.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Work Order Extras</th>
          </tr>
          <tr v-for="(note, i) in wo_extras_notes" :key="i">
            <td class="col-medium">
              {{ note.nice_date }}
            </td>
            <td v-html="getPlainText(note.note_text)" />
          </tr>
        </table>
      </div>
    </section>

    <section v-if="wo.WO_Number">
      <table class="wo-item-list wo-checklist">
        <tr>
          <td><label>Complete</label></td>
          <td class="narrow">
            <i v-if="wo.Dispatch_Status_Code.toString() === '7' || wo.Dispatch_Status_Code == 'F'" class="ti ti-check" />
          </td>
          <td><label>Quotation</label></td>
          <td class="narrow">
            <i v-if="wo.Customer_PO_Number" class="ti ti-check" />
          </td>
          <td><label>Recommendation</label></td>
          <td class="narrow">
            <i v-if="wo_recommendation_notes && wo_recommendation_notes.length > 0" class="ti ti-check" />
          </td>
          <td><label>Safety</label></td>
          <td class="narrow">
            <i class="ti ti-check" />
          </td>
        </tr>
      </table>
    </section>

    <!--  <section class="row" v-for="ts in 7">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Space of notes to come</th>
          </tr>
          <tr v-for="row in Math.floor(Math.random() * Math.floor(30))">
            <td>2022-02-22</td>
            <td>This is a Twosday</td>
          </tr>
        </table>
      </div>
    </section> -->

    <section v-if="wo.WO_Number" class="save-section">
      <table class="wo-item-list border-bottom">
        <tr>
          <td>
            <p class="satisfaction-text">If for any reason our service did not exceed your expectations please contact us.</p>
          </td>
          <td>
            <div class="signature-display-container">
              <p><label>Customer Signature</label></p>
              <div v-if="wo.WO_Number">
                <div class="existing-signature" v-if="existingSig">
                  <img :src="this.urls.mssql + 'wo-signatures/' + existingSig" />
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </section>

    <div v-if="pdf" class="footer" style="margin-bottom: 20px">
      <div class="footer-inner">
        <div class="page-number">
          <p>Page 1 of 1</p>
        </div>
      </div>
    </div>

    <div v-if="wo.WO_Number">
      <div v-if="wo.Dispatch_Status_Code.toString() === '7' || wo.Dispatch_Status_Code === 'F'">
        <button v-if="!isLocked" class="btn not-pdf" @click="checkShowWoSign()" v-html="'Sign Off'" />
        <button v-if="!isLocked && sherAuth.eid" class="btn not-pdf" @click="showWoSignOffSend()" v-html="'Email Sign Off Link'" />
        <button v-if="lastSigned && isLocked" class="btn not-pdf" @click="downloadLastSigned()" v-html="'Download PDF'" />
        <button v-if="isAdmin && existingSig" class="btn not-pdf" @click="saveEmailPDF()" v-html="'PDF Re-Generate'" />
      </div>
      <div v-else-if="!pdf">
        <p><i>WO in preview mode pending "Finished" status.</i></p>
      </div>
    </div>
  </div>
  <br />
  <br />
  <br />
  <br />
  <waiting-spinner :wait="wait" />
</div>
