
<div class="item-note-container">
  <div v-if="spectrumSingleNote || singleNote" class="single-note">
    <label>{{ label }}</label>
    <a class="note-edit-toggle" v-if="!editingItem && !readOnly" @click="editItem(true, existingText)">
      <i class="ti-pencil" style="padding-left:5px" />
    </a>
    <div :class="['detail-item--edit']" v-if="editingItem">
      <div class="edit-container">
        <wysiwyg v-model="editNoteText" v-click-outside="editNoteChange" />
        <div class="editify-controls">
          <button
            v-if="editNoteText !== existingText"
            class="edit-save"
            @click="
              newNoteText = editNoteText
              addNote()
              editingItem = false
            "
          >
            <i class="ti-check" />
          </button>
          <button class="edit-cancel" @click="cancelEdit">
            <i class="ti-na" />
          </button>
        </div>
        <p class="paste-note">
          {{ pasteNote }}
        </p>
      </div>
    </div>
    <div v-else>
      <div class="note-text-display" v-html="existingText || '<span data-empty>(Empty)</span>'" />
    </div>
  </div>
  <!-- end of single note -->
  <div v-else class="multi-note">
    <!-- else is multi note -->
    <label>{{ label }}</label>
    <br />
    <span v-if="!allNotes || !allNotes.length" data-empty>
      (Empty)
      <br /><br />
    </span>
    <div v-if="allNotes" class="note-container">
      <ul :class="['existing-notes', 'show-scrollbar', { 'read-only': readOnly || !isEditor }]" :style="{ 'max-height': maxHeight }">
        <li v-for="(note, id) in allNotes" :key="id">
          <div v-if="editingItem == note.id">
            <div class="edit-container">
              <label>Edit Note</label>
              <div class="form-group">
                <date-picker v-model="editNoteDate" :pre-filled="false" :disabled="false" />
              </div>
              <wysiwyg v-model="editNoteText" v-click-outside="editNoteChange" />
              <div class="editify-controls">
                <span class="edit-save" v-if="existingText !== editNoteText || editNoteDate" @click="updateNote(note.id)" title="Save edit">
                  <i class="ti-check" />
                </span>
                <span class="edit-cancel" @click="cancelEdit" title="Cancel editing">
                  <i class="ti-na" />
                </span>
                <span v-if="isPM" class="edit-delete" @click="deleteNote(note.id)" title="Double click to delete">
                  <i class="ti-close" />
                </span>
              </div>
              <p class="paste-note paste-note-multi">
                {{ pasteNote }}
              </p>
            </div>
          </div>
          <div v-else>
            <p class="note-meta">
              <span class="note-date" v-if="note.nice_date" v-html="note.nice_date" /> -
              <span class="note-author" v-if="note.author_name" v-html="note.author_name" />
              <span class="note-author" v-if="note.extra_number" v-html="' - ' + note.extra_number" />
              <span v-if="canEditNote(note) && editingItem != note.id" class="note-edit-button" @click="editItem(note.id, note.note_text, note.nice_date)">
                <i class="ti-pencil" />
              </span>
            </p>
            <p class="note-text" v-html="note.note_text" />
          </div>
        </li>
      </ul>
    </div>

    <div v-if="!readOnly && isEditor && !usePropText" class="add-note">
      <label> New Note </label>

      <div class="form-group">
        <date-picker v-model="noteDate" :pre-filled="true" :disabled="false" />
      </div>

      <wysiwyg v-model="newNoteText" v-click-outside="addNoteChange" v-if="!readOnly" />
      <div v-else>
        <textarea v-model="newNoteText" class="form-control" disabled readonly />
      </div>

      <p class="paste-note">Note: Some formatting from pasted text may be filtered on save, click outside edit box to preview any changes.</p>
      <button class="btn" @click="addNote">Add Note</button>
    </div>
  </div>
  <waiting-spinner :wait="wait" :contained="true" />
</div>
