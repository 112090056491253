<template>
  <div>
    <div class="test-section">
      <div class="editor-stack">
        <div class="editor-item">
          <h4>Notes</h4>
          <RichTextInline :content="content" @update:content="content = $event" :readonly="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import RichTextInline from 'components/UIComponents/RichTextInline.vue'

export default {
  name: 'app',
  data() {
    return {
      content: ''
    }
  },

  components: {
    WaitingSpinner,
    RichTextInline
  },

  computed: {
    ...mapGetters([])
  },

  methods: {},

  mounted() {}
}
</script>

<style lang="scss" scoped>
.test-section {
  max-width: 800px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
}

.editor-stack {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.editor-item {
  h4 {
    margin-bottom: 8px;
    font-size: 14px;
    color: #666;
    font-weight: 600;
  }
}

:deep(.rich-text-inline) {
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;

  .formatted-content {
    padding: 12px;
  }
}

.content-preview {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ddd;

  .preview-box {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #f9f9f9;
    min-height: 100px;
  }
}
</style>
