
<div>
  <div class="row">
    <div class="col-sm-12">
      <div :class="['card']">
        <div class="content">
          <bh-table :table-settings="tableSettings" />
        </div>
      </div>
    </div>
  </div>
</div>
