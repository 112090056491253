<template>
  <div>
    <div class="content">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div :class="'detail-item'">
            <label>Employee</label>
            <p>{{ request.employeeName }}</p>
          </div>
          <div :class="'detail-item'">
            <label>First Off</label>
            <p>{{ request.first_off }}</p>
          </div>
          <div :class="'detail-item'">
            <label>Last Off</label>
            <p>{{ request.last_off }}</p>
          </div>
        </div>

        <div class="col-sm-12 col-md-6">
          <div :class="'detail-item'" v-if="isAdmin">
            <label>Status</label>
            <select-field :options="statuses" :label-text="'Status'" :option-display-keys="['name']" :option-val="'name'" v-model="request.status" @input="checkChanges()"/>
          </div>
          <div :class="'detail-item'">
            <label>Reason</label>
            <p>{{ request.reason }}</p>
          </div>
          <div :class="'detail-item'">
            <label>Requested</label>
            <p>{{ request.niceDate }}</p>
          </div>
        </div>
        <div class="col-sm-12" v-if="isAdmin">
          <div :class="'detail-item'">
            <label>Message</label>
            <textarea @input="checkChanges" class="form-control" v-model="request.status_message"/>
          </div>
          <div :class="'detail-item'">
            <checkbox v-model="emailEmployee"> Email update to employee</checkbox>
          </div>
        </div>
      </div>
      <div>
        <button :class="['btn', { pulse: changed }]" @click="updateRequest()" v-if="isAdmin">Update</button>
        <button :class="['btn', { pulse: changed }]" @click="withdrawRequest()" v-if="!isAdmin && request.status != 'Withdrawn' && request.status != 'Disapproved'">Withdraw
          Request
        </button>
      </div>
    </div>

    <waiting-spinner/>
  </div>
</template>
<script>
import store from 'store'
import {mapGetters} from 'vuex'
import appFuncs from 'appFuncs'
import SelectField from 'components/UIComponents/SelectField'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {
      emailEmployee: false,
      request: {
        status: '',
        status_message: ''
      },
      statuses: [{name: 'Open Request'}, {name: 'TBD'}, {name: 'Approved'}, {name: 'Disapproved'}, {name: 'Withdrawn'}],
      loadedData: {},
      changed: false
    }
  },

  components: {
    SelectField,
    WaitingSpinner
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    },
    vacationRequests: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters(['employees']),

    isAdmin() {
      return store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    }
  },

  methods: {
    getEmployeeName(id) {
      if (this.employees && this.employees.length) {
        let obj = this.employees.find(itm => {
          return itm.Employee_Code == id
        })
        if (obj) {
          return obj.Employee_Name
        }
      }
    },
    checkChanges() {
      for (var prop in this.request) {
        if (this.request.hasOwnProperty(prop)) {
          if (this.request[prop] !== this.loadedData[prop]) {
            this.changed = true
          }
        }
      }
    },
    updateRequest() {
      const params = {
        action: 'update_vacation_request',
        id: this.id,
        data: this.request, //sending all will rm most in backend after using non saveable data
        emailEmployee: this.emailEmployee
      }
      this.$bus.$emit('setWaiting', {name: params.action, message: 'Updating request'})
      appFuncs
        .shRequest(params)
        .then((res, data) => {
          this.$snack.open((data && data.message) || 'Request Updated')
        })
        .catch(res => {
          this.$snack.open((res && res.message) || 'Problem updating request', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },
    withdrawRequest() {
      if (confirm('Would you like to withdraw vacation request?')) {
        const params = {
          action: 'withdraw_vacation_request',
          id: this.id,
          data: {
            status: 'Withdrawn'
          }
        }
        this.$bus.$emit('setWaiting', {name: params.action, message: 'Updating request'})
        appFuncs
          .shRequest(params)
          .then((res, data) => {
            this.$snack.open((data && data.message) || 'Request Updated')
            this.$bus.$emit('refreshRequests', data)
          })
          .catch(res => {
            this.$snack.open((res && res.message) || 'Problem updating request', 'error')
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', params.action)
          })
      }
    }
  },

  mounted() {
    this.vacationRequests = this.vacationRequests
    this.request = this.vacationRequests.find(itm => {
      return itm.id === this.id
    })
    this.loadedData = JSON.parse(JSON.stringify(this.request))

    if (!this.id || !this.request || !this.request.id) {
      this.$snack.open('Problem retrieving data, please reload to try again.', 'error')
    }
  },

  watch: {
    request() {
      this.checkChanges()
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-item {
  margin-bottom: 15px;

  h3 {
    font-size: 20px;
    font-weight: bolder;
    margin: 0;
  }
}
</style>
