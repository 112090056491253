<template>
  <div class="tab-section white">
    <div class="max-width-large">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{ active: tab == 'Employees' }]">
          <a href="#" @click="tab = 'Employees'"> Employees </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'Subcontractors' }]">
          <a href="#" @click="tab = 'Subcontractors'"> Subcontractors </a>
        </li>
      </ul>

      <div v-if="tab == 'Employees'" :class="['card', { Main: tab == 'Employees' }]">
        <div class="content">
          <div class="row" style="margin-bottom: 10px">
            <div class="col-sm-6 col-md-3">
              <region-filter @select-region="selectRegion" />
            </div>
          </div>
          <employee-list :region="region" />
        </div>
      </div>
      <div v-if="tab == 'Subcontractors'" :class="['card']">
        <div class="content">
          <employee-subcontractor-list />
        </div>
      </div>
    </div>

    <waiting-spinner />
  </div>
</template>
<script>
import navTabs from 'mixins/navTabs'
import EmployeeList from 'components/Dashboard/Contacts/EmployeeList'
import EmployeeSubcontractorList from 'components/Dashboard/Contacts/EmployeeSubcontractorList'
import RegionFilter from 'components/Dashboard/RegionFilter'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {
      tab: 'Employees',
      region: null
    }
  },
  mixins: [navTabs],
  components: {
    EmployeeList,
    EmployeeSubcontractorList,
    RegionFilter,
    WaitingSpinner
  },
  methods: {
    selectRegion(selected_region) {
      this.region = selected_region
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped></style>
