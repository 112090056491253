<template>
  <div>
    <div class="sh-widget card wide-large">
      <div class="content">

        <div class="top-section">
          <div class="">
            <h4>Top Cashflow</h4>
          </div>
          <checkbox2 :true-val="true" :false-val="false" v-model="showAll" label="Show All">Show All</checkbox2>
        </div>

        <div class="tab-section outline white border-bottom">
          <ul class="nav nav-tabs sub-tabs">
            <li role="presentation" :class="[{ active: tab1 == 'Positive' }]">
              <a href="#" @click="tab1 = 'Positive'">
                Positive
              </a>
            </li>
            <li role="presentation" :class="[{ active: tab1 == 'Negative' }]">
              <a href="#" @click="tab1 = 'Negative'">
                Negative
              </a>
            </li>
          </ul>
        </div>

        <div class="tab-section outline white border-bottom">
          <ul class="nav nav-tabs sub-tabs">
            <li role="presentation" :class="[{ active: tabMain == 'Customers' }]">
              <a href="#" @click="tabMain = 'Customers'">
                Customers
              </a>
            </li>
            <li role="presentation" :class="[{ active: tabMain == 'Jobs' }]">
              <a href="#" @click="tabMain = 'Jobs', getJobData()">
                Jobs
              </a>
            </li>
          </ul>
        </div>

        <div class="table-container show-scrollbar">
          <table>
            <thead>
            <tr>
              <th></th>
              <th v-if="tabMain === 'Jobs'" class="job"><label>Job</label></th>
              <th v-if="tabMain === 'Jobs'" class="desc"><label>Description</label></th>
              <th><label>Customer</label></th>
              <th><label>Cash In</label></th>
              <th><label>Cash Out</label></th>
              <th><label>Net Cash Flow</label></th>
            </tr>
            </thead>
            <tr v-for="(d, i) in tableData" :key="i">
              <td>{{ d.number }}</td>
              <td v-if="tabMain === 'Jobs'" class="job">{{ d.Job_Number }}</td>
              <td v-if="tabMain === 'Jobs'" class="desc">{{ d.Job_Description }}</td>
              <td>{{ d.Customer_Name }}</td>
              <td>{{ formatNumber(d.Cash_In, 1, true) }}</td>
              <td>{{ formatNumber(d.Cash_Out, 1, true) }}</td>
              <td>{{ formatNumber(d.Net_Cash_Flow, 1, true) }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <waiting-spinner :wait="wait" :contained="true"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import appFuncs from 'appFuncs';
import Checkbox2 from 'components/UIComponents/Checkbox';
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'CustomerTopCashFlow',
  data() {
    return {
      wait: {},
      data: [],
      jobData: [],
      showAll: false,
      tabMain: 'Customers',
      tab1: 'Positive'
    };
  },

  props: {},

  components: {
    Checkbox2,
    WaitingSpinner
  },

  computed: {
    ...mapGetters([
      'userAuthedData',
      'customers'
    ]),

    tableData() {
      return this.tabMain === 'Customers' ? this.topCustomerNegativeCashflow : this.topJobsWithNegativeCashFlow;
    },

    topCustomerNegativeCashflow() {
      const data = [...this.data];
      const customers = [...this.customers] || [];

      // Group by Customer_Code and sum Cash_In, Cash_Out, Net_Cash_Flow
      const grouped = data
        .filter(job => job.Customer_Code !== 'SE')
        .reduce((acc, cur) => {
          const key = cur.Customer_Code;
          if (!acc[key]) {
            acc[key] = {
              Customer_Code: cur.Customer_Code,
              Cash_In: 0,
              Cash_Out: 0,
              Net_Cash_Flow: 0
            };
          }
          acc[key].Cash_In += cur.Cash_In;
          acc[key].Cash_Out += cur.Cash_Out;
          acc[key].Net_Cash_Flow += cur.Net_Cash_Flow;
          return acc;
        }, {});

      // Convert object to array
      let groupedArray = Object.values(grouped);

      // Sort by Net_Cash_Flow asc (most negative at the top)
      if (this.tab1 === 'Positive') {
        groupedArray.sort((a, b) => b.Net_Cash_Flow - a.Net_Cash_Flow);
      } else {
        groupedArray.sort((a, b) => a.Net_Cash_Flow - b.Net_Cash_Flow);
      }

      // Add Customer_Name from this.customers
      groupedArray = groupedArray.map(item => {
        let customerName = item.Customer_Code; // Default to Customer_Code if no match is found
        if (customers.length) {
          const customer = customers.find(c => c && c.Customer_Code === item.Customer_Code);
          if (customer) {
            customerName = customer.Name || customer.Customer_Code;
          }
        }
        return {
          ...item,
          Customer_Name: customerName
        };
      });

      // Calculate grand total
      const grandTotal = groupedArray.reduce((acc, cur) => {
        acc.Cash_In += cur.Cash_In;
        acc.Cash_Out += cur.Cash_Out;
        acc.Net_Cash_Flow += cur.Net_Cash_Flow;
        return acc;
      }, {Cash_In: 0, Cash_Out: 0, Net_Cash_Flow: 0, Customer_Name: 'Grand Total'});

      // Add number property for ranking
      groupedArray = groupedArray.map((item, index) => {
        if (item.Customer_Name !== 'Others' && item.Customer_Name !== 'Grand Total') {
          return {
            ...item,
            number: index + 1
          };
        }
        return item;
      });

      // If this.showAll is false, calculate "Others" total and add to the array
      if (!this.showAll) {
        const top10 = groupedArray.slice(0, 10);
        const others = groupedArray.slice(10).reduce((acc, cur) => {
          acc.Cash_In += cur.Cash_In;
          acc.Cash_Out += cur.Cash_Out;
          acc.Net_Cash_Flow += cur.Net_Cash_Flow;
          return acc;
        }, {Cash_In: 0, Cash_Out: 0, Net_Cash_Flow: 0, Customer_Name: 'Others'});

        // groupedArray = [...top10, others, grandTotal];
        groupedArray = [...top10, grandTotal];
      } else {
        groupedArray.push(grandTotal);
      }

      return groupedArray;
    },

    topJobsWithNegativeCashFlow() {
      const jobData = [...this.jobData];
      const data = [...this.data];
      const customers = [...this.customers] || [];

      // Group by Job_Number and sum Cash_In, Cash_Out, Net_Cash_Flow
      const groupedCashFlow = data.reduce((acc, cur) => {
        const key = cur.Job_Number;
        if (!acc[key]) {
          acc[key] = {
            Cash_In: 0,
            Cash_Out: 0,
            Net_Cash_Flow: 0
          };
        }
        acc[key].Cash_In += parseFloat(cur.Cash_In || "0.00");
        acc[key].Cash_Out += parseFloat(cur.Cash_Out || "0.00");
        acc[key].Net_Cash_Flow += parseFloat(cur.Net_Cash_Flow || "0.00");
        return acc;
      }, {});

      let results = jobData
        .filter(job => job.Customer_Code !== 'SE')
        .map(job => {
          // Get the summed cash flow data for the job
          const cashFlowData = groupedCashFlow[job.Job_Number] || {Cash_In: 0, Cash_Out: 0, Net_Cash_Flow: 0};

          // Find the corresponding customer name
          let customerName = job.Customer_Code;
          const customer = customers.find(c => c && c.Customer_Code === job.Customer_Code);
          if (customer) {
            customerName = customer.Name || customer.Customer_Code;
          }

          return {
            ...job,
            Customer_Name: customerName,
            Cash_In: cashFlowData.Cash_In.toFixed(2),
            Cash_Out: cashFlowData.Cash_Out.toFixed(2),
            Net_Cash_Flow: cashFlowData.Net_Cash_Flow.toFixed(2)
          };
        })
        .filter(item => parseFloat(item.Net_Cash_Flow) !== 0) // Remove rows with Net_Cash_Flow of 0

      if (this.tab1 === 'Negative') {
        results = results.sort((a, b) => a.Net_Cash_Flow - b.Net_Cash_Flow); // Sort by Net_Cash_Flow ASC
      } else {
        results = results.sort((a, b) => b.Net_Cash_Flow - a.Net_Cash_Flow); // Sort by Net_Cash_Flow DESC
      }

      if (!this.showAll) {
        const top10 = results.slice(0, 10);
        const others = results.slice(10).reduce((acc, cur) => {
          acc.Cash_In += parseFloat(cur.Cash_In);
          acc.Cash_Out += parseFloat(cur.Cash_Out);
          acc.Net_Cash_Flow += parseFloat(cur.Net_Cash_Flow);
          return acc;
        }, {Cash_In: 0, Cash_Out: 0, Net_Cash_Flow: 0, Job_Number: 'Other', Customer_Name: 'Other'});

        // results = [...top10, others];
        results = [...top10];
      }

      // Calculate grand total
      const grandTotal = results.reduce((acc, cur) => {
        acc.Cash_In += parseFloat(cur.Cash_In);
        acc.Cash_Out += parseFloat(cur.Cash_Out);
        acc.Net_Cash_Flow += parseFloat(cur.Net_Cash_Flow);
        return acc;
      }, {Cash_In: 0, Cash_Out: 0, Net_Cash_Flow: 0, Job_Number: 'Total', Customer_Name: 'Total'});

      results.push(grandTotal);

      results = results.map((item, index) => {
        if (item.Customer_Name !== 'Other' && item.Customer_Name !== 'Total') {
          return {
            ...item,
            number: index + 1
          };
        }
        return item;
      });

      return results;
    }
  },

  methods: {
    getData() {
      return new Promise((resolve, reject) => {
        this.wait = {name: 'get_accounting_cashflow', message: 'Getting data'};
        appFuncs.shRequest({action: 'get_accounting_cashflow'}).then(res => {
          this.data = res || [];
          resolve();
        }).catch((res) => {
          this.$snack.open(res.message || 'Problem fetching data', 'warning');
          console.log(res);
          reject();
        }).finally(() => {
          this.wait = {};
        });
      });
    },

    getJobData() {
      return new Promise((resolve, reject) => {
        this.wait = {name: 'get_all_job_summary_details', message: 'Getting job data'};
        appFuncs.shRequest({action: 'get_all_job_summary_details', not_status: 'C'}).then(res => {
          this.jobData = res || [];
          resolve();
        }).catch((res) => {
          this.$snack.open(res.message || 'Problem fetching job data', 'warning');
          console.log(res);
          reject();
        }).finally(() => {
          this.wait = {};
        });
      });
    }
  },

  mounted() {
    this.getData();
  },

  beforeDestroy() {
  },

};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/paper/_variables.scss";

.sh-widget {
  th:nth-child(1), td:nth-child(1) {
    width: 30px;
  }

  th.job, td.job {
    width: 70px;
  }

  th.desc, td.desc {
    // width: 70px;
  }
}


</style>
