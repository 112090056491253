<template>
  <div>
    <waiting-spinner />

    <div v-if="missingWoRequirements.length" class="row" style="margin-bottom: 10px">
      <div class="col-sm-12">
        <div>
          <p v-if="missingWoRequirements.length" class="text-danger">
            <i
              ><strong><span style="text-decoration: underline">RED</span> requirements must be inputted before continuing.</strong></i
            >
          </p>
          <p v-else-if="wo.Dispatch_Status_Code.toString() !== '7' && wo.Dispatch_Status_Code !== 'F'" class="text-danger"><i>WO must be marked as finished prior to sign off.</i></p>
          <p v-else><i>All requirements have been met.</i></p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="content">
            <label><strong>Service Report Options</strong></label>
            <br />
            <div class="form-group">
              <span class="inline-checkbox">
                <checkbox2 v-model="signOff.isQuote" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                  <span>Quote</span>
                </checkbox2>
              </span>
              <span class="inline-checkbox">
                <checkbox2 v-model="signOff.hideLaborDetails" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                  <span>Hide Time Log Notes</span>
                </checkbox2>
              </span>
            </div>
          </div>
        </div>

        <div class="card border">
          <div class="content">
            <div class="form-group">
              <label><strong>Required Doc &amp; Photo Categories</strong></label>
              <br />
              <span class="inline-checkbox">
                <checkbox2 v-model="files.preWorkPhotos" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                  <span :class="{ 'text-danger': !checkMetWoRequirement('preWorkPhotos') }">Pre-Work Photos</span>
                </checkbox2>
              </span>
              <span class="inline-checkbox">
                <checkbox2 v-model="files.postWorkPhotos" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                  <span :class="{ 'text-danger': !checkMetWoRequirement('postWorkPhotos') }">Post Work Photos</span>
                </checkbox2>
              </span>
              <div>
                <button :class="['btn', 'btn-sm', { pulse: !checkMetWoRequirement(['postWorkPhotos', 'postWorkPhotos']) }]" @click="showFiles()" v-html="'Add Photos'" />
              </div>
            </div>
          </div>
        </div>

        <div class="card border">
          <div class="content">
            <div class="form-group">
              <label><strong>Required Note Categories</strong></label>
              <br />
              <span class="inline-checkbox">
                <checkbox2 v-model="notes.wo_work_ordered_notes" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                  <span :class="{ 'text-danger': !checkMetWoRequirement('wo_work_ordered_notes') }">Scope of Work Notes</span>
                </checkbox2>
              </span>
              <!-- <span class="inline-checkbox">
                <checkbox2 v-model="notes.wo_work_order_notes" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                  <span :class="{'text-danger':!checkMetWoRequirement('wo_work_order_notes')}">Scope Notes</span>
                </checkbox2>
              </span> -->
              <span class="inline-checkbox">
                <checkbox2 v-model="notes.wo_internal_notes" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                  <span :class="{ 'text-danger': !checkMetWoRequirement('wo_internal_notes') }">Internal Notes</span>
                </checkbox2>
              </span>
              <span class="inline-checkbox">
                <checkbox2 v-model="notes.wo_extras_notes" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                  <span :class="{ 'text-danger': !checkMetWoRequirement('wo_extras_notes') }">Work Order Extras</span>
                </checkbox2>
              </span>
              <span class="inline-checkbox">
                <checkbox2 v-model="notes.wo_completed_notes" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                  <span :class="{ 'text-danger': !checkMetWoRequirement('wo_completed_notes') }">Work Completed</span>
                </checkbox2>
              </span>
              <span class="inline-checkbox">
                <checkbox2 v-model="notes.wo_recommendation_notes" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                  <span :class="{ 'text-danger': !checkMetWoRequirement('wo_recommendation_notes') }">Recommendations</span>
                </checkbox2>
              </span>
            </div>
          </div>
        </div>
        <div class="card border">
          <div class="content">
            <div class="form-group">
              <label><strong>Other</strong></label>
              <br />
              <span class="inline-checkbox">
                <checkbox2 v-model="other.require_detailed_notes" true-value="true" :disabled="!isPM" @change="compareLoadedOptions">
                  <span>Require Detailed Notes</span>
                </checkbox2>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button v-if="isPM" :class="['btn', { pulse: optionsUpdated }, { 'btn-primary': optionsUpdated }]" @click="save()" v-html="'Update'" />

    <button v-if="woSignOffTemplateLink && !inSequence" :class="['btn', { disabled: optionsUpdated }]" @click="checkFinishSignOff()" v-html="'Capture Signature'" />

    <button v-if="woSignOffTemplateLink && !inSequence" :class="['btn']" @click="openWOSignOffTemplateLink()" v-html="'Preview'" />

    <!-- <button
      v-if="!inSequence && woSignOffTemplateLink"
      :class="['btn', { disabled: missingWoRequirements.length || ((wo.Dispatch_Status_Code !== '7' && wo.Dispatch_Status_Code !== 'F') && !justMarkedFinished) }]"
      @click="openWOSignOffTemplateLink"
    >
      Signature
    </button>

    <button
      v-if="!inSequence && (wo.Dispatch_Status_Code !== '7' && wo.Dispatch_Status_Code !== 'F') && (isPM || isWOPM || isWOTech)"
      :class="['btn', {disabled: missingWoRequirements.length}]"
      @click="finishWO"
    >
      Mark WO as Finished
    </button> -->
  </div>
</template>
<script>
import store from 'store'
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import WoFiles from 'components/Dashboard/JobWo/WOFiles'
import WOFuncs from 'mixins/WOFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import Checkbox2 from 'components/UIComponents/Checkbox'

export default {
  data() {
    return {
      options: {},
      files: {
        preWorkPhotos: 'false',
        postWorkPhotos: 'true'
      },
      notes: {
        wo_recommendation_notes: 'false',
        wo_completed_notes: 'true',
        wo_work_ordered_notes: 'true',
        wo_extras_notes: 'false',
        wo_work_order_notes: 'false',
        wo_internal_notes: 'false'
      },
      signOff: {},
      wo: {},
      justMarkedFinished: false,
      optionsUpdated: false,
      loadedOptions: '',
      other: {
        require_detailed_notes: 'false'
      }
    }
  },

  props: {
    woid: {
      type: String,
      required: true
    },

    inSequence: {
      type: Boolean,
      required: false
    },

    woSignOffTemplateLink: {
      type: String,
      default: ''
    }
  },

  mixins: [WOFuncs],

  components: { Checkbox2, WaitingSpinner },

  computed: {
    userID() {
      return store.getters.userAuthedData.eid
    },

    isPM() {
      return store.getters.userAuthedData.user_role.indexOf('pm') !== -1
    },

    isAdmin() {
      return store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isWOPM() {
      if (this.userID == this.wo.PM) {
        return true
      }
      return false
    },

    isWOTech() {
      if (this.userID == this.wo.tech_main) {
        return true
      }

      if (this.wo.techs && this.wo.techs.length && this.wo.techs.indexOf(this.userID) !== -1) {
        return true
      }

      return false
    },

    urls() {
      return store.getters.urls
    },

    hasWoHours() {
      return ((this.woRequirementItems.allTimeCards || false).data || false).length ? true : false
    }
  },

  methods: {
    save(lock) {
      if (!this.isPM) {
        return
      }

      if (!this.woid) {
        this.$snack.open('Problem saving options please refresh and try again.', 'error')
      }

      let admin_options = {
        files: this.files,
        notes: this.notes,
        signOff: this.signOff,
        updated: new Date().getTime(),
        other: this.other
      }

      for (var prop in admin_options) {
        if (admin_options.hasOwnProperty(prop)) {
          for (var yo in admin_options[prop]) {
            if (admin_options[prop].hasOwnProperty(yo)) {
              if (!admin_options[prop][yo]) {
                delete admin_options[prop][yo]
              }
            }
          }
        }
      }

      let params = {
        action: 'upsert_wos_app_data',
        id: this.woid,
        data: {
          admin_options
        }
      }

      if (lock) {
        params.data.locked = true
      }

      this.$bus.$emit('setWaiting', { name: 'upsert_wos_app_data', message: 'Updating WO Admin Options' })
      appFuncs.ajax_request(this.$store.getters.sherAuth, params, res => {
        this.$bus.$emit('stopWaiting', 'upsert_wos_app_data')
        if (res.status === 'success') {
          this.$store.dispatch('getWoRequirementItems', { woid: this.woid, refresh: 1 }).then(() => {
            if (this.woOptions) {
              this.files = this.woOptions.files || this.files
              this.notes = this.woOptions.notes || this.notes
              this.signOff = this.woOptions.signOff || {}
              setTimeout(() => {
                this.setLoadedOptions()
              }, 500)
            }
          })

          // this.$bus.$emit('modalClose', 'wo-admin-options');
          this.$router.push({ name: 'Work Order', params: { id: this.woid } })
          this.$snack.open('Options updated')
          if (lock) {
            this.$bus.$emit('updateWOAssemble', 'refresh')
          }
        } else {
          this.$snack.open(res.message, 'error')
        }
      })
    },

    openWOSignOffTemplateLink() {
      /*

        - used directly for preview, signoff template only allows signoff if WO Finished
        - WO signoff access link is not protected, if capture signature needs to only be avail to admin, pm or wotech, preview needs to be the same otherwise preview will act the same as the capture signature link when WO is Finished

        */

      if (this.woSignOffTemplateLink) {
        window.open(this.woSignOffTemplateLink)
        this.$bus.$emit('modalClose', 'wo-admin-options')
      } else {
        this.$snack.open('Problem with getting template link, please make sure WO is finished and try again', 'error')
      }
    },

    finishWO(callback) {
      // callback should only get called if success

      if (this.hasMissingWoRequirements) {
        alert('All WO requirements must be met before WO can be marked with Finished Dispatch Status')
        return
      }

      if (!this.hasWoHours) {
        if (!confirm('You are attempting to "Finish" a WO with no hours added. Do you wish to proceed?')) {
          return
        }
      }

      const params = {
        action: 'wo_status_update',
        wo_number: this.woid,
        dispatch_status_code: '7', // value is for Finished
        priority_code: this.wo.Priority_Code == 10 ? 10 : 3 // if 10:Report sent leave it, else change to 3:Needs Signature
      }

      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Updating Work Order'
      })

      appFuncs
        .shRequest(params)
        .then(() => {
          // may not be necessary anymore
          this.justMarkedFinished = true

          if (typeof callback === 'function') {
            callback()
          }

          // trigger finished notification
          appFuncs.sendWONnotifications(this.woid)

          this.$snack.open('Work Order successfully updated')
          // triggers woAddEdit data fetch which syncs this data through prop
          this.$bus.$emit('refreshWODetailsBase', 'refresh')

          if (this.enableBillingTasks) {
            // this.showCreateBillingTask()
          }
        })
        .catch(err => {
          console.error('Error updating work order status:', err)
          this.$snack.open('Error updating work order status, please try again later', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    showFiles() {
      this.$Modal({
        parent: this,
        name: 'wo-files', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        title: 'Add WO Photos',
        component: WoFiles,
        props: {
          woid: this.woid,
          showTabs: ['Pre-Work', 'Post-Work'],
          stayPilled: true,
          isOutlined: false
        }
      })
      // this.$bus.$emit('modalClose', 'wo-admin-options');
    },

    checkFinishSignOff() {
      if (this.hasMissingWoRequirements) {
        alert('All WO requirements must be met before continuing')
        return
      }

      if (confirm('This will mark the WO as Finished and lock further changes, proceed?"')) {
        this.finishWO(() => {
          this.openWOSignOffTemplateLink()
        })
      }
    },

    getOptionsStr() {
      let objs = [this.notes, this.files, this.signOff]
      let options = []
      for (var i = 0; i < objs.length; i++) {
        let itm = objs[i]
        for (var prop in itm) {
          if (itm.hasOwnProperty(prop)) {
            if (itm[prop] === 'true' || itm[prop] === true) {
              options.push(prop)
            }
          }
        }
      }
      return JSON.stringify(options)
    },

    setLoadedOptions() {
      this.loadedOptions = this.getOptionsStr()
      setTimeout(() => {
        this.compareLoadedOptions()
      }, 200)
    },

    compareLoadedOptions() {
      let options = this.getOptionsStr()
      if (this.loadedOptions !== options) {
        this.optionsUpdated = true
      } else {
        this.optionsUpdated = false
      }
    },

    loadData(refresh) {
      return new Promise(async resolve => {
        if (!this.woid) {
          resolve()
          return
        }

        this.$bus.$emit('setWaiting', { name: 'getWOWip', message: 'Getting WO Data' })

        const params = {
          action: 'assemble_wip_wo_data',
          woNumber: this.woid
        }

        appFuncs
          .shRequest(params)
          .then(data => {
            this.wo = data[0]
          })
          .catch(err => {
            this.$snack.open('Problem fetching WO data, please close and try again', 'error')
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'getWOWip')
          })
        resolve()
      })
    },

    showCreateBillingTask() {
      this.$Modal({
        parent: this,
        name: 'BillingTaskAdd',
        size: 'md',
        title: `Add Billing Task`,
        component: () => import('src/components/Dashboard/Billing/BillingTaskAdd.vue'),
        props: {
          WO_Number: this.woid,
          setTaskType: 'BC',
          setAssignedTo: null
        }
      })
    }
  },

  mounted() {
    this.loadData()

    this.$store.dispatch('getWoRequirementItems', { woid: this.woid }).then(() => {
      if (this.woOptions) {
        this.files = this.woOptions.files || this.files
        this.notes = this.woOptions.notes || this.notes
        this.signOff = this.woOptions.signOff || {}
        this.other = this.woOptions.other || {}
        setTimeout(() => {
          this.setLoadedOptions()
        }, 500)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.btn {
  // min-width:90px;
}

.inline-checkbox {
  margin-right: 20px;
}

.card {
  &.border {
    // padding:20px 20px 0px;
    // border:1px solid $light-gray;
    // border-radius:5px;
  }
}
</style>
