
<div>
  <div class="content">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label for="ar_send_month_day">Auto Send Month Day</label>
          <p class="sub-label">
            Number from 1-29
          </p>
          <input type="number" class="form-control" name="ar_send_month_day" v-model="appOptions.ar_send_month_day" rows="5">
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <span class="inline-checkbox">
            <checkbox v-model="appOptions.ar_statement_enable_auto_send">
              Enable Statement Auto Send
            </checkbox>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label for="ar_statement_default_text">Statement Default Text</label>
          <textarea
            class="form-control"
            name="ar_statement_default_text"
            rows="5"
            :value="decode(appOptions.ar_statement_default_text)"
            @input="appOptions.ar_statement_default_text = encode($event.target.value, 'stripTags')"
          />
        </div>
        <div class="form-group">
          <label for="ar_statement_email_text">Statement Email Text</label>
          <textarea
            class="form-control"
            name="ar_statement_email_text"
            rows="5"
            :value="decode(appOptions.ar_statement_email_text)"
            @input="appOptions.ar_statement_email_text = encode($event.target.value, 'stripTags')"
          />
        </div>
        <input type="submit" class="btn" @click="update()" value="Update">
      </div>
    </div>
    <waiting-spinner/>
  </div>
</div>
