<template>
  <div :class="sidebarClasses" :data-background-color="backgroundColor">
    <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <div class="sidebar-wrapper" id="style-3">
      <div class="logo">
        <a href="#" class="sidebar-logo">
          <img :src="urls.frontend + 'static/img/logo-inverted.png'" />
        </a>
      </div>
      <slot />
      <ul :class="navClasses">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <li v-for="(link, index) in sidebarLinks" @click="showChildren(link)" :class="{ 'child-active': dropdownActive === link.name }">
          <router-link v-if="!testExternalLink(link.path) && !link.children && testPermission(link.role)" :class="'sb-link'" :to="link.path" :ref="link.name">
            <p>
              <i :class="link.icon" />
              {{ link.name }}
            </p>
          </router-link>

          <a v-else-if="!link.children && testPermission(link.role)" :href="link.path" :class="'sb-link'" :target="'_blank'">
            <p>
              <i :class="link.icon" />
              {{ link.name }}
            </p>
          </a>

          <span v-else-if="testPermission(link.role)" :class="'sb-link'">
            <p>
              <i :class="link.icon" />
              {{ link.name }}
            </p>
          </span>

          <ul v-if="dropdownActive === link.name">
            <li v-for="child in link.children" v-if="testPermission(child.role)">
              <router-link v-if="!testExternalLink(child.path)" :to="child.path" :ref="child.name">
                {{ child.name }}
              </router-link>
              <a v-else :href="child.path" :target="'_blank'">{{ child.name }}</a>
            </li>
          </ul>
        </li>

        <li>
          <a :href="'#'" :class="'sb-link'" @click="openFeedback">
            <p>
              <i :class="'ti-flag-alt'" />
              Feedback / Errors
            </p>
          </a>
        </li>

        <li class="logout-link">
          <a href="#" @click="logout()">Log Out</a>
        </li>
      </ul>
      <!-- <moving-arrow :move-y="arrowMovePx">

      </moving-arrow> -->
    </div>

    <!-- <waiting-spinner /> -->
  </div>
</template>
<script>
import MovingArrow from './MovingArrow.vue'

import store from 'store'
import $ from 'jquery'

export default {
  data: function () {
    return {}
  },

  props: {
    type: {
      type: String,
      default: 'sidebar',
      validator: value => {
        let acceptedValues = ['sidebar', 'navbar']
        return acceptedValues.indexOf(value) !== -1
      }
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: value => {
        let acceptedValues = ['white', 'black', 'darkblue']
        return acceptedValues.indexOf(value) !== -1
      }
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: value => {
        let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
        return acceptedValues.indexOf(value) !== -1
      }
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    }
  },
  components: {
    MovingArrow
  },
  computed: {
    sidebarClasses() {
      if (this.type === 'sidebar') {
        return 'sidebar'
      } else {
        return 'collapse navbar-collapse off-canvas-sidebar'
      }
    },
    navClasses() {
      if (this.type === 'sidebar') {
        return 'nav'
      } else {
        return 'nav navbar-nav'
      }
    },
    /**
     * Styles to animate the arrow near the current active sidebar link
     * @returns {{transform: string}}
     */
    arrowMovePx() {
      //return this.linkHeight * this.activeLinkIndex + 8
      return this.activeLinkHeight
    },

    urls() {
      return store.getters.urls
    },

    employeeRole() {
      return store.getters.userAuthedData.employee_role
    },

    userRoles() {
      return store.getters.userAuthedData.user_role
    }
  },
  data() {
    return {
      linkHeight: 60,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false,
      dropdownActive: '',
      activeLinkHeight: 0
    }
  },
  methods: {
    findActiveLink() {
      var activeEl = $('.router-link-exact-active')
      if (activeEl.length) {
        var height = activeEl.offset().top
        var navHeight = $('ul.nav').offset().top
        var linkHeight = activeEl.outerHeight()

        var navPos = height - navHeight - 2
        this.activeLinkHeight = navPos
      }

      // this.sidebarLinks.find((element, index) => {
      //   let found = element.path === this.$route.path
      //   if (found) {
      //     this.activeLinkIndex = index;

      //     // find the live height of the link
      //     // find element, get js top position relative to container

      //     console.log(element);

      //     this.activeLinkHeight = '';

      //   }
      //   return found
      // });
    },

    testPermission(role) {
      if (!role || !this.userRoles) {
        return true
      }

      const isAdmin = this.userRoles.indexOf('admin') !== -1

      if (isAdmin) {
        return true
      }

      // Check if role is an array
      if (Array.isArray(role)) {
        for (let r of role) {
          const allRoles = this.userRoles.concat(this.employeeRole)
          if (allRoles.indexOf(r) === -1) {
            return false
          }
        }
        return true
      }

      // Existing functionality for single role
      if (this.userRoles.indexOf(role) === -1) {
        return false
      }

      return true
    },

    testExternalLink(link) {
      if (link && link.indexOf('http') !== -1) {
        return true
      }
    },

    showChildren(itm) {
      if (itm.children) {
        this.dropdownActive = itm.name
      } else {
        this.dropdownActive = ''
      }
    },

    logout() {
      localStorage.clear()
      store.dispatch('clearLogin')
      this.$router.push({ path: '/login' })
      this.$sidebar.showSidebar = ''
    },

    openFeedback() {
      this.$bus.$emit('feedbackOpen')
    }
  },

  mounted() {
    var self = this
    this.findActiveLink()

    $('.sb-link').on('click', function () {
      setTimeout(function () {
        self.findActiveLink()
      }, 100)
    })
  },

  watch: {
    $route: function (newRoute, oldRoute) {
      var self = this
      setTimeout(function () {
        self.findActiveLink()
      }, 300)
    }
  }
}
</script>
<style>
.sidebar .loading-spinner {
  display: none;
}

.sidebar-logo {
  width: 100%;
}

.sidebar-logo img {
  width: 100%;
}

.off-canvas-sidebar .logout-link {
  padding: 10px 0px 10px 25px;
}
</style>
