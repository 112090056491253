<template>
  <div>
    <simple-header />
    <div class="materials-admin material-lists-component">
      <div class="row">
        <div class="col-sm-12 col-md-9">
          <material-category-train ref="sourceDiv" class="material-category-train" :category-current="categoryCurrent" @materialSelectCategory="selectCategory($event)" />
        </div>

        <div class="col-sm-12 col-md-3" v-if="1 == 2">
          <span class="inline-checkbox" style="float: right">
            <checkbox2 v-model="showOneTimeMaterials" @change="getMaterials()"> Show One-Time Materials</checkbox2>
          </span>
        </div>

        <div class="col-sm-12">
          <div class="category-table-container">
            <materials-category-table :ref="'matCatTable'" @selectCategory=";(categoryCurrent = $event), getMaterials()" :edit-categories="true" label="Subcategories" />

            <div class="info-section visible-lg">
              <h4>Table Actions:</h4>
              <ul>
                <!-- <li><i class="fa fa-search" />Search for Material</li> -->
                <!-- <li><i class="fa fa-thumbtack" />Pin materials to a list</li> -->
                <li><i class="fa fa-pen" v-if="isMaterialsAdmin" />Row edit mode</li>
                <li><i class="fa fa-check-square" />Row select mode</li>
                <li><i class="fa fa-ban" />Stop edit or row select mode</li>
                <li v-if="isMaterialsAdmin"><i class="fa fa-exchange-alt" />Change material category</li>
                <!-- <li><i class="fa fa-trash" />Delete selected materials</li> -->
              </ul>

              <h4>Material Actions:</h4>
              <ul>
                <li><i class="icon-dollar-file-add" />Add price entry to material</li>
                <li><i class="icon-dollar-files-show" />Show material price entries</li>
                <li v-if="isMaterialsAdmin"><i class="ti ti-pencil" />Show all material edit fields</li>
              </ul>
            </div>

            <div>
              <button class="btn" @click="addMaterial()" v-html="'Add Material'" v-if="isMaterialsAdmin" />

              <button class="btn" @click="showMaterialImport" v-html="'Import Materials'" v-if="isMaterialsAdmin" />
              <!-- <button
                class="btn"
                @click="triggerExportCSV"
                v-html="'Accubid Export'"
              /> -->
            </div>
          </div>

          <div class="material-table">
            <div ref="targetDiv" class="sorting-buttons">
              <button :class="['btn', { active: sorting === 'Default' }]" @click="sorting = 'Default'" v-html="'Default'" />
              <button :class="['btn', { active: sorting === 'Popular' }]" @click="sorting = 'Popular'" v-html="'Popular'" />
            </div>
            <div class="material-table-inner">
              <div class="row">
                <div class="col-sm-12 col-md-8">
                  <div class="material-search-container">
                    <material-search overlay @selectMaterial="selectMaterial" />
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="material-table-options">
                    <div class="edit-mode-container">
                      <!-- <span class="is-link" @click="showMaterialSearch()" title="Material Search">
                        <i class="fa fa-search" />
                      </span> -->
                      <span v-if="selectingMaterials">
                        <span class="is-link" @click="selectingMaterials = false" title="Cancel Select Materials">
                          <i class="fa fa-ban" />
                        </span>
                        <span class="is-link" @click="moveMaterials()" title="Move Materials" v-if="isMaterialsAdmin">
                          <i class="fa fa-exchange-alt" />
                        </span>
                        <!-- <span class="is-link" @click="deleteMaterials()" title="Delete Materials">
                          <i class="fa fa-trash" />
                        </span> -->
                      </span>
                      <span v-else-if="isDragging">
                        <span class="is-link" @click="isDragging = false" title="Cancel Drag and Drop">
                          <i class="fa fa-ban" />
                        </span>
                        <span class="is-link save-dragging" @click="saveDragging" title="Save">
                          <i class="ti-save-alt" />
                        </span>
                      </span>
                      <span v-else>
                        <span class="is-link" v-if="isMaterialsAdmin && !isDragging && sorting === 'Default'" @click="updateDragging(true)" title="Drag and Drop">
                          <i class="ti-move" />
                        </span>
                        <span class="is-link" v-if="!inlineEditable && isMaterialsAdmin" @click="updateInlineEditing(true)" title="Enable Inline Editing">
                          <i class="fa fa-pen" />
                        </span>
                        <span class="is-link" v-else-if="isMaterialsAdmin" @click="updateInlineEditing(false)" title="Disable Inline Editing">
                          <i class="fa fa-ban" />
                        </span>
                        <span class="is-link" @click="selectingMaterials = true" title="Select Multiple Materials">
                          <i class="fa fa-check-square" />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <tabulator-table
                ref="materialTable"
                :table-data="currentMaterials"
                :table-columns="materialTableColumns"
                @updateCol="updateMaterialCol"
                :allow-new-row="false"
                :inline-edit-row-validate="['brief_description']"
                @colClick="materialClick"
                :table-height="600"
                :max-table-height="1500"
                :table-striped="true"
                :table-condensed="false"
                :selectable="selectingMaterials"
                :row-formatter="materialRowFormatter"
                :movable-rows="isDragging"
                @rowMoved="handleRowMoved"
              />
            </div>

            <br />
          </div>
        </div>
      </div>

      <div v-html="downloadForm" />

      <waiting-spinner :wait="wait" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import SimpleHeader from 'components/Dashboard/Misc/SimpleHeader'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import MaterialPriceFuncs from 'components/Dashboard/Materials/MaterialPriceFuncs'
import Checkbox2 from 'components/UIComponents/Checkbox'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'MaterialsAdmin',
  data() {
    return {
      wait: { message: '' },
      newCategoryName: 'All',
      selectingMaterials: false,
      newMaterialName: '',
      loadedMaterials: [],
      categoryCurrent: 0,
      sorting: 'Default',
      inlineEditable: false,
      isDragging: false,
      currentMaterials: [],
      draggedMaterials: [],
      downloadForm: '',
      showOneTimeMaterials: false
    }
  },

  components: {
    SimpleHeader,
    MaterialCategoryTrain: () => import('components/Dashboard/Materials/MaterialCategoryTrain'),
    TabulatorTable,
    MaterialSearch: () => import('components/Dashboard/Materials/MaterialSearch2'),
    Checkbox2,
    WaitingSpinner
  },

  computed: {
    ...mapGetters(['materialCategories']),

    isAdmin() {
      return this.$store.state.userAuthedData.user_role.indexOf('admin') !== -1
    },

    materialTableColumns() {
      const matColumns = [
        {
          title: 'Brief Description',
          field: 'brief_description',
          sorter: 'string',
          headerSort: !this.isDragging,
          editable: this.inlineEditable && this.isMaterialsAdmin,
          editor: 'input',
          frozen: true,
          width: 240,
          cssClass: 'no-horo-border'
          // formatter: (val) => {
          //   let newRow = (((val._cell || {}).row || {}).data || {}).id || null;
          //   if (newRow === 'new') {
          //     return '<span class="text-muted">Enter NEW in edit mode</span>';
          //   }
          //   return val.getValue();
          // }
        },
        {
          title: 'Add Price',
          field: 'addPrice',
          frozen: true,
          titleFormatter: val => {
            return ''
          },
          formatter: val => {
            let newRow = (((val._cell || {}).row || {}).data || {}).id || null
            if (newRow !== 'new') {
              return '<i class="icon-dollar-file-add" style="font-size:18px" />'
            }
          },
          width: 26,
          minWidth: 26,
          headerSort: false,
          visible: !this.selectingMaterials,
          cssClass: 'clickable no-horo-padding no-horo-border'
        },
        {
          title: 'Price History',
          field: 'showPrices',
          frozen: true,
          titleFormatter: val => {
            return ''
          },
          formatter: val => {
            let newRow = (((val._cell || {}).row || {}).data || {}).id || null
            if (newRow !== 'new') {
              return '<i class="icon-dollar-files-show" style="font-size:18px" />'
            }
          },
          width: 26,
          minWidth: 26,
          headerSort: false,
          visible: !this.selectingMaterials,
          cssClass: 'clickable no-horo-padding no-horo-border'
        },
        {
          title: 'Edit',
          field: 'edit',
          frozen: true,
          titleFormatter: val => {
            return ''
          },
          formatter: val => {
            let newRow = (((val._cell || {}).row || {}).data || {}).id || null
            if (newRow !== 'new') {
              return '<i class="ti ti-pencil" />'
            }
          },
          width: 26,
          minWidth: 26,
          headerSort: false,
          visible: !this.selectingMaterials && this.isMaterialsAdmin,
          cssClass: 'clickable no-horo-padding no-horo-border'
        },
        {
          field: 'id',
          visible: false
        },
        {
          title: 'Detailed Description',
          field: 'detailed_description',
          sorter: 'string',
          headerSort: !this.isDragging,
          editable: this.inlineEditable,
          editor: 'input',
          width: 260
        },
        // {
        //   title: 'Vendor',
        //   field: 'supplier',
        //   sorter: 'string',
        //   editable: false,
        //   editor: 'input',
        // },
        // {
        //   title: 'Manufacturer',
        //   field: 'manufacturer',
        //   sorter: 'string',
        //   editable: false,
        //   editor: 'input',
        // },
        {
          title: 'SES Part No.',
          field: 'id',
          sorter: 'string',
          headerSort: !this.isDragging,
          editable: false,
          formatter: obj => {
            let val = obj.getValue()
            if (val === 'new') {
              return ''
            }
            return val
          }
        },
        // {
        //   title: 'Parent',
        //   field: 'parent',
        //   sorter: 'number',
        // },
        {
          title: 'Catalog No.',
          field: 'catalog_number',
          sorter: 'string',
          headerSort: !this.isDragging,
          editable: this.inlineEditable,
          editor: 'input'
        },
        {
          title: 'Unit',
          field: 'material_unit',
          sorter: 'string',
          headerSort: !this.isDragging,
          editable: false,
          editor: 'input'
        },
        {
          title: 'Price',
          field: 'price',
          sorter: 'string',
          headerSort: !this.isDragging,
          editable: false,
          editor: 'input',
          formatter: 'money',
          formatterParams: {
            symbol: '$ ',
            precision: 3
          }
        },
        {
          title: 'Discount %',
          field: 'discount_percent',
          sorter: 'string',
          headerSort: !this.isDragging,
          editable: false,
          editor: 'input'
        },
        {
          title: 'Net Cost',
          field: 'price_net_cost',
          sorter: 'string',
          headerSort: !this.isDragging,
          formatter: 'money',
          formatterParams: {
            symbol: '$ ',
            precision: 3
          }
        },
        {
          title: 'Price Added',
          field: 'price_date',
          sorter: 'string',
          headerSort: !this.isDragging,
          editable: false,
          editor: false
        },
        {
          title: 'Price Expires',
          field: 'price_expires',
          sorter: 'string',
          headerSort: !this.isDragging,
          editable: false,
          editor: false
        },
        {
          title: 'Based On',
          field: 'based_on',
          sorter: 'string',
          headerSort: !this.isDragging
        },
        {
          title: 'Quoted Unit Default',
          field: 'unit_default',
          sorter: 'string',
          headerSort: !this.isDragging
        },
        {
          title: 'Manufacturer',
          field: 'manufacturer',
          sorter: 'string',
          headerSort: !this.isDragging
        },
        {
          title: 'Trade Size',
          field: 'trade_size',
          sorter: 'string',
          headerSort: !this.isDragging
        },
        {
          title: 'Type',
          field: 'type',
          sorter: 'string',
          headerSort: !this.isDragging
        },
        {
          title: 'Type 2',
          field: 'type_2',
          sorter: 'string',
          headerSort: !this.isDragging
        },
        {
          title: 'Type 3',
          field: 'type_3',
          sorter: 'string',
          headerSort: !this.isDragging
        },
        {
          title: 'Type 4',
          field: 'type_4',
          sorter: 'string',
          headerSort: !this.isDragging
        },
        {
          title: 'Colour',
          field: 'colour',
          sorter: 'string',
          headerSort: !this.isDragging
        },
        {
          title: 'Mat Comp',
          field: 'material_composition',
          sorter: 'string',
          headerSort: !this.isDragging
        },
        {
          title: 'Voltage',
          field: 'voltage',
          sorter: 'string',
          headerSort: !this.isDragging
        },
        {
          title: 'Amperage',
          field: 'amperage',
          sorter: 'string',
          headerSort: !this.isDragging
        }
      ]
      if (this.isDragging) {
        matColumns.unshift({ rowHandle: true, formatter: 'handle', headerSort: false, frozen: true, width: 30, minWidth: 30, cssClass: 'clickable no-horo-border' })
      }
      return matColumns
    }
  },

  methods: {
    handleRowMoved(data) {
      this.draggedMaterials = data
    },
    saveDragging() {
      if (!this.draggedMaterials.length) return

      const materialsMysSort = []
      this.currentMaterials.map((el, index) => {
        if (el.id !== this.draggedMaterials[index].id) {
          materialsMysSort.push({
            id: this.draggedMaterials[index].id,
            sortValue: el.mys_default_sort
          })
        }
      })

      const params = {
        action: 'upsert_materials_mys_sort',
        idsWithSortValues: materialsMysSort
      }
      this.wait.message = 'Updating Materials Sequence'
      appFuncs
        .shRequest(params)
        .then(data => {
          this.$snack.open('Materials Sequence Updated Successfully')
          this.getMaterials()
        })
        .catch(res => {
          console.log(res)
          this.$snack.open(res.message || 'Problem Updating Materials Sequence', 'error')
        })
        .finally(() => {
          this.isDragging = false
          this.draggedMaterials = []
          this.wait.message = ''
        })
    },
    selectCategory(id) {
      this.categoryCurrent = id
      this.$refs.matCatTable.selectCategory(id)
      this.getMaterials()
    },

    upsertCategory(id) {
      if (!id && !this.newCategoryName) {
        this.$snack.open('Category name required.', 'warning')
        return
      }
      const params = {
        action: 'upsert_material_category',
        data: {
          id: id ? id : 0,
          parent: this.categoryCurrent || null,
          name: this.newCategoryName
        }
      }
      this.wait.message = 'Updating Material Categories'
      appFuncs
        .shRequest(params)
        .then(data => {
          this.$snack.open('Categories Updated')
          this.newCategoryName = ''
          this.getCategories('refresh')
        })
        .catch(res => {
          console.log(res)
          this.$snack.open(res.message || 'Problem updating categories', 'error')
        })
        .finally(() => {
          this.wait.message = ''
        })
    },

    editCategory(categoryId) {
      this.$Modal({
        parent: this,
        name: 'MaterialsCategoryCategoryEdit', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Materials/MaterialsCategoryCategoryEdit'),
        props: {
          categoryId,
          objectType: 'Materials'
        }
      })
    },

    getCategories(refresh) {
      this.$bus.$emit('setWaiting', { name: 'getMaterialCategories', message: 'Getting Material Categories' })
      this.$store.dispatch('getMaterialCategories', refresh).finally(() => {
        this.$bus.$emit('stopWaiting', 'getMaterialCategories')
      })
    },

    async getMaterials(silent) {
      const params = {
        action: 'get_materials_with_prices',
        parent: this.categoryCurrent,
        status: 'active',
        showTempItems: this.showOneTimeMaterials ? 'yes' : 'no',
        getDescendants: 1,
        sorting: this.sorting
      }

      if (!silent) {
        this.$bus.$emit('setWaiting', { name: params.action, message: 'Getting Materials' })
      }
      await appFuncs
        .shRequest(params)
        .then(data => {
          this.loadedMaterials = data
          this.setMaterials()
        })
        .catch(data => {
          this.$snack.open(data.message || 'Problem getting materials.', 'error')
        })
        .finally(() => {
          if (!silent) {
            this.$bus.$emit('stopWaiting', params.action)
          }
        })
    },

    setMaterials() {
      // this gets called on update to be used without reloading table data
      if (!Array.isArray(this.loadedMaterials)) return
      let data = JSON.parse(JSON.stringify(this.loadedMaterials))
      for (var i = 0; i < data.length; i++) {
        // strip commas from number fields to fix problems w tabulator money formatter
        let moneyFields = ['material_price']
        for (var mi = 0; mi < moneyFields.length; mi++) {
          data[i][moneyFields[mi]] = this.stripToNumber(data[i][moneyFields[mi]], 'leaveThousandCommas')
        }

        // do on-the-fly calc for material net cost
        let price = data[i].price || 0
        data[i].price_net_cost = price - ((data[i].discount_percent || 0) / 100) * price

        for (var prop in data[i]) {
          if (data[i].hasOwnProperty(prop) && typeof data[i][prop] === 'string') {
            data[i][prop] = this.decode(data[i][prop])
          }
        }

        // prepare price expiry date
        data[i].price_expires = data[i].price_date && data[i].price_expiry ? moment(data[i].price_date, 'YYYY-MM-DD').add(data[i].price_expiry, 'days').format('YYYY-MM-DD') : ''

        // // flag for row coloring
        // let priceExpiresIn = data[i].price_expires && moment(data[i].price_expires, 'YYYY-MM-DD').diff(moment(), 'days');
        // data[i].price_expires_in = priceExpiresIn;
        // data[i].price_expired_by = priceExpiresIn < 1 ? Math.abs(priceExpiresIn) : 0;
      }
      this.currentMaterials = data
      //this.$refs.materialTable.updateTable();
    },

    materialRowFormatter(row) {
      let data = row.getData()
      let rowEl = row.getElement()

      if (data.status === 'deleted') {
        rowEl.classList.add('deleted')
      } else if (data.temp_item === 'yes') {
        rowEl.classList.add('temp-item')
      }

      const priceExpired = !data.price_expired && data.price_expired !== 0
      if (priceExpired || data.price_expired > 90) {
        rowEl.classList.add('price-expired-long')
      } else if (data.price_expired > 0) {
        rowEl.classList.add('price-expired')
      } else if (data.price_expired <= 0 && data.price_date) {
        rowEl.classList.add('price-valid')
      }
    },

    upsertMaterial(inline) {
      if (!this.newMaterialName) {
        this.$snack.open('Please enter new material Brief Description', 'warning')
        return
      }
      const obj = {
        id: 0,
        col: 'brief_description',
        val: this.newMaterialName
      }
      this.updateMaterialCol(obj)
    },

    updateMaterialCol(obj) {
      if (!obj.col) {
        return
      }

      const params = {
        action: 'upsert_material',
        id: obj.id,
        data: {
          [obj.col]: obj.val,
          parent: this.categoryCurrent
        }
      }
      appFuncs
        .shRequest(params)
        .then(data => {
          if (obj.id === 0) {
            this.getMaterials(false)
            this.newMaterialName = ''
          } else {
            this.getMaterials(true)

            return

            // if not new material, do manual existing data update, original plan here was to avoid reloading and re-scrolling table etc, appears to be unavoidable even utiliing tabulator reactive data

            // get existing row
            let row = this.loadedMaterials.find(itm => {
              return itm.id == obj.id
            })

            // update row col
            row[obj.col] = obj.val

            // update data
            this.loadedMaterials.splice(obj.id, 1, row)

            // run data formatting
            this.setMaterials()
          }
        })
        .catch(data => {
          console.log(data)
          // only update on fail
          this.getMaterials(true)
          this.$snack.open(data.message || 'Problem updating material data.', 'error')
        })
    },

    addMaterial(oneTimeItem) {
      this.$Modal({
        parent: this,
        name: 'MaterialEdit', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Materials/MaterialEdit'),
        props: {
          material: {
            id: 0,
            parent: 0
          },
          categoryCurrent: this.categoryCurrent,
          oneTimeItem: oneTimeItem ? true : false
        }
      })
    },

    editMaterial(materialId) {
      let material = this.currentMaterials.find(itm => {
        return itm.id == materialId
      })
      if (!material) {
        this.$snack.open('Problem selecting material.', 'error')
        return
      }

      this.$Modal({
        parent: this,
        name: 'MaterialEdit', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Materials/MaterialEdit'),
        props: {
          material
        }
      })
    },

    addPrice(materialId) {
      let material = this.currentMaterials.find(itm => {
        return itm.id == materialId
      })
      if (!material) {
        this.$snack.open('Problem selecting material.', 'error')
        return
      }

      this.$Modal({
        parent: this,
        name: 'PriceEdit',
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Materials/PriceEdit'),
        props: {
          material
        }
      })
    },

    showPrices(materialId) {
      let material = this.currentMaterials.find(itm => {
        return itm.id == materialId
      })
      if (!material) {
        this.$snack.open('Problem selecting material.', 'error')
        return
      }

      this.$Modal({
        parent: this,
        name: 'PriceHistory',
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Materials/PriceHistory'),
        props: {
          material
        }
      })
    },

    materialClick(obj) {
      let id = ((obj || {}).cell || {}).id || 0
      let field = ((obj || {}).cell || {}).field || 0
      if (field === 'edit') {
        this.editMaterial(id)
      }
      if (field === 'addPrice') {
        this.addPrice(id)
      }
      if (field === 'showPrices') {
        this.showPrices(id)
      }
    },

    showMaterialImport() {
      this.$Modal({
        parent: this,
        name: 'MaterialImportAccubid', // used for closing specific modal programmatically
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Materials/MaterialImportAccubid'),
        props: {
          parent: this.categoryCurrent
        }
      })
    },

    moveMaterials() {
      let selectedRows = this.$refs.materialTable.getSelectedData()
      let materialIds = selectedRows.map(itm => {
        return itm.id
      })

      if (!Array.isArray(materialIds) || !materialIds.length) {
        this.$snack.open('Please select Materials to move', 'warning')
        return
      }

      this.$Modal({
        parent: this,
        name: 'MaterialsCategoryEdit', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Materials/MaterialsCategoryEdit'),
        props: {
          initCategoryId: this.categoryCurrent,
          materialIds
        }
      })
    },

    showMaterialSearch() {
      this.$Modal({
        parent: this,
        name: 'MaterialSearch', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Materials/MaterialSearch'),
        title: 'Material Search'
      })
    },

    deleteMaterials() {
      // don't allow mass delete
      return

      let rows = this.$refs.materialTable.getSelectedData()

      let ids = rows.map(itm => {
        return itm.id
      })

      if (!Array.isArray(ids) || !ids.length) {
        this.$snack.open('Please select Materials to delete.', 'warning')
        return
      }

      if (!confirm("Are you sure you'd like to permanently delete selected items?")) {
        return
      }

      const params = {
        action: 'delete_material',
        ids
      }
      this.$bus.$emit('setWaiting', { name: params.action, message: 'Deleting Material' })
      appFuncs
        .shRequest(params)
        .then(res => {
          this.$snack.open('Material deleted')
          this.$bus.$emit('edit-material-item')
          this.$bus.$emit('modalClose', 'MaterialEdit')
        })
        .catch(data => {
          console.log(data)
          this.$snack.open(data.message || 'Problem deleteing material data.', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    updateInlineEditing(val) {
      this.inlineEditable = val
      setTimeout(() => {
        this.$refs.materialTable.reloadTable()
      })
    },

    updateDragging(val) {
      this.isDragging = val
      setTimeout(() => {
        this.$refs.materialTable.reloadTable()
      })
    },

    triggerExportCSV() {
      // using dynamic form to send POST vals and download in new tab
      let form = '<form id="download-form" method="POST" action="' + this.$store.getters.urls.backend + 'download-file.php" target="_blank">'
      form += '<input type="hidden" name="action" value="export_material_data_csv">'
      form += '<input type="hidden" name="categoryId" value="' + encodeURI(this.categoryCurrent) + '">'
      form += '<input type="hidden" name="jwt" value="' + this.$store.getters.sherAuth.jwt + '">'
      form += '</form>'
      this.downloadForm = form

      setTimeout(() => {
        $('#download-form').submit()
        this.downloadForm = null
      }, 500)
    },

    selectMaterial(mat) {
      let parent = mat && mat.parent
      this.selectCategory(parent)
    },

    observeHeight() {
      if (window.innerWidth <= 600) return

      const source = this.$refs.sourceDiv?.$el
      const target = this.$refs.targetDiv
      if (!source || !target) return
      target.style.top = `${-source.offsetHeight}px`
      this.observer = new MutationObserver(() => {
        target.style.top = `${-source.offsetHeight}px`
      })
      this.observer.observe(source, {
        attributes: true,
        childList: true,
        subtree: true
      })
    }
  },

  async mounted() {
    this.getCategories()
    await this.getMaterials()
    this.$bus.$on('edit-category-item', obj => {
      this.editCategory(obj)
    })
    this.$bus.$on('materialsImported', () => {
      this.getMaterials()
    })
    this.$bus.$on(['edit-material-item', 'edit-material-price-item'], obj => {
      this.getMaterials()
    })

    this.observeHeight()
  },

  beforeDestroy() {
    this.$bus.$off(['edit-material-item', 'edit-material-price-item', 'materialsImported'])

    if (this.observer) {
      this.observer.disconnect()
    }
  },

  watch: {
    selectingMaterials(allow) {
      if (allow) {
        this.inlineEditable = false
      }
    },
    sorting() {
      this.getMaterials()
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/sass/paper/_variables.scss';

.tabulator-row {
  &.deleted {
    background-color: $input-red !important;
  }

  &.temp-item {
    background-color: $input-blue !important;
  }

  &.price-valid {
    [tabulator-field='price_expires'],
    [tabulator-field='price_vendor_code'],
    [tabulator-field='price_added'],
    [tabulator-field='price_date'],
    [tabulator-field='price'],
    [tabulator-field='cost'],
    [tabulator-field='costTotal'],
    [tabulator-field='carried_price'],
    [tabulator-field='lowest_price'],
    [tabulator-field='lowest_price_vendor'],
    [tabulator-field='active_price_qty'],
    [tabulator-field='active_price_vendors'] {
      background-color: $input-green !important;
    }
  }

  &.price-expired-long {
    [tabulator-field='price_expires'],
    [tabulator-field='price_vendor_code'],
    [tabulator-field='price_added'],
    [tabulator-field='price_date'],
    [tabulator-field='price'],
    [tabulator-field='cost'],
    [tabulator-field='costTotal'],
    [tabulator-field='carried_price'],
    [tabulator-field='lowest_price'],
    [tabulator-field='lowest_price_vendor'],
    [tabulator-field='active_price_qty'],
    [tabulator-field='active_price_vendors'] {
      background-color: $input-red-dark !important;
    }
  }

  &.price-expired {
    [tabulator-field='price_expires'],
    [tabulator-field='price_vendor_code'],
    [tabulator-field='price_added'],
    [tabulator-field='price_date'],
    [tabulator-field='price'],
    [tabulator-field='cost'],
    [tabulator-field='costTotal'],
    [tabulator-field='carried_price'],
    [tabulator-field='lowest_price'],
    [tabulator-field='lowest_price_vendor'],
    [tabulator-field='active_price_qty'],
    [tabulator-field='active_price_vendors'] {
      background: $input-orange !important;
    }
  }

  &.selected {
    background: $input-yellow !important;
  }

  &.valid-prices {
    background: $input-green !important;
  }

  &.invalid-prices {
    background: $input-red-dark !important;
  }
}
</style>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.material-lists-component {
  padding: 5px 30px;
  background: #fff; //$bg-grey;
  height: 100%;
  max-width: 3000px;
  margin: 0;

  .material-category-train {
    max-width: 350px;
  }
}

.material-search-container {
  input {
    background: #fff;
  }
}

.category-table-container {
  width: 100%;
  max-width: 350px;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;

  .tabulator {
    margin-bottom: 0;
  }
}

.material-table {
  max-width: calc(100% - 370px);
  width: 100%;
  float: left;
  position: relative;

  .sorting-buttons {
    position: absolute;
    top: -60px;
  }

  .tabulator {
    margin-bottom: 0;
  }
}

.material-table-options {
  .edit-mode-container {
    display: inline-block;
    padding: 5px;
    font-size: 18px;
    vertical-align: middle;
    float: right;

    .save-dragging {
      margin-left: 5px;
      font-size: 17px;
    }

    a {
      color: $text-color;
    }
  }

  .material-add-container {
    display: inline-block;
    margin: 0 0 5px;

    .form-group {
      input {
        display: inline-block;
        width: calc(100% - 30px);
        min-width: 215px;
      }

      a {
        width: 25px;
        display: inline-block;
        text-align: center;
        color: $text-color;

        i {
          padding: 10px;
          font-size: 16px;
        }
      }
    }

    .form-group {
      margin: 0 !important;
    }

    input {
      background: $white-color;
      height: 36px;
      font-size: 14px;
      width: 300px;
      border: 1px solid $medium-gray;
    }
  }
}

@media (max-width: 600px) {
  .material-table {
    max-width: 100%;
    float: left;

    .sorting-buttons {
      position: relative;
      top: 0;
    }
  }
  .category-table-container {
    max-width: 100%;
    float: left;
  }
}

.text-tiny {
  font-size: 12px;
}

.tabulator-col-title {
  //  text-align:center!important;
}

.materials-admin {
  padding: 10px 30px;
  background: #fff; //$bg-grey;
  height: 100%;
}

.info-section {
  margin: 30px 0;

  h4 {
    font-size: 16px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 5px 0;
      font-size: 13px;

      i {
        margin: 5px 10px 5px 0;
        font-size: 18px;
      }

      * {
        vertical-align: middle;
        display: inline-block;
      }
    }
  }
}
</style>
