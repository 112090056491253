
<div class="navbar-container">
  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" :class="{ toggled: $sidebar.showSidebar }" @click="toggleSidebar">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar bar1"></span>
          <span class="icon-bar bar2"></span>
          <span class="icon-bar bar3"></span>
        </button>
        <span class="navbar-brand">{{ routeName }}</span>

        <!-- <a v-if="!['Work Order', 'Job', 'Jobs', 'Work Orders'].includes($route.name)" class="navbar-brand">{{ routeName }}</a>
        <div v-else class="navbar-brand-wrapper" @click="selectJobWO($route.name)">
          <a class="navbar-brand">{{ routeName }}</a>
          <i class="ti-angle-down is-link"></i>
        </div> -->
      </div>
      <div class="navbar-right-menu">
        <ul class="nav navbar-nav navbar-right">
          <li v-if="usersName">
            <span>Welcome, {{ usersName }}</span>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- <div class="refresh-button">
    <div :class="['refresh-button-inner', { spin: deletingStorage }]">
      <i class="ti-reload" @click="deleteStorage" :title="'Clear Saved Data'"></i>
    </div>
  </div> -->
</div>
