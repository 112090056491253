<template>
  <div class="customer-ticket" id="customer-ticket">
    <div class="container">
      <div class="row header" v-if="job.Job_Number" id="pdfReady">
        <div class="col-xs-12 col-sm-4">
          <router-link to="/">
            <img :src="`${urls.frontend}static/img/${logoFile}`" />
          </router-link>
        </div>
        <div class="col-xs-12 col-sm-4">
          <p v-html="regionAddress" />
        </div>
        <div class="col-xs-12 col-sm-4 text-right">
          <h4>
            <span v-if="jobExtra.type === 'tm' || jobExtra.type === 'extra tm'"> T&amp;M Job Sign Off </span>
            <span v-else-if="jobExtra.type === 'other'"> Job Other </span>
            <span v-else> Job Extra </span>
            <br />
            <strong> #<span v-html="jobExtraNumber" /> </strong>
          </h4>
        </div>
      </div>

      <div class="row wo-info" v-if="job.Job_Number">
        <div class="col-sm-12 col-md-6">
          <table class="wo-item-list border-bottom">
            <tr>
              <td class="col-medium">
                <strong>Customer Name</strong>
              </td>
              <td>
                <span v-html="job.Customer_Name" />
              </td>
            </tr>
            <tr>
              <td class="col-medium">
                <strong>Job Number</strong>
              </td>
              <td>
                <span v-html="job.Job_Number" />
              </td>
            </tr>
            <tr></tr>

            <tr>
              <td class="col-medium">
                <strong>Site Address</strong>
              </td>
              <td>
                <span v-if="job.Address_1" v-html="job.Address_1" />
                <br />
                <span v-if="job.City" v-html="job.City" /><span v-if="job.State" v-html="', ' + job.State" /><span v-if="job.Zip_Code" v-html="', ' + job.Zip_Code" />
              </td>
            </tr>
            <tr>
              <td class="col-medium">
                <strong>Signed Date</strong>
              </td>
              <td>
                <span v-html="dateFromUnix(signOffDate)" />
              </td>
            </tr>
          </table>
          <p class="safety-text">Safety First - Every Time - No Shortcuts</p>
        </div>

        <div class="col-sm-12 col-md-6">
          <table class="wo-item-list">
            <tr>
              <td class="col-medium">
                <strong>Quote Number</strong>
              </td>
              <td>
                <span v-html="jobExtra.quote_number" />
              </td>
            </tr>
            <tr>
              <td class="col-medium">
                <strong>Customer PO</strong>
              </td>
              <td>
                <span v-if="jobExtra.po">
                  {{ jobExtra.po }}
                </span>
                <span v-else>
                  {{ job.Contract_Number }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="col-medium">
                <strong>Requested By</strong>
              </td>
              <td>
                <span v-html="job.Owner_Name" />
              </td>
            </tr>
            <tr>
              <td class="col-medium">
                <strong>Serviced By</strong>
              </td>
              <td>
                <span v-html="job.Project_Manager_Name" />
              </td>
            </tr>
          </table>
        </div>
      </div>

      <section class="row" v-if="jobExtra.description">
        <div class="col-sm-12">
          <table class="wo-item-list">
            <tr>
              <th>Work Summary</th>
            </tr>
            <tr>
              <td v-html="getPlainText(jobExtra.description)" />
            </tr>
          </table>
        </div>
      </section>

      <section class="row" v-if="job_extras_notes.length">
        <div class="col-sm-12">
          <table class="wo-item-list">
            <tr>
              <th class="col-narrow">Date</th>
              <th>Work Completed</th>
            </tr>
            <tr v-for="(note, i) in job_extras_notes" :key="i">
              <td class="col-medium">
                <span v-html="note.nice_date" />
              </td>
              <td v-html="getPlainText(note.note_text)" />
            </tr>
          </table>
        </div>
      </section>

      <section class="row" v-if="!hideDetails && !hideLaborDetails && timeEntries && timeEntries.length">
        <div class="col-sm-12">
          <table v-if="totalLaborHours >= 4 || showLaborLog" class="wo-item-list time-log">
            <tr>
              <th class="col-narrow">Date</th>
              <th v-if="showLaborLog">Time Log</th>
              <th>Employee</th>
              <th v-if="showLaborHours">Hours</th>
              <th v-if="showLaborHours">Type</th>
            </tr>
            <tr v-for="(entry, i) in timeEntries" :key="i">
              <td class="col-medium" v-html="entry.entry_date" />
              <td v-if="showLaborLog" v-html="getPlainText(entry.message)" />
              <td v-html="entry.employee_name" />
              <td v-if="showLaborHours" v-html="entry.hours" />
              <td v-if="showLaborHours" v-html="entry.hour_term" />
            </tr>
          </table>
          <table v-if="!showLaborHours" class="wo-item-list time-log">
            <tr>
              <th>Labor</th>
            </tr>
            <tr>
              <td>Standard Service Charge</td>
            </tr>
          </table>
        </div>
      </section>
      <section class="row" v-else-if="!hideDetails && hideLaborDetails && timeEntriesSummary && timeEntriesSummary.length">
        <div class="col-sm-12">
          <table v-if="totalLaborHours >= 4 || showLaborLog" class="wo-item-list time-log">
            <tr>
              <th class="col-narrow">Date</th>
              <th v-if="showLaborLog">Time Log</th>
              <th>Employee</th>
              <th v-if="showLaborHours">Hours</th>
            </tr>
            <tr v-for="(entry, i) in timeEntriesSummary" :key="i">
              <td class="col-medium" v-html="entry.entry_date" />
              <td v-html="entry.employee_name" />
              <td v-if="showLaborLog" v-html="getPlainText(entry.message)" />
              <td v-if="showLaborHours" v-html="entry.hours" />
            </tr>
          </table>
        </div>
      </section>

      <section class="row" v-if="!hideDetails && job_materials_notes.length">
        <div class="col-sm-12">
          <table class="wo-item-list">
            <tr>
              <th class="col-narrow">Date</th>
              <th>Materials</th>
            </tr>
            <tr v-for="(note, i) in job_materials_notes" :key="i">
              <td class="col-medium">
                <span v-html="note.nice_date" />
              </td>
              <td v-html="getPlainText(note.note_text)" />
            </tr>
          </table>
        </div>
      </section>

      <section class="row" v-if="!hideDetails && jobExtra.other_charges_list && jobExtra.other_charges_list.length">
        <div class="col-sm-12">
          <table class="wo-item-list">
            <tr>
              <th class="col-narrow">Item #</th>
              <th>Other Charges</th>
            </tr>
            <tr v-for="(entry, i) in jobExtra.other_charges_list" :key="i">
              <td v-html="i + 1" />
              <td v-html="getPlainText(entry.Description)" />
            </tr>
          </table>
        </div>
      </section>

      <section v-if="jobExtra.id">
        <table class="wo-item-list wo-checklist">
          <tr>
            <td><label>Complete</label></td>
            <td class="narrow">
              <i class="ti ti-check" />
            </td>
            <td><label>Quotation</label></td>
            <td class="narrow">
              <i class="ti ti-check" />
            </td>
            <td><label>Recommendation</label></td>
            <td class="narrow">
              <i class="ti ti-check" />
            </td>
            <td><label>Safety</label></td>
            <td class="narrow">
              <i class="ti ti-check" />
            </td>
          </tr>
        </table>
      </section>

      <section>
        <table class="wo-item-list border-bottom" v-if="jobExtra.id">
          <tr>
            <td>
              <p class="satisfaction-text">If for any reason our service did not exceed your expectations please contact us.</p>
            </td>
            <td>
              <div class="signature-display-container">
                <p><label>Customer Signature</label></p>
                <div v-if="jobExtra.id">
                  <div class="existing-signature" v-if="existingSig">
                    <img :src="this.urls.mssql + 'job-extra-signatures/' + existingSig" />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </section>

      <div v-if="pdf" class="footer" style="margin-bottom: 20px">
        <div class="footer-inner">
          <div class="page-number">
            <p>Page 1 of 1</p>
          </div>
        </div>
      </div>

      <section class="not-pdf">
        <div v-if="job.Job_Number">
          <div>
            <button v-if="!isLocked && (jobExtra.status === 'finished' || ['report sent', 'unresponsive'].includes(jobExtra.status))" class="btn not-pdf" @click="showStartSign()" v-html="'Sign Off'" />
            <button
              v-if="!isLocked && sherAuth.eid && (jobExtra.status === 'finished' || ['report sent', 'unresponsive'].includes(jobExtra.status))"
              class="btn not-pdf"
              @click="showSignOffSend()"
              v-html="'Email Sign Off Link'"
            />
            <button v-if="signed && isLocked" class="btn not-pdf" @click="downloadSigned()" v-html="'Download PDF'" />
            <button v-if="isAdmin && existingSig" class="btn not-pdf" @click="saveEmailPDF()" v-html="'PDF Re-Generate'" />
          </div>
        </div>
      </section>
    </div>

    <br />
    <br />
    <br />
    <br />

    <waiting-spinner :wait="wait" />
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'
import commonFuncs from 'mixins/commonFuncs'
import SignatureSave from 'components/Dashboard/Forms/SignatureSave'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import dateFormat from 'dateformat'

export default {
  components: { WaitingSpinner },
  data() {
    return {
      job: {},
      jobExtra: {},
      wait: {
        message: ''
      },
      contacts: [],
      existingSig: '',
      existingPDF: '',
      signOff: [],
      timeEntries: [],
      timeEntriesSummary: [],
      signed: '',
      job_extras_notes: [],
      job_materials_notes: [],
      signOffDate: '',
      totalLaborHours: 0,
      showLaborLog: true,
      showLaborHours: true // totalLaborHours >= 4
    }
  },

  props: {
    authCode: {
      type: String,
      required: true
    },

    pdf: {
      type: [String, Boolean],
      default: false
    }
  },

  mixins: [commonFuncs],

  computed: {
    sherAuth() {
      return this.$store.getters.sherAuth
    },

    userEmail() {
      return ((this.$store.getters.appData || {}).employeeData || {}).Employee_Email || ''
    },

    isAdmin() {
      let auth = this.$store.getters.userAuthedData.user_role
      return auth ? auth.indexOf('admin') !== -1 : false
    },

    urls() {
      return this.$store.getters.urls
    },

    auth() {
      return this.$store.getters.urls
    },

    hasSignOff() {
      return this.jobExtra && this.signOff.length >= 1 ? true : false
    },

    jobExtraNumber() {
      return this.formatExtraNumber(this.jobExtra.job_number, this.jobExtra.id)
    },

    isLocked() {
      return this.jobExtra && this.jobExtra.locked == 1
    },

    hideDetails() {
      return this.jobExtra && this.jobExtra.hide_details === 'yes'
    },

    hideLaborDetails() {
      return this.jobExtra && this.jobExtra.hide_labor_rate_details === 'yes'
    },

    companyName() {
      const vars = this.getRegionVars(this.job.region)
      return vars.companyName
    },

    logoFile() {
      const vars = this.getRegionVars(this.job.region)
      return vars.logoFile
    },

    regionAddress() {
      const vars = this.getRegionVars(this.job.region)
      return vars.address
    },

    regionTel() {
      const vars = this.getRegionVars(this.job.region)
      return vars.tel
    }
  },

  methods: {
    getData(callback) {
      if (!this.authCode) {
        return
      }

      this.wait.message = 'Fetching data'

      const params = {
        action: 'get_job_extra_sign_off_data',
        auth_code: this.authCode,
        urldecode: 'false'
      }

      if (this.pdf) {
        params.try_cache = 1
      }

      appFuncs
        .shRequest(params)
        .then(res => {
          if (!res.job || !res.job.Job_Number) {
            this.$snack.open('Problem loading Job, please try again later or request a differnet link.', 'error')
          } else {
            this.job = res.job
            this.jobExtra = res.job_extra
            this.existingSig = res.last_job_signature
            this.signed = ((res.last_signed || {}).a_attr || {}).href || ''
            this.contacts = res.customer_contacts || []
            this.job_materials_notes = ((res.notes || {}).job_materials_notes || {}).data || []
            this.job_extras_notes = ((res.notes || {}).job_extras_notes || {}).data || []
            this.signOffDate = res.sign_off_date
            this.existingPDF = res.last_signed

            let timeEntries = res.time_card_entries || []

            // extra filtering, backend should already do so
            timeEntries = timeEntries.filter(itm => {
              return itm.extra_id == this.jobExtra.id
            })

            this.totalLaborHours = 0
            for (var i = 0; i < timeEntries.length; i++) {
              this.totalLaborHours += parseFloat(timeEntries[i].hours) || 0
            }

            timeEntries = timeEntries.sort((a, b) => {
              if (a.entry_date > b.entry_date) return -1
              if (a.entry_date < b.entry_date) return 1
              // if no difference in work dates sort by time entered
              if (a.timestamp > b.timestamp) return -1
              if (a.timestamp < b.timestamp) return 1
            })

            this.timeEntries = timeEntries

            // summarize timecard entries for view option
            let timeEntriesSummary = {}
            for (let i = 0; i < timeEntries.length; i++) {
              const key = `${timeEntries[i].employee_code}_${timeEntries[i].entry_date}`
              if (timeEntriesSummary[key]) {
                timeEntriesSummary[key].hours = timeEntriesSummary[key].hours + parseFloat(timeEntries[i].hours)
                timeEntriesSummary[key].message = timeEntriesSummary[key].message + '<div></div>' + timeEntries[i].message
              } else {
                timeEntriesSummary[key] = {
                  hours: parseFloat(timeEntries[i].hours),
                  employee_name: timeEntries[i].employee_name,
                  entry_date: timeEntries[i].entry_date,
                  message: timeEntries[i].message
                }
              }
            }
            this.timeEntriesSummary = Object.values(timeEntriesSummary)

            if (typeof callback === 'function') {
              callback()
            }
          }
        })
        .catch(e => {
          console.log(e)
          this.$snack.open('Problem retrieving Job data, please try again now or later.', 'error')
        })
        .finally(() => {
          this.wait.message = ''
        })
    },

    downloadSigned() {
      window.open(this.signed)
    },

    showStartSign() {
      if (this.isLocked) {
        return false
      }
      this.$bus.$emit('modalOpen', {
        title: 'Sign Off Job Extra Report',
        classes: 'gray-bg',
        name: 'signature-save',
        size: 'modal-m',
        component: 'signature-save',
        componentData: {
          itemId: this.jobExtraNumber,
          name: 'signature-save',
          authCode: this.authCode,
          //customerContacts: this.customerContacts,
          saveFolder: 'job-extra-signatures',
          showDate: true,
          itemType: 'Job Extra',
          existingSig: '',
          customerContacts: this.contacts
        }
      })
    },

    saveEmailPDF(emailTo) {
      appFuncs.scrollTop()

      const params = {
        action: 'save_job_extra_service_request_pdf',
        auth_code: this.authCode,
        url: window.location.href,
        email_to: emailTo,
        email_cc: this.userEmail
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Saving document' })
      appFuncs
        .shRequest(params, 1)
        .then(data => {
          let message = ''
          if (data.emailRes && data.emailRes['status'] === 'success') {
            message = 'PDF emailed successfully'
          } else {
            message = 'PDF saved successfully'
          }

          appFuncs.scrollTop()

          if (!emailTo && data.location) {
            window.open(data.location)
          }

          this.getData()
          this.$snack.open(message)
        })
        .catch(res => {
          console.log(res)
          this.$snack.open('Problem generating PDF, please try again later', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    downloadPDF() {
      window.open(this.existingPDF)
    },

    getPlainText(text) {
      return appFuncs.getPlainText(text)
    },

    showSignOffSend() {
      let data = Object.assign({}, this.jobExtra)
      data.jobExtraNumber = this.jobExtraNumber

      data.region = this.job.region

      this.$Modal({
        parent: this,
        title: 'Email Sign Off Link',
        classes: 'gray-bg',
        name: 'send-signoff-link',
        size: 'lg',
        component: () => import('components/Dashboard/JobWo/SendSignOffLink.vue'),
        props: {
          name: 'send-signoff-link',
          authCode: this.authCode,
          itemType: 'Job Extra',
          contacts: this.contacts,
          data
        }
      })
    },

    pdfClearModifications() {
      let footer = document.getElementsByClassName('footer')[0]
      if (!footer) {
        return
      }
      // remove all previously added content
      let addedContent = document.getElementsByClassName('added-content')
      while (addedContent[0]) {
        addedContent[0].parentNode.removeChild(addedContent[0])
      }

      let addedSpacers = document.getElementsByClassName('added-spacer')
      while (addedSpacers[0]) {
        addedSpacers[0].parentNode.removeChild(addedSpacers[0])
      }
      let addedLines = document.getElementsByClassName('measure-line')
      while (addedLines[0]) {
        addedLines[0].parentNode.removeChild(addedLines[0])
      }
      let pageNum = footer.getElementsByClassName('page-number')
      if (pageNum && pageNum[0]) {
        pageNum[0].innerHTML = '<p>Page 1 of 1<p>'
      }
    },

    pdfSpaceSections() {
      let mainEl = document.getElementById('customer-ticket')
      let sections = Array.prototype.slice.call(document.querySelectorAll('section'))

      let header = document.getElementsByClassName('header')[0]
      let footer = document.getElementsByClassName('footer')[0]
      let footerHeight = footer.offsetHeight + parseInt(footer.style.marginBottom)

      const pageHeight = 1490
      let nextPageBottom = pageHeight
      let pageCount = 1

      sections.forEach(function (itm) {
        let top = itm.getBoundingClientRect().top + document.documentElement.scrollTop
        let itmHeight = itm.offsetHeight
        let bottom = itmHeight + top + 100

        // if page break is between top and bottom, add enough margin to get on next page
        if (top < nextPageBottom && bottom > nextPageBottom) {
          // if section is taller than page, add break between table rows
          if (itmHeight > 500) {
            let rows = itm.getElementsByTagName('tr')
            Array.from(rows).forEach(function (rowItm) {
              let rowTop = rowItm.getBoundingClientRect().top + document.documentElement.scrollTop
              let rowHeight = rowItm.offsetHeight
              let rowBottom = rowHeight + rowTop + 100
              if (rowTop < nextPageBottom && rowBottom > nextPageBottom) {
                let marginNeeded = pageCount * pageHeight - rowTop + header.offsetHeight
                let spacer = document.createElement('div')
                spacer.classList.add('added-spacer')
                spacer.style.paddingTop = marginNeeded + 'px' // use padding to prevent top of margin issue from not creating space on next page.
                rowItm.insertAdjacentHTML('beforebegin', spacer.outerHTML)
                nextPageBottom += pageHeight
                pageCount++
              }
            })
          } else {
            let marginNeeded = pageCount * pageHeight - top + header.offsetHeight
            let spacer = document.createElement('div')
            spacer.classList.add('added-spacer')
            spacer.style.paddingTop = marginNeeded + 'px' // use padding to prevent top of margin issue from not creating space on next page.
            itm.insertAdjacentHTML('beforebegin', spacer.outerHTML)
            nextPageBottom += pageHeight
            pageCount++
          }
        }
      })

      let newFooter = null
      let addedContent = null
      let pageNo = 0
      let topPos = 0
      let pageNum = null
      for (var i = 0; i < pageCount - 1; i++) {
        // -1 dont do last page, use original footer
        pageNo++
        newFooter = footer.cloneNode(true)
        pageNum = newFooter.getElementsByClassName('page-number')
        if (pageNum && pageNum[0]) {
          pageNum[0].innerHTML = '<p>Page ' + pageNo + ' of ' + pageCount + '<p>'
        }
        topPos = pageNo * pageHeight - footerHeight
        addedContent = '<div class="added-content" style="top:' + topPos + 'px">' + newFooter.outerHTML + '<div style="margin-top:0px">' + header.outerHTML + '</div>' + '</div>'
        mainEl.insertAdjacentHTML('afterend', addedContent)
      }

      // update original footer now at bottom of page
      let pageNumLast = footer.getElementsByClassName('page-number')
      if (pageNumLast && pageNumLast[0]) {
        pageNumLast[0].innerHTML = '<p>Page ' + pageCount + ' of ' + pageCount + '<p>'
      }
      // this.addGridLines();
    },

    addGridLines() {
      let addedContent = document.getElementsByClassName('measure-line')
      while (addedContent[0]) {
        addedContent[0].parentNode.removeChild(addedContent[0])
      }

      let mainEl = document.getElementById('customer-ticket').offsetHeight
      let parts = mainEl / 10
      let line = null
      for (var i = 0; i < parts; i++) {
        line = document.createElement('div')
        line.setAttribute('class', 'measure-line')
        line.style.top = i * 10 + 'px'
        line.innerHTML = i * 10
        if ((i * 10) % 1490 == 0) {
          line.style.background = 'purple'
        }
        document.getElementsByTagName('body')[0].appendChild(line)
      }
    }
  },

  mounted() {
    this.getData()
    if (this.$store.getters.userAuthedData && this.$store.getters.userAuthedData.eid) {
      this.userName = this.$store.getters.userAuthedData.fname + ' ' + this.$store.getters.userAuthedData.lname
    }

    // this gets added when pdf is getting generated - helper for css changes
    //document.querySelector("body").classList.add("pdf")

    this.$bus.$on('signatureSaved', data => {
      // display new sig
      this.getData(() => {
        if (data.saveDoc) {
          this.saveEmailPDF(data.emailTo)
        }
      })
    })
  },

  updated() {
    if (this.pdf) {
      this.pdfClearModifications()
      setTimeout(() => {
        this.pdfSpaceSections()
      }, 150)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

// pdf styles
body.pdf .customer-ticket {
  min-width: 1200px;
}

body.pdf .header > div {
  width: 33% !important;
  text-align: left !important;
  float: left;
}

body.pdf .wo-info > div {
  width: 50% !important;
  text-align: left !important;
  float: left;
}

body.pdf .header .col-sm-4.text-right {
  text-align: right !important;
}

body.pdf .header img {
  margin: 0 !important;
}

.customer-ticket .container {
  max-width: 1100px;
  margin: 0 auto;
}

.header {
  padding: 40px 0 30px;

  img {
    width: 100%;
    max-width: 300px;

    @media (max-width: 767px) {
      margin: 0 auto;
      display: block;
    }
  }

  h4 {
    margin: 0;
  }

  h5 {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    > .col-xs-12 {
      text-align: center;
    }
  }
}

.wo-info {
  span {
    // font-weight:bold;
    // text-decoration:underline;
  }
}

hr {
  border-color: $dark-gray;
}

table.wo-item-list {
  width: 100%;
  margin-bottom: 20px;

  td,
  th {
    border: 1px solid $other-medium-gray;
    padding: 3px;
    word-break: break-word;
    min-width: 90px;
  }

  &:not(.border-bottom) {
    td,
    th {
      //  border-bottom:0;
    }
  }

  .col-narrow {
    width: 80px;
  }

  .col-medium {
    width: 150px;
  }
}

.wo-status-container {
  margin-bottom: 20px;

  h3 {
    display: inline-block;
  }

  .wo-status {
    padding: 10px 0;
    font-size: 16px;

    span {
      text-transform: uppercase;
      font-weight: bold;

      &.green {
        color: $success-color;
      }

      &.yellow {
        color: $general-yellow;
      }

      &.red {
        color: $general-red;
      }
    }
  }
}

.safety-text {
  color: red;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
}

.wo-checklist {
  display: none;
  min-width: 500px;
  table-layout: fixed;
  margin-bottom: 0 !important;

  td {
    width: 100px;
    border-bottom: 0 !important;

    &.narrow {
      width: 20px;
      text-align: center;

      i {
        color: green;
      }
    }
  }

  label {
    margin-bottom: 0;
  }
}

.satisfaction-text {
  color: red;
  padding: 20px 40px;
  text-transform: uppercase;
  font-size: 18px;

  @media (max-width: 600px) {
    font-size: 12px;
    padding: 5px;
  }
}

.signature-display-container {
  min-width: 200px;
  max-width: 370px;
  min-height: 150px;
  padding: 5px;

  img {
    width: 100%;
  }

  p {
    text-align: center;
    padding: 5px;
    margin: 0;
  }
}

.footer {
  .footer-inner {
    max-width: 1100px;
    margin: 0 auto;
  }

  .page-number {
    text-align: right;
  }
}
</style>
