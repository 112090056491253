
<div class="tab-section white">
  <div class="max-width-large">
    <ul class="nav nav-tabs">
      <li role="presentation" :class="[{ active: tab == 'Employees' }]">
        <a href="#" @click="tab = 'Employees'"> Employees </a>
      </li>
      <li role="presentation" :class="[{ active: tab == 'Subcontractors' }]">
        <a href="#" @click="tab = 'Subcontractors'"> Subcontractors </a>
      </li>
    </ul>

    <div v-if="tab == 'Employees'" :class="['card', { Main: tab == 'Employees' }]">
      <div class="content">
        <div class="row" style="margin-bottom: 10px">
          <div class="col-sm-6 col-md-3">
            <region-filter @select-region="selectRegion" />
          </div>
        </div>
        <employee-list :region="region" />
      </div>
    </div>
    <div v-if="tab == 'Subcontractors'" :class="['card']">
      <div class="content">
        <employee-subcontractor-list />
      </div>
    </div>
  </div>

  <waiting-spinner />
</div>
