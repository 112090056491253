
<div class="page-container">
  <div class="row">
    <div class="col-sm-12">

      <div class="row top-row" style="padding: 20px 0px 0;">
        <div class="col-sm-12">
          <h3 style="display:inline-block;"><span class="quote-number">{{ quoteNumber }}</span></h3>
          <button
            class="btn"
            @click="save"
            style="float: right;margin-top:20px"
          >
            Save
          </button>
        </div>
      </div>

      <hr>

      <quote-write-up
        ref="quoteWriteUpComponent"
        v-if="!readOnly"
        :quote-material-list-id="quoteMaterialListId"
        :readonly="false"
      />
    </div>
    <waiting-spinner/>
  </div>
</div>
