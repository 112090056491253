
<div :class="['app-main', { 'nav-open': $sidebar.showSidebar }, { 'nav-locked': stickSidebar }]">
  <div ref="main" style="position: relative">
    <div class="newVersionBanner not-pdf" v-if="appIsOutdated && !isPdf">
      <div>
        <div class="row">
          <div class="col-sm-12">
            <div>
              <h4>NEW VERSION</h4>
              <p>
                Reload to ensure compatibility.
                <span
                  style="padding-left: 3px"
                  class="ti-info-alt"
                  v-tooltip="{
                    content: tooltipContent,
                    placement: 'bottom',
                    hideOnTargetClick: false,
                    delay: { show: 200, hide: 2500 },
                    offset: 5,
                    classes: ['wide']
                  }"
                />
              </p>
            </div>
            <button class="btn btn-lg btn-pip btn-no-radius" @click="reloadApp">Reload</button>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <!--This sidebar appears only for screens smaller than 992px-->
    <side-bar type="navbar" :sidebar-links="$sidebar.sidebarLinks" />
    <div v-if="authorized && !feedbackOpen" class="user-feedback-button">
      <a href="#" @click="showUserFeedback" class="pulse feedback-button" title="Help/Feedback/Error Report"><i class="ti-flag-alt"></i></a>
    </div>

    <modal />
    <modal-multi />
    <waiting-spinner v-if="authorizing" />
  </div>
</div>
