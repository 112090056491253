<template>
  <div class="table-editor">
    <textarea :id="id" class="mys-table-editor"/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: 'table-editor-' + Math.random().toString(36).substr(2, 9),
      pageEditor: null
    };
  },

  props: {
    tableContent: {
      type: String,
      default: ''
    }
  },

  methods: {
    loadEditor() {
      const pageEditor = window.tinymce.init({
        selector: '#' + this.id,
        // content_css: ['base.css', 'generic-tables.css'],
        plugins: 'table autoresize code contextmenu nonbreaking paste visualchars',
        menubar: false,
        toolbar: 'table tablerowprops tablecellprops tablemergecells | bold preparetable code',
        // toolbar: 'newdocument | table tablerowprops tablecellprops tablemergecells | alignnone alignright bold styleselect | nonbreaking visualchars | preparetable code',
        table_toolbar: 'tablerowprops tablecellprops tablemergecells | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol', // below every table
        contextmenu: 'tableprops cell row column', // appears on right click
        autoresize_max_height: 600,
        formats: {
          alignright: {selector: 'td,th', classes: 'numeric'},
        },
        style_formats: [
          {title: 'Visually hidden text', inline: 'span', classes: 'visuallyhidden'},
        ],
        // only allow table elements, plus a few more useful inline elements
        // br = if added, TinyMCE adds br's all over the place (but they don't get through to the source code to be copied), causing issues with marking of empty header cells
        // class: source-tableeditor = in case we need to do something in slimmer
        valid_elements: 'table[class:source-tableeditor|id],caption,col,colgroup[span],thead,tbody,tfoot,tr[class],td[scope|colspan|rowspan|class],th[scope|colspan|rowspan|class],strong,span[class],abbr[title],br',
        forced_root_block: false,
        table_grid: false,
        table_advtab: false,
        table_cell_advtab: false,
        table_row_advtab: false,
        table_use_colgroups: true,
        content_style: `
            table {
              min-width: 500px;
              border-collapse: collapse;
              table-layout: auto;
            }
            td {
              padding: 5px;
              border: 1px solid #ccc;
              text-align: left;
            }
            tr:nth-child(even) {
              background-color: #f1f1f1;
            }`
      });
    },

    getContent() {
      const editor = window.tinymce.get(this.id);
      if (editor) {
        const content = editor.getContent();
        return content;
      }
    },

    listenForEditors(editor) {
      if (editor.id === this.id) {
        const pageEditor = window.tinymce.get(this.id);
        pageEditor.on('init', () => {
          pageEditor.setContent(this.tableContent);
        });
      }
    },

    setEditorEvents() {
      window.tinymce.on('AddEditor', function (e) {
        this.$bus.$emit('editorAdded', e.editor);
      });
    }
  },

  mounted() {
    this.$bus.$on('editorAdded', this.listenForEditors);
    this.loadEditor();
  },

  beforeDestroy() {
    this.$bus.$off('editorAdded', this.listenForEditors);
  }
}
</script>

<style lang="scss">


</style>
