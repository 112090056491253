<template>
  <div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <!-- <div class="form-group">
          <label>Customer Code (optional)</label>
          <input type="text" class="form-control" v-model="formVals.customer_code">
        </div> -->

        <div class="form-group">
          <label>Company Name</label><span class="required-star">*</span>
          <input type="text" class="form-control" v-model="formVals.company_name">
        </div>

        <div class="form-group">
          <label>Address 1</label><span class="required-star">*</span>
          <input type="text" class="form-control" v-model="formVals.address_1">
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="form-group">
          <label>Address 2</label>
          <input type="text" class="form-control" v-model="formVals.address_2">
        </div>

        <div class="form-group">
          <label>Postal Code</label><span class="required-star">*</span>
          <input
            type="text"
            class="form-control"
            v-model="formVals.postal_code"
            v-mask="chooseMaskPostalZip(formVals.postal_code)"
          >
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="form-group">
          <label>City</label><span class="required-star">*</span>
          <input type="text" class="form-control" v-model="formVals.city">
        </div>
        <div class="form-group">
          <label>Province</label><span class="required-star">*</span>
          <input type="text" class="form-control" v-model="formVals.state">
        </div>
      </div>
    </div>

    <input type="hidden" class="form-control" v-model="formVals.terms_code">
    <input type="hidden" class="form-control" v-model="formVals.tax_code">
    <button class="btn" @click="addData">Add Customer</button>
    <waiting-spinner/>

  </div>
</template>
<script>

import store from 'store';
import appFuncs from 'appFuncs';
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  components: {WaitingSpinner},
  data() {
    return {
      formVals: {
        tax_code: 'HST',
        terms_code: 1,
        state: 'ON',
      },
    };
  },

  methods: {
    addData() {
      const params = {
        action: 'add_customer',
        data: this.formVals
      };

      this.$bus.$emit('setWaiting', {name: 'addCustomer', message: 'Adding customer'});
      appFuncs.ajax_request(store.getters.sherAuth, params, result => {
        this.$bus.$emit('stopWaiting', 'addCustomer');
        if (result.status === 'success') {
          this.$bus.$emit('modalClose', 'add-customer');
          this.$bus.$emit('setWaiting', {name: 'getCustomers', message: 'Getting customers'});
          this.$store.dispatch('getCustomers', true).then(() => {
            this.formVals.Customer_Code = ((result.data || {}).Customer_Code) || '';
            this.$bus.$emit('customerAdd', this.formVals);
            this.formVals = {
              tax_code: 'HST',
              terms_code: 1,
              state: 'ON'
            };
          }).finally(() => {
            this.$bus.$emit('stopWaiting', 'getCustomers');
          });
          this.$snack.open('Customer successfully added in Spectrum and Salesforce');
        } else if (result.message && result.message.indexOf('duplicate') !== -1) {
          this.$snack.open('Record failed to get created due to duplicate record detection, please search for existing record or modify entry details.', 'warning', null, null, 10, true);
        } else {
          this.$snack.open('Problem adding customer please try again later', 'error');
        }
      });
    },
  },

  watch: {
    'formVals.customer_code'(newVal, oldVal) {
      if (typeof newVal === 'string') {
        this.formVals.customer_code = newVal.toUpperCase().substring(0, 10);
      }
    }
  }
};

</script>

<style lang="scss" scoped>
.card .content {
  padding: 15px;
}
</style>
