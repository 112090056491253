
<div>
  <form id="job-filter-form" class="card">
    <div class="content">
      <h4>Filter By:</h4>
      <div class="row">
        <div class="col-md-4 form-group">
          <label for="company_filter">Job Number</label>
          <input class="form-control" type="text" name="company_filter" v-model="numberFilter">
        </div>

        <div class="col-md-4 form-group">
          <label for="customer_name">Customer Name</label>
          <input class="form-control" type="text" name="customer_name" v-model="companyFilter">
        </div>

        <div class="col-md-4 form-group">
          <label for="job_description">Job Description</label>
          <input class="form-control" type="text" name="job_description" v-model="descFilter">
        </div>
      </div>
    </div>
  </form>

  <div class="table-responsive">
    <table class="table table-border">
      <thead>
      <tr>
        <th>Job Number</th>
        <th>Customer Name</th>
        <th>Job Description</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="job in filteredJobs">
        <td><a href="#" v-on:click="selectJob(job.Job_Number, $event)">{{ job.Job_Number }}</a></td>
        <td><span v-html="job.Customer_Name"></span></td>
        <td><span v-html="job.Job_Description"></span></td>
      </tr>
      </tbody>
    </table>
  </div>

  <waiting-spinner/>

</div>
