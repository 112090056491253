<template>
  <div class="tab-section white">

    <set-view-as-employee v-if="isAdmin" />

    <div class="max-width-large">
      <div class="row">
        <div class="col-lg-12">
          <button class="btn" @click="addExpense" v-html="'Add Expenses'" />
        </div>
      </div>

      <br />

      <div class="tab-section outline white">
        <ul class="nav nav-tabs">
          <li role="presentation" :class="[{ active: tabMain == 'Personal' }]">
            <a href="#" @click=";(tabMain = 'Personal'), (vendorType = 'Personal')"> Personal </a>
          </li>
          <li role="presentation" :class="[{ active: tabMain == 'Company' }]">
            <a href="#" @click=";(tabMain = 'Company'), (vendorType = 'Company')"> Company </a>
          </li>
        </ul>
      </div>

      <div class="card">
        <div class="content">
          <alert
            :alert="{
              show: alertMessage,
              message: alertMessage,
              state: 'warning',
              dismiss: 'none'
            }"
          />
          <div class="row" v-if="isAdmin && 'cats' == 'dogs'">
            <div class="col-sm-4">
              <div class="form-group">
                <label>Vendor</label><span class="required-star">*</span>
                <select-field :options="vendors" :option-display-keys="['Vendor_Name']" :option-val="'Vendor_Code'" v-model="vendor" :item-name="'Vendor'" @selectItem="getInvoices" />
              </div>
            </div>
          </div>

          <div class="tab-section outline">
            <ul class="nav nav-tabs">
              <li v-if="tabMain == 'Personal'" role="presentation" :class="[{ active: tab == 'Open' }]">
                <a href="#" @click="tab = 'Open'"> Open Invoices </a>
              </li>
              <li v-if="tabMain == 'Personal'" role="presentation" :class="[{ active: tab == 'History' }]">
                <a href="#" @click="tab = 'History'"> Detailed History </a>
              </li>
              <li v-if="tabMain == 'Company'" role="presentation" :class="[{ active: tab == 'History' }]">
                <a href="#" @click="tab = 'History'">Expense History </a>
              </li>
              <li role="presentation" :class="[{ active: tab == 'Info' }]">
                <a href="#" @click="tab = 'Info'"> Instructions </a>
              </li>
            </ul>

            <div class="tab-content">
              <div v-if="tab == 'Open'" :class="['card', { Main: tab == 'Open' }]">
                <div class="content" v-if="tabMain === 'Personal'">
                  <h5>Not Yet Approved</h5>
                  <tabulator-tiles-dynamic
                    :table-data="unapprovedInvoices"
                    :table-columns="tableColumnsNotYetApproved"
                    table-fit="fitColumns"
                    :table-condensed="false"
                    empty-text="(none)"
                    ref="openExpenses"
                    :max-table-height="'300px'"
                  />
                  <br /><br />
                  <h5>Approved for Payment</h5>
                  <tabulator-tiles-dynamic
                    :table-data="approvedInvoices"
                    :table-columns="tableColumnsApproved"
                    table-fit="fitColumns"
                    :table-condensed="false"
                    empty-text="(none)"
                    ref="openExpenses"
                    :max-table-height="'300px'"
                    :initial-sort="[{ column: 'Date_List1', dir: 'asc' }]"
                  />
                </div>
              </div>
              <div v-if="tab == 'ExpenseHistory'" :class="['card']">
                <div class="content" v-if="tabMain === 'Personal'">
                  <tabulator-tiles-dynamic
                    :table-data="filteredExpenseHistory"
                    :table-columns="tableColumnsExpenseHistory"
                    table-fit="fitColumns"
                    :table-condensed="false"
                    empty-text="(none)"
                    ref="openExpenses"
                    :max-table-height="'700px'"
                    :initial-sort="[{ column: 'Check_Date', dir: 'asc' }]"
                  />
                  <div class="row">
                    <div class="col-md-8"></div>
                    <div class="col-md-2">Boot expenses: {{ formatNumber(boot_spent, 1) }}</div>
                    <div class="col-md-2">Tool expenses: {{ formatNumber(tool_spend, 1) }}</div>
                  </div>
                </div>
              </div>
              <div v-if="tab == 'History'" :class="['card']">
                <div v-if="tabMain === 'Company'" class="row">
                  <div class="col-sm-4">
                    <div class="form-group" style="margin-left:15px">
                      <label>Month</label>
                      <select-field :options="monthsArray" :option-display-keys="['name']" :option-val="'value'" v-model="showHistoryMonth" :item-name="'Month'" />
                    </div>
                  </div>
                </div>

                <div class="content" v-if="tabMain === 'Personal'">
                  <tabulator-tiles-dynamic
                    :table-data="historicInvoices"
                    :table-columns="tableColumnsHistoric"
                    table-fit="fitColumns"
                    :table-condensed="false"
                    empty-text="(none)"
                    ref="openExpenses"
                    :max-table-height="'700px'"
                  />
                </div>
                <div class="content" v-if="tabMain === 'Company'">
                  <tabulator-tiles-dynamic
                    :table-data="filteredAllCompanyExpenses"
                    :table-columns="tableColumnsAllCompanyExpenses"
                    table-fit="fitColumns"
                    :table-condensed="false"
                    empty-text="(none)"
                    ref="openExpenses"
                    :max-table-height="'700px'"
                  />
                </div>
              </div>
              <div v-if="tab == 'Info'" :class="['card']">
                <div class="content">
                  <ul class="instruction-info">
                    <li>Use the Add Expenses button to submit personal expenses or company expenses attached to a Job, WO or vehicles.</li>
                    <li>Within the Add Expenses dialog, add one or more expense types using the Add Expense buttons, input required fields then attach receipts showing payment details.</li>
                    <li>Track re-embursement for personal expenses using the tables within the Open Invoices and History tabs.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>
<script>
import navTabs from 'mixins/navTabs'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import appFuncs from 'appFuncs'
import { mapGetters } from 'vuex'
import SelectField from 'components/UIComponents/SelectField'
import TableTiles from 'components/UIComponents/TableTiles'
import TabulatorTilesDynamic from 'components/UIComponents/TabulatorTilesDynamic'
import Alert from 'components/UIComponents/Alert'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import SetViewAsEmployee from 'components/Dashboard/Widgets/SetViewAsEmployee'

export default {
  data() {
    return {
      tabMain: 'Personal',
      tab: 'Open',
      vendorType: 'Personal',
      vendor: '',
      bankAccountCode: '',
      unapprovedInvoices: [],
      approvedInvoices: [],
      historicInvoices: [],
      employeesDetails: [],
      monthsArray: [],
      showHistoryMonth: null,
      alertMessage: '',
      expenseHistories: [],
      extra_boot_amount: 0,
      extra_tool_amount: 0,
      hire_date: '',
      allCompanyExpenses: []
    }
  },

  mixins: [navTabs],
  components: {
    TabulatorTable,
    SelectField,
    TableTiles,
    TabulatorTilesDynamic,
    Alert,
    WaitingSpinner,
    SetViewAsEmployee
  },

  computed: {
    ...mapGetters(['sherAuth', 'vendors', 'equipment', 'appOptions', 'viewAsEmployeeId']),

    boot_spent() {
      if (this.hire_date != '' && this.hire_date != null) {
        let amount = 0 // parseFloat(this.appOptions.boot_allowance) + this.extra_boot_amount ??
        let res = this.calculateCycleDates(this.hire_date, this.appOptions.expense_cycle_months)
        let start_date = moment(res.start_date).toDate()
        for (const ele of this.expenseHistories) {
          let trans_date = moment(ele.Trans_Date).toDate()
          if (trans_date >= start_date && ele.GL_Distribution_Account == 6084) {
            amount += ele.Payment_Amount
          }
        }
        return amount
      }
      return 0
    },
    tool_spend() {
      if (this.hire_date != '' && this.hire_date != null) {
        let amount = parseFloat(this.appOptions.tool_allowance) + this.extra_tool_amount
        let res = this.calculateCycleDates(this.hire_date, this.appOptions.expense_cycle_months)
        let start_date = moment(res.start_date).toDate()
        for (const ele of this.expenseHistories) {
          let trans_date = moment(ele.Trans_Date).toDate()
          if (trans_date >= start_date && ele.GL_Distribution_Account == 6081) {
            amount -= ele.Payment_Amount
          }
        }
        return amount
      }
      return 0
    },
    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },

    tableColumnsNotYetApproved() {
      return [
        {
          title: 'Transaction Date',
          field: 'Transaction_Date',
          width: 200,
          responsive: 0,
          formatter: cell => {
            let date = cell.getValue()
            return this.formatDate(date)
          },
          hideName: (field, value) => false
        },
        // {
        //   title: 'Transaction Date',
        //   field: 'Transaction_Date_Time',
        //   width: 200,
        //   responsive: 0
        // },
        {
          title: 'Vendor',
          field: 'Vendor_Code',
          width: 200,
          responsive: 0,
          formatter: cell => {
            let vendorCode = cell.getValue()
            let vendor = this.vendors.find(itm => itm.Vendor_Code == vendorCode)
            return vendor ? vendor.Vendor_Name : vendorCode
          },
        },
        {
          title: 'Amount',
          field: 'Debit_Amount',
          width: 100,
          responsive: 0,
          formatter: cell => {
            let amt = cell.getValue()
            return this.formatNumber(amt, 1)
          },
          displayType: 'lowerRight',
          hideName: (field, val) => true
        },
        {
          title: 'Job Number',
          field: 'Job_Number',
          width: 120,
          responsive: 0,
          hideName: (field, val) => false
        },
        {
          title: 'Job Phase',
          field: 'Phase_Code',
          width: 120,
          responsive: 0,
          hideName: (field, val) => false
        },
        {
          title: 'Equipment',
          field: 'Equipment_Code',
          width: 250,
          responsive: 0,
          hideName: (field, val) => false,
          formatter: cell => {
            let equipmentCode = cell.getValue()
            let equipment = this.equipment.find(itm => itm.Code == equipmentCode)
            return equipment ? equipment.Name : equipmentCode
          }
        },
        {
          title: 'Remarks',
          field: 'Remarks',
          width: 300,
          responsive: 2,
          displayType: 'header',
          hideName: (field, val) => true
        },
        {
          title: 'Invoice Number',
          field: 'invoice_number',
          width: 200,
          responsive: 0,
          hideName: (field, val) => false
        }
      ]
    },

    tableColumnsApproved() {
      return [
        {
          title: 'Vendor',
          field: 'Vendor_Code',
          width: 200,
          responsive: 0,
          formatter: cell => {
            let vendorCode = cell.getValue()
            let vendor = this.vendors.find(itm => itm.Vendor_Code == vendorCode)
            return vendor ? vendor.Vendor_Name : vendorCode
          },
          hideName: (field, val) => false
        },
        {
          title: 'Amount',
          field: 'Invoice_Amount',
          width: 100,
          responsive: 0,
          formatter: cell => {
            let amt = cell.getValue()
            return this.formatNumber(amt, 1)
          },
          displayType: 'lowerRight',
          hideName: (field, val) => true
        },
        {
          title: 'Invoice Date',
          field: 'Date_List1',
          width: 150,
          responsive: 0,
          formatter: cell => {
            let date = cell.getValue()
            return this.formatDate(date)
          },
          hideName: (field, val) => false
        },
        // {
        //   title: 'Date List 2',
        //   field: 'Date_List2',
        //   width: 150,
        //   responsive: 0,
        //   formatter: (cell) => {
        //     let date = cell.getValue();
        //     return this.formatDate(date);
        //   }
        // },
        // {
        //   title: 'Date List 3',
        //   field: 'Date_List3',
        //   width: 150,
        //   responsive: 0,
        //   formatter: (cell) => {
        //     let date = cell.getValue();
        //     return this.formatDate(date);
        //   }
        // },
        // {
        //   title: 'Date List 5',
        //   field: 'Date_List5',
        //   width: 150,
        //   responsive: 0,
        //   formatter: (cell) => {
        //     let date = cell.getValue();
        //     return this.formatDate(date);
        //   }
        // },
        {
          title: 'Remarks',
          field: 'Remarks',
          width: 300,
          responsive: 2,
          displayType: 'header',
          hideName: (field, val) => true
        },
        {
          title: 'Invoice Number',
          field: 'Invoice_Number',
          width: 200,
          responsive: 0
        }
      ]
    },

    tableColumnsHistoric() {
      return [
        {
          title: 'Vendor',
          field: 'vendor_code',
          width: 200,
          responsive: 0,
          formatter: cell => {
            let vendorCode = cell.getValue()
            let vendor = this.vendors.find(itm => itm.Vendor_Code == vendorCode)
            return vendor ? vendor.Vendor_Name : vendorCode
          },
          hideName: (field, val) => false
        },
        {
          title: 'Payment Amount',
          field: 'Payment_Amount',
          width: 150,
          responsive: 0,
          formatter: cell => {
            let amt = cell.getValue()
            return this.formatNumber(amt, 1)
          },
          displayType: 'lowerRight',
          hideName: (field, val) => true
        },
        {
          title: 'Check Date',
          field: 'Check_Date',
          width: 150,
          responsive: 0,
          formatter: cell => {
            let date = cell.getValue()
            return this.formatDate(date)
          },
          hideName: (field, val) => false
        },
        {
          title: 'Trans Date',
          field: 'Check_Date',
          width: 150,
          responsive: 0,
          formatter: cell => {
            let date = cell.getValue()
            return this.formatDate(date)
          },
          hideName: (field, val) => false
        },
        {
          title: 'Remarks',
          field: 'Remarks',
          width: 300,
          responsive: 2,
          displayType: 'header',
          hideName: (field, val) => true
        },
        {
          title: 'Invoice Number',
          field: 'invoice_number',
          width: 200,
          responsive: 0,
          hideName: (field, val) => false
        }
      ]
    },

    tableColumnsAllCompanyExpenses() {
      return [
        {
          title: 'Vendor',
          field: 'Vendor_Code',
          width: 200,
          responsive: 0,
          formatter: cell => {
            let vendorCode = cell.getValue()
            let vendor = this.vendors.find(itm => itm.Vendor_Code == vendorCode)
            return vendor ? vendor.Vendor_Name : vendorCode
          },
          hideName: (field, val) => false
        },
        {
          title: 'Invoice Amount',
          field: 'Payment_Amount',
          width: 150,
          responsive: 0,
          formatter: cell => {
            let amt = cell.getValue()
            return this.formatNumber(amt, 1)
          },
          displayType: 'lowerRight',
          hideName: (field, val) => true
        },
        {
          title: 'Entered Date',
          field: 'Date_Entered',
          width: 150,
          responsive: 0,
          formatter: cell => {
            let date = cell.getValue()
            return this.formatDate(date)
          },
          hideName: (field, val) => false
        },
        {
          title: 'Remarks',
          field: 'Remarks',
          width: 300,
          responsive: 2,
          displayType: 'header',
          hideName: (field, val) => true
        },
        {
          title: 'Invoice Number',
          field: 'Invoice_Number',
          width: 200,
          responsive: 0,
          hideName: (field, val) => false
        }
      ]
    },

    employeeDetails() {
      let employeeId = this.viewAsEmployeeId ? this.viewAsEmployeeId : this.sherAuth.eid
      let emps = this.employeesDetails.find(itm => itm.Employee_Code == employeeId)
      return emps
    },

    filteredAllCompanyExpenses() {
      if (this.showHistoryMonth) {
        return this.allCompanyExpenses.filter(itm => {
          return itm.Check_Month === this.showHistoryMonth
        })
      } else {
        return this.allCompanyExpenses
      }
    },

    tableColumnsExpenseHistory() {
      return [
        {
          title: 'Invoice Number',
          field: 'invoice_number',
          width: 150,
          responsive: 0,
          displayType: 'lowerRight',
          hideName: (field, val) => true
        },
        {
          title: 'Remarks',
          field: 'Remarks',
          width: 300,
          responsive: 0,
          displayType: 'lowerRight',
          hideName: (field, val) => true
        },
        {
          title: 'Expense Type',
          field: 'expense_type',
          width: 150,
          responsive: 0,
          displayType: 'lowerRight',
          hideName: (field, val) => true
        },
        {
          title: 'Check Date',
          field: 'Check_Date',
          width: 150,
          responsive: 0,
          formatter: cell => {
            let date = cell.getValue()
            return this.formatDate(date)
          },
          hideName: (field, val) => false
        },
        {
          title: 'Trans Date',
          field: 'Check_Date',
          width: 150,
          responsive: 0,
          formatter: cell => {
            let date = cell.getValue()
            return this.formatDate(date)
          },
          hideName: (field, val) => false
        },
        {
          title: 'Payment Amount',
          field: 'Payment_Amount',
          width: 150,
          responsive: 0,
          formatter: cell => {
            let amt = cell.getValue()
            return this.formatNumber(amt, 1)
          },
          displayType: 'lowerRight',
          hideName: (field, val) => true
        }
      ]
    },
    filteredExpenseHistory() {
      let elements = []
      for (let ele of this.expenseHistories) {
        ele.expense_type = ele.GL_Distribution_Account == 6084 ? 'Boot Allowance' : 'Tool Allowance'
        elements.push(ele)
      }
      return elements
    }
  },

  methods: {
    getMonthsArray() {
      var currentDate = new Date() // Get the current date
      var monthsArray = [] // Initialize the array

      for (var i = 0; i < 60; i++) {
        // Iterate over 60 months (5 years)
        var month = currentDate.getMonth() + 1 // Get the month (adding 1 because getMonth() returns 0-11)
        var year = currentDate.getFullYear() // Get the year

        // Format the value as 'yyyy-mm'
        var formattedValue = year + '-' + (month < 10 ? '0' + month : month)

        // Create an object with 'name' and 'value' properties
        var monthObject = {
          name:
            new Date(year, month - 1, 1).toLocaleString('default', {
              month: 'long'
            }) +
            ' ' +
            year,
          value: formattedValue
        }

        // Add the object to the array
        monthsArray.push(monthObject)

        // Move to the previous month
        currentDate.setMonth(currentDate.getMonth() - 1)
      }

      this.monthsArray = monthsArray
    },

    getEmployeeUdfDetails() {
      return new Promise((resolve, reject) => {
        var params = {
          action: 'get_employee_app_data'
        }

        this.$bus.$emit('setWaiting', {
          name: params.action,
          message: 'Getting Employee details'
        })
        appFuncs
          .shRequest(params)
          .then(data => {
            let employeesDetails = []
            for (let i = 0; i < data.length; i++) {
              employeesDetails.push({
                Employee_Code: data[i].employee_code,
                vendor_code: data[i].default_vendor_code,
                corp_cc_code: data[i].corp_cc_code,
                sub_cc_code: data[i].sub_cc_code,
                default_vehicle: data[i].default_vehicle,
                hire_date: data[i].hire_date
              })
            }
            this.employeesDetails = employeesDetails
            this.$bus.$emit('stopWaiting', params.action)
            resolve()
          })
          .catch(e => {
            this.$bus.$emit('stopWaiting', params.action)
            reject()
          })
      })
    },

    /*
      - Personal
        - Unapproved uses the employee's vendor code eg MIKEN
        - Approved uses the employee's vendor code eg MIKEN
        - History uses the employee's vendor code eg MIKEN
    */

    getInvoicesForApproval() {
      // aka unapproved
      this.alertMessage = ''
      var params = {
        action: 'get_invoices_for_approval'
      }

      if (!this.vendor) {
        this.alertMessage = 'You do not have a vendor code assigned to your user profile, please contact the office for assistance.'
        this.unapprovedInvoices = []
        return
      }
      params.vendor_code = this.vendor


      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Getting invoices'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          data = Array.isArray(data) ? data : []
          data = data.map(itm => {
            return {
              ...itm,
              Transaction_Date_Time: new Date(itm.Transaction_Date).getTime() // Concatenate for a sortable, dashless datetime
            }
          })

          data.sort((a, b) => {
            return a.Transaction_Date_Time < b.Transaction_Date_Time ? 1 : -1
          })

          this.unapprovedInvoices = data
        })
        .catch(e => {
          this.$snack.open(e.message || 'Problem getting invoices', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    getInvoicesApproved() {
      this.alertMessage = ''
      var params = {
        action: 'get_invoices_approved',
        vendor_code: this.tabMain === 'Personal' ? this.vendor : this.bankAccountCode
      }
      if (!params.vendor_code) {
        this.alertMessage = 'You do not have a vendor assigned to your user profile, please contact the office for assistance.'
        this.approvedInvoices = []
        return
      }
      this.$bus.$emit('setWaiting', {
        name: params.actions,
        message: 'Getting invoices'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.approvedInvoices = Array.isArray(data) ? data : []
        })
        .catch(e => {
          this.$snack.open(e.message || 'Problem getting invoices', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.actions)
        })
    },

    getPaymentHistory() {
      // only used for personal

      this.alertMessage = ''
      if (!this.vendor) {
        this.alertMessage = 'You do not have a vendor code assigned to your user profile, please contact the office for assistance.'
        this.historicInvoices = []
        return
      }
      var params = {
        action: 'get_payment_history',
        vendor_code: this.vendor
      }

      this.$bus.$emit('setWaiting', {
        name: params.actions,
        message: 'Getting invoices'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.historicInvoices = Array.isArray(data) ? data : []
        })
        .catch(e => {
          this.$snack.open(e.message || 'Problem getting invoices', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.actions)
        })
    },

    getAllCompanyExpenses() {
      this.alertMessage = ''
      var params = {
        action: 'get_all_company_expenses',
        employee_code: this.sherAuth.eid
      }
      this.$bus.$emit('setWaiting', {
        name: params.actions,
        message: 'Getting invoices'
      })
      appFuncs
        .shRequest(params)
        .then(data => {

          let expenses = Array.isArray(data) ? data : []
          this.allCompanyExpenses = expenses.map(item => {
            const checkDate = new Date(item.Date_Entered)
            const year = checkDate.getFullYear()
            const month = String(checkDate.getMonth() + 1).padStart(2, '0')
            const day = String(checkDate.getDate()).padStart(2, '0')
            item.Check_Month = `${year}-${month}` //-${day}`;
            return item
          })
        })
        .catch(e => {
          this.$snack.open(e.message || 'Problem getting company expenses', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.actions)
        })
    },

    getVendors() {
      this.$bus.$emit('setWaiting', {
        name: 'getVendors',
        message: 'Getting Vendors'
      })
      this.$store
        .dispatch('getVendors')
        .then(() => {})
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getVendors')
        })
    },

    getInvoices() {
      switch (this.tab) {
        case 'Open':
          this.getInvoicesForApproval()
          this.getInvoicesApproved()
          break
        case 'History':
          this.getPaymentHistory()
          break
        case 'ExpenseHistory':
          this.getExpenseHistory()
          break
      }
    },

    addExpense() {
      this.$router.push({ path: '/add-expense' })
      return

      this.$Modal({
        parent: this,
        name: 'AddExpense', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl..
        hideClose: false,
        component: () => import('components/Dashboard/Expenses/AddExpense.vue')
      })
    },

    getExpenseHistory() {
      // only used for personal

      this.alertMessage = ''
      if (!this.vendor) {
        this.alertMessage = 'You do not have a vendor code assigned to your user profile, please contact the office for assistance.'
        this.historicInvoices = []
        return
      }
      var params = {
        action: 'get_payment_history2',
        vendor_code: this.vendor
      }

      this.$bus.$emit('setWaiting', {
        name: params.actions,
        message: 'Getting invoices'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.expenseHistories = Array.isArray(data) ? data : []
        })
        .catch(e => {
          this.$snack.open(e.message || 'Problem getting invoices', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.actions)
        })
    },

    async getEmployeeData() {
      let data = await appFuncs.shRequest({
        action: 'get_employee_app_data',
        employee_code: this.$store.getters.userAuthedData['eid']
      })
      if (data.extra_boot_allowance != null) {
        this.extra_boot_amount = data.extra_boot_allowance
      }
      if (data.extra_tool_allowance != null) {
        this.extra_tool_amount = data.extra_tool_allowance
      }
    },

    async getData() {
      let vendors = this.getVendors()
      let equipment = this.$store.dispatch('getEquipment')
      let empUDFs = this.getEmployeeUdfDetails()
      let appOptions = this.$store.dispatch('getAppOptions', 1)

      Promise.all([vendors, equipment, empUDFs, appOptions]).then(() => {
        this.vendor = this.employeeDetails.vendor_code
        this.hire_date = this.employeeDetails.hire_date
        this.bankAccountCode = this.employeeDetails.sub_cc_code
        this.getInvoices()
      })
    }
  },

  async mounted() {
    this.getMonthsArray()
    this.showHistoryMonth = this.monthsArray[0]['value']

    await this.getData();

    this.$bus.$on('added-expense', this.getInvoices)
    this.getEmployeeData()
  },

  watch: {
    tabMain: {
      immediate: false,
      handler(newVal, oldVal) {
        if (this.tabMain === 'Company') {
          this.tab = 'History';
          this.getAllCompanyExpenses()
        } else {
          this.getInvoices()
        }
      }
    },
    tab: {
      immediate: false,
      handler(newVal, oldVal) {
        if (this.tabMain === 'Personal') {
          this.getInvoices()
        }
      }
    },
    viewAsEmployeeId() {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  h5 {
    margin: 10px 8px;
  }
}

.instruction-info {
  max-width: 1000px;
  padding: 20px 40px;

  li {
    padding: 10px 0;
  }
}
</style>
