<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="tab-section outline white">
          <ul class="nav nav-tabs">
            <li role="presentation" :class="[{ active: tab == 'Statement Setup' }]">
              <a href="#" @click="tab = 'Statement Setup'"> Statement Setup </a>
            </li>
            <li role="presentation" :class="[{ active: tab == 'Routing Code' }]">
              <a href="#" @click="tab = 'Routing Code'"> Routing Code </a>
            </li>
          </ul>
          <div :class="['tab-content', { 'first-active': tab === 'Statement Setup' }]">
            <div class="tab-content-inner">
              <div v-if="tab == 'Statement Setup'">
                <div class="content">
                  <h4>
                    <strong>{{ fields.Name }}</strong>
                  </h4>
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="form-group">
                        <label>Last Statement Sent</label>
                        <p v-if="fields.last_sent">
                          {{ fields.last_sent }}
                        </p>
                        <p v-else>
                          <i>none</i>
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="form-group">
                        <span class="inline-checkbox">
                          <checkbox2 v-model="fields.disable_auto_send" @change="changed = true" :true-val="1" :false-val="0"> Disable Customer Report Auto Send </checkbox2>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="form-group">
                        <label>Default AR Contacts</label>
                        <select-field-multi
                          :options="contacts"
                          :option-display-keys="['Name']"
                          :selected-display-keys="['Email']"
                          :option-val="'sfId'"
                          v-model="fields.ar_contacts_default"
                          :placeholder-text="'Add contact'"
                          :preselect-single-option="false"
                          @input="changed = true"
                          :allow-add-simple-item="true"
                          item-name="Email Address"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="form-group">
                        <label>Extra Contacts (manual send only)</label>
                        <select-field-multi
                          :options="contacts"
                          :option-display-keys="['Name']"
                          :selected-display-keys="['Email']"
                          :option-val="'sfId'"
                          v-model="fields.ar_contacts_extra"
                          :placeholder-text="'Add contact'"
                          :preselect-single-option="false"
                          @input="changed = true"
                          :allow-add-simple-item="true"
                          item-name="Email Address"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Custom Text (manual send only)</label>
                    <textarea
                      @change="changed = true"
                      class="form-control"
                      :value="decode(fields.custom_text)"
                      @input="fields.custom_text = encode($event.target.value, 'stripTags')"
                      rows="6"
                      placeholder="Leave empty to use default text."
                    />
                  </div>

                  <button class="btn" @click="saveSettings">Save Settings</button>

                  <a v-if="previewLink" class="btn" @click="preview" :href="previewLink" target="_blank" :disabled="changed"> Preview Report </a>

                  <button class="btn" @click="send" :disabled="changed">Send Report Now</button>

                  <button class="btn" @click="download">Download Report</button>
                </div>
              </div>
              <div v-if="tab == 'Routing Code'">
                <div class="">
                  <h5>
                    Accounts Receivable (Billing) Routing Code
                  </h5>
                  <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Routing Code</label>
                        <select-field
                          :options="billingRoutingCodes"
                          :option-display-keys="['routing_code', 'routing_description']"
                          :option-val="'routing_code'"
                          v-model="customer.billing_routing_code"
                          placeholder-text="Assign Routing Code"
                          :label="'Routing Code'"
                          :disabled="!isCaptain && !isAdmin"
                        />
                      </div>
                    </div>
                  </div>
                  <button class="btn" @click="updateAppCustomer">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import moment from 'moment'
import SelectFieldMulti from 'components/UIComponents/SelectFieldMulti'
import SelectField from 'components/UIComponents/SelectField'
import appFuncs from 'appFuncs'
import Checkbox2 from 'components/UIComponents/Checkbox'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {
      // set from customers obj on load
      fields: {
        billing_routing_code: null
      },
      contacts: [],
      previewLink: null,
      changed: false,
      tab: 'Statement Setup',
      billingRoutingCodes: []
    }
  },

  components: {
    SelectFieldMulti,
    Checkbox2,
    WaitingSpinner,
    SelectField
  },

  props: {
    id: {
      type: [String, Number, null],
      default: null
    },

    customer: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  computed: {
    ...mapGetters(['customerContacts']),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },
    isPM() {
      return this.$store.getters.userAuthedData.user_role.indexOf('pm') !== -1
    },
    isCaptain() {
      return this.$store.getters.userAuthedData.user_role.indexOf('captain') !== -1
    }
  },

  methods: {
    getStatementLink() {
      let params = {
        action: 'get_ar_report_link',
        customerCode: this.fields.Customer_Code
      }
      this.$bus.$emit('setWaiting', { name: params.action, message: 'Fetching Preview Link' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.previewLink = `${this.$store.getters.urls.frontend}#/ar-statement/${data}?manualSend=1`
        })
        .catch(res => {
          this.$snack.open(res.message || 'Problem getting preview link', 'warning')
          console.log(res)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    saveSettings(callback) {
      let params = {
        action: 'accounts_receivable_customer_entry_update',
        customerCode: this.fields.Customer_Code,
        contactsExtra: this.fields.ar_contacts_extra && this.fields.ar_contacts_extra.length ? this.fields.ar_contacts_extra : null,
        contactsDefault: this.fields.ar_contacts_default && this.fields.ar_contacts_default.length ? this.fields.ar_contacts_default : null,
        customText: this.fields.custom_text,
        disableAutoSend: this.fields.disable_auto_send
      }
      this.$bus.$emit('setWaiting', { name: params.action, message: 'Saving settings' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.$snack.open('Settings saved')
          this.$store.dispatch('getCustomerSettingsAR', 'refresh').then(() => {
            this.$bus.$emit('arSettingsUpdated')
            this.changed = false
          })
          if (typeof callback === 'function') {
            callback()
          }
        })
        .catch(res => {
          this.$snack.open(res.message || 'Problem saving settings', 'error')
          console.log(res)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    preview() {},

    send() {
      if (!this.fields.ar_contacts_default || !this.fields.ar_contacts_default.length) {
        if (!confirm('No customer contacts added, send to Sheridan only?')) {
          return
        }
      } else if (!confirm('Confirm report send?')) {
        return
      }

      let params = {
        action: 'send_ar_statement',
        customerCode: this.fields.Customer_Code,
        manualSend: true
      }
      this.$bus.$emit('setWaiting', { name: params.action, message: 'Sending statement' })
      appFuncs
        .shRequest(params)
        .then(data => {
          if (data.pdfRes && data.pdfRes.status !== 'success') {
            this.$snack.open(data.pdfRes.message || 'Problem creating PDF', 'error')
          }

          if (data.emailRes) {
            if (data.emailRes.status === 'success') {
              this.$snack.open('Sent successfully')
            } else {
              this.$snack.open(data.emailRes.message || 'Problem emailing statement, please check contact details or try again later.', 'error')
            }
          }

          // refresh to get last sent data
          this.$store.dispatch('getCustomerSettingsAR', 'refresh').then(() => {
            this.$bus.$emit('arSettingsUpdated')
          })
        })
        .catch(res => {
          this.$snack.open(res.message || 'Problem sending statement', 'error')
          console.log(res)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    download() {
      this.$Modal({
        parent: this,
        name: 'DownloadARReport', // used for closing specific modal programmatically
        size: 'sm', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/AccountsReceivable/DownloadARReport'),
        contentClasses: 'smoke-bg',
        props: {
          customerCode: this.fields.Customer_Code
        }
      })
    },

    getBillingRoutingCodes() {
      var params = {
        action: 'get_routing_codes'
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Getting Billing Routing Codes' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.billingRoutingCodes = data
        })
        .catch(err => {
          console.log(err)
          snack.open(err.message || 'Problem fetching billing routing codes', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    updateAppCustomer() {
      if (!this.customer.Customer_Code) return

      const params = {
        action: 'upsert_customer_app_data',
        data: {
          Customer_Code: this.customer.Customer_Code,
          billing_routing_code: this.customer.billing_routing_code
        }
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Updating Customer Data' })

      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Updating Customer Data'
      })
      appFuncs
        .shRequest(params)
        .then(data => {

        })
        .catch(err => {
          console.log(err)
          this.$snack.open(err.message || 'Problem updating Customer', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    }
  },

  mounted() {
    if (!this.customer.id) {
      // fetch all data and set obj
    }

    this.fields = this.customer

    // set contacts for this customer
    this.$store.dispatch('getCustomerContacts').then(() => {
      let cc = this.fields.Customer_Code || null
      if (!cc) {
        this.contacts = this.customerContacts
      } else {
        this.contacts = this.customerContacts.filter(itm => {
          return itm.Account_Customer_Code__c == cc
        })
      }
    })
    this.getBillingRoutingCodes()
    this.getStatementLink()

  }
}
</script>
<style lang="scss" scoped></style>
