
<div class="signature-container">
  <div v-if="step === 1">
    <div class="tab-section outline white border-bottom">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{ active: newContact === true }]">
          <a href="#" @click="newContact = true"> New Contact </a>
        </li>
        <li role="presentation" :class="[{ active: newContact === false }]">
          <a href="#" @click="newContact = false"> Existing Contact </a>
        </li>
      </ul>
    </div>

    <div :class="['card']">
      <div class="content tab-content border">
        <div v-if="newContact">
          <div class="row">
            <div class="form-group col-md-6">
              <label>Full Name<span class="required-star">*</span></label>
              <input class="form-control" type="text" v-model="newSignName" :placeholder="'First and Last Name'" autocomplete="off" />
            </div>
            <div class="form-group col-md-6">
              <label>Email Address<span v-if="emailSend" class="required-star">*</span></label>
              <input class="form-control" type="text" v-model="newSignEmail" :placeholder="'name@example.com'" autocomplete="off" />
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="form-group col-md-6">
              <label>Full Name<span class="required-star">*</span></label>
              <select-field :options="customerContacts" :option-display-keys="['Name']" :option-val="'Id'" :option-seperator="' '" v-model="selectedContactId" :disabled="false" />
            </div>
            <div class="form-group col-md-6">
              <label>Email Address<span v-if="emailSend" class="required-star">*</span></label>
              <input class="form-control Xfake-readonly" type="text" Xreadonly v-model="signEmail" :placeholder="'name@example.com'" autocomplete="off" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <checkbox v-model="emailSend" true-value="true">
              <span>Email Completed PDF</span>
            </checkbox>
          </div>
        </div>

        <button class="btn" @click="step = 2" :disabled="!formValid">Next</button>
      </div>
    </div>
  </div>

  <div v-show="step === 2" class="signature-pad-fixed">
    <div class="signature-pad-container">
      <p>Please draw signature with finger or by holding left mouse button.</p>
      <canvas id="signature-pad" />
      <br />
      <button class="btn" @click="step = 1">Prev</button>
      <button class="btn danger" @click="clearSignature">Clear</button>

      <button class="btn" @click="saveSignature">Finish</button>
    </div>
  </div>

  <waiting-spinner :wait="wait" />
</div>
