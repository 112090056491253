
<div>
  <simple-header />
  <div class="materials-admin material-lists-component">
    <div class="row">
      <div class="col-sm-12 col-md-9">
        <material-category-train :category-current="categoryCurrent" @materialSelectCategory="selectCategory($event)" />
      </div>

      <div class="col-sm-12 col-md-3" v-if="1 == 2">
        <span class="inline-checkbox" style="float: right">
          <checkbox2 v-model="showOneTimeMaterials" @change="getMaterials()"> Show One-Time Materials</checkbox2>
        </span>
      </div>

      <div class="col-sm-12">
        <div class="category-table-container">
          <materials-category-table :ref="'matCatTable'" @selectCategory=";(categoryCurrent = $event), getMaterials()" :edit-categories="true" label="Subcategories" item-type="oc" />

          <div class="info-section visible-lg">
            <h4>Table Actions:</h4>
            <ul>
              <!-- <li><i class="fa fa-search" />Search for Material</li> -->
              <!-- <li><i class="fa fa-thumbtack" />Pin materials to a list</li> -->
              <li><i class="fa fa-pen" v-if="isMaterialsAdmin" />Row edit mode</li>
              <li><i class="fa fa-check-square" />Row select mode</li>
              <li><i class="fa fa-ban" />Stop edit or row select mode</li>
              <li v-if="isMaterialsAdmin"><i class="fa fa-exchange-alt" />Change category</li>
              <!-- <li><i class="fa fa-trash" />Delete selected materials</li> -->
            </ul>

            <h4>Actions:</h4>
            <ul>
              <li><i class="icon-dollar-file-add" />Add price entry</li>
              <li><i class="icon-dollar-files-show" />Show price entries</li>
              <li v-if="isMaterialsAdmin"><i class="ti ti-pencil" />Show edit fields</li>
            </ul>
          </div>

          <div>
            <button class="btn" @click="addMaterial()" v-html="'Add Other Charge'" v-if="isMaterialsAdmin" />

            <!-- <button
              class="btn"
              @click="showMaterialImport"
              v-html="'Import Materials'"
              v-if="isMaterialsAdmin"
            /> -->
            <!-- <button
              class="btn"
              @click="triggerExportCSV"
              v-html="'Accubid Export'"
            /> -->
          </div>
        </div>

        <div class="material-table">
          <div class="material-table-inner">
            <div class="row">
              <div class="col-sm-12 col-md-8">
                <!-- <div class="material-search-container">
                  <material-search
                    overlay
                    @selectMaterial="selectMaterial"
                  />
                </div> -->
              </div>
              <div class="col-sm-12 col-md-4">
                <div class="material-table-options">
                  <div class="edit-mode-container">
                    <!-- <span class="is-link" @click="showMaterialSearch()" title="Material Search">
                      <i class="fa fa-search" />
                    </span> -->
                    <span v-if="selectingMaterials">
                      <span class="is-link" @click="selectingMaterials = false" title="Cancel Select Materials">
                        <i class="fa fa-ban" />
                      </span>
                      <span class="is-link" @click="moveMaterials()" title="Move Materials" v-if="isMaterialsAdmin">
                        <i class="fa fa-exchange-alt" />
                      </span>
                      <!-- <span class="is-link" @click="deleteMaterials()" title="Delete Materials">
                        <i class="fa fa-trash" />
                      </span> -->
                    </span>
                    <span v-else>
                      <span class="is-link" v-if="!inlineEditable && isMaterialsAdmin" @click="updateInlineEditing(true)" title="Enable Inline Editing">
                        <i class="fa fa-pen" />
                      </span>
                      <span class="is-link" v-else-if="isMaterialsAdmin" @click="updateInlineEditing(false)" title="Disable Inline Editing">
                        <i class="fa fa-ban" />
                      </span>
                      <span class="is-link" @click="selectingMaterials = true" title="Select Multiple Materials">
                        <i class="fa fa-check-square" />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <tabulator-table
              ref="materialTable"
              :table-data="currentMaterials"
              :table-columns="materialTableColumns"
              @updateCol="updateMaterialCol"
              :allow-new-row="false"
              :inline-edit-row-validate="['brief_description']"
              @colClick="materialClick"
              :xtable-height="500"
              :max-table-height="1500"
              :table-striped="true"
              :table-condensed="false"
              :selectable="selectingMaterials"
              :row-formatter="materialRowFormatter"
            />
          </div>

          <br />
        </div>
      </div>
    </div>

    <div v-html="downloadForm" />

    <waiting-spinner :wait="wait" />
  </div>
</div>
