<template>
  <div class="bh-table-container">
    <div v-if="!hideSearch" class="form-group">
      <input type="text" :class="['form-control', 'search-all', { 'not-empty': filterAll }]" v-model="filterAll" placeholder="Filter" @input="checkEmitEmptySearch" />
    </div>
    <div class="shtable-table shtable-show table-responsive show-scrollbars">
      <table :class="tableClasses" :style="[{ tableLayout: tableSettings.tableLayout }]">
        <thead>
          <tr class="header-row">
            <th v-if="preFilteredShow(key)" v-for="(field, key) in fields" @click="sortColumns(key, field)" :class="[field.name, key, fields[key]['class']]" :style="fields[key]['style']">
              <span v-html="field.name !== null ? field.name : key" />
              <i v-if="sortCol == key && !sortColReverse" class="ti-angle-up" />
              <i v-else-if="sortCol == key" class="ti-angle-down" />
            </th>
            <th v-if="!hideItemEdit && !noItemEdit" class="edit-col">
              <!-- edit col -->
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr class="filter-row" v-if="showColFilter">
        <td v-for="(field, key) in fields" v-if="filterRow && preFilteredShow(key) && !field['noFilter']">
          <input type="text" v-model="shtableFilter[key]" placeholder="Filter">
        </td>
        <td v-else-if="!preFiltered[key]" class="empty" />
        <td v-if="!hideItemEdit && !noItemEdit" class="edit-col">
        </td>
      </tr> -->
          <tr v-if="shItem.id || shItem.id === 0" v-for="(shItem, shk) in filteredShItems" :key="shk" :data-row-id="shItem.id" :class="['shItem', { editable: !noItemEdit }, shItem['class']]">
            <!-- show each item - if key is in prefiltered vals array -->
            <td
              v-for="(field, key) in fields"
              v-if="preFilteredShow(key)"
              :data-column-name="key"
              :class="[{ wide: fields[key]['wide'] }, key, fields[key]['class']]"
              :key="key"
              :style="[fields[key]['style'], { position: 'relative' }]"
            >
              <!-- item to trigger custom action -->
              <a
                v-if="shItem[key] && shItem[key]['action']"
                @click="customAction(shItem[key]['action'], shItem, $event)"
                v-html="shItem[key]['title']"
                :class="['linked-item', { centered: shItem[key]['centered'] }]"
              />

              <span v-else-if="field.type == 'checkbox'">
                <checkbox v-on:change="checkboxUpdate(shItem.id, key, $event)" :checked="shItem[key] == true ? true : false" />
              </span>

              <!-- if normal item -->
              <span
                v-else-if="!noItemEdit"
                :class="['editable', { centered: fields[key]['centered'] }, { 'empty-content': !shItem[key] }]"
                :data-id="shItem.id"
                @click.exact="(editRow(shItem.id, shItem), colClick(key, shItem))"
                v-on:click.alt="editRow(shItem.id, shItem, true)"
                v-html="showFormatted(key, shItem)"
                :title="'Click to edit'"
              />

              <!-- no editable item -->
              <span v-else v-html="showFormatted(key, shItem)" />
              <!-- <span style="position:absolute;top:0;height:100%;width:100%;" /> -->
            </td>

            <td v-if="!hideItemEdit && !noItemEdit && !shItem['noItemEdit']" class="edit-col">
              <span @click="editRow(shItem.id, shItem)">
                <i class="ti-pencil" />
              </span>
            </td>
            <!-- <td v-else /> not sure what this was here for -->
          </tr>
          <tr class="empty-row" v-if="!filteredShItems || !filteredShItems[0]">
            <td>No results</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-if="!noItemEdit && !hideTip" class="table-tip">Tip: Click rows to open details.</p>
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'

export default {
  props: {
    tableSettings: {
      type: Object,
      required: true
    },
    rowClick: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      urls: store.getters.urls,
      shItems: [],
      shtableFilter: {},
      filterAll: this.tableSettings.filterAll || '',
      customLink: this.tableSettings.customLink || false,
      hasPreFilter: false,
      filterRow: true,
      phone_keys: ['Phone Number', 'Phone_Number', 'Direct Line', 'Mobile', 'mobile_phone', 'MobilePhone', 'general_phone', 'direct_phone', 'Employee_Mobile_Phone', 'Employee_Extension', 'Phone'],
      sortCol: '',
      sortColReverse: false,
      eventActions: [],
      filteredShItems: [],
      filterAllEmptyStarted: false
    }
  },

  computed: {
    preFiltered() {
      var preFiltered = ''

      // OLD WAY
      var modalVals = store.getters.modal.componentData
      if (modalVals && modalVals.preFiltered) {
        preFiltered = modalVals.preFiltered
      }

      // NEW way, picks up tableSetting prop set in Mounted
      if (this.tableSettings.preFiltered) {
        preFiltered = this.tableSettings.preFiltered
      }

      // convert prefiltered values into array
      var preFilteredArrVal = {}
      if (preFiltered) {
        this.hasPreFilter = 1
        for (var key in preFiltered) {
          if (!preFiltered.hasOwnProperty(key)) continue
          // if is array, just re-assign
          if (Array.isArray(preFiltered[key])) {
            preFilteredArrVal[key] = preFiltered[key]
            continue
          }
          // re-assign val as array itm
          preFilteredArrVal[key] = [preFiltered[key]]
        }
      }
      return preFilteredArrVal
    },

    fields() {
      var fields = this.tableSettings.fields
      var filteredFields = {}

      for (var key in fields) {
        // skip loop if the property is from prototype
        if (!fields.hasOwnProperty(key)) continue

        if (fields[key]['hiddenView'] && !this.enableEditing) {
          continue
        }
        filteredFields[key] = fields[key]
      }
      return filteredFields
    },

    showColFilter() {
      if (this.hideAllSearch) {
        return false
      }
      // put shtableFilters values into string
      let shtableFilters = Object.values(this.shtableFilter).join('')

      if (!shtableFilters && this.filteredShItems && this.filteredShItems.length < 10) {
        return false
      }
      return true
    },

    noItemEdit() {
      if (this.tableSettings.noItemEdit) {
        return true
      }
      return false
    },

    hideItemEdit() {
      if (this.tableSettings.hideItemEdit) {
        return true
      }
      return true
      return false
    },

    hideAllSearch() {
      if (this.tableSettings.hideAllSearch) {
        return true
      }
      return false
    },

    hideSearch() {
      if (this.tableSettings.hideSearch || this.hideAllSearch) {
        return true
      }
      return false
    },

    hideTip() {
      return this.tableSettings.hideTip
    },

    tableClasses() {
      return ['table', 'table-border', { editable: !this.noItemEdit }]
    }
  },

  methods: {
    preFilteredShow(key) {
      if (this.fields[key] && this.fields[key]['hidden']) {
        return false
      }

      if (!this.preFiltered[key]) {
        return true
      }

      if (this.preFiltered[key].length > 1) {
        return true
      }

      // if prefilter key value is not empty
      for (var i = this.preFiltered[key].length - 1; i >= 0; i--) {
        if (!this.preFiltered[key][i]) {
          return true
        }
      }
      return false
    },

    colClick(col, itm) {
      this.$emit('colClick', { col, itm })
    },

    filterShItems() {
      var self = this
      // var shItems = this.condenseItems();
      var shItems = this.tableSettings.tableData

      if (shItems && shItems.length) {
        for (var key in self.fields) {
          // skip loop if the property is from prototype
          if (!self.fields.hasOwnProperty(key)) continue
          if (self.fields[key]) {
            var field = self.fields[key]
            if (field) {
              // filtering for each column - partial matches allowed
              shItems = shItems.filter(function (item) {
                if (typeof self.shtableFilter[key] === 'undefined') return true

                if (typeof item[key] === 'undefined') {
                  // remove blank matches
                  return false
                }

                if (String(item[key]).toLowerCase().indexOf(String(self.shtableFilter[key]).toLowerCase()) !== -1) {
                  return true
                }
              })

              // filtering for preFiltered columns - no partial matches
              shItems = shItems.filter(function (item) {
                if (typeof item[key] !== 'undefined' && typeof self.preFiltered[key] !== 'undefined') {
                  // if prefilter item is empty array do not filter anything
                  if (!self.preFiltered[key].length) {
                    return true
                  }
                  for (var i = self.preFiltered[key].length - 1; i >= 0; i--) {
                    // return if prefiltered itm empty
                    if (!self.preFiltered[key][i]) {
                      return true
                    }

                    // if shItem prefiltered key val is array, check if contains filter val
                    if (Array.isArray(item[key])) {
                      if (item[key].indexOf(self.preFiltered[key][i]) !== -1) {
                        return true
                      }
                    }

                    if (self.preFiltered[key][i] == item[key]) {
                      return true
                    }
                  }
                } else {
                  return item
                }
              })
            }
          }
        }

        if (this.filterAll) {
          var results = []
          let filter = this.filterAll ? this.removeWhiteSpace(this.filterAll.toLowerCase()) : ''
          for (var i = shItems.length - 1; i >= 0; i--) {
            var shItem = shItems[i]
            var keys = this.tableSettings.fields
            var shItemAdded = false
            // loop through each shItem obj key
            for (var prop in keys) {
              if (keys.hasOwnProperty(prop)) {
                let val = this.removeWhiteSpace(String(self.getSortVal(shItem[prop])).toLowerCase())
                if (!shItemAdded) {
                  if (val.indexOf(filter, 0) !== -1) {
                    results.push(shItem)
                    shItemAdded = true
                  }
                }
              }
            }
          }
          shItems = results.reverse() // sorting method above reverses original sort order
        }

        // add params for linkable items
        for (var i = shItems.length - 1; i >= 0; i--) {
          if (shItems[i]['Email Address']) {
            shItems[i]['Email Address_formatted'] = '<a href="mailto:' + shItems[i]['Email Address'] + '">' + shItems[i]['Email Address'] + '</a>'
          }

          if (shItems[i]['Email']) {
            shItems[i]['Email_formatted'] = '<a href="mailto:' + shItems[i]['Email'] + '">' + shItems[i]['Email'] + '</a>'
          }
          if (shItems[i]['Employee_Email']) {
            shItems[i]['Employee_Email_formatted'] = '<a href="mailto:' + shItems[i]['Employee_Email'] + '">' + shItems[i]['Employee_Email'] + '</a>'
          }

          // format the various phone keys that may be used
          for (var p = this.phone_keys.length - 1; p >= 0; p--) {
            var key = this.phone_keys[p]
            if (shItems[i][key]) {
              shItems[i][[key] + '_formatted'] = '<a href="tel:' + this.stripToNumber(shItems[i][key]) + '">' + this.formatPhoneNumber(shItems[i][key]) + '</a>'
            }
          }

          if (shItems[i]['Address']) {
            var address1linkStr = shItems[i]['Address']
            address1linkStr = encodeURIComponent(address1linkStr).replace(/%20/g, '+')
            shItems[i]['Address_formatted'] = '<a href="https://maps.google.com/?q=' + address1linkStr + '" target="_blank">' + shItems[i]['Address'] + '</a>'
          }

          if (shItems[i]['Address_1']) {
            var address1linkStr = shItems[i]['Address_1']

            if (shItems[i]['City']) {
              address1linkStr += ' ' + shItems[i]['City']
            }

            address1linkStr = encodeURIComponent(address1linkStr).replace(/%20/g, '+')
            shItems[i]['Address_1_formatted'] = '<a href="https://maps.google.com/?q=' + address1linkStr + '" target="_blank">' + shItems[i]['Address_1'] + '</a>'
          }
        }
      }

      if (shItems && shItems[1] && this.sortCol) {
        shItems.sort((a, b) => {
          a = this.getSortVal(a[this.sortCol])
          b = this.getSortVal(b[this.sortCol])

          if (typeof a === 'string' && typeof b === 'string') {
            if (a.trim() < b.trim()) return -1
            if (a.trim() > b.trim()) return 1
          }

          // if one is empty nuke it
          if (typeof a === 'string' && !b) {
            return -1
          }

          // if made it this far sort as numbers
          a = parseFloat(a)
          a = a ? a : 0
          b = parseFloat(b)
          b = b ? b : 0

          if (a < b) return -1
          if (a > b) return 1
        })

        if (this.sortColReverse) {
          shItems = shItems.reverse()
        }
      }

      this.filteredShItems = shItems
    },

    showFormatted(key, value) {
      var val = ''
      if (!this.enableEditing && value[key + '_formatted']) {
        val = value[key + '_formatted']
      } else {
        val = value[key]
      }

      if (typeof val === 'string' || typeof val === 'number') {
        return val
      }
    },

    condenseItems() {
      // remove items that do not have data matching used columns
      var items = this.tableData
      var obj = {}

      if (items) {
        for (var i = items.length - 1; i >= 0; i--) {
          var empty = true
          for (var key in this.fields) {
            if (!this.fields.hasOwnProperty(key)) continue
            if (items[i][key]) {
              empty = false
              continue
            }
          }
          if (empty) {
            items.splice(i, 1)
          }
        }
      }
      return items
    },

    // removeHidden(shItems) {
    //   shItems = shItems.filter(function(item){
    //     return item[key].toLowerCase().indexOf(self.shtableFilter[key]).toLowerCase() > -1;
    //   });
    //   return shItems;
    // },

    editRow(id, obj, modifier) {
      if (this.noItemEdit) {
        return
      }
      var tableName = this.tableSettings.tableName || ''
      // trigger parent event from component attr (non global)

      if (modifier) {
        obj.modifier = true
      }
      this.$emit('edit-item', id)
      this.$emit('edit-item-obj', obj)
    },

    customAction(action, obj, event) {
      event.preventDefault()
      event.stopPropagation()
      this.eventActions.push({ action: action, obj: obj })
      this.$bus.$emit(action, obj)
    },

    sortColumns(col, field) {
      // if (field.noFilter) {
      //   return;
      // }
      if (this.sortCol == col) {
        this.sortColReverse = !this.sortColReverse
      }
      this.sortCol = col
      this.filterShItems()
    },

    // if col val is an object html icon or object, get the data-filterby attr or object title||action to sort by
    getSortVal(b) {
      if (!b) return 0

      if (b.title) {
        b = b.title
      } else if (b.action) {
        b = b.action
      }

      if (typeof b === 'string' && b.indexOf('data-filterby="') !== -1) {
        let m = b.match(/data-filterby="(-?[^"]*)"/)
        b = m && m[1] ? m[1].trim() : b
        let parsedValue = parseFloat(b)
        if (!isNaN(parsedValue)) {
          b = parsedValue
        }
      }

      // strip all but letters and numbers and decimals
      if (typeof b === 'string') {
        b = b.replace(/[^a-zA-Z0-9|\.]/g, '')
      }

      if (typeof b === 'string' || typeof b === 'number') {
        // try to round number in string val, if not null assume val is an ok number (with decimal removed)
        let roundNum = Math.round(b)
        if (roundNum) {
          b = roundNum
        }
      }

      return b
    },

    checkboxUpdate(id, field, value) {
      this.$emit('item-checked', { id, field, value })
    },

    checkEmitEmptySearch() {
      // wait 2 seconds if no results send event to parent with searched value
      setTimeout(() => {
        if (this.filterAll && !this.filteredShItems.length) {
          // wait to prevent duplicating events
          if (!this.filterAllEmptyStarted) {
            this.filterAllEmptyStarted = true

            this.$emit('filterAllEmpty', {
              value: this.filterAll
            })

            // clear wait
            setTimeout(() => {
              this.filterAllEmptyStarted = false
            }, 2000)
          }
        }
      }, 2000)
    }
  },

  mounted() {
    this.filterShItems()

    // run filtering on update
    this.$bus.$on('bhUpdate', () => {
      this.filterShItems()
    })

    if (this.tableSettings.sortCol) {
      this.sortCol = this.tableSettings.sortCol
    }

    if (this.tableSettings.sortColReverse) {
      this.sortColReverse = this.tableSettings.sortColReverse
    }
  },

  watch: {
    tableSettings: {
      handler(ts) {
        if (ts.filterAll) {
          // put items here that have a hard time updating from props
          this.filterAll = ts.filterAll
          this.customLink = ts.customLink
        }
      },
      deep: true
    },

    'tableSettings.tableData'() {
      this.filterShItems()
    },

    filterAll() {
      this.filterShItems()
    }
  },

  beforeDestroy() {
    // for (var i = this.eventActions.length - 1; i >= 0; i--) {
    //   console.log('removed ' + this.eventActions[i]['action']);
    //   this.$bus.$off(this.eventActions[i]['action'], this.eventActions[i]['obj']);
    // }
  }
}
</script>

<style lang="scss">
@import 'src/assets/sass/paper/_variables.scss';

.linked-item {
  i {
    color: $turquoise;
  }
}

td {
  position: relative;

  &.centered {
    text-align: center;
  }

  .checkbox-container {
    // display:inline-block;
    margin: 0;

    label {
      display: none;
    }
  }

  span.empty-content {
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
}

.shtable-table {
  .text-warning {
    color: #fbbf33 !important;
  }
}
</style>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

input.not-empty {
  border: 2px solid $violet;
}

.shtable-table {
  overflow-x: scroll;
  border: none !important;

  thead > tr > th {
    border: 0;
  }

  hr {
    margin-bottom: 10px;
  }

  table {
    margin-bottom: 10px;
    width: 100%;

    @media (min-width: 1000px) {
      // table-layout: fixed;
      // border-collapse: collapse;
    }

    .text-warning {
      color: #fba333 !important;
    }

    th,
    td {
      &.narrow {
        white-space: normal;
        max-width: 100px !important;
        // display:inline-block;
      }

      &.narrower {
        white-space: normal;
        max-width: 70px !important;
        // display:inline-block;
      }
    }

    td {
      &.bold {
        font-weight: bold;
      }
    }

    &.editable {
      tr span.editable {
        cursor: pointer;
        //cursor:alias;
        touch-action: manipulation;
      }
    }

    tr.header-row {
      cursor: pointer;

      th {
        &.Description {
          // min-width:400px;
        }

        i {
          font-size: 11px;
          padding: 0 5px;
        }

        &.view {
          min-width: 10px;
        }

        &.icon-item {
          max-width: 120px;
          text-align: center;
        }
      }
    }
  }

  th {
    font-size: 14px;
    font-weight: 500;
    min-width: 80px;
    padding-top: 0;
    padding-bottom: 3px;

    &.icon-item {
      min-width: 30px;
      text-align: center;
    }

    &:first-child {
      padding-left: 3px !important;
    }
  }

  tr {
    &.shItem {
      border-top: 1px solid #eee;
    }

    td:not(.empty) {
      padding: 14px 10px 10px 10px;
      border: none;
      //white-space: nowrap;
      width: 1px;
      min-width: 100px;

      &:not(.narrow):not(.narrower) {
        min-width: 150px;
      }

      &.wide {
        white-space: normal;
        min-width: 300px;
      }

      &.super-wide {
        white-space: normal;
        min-width: 500px;
      }

      &.icon-item {
        white-space: normal;
        min-width: 30px;
        text-align: center;
      }

      &.wide-medium {
        white-space: normal;
        min-width: 200px;
      }

      &:first-of-type {
        padding-left: 15px;
      }

      .linked-item {
        cursor: pointer;

        i {
          // color:blue
        }
      }

      &.narrow {
        white-space: normal;
        max-width: 100px !important;
        //display:inline-block;
      }

      .centered {
        text-align: center;
        display: block;
      }
    }

    &.empty-row {
      width: 100%;
      background: none !important;
    }

    &.shtable-add {
      td {
        padding: 5px;
        border: 0;

        input {
          &:focus {
            border-bottom: 0px solid blue;
          }
        }

        span {
          display: inline;
        }
      }
    }

    &.filter-row {
      td {
        border: none;
        padding-top: 0;

        &:first-child {
          padding-left: 0 !important;
        }
      }

      input {
        // background:transparent;
        //border:1px solid #ccc;
        background: #f6f5f6;
      }
    }
  }

  &.shtable-show {
    tr {
      position: relative;
      // border-top:1px solid #eee;

      &:nth-child(even) {
        // background:#f6f5f6;
      }

      &.green {
        background: #dafee5;
      }

      &.yellow {
        background: #fdf1c1;
      }

      &.orange {
        background: #ffce9a;
      }

      &.red {
        background: #ffc0b5;
      }

      &.blue {
        background: #daebfe;
      }

      &.purple {
        background: #f3dafe;
      }
    }
  }

  td {
    &.centered {
      text-align: center;
    }

    span {
      &.wide {
        max-width: 250px;
        white-space: normal;
      }

      &.icon-item {
        //max-width:30px;
        white-space: normal;
      }

      &.wide-medium {
        white-space: normal;
        max-width: 180px;
      }

      .checkbox-container {
        //display:inline-block;
        width: auto;
      }
    }

    input {
      width: 100%;
      padding: 8px 5px;
      display: inline;
      border: none;
      padding: 5px 8px;
      background: #ededed;
      border-radius: 3px;
    }

    &.view {
      min-width: 30px !important;

      span {
        min-width: 10px !important;
      }
    }
  }
}

.edit-col {
  white-space: normal !important;
  width: 20px !important;
  min-width: 20px !important;
  cursor: pointer;
  padding: 0 !important;

  span {
    width: 20px !important;
    margin: 0 auto;
    padding: 10px 25px;
    text-align: center;
    font-size: 17px;
  }
}
</style>

<style>
td i {
  font-size: 17px !important;
}
</style>
