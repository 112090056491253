<template>
  <div class="wo-contacts">
    <div v-if="!jobError">
      <h5>Contacts Assigned to Job</h5>

      <bh-table :table-settings="contactsAssigned" @item-checked="itemChecked" @edit-item="editContact" />

      <hr />
      <br />

      <h5>All Contacts <span class="fa fa-plus-circle is-link" @click="editContact()" /></h5>

      <bh-table :table-settings="contactsAll" @item-checked="itemChecked" @edit-item="editContact" />

      <br />
    </div>
    <p v-else>Problem loading Job Data, please close and continue from Job Details pages.</p>

    <waiting-spinner :container="true" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import BhTable from 'components/UIComponents/BhTable'
import CustomerContactsAddEdit from 'src/components/Dashboard/Contacts/CustomerContactsAddEdit.vue'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {
      jobError: false,
      job: {},
      filterBySite: 'all',
      contacts: [],
      jobAssignedContacts: [],
      tableFields: {
        Name: {
          name: 'Name'
        },
        Title: {
          name: 'Title'
        },
        jobSiteName: {
          name: 'Site'
        },
        Email: {
          name: 'Email'
        },
        assigned_contact: {
          name: 'Assigned',
          type: 'checkbox',
          class: 'centered'
        },
        invoice_contact: {
          name: 'Invoice Contact',
          type: 'checkbox',
          class: 'centered'
        },
        MobilePhone: {
          name: 'Mobile Phone'
        },
        Phone: {
          name: 'Direct Phone'
        },
        Phone_Extension__c: {
          name: 'Direct Phone Extension'
        },
        ticket_link: {
          name: 'Ticket Preview',
          class: 'centered'
        }
      }
    }
  },

  props: {
    jobid: {
      type: [Number, String],
      required: true
    }
  },

  components: {
    BhTable,
    WaitingSpinner
  },

  computed: {
    ...mapGetters(['customerContacts', 'jobAssemble']),

    contactsAll() {
      let fields = JSON.parse(JSON.stringify(this.tableFields))
      fields.invoice_contact.hidden = true
      delete fields.ticket_link
      return {
        fields: fields,
        hideSearch: true,
        tableData: this.contactsAllData
      }
    },

    contactsAssignedData() {
      const contacts = JSON.parse(JSON.stringify(this.contactsAllData))
      return contacts
        .filter(itm => this.job.job_contacts.includes(itm.Id))
        .map(itm => {
          itm.id = itm.Id
          itm.invoice_contact = this.job.invoice_contacts.includes(itm.Id)
          itm.assigned_contact = true
          return itm
        })
    },

    contactsAssigned() {
      for (var i = 0; i <= this.contactsAssignedData.length - 1; i++) {
        this.contactsAssignedData[i].ticket_link = {
          title: '<i class="ti ti-new-window"></i>',
          action: 'getTicketLink'
        }
      }

      return {
        fields: this.tableFields,
        hideSearch: true,
        tableData: this.contactsAssignedData
      }
    },

    contactsAllData() {
      const contacts = Array.isArray(this.customerContacts) ? this.customerContacts : []
      const jobContacts = Array.isArray(this.job.job_contacts) ? this.job.job_contacts : []
      return contacts
        .filter(itm => this.job.Customer_Code == itm.Account_Customer_Code__c)
        .map(itm => {
          itm.id = itm.Id
          itm.assigned_contact = jobContacts.includes(itm.Id)
          return itm
        })
    }
  },

  methods: {
    async load() {
      const getJobAssemble = this.$store.dispatch('getJobAssemble')
      const getContacts = this.$store.dispatch('getCustomerContacts', { refresh: true })

      this.$bus.$emit('setWaiting', { name: 'getData', message: 'Getting Data' })
      await Promise.all([getContacts, getJobAssemble])
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getData')
        })

      const job = this.jobAssemble.find(itm => {
        return itm.id == this.jobid
      })

      if (job) {
        this.job = job
        this.job.job_contacts = Array.isArray(this.job.job_contacts) ? this.job.job_contacts : []
        this.job.invoice_contacts = Array.isArray(this.job.invoice_contacts) ? this.job.invoice_contacts : []
      } else {
        this.jobError = true
      }
    },

    updateJob() {
      const params = {
        action: 'upsert_app_job_main_data',
        id: this.jobid,
        data: {
          job_contacts: this.job.job_contacts?.length ? this.job.job_contacts : null,
          invoice_contacts: this.job.invoice_contacts?.length ? this.job.invoice_contacts : null
        }
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Updating App Data' })
      appFuncs
        .shRequest(params)
        .then(res => {
          this.$bus.$emit('jobDataUpdated')
        })
        .catch(res => {
          console.log(res)
          this.$snack.open('Problem updating some data, please try again.', 'warning')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    editContact(editId) {
      this.$Modal({
        parent: this,
        name: 'customer-contact-add-edit', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        title: '',
        component: CustomerContactsAddEdit,
        props: {
          editId,
          preSelected: {
            Account_Customer_Code__c: this.job.Customer_Code
          }
        }
      })
    },

    removeItemFromArray(arr, item) {
      const index = arr.indexOf(item)
      if (index !== -1) {
        arr.splice(index, 1)
      }
    },

    itemChecked(obj) {
      if (obj.field == 'assigned_contact') {
        this.removeItemFromArray(this.job.job_contacts, obj.id)

        if (obj.value) {
          this.job.job_contacts.push(obj.id)
          // default to invoice contacts
          this.job.invoice_contacts.push(obj.id)
        } else {
          // Remove from invoice contacts if unchecked from job contacts
          this.removeItemFromArray(this.job.invoice_contacts, obj.id)
        }
      } else if (obj.field == 'invoice_contact') {
        this.removeItemFromArray(this.job.invoice_contacts, obj.id)
        if (obj.value) {
          this.job.invoice_contacts.push(obj.id)
        }
      }

      this.updateJob()
    },

    getCustomerTicketLink(contactId) {
      const params = {
        action: 'get_job_ticket_code',
        job_number: this.jobid,
        customer_contact_id: contactId
      }

      appFuncs
        .shRequest(params, 1)
        .then(data => {
          const modalContent = `<p class="text-center"style="margin:30px 0">Customer ticket preview:<br><br><a target="_blank" href="${
            this.$store.getters.urls.frontend + '#/customer-ticket/job/' + data
          }" class="btn">Customer Ticket</a></p>`
          this.$bus.$emit('modalOpen', {
            size: 'modal-sm',
            name: 'customer-contact-job-ticket-prompt',
            title: '',
            contentClasses: 'gray-bg centered',
            content: modalContent
          })
        })
        .catch(res => {
          console.log(res)
        })
    }
  },

  mounted() {
    if (!this.jobid) {
      this.jobError = true
    }

    // this.$bus.$on('editCustomerNotifications', (contact)=>{
    //    this.editCustomerNotifications(contact);
    // });

    this.$bus.$on('bhUpdate', () => {
      this.load()
    })

    this.$bus.$on('getTicketLink', contact => {
      this.getCustomerTicketLink(contact.Id)
    })

    this.$bus.$on('customer-contacts-updated', () => {
      this.$store.dispatch('getCustomerContacts', 'refresh').then(() => {
        this.load()
      })
    })

    this.load()
  },

  beforeDestroy() {
    // this.$bus.$off(['editCustomerNotifications']);
  }
}
</script>

<style lang="scss" scoped>
h5 {
  margin: 5px 0 10px;
}
</style>
