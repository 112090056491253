<template>
  <div class="wo-info">
    <div v-if="!woError">
      <waiting-spinner/>
      <div class="tab-section outline white other-charges-section">
        <alert :alert="alert"/>
        <div class="sh-widget">
          <table>
            <label>Other Charges</label>
            <thead>
            <tr>
              <th></th>
              <!-- <th>Invoice_Extension</th>
              <th>Cost_Total</th> -->
              <th></th>
            </tr>
            </thead>
            <tbody v-if="otherCharges.length">
            <tr v-for="(row, key) in otherCharges" :key="key">
              <td v-html="row.Other_Cost_Item + ' - ' + row.Description"/>
              <!-- <td v-html="row.Invoice_Extension" />
              <td v-html="row.Cost_Total" /> -->
              <td v-if="!readOnly" class="delete" @click="deleteOtherCharge(row.System_Key, row.Other_Cost_Item)"><i class="ti ti-close"></i></td>
            </tr>
            <!-- <tr :class="'totals-row'">
              <td v-html="hourTotals.regular" :class="'not-empty'" />
              <td v-html="hourTotals.ot" :class="'not-empty'" />
              <td v-html="hourTotals.dt" :class="'not-empty'" />
              <td></td>
            </tr> -->
            </tbody>
            <tbody v-else :class="'empty-result'">
            <tr><span class="empty">None</span></tr>
            </tbody>
          </table>
        </div>

        <br><br>

        <div v-if="!readOnly" class="row">
          <div class="col-sm-12 col-md-9">
            <select-field :options="otherChargeItems" :option-display-keys="['Description']" :option-val="'Other_Cost_Item'" v-model="addChargeItem"/>
            <br>
          </div>
          <div class="col-sm-12 col-md-2">
            <button class="btn" @click="addOtherCharge">Add</button>
          </div>
        </div>
        <p v-else><i>Other Charges cannot be added to WO in Locked status.</i></p>

      </div>
    </div>
    <p v-else>Problem loading WO Data, please ti-close and continue from WO Details pages.</p>
  </div>
</template>
<script>

import {mapGetters} from 'vuex';
import appFuncs from 'appFuncs';
import SelectField from 'components/UIComponents/SelectField';
import Alert from 'components/UIComponents/Alert';
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {
      woError: false,
      wo: null,
      otherCharges: [],
      otherChargeItems: [],
      addChargeItem: '',
      alert: {}
    };
  },

  props: {
    woid: {
      type: [Number, String],
      required: true,
    },

    inSequence: {
      type: Boolean,
      default: false
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },

  components: {
    SelectField,
    Alert,
    WaitingSpinner
  },

  computed: {
    ...mapGetters([
      'woAssemble',
    ]),
  },

  methods: {
    load(refresh) {
      this.$bus.$emit('setWaiting', {name: 'woAssemble', message: 'Getting WO Assemble'});
      this.$store.dispatch('getWoAssemble', refresh).then(() => {
        this.wo = this.woAssemble.find((itm) => {
          return itm.WO_Number == this.woid;
        });
        if (this.wo) {
          this.otherCharges = this.wo.other_charges;
        }
      }).finally(() => {
        this.$bus.$emit('stopWaiting', 'woAssemble');
      });
    },

    getOtherChargesList() {
      let params = {
        action: 'get_wo_other_charges_list'
      };
      this.$bus.$emit('setWaiting', {name: params.action, message: 'Getting Charge Items'});
      appFuncs.shRequest(params, 1).then((data) => {
        this.otherChargeItems = data;
      }).catch((res) => {
        console.log(res)
      }).finally(() => {
        this.$bus.$emit('stopWaiting', params.action);
      });
    },

    addOtherCharge() {
      this.alert = {};

      if (!this.addChargeItem) {
        this.alert = {
          show: 1,
          message: 'Please select a charge to add',
          state: 'warning',
          dismiss: 'manual'
        };
        return;
      }

      let params = {
        action: 'add_wo_other_charges',
        wo_number: this.woid,
        other_charge_item: this.addChargeItem,
      };

      this.$bus.$emit('setWaiting', {name: params.action, message: 'Adding Charge Item'});
      appFuncs.shRequest(params).then((data) => {
        // this.alert = {
        //    show: 1,
        //    message: 'Item added successfully',
        //    state: 'success',
        //    dismiss: 'auto'
        //  };
        this.load('refresh');
      }).catch((res) => {
        console.log(res);
        this.alert = {
          show: 1,
          message: 'Problem adding charge item, please reload page and try again.',
          state: 'danger',
          dismiss: 'manual'
        };
      }).finally(() => {
        this.$bus.$emit('stopWaiting', params.action);
      });
    },

    deleteOtherCharge(systemKey, otherCostItem) {
      this.alert = {};
      let params = {
        action: 'delete_wo_other_charges',
        wo_number: this.woid,
        system_key: systemKey,
        other_cost_item: otherCostItem
      };

      this.$bus.$emit('setWaiting', {name: params.action, message: 'Removing Charge Item'});
      appFuncs.shRequest(params).then((data) => {
        this.alerts = {
          show: 1,
          message: 'Item removed successfully',
          state: 'success',
          dismiss: 'auto'
        };
        this.load('refresh');
      }).catch((res) => {
        console.log(res);
        this.alert = {
          show: 1,
          message: 'Problem removing charge item, please reload page and try again.',
          state: 'danger',
          dismiss: 'manual'
        };
      }).finally(() => {
        this.$bus.$emit('stopWaiting', params.action);
      });
    },

    next() {
      this.$bus.$emit('modalClose', 'wo-info');
      this.$bus.$emit('modalOpen', {
        component: 'wo-contacts',
        size: 'modal-lg',
        name: 'wo-contacts',
        title: 'Set Contact Options',
        classes: 'gray-bg',
        componentData: {
          component: 'wo-contacts',
          woid: this.woid,
          inSequence: true,
        }
      });
    },
  },

  mounted() {
    if (!this.woid) {
      this.woError = true;
    }

    this.load();

    this.getOtherChargesList();
  },
};

</script>

<style lang="scss" scoped>

@import "src/assets/sass/paper/_variables.scss";

.wo-info {
  .tab-content {
    // min-height:300px;
  }
}

.sh-widget-container {
  overflow: scroll;
}

.other-charges-section {
  max-width: 700px;
}


.sh-widget {
  max-width: 700px !important;
  min-width: 400px;
  width: 100%;

  .empty-result {
    padding: 5px;
    font-style: italic;
    font-size: 15px;
  }

  table {
    table-layout: initial;
  }

  td {
    padding: 5px 10px;
  }

  tr {
    &.orange {
      background: $input-orange;

      td {
        border-color: darken($input-orange, 4%);
      }
    }

    &.totals-row {
      font-weight: bolder;

      td {
        border: none;

        &.not-empty {
          border: 1px solid $light-gray;
        }
      }
    }

    .empty {
      padding: 3px;
    }
  }
}

.delete {
  color: $general-red;
  cursor: pointer;
  border: 0;
}


</style>
