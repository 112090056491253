
<!-- prettier-ignore -->
<div class="pdf-template" id="pdf-template">
  <section v-if="!pdf && authedUser && data.showSendOptions" class="not-pdf section quote-action-section">
    <p style="font-size:18px">
      <strong>Quote Status:</strong> {{ data.quote_status }}
    </p>
    <!-- <p v-if="data.use_this_revision !== 'yes'">
      <i>Send options not available for inactive quote version</i>
    </p> -->
    <!-- <p v-if="hideBasePrice" class="text-danger">Note that Hide Base Price option is selected.</p> -->
    <br>
    <div>
      <button
        class="btn not-pdf"
        @click="generatePDF(true, null)"
        v-html="'Print Quote'"
      />
      <button
        class="btn not-pdf"
        @click="generatePDF(false, null)"
        v-html="'Print Quote Without Price'"
      />
      <button
        class="btn not-pdf"
        @click="emailQuote(true)"
        v-html="'Email Quote With Price'"
      />
      <button
        class="btn not-pdf"
        @click="emailQuote(false)"
        v-html="'Email Quote Without Price'"
      />
      <!-- <button
        v-if="quoteSent"
        class="btn not-pdf"
        @click="update('Quote in Progress')"
        v-html="'Re-Open Quote'"
      /> -->
      <button
        v-if="!quoteSent && data.use_this_revision === 'yes' && 1 === 2"
        class="btn not-pdf"
        @click="update('Sent and Pending')"
        v-html="'Mark Quote Sent'"
      />
      <button
        v-if="existingPDF"
        class="btn not-pdf"
        @click="downloadPDF()"
        v-html="'Download PDF'"
      />
    </div>
  </section>
  <div class="" v-if="data.quote_number">
    <div class="header" id="pdfReady">
      <div class=" header__row">
        <div class="header__image-container">
          <div>
            <img :src="`${urls.frontend}static/img/${logoFile}`">
          </div>
        </div>
        <div class="header__address-container">
          <p v-html="regionAddress"/>
        </div>
      </div>
      <div class="header-bottom"/>
    </div>

    <section class="section">
      Attention: {{ data.requested_by }} <br>
      Quote Number: {{ data.quote_number }} <br>
      Revision: {{ data.quote_revision }} <br>
      Sent Date: {{ data.sent_date ? formatDate(data.sent_date) : formatDate(new Date()) }} <br>
      <span v-if="data.quoted_by_name && data.quoted_by_name !== 'Unassigned'">Quoted By: {{ data.quoted_by_name }} <br></span>
      <span v-if="data.captain_name && data.captain_name !== 'Unassigned' && data.captain_name !== data.quoted_by_name">Reviewed By: {{ data.captain_name }}  <br> </span>
      # of Pages: {{ pageCount }}
    </section>

    <section class="section">
      <div class="row">
        <div class="col-xs-6">
          <label>Billing Address</label> <br>
          {{ data.customer_name }} <br>
          {{ data.billing_street }} <br>
          {{ data.billing_city }}, {{ data.billing_province }}, {{ data.billing_postal }} <br>
          {{ data.billing_country }}
        </div>
        <div class="col-xs-6">
          <label>Site Address</label><br>
          {{ data.site_name }} <br>
          {{ data.site_street }} <br>
          {{ data.site_city }}, {{ data.site_province }}, {{ data.site_postal }} <br>
          {{ data.site_country }}
        </div>
      </div>
    </section>

    <section class="section">
      <h4><strong>{{ data.quote_name }}</strong></h4>
    </section>

    <section class="section main-section">
      <h5 class="underline">Scope of Work</h5>
      <!-- <div class="nips" style="width: 100%;height:10px"></div> -->
      <div class="note-table">
        <div>
          <div
            v-for="(s, si) in data.systems"
            :key="si"
            class="table-number-content-parent"
          >
            <div><strong>{{ s.number }}</strong></div>
            <div>{{ s.name }} <span v-if="s.as_option === 'yes'" class="optional"> - <strong>[OPTIONAL PRICING PROVIDED]</strong></span></div>
          </div>
        </div>
      </div>
    </section>

    <section class="section main-section" v-if="specNotes && specNotes.length">
      <section class="section">
        <h5 class="underline">Details and Specifications:</h5>
      </section>
      <div
        class=""
        v-for="(n, ni) in specNotes"
        :key="ni"
      >
        <section class="section">
          <div class="note-table system-notes" style="margin-bottom: 20px;">
            <div class="table-number-content-parent">
              <div><strong>{{ n.sn }}</strong></div>
              <div>{{ n.name }} <span v-if="n.as_option === 'yes'" class="optional"> - <strong>[OPTIONAL PRICING PROVIDED]</strong></span></div>
            </div>
            <div>
              <div>
                <section
                  v-for="(nn, nni) in n.notes"
                  :key="nni"
                  class="table-number-content-parent section"
                  style="padding-left:10px"
                >
                  <div><strong>{{ nn.sl }}.</strong></div>
                  <div v-html="getSafeHtml(nn.t)"></div>
                </section>
              </div>
            </div>
          </div>
          <div class="note-added-table">
            <div v-html="n.table"/>
          </div>
        </section>

      </div>

    </section>

    <section
      class="section main-section"
      v-for="(n, ni) in notes"
      :key="ni"
    >
      <h5 class="underline">{{ n.name }}: </h5>
      <div class="note-table">
        <div>
          <div
            v-for="(nn, nni) in n.notes"
            :key="nni"
            :class="{ newline: n.notes[nni-1] && (parseFloat(n.notes[nni].sn) > parseFloat(n.notes[nni-1].sn)) }"
            class="table-number-content-parent"
          >
            <div><strong>{{ nn.sl }}.</strong></div>
            <div v-html="getSafeHtml(nn.t)"></div>
          </div>
        </div>
      </div>

      <div class="note-added-table" v-if="n.table">
        <div v-html="n.table"/>
      </div>

    </section>

    <!--
      Price Section: It appears only if showPrice is true.
      Price Header: This is shown if either hideBasePrice is false or data.price_table is present.
      Price Row: It's displayed only if hideBasePrice is false.
    -->
    <section class="section main-section" v-if="showPrice">

      <table class="note-table fee-table" style="margin: 0 0 10px">
        <thead v-if="!hideBasePrice || data.price_table">
        <th>Our Fee to Complete the Following:</th>
        <th v-if="!hideBasePrice">Quoted Amount</th>
        </thead>
        <tbody>
        <tr v-if="!hideBasePrice">
          <td>{{ data.quote_number }} - {{ data.quote_name }}</td>
          <td style="text-align:right">{{ quoteSellingBasePrice }}</td>
          <tr/>
        </tbody>
      </table>

      <div class="note-added-table" v-if="data.price_table">
        <div v-html="data.price_table"/>
      </div>

    </section>

    <section class="section optional-systems-section main-section" v-if="optionalSystems.length && priceType === 'quote'">
      <h5 class="optional">OPTIONAL ITEMS</h5>
      <div class="note-table systems-table optional-table">
        <div class="table-number-content-parent triple-col">
          <div>Item</div>
          <div>Scope</div>
          <div>Option Price</div>
        </div>
        <div>
          <div
            v-for="(s, si) in optionalSystems"
            :key="si"
            class="table-number-content-parent triple-col"
          >
            <div>{{ s.number }}</div>
            <div>{{ s.name }}</div>
            <div style="text-align:right">
              <span v-if="showPrice && s.selling_price">+{{ formatNumber(s.selling_price, 1) }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="optional-price-terms">
        <div class="note-table">
          <div>
            <div class="table-number-content-parent">
              <div><strong>a.</strong></div>
              <div>Optional prices have <span style="text-decoration: underline">not</span> been included in the Base Bid Quoted Amount of {{ formatNumber(data.selling_price,
                true, true) }}. These should be considered additions to the base bid.
              </div>
            </div>
            <div class="table-number-content-parent">
              <div><strong>b.</strong></div>
              <div>Main scope must be awarded for adder prices to be valid; exceptions may apply.</div>
            </div>
            <div class="table-number-content-parent">
              <div><strong>c.</strong></div>
              <div>All quote conditions apply to any optional prices unless stated otherwise.</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section main-section">
      <h5 class="underline">Terms:</h5>
      <div class="note-table">
        <div>
          <div class="table-number-content-parent">
            <div><strong>a.</strong></div>
            <div>{{ data.deposit_percent || `${data.deposit_percent}% deposit upon PO issuance` }}</div>
          </div>
          <div class="table-number-content-parent">
            <div><strong>c.</strong></div>
            <div>{{ data.terms_option || 'Remaining Net 30 with progress billings each month' }}</div>
          </div>
          <div class="table-number-content-parent">
            <div><strong>b.</strong></div>
            <div>{{ data.payment_price_type || 'Quote Fixed Price' }}</div>
          </div>
          <div v-if="data.progress_payment_text" class="table-number-content-parent">
            <div><strong>d.</strong></div>
            <div>{{ data.progress_payment_text }}</div>
          </div>
        </div>
      </div>
    </section>

    <section class="section main-section" style="margin-top:20px;">
      Sincerely, <br>
      {{ data.quoted_by_name && data.quoted_by_name !== 'Unassigned' ? data.quoted_by_name : data.captain_name }} <br>
    </section>

    <br>

    <section class="section main-section" v-if="data.enhanced_disclaimer_text">
      <h5 class="underline">Disclaimer</h5>
      <div v-html="data.enhanced_disclaimer_text"></div>
      <br>
    </section>

    <!-- <section class="section main-section" v-if="data.progress_payment_text">
      <h5 class="underline">Progress Payment Clause</h5>
      <div v-html="data.progress_payment_text"></div>
      <br>
    </section> -->

    <section class="section main-section">
      <h5 class="underline">Conditions:</h5>
      <div v-html="data.quote_conditions"></div>
    </section>

    <br>

    <section class="section main-section attachments-section" v-if="data.attachments && data.attachments.length">
      <h5 class="underline">Attachments</h5>
      <div v-for="(image, index) in data.attachments" :key="index">
        <!-- Start a new row div every 3 items -->
        <div v-if="index % 3 === 0" class="row">
          <attachment-component
            v-if="data.attachments[index]"
            :image="data.attachments[index]"
            @image-loaded="handleImageLoaded"
          />
          <attachment-component
            v-if="data.attachments[index + 1]"
            :image="data.attachments[index + 1]"
            @image-loaded="handleImageLoaded"
          />
          <attachment-component
            v-if="data.attachments[index + 2]"
            :image="data.attachments[index + 2]"
            @image-loaded="handleImageLoaded"
          />
        </div>
      </div>
    </section>

    <br>

    <div v-if="pdf" class="footer" style="display: none;">
      <div class="footer-inner">
        <div class="footer-left">
          {{ data.template_date }}
        </div>
        <div class="footer-center">
          {{ companyName }} Ltd.
        </div>
        <div class="footer-right">
          <div class="page-number">
            <p>Page 1 of 1</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner/>
</div>
