
<div class="tab-section white">
  <div class="max-width-large">
    <ul class="nav nav-tabs">
      <li role="presentation" :class="[{active: tab == 'vendors'}]">
        <a href="#" @click="tab = 'vendors'">
          Vendors
        </a>
      </li>
      <li role="presentation" :class="[{active: tab == 'contacts'}]">
        <a href="#" @click="tab = 'contacts', selectedVendor = ''">
          Vendor Contacts
        </a>
      </li>
    </ul>

    <div v-if="tab == 'vendors'" :class="['card',{'Main':tab == 'vendors'}]">
      <div class="content">
        <vendor-list @openVendorContact="gotoVendor"/>
      </div>
    </div>
    <div v-if="tab == 'contacts'" :class="['card']">
      <div class="content">
        <vendor-contact-list2 :selected-vendor="selectedVendor"/>
      </div>
    </div>
  </div>

  <waiting-spinner/>
</div>
