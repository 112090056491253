<template>
  <div>
    <div class="simple-header">
      <div class="row">
        <div class="col-xs-4">
          <div class="logo">
            <span class="sidebar-logo">
              <img :src="'static/img/logo.jpg'"/>
            </span>
          </div>
        </div>
        <div class="col-xs-8 right-links">
          <router-link v-for="(link, li) in links" :key="li" :to="{ path: link.path }" class="is-link underlined text-normal" v-html="link.title"
                       v-show="hasPermission(link.role)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  name: 'SimpleHeader',
  data() {
    return {
      links: [
        {
          path: '/materials',
          title: 'Materials'
        },
        {
          path: '/equipment',
          title: 'Equipment'
        },
        {
          path: '/other-charges',
          title: 'Other Charges'
        },
        {
          path: '/materials-admin-one-time',
          title: 'Materials One Time Admin',
          role: ['pm', 'D']
        },
        {
          path: '/assemblies',
          title: 'BOMs'
        },
        {
          path: '/material-lists',
          title: 'Material Lists'
        },
        {
          title: 'RFPs',
          path: '/rfps'
        },
        // {
        //   path: '/material-quotes',
        //   title: 'BOMs'
        // },
        {
          path: '/app',
          title: 'Dashboard'
        }
      ]
    }
  },

  props: {
    xlinks: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  computed: {
    ...mapGetters(['userAuthedData']),

    roles() {
      return Array.isArray(this.userAuthedData.user_role) ? this.userAuthedData.user_role : []
    }
  },

  methods: {
    hasPermission(role) {
      if (!role || this.roles.indexOf('admin') !== -1) return true
      if (!Array.isArray(role)) {
        role = [role]
      }
      for (let i = 0; i < role.length; i++) {
        if (this.roles.indexOf(role[i]) === -1) {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.simple-header {
  background: #fff;
  padding: 20px 30px;
  border-bottom: 2px solid $bg-grey;
  max-width: 3000px;

  .logo {
    width: 200px;
  }

  .right-links {
    text-align: right;
    padding: 15px 30px;

    a {
      padding: 5px 10px;
      text-decoration: none;
      font-size: 16px;

      &.router-link-exact-active {
        font-weight: bold;
      }
    }
  }
}
</style>
