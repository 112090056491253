<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card">
        <div class="content">
          <h3>Reset Password</h3>
          <reset-password/>
          <br>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ResetPassword from 'components/GeneralViews/ResetPassword.vue'

export default {
  components: {
    ResetPassword,
  }
}

</script>


<style lang="scss" scoped>

.card {
  max-width: 600px !important;
}

</style>
