<template>
  <div class="tab-section white">
    <div class="max-width-large">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{active: tab == 'vendors'}]">
          <a href="#" @click="tab = 'vendors'">
            Vendors
          </a>
        </li>
        <li role="presentation" :class="[{active: tab == 'contacts'}]">
          <a href="#" @click="tab = 'contacts', selectedVendor = ''">
            Vendor Contacts
          </a>
        </li>
      </ul>

      <div v-if="tab == 'vendors'" :class="['card',{'Main':tab == 'vendors'}]">
        <div class="content">
          <vendor-list @openVendorContact="gotoVendor"/>
        </div>
      </div>
      <div v-if="tab == 'contacts'" :class="['card']">
        <div class="content">
          <vendor-contact-list2 :selected-vendor="selectedVendor"/>
        </div>
      </div>
    </div>

    <waiting-spinner/>
  </div>
</template>
<script>

import navTabs from 'mixins/navTabs';
import VendorList from 'components/Dashboard/Contacts/VendorList';
import VendorContactList2 from 'components/Dashboard/Contacts/VendorContactList2';
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'VendorList2',
  data() {
    return {
      tab: 'vendors',
      selectedVendor: ''
    };
  },

  mixins: [
    navTabs
  ],

  components: {
    VendorList,
    VendorContactList2,
    WaitingSpinner
  },

  methods: {
    gotoVendor(id) {
      this.tab = 'contacts';
      this.selectedVendor = id;
    }
  },
};

</script>

<style lang="scss" scoped>

</style>
