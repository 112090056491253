/*
    Global Mixin, use for methods only
*/

import he from 'he'
// import moment from 'moment'
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'
import { diff } from 'deep-object-diff'

moment.tz.setDefault('America/Toronto')

export default {
  computed: {
    ...mapGetters(['userAuthedData', 'appData']),

    isMaterialsAdmin() {
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isMaterialsPM() {
      return this.userAuthedData.user_role.indexOf('pm') !== -1
    },

    isMobile() {
      return window.innerWidth <= '600'
    },

    isDev() {
      return !window.location.href.startsWith('https://mysheridan.ca')
      // return process.env.NODE_ENV === 'development'
    },

    enableBillingTasks() {
      return true //this.isDev
    },

    projectStages: () => [
      { code: '1', description: 'Not Started', id: 'not-started' },
      { code: '4', description: 'On Going', id: 'on-going' },
      { code: '4', description: 'Stagnant', id: 'stagnant' },
      { code: '5', description: 'On-Hold - Bill Costs', id: 'on-hold-bill-costs' },
      { code: '5', description: 'On-Hold - No Billing Allowed', id: 'on-hold-no-billing' },
      { code: '7', description: 'Finished', id: 'finished' },
      { code: '6', description: 'Cancelled - No Billing Allowed', id: 'cancelled-no-billing' },
      { code: '7', description: 'Cancelled - Bill Costs', id: 'cancelled-bill-costs' }
    ],

    signOffStages: () => [
      { description: 'Not Signed', id: 'not-signed' },
      { description: 'Needs Signature', id: 'needs-signature' },
      { description: 'Sign off Sent', id: 'sign-off-sent' },
      { description: 'Signed', id: 'signed' },
      { description: 'Unresponsive', id: 'unresponsive' },
      { description: 'Ok to Bill', id: 'ok-to-bill' }
    ],

    divisionCostGroupOptions() {
      if (!Array.isArray(this.emCostGroups)) return []
      return this.emCostGroups
        .map(itm => {
          const using = ['AUTOMATION', 'GTA BRANCH', 'HARDIE', 'LONDON BRA', 'WTP']
          if (!using.includes(itm.Cost_Group_Code)) {
            return null
          }
          return {
            name: itm.Cost_Group_Description,
            value: itm.Cost_Group_Code
          }
        })
        .filter(itm => itm !== null)
    }
  },

  methods: {
    getProjectStage(id) {
      return this.projectStages.find(s => s.id === id)
    },

    getSignOffStage(id) {
      return this.signOffStages.find(s => s.id === id)
    },

    removeWhiteSpace(str) {
      return str.replace(/\s/g, '')
    },

    formatNumberOld(num, dollar) {
      num = parseFloat(num)
      num = isNaN(num) ? 0 : num
      if (dollar) {
        return num.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        })
      }
      return num.toLocaleString('en')
    },

    formatNumber(num, dollar, hideDollar) {
      num = parseFloat(num)
      num = isNaN(num) ? 0 : num

      if (dollar) {
        if (hideDollar) {
          // Format without currency symbol
          return num.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        }

        return num.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      }

      return num.toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },

    stripToNumber(str, leaveThousandCommas) {
      if (leaveThousandCommas) {
        return str ? String(str).replace(/[^0-9|.]/g, '') : ''
      }
      const num = parseFloat(String(str).replace(/[^\d.]/g, ''))
      return num ? num : 0
    },

    // helper for vue-the-mask phone number input formatting - use in v-mask directive on input fields
    chooseMask(val) {
      val = this.stripToNumber(val)
      return val && val.length > 10 ? '#-###-###-####' : '###-###-####'
    },

    chooseMaskPostalZip(val) {
      if (val && parseInt(val.charAt(0))) {
        return '#####'
      } else {
        return 'A#A #A#'
      }
    },

    formatPhoneNumber(val) {
      // convert to string and use only first 10 chars
      val = this.stripToNumber(val).toString()
      if (val.length === 11) {
        return val.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4')
      } else if (val.length === 10) {
        return val.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
      } else {
        return val
      }
    },

    daysBetweenTimestamps(timestamp1, timestamp2) {
      // Convert timestamps to milliseconds
      const date1 = new Date(timestamp1 * 1000)
      const date2 = timestamp2 ? new Date(timestamp2 * 1000) : new Date()

      // Calculate the difference in time
      const timeDifference = Math.abs(date2 - date1)

      // Convert time difference from milliseconds to days
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

      return daysDifference
    },

    formatDate(str, stripFormat) {
      if (!str) {
        return ''
      }

      // Check if the input string is a Unix timestamp
      const isUnixTimestamp = /^\d{10}$/.test(str)

      if (isUnixTimestamp) {
        const unixDate = moment.unix(parseInt(str, 10))
        if (unixDate.isValid()) {
          return unixDate.format('MMM Do YYYY')
        } else {
          console.error('Invalid Unix timestamp:', str)
          return 'Invalid date'
        }
      }

      if (str && stripFormat) {
        let fmtd = str.match(/\d{4}([.\-/ ])\d{2}\1\d{2}/)
        if (fmtd && fmtd[0]) {
          let dst = moment(fmtd[0]).format('MMM Do YYYY')
          return dst
        }
      }
      return moment(str).format('MMM Do YYYY')
    },

    calculateCycleDates(hireTimestamp, monthsCycle) {
      const hireDate = new Date(hireTimestamp * 1000) // Convert Unix timestamp to Date
      const currentDate = new Date()

      // Calculate difference in months between hire date and current date
      const monthsSinceHire = this.getMonthDifference(hireDate, currentDate)

      // Find the current cycle number
      const currentCycle = Math.floor(monthsSinceHire / monthsCycle)

      // Calculate the cycle start date
      const cycleStartDate = new Date(hireDate)
      cycleStartDate.setMonth(cycleStartDate.getMonth() + currentCycle * monthsCycle)

      // Calculate the cycle end date (add cycle length months, then subtract 1 day)
      const cycleEndDate = new Date(cycleStartDate)
      cycleEndDate.setMonth(cycleEndDate.getMonth() + monthsCycle)
      cycleEndDate.setDate(cycleEndDate.getDate() - 1)

      return {
        start_date: this.formattingDate(cycleStartDate),
        end_date: this.formattingDate(cycleEndDate)
      }
    },
    getMonthDifference(startDate, endDate) {
      const yearsDifference = endDate.getFullYear() - startDate.getFullYear()
      const monthsDifference = endDate.getMonth() - startDate.getMonth()
      return yearsDifference * 12 + monthsDifference
    },
    formattingDate(date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },

    getDays(str) {
      // convert today minus date string (2019-12-31 00:00:00) to day count
      let end = moment()
      let duration = moment.duration(end.diff(str))
      let days = duration.asDays()
      return Math.floor(days) || 0
    },

    getDaysOld(unixTimestamp) {
      const createDate = moment.unix(unixTimestamp)
      const now = moment()
      const daysDiff = now.diff(createDate, 'days')
      return `${daysDiff} days`
    },

    dateFromUnix(date, showTime) {
      if (!date) {
        date = Math.floor(Date.now() / 1000)
      }
      if (showTime) {
        return moment.unix(date).format('MMM Do YYYY h:mm a')
      } else {
        return moment.unix(date).format('MMM Do YYYY')
      }
    },

    encode(value, stripTags) {
      if (stripTags) {
        value = value.replace(/(<([^>]+)>)/gi, '')
      }
      if (value) {
        return he.encode(value)
      }
    },

    decode(value) {
      if (value) {
        value = he.decode(value, {
          isAttributeValue: false
        })
      }
      return value
    },

    copyText(text) {
      var self = this
      this.$copyText(text).then(
        function (e) {
          self.copyTextMsg = 'Copied'
          setTimeout(function () {
            self.copyTextMsg = ''
          }, 2000)
          setTimeout(function () {
            self.copyTextMsg = 'Click to copy'
          }, 7000)
        },
        function (e) {
          console.log(e)
        }
      )
    },

    randomCharacterString(length) {
      var result = ''
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },

    getUnixTimeWithRandom() {
      const unixTime = Date.now()
      const randomSixDigits = Math.floor(100000 + Math.random() * 900000)
      return `${unixTime}${randomSixDigits}`
    },

    /**
     * @return object
     * @param {string} employeeCode
     * @param {array} employees
     * @description Get employee object from employee code
     */
    getEmployeeName(employeeCode, employees) {
      if (!employeeCode || !employees || !employees.length) return {}
      let match = employees.find(itm => {
        return itm.Employee_Code == employeeCode
      })
      return match || {}
    },

    /**f
     * @return string
     * @param {string} employeeCode
     * @param {array} employees
     * @description Get employee object from employee code
     */
    getEmployeeNameStr(employeeCode, employees) {
      if (!employeeCode || !employees || !employees.length) return ''
      let match = employees.find(itm => {
        return itm.Employee_Code == employeeCode
      })
      return match?.Employee_Name || ''
    },

    formatExtraNumber(itemId, extraId) {
      let number = ''
      for (let i = 0; i < 5 - extraId.toString().length; i++) {
        number += '0'
      }
      return `${itemId}-${number}${extraId.toString()}`
    },

    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },

    incrementLetter(last) {
      if (!last) return 'a'
      return last.substring(0, last.length - 1) + String.fromCharCode(last.charCodeAt(last.length - 1) + 1)
    },

    isObjEmpty(obj) {
      if (!obj) return true // handle undefined, null, false, 0, and empty string
      if (typeof obj !== 'object') return true // handle non-object types such as strings, numbers, and booleans
      if (Array.isArray(obj)) return obj.length === 0 // handle arrays
      return Object.keys(obj).length === 0 // handle objects
    },

    filterObjectKeys(arr, keys, useTypeStrings) {
      let filtered = []
      for (let ai = 0; ai < arr.length; ai++) {
        let add = {}
        for (let i = 0; i < keys.length; i++) {
          if (arr[ai][keys[i]]) {
            add[keys[i]] = useTypeStrings ? String(arr[ai][keys[i]]) : arr[ai][keys[i]]
          }
        }
        filtered.push(add)
      }
      return filtered
    },

    filterObjectByKeys(obj, keysArray) {
      let filteredObj = {}
      for (let key of keysArray) {
        if (obj.hasOwnProperty(key)) {
          filteredObj[key] = obj[key]
        }
      }
      return filteredObj
    },

    isComponentRendered(componentName, root = this.$root) {
      // Check if the root component's name matches the component name you are looking for
      if (root.$options.name === componentName) {
        return true
      }

      // Recursively check each child component of the current component
      for (let i = 0; i < root.$children.length; i++) {
        const child = root.$children[i]
        if (this.isComponentRendered(componentName, child)) {
          return true
        }
      }

      // Component not found
      return false
    },

    getSetFiscalYear() {
      const fiscalYear = this.$store.getters.fiscalYear
      this.$store.commit('quoteFilterFiscalYear', fiscalYear)
      return

      let fy = this.$store.state.quoteFilterFiscalYear

      if (Array.isArray(fy) && fy.length) {
        fy = fy[0]
      }

      if (!fy) {
        const date = new Date()
        let year = date.getFullYear()
        const monthNumber = date.getMonth()
        if (monthNumber > 8) {
          // greater than september (indexed scale)
          year += 1
        }
        fy = year.toString()
      }

      this.$store.commit('quoteFilterFiscalYear', fy)

      return fy
    },

    scrollTop(el) {
      if (!el) {
        el = $('.modal-multi')
        if (!el || !el.length) {
          el = $('.main-panel')
        }
      }
      //const position = $(el).offset().top;
      //$('.main-panel')[0].scrollTo(0,0);
      $(el).animate({ scrollTop: 0 }, 500)
    },

    getNextHeaderSort(headers) {
      let sortNotes = JSON.parse(JSON.stringify(headers)).filter(itm => {
        return parseInt(itm.sort) < 9999
      }) // remove Not Included hardcoded 9999 id item
      let sortedHeaders = sortNotes.sort((a, b) => {
        let aOrder = parseFloat(a.sort)
        let bOrder = parseFloat(b.sort)
        if (aOrder > bOrder) return -1
        if (aOrder < bOrder) return 1
        return 0
      })
      return sortedHeaders[0] ? parseInt(sortedHeaders[0].sort) + 1 : 2
    },

    convertObjectValsToString(obj) {
      // Base case: if it's neither an object nor an array, just return the string representation
      if (obj === null || obj === undefined) return '' // Covering our null and undefined bases
      if (typeof obj !== 'object') return obj.toString()

      // Case for arrays
      if (Array.isArray(obj)) {
        return obj.map(this.convertObjectValsToString) // Recurse for each element
      } else {
        // Case for objects
        const newObj = {}
        Object.entries(obj).forEach(([key, value]) => {
          // Recursively convert objects or array values, other types are converted to string directly
          if (typeof value === 'object' && value !== null) {
            newObj[key] = this.convertObjectValsToString(value) // Recursion deep dive
          } else if (typeof value === 'function') {
            newObj[key] = 'function' // Or maybe you want to stringify it differently, up to you
          } else {
            newObj[key] = value === undefined || value === null ? '' : value.toString() // Undefined becomes empty string
          }
        })
        return newObj
      }
    },

    extractQuoteIncr(quoteNumber) {
      // returns quote incr from full quote number
      const match = quoteNumber.match(/Q(\d+)-/)
      if (match) {
        return match[1]
      }
      return ''
    },

    getRegionVars(region) {
      let vars = {
        name: 'sheridan', // Default values
        companyName: 'Sheridan Electric Ltd',
        logoFile: 'logo.jpg',
        address: `34-966 Pantera Drive<br>
                  Mississauga, Ontario, L4W 2S1<br>
                  Tel: 905-625-0265<br>
                  Toll Free: 1-800-517-8512<br>
        info@sheridanelectric.ca`,
        tel: '905-625-0265',
        tollFree: '1-800-517-8512' // Some regions may not have a toll-free number
      }

      switch (region) {
        case 'location_hardie':
          vars.name = 'hardie'
          vars.companyName = 'Hardie Industrial Services'
          vars.logoFile = 'hardie-sheridan-logo.jpg'
          vars.address = `594 Newbold St<br>
                          London, Ontario, N6E 2W9<br>
                          Tel: 519-649-1433<br>
                          info@hardie.on.ca`
          vars.tel = '519-649-1433'
          vars.tollFree = '' // Assuming Hardie does not have a toll-free number
          break
        case 'location_sheridan':
        case 'location_automation':
          vars.name = region === 'location_automation' ? 'automation' : 'sheridan'
          vars.companyName = region === 'location_automation' ? 'Sheridan Automation' : 'Sheridan Electric'
          vars.logoFile = region === 'location_automation' ? 'sheridan-automation-logo.jpg' : 'logo.jpg'
          // Address, tel, and tollFree are already set to Sheridan's by default
          break
      }

      return vars
    },

    shrug() {
      return '¯\\_(ツ)_/¯'
    },

    encodeJSONToBase64(jsonData) {
      // Convert JSON object to a string
      const jsonString = JSON.stringify(jsonData)

      // Encode the string to Base64
      const base64Encoded = btoa(jsonString)

      return base64Encoded
    },

    decodeBase64ToJSON(base64String) {
      // Decode from Base64 to a string
      const jsonString = atob(base64String)

      // Parse the string back into JSON
      const jsonData = JSON.parse(jsonString)

      return jsonData
    },

    loosieDiff(lhs, rhs) {
      const rawDiff = diff(lhs, rhs)
      return this.filterTypeDifferences(rawDiff, lhs, rhs)
    },

    filterTypeDifferences(diffObj, lhs, rhs) {
      if (typeof diffObj !== 'object' || diffObj === null) {
        return diffObj
      }

      return Object.keys(diffObj).reduce((acc, key) => {
        // if not loosely equal and one has a value
        if (lhs[key] != rhs[key] && (lhs[key] || rhs[key])) {
          acc[key] = diffObj[key]
        }
        return acc
      }, {})
    },

    isFirstDateBeforeSecondDate(startDate, endDate) {
      const toFormattedDate = date => {
        const d = new Date(date)
        return d.toISOString().split('T')[0]
      }

      const currentDate = toFormattedDate(new Date())
      const start = startDate ? toFormattedDate(new Date(startDate)) : currentDate
      const end = endDate ? toFormattedDate(new Date(endDate)) : currentDate

      return start < end
    },

    isFirstDateAfterOrEqualToSecondDate(startDate, endDate) {
      const toFormattedDate = date => {
        const d = new Date(date)
        return d.toISOString().split('T')[0]
      }

      const currentDate = toFormattedDate(new Date())
      const start = startDate ? toFormattedDate(new Date(startDate)) : currentDate
      const end = endDate ? toFormattedDate(new Date(endDate)) : currentDate

      // console.log("Start Date:", start);
      // console.log("End Date:", end);
      // console.log("Current Date:", currentDate);

      return start >= end
    }
  }
}
