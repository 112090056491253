
<div>
  <div class="content">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div :class="'detail-item'">
          <label>Employee</label>
          <p>{{ request.employeeName }}</p>
        </div>
        <div :class="'detail-item'">
          <label>First Off</label>
          <p>{{ request.first_off }}</p>
        </div>
        <div :class="'detail-item'">
          <label>Last Off</label>
          <p>{{ request.last_off }}</p>
        </div>
      </div>

      <div class="col-sm-12 col-md-6">
        <div :class="'detail-item'" v-if="isAdmin">
          <label>Status</label>
          <select-field :options="statuses" :label-text="'Status'" :option-display-keys="['name']" :option-val="'name'" v-model="request.status" @input="checkChanges()"/>
        </div>
        <div :class="'detail-item'">
          <label>Reason</label>
          <p>{{ request.reason }}</p>
        </div>
        <div :class="'detail-item'">
          <label>Requested</label>
          <p>{{ request.niceDate }}</p>
        </div>
      </div>
      <div class="col-sm-12" v-if="isAdmin">
        <div :class="'detail-item'">
          <label>Message</label>
          <textarea @input="checkChanges" class="form-control" v-model="request.status_message"/>
        </div>
        <div :class="'detail-item'">
          <checkbox v-model="emailEmployee"> Email update to employee</checkbox>
        </div>
      </div>
    </div>
    <div>
      <button :class="['btn', { pulse: changed }]" @click="updateRequest()" v-if="isAdmin">Update</button>
      <button :class="['btn', { pulse: changed }]" @click="withdrawRequest()" v-if="!isAdmin && request.status != 'Withdrawn' && request.status != 'Disapproved'">Withdraw
        Request
      </button>
    </div>
  </div>

  <waiting-spinner/>
</div>
