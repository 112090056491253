<template>
  <div id="aging-page">
    <div class="row">
      <div class="col col-sm-12 col-md-5">
        <div class="sh-widget card wide-medium">
          <div class="content">
            <div class="top-section">
              <div class="">
                <h4>Accounts Receivables</h4>
              </div>
            </div>
            <div class="table-container show-scrollbar">
              <table>
                <thead>
                  <tr>
                    <th><label>Dates</label></th>
                    <th><label>% of AR</label></th>
                    <th><label>$ of AR</label></th>
                  </tr>
                </thead>
                <tr>
                  <td>Current</td>
                  <td>{{ formatNumber(arCustomerTotals.currentp) }}%</td>
                  <td>{{ formatNumber(arCustomerTotals.current, 1) }}</td>
                </tr>
                <tr>
                  <td>1-30 days</td>
                  <td>{{ formatNumber(arCustomerTotals.days30p) }}%</td>
                  <td>{{ formatNumber(arCustomerTotals.days30, 1) }}</td>
                </tr>
                <tr>
                  <td>31-60 days</td>
                  <td>{{ formatNumber(arCustomerTotals.days60p) }}%</td>
                  <td>{{ formatNumber(arCustomerTotals.days60, 1) }}</td>
                </tr>
                <tr>
                  <td>61-90 days</td>
                  <td>{{ formatNumber(arCustomerTotals.days90p) }}%</td>
                  <td>{{ formatNumber(arCustomerTotals.days90, 1) }}</td>
                </tr>
                <tr>
                  <td>>90 days</td>
                  <td>{{ formatNumber(arCustomerTotals.daysOver90p) }}%</td>
                  <td>{{ formatNumber(arCustomerTotals.daysOver90, 1) }}</td>
                </tr>
                <tr>
                  <td>Retention</td>
                  <td>{{ formatNumber(arCustomerTotals.retentionp) }}%</td>
                  <td>{{ formatNumber(arCustomerTotals.retention, 1) }}</td>
                </tr>
                <tr></tr>
                <tr>
                  <td>Total:</td>
                  <td>100%</td>
                  <td>{{ formatNumber(arCustomerTotals.totalOwing, true) }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="sh-widget card wide-medium">
          <div class="content">
            <div class="top-section">
              <div class="">
                <h4>Accounts Payable</h4>
              </div>
            </div>
            <div class="table-container show-scrollbar">
              <table>
                <thead>
                  <tr>
                    <th><label>Dates</label></th>
                    <th><label>% of AP</label></th>
                    <th><label>$ of AP</label></th>
                  </tr>
                </thead>
                <tr>
                  <td>Unapproved</td>
                  <td>{{ formatNumber(apCustomerTotals.unapprovedp) }}%</td>
                  <td>{{ formatNumber(unapprovedInvoiceTotal, 1) }}</td>
                </tr>
                <tr>
                  <td>Current</td>
                  <td>{{ formatNumber(apCustomerTotals.currentp) }}%</td>
                  <td>{{ formatNumber(apCustomerTotals.current, 1) }}</td>
                </tr>
                <tr>
                  <td>1-30 days</td>
                  <td>{{ formatNumber(apCustomerTotals.days30p) }}%</td>
                  <td>{{ formatNumber(apCustomerTotals.days30, 1) }}</td>
                </tr>
                <tr>
                  <td>31-60 days</td>
                  <td>{{ formatNumber(apCustomerTotals.days60p) }}%</td>
                  <td>{{ formatNumber(apCustomerTotals.days60, 1) }}</td>
                </tr>
                <tr>
                  <td>61-90 days</td>
                  <td>{{ formatNumber(apCustomerTotals.days90p) }}%</td>
                  <td>{{ formatNumber(apCustomerTotals.days90, 1) }}</td>
                </tr>
                <tr>
                  <td>>90 days</td>
                  <td>{{ formatNumber(apCustomerTotals.daysOver90p) }}%</td>
                  <td>{{ formatNumber(apCustomerTotals.daysOver90, 1) }}</td>
                </tr>
                <tr>
                  <td>Retention</td>
                  <td>{{ formatNumber(apCustomerTotals.retentionp) }}%</td>
                  <td>{{ formatNumber(apCustomerTotals.retention, 1) }}</td>
                </tr>
                <tr>
                  <td>Total:</td>
                  <td>100%</td>
                  <td>{{ formatNumber(apCustomerTotals.totalOwing, true) }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="sh-widget card wide-large">
          <div class="content">
            <div class="top-section">
              <div class="">
                <h4>AR - AP Status</h4>
              </div>
            </div>
            <div class="table-container show-scrollbar">
              <table>
                <thead>
                  <tr>
                    <th><label>AR</label></th>
                    <th><label>AP</label></th>
                    <th><label>Unapproved AP</label></th>
                    <th><label>WIP</label></th>
                    <th><label>Net</label></th>
                  </tr>
                </thead>
                <tr>
                  <td>{{ formatNumber(arCustomerTotals.totalOwing, 1) }}</td>
                  <td>{{ formatNumber(apCustomerTotals.totalOwing, 1) }}</td>
                  <td>{{ formatNumber(unapprovedInvoiceTotal, 1) }}</td>
                  <td>{{ formatNumber(wipTotal, 1) }}</td>
                  <td>{{ formatNumber(arCustomerTotals.totalOwing - unapprovedInvoiceTotal - apCustomerTotals.totalOwing + wipTotal, 1) }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-sm-12 col-md-7">
        <customer-top-cash-flow v-if="customers.length" />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="sh-widget card wide-xl top-account-table">
          <div class="content">
            <div class="top-section">
              <div class="">
                <h4>Top Accounts Receivable Top</h4>
              </div>
            </div>
            <div class="table-container show-scrollbar">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th><label>AR Top Account by Customer</label></th>
                    <th @click="setArTopAccounts('totalOwing')" :class="[{ sorted: sortBy == 'totalOwing' }, 'clickable']"><label>Total Owing</label></th>
                    <th @click="setArTopAccounts('retention')" :class="[{ sorted: sortBy == 'retention' }, 'clickable']"><label>Retention</label></th>
                    <th><label>Retention % of Total</label></th>
                    <th @click="setArTopAccounts('total')" :class="[{ sorted: sortBy == 'total' }, 'clickable']"><label>Non Retention</label></th>
                    <th><label>Non Retention % of Total</label></th>
                    <th @click="setArTopAccounts('current')" :class="[{ sorted: sortBy == 'current' }, 'clickable']"><label>Current Owing</label></th>
                    <th><label>Current %</label></th>
                    <th @click="setArTopAccounts('days30')" :class="[{ sorted: sortBy == 'days30' }, 'clickable']"><label>1-30 DAYS</label></th>
                    <th><label>1-30 DAYS %</label></th>
                    <th @click="setArTopAccounts('days60')" :class="[{ sorted: sortBy == 'days60' }, 'clickable']"><label>31-60 DAYS</label></th>
                    <th><label>31-60 DAYS %</label></th>
                    <th @click="setArTopAccounts('days90')" :class="[{ sorted: sortBy == 'days90' }, 'clickable']"><label>61-90 DAYS</label></th>
                    <th><label>61-90 DAYS %</label></th>
                    <th @click="setArTopAccounts('daysOver90')" :class="[{ sorted: sortBy == 'daysOver90' }, 'clickable']"><label>>90 DAYS</label></th>
                    <th><label>>90 DAYS %</label></th>
                    <!-- <th @click="setArTopAccounts('daysOver30')" :class="[{ sorted : sortBy == 'daysOver30'}, 'clickable']"><label>$ Total <br>31+ Days</label></th>
                  <th><label>% Total <br>31+ Days</label></th> -->
                  </tr>
                </thead>

                <tr v-for="(tat, i) in topAccountTotals" :key="i" @click="openDetails(tat)" class="is-link">
                  <td>{{ i + 1 }}</td>
                  <td>{{ tat.Name }}</td>
                  <td>{{ formatNumber(tat.owing.totalOwing, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.retention, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.retentionp) }}%</td>
                  <td>{{ formatNumber(tat.owing.total, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.totalp) }}%</td>
                  <td>{{ formatNumber(tat.owing.current, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.currentp) }}%</td>
                  <td>{{ formatNumber(tat.owing.days30, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.days30p) }}%</td>
                  <td>{{ formatNumber(tat.owing.days60, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.days60p) }}%</td>
                  <td>{{ formatNumber(tat.owing.days90, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.days90p) }}%</td>
                  <td>{{ formatNumber(tat.owing.daysOver90, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.daysOver90p) }}%</td>
                  <!-- <td>{{ formatNumber(tat.owing.daysOver30, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.daysOver30p) }}%</td> -->
                </tr>

                <tr v-if="'cats' === 'cats'">
                  <td>11</td>
                  <td>Others</td>
                  <td>{{ formatNumber(otherAccountTotals.totalOwing, 1) }}</td>
                  <td>{{ formatNumber(otherAccountTotals.retention, 1) }}</td>
                  <td>{{ formatNumber(otherAccountTotals.retentionp) }}%</td>
                  <td>{{ formatNumber(otherAccountTotals.total, 1) }}</td>
                  <td>{{ formatNumber(otherAccountTotals.totalp) }}%</td>
                  <td>{{ formatNumber(otherAccountTotals.current, 1) }}</td>
                  <td>{{ formatNumber(otherAccountTotals.currentp) }}%</td>
                  <td>{{ formatNumber(otherAccountTotals.days30, 1) }}</td>
                  <td>{{ formatNumber(otherAccountTotals.days30p) }}%</td>
                  <td>{{ formatNumber(otherAccountTotals.days60, 1) }}</td>
                  <td>{{ formatNumber(otherAccountTotals.days60p) }}%</td>
                  <td>{{ formatNumber(otherAccountTotals.daysOver90, 1) }}</td>
                  <td>{{ formatNumber(otherAccountTotals.days90p) }}%</td>
                  <td>{{ formatNumber(otherAccountTotals.daysOver90, 1) }}</td>
                  <td>{{ formatNumber(otherAccountTotals.daysOver90p) }}%</td>
                  <!-- <td>{{ formatNumber(otherAccountTotals.daysOver30, 1) }}</td>
                  <td>{{ formatNumber(otherAccountTotals.daysOver30p) }}%</td> -->
                </tr>

                <tr>
                  <td></td>
                  <td>Total:</td>
                  <td>{{ formatNumber(arCustomerTotals.totalOwing, 1) }}</td>
                  <td>{{ formatNumber(arCustomerTotals.retention, 1) }}</td>
                  <td>100%</td>
                  <td>{{ formatNumber(arCustomerTotals.total, 1) }}</td>
                  <td>100%</td>
                  <td>{{ formatNumber(arCustomerTotals.current, 1) }}</td>
                  <td>100%</td>
                  <td>{{ formatNumber(arCustomerTotals.days30, 1) }}</td>
                  <td>100%</td>
                  <td>{{ formatNumber(arCustomerTotals.days60, 1) }}</td>
                  <td>100%</td>
                  <td>{{ formatNumber(arCustomerTotals.days90, 1) }}</td>
                  <td>100%</td>
                  <td>{{ formatNumber(arCustomerTotals.daysOver90, 1) }}</td>
                  <td>100%</td>
                  <!-- <td>{{ formatNumber(arCustomerTotals.daysOver30, 1) }}</td>
                  <td>100%</td> -->
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="sh-widget card wide-xl top-account-table">
          <div class="content">
            <div class="top-section">
              <div class="">
                <h4>Top Accounts Payable</h4>
              </div>
            </div>
            <div class="table-container show-scrollbar">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th><label>AP Top Account by Vendor</label></th>
                    <th @click="setApTopAccounts('totalOwing')" :class="[{ sorted: sortBy == 'totalOwing' }, 'clickable']"><label>Total Owing</label></th>
                    <th @click="setApTopAccounts('retention')" :class="[{ sorted: sortBy == 'retention' }, 'clickable']"><label>Retention</label></th>
                    <th><label>Retention % of Total</label></th>
                    <th @click="setApTopAccounts('total')" :class="[{ sorted: sortBy == 'total' }, 'clickable']"><label>Non Retention</label></th>
                    <th><label>Non Retention % of Total</label></th>
                    <th @click="setApTopAccounts('current')" :class="[{ sorted: sortBy == 'current' }, 'clickable']"><label>Current Owing</label></th>
                    <th><label>Current %</label></th>
                    <th @click="setApTopAccounts('days30')" :class="[{ sorted: sortBy == 'days30' }, 'clickable']"><label>1-30 DAYS</label></th>
                    <th><label>1-30 DAYS %</label></th>
                    <th @click="setApTopAccounts('days60')" :class="[{ sorted: sortBy == 'days60' }, 'clickable']"><label>31-60 DAYS</label></th>
                    <th><label>31-60 DAYS %</label></th>
                    <th @click="setApTopAccounts('days90')" :class="[{ sorted: sortBy == 'days90' }, 'clickable']"><label>61-90 DAYS</label></th>
                    <th><label>61-90 DAYS %</label></th>
                    <th @click="setApTopAccounts('daysOver90')" :class="[{ sorted: sortBy == 'daysOver90' }, 'clickable']"><label>>90 DAYS</label></th>
                    <th><label>>90 DAYS %</label></th>
                    <!-- <th @click="setArTopAccounts('daysOver30')" :class="[{ sorted : sortBy == 'daysOver30'}, 'clickable']"><label>$ Total <br>31+ Days</label></th>
                  <th><label>% Total <br>31+ Days</label></th> -->
                  </tr>
                </thead>

                <tr v-for="(tat, i) in topApAccountTotals" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ tat.Vendor_Name }}</td>
                  <td>{{ formatNumber(tat.owing.totalOwing, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.retention, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.retentionp) }}%</td>
                  <td>{{ formatNumber(tat.owing.total, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.totalp) }}%</td>
                  <td>{{ formatNumber(tat.owing.current, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.currentp) }}%</td>
                  <td>{{ formatNumber(tat.owing.days30, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.days30p) }}%</td>
                  <td>{{ formatNumber(tat.owing.days60, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.days60p) }}%</td>
                  <td>{{ formatNumber(tat.owing.days90, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.days90p) }}%</td>
                  <td>{{ formatNumber(tat.owing.daysOver90, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.daysOver90p) }}%</td>
                  <!-- <td>{{ formatNumber(tat.owing.daysOver30, 1) }}</td>
                  <td>{{ formatNumber(tat.owing.daysOver30p) }}%</td> -->
                </tr>

                <tr v-if="'cats' === 'cats'">
                  <td>11</td>
                  <td>Others</td>
                  <td>{{ formatNumber(otherApAccountTotals.totalOwing, 1) }}</td>
                  <td>{{ formatNumber(otherApAccountTotals.retention, 1) }}</td>
                  <td>{{ formatNumber(otherApAccountTotals.retentionp) }}%</td>
                  <td>{{ formatNumber(otherApAccountTotals.total, 1) }}</td>
                  <td>{{ formatNumber(otherApAccountTotals.totalp) }}%</td>
                  <td>{{ formatNumber(otherApAccountTotals.current, 1) }}</td>
                  <td>{{ formatNumber(otherApAccountTotals.currentp) }}%</td>
                  <td>{{ formatNumber(otherApAccountTotals.days30, 1) }}</td>
                  <td>{{ formatNumber(otherApAccountTotals.days30p) }}%</td>
                  <td>{{ formatNumber(otherApAccountTotals.days60, 1) }}</td>
                  <td>{{ formatNumber(otherApAccountTotals.days60p) }}%</td>
                  <td>{{ formatNumber(otherApAccountTotals.daysOver90, 1) }}</td>
                  <td>{{ formatNumber(otherApAccountTotals.days90p) }}%</td>
                  <td>{{ formatNumber(otherApAccountTotals.daysOver90, 1) }}</td>
                  <td>{{ formatNumber(otherApAccountTotals.daysOver90p) }}%</td>
                  <!-- <td>{{ formatNumber(otherApAccountTotals.daysOver30, 1) }}</td>
                  <td>{{ formatNumber(otherApAccountTotals.daysOver30p) }}%</td> -->
                </tr>

                <tr>
                  <td></td>
                  <td>Total:</td>
                  <td>{{ formatNumber(apCustomerTotals.totalOwing, 1) }}</td>
                  <td>{{ formatNumber(apCustomerTotals.retention, 1) }}</td>
                  <td>100%</td>
                  <td>{{ formatNumber(apCustomerTotals.total, 1) }}</td>
                  <td>100%</td>
                  <td>{{ formatNumber(apCustomerTotals.current, 1) }}</td>
                  <td>100%</td>
                  <td>{{ formatNumber(apCustomerTotals.days30, 1) }}</td>
                  <td>100%</td>
                  <td>{{ formatNumber(apCustomerTotals.days60, 1) }}</td>
                  <td>100%</td>
                  <td>{{ formatNumber(apCustomerTotals.days90, 1) }}</td>
                  <td>100%</td>
                  <td>{{ formatNumber(apCustomerTotals.daysOver90, 1) }}</td>
                  <td>100%</td>
                  <!-- <td>{{ formatNumber(apCustomerTotals.daysOver30, 1) }}</td>
                  <td>100%</td> -->
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'

import moment from 'moment'
import { mapGetters } from 'vuex'
import CustomerTopCashFlow from './CustomerTopCashFlow.vue'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  components: { CustomerTopCashFlow, WaitingSpinner },
  data() {
    return {
      data: [],
      apData: [],
      arData: [],
      topAccountTotals: [],
      otherAccountTotals: {},
      arCustomerTotals: {},
      sortBy: '',
      topApAccountTotals: [],
      otherApAccountTotals: {},
      apCustomerTotals: {},
      unapprovedInvoices: [],
      unapprovedInvoiceTotal: 0,
      wipTotal: 0
    }
  },

  computed: {
    ...mapGetters(['customers', 'customerSettingsAR', 'arItems', 'vendors']),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    }
  },

  methods: {
    fetchApData() {
      return new Promise(async (resolve, reject) => {
        this.$bus.$emit('setWaiting', { name: 'fetchAPSummaryItems', message: 'Getting data' })

        // fetch all items at same time, do stuff after all fetched
        await Promise.all([
          (async () =>
            this.$store.dispatch('getVendors').catch(res => {
              this.$snack.open('Problem getting Spectrum customer info', 'error')
            }))(),
          (async () =>
            appFuncs.shRequest({ action: 'get_accounts_payable' }).catch(res => {
              this.$snack.open('Problem getting AP items', 'error')
              console.log(res)
            }))()
        ])
          .then(arr => {
            if (Array.isArray(arr[1])) {
              this.apData = arr[1]
            }
            resolve()
          })
          .catch(err => {
            reject(err)
          })

        this.$bus.$emit('stopWaiting', 'fetchAPSummaryItems')

        if (!this.apData.length || !this.vendors.length) {
          this.$snack.open('Problem fetching some data', 'error')
          return
        }
      })
    },

    fetchArData() {
      ;(async () => {
        this.$bus.$emit('setWaiting', { name: 'fetchARSummaryItems', message: 'Getting data' })

        // fetch all items at same time, do stuff after all fetched
        await Promise.all([
          (async () =>
            this.$store.dispatch('getCustomers').catch(res => {
              this.$snack.open('Problem getting Spectrum customer info', 'error')
            }))(),
          (async () =>
            this.$store.dispatch('getARItems').catch(res => {
              this.$snack.open('Problem getting receivable items', 'error')
              console.log(res)
            }))(),
          (async () =>
            this.$store.dispatch('getCustomerSettingsAR').catch(res => {
              this.$snack.open('Problem getting customer account settings', 'error')
              console.log(res)
            }))()
        ])

        this.$bus.$emit('stopWaiting', 'fetchARSummaryItems')
        this.setArTopAccounts()
      })()
    },

    setArTopAccounts(sort) {
      // for each customer total amount owing in each period
      // when period changes, order customers by amount owing within each period

      let customers = [...this.customers]
      let arItems = [...this.arItems]
      let res = []
      // arItems = arItems.filter(itm => !itm.Invoice_Due_Date);
      // console.log(arItems);

      let dftotal = 0
      for (let ari = 0; ari < arItems.length; ari++) {
        dftotal += parseFloat(arItems[ari].Invoice_Balance)
      }
      console.log(dftotal)

      for (let ci = 0; ci < customers.length; ci++) {
        customers[ci].owing = { daysOver90: 0, days90: 0, days60: 0, days30: 0, total: 0, daysOver30: 0, current: 0, retention: 0, totalOwing: 0 }
        for (let ari = 0; ari < arItems.length; ari++) {
          if (customers[ci].Customer_Code == arItems[ari].Customer_Code) {
            const days = this.getDays(arItems[ari].Invoice_Due_Date ? arItems[ari].Invoice_Due_Date : arItems[ari].Invoice_Date)
            const amt = parseFloat(arItems[ari].Invoice_Amount - arItems[ari].Retention_Balance)

            // total is now nonRetention
            const nonRetention = parseFloat(arItems[ari].Invoice_Balance) - parseFloat(arItems[ari].Retention_Balance)
            customers[ci].owing.total += nonRetention

            const totalOwing = parseFloat(arItems[ari].Invoice_Balance)
            customers[ci].owing.retention += parseFloat(arItems[ari].Retention_Balance)
            customers[ci].owing.totalOwing += totalOwing

            if (days >= 31) {
              customers[ci].owing.daysOver30 += nonRetention
            }

            if (days <= 0) {
              customers[ci].owing.current += nonRetention
            } else if (days >= 1 && days <= 30) {
              customers[ci].owing.days30 += nonRetention
            } else if (days >= 31 && days <= 60) {
              customers[ci].owing.days60 += nonRetention
            } else if (days >= 61 && days <= 90) {
              customers[ci].owing.days90 += nonRetention
            } else if (days >= 91) {
              customers[ci].owing.daysOver90 += nonRetention
            }
          }
        }
        res.push(customers[ci])
      }

      sort = sort || 'totalOwing'
      this.sortBy = sort

      res.sort((a, b) => {
        return b.owing[sort] - a.owing[sort]
      })

      let topRes = []
      let others = { daysOver90: 0, days90: 0, days60: 0, days30: 0, total: 0, daysOver30: 0, current: 0, retention: 0, totalOwing: 0 }
      let totals = { daysOver90: 0, days90: 0, days60: 0, days30: 0, total: 0, daysOver30: 0, current: 0, retention: 0, totalOwing: 0 }
      for (let ti = 0; ti < res.length; ti++) {
        if (ti < 10) {
          topRes.push(res[ti])
        } else {
          others.daysOver90 += res[ti].owing.daysOver90
          others.days90 += res[ti].owing.days90
          others.days60 += res[ti].owing.days60
          others.days30 += res[ti].owing.days30
          others.current += res[ti].owing.current
          others.total += res[ti].owing.total
          others.daysOver30 += res[ti].owing.daysOver30
          others.retention += res[ti].owing.retention
          others.totalOwing += res[ti].owing.totalOwing
        }
        totals.daysOver90 += res[ti].owing.daysOver90
        totals.days90 += res[ti].owing.days90
        totals.days60 += res[ti].owing.days60
        totals.days30 += res[ti].owing.days30
        totals.current += res[ti].owing.current
        totals.total += res[ti].owing.total
        totals.daysOver30 += res[ti].owing.daysOver30
        totals.retention += res[ti].owing.retention
        totals.totalOwing += res[ti].owing.totalOwing
      }

      // set % of AR now that totals are set

      // This is the %. You will divide the dollars in 0-30 days by the total Dollars of all AR to get this value
      for (let ti = 0; ti < res.length; ti++) {
        res[ti].owing.totalp = ((res[ti].owing.total / totals.total) * 100).toFixed(0)
        res[ti].owing.currentp = ((res[ti].owing.current / totals.current) * 100).toFixed(0)
        res[ti].owing.days30p = ((res[ti].owing.days30 / totals.days30) * 100).toFixed(0)
        res[ti].owing.days60p = ((res[ti].owing.days60 / totals.days60) * 100).toFixed(0)
        res[ti].owing.days90p = ((res[ti].owing.days90 / totals.days90) * 100).toFixed(0)
        res[ti].owing.daysOver30p = ((res[ti].owing.daysOver30 / totals.daysOver30) * 100).toFixed(0)
        res[ti].owing.daysOver90p = ((res[ti].owing.daysOver90 / totals.daysOver90) * 100).toFixed(0)
        res[ti].owing.retentionp = ((res[ti].owing.retention / totals.retention) * 100).toFixed(0)
      }

      others.totalp = ((others.total / totals.total) * 100).toFixed(0)
      others.currentp = ((others.current / totals.current) * 100).toFixed(0)
      others.days30p = ((others.days30 / totals.days30) * 100).toFixed(0)
      others.days60p = ((others.days60 / totals.days60) * 100).toFixed(0)
      others.days90p = ((others.days90 / totals.days90) * 100).toFixed(0)
      others.daysOver30p = ((others.daysOver30 / totals.daysOver30) * 100).toFixed(0)
      others.daysOver90p = ((others.daysOver90 / totals.daysOver90) * 100).toFixed(0)
      others.retentionp = ((others.retention / totals.retention) * 100).toFixed(0)

      totals.currentp = ((totals.current / totals.totalOwing) * 100).toFixed(0)
      totals.days30p = ((totals.days30 / totals.totalOwing) * 100).toFixed(0)
      totals.days60p = ((totals.days60 / totals.totalOwing) * 100).toFixed(0)
      totals.days90p = ((totals.days90 / totals.totalOwing) * 100).toFixed(0)
      totals.daysOver90p = ((totals.daysOver90 / totals.totalOwing) * 100).toFixed(0)
      totals.retentionp = ((totals.retention / totals.totalOwing) * 100).toFixed(0)

      this.topAccountTotals = topRes
      this.otherAccountTotals = others
      this.arCustomerTotals = totals
    },

    setApTopAccounts(sort) {
      let vendors = [...this.vendors]
      let arItems = [...this.apData]
      let res = []

      for (let ci = 0; ci < vendors.length; ci++) {
        vendors[ci].owing = { daysOver90: 0, days90: 0, days60: 0, days30: 0, total: 0, daysOver30: 0, current: 0, retention: 0, totalOwing: 0, unapproved: 0 }
        for (let ari = 0; ari < arItems.length; ari++) {
          if (vendors[ci].Vendor_Code === arItems[ari].Vendor_Code) {
            const invoiceAmt = parseFloat(arItems[ari].Invoice_Amount)
            const paymentTotal = parseFloat(arItems[ari].Payment_Total)
            const retentionTax = parseFloat(arItems[ari].Retention_VAT_Amt)
            const retentionAmt = parseFloat(arItems[ari].Retention)

            const days = this.getDays(arItems[ari].Date_List4)
            const invoiceDate = this.getDays(arItems[ari].INVOICE_DATE)
            let totalOwing = invoiceAmt - paymentTotal
            const retention = retentionAmt + retentionTax
            let amt = totalOwing - retention
            const notDueYet = invoiceDate <= 0

            if (arItems[ari].AP_post_flag_list1 == 1 || notDueYet) {
              continue
            }

            // reverse if credit
            if (arItems[ari].Invoice_Type_Code === 'C') {
              amt -= amt * 2
              totalOwing -= totalOwing * 2
            }

            vendors[ci].owing.total += amt
            vendors[ci].owing.retention += retention
            vendors[ci].owing.totalOwing += totalOwing
            if (days >= 31) {
              vendors[ci].owing.daysOver30 += amt
            }

            if (days <= 0) {
              vendors[ci].owing.current += amt
            } else if (days >= 1 && days <= 30) {
              vendors[ci].owing.days30 += amt
            } else if (days >= 31 && days <= 60) {
              vendors[ci].owing.days60 += amt
            } else if (days >= 61 && days <= 90) {
              vendors[ci].owing.days90 += amt
            } else if (days >= 91) {
              vendors[ci].owing.daysOver90 += amt
            }
          }
        }
        res.push(vendors[ci])
      }

      sort = sort || 'totalOwing'
      this.sortBy = sort

      res.sort((a, b) => {
        return b.owing[sort] - a.owing[sort]
      })

      let topRes = []
      let others = { daysOver90: 0, days90: 0, days60: 0, days30: 0, total: 0, daysOver30: 0, current: 0, retention: 0, totalOwing: 0 }
      let totals = { daysOver90: 0, days90: 0, days60: 0, days30: 0, total: 0, daysOver30: 0, current: 0, retention: 0, totalOwing: 0 }
      for (let ti = 0; ti < res.length; ti++) {
        if (ti < 10) {
          topRes.push(res[ti])
        } else {
          others.daysOver90 += res[ti].owing.daysOver90
          others.days90 += res[ti].owing.days90
          others.days60 += res[ti].owing.days60
          others.days30 += res[ti].owing.days30
          others.current += res[ti].owing.current
          others.total += res[ti].owing.total
          others.daysOver30 += res[ti].owing.daysOver30
          others.retention += res[ti].owing.retention
          others.totalOwing += res[ti].owing.totalOwing
        }
        totals.daysOver90 += res[ti].owing.daysOver90
        totals.days90 += res[ti].owing.days90
        totals.days60 += res[ti].owing.days60
        totals.days30 += res[ti].owing.days30
        totals.current += res[ti].owing.current
        totals.total += res[ti].owing.total
        totals.daysOver30 += res[ti].owing.daysOver30
        totals.retention += res[ti].owing.retention
        totals.totalOwing += res[ti].owing.totalOwing
      }

      // const totaltemp = totals.retention + totals.daysOver90 + totals.days90 + totals.days60 + totals.days30 + totals.current + this.unapprovedInvoiceTotal

      totals.totalOwing += this.unapprovedInvoiceTotal

      // set % of AR now that totals are set

      // This is the %. You will divide the dollars in 0-30 days by the total Dollars of all AR to get this value
      for (let ti = 0; ti < res.length; ti++) {
        res[ti].owing.totalp = ((res[ti].owing.total / totals.total) * 100).toFixed(0)
        res[ti].owing.currentp = ((res[ti].owing.current / totals.current) * 100).toFixed(0)
        res[ti].owing.days30p = ((res[ti].owing.days30 / totals.days30) * 100).toFixed(0)
        res[ti].owing.days60p = ((res[ti].owing.days60 / totals.days60) * 100).toFixed(0)
        res[ti].owing.days90p = ((res[ti].owing.days90 / totals.days90) * 100).toFixed(0)
        res[ti].owing.daysOver30p = ((res[ti].owing.daysOver30 / totals.daysOver30) * 100).toFixed(0)
        res[ti].owing.daysOver90p = ((res[ti].owing.daysOver90 / totals.daysOver90) * 100).toFixed(0)
        res[ti].owing.retentionp = ((res[ti].owing.retention / totals.retention) * 100).toFixed(0)
      }

      others.totalp = ((others.total / totals.total) * 100).toFixed(0)
      others.currentp = ((others.current / totals.current) * 100).toFixed(0)
      others.days30p = ((others.days30 / totals.days30) * 100).toFixed(0)
      others.days60p = ((others.days60 / totals.days60) * 100).toFixed(0)
      others.days90p = ((others.days90 / totals.days90) * 100).toFixed(0)
      others.daysOver30p = ((others.daysOver30 / totals.daysOver30) * 100).toFixed(0)
      others.daysOver90p = ((others.daysOver90 / totals.daysOver90) * 100).toFixed(0)
      others.retentionp = ((others.retention / totals.retention) * 100).toFixed(0)

      totals.unapprovedp = ((this.unapprovedInvoiceTotal / totals.totalOwing) * 100).toFixed(0)
      totals.currentp = ((totals.current / totals.totalOwing) * 100).toFixed(0)
      totals.days30p = ((totals.days30 / totals.totalOwing) * 100).toFixed(0)
      totals.days60p = ((totals.days60 / totals.totalOwing) * 100).toFixed(0)
      totals.days90p = ((totals.days90 / totals.totalOwing) * 100).toFixed(0)
      totals.daysOver90p = ((totals.daysOver90 / totals.totalOwing) * 100).toFixed(0)
      totals.retentionp = ((totals.retention / totals.totalOwing) * 100).toFixed(0)

      this.topApAccountTotals = topRes
      this.otherApAccountTotals = others
      this.apCustomerTotals = totals
    },

    openDetails(obj) {
      // console.log(obj); return;
      let cc = obj.Customer_Code
      let customer = this.customers.find(itm => {
        return itm.Customer_Code == cc
      })

      this.$Modal({
        parent: this,
        name: 'AccountsReceivableDetails', // used for closing specific modal programmatically
        title: 'Accounts Receivable Customer Options',
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/AccountsReceivable/AccountsReceivableDetails.vue'),
        props: {
          customer
        }
      })
    },

    fetchUnapprovedInvoices() {
      return new Promise((resolve, reject) => {
        this.$bus.$emit('setWaiting', { name: 'get_unapproved_invoices', message: 'Getting data' })
        appFuncs
          .shHttp({ action: 'get_unapproved_invoices' })
          .then(res => {
            if (res.status === 'success') {
              this.unapprovedInvoiceTotal = res.total || 0
              resolve()
            } else {
              this.$snack.open(res.message || 'Problem fetching data', 'warning')
              reject()
            }
          })
          .catch(res => {
            this.$snack.open(res.message || 'Problem fetching data', 'warning')
            console.log(res)
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'get_unapproved_invoices')
          })
      })
    },

    fetchWIP() {
      // this.$bus.$emit('setWaiting', { name: 'get_over_under_billing', message: 'Getting data' });
      appFuncs
        .shHttp({ action: 'get_over_under_billing' })
        .then(res => {
          if (res.status === 'success') {
            this.wipTotal = res.total || 0
          } else {
            this.$snack.open(res.message || 'Problem fetching data', 'warning')
          }
        })
        .catch(res => {
          this.$snack.open(res.message || 'Problem fetching data', 'warning')
          console.log(res)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'get_over_under_billing')
        })
    }
  },

  async mounted() {
    this.fetchWIP()

    let arFetch = this.fetchArData()
    let apFetch = this.fetchApData()
    let unapprovedApFetch = this.fetchUnapprovedInvoices()
    let customerFetch = this.$store.dispatch('getCustomers')

    await Promise.all([arFetch, apFetch, unapprovedApFetch, customerFetch])
      .then(() => {
        this.setApTopAccounts()
      })
      .catch(() => {
        this.$snack.open('Problem fetching data', 'error')
      })
  }
}
</script>

<style lang="scss">
@import 'src/assets/sass/paper/_variables.scss';

#aging-page {
  .sh-widget {
    margin: 5px !important;
  }

  .table-container {
    // max-height:220px;
    overflow-x: hidden;
    overflow-y: scroll;

    label {
      font-size: 13px;
      font-weight: bold;
    }

    tr {
      &:nth-child(odd) {
        &:not(:last-child) {
          background: $light-gray;
        }
      }

      &:last-child {
        background: rgb(93, 93, 93);
        color: #fff;
        font-weight: bold;
      }
    }

    thead {
      tr {
        border: 2px solid $dark-gray;
        border-bottom: none;
        background: rgb(65, 120, 232) !important;

        td,
        th {
          border: 1px solid #fff;
        }

        label {
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }

  .top-account-table {
    max-width: 2000px;
    min-width: 2000px;

    table {
      th.sorted {
        label {
          width: 100%;
          position: relative;
          padding-right: 20px;
        }

        :after {
          content: '\e64b';
          font-family: 'themify';
          position: absolute;
          top: 0;
          right: 5px;
          color: #fff;
        }
      }

      tr {
        td,
        th {
          width: auto;

          &.min {
            width: 1%;
            white-space: nowrap;
          }

          // &:nth-child(even) {
          //   width: 80px;
          //   white-space: nowrap;
          // }
          &:nth-child(2) {
            width: 220px;
          }
        }

        > :nth-child(1) {
          width: 40px;
        }

        > :nth-child(2) {
          width: 150px;
        }

        &:last-child {
          // font-weight: bold;
        }
      }
    }
  }
}
</style>
