import GetJobInfo from 'src/components/Dashboard/Jobs/GetJobInfo.vue'
import GetWOInfo from 'src/components/Dashboard/Jobs/GetWOInfo.vue'
import WOFiles from 'src/components/Dashboard/JobWo/WOFiles.vue'
import WOContacts from 'src/components/Dashboard/JobWo/WOContacts.vue'
import SignatureSave from 'components/Dashboard/Forms/SignatureSave.vue'

import BhTableAddEdit from 'src/components/Dashboard/Jobs/BhTableAddEdit.vue'
import AddCustomer from 'src/components/Dashboard/Contacts/AddCustomer.vue'

import CustomerContacts from 'src/components/Dashboard/Contacts/CustomerContactList.vue'
import AddTimeEntry from 'src/components/Dashboard/TimeEntry/AddTimeEntry.vue'

import Checkbox from 'vue-material-checkbox'

import MaterialsCategoryTable from 'components/Dashboard/Materials/MaterialsCategoryTable.vue'

// import intl from 'intl'
// import 'intl/locale-data/jsonp/en-CA'

// global.Intl = intl

import VCalendar from 'v-calendar'
import 'v-calendar/lib/v-calendar.min.css'
import wysiwyg from 'vue-wysiwyg-improved'

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component('get-job-info', GetJobInfo)
    Vue.component('get-wo-info', GetWOInfo)
    Vue.component('bh-table-add-edit', BhTableAddEdit)
    Vue.component('add-customer', AddCustomer)
    Vue.component('customer-contacts', CustomerContacts)
    Vue.component('add-time-entry', AddTimeEntry)
    Vue.component('add-time-entry-duplicated', AddTimeEntry)
    Vue.component('wo-files', WOFiles)
    Vue.component('wo-contacts', WOContacts)
    Vue.component('signature-save', SignatureSave)

    // keep this as global to avoid issues with loading when already loaded in parent? component.
    Vue.component('materials-category-table', MaterialsCategoryTable)

    Vue.use(VCalendar, {
      locale: 'en-CA'
    })

    Vue.use(Checkbox)

    Vue.use(wysiwyg, {
      hideModules: {
        justifyLeft: true,
        justifyRight: true,
        justifyCenter: true,
        link: true,
        code: true,
        image: true,
        table: true
      },
      forcePlainTextOnPaste: false
    })
  }
}

export default GlobalComponents
