
<div>
  <div class="row">
    <div class="col-md-12">
      <div class="tab-section outline white">
        <ul class="nav nav-tabs">
          <li role="presentation" :class="[{ active: tab == 'Statement Setup' }]">
            <a href="#" @click="tab = 'Statement Setup'"> Statement Setup </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Routing Code' }]">
            <a href="#" @click="tab = 'Routing Code'"> Routing Code </a>
          </li>
        </ul>
        <div :class="['tab-content', { 'first-active': tab === 'Statement Setup' }]">
          <div class="tab-content-inner">
            <div v-if="tab == 'Statement Setup'">
              <div class="content">
                <h4>
                  <strong>{{ fields.Name }}</strong>
                </h4>
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <label>Last Statement Sent</label>
                      <p v-if="fields.last_sent">
                        {{ fields.last_sent }}
                      </p>
                      <p v-else>
                        <i>none</i>
                      </p>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <span class="inline-checkbox">
                        <checkbox2 v-model="fields.disable_auto_send" @change="changed = true" :true-val="1" :false-val="0"> Disable Customer Report Auto Send </checkbox2>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <label>Default AR Contacts</label>
                      <select-field-multi
                        :options="contacts"
                        :option-display-keys="['Name']"
                        :selected-display-keys="['Email']"
                        :option-val="'sfId'"
                        v-model="fields.ar_contacts_default"
                        :placeholder-text="'Add contact'"
                        :preselect-single-option="false"
                        @input="changed = true"
                        :allow-add-simple-item="true"
                        item-name="Email Address"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <label>Extra Contacts (manual send only)</label>
                      <select-field-multi
                        :options="contacts"
                        :option-display-keys="['Name']"
                        :selected-display-keys="['Email']"
                        :option-val="'sfId'"
                        v-model="fields.ar_contacts_extra"
                        :placeholder-text="'Add contact'"
                        :preselect-single-option="false"
                        @input="changed = true"
                        :allow-add-simple-item="true"
                        item-name="Email Address"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Custom Text (manual send only)</label>
                  <textarea
                    @change="changed = true"
                    class="form-control"
                    :value="decode(fields.custom_text)"
                    @input="fields.custom_text = encode($event.target.value, 'stripTags')"
                    rows="6"
                    placeholder="Leave empty to use default text."
                  />
                </div>

                <button class="btn" @click="saveSettings">Save Settings</button>

                <a v-if="previewLink" class="btn" @click="preview" :href="previewLink" target="_blank" :disabled="changed"> Preview Report </a>

                <button class="btn" @click="send" :disabled="changed">Send Report Now</button>

                <button class="btn" @click="download">Download Report</button>
              </div>
            </div>
            <div v-if="tab == 'Routing Code'">
              <div class="">
                <h5>
                  Accounts Receivable (Billing) Routing Code
                </h5>
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <label>Routing Code</label>
                      <select-field
                        :options="billingRoutingCodes"
                        :option-display-keys="['routing_code', 'routing_description']"
                        :option-val="'routing_code'"
                        v-model="customer.billing_routing_code"
                        placeholder-text="Assign Routing Code"
                        :label="'Routing Code'"
                        :disabled="!isCaptain && !isAdmin"
                      />
                    </div>
                  </div>
                </div>
                <button class="btn" @click="updateAppCustomer">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner />
</div>
