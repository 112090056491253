
<div>
  <div class="sh-widget card wide-large">
    <div class="content">

      <div class="top-section">
        <div class="">
          <h4>Top Cashflow</h4>
        </div>
        <checkbox2 :true-val="true" :false-val="false" v-model="showAll" label="Show All">Show All</checkbox2>
      </div>

      <div class="tab-section outline white border-bottom">
        <ul class="nav nav-tabs sub-tabs">
          <li role="presentation" :class="[{ active: tab1 == 'Positive' }]">
            <a href="#" @click="tab1 = 'Positive'">
              Positive
            </a>
          </li>
          <li role="presentation" :class="[{ active: tab1 == 'Negative' }]">
            <a href="#" @click="tab1 = 'Negative'">
              Negative
            </a>
          </li>
        </ul>
      </div>

      <div class="tab-section outline white border-bottom">
        <ul class="nav nav-tabs sub-tabs">
          <li role="presentation" :class="[{ active: tabMain == 'Customers' }]">
            <a href="#" @click="tabMain = 'Customers'">
              Customers
            </a>
          </li>
          <li role="presentation" :class="[{ active: tabMain == 'Jobs' }]">
            <a href="#" @click="tabMain = 'Jobs', getJobData()">
              Jobs
            </a>
          </li>
        </ul>
      </div>

      <div class="table-container show-scrollbar">
        <table>
          <thead>
          <tr>
            <th></th>
            <th v-if="tabMain === 'Jobs'" class="job"><label>Job</label></th>
            <th v-if="tabMain === 'Jobs'" class="desc"><label>Description</label></th>
            <th><label>Customer</label></th>
            <th><label>Cash In</label></th>
            <th><label>Cash Out</label></th>
            <th><label>Net Cash Flow</label></th>
          </tr>
          </thead>
          <tr v-for="(d, i) in tableData" :key="i">
            <td>{{ d.number }}</td>
            <td v-if="tabMain === 'Jobs'" class="job">{{ d.Job_Number }}</td>
            <td v-if="tabMain === 'Jobs'" class="desc">{{ d.Job_Description }}</td>
            <td>{{ d.Customer_Name }}</td>
            <td>{{ formatNumber(d.Cash_In, 1, true) }}</td>
            <td>{{ formatNumber(d.Cash_Out, 1, true) }}</td>
            <td>{{ formatNumber(d.Net_Cash_Flow, 1, true) }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <waiting-spinner :wait="wait" :contained="true"/>
</div>
