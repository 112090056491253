
<div class="bh-table-container">
  <div v-if="!hideSearch" class="form-group">
    <input type="text" :class="['form-control', 'search-all', { 'not-empty': filterAll }]" v-model="filterAll" placeholder="Filter" @input="checkEmitEmptySearch" />
  </div>
  <div class="shtable-table shtable-show table-responsive show-scrollbars">
    <table :class="tableClasses" :style="[{ tableLayout: tableSettings.tableLayout }]">
      <thead>
        <tr class="header-row">
          <th v-if="preFilteredShow(key)" v-for="(field, key) in fields" @click="sortColumns(key, field)" :class="[field.name, key, fields[key]['class']]" :style="fields[key]['style']">
            <span v-html="field.name !== null ? field.name : key" />
            <i v-if="sortCol == key && !sortColReverse" class="ti-angle-up" />
            <i v-else-if="sortCol == key" class="ti-angle-down" />
          </th>
          <th v-if="!hideItemEdit && !noItemEdit" class="edit-col">
            <!-- edit col -->
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr class="filter-row" v-if="showColFilter">
      <td v-for="(field, key) in fields" v-if="filterRow && preFilteredShow(key) && !field['noFilter']">
        <input type="text" v-model="shtableFilter[key]" placeholder="Filter">
      </td>
      <td v-else-if="!preFiltered[key]" class="empty" />
      <td v-if="!hideItemEdit && !noItemEdit" class="edit-col">
      </td>
    </tr> -->
        <tr v-if="shItem.id || shItem.id === 0" v-for="(shItem, shk) in filteredShItems" :key="shk" :data-row-id="shItem.id" :class="['shItem', { editable: !noItemEdit }, shItem['class']]">
          <!-- show each item - if key is in prefiltered vals array -->
          <td
            v-for="(field, key) in fields"
            v-if="preFilteredShow(key)"
            :data-column-name="key"
            :class="[{ wide: fields[key]['wide'] }, key, fields[key]['class']]"
            :key="key"
            :style="[fields[key]['style'], { position: 'relative' }]"
          >
            <!-- item to trigger custom action -->
            <a
              v-if="shItem[key] && shItem[key]['action']"
              @click="customAction(shItem[key]['action'], shItem, $event)"
              v-html="shItem[key]['title']"
              :class="['linked-item', { centered: shItem[key]['centered'] }]"
            />

            <span v-else-if="field.type == 'checkbox'">
              <checkbox v-on:change="checkboxUpdate(shItem.id, key, $event)" :checked="shItem[key] == true ? true : false" />
            </span>

            <!-- if normal item -->
            <span
              v-else-if="!noItemEdit"
              :class="['editable', { centered: fields[key]['centered'] }, { 'empty-content': !shItem[key] }]"
              :data-id="shItem.id"
              @click.exact="(editRow(shItem.id, shItem), colClick(key, shItem))"
              v-on:click.alt="editRow(shItem.id, shItem, true)"
              v-html="showFormatted(key, shItem)"
              :title="'Click to edit'"
            />

            <!-- no editable item -->
            <span v-else v-html="showFormatted(key, shItem)" />
            <!-- <span style="position:absolute;top:0;height:100%;width:100%;" /> -->
          </td>

          <td v-if="!hideItemEdit && !noItemEdit && !shItem['noItemEdit']" class="edit-col">
            <span @click="editRow(shItem.id, shItem)">
              <i class="ti-pencil" />
            </span>
          </td>
          <!-- <td v-else /> not sure what this was here for -->
        </tr>
        <tr class="empty-row" v-if="!filteredShItems || !filteredShItems[0]">
          <td>No results</td>
        </tr>
      </tbody>
    </table>
  </div>
  <p v-if="!noItemEdit && !hideTip" class="table-tip">Tip: Click rows to open details.</p>
</div>
