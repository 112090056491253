
<div class="sh-widget wide-large card">
  <div class="content">
    <h3>
      My Quotes <span v-if="filter === 'overdue'">(Overdue)</span><span v-else>(In Progress)</span> ({{ count }})
      <router-link :to="{ name: 'All Quotes' }" class="ti-new-window is-link" tag="i" />
    </h3>
    <div class="content-inner show-scrollbars">
      <bh-table :table-settings="tableSettings" :style="{ width: '630px' }" @edit-item="goToItem" />
      <waiting-spinner :contained="true" />
    </div>
  </div>
</div>
