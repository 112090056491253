<template>
  <div class="signature-container">
    <div v-if="step === 1">
      <div class="tab-section outline white border-bottom">
        <ul class="nav nav-tabs">
          <li role="presentation" :class="[{ active: newContact === true }]">
            <a href="#" @click="newContact = true"> New Contact </a>
          </li>
          <li role="presentation" :class="[{ active: newContact === false }]">
            <a href="#" @click="newContact = false"> Existing Contact </a>
          </li>
        </ul>
      </div>

      <div :class="['card']">
        <div class="content tab-content border">
          <div v-if="newContact">
            <div class="row">
              <div class="form-group col-md-6">
                <label>Full Name<span class="required-star">*</span></label>
                <input class="form-control" type="text" v-model="newSignName" :placeholder="'First and Last Name'" autocomplete="off" />
              </div>
              <div class="form-group col-md-6">
                <label>Email Address<span v-if="emailSend" class="required-star">*</span></label>
                <input class="form-control" type="text" v-model="newSignEmail" :placeholder="'name@example.com'" autocomplete="off" />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="form-group col-md-6">
                <label>Full Name<span class="required-star">*</span></label>
                <select-field :options="customerContacts" :option-display-keys="['Name']" :option-val="'Id'" :option-seperator="' '" v-model="selectedContactId" :disabled="false" />
              </div>
              <div class="form-group col-md-6">
                <label>Email Address<span v-if="emailSend" class="required-star">*</span></label>
                <input class="form-control Xfake-readonly" type="text" Xreadonly v-model="signEmail" :placeholder="'name@example.com'" autocomplete="off" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-md-12">
              <checkbox v-model="emailSend" true-value="true">
                <span>Email Completed PDF</span>
              </checkbox>
            </div>
          </div>

          <button class="btn" @click="step = 2" :disabled="!formValid">Next</button>
        </div>
      </div>
    </div>

    <div v-show="step === 2" class="signature-pad-fixed">
      <div class="signature-pad-container">
        <p>Please draw signature with finger or by holding left mouse button.</p>
        <canvas id="signature-pad" />
        <br />
        <button class="btn" @click="step = 1">Prev</button>
        <button class="btn danger" @click="clearSignature">Clear</button>

        <button class="btn" @click="saveSignature">Finish</button>
      </div>
    </div>

    <waiting-spinner :wait="wait" />
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'
import SignaturePad from 'signature_pad'
import dateFormat from 'dateformat'
import SelectField from 'components/UIComponents/SelectField'
import NavTabs from 'mixins/navTabs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {
      wait: {
        message: ''
      },
      savedImagePath: '',
      urls: store.getters.urls,
      signaturePad: null,
      signName: '', // used for inputting text
      signEmail: '', // used for showing matched email
      newContact: false,
      selectedContactId: '',
      newSignName: '', // used in add new name field
      newSignEmail: '', // used in add new email field
      emailSend: 'true',
      step: 1,
      windowWidth: 0
    }
  },

  components: {
    SelectField,
    WaitingSpinner
  },

  mixins: [NavTabs],

  props: {
    itemType: {
      type: String,
      default: ''
    },
    itemId: {
      type: [Number, String],
      default: ''
    },
    signNameDefault: {
      type: String,
      default: ''
    },
    saveFolder: {
      type: String,
      required: true
    },
    signId: {
      type: String,
      default: ''
    },
    authCode: {
      type: String,
      default: ''
    },
    existingSig: {
      type: String,
      default: ''
    },
    customerContacts: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  computed: {
    date() {
      return dateFormat(null, 'mm/dd/yyyy HH:MM')
    },

    formValid() {
      if (!this.newSignName && !this.signName) {
        return false
      }
      if (!this.emailSend) return true
      if (this.newContact) {
        return this.newSignEmail
      }
      return this.signEmail
    }
  },

  methods: {
    async setUpPad() {
      this.windowWidth = window.innerWidth
      var canvas = document.querySelector('#signature-pad')
      let container = document.querySelector('.signature-pad-container')

      if (window.innerWidth > 700) {
        canvas.width = 540
        canvas.height = 280
      } else {
        canvas.width = 340
        canvas.height = 280
      }

      this.signaturePad = new SignaturePad(canvas, {
        backgroundColor: '#fff'
      })

      this.setCanvasText()
    },

    saveSignature() {
      let isEmpty = this.signaturePad.isEmpty()

      if (!confirm('Is signature complete?')) {
        return
      }

      if (isEmpty) {
        this.$snack.open('Signature must not be empty', 'warning')
        return
      }

      if (this.newContact && (!this.newSignName || this.newSignName.trim().split(' ').length < 2)) {
        this.$snack.open('Full name must be entered for signature', 'warning')
        return
      }

      let signName = this.signName
      let signEmail = this.signEmail

      if (this.newContact) {
        signName = this.newSignName
        signEmail = this.newSignEmail
      }

      if (this.emailSend) {
        if (!signEmail) {
          this.$snack.open('Email address missing', 'warning')
          return
        }
      }

      let sigFileData = this.signaturePad.toDataURL('image/jpeg')

      let params = {
        action: 'save_signature',
        auth_code: this.authCode,
        img_data: sigFileData,
        sign_name: signName,
        path: this.saveFolder
      }

      this.wait.message = 'Saving signature'

      appFuncs
        .shRequest(params, 1)
        .then(data => {
          this.step = 1
          this.savedImagePath = data.path
          this.setCanvasText()
          this.wait.message = ''
          this.$snack.open('Signature saved successfully')

          this.$bus.$emit('modalClose', 'signature-save')

          if (!this.emailSend) {
            signEmail = null
          }

          this.$bus.$emit('signatureSaved', {
            path: data.path,
            saveDoc: true,
            emailTo: signEmail
          })

          //this.$parent.saveEmailPDF(signEmail);
        })
        .catch(result => {
          this.wait.message = ''
          console.log(result)
          this.$snack.open(result.message, 'error')
        })
    },

    clearSignature() {
      if (!this.signaturePad) return
      let sig = this.signaturePad.clear()
      this.setCanvasText()
    },

    setCanvasText() {
      if (!this.signaturePad) return
      let padText = ''
      if (this.itemType && this.itemId) {
        padText = this.itemType + ' #' + this.itemId
      }
      let loadName = ''
      if (!this.newContact) {
        loadName = this.signName
      } else {
        loadName = this.newSignName
      }

      let nameParts = loadName.split(' ')
      for (var i = nameParts.length - 1; i >= 0; i--) {
        if (nameParts[i].length > 2) {
          nameParts[i] = nameParts[i].charAt(0).toUpperCase() + nameParts[i].slice(1)
        }
      }
      let name = nameParts.join(' ')

      let canvas = document.querySelector('#signature-pad')
      let ctx = canvas.getContext('2d')

      ctx.font = '16px Arial'

      ctx.fillText(padText, 10, 30)
      ctx.fillText('Signed: ' + name, 10, 245)
      ctx.fillText('On: ' + this.date, 10, 265)
    },

    newSignature() {
      this.savedImagePath = ''
      setTimeout(() => {
        this.signName = this.signNameDefault
        this.setUpPad()
        this.setCanvasText()
      }, 500)
    },

    matchSetContact() {
      let match = this.customerContacts.find(itm => {
        return itm.Name == this.signNameDefault
      })

      if (match) {
        this.selectedContactId = match.Id
        this.signName = match.FirstName + ' ' + match.LastName
        this.signEmail = match.Email
      } else {
        this.newSignName = this.signNameDefault
      }
    }
  },

  mounted() {
    if (this.existingSig) {
      this.savedImagePath = this.urls.mssql + this.saveFolder + '/' + this.existingSig
    } else {
      this.signName = this.signNameDefault
    }
    this.matchSetContact()
  },

  watch: {
    newSignName(val) {
      this.clearSignature()
      this.setCanvasText()
    },
    newContact(val) {
      this.clearSignature()
      this.setCanvasText()
    },
    selectedContactId(val) {
      let match = this.customerContacts.find(itm => {
        return itm.Id == this.selectedContactId || itm.AccountId == this.selectedContactId
      })

      if (match) {
        this.signName = match.FirstName + ' ' + match.LastName
        this.signEmail = match.Email
      } else {
        this.signName = this.signNameDefault
      }
      this.clearSignature()
      this.setCanvasText()
    },

    step() {
      if (this.step == 2) {
        this.setUpPad()
        window.addEventListener('resize', () => {
          if (this.windowWidth !== window.innerWidth) {
            this.setUpPad()
          }
        })
        window.addEventListener('orientationchange', () => {
          if (this.windowWidth !== window.innerWidth) {
            this.setUpPad()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.signature-container {
  position: relative;
  max-width: 600px;

  canvas {
    border: 1px solid #ccc;
    border-radius: 10px;
    background: transparent;
  }
}

.signature-pad-fixed {
  @media (max-width: 991px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 999999;
  }

  .signature-pad-container {
    position: relative;
    margin: 10px auto;
    max-width: 600px;

    @media (max-width: 700px) {
      max-width: 350px;
      padding: 0 5px;
    }

    @media (max-width: 991px) {
      //  padding: 0 10px;
    }
  }

  .namesig {
    position: absolute;
    bottom: 10px;
  }

  img {
    position: absolute;
    width: 200px;
    bottom: 0;
    display: block;
  }
}

.existing-sig {
  margin-bottom: 5px;
}

.new-sig-link {
  text-align: right;

  a {
    color: $font-color;
    text-decoration: underline;
  }
}
</style>
