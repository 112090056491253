
<div class="wo-files">
  <div v-if="!woError">
    <div class="">
      <div :class="['tab-section', 'white', { outline: isOutlined }]">
        <ul :class="['nav', 'nav-tabs', { 'always-pilled': stayPilled }]">
          <li v-if="isVisible('Plans')" role="presentation" :class="[{ active: tab == 'Plans' }]">
            <a href="#" @click="tab = 'Plans'"> Plans, Drawings, Other Info </a>
          </li>
          <li v-if="isVisible('PO')" role="presentation" :class="[{ active: tab == 'PO' }]">
            <a href="#" @click="tab = 'PO'"> PO Files </a>
          </li>
          <li v-if="isVisible('Pre-Work')" role="presentation" :class="[{ active: tab == 'Pre-Work' }, { 'requires-item': !checkMetWoRequirement('preWorkPhotos') }]">
            <a href="#" @click="tab = 'Pre-Work'"> Pre-Work Photos </a>
          </li>
          <li v-if="isVisible('Post-Work')" role="presentation" :class="[{ active: tab == 'Post-Work' }, { 'requires-item': !checkMetWoRequirement('postWorkPhotos') }]">
            <a href="#" @click="tab = 'Post-Work'"> Post-Work Photos </a>
          </li>
          <li v-if="isVisible('Sign Off')" role="presentation" :class="[{ active: tab == 'Sign Off' }]">
            <a href="#" @click="tab = 'Sign Off'"> Signed WOs </a>
          </li>
        </ul>
        <div :class="[{ 'first-active': tab === 'Plans' }, 'tab-content']">
          <div v-show="tab === 'Plans'" class="first-active">
            <file-upload :label="''" :path="'wo-uploads/' + woid + '/'" :subdir="'plans'" :zip-depth="-1" :name="'Plans'" :can-delete="true" />
          </div>
          <div v-show="tab === 'Pre-Work'">
            <file-upload
              :label="''"
              :path="'wo-uploads/' + woid + '/'"
              :subdir="'pre-work'"
              :zip-depth="-1"
              :name="'Pre-Work Photos'"
              @updated="getWoRequirementItems('refresh')"
              :can-delete="true"
            />
          </div>
          <div v-show="tab === 'PO'">
            <file-upload :label="''" :path="'wo-uploads/' + woid + '/'" :subdir="'po'" :zip-depth="-1" :name="'PO Files'" :can-delete="true" :Xupload-data="uploadData" />
          </div>
          <div v-show="tab === 'Post-Work'">
            <file-upload
              :label="''"
              :path="'wo-uploads/' + woid + '/'"
              :subdir="'post-work'"
              :zip-depth="-1"
              :name="'Post-Work Photos'"
              @updated="getWoRequirementItems('refresh')"
              :can-delete="true"
            />
          </div>
          <div v-show="tab === 'Sign Off'">
            <file-upload :label="''" :path="'wo-uploads/' + woid + '/'" :subdir="'sign-off'" :zip-depth="-1" :name="'Sign Off'" :can-delete="false" />
          </div>
          <div v-if="!tab">
            <p>Select a file category</p>
          </div>
        </div>
      </div>
    </div>
    <button v-if="inSequence" class="btn" @click="next" v-html="'Next'" />
  </div>
  <p v-else>Problem loading WO Data, please close and continue from WO Details pages.</p>
</div>
