<template>
  <div>
    <alert />
    <div class="card">
      <div class="content">
        <div v-for="(field, key) in componentData.fields" class="form-group" v-if="!field.hiddenView">
          <label v-html="field.name ? field.name : key"></label><span class="required-star" v-if="field.required">*</span>
          <input v-if="field.use === 'phone'" type="text" class="form-control" v-model="formVals[key]" :readonly="field.readonly" :required="field.required" v-mask="chooseMask(formVals[key])" />
          <input v-else v-bind:type="[field.type ? field.type : 'text']" class="form-control" v-model="formVals[key]" :readonly="field.readonly" :required="field.required" />
        </div>
        <div class="button-container" v-if="componentData.editId">
          <button class="btn" @click="updateData">Update {{ componentData.itemType }}</button>
          <button class="btn" @click="deleteData">Remove {{ componentData.itemType }}</button>
        </div>
        <div v-else>
          <button class="btn" @click="addData">Add {{ componentData.itemType }}</button>
        </div>
      </div>
    </div>

    <waiting-spinner />
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'
import Alert from 'components/UIComponents/Alert'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  components: {
    Alert,
    WaitingSpinner
  },

  data() {
    return {
      urls: store.getters.urls,
      formVals: this.componentData.fieldDefaults || {}
    }
  },

  methods: {
    getData() {
      appFuncs.getTableData(this.componentData.dbTable, result => {
        if (result.status == 'success') {
          for (var i = result.data.length - 1; i >= 0; i--) {
            if (this.componentData.editId == result.data[i]['id']) {
              this.formVals = result.data[i]
              break
            }
          }
        } else {
          this.$snack.open(result.message, 'error')
        }
      })
    },

    updateData() {
      if (!this.validateFields()) {
        return
      }
      // // strip any phone number vals
      // let phoneNames = ['Phone Number', 'Direct Line'];
      // for (var prop in this.formVals) {
      //   if (this.formVals.hasOwnProperty(prop)) {
      //     if (phoneNames.indexOf(prop) !== -1) {
      //       this.formVals[prop] = this.stripToNumber(this.formVals[prop]);
      //     }
      //   }
      // }

      var rowId = this.componentData.editId
      appFuncs.updateTableData(this.componentData.dbTable, rowId, this.formVals, result => {
        this.$bus.$emit('bhUpdate', this.formVals)
        this.$store.commit('modal', { trigger: 0 })
        var modalName = this.componentData.modalName
        if (modalName) {
          this.$bus.$emit('modalClose', modalName)
        }
        setTimeout(() => {
          this.$snack.open(this.componentData.itemType + ' updated successfully', 'success')
        }, 500)
      })
    },

    addData() {
      if (!this.validateFields()) {
        return
      }
      appFuncs.addTableData(this.componentData.dbTable, this.formVals, result => {
        this.$bus.$emit('bhUpdate', this.formVals)
        this.formVals = {}
        this.$store.commit('modal', { trigger: 0 })
        var modalName = this.componentData.modalName
        if (modalName) {
          this.$bus.$emit('modalClose', modalName)
        }
      })
    },

    deleteData() {
      var self = this
      if (!confirm("Are you sure you'd like to delete this " + this.componentData.itemType + '?')) {
        return
      }

      var data = {
        action: 'delete_table_data',
        table: this.componentData.dbTable,
        row_id: this.formVals.id
      }

      appFuncs.ajax_request(store.getters.sherAuth, data, result => {
        if (result.status === 'success') {
          this.$bus.$emit('bhUpdate', this.formVals)
          var modalName = this.componentData.modalName
          console.log(this.componentData)
          if (modalName) {
            this.$bus.$emit('modalClose', modalName)
          }
          this.$store.commit('modal', { trigger: 0 })
        } else {
          this.$snack.open(result.message, 'error')
        }
      })
    },

    validateFields() {
      // validate declared fields with required property
      this.$store.commit('alerts', {})
      var fields = this.componentData.fields
      for (var key in fields) {
        if (!fields.hasOwnProperty(key)) continue // skip if prop is from prototype

        if (fields[key]['required']) {
          if (!this.formVals[key]) {
            this.$snack.open(fields[key]['name'] + ' is required.', 'warning')
            return
          }
        }
      }
      return true
    }
  },

  mounted() {
    this.getData()
  },

  props: ['componentData']
}
</script>

<style lang="scss" scoped>
.card .content {
  padding: 15px;
}

.button-container {
  padding: 0 10px 10px;
}

.form-group {
  max-width: 33%;
  width: 100%;
  display: inline-block;
  padding: 10px;

  @media (max-width: 1000px) {
    max-width: 50%;
  }
  @media (max-width: 600px) {
    max-width: 100%;
  }
}
</style>
