
<div>
  <div class="row" v-if="typeFilter">
    <div class="col-sm-3">
      <button class="btn" @click="openPO()" style="margin: 0 0 20px">New PO</button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="po-table-container">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3" style="margin-bottom: 20px">
            <label>Type</label>
            <select-field
              :options="[{ name: 'Job' }, { name: 'WO' }]"
              :label-text="'Type'"
              :option-display-keys="['name']"
              :option-val="'name'"
              v-model="typeFilter"
              :allow-clear="true"
              @selectItem="getPOs()"
            />
          </div>
          <div v-if="typeFilter === 'Job'" class="col-sm-12 col-md-6 col-lg-3" style="margin-bottom: 20px">
            <label>Jobs</label>
            <select-field
              v-model="filterJob"
              :options="jobAssemble"
              :option-display-keys="['Job_Number', 'Name', 'Job_Description']"
              :option-val="'Job_Number'"
              :empty-option-text="'No items.'"
              @selectItem="getPOs()"
              :allow-clear="true"
            />
          </div>
          <div v-if="typeFilter === 'WO'" class="col-sm-12 col-md-6 col-lg-3" style="margin-bottom: 20px">
            <label>WOs</label>
            <select-field
              v-model="filterWO"
              :options="woAssemble"
              :option-display-keys="['WO_Number', 'Name', 'Summary_Description']"
              :option-val="'WO_Number'"
              :empty-option-text="'No items.'"
              @selectItem="getPOs()"
              :allow-clear="true"
            />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3" style="margin-bottom: 20px">
            <label>Vendor</label>
            <select-field
              :options="vendors"
              :label-text="'Vendors'"
              :option-display-keys="['Vendor_Name']"
              :option-val="'Vendor_Code'"
              v-model="vendorFilter"
              :allow-clear="true"
              @selectItem="getPOs()"
            />
          </div>
          <!-- <div class="col-sm-12 col-md-6 col-lg-3">
            <label>Customer</label>
            <select-field :options="customers" :option-display-keys="['Name']" :option-val="'Customer_Code'" @change="val => getPOs(val)" :value="customerFilter" :allow-clear="true" />
          </div> -->
        </div>

        <div class="card">
          <div class="content">
            <h5>Purchase Orders</h5>
            <bh-table :table-settings="tableSettings" @colClick="openPO" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner :wait="wait" />
</div>
