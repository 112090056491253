<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      woPriorityDescriptions: [
        { code: '1', description: 'TBS' },
        { code: '2', description: 'In Progress' },
        { code: '11', description: 'Cancelled' },
        { code: '9', description: 'On Hold' },
        { code: '3', description: 'Need Signature' },
        { code: '10', description: 'Report Sent' },
        { code: '12', description: 'Unresponsive - No Sign Off' },
        { code: '13', description: 'Signed' },
        { code: '4', description: 'Ok to Bill' }
      ],
      woDispatchStatuses: [
        { code: '1', description: 'Open' },
        { code: '2', description: 'Scheduled' },
        { code: '3', description: 'Assigned' },
        { code: '4', description: 'On-Going' },
        { code: '5', description: 'On-Hold' },
        { code: '6', description: 'Cancelled' },
        { code: '7', description: 'Finished' },
        { code: 'C', description: 'Complete' }
      ]
    }
  },

  computed: {
    ...mapGetters(['userAuthedData', 'woRequirementItems', 'woOptions', 'woAssemble', 'viewAsEmployeeId']),

    isAdmin() {
      if (!this.userAuthedData?.user_role) {
        return false
      }
      return this.userAuthedData?.user_role?.indexOf('admin') !== -1
    },

    isFinished() {
      if (!this.wo.Dispatch_Status_Code) {
        return false
      }
      let unfinishedCodes = ['1', '2', '3', '4', '5']
      return unfinishedCodes.indexOf(this.wo.Dispatch_Status_Code.toString()) === -1
    },

    woRequiresDetailedNotes() {
      return ((this.woOptions || {}).other || {}).require_detailed_notes || false
    },

    woRequirementList() {
      let files = this.files || this.woOptions.files
      let notes = this.notes || this.woOptions.notes
      let all = []
      if (files) {
        for (var prop in files) {
          if (files.hasOwnProperty(prop)) {
            if (files[prop]) {
              all.push(prop)
            }
          }
        }
      }
      if (notes) {
        for (var prop in notes) {
          if (notes.hasOwnProperty(prop)) {
            if (notes[prop]) {
              all.push(prop)
            }
          }
        }
      }
      return all
    },

    missingWoRequirements() {
      let files = this.files || this.woOptions.files
      let notes = this.notes || this.woOptions.notes
      let requirementItems = this.woRequirementItems

      let missing = []
      if (files) {
        for (var prop in files) {
          if (files.hasOwnProperty(prop)) {
            if (files[prop] === 'true' && (!requirementItems[prop] || !requirementItems[prop].data || !requirementItems[prop].data.length)) {
              missing.push(prop)
            }
          }
        }
      }

      if (notes) {
        for (var prop in notes) {
          if (notes.hasOwnProperty(prop)) {
            if (notes[prop] === 'true' && (!requirementItems[prop] || !requirementItems[prop].data || !requirementItems[prop].data.length)) {
              missing.push(prop)
            }
          }
        }
      }
      return missing
    },

    hasMissingWoRequirements() {
      if (!this.missingWoRequirements || (this.missingWoRequirements && this.missingWoRequirements.length)) {
        return true
      }
      return false
    },

    myWOs() {
      let myWOs = this.woAssemble.filter(itm => {
        const viewAsEmployeeId = this.viewAsEmployeeId ? this.viewAsEmployeeId : this.userAuthedData?.eid
        if (itm.PM == viewAsEmployeeId || itm.lead == viewAsEmployeeId) {
          return true
        }
        if (!viewAsEmployeeId || (itm.techs && itm.techs.length && itm.techs.indexOf(viewAsEmployeeId.toString()) !== -1)) {
          return true
        }
      })
      return myWOs
    },

    myWOIds() {
      let myWOIds = this.myWOs.map(itm => {
        return itm.WO_Number
      })

      if (!myWOIds.length) {
        // return non existing array val to avoid empty filtering
        return ['NONE']
      }
      return myWOIds
    }
  },

  methods: {
    getWoRequirementItems(refresh) {
      if (!this.woid) {
        return
      }
      this.$store.dispatch('getWoRequirementItems', { woid: this.woid, refresh })
    },

    checkMetWoRequirement(itms) {
      if (Array.isArray(itms)) {
        let noMissing = true
        for (var i = 0; i < itms.length; i++) {
          if (this.missingWoRequirements.indexOf(itms[i]) !== -1) {
            noMissing = false
            break
          }
        }
        return noMissing
      } else {
        return this.missingWoRequirements.indexOf(itms) === -1
      }
    },

    checkIsWOItemRequired(typeKey) {
      return this.woRequirementList.indexOf(typeKey) !== -1
    }
  },

  mounted() {
    this.getWoRequirementItems()
  },

  watch: {
    woid() {
      if (this.woid) {
        this.getWoRequirementItems(true)
      }
    }
  }
}
</script>
