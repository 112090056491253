<template>
  <div style="height:100%;">
    <simple-header/>
    <div class="material-lists">
      <div class="row">
        <div class="col-sm-12">
          <material-lists :list-type="listType"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleHeader from 'components/Dashboard/Misc/SimpleHeader';
import MaterialLists from 'components/Dashboard/Materials/RFPLists';

export default {
  name: 'RFPListPage',
  data() {
    return {};
  },

  components: {
    SimpleHeader,
    MaterialLists
  },

  props: {
    listType: {
      type: String,
      default: ''
    }
  }

};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/paper/_variables.scss";

.material-lists {
  padding: 5px 30px;
  background: #fff; //$bg-grey;
  height: 100%;
}

</style>
