
<div>
  <div class="sh-widget basic-app-widget widget-table wide-large card hours-tracker-widget" style="min-height: 470px">
    <div>
      <div class="content">
        <div class="widget-header" style="margin-bottom: 20px">
          <h4>Hours Tracker</h4>
          <div class="row">
            <div class="col-sm-12 col-md-6" v-if="showDepartmentSelector">
              <div class="form-group">
                <select-department-as-employee :value="selectedDepartment" :employee-id="employeeId" />
              </div>
            </div>

            <div class="col-sm-12 col-md-5 pull-right">
              <div class="form-group">
                <span
                  style="padding-left: 3px"
                  class="ti-info-alt"
                  v-tooltip="{
                    content: 'Fiscal year shown is 1 year behind actual fiscal year',
                    placement: 'bottom',
                    hideOnTargetClick: false,
                    delay: { show: 200, hide: 2500 },
                    offset: 5,
                    classes: ['wide']
                  }"
                />
                <select-field
                  :options="Array.from({ length: new Date().getFullYear() - 2020 + 1 }, (_, i) => ({ value: (fiscalYear - i).toString() }))"
                  :option-display-keys="['value']"
                  :option-val="'value'"
                  v-model="viewFiscalYear"
                  @selectItem="val => this.getHoursStats()"
                  style="display: inline-block; margin: 0 0 0 10px"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="content-inner">
          <tabulator-table
            :table-data="tableDataHours"
            :table-columns="tableColumnsHours"
            table-fit="fitColumns"
            :table-condensed="false"
            empty-text="(none)"
            @colClick="colClick"
            :selectable="false"
            ref="hoursTable"
            :header-visible="false"
          />
          <DonutChart :series="[30, 50, 20]" :dataHours="tableDataHours" :labels="['Red', 'Blue', 'Yellow']" />
        </div>
      </div>
    </div>
    <waiting-spinner :contained="true" />
  </div>
</div>
