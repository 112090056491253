<template>
  <div>
    <div class="content">
      <div class="card">
        <h5>Edit Field</h5>
        <div v-if="!singleField" class="form-group">
          <label>Field Name</label>
          <input type="text" v-model="localObj.name" class="form-control"/>
        </div>
        <div class="form-group">
          <label>Field Value</label>
          <wysiwyg v-model="localObj.value"/>
        </div>
        <button class="btn btn-success" @click="saveItem">Save</button>
        <button class="btn btn-danger" @click="closeModal">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      localObj: {
        name: '',
        value: ''
      }
    }
  },
  props: {
    obj: {
      type: Object,
      required: true
    },
    singleField: {
      type: Boolean,
      default: false
    },
    editRef: {
      type: String,
      required: true
    }
  },

  methods: {
    saveItem() {
      if (!this.singleField && !this.localObj.name) {
        this.$snack.open('Field name cannot be empty', 'warning')
        return
      }
      if (!this.localObj.value) {
        this.$snack.open('Field value cannot be empty', 'warning')
        return
      }
      this.$bus.$emit('saveAdminOption', this.localObj)
      this.closeModal()
    },

    closeModal() {
      this.$bus.$emit('modalClose', 'EditAdminOptionValue')
    }
  },

  mounted() {
    this.localObj = JSON.parse(JSON.stringify(this.obj))
    this.localObj.editRef = this.editRef
  }
}
</script>
