
<div>
  <div class="login-form container">
    <div class="welcome-logo">
      <img :src="urls.frontend + 'static/img/logo.jpg'" />
    </div>

    <div class="note-form-inner">
      <form id="login-form" @submit.prevent="login_employee">
        <br />
        <div class="form-group">
          <label for="employee_code">Login</label>
          <input type="text" class="form-control" name="employee_code" v-model="formVals.employee_id" />
        </div>

        <div class="form-group">
          <label for="employee_pw">Password</label>
          <input type="password" class="form-control" name="employee_pw" v-model="formVals.employee_pw" autocomplete="off" />
        </div>

        <div>
          <div class="button-container">
            <button class="btn btn-primary" @click="login_employee">Log In</button>
          </div>
        </div>

        <waiting-spinner />
      </form>
      <div class="login-options-container">
        <a href="#" class="password-reset" @click="requestPassword()">Retrieve Login ID/Password?</a>
      </div>
    </div>
  </div>
</div>
