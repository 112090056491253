
<div>
  <tabulator-table
    table-id="start_finish_date_changes"
    :table-data="tableData"
    :table-columns="tableColumns"
    :table-condensed="false"
    empty-text="(none)"
  />
  <waiting-spinner />
</div>
