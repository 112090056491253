<template>
  <div>
    <div class="add-exoense-item-container">
      <div class="row upper row-no-gutters">
        <div class="col-xs-8">
          <h4>{{ expenseObj.name }}</h4>
        </div>
        <div class="col-xs-4 cancel-container">
          <a @click="cancel">Cancel</a>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="btn-group" v-if="expenseType === 'jobwo'">
            <button class="btn btn-no-radius btn-blue btn-pip-lg" @click="expenseType = 'job'">Job</button>
            <button class="btn btn-no-radius btn-blue btn-pip-lg" @click="expenseType = 'wo'">Work Order</button>
            <br /><br /><br />
          </div>

          <div v-else>
            <div v-if="expenseType === 'wo'">
              <div class="form-group">
                <label>Work Order</label><span class="required-star">*</span>
                <select-field-dynamic
                  v-model="expense.WO_Number"
                  :options="wos"
                  :option-display-keys="['WO_Number', 'customer_name', 'Summary_Description']"
                  :option-val="'WO_Number'"
                  :empty-option-text="'Fetching items...'"
                  :label="'Work Orders'"
                />
              </div>
            </div>

            <div v-if="expenseType === 'job'">
              <div class="form-group">
                <label>Job Number</label><span class="required-star">*</span>
                <select-field-dynamic
                  v-model="expense.Job_Number"
                  :options="jobAssemble"
                  :option-display-keys="['Job_Number', 'Name', 'Job_Description']"
                  :option-val="'Job_Number'"
                  :empty-option-text="'Fetching items...'"
                  :label="'Job Numbers'"
                />
              </div>
            </div>

            <div v-if="expenseType === 'truck'">
              <div class="form-group">
                <label>Equipment</label><span class="required-star">*</span>
                <select-field-dynamic
                  v-model="expense.Equipment_Code"
                  :options="expenseObj.equipmentList"
                  :option-display-keys="['Name']"
                  :option-val="'Code'"
                  :empty-option-text="'Fetching items...'"
                  :label="'Equipment'"
                />
              </div>
            </div>

            <div v-if="expenseType === 'truck'">
              <div class="form-group">
                <label>Expense Type</label><span class="required-star">*</span>
                <select-field-dynamic
                  v-model="expense.cost_category_code"
                  :options="truckExpenseTypes"
                  :option-display-keys="['Cost_Category_Description']"
                  :option-val="'Cost_Category_Code'"
                  :empty-option-text="'Fetching items...'"
                  :label="'Expense Type'"
                />
              </div>
            </div>

            <div v-else-if="expenseType === 'personal'" class="form-group">
              <label>Expense Type</label><span class="required-star">*</span>
              <!-- <div class="row" v-if="expense.type == 13 || expense.type == 12">
                <div class="col-md-12 text-right">Eligible Amount: {{ formatNumber(eligible_amount, 1) }}</div>
              </div> -->
              <select-field-dynamic
                v-model="expense.type"
                :options="personalExpenseTypes"
                :option-display-keys="['Name']"
                :option-val="'type'"
                :empty-option-text="'Fetching items...'"
                :label="'Expense Type'"
                @selectItem="setPerDiemDates"
              />
              <p class="text-danger" style="padding: 4px 0" v-if="expense.type == 5">This is never to be used for Job or WO based travel</p>
              <p class="text-danger" style="padding: 4px 0" v-if="expense.type == 7">This is not for Per Diems. If wanting Per Diem please use Job or WO entry screen</p>
            </div>

            <div v-else class="form-group">
              <label>Expense Type</label><span class="required-star">*</span>
              <select-field-dynamic
                v-model="selectedExpenseType"
                :options="expenseObj.types"
                :option-display-keys="['Name']"
                :option-val="'id'"
                :empty-option-text="'Fetching items...'"
                :label="'Expense Type'"
                @selectItem="setExpenseName"
              />
            </div>

            <div v-if="expenseType !== 'personal' && expenseName === 'Per Diem'">
              <div class="form-group">
                <label>Start Date</label><span class="required-star">*</span>
                <date-picker
                  v-model="expense.startDate"
                  :format="'string'"
                  :is-inline="false"
                  :pre-filled="false"
                  ref="datePicker"
                  @selectDate="setPerDiemDates"
                  :min-date="minMaxDates.minDate"
                  :max-date="minMaxDates.maxDate"
                />
              </div>

              <div class="form-group">
                <label>End Date</label><span class="required-star">*</span>
                <date-picker
                  v-model="expense.endDate"
                  :format="'string'"
                  :is-inline="false"
                  :pre-filled="false"
                  ref="datePicker"
                  @selectDate="setPerDiemDates"
                  :min-date="minMaxDates.minDate"
                  :max-date="minMaxDates.maxDate"
                />
              </div>
            </div>

            <div v-if="expenseName === 'Job/WO CBA Travel'" class="form-group">
              <div class="form-group">
                <label>Trip Dates</label><span class="required-star">*</span>
                <p>One date per round trip</p>
                <date-picker
                  v-model="expense.tripDates"
                  :format="'string'"
                  :is-inline="false"
                  :pre-filled="false"
                  ref="datePicker"
                  @selectDate="setTripDates"
                  :min-date="minMaxDates.minDate"
                  :max-date="minMaxDates.maxDate"
                  :mode="'multiple'"
                />
              </div>
            </div>

            <div class="form-group" v-if="expenseName === 'Job/WO CBA Travel'">
              <label>KM Distance</label><span class="required-star">*</span>
              <select-field-dynamic
                v-model="travelDistance"
                :options="[
                  { name: '60 km - 120 km', val: 50.0 },
                  { name: '120 km - 200 km', val: 55.0 },
                  { name: '200 km - 400 km', val: 60.0 },
                  { name: '400 km and more', val: 65.0 }
                ]"
                :option-display-keys="['name']"
                :option-val="'val'"
                :empty-option-text="'Fetching items...'"
                :label="'KM Distance'"
                @selectItem="setTripDates"
              />
            </div>

            <div v-if="expenseName === 'Job/WO CBA Travel' || expenseName === 'Per Diem'" class="form-group">
              <label>Qty</label><span class="required-star">*</span>
              <select-field-dynamic
                v-model="expense.qty"
                :options="[{ val: 1 }, { val: 2 }, { val: 3 }, { val: 4 }, { val: 5 }, { val: 6 }, { val: 7 }, { val: 8 }, { val: 9 }, { val: 10 }, { val: 11 }, { val: 12 }, { val: 13 }, { val: 14 }]"
                :option-display-keys="['val']"
                :option-val="'val'"
                :empty-option-text="'Fetching items...'"
                :label="'Qty'"
                disabled
              />
            </div>

            <div v-if="expense.Job_Number && expense.type && !costTypePhases.length">
              <p class="text-danger">There are no phases available for this Job Number and Expense Type</p>
            </div>
            <div v-if="expense.Job_Number" class="form-group">
              <label>Phase</label><span class="required-star">*</span>
              <select-field-dynamic
                v-model="expense.Phase_Code"
                :options="costTypePhases"
                :option-display-keys="['Phase_Code', 'Description']"
                :option-val="'Phase_Code'"
                :empty-option-text="'Fetching items...'"
                :label="'Job Numbers'"
                :disabled="!costTypePhases.length"
              />
            </div>
          </div>

          <div class="form-group">
            <label>Description</label><span class="required-star">*</span>
            <input type="text" class="form-control" v-model="expense.description" :disabled="expenseName === 'Per Diem' || expenseName === 'Job/WO CBA Travel'" />
          </div>

          <div class="form-group">
            <label>Sub Total</label><span class="required-star">*</span>
            <!-- <p v-if="calculatedAmount >= eligible_amount && (expense.type == 13 || expense.type == 12)" class="text-danger" style="padding: 10px 0">
              Please note that entered Sub total is higher than Eligible amount.
            </p> -->
            <vue-numeric
              currency="$"
              separator=","
              :class="['form-control']"
              :precision="2"
              v-model="expense.sub_total"
              inputmode="decimal"
              :disabled="expenseName === 'Per Diem' || expenseName === 'Travel Expense' || expenseName === 'Job/WO CBA Travel'"
            />

            <p v-if="expenseType === 'truck' && expense.Equipment_Category === 'FUEL'" class="text-danger" style="padding: 10px 0">
              Please note that the fuel dollar value entered will automatically have HST subtracted then re-added as totalled within itemized list."
            </p>
          </div>

          <div class="row button-row">
            <div class="col-sm-12">
              <div class="btn-group">
                <button class="btn btn-no-radius btn-blue btn-pip-lg" @click="save">Save &amp; Continue</button>
                <!-- <button
                  class="btn btn-no-radius btn-blue btn-pip-lg"
                  @click="saveAdd"
                >
                  Add Another
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>
<script>
import VueNumeric from 'vue-numeric'
import SelectFieldDynamic from 'components/UIComponents/SelectFieldDynamic'
import appFuncs from 'appFuncs'
import { mapGetters } from 'vuex'
import DatePicker from 'components/UIComponents/DatePicker'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

moment.tz.setDefault('America/Toronto')

import dateFormat from 'dateformat'

export default {
  components: {
    VueNumeric,
    SelectFieldDynamic,
    DatePicker,
    WaitingSpinner
  },

  data() {
    return {
      expense: {
        category: null,
        description: '',
        sub_total: 0,
        type: '',
        Phase_Code: '',
        Job_Number: '',
        Cost_Type: '',
        cost_category_code: '',
        Name: '',
        qty: 1
      },
      expenseType: null,
      travelDistance: 0,
      expenseName: '',
      wos: [],
      phases: [],
      truckExpenseTypes: [],
      perDiemVal: 63,
      minMaxDates: {
        minDate: null,
        maxDate: null
      },
      selectedExpenseType: null,
      expenseHistories: [],
      extra_boot_amount: 0,
      extra_tool_amount: 0,
      default_vendor_code: '',
      hire_date: '',
      employee_data: {}
    }
  },

  props: {
    expenseTypeProp: {
      type: String,
      required: true
    },
    expenseData: {
      type: Array,
      required: true
    },
    employeeDetails: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['jobAssemble', 'appOptions']),

    personalExpenseTypes() {
      let types = this.expenseObj.types
      let boot_groups = this.appOptions.boot_groups
      let tool_groups = this.appOptions.tool_groups
      let keep_boot = false
      let keep_tool = false

      if (boot_groups != undefined) {
        for (const value of boot_groups) {
          if (this.employee_data[value] != undefined && this.employee_data[value] != 'yes') {
            keep_boot = true
          }
        }
      }

      if (tool_groups != undefined) {
        for (const value of tool_groups) {
          if (this.employee_data[value] != undefined && this.employee_data[value] != 'yes') {
            keep_tool = true
          }
        }
      }

      let all_types = []
      for (const index in types) {
        let type = types[index]
        if (type.Code == 6084) {
          if (keep_boot) {
            all_types.push(type)
          }
        } else if (type.Code == 6081) {
          if (keep_tool) {
            all_types.push(type)
          }
        } else {
          all_types.push(type)
        }
      }

      return all_types
    },

    expenseObj() {
      return this.expenseData.find(itm => itm.code === this.expenseType) || {}
    },

    costTypePhases() {
      let types = (this.expenseData.find(itm => itm.code == this.expenseType) || {}).types
      let selectedCostType = types.find(itm => itm.Code == this.expense.type)
      return this.phases.filter(itm => itm.Cost_Type == (selectedCostType || {}).Cost_Type)
    },
    eligible_amount() {
      let amount = 0
      let code = ''
      if (this.expense.type == 13) {
        amount = parseFloat(this.appOptions.tool_allowance) + this.extra_tool_amount
        code = 6081
      }
      if (this.expense.type == 12) {
        amount = parseFloat(this.appOptions.boot_allowance) + this.extra_boot_amount
        code = 6084
      }
      if (this.hire_date != '' && this.hire_date != null) {
        let res = this.calculateCycleDates(this.hire_date, this.appOptions.expense_cycle_months)
        let start_date = moment(res.start_date).toDate()
        for (const ele of this.expenseHistories) {
          let trans_date = moment(ele.Trans_Date).toDate()
          if (trans_date >= start_date && ele.GL_Distribution_Account == code) {
            amount -= ele.Payment_Amount
          }
        }
      }
      return amount
    },
    calculatedAmount() {
      let amount = this.expense.sub_total
      amount += amount * 0.13
      return amount
    }
  },

  methods: {
    setExpenseName(val, obj) {
      this.expenseName = obj.Name || ''
      this.expense.code = obj.Code
      this.expense.type = obj.Code
      this.setPerDiemDates()
    },

    setPerDiemDates() {
      this.expense.description = ''

      if (this.expense.startDate && this.expense.endDate && this.expenseName === 'Per Diem') {
        let start = moment(this.expense.startDate)
        let end = moment(this.expense.endDate)
        let diff = end.diff(start, 'days')
        if (diff < 0) {
          this.$snack.open('End date must be after start date', 'warning')
          this.expense.endDate = ''
          return
        }
        this.expense.qty = diff + 1

        this.expense.description = `Per diem: ${start.format('MM/DD/YY')} - ${end.format('MM/DD/YY')}`

        this.setSubTotal()
      }
    },

    setTripDates() {
      this.expense.description = ''
      if (this.expense.tripDates && this.expenseName === 'Job/WO CBA Travel') {
        let tripDatesFormatted = []
        for (let i = 0; i < this.expense.tripDates.length; i++) {
          tripDatesFormatted.push(moment(this.expense.tripDates[i]).format('YYYY-MM-DD'))
        }

        this.expense.qty = tripDatesFormatted.length

        let distanceCode = ''
        switch (this.travelDistance) {
          case 50.0:
            distanceCode = '1'
            break
          case 55.0:
            distanceCode = '2'
            break
          case 60.0:
            distanceCode = '3'
            break
          case 65.0:
            distanceCode = '4'
            break
        }

        this.expense.description = `CBA${distanceCode}-${tripDatesFormatted.map(date => moment(date).format('MD')).join(',')}`
        this.setSubTotal()
      }
    },

    save() {
      let requiredFields = ['description', 'sub_total', 'type']
      if (this.expenseType === 'job') {
        requiredFields = requiredFields.concat(['Job_Number', 'Phase_Code', 'Cost_Type'])
      } else if (this.expenseType === 'wo') {
        requiredFields = requiredFields.concat(['WO_Number', 'type'])
      } else if (this.expenseType === 'truck') {
        requiredFields = requiredFields.concat(['Equipment_Code', 'Equipment_Category'])
      } else if (this.expenseType === 'personal') {
        requiredFields = requiredFields.concat([])
      }

      let valid = true
      for (let i = 0; i < requiredFields.length; i++) {
        if (!this.expense[requiredFields[i]]) {
          valid = false
          break
        }
      }
      if (!valid) {
        this.$snack.open('One or more required fields are missing', 'warning')
        return
      }

      // if fuel expense, subtract HST then allow GST to get added back with totalling receipt
      if (this.expense.Equipment_Category === 'FUEL' || this.expenseName === 'Per Diem' || this.expenseName === 'Job/WO CBA Travel') {
        this.expense.sub_total = (this.expense.sub_total / (1 + 13 / 100)).toFixed(3) // find reverse percentage
      }

      let obj = this.expense
      obj.code = this.expenseType
      obj.name = this.expenseName || this.expenseObj.name
      obj.remarks = this.expenseName

      this.$emit('add', obj)
    },

    cancel() {
      this.$emit('cancel')
    },

    getGlobalOptions(group) {
      var params = {
        action: 'get_global_options',
        group
      }
      this.$bus.$emit('setWaiting', {
        name: `get_global_options ${group}`,
        message: 'Getting Options'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.perDiemVal = data && data[0] ? data[0].value : 63
        })
        .catch(err => {
          this.$snack.open(err.message || 'Problem getting quote option', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', `get_global_options ${group}`)
        })
    },

    getWOs() {
      var params = {
        action: 'get_wo_main'
      }
      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Getting WOs'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.wos = data
          this.$bus.$emit('stopWaiting', params.action)
        })
        .catch(err => {
          this.$snack.open(err.message || 'Problem fetching Work Order data', 'error')
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    getJobs() {
      this.$bus.$emit('setWaiting', {
        name: 'getJobs',
        message: 'Getting Jobs'
      })
      this.$store
        .dispatch('getJobAssemble', { not_status: 'C' })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getJobs')
        })
    },

    getJobPhases() {
      if (this.expense.Job_Number) {
        var data = {
          action: 'get_job_phase',
          job_number: this.expense.Job_Number,
          status_code: 'A'
        }

        this.$bus.$emit('setWaiting', {
          name: 'getJobPhases',
          message: 'Getting Job Phases'
        })
        appFuncs.ajax_request(this.$store.getters.sherAuth, data, result => {
          if (result.status === 'success') {
            this.phases = Array.isArray(result.data) ? result.data : []
            if (!this.phases.length) {
              this.$snack.open('No Job Phase available, please notify admin to post time to this job.', 'error')
            }
          }
          this.$bus.$emit('stopWaiting', 'getJobPhases')
        })
      }
    },

    getVehicleExpenses() {
      var params = {
        action: 'get_cost_ec_categories'
      }
      this.$bus.$emit('setWaiting', {
        name: params.actions,
        message: 'Getting Expense Types'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.truckExpenseTypes = Array.isArray(data) ? data : []
        })
        .catch(e => {
          this.$snack.open(e.message || 'Problem getting vehicle expense types', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.actions)
        })
    },

    getMinMaxDates() {
      if (!this.isPM || 'lunch' !== 'tunafish') {
        var date = new Date()

        var prevSunday = new Date(date)
        prevSunday.setDate(prevSunday.getDate() - ((prevSunday.getDay() + 7) % 7))

        var weekNo = moment(prevSunday).format('W')

        /*
          A bit whacky but works for year week pay period change, maybe change back next year to just:
          if (weekNo % 2 !== 0) {
              prevSunday = moment(prevSunday).subtract(1, 'week').toDate();
            }
          */

        if (date.getFullYear() === 2020 || weekNo == 53) {
          if (weekNo % 2 !== 0) {
            prevSunday = moment(prevSunday).subtract(1, 'week').toDate()
          }
        } else {
          if (weekNo % 2 === 0) {
            prevSunday = moment(prevSunday).subtract(1, 'week').toDate()
          }
        }

        var endDate = moment(prevSunday).add(13, 'days').toDate()

        this.minMaxDates = {
          minDate: prevSunday,
          maxDate: endDate,
          formatted: dateFormat(prevSunday, 'mediumDate') + ' - ' + dateFormat(endDate, 'mediumDate')
        }
      }
    },

    setSubTotal() {
      if (this.expenseName === 'Per Diem') {
        this.expense.sub_total = this.perDiemVal * this.expense.qty
      } else if (this.expenseName === 'Job/WO CBA Travel') {
        this.expense.sub_total = this.travelDistance * this.expense.qty
      } else {
        this.expense.sub_total = 0
      }
    },
    async getHistories() {
      var params = {
        action: 'get_payment_history2',
        vendor_code: this.default_vendor_code
      }

      let data = await appFuncs.shRequest(params)

      this.expenseHistories = Array.isArray(data) ? data : []
    },
    async getEmployeeData() {
      let data = await appFuncs.shRequest({
        action: 'get_employee_app_data',
        employee_code: this.$store.getters.userAuthedData['eid']
      })
      this.employee_data = data
      this.default_vendor_code = data.default_vendor_code
      this.hire_date = data.hire_date
      if (data.extra_boot_allowance != null) {
        this.extra_boot_amount = data.extra_boot_allowance
      }
      if (data.extra_tool_allowance != null) {
        this.extra_tool_amount = data.extra_tool_allowance
      }
    }
  },

  async mounted() {
    this.expenseType = this.expenseTypeProp
    if (this.expenseType === 'job') {
      this.getJobs()
    } else if (this.expenseType === 'truck') {
      this.getVehicleExpenses()
    }
    this.expense.Equipment_Code = this.employeeDetails ? this.employeeDetails.default_vehicle : ''

    this.getGlobalOptions('per_diem_rate')

    this.$store.dispatch('getAppOptions')

    this.getMinMaxDates()

    await this.getEmployeeData()
    await this.getHistories()
  },

  watch: {
    // restrict to 6 days max
    'expense.tripDates'(newVal, oldVal) {
      if (newVal.length > 6) {
        this.expense.tripDates = newVal.slice(0, 6)
        this.setTripDates()
      }
    },

    expenseType() {
      if (this.expenseType === 'wo' && !this.wos.length) {
        this.getWOs()
      }
    },

    'expense.Job_Number'() {
      if (this.expense.Job_Number) {
        this.getJobPhases()
      }
    },

    'expense.Phase_Code'() {
      if (this.expense.Phase_Code) {
        // this.getJobPhases();
      }
    },

    'expense.type'() {
      if (this.expense.type) {
        let types = (this.expenseData.find(itm => itm.code == this.expenseType) || {}).types
        let type = types.find(itm => itm.Code == this.expense.type)
        this.expense.Cost_Type = type ? type.Cost_Type : '' // for jobs
        this.expense.Phase_Code = ''

        this.setSubTotal()
      }
    },

    'expense.cost_category_code'() {
      if (this.truckExpenseTypes && this.truckExpenseTypes.length) {
        let truckExpenseType = this.truckExpenseTypes.find(itm => itm.Cost_Category_Code == this.expense.cost_category_code)
        this.expense.Equipment_Category = truckExpenseType ? truckExpenseType.Cost_Category_Code : ''
        this.expense.Equipment_Category_Description = truckExpenseType ? truckExpenseType.Cost_Category_Description : ''
        this.expense.type = truckExpenseType ? truckExpenseType.GL_Debit_Account : ''
      }
    },

    'expense.description'(newVal, oldVal) {
      if (newVal.length > 30) {
        this.expense.description = oldVal
      }
    },

    'expense.qty'(newVal, oldVal) {
      if (this.expenseName === 'Per Diem') {
        this.expense.sub_total = this.perDiemVal * newVal
      } else if (this.expenseName === 'Job/WO CBA Travel') {
        this.expense.sub_total = this.travelDistance * newVal
      } else {
        this.expense.sub_total = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.add-exoense-item-container {
  max-width: 400px;
  background: #fff;
  padding: 10px;

  .upper {
    padding: 15px 0 10px;
    vertical-align: bottom;
    border-bottom: 2px solid $light-gray;
    margin: 0 auto 15px;
    // max-width: calc(100% - 6px);

    h4 {
      margin: 0;
    }

    .cancel-container {
      padding-top: 10px;
      text-align: right;
      font-weight: bold;

      a {
        color: $pastel-orange;
      }
    }
  }

  ul.select-item-list {
    list-style-type: none;
    padding: 0;

    li {
      padding: 8px;
      border-bottom: 1px solid $light-gray;
    }
  }

  .button-row {
    .btn-group {
      display: inline-block;
      text-align: center;
      width: 100%;

      .btn {
        float: none;
      }
    }
  }
}
</style>
