
<div>
  <div class="row">
    <div class="col-sm-12">
      <button class="btn" @click="showOptions">Statement Options</button>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-sm-12">
      <div :class="['card']">
        <div class="content">
          <bh-table :table-settings="tableSettings" @edit-item="showItem" />
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</div>
