
<div>
  <waiting-spinner />

  <div v-if="missingWoRequirements.length" class="row" style="margin-bottom: 10px">
    <div class="col-sm-12">
      <div>
        <p v-if="missingWoRequirements.length" class="text-danger">
          <i
            ><strong><span style="text-decoration: underline">RED</span> requirements must be inputted before continuing.</strong></i
          >
        </p>
        <p v-else-if="wo.Dispatch_Status_Code.toString() !== '7' && wo.Dispatch_Status_Code !== 'F'" class="text-danger"><i>WO must be marked as finished prior to sign off.</i></p>
        <p v-else><i>All requirements have been met.</i></p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="content">
          <label><strong>Service Report Options</strong></label>
          <br />
          <div class="form-group">
            <span class="inline-checkbox">
              <checkbox2 v-model="signOff.isQuote" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                <span>Quote</span>
              </checkbox2>
            </span>
            <span class="inline-checkbox">
              <checkbox2 v-model="signOff.hideLaborDetails" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                <span>Hide Time Log Notes</span>
              </checkbox2>
            </span>
          </div>
        </div>
      </div>

      <div class="card border">
        <div class="content">
          <div class="form-group">
            <label><strong>Required Doc &amp; Photo Categories</strong></label>
            <br />
            <span class="inline-checkbox">
              <checkbox2 v-model="files.preWorkPhotos" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                <span :class="{ 'text-danger': !checkMetWoRequirement('preWorkPhotos') }">Pre-Work Photos</span>
              </checkbox2>
            </span>
            <span class="inline-checkbox">
              <checkbox2 v-model="files.postWorkPhotos" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                <span :class="{ 'text-danger': !checkMetWoRequirement('postWorkPhotos') }">Post Work Photos</span>
              </checkbox2>
            </span>
            <div>
              <button :class="['btn', 'btn-sm', { pulse: !checkMetWoRequirement(['postWorkPhotos', 'postWorkPhotos']) }]" @click="showFiles()" v-html="'Add Photos'" />
            </div>
          </div>
        </div>
      </div>

      <div class="card border">
        <div class="content">
          <div class="form-group">
            <label><strong>Required Note Categories</strong></label>
            <br />
            <span class="inline-checkbox">
              <checkbox2 v-model="notes.wo_work_ordered_notes" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                <span :class="{ 'text-danger': !checkMetWoRequirement('wo_work_ordered_notes') }">Scope of Work Notes</span>
              </checkbox2>
            </span>
            <!-- <span class="inline-checkbox">
              <checkbox2 v-model="notes.wo_work_order_notes" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                <span :class="{'text-danger':!checkMetWoRequirement('wo_work_order_notes')}">Scope Notes</span>
              </checkbox2>
            </span> -->
            <span class="inline-checkbox">
              <checkbox2 v-model="notes.wo_internal_notes" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                <span :class="{ 'text-danger': !checkMetWoRequirement('wo_internal_notes') }">Internal Notes</span>
              </checkbox2>
            </span>
            <span class="inline-checkbox">
              <checkbox2 v-model="notes.wo_extras_notes" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                <span :class="{ 'text-danger': !checkMetWoRequirement('wo_extras_notes') }">Work Order Extras</span>
              </checkbox2>
            </span>
            <span class="inline-checkbox">
              <checkbox2 v-model="notes.wo_completed_notes" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                <span :class="{ 'text-danger': !checkMetWoRequirement('wo_completed_notes') }">Work Completed</span>
              </checkbox2>
            </span>
            <span class="inline-checkbox">
              <checkbox2 v-model="notes.wo_recommendation_notes" true-val="true" false-val="false" :disabled="!isPM" @change="compareLoadedOptions">
                <span :class="{ 'text-danger': !checkMetWoRequirement('wo_recommendation_notes') }">Recommendations</span>
              </checkbox2>
            </span>
          </div>
        </div>
      </div>
      <div class="card border">
        <div class="content">
          <div class="form-group">
            <label><strong>Other</strong></label>
            <br />
            <span class="inline-checkbox">
              <checkbox2 v-model="other.require_detailed_notes" true-value="true" :disabled="!isPM" @change="compareLoadedOptions">
                <span>Require Detailed Notes</span>
              </checkbox2>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button v-if="isPM" :class="['btn', { pulse: optionsUpdated }, { 'btn-primary': optionsUpdated }]" @click="save()" v-html="'Update'" />

  <button v-if="woSignOffTemplateLink && !inSequence" :class="['btn', { disabled: optionsUpdated }]" @click="checkFinishSignOff()" v-html="'Capture Signature'" />

  <button v-if="woSignOffTemplateLink && !inSequence" :class="['btn']" @click="openWOSignOffTemplateLink()" v-html="'Preview'" />

  <!-- <button
    v-if="!inSequence && woSignOffTemplateLink"
    :class="['btn', { disabled: missingWoRequirements.length || ((wo.Dispatch_Status_Code !== '7' && wo.Dispatch_Status_Code !== 'F') && !justMarkedFinished) }]"
    @click="openWOSignOffTemplateLink"
  >
    Signature
  </button>

  <button
    v-if="!inSequence && (wo.Dispatch_Status_Code !== '7' && wo.Dispatch_Status_Code !== 'F') && (isPM || isWOPM || isWOTech)"
    :class="['btn', {disabled: missingWoRequirements.length}]"
    @click="finishWO"
  >
    Mark WO as Finished
  </button> -->
</div>
