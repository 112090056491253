import Vue from 'vue';
import Snackbar from './Snackbar';

const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue;

const SnackbarComponent = Vue.extend(Snackbar);
const SnackbarInstance = new SnackbarComponent({});
SnackbarInstance.$mount();

document.body.appendChild(SnackbarInstance.$el);

const open = (message, type, action, actionText, priority, persist) => {
  SnackbarInstance.addSnack(message, type, action, actionText, priority, persist);
};
const remove = (message) => {
  SnackbarInstance.removeSnack({message});
};
const removeAll = (message) => {
  SnackbarInstance.removeAll();
};

const snack = {open, remove, removeAll};

Vue.prototype.$snack = snack;

export default snack;

