
<div>
  <alert/>

  <iframe class="responsive-table-embed"
          src="https://docs.google.com/forms/d/e/1FAIpQLSdN3cLpAkt1vnO91nX4j_TDCE1CB3UKAjc_KmYRH2rQA8l-Ew/viewform?c=0&w=1&amp;single=true&amp;widget=true&amp;headers=false"
  ></iframe>

  <waiting-spinner/>

</div>
