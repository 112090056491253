<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <button class="btn" @click="showOptions">Statement Options</button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-sm-12">
        <div :class="['card']">
          <div class="content">
            <bh-table :table-settings="tableSettings" @edit-item="showItem" />
          </div>
        </div>
      </div>
      <waiting-spinner />
    </div>
  </div>
</template>
<script>
// import appFuncs from 'appFuncs';
import { mapGetters } from 'vuex'
import BhTable from 'components/UIComponents/BhTable'
import AccountsReceivableDetails from 'components/Dashboard/AccountsReceivable/AccountsReceivableDetails.vue'
import AccountsReceivableOptions from 'components/Dashboard/AccountsReceivable/AccountsReceivableOptions.vue'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import moment from 'moment'
import appFuncs from 'appFuncs'

export default {
  data() {
    return {
      tableSettings: {
        tableData: [],
        fields: {
          Name: {
            name: 'Name'
          },
          current: {
            name: 'Current'
          },
          _30Days: {
            name: '< 30 days'
          },
          _60Days: {
            name: '< 60 days'
          },
          _90Days: {
            name: '< 90 days'
          },
          over90Days: {
            name: '> 90 days'
          },
          retention: {
            name: 'Retention'
          },
          last_sent: {
            name: 'Last Sent'
          }
          // has_contact: {
          //   name: 'AR Contact'
          // }
        },
        sortCol: '_30Days',
        sortColReverse: true
      }
    }
  },

  components: {
    BhTable,
    WaitingSpinner
  },

  props: {
    customerCode: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters(['customers', 'customerSettingsAR', 'arItems'])
  },

  methods: {
    fetchData() {
      return new Promise((resolve, reject) => {
        ;(async () => {
          let customersSpectrum = []
          let customersSf = []

          this.$bus.$emit('setWaiting', { name: 'fetchARSummaryItems', message: 'Getting data' })

          // fetch all items at same time, do stuff after all fetched
          await Promise.all([
            (async () =>
              this.$store.dispatch('getCustomers').catch(res => {
                this.$snack.open('Problem getting Spectrum customer info', 'error')
              }))(),
            (async () =>
              this.$store.dispatch('getARItems').catch(res => {
                this.$snack.open('Problem getting receivable items', 'error')
              }))(),
            (async () =>
              this.$store.dispatch('getCustomerSettingsAR').catch(res => {
                this.$snack.open('Problem getting customer account settings', 'error')
              }))()
          ])

          let em = this.arItems.filter(itm => {
            return itm.Retention_Amount
          })

          this.$bus.$emit('stopWaiting', 'fetchARSummaryItems')

          let tableData = []
          for (var ci = 0; ci < this.customers.length; ci++) {
            let customerData = {
              id: ci + 1, // for BHtable
              Customer_Code: this.customers[ci].Customer_Code,
              Name: this.customers[ci].Name
            }

            // attach customer setting data
            for (var csi = 0; csi < this.customerSettingsAR.length; csi++) {
              if (this.customers[ci].Customer_Code == this.customerSettingsAR[csi].customer_code) {
                customerData.last_sent = this.getLastSent(this.customerSettingsAR[csi])
                customerData.custom_text = this.customerSettingsAR[csi].custom_text
                customerData.ar_contacts_default = this.customerSettingsAR[csi].ar_contacts_default
                customerData.ar_contacts_extra = this.customerSettingsAR[csi].ar_contacts_extra
                customerData.class = this.customerSettingsAR[csi].send_status == 'fail' ? 'red' : ''
                customerData.disable_auto_send = this.customerSettingsAR[csi].disable_auto_send == 1 ? 1 : 0
              }
            }

            if (!customerData.ar_contacts_default || !customerData.ar_contacts_default.length) {
              customerData.class = 'orange'
            }

            // attach receievable items
            ;(customerData._30Days = 0), (customerData._60Days = 0), (customerData._90Days = 0), (customerData.over90Days = 0), (customerData.current = 0), (customerData.retention = 0)

            let arItems = this.arItems.filter(itm => {
              return itm.Transaction_Type !== 'P'
            })

            for (var ari = 0; ari < arItems.length; ari++) {
              if (this.customers[ci].Customer_Code == arItems[ari].Customer_Code) {
                if (this.getDays(arItems[ari].Invoice_Due_Date) >= 91) {
                  customerData.over90Days += parseFloat(arItems[ari].Invoice_Balance - arItems[ari].Retention_Balance)
                } else if (this.getDays(arItems[ari].Invoice_Due_Date) >= 61) {
                  customerData._90Days += parseFloat(arItems[ari].Invoice_Balance - arItems[ari].Retention_Balance)
                } else if (this.getDays(arItems[ari].Invoice_Due_Date) >= 31) {
                  customerData._60Days += parseFloat(arItems[ari].Invoice_Balance - arItems[ari].Retention_Balance)
                } else if (this.getDays(arItems[ari].Invoice_Due_Date) >= 1) {
                  customerData._30Days += parseFloat(arItems[ari].Invoice_Balance - arItems[ari].Retention_Balance)
                } else {
                  customerData.current += parseFloat(arItems[ari].Invoice_Balance - arItems[ari].Retention_Balance)
                }
                customerData.retention += parseFloat(arItems[ari].Retention_Amount)
              }
            }

            if (customerData.current || customerData._30Days || customerData._60Days || customerData._90Days || customerData.over90Days || customerData.retention) {
              customerData.current = this.formatNumber(customerData.current)
              customerData._30Days = this.formatNumber(customerData._30Days)
              customerData._60Days = this.formatNumber(customerData._60Days)
              customerData._90Days = this.formatNumber(customerData._90Days)
              customerData.over90Days = this.formatNumber(customerData.over90Days)
              customerData.retention = this.formatNumber(customerData.retention)
              tableData.push(customerData)
            }
          } // end of main customers loop

          this.tableSettings.tableData = tableData
          resolve()
        })()
      })
    },

    formatNumber(val) {
      return appFuncs.formatNumber(val, true)
    },

    getLastSent(itm) {
      let time = itm.last_send_success
      if (time) {
        let formatted = moment.unix(time).format('YYYY-MM-DD')
        return formatted
      }
    },

    showItem(id, customerCode) {
      let customer = {}
      if (customerCode) {
        customer = this.customers.find(itm => {
          return itm.Customer_Code == customerCode
        })
      } else {
        customer = this.tableSettings.tableData.find(itm => {
          return itm.id == id
        })
      }

      this.$Modal({
        parent: this,
        name: 'AccountsReceivableDetails', // used for closing specific modal programmatically
        title: 'Accounts Receivable Customer Options',
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: AccountsReceivableDetails,
        props: {
          id,
          customer
        }
      })
    },

    showOptions() {
      this.$Modal({
        parent: this,
        name: 'AccountsReceivableOptions', // used for closing specific modal programmatically
        title: 'Accounts Receivable Statement Options',
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: AccountsReceivableOptions
      })
    }
  },

  async mounted() {
    await this.fetchData()

    if (this.customerCode) {
      this.showItem(null, this.customerCode)
    }

    this.$bus.$on('arSettingsUpdated', () => {
      // store items needing update should have already been finished by whatever did emit
      this.fetchData()
    })
  },

  beforeDestroy() {
    // this.$bus.$off(['quoteUpdate', 'quoteEditNotes']);
  }
}
</script>

<style lang="scss" scoped></style>
