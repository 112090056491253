<template>
  <div>
    <div v-for="(parent, index) in parents" :key="index" class="parent">
      <h3>Parent Element {{ index + 1 }}</h3>
      <div v-for="(sub, subIndex) in parent.subElements" :key="subIndex">
        <FormulateInput
          :type="sub.type"
          :label="sub.label"
          :validation="sub.validation"
          v-model="sub.value"
        />
      </div>
      <button @click="deleteParent(index)">Delete Parent</button>
    </div>
    <button @click="addParent">Add Parent</button>
    <button @click="save">Save</button>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import appFuncs from "appFuncs";

export default {
  name: "CustomComponent",
  props: {
    config: {
      type: Array,
      required: true,
    },
    group: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      parents: [],
    };
  },

  async mounted() {
    await this.load();
  },

  methods: {
    addParent() {
      this.parents.push({
        subElements: JSON.parse(JSON.stringify(this.config)),
      });
    },

    deleteParent(index) {
      this.parents.splice(index, 1);
    },

// Save method
    async save() {
      const parentsToSave = this.parents.map((parent) => {
        return {
          subElements: parent.subElements.map((subElement) => {
            return {
              label: subElement.label,
              value: subElement.value,
            };
          }),
        };
      });

      const params = {
        action: 'upsert_parent_sub_element',
        group: this.group,
        parents: parentsToSave,
      };

      try {
        const response = await appFuncs.shHttp(params);
        if (response.status === 'success') {
          this.$snack.open('Data saved successfully', 'success');
        } else {
          this.$snack.open(response.message || 'Problem saving data.', 'error');
        }
      } catch (error) {
        this.$snack.open(error.message || 'Problem saving data.', 'error');
      }
    },

// Load method
    async load() {
      const params = {
        action: 'load_parent_sub_elements',
        group: this.group,
      };

      try {
        const response = await appFuncs.shHttp(params);
        if (response.status === 'success') {
          const parentsData = response.data;

          this.parents = parentsData.map((parentData) => {
            return {
              subElements: parentData.subElements.map((subElement) => {
                return {
                  label: subElement.label,
                  value: subElement.value,
                  type: subElement.type,
                  validation: subElement.validation,
                };
              }),
            };
          });
        } else {
          this.$snack.open(response.message || 'Problem loading data.', 'error');
        }
      } catch (error) {
        this.$snack.open(error.message || 'Problem loading data.', 'error');
      }
    },


  },
};
</script>
