
<div>
  <div class="pdf-body" id="pdf-body">
    <div class="container" v-if="loadSuccess">
      <section>
        <div class="header">
          <div class="row">
            <div class="col-xs-8">
              <router-link to="/">
                <img :src="urls.frontend + 'static/img/logo.jpg'" />
              </router-link>
            </div>
            <div class="col-xs-4">
              <p>
                34-966 Pantera Drive<br />
                Mississauga, Ontario, L4W 2S1<br />
                Tel: (905) 625-0265 <br />
                Fax: (905) 625-0268 <br />
                Email: accountsreceivable@sheridanelectric.ca
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <h3 style="text-align: center" class="section-title"><strong>Statement</strong></h3>
        <div class="statement-info-section">
          <div class="row">
            <div class="col-xs-6 border-section">
              <p>
                <strong>To:</strong><br />
                <span v-html="customer.Name" /><br />
                <span v-if="customer.Address_1" v-html="customer.Address_1 + ', '" />
                <span v-if="customer.Address_2" v-html="customer.Address_2 + ', '" />
                <br />
                <span v-if="customer.Address_3" v-html="customer.Address_3 + ', '" />
                <span v-if="customer.State" v-html="customer.State + ', '" />
                <span v-if="customer.Zip_Code" v-html="customer.Zip_Code" />
              </p>
            </div>
            <div class="col-xs-6 border-section">
              <p>
                <span style="min-width: 150px; display: inline-block"><strong>Period Ending:</strong></span
                >{{ dateToday }}
                <br />
                <span style="min-width: 150px; display: inline-block"><strong>Customer Code:</strong></span
                >{{ customer.Customer_Code }}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section style="margin: 30px 0">
        <h5 v-if="(manualSend && ccSettings.custom_text) || defaultText" style="margin-bottom: 5px">
          <strong>Attn: Accounts Receivable</strong>
        </h5>
        <p v-if="manualSend && ccSettings.custom_text" v-html="ccSettings.custom_text" />
        <p v-else-if="defaultText">
          {{ defaultText }}
        </p>
      </section>

      <section class="row">
        <table class="">
          <tr>
            <td>
              <strong>Date</strong>
            </td>
            <td>
              <strong>Invoice Number</strong>
            </td>
            <td>
              <strong>Type</strong>
            </td>
            <td>
              <strong>Job/WO</strong>
            </td>
            <td>
              <strong>PO Number</strong>
            </td>
            <td>
              <strong>Contact</strong>
            </td>
            <td>
              <strong>Original Amt</strong>
            </td>
            <td>
              <strong>Prev. Paid</strong>
            </td>
            <td>
              <strong>Retention</strong>
            </td>
            <td>
              <strong>Amount Due</strong>
            </td>
            <td>
              <strong>Due Date</strong>
            </td>
            <td>
              <strong>Status</strong>
            </td>
          </tr>
          <tr v-for="(rItm, i) in arItems" :key="i">
            <td>{{ rItm.niceDate }}</td>
            <td>{{ rItm.transNo }}</td>
            <td>{{ rItm.type }}</td>
            <td>{{ rItm.jobWO }}</td>
            <td>{{ rItm.poNumber }}</td>
            <td>{{ rItm.customerContact }}</td>
            <td>{{ formatNumber(rItm.origAmt) }}</td>
            <td>{{ formatNumber(rItm.prevPaid) }}</td>
            <td>{{ formatNumber(rItm.retention) }}</td>
            <td>{{ formatNumber(rItm.amtDue) }}</td>
            <td>{{ rItm.dueDate }}</td>
            <td>{{ rItm.status }}</td>
          </tr>

          <tr>
            <td style="border: none; padding: 5px"></td>
          </tr>

          <tr class="totals-row">
            <td>Totals</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ formatNumber(origAmtTotal) }}</td>
            <td>{{ formatNumber(prevPaidTotal) }}</td>
            <td>{{ formatNumber(retentionTotal) }}</td>
            <td>{{ formatNumber(amtDueTotal) }}</td>
            <td></td>
            <td></td>
          </tr>
        </table>
        <p v-if="!arItems || !arItems.length">
          <i>No Receivables found</i>
        </p>
      </section>

      <section class="row" style="margin-top: 30px">
        <p style="text-align: center; font-size: 18px"><strong>Overdue Amounts</strong></p>
        <table class="">
          <tr>
            <td />
            <td />
            <td><strong>Current</strong></td>
            <td><strong>1 to 30 days</strong></td>
            <td><strong>31 to 60 days</strong></td>
            <td><strong>61 to 90 days</strong></td>
            <td><strong>Over 90 days</strong></td>
            <td><strong>Retention</strong></td>
          </tr>
          <tr>
            <td><strong>Aging Breakdown</strong></td>
            <td />
            <td>{{ formatNumber(currentDue) }}</td>
            <td>{{ formatNumber(total30days) }}</td>
            <td>{{ formatNumber(total60days) }}</td>
            <td>{{ formatNumber(total90days) }}</td>
            <td>{{ formatNumber(totalOver90days) }}</td>
            <td>{{ formatNumber(totalRetention) }}</td>
          </tr>
        </table>
      </section>

      <div v-if="pdf" class="footer" style="margin-bottom: 20px">
        <div class="footer-inner">
          <div class="page-number">
            <p>Page 1 of 1</p>
          </div>
        </div>
      </div>

      <!-- <div class="row not-pdf">
        <div class="col-sm-12" style="margin-bottom:15px">
          <hr>
          <div v-if="apContact.Email">
            <button
              class="btn"
              @click="sendReport"
              v-html="'Send Report'"
            />
            <div style="italic">
              <strong>AP Contacts:</strong>
              <span v-for="(contact, apci) in apContacts" :key="apci">
                <span v-html="contact.Email+ (apContacts.length > 1 && apci + 1 !== apContacts.length ? ', ' : '')" />
              </span>
            </div>
          </div>
          <p v-else>
            <i>No AP Contact found for this vendor</i>
          </p>
        </div>
      </div> -->
    </div>

    <waiting-spinner :wait="wait" />
  </div>
</div>
