
<div>
  <alert />
  <div class="card">
    <div class="content">
      <div v-for="(field, key) in componentData.fields" class="form-group" v-if="!field.hiddenView">
        <label v-html="field.name ? field.name : key"></label><span class="required-star" v-if="field.required">*</span>
        <input v-if="field.use === 'phone'" type="text" class="form-control" v-model="formVals[key]" :readonly="field.readonly" :required="field.required" v-mask="chooseMask(formVals[key])" />
        <input v-else v-bind:type="[field.type ? field.type : 'text']" class="form-control" v-model="formVals[key]" :readonly="field.readonly" :required="field.required" />
      </div>
      <div class="button-container" v-if="componentData.editId">
        <button class="btn" @click="updateData">Update {{ componentData.itemType }}</button>
        <button class="btn" @click="deleteData">Remove {{ componentData.itemType }}</button>
      </div>
      <div v-else>
        <button class="btn" @click="addData">Add {{ componentData.itemType }}</button>
      </div>
    </div>
  </div>

  <waiting-spinner />
</div>
