<template>
  <div class="customer-ticket" id="customer-ticket">
    <div class="container">
      <div class="row header" v-if="wo.WO_Number">
        <div class="col-xs-12 col-sm-4">
          <router-link to="/">
            <img :src="`${urls.frontend}static/img/${logoFile}`" />
          </router-link>
        </div>
        <div class="col-xs-12 col-sm-4">
          <p v-html="regionAddress" />
        </div>
        <div class="col-xs-12 col-sm-4 text-right">
          <h4>
            Work Order<br />
            <strong> #<span v-html="wo.WO_Number" /> </strong>
          </h4>
          <!-- using woLoaded id for PDF generating start flag -->
        </div>
      </div>
      <!--
      <div class="row" v-if="woStatus">
        <div class="col-sm-12 wo-status-container">
          <span v-if="woStatus" class="wo-status">
            Status: <span :class="woStatusClass">{{ woStatus }}</span>
          </span>
        </div>
      </div>
      -->
      <div class="row wo-info" v-if="wo.customer_name">
        <div class="col-sm-12 col-md-6">
          <table class="wo-item-list border-bottom">
            <tr>
              <td class="col-medium">
                <strong>Customer Name</strong>
              </td>
              <td>
                <span v-html="wo.customer_name" />
              </td>
            </tr>
            <tr>
              <td class="col-medium">
                <strong>Site Address</strong>
              </td>
              <td>
                <span v-if="wo.site_address" v-html="wo.site_address" />
                <br />
                <span v-if="wo.site_city" v-html="wo.site_city" /><span v-if="wo.site_province" v-html="', ' + wo.site_province" /><span
                  v-if="wo.site_postal_code"
                  v-html="', ' + wo.site_postal_code"
                />
              </td>
            </tr>
          </table>
          <p class="safety-text">Safety First - Every Time - No Shortcuts</p>
        </div>

        <div class="col-sm-12 col-md-6" v-if="wo.WO_Number" id="pdfReady">
          <table class="wo-item-list">
            <tr>
              <td class="col-medium">
                <strong>Date</strong>
              </td>
              <td v-if="wo.Finished_Date">{{ formatDate(wo.Finished_Date) }}</td>
              <td v-else>{{ formatDate(new Date()) }}</td>
            </tr>
            <tr>
              <td class="col-medium">
                <strong>Customer PO</strong>
              </td>
              <td>
                <span v-html="wo.Customer_PO_Number" />
              </td>
            </tr>
            <tr>
              <td class="col-medium">
                <strong>Requested By</strong>
              </td>
              <td>
                <span v-html="wo.Bill_Contract" />
              </td>
            </tr>
            <tr>
              <td class="col-medium">
                <strong>Serviced By</strong>
              </td>
              <td>
                <span v-html="wo.Lead_Name" />
              </td>
            </tr>
          </table>
        </div>
      </div>

      <section class="row" v-if="wo_notes && wo_notes.length">
        <div class="col-sm-12">
          <table class="wo-item-list">
            <tr>
              <th class="col-narrow">Date</th>
              <th>Scope of Work</th>
            </tr>
            <tr v-for="(note, i) in wo_notes" :key="i">
              <td class="col-medium">
                <span v-html="note.nice_date" />
              </td>
              <td v-html="getPlainText(note.note_text)" />
            </tr>
          </table>
        </div>
      </section>

      <section class="row" v-if="wo_work_ordered_notes && wo_work_ordered_notes.length">
        <div class="col-sm-12">
          <table class="wo-item-list">
            <tr>
              <th class="col-narrow">Date</th>
              <th>Scope Notes</th>
            </tr>
            <tr v-for="(note, i) in wo_work_ordered_notes" :key="i">
              <td class="col-medium">
                <span v-html="note.nice_date" />
              </td>
              <td v-html="getPlainText(note.note_text)" />
            </tr>
          </table>
        </div>
      </section>

      <section class="row" v-if="wo_completed_notes && wo_completed_notes.length">
        <div class="col-sm-12">
          <table class="wo-item-list">
            <tr>
              <th class="col-narrow">Date</th>
              <th>Work Completed Notes</th>
            </tr>
            <tr v-for="(note, i) in wo_completed_notes" :key="i">
              <td class="col-medium">
                {{ note.nice_date }}
              </td>
              <td v-html="getPlainText(note.note_text)" />
            </tr>
          </table>
        </div>
      </section>

      <section class="row" v-if="!isQuote && timeEntries && timeEntries.length">
        <div class="col-sm-12">
          <table v-if="totalLaborHours >= 4 || showLaborLog" class="wo-item-list time-log">
            <tr>
              <th class="col-narrow">Date</th>
              <th v-if="showLaborLog">Time Log</th>
              <th>Employee</th>
              <th v-if="totalLaborHours >= 4">Hours</th>
              <th v-if="totalLaborHours >= 4">Type</th>
            </tr>
            <tr v-for="(entry, i) in timeEntries" :key="i">
              <td class="col-medium" v-html="entry.entry_date" />
              <td v-if="showLaborLog" v-html="getPlainText(entry.message)" />
              <td v-html="entry.employee_name" />
              <td v-if="totalLaborHours >= 4" v-html="entry.hours" />
              <td v-if="totalLaborHours >= 4" v-html="entry.hour_term" />
            </tr>
          </table>

          <table v-if="totalLaborHours < 4" class="wo-item-list time-log">
            <tr>
              <th>Labor</th>
            </tr>
            <tr>
              <td>Standard Service Charge</td>
            </tr>
          </table>
        </div>
      </section>

      <section class="row" v-if="wo_materials && wo_materials.length">
        <div class="col-sm-12">
          <table class="wo-item-list">
            <tr>
              <th class="col-narrow">Date</th>
              <th>Materials</th>
            </tr>
            <tr v-for="(note, i) in wo_materials" :key="i">
              <td class="col-medium">
                {{ note.nice_date }}
              </td>
              <td v-html="getPlainText(note.note_text)" />
            </tr>
          </table>
        </div>
      </section>

      <section class="row" v-if="otherCharges && otherCharges.length">
        <div class="col-sm-12">
          <table class="wo-item-list">
            <tr>
              <th class="col-narrow">Item #</th>
              <th>Other Charges</th>
            </tr>
            <tr v-for="(entry, i) in otherCharges" :key="i">
              <td v-html="i + 1" />
              <td v-html="getPlainText(entry.Description)" />
            </tr>
          </table>
        </div>
      </section>

      <section class="row" v-if="wo_recommendation_notes.length">
        <div class="col-sm-12">
          <table class="wo-item-list">
            <tr>
              <th class="col-narrow">Date</th>
              <th>Recommendations</th>
            </tr>
            <tr v-for="(note, i) in wo_recommendation_notes" :key="i">
              <td class="col-medium">
                {{ note.nice_date }}
              </td>
              <td v-html="getPlainText(note.note_text)" />
            </tr>
          </table>
        </div>
      </section>

      <section class="row" v-if="wo_extras_notes.length">
        <div class="col-sm-12">
          <table class="wo-item-list">
            <tr>
              <th class="col-narrow">Date</th>
              <th>Work Order Extras</th>
            </tr>
            <tr v-for="(note, i) in wo_extras_notes" :key="i">
              <td class="col-medium">
                {{ note.nice_date }}
              </td>
              <td v-html="getPlainText(note.note_text)" />
            </tr>
          </table>
        </div>
      </section>

      <section v-if="wo.WO_Number">
        <table class="wo-item-list wo-checklist">
          <tr>
            <td><label>Complete</label></td>
            <td class="narrow">
              <i v-if="wo.Dispatch_Status_Code.toString() === '7' || wo.Dispatch_Status_Code == 'F'" class="ti ti-check" />
            </td>
            <td><label>Quotation</label></td>
            <td class="narrow">
              <i v-if="wo.Customer_PO_Number" class="ti ti-check" />
            </td>
            <td><label>Recommendation</label></td>
            <td class="narrow">
              <i v-if="wo_recommendation_notes && wo_recommendation_notes.length > 0" class="ti ti-check" />
            </td>
            <td><label>Safety</label></td>
            <td class="narrow">
              <i class="ti ti-check" />
            </td>
          </tr>
        </table>
      </section>

      <!--  <section class="row" v-for="ts in 7">
        <div class="col-sm-12">
          <table class="wo-item-list">
            <tr>
              <th class="col-narrow">Date</th>
              <th>Space of notes to come</th>
            </tr>
            <tr v-for="row in Math.floor(Math.random() * Math.floor(30))">
              <td>2022-02-22</td>
              <td>This is a Twosday</td>
            </tr>
          </table>
        </div>
      </section> -->

      <section v-if="wo.WO_Number" class="save-section">
        <table class="wo-item-list border-bottom">
          <tr>
            <td>
              <p class="satisfaction-text">If for any reason our service did not exceed your expectations please contact us.</p>
            </td>
            <td>
              <div class="signature-display-container">
                <p><label>Customer Signature</label></p>
                <div v-if="wo.WO_Number">
                  <div class="existing-signature" v-if="existingSig">
                    <img :src="this.urls.mssql + 'wo-signatures/' + existingSig" />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </section>

      <div v-if="pdf" class="footer" style="margin-bottom: 20px">
        <div class="footer-inner">
          <div class="page-number">
            <p>Page 1 of 1</p>
          </div>
        </div>
      </div>

      <div v-if="wo.WO_Number">
        <div v-if="wo.Dispatch_Status_Code.toString() === '7' || wo.Dispatch_Status_Code === 'F'">
          <button v-if="!isLocked" class="btn not-pdf" @click="checkShowWoSign()" v-html="'Sign Off'" />
          <button v-if="!isLocked && sherAuth.eid" class="btn not-pdf" @click="showWoSignOffSend()" v-html="'Email Sign Off Link'" />
          <button v-if="lastSigned && isLocked" class="btn not-pdf" @click="downloadLastSigned()" v-html="'Download PDF'" />
          <button v-if="isAdmin && existingSig" class="btn not-pdf" @click="saveEmailPDF()" v-html="'PDF Re-Generate'" />
        </div>
        <div v-else-if="!pdf">
          <p><i>WO in preview mode pending "Finished" status.</i></p>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <waiting-spinner :wait="wait" />
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'
import SignatureSave from 'components/Dashboard/Forms/SignatureSave'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import WOFuncs from 'mixins/woFuncs'

export default {
  components: { WaitingSpinner },
  data() {
    return {
      wo: {},
      wo_completed_notes: [],
      wo_extras_notes: [],
      wo_notes: [],
      wo_recommendation_notes: [],
      wo_work_ordered_notes: [],
      wo_materials: [],
      customerEdit: false,
      preWorkPhotos: [],
      postWorkPhotos: [],
      emailNotifications: null,
      customerContacts: [],
      reviewData: {},
      userName: '',
      wait: {
        message: ''
      },
      existingSig: '',
      interval: 0,
      wobs: {
        complete: 'true',
        quotation: 'true',
        recommendation: '',
        safety: 'true'
      },
      existingPDF: '',
      signOff: [],
      timeEntries: [],
      totalLaborHours: 0,
      showLaborLog: false,
      otherCharges: [],
      isLocked: true,
      lastSigned: '',
      clicked: 0,
      isQuote: false
    }
  },

  props: {
    authCode: {
      type: String,
      required: true
    },
    pdf: {
      type: [String, Boolean],
      default: false
    }
  },

  mixins: [WOFuncs],

  computed: {
    userEmail() {
      return ((this.$store.getters.appData || {}).employeeData || {}).Employee_Email || ''
    },

    sherAuth() {
      return this.$store.getters.sherAuth
    },

    isAdmin() {
      let auth = this.$store.getters.userAuthedData.user_role
      return auth ? auth.indexOf('admin') !== -1 : false
    },

    urls() {
      return this.$store.getters.urls
    },

    auth() {
      return this.$store.getters.urls
    },

    woStatus() {
      const statuses = this.woDispatchStatuses
      const statusName = statuses.find(itm => {
        return itm.code == this.wo.Dispatch_Status_Code
      })
      if (statusName) {
        return statusName.description
      }
      return ''
    },

    woStatusClass() {
      switch (this.wo.Dispatch_Status_Code.toString()) {
        case '1':
        case '2':
        case '3':
        case '4':
        case '7':
        case 'F':
          return 'text-success'
        case '5':
          return 'text-warning'
        case '6':
          return 'text-danger'
        default:
          return ''
      }
    },

    hasSignOff() {
      return this.wo.WO_Number && this.signOff.length >= 1 ? true : false
    },

    companyName() {
      const vars = this.getRegionVars(this.wo.region)
      return vars.companyName
    },

    logoFile() {
      const vars = this.getRegionVars(this.wo.region)
      return vars.logoFile
    },

    regionAddress() {
      const vars = this.getRegionVars(this.wo.region)
      return vars.address
    }
  },

  methods: {
    getData(callback) {
      if (!this.authCode) {
        return
      }

      this.wait.message = 'Fetching data'

      const params = {
        action: 'get_wo_sign_off_data',
        auth_code: this.authCode,
        urldecode: 'false'
      }

      if (this.pdf) {
        params.try_cache = 1
      }

      let saveName = params.action + this.authCode

      appFuncs
        .shRequest(params, 1)
        .then(data => {
          this.setData(data)
          if (typeof callback === 'function') {
            callback()
          }
        })
        .catch(e => {
          console.log(e)
          this.$snack.open('Problem retrieving Work Order data, please try again now or later.', 'error')
        })
        .finally(() => {
          this.wait.message = ''
        })
    },

    setData(data) {
      this.wo = data.wo
      this.wo_completed_notes = ((data.notes || {}).wo_completed_notes || {}).data || []
      this.wo_recommendation_notes = ((data.notes || {}).wo_recommendation_notes || {}).data || []
      this.wo_notes = ((data.notes || {}).wo_notes || {}).data || [] // scope of work notes
      this.wo_work_ordered_notes = ((data.notes || {}).wo_work_ordered_notes || {}).data || [] // scope notes
      this.wo_extras_notes = ((data.notes || {}).wo_extras_notes || {}).data || []
      this.wo_materials = ((data.notes || {}).wo_materials || {}).data || []
      this.existingSig = data.last_wo_signature
      this.signOff = (data.signOff || {}).data || []
      this.timeEntries = data.time_card_entries || []
      this.otherCharges = data.wo.other_charges || []
      this.customerContacts = data.customer_contacts || []
      this.lastSigned = ((data.last_signed || {}).a_attr || {}).href || ''
      this.setUserOptions()
      this.loadExistingPDF()

      const signedPriorityCodes = [13, 4]
      this.isLocked = signedPriorityCodes.includes(parseInt(this.wo.Priority_Code))

      if (!this.wo.WO_Number) {
        this.$snack.open('Problem retrieving Work Order data, please try again now or later.', 'error')
      }

      // if user is MySheridan logged in and unposted time entries exist show sync option.
      if (this.sherAuth && this.sherAuth.eid) {
        const hasUnposted = this.timeEntries.find(itm => {
          return !itm.posted
        })
        if (hasUnposted) {
          // this.showTimeSync();
        }
      }

      this.totalLaborHours = 0
      for (var i = 0; i < this.timeEntries.length; i++) {
        this.totalLaborHours += parseFloat(this.timeEntries[i].hours) || 0
      }

      this.timeEntries = this.timeEntries.sort((a, b) => {
        if (a.entry_date > b.entry_date) return -1
        if (a.entry_date < b.entry_date) return 1
        // if no difference in work dates sort by time entered
        if (a.timestamp > b.timestamp) return -1
        if (a.timestamp < b.timestamp) return 1
      })

      this.isQuote = (((this.wo.admin_options || {}).signOff || {}).isQuote || false) == 'true' ? true : false
      this.showLaborLog = (((this.wo.admin_options || {}).signOff || {}).hideLaborDetails || false) == 'true' ? false : true
    },

    downloadLastSigned() {
      window.open(this.lastSigned)
    },

    setUserOptions() {
      const finishedCodes = ['F', '7']
      const woFinished = this.wo.Dispatch_Status_Code ? finishedCodes.indexOf(this.wo.Dispatch_Status_Code.toString()) !== -1 : false
    },

    loadExistingPDF() {
      if (this.signOff.length) {
        return
      }
      let latest = 0
      for (var i = this.signOff.length - 1; i >= 0; i--) {
        let parts = this.signOff[i].text.split('.')
        if (parts[1] && latest < parseInt(parts[1])) {
          latest = parts[1]
          this.existingPDF = this.signOff[i].a_attr.href
        }
      }
    },

    showTimeSync() {
      this.$bus.$emit('modalOpen', {
        title: 'Sign Off Options',
        classes: 'gray-bg',
        name: 'wo-sign-off-options',
        size: 'modal-m',
        component: () => import('src/components/Dashboard/JobWo/WOSignOffOptions'),
        componentData: {
          department: 'WO',
          woid: this.wo.WO_Number,
          name: 'wo-sign-off-options'
        }
      })
    },

    showWoSign() {
      this.$bus.$emit('modalOpen', {
        title: 'Sign Off Work Order',
        classes: 'gray-bg',
        name: 'signature-save',
        size: 'modal-m',
        component: 'signature-save',
        componentData: {
          itemId: this.wo.WO_Number,
          name: 'signature-save',
          signNameDefault: this.wo.Bill_Contract,
          authCode: this.authCode,
          customerContacts: this.customerContacts,
          saveFolder: 'wo-signatures',
          showDate: true,
          itemType: 'WO',
          existingSig: ''
        }
      })
    },

    showWoSignOffSend() {
      this.$Modal({
        parent: this,
        title: 'Email Sign Off Link',
        classes: 'gray-bg',
        name: 'send-signoff-link',
        size: 'lg',
        component: () => import('components/Dashboard/JobWo/SendSignOffLink.vue'),
        props: {
          name: 'send-signoff-link',
          authCode: this.authCode,
          itemType: 'WO',
          contacts: this.customerContacts,
          data: this.wo
        }
      })
    },

    checkShowWoSign() {
      if (this.wo.Dispatch_Status_Code.toString() !== '7' && this.wo.Dispatch_Status_Code !== 'F') {
        console.log('WO does not have finished status')
        return
      }

      this.showWoSign()
      return

      // if online re-check that wo is not locked
      // if (!this.isOnline) {
      //   this.showWoSign();
      //   return;
      // }

      const params = {
        action: 'get_wo_signed_status',
        wo_number: this.wo.WO_Number,
        auth_code: this.authCode
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Checking WO Status' })
      appFuncs
        .shRequest(params, 1)
        .then(data => {
          if (data === 'not locked') {
            this.showWoSign()
          } else {
            this.$snack.open('This Work Order appears to been signed, refresh the page to re-check', 'error')
          }
        })
        .catch(res => {
          console.log(res)
          this.$snack.open('Problem getting WO status, refresh the page to try again.', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    saveEmailPDF(emailTo) {
      appFuncs.scrollTop()

      const params = {
        action: 'receive_save_wo_signoff',
        auth_code: this.authCode,
        url: window.location.href,
        email_to: emailTo,
        email_cc: this.userEmail
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Saving document' })
      appFuncs
        .shRequest(params, 1)
        .then(data => {
          let message = ''
          if (data.emailRes && data.emailRes['status'] === 'success') {
            message = 'PDF emailed successfully'
          } else {
            message = 'PDF saved successfully'
          }

          appFuncs.scrollTop()

          this.getData()

          if (!emailTo && data.location) {
            window.open(data.location)
          }

          this.$snack.open(message)
        })
        .catch(res => {
          console.log(res)
          this.$snack.open(res.message || 'Problem generating PDF, please try again later', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    downloadPDF() {
      window.open(this.existingPDF)
    },

    getPlainText(text) {
      return appFuncs.getPlainText(text)
    },

    pdfClearModifications() {
      let footer = document.getElementsByClassName('footer')[0]
      if (!footer) {
        return
      }
      // remove all previously added content
      let addedContent = document.getElementsByClassName('added-content')
      while (addedContent[0]) {
        addedContent[0].parentNode.removeChild(addedContent[0])
      }

      let addedSpacers = document.getElementsByClassName('added-spacer')
      while (addedSpacers[0]) {
        addedSpacers[0].parentNode.removeChild(addedSpacers[0])
      }
      let addedLines = document.getElementsByClassName('measure-line')
      while (addedLines[0]) {
        addedLines[0].parentNode.removeChild(addedLines[0])
      }
      let pageNum = footer.getElementsByClassName('page-number')
      if (pageNum && pageNum[0]) {
        pageNum[0].innerHTML = '<p>Page 1 of 1<p>'
      }
    },

    pdfSpaceSections() {
      let mainEl = document.getElementById('customer-ticket')
      let sections = Array.prototype.slice.call(document.querySelectorAll('section'))

      let header = document.getElementsByClassName('header')[0]
      let footer = document.getElementsByClassName('footer')[0]
      let footerHeight = footer.offsetHeight + parseInt(footer.style.marginBottom)

      const pageHeight = 1490
      let nextPageBottom = pageHeight
      let pageCount = 1

      sections.forEach(function (itm) {
        let top = itm.getBoundingClientRect().top + document.documentElement.scrollTop
        let itmHeight = itm.offsetHeight
        let bottom = itmHeight + top + 100

        // if page break is between top and bottom, add enough margin to get on next page
        if (top < nextPageBottom && bottom > nextPageBottom) {
          // if section is taller than page, add break between table rows
          if (itmHeight > 500) {
            let rows = itm.getElementsByTagName('tr')
            Array.from(rows).forEach(function (rowItm) {
              let rowTop = rowItm.getBoundingClientRect().top + document.documentElement.scrollTop
              let rowHeight = rowItm.offsetHeight
              let rowBottom = rowHeight + rowTop + 100
              if (rowTop < nextPageBottom && rowBottom > nextPageBottom) {
                let marginNeeded = pageCount * pageHeight - rowTop + header.offsetHeight
                let spacer = document.createElement('div')
                spacer.classList.add('added-spacer')
                spacer.style.paddingTop = marginNeeded + 'px' // use padding to prevent top of margin issue from not creating space on next page.
                rowItm.insertAdjacentHTML('beforebegin', spacer.outerHTML)
                nextPageBottom += pageHeight
                pageCount++
              }
            })
          } else {
            let marginNeeded = pageCount * pageHeight - top + header.offsetHeight
            let spacer = document.createElement('div')
            spacer.classList.add('added-spacer')
            spacer.style.paddingTop = marginNeeded + 'px' // use padding to prevent top of margin issue from not creating space on next page.
            itm.insertAdjacentHTML('beforebegin', spacer.outerHTML)
            nextPageBottom += pageHeight
            pageCount++
          }
        }
      })

      let newFooter = null
      let addedContent = null
      let pageNo = 0
      let topPos = 0
      let pageNum = null
      for (var i = 0; i < pageCount - 1; i++) {
        // -1 dont do last page, use original footer
        pageNo++
        newFooter = footer.cloneNode(true)
        pageNum = newFooter.getElementsByClassName('page-number')
        if (pageNum && pageNum[0]) {
          pageNum[0].innerHTML = '<p>Page ' + pageNo + ' of ' + pageCount + '<p>'
        }
        topPos = pageNo * pageHeight - footerHeight
        addedContent = '<div class="added-content" style="top:' + topPos + 'px">' + newFooter.outerHTML + '<div style="margin-top:0px">' + header.outerHTML + '</div>' + '</div>'
        mainEl.insertAdjacentHTML('afterend', addedContent)
      }

      // update original footer now at bottom of page
      let pageNumLast = footer.getElementsByClassName('page-number')
      if (pageNumLast && pageNumLast[0]) {
        pageNumLast[0].innerHTML = '<p>Page ' + pageCount + ' of ' + pageCount + '<p>'
      }

      // this.addGridLines();
    },

    addGridLines() {
      let addedContent = document.getElementsByClassName('measure-line')
      while (addedContent[0]) {
        addedContent[0].parentNode.removeChild(addedContent[0])
      }

      let mainEl = document.getElementById('customer-ticket').offsetHeight
      let parts = mainEl / 10
      let line = null
      for (var i = 0; i < parts; i++) {
        line = document.createElement('div')
        line.setAttribute('class', 'measure-line')
        line.style.top = i * 10 + 'px'
        line.innerHTML = i * 10
        if ((i * 10) % 1490 == 0) {
          line.style.background = 'purple'
        }
        document.getElementsByTagName('body')[0].appendChild(line)
      }
    }
  },

  mounted() {
    this.getData()
    if (this.$store.getters.userAuthedData && this.$store.getters.userAuthedData.eid) {
      this.userName = this.$store.getters.userAuthedData.fname + ' ' + this.$store.getters.userAuthedData.lname
    }

    // this gets added when pdf is getting generated - helper for css changes
    // document.querySelector("body").classList.add("pdf");

    this.$bus.$on('signatureSaved', data => {
      // display new sig
      this.getData(() => {
        if (data.saveDoc) {
          this.saveEmailPDF(data.emailTo)
        }
      })

      if (this.existingPDF) {
        setTimeout(() => {
          this.$snack.open('Re-Generate PDF to ensure that updated signature is present.', 'error')
          this.existingPDF = ''
        }, 3000)
      }
    })
  },

  updated() {
    if (this.pdf) {
      this.pdfClearModifications()
      setTimeout(() => {
        this.pdfSpaceSections()
      }, 150)
    }
  }
}
</script>

<style lang="scss">
.measure-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background: red;
}

.added-content {
  width: 100%;
  position: absolute;

  .header {
    max-width: 1100px;
    margin: 0 auto;
  }

  .footer-inner {
    padding: 0 15px;
  }
}
</style>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

// pdf styles
body.pdf .customer-ticket {
  min-width: 1200px;
}

body.pdf .header > div {
  width: 33% !important;
  text-align: left !important;
  float: left;
}

body.pdf .wo-info > div {
  width: 50% !important;
  text-align: left !important;
  float: left;
}

body.pdf .header .col-sm-4.text-right {
  text-align: right !important;
}

body.pdf .header img {
  margin: 0 !important;
}

.customer-ticket .container {
  max-width: 1100px;
  margin: 0 auto;
}

.header {
  padding: 40px 0 10px;

  img {
    width: 100%;
    max-width: 300px;

    @media (max-width: 767px) {
      margin: 0 auto;
      display: block;
    }
  }

  h4 {
    margin: 0;
  }

  h5 {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    > .col-xs-12 {
      text-align: center;
    }
  }
}

.wo-info {
  span {
    // font-weight:bold;
    // text-decoration:underline;
  }
}

hr {
  border-color: $dark-gray;
}

table.wo-item-list {
  width: 100%;
  margin-bottom: 20px;

  td,
  th {
    border: 1px solid $other-medium-gray;
    padding: 3px;
    min-width: 90px;
    word-break: break-word;
  }

  &:not(.border-bottom) {
    td,
    th {
      //  border-bottom:0;
    }
  }

  .col-narrow {
    width: 80px;
  }

  .col-medium {
    width: 150px;
  }
}

.wo-status-container {
  margin-bottom: 20px;

  h3 {
    display: inline-block;
  }

  .wo-status {
    padding: 10px 0;
    font-size: 16px;

    span {
      text-transform: uppercase;
      font-weight: bold;

      &.green {
        color: $success-color;
      }

      &.yellow {
        color: $general-yellow;
      }

      &.red {
        color: $general-red;
      }
    }
  }
}

.safety-text {
  color: red;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
}

.wo-checklist {
  display: none;
  min-width: 500px;
  table-layout: fixed;
  margin-bottom: 0 !important;

  td {
    width: 100px;
    border-bottom: 0 !important;

    &.narrow {
      width: 20px;
      text-align: center;

      i {
        color: green;
      }
    }
  }

  label {
    margin-bottom: 0;
  }
}

.satisfaction-text {
  color: red;
  padding: 20px 40px;
  text-transform: uppercase;
  font-size: 18px;

  @media (max-width: 600px) {
    font-size: 12px;
    padding: 5px;
  }
}

.signature-display-container {
  min-width: 200px;
  max-width: 370px;
  min-height: 150px;
  padding: 5px;

  img {
    width: 100%;
  }

  p {
    text-align: center;
    padding: 5px;
    margin: 0;
  }
}

.footer {
  .footer-inner {
    max-width: 1100px;
    margin: 0 auto;
  }

  .page-number {
    text-align: right;
  }
}
</style>
