<template>
  <div>
    <div class="select-field-multi-container">
      <select-field-dynamic
        :options.sync="selectableOptions"
        :option-display-keys="optionDisplayKeys"
        :option-val="optionVal"
        :disabled="disabled"
        :disabled-input="disabledInput"
        :classes="allClasses"
        :option-seperator="optionSeperator"
        :empty-option-text="emptyOptionText"
        :inline="inline"
        :placeholder-text="placeholderText"
        @selectItem="addItem"
        :allow-add-item="allowAddItem"
        @addOption="addOption"
        :allow-add-simple-item="allowAddSimpleItem"
        :item-name="itemName"
        :preselect-single-option="preselectSingleOption"
        :filter-on-key-val="filterOnKeyVal"
        :label="label"
        :show-remove-warning="true"
        :allow-clear="allowClear"
        @clear="clear"
      />

      <div class="selected-items-container" v-if="showSelected">
        <label class="assigned" v-if="selectedItems.length" v-html="itemName ? itemName + 's' : 'Assigned'" />
        <ul>
          <li v-for="(itm, i) in selectedItems" :key="i">
            -
            <span v-html="getDisplayVal(itm)"></span>
            <i class="ti ti-close" @click="removeFromSelected(itm)" v-if="!disabled"></i>
          </li>
        </ul>
      </div>
    </div>

    <waiting-spinner />
  </div>
</template>
<script>
import SelectFieldDynamic from 'components/UIComponents/SelectFieldDynamic'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {
      multiOptions: [], // contains options instead of prop
      selectableOptions: []
    }
  },

  props: {
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    classes: {
      type: Array,
      default: function () {
        return []
      }
    },
    optionDisplayKeys: Array,
    selectedDisplayKeys: Array,
    optionVal: [String, Array],
    value: [Array, String, Number],
    optionSeperator: {
      type: String,
      default: ' - '
    },
    emptyOptionText: {
      type: String,
      default: 'No items found'
    },
    disabled: {
      type: [Boolean, Number, String],
      default: false
    },
    disabledInput: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    placeholderText: {
      type: String,
      default: 'Add Item'
    },
    allowAddSimpleItem: {
      type: Boolean,
      default: false
    },
    allowAddItem: {
      type: Boolean,
      default: false
    },
    itemName: {
      type: String,
      default: ''
    },
    preselectSingleOption: {
      type: Boolean,
      default: false // true val creates illusion of item getting assigned to list when not - just because showing in select field
    },
    filterOnKeyVal: {
      type: Object,
      default() {
        return {}
      }
    },
    showSelected: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    showRemoveWarning: {
      type: Boolean,
      default: true
    },
    allowClear: {
      type: Boolean,
      default: false
    }
  },

  components: {
    SelectFieldDynamic,
    WaitingSpinner
  },

  computed: {
    selectedItems() {
      if (Array.isArray(this.value) && this.value[0]) return this.value
      if (this.value && !Array.isArray(this.value)) return [this.value]
      return []
    },

    allClasses() {
      let classes = this.classes
      classes.push('fake-disabled')
      return classes
    }
  },

  methods: {
    addItem(payload) {
      let addedItems = []
      if (payload) {
        if (this.selectedItems.indexOf(payload) === -1) {
          this.selectedItems.push(payload)
          addedItems.push(payload)
        }
        this.$emit('input', this.selectedItems)
        this.$emit('selectItem', this.selectedItems)
        this.$emit('addItem', addedItems)
        this.setSelectableOptions()
      }
    },

    setSelectableOptions() {
      let options = JSON.parse(JSON.stringify(this.multiOptions))
      for (var i = options.length - 1; i >= 0; i--) {
        // if this optionitem value is in selected items, remove it from selectable options
        if (this.selectedItems.indexOf(options[i][this.optionVal]) !== -1) {
          options.splice(i, 1)
        }
      }
      this.selectableOptions = options
    },

    addOption(option) {
      this.multiOptions.push(option)
    },

    clear() {
      // this.value = []
      this.$emit('input', [])
      this.$emit('selectItem', [])
      this.setSelectableOptions()
    },

    removeFromSelected(val) {
      if (this.showRemoveWarning && !confirm("Are you sure you'd like to delete this item?")) {
        return
      }
      if (this.disabled) {
        return
      }
      let i = this.selectedItems.indexOf(val)
      if (i !== -1) {
        this.selectedItems.splice(i, 1)
        this.$emit('input', this.selectedItems)
        this.$emit('selectItem', this.selectedItems)
        this.setSelectableOptions()
      }
    },

    getDisplayVal(val) {
      let match = this.multiOptions.find(itm => {
        return itm[this.optionVal] == val
      })

      let outStr = ''

      if (match) {
        const displayKeys = !this.selectedDisplayKeys?.length ? this.optionDisplayKeys : this.selectedDisplayKeys

        for (var i = 0; i < displayKeys.length; i++) {
          if (match[displayKeys[i]] == undefined) continue

          // was showing key name when val undefined for some reason - removing for functionality with SendSignOffLink - changed after GIT 17eecd119c7a
          // outStr+= match[this.optionDisplayKeys[i]] == undefined ? this.optionDisplayKeys[i] : match[this.optionDisplayKeys[i]];
          outStr += match[displayKeys[i]] == undefined ? '' : match[displayKeys[i]]

          if (i !== displayKeys.length - 1) {
            outStr += this.optionSeperator
          }
        }
        return outStr
      } else {
        return val
      }
    }
  },

  watch: {
    options() {
      this.multiOptions = this.options
      this.setSelectableOptions()
    }
  },

  mounted() {
    this.multiOptions = this.options
    this.setSelectableOptions()
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.selected-items-container {
  padding: 0 5px;

  ul {
    padding: 0 0 0 5px;

    li {
      display: block;

      i {
        float: right;
        font-size: 11px;
        color: $general-red;
        cursor: pointer;
      }
    }
  }
}

label.assigned {
  font-size: 13px;
  font-weight: 500;
  margin-top: 5px;
}
</style>
