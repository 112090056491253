
<div>
  <div class="row">
    <div class="col-sm-12 form-column">
      <div v-if="pictures.length" class="imgs-container">
        <div :class="'img-container'" v-for="(picture, pi) in pics" :key="pi">
          <div class="rotate-container">
            <img :src="picture.a_attr.href" @load="imageLoaded">
          </div>
          <p><a target="_blank" :href="picture.a_attr.href">{{ picture.text }}</a></p>
        </div>
      </div>
      <div v-else>
        <p>No pictures added yet.</p>
      </div>
    </div>
  </div>
  <waiting-spinner/>
</div>
