<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-lg-8">
        <router-link class="back-nav-link" :to="{ name: 'Purchase Order Reports' }"><i class="fa fa-chevron-left" />Back</router-link>

        <div class="card">
          <div class="content">
            <div class="po-summary">
              <div v-if="typeFormatted === 'vendor'" class="vendor-contact-display">
                <label><strong>Vendor:</strong> <span v-html="vendor.Vendor_Name" /></label>
                <div v-if="vendorContactName && contact.Email">
                  <label><strong>Billing Contact:</strong> <span v-html="vendorContactName" /></label>
                  <label><strong>Email:</strong> <span v-html="contact.Email || ''" /></label>
                  <i class="ti ti-pencil vendor-contact-edit" @click="addEditBillingContactLink" />
                </div>
                <a v-else v-html="'<i><u>Add Billing Contact</u></i>'" class="link" @click="addEditBillingContactLink" style="margin-top: 30px" />
              </div>
              <div v-if="typeFormatted === 'wo'" class="po-summary">
                <label><strong>WO#: </strong> <span v-html="itmCode" /></label>
                <label><strong>Description: </strong> <span v-html="wo.Summary_Description" /></label>
              </div>
              <div v-if="typeFormatted === 'job'" class="po-summary">
                <label><strong>Job#: </strong> <span v-html="itmCode" /></label>
                <label><strong>Description: </strong> <span v-html="job.Job_Description" /></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-8">
        <div v-if="typeFormatted === 'vendor'" class="row">
          <div class="col-xs-6">
            <a v-if="previewUrl" class="btn" :href="previewUrl" target="_blank"> Preview Report </a>
          </div>
          <div class="col-xs-6 text-right">
            <div style="display: inline-block">
              <checkbox v-model="disable_auto_send" @change="updateVendor"> Disable Auto Reports</checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />

    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="content po-table-container">
            <h4 style="margin: 10px 0 20px">Open Purchase Orders</h4>
            <!-- <p><i>(Open and older than 2 weeks)</i></p> -->
            <bh-table :table-settings="tableSettings" />
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner :wait="wait" />
  </div>
</template>

<script>
import store from 'store'
import { mapGetters } from 'vuex'
import DatePicker from 'components/UIComponents/DatePicker'
import SelectField from 'components/UIComponents/SelectField'
import appFuncs from 'appFuncs'
import BhTable from 'components/UIComponents/BhTable'
import VendorContactsAddEdit from 'components/Dashboard/Contacts/VendorContactsAddEdit'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import dateFormat from 'dateformat'

export default {
  name: 'POReportSummary',
  data() {
    return {
      wait: {
        message: ''
      },
      vendor: {},
      selectedVendor: '',
      contact: {},
      job: {},
      wo: {},
      disable_auto_send: null,
      autoPOReportsSend: null,
      vendorUpdating: false,
      tableSettings: {
        tableData: [],
        fields: {},
        noItemEdit: true,
        hideSearch: false
      },
      previewUrl: ''
    }
  },

  components: {
    SelectField,
    BhTable,
    WaitingSpinner
  },

  props: {
    type: {
      type: String,
      required: true
    },
    itmCode: {
      type: [String, Number],
      required: true
    }
  },

  computed: {
    ...mapGetters(['vendors', 'vendorContacts', 'vendorPOData', 'woAssemble']),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },

    vendorContactName() {
      if (this.contact.First_Name || this.contact.Last_Name) {
        return (this.contact.First_Name || '') + ' ' + (this.contact.Last_Name || '')
      }
      return ''
    },

    typeFormatted() {
      return typeof this.type === 'string' ? this.type.toLowerCase() : ''
    }
  },

  methods: {
    getPOs() {
      const params = {
        action: 'get_purchase_orders',
        type: this.type,
        itmCode: this.itmCode,
        status: 'OPEN',
        limit: 100,
        daysOld: 90,
        daysNew: 14
      }
      this.wait.message = 'Getting Purchase Orders'
      appFuncs
        .shRequest(params, 1)
        .then((data, res) => {
          if (Array.isArray(data)) {
            for (var i = data.length - 1; i >= 0; i--) {
              data[i].id = data[i].PO_Number
              data[i].niceDate = dateFormat(Date.parse(data[i]['PO_Date_List1']), 'mm/dd/yyyy')
              data[i].Vendor_Name = this.getVendorName(data[i].Vendor_Code) || data[i].Vendor_Code
              data[i].Full_Address = data[i].Ship_Address_1 + ', ' + data[i].Ship_City + ', ' + data[i].Ship_State
            }
            this.tableSettings.tableData = data
          } else {
            this.$snack.open('Problem getting purchase orders', 'error')
          }
        })
        .catch(res => {
          console.log(res)
          this.$snack.open('Problem getting purchase orders.', 'error')
        })
        .finally(() => {
          this.wait.message = ''
        })
    },

    getReportTemplateURL() {
      if (this.typeFormatted !== 'vendor') {
        return
      }
      const params = {
        action: 'get_po_report_template_code',
        type: this.type,
        itmCode: this.itmCode
      }
      this.wait.message = 'Getting Report Page'
      appFuncs
        .shRequest(params, 1)
        .then(data => {
          if (data && data.code) {
            this.previewUrl = `${this.$store.getters.urls.frontend}#/po-report/${data.code}`
          } else {
            this.$snack.open('Problem getting report page', 'error')
          }
        })
        .catch(res => {
          console.log(res)
          this.$snack.open('Problem getting report page.', 'error')
        })
        .finally(() => {
          this.wait.message = ''
        })
    },

    getVendorContacts(refresh) {
      this.$bus.$emit('setWaiting', { name: 'getVendorContacts', message: 'Getting Vendor Contacts' })
      this.$store.dispatch('getVendorContacts', refresh).then(() => {
        this.$bus.$emit('stopWaiting', 'getVendorContacts')
        let contact = this.vendorContacts.find(itm => {
          return itm.Vendor_Code == this.itmCode && itm.AP == 1
        })
        this.contact = contact || {}
      })
    },

    addEditBillingContactLink() {
      // show vendor contact add edit modal
      this.$Modal({
        parent: this,
        name: 'VendorContactsAddEdit', // used for closing specific modal programmatically
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: VendorContactsAddEdit,
        props: {
          id: this.contact.id || 0,
          selectedVendor: this.vendor.Vendor_Code
        }
      })
    },

    getVendorName(code) {
      const vendor = this.vendors.find(itm => {
        return itm.Vendor_Code == code
      })
      if (vendor) {
        return vendor.Vendor_Name
      }
      return ''
    },

    updateVendor() {
      setTimeout(() => {
        // make sure checkbox change has completed

        if (this.vendorUpdating || this.typeFormatted !== 'vendor') return

        const params = {
          action: 'vendor_po_reports_entry_update',
          Vendor_Code: this.itmCode,
          disableAutoSend: this.disable_auto_send ? 1 : 0
        }

        this.$bus.$emit('setWaiting', { name: params.action, message: 'Updating Vendor data' })
        this.vendorUpdating = true
        appFuncs
          .shRequest(params)
          .then(data => {
            // this.$snack.open('Vendor data updated');
            // this.getVendors('refresh');
          })
          .catch(res => {
            console.log(res)
            this.$snack.open('Problem updating vendor data', 'warning')
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', params.action)
            setTimeout(() => {
              this.vendorUpdating = false
            }, 1000)
          })
      }, 500)
    },

    getVendors(refresh) {
      this.$bus.$emit('setWaiting', { name: 'getVendors', message: 'Getting Vendors' })
      this.$store.dispatch('getVendors', refresh).then(() => {
        this.$bus.$emit('stopWaiting', 'getVendors')
        this.getPOs() // has dependancy on vendor data
        let vendor = this.vendors.find(itm => {
          return itm.Vendor_Code === this.itmCode
        })
        if (vendor) {
          this.vendor = vendor
          this.autoPOReportsSend = this.vendor.AutoPOReportsSend
        }
      })
    },

    getVendorPOdata() {
      if (this.typeFormatted !== 'vendor') return

      this.$store
        .dispatch('getVendorPOData')
        .then(() => {
          const vendorPOData = this.vendorPOData.find(itm => {
            return itm.vendor_code == this.itmCode
          })

          console.log(vendorPOData)

          if (vendorPOData) {
            this.disable_auto_send = vendorPOData.disable_auto_send == '1'
          }
        })
        .catch(res => {
          this.$snack.open('Problem getting vendor PO data', 'error')
          console.log(res)
        })
    },

    setTableFields() {
      switch (this.typeFormatted) {
        case 'vendor':
          this.tableSettings.fields = {
            niceDate: {
              name: 'Date'
            },
            PO_Number: {
              name: 'PO Number'
            },
            Status_Flag: {
              name: 'Status'
            },
            Full_Address: {
              name: 'Ship to Address'
            }
          }
          break
        case 'wo':
          this.tableSettings.fields = {
            niceDate: {
              name: 'Date'
            },
            Vendor_Name: {
              name: 'Vendor'
            },
            Job_Number: {
              name: 'WO Number'
            },
            PO_Number: {
              name: 'PO Number'
            },
            Status_Flag: {
              name: 'Status'
            },
            Full_Address: {
              name: 'Ship to Address'
            }
          }
          break
        case 'job':
          this.tableSettings.fields = {
            niceDate: {
              name: 'Date'
            },
            Vendor_Name: {
              name: 'Vendor'
            },
            Job_Number: {
              name: 'Job Number'
            },
            PO_Number: {
              name: 'PO Number'
            },
            Status_Flag: {
              name: 'Status'
            },
            Full_Address: {
              name: 'Ship to Address'
            }
          }
      }
    }
  },

  mounted() {
    this.setTableFields()
    this.getReportTemplateURL()
    this.getVendors()
    this.getVendorContacts()
    this.getVendorPOdata()

    this.$bus.$on('vendorContactUpdate', () => {
      this.getVendorContacts('refresh')
    })

    this.$bus.$emit('setWaiting', { name: 'getWoAssemble', message: 'Getting Work Orders' })
    this.$store
      .dispatch('getWoAssemble')
      .then(() => {
        if (Array.isArray(this.woAssemble)) {
          const wo = this.woAssemble.find(itm => {
            return itm.WO_Number == this.itmCode
          })
          this.wo = wo || {}
        }
      })
      .finally(() => {
        this.$bus.$emit('stopWaiting', 'getWoAssemble')
      })

    appFuncs.getJobInfo(res => {
      if (res.status === 'success') {
        const jobs = Array.isArray(res.data) ? res.data : []
        const job = jobs.find(itm => {
          return itm.Job_Number == this.itmCode
        })
        this.job = job || {}
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.back-nav-link {
  display: block;
  margin: -20px 0 20px;
  color: $font-color;
  text-decoration: underline;
}

.vendor-contact-display {
  position: relative;
  padding-right: 30px;

  .vendor-contact-edit {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    font-size: 16px;
  }
}

.po-summary {
  // border: 1px solid;
  padding: 10px;
  // margin-bottom:20px;
  // border-radius: $border-radius-base;

  label {
    display: block;
    font-size: 18px;
    font-weight: normal;
  }
}

.po-table-container {
  h3 {
    margin: 15px 0 0;
  }
}
</style>
