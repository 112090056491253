import Vue from 'vue'
import ConfirmDialog from 'src/components/UIComponents/ConfirmDialog.vue'

const ConfirmDialogConstructor = Vue.extend(ConfirmDialog)

function openConfirmDialog({ title, html, confirmButton, cancelButton, modalName, hideCancel }) {
  return new Promise((resolve, reject) => {
    const instance = new ConfirmDialogConstructor({
      propsData: { title, html, confirmButton, cancelButton, modalName, hideCancel }
    })

    instance.$mount()
    document.body.appendChild(instance.$el)

    instance.$on('confirm', () => {
      resolve(true)
      instance.$destroy()
      document.body.removeChild(instance.$el)
    })

    instance.$on('cancel', () => {
      resolve(false)
      instance.$destroy()
      document.body.removeChild(instance.$el)
    })

    instance.showModal()
  })
}

async function showConfirmDialog(html, hideCancel) {
  const confirm = await openConfirmDialog({
    title: 'Confirm Action',
    html,
    hideCancel: hideCancel ? true : false,
    confirmButton: {
      text: 'OK'
    },
    cancelButton: {
      text: 'Cancel'
    },
    modalName: 'confirmDialog'
  })

  return confirm
}

export default openConfirmDialog
export { openConfirmDialog, showConfirmDialog }
