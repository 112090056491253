
<div class="po-report" id="po-report">
  <div class="container">
    <div class="header">
      <div>
        <router-link to="/">
          <img :src="urls.frontend + 'static/img/logo.jpg'" />
        </router-link>
      </div>
      <hr />
    </div>

    <section class="row" v-if="itmCode" id="pdfReady">
      <div class="col col-xs-8 doc-title">
        <h4>ATTN: ACCOUNTS RECEIVABLE DEPARTMENT</h4>
        <h4>INVOICE COPY REQUEST</h4>
      </div>
      <div class="col col-xs-4 doc-company text-right">
        <p>
          <strong><span v-html="vendor.Vendor_Name" /></strong><br />
          <span v-html="vendor.Address_1" />, <span v-html="vendor.Address_2" /><br />
          <span v-html="vendor.Address_3" />, <span v-html="vendor.State" />, <span v-html="vendor.Zip_Code" /><br />
          <span v-if="apContact.Direct_Line">Tel: <span v-html="apContact.Direct_Line" /><br /></span>
          Email: <span v-html="apContact.Email" /><br />
        </p>
      </div>
    </section>

    <section class="row">
      <div class="col-sm-12 col-md-4 doc-date">
        <strong>{{ dateToday }}</strong>
      </div>

      <div class="col-sm-12 doc-content">
        <div v-html="reportText" style="margin-bottom: 15px" />

        <p>Please send invoice copies to:</p>

        <p>
          <strong>Email:</strong> accounts_payable@sheridanelectric.ca <br /><strong>Fax:</strong> (905) 625-0265 <br /><strong>Mail:</strong> <br />&emsp;Attn: Accounts Payable <br />&emsp;Sheridan
          Electric Services <br />&emsp;966 Pantera Dr, Unit #34 <br />&emsp;Mississauga ON, L4W 2S1
        </p>

        <br />
        <p>Thank you for your assistance.</p>
      </div>
    </section>

    <section class="row">
      <div class="col-sm-12">
        <h4 class="section-title">Outstanding PO's</h4>
        <table class="wo-item-list">
          <tr>
            <td>
              <strong>Date</strong>
            </td>
            <td>
              <strong>PO Number</strong>
            </td>
            <td>
              <strong>Status</strong>
            </td>
            <td>
              <strong>Ship Address</strong>
            </td>
          </tr>
          <tr v-for="(po, pi) in pos" :key="pi">
            <td>{{ po.niceDate }}</td>
            <td>{{ po.PO_Number }}</td>
            <td>{{ po.Status_Flag }}</td>
            <td>{{ po.Full_Address }}</td>
          </tr>
        </table>
        <p v-if="!pos || !pos.length"><i>No Open PO's found</i></p>
      </div>
    </section>

    <div v-if="pdf" class="footer" style="margin-bottom: 20px">
      <div class="footer-inner">
        <div class="page-number">
          <p>Page 1 of 1</p>
        </div>
      </div>
    </div>

    <div class="row not-pdf">
      <div class="col-sm-12" style="margin-bottom: 15px">
        <hr />
        <div v-if="apContact.Email">
          <button class="btn" @click="sendReport" v-html="'Send Report'" />
          <div style="italic">
            <strong>AP Contacts:</strong>
            <span v-for="(contact, apci) in apContacts" :key="apci">
              <span v-html="contact.Email + (apContacts.length > 1 && apci + 1 !== apContacts.length ? ', ' : '')" />
            </span>
          </div>
        </div>
        <p v-else><i>No AP Contact found for this vendor</i></p>
      </div>
    </div>
  </div>

  <waiting-spinner :wait="wait" />
</div>
