<template>
  <div class="">
    <div class="row">
      <div :class="[statusOptions && statusOptions.length > 1 ? 'col-sm-12 col-md-6' : 'col-sm-12']" v-if="showAuthor">
        <div class="form-group">
          <label>Author</label>
          <select-field
            :options="employees"
            :option-display-keys="['Employee_Name']"
            :option-val="'Employee_Code'"
            v-model="author"
            :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
            allow-clear="All"
            @selectItem="getLists()"
          />
        </div>
      </div>
      <div :class="[showAuthor ? 'col-sm-12 col-md-6' : 'col-sm-12']" v-if="statusOptions && statusOptions.length > 1">
        <div class="form-group">
          <label>Status</label>
          <select-field :options="statusOptions" :option-display-keys="['name']" :option-val="'val'" v-model="rfpStatus" @selectItem="getLists()" allow-clear="Any" />
        </div>
      </div>
    </div>

    <div class="">
      <tabulator-table :table-data="filteredLists" :table-columns="tableColumns" @colClick="tableClick" table-fit="fitColumns" :table-condensed="false" empty-text="(none)" :table-striped="true" />
    </div>
    <waiting-spinner :wait="wait" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import SelectField from 'components/UIComponents/SelectField'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import moment from 'moment'

export default {
  name: 'MaterialGroupsList',
  data() {
    return {
      wait: { message: '' },
      materialLists: [],
      type: '',
      author: '',
      quoteNumber: '',
      rfpStatus: 'locked'
    }
  },

  computed: {
    ...mapGetters(['employees']),

    tableColumns() {
      return [
        {
          field: 'id',
          visible: false,
          frozen: true
        },
        {
          title: 'Name',
          field: 'name',
          sorter: 'string',
          width: 200,
          cssClass: 'no-horo-border'
        },
        {
          title: 'Description',
          field: 'description',
          sorter: 'string',
          minWidth: 200
          //visible: !this.minFields
        },
        {
          title: 'Author',
          field: 'added_by',
          sorter: 'string',
          minWidth: 150,
          visible: this.showAuthor,
          formatter: val => {
            const employeeCode = val.getValue()
            const obj = this.employees.find(itm => {
              return itm.Employee_Code == employeeCode
            })
            return obj && obj.Employee_Name ? obj.Employee_Name : ''
          }
        },
        {
          title: 'Status',
          field: 'status',
          sorter: 'string',
          minWidth: 80,
          visible: this.statusOptions.length > 1,
          formatter: val => {
            let out = ''
            switch (val.getValue()) {
              case 'open':
                out = 'Draft'
                break
              case 'locked':
                out = 'Active'
                break
              case 'deleted':
                out = 'Deleted'
                break
            }
            return out
          }
        },
        {
          title: 'Date Added',
          field: 'date_added',
          sorter: 'date',
          minWidth: 120,
          formatter: 'datetime',
          formatterParams: {
            inputFormat: 'X',
            outputFormat: 'MMM Do YYYY'
          }
        }
      ]
    },

    filteredLists() {
      let lists = JSON.parse(JSON.stringify(this.materialLists))
      if (this.type) {
        lists = lists.filter(itm => {
          return itm.type === this.type
        })
      }

      if (this.author) {
        lists = lists.filter(itm => {
          return itm.added_by == this.author
        })
      }
      return lists
    }
  },

  props: {
    selectedMaterials: {
      type: Array,
      default: function () {
        return []
      }
    },
    statusOptions: {
      type: Array,
      default: function () {
        return [
          { name: 'Active', val: 'locked' },
          { name: 'Deleted', val: 'deleted' }
        ]
      }
    },
    showAuthor: {
      type: Boolean,
      default: false
    },
    minFields: {
      type: Boolean,
      default: false
    },
    listType: {
      type: String,
      required: true
    }
  },

  components: {
    TabulatorTable,
    SelectField,
    WaitingSpinner
  },

  methods: {
    tableClick(obj) {
      // listen to table column clicks and trigger actions
      let id = ((obj || {}).cell || {}).id || 0

      const rowObj = this.materialLists.find(itm => {
        return itm.id == id
      })

      this.$emit('openMaterialGroupList', { id, rowObj })
    },

    getLists() {
      const params = {
        action: 'get_materials_groups',
        conditionList: [
          {
            column: 'type',
            value: this.listType
          }
        ]
      }

      if (this.rfpStatus) {
        params.conditionList.push({
          column: 'status',
          value: this.rfpStatus
        })
      }

      if (this.author) {
        params.conditionList.push({
          column: 'added_by',
          value: this.author
        })
      }

      this.$bus.$emit('setWaiting', { name: 'getLists', message: 'Getting Material Lists' })

      appFuncs
        .shHttp(params)
        .then(res => {
          if (res && res.status === 'success') {
            let lists = res.data
            for (var i = 0; i < lists.length; i++) {
              lists[i].materials_added = lists[i].material_list && lists[i].material_list.length ? lists[i].material_list.length : 0
              lists[i].rfq_number = 'RFP' + lists[i].id
            }
            this.materialLists = lists
          } else {
            this.$snack.open(res.message || 'Problem getting material lists', 'error')
          }
        })
        .catch(res => {
          this.$snack.open(res.message || 'Problem getting material lists', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getLists')
        })
    }
  },

  mounted() {
    this.rfpStatus = this.statusOptions[0].val

    this.getLists()

    this.$bus.$emit('setWaiting', { name: 'getEmployees', message: 'Getting Employees' })
    this.$store
      .dispatch('getEmployees')
      .then(() => {
        if (!this.author) {
          var currentUser = this.$store.getters.userAuthedData.eid
          for (var i = this.employees.length - 1; i >= 0; i--) {
            if (this.employees[i]['Employee_Code'] == currentUser) {
              this.author = this.employees[i]['Employee_Code']
              break
            }
          }
        }
      })
      .finally(() => {
        this.$bus.$emit('stopWaiting', 'getEmployees')
      })

    this.$bus.$on(['materialListUpdated'], () => {
      this.getLists()
    })
  },

  watch: {
    quoteNumber() {
      this.getLists()
    },
    author() {
      this.getLists()
    },
    listType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getLists()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';
</style>
