
<div>
  <div class="row">
    <div class="col col-sm-12">
      <div :id="dzId" class="dropzone" style="margin-bottom: 5px" />
    </div>
  </div>
  <div class="row">
    <div class="col col-sm-12 po-file-container">
      <span v-for="(po, i) in docs" :key="i">
        <span class="has-link doc-item" @click="openUrlIf200(po.externalFileLocation)">{{ po.Image_Description }}</span>
      </span>
    </div>
    <div class="col col-sm-12">
      <button class="btn btn-sm" @click="uploadFile" v-html="'Upload'" :disabled="!jobId" style="margin: 5px 0" />
    </div>
  </div>
</div>
