
<div>
  <div class="tab-section border-bottom">
    <ul class="nav nav-tabs">
      <li role="presentation" :class="[{ active: tab == 'Overall' }]">
        <a href="#" @click="tab = 'Overall'"> Overall </a>
      </li>
      <li role="presentation" :class="[{ active: tab == 'Estimators' }]">
        <a href="#" @click="tab = 'Estimators'"> Estimators </a>
      </li>
      <li role="presentation" :class="[{ active: tab == 'Sales Tracking' }]">
        <a href="#" @click="tab = 'Sales Tracking'"> Sales Tracking </a>
      </li>
    </ul>

    <div :class="[{ Main: tab == 'Overall' }, 'tab-content']">
      <div class="clearfix">
        <div v-if="tab == 'Overall'">
          <quote-totals />
        </div>
      </div>
    </div>

    <div class="row" v-if="tab === 'Estimators' || tab === 'Sales Tracking'">
      <div class="col-sm-12 col-md-6 col-lg-3 Xpull-right" style="margin-left: 5px">
        <label>Fiscal Year</label>
        <select-field :options="yearOptions" v-model="fiscal_year" :option-display-keys="['name']" :option-val="'val'" @select-item="val => changeFiscalYear(val)" />
        <br />
      </div>
    </div>

    <div :class="[{ Main: tab == 'Estimators' }, 'tab-content']">
      <div class="clearfix">
        <div v-if="tab == 'Estimators'">
          <div class="sh-widget card wide-medium">
            <div class="content">
              <div class="top-section">
                <div class="">
                  <h4>Quotes in Progress</h4>
                </div>
              </div>
              <div class="table-container show-scrollbar">
                <table>
                  <tr>
                    <th><label>Lead Estimator</label></th>
                    <th><label>Qty</label></th>
                    <th><label>Value</label></th>
                  </tr>
                  <tr v-for="qr in qip">
                    <td>{{ qr.Employee_Name }}</td>
                    <td>{{ qr.quotes_progress }}</td>
                    <td>{{ qr.quotes_progress_val }}</td>
                  </tr>
                  <tr>
                    <td><strong>Totals:</strong></td>
                    <td>
                      <strong>{{ quoteTotals.total_in_progress }}</strong>
                    </td>
                    <td>
                      <strong>${{ quoteProgressTotal }}</strong>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="sh-widget card wide-medium">
            <div class="content">
              <div class="top-section">
                <div class="">
                  <h4>Quotes Sent and Pending</h4>
                </div>
              </div>
              <div class="table-container show-scrollbar">
                <table>
                  <tr>
                    <th><label>Lead Estimator</label></th>
                    <th><label>Qty</label></th>
                    <th><label>Value</label></th>
                  </tr>
                  <tr v-for="qs in qsent">
                    <td>{{ qs.Employee_Name }}</td>
                    <td>{{ qs.quotes_sent_pending }}</td>
                    <td>{{ qs.quotes_sent_pending_val }}</td>
                  </tr>
                  <tr>
                    <td><strong>Totals:</strong></td>
                    <td>
                      <strong>{{ quoteTotals.total_sent_pending }}</strong>
                    </td>
                    <td>
                      <strong>${{ quotePendingTotal }}</strong>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div v-if="tab == 'Sales Tracking'">
          <div class="sh-widget card wide-medium">
            <div class="content">
              <div class="top-section">
                <div class="">
                  <h4>Quotes in Progress</h4>
                </div>
              </div>
              <div class="table-container show-scrollbar">
                <table>
                  <tr>
                    <th><label>Person</label></th>
                    <th><label>Qty</label></th>
                    <th><label>Value</label></th>
                  </tr>
                  <tr v-for="qr in qsProg">
                    <td>{{ qr.Employee_Name }}</td>
                    <td>{{ qr.quotes_progress }}</td>
                    <td>{{ qr.quotes_progress_val_dol }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="sh-widget card wide-medium">
            <div class="content">
              <div class="top-section">
                <div class="">
                  <h4>Quotes Sent and Pending</h4>
                </div>
              </div>
              <div class="table-container show-scrollbar">
                <table>
                  <tr>
                    <th><label>Person</label></th>
                    <th><label>Qty</label></th>
                    <th><label>Value</label></th>
                  </tr>
                  <tr v-for="qr in qsPending">
                    <td>{{ qr.Employee_Name }}</td>
                    <td>{{ qr.quotes_sent_pending }}</td>
                    <td>{{ qr.quotes_sent_pending_val_dol }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="sh-widget card wide-medium">
            <div class="content">
              <div class="top-section">
                <div class="">
                  <h4>Quotes Won</h4>
                </div>
              </div>
              <div class="table-container show-scrollbar">
                <table>
                  <tr>
                    <th><label>Person</label></th>
                    <th><label>Quotes Won</label></th>
                    <th><label>Quotes Won (total value)</label></th>
                  </tr>
                  <tr v-for="qr in qsWon">
                    <td>{{ qr.Employee_Name }}</td>
                    <td>{{ qr.quotes_won }}</td>
                    <td>{{ qr.quotes_won_val_dol }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="sh-widget card wide-medium">
            <div class="content">
              <div class="top-section">
                <div class="">
                  <h4>Quotes Lost</h4>
                </div>
              </div>
              <div class="table-container show-scrollbar">
                <table>
                  <tr>
                    <th><label>Person</label></th>
                    <th><label>Quotes Lost</label></th>
                    <th><label>Quotes Lost (total value)</label></th>
                  </tr>
                  <tr v-for="qr in qsLost">
                    <td>{{ qr.Employee_Name }}</td>
                    <td>{{ qr.quotes_lost }}</td>
                    <td>{{ qr.quotes_lost_val_dol }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner />
</div>
