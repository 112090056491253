<template>
  <div>
    <div>
      <tabulator-table
        ref="categoryTable"
        :table-data="filteredCategories"
        :table-columns="categoryColumns"
        @colClick="categoryClick"
        table-fit="fitColumns"
        :table-condensed="false"
        empty-text="(none)"
      />
      <div v-if="editCategories && isMaterialsAdmin" class="category-add-container">
        <div class="form-group">
          <input type="text" class="form-control" v-model="newCategoryName" @keyup.enter="upsertCategory()" placeholder="Add Category" />
          <span class="is-link" @click="upsertCategory()">
            <i class="fa fa-plus" />
          </span>
        </div>
      </div>
    </div>
    <waiting-spinner :wait="wait" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import MaterialsCategoryCategoryEdit from 'components/Dashboard/Materials/MaterialsCategoryCategoryEdit'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'MaterialsCategoryTable',
  data() {
    return {
      wait: { message: '' },
      newCategoryName: '',
      categoryCurrent: 0
    }
  },
  computed: {
    ...mapGetters(['materialCategories', 'userAuthedData']),

    categoryColumns() {
      let cols = [
        {
          field: 'id',
          visible: false
        },
        {
          field: 'prevCategory',
          titleFormatter: val => {
            return '<i class="ti ti-angle-left" />'
          },
          width: 25,
          minWidth: 20,
          headerSort: false,
          formatter: val => {
            return ''
          },
          headerClick: () => {
            this.prevCategory()
          },
          cssClass: 'prev-link'
        },
        {
          title: this.label,
          field: 'name',
          sorter: 'string',
          tooltip: true
        }
      ]

      if (this.editCategories && this.isMaterialsAdmin) {
        cols.push({
          title: '',
          field: 'edit',
          formatter: val => {
            return '<i class="ti ti-pencil" />'
          },
          width: 30,
          headerSort: false
        })
      }
      return cols
    },

    filteredCategories() {
      let data = JSON.parse(JSON.stringify(this.materialCategories))
      data = data.filter(itm => {
        if (itm.item_type !== this.itemType) {
          return false
        }
        if (this.categoryCurrent) {
          return itm.parent == this.categoryCurrent
        } else {
          return !itm.parent
        }
      })
      return data
    },

    category() {
      if (this.categoryCurrent) {
        let match = this.materialCategories.find(itm => {
          return itm.id == this.categoryCurrent
        })
        if (match) {
          return match
        }
      }
      return {}
    },

    categoryName() {
      return this.category && this.category.name ? this.category.name : ''
    }
  },

  props: {
    label: {
      type: String,
      default: 'Category'
    },

    initCategoryCurrent: {
      type: [Number, String],
      default: 0
    },

    editCategories: {
      type: Boolean,
      default: false
    },

    itemType: {
      type: String,
      default: 'm'
    }
  },

  components: {
    TabulatorTable,
    WaitingSpinner
  },

  methods: {
    categoryClick(obj) {
      let id = ((obj || {}).cell || {}).id || 0
      let field = ((obj || {}).cell || {}).field || 0
      if (field === 'name') {
        this.selectCategory(id)
        return
      }
      if (field === 'edit') {
        this.editCategory(id)
      }
    },

    selectCategory(id) {
      // prevent stalls in some cases
      if (this.categoryCurrent == id) {
        id = 0
      }

      this.categoryCurrent = id
      this.$emit('selectCategory', id)

      // if (id === 0) {
      //   this.$refs.categoryTable.table.updateColumnDefinition("prevCategory", { visible: false });
      // } else {
      //   this.$refs.categoryTable.table.updateColumnDefinition("prevCategory", { visible: true });
      // }
    },

    prevCategory() {
      this.selectCategory(this.category.parent || 0)
    },

    upsertCategory(id) {
      if (!id && !this.newCategoryName) {
        this.$snack.open('Category name required.', 'warning')
        return
      }
      const params = {
        action: 'upsert_material_category',
        data: {
          id: id ? id : 0,
          parent: this.categoryCurrent || null,
          name: this.newCategoryName,
          item_type: this.itemType
        }
      }
      this.wait.message = 'Updating Material Categories'
      appFuncs
        .shHttp(params)
        .then(res => {
          this.$snack.open('Categories Updated')
          this.newCategoryName = ''
          this.getCategories('refresh').then(() => {
            if (res.id) {
              // this.selectCategory(res.id);
            }
          })
        })
        .catch(res => {
          console.log(res)
          this.$snack.open(res.message || 'Problem updating categories', 'error')
        })
        .finally(() => {
          this.wait.message = ''
        })
    },

    editCategory(categoryId) {
      this.$Modal({
        parent: this,
        name: 'MaterialsCategoryCategoryEdit', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        component: MaterialsCategoryCategoryEdit,
        props: {
          categoryId,
          objectType: 'Materials'
        }
      })
    },

    getCategories(refresh) {
      return new Promise((resolve, reject) => {
        this.$bus.$emit('setWaiting', { name: 'getMaterialCategories', message: 'Getting Material Categories' })
        this.$store
          .dispatch('getMaterialCategories', refresh)
          .then(() => {
            this.$bus.$emit('stopWaiting', 'getMaterialCategories')
            resolve()
          })
          .finally(() => {
            reject()
          })
      })
    },

    listenCategoryChange(catId) {
      this.categoryCurrent = catId
    }
  },

  mounted() {
    this.getCategories()
    this.$bus.$on('edit-category-item', obj => {
      this.editCategory(obj)
    })

    this.$bus.$on('materialCategoryListCategoryChange', this.listenCategoryChange)
  },

  watch: {
    categoryCurrent: {
      handler() {
        this.categoryCurrent = this.categoryCurrent || this.initCategoryCurrent
      },
      immediate: true
    }
  },

  beforeDestroy() {
    this.$bus.$off('edit-category-item')
    this.$bus.$off('materialCategoryListCategoryChange')
  }
}
</script>

<style lang="scss">
@import 'src/assets/sass/paper/_variables.scss';

.category-add-container {
  margin-top: 2px;

  .form-group {
    margin: 0 !important;
  }

  input {
    display: inline-block;
    background: $white-color;
    height: 36px;
    font-size: 14px;
    width: calc(100% - 35px);
    border: 1px solid $medium-gray;
  }

  a {
    display: inline-block;
    width: 30px;
    text-align: center;
    color: $text-color;
    padding: 3px;

    &:hover {
      color: $text-color;
    }
  }
}

[tabulator-field='name'],
[tabulator-field='edit'] {
  cursor: pointer;
}

.prev-link {
  cursor: pointer;
}
</style>
