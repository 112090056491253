
<div>
  <div class="row">
    <div class="col-sm-12">
      <h4>Edit Material Category</h4>

      <div class="form-group">
        <label>Category Name</label>
        <input type="text" class="form-control" v-model="category.name"/>
      </div>

      <div class="form-group">
        <label>Category Parent</label>
        <input type="text" class="form-control fake-disabled" v-model="currentParentName" readonly @click="editParentCategory"/>
      </div>

      <div v-if="objectType !== 'material'" class="form-group">
        <label>Cost Type</label>
        <input type="text" class="form-control" v-model="category.cost_type"/>
      </div>

      <button class="btn" @click="update" v-html="'Update'"/>

      <button class="btn" @click="deleteCategory" v-html="'Delete'"/>
    </div>
  </div>
  <waiting-spinner :wait="wait"/>
</div>
