
<div class="max-width-large">
  <p class="text-danger">
    Using the same passwords across different websites makes your accounts vulnerable to security breaches in the likely event that one or more websites you use gets hacked.
    <strong>Please ensure that the password you use here is unique.</strong> Consider using a
    <a class="text-danger underlined" target="_blank" href="https://www.google.com/search?q=best+password+manager">password manager</a> to help simplify this best practise.
  </p>
  <br/>
  <form class="form-light">
    <div v-if="promptExistingPassword" class="form-group">
      <label for="password">Existing Password</label>
      <input type="password" class="form-control" name="existing_password" v-model="existing_password"/>
    </div>
    <div class="form-group">
      <label for="password">New Password</label>
      <input type="password" class="form-control" name="password" v-model="password"/>
    </div>
    <div class="form-group">
      <label for="password">Confirm New Password</label>
      <input type="password" class="form-control" name="password_confirm" v-model="password_confirm"/>
    </div>
    <input type="submit" class="btn" @click="resetPassword($event)" value="Submit"/>
  </form>
  <waiting-spinner/>
</div>
