<template>
  <div>
    <div class="row">
      <div class="col col-sm-12">
        <div class="tab-section white">
          <ul class="nav nav-tabs">
            <li role="presentation" :class="[{ active: tab == 'Terms' }]">
              <a href="#" @click="tab = 'Terms'"> Terms and Payment </a>
            </li>
            <li role="presentation" :class="[{ active: tab == 'Costing' }]">
              <a href="#" @click="tab = 'Costing'"> Costing </a>
            </li>
            <li role="presentation" :class="[{ active: tab == 'Labor' }]">
              <a href="#" @click="tab = 'Labor'"> Labor Rates </a>
            </li>
            <li role="presentation" :class="[{ active: tab == 'Wage Codes' }]">
              <a href="#" @click="tab = 'Wage Codes'"> Wage Codes </a>
            </li>
            <li role="presentation" :class="[{ active: tab == 'Expenses' }]">
              <a href="#" @click="tab = 'Expenses'"> Expenses </a>
            </li>
            <li role="presentation" :class="[{ active: tab == 'Cost Centers' }]">
              <a href="#" @click="tab = 'Cost Centers'"> Cost Centers </a>
            </li>
            <li role="presentation" :class="[{ active: tab == 'Billing System' }]">
              <a href="#" @click=";(tab = 'Billing System'), getRoutingCodes()"> Billing System </a>
            </li>
            <li role="presentation" :class="[{ active: tab == 'Job Setup' }]">
              <a href="#" @click=";(tab = 'Job Setup'), getJobSetupData()"> Job Setup </a>
            </li>
          </ul>
          <div :class="['tab-content', { 'first-active': tab === 'Terms' }]">
            <div class="tab-content-inner">
              <div class="card">
                <div class="content">
                  <div v-show="tab == 'Terms'">
                    <div class="row">
                      <div class="columns col-sm-12 col-lg-6">
                        <admin-option-field :field="'terms'" :label="'Terms'" />
                        <admin-option-field :field="'deposit_amount'" :label="'Deposit Amount'" />
                      </div>
                      <div class="columns col-sm-12 col-lg-6">
                        <admin-option-field :field="'progress_payment_clause'" :label="'Progress Payment Clause'" :detail-value="true" />
                        <admin-option-field :field="'enhanced_disclaimer_text'" :label="'Enahanced Disclaimer'" :detail-value="true" />
                        <admin-option-field :field="'payment_price_type'" :label="'Price Type'" :detail-value="true" />
                      </div>
                      <div class="columns col-sm-12 col-lg-6">
                        <admin-option-field :field="'quote_conditions'" :label="'Conditions'" :single-field="true" :detail-value="true" />
                      </div>
                    </div>
                  </div>
                  <div v-show="tab === 'Costing'">
                    <div class="row">
                      <div class="columns col-sm-12">
                        <div class="group-container">
                          <h5>Overhead Amounts</h5>
                          <div class="row">
                            <!-- <div class="form-group">
                              <label>Job Sell Pricing Formula</label>
                              <input type="text" :class="['form-control', 'fake-disabled', 'select-field', 'custom-select']" v-model="sellingPriceOptionText" readonly @click="selectSellingPriceOption">
                            </div> -->

                            <div v-for="(dept, index) in quoteDepartments" :key="index">
                              <admin-option-field
                                class="columns col-sm-4"
                                :field="`quote_overhead_amount_${dept.name}`"
                                :label="`${dept.name}`"
                                :single-field="true"
                                :value-type="'dollar'"
                                :separate-section="false"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="tab === 'Labor'">
                    <admin-grouped-fields
                      :field="'labor_rates'"
                      :label="'Labor Rates'"
                      :fields="[
                        {
                          label: 'Name',
                          name: 'name',
                          type: 'text',
                          required: true
                        },
                        {
                          label: 'Cost',
                          name: 'cost',
                          type: 'money',
                          required: true
                        },
                        {
                          label: 'Rate',
                          name: 'rate',
                          type: 'money',
                          required: true
                        },
                        {
                          label: 'Phase',
                          name: 'phase',
                          type: 'text',
                          required: true
                        }
                      ]"
                    />
                  </div>
                  <div v-show="tab === 'Wage Codes'">
                    <admin-grouped-fields
                      :field="'wage_codes'"
                      :label="'Wage Codes'"
                      :fields="[
                        {
                          label: 'Name',
                          name: 'name',
                          type: 'text',
                          required: true
                        },
                        {
                          label: 'Job Wage Code',
                          name: 'job_wage_code',
                          type: 'text',
                          required: false
                        },
                        {
                          label: 'WO Wage Code',
                          name: 'wo_wage_code',
                          type: 'text',
                          required: false
                        },
                        {
                          label: 'Job Union Code',
                          name: 'job_union_code',
                          type: 'text',
                          required: false
                        },
                        {
                          label: 'WO Union Code',
                          name: 'wo_union_code',
                          type: 'text',
                          required: false
                        }
                      ]"
                    />
                  </div>
                  <div v-show="tab === 'Expenses'">
                    <div class="row">
                      <admin-option-field class="columns col-sm-4" :field="`per_diem_rate`" :label="`Per Diem Rate`" :single-field="true" :value-type="'dollar'" :separate-section="false" />
                    </div>
                  </div>
                  <div v-show="tab === 'Cost Centers'">
                    <div class="row" style="padding-top: 10px">
                      <div class="columns col-sm-12">
                        <div class="tab-section outline" style="padding: 0">
                          <ul class="nav nav-tabs">
                            <li role="presentation" :class="[{ active: costCenterTab == 'Department' }]">
                              <a href="#" @click="costCenterTab = 'Department'"> Job Department </a>
                            </li>
                            <li role="presentation" :class="[{ active: costCenterTab == 'Region' }]">
                              <a href="#" @click="costCenterTab = 'Region'"> Region </a>
                            </li>
                            <li role="presentation" :class="[{ active: costCenterTab == 'Costing' }]">
                              <a href="#" @click=";(costCenterTab = 'Costing'), getCostCenterOptions()"> Costing </a>
                            </li>
                          </ul>
                          <div :class="['tab-content', { 'first-active': costCenterTab === 'Department' }]">
                            <div class="tab-content-inner">
                              <div v-show="costCenterTab == 'Department'">
                                <p>Add ONE cost center for each region that the cost center belongs to. In the Region tab, each region will list cost centers which belong to that region.</p>
                                <admin-option-field
                                  :field="'cost_centers'"
                                  :label="'Cost Centers'"
                                  :detail-value="true"
                                  :show-value="true"
                                  :edit-component="AdminOptionFieldCostCenterEditor"
                                  :show-default="false"
                                  :edit-component-name="'AdminOptionFieldCostCenterEditor'"
                                />
                              </div>
                              <div v-show="costCenterTab == 'Region'">
                                <p>List cost centers that belong to each region.</p>
                                <admin-option-field
                                  :field="'cost_center_regions'"
                                  :label="'Region'"
                                  :detail-value="true"
                                  :show-value="true"
                                  :edit-component="RegionCostCenterEditComponent"
                                  :show-default="false"
                                  :allow-delete="false"
                                  :edit-component-name="'RegionCostCenterEditComponent'"
                                />
                              </div>
                              <div v-show="costCenterTab == 'Costing'">
                                <div class="row">
                                  <div v-for="(cc, index) in jobCostCenters" :key="index">
                                    <admin-option-field
                                      class="columns col-sm-6"
                                      :field="`quote_overhead_amount_${cc.Code}`"
                                      :label="`${cc.Name} ${cc.Code}`"
                                      :single-field="true"
                                      :value-type="'dollar'"
                                      :separate-section="false"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="tab === 'Billing System'">
                    <div class="row" style="padding-top: 10px">
                      <div class="columns col-sm-12">
                        <div class="tab-section outline" style="padding: 0">
                          <ul class="nav nav-tabs">
                            <li role="presentation" :class="[{ active: billingTab == 'Routing Codes' }]">
                              <a href="#" @click=";(billingTab = 'Routing Codes'), getRoutingCodes()"> Routing Codes </a>
                            </li>
                            <li role="presentation" :class="[{ active: billingTab == 'Maintenance' }]">
                              <a href="#" @click="billingTab = 'Maintenance'; getBillingTaskSettings()"> Maintenance </a>
                            </li>
                          </ul>
                          <div :class="['tab-content', { 'first-active': billingTab === 'Routing Codes' }]">
                            <div class="tab-content-inner">
                              <div v-show="billingTab == 'Routing Codes'">
                                <div class="row">
                                  <div class="col col-sm-12">
                                    <div class="routing-code-search">
                                      <div class="form-group">
                                        <label>Search</label>
                                        <input type="text" class="form-control" v-model="routingCodeSearch" placeholder="Search" />
                                        <button @click="searchRoutingCode" class="btn">Search</button>
                                        <button v-if="routingCodeSearch" @click="getRoutingCodes()" class="btn">Clear</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col col-sm-12 col-md-6">
                                    <tabulator-table
                                      style="margin-bottom: 40px"
                                      :table-data="tableDataRoutingCodes"
                                      :table-columns="tableColumnsRoutingCodes"
                                      table-fit="fitColumns"
                                      :table-condensed="false"
                                      empty-text="(none)"
                                      @colClick="routingCodesColClick"
                                      :selectable="false"
                                      ref="routingCodesTable"
                                      :responsive-layout="false"
                                      :movable-rows="true"
                                    />
                                    <button @click="addRoutingCode" class="btn">Add Routing Code</button>
                                  </div>
                                  <div class="col col-sm-12"></div>
                                </div>
                              </div>
                              <div v-show="billingTab == 'Maintenance'">
                                <div class="row">
                                  <div class="col col-sm-12 col-md-6">
                                    <tabulator-table
                                      ref="billingTasksTable"
                                      :table-data="tableDataBillingTasks"
                                      :table-columns="tableColumnsBillingTasks"
                                      table-fit="fitColumns"
                                      :table-condensed="false"
                                      empty-text="(none)"
                                      :selectable="false"
                                      :responsive-layout="false"
                                      style="margin-bottom: 40px"
                                    />
                                  </div>
                                  <div class="col col-sm-12">
                                    <button @click="saveBillingTaskSettings" class="btn">Save</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="tab === 'Job Setup'">
                    <div class="row" style="padding-top: 10px">
                      <div class="columns col-sm-12">
                        <div class="tab-section outline" style="padding: 0">
                          <ul class="nav nav-tabs">
                            <li role="presentation" :class="[{ active: jobSetupTab == 'Jobs' }]">
                              <a href="#" @click=";(jobSetupTab = 'Jobs'), getJobSetupData()"> Jobs </a>
                            </li>
                            <li role="presentation" :class="[{ active: jobSetupTab == 'Departments' }]">
                              <a href="#" @click="jobSetupTab = 'Departments'; getDepartmentsData()"> Departments </a>
                            </li>
                          </ul>
                          <div :class="['tab-content', { 'first-active': jobSetupTab === 'Jobs' }]">
                            <div class="tab-content-inner">
                              <div v-show="jobSetupTab == 'Jobs'">
                                <select-field
                                  :options="Array.from({ length: new Date().getFullYear() - 2020 + 1 }, (_, i) => ({ value: (new Date().getFullYear() - i).toString() }))"
                                  :option-display-keys="['value']"
                                  :option-val="'value'"
                                  v-model="viewFiscalYear"
                                  @selectItem="val => this.getJobSetupData()"
                                  style="display: inline-block; margin: 0 0 0 10px"
                                />
                                <div class="row">
                                  <div class="col col-sm-12 col-md-6">
                                    <tabulator-table
                                      style="margin-bottom: 40px"
                                      :table-data="tableDataJobSetup"
                                      :table-columns="tableColumnsJobSetup"
                                      table-fit="fitColumns"
                                      :table-condensed="false"
                                      empty-text="(none)"
                                      @colClick="jobSetupColClick"
                                      :selectable="false"
                                      ref="jobSetupTable"
                                      :responsive-layout="false"
                                      :movable-rows="true"
                                    />
                                    <button @click="addJobSetup" class="btn">Click to Add</button>
                                  </div>
                                  <div class="col col-sm-12"></div>
                                </div>
                              </div>
                              <div v-show="jobSetupTab == 'Departments'">
                                <select-field
                                  :options="Array.from({ length: new Date().getFullYear() - 2020 + 1 }, (_, i) => ({ value: (new Date().getFullYear() - i).toString() }))"
                                  :option-display-keys="['value']"
                                  :option-val="'value'"
                                  v-model="viewFiscalYear"
                                  @selectItem="val => this.getDepartmentsData()"
                                  style="display: inline-block; margin: 0 0 0 10px"
                                />
                                <div class="row">
                                  <div class="col col-sm-12">
                                    <tabulator-table
                                      ref="bdepartmentsTable"
                                      :table-data="tableDataDepartments"
                                      :table-columns="tableColumnsDepartments"
                                      table-fit="fitColumns"
                                      :table-condensed="false"
                                      empty-text="(none)"
                                      @colClick="departmentColClick"
                                      :selectable="false"
                                      :responsive-layout="false"
                                      style="margin-bottom: 40px"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import SelectField from 'components/UIComponents/SelectField'
import Checkbox2 from 'components/UIComponents/Checkbox'
import VueNumeric from 'vue-numeric'
import AdminOptionField from 'components/UIComponents/AdminOptionField'
import AdminGroupedFields from 'components/UIComponents/AdminGroupedFields'
import quoteFuncs from 'mixins/quoteFuncs.vue'
import NestedForm from 'components/UIComponents/NestedForm.vue'
import AdminOptionFieldCostCenterEditor from 'components/UIComponents/AdminOptionFieldCostCenterEditor.vue'
import RegionCostCenterEditComponent from 'components/UIComponents/RegionCostCenterEditComponent.vue'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import snack from '../../UIComponents/Snackbar'
import TabulatorTable from 'components/UIComponents/TabulatorTable'

export default {
  name: 'QuoteSetup',
  data() {
    return {
      AdminOptionFieldCostCenterEditor,
      RegionCostCenterEditComponent,
      tab: 'Terms',
      termOptions: [{ name: 'Net 30' }, { name: 'Net 90' }, { name: 'Due Upon Receipt' }, { name: 'Other TBD' }],
      depositAmount: [{ name: '20%' }, { name: '50%' }, { name: '50% Upon PO' }, { name: '50% Prior to Delivery' }, { name: 'COD' }],
      fields: {
        terms_option: null
      },
      overHeadAmount: null,
      sellingPriceOption: null,
      data: [],
      quoteRateTypes: [{ type: 'labor' }, { type: 'material' }, { type: 'equipment' }, { type: 'subcontractor' }, { type: 'other' }],
      costCenterTab: 'Department',
      billingTab: 'Routing Codes',
      jobSetupTab: 'Jobs',
      costCenterOptions: [],
      subCostCenterOptions: [],
      tableDataRoutingCodes: [],
      tableDataJobSetup: [],
      tableDataDepartments: [],
      routingCodeSearch: '',
      tableDataBillingTasks: [
        {
          id: 'billing-task-DI',
          billingTask: 'Deposit Invoice',
          approvalRequired: ''
        },
        {
          id: 'billing-task-PI',
          billingTask: 'Progress Invoice',
          approvalRequired: ''
        },
        {
          id: 'billing-task-BC',
          billingTask: 'Bill Complete',
          approvalRequired: ''
        },
        {
          id: 'billing-task-BE',
          billingTask: 'Bill Extras',
          approvalRequired: ''
        },
        {
          id: 'billing-task-BR',
          billingTask: 'Bill Retention',
          approvalRequired: ''
        }
      ],
      tableColumnsBillingTasks: [
        {
          title: 'Billing Task',
          field: 'billingTask'
        },
        {
          title: 'Approval Required',
          field: 'approvalRequired',
          formatter: cell => {
            let value = cell.getValue()
            value === 'yes' ? (value = 'Yes') : (value = 'No')
            return value
          },
          editor: 'select',
          editorParams: {
            values: { yes: 'Yes', no: 'No' },
            placeholderEmpty: 'Select an Option'
          }
        }
      ],
      jobUseTypes: [ 'Quoting', 'Safety', 'Unbillable'],
      viewFiscalYear: this.fiscalYear
    }
  },

  components: {
    SelectField,
    Checkbox2,
    VueNumeric,
    AdminOptionField,
    AdminGroupedFields,
    NestedForm,
    WaitingSpinner,
    TabulatorTable
  },

  props: {},

  mixins: [quoteFuncs],

  computed: {
    ...mapGetters(['userAuthedData', 'appOptions', 'configuredCostCenters', 'jobCostCenters', 'fiscalYear', 'emCostGroups']),

    costCenterIds() {
      return this.configuredCostCenters.map(costCenter => costCenter.id)
    },

    tableColumnsRoutingCodes() {
      return [
        { title: 'ID', field: 'id', visible: false },
        { title: 'Routing Code', field: 'routing_code', sorter: 'string', cssClass: 'is-link' },
        { title: 'Description', field: 'routing_description', sorter: 'string', cssClass: 'is-link' }
      ]
    },

    tableColumnsJobSetup() {
      return [
        { title: 'Job #', field: 'Job_Number', sorter: 'number', cssClass: 'is-link' },
        { title: 'Job Description', field: 'Job_Description', sorter: 'string', cssClass: 'is-link' },
        { title: 'Type', field: 'job_use_type', sorter: 'string', cssClass: 'is-link' }
      ]
    },

    tableColumnsDepartments() {
      const _this = this
      return [
        { title: 'Cost Group', field: 'Cost_Group_Code', sorter: 'number', cssClass: 'is-link' },
        { title: 'Description', field: 'Cost_Group_Description', sorter: 'string', cssClass: 'is-link' },
        { title: 'Unbillable Jobs', field: 'Unbillable_Jobs', sorter: 'string', cssClass: 'is-link',
          formatter: function (cell) {
            return _this.convertToJobLink(cell.getValue())
          }
        },
        { title: 'Quoting Jobs', field: 'Quoting_Jobs', sorter: 'string', cssClass: 'is-link',
          formatter: function (cell) {
            return _this.convertToJobLink(cell.getValue())
          }
        },
        { title: 'Safety Jobs', field: 'Safety_Jobs', sorter: 'string', cssClass: 'is-link',
          formatter: function (cell) {
            return _this.convertToJobLink(cell.getValue())
          }
        }
      ]
    }
  },

  methods: {
    convertToJobLink(jobNumber) {
      return jobNumber ? `<a class="underlined" style="color: #000;" href="#/app/job-details/${jobNumber}">${jobNumber}</a>` : ''
    },

    selectSellingPriceOption() {
      this.$Modal({
        parent: this,
        name: 'SelectSellingPriceOption', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Quotes/SelectSellingPriceOption'),
        contentClasses: 'smoke-bg'
      })
    },

    getCostCenterOptions() {
      var params = {
        action: 'get_global_options',
        group: 'cost_centers'
      }

      this.$bus.$emit('setWaiting', { name: 'get_global_options', message: 'Getting Cost Centers' })
      appFuncs
        .shHttp(params)
        .then(res => {
          let cco = res.data

          this.costCenterOptions = cco.sort((a, b) => {
            return a.sort - b.sort
          })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'get_global_options')
        })
    },

    getSubCostCenterOptions() {
      var params = {
        action: 'get_global_options',
        group_contains: 'sub_cost_centers'
      }

      this.$bus.$emit('setWaiting', { name: 'sub_cost_centers', message: 'Getting Sub Cost Centers' })
      appFuncs
        .shHttp(params)
        .then(res => {
          let cco = res.data

          this.subCostCenterOptions = cco.sort((a, b) => {
            return a.sort - b.sort
          })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'sub_cost_centers')
        })
    },

    addRoutingCode() {
      this.$Modal({
        parent: this,
        name: 'EditRoutingCodes', // used for closing specific modal programmatically
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Billing/EditRoutingCodes')
      })
    },

    addJobSetup() {
      this.$Modal({
        parent: this,
        name: 'EditJobSetup', // used for closing specific modal programmatically
        size: 'sm', // sm, md, lg, xl
        title: 'Add Job Setup',
        component: () => import('components/Dashboard/Jobs/EditJobSetup'),
        props: {
          viewFiscalYear: +this.viewFiscalYear,
        }
      })
    },

    getRoutingCodes() {
      var params = {
        action: 'get_routing_codes'
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Getting Routing Codes' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.tableDataRoutingCodes = data
          this.routingCodeSearch = ''
        })
        .catch(err => {
          console.log(err)
          snack.open(err.message || 'Problem fetching routing codes', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    routingCodesColClick(obj) {
      const id = (obj.row || {}).id
      this.$Modal({
        parent: this,
        name: 'EditRoutingCodes', // used for closing specific modal programmatically
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Billing/EditRoutingCodes'),
        props: {
          id
        }
      })
    },

    jobSetupColClick(obj) {
      this.$Modal({
        parent: this,
        name: 'EditJobSetup', // used for closing specific modal programmatically
        size: 'sm', // sm, md, lg, xl
        title: 'Edit Job Setup',
        component: () => import('components/Dashboard/Jobs/EditJobSetup'),
        props: {
          viewFiscalYear: obj.row?.job_fiscal_year,
          jobUseType: obj.row?.job_use_type,
          jobNumber: obj.row?.Job_Number
        }
      })
    },

    departmentColClick(obj) {
      if (obj.cell.field && ['Quoting_Jobs', 'Unbillable_Jobs', 'Safety_Jobs'].includes(obj.cell.field)) {
        this.$Modal({
          parent: this,
          name: 'EditDepartment', // used for closing specific modal programmatically
          size: 'sm', // sm, md, lg, xl
          title: 'Edit Department',
          component: () => import('components/Dashboard/Jobs/EditDepartment'),
          props: {
            fiscalYear: +this.viewFiscalYear,
            costGroupCode: obj.row?.Cost_Group_Code,
            jobUseType: obj.cell.field.split('_Jobs')[0],
          }
        })
      }
    },

    listenUpdateRoutingCodes() {
      this.getRoutingCodes()
    },

    searchRoutingCode() {
      var params = {
        action: 'search_routing_codes',
        search: this.routingCodeSearch
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Searching Routing Codes' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.tableDataRoutingCodes = data
        })
        .catch(err => {
          console.log(err)
          snack.open(err.message || 'Problem searching routing codes', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    async saveBillingTaskSettings() {
      let data = (this.$refs.billingTasksTable || {}).table.getData() || []
      data = Array.isArray(data) ? data : []

      this.$bus.$emit('setWaiting', { name: 'saveBillingTaskSettings', message: 'Saving Settings' })

      const params = {
        action: 'save_billing_task_approval_required_settings',
        data
      }

      appFuncs
        .shRequest(params)
        .catch(err => {
          console.log(err)
          this.$snack.open(err.message || 'Problem saving settings', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'saveBillingTaskSettings')
        })
    },

    getBillingTaskSettings() {
      var params = {
        action: 'get_billing_task_approval_required_settings'
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Getting Billing Task Settings' })
      appFuncs
        .shRequest(params)
        .then(data => {
          const approvalData = data;
          this.tableDataBillingTasks.forEach(task => {
            const found = approvalData.find(item => item.id === task.id);
            if (found) {
              task.approvalRequired = found.value;
            }
          });
        })
        .catch(err => {
          console.log(err)
          snack.open(err.message || 'Problem fetching billing task settings', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    getJobNumberByCostGroup(costGroupCode, jobUseType) {
      return this.tableDataJobSetup.find((e) => e.cost_group_codes?.includes(costGroupCode) && e.job_use_type === jobUseType)?.Job_Number
    },

    async getJobSetupData(fiscalYear = null) {
      if (fiscalYear) {
        this.viewFiscalYear = fiscalYear
      }
      this.$bus.$emit('setWaiting', { name: 'getJobsSetup', message: 'Getting Jobs Setup' })
      await appFuncs.shHttp({ action: 'get_job_setup_data', fiscalYear: this.viewFiscalYear, jobUseTypes: this.jobUseTypes })
        .then(res => {
          this.tableDataJobSetup = res['data']
          this.$bus.$emit('stopWaiting', 'getJobsSetup')
        })
        .catch(res => {
          snack.open('Problem getting job setup data', 'error')
        })
    },

    async getDepartmentsData() {
      await this.getJobSetupData()
      this.tableDataDepartments = this.emCostGroups?.map((e) => {
        return {
          Cost_Group_Code : e.Cost_Group_Code,
          Cost_Group_Description : e.Cost_Group_Description,
          Unbillable_Jobs : this.getJobNumberByCostGroup(e.Cost_Group_Code, 'Unbillable'),
          Quoting_Jobs : this.getJobNumberByCostGroup(e.Cost_Group_Code, 'Quoting'),
          Safety_Jobs : this.getJobNumberByCostGroup(e.Cost_Group_Code, 'Safety'),
        }
      })
    },
  },

  mounted() {
    this.$bus.$emit('setWaiting', { name: 'getJobCostCenters', message: 'Getting Job Cost Centers' })
    this.$store.dispatch('getEmCostGroups')
    this.$store
      .dispatch('getJobCostCenters')
      .finally(() => {
        this.$bus.$emit('stopWaiting', 'getJobCostCenters')
      })
      .catch(err => {
        console.log(err)
      })

    this.$bus.$on('updateRoutingCodes', this.listenUpdateRoutingCodes)
    this.$bus.$on('getJobSetupData', this.getJobSetupData)
    this.$bus.$on('getDepartmentsData', this.getDepartmentsData)
  },

  watch: {
    fiscalYear: {
      handler() {
        this.viewFiscalYear = this.fiscalYear
      },
      immediate: true
    }
  },

  beforeDestroy() {
    this.$bus.$off('updateRoutingCodes', this.listenUpdateRoutingCodes)
    this.$bus.$off('getJobSetupData', this.getJobSetupData)
    this.$bus.$off('getDepartmentsData', this.getDepartmentsData)
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.group-container {
  border: 1px solid $light-gray;
  padding: 10px;
}

.routing-code-search {
  border: 1px solid #ddd;
  padding: 20px 20px 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;

  label {
    display: block;
  }
  input {
    max-width: 500px;
    display: inline-block;
    margin-right: 10px;
  }
}
</style>
