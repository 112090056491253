<template>
  <div class="pdf-template" id="pdf-template">
    <div class="" v-if="data.length">
      <div class="header" id="pdfReady">
        <div class="row row-no-gutters" style="height: 107px">
          <div class="col-xs-4">
            <div>
              <img :src="`${urls.frontend}static/img/${logoFile}`"/>
            </div>
          </div>
          <div class="col-xs-4">
            <p v-html="regionAddress"/>
          </div>
        </div>
        <div class="header-bottom col-xs-12"/>
      </div>
      <section class="section main-section">
        <div class="section report-table">
          <div class="row header-row">
            <div class="col-sm-2">Job/WO Number / Description / PO / Status Note</div>
            <div class="col-sm-2">Job Status</div>
            <div class="col-sm-2">Customer / Location / Contact</div>
            <div class="col-sm-2">Division / Region / Project Lead / Captain</div>
            <div class="col-sm-2">Costing</div>
            <div class="col-sm-2">Dates</div>
          </div>
          <div v-for="(group, groupIndex) in groupedTableData" :key="groupIndex">
            <section class="section">
              <div v-for="item in group" :key="item.id" :class="['row', item.class]">
                <div class="col-sm-2" v-html="item.title"></div>
                <div class="col-sm-1" v-html="item.status"></div>
                <div class="col-sm-1" v-html="item.status2"></div>
                <div class="col-sm-2" v-html="item.customer"></div>
                <div class="col-sm-2" v-html="item.meta"></div>
                <div class="col-sm-1" v-html="item.costing"></div>
                <div class="col-sm-1" v-html="item.costing2"></div>
                <div class="col-sm-1" v-html="item.dates"></div>
                <div class="col-sm-1" v-html="item.dates2"></div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <div v-if="pdf" class="footer" style="margin-bottom: 1px">
        <div class="footer-inner">
          <div class="footer-left">
            {{ data.template_date }}
          </div>
          <div class="footer-center">{{ companyName }} Ltd.</div>
          <div class="footer-right">
            <div class="page-number">
              <p>Page 1 of 1</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner/>
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'
import commonFuncs from 'mixins/commonFuncs'
import {mapGetters} from 'vuex'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'JobWOReportTemplate',
  data() {
    return {
      data: [],
      employees: [],
      regions: [],
      costCenters: []
    }
  },

  components: {WaitingSpinner},

  props: {
    authCode: {
      type: String,
      required: true
    },
    pdf: {
      type: [String, Boolean],
      default: false
    }
  },

  mixins: [commonFuncs],

  computed: {
    ...mapGetters(['userAuthedData', 'sherAuth', 'urls']),

    authedUser() {
      return (this.userAuthedData || {}).eid || null
    },

    isAdmin() {
      let auth = this.$store.getters.userAuthedData.user_role
      return auth ? auth.indexOf('admin') !== -1 : false
    },

    companyName() {
      const vars = this.getRegionVars(this.data.region)
      return vars.companyName
    },

    logoFile() {
      const vars = this.getRegionVars(this.data.region)
      return vars.logoFile
    },

    regionAddress() {
      const vars = this.getRegionVars(this.data.region)
      return vars.address
    },

    groupedTableData() {
      const grouped = []
      for (let i = 0; i < this.tableData.length; i += 4) {
        grouped.push(this.tableData.slice(i, i + 4))
      }
      return grouped
    },

    tableData() {
      let data = this.data
      let myItems = []
      let filtered = []

      filtered = JSON.parse(JSON.stringify(data))

      filtered = filtered.flatMap(itm => {
        const currentDate = new Date()
        const lastStatusNoteDate = itm.last_status_note_date ? new Date(itm.last_status_note_date) : null
        const statusNotesDaysOld = lastStatusNoteDate ? Math.floor((currentDate - lastStatusNoteDate) / (1000 * 60 * 60 * 24)) : null
        let statusNotesClass = ''

        if (statusNotesDaysOld !== null) {
          if (statusNotesDaysOld <= 28) {
            statusNotesClass = 'text-success'
            // } else if (statusNotesDaysOld <= 28) {
            //   statusNotesClass = 'text-warning';
          } else {
            statusNotesClass = 'text-danger'
          }
        }

        let stageVal = itm.stage ? itm.stage : 'Add'
        if (stageVal === 'Report Sent' || stageVal === 'WO Sent') {
          stageVal = `Sent`
          if (itm.last_report_send_date) {
            stageVal = ` <span class="text-danger">Sent (${itm.last_report_send_date})</span>`
          }
        }

        return [
          {
            id: this.randomCharacterString(12),
            job_or_wo: itm.job_number ? 'Job' : 'WO',
            itemNumber: itm.job_number || itm.wo_number,
            title: itm.job_number ? `Job ${itm.job_number}` : `WO ${itm.wo_number}`,
            status: 'Stage:',
            status2: stageVal,
            customer: itm.customer_name,
            meta: itm.costCenterName,
            costing: 'Quoted Cost:',
            costing2: this.formatNumber(itm.quoted_cost, 1),
            dates: 'Date Ordered:',
            dates2: this.formatDate(itm.date_ordered),
            class: 'first-row'
          },
          {
            id: this.randomCharacterString(12),
            job_or_wo: itm.job_number ? 'Job' : 'WO',
            itemNumber: itm.job_number || itm.wo_number,
            title: itm.description,
            status: 'Sign Off Status:',
            status2: itm.sign_off === 'yes' ? 'Signed' : 'Not Signed',
            // customer: `<a class="no-print-url" target="_blank" href="https://maps.google.com?q=${encodeURIComponent(itm.address)}">${itm.address}</a>`,
            customer: itm.address,
            meta: itm.regionName,
            costing: 'Cost to Date:',
            costing2: this.formatNumber(itm.cost_to_date, 1),
            dates: 'Last Work Date:',
            dates2: this.formatDate(itm.last_cost_date),
            class: 'second-row'
          },
          {
            id: this.randomCharacterString(12),
            job_or_wo: itm.job_number ? 'Job' : 'WO',
            itemNumber: itm.job_number || itm.wo_number,
            title: itm.po_number ? `PO: ${itm.po_number}` : '<span class="text-danger underlined">PO: Add</span>',
            status: '',
            status2: '',
            customer: itm.contact_name,
            meta: itm.pm_name,
            costing: 'Billed to Date:',
            costing2: this.formatNumber(itm.billed_to_date, 1),
            dates: 'Last Billed:',
            dates2: itm.last_billed_date ? this.formatDate(itm.last_billed_date) : 'Not Billed',
            class: 'third-row'
          },
          {
            id: this.randomCharacterString(12),
            job_or_wo: itm.job_number ? 'Job' : 'WO',
            itemNumber: itm.job_number || itm.wo_number,
            title: itm.last_status_note_text ? `<span style="font-style:italic">${itm.last_status_note_text}</span>` : '<span class="text-danger underlined">None</span>',
            status: 'Last Note:',
            status2: `<span class="${statusNotesClass}">${itm.last_status_note_date ? this.formatDate(itm.last_status_note_date) : '<span class="underline">None</span>'}</span>`,
            customer: '',
            meta: itm.captain_name,
            costing: 'Under/Over Billing:',
            costing2: itm.over_under_billing,
            dates: 'Net Cash Flow',
            dates2: this.formatNumber(itm.net_cash_flow, 1),
            class: 'fourth-row'
          }
        ]
      })

      return filtered
    }
  },

  methods: {
    getData() {
      if (!this.authCode) {
        this.$snack.open('Cannot fetch data', 'error')
        return
      }
      const params = {
        action: 'fetch_format_job_wo_report_data_unauth',
        auth_code: this.authCode
      }
      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Fetching data'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.data = data
        })
        .catch(err => {
          console.log(err)
          this.$snack.open(err.message || 'Problem fetching report data', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    generatePDF(showPrice, emails, emailNote, signature, emailCc, emailBcc) {
      const params = {
        action: 'quote_template_generate_pdf_email',
        quote_id: this.data.id,
        emailTo: emails,
        emailCc,
        emailBcc,
        showPrice: showPrice ? 1 : null,
        emailNote,
        signature,
        emailFrom: this.data.captain_email
      }

      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Generating PDF'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.existingPDF = data.location || null
          this.downloadPDF()

          if (emails) {
            this.showMarkSendDialog()
          }
        })
        .catch(err => {
          console.log(err)
          this.$snack.open(err.message || 'Problem generating pdf', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    downloadPDF() {
      window.open(this.existingPDF)
    },

    getPlainText(text) {
      return appFuncs.getPlainText(text)
    },

    pdfClearModifications() {
      let footer = document.getElementsByClassName('footer')[0]
      if (!footer) {
        return
      }
      // remove all previously added content
      let addedContent = document.getElementsByClassName('added-content')
      while (addedContent[0]) {
        addedContent[0].parentNode.removeChild(addedContent[0])
      }

      let addedSpacers = document.getElementsByClassName('added-spacer')
      while (addedSpacers[0]) {
        addedSpacers[0].parentNode.removeChild(addedSpacers[0])
      }
      let addedLines = document.getElementsByClassName('measure-line')
      while (addedLines[0]) {
        addedLines[0].parentNode.removeChild(addedLines[0])
      }
      let pageNum = footer.getElementsByClassName('page-number')
      if (pageNum && pageNum[0]) {
        pageNum[0].innerHTML = '<p>Page 1 of 1<p>'
      }
    },

    pdfSpaceSections() {
      let mainEl = document.getElementById('pdf-template')
      let sections = Array.prototype.slice.call(document.querySelectorAll('section'))

      let header = document.getElementsByClassName('header')[0]
      let footer = document.getElementsByClassName('footer')[0]
      let footerHeight = footer.offsetHeight + parseInt(footer.style.marginBottom)

      const pageHeight = 1460
      let nextPageBottom = pageHeight
      let pageCount = 1

      sections.forEach(function (itm) {
        let top = itm.getBoundingClientRect().top + document.documentElement.scrollTop
        let itmHeight = itm.offsetHeight
        let bottom = itmHeight + top + 100
        let hasAttachments = itm.classList.contains('attachments-section')

        // if page break is between top and bottom, add enough margin to get on next page
        if (top < nextPageBottom && bottom > nextPageBottom) {
          // if section is taller than page, add break between table rows
          if (itmHeight > 500 && !hasAttachments) {
            let rows = itm.getElementsByTagName('tr')
            Array.from(rows).forEach(function (rowItm) {
              let rowTop = rowItm.getBoundingClientRect().top + document.documentElement.scrollTop
              let rowHeight = rowItm.offsetHeight
              let rowBottom = rowHeight + rowTop + 100
              if (rowTop < nextPageBottom && rowBottom > nextPageBottom) {
                let marginNeeded = pageCount * pageHeight - rowTop + header.offsetHeight + 20
                let spacer = document.createElement('div')
                spacer.classList.add('added-spacer')
                spacer.style.paddingTop = marginNeeded + 'px' // use padding to prevent top of margin issue from not creating space on next page.
                rowItm.insertAdjacentHTML('beforebegin', spacer.outerHTML)
                nextPageBottom += pageHeight
                pageCount++
              }
            })
          } else {
            let marginNeeded = pageCount * pageHeight - top - 20 + header.offsetHeight + footer.offsetHeight
            let spacer = document.createElement('div')
            spacer.classList.add('added-spacer')
            spacer.style.paddingTop = marginNeeded + 'px' // use padding to prevent top of margin issue from not creating space on next page.
            itm.insertAdjacentHTML('beforebegin', spacer.outerHTML)
            nextPageBottom += pageHeight
            pageCount++
          }
        }
      })

      let newFooter = null
      let addedContent = null
      let pageNo = 0
      let topPos = 0
      let pageNum = null
      for (var i = 0; i < pageCount; i++) {
        let isLastPage = i + 1 == pageCount
        pageNo++
        newFooter = footer.cloneNode(true)
        newFooter.style.visibility = 'visible'
        pageNum = newFooter.getElementsByClassName('page-number')
        if (pageNum && pageNum[0]) {
          pageNum[0].innerHTML = '<p>Page ' + pageNo + ' of ' + pageCount + '<p>'
        }
        topPos = pageNo * pageHeight - footerHeight - 30
        addedContent = '<div class="added-content" style="top:' + topPos + 'px">' + newFooter.outerHTML
        if (!isLastPage) {
          addedContent += '<div style="margin-top:0px">' + header.outerHTML + '</div>' + '</div>'
        }
        mainEl.insertAdjacentHTML('afterend', addedContent)
      }

      // hide original footer as to not have to apply changes that disrupt re-layout
      footer.style.visibility = 'hidden'

      // update original footer now at bottom of page
      let pageNumLast = footer.getElementsByClassName('page-number')
      if (pageNumLast && pageNumLast[0]) {
        pageNumLast[0].innerHTML = '<p>Page ' + pageCount + ' of ' + pageCount + '<p>'
      }

      this.pageCount = pageCount
      // this.addGridLines()
    },

    addGridLines() {
      let addedContent = document.getElementsByClassName('measure-line')
      while (addedContent[0]) {
        addedContent[0].parentNode.removeChild(addedContent[0])
      }

      let mainEl = document.getElementById('pdf-template').offsetHeight
      let parts = mainEl / 10
      let line = null
      for (var i = 0; i < parts; i++) {
        line = document.createElement('div')
        line.setAttribute('class', 'measure-line')
        line.style.top = i * 10 + 'px'
        line.innerHTML = i * 10
        if ((i * 10) % 1460 == 0) {
          line.style.background = 'purple'
        }
        document.getElementsByTagName('body')[0].appendChild(line)
      }
    }
  },

  mounted() {
    this.getData()

    if (this.authedUser) {
      this.$store.dispatch('getEmployees')
    }
  },

  updated() {
    if (this.pdf) {
      this.pdfClearModifications()
      setTimeout(() => {
        this.pdfSpaceSections()
      }, 150)
    }
  }
}
</script>

<style lang="scss">
.section {
  ul,
  ol {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    list-style-position: inside;

    li {
      margin-bottom: 5px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

body .pdf-template {
  min-width: 1020px;
  width: 1020px;
  margin: 0 auto;
  padding: 0 20px;

  @media print {
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
      float: left;
    }
    .col-sm-12 {
      width: 100%;
    }
    .col-sm-11 {
      width: 91.66666667%;
    }
    .col-sm-10 {
      width: 83.33333333%;
    }
    .col-sm-9 {
      width: 75%;
    }
    .col-sm-8 {
      width: 66.66666667%;
    }
    .col-sm-7 {
      width: 58.33333333%;
    }
    .col-sm-6 {
      width: 50%;
    }
    .col-sm-5 {
      width: 41.66666667%;
    }
    .col-sm-4 {
      width: 33.33333333%;
    }
    .col-sm-3 {
      width: 25%;
    }
    .col-sm-2 {
      width: 16.66666667%;
    }
    .col-sm-1 {
      width: 8.33333333%;
    }

    .no-print-url::after {
      content: none;
    }
  }
}

@media print {
  .report-table {
    background-color: inherit !important; /* Maintain the background colors for print */
    -webkit-print-color-adjust: exact !important; /* For Webkit browsers like Chrome and Safari */
    print-color-adjust: exact !important; /* For other browsers */
  }

  .first-row {
    background-color: #f9f9f9 !important;
  }

  .second-row {
    background-color: #fff !important;
  }

  .third-row {
    background-color: #f9f9f9 !important;
  }

  .fourth-row {
    background-color: #ffffff !important;
    border-bottom: 2px solid #000 !important;
  }
}

.quote-action-section {
  padding: 20px;
  border: 3px dashed;
  margin-top: 20px;
  border-radius: 5px;
}

.optional {
  color: $pastel-blue;
  display: inline-block;
}

.header {
  background: #fff;

  padding: 40px 0px 20px;

  .header-bottom {
    height: 1px;
    border-top: 3px solid #000;
  }

  img {
    width: auto;
    height: 90px;
  }
}

.section {
  width: 100%;

  &.main-section {
    margin-bottom: 20px;
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    font-style: italic;
  }

  h5 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0px;
    font-style: italic;
    margin-top: 10px;

    &.underline {
      border-bottom: 1px solid;
      padding: 3px 0;
      margin-bottom: 7px;
    }
  }

  &.has-border {
    border: 1px solid $other-medium-gray;
    padding: 15px;

    h5 {
      margin: 0 0 10px;
      text-decoration: underline;
    }
  }
}

table.note-table {
  width: 100%;
  margin-bottom: 5px;
  table-layout: fixed;

  &:not(.fee-table) td {
    &:first-child {
      //  text-transform: uppercase;
    }
  }

  td,
  th {
    //  border:1px solid $other-medium-gray;
    padding: 3px;
    word-break: break-word;
    min-width: 90px;
    font-weight: normal;
    vertical-align: top;
    white-space: pre-line;

    &:first-child {
      width: 35px;
    }

    &:nth-child(3) {
      width: 110px;
    }
  }

  td {
    // white-space: pre-line;
  }

  &.system-notes {
    td:first-child {
      width: 50px;
      padding-left: 10px;
      display: inline-block;
      white-space: nowrap;
    }
  }

  &.fee-table {
    table-layout: fixed;
    margin: 5px 0 40px;

    td,
    th {
      &:first-child {
        width: auto;
      }

      &:nth-child(2) {
        width: 150px;
      }
    }

    th {
      border: 0;
      font-weight: bold;
    }

    tr {
      //  border: 1px solid;
      background: #eee;

      td {
        padding: 5px;
        border: 1px solid;
      }
    }
  }

  &.systems-table {
    table-layout: fixed;
    margin: 5px 0 40px;

    td,
    th {
      &:first-child {
        width: 150px;
      }

      &:nth-child(3) {
        width: 150px;
      }
    }

    th {
      border: 0;
      font-weight: bold;
    }

    tr {
      //  border: 1px solid;
      background: #eee;

      td {
        padding: 5px;
        border: 1px solid;
      }
    }
  }

  &.optional-table {
    margin-bottom: 10px;
  }
}

.footer {
  background: #fff;

  .footer-inner {
    max-width: 1020px;
    margin: 0 auto;
    padding: 50px 0px 0 !important;
  }

  .footer-left {
    width: 33%;
    display: inline-block;
    text-align: left;
  }

  .footer-center {
    width: 33%;
    display: inline-block;
    text-align: center;
  }

  .footer-right {
    width: 33%;
    display: inline-block;
    text-align: right;
  }
}

.measure-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background: red;
}

.added-content {
  width: 100%;
  position: absolute;

  .header {
    max-width: 1020px;
    margin: 50px auto 0px;
    padding: 50px 20px 0;
  }

  .footer-inner {
    padding: 50px 20px 20px !important;
  }
}

.optional-systems-section {
  border: 1px solid $other-medium-gray;
  padding: 5px 15px;
  border-radius: 5px;
}

.table-number-content-parent {
  vertical-align: top;

  > div {
    padding: 3px;
    vertical-align: top;
    word-wrap: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
    white-space: pre-line;
  }

  > :nth-child(1) {
    width: 50px;
    display: inline-block;
  }

  > :nth-child(2) {
    width: calc(100% - 60px);
    display: inline-block;
    word-wrap: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
  }

  &.triple-col {
    > :nth-child(2) {
      width: calc(100% - 370px);
    }

    > :nth-child(3) {
      width: 300px;
      display: inline-block;
      text-align: right;
    }
  }
}

.report-table {
  font-size: 12px;

  .row {
    border-bottom: 1px solid #ccc;
    padding: 8px 0;
    overflow: hidden;

    .col-sm-1,
    .col-sm-2 {
      padding: 0 2px;
    }
  }

  .header-row {
    background-color: #f0f0f0;
    font-weight: bold;
    border-bottom: 2px solid #000;
    height: auto;
  }

  section.section {
    // border-top: 2px solid fuchsia;
  }

  .first-row {
    background-color: #f9f9f9;
  }

  .second-row {
    background-color: #ffffff;
  }

  .third-row {
    background-color: #f9f9f9;
  }

  .fourth-row {
    background-color: #ffffff;
    border-bottom: 2px solid #000;
  }
}

.attachments-section {
  .attachment-container {
    width: 350px;
    object-fit: contain;
    padding: 5px;
    display: inline-block;
  }

  img {
    display: block; /* Removes default inline-block spacing */
    max-width: 100%; /* Makes sure the image takes full width of the container */
    max-height: 300px; /* Set the maximum height as desired */
    object-fit: cover; /* Adjusts the size of the image within the container while preserving aspect ratio */
    width: 100%;
    margin-top: 4px;
  }
}
</style>
