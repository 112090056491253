<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Parent Category</label>
          <br>
          <span class="current-category">
            {{ currentParentName }}
          </span>
        </div>
        <materials-category-table @selectCategory="categoryCurrent = $event" label="Choose Subcategory" :init-category-current="categoryCurrent" :item-type="itemType"/>
        <br>
        <button
          class="btn"
          @click="$bus.$emit('changeMaterialCategory', categoryCurrent), $bus.$emit('modalClose', 'MaterialsChangeCategory')"
          v-html="'Set Parent Category'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import appFuncs from 'appFuncs';

export default {
  name: 'MaterialsChangeCategory',
  data() {
    return {
      categoryCurrent: null,
    };
  },

  props: {
    categoryId: {
      type: [Number, String],
      required: true
    },
    itemType: {
      type: String,
      default: 'm'
    }
  },

  computed: {
    ...mapGetters([
      'materialCategories',
    ]),

    // filteredCategories() {
    //   return this.materialCategories.filter(itm => {
    //     return itm.item_type != this.itemType;
    //   });
    // },

    currentParentName() {
      let parent = this.materialCategories.find(itm => {
        return itm.id == this.categoryCurrent;
      });
      if (parent) {
        return parent.name;
      }
      return 'None';
    }
  },

  mounted() {
    this.categoryCurrent = this.categoryId;
  },

};

</script>

<style lang="scss" scoped>
@import "src/assets/sass/paper/_variables.scss";

h4 {
  margin: 10px 0;
}

.current-category {
  font-size: 24px;
  font-weight: bold;
}
</style>
