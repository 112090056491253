<template>
  <div class="item-note-container">
    <div v-if="spectrumSingleNote || singleNote" class="single-note">
      <label>{{ label }}</label>
      <a class="note-edit-toggle" v-if="!editingItem && !readOnly" @click="editItem(true, existingText)">
        <i class="ti-pencil" style="padding-left:5px" />
      </a>
      <div :class="['detail-item--edit']" v-if="editingItem">
        <div class="edit-container">
          <wysiwyg v-model="editNoteText" v-click-outside="editNoteChange" />
          <div class="editify-controls">
            <button
              v-if="editNoteText !== existingText"
              class="edit-save"
              @click="
                newNoteText = editNoteText
                addNote()
                editingItem = false
              "
            >
              <i class="ti-check" />
            </button>
            <button class="edit-cancel" @click="cancelEdit">
              <i class="ti-na" />
            </button>
          </div>
          <p class="paste-note">
            {{ pasteNote }}
          </p>
        </div>
      </div>
      <div v-else>
        <div class="note-text-display" v-html="existingText || '<span data-empty>(Empty)</span>'" />
      </div>
    </div>
    <!-- end of single note -->
    <div v-else class="multi-note">
      <!-- else is multi note -->
      <label>{{ label }}</label>
      <br />
      <span v-if="!allNotes || !allNotes.length" data-empty>
        (Empty)
        <br /><br />
      </span>
      <div v-if="allNotes" class="note-container">
        <ul :class="['existing-notes', 'show-scrollbar', { 'read-only': readOnly || !isEditor }]" :style="{ 'max-height': maxHeight }">
          <li v-for="(note, id) in allNotes" :key="id">
            <div v-if="editingItem == note.id">
              <div class="edit-container">
                <label>Edit Note</label>
                <div class="form-group">
                  <date-picker v-model="editNoteDate" :pre-filled="false" :disabled="false" />
                </div>
                <wysiwyg v-model="editNoteText" v-click-outside="editNoteChange" />
                <div class="editify-controls">
                  <span class="edit-save" v-if="existingText !== editNoteText || editNoteDate" @click="updateNote(note.id)" title="Save edit">
                    <i class="ti-check" />
                  </span>
                  <span class="edit-cancel" @click="cancelEdit" title="Cancel editing">
                    <i class="ti-na" />
                  </span>
                  <span v-if="isPM" class="edit-delete" @click="deleteNote(note.id)" title="Double click to delete">
                    <i class="ti-close" />
                  </span>
                </div>
                <p class="paste-note paste-note-multi">
                  {{ pasteNote }}
                </p>
              </div>
            </div>
            <div v-else>
              <p class="note-meta">
                <span class="note-date" v-if="note.nice_date" v-html="note.nice_date" /> -
                <span class="note-author" v-if="note.author_name" v-html="note.author_name" />
                <span class="note-author" v-if="note.extra_number" v-html="' - ' + note.extra_number" />
                <span v-if="canEditNote(note) && editingItem != note.id" class="note-edit-button" @click="editItem(note.id, note.note_text, note.nice_date)">
                  <i class="ti-pencil" />
                </span>
              </p>
              <p class="note-text" v-html="note.note_text" />
            </div>
          </li>
        </ul>
      </div>

      <div v-if="!readOnly && isEditor && !usePropText" class="add-note">
        <label> New Note </label>

        <div class="form-group">
          <date-picker v-model="noteDate" :pre-filled="true" :disabled="false" />
        </div>

        <wysiwyg v-model="newNoteText" v-click-outside="addNoteChange" v-if="!readOnly" />
        <div v-else>
          <textarea v-model="newNoteText" class="form-control" disabled readonly />
        </div>

        <p class="paste-note">Note: Some formatting from pasted text may be filtered on save, click outside edit box to preview any changes.</p>
        <button class="btn" @click="addNote">Add Note</button>
      </div>
    </div>
    <waiting-spinner :wait="wait" :contained="true" />
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'
import DatePicker from 'components/UIComponents/DatePicker'
import commonFuncs from 'mixins/commonFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import sanitizeHtml from 'sanitize-html'

export default {
  data() {
    return {
      wait: { message: '' },
      urls: store.getters.urls,
      newNoteText: '',
      noteDate: null,
      allNotes: [],
      editNoteText: '',
      editNoteDate: '',
      editingItem: 0,
      existingText: '',
      label: this.labelText || 'Notes',
      pasteNote: 'Note: Some formatting from pasted text may be filtered on save, click outside edit box to preview any changes.'
    }
  },

  mixins: [commonFuncs],

  props: {
    table: {
      type: String,
      default: '',
      required: true
    },
    itemId: {
      type: [String, Number],
      default: '',
      required: true
    },
    extraId: {
      type: [String, Number],
      default: '',
      required: false
    },
    showExtraIdOnly: {
      type: Boolean,
      default: false
    },
    labelText: {
      type: String,
      default: ''
    },
    spectrumSingleNote: {
      // if true note field is one large single note which is manageable in Spectrum
      type: Boolean,
      default: false
    },
    singleNote: {
      // if true note field is one large single note which is manageable in Spectrum
      type: Boolean,
      default: false
    },
    postSpectrumType: {
      type: String,
      default: ''
    },
    postSpectrumName: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    itmType: {
      type: String,
      default: ''
    },
    requireDetailedNotes: {
      type: Boolean,
      default: false
    },
    usePlainText: {
      type: Boolean,
      default: false
    },
    usePropText: {
      type: Boolean,
      default: false
    },
    propText: {
      type: String,
      default: ''
    },
    loadFromIds: {
      type: Array,
      default: () => []
    },
    maxHeight: {
      type: String,
      default: '380px'
    }
  },

  components: {
    DatePicker,
    WaitingSpinner
  },

  computed: {
    isPM() {
      return store.getters.userAuthedData.user_role.indexOf('pm') !== -1
    },

    isEditor() {
      const userRoleArr = Array.isArray(store.getters.userAuthedData.user_role) ? store.getters.userAuthedData.user_role : []
      return userRoleArr.indexOf('editor') !== -1
    }
  },

  methods: {
    async addNote() {
      return new Promise((resolve, reject) => {
        if (this.readOnly || !this.isEditor) {
          reject()
          return
        }

        if (!this.newNoteText) {
          this.$snack.open('Note text cannot be empty', 'warning')
          reject()
          return
        }

        if (this.requireDetailedNotes && this.newNoteText.length < 40) {
          this.$snack.open('Admin has required this note type/location to contain sentence detail or longer text length', 'warning')
          reject()
          return
        }

        var userAuthedData = store.getters.userAuthedData

        console.log(userAuthedData)

        var data = {
          action: 'add_item_note',
          table: this.table,
          author_id: userAuthedData.eid,
          author_name: userAuthedData.fname + ' ' + userAuthedData.lname,
          note_text: this.noteChange(this.newNoteText),
          note_date: this.noteDate,
          item_id: this.itemId,
          extra_id: this.extraId,
          spectrum_note_type: this.postSpectrumType,
          spectrum_note_topic: this.postSpectrumName,
          spectrum_single_note: this.spectrumSingleNote ? 1 : '' // convert bool to string
        }

        this.wait = { message: 'Adding note' }

        appFuncs.ajax_request(store.getters.sherAuth, data, result => {
          this.wait = { message: '' }
          const timestamp = result.timestamp

          if (result.status === 'success') {
            this.newNoteText = ''
            this.getNotes(true) // refresh
            let updateData = {
              spectrumTopic: this.postSpectrumName,
              noteTable: this.table,
              itemId: this.itemId,
              noteText: data.note_text,
              timestamp
            }
            this.$emit('updated', updateData)
            this.$bus.$emit('itemCommentUpdate', updateData)
            resolve()
          } else {
            this.$snack.open(result.message || 'Problem adding note', 'error')
            reject()
          }
        })
      })
    },

    getDBNotes() {
      var params = {
        action: 'get_item_notes',
        table: this.table,
        item_id: this.itemId,
        load_from_ids: this.loadFromIds
      }

      if (this.showExtraIdOnly) {
        params.extra_id = this.extraId
      }

      this.wait = { message: 'Getting notes' }
      appFuncs.ajax_request(store.getters.sherAuth, params, result => {
        this.wait = { message: '' }
        if (result.status == 'success') {
          let allNotes = result.data
          for (let i = 0; i < allNotes.length; i++) {
            if (i == 0) {
              this.existingText = allNotes[i].note_text
            }
            if (allNotes[i].extra_id && this.itmType === 'Job') {
              allNotes[i].extra_number = this.formatExtraNumber(this.itemId, allNotes[i].extra_id)
            }
          }

          allNotes.sort((a, b) => b.timestamp - a.timestamp)

          this.allNotes = allNotes
        } else {
          this.$snack.open(result.message || 'Problem getting notes', 'error')
        }
      })
    },

    getSpectrumNotes(refresh) {
      if (this.postSpectrumType === 'job') {
        var storageName = 'job_notes-' + this.itemId
        if (!refresh) {
          let saved = appFuncs.storageGet(storageName)
          if (saved) {
            if (saved.status === 'success') {
              let noteObj = saved.data.find(itm => {
                return itm.Job_Number === this.itemId && itm.Notes_Topic === this.postSpectrumName
              })
              if (typeof noteObj === 'object') {
                this.existingText = noteObj.Notes_Text
              }
            }
            return
          }
        }

        var data = {
          action: 'get_mssql_job_notes2',
          job_number: this.itemId
        }

        this.wait = { message: 'Fetching Notes' }
        appFuncs.ajax_request(
          store.getters.sherAuth,
          data,
          result => {
            this.wait = { message: '' }
            if (result.status === 'success') {
              let noteObj = result.data.find(itm => {
                return itm.Job_Number === this.itemId && itm.Notes_Topic === this.postSpectrumName
              })
              if (typeof noteObj === 'object') {
                this.existingText = noteObj.Notes_Text
              }
              appFuncs.storageSave(storageName, result.data, 5)
            }
          },
          null,
          store.getters.urls.mssql + 'ajax.php'
        )
      } else if (this.postSpectrumType === 'wo') {
        var storageName = 'wo_notes-' + this.itemId
        if (!refresh) {
          let saved = appFuncs.storageGet(storageName)
          if (saved) {
            if (saved.status === 'success') {
              let noteObj = saved.data.find(itm => {
                return itm.WO_Number === this.itemId && itm.Notes_Topic === this.postSpectrumName
              })
              if (typeof noteObj === 'object') {
                this.existingText = noteObj.Notes_Text
              }
            }
            return
          }
        }

        var data = {
          action: 'get_mssql_wo_notes',
          wo_number: this.itemId
        }

        this.wait = { message: 'Fetching Work Order Notes' }
        appFuncs.ajax_request(
          store.getters.sherAuth,
          data,
          result => {
            this.$bus.$emit('stopWaiting', data.action)
            this.wait = { message: '' }
            if (result.status === 'success') {
              let noteObj = result.data.find(itm => {
                return itm.WO_Number === this.itemId && itm.Notes_Topic === this.postSpectrumName
              })
              if (typeof noteObj === 'object') {
                this.existingText = noteObj.Notes_Text
              }
              appFuncs.storageSave(storageName, result.data, 5)
            }
          },
          null,
          store.getters.urls.mssql + 'ajax.php'
        )
      }
    },

    getNotes(refresh) {
      if (this.spectrumSingleNote) {
        this.getSpectrumNotes(refresh)
      } else {
        this.getDBNotes()
      }
    },

    editItem(id, noteText, niceDate) {
      this.existingText = noteText
      this.editingItem = id
      this.editNoteText = noteText
      this.editNoteDate = niceDate
    },

    cancelEdit() {
      // if(this.existingText !== this.editNoteText && !confirm("Are you sure you'd like to discard changes?")){
      //   return;
      // }
      this.editingItem = ''
      this.editNoteText = ''
      this.editNoteDate = ''
    },

    deleteNote(id) {
      if (!confirm("Are you sure you'd like to delete this note?")) {
        return
      }

      var data = {
        action: 'delete_item_note',
        table: this.table,
        row_id: id,
        item_id: this.itemId,
        spectrum_note_type: this.postSpectrumType,
        spectrum_note_topic: this.postSpectrumName
      }

      this.wait = { message: 'Deleting note' }
      appFuncs.ajax_request(store.getters.sherAuth, data, result => {
        this.wait = { message: '' }
        if (result.status === 'success') {
          this.getNotes(true) // refresh
          this.cancelEdit()
          this.$emit('updated')
          this.$bus.$emit('itemCommentUpdate', {})
          this.$snack.open('Note deleted')
        } else {
          this.$snack.open(result.message || 'Problem deleting note', 'error')
        }
      })
    },

    updateNote(id) {
      if (this.readOnly || !this.isEditor) {
        return
      }

      if (!this.editNoteText) {
        this.$snack.open('Note text cannot be empty', 'warning')
        return
      }

      if (this.requireDetailedNotes && this.editNoteText.length < 40) {
        this.$snack.open('Admin has required this note type/location to contain sentence detail or longer text length', 'warning')
        return
      }

      var data = {
        action: 'update_note_text',
        table: this.table,
        row_id: id, // id of actual db row
        note_text: this.noteChange(this.editNoteText),
        note_date: this.editNoteDate,
        item_id: this.itemId, // Job or WO number usually, used for updating Spectrum
        extra_id: this.extraId,
        spectrum_note_type: this.postSpectrumType,
        spectrum_note_topic: this.postSpectrumName,
        spectrum_single_note: this.spectrumSingleNote ? 1 : ''
      }

      this.wait = { message: 'Saving note' }
      appFuncs.ajax_request(store.getters.sherAuth, data, result => {
        if (result.status === 'success') {
          this.getNotes(true) // refresh
          this.cancelEdit()
          this.$emit('updated')
          this.$bus.$emit('itemCommentUpdate', {})
          this.$snack.open('Note updated')
        } else {
          this.$snack.open(result.message || 'Problem updating note', 'error')
        }
      })
    },

    canEditNote(note) {
      if (this.readOnly || !this.isEditor) {
        return false
      }
      if (this.isPM || store.getters.userAuthedData.eid.toString() === note.author_id.toString()) {
        return true
      }
    },

    noteChange(text) {
      if (!text) {
        return ''
      }
      if (this.usePlainText) {
        return sanitizeHtml(text, {
          allowedTags: []
        })
      }
      return sanitizeHtml(text, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['u', 'h1', 'h2'])
      })
    },

    // arg-less method for v-click-outside
    addNoteChange() {
      this.newNoteText = this.noteChange(this.newNoteText)
    },

    // arg-less method for v-click-outside
    editNoteChange() {
      this.editNoteText = this.noteChange(this.editNoteText)
    }
  },

  mounted() {
    this.getNotes()
  },

  watch: {
    itemId() {
      this.getNotes()
    },

    propText() {
      this.newNoteText = this.propText
    },

    loadFromIds() {
      this.getDBNotes()
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.item-note-container {
  margin-bottom: 0;
  position: relative;
}

.edit-container,
.add-note {
  padding: 0 0 10px 0;
  border-radius: 4px;
}

.multi-note {
  .edit-container {
    border: 1px solid $grey-border-color;
    padding: 10px;
    margin-right: 10px;
  }
}

.existing-notes {
  overflow-y: scroll;
  padding: 0;

  &:not(.read-only) {
    border-bottom: 1px solid $grey-border-color;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }

  li {
    padding: 10px 0;
    display: block;
  }
}

.note-text {
  white-space: pre-wrap;
  border-left: 4px solid #d2d2d2;
  padding-left: 10px;
  font-size: 14px;
}

.note-author {
  font-weight: bold;
}

.note-date {
  font-weight: bold;
}

.note-edit-toggle {
  color: $text-color;
}

.note-edit-button {
  color: #555;
  border: 1px solid;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  display: inline-block;
  margin: 0 10px 0 0;
  float: right;
}

.edit-container {
  position: relative;
}

.paste-note {
  font-size: 12px;
  font-style: italic;
  color: #555;
  margin: 5px;
  min-height: 35px;
}

.editify-controls {
  bottom: 0px;
  right: 0px;
  float: right;
  position: relative;
  margin: 5px 0 0 10px;

  @media (max-width: 480px) {
    float: none;
    text-align: right;
  }
}
</style>
