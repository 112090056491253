<template>
  <div class="modal fade" id="siteModal" tabindex="-1" role="dialog" aria-labelledby="siteModalLabel" data-keyboard="false" data-backdrop="static">
    <div v-bind:class="classes" role="document">
      <div v-bind:class="contentClasses">
        <div class="modal-body">
          <h4 v-if="modal.title" class="modal-title" id="siteModalLabel">{{ modal.title }}</h4>
          {{ modal.content }}
          <div class="button-container">
            <button v-for="button in modal.buttons" v-on:click="modalFunction(button.context, button.buttonFunc, button.buttonParams)" type="button" class="btn btn-primary">
              {{ button.buttonText }}
            </button>
            <router-to v-if="modal.route" type="button" class="btn btn-primary">{{ modal.buttonText }}</router-to>
          </div>

          <div v-if="modal.component">
            <component v-bind:is="modal.component" :componentData="componentData"></component>
          </div>
        </div>
      </div>
      <button v-if="!modal.hideClose" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    </div>
  </div>
</template>

<script>
import store from 'store'
import appFuncs from 'appFuncs'
import { mapGetters } from 'vuex'
import $ from 'jquery'

export default {
  data() {
    return {
      componentData: ''
    }
  },

  components: {
    // ResetPassword
  },

  computed: {
    modal() {
      return store.getters.modal
    },

    classes() {
      var size = this.modal.size || ''
      var classes = 'modal-dialog '
      classes += size
      return classes
    },

    contentClasses() {
      var classes = 'modal-content '
      if (this.modal.classes) {
        classes += this.modal.classes
      }
      return classes
    }
  },

  methods: {
    openModal() {
      $('#siteModal').modal('show')
      store.commit('alerts', { show: 0 })
    },

    closeModal() {
      store.commit('modal', { trigger: 0 })
      store.commit('alerts', { show: 0 })
    },

    modalFunction(context, buttonFunc, buttonParams) {
      appFuncs.triggerModalFunction(context, buttonFunc, buttonParams)
    }
  },

  mounted() {
    var self = this
    $('#siteModal').on('hidden.bs.modal', function (e) {
      self.closeModal()
    })

    window.addEventListener('keyup', function (event) {
      if (event.keyCode == 27) {
        if (self.modal && !self.modal.hideClose) {
          self.closeModal()
        }
      }
    })

    this.$bus.$on('navigated', () => {
      self.closeModal()
    })
  },

  watch: {
    modal(oldVal, newVal) {
      if (oldVal === newVal) {
        return
      }
      if (this.modal.trigger === 1) {
        this.openModal()
      } else if (this.modal.trigger === 0) {
        // $('#siteModal').modal('hide')
      }

      if (this.modal.componentData) {
        this.componentData = this.modal.componentData
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.modal {
  overflow-y: scroll !important;

  .modal-body {
    padding: 30px !important;
  }

  .modal-xl {
    max-width: 1400px;
    width: 96%;
  }

  .modal-content {
    padding: 0px;

    h4 {
      margin-bottom: 10px;
    }

    .button-container {
      margin-top: 15px;

      .btn {
        margin-right: 5px;
      }
    }
  }

  .close {
    top: 10px;
    right: 12px;
    position: absolute;
    font-size: 28px;
  }
}
</style>
