
<div>
  <div class="row">
    <div class="col-sm-12">
      <div :class="['group-container', { 'separate-section': separateSection }]" v-for="(element, index) in elements" :key="index">
        <table>
          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(subField, i) in element.fields" :key="i">
              <td>
                <div class="form-group">
                  <label>{{ subField.label }}</label>
                  <vue-numeric v-if="subField.type === 'dollar'" currency="$" separator="," :class="['form-control']" :precision="2" v-model="element.value" inputmode="decimal" />
                  <input type="text" v-else v-model="subField.value" class="form-control" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-sm" @click="saveItem(element)">Save</button>
      </div>
      <button class="btn" @click="addOption">Add Option</button>
    </div>
  </div>
  <waiting-spinner />
</div>
