import DashboardLayout from 'src/components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/components/GeneralViews/NotFoundPage.vue'

import Main from 'src/components/Main.vue'

import Login from 'src/components/GeneralViews/Login.vue'
import Disallowed from 'src/components/GeneralViews/Disallowed.vue'
import AdminOptions from 'src/components/GeneralViews/AdminOptions.vue'
import SetupEmployeeExpenses from 'src/components/GeneralViews/SetupEmployeeExpenses.vue'
import QuoteSetup from 'src/components/Dashboard/Quotes/QuoteSetup.vue'

// Admin pages
// import Overview from 'src/components/Dashboard/Views/Overview.vue'
import UserProfile from 'src/components/Dashboard/Views/UserProfile.vue'
// import Notifications from 'src/components/Dashboard/Views/Notifications.vue'
import Icons from 'src/components/Dashboard/Views/Icons.vue'
// import Maps from 'src/components/Dashboard/Views/Maps.vue'
// import Typography from 'src/components/Dashboard/Views/Typography.vue'
// import TableList from 'src/components/Dashboard/Views/TableList.vue'

// Jobs pages
import AddNote from 'src/components/Dashboard/Jobs/AddNote.vue'
// import GetJobInfo from 'src/components/Dashboard/Jobs/GetJobInfo.vue'
// import JobBoard from 'src/components/Dashboard/Jobs/JobBoard.vue'

// Asset Pages
// import AssetSchedulingList from 'src/components/Dashboard/Jobs/AssetSchedulingList.vue'
// import AssetsManageEquipment from 'src/components/Dashboard/Jobs/AssetsManageEquipment.vue'
// import AssetsAllTrade from 'src/components/Dashboard/Jobs/AssetsAllTrade.vue'
// import AssetsBercon from 'src/components/Dashboard/Jobs/AssetsBercon.vue'
// import AssetsSheridan from 'src/components/Dashboard/Jobs/AssetsSheridan.vue'
// import AssetsUnitedRentals from 'src/components/Dashboard/Jobs/AssetsUnitedRentals.vue'

// Quote Pages
import QuoteList from 'src/components/Dashboard/Quotes/QuoteList.vue'
import QuoteReports from 'src/components/Dashboard/Quotes/QuoteReports.vue'
import CustomerQuoteTemplate from 'src/components/Dashboard/Quotes/CustomerQuoteTemplate.vue'
import QuoteWriteUpPage from 'src/components/Dashboard/Quotes/QuoteWriteUpPage.vue'

// Time Entry
import TimeEntryList from 'src/components/Dashboard/TimeEntry/TimeEntryList.vue'
import AddTimeEntry from 'src/components/Dashboard/TimeEntry/AddTimeEntry.vue'

// Form pages
import VacationRequest from 'src/components/Dashboard/Forms/VacationRequest.vue'
import OvertimeAvailability from 'src/components/Dashboard/Forms/OvertimeAvailability.vue'
// import MileageRequest from 'src/components/Dashboard/Forms/MileageRequest.vue'
import ToolAllowance from 'src/components/Dashboard/Forms/ToolAllowance.vue'

// Contacts Pages
import EmployeeContactList from 'src/components/Dashboard/Contacts/EmployeeContactList.vue'
import VendorList from 'src/components/Dashboard/Contacts/VendorList2.vue'
import CustomerList from 'src/components/Dashboard/Contacts/CustomerList.vue'
import CustomerContactList from 'src/components/Dashboard/Contacts/CustomerContactList.vue'

//expenses
import EmployeeExpenses from 'src/components/Dashboard/Contacts/EmployeeExpense.vue'

// Resources Pages
import ResourcesMain from 'src/components/Dashboard/Resources/ResourcesMain.vue'

// Job WO Manage Section
import ListJobs from 'src/components/Dashboard/JobWo/ListJobs.vue'
import JobDetails from 'src/components/Dashboard/JobWo/JobDetails.vue'
import ListWOs from 'src/components/Dashboard/JobWo/ListWOs.vue'
import JobWOReport from 'src/components/Dashboard/JobWo/JobWOReport.vue'
import WODetails from 'src/components/Dashboard/JobWo/WODetails.vue'
import JobWOReportTemplate from 'src/components/Dashboard/JobWo/JobWOReportTemplate.vue'

import Test from 'src/components/Dashboard/Test.vue'
import QuillTest from 'src/components/Dashboard/QuillTest.vue'

// Purchase Order Items
import POReports from 'src/components/Dashboard/PurchaseOrders/POReports.vue'
import POReportSummary from 'src/components/Dashboard/PurchaseOrders/POReportSummary.vue'
import ListPOs from 'src/components/Dashboard/PurchaseOrders/ListPOs.vue'

// Customer Facing
import CustomerWOTicket from 'src/components/GeneralViews/CustomerWOTicket.vue'
import CustomerJOBTicket from 'src/components/GeneralViews/CustomerJOBTicket.vue'
import WOSignOffTemplate from 'src/components/GeneralViews/WOSignOffTemplate.vue'
import JobSignOffTemplate from 'src/components/GeneralViews/JobSignOffTemplate.vue'
import JobExtraSignOffTemplate from 'src/components/GeneralViews/JobExtraSignOffTemplate.vue'
import PurchaseOrderReportTemplate from 'src/components/GeneralViews/PurchaseOrderReportTemplate.vue'
import POTemplate from 'src/components/Dashboard/PurchaseOrders/POTemplate.vue'

import MaterialsAdmin from 'src/components/Dashboard/Materials/MaterialsAdmin.vue'
import EquipmentAdmin from 'src/components/Dashboard/Materials/EquipmentAdmin.vue'
import OtherChargesAdmin from 'src/components/Dashboard/Materials/OtherChargesAdmin.vue'
import RFPListPage from 'src/components/Dashboard/Materials/RFPListPage.vue'
import MaterialGroupsListPage from 'src/components/Dashboard/Materials/MaterialGroupsListPage.vue'
import MaterialsAdminOneTime from 'src/components/Dashboard/Materials/MaterialsAdminOneTime.vue'

import AccountsReceivableList from 'src/components/Dashboard/AccountsReceivable/AccountsReceivableList.vue'
import AccountsReceivableStatement from 'src/components/GeneralViews/AccountsReceivableStatement.vue'
import AccountsPayableList from 'src/components/Dashboard/AccountsPayable/AccountsPayableList.vue'
import Aging from 'src/components/Dashboard/AccountsReceivable/Aging.vue'

import AddExpense from 'src/components/Dashboard/Expenses/AddExpense.vue'
import ExpensesList from 'src/components/Dashboard/Expenses/ExpensesList.vue'

import BillingList from 'src/components/Dashboard/Billing/BillingList.vue'
import BillingSubTasks from 'src/components/Dashboard/Billing/BillingSubTasks.vue'

import DepartmentReport from 'src/components/Dashboard/TimeEntry/DepartmentReports.vue'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/disallowed',
    name: 'Disallowed',
    component: Disallowed
  },
  {
    path: '/app',
    component: DashboardLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'main',
        component: Main
      },
      {
        path: 'add-note',
        name: 'Project Log',
        component: AddNote,
        meta: {
          role: 'editor'
        }
      },
      {
        path: 'admin-options',
        name: 'App Options',
        component: AdminOptions,
        meta: {
          role: 'admin'
        }
      },
      {
        path: 'setup',
        name: 'Setup',
        component: QuoteSetup,
        meta: {
          role: 'admin'
        }
      },
      {
        path: 'user-options',
        name: 'User Options',
        component: UserProfile
      },
      {
        path: 'vendors',
        name: 'Vendors',
        component: VendorList
      },
      {
        path: 'department-reports',
        name: 'Department Reports',
        component: DepartmentReport
      },
      {
        path: 'policies-procedures',
        name: 'Policies & Procedures',
        component: ResourcesMain
      },
      {
        path: 'vacation-request',
        name: 'Vacation Request',
        component: VacationRequest
      },
      {
        path: 'overtime-availability',
        name: 'Overtime Availability',
        component: OvertimeAvailability
      },
      // {
      //   path: 'mileage-request',
      //   name: 'Mileage Request',
      //   component: MileageRequest
      // },
      // {
      //   path: 'job-board',
      //   name: 'Job Board',
      //   component: JobBoard,
      //   meta: {
      //     role: 'viewer'
      //   }
      // },
      // {
      //   path: 'asset-scheduling',
      //   name: 'Asset Scheduling',
      //   component: AssetSchedulingList
      // },
      // {
      //   path: 'assets/asset-scheduling',
      //   name: 'Assets',
      //   component: AssetSchedulingList
      // },
      // {
      //   path: 'assets/asset-manage',
      //   name: 'Assets Manage Equipment',
      //   component: AssetsManageEquipment
      // },
      // {
      //   path: 'assets/all-trade',
      //   name: 'Assets All Trade',
      //   component: AssetsAllTrade
      // },
      // {
      //   path: 'assets/bercon',
      //   name: 'Assets Bercon',
      //   component: AssetsBercon
      // },
      // {
      //   path: 'assets/sheridan',
      //   name: 'Assets Sheridan',
      //   component: AssetsSheridan
      // },
      // {
      //   path: 'assets/united-rentals',
      //   name: 'Assets United Rentals',
      //   component: AssetsUnitedRentals
      // },
      {
        path: 'employees',
        name: 'Employee Contacts',
        component: EmployeeContactList
      },
      {
        path: 'employee-expenses',
        name: 'Employee Expenses',
        component: EmployeeExpenses
      },
      {
        path: 'setup-employee-expenses',
        name: 'Setup for Employee Expenses',
        component: SetupEmployeeExpenses
      },
      {
        path: 'customers',
        name: 'Customer List',
        component: CustomerList,
        meta: {
          role: 'pm'
        }
      },
      {
        path: 'customer-contacts',
        name: 'Customer Contacts',
        component: CustomerContactList,
        meta: {
          role: 'pm'
        }
      },
      {
        path: 'tool-allowance',
        name: 'Tool Allowance',
        component: ToolAllowance
      },
      {
        path: 'all-quotes/:openQuoteIncr?/:openTab?/:setEditMode?',
        name: 'All Quotes',
        component: QuoteList,
        meta: {
          role: ['pm', 'Q']
        },
        props: route => ({ openQuoteIncr: route.params.openQuoteIncr, openTab: route.params.openTab, setEditMode: route.params.setEditMode, dueStatus: route.query.dueStatus })
      },
      {
        path: 'quotes/unassigned/:openQuoteIncr?/:openTab?/:setEditMode?',
        name: 'Unassigned Quotes',
        component: QuoteList,
        meta: {
          role: ['pm', 'Q']
        },
        props: route => ({ openQuoteIncr: route.params.openQuoteIncr, openTab: route.params.openTab, setEditMode: route.params.setEditMode })
      },
      {
        path: 'my-quotes',
        name: 'My Quotes',
        component: QuoteList,
        meta: {
          role: ['pm', 'Q']
        }
      },
      {
        path: 'quote-reports',
        name: 'Quote Reports',
        component: QuoteReports,
        meta: {
          role: ['pm', 'R']
        }
      },
      {
        path: 'time-entry',
        name: 'Time Entry',
        component: TimeEntryList,
        props: route => ({ page: 'entry' })
      },
      {
        path: 'time-approval/:filterApproval?',
        name: 'Time Approval',
        component: TimeEntryList,
        props: route => ({ page: 'approval', filterApproval: route.params.filterApproval }),
        meta: {
          role: ['pm']
        }
      },
      {
        path: 'add-time-entry',
        name: 'Add Time Entry',
        component: AddTimeEntry
      },
      {
        path: 'expenses',
        name: 'Expenses',
        component: ExpensesList
      },
      {
        path: 'jobs/:stage?',
        name: 'Jobs',
        component: ListJobs,
        props: route => ({ filterAll: route.query.filterAll, stage: route.params.stage }),
        meta: {
          role: ['editor', 'F']
        }
      },
      {
        path: 'job-wo-report/:stage?',
        name: 'My Jobs WO',
        component: JobWOReport,
        props: route => ({ filterAll: route.query.filterAll, stage: route.params.stage, show: route.query.show }),
        meta: {
          role: ['pm']
        }
      },
      {
        path: 'job-details/:id',
        name: 'Job',
        component: JobDetails,
        meta: {
          role: ['editor', 'F']
        }
      },
      // {
      //   path: 'job-details/new',
      //   name: 'Job',
      //   component: JobDetails,
      //   props: route => ({ filterAll: route.query.filterAll, stage: route.params.stage })
      // },
      {
        path: 'wos',
        name: 'Work Orders',
        component: ListWOs,
        props: route => ({ filterAll: route.query.filterAll }),
        meta: {
          role: 'editor'
        }
      },
      {
        path: 'wo-details/:id',
        name: 'Work Order',
        component: WODetails,
        meta: {
          role: 'editor'
        }
      },
      {
        path: 'billing-list/:taskType?/:taskId?',
        name: 'Billing List',
        component: BillingList,
        meta: {
          role: 'pm'
        },
        props: route => ({ show: route.query.show })
      },
      {
        path: 'tasks/:taskType?/:taskId?',
        name: 'Billing Sub Tasks',
        component: BillingSubTasks,
        meta: {
          role: 'pm'
        },
        props: route => ({ show: route.query.show })
      },
      {
        path: 'materials',
        redirect: '/materials'
      },
      // {
      //   path: 'test',
      //   name: 'Test',
      //   component: Test
      // },
      {
        path: 'icons',
        name: 'Icons',
        component: Icons
      },
      {
        path: 'accounts-receivable/:customerCode?',
        name: 'AccountsReceivableList',
        component: AccountsReceivableList,
        meta: {
          role: 'admin'
        },
        props: route => ({ customerCode: route.params.customerCode })
      },
      {
        path: 'accounts-payable',
        name: 'AccountsPayableList',
        component: AccountsPayableList,
        meta: {
          role: 'admin'
        }
      },
      {
        path: 'po-report/:type/:itmCode',
        name: 'PO Report Summary',
        component: POReportSummary,
        props: route => ({ type: route.params.type, itmCode: route.params.itmCode })
      },
      {
        path: 'pos',
        name: 'POs',
        component: ListPOs
      },
      {
        path: 'aging',
        name: 'Aging',
        component: Aging,
        meta: {
          role: 'admin'
        }
      },
      {
        path: 'po-reports',
        name: 'Purchase Order Reports',
        component: POReports,
        meta: {
          role: 'admin'
        }
      }
    ] // end of app children
  },
  {
    path: '/materials',
    name: 'Materials Admin',
    component: MaterialsAdmin,
    meta: {
      role: ['pm', 'D'],
      requiresAuth: true
    }
  },
  {
    path: '/equipment',
    name: 'Equipment Admin',
    component: EquipmentAdmin,
    meta: {
      role: 'pm',
      requiresAuth: true
    }
  },
  {
    path: '/other-charges',
    name: 'Other Charges Admin',
    component: OtherChargesAdmin,
    meta: {
      role: 'pm',
      requiresAuth: true
    }
  },
  {
    path: '/materials-admin-one-time',
    name: 'Materials Admin One Time',
    component: MaterialsAdminOneTime,
    meta: {
      role: ['pm', 'D'],
      requiresAuth: true
    }
  },
  {
    path: '/quote-write-up/:quoteMaterialListId?',
    name: 'Quote Write Up',
    component: QuoteWriteUpPage,
    meta: {
      role: ['pm', 'Q']
    },
    props: route => ({ quoteMaterialListId: route.params.quoteMaterialListId })
  },
  {
    path: '/rfps',
    name: 'RFP List',
    component: RFPListPage,
    meta: {
      role: ['pm', 'Q'],
      requiresAuth: true
    },
    props: route => ({ listType: route.params.listType })
  },
  {
    path: '/assemblies',
    name: 'BOMs',
    component: MaterialGroupsListPage,
    meta: {
      role: ['pm', 'Q'],
      requiresAuth: true
    },
    props: route => ({ listType: 'assembly' })
  },
  {
    path: '/material-lists',
    name: 'Material Lists',
    component: MaterialGroupsListPage,
    meta: {
      role: ['pm', 'Q'],
      requiresAuth: true
    },
    props: route => ({ listType: 'list' })
  },
  {
    path: '/customer-ticket/wo/:authCode',
    component: CustomerWOTicket,
    props: route => ({ authCode: route.params.authCode })
  },
  {
    path: '/customer-ticket/job/:authCode',
    component: CustomerJOBTicket,
    props: route => ({ authCode: route.params.authCode })
  },
  {
    path: '/wo-signoff/:authCode',
    name: 'wo-signoff',
    component: WOSignOffTemplate,
    props: route => ({ authCode: route.params.authCode, pdf: route.query.pdf })
  },
  {
    path: '/job-signoff/:authCode',
    name: 'job-signoff',
    component: JobSignOffTemplate,
    props: route => ({ authCode: route.params.authCode, pdf: route.query.pdf })
  },
  {
    path: '/job-extra-signoff/:authCode',
    name: 'job-extra-signoff',
    component: JobExtraSignOffTemplate,
    props: route => ({ authCode: route.params.authCode, pdf: route.query.pdf })
  },
  {
    path: '/po-report/:authCode',
    name: 'Purchase Order Report',
    component: PurchaseOrderReportTemplate,
    props: route => ({ authCode: route.params.authCode, pdf: route.query.pdf })
  },
  {
    path: '/ar-statement/:authCode',
    name: 'Accounts Receivable Statement',
    component: AccountsReceivableStatement,
    props: route => ({ authCode: route.params.authCode, pdf: route.query.pdf, manualSend: route.query.manualSend })
  },
  {
    path: '/customer-quote/:authCode',
    name: 'customer-quote',
    component: CustomerQuoteTemplate,
    props: route => ({ authCode: route.params.authCode, pdf: route.query.pdf, showPrice: route.query.showPrice })
  },
  {
    path: '/po-template/:authCode',
    name: 'po-template',
    component: POTemplate,
    props: route => ({ authCode: route.params.authCode, pdf: route.query.pdf })
  },
  {
    path: '/job-wo-report-template/:authCode',
    name: 'job-po-report-template',
    component: JobWOReportTemplate,
    props: route => ({ authCode: route.params.authCode, pdf: route.query.pdf })
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
    meta: {
      role: 'admin',
      requiresAuth: true
    }
  },
  {
    path: '/add-expense',
    name: 'Add Expenses',
    component: AddExpense
  },
  { path: '*', component: NotFound }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
 function view(name) {
 var res= require('../components/Dashboard/Views/' + name + '.vue');
 return res;
 };**/

export default routes
