<template>
  <div class="wo-info">
    <div v-if="!woError">
      <div :class="['tab-section', 'white', { outline: isOutlined }]">
        <ul class="nav nav-tabs">
          <li role="presentation" :class="[{ active: tab == 'Scope of Work' }, { 'requires-item': !checkMetWoRequirement('wo_work_ordered_notes') }]">
            <a href="#" @click="tab = 'Scope of Work'"> Scope of Work </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Internal Notes' }, { 'requires-item': !checkMetWoRequirement('wo_internal_notes') }]">
            <a href="#" @click="tab = 'Internal Notes'"> Internal Notes </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Customer Info' }]">
            <a href="#" @click="tab = 'Customer Info'"> Customer Info </a>
          </li>
          <!-- <li role="presentation" :class="[{active: tab == 'Scope Notes'}]">
            <a href="#" @click="tab = 'Scope Notes'">
              Scope Notes
            </a>
          </li> -->
          <li role="presentation" :class="[{ active: tab == 'Time Entry Notes' }]">
            <a href="#" @click="tab = 'Time Entry Notes'"> Time Entry Notes </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Work Completed' }, { 'requires-item': !checkMetWoRequirement('wo_completed_notes') }]">
            <a href="#" @click="tab = 'Work Completed'"> Work Completed </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Materials' }]">
            <a href="#" @click="tab = 'Materials'"> Materials </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Work Order Extras' }, { 'requires-item': !checkMetWoRequirement('wo_extras_notes') }]">
            <a href="#" @click="tab = 'Work Order Extras'"> Work Order Extras </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Recommendations' }, { 'requires-item': !checkMetWoRequirement('wo_recommendation_notes') }]">
            <a href="#" @click="tab = 'Recommendations'"> Recommendations </a>
          </li>
        </ul>

        <div :class="[{ 'first-active': tab == 'Scope of Work' }, 'tab', tab, 'tab-content']">
          <div v-show="tab === 'Scope of Work'">
            <item-comments
              :table="'WOWorkOrderedNotes'"
              :item-id="this.woid"
              :label-text="''"
              :spectrum-single-note="false"
              :post-spectrum-name="'WORK ORDERED'"
              :post-spectrum-type="'wo'"
              :read-only="readOnly"
              @updated="getWoRequirementItems('refresh')"
              :Xrequire-detailed-notes="checkIsWOItemRequired('wo_work_ordered_notes')"
            />
          </div>
          <div v-show="tab == 'Internal Notes'">
            <h5>
              <strong><i>Internal notes aren't shown to customer.</i></strong>
            </h5>
            <br/>
            <item-comments
              :table="'WOInternalNotes'"
              :item-id="this.woid"
              :label-text="''"
              :spectrum-single-note="false"
              :post-spectrum-name="'Internal (SES Only)'"
              :post-spectrum-type="'wo'"
              :read-only="readOnly"
              @updated="getWoRequirementItems('refresh')"
              :Xrequire-detailed-notes="checkIsWOItemRequired('wo_internal_notes')"
            />
          </div>
          <div v-show="tab === 'Customer Info'">
            <item-comments
              label-text="Warnings"
              :table="'CustomerWarnings'"
              :item-id="customer_id"
              v-if="customer_id != ''"
              :show-extra-id-only="false"
              itm-type="Job"
              :single-note="true"
              :read-only="true"
            />
          </div>
          <div v-show="tab === 'Scope Notes'">
            <item-comments
              :table="'WOWorkOrderNotes'"
              :item-id="this.woid"
              :label-text="''"
              :spectrum-single-note="false"
              :post-spectrum-name="'WORK ORDER NOTES'"
              :post-spectrum-type="'wo'"
              :read-only="readOnly"
              @updated="getWoRequirementItems('refresh')"
              :Xrequire-detailed-notes="checkIsWOItemRequired('wo_work_order_notes')"
            />
          </div>
          <div v-show="tab === 'Work Order Extras'">
            <item-comments
              :table="'WOOrderedExtrasNotes'"
              :item-id="this.woid"
              :label-text="''"
              :spectrum-single-note="false"
              :post-spectrum-name="'WORK ORDER EXTRAS'"
              :post-spectrum-type="'wo'"
              :read-only="readOnly"
              @updated="getWoRequirementItems('refresh')"
              :Xrequire-detailed-notes="checkIsWOItemRequired('wo_extras_notes')"
            />
          </div>
          <div v-show="tab === 'Materials'">
            <item-comments
              :table="'WOMaterials'"
              :item-id="this.woid"
              :label-text="''"
              :spectrum-single-note="false"
              :post-spectrum-name="'MATERIALS'"
              :post-spectrum-type="'wo'"
              :read-only="readOnly"
            />
          </div>
          <div v-show="tab === 'Time Entry Notes'">
            <label>Notes</label>
            <div v-if="timeCardEntries.length && timeCardEntries[0].message" class="note-container">
              <ul :class="['existing-notes', 'show-scrollbar']">
                <li v-for="(note, id) in timeCardEntries" :key="id">
                  <div v-if="note.message">
                    <p class="note-meta">
                      <span class="note-date" v-if="note.entry_date" v-html="note.entry_date"/> -
                      <span class="note-author" v-if="note.employee_name" v-html="note.employee_name"/>
                    </p>
                    <p class="note-text" v-html="note.message"/>
                  </div>
                </li>
              </ul>
            </div>
            <p v-else>(Empty)</p>
          </div>
          <div v-show="tab === 'Work Completed'">
            <item-comments
              :table="'WOCompletedNotes'"
              :item-id="this.woid"
              :label-text="''"
              :spectrum-single-note="false"
              :post-spectrum-name="'WORK COMPLETED'"
              :post-spectrum-type="'wo'"
              :read-only="readOnly"
              @updated="getWoRequirementItems('refresh')"
              :Xrequire-detailed-notes="checkIsWOItemRequired('wo_completed_notes')"
            />
          </div>
          <div v-show="tab === 'Recommendations'">
            <item-comments
              :table="'WORecommendationsNotes'"
              :item-id="this.woid"
              :label-text="''"
              :spectrum-single-note="false"
              :post-spectrum-name="'RECOMMENDATIONS'"
              :post-spectrum-type="'wo'"
              :read-only="readOnly"
              @updated="getWoRequirementItems('refresh')"
              :Xrequire-detailed-notes="checkIsWOItemRequired('wo_recommendation_notes')"
            />
          </div>
        </div>
      </div>
      <br/>
      <button v-if="inSequence" class="btn" @click="next" v-html="'Next'"/>
    </div>
    <p v-else>Problem loading WO Data, please close and continue from WO Details pages.</p>
  </div>
</template>
<script>
import ItemComments from 'components/GeneralViews/ItemCommentsWYSIWYG'
import NavTabs from 'mixins/navTabs'
import WOFuncs from 'mixins/WOFuncs'
import appFuncs from 'appFuncs'
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      woError: false,
      tab: 'Scope of Work',
      timeCardEntries: [],
      customer_id: ''
    }
  },

  props: {
    woid: {
      type: [Number, String],
      required: true
    },
    inSequence: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isOutlined: {
      type: Boolean,
      default: true
    },
    wo: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['employees'])
  },

  components: {
    ItemComments
  },

  mixins: [NavTabs, WOFuncs],

  methods: {
    next() {
      this.$bus.$emit('modalClose', 'wo-info')
      this.$bus.$emit('modalOpen', {
        component: 'wo-contacts',
        size: 'modal-full',
        name: 'wo-contacts',
        title: 'Set Contact Options',
        classes: 'gray-bg',
        componentData: {
          component: 'wo-contacts',
          woid: this.woid,
          inSequence: true
        }
      })
    },

    getTimeCardEntries() {
      var data = {
        action: 'get_timecard_entries',
        type: 'WO',
        number: this.woid,
        approval_status: 'all'
      }

      this.$store.commit('waiting', {status: 1, message: 'Getting Time Card Entries'})
      appFuncs.ajax_request(this.$store.getters.sherAuth, data, res => {
        if (res.status === 'success') {
          this.timeCardEntries = res.data
          if (this.employees.length) {
            for (let i = 0; i < res.data.length; i++) {
              let emp = this.employees.find(itm => {
                return itm.Employee_Code == res.data[i].employee_code
              })
              if (emp) {
                res.data[i].employee_name = emp.Employee_Name
              }
            }
          }
        } else {
          this.$snack.open('Problem getting timecard entries', 'error')
        }
        this.$store.commit('waiting', {status: 0})
      })
    }
  },

  mounted() {
    if (!this.woid) {
      this.woError = true
    }

    this.$store.dispatch('getEmployees').then(() => {
      this.getTimeCardEntries()
    })

    this.customer_id = this.wo.customerCode

    if (appFuncs.isEmpty(this.woRequirementItems)) {
      this.getWoRequirementItems().then(() => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.wo-info {
  .tab-content {
    min-height: 300px;
    margin-bottom: 0 !important;
  }

  .note-container {
    ul {
      list-style: none;
      padding: 10px 0;
    }

    .note-text {
      white-space: pre-wrap;
      border-left: 4px solid #d2d2d2;
      padding-left: 10px;
      font-size: 14px;
    }

    .note-author {
      font-weight: bold;
    }

    .note-date {
      font-weight: bold;
    }
  }
}
</style>
