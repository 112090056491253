<template>
  <div class="form-group" style="max-width: 300px">
    <label>View as Employee</label>
    <select-field
      :options="employeeOptions"
      :option-display-keys="['Employee_Name']"
      :option-val="'Employee_Code'"
      v-model="employee"
      @selectItem="selectEmployee"
      :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
      :allow-clear="isAdmin"
    />
  </div>
</template>

<script>
import store from 'store'
import { mapGetters } from 'vuex'
import SelectField from 'components/UIComponents/SelectField'

export default {
  data() {
    return {
      employee: ''
    }
  },

  components: {
    SelectField
  },

  computed: {
    ...mapGetters(['employees', 'userAuthedData', 'selectedDepartment']),

    isAdmin() {
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    },

    employeeOptions() {
      if (this.isAdmin && !this.selectedDepartment) return this.employees

      const employee = this.employees.find(emp => emp.Employee_Code == this.userAuthedData.eid)
      const employeeEmCostGroups = employee?.em_cost_groups ? employee.em_cost_groups : []

      const filteredEmployees = this.employees.filter(itm => {
        // if department is selected, employees who has access in selected department
        if (this.selectedDepartment) return itm.em_cost_groups ? itm.em_cost_groups.includes(this.selectedDepartment) : false

        // if department is not selected,  employees who has access in departments of the auth user
        return itm.em_cost_groups ? itm.em_cost_groups.some(_ => employeeEmCostGroups.includes(_)) : false
      })
      return filteredEmployees
    },

    tableSettings() {
      return {
        tableData: this.myWOs,
        fields: {
          WO_Number: {
            name: 'WO Number',
            class: 'narrow bold'
          },
          customer_name: {
            name: 'Customer Name'
          },
          Summary_Description: {
            name: 'Description',
            wide: 1
          },
          Priority_Desc: {
            name: 'Stage',
            noFilter: 1
          }
        },
        hideAllSearch: true,
        hideTip: true,
        tableLayout: 'auto'
      }
    }
  },

  methods: {
    selectEmployee(val) {
      this.$bus.$emit('setViewAsEmployeeId', val)
      this.$store.commit('viewAsEmployeeId', val)
    }
  },

  async mounted() {
    await this.$store.dispatch('getEmployees')
    this.employee = this.$store.getters.viewAsEmployeeId || this.$store.getters.userAuthedData.eid || ''
    this.selectEmployee(this.employee)
  },

  watch: {
    '$store.getters.viewAsEmployeeId': {
      handler(val) {
        this.employee = val
        this.selectEmployee(val)
      },
      immediate: false
    },
    employeeOptions: {
      handler(val) {
        if (val.length) {
          // when change the department and selected user doesn't have access in selected department, select first user
          // if (!val.map(_ => _.Employee_Code).includes(this.employee)) this.selectEmployee(val[0].Employee_Code)
        }
      }
    }
  },

  beforeDestroy() {
    this.$bus.$off('setViewAsEmployeeId')
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

h3 {
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;

  i {
    color: $primary-color;
    float: right;
  }
}

.content-inner {
  overflow: scroll !important;
  max-height: 300px;
  // max-width:600px;
}
</style>
