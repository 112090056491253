
<div class="wo-contacts">
  <div v-if="!jobError">
    <h5>Contacts Assigned to Job</h5>

    <bh-table :table-settings="contactsAssigned" @item-checked="itemChecked" @edit-item="editContact" />

    <hr />
    <br />

    <h5>All Contacts <span class="fa fa-plus-circle is-link" @click="editContact()" /></h5>

    <bh-table :table-settings="contactsAll" @item-checked="itemChecked" @edit-item="editContact" />

    <br />
  </div>
  <p v-else>Problem loading Job Data, please close and continue from Job Details pages.</p>

  <waiting-spinner :container="true" />
</div>
