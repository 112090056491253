<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <h4>Edit Material Category</h4>

        <div class="form-group">
          <label>Category Name</label>
          <input type="text" class="form-control" v-model="category.name"/>
        </div>

        <div class="form-group">
          <label>Category Parent</label>
          <input type="text" class="form-control fake-disabled" v-model="currentParentName" readonly @click="editParentCategory"/>
        </div>

        <div v-if="objectType !== 'material'" class="form-group">
          <label>Cost Type</label>
          <input type="text" class="form-control" v-model="category.cost_type"/>
        </div>

        <button class="btn" @click="update" v-html="'Update'"/>

        <button class="btn" @click="deleteCategory" v-html="'Delete'"/>
      </div>
    </div>
    <waiting-spinner :wait="wait"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import appFuncs from 'appFuncs'
import MaterialsChangeCategory from 'components/Dashboard/Materials/MaterialsChangeCategory'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'MaterialsCategoryCategoryEdit',
  components: {WaitingSpinner},
  data() {
    return {
      wait: {
        message: ''
      },
      categoriesAll: [],
      category: {}
    }
  },

  props: {
    categoryId: {
      type: [Number, String],
      required: true
    },
    objectType: {
      type: String,
      default: 'Materials'
    }
  },

  computed: {
    ...mapGetters(['materialCategories']),

    currentParentName() {
      let parent = this.materialCategories.find(itm => {
        return itm.id == this.category.parent
      })
      if (parent) {
        return parent.name
      }
      return 'None'
    }
  },

  methods: {
    update() {
      if (!this.category.name) {
        this.$snack.open('Category name required.', 'warning')
        return
      }

      if (this.category.parent == this.category.id) {
        this.$snack.open('Cannot set self as category parent.', 'warning')
        return
      }

      const params = {
        action: 'upsert_material_category',
        id: this.category.id,
        data: {
          name: this.category.name,
          parent: this.category.parent,
          cost_type: this.category.cost_type
        }
      }
      this.wait.message = 'Updating Material Categories'
      appFuncs
        .shRequest(params)
        .then(data => {
          this.$snack.open('Categories Updated')
          this.$store.dispatch('getMaterialCategories', 'refresh')
          this.$bus.$emit('modalClose', 'MaterialsCategoryCategoryEdit')
        })
        .catch(res => {
          console.log(res)
          this.$snack.open(res.message || 'Problem updating categories', 'error')
        })
        .finally(() => {
          this.wait.message = ''
        })
    },

    getCategoryName(id) {
      let parent = this.materialCategories.find(itm => {
        return itm.id == id
      })
      if (parent) {
        return parent.name
      }
      return ''
    },

    editParentCategory() {
      this.$Modal({
        parent: this,
        name: 'MaterialsChangeCategory', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        component: MaterialsChangeCategory,
        props: {
          categoryId: this.category.parent
        }
      })
    },

    deleteCategory() {
      if (!confirm("Are you sure you'd like to permanently delete this category?")) {
        return
      }
      const params = {
        action: 'delete_material_category',
        categoryId: this.category.id
      }
      appFuncs
        .shRequest(params)
        .then(data => {
          this.$snack.open('Category deleted')
          this.$store.dispatch('getMaterialCategories', 'refresh')
          this.$bus.$emit('modalClose', 'MaterialsCategoryCategoryEdit')
        })
        .catch(data => {
          console.log(data)
          this.$snack.open(data.message || 'Problem deleting material category.', 'warning')
        })
    }
  },
  mounted() {
    this.$bus.$on('changeMaterialCategory', parentId => {
      this.category.parent = parentId
    })
  },

  watch: {
    categoryId: {
      handler(id) {
        let category = this.materialCategories.find(itm => {
          return itm.id == id
        })
        if (category) {
          this.category = category
        } else {
          this.$snack.open('Problem getting category data', 'error')
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

h4 {
  margin: 10px 0;
}
</style>
