import Vue from 'vue'
import Vuex from 'vuex'
import bugsnag from '@bugsnag/js'
import bugsnagVue from '@bugsnag/plugin-vue'
import vClickOutside from 'v-click-outside'

// Plugins
import GlobalComponents from './gloablComponents'
import SideBar from './components/UIComponents/SidebarPlugin'
import App from './App'
import CommonFuncs from './mixins/commonFuncs'

let env = process.env.NODE_ENV
if (env === 'production') {
  const bugsnagClient = bugsnag('f3ae3c5aa22b868ed30dd1316a3c7451')
  bugsnagClient.use(bugsnagVue, Vue)
  Vue.prototype.$Bugsnag = bugsnagClient
}

import router from './router'
import Modal from './components/UIComponents/Modal2'
import '../static/css/themify-icons.css'

import $ from 'jquery'
window.$ = $

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

Vue.component('Modal', Modal)

const ModalComponent = Vue.extend(Modal)
Vue.prototype.$Modal = obj => {
  const instance = new ModalComponent({
    parent: obj.parent,
    propsData: obj
  })
  instance.$mount()
  instance.$on('destroy', () => {
    instance.$destroy()
  })
  document.body.appendChild(instance.$el)
}

// NEW custom Snackbar - based on Buefy but with better handling for duplicates and closing all
import snack from './components/UIComponents/Snackbar/'

// vuex setup
import store from 'store'

// library imports
import 'bootstrap/dist/css/bootstrap.css'
import './assets/sass/paper-dashboard.scss'
import EventBus from 'eventbus'

import '@fortawesome/fontawesome-free/css/all.css'

import './assets/css/icomoon.css'

import appFuncs from 'appFuncs'

Vue.mixin(CommonFuncs)

// setup Tocca with custom double tap threshold as constant
// var DBL_TAP_THRESHOLD = 400
// import Tocca from 'tocca'
// Vue.use(Tocca)

// format phone numbers
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

// plugin setup
Vue.use(Vuex)
Vue.use(GlobalComponents)
Vue.use(vClickOutside)
Vue.use(SideBar)

import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

// if (env === 'production' && 'serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker
//       .register('/sw.js')
//       .then(registration => {
//         //console.log('SW registered: ', registration);
//       })
//       .catch(registrationError => {
//         // console.log('SW registration failed: ', registrationError);
//       })
//   })
// }

// do logic to get user auth data and direct to correct page
router.beforeEach((to, from, next) => {
  snack.removeAll()
  store.commit('path', next)
  EventBus.$emit('navigated')
  store.dispatch('setUserAuthedData')
  store.dispatch('setSherAuth')
  store.dispatch('checkAppVersion')

  let sherAuth = store.getters.sherAuth
  let userAuthedData = store.getters.userAuthedData

  // simplest auth bypass
  let unAuthPageNames = ['login', 'wo-signoff', 'job-signoff']
  if (!sherAuth || !sherAuth.eid || !userAuthedData || !userAuthedData.eid) {
    if (unAuthPageNames.indexOf(to.name) !== -1) {
      next()
      return
    }
  }

  let now = new Date().getTime() / 1000
  let expired = !sherAuth || !sherAuth.eid || sherAuth.expires < now

  // add detect online
  let isOnline = true
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  //debug expire problems
  // console.groupCollapsed('auth details');
  // console.log(sherAuth);
  // console.log('now:     ' + now);
  // console.log('expires: ' + sherAuth.expires);
  // console.log('expires > now: ' + (sherAuth.expires > now));
  // console.log(sherAuth.eid);
  // console.log('expired: ' + expired);
  // console.log(sherAuth.eid);
  // console.log(userAuthedData.eid);
  // console.groupEnd();

  // IF MESSING WITH THIS TEST SIGNOFFS LOGGED OUT
  if (requiresAuth && (!sherAuth.eid || !userAuthedData.eid || (expired && isOnline))) {
    snack.removeAll()
    snack.open('Auth required, please login again.', 'error', null, null, 100) // 100 priority value
    appFuncs.storageClear('sherAuth')
    appFuncs.storageClear('userAuthedData')
    localStorage.setItem('origin', to.path)
    next('/login')
    return
  }

  EventBus.$emit('checkUnsavedChanges')
  if (store.getters.unsavedData) {
    if (!confirm('Unsaved changes detected, are you sure you want to leave this page?')) {
      return
    }
    store.dispatch('unsavedDataClear')
    EventBus.$emit('refreshWODetailsBase')
  }

  // check page user role requirements
  let pageRole = to.meta.role
  if (requiresAuth && pageRole) {
    // Convert pageRole to an array if it's not already one
    if (!Array.isArray(pageRole)) {
      pageRole = [pageRole]
    }

    const authRoles = userAuthedData.user_role.concat(userAuthedData.employee_role)

    // Check if user_role contains all roles in pageRole
    const hasAllRoles = pageRole.every(role => authRoles && authRoles.indexOf(role) !== -1)

    const isAdmin = userAuthedData.user_role.indexOf('admin') === -1 ? false : true

    if (!hasAllRoles && !isAdmin) {
      next('/disallowed')
      return
    }
  }

  // if logged in (just logged in) and has path saved go to that page
  if (to.name === 'login' && userAuthedData.eid) {
    let path = localStorage.getItem('origin')
    if (path) {
      next(path)
      localStorage.removeItem('origin')
      return
    }
  }

  store.dispatch('getAppData')

  next()
})

// import bootstrap from bootstrap

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,

  data() {
    return {
      loginExpiry: null,
      expiresWarning: 60 * 1, // warning time before expiry
      inactivityTimer: null
    }
  },

  computed: {
    sherAuth() {
      return store.getters.sherAuth
    },

    userAuthedData() {
      return store.getters.userAuthedData
    },

    path() {
      return store.getters.path
    }
  },

  methods: {
    checkUserTasks() {
      this.resetDefaultPassword()
    },

    resetDefaultPassword() {
      if (typeof this.sherAuth.pw !== 'undefined' && this.sherAuth.pw === 'reset') {
        var modalObj = {
          trigger: 1,
          hideClose: 1,
          title: 'Reset Password',
          content: 'You have a provided password that must be reset',
          component: () => import('src/components/GeneralViews/ResetPassword')
        }
        store.commit('modal', modalObj)
      }
    },

    getLoginExpiry() {
      var expires = this.sherAuth.expires
      if (!expires) {
        return
      }

      let requiresAuth = this.$route.matched.some(record => record.meta.requiresAuth)
      var date = new Date()
      var unixSeconds = date.getTime() / 1000
      var expiresIn = expires - unixSeconds
      if (expiresIn < 0) {
        this.$store.dispatch('clearLogin')
        if (!requiresAuth) return
        this.$snack.open('Login has expired, please log in again to continue', 'error', this.sendToLogin, 'LOG IN', 100) // 100 priority value
        // clearInterval(this.loginExpiry);
      } else if (requiresAuth && expiresIn < this.expiresWarning) {
        this.$snack.open('Approaching idle activity login expiry.', 'error', this.refreshUserAuth, 'REFRESH', 90) // 90 priority value
      }
      return expiresIn
    },

    sendToLogin() {
      snack.removeAll()
      router.push({ path: '/login' })
    },

    refreshUserAuth() {
      // trigger auth update on nav if about to expire
      var expiresIn = this.getLoginExpiry()
      if (this.userAuthedData && this.userAuthedData.eid && expiresIn < this.expiresWarning) {
        snack.removeAll()
        appFuncs.get_authed_userdata()
        // snack.open('Login Refreshed');
      }
    },

    navUpdates() {
      this.refreshUserAuth()
      this.checkUserTasks()
    },

    enableDisableButtonsAuth() {
      // if logged out and on auth req'd page, disable any buttons
      let requiresAuth = this.$route.matched.some(record => record.meta.requiresAuth)
      let buttons = document.getElementsByClassName('btn')
      for (var i = buttons.length - 1; i >= 0; i--) {
        if (requiresAuth && (!this.userAuthedData || !this.userAuthedData.eid)) {
          buttons[i].setAttribute('disabled', true)
          buttons[i].setAttribute('data-expire-disabled', true)
        } else if (buttons[i].hasAttribute('data-expire-disabled')) {
          buttons[i].removeAttribute('disabled')
          buttons[i].removeAttribute('data-expire-disabled')
        }
      }
    },

    setInactivity() {
      clearTimeout(this.inactivityTimer)
      this.inactivityTimer = setTimeout(() => {
        this.$bus.$emit('userInactive')
        this.setInactivity()
      }, 60 * 1000)
    },

    setEditorEvents() {
      window.tinymce.on('AddEditor', function (e) {
        console.log('Added editor with id: ' + e.editor.id)
        EventBus.$emit('editorAdded', e.editor)
      })
    }
  },

  watch: {
    userAuthedData(newUad) {
      if (newUad && newUad.eid) {
        if (typeof this.$Bugsnag === 'function') {
          this.$Bugsnag.user = store.getters.userAuthedData
        }
      } else {
        // console.log('logged out');
      }

      this.enableDisableButtonsAuth(newUad)
    }
  },

  mounted() {
    var self = this
    this.getLoginExpiry()
    clearInterval(this.loginExpiry)
    this.loginExpiry = setInterval(this.getLoginExpiry, 1000 * 4)

    appFuncs.get_authed_userdata()

    this.$bus.$on('navigated', data => {
      self.navUpdates()
    })

    self.navUpdates()

    $('body').on('click', '.refresh-auth', function (e) {
      e.preventDefault()
      self.refreshUserAuth()
    })
    $('body').on('click', '.login-link', function (e) {
      e.preventDefault()
      router.push('/login')
    })

    // stop click event if href = '#'
    $('body').on('click', 'a', function (e) {
      if (this.getAttribute('href') == '#') {
        e.preventDefault()
      }
    })

    // should get rid of this and set beforeunload listeners on each component
    $(window).on('beforeunload', function () {
      EventBus.$emit('checkUnsavedChanges')
      if (store.getters.unsavedData) {
        return 'Unsaved changes detected, are you sure you want to leave this page?'
      }
    })

    this.setInactivity()
    document.onmousemove = () => {
      this.setInactivity()
    }
    document.onkeydown = () => {
      this.setInactivity()
    }
    document.onmousedown = () => {
      this.setInactivity()
    }
    document.onkeydown = () => {
      this.setInactivity()
    }
    document.ontouchstart = () => {
      this.setInactivity()
    }
    document.onclick = () => {
      this.setInactivity()
    }

    const script = document.createElement('script')
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/tinymce/4.9.11/tinymce.min.js'
    script.async = true
    script.onload = () => {
      this.setEditorEvents()
    }
    script.onerror = () => {
      this.$snack.open('Problem loading table editor', 'error')
    }

    document.head.appendChild(script)

    if ('caches' in window) {
      console.log('do cache clear')
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name)
          console.log('deleted cache: ' + name)
        })
      })
    }

    // Add service worker deletion code here
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        registrations.forEach(function (registration) {
          registration.unregister()
          console.log('Service Worker unregistered:', registration)
        })
      })
    }
  },

  updated() {
    this.enableDisableButtonsAuth()
  }
})
