<template>
  <div>
    <div
      class="matches-container"
      v-if="matches.length"
    >
      <p><strong>Existing Matches</strong></p>
      <ul>
        <li
          v-for="(match, mi) in matches"
          :key="mi"
          @click="$bus.$emit('select-show-customer-contact', match.Id)"
        >
          <span
            v-if="match.FirstName"
            v-html="match.FirstName"
            class="is-link underlined"
          /> <span
          v-if="match.LastName"
          v-html="match.LastName"
          class="is-link underlined"
        /><span
          v-if="match.Email"
          v-html="', ' + match.Email"
        /><span
          v-if="match.Phone"
          v-html="', ' + match.Phone"
        /><span
          v-if="match.Title"
          v-html="' (' + match.Title + ')'"
        />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

import {mapGetters} from 'vuex';

export default {
  data() {
    return {};
  },

  props: {
    firstName: {
      type: String,
      default() {
        return '';
      }
    },
    lastName: {
      type: String,
      default() {
        return '';
      }
    },
    email: {
      type: String,
      default() {
        return '';
      }
    },
    phoneDirect: {
      type: String,
      default() {
        return '';
      }
    },
    phoneMobile: {
      type: String,
      default() {
        return '';
      }
    },
    currentlySelectedId: {
      type: String,
      default() {
        return '';
      }
    }
  },

  computed: {
    ...mapGetters([
      'customerContacts'
    ]),

    matches() {
      let inputFirstName = this.firstName ? this.firstName.toString().toLowerCase().trim() : '';
      let inputLastName = this.lastName ? this.lastName.toString().toLowerCase().trim() : '';
      let inputEmail = this.email ? this.email.toString().toLowerCase().trim() : '';
      let inputPhoneDirect = this.phoneDirect ? this.phoneDirect.toString().toLowerCase().trim() : '';
      let inputPhoneMobile = this.phoneMobile ? this.phoneMobile.toString().toLowerCase().trim() : '';

      let ccs = JSON.parse(JSON.stringify(this.customerContacts));
      let matches = [];
      for (var i = 0; i < ccs.length; i++) {

        // dont add currently selected
        if (this.currentlySelectedId && ccs[i].Id === this.currentlySelectedId) continue;

        if (inputFirstName && inputLastName) {
          const firstName = ccs[i].FirstName ? ccs[i].FirstName.toLowerCase().trim() : '';
          const lastName = ccs[i].LastName ? ccs[i].LastName.toLowerCase().trim() : '';
          if (inputFirstName && inputLastName && (firstName === inputFirstName) && (lastName === inputLastName)) {
            matches.push(ccs[i]);
            continue;
          }
        }
        if (inputEmail) {
          const email = ccs[i].Email ? ccs[i].Email.toLowerCase().trim() : '';
          if (email && email === inputEmail) {
            matches.push(ccs[i]);
            continue;
          }
        }
        if (inputPhoneDirect) {
          const phoneDirect = ccs[i].Phone ? ccs[i].Phone.toLowerCase().trim() : '';
          if (phoneDirect && phoneDirect === inputPhoneDirect) {
            matches.push(ccs[i]);
            continue;
          }
        }
        if (inputPhoneMobile) {
          const phoneMobile = ccs[i].MobilePhone ? ccs[i].MobilePhone.toLowerCase().trim() : '';
          if (phoneMobile && phoneMobile === inputPhoneMobile) {
            matches.push(ccs[i]);
            continue;
          }
        }
      }
      return matches;
    }
  },

  mounted() {
    this.$bus.$emit('setWaiting', {name: 'getCustomerContacts', message: 'Getting Contacts'});
    this.$store.dispatch('getCustomerContacts').finally(() => {
      this.$bus.$emit('stopWaiting', 'getCustomerContacts');
    });
  }
};

</script>

<style lang="scss" scoped>
@import "src/assets/sass/paper/_variables.scss";

.matches-container {
  border: 2px dashed $medium-gray;
  padding: 15px 15px 10px;
  border-radius: $border-radius-small;
  margin-bottom: 20px;
  // background: $gray-input-bg;

  ul {
    // list-style-position: inside;
    padding: 0 20px;

    li::marker {
      margin: 0 !important;
    }
  }
}

</style>
