
<div v-if="spin" class="load-spinner-container not-pdf">
  <div>
    <div :class="['loader-overlay', { contained: contained }]">
      <div class="loading-spinner">
        <div class="loader loader2">
          <div>
            <div>
              <div>
                <div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span class="message">{{ spin }}</span>
      </div>
    </div>
  </div>
</div>
