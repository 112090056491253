
<div class="max-width-xlarge">
  <div class="row">
    <div class="col-md-3 col-lg-2" v-show="isAdmin">
      <set-view-as-employee />
    </div>
    <div class="col-md-3 col-lg-2">
      <select-department-as-employee :value="selectedDepartment" :is-show-label="true" :employee-id="viewAsEmployeeId" />
    </div>
  </div>

  <div>
    <widget-hours-tracker :employee-id="viewAsEmployeeId" :show-department-selector="true" />
    <widget-group-metrics />
  </div>

  <div class="row">
    <div class="col-md-12">
      <widget-hours-table />
    </div>
  </div>

  <!-- <div>
    <widget-quotes-by-department />
    <widget-billings-by-department />
  </div>

  <div>
    <widget-projects-by-department />
  </div> -->
</div>
