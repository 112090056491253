
<div class="file-upload-container">
  <div class="row">
    <div v-if="useSimpleUpload && !readonly" class="col-sm-12">
      <!-- <div class="btn btn-sm display-as-button" :id="dzId">Upload File</div> -->
      <button class="btn btn-sm" style="margin: 10px 0" @click="useSimpleUpload = false">Upload File</button>
    </div>
    <div v-else :class="fileIsSelected ? 'col-sm-12 col-md-8' : 'col-sm-12'">
      <label v-if="label || (existingFiles.length && !readonly)">{{ label }}</label>
      <span v-if="existingFiles && existingFiles.length >= 1 && !readonly">
        <a href="#" class="label-link ti-download" @click="triggerDownload()" v-if="!isUnauth" />
        <a v-if="hasViewable" href="#" class="label-link ti-eye" @click="showPictures" />
      </span>
      <div class="file-upload-inner">
        <div :id="dzId" class="dropzone" v-if="!readonly" />
        <ul v-if="existingFiles && existingFiles.length >= 1">
          <li v-for="(mainFile, mfk) in existingFiles" :key="mfk">
            <a v-if="mainFile && mainFile.a_attr && showPreview" href="javascript:void(0)" @click="selectFile(mainFile)">{{ mainFile.text }}</a>
            <a v-else-if="mainFile && mainFile.a_attr" :href="mainFile.a_attr.href" target="_blank">{{ mainFile.text }}</a>
            <span :class="['file-delete', showDeleteIcon ? 'show-delete-icon' : '']" v-if="allowDelete" @click="fileDelete(mainFile)">
              <i class="ti-close" />
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="file-viewer col-sm-12 col-md-4" v-if="fileIsSelected">
      <iframe v-if="selectedFile.type === 'application/pdf'" :src="selectedFile.url" width="100%" height="600" />
      <div v-else-if="fileErrorText" class="text-danger">
        {{ fileErrorText }}
      </div>
      <img v-else-if="selectedFile.type && selectedFile.type.startsWith('image/')" :src="selectedFile.url" alt="Selected Image" />
      <div v-else-if="selectedFile.type === 'text/csv'" v-html="csvContent" />
      <div class="file-viewer-links" v-if="selectedFile && selectedFile.url">
        <span class="is-link" @click="openInNewTab">
          <i class="ti-new-window" style="padding: 0 3px 3px 3px" />
        </span>
        <span class="is-link" @click="closePreview">
          <i class="ti-na" style="padding: 3px" />
        </span>
      </div>
    </div>
    <div v-html="downloadForm" />
  </div>
</div>
