
<div class="customer-ticket" id="customer-ticket">
  <div class="container">
    <div class="row header" v-if="job.Job_Number" id="pdfReady">
      <div class="col-xs-12 col-sm-4">
        <router-link to="/">
          <img :src="`${urls.frontend}static/img/${logoFile}`" />
        </router-link>
      </div>
      <div class="col-xs-12 col-sm-4">
        <p v-html="regionAddress" />
      </div>
      <div class="col-xs-12 col-sm-4 text-right">
        <!-- using woLoaded id for PDF generating start flag -->
        <h4>
          Job Number
          <br />
          <strong> #<span v-html="job.Job_Number" /> </strong>
        </h4>
      </div>
    </div>

    <div class="row wo-info" v-if="job.Job_Number">
      <div class="col-sm-12 col-md-6">
        <table class="wo-item-list border-bottom">
          <tr>
            <td class="col-medium">
              <strong>Customer Name</strong>
            </td>
            <td>
              <span v-html="job.Customer_Name" />
            </td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Job Description</strong>
            </td>
            <td>
              <span v-html="job.Job_Description" />
            </td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Site Address</strong>
            </td>
            <td>
              <span v-if="job.Address_1" v-html="job.Address_1" />
              <br />
              <span v-if="job.City" v-html="job.City" /><span v-if="job.State" v-html="', ' + job.State" /><span v-if="job.Zip_Code" v-html="', ' + job.Zip_Code" />
            </td>
          </tr>
        </table>
        <p class="safety-text">Safety First - Every Time - No Shortcuts</p>
      </div>

      <div class="col-sm-12 col-md-6">
        <table class="wo-item-list">
          <tr>
            <td class="col-medium">
              <strong>Date</strong>
            </td>
            <td>
              <span v-html="formatDate(signOffDate)" />
            </td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Quote Number</strong>
            </td>
            <td>
              <span v-html="job.Quote_Number" />
            </td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Customer PO</strong>
            </td>
            <td>
              <span v-html="job.Contract_Number" />
            </td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Requested By</strong>
            </td>
            <td>
              <span v-html="job.Owner_Name" />
            </td>
          </tr>
          <tr>
            <td class="col-medium">
              <strong>Serviced By</strong>
            </td>
            <td>
              <span v-html="job.Project_Manager_Name" />
            </td>
          </tr>
        </table>
      </div>
    </div>

    <section class="row" v-if="job_description_notes.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Scope of Work Notes</th>
          </tr>
          <tr v-for="(note, i) in job_description_notes" :key="i">
            <td class="col-medium">
              <span v-html="note.nice_date" />
            </td>
            <td v-html="getPlainText(note.note_text)" />
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="job_other_details_notes.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Scope Notes</th>
          </tr>
          <tr v-for="(note, i) in job_other_details_notes" :key="i">
            <td class="col-medium">
              <span v-html="note.nice_date" />
            </td>
            <td v-html="getPlainText(note.note_text)" />
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="job_completed_notes.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Job Completed Notes</th>
          </tr>
          <tr v-for="(note, i) in job_completed_notes" :key="i">
            <td class="col-medium">
              <span v-html="note.nice_date" />
            </td>
            <td v-html="getPlainText(note.note_text)" />
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="job_recommendations_notes.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Recommendations</th>
          </tr>
          <tr v-for="(note, i) in job_recommendations_notes" :key="i">
            <td class="col-medium">
              <span v-html="note.nice_date" />
            </td>
            <td v-html="getPlainText(note.note_text)" />
          </tr>
        </table>
      </div>
    </section>

    <section class="row" v-if="job_extras_notes.length">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Job Extras</th>
          </tr>
          <tr v-for="(note, i) in job_extras_notes" :key="i">
            <td class="col-medium">
              <span v-html="note.nice_date" />
            </td>
            <td v-html="getPlainText(note.note_text)" />
          </tr>
        </table>
      </div>
    </section>

    <!-- <section class="row" v-for="ts in 7">
      <div class="col-sm-12">
        <table class="wo-item-list">
          <tr>
            <th class="col-narrow">Date</th>
            <th>Space of notes to come</th>
          </tr>
          <tr v-for="row in Math.floor(Math.random() * Math.floor(30))">
            <td>2022-02-22</td>
            <td>This is a Twosday</td>
          </tr>
        </table>
      </div>
    </section> -->

    <section v-if="job.Job_Number">
      <table class="wo-item-list wo-checklist">
        <tr>
          <td><label>Complete</label></td>
          <td class="narrow">
            <i class="ti ti-check" />
          </td>
          <td><label>Quotation</label></td>
          <td class="narrow">
            <i class="ti ti-check" />
          </td>
          <td><label>Recommendation</label></td>
          <td class="narrow">
            <i class="ti ti-check" />
          </td>
          <td><label>Safety</label></td>
          <td class="narrow">
            <i class="ti ti-check" />
          </td>
        </tr>
      </table>
    </section>

    <section>
      <table class="wo-item-list border-bottom" v-if="job.Job_Number">
        <tr>
          <td>
            <p class="satisfaction-text">If for any reason our service did not exceed your expectations please contact us.</p>
          </td>
          <td>
            <div class="signature-display-container">
              <p><label>Customer Signature</label></p>
              <div v-if="job.Job_Number">
                <div class="existing-signature" v-if="existingSig">
                  <img :src="this.urls.mssql + 'job-signatures/' + existingSig" />
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </section>

    <div v-if="pdf" class="footer" style="margin-bottom: 20px">
      <div class="footer-inner">
        <div class="page-number">
          <p>Page 1 of 1</p>
        </div>
      </div>
    </div>

    <section class="not-pdf" v-if="!this.pdf">
      <div v-if="job.Job_Number">
        <div>
          <button v-if="!isLocked && job.Status_Code === 'A'" class="btn not-pdf" @click="showStartSign()" v-html="'Sign Off'" />
          <button v-if="!isLocked && sherAuth.eid && job.Status_Code === 'A'" class="btn not-pdf" @click="showSignOffSend()" v-html="'Email Sign Off Link'" />
          <button v-if="signed && isLocked" class="btn not-pdf" @click="downloadSigned()" v-html="'Download PDF'" />
          <button v-if="isAdmin && existingSig" class="btn not-pdf" @click="saveEmailPDF()" v-html="'PDF Re-Generate'" />
        </div>
      </div>
      <div v-if="!signed && job.Status_Code !== 'A'">
        <p><i>Please contact office to advise job status before signing off.</i></p>
      </div>
    </section>
  </div>

  <br />
  <br />
  <br />
  <br />

  <waiting-spinner :wait="wait" />
</div>
