
<div>
  <h3><span v-html="id ? 'Edit' : 'Add'" /> Vendor Contact</h3>
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <div class="form-group">
        <label>Company<span class="required-star">*</span></label>
        <select-field
          :options="vendors"
          :option-display-keys="['Vendor_Name']"
          :option-val="'Vendor_Code'"
          v-model="contact.Vendor_Code"
          placeholder-text="Select a Vendor"
          :filter-on-key-val="{ key: 'Status', val: 'A' }"
        />
      </div>
      <div class="form-group">
        <label>Address</label>
        <p v-html="vendor.Address_1 || '(empty)'" />
      </div>
      <div class="form-group">
        <label>First Name</label>
        <input type="text" class="form-control" v-model="contact.First_Name" />
      </div>
      <div class="form-group">
        <label>Last Name</label>
        <input type="text" class="form-control" v-model="contact.Last_Name" />
      </div>
      <div class="form-group">
        <label>Title</label>
        <input type="text" class="form-control" v-model="contact.Title" />
      </div>
    </div>

    <div class="col-md-6 col-sm-12">
      <div class="form-group">
        <label>Direct Line</label>
        <input type="text" class="form-control" v-model="contact.Direct_Line" v-mask="chooseMask(contact.Direct_Line)" />
      </div>
      <div class="form-group">
        <label>Extension</label>
        <input type="number" class="form-control" v-model="contact.Extension" />
      </div>
      <div class="form-group">
        <label>Mobile</label>
        <input type="text" class="form-control" v-model="contact.Mobile" v-mask="chooseMask(contact.Mobile)" />
      </div>
      <div class="form-group">
        <label>Email</label>
        <input type="text" class="form-control" v-model="contact.Email" />
      </div>
      <div class="form-group">
        <label>Attributes</label>
        <input type="text" class="form-control" v-model="contact.Attributes" />
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group">
        <label>Notes</label>
        <textarea type="text" class="form-control" v-model="contact.Notes" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <checkbox v-model="contact.AP"> Accounts Payable Contact</checkbox>
      </div>
    </div>

    <div class="col-sm-6">
      <checkbox2 v-model="contact.Default_Purchase_Contact" :true-val="'yes'" :false-val="'no'"> Default Purchase Contact</checkbox2>
    </div>
  </div>

  <button class="btn" @click="addUpdate" v-html="id ? 'Update' : 'Add New'" />

  <button v-if="id" class="btn" @click="deleteContact" v-html="'Delete'" />

  <waiting-spinner :wait="wait" />
</div>
