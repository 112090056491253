
<div>
  <div class="medium" style="max-width:400px" v-show="!showExpenseDialog">
    <button type="button" @click="close()" class="close close-link" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="content">
      <div class="" style="padding:8px">
        <div class="upper">
          <h4 style="margin-top:15px;font-weight:normal">Add Expenses</h4>
        </div>
        <div class="add-fields">
          <div class="row">
            <div class="col-sm-12">

              <div class="form-group">
                <label>Added By</label><span class="required-star">*</span>
                <select-field-dynamic
                  :options="employees"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="formVals.added_by"
                  :item-name="'Employee'"
                  :label="'Added By'"
                  :filter-on-key-val="{key: 'Employment_Status', val: 'A'}"
                />
              </div>

              <div class="form-group">
                <label>Date</label><span class="required-star">*</span>
                <p v-if="!formVals.date" class="text-danger">Please select date of transaction</p>
                <date-picker
                  v-model="formVals.date"
                  :format="'string'"
                  :is-inline="false"
                  :pre-filled="false"
                  ref="datePicker"
                />
              </div>

              <div class="form-group">
                <label>Payment Method</label><span class="required-star">*</span>
                <select-field-dynamic
                  v-model="formVals.payment_method"
                  :options="[{ name: 'Personal Card', value: 'personal' }, { name: 'Company Credit Card', value: 'company_credit_card' }]"
                  :option-display-keys="['name']"
                  :option-val="'value'"
                  :empty-option-text="'Fetching items...'"
                  :label="'Payment Method'"
                />
              </div>

              <div
                v-if="formVals.payment_method === 'company_credit_card'"
                class="form-group"
              >
                <label>Vendor</label><span class="required-star">*</span>
                <select-field-dynamic
                  :options="vendors"
                  :option-display-keys="['Vendor_Name']"
                  :option-val="'Vendor_Code'"
                  v-model="formVals.vendor_code"
                  placeholder-text="Select a Vendor"
                  :label="'Vendor'"
                  :filter-on-key-val="{key: 'Status', val: 'A'}"
                />
              </div>

              <label>Listing of Receipt Expenses</label>
              <div class="expenses-table">
                <!-- <bh-table
                  :table-settings="expenseTableSettings"
                  @edit-item-obj="expenseTableEdit"
                /> -->

                <tabulator-table
                  :table-data="expensesList"
                  :table-columns="tableColumns"
                  table-fit="fitColumns"
                  :table-condensed="false"
                  empty-text="(none)"
                  :ref="'expenseTable'"
                  :max-table-height="'300px'"
                  @colClick="tableClick"
                />

              </div>

              <label>Add Expense</label>
              <div class="btn-group">
                <button class="btn btn-no-radius btn-blue btn-pip-lg" @click="showExpenseItem('job')">Job</button>
                <button class="btn btn-no-radius btn-blue btn-pip-lg" @click="showExpenseItem('wo')">WO</button>
                <button class="btn btn-no-radius btn-blue btn-pip-lg" @click="showExpenseItem('truck')">Work Truck</button>
                <button class="btn btn-no-radius btn-blue btn-pip-lg" @click="showExpenseItem('personal')">Personal</button>
              </div>
              <br><br>

              <div class="form-group">
                <label>Sub Total</label>
                <vue-numeric
                  currency="$"
                  separator=","
                  :class="['form-control']"
                  :precision="2"
                  :read-only-class="'form-control readonly'"
                  :value="subTotalTotal"
                  read-only
                />
              </div>

              <div class="form-group">
                <label>Sales Tax</label><span class="required-star">*</span>
                <vue-numeric
                  currency="$"
                  separator=","
                  :class="['form-control']"
                  :precision="2"
                  :read-only-class="'form-control'"
                  v-model="formVals.sales_tax"
                  inputmode="decimal"
                />
              </div>

              <div class="form-group">
                <label>Total</label>
                <vue-numeric
                  currency="$"
                  separator=","
                  :class="['form-control']"
                  :precision="2"
                  :read-only-class="'form-control readonly'"
                  :value="formVals.sales_tax + subTotalTotal"
                  read-only
                />
              </div>
            </div>
          </div>

          <div class="form-group dropzone-receipt-file-uploader">
            <label>Receipt File</label>
            <form class="dropzone" id="receiptDrop"></form>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <br></br>
              <button
                class="btn btn-no-radius btn-full-width btn-pip-lg"
                @click="save"
              >
                Submit
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <add-expense-item
    v-if="showExpenseDialog"
    :expense-type-prop="showExpenseDialog"
    :expense-data="expenseData"
    :employee-details="employeeDetails"
    @cancel="showExpenseDialog = null"
    @add="addExpenseItem"
  />

  <waiting-spinner/>

</div>
