<template>
  <div class="">
    <set-view-as-employee v-if="isAdmin" />
    <br />

    <div class="row">
      <div class="col-sm-12">
        <div class="sh-widget card wide-large" style="margin-bottom: 20px !important" v-if="isPMp">
          <div class="content">
            <h4>My Jobs Summary</h4>
            <div class="table-container show-scrollbar">
              <table>
                <thead>
                  <tr>
                    <th><label>Under Billings</label></th>
                    <th><label>Net Cash Flow</label></th>
                    <th><label>Total Contract Value</label></th>
                    <th><label>Billings Remaining</label></th>
                  </tr>
                </thead>
                <tr>
                  <td>{{ formatNumber(jobsTotal.Under_Over_Billing, 1) }}</td>
                  <td :class="jobsTotal.Net_Cash_Flow > 0 ? 'green' : 'red'">
                    {{ formatNumber(jobsTotal.Net_Cash_Flow, 1) }}
                  </td>
                  <td>{{ formatNumber(jobsTotal.Total_Contract_Amount, 1) }}</td>
                  <td>{{ formatNumber(jobsTotal.Billings_Remaining, 1) }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isPM">
      <button :class="'btn'" @click="addJob">Add New</button>
      <br /><br />
    </div>

    <div class="tab-section white">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{ active: tab == 'My Jobs' }]">
          <a href="#" @click=";(tab = 'My Jobs'), setTable()"> My Jobs </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'All Jobs' }]" v-if="isPM">
          <a href="#" @click=";(tab = 'All Jobs'), setTable()"> All Jobs </a>
        </li>
      </ul>

      <div :class="['card', { Main: tab == 'My Jobs' }]">
        <div class="content">
          <div class="row filter-row no-gutter">
            <div class="inline-block-row col-sm-6">
              <span class="inline-checkbox">
                <checkbox2 v-model="jobOpenShow" @change="setTable()"> Show Active</checkbox2>
              </span>
              <span class="inline-checkbox">
                <checkbox2 v-model="jobFinishedShow" @change="setTable()"> Show Inactive</checkbox2>
              </span>
              <span class="inline-checkbox">
                <checkbox2 v-model="jobAllShow"> Show Complete</checkbox2>
              </span>
              <span class="inline-checkbox">
                <checkbox2 @change="setTable()" @changeValue="setMissingPo"> Show Missing PO's</checkbox2>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Department</label>
              <select-field :options="costCenters" :option-display-keys="['name']" :option-val="'value'" v-model="costCenter" :allow-clear="true" @change="setTable()" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Stage</label>
              <select-field :options="[...projectStages, ...signOffStages]" :option-display-keys="['description']" :option-val="'id'" v-model="stageCode" :allow-clear="true" @change="setTable()" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Note Status</label>
              <select-field
                :options="[{ name: 'Current' }, { name: 'Stale' }, { name: 'Outdated' }, { name: 'None' }]"
                :label-text="'Note Status'"
                :option-display-keys="['name']"
                :option-val="'name'"
                v-model="noteStatusFilter"
                :allow-clear="true"
                @change="setTable()"
              />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <region-filter @select-region="selectRegion" />
            </div>
          </div>
          <br />
          <bh-table :table-settings="tableSettings" @colClick="colClick" />
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>
<script>
import store from 'store'
import BhTable from 'components/UIComponents/BhTable'
import navTabs from 'mixins/navTabs'
import appFuncs from 'appFuncs'
import JobWIPDetails from 'src/components/Dashboard/JobWo/JobWIPDetails.vue'

import dateFormat from 'dateformat'
import { mapGetters } from 'vuex'
import JobFuncs from 'mixins/JobFuncs'
import Checkbox2 from 'components/UIComponents/Checkbox'
import SelectField from 'components/UIComponents/SelectField'
import SetViewAsEmployee from 'components/Dashboard/Widgets/SetViewAsEmployee'
import RegionFilter from 'components/Dashboard/RegionFilter'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    var self = this
    return {
      tab: 'My Jobs',
      eid: store.getters.userAuthedData.eid,
      jobData: [],
      jobCosting: [],
      jobBilling: [],
      jobOpenShow: true,
      jobFinishedShow: true,
      jobHoldShow: true,
      jobCompleteShow: false,
      jobAllShow: false,
      jobMissingPoShow: false,
      costCenter: '',
      stageCode: '',
      noteStatusFilter: '',
      region: null
    }
  },

  props: {
    filterAll: {
      type: Boolean,
      default: false
    },
    stage: {
      type: [Number, String],
      default: 0
    }
  },

  components: {
    BhTable,
    Checkbox2,
    SelectField,
    SetViewAsEmployee,
    RegionFilter,
    WaitingSpinner
  },

  mixins: [navTabs, JobFuncs],

  computed: {
    ...mapGetters(['jobAssemble', 'userAuthedData', 'employees', 'regions']),

    tableSettings() {
      return {
        fields: this.jobFields,
        tableData: this.filteredJobs,
        hideItemEdit: true
      }
    },

    jobFields() {
      return {
        Job_Number: {
          name: 'Job #',
          class: 'narrow'
        },
        // status_note: {
        //   name: 'Status',
        //   noFilter: 1,
        //   class: 'wide'
        // },
        // Department:{},
        Name: {
          name: 'Customer Name'
        },
        Job_Description: {
          name: 'Job Description',
          wide: 1
        },
        ProjectStageName: {
          name: 'Project Stage'
        },
        SignOffStageName: {
          name: 'Sign Off Stage'
        },
        Est_Start_Date: {
          name: 'Start Date',
          class: 'underlined'
        },
        Est_Complete_Date: {
          name: 'Finish Date',
          class: 'underlined'
        },
        // Superintendent_Name:{
        //   name:'Captain'
        // },
        DepartmentName: {
          name: 'Department'
        },
        region_name: {
          name: 'Region'
        },
        Project_Manager_Name: {
          name: 'Project Lead'
        },
        // Sales_Person_Name: {
        //   name:'Sales Person'
        // },
        // status: {
        //   name:'Status',
        //   hidden:1
        // },
        Total_Contract_Amount_Display: {
          name: 'Contract Amount',
          hidden: !this.isPMp
        },
        po_number_display: {
          name: 'PO #',
          class: 'narrow underlined'
        },
        // Billed_JTD_Display: {
        //   name: 'Billed To Date',
        //   hidden: !this.isPMp
        // },
        // Under_Over_Billing_Display: {
        //   name: 'Under/Over Billing',
        //   hidden: !this.isPMp
        // },
        // Net_Cash_Flow_Display: {
        //   name: 'Net Cash Flow',
        //   hidden: !this.isPMp
        // },
        // Last_Bill_Date: {
        //   name: 'Last Bill Date',
        //   hidden: !this.isPMp
        // },
        Last_Cost_Date: {
          name: 'Last Work Date',
          hidden: !this.isPMp
        }
        // Stage_Description: {
        //   name: 'Stage',
        //   noFilter: 1
        // },
        // Job_Recieved: {
        //   name: 'Sign Off',
        //   noFilter: 1,
        //   class: 'narrow'
        // },
        // last_status_note_date_formatted: {
        //   name: 'Last Status Note'
        // },
      }
    },

    isAdmin() {
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPM() {
      return this.userAuthedData.user_role.indexOf('pm') !== -1
    },

    isPMp() {
      return this.isPM && this.userAuthedData.user_role.indexOf('P') !== -1
    },

    jobsTotal() {
      const jobsTotal = {
        Under_Over_Billing: 0,
        Total_Contract_Amount: 0,
        Billed_JTD: 0,
        Net_Cash_Flow: 0,
        Billings_Remaining: 0
      }

      const jobs = [...this.jobData]

      for (let i = 0; i < jobs.length; i++) {
        let underOverBillingValue = parseFloat(jobs[i]['Under_Over_Billing']) || 0
        let contractAmountValue = isNaN(parseFloat(jobs[i]['Total_Contract_Amount'])) ? 0 : parseFloat(jobs[i]['Total_Contract_Amount'])
        let billedJTDValue = isNaN(parseFloat(jobs[i]['Billed_JTD'])) ? 0 : parseFloat(jobs[i]['Billed_JTD'])
        let netCashFlowValue = isNaN(parseFloat(jobs[i]['Net_Cash_Flow'])) ? 0 : parseFloat(jobs[i]['Net_Cash_Flow'])
        let billingsRemaining = contractAmountValue - billedJTDValue

        const jobNumber = parseInt(jobs[i].Job_Number)
        if (this.myJobIds.indexOf(jobNumber) !== -1) {
          // Add the values to the jobsTotal object
          jobsTotal['Under_Over_Billing'] += underOverBillingValue
          jobsTotal['Total_Contract_Amount'] += contractAmountValue
          jobsTotal['Billed_JTD'] += billedJTDValue
          jobsTotal['Net_Cash_Flow'] += netCashFlowValue
          jobsTotal['Billings_Remaining'] += billingsRemaining
        }
      }
      return jobsTotal
    },

    filteredJobs() {
      let jobs = Array.isArray(this.jobData) ? JSON.parse(JSON.stringify(this.jobData)) : []

      let showStatuses = []
      if (this.jobAllShow) {
        showStatuses.push('C')
      }
      if (this.jobFinishedShow) {
        showStatuses.push('I')
      }
      if (this.jobOpenShow) {
        showStatuses.push('A')
      }
      if (this.costCenter) {
        jobs = jobs.filter(itm => {
          return String(itm.Cost_Center) == this.costCenter
        })
      }
      if (this.jobMissingPoShow) {
        jobs = jobs.filter(itm => {
          return String(itm.po_number_value) == '' || itm.po_number_value == null
        })
      }

      let filteredJobs = []

      for (let i = 0; i < jobs.length; i++) {
        const itm = jobs[i]

        // Check if the job's status should be shown
        const isStatusVisible = showStatuses.indexOf(itm.status) !== -1

        // Check if the job is part of "My Jobs" tab
        const isMyJob = this.tab === 'My Jobs' ? this.myJobIds.indexOf(parseInt(itm.Job_Number)) !== -1 : true

        // Check if the job's stage matches the selected stage
        const isStageMatch = [itm.project_stage, itm.sign_off_stage].includes(this.stageCode) || !this.stageCode

        const noteStatus = itm.note_status || 'none'

        const hasNoteStatus = !this.noteStatusFilter || this.noteStatusFilter == noteStatus ? true : false

        // Add the job to the filteredJobs array if all conditions are met
        if (isStatusVisible && isMyJob && isStageMatch && hasNoteStatus) {
          filteredJobs.push(itm)
        }

        jobs[i].ProjectStageName = this.projectStages.find(e => e.id == jobs[i].project_stage)?.description || jobs[i].project_stage
        jobs[i].SignOffStageName = this.signOffStages.find(e => e.id == jobs[i].sign_off_stage)?.description || jobs[i].sign_off_stage
      }

      filteredJobs = filteredJobs
        .filter(itm => {
          if (!this.region) return true
          return itm.region == this.region
        })
        .map(itm => {
          itm.Est_Start_Date = itm.Est_Start_Date ? this.formatDate(itm.Est_Start_Date) : ''
          itm.Est_Complete_Date = itm.Est_Complete_Date ? this.formatDate(itm.Est_Complete_Date) : ''
          return itm
        })

      return filteredJobs
    }
  },

  methods: {
    setTable() {},
    setMissingPo(value) {
      this.jobMissingPoShow = value
    },

    async loadData(refresh) {
      let notStatus = 'C'
      if (this.jobAllShow) {
        notStatus = ''
      }

      this.$bus.$emit('setWaiting', {
        name: 'getJobAssemble',
        message: 'Getting Jobs'
      })
      const jobsData = this.$store.dispatch('getJobAssemble', {
        refresh,
        not_status: notStatus
      })

      try {
        await Promise.all([jobsData])
      } catch (e) {
        console.log(e)
      }
      this.$bus.$emit('stopWaiting', 'getJobAssemble')

      if (!this.jobAssemble || !this.jobAssemble.length) {
        this.$snack.open('Problem getting Jobs', 'error')
        return
      }

      let jobs = JSON.parse(JSON.stringify(this.jobAssemble))
      var timestampSeconds = Date.now() / 1000

      // add row link
      for (var i = jobs.length - 1; i >= 0; i--) {
        jobs[i].id = jobs[i].Job_Number

        if (jobs[i]['Job_Number'] == 7013) {
          console.log(jobs[i])
        }

        let filterVal = ''
        let Stage_Description = jobs[i]['Stage_Description'] || ''
        filterVal = Stage_Description || 'z'

        if (jobs[i]['Stage'] == 5) {
          jobs[i]['Stage_Description'] = {
            action: 'openJobWipMain',
            title:
              '<i data-filterby="' + filterVal + '" class="ti-new-window"></i>&nbsp;<span class="text-danger font-weight-bold">' + Stage_Description + ' (' + jobs[i].Last_Report_Send_Date + ')</span>'
          }
        } else {
          jobs[i]['Stage_Description'] = {
            action: 'openJobWipMain',
            title: '<i data-filterby="' + filterVal + '" class="ti-new-window"></i>&nbsp;<span>' + Stage_Description + '</span>'
          }
        }

        let jobRecieved = jobs[i]['Job_Recieved'] || ''
        filterVal = jobRecieved || 'z'
        jobs[i]['Job_Recieved'] = {
          action: 'openJobWipMain',
          centered: 1,
          title: jobRecieved
        }

        let status_note = jobs[i]['status_note'] || ''
        filterVal = status_note || 'z'

        let dateFormattedText = ''
        let statusNoteFormatted = ''
        if (jobs[i]['last_status_note_date']) {
          let dateFormatted = dateFormat(parseInt(jobs[i]['last_status_note_date']) * 1000, 'yyyy-mm-dd')

          if (parseInt(jobs[i]['last_status_note_date']) + 60 * 60 * 24 * 7 > timestampSeconds) {
            // less than week old
            dateFormattedText = `<span class="text-success font-weight-bold">${dateFormatted}</span>`
            statusNoteFormatted = `<span class="text-success font-weight-bold">${status_note}</span>`
            jobs[i]['note_status'] = 'Current'
          } else if (parseInt(jobs[i]['last_status_note_date']) + 60 * 60 * 24 * 14 > timestampSeconds) {
            // less than 2 weeks old
            dateFormattedText = `<span class="text-warning font-weight-bold">${dateFormatted}</span>`
            statusNoteFormatted = `<span class="text-warning font-weight-bold">${status_note}</span>`
            jobs[i]['note_status'] = 'Stale'
          } else {
            // more than 2 weeks old
            dateFormattedText = `<span class="text-danger font-weight-bold">${dateFormatted}</span>`
            statusNoteFormatted = `<span class="text-danger font-weight-bold">${status_note}</span>`
            jobs[i]['note_status'] = 'Outdated'
          }
        } else {
          dateFormattedText = `<span class="text-danger font-weight-bold">none</span>`
          statusNoteFormatted = `<span class="text-danger font-weight-bold">none</span>`
          jobs[i]['note_status'] = 'None'
        }

        jobs[i]['last_status_note_date_formatted'] = {
          action: 'openJobWipNotes',
          title: dateFormattedText
        }

        jobs[i]['status_note'] = {
          action: 'openJobWipNotes',
          title: '<i data-filterby="' + filterVal + '" class="ti-new-window"></i>&nbsp;<span>' + statusNoteFormatted + '</span>'
        }

        if (this.jobCosting && this.jobCosting.length) {
          for (var j = 0; j < this.jobCosting.length; j++) {
            if (jobs[i].Job_Number == this.jobCosting[j].Job_Number) {
              // Copy all properties except for Company_Code
              for (var key in this.jobCosting[j]) {
                if (key !== 'Company_Code') {
                  jobs[i][key] = this.jobCosting[j][key]
                }
              }
            }
          }
        }

        if (this.jobBilling && this.jobBilling.length) {
          for (var j = 0; j < this.jobBilling.length; j++) {
            if (jobs[i].Job_Number == this.jobBilling[j].Job_Number) {
              jobs[i].Under_Over_Billing = parseFloat(this.jobBilling[j].Under_Over_Billing) ? parseFloat(this.jobBilling[j].Under_Over_Billing) : 0
            }
          }
        }

        // Convert and check each property, then store in a variable
        let underOverBillingValue = isNaN(parseFloat(jobs[i]['Under_Over_Billing'])) ? 0 : parseFloat(jobs[i]['Under_Over_Billing'])
        let contractAmountValue = isNaN(parseFloat(jobs[i]['Total_Contract_Amount'])) ? 0 : parseFloat(jobs[i]['Total_Contract_Amount'])
        let billedJTDValue = isNaN(parseFloat(jobs[i]['Billed_JTD'])) ? 0 : parseFloat(jobs[i]['Billed_JTD'])
        let netCashFlowValue = isNaN(parseFloat(jobs[i]['Net_Cash_Flow'])) ? 0 : parseFloat(jobs[i]['Net_Cash_Flow'])
        let billingsRemaining = contractAmountValue - billedJTDValue

        // Use the variables in the original code
        // jobs[i].class = netCashFlowValue > 0 ? 'green' : !netCashFlowValue ? '' : 'red';
        jobs[i]['Under_Over_Billing'] = underOverBillingValue || 0
        jobs[i]['Total_Contract_Amount'] = contractAmountValue || 0
        jobs[i]['Billed_JTD'] = billedJTDValue || 0
        jobs[i]['Net_Cash_Flow'] = netCashFlowValue || 0
        jobs[i]['po_number_value'] = jobs[i].po_number

        if (!jobs[i].po_number) {
          jobs[i].po_number_display = '<span class="has-link text-danger underlined">Add</span>'
        } else {
          jobs[i].po_number_display = jobs[i].po_number
        }

        const pm = this.employees.find(itm => {
          return itm.Employee_Code == jobs[i].Project_Manager
        })
        jobs[i].Project_Manager_Name = pm ? pm.Employee_Name : ''

        underOverBillingValue = parseFloat(jobs[i]['Under_Over_Billing']) || 0
        jobs[i]['Under_Over_Billing_Display'] =
          underOverBillingValue > 0
            ? `<span data-filterby="${underOverBillingValue}" class="text-success font-weight-bold">${this.formatNumber(underOverBillingValue, 1)}</span>`
            : underOverBillingValue < 0
              ? `<span data-filterby="${underOverBillingValue}" class="text-danger font-weight-bold">${this.formatNumber(underOverBillingValue, 1)}</span>`
              : 0
        jobs[i]['Total_Contract_Amount_Display'] = this.formatNumber(jobs[i]['Total_Contract_Amount'], 1)
        jobs[i]['Billed_JTD_Display'] = this.formatNumber(jobs[i]['Billed_JTD'], 1)
        jobs[i]['Net_Cash_Flow_Display'] = this.formatNumber(jobs[i]['Net_Cash_Flow'], 1)

        const dept = this.costCenters.find(itm => {
          return itm.value == jobs[i].Cost_Center
        })

        jobs[i]['DepartmentName'] = dept ? dept.name : jobs[i].Cost_Center

        let region_name = ''
        if (jobs[i].region != null && jobs[i].region != undefined && jobs[i].region != '') {
          for (const region of this.regions) {
            if (jobs[i].region == region.id) {
              region_name = region.name
            }
          }
        }
        jobs[i].region_name = region_name
      }

      const jobData = jobs && jobs.length ? jobs : []

      this.jobData = jobData.sort((a, b) => {
        return a.Job_Number < b.Job_Number ? 1 : -1
      })

      this.tableData = this.jobData
    },

    getCashFlowItems() {
      return new Promise((resolve, reject) => {
        if (!this.isPMp) {
          resolve()
          return
        }
        const params = {
          action: 'get_accounting_cashflow'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            this.jobCosting = data
            resolve()
          })
          .catch(res => {
            console.log(res)
            this.$snack.open(res.message || 'Problem getting Job Costing', 'error')
            reject()
          })
      })
    },

    getOverUnderBillingMin() {
      return new Promise((resolve, reject) => {
        if (!this.isPMp) {
          resolve()
          return
        }
        const params = {
          action: 'get_over_under_billing_min'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            this.jobBilling = data
            resolve()
          })
          .catch(res => {
            console.log(res)
            this.$snack.open(res.message || 'Problem getting Job Billing', 'error')
            reject()
          })
      })
    },

    colClick(obj) {
      const id = obj.itm.Job_Number
      const obj2 = Object.assign({}, obj.itm)
      if (this.isPM && obj.col == 'po_number_display') {
        this.editPONumber(obj2)
      } else if (obj.col == 'Est_Start_Date' || obj.col == 'Est_Complete_Date') {
        this.editDates(obj.itm)
      } else {
        this.$router.push('/app/job-details/' + id)
      }
    },

    editDates(obj) {
      this.$Modal({
        parent: this,
        name: 'SetEstimatedStartDate',
        size: 'lg',
        title: 'Project Update',
        component: () => import('components/Dashboard/JobWo/SetEstimatedStartDate.vue'),
        props: {
          obj
        }
      })
    },

    editPONumber(obj) {
      console.log(obj)
      this.$bus.$emit('modalOpen', {
        title: 'Edit PO Number',
        classes: 'gray-bg',
        name: 'JobWOPOEdit',
        title: '',
        size: 'modal-md',
        component: () => import('src/components/Dashboard/JobWo/JobWoPoEdit.vue'),
        componentData: {
          obj
        }
      })
    },

    listenPOEdit(obj) {
      if (!obj.Job_Number || !obj.po) return
      for (var i = 0; i < this.jobData.length; i++) {
        if (this.jobData[i].Job_Number == obj.Job_Number) {
          this.jobData[i].po_number = obj.po
          break
        }
      }
    },

    listenSetStartFinishDates(startDate, finishDate, jobNumber) {
      if (!jobNumber) return
      for (var i = 0; i < this.jobData.length; i++) {
        if (this.jobData[i].Job_Number == jobNumber) {
          this.jobData[i].Est_Start_Date = startDate
          this.jobData[i].Est_Complete_Date = finishDate
          break
        }
      }
    },

    listenLoadData() {
      console.log('listenLoadData')
      this.loadData(1)
    },

    openJobWipMain(obj) {
      this.$Modal({
        parent: this,
        name: 'JobWIPDetails',
        size: 'xl',
        component: JobWIPDetails,
        title: 'Job PM',
        props: {
          inputJobId: obj.Job_Number,
          jobData: obj, //singleJobData,
          setTab: 'Main',
          jobNav: this.tab
        }
      })
    },

    // openJobWipNotes(obj) {
    //   this.$Modal({
    //     parent: this,
    //     name: 'JobWIPDJobetails',
    //     size: 'xl',
    //     title: 'Job PM',
    //     component: JobWIPDetails,
    //     props: {
    //       inputJobId: obj.Job_Number,
    //       jobData: obj,
    //       setTab: 'Notes',
    //       jobNav: this.tab
    //     }
    //   })
    // },

    openJobWipNotes(obj) {
      this.$Modal({
        parent: this,
        name: 'JobStatusNotes',
        size: 'lg',
        title: `Job Status Notes`,
        component: () => import('src/components/Dashboard/JobWo/JobStatusNotes.vue'),
        props: {
          jobNumber: obj.Job_Number,
          showJobNumber: true,
          jobNumbers: this.tab == 'My Jobs' ? this.myJobIds : this.jobIds
        }
      })
    },

    addJob() {
      this.$Modal({
        parent: this,
        name: 'JobDetails',
        size: 'xl',
        contentClasses: 'gray-bg',
        component: () => import('src/components/Dashboard/JobWo/JobDetails.vue'),
        title: 'Add Job',
        props: {}
      })
    },

    selectRegion(selected_region) {
      this.region = selected_region
    }
  },

  async mounted() {
    if (this.$route.path == '/app/jobs/new') {
      this.addJob()
      return
    }

    const jobCosting = this.getCashFlowItems()
    const jobBilling = this.getOverUnderBillingMin()
    const employees = this.$store.dispatch('getEmployees')
    const regions = this.$store.dispatch('getRegions')

    this.$bus.$emit('setWaiting', { name: 'getData', message: 'Getting Data' })
    try {
      await Promise.all([jobCosting, jobBilling, employees, regions])
    } catch (e) {
      console.log(e)
    }
    this.$bus.$emit('stopWaiting', 'getData')

    this.loadData()

    this.$bus.$on(['setViewAsEmployeeId', 'itemCommentUpdate', 'updateWIP', 'updateJobWO'], this.listenLoadData)

    this.$bus.$on('openJobWipMain', this.openJobWipMain)
    this.$bus.$on('openJobWipNotes', this.openJobWipNotes)

    if (this.stage) {
      this.stageCode = this.stage
    }

    this.$bus.$on('JobWOPOEdit', this.listenPOEdit)
    this.$bus.$on('setStartFinishDates', this.listenSetStartFinishDates)
  },

  watch: {
    jobAllShow(newVal, oldVal) {
      if (newVal != oldVal) {
        this.loadData(1)
      }
    }
  },

  beforeDestroy() {
    this.$bus.$off('JobWOPOEdit', this.listenPOEdit)
    this.$bus.$off('setStartFinishDates', this.listenSetStartFinishDates)
    this.$bus.$off(['setViewAsEmployeeId', 'itemCommentUpdate', 'updateWIP', 'updateJobWO'], this.listenLoadData)
    this.$bus.$off('openJobWipMain', this.openJobWipMain)
    this.$bus.$off('openJobWipNotes', this.openJobWipNotes)
  }
}
</script>

<style lang="scss" scoped>
// @import "src/assets/sass/paper/_variables.scss";

/* Custom styles for larger screens */
.filter-row {
  font-size: 0; /* To remove whitespace between inline-block elements */
  margin-bottom: 10px;
}

.inline-block-row {
  display: inline-block;
  vertical-align: top;
  font-size: 16px; /* Reset font size */
  width: auto; /* Allow width to fit content */
  float: none; /* Override Bootstrap's float */
}

/* Bootstrap's styles will take over for smaller screens (col-sm-6) */
@media (max-width: 767px) {
  .inline-block-row {
    display: block;
    width: 100%;
  }
}
</style>
