<template>
  <div :class="['app-main', { 'nav-open': $sidebar.showSidebar }, { 'nav-locked': stickSidebar }]">
    <div ref="main" style="position: relative">
      <div class="newVersionBanner not-pdf" v-if="appIsOutdated && !isPdf">
        <div>
          <div class="row">
            <div class="col-sm-12">
              <div>
                <h4>NEW VERSION</h4>
                <p>
                  Reload to ensure compatibility.
                  <span
                    style="padding-left: 3px"
                    class="ti-info-alt"
                    v-tooltip="{
                      content: tooltipContent,
                      placement: 'bottom',
                      hideOnTargetClick: false,
                      delay: { show: 200, hide: 2500 },
                      offset: 5,
                      classes: ['wide']
                    }"
                  />
                </p>
              </div>
              <button class="btn btn-lg btn-pip btn-no-radius" @click="reloadApp">Reload</button>
            </div>
          </div>
        </div>
      </div>
      <router-view></router-view>
      <!--This sidebar appears only for screens smaller than 992px-->
      <side-bar type="navbar" :sidebar-links="$sidebar.sidebarLinks" />
      <div v-if="authorized && !feedbackOpen" class="user-feedback-button">
        <a href="#" @click="showUserFeedback" class="pulse feedback-button" title="Help/Feedback/Error Report"><i class="ti-flag-alt"></i></a>
      </div>

      <modal />
      <modal-multi />
      <waiting-spinner v-if="authorizing" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Modal from 'components/UIComponents/Modal.vue'
import ModalMulti from 'components/UIComponents/ModalMulti.vue'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import SideBar from 'components/UIComponents/SidebarPlugin/SideBar'

export default {
  data() {
    return {
      feedbackOpen: false,
      tooltipContent: 'Not working? <a href="/static/how-to-clear-cache-single-website.jpg" target="_blank">clear cache</a>.'
    }
  },

  components: {
    Modal,
    ModalMulti,
    WaitingSpinner,
    SideBar
  },

  computed: {
    ...mapState(['stickSidebar', 'appIsOutdated']),

    showSidebar() {
      if (this.stickSidebar) {
        return true
      }
      return this.$sidebar.showSidebar
    },

    authorizing() {
      var waiting = this.$store.getters.waiting
      if (waiting.name && waiting.name === 'authorizing') {
        return true
      } else {
        return false
      }
    },

    authorized() {
      if (this.$store.getters.userAuthedData && this.$store.getters.userAuthedData.eid) {
        return true
      }
      return false
    },

    isPdf() {
      return this.$route.query?.pdf === '1'
    }
  },

  methods: {
    showUserFeedback() {
      this.$sidebar.showSidebar = ''
      this.$bus.$emit('modalOpen', {
        title: 'Help / Feedback / Error Report',
        classes: 'gray-bg',
        name: 'send-user-feedback',
        size: 'modal-m',
        component: () => import('src/components/Dashboard/SendUserFeedback')
      })
    },

    reloadApp() {
      // Clear the browser cache
      if ('caches' in window) {
        caches.keys().then(names => {
          names.forEach(name => {
            caches.delete(name)
          })
        })
      }
      location.reload(true)
    }
  },

  mounted() {
    this.$store.dispatch('getStickSidebar')

    // clear all saved data on refresh
    sessionStorage.clear()

    this.$bus.$on('modalOpen', data => {
      if (data.name == 'send-user-feedback') {
        this.feedbackOpen = true
      }
    })

    this.$bus.$on('modalClosed', data => {
      if (data.name == 'send-user-feedback') {
        this.feedbackOpen = false
      }
    })

    this.$bus.$on('feedbackOpen', () => {
      this.showUserFeedback()
    })
  }
}
</script>

<style lang="scss">
@import 'vue-wysiwyg-improved/dist/vueWysiwyg.css';
@import 'src/assets/sass/paper/_variables.scss';

.app-main,
.app-main > div {
  height: 100%; // for component bg etc
}

.user-feedback-button {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 9999;

  a {
    width: 30px;
    height: 30px;
    display: block;
    border-radius: 50%;
    background: $primary-color;

    i {
      text-align: center;
      font-size: 14px;
      line-height: 30px;
      display: block;
      color: #fff;
      margin-left: -5px;
      margin-top: 4px;
    }
  }

  @media (max-width: 768px) {
    bottom: 15px;
    right: 15px;

    a {
      width: 30px;
      height: 30px;

      i {
        font-size: 14px;
        line-height: 30px;
      }
    }
  }
}

.newVersionBanner {
  background: #fff;
  border: 4px solid $pastel-orange;
  border-radius: 10px;
  text-align: center;
  position: absolute;
  z-index: 1060;
  bottom: 0;
  right: 0;

  h4 {
    font-size: 24px;
    font-weight: 700;
  }

  .btn,
  .btn:hover,
  .btn:focus {
    background: $pastel-orange;
    border-color: $pastel-orange;
    color: #fff;
    font-size: 16px;
  }

  a {
    // color: $text-color;
  }

  div {
    padding: 10px 10px;
    min-width: 300px;
    max-width: 500px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    div {
      padding: 5px 10px;
      min-width: 100%;
      width: 100%;
    }
    h4 {
      margin: 0;
    }
  }
}
</style>
