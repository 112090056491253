<template>
  <div class="max-width-large">
    <p class="text-danger">
      Using the same passwords across different websites makes your accounts vulnerable to security breaches in the likely event that one or more websites you use gets hacked.
      <strong>Please ensure that the password you use here is unique.</strong> Consider using a
      <a class="text-danger underlined" target="_blank" href="https://www.google.com/search?q=best+password+manager">password manager</a> to help simplify this best practise.
    </p>
    <br/>
    <form class="form-light">
      <div v-if="promptExistingPassword" class="form-group">
        <label for="password">Existing Password</label>
        <input type="password" class="form-control" name="existing_password" v-model="existing_password"/>
      </div>
      <div class="form-group">
        <label for="password">New Password</label>
        <input type="password" class="form-control" name="password" v-model="password"/>
      </div>
      <div class="form-group">
        <label for="password">Confirm New Password</label>
        <input type="password" class="form-control" name="password_confirm" v-model="password_confirm"/>
      </div>
      <input type="submit" class="btn" @click="resetPassword($event)" value="Submit"/>
    </form>
    <waiting-spinner/>
  </div>
</template>
<script>
import appFuncs from 'appFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  components: {WaitingSpinner},
  data() {
    return {
      existing_password: '',
      password: '',
      password_confirm: ''
    }
  },

  computed: {
    promptExistingPassword() {
      if (this.$store.getters.sherAuth.pw !== 'reset') {
        return true
      }
      return false
    }
  },

  methods: {
    resetPassword(e) {
      e.preventDefault()
      this.$store.commit('waiting', {status: 1, message: 'Saving new password'})

      var data = {
        action: 'reset_password',
        sher_auth: this.$store.getters.sherAuth,
        employee_id: this.$store.getters.userAuthedData.eid,
        existing_password: this.existing_password,
        employee_pw: this.password,
        employee_pw_confirm: this.password_confirm
      }

      $.ajax({
        url: this.$store.getters.urls.backend + 'ajax.php',
        type: 'POST',
        data: $.param(data),
        dataType: 'json',
        success: result => {
          this.$store.commit('waiting', {status: 0})
          if (result['status'] === 'success') {
            var sherAuth = this.$store.getters.sherAuth
            sherAuth.jwt = result.jwt
            sherAuth.pw = ''

            let jwd = ((result || {}).jwt_data || {}).data

            this.$store.dispatch('setSherAuth', {
              jwt: result.jwt,
              eid: jwd.eid,
              user_login: jwd.user_login,
              expires: jwd.expires
            })

            this.$store.dispatch('setUserAuthedData', {
              user_login: result.jwt_data.data.user_login,
              eid: result.jwt_data.data.eid,
              fname: result.jwt_data.data.fname,
              mname: result.jwt_data.data.mname,
              lname: result.jwt_data.data.lname,
              user_role: result.jwt_data.data.user_role,
              employee_role: result.jwt_data.data.employee_role
            })

            this.$snack.open(result.message, 'success')
            this.existing_password = ''
            this.password = ''
            this.password_confirm = ''

            if (this.$store.getters.modal.trigger) {
              this.$store.commit('modal', {trigger: 0})
            }
          } else {
            this.$snack.open(result.message, 'error')
          }
        },
        error: (e, status) => {
          this.$store.commit('waiting', {status: 0})
          console.log(status)
          this.$store.commit('Issues communicating with server, please try again now or later.', alertVals)
        }
      })
    }
  }
}
</script>
