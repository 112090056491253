
<div>
  <div class="row">
    <div class="col-sm-12">
      <button
        :class="'btn'"
        @click="addEditItem(0)"
        v-html="'Add New'"
      />
      <bh-table :table-settings="tableSettings" @edit-item="addEditItem"/>
    </div>
  </div>
  <waiting-spinner :wait="wait"/>
</div>
