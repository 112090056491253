
<div>
  <div class="sh-widget wide-medium-large basic-app-widget card" style="min-height: 470px">
    <div class="">
      <div class="content">
        <div class="widget-header">
          <h4>Company Metrics</h4>
        </div>
        <div class="group-metrics">
          <div class="metrics-row">
            <div class="metrics-item">
              <span><strong>Total Labor Cost</strong></span>
              <p>{{ this.formatNumber(this.total_labor_cost, 1) }}</p>
            </div>
            <div class="metrics-item">
              <span><strong>Total Hours</strong></span>
              <p>{{ this.formatNumber(this.total_hours) }}</p>
            </div>
            <div class="metrics-item">
              <span><strong>Average Labor Burden</strong></span>
              <p>{{ this.formatNumber(this.average_labor_burden, 1) }}</p>
            </div>
          </div>

          <div class="metrics-row">
            <div class="metrics-item">
              <span><strong>Billable Costs</strong></span>
              <p>{{ this.formatNumber(this.billable_costs, 1) }}</p>
              <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.billable_costs_in_total, 2) }}% of total</p>
            </div>
            <div class="metrics-item">
              <span><strong>Billable hours</strong></span>
              <p>{{ this.formatNumber(this.billable_hours) }}</p>
              <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.billable_hours_in_total, 2) }}% of total</p>
            </div>
            <div class="metrics-item">
              <span><strong>UB Safety Quoting Costs</strong></span>
              <p>{{ this.formatNumber(this.ub_safe_quoting_cost, 1) }}</p>
              <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.ub_safe_quoting_cost_in_total, 2) }}% of total</p>
            </div>
          </div>

          <div class="metrics-row">
            <div class="metrics-item">
              <span><strong>Unbillable Costs</strong></span>
              <p>{{ this.formatNumber(this.unbillable_costs, 1) }}</p>
              <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.unbillable_costs_in_total, 2) }}% of total</p>
            </div>
            <div class="metrics-item">
              <span><strong>Quoting Costs</strong></span>
              <p>{{ this.formatNumber(this.quoting_costs, 1) }}</p>
              <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.quoting_costs_in_total, 2) }}% of total</p>
            </div>
            <div class="metrics-item">
              <span><strong>Safety Costs</strong></span>
              <p>{{ this.formatNumber(this.safety_costs, 1) }}</p>
              <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.safety_costs_in_total, 2) }}% of total</p>
            </div>
          </div>

          <div class="metrics-row">
            <div class="metrics-item">
              <span><strong>Unbillable Hours</strong></span>
              <p>{{ this.formatNumber(this.unbillable_hours, 1, true) }}</p>
              <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.unbillable_hours_in_total, 2) }}% of total</p>
            </div>
            <div class="metrics-item">
              <span><strong>Quoting Hours</strong></span>
              <p>{{ this.formatNumber(this.quoting_hours, 1, true) }}</p>
              <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.quoting_hours_in_total, 2) }}% of total</p>
            </div>
            <div class="metrics-item">
              <span><strong>Safety Hours</strong></span>
              <p>{{ this.formatNumber(this.safety_hours, 1, true) }}</p>
              <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.safety_hours_in_total, 2) }}% of total</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner :contained="true" />
  </div>
</div>
