<template>
  <div class="max-width-large">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="card">
          <div class="content">
            <h4>General</h4>

            <div class="form-group">
              <label for="expense_cycle_months">Expense Cycle months</label>
              <vue-numeric :class="['form-control']" :read-only-class="'form-control readonly'" v-model="appOptions.expense_cycle_months" />
            </div>

            <div class="form-group">
              <label for="tool_allowance">Tool Allowance</label>
                  <vue-numeric currency="$" separator="," :class="['form-control']" :precision="2" :read-only-class="'form-control readonly'" v-model="appOptions.tool_allowance" />
            </div>

            <div class="form-group">
              <label for="customer_feedback_emails">Tool Allowance Groups</label>
              <select-field-multi
                :option-display-keys="['name']"
                :option-seperator="' '"
                :option-val="'id'"
                v-model="appOptions.tool_groups"
                :allow-clear="true"
                placeholder-text="Select Tool Allowance Groups"
                :options="tool_groups"
              />
            </div>

            <div class="form-group">
              <label for="tool_allowance">Boot Allowance</label>
              <vue-numeric currency="$" separator="," :class="['form-control']" :precision="2" :read-only-class="'form-control readonly'" v-model="appOptions.boot_allowance" />
            </div>

            <div class="form-group">
              <label for="customer_feedback_emails">Boot Allowance Groups</label>
              <select-field-multi
                :option-display-keys="['name']"
                :option-seperator="' '"
                :option-val="'id'"
                v-model="appOptions.boot_groups"
                :allow-clear="true"
                placeholder-text="Select Boot Allowance Groups"
                :options="tool_groups"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <input type="submit" class="btn" @click="update()" value="Update" />

    <waiting-spinner />
  </div>
</template>
<script>
import appFuncs from 'appFuncs'
import { mapGetters } from 'vuex'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import VueNumeric from 'vue-numeric'
import DatePicker from 'components/UIComponents/DatePicker.vue'

export default {
  name: 'AdminOptions',
  data() {
    return {
      tool_groups: [
        { id: 'cba_employee', name: 'CBA employee' },
        { id: 'non_cba_contracting_employee', name: 'Non CBA Contracting Employee' },
        { id: 'automation_employee', name: 'Automation Employee' },
        { id: 'estimating_employee', name: 'Estimating Employee' },
        { id: 'sales_employee', name: 'Sales Employee' },
        { id: 'administration_employee', name: 'Administration Employee' }
      ],
      date: new Date(),
      masks: {
        weekdays: 'www',
        input: 'DD/MM'
      },
      model_config: {
        type: 'string',
        mask: 'DD/MM'
      }
    }
  },

  computed: {
    ...mapGetters(['appOptions'])
  },

  components: {
    WaitingSpinner,
    SelectFieldMulti: () => import('components/UIComponents/SelectFieldMulti'),
    VueNumeric,
    DatePicker
  },

  methods: {
    update() {
      const params = {
        action: 'update_app_options',
        options: this.appOptions
      }
      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Getting App Options'
      })
      appFuncs
        .shRequest(params, false)
        .then(res => {
          // 1 for use server, or false
          if (res && res.status === 'success') {
            if (res.updated && res.updated.status === 'success') {
              this.$store.commit('appOptions', res.updated.data)
            }
            this.$snack.open('Options updated')
          } else {
            this.$snack.open('Problem updating options', 'warning')
          }
          console.log(res)
        })
        .catch(res => {
          this.$snack.open(res.message || 'Problem updating options', 'warning')
          console.log(res)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    }
  },

  mounted() {
    this.$bus.$emit('setWaiting', {
      name: 'getAppOptions',
      message: 'Getting App Options'
    })
    this.$store.dispatch('getAppOptions', 1).finally(() => {
      this.$bus.$emit('stopWaiting', 'getAppOptions')
    })
  }
}
</script>

<style scoped>
h4 {
  margin: 10px 0 15px;
}
</style>
