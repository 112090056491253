
<div>
  <div class="row">
    <div class="col-sm-12">
      <button class="btn" @click="showOptions">Report Options</button>
    </div>
  </div>
  <br />
  <div class="card" style="z-index: 50; position: relative">
    <div class="content">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="select-item">
            <label>By Work Order</label>
            <select-field :options="woAssemble" :option-display-keys="['WO_Number', 'Summary_Description']" :option-val="['WO_Number']" v-model="selectedWO" />
            <button :disabled="!selectedWO" class="btn" @click="$router.push({ name: 'PO Report Summary', params: { type: 'wo', itmCode: selectedWO } })" v-html="'View Report'" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6">
          <div class="select-item">
            <label>By Job</label>
            <select-field :options="jobs" :option-display-keys="['Job_Number', 'Job_Description']" :option-val="['Job_Number']" v-model="selectedJob" />
            <button :disabled="!selectedJob" class="btn" @click="$router.push({ name: 'PO Report Summary', params: { type: 'job', itmCode: selectedJob } })" v-html="'View Report'" />
          </div>
        </div>
      </div>
      <!--  <div class="row">
              <div class="col-sm-12 col-md-12">
                <div class="select-item">
                  <label>By Vendor</label>
                  <select-field
                    :options="vendorList"
                    :option-display-keys="['Vendor_Name']"
                    :option-val="['Vendor_Code']"
                    v-model="selectedVendor"
                  />
                  <button
                    :disabled="!selectedVendor"
                    class="btn"
                    @click="$router.push({name: 'PO Report Summary', params: {type: 'vendor', itmCode: selectedVendor }})"
                    v-html="'View Report'"
                  />
                </div>
              </div>
            </div> -->
    </div>
  </div>

  <div class="card">
    <div class="content">
      <div class="row">
        <div class="card">
          <div class="col-sm-12">
            <label>By Vendor</label>
            <bh-table :table-settings="tableSettings" @edit-item-obj="openVendor" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <waiting-spinner :wait="wait" />
</div>
