<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="new-request">
          <div class="tab-section white">
            <ul class="nav nav-tabs">
              <li role="presentation" :class="[{ active: tab == 'Request' }]">
                <a href="#" @click="tab = 'Request'"> Request </a>
              </li>
              <li role="presentation" :class="[{ active: tab == 'stats' }]">
                <a href="#" @click="tab = 'stats'"> Used/Earned </a>
              </li>
              <li role="presentation" :class="[{ active: tab == 'Instructions' }]">
                <a href="#" @click="tab = 'Instructions'"> Instructions </a>
              </li>
            </ul>

            <div :class="[{ 'first-active': tab == 'Request' }, 'tab', tab, 'tab-content', 'card']">
              <div class="content">
                <div v-if="tab == 'Request'">
                  <!-- <div class="form-group">
                    <label for="note_text">Email Address</label>
                    <input type="text" class="form-control" v-model="email">
                  </div> -->
                  <div class="row">
                    <div class="column col-sm-12 col-md-6">
                      <div class="form-group">
                        <label>First Day Off</label>
                        <date-picker v-model="first_off" :minDate="minDateFirst" :format="'date'" :preFilled="false"></date-picker>
                      </div>
                    </div>
                    <div class="column col-sm-12 col-md-6">
                      <div class="form-group">
                        <label>Last Day Off</label>
                        <date-picker v-model="last_off" :minDate="minDateLast" :format="'date'" :preFilled="false"></date-picker>
                      </div>
                    </div>
                  </div>

                  <label>Vacation Request Reason:</label>
                  <ul class="form-group">
                    <li>
                      <input type="radio" id="reason1" v-model="reason" value="Vacation" />
                      <label for="reason1">Vacation</label>
                    </li>
                    <li>
                      <input type="radio" id="reason2" v-model="reason" value="Medical or Family Emergency" />
                      <label for="reason2">Medical or Family Emergency</label>
                    </li>
                    <li>
                      <input type="radio" id="reason3" v-model="reason" value="Other" />
                      <label for="reason3">Other</label>
                    </li>
                  </ul>

                  <p class="text-center">
                    <checkbox v-model="readInstructions"> I've read and understand the instructions.</checkbox>
                  </p>

                  <button type="submit" class="btn" v-on:click="sendForm()">Send Request</button>
                </div>

                <div v-if="tab == 'stats'" :class="['tab', tab, 'tab-content', '']">
                  <div class="content show-scrollbars">
                    <bh-table :table-settings="tableSettingsStats" />
                  </div>
                </div>

                <div class="instructions" v-if="tab == 'Instructions'">
                  <p>Use this form to request time off for any reason. If you have multiple blocks of time to request off (separated by a day where you are working), submit them each individually.</p>

                  <p>
                    We will do our best to accommodate all vacation requests and dates requested however, each request is subject to review and approval. If you request time off with less than two
                    weeks notice there is a greater chance it may be rejected due to prior scheduling or job requirements. Time requested with two weeks or more notice will most often be approved but
                    we must ensure first that there are not too many people off at any one time and that we will be able to satisfy job labor needs.
                  </p>

                  <p>Do not book vacation without prior approval first as there is always a possibility that the dates requested off will not be approved.</p>

                  <p>Vacation requests will be approved or rejected with alternate dates within five (5) working days.</p>

                  <p>If you have any questions, contact the office: 905-625-0265</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h4>All Requests</h4>
        <div class="card">
          <div class="content">
            <div class="row" style="margin-bottom: 30px" v-if="canManage">
              <div class="col-md-3">
                <label>Status</label>
                <select-field :options="statuses" :label-text="'Status'" :option-display-keys="['name']" :option-val="'name'" v-model="status" @input="changeStatus()" />
              </div>
              <div class="col-md-3">
                <label>Select Employee</label>
                <select-field-dynamic
                  :options="filteredEmployees"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'id'"
                  v-model="fetchEmployeeId"
                  @selectItem="changeStatus()"
                  :preselect-single-option="false"
                  :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                  label="Employee"
                  :allow-clear="canManage"
                />
              </div>
              <div class="col-md-3">
                <label>Year</label>
                <select-field :options="years" :label-text="'Year'" :option-display-keys="['years']" :option-val="'years'" v-model="year" @input="changeStatus()" :allow-clear="true" />
              </div>
            </div>
            <bh-table :table-settings="tableSettings" @edit-item="editRequest" />
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner :wait="wait" />
  </div>
</template>
<script>
import store from 'store'
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'

import moment from 'moment'
import dateFormat from 'dateformat'
import DatePicker from 'components/UIComponents/DatePicker'
import NavTabs from 'mixins/navTabs'
import BhTable from 'components/UIComponents/BhTable'
import VacationEdit from 'src/components/Dashboard/Misc/VacationEdit.vue'
import SelectField from 'components/UIComponents/SelectField'
import SelectFieldDynamic from 'components/UIComponents/SelectFieldDynamic'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {
      wait: {
        message: ''
      },
      tab: 'Request',
      urls: store.getters.urls,
      vacationRequests: [],
      first_off: null,
      last_off: null,
      reason: '',
      fetchEmployeeId: '',
      requests: [],
      readInstructions: false,
      vacationData: [],

      status: '',
      statuses: [{ name: 'Open Request' }, { name: 'TBD' }, { name: 'Approved' }, { name: 'Disapproved' }, { name: 'Withdrawn' }],

      year: '',
      years: [],

      tableSettings: {
        tableData: '',
        fields: {
          employeeName: {
            name: 'Employee'
          },
          first_off: {
            name: 'First Off'
          },
          last_off: {
            name: 'Last Off'
          },
          reason: {
            name: 'Reason'
          },
          niceDate: {
            name: 'Requested'
          },
          status: {
            name: 'Status'
          },
          status_message: {
            name: 'Message',
            wide: 1
          }
        },
        noItemEdit: false,
        hideSearch: false
      },
      tableSettingsStats: {
        tableData: [],
        fields: {
          Employee_Name: {
            name: 'Employee'
          },
          vacation_used_ytd: {
            name: 'Vacation Balance (Hours)'
          }
          // 'vacation_earned_ytd': {
          //   name: 'Vacation Earned (YTD)'
          // }
        },
        noItemEdit: true,
        hideSearch: true
      }
    }
  },

  components: {
    DatePicker,
    BhTable,
    SelectField,
    SelectFieldDynamic,
    WaitingSpinner
  },

  mixins: [NavTabs],

  computed: {
    ...mapGetters(['employees', 'appData', 'userAuthedData']),
    user() {
      var userObj = this.userAuthedData
      return userObj.fname + ' ' + userObj.lname
    },
    email() {
      return ((this.appData || null).employeeData || null).Employee_Email || null
    },
    isAdmin() {
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    },
    today() {
      return moment().utcOffset(-4, true).toDate()
    },
    minDateFirst() {
      return moment(this.today).add(1, 'days').toDate()
    },
    minDateLast() {
      if (this.first_off) {
        return this.first_off
      } else {
        return this.today
      }
    },
    filteredEmployees() {
      let employees = this.employees.length ? JSON.parse(JSON.stringify(this.employees)) : []
      employees.unshift({
        id: '',
        Employee_Name: 'All Employees',
        Employment_Status: 'A'
      })
      return employees.filter(itm => {
        return itm.Employment_Status === 'A'
      })
    },

    canManage() {
      return this.isAdmin || this.isCaptain
    },

    isCaptain() {
      return this.isAdmin || this.userAuthedData.employee_role === 'captain'
    }
  },
  methods: {
    sendForm() {
      var today = moment().utcOffset(-4, true).toDate()
      var error = ''

      // if(!this.email){
      //   error = 'Email address required.';
      // }else

      if (!this.first_off) {
        error = 'First Off date required'
      } else if (!this.last_off) {
        error = 'Last Off date required.'
      } else if (!this.reason) {
        error = 'Reason required.'
      } else if (this.first_off <= this.today) {
        error = 'First Off day must be after today.'
      } else if (this.last_off < this.first_off) {
        error = 'Last Off day must be on or after First Off day.'
      }

      if (error) {
        this.$snack.open(error, 'warning')
        return
      }

      if (!this.readInstructions) {
        this.$snack.open('Please check the box indicating that you have read the instructions.', 'warning')
        return
      }

      var data = {
        action: 'new_vacation_request',
        name: this.user,
        email: this.email,
        first_off: moment(this.first_off).utcOffset(-4, true).format('YYYY-MM-DD'),
        last_off: moment(this.last_off).utcOffset(-4, true).format('YYYY-MM-DD'),
        reason: this.reason
      }

      this.wait.message = 'Sending Request'

      appFuncs.ajax_request(store.getters.sherAuth, data, result => {
        if (result.status === 'success') {
          this.first_off = null // null values used to clear datepicker in compoment
          this.last_off = null
          this.reason = ''
          this.getVacationRequests()
          this.$snack.open(result.message)
        } else {
          this.$snack.open(result.message, 'error')
        }
        this.wait.message = ''
      })
    },

    getVacationRequests() {
      const params = {
        action: 'get_vacation_requests',
        employee_id: this.fetchEmployeeId,
        status: this.status,
        year: this.year
      }
      this.wait.message = 'Getting existing requests'
      appFuncs
        .shRequest(params)
        .then(data => {
          if (Array.isArray(data)) {
            this.vacationRequests = data
            this.updateTable()
          } else {
            this.$snack.open('Problem getting existing requests.', 'error')
          }
        })
        .catch(res => {
          this.$snack.open('Problem getting existing requests.', 'error')
        })
        .finally(() => {
          this.wait.message = ''
        })
    },

    getVacationYears() {
      const params = {
        action: 'get_vacation_years'
      }
      this.wait.message = 'Getting existing requests'
      appFuncs
        .shRequest(params)
        .then(data => {
          if (Array.isArray(data)) {
            this.years = data
          } else {
            this.$snack.open('Problem getting existing requests.', 'error')
          }
        })
        .catch(res => {
          this.$snack.open('Problem getting existing requests.', 'error')
        })
        .finally(() => {
          this.wait.message = ''
        })
    },

    getEmployeeName(id) {
      if (this.employees && this.employees.length) {
        let obj = this.employees.find(itm => {
          return itm.Employee_Code == id
        })
        if (obj) {
          return obj.Employee_Name
        }
      }
    },

    updateTable() {
      let requests = Array.isArray(this.vacationRequests) ? this.vacationRequests : []

      for (var i = requests.length - 1; i >= 0; i--) {
        requests[i].niceDate = dateFormat(new Date(requests[i].requested) * 1000, 'mmm dS yyyy')
        requests[i].employeeName = this.getEmployeeName(requests[i].employee_id)
      }

      requests = requests.sort((a, b) => {
        return b.requested - a.requested
      })

      this.tableSettings.tableData = requests
    },

    editRequest(id) {
      let request = this.vacationRequests.find(itm => {
        return itm.id === id
      })
      // if (request.status == 'Withdrawn' && this.isAdmin) {
      //   return
      // }
      this.$Modal({
        parent: this,
        name: 'VacationEdit',
        size: 'md',
        hideClose: false,
        component: VacationEdit,
        props: {
          id,
          vacationRequests: this.vacationRequests
        }
      })
    },

    setVacationStats() {
      if (!this.vacationData || !this.vacationData.length) return

      var filteredList = []

      for (var i = 0; i < this.vacationData.length; i++) {
        // add some id for bhtable
        this.vacationData[i].id = this.vacationData[i].Employee_Code
        // add employee name
        if (this.filteredEmployees && this.filteredEmployees.length) {
          let employee = this.filteredEmployees.find(itm => {
            return itm.Employee_Code == this.vacationData[i].Employee_Code
          })

          this.vacationData[i].Employee_Name = employee ? employee.Employee_Name : null
        }

        // if employee is not found, they may not have Active Status, skip for now
        if (this.vacationData[i].Employee_Name) {
          filteredList.push(this.vacationData[i])
        }
      }
      this.tableSettingsStats.tableData = filteredList
    },

    changeStatus() {
      this.getVacationRequests()
    }
  },

  async mounted() {
    this.fetchEmployeeId = this.userAuthedData.eid

    const fetchEmployees = this.$store.dispatch('getEmployees').catch(err => {
      this.$snack.open(err.message || 'Problem fetching employees', 'error')
    })
    const fetchVacationStats = appFuncs
      .shRequest({ action: 'get_employee_vacation_balance' })
      .then(data => {
        this.vacationData = Array.isArray(data) ? data : []
      })
      .catch(err => {
        this.$snack.open(err.message || 'Problem fetching vacation data', 'error')
      })

    this.wait.message = 'Fetching data'
    await Promise.all([fetchEmployees, fetchVacationStats])
    this.wait.message = ''

    this.fetchEmployeeId = this.userAuthedData.eid

    this.setVacationStats()

    if (this.canManage) {
      this.status = 'Open Request'
    }

    this.getVacationRequests()
    this.getVacationYears()

    this.$bus.$on('refreshRequests', data => {
      this.getVacationRequests()
    })
  }
}
</script>

<style lang="scss" scoped>
.new-request {
  max-width: 600px;
}

ul.form-group {
  padding-left: 10px;

  li {
    display: block;
    margin-bottom: 3px;

    label {
      padding-left: 10px;
    }
  }
}

.instructions {
  padding: 10px;
}

.tab.stats {
  padding-bottom: 10px;

  .content {
    max-height: 320px;
    overflow: scroll;
  }
}
</style>
