
<div class="tab-section white">

  <set-view-as-employee v-if="isAdmin" />

  <div class="max-width-large">
    <div class="row">
      <div class="col-lg-12">
        <button class="btn" @click="addExpense" v-html="'Add Expenses'" />
      </div>
    </div>

    <br />

    <div class="tab-section outline white">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{ active: tabMain == 'Personal' }]">
          <a href="#" @click=";(tabMain = 'Personal'), (vendorType = 'Personal')"> Personal </a>
        </li>
        <li role="presentation" :class="[{ active: tabMain == 'Company' }]">
          <a href="#" @click=";(tabMain = 'Company'), (vendorType = 'Company')"> Company </a>
        </li>
      </ul>
    </div>

    <div class="card">
      <div class="content">
        <alert
          :alert="{
            show: alertMessage,
            message: alertMessage,
            state: 'warning',
            dismiss: 'none'
          }"
        />
        <div class="row" v-if="isAdmin && 'cats' == 'dogs'">
          <div class="col-sm-4">
            <div class="form-group">
              <label>Vendor</label><span class="required-star">*</span>
              <select-field :options="vendors" :option-display-keys="['Vendor_Name']" :option-val="'Vendor_Code'" v-model="vendor" :item-name="'Vendor'" @selectItem="getInvoices" />
            </div>
          </div>
        </div>

        <div class="tab-section outline">
          <ul class="nav nav-tabs">
            <li v-if="tabMain == 'Personal'" role="presentation" :class="[{ active: tab == 'Open' }]">
              <a href="#" @click="tab = 'Open'"> Open Invoices </a>
            </li>
            <li v-if="tabMain == 'Personal'" role="presentation" :class="[{ active: tab == 'History' }]">
              <a href="#" @click="tab = 'History'"> Detailed History </a>
            </li>
            <li v-if="tabMain == 'Company'" role="presentation" :class="[{ active: tab == 'History' }]">
              <a href="#" @click="tab = 'History'">Expense History </a>
            </li>
            <li role="presentation" :class="[{ active: tab == 'Info' }]">
              <a href="#" @click="tab = 'Info'"> Instructions </a>
            </li>
          </ul>

          <div class="tab-content">
            <div v-if="tab == 'Open'" :class="['card', { Main: tab == 'Open' }]">
              <div class="content" v-if="tabMain === 'Personal'">
                <h5>Not Yet Approved</h5>
                <tabulator-tiles-dynamic
                  :table-data="unapprovedInvoices"
                  :table-columns="tableColumnsNotYetApproved"
                  table-fit="fitColumns"
                  :table-condensed="false"
                  empty-text="(none)"
                  ref="openExpenses"
                  :max-table-height="'300px'"
                />
                <br /><br />
                <h5>Approved for Payment</h5>
                <tabulator-tiles-dynamic
                  :table-data="approvedInvoices"
                  :table-columns="tableColumnsApproved"
                  table-fit="fitColumns"
                  :table-condensed="false"
                  empty-text="(none)"
                  ref="openExpenses"
                  :max-table-height="'300px'"
                  :initial-sort="[{ column: 'Date_List1', dir: 'asc' }]"
                />
              </div>
            </div>
            <div v-if="tab == 'ExpenseHistory'" :class="['card']">
              <div class="content" v-if="tabMain === 'Personal'">
                <tabulator-tiles-dynamic
                  :table-data="filteredExpenseHistory"
                  :table-columns="tableColumnsExpenseHistory"
                  table-fit="fitColumns"
                  :table-condensed="false"
                  empty-text="(none)"
                  ref="openExpenses"
                  :max-table-height="'700px'"
                  :initial-sort="[{ column: 'Check_Date', dir: 'asc' }]"
                />
                <div class="row">
                  <div class="col-md-8"></div>
                  <div class="col-md-2">Boot expenses: {{ formatNumber(boot_spent, 1) }}</div>
                  <div class="col-md-2">Tool expenses: {{ formatNumber(tool_spend, 1) }}</div>
                </div>
              </div>
            </div>
            <div v-if="tab == 'History'" :class="['card']">
              <div v-if="tabMain === 'Company'" class="row">
                <div class="col-sm-4">
                  <div class="form-group" style="margin-left:15px">
                    <label>Month</label>
                    <select-field :options="monthsArray" :option-display-keys="['name']" :option-val="'value'" v-model="showHistoryMonth" :item-name="'Month'" />
                  </div>
                </div>
              </div>

              <div class="content" v-if="tabMain === 'Personal'">
                <tabulator-tiles-dynamic
                  :table-data="historicInvoices"
                  :table-columns="tableColumnsHistoric"
                  table-fit="fitColumns"
                  :table-condensed="false"
                  empty-text="(none)"
                  ref="openExpenses"
                  :max-table-height="'700px'"
                />
              </div>
              <div class="content" v-if="tabMain === 'Company'">
                <tabulator-tiles-dynamic
                  :table-data="filteredAllCompanyExpenses"
                  :table-columns="tableColumnsAllCompanyExpenses"
                  table-fit="fitColumns"
                  :table-condensed="false"
                  empty-text="(none)"
                  ref="openExpenses"
                  :max-table-height="'700px'"
                />
              </div>
            </div>
            <div v-if="tab == 'Info'" :class="['card']">
              <div class="content">
                <ul class="instruction-info">
                  <li>Use the Add Expenses button to submit personal expenses or company expenses attached to a Job, WO or vehicles.</li>
                  <li>Within the Add Expenses dialog, add one or more expense types using the Add Expense buttons, input required fields then attach receipts showing payment details.</li>
                  <li>Track re-embursement for personal expenses using the tables within the Open Invoices and History tabs.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner />
</div>
