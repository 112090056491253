
<div>
  <alert/>

  <iframe class="responsive-table-embed"
          src="https://docs.google.com/forms/d/e/1FAIpQLSekEh-tir67GB23RhMauoA2Ot0TBIBcpPNA3FyceNfIn8DLsQ/viewform?c=0&w=1&amp;single=true&amp;widget=true&amp;headers=false"
  ></iframe>

  <waiting-spinner/>

</div>
