
<div>
  <div class="row">
    <div class="col-sm-12">
      <div class="po-table-container">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3" style="margin-bottom: 20px">
            <label>Vendor</label>
            <select-field :options="vendorOptions" :label-text="'Vendors'" :option-display-keys="['name']" :option-val="'value'" v-model="vendorFilter" :allow-clear="true" />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3" style="margin-bottom: 20px">
            <label>PO Status</label>
            <select-field :allow-clear="true" :options="poStatusOptions" :label-text="'PO Status'" :option-display-keys="['name']" :option-val="'value'" v-model="poStatusFilter" />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3" style="margin-top: 28px">
            <button v-if="!isLocked" class="btn" @click="openPO()">New PO</button>
          </div>
        </div>

        <h5>Purchase Orders</h5>
        <bh-table :table-settings="tableSettings" @colClick="openPO" />
      </div>
    </div>
  </div>
  <waiting-spinner :wait="wait" />
</div>
