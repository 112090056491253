<template>
  <div>
    <h3><span v-html="id ? 'Edit' : 'Add'" /> Vendor Contact</h3>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label>Company<span class="required-star">*</span></label>
          <select-field
            :options="vendors"
            :option-display-keys="['Vendor_Name']"
            :option-val="'Vendor_Code'"
            v-model="contact.Vendor_Code"
            placeholder-text="Select a Vendor"
            :filter-on-key-val="{ key: 'Status', val: 'A' }"
          />
        </div>
        <div class="form-group">
          <label>Address</label>
          <p v-html="vendor.Address_1 || '(empty)'" />
        </div>
        <div class="form-group">
          <label>First Name</label>
          <input type="text" class="form-control" v-model="contact.First_Name" />
        </div>
        <div class="form-group">
          <label>Last Name</label>
          <input type="text" class="form-control" v-model="contact.Last_Name" />
        </div>
        <div class="form-group">
          <label>Title</label>
          <input type="text" class="form-control" v-model="contact.Title" />
        </div>
      </div>

      <div class="col-md-6 col-sm-12">
        <div class="form-group">
          <label>Direct Line</label>
          <input type="text" class="form-control" v-model="contact.Direct_Line" v-mask="chooseMask(contact.Direct_Line)" />
        </div>
        <div class="form-group">
          <label>Extension</label>
          <input type="number" class="form-control" v-model="contact.Extension" />
        </div>
        <div class="form-group">
          <label>Mobile</label>
          <input type="text" class="form-control" v-model="contact.Mobile" v-mask="chooseMask(contact.Mobile)" />
        </div>
        <div class="form-group">
          <label>Email</label>
          <input type="text" class="form-control" v-model="contact.Email" />
        </div>
        <div class="form-group">
          <label>Attributes</label>
          <input type="text" class="form-control" v-model="contact.Attributes" />
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group">
          <label>Notes</label>
          <textarea type="text" class="form-control" v-model="contact.Notes" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <checkbox v-model="contact.AP"> Accounts Payable Contact</checkbox>
        </div>
      </div>

      <div class="col-sm-6">
        <checkbox2 v-model="contact.Default_Purchase_Contact" :true-val="'yes'" :false-val="'no'"> Default Purchase Contact</checkbox2>
      </div>
    </div>

    <button class="btn" @click="addUpdate" v-html="id ? 'Update' : 'Add New'" />

    <button v-if="id" class="btn" @click="deleteContact" v-html="'Delete'" />

    <waiting-spinner :wait="wait" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import SelectField from 'components/UIComponents/SelectField'
import Checkbox2 from 'components/UIComponents/Checkbox'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'VendorContactAddEdit',
  data() {
    return {
      wait: {
        message: ''
      },
      vendor: {},
      contact: {
        Vendor_Code: '',
        First_Name: '',
        Last_Name: '',
        Title: '',
        Direct_Line: '',
        Extension: '',
        Email: '',
        Mobile: '',
        Attributes: '',
        Notes: '',
        AP: 0,
        Address_1: '',
        Default_Purchase_Contact: 'no'
      }
    }
  },

  props: {
    id: {
      type: [Number, String],
      required: true
    },
    selectedVendor: {
      type: String,
      default: ''
    }
  },

  components: {
    SelectField,
    Checkbox2,
    WaitingSpinner
  },

  computed: {
    ...mapGetters(['vendors', 'vendorContacts']),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    }
  },

  methods: {
    addUpdate() {
      if (this.contact.AP && (!this.contact.Email || !this.contact.First_Namme || !this.contact.Last_Name)) {
        this.$snack.open('Contact must have Name and Email to be assigned as AP', 'warning')
        return
      }

      const params = {
        action: 'upsert_vendor_contact',
        id: this.id,
        data: this.contact
      }

      params.data.AP = this.contact.AP ? 1 : 0

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Updating contact' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.$snack.open('Contact updated.')
          this.$bus.$emit('setWaiting', { name: 'getVendorContacts', message: 'Getting contacts' })
          this.$store.dispatch('getVendorContacts', 1).finally(() => {
            this.$bus.$emit('stopWaiting', 'getVendorContacts')
            this.$bus.$emit('vendorContactUpdate')
          })
        })
        .catch(res => {
          this.$snack.open(res.message || 'Problem updating contact.', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    deleteContact() {
      if (!this.id) {
        this.$snack.open('Cannot delete contact, please reload to try again.', 'warning')
        return
      }

      if (!confirm("Are you sure you'd like to permanently delete this contact?")) {
        return
      }

      const params = {
        action: 'delete_vendor_contact',
        id: this.id
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Deleting contact' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.$snack.open('Contact deleted.')
          this.$bus.$emit('setWaiting', { name: 'getVendorContacts', message: 'Getting contacts' })
          this.$store.dispatch('getVendorContacts', 1).finally(() => {
            this.$bus.$emit('stopWaiting', 'getVendorContacts')
            this.$bus.$emit('vendorContactUpdate')
            this.$bus.$emit('modalClose', 'VendorContactsAddEdit')
          })
        })
        .catch(res => {
          this.$snack.open(res.message || 'Problem deleting contact.', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    getContacts(refresh) {
      this.$bus.$emit('setWaiting', { name: 'getVendorContacts', message: 'Getting Vendor Contacts' })
      this.$store
        .dispatch('getVendorContacts', refresh)
        .then(() => {
          const contact = this.vendorContacts.find(itm => {
            return itm.id == this.id
          })
          if (contact) {
            this.getVendor(contact.Vendor_Code)
            this.contact = contact
          }
          this.$bus.$emit('bhUpdate')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getVendorContacts')
        })
    },

    getVendor(vendorCode) {
      this.$bus.$emit('setWaiting', { name: 'getVendors', message: 'Getting Vendors' })
      this.$store
        .dispatch('getVendors')
        .then(() => {
          const vendor = this.vendors.find(itm => {
            return itm.Vendor_Code == vendorCode
          })
          this.vendor = vendor || {}
          if (this.vendor) {
            this.contact.Vendor_Code = this.vendor.Vendor_Code
          }
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getVendors')
        })
    }
  },

  mounted() {
    this.getContacts()

    if (!this.id && this.selectedVendor) {
      this.getVendor(this.selectedVendor)
      // if (this.vendor) {
      //   console.log(this.vendor)
      //   this.contact.Vendor_Code = this.vendor.Vendor_Code;
      // }
    }
  },

  watch: {
    'contact.Vendor_Code': function (val) {
      this.getVendor(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  min-height: 65px;
}
</style>
