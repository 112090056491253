
<div>
  <div v-if="showFilterRow" class="row">
    <div class="col-sm-12 col-md-4">
      <div class="form-group">
        <label>From Date:</label>
        <date-picker v-model="filterMinDate" :format="'date'" :is-inline="false" :pre-filled="false" @input="getTimeCardEntries()" />
      </div>
      <div class="form-group">
        <label>To Date:</label>
        <date-picker v-model="filterMaxDate" :format="'date'" :is-inline="false" :pre-filled="false" @input="getTimeCardEntries()" />
      </div>
    </div>
    <div class="col-sm-12 col-md-4">
      <div class="form-group">
        <label>Select By Employee:</label>
        <select-field
          v-model="filterEmployee"
          :options="employees"
          :option-display-keys="['Employee_Name']"
          :option-val="'Employee_Code'"
          :empty-option-text="'No options'"
          :allow-clear="true"
          :preselect-single-option="false"
          :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
          @selectItem="getTimeCardEntries(), $refs.selectSubcontractor.selectEl({})"
          ref="selectEmployee"
        />
      </div>
      <div class="form-group">
        <label>Select By Subcontractor:</label>
        <select-field
          v-model="filterSubcontractor"
          :options="subcontractors"
          :option-display-keys="['Name']"
          :option-val="'id'"
          :empty-option-text="'No options'"
          :allow-clear="true"
          :preselect-single-option="false"
          @selectItem="getTimeCardEntries(), $refs.selectEmployee.selectEl({})"
          ref="selectSubcontractor"
        />
      </div>
    </div>
    <div class="col-sm-12 col-md-4">
      <div v-if="type === 'Job' || type === 'Job Extra'">
        <div class="form-group">
          <label>Select By Phase:</label>
          <select-field
            v-model="filterPhase"
            :label-text="'Phase:'"
            :options="phases"
            :option-display-keys="['Phase_Code', 'Description']"
            :option-val="'Phase_Code'"
            :empty-option-text="'Fetching items...'"
            @selectItem="getTimeCardEntries()"
          />
        </div>
      </div>
      <div v-if="type === 'Job' && extraId">
        <div class="form-group" style="margin-bottom: 0">
          <checkbox v-model="showSelected" style="margin: 5px 0"> Show Assigned to this Sign Off</checkbox>
        </div>
        <div class="form-group">
          <checkbox v-model="hideAssigned" style="margin: 5px 0"> Hide Assigned to other Sign Offs</checkbox>
        </div>
      </div>
    </div>
  </div>

  <div style="padding: 20px 0">
    <button class="btn" @click="addTimeEntry" v-if="!isLocked">Add Time Entry</button>
    <p v-else>
      <i>Labor cannot be added to in Locked status.</i>
    </p>

    <button class="btn" @click="addForemanTimeEntry" v-if="!isLocked && isPM">Foreman Time Entry</button>

    <!-- <button v-if="!extraId && isPM && timeCardEntries.entries && timeCardEntries.entries.length" class="btn" @click="syncTimeEntries">Post All to Spectrum</button> -->
    <button v-if="allowDownload" class="btn" @click="$refs.jobWoLabor.exportCSV(`Labor-List-${type}-${itemNumber}`)">Download CSV</button>

    <button class="btn" @click="assignAllFilteredTimeEntries" v-if="!isLocked">Assign All</button>
  </div>

  <p v-if="showEntriesWarning" class="large-result-warning">
    <i><strong>Large number of entries, please use add filter criteria, OR Download CSV.</strong></i>
  </p>

  <p v-else>
    <i>Showing {{ filteredTimecardEntries.length }} of {{ entryCount }} in all dates</i>
    <br /><br />
  </p>

  <tabulator-table v-show="!showEntriesWarning" :table-data="filteredTimecardEntries" :table-columns="tableColumns" table-fit="fitColumns" :table-condensed="false" empty-text="(none)" ref="jobWoLabor" @colClick="tableClick" />

  <waiting-spinner />
</div>
