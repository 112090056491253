<template>
  <div class="notices is-top not-pdf">
    <transition
      enter-active-class="fadeInUp"
      leave-active-class="fadeOut"
    >
      <div
        v-if="snack && snack.message"
        :class="['snackbar', snack.type,'is-top']"
      >
        <div class="text" v-html="snack.message"/>
        <div
          v-if="snack.actionText"
          class="action"
          @click="snackAction(snack)"
          :class="snack.type"
        >
          <button class="button">
            {{ snack.actionText }}
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  // based on Buefy Snackbar, adds better dismiss and duplicate prevention
  name: 'Snackbar',
  data() {
    return {
      snackbars: []
    };
  },
  props: {
    actionText: {
      type: String,
      default: 'OK'
    },
    onAction: {
      type: Function,
      default: () => {
      }
    },
    indefinite: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    snack() {
      let snacks = this.snackbars;

      snacks.sort((a, b) => {
        return a.priority - b.priority;
      });

      let length = snacks.length;
      if (length > 0) {
        return snacks[length - 1];
      }
      return {};
    }
  },
  methods: {
    addSnack(message, type, action, actionText, priority, persist) {
      if (!message) return;
      action = typeof action === 'function' ? action : () => {
      };
      actionText = actionText || 'OK';
      persist = persist ? persist : false;
      let persistTime = 3000;
      switch (type) {
        case 'warning':
          type = 'is-warning';
          persistTime = 6000;
          break;
        case 'error':
          type = 'is-danger';
          persist = true;
          break;
        default:
          type = 'is-success';
      }
      ;

      const snack = {
        message,
        type,
        action,
        actionText,
        priority: priority || 0
      };
      let exists = this.snackbars.find(itm => {
        return itm.message === snack.message;
      });
      if (!exists) {
        this.snackbars.push(snack);
      }

      if (!persist) {
        setTimeout(() => {
          this.removeSnack(snack);
        }, persistTime);
      }
    },

    snackAction(snack) {
      snack.action();
      this.removeSnack(snack);
    },

    removeSnack(snack) {
      if (!snack.message) return;
      let index = this.snackbars.findIndex((itm) => {
        return itm && itm.message === snack.message;
      });
      this.snackbars.splice(index, 1);
    },

    removeAll() {
      this.snackbars = [];
    },

    // isLatest (snack) {
    //   let index = this.snackbars.findIndex((itm) => {
    //     if (itm && itm.message === snack.message) {
    //       return true;
    //     }
    //   });
    //   if (index !== 'undefined' && index === this.snackbars.length - 1) {
    //     return true;
    //   }
    // }
  },
};
</script>

<style lang="scss">

@import "src/assets/sass/paper/_variables.scss";

.notices {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0; //2em;
  overflow: hidden;
  z-index: 100000000;
  pointer-events: none;
  top: 0;

  @media(min-width: 769px) {
    top: 10px;
  }

}

.notices .snackbar {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  animation-duration: 150ms;
  margin: 0; //0.5em 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  pointer-events: auto;
  background: #363636;
  color: whitesmoke;
  min-height: 3em;
  font-size: 18px;


  // max-width:100%;
  // width:100%;

  &.is-danger {
    background: red;
  }

  &.is-warning {
    background: $general-yellow;
    color: $font-color;
  }

  &.is-success {
    background: $general-green;
    color: $font-color;
  }
}

.notices .snackbar .text {
  padding: 1.5em;
}

.notices .snackbar .action {
  margin-left: auto;
  padding: 0.5em;
  padding-left: 0;
  padding-right: 1em;
}

.notices .snackbar .action .button {
  font-weight: 600;
  text-transform: uppercase;
  background: #363636;
  border: transparent;
}

.notices .snackbar .action .button:hover {
  background: #292929;
}

.notices .snackbar .action .button:active {
  background: #292929;
}

.notices .snackbar .action.is-white .button {
  color: white;
}

.notices .snackbar .action.is-black .button {
  color: #0a0a0a;
}

.notices .snackbar .action.is-light .button {
  color: whitesmoke;
}

.notices .snackbar .action.is-dark .button {
  color: #363636;
}

.notices .snackbar .action.is-primary .button {
  color: #7957d5;
}

.notices .snackbar .action.is-link .button {
  color: #7957d5;
}

.notices .snackbar .action.is-info .button {
  color: #167df0;
}

.notices .snackbar .action.is-success .button {
  color: #23d160;
}

.notices .snackbar .action.is-warning .button {
  color: #ffdd57;
}

.notices .snackbar .action.is-danger .button {
  color: #ff3860;
}

@media screen and (max-width: 768px) {
  .notices .snackbar {
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
}

@media screen and (min-width: 769px), print {
  .notices .snackbar {
    // min-width: 350px;
    // max-width: 600px;
    overflow: hidden;
  }
}

.notices .notification {
  max-width: 600px;
}

.notices .toast.is-top, .notices .toast.is-bottom,
.notices .snackbar.is-top,
.notices .snackbar.is-bottom,
.notices .notification.is-top,
.notices .notification.is-bottom {
  align-self: center;
}

.notices .toast.is-top-right, .notices .toast.is-bottom-right,
.notices .snackbar.is-top-right,
.notices .snackbar.is-bottom-right,
.notices .notification.is-top-right,
.notices .notification.is-bottom-right {
  align-self: flex-end;
}

.notices .toast.is-top-left, .notices .toast.is-bottom-left,
.notices .snackbar.is-top-left,
.notices .snackbar.is-bottom-left,
.notices .notification.is-top-left,
.notices .notification.is-bottom-left {
  align-self: flex-start;
}

.notices .toast.is-toast,
.notices .snackbar.is-toast,
.notices .notification.is-toast {
  opacity: 0.92;
}

.notices.is-top {
  flex-direction: column;
}

.notices.is-bottom {
  flex-direction: column-reverse;
}

.notices.is-bottom .notification {
  margin-bottom: 0;
}

.notices.is-bottom .notification:not(:first-child) {
  margin-bottom: 1.5rem;
}

.notices.has-custom-container {
  position: absolute;
}

@media screen and (max-width: 768px) {
  .notices {
    padding: 0;
    position: fixed !important;
  }
}

</style>
