<script>
export default {
  data() {
    return {}
  },

  methods: {
    changeTab(tab) {
      this.$bus.$emit('checkUnsavedChanges')
      if (this.$store.getters.unsavedData) {
        if (!confirm('Unsaved changes detected, are you sure you want to leave this page?')) {
          return
        }
        this.$store.dispatch('unsavedDataClear')
        this.$bus.$emit('refreshWODetailsBase')
      }
      this.tab = tab
    },

    convertTabsToPills() {
      var navTabs = $('.nav-tabs')

      $.each(navTabs, function (i, v) {
        var nav = v
        var stayPilled = false

        if ($(nav).length) {
          if ($(nav).hasClass('always-pilled')) {
            stayPilled = 1
          }

          $(nav, card).removeClass('nav-pilled, pilled')
          var contentWidth = $(nav).outerWidth()
          var totalWidth = 0

          $(nav)
            .find('li')
            .each(function (i, d) {
              totalWidth += $(d).outerWidth()
            })

          var card = $(nav).parents('.tab-section')
          if (stayPilled || totalWidth + 50 > contentWidth) {
            $(nav).addClass('pilled')
            $(card).addClass('nav-pilled')
          } else {
            $(nav).removeClass('pilled')
            $(card).removeClass('nav-pilled')
          }
        }
      })
    }
  },

  mounted() {
    var self = this
    this.convertTabsToPills()

    setTimeout(() => {
      this.convertTabsToPills()
    }, 200)

    $(window).on('resize', function () {
      self.convertTabsToPills()
    })
  },

  updated() {
    if (typeof this.convertTabsToPills === 'function') {
      //this.convertTabsToPills();
    }
  }
}
import $ from 'jquery'
</script>

<style>
.tab-section:not(.nav-pilled) .Main,
.tab-section:not(.nav-pilled) .first-active {
  border-top-left-radius: 0 !important;
}

.nav-tabs {
  li {
    cursor: pointer;
  }
}
</style>
