<template>
  <div>
    <div class="pdf-body" id="pdf-body">
      <div class="container" v-if="loadSuccess">
        <section>
          <div class="header">
            <div class="row">
              <div class="col-xs-8">
                <router-link to="/">
                  <img :src="urls.frontend + 'static/img/logo.jpg'" />
                </router-link>
              </div>
              <div class="col-xs-4">
                <p>
                  34-966 Pantera Drive<br />
                  Mississauga, Ontario, L4W 2S1<br />
                  Tel: (905) 625-0265 <br />
                  Fax: (905) 625-0268 <br />
                  Email: accountsreceivable@sheridanelectric.ca
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <h3 style="text-align: center" class="section-title"><strong>Statement</strong></h3>
          <div class="statement-info-section">
            <div class="row">
              <div class="col-xs-6 border-section">
                <p>
                  <strong>To:</strong><br />
                  <span v-html="customer.Name" /><br />
                  <span v-if="customer.Address_1" v-html="customer.Address_1 + ', '" />
                  <span v-if="customer.Address_2" v-html="customer.Address_2 + ', '" />
                  <br />
                  <span v-if="customer.Address_3" v-html="customer.Address_3 + ', '" />
                  <span v-if="customer.State" v-html="customer.State + ', '" />
                  <span v-if="customer.Zip_Code" v-html="customer.Zip_Code" />
                </p>
              </div>
              <div class="col-xs-6 border-section">
                <p>
                  <span style="min-width: 150px; display: inline-block"><strong>Period Ending:</strong></span
                  >{{ dateToday }}
                  <br />
                  <span style="min-width: 150px; display: inline-block"><strong>Customer Code:</strong></span
                  >{{ customer.Customer_Code }}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section style="margin: 30px 0">
          <h5 v-if="(manualSend && ccSettings.custom_text) || defaultText" style="margin-bottom: 5px">
            <strong>Attn: Accounts Receivable</strong>
          </h5>
          <p v-if="manualSend && ccSettings.custom_text" v-html="ccSettings.custom_text" />
          <p v-else-if="defaultText">
            {{ defaultText }}
          </p>
        </section>

        <section class="row">
          <table class="">
            <tr>
              <td>
                <strong>Date</strong>
              </td>
              <td>
                <strong>Invoice Number</strong>
              </td>
              <td>
                <strong>Type</strong>
              </td>
              <td>
                <strong>Job/WO</strong>
              </td>
              <td>
                <strong>PO Number</strong>
              </td>
              <td>
                <strong>Contact</strong>
              </td>
              <td>
                <strong>Original Amt</strong>
              </td>
              <td>
                <strong>Prev. Paid</strong>
              </td>
              <td>
                <strong>Retention</strong>
              </td>
              <td>
                <strong>Amount Due</strong>
              </td>
              <td>
                <strong>Due Date</strong>
              </td>
              <td>
                <strong>Status</strong>
              </td>
            </tr>
            <tr v-for="(rItm, i) in arItems" :key="i">
              <td>{{ rItm.niceDate }}</td>
              <td>{{ rItm.transNo }}</td>
              <td>{{ rItm.type }}</td>
              <td>{{ rItm.jobWO }}</td>
              <td>{{ rItm.poNumber }}</td>
              <td>{{ rItm.customerContact }}</td>
              <td>{{ formatNumber(rItm.origAmt) }}</td>
              <td>{{ formatNumber(rItm.prevPaid) }}</td>
              <td>{{ formatNumber(rItm.retention) }}</td>
              <td>{{ formatNumber(rItm.amtDue) }}</td>
              <td>{{ rItm.dueDate }}</td>
              <td>{{ rItm.status }}</td>
            </tr>

            <tr>
              <td style="border: none; padding: 5px"></td>
            </tr>

            <tr class="totals-row">
              <td>Totals</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ formatNumber(origAmtTotal) }}</td>
              <td>{{ formatNumber(prevPaidTotal) }}</td>
              <td>{{ formatNumber(retentionTotal) }}</td>
              <td>{{ formatNumber(amtDueTotal) }}</td>
              <td></td>
              <td></td>
            </tr>
          </table>
          <p v-if="!arItems || !arItems.length">
            <i>No Receivables found</i>
          </p>
        </section>

        <section class="row" style="margin-top: 30px">
          <p style="text-align: center; font-size: 18px"><strong>Overdue Amounts</strong></p>
          <table class="">
            <tr>
              <td />
              <td />
              <td><strong>Current</strong></td>
              <td><strong>1 to 30 days</strong></td>
              <td><strong>31 to 60 days</strong></td>
              <td><strong>61 to 90 days</strong></td>
              <td><strong>Over 90 days</strong></td>
              <td><strong>Retention</strong></td>
            </tr>
            <tr>
              <td><strong>Aging Breakdown</strong></td>
              <td />
              <td>{{ formatNumber(currentDue) }}</td>
              <td>{{ formatNumber(total30days) }}</td>
              <td>{{ formatNumber(total60days) }}</td>
              <td>{{ formatNumber(total90days) }}</td>
              <td>{{ formatNumber(totalOver90days) }}</td>
              <td>{{ formatNumber(totalRetention) }}</td>
            </tr>
          </table>
        </section>

        <div v-if="pdf" class="footer" style="margin-bottom: 20px">
          <div class="footer-inner">
            <div class="page-number">
              <p>Page 1 of 1</p>
            </div>
          </div>
        </div>

        <!-- <div class="row not-pdf">
          <div class="col-sm-12" style="margin-bottom:15px">
            <hr>
            <div v-if="apContact.Email">
              <button
                class="btn"
                @click="sendReport"
                v-html="'Send Report'"
              />
              <div style="italic">
                <strong>AP Contacts:</strong>
                <span v-for="(contact, apci) in apContacts" :key="apci">
                  <span v-html="contact.Email+ (apContacts.length > 1 && apci + 1 !== apContacts.length ? ', ' : '')" />
                </span>
              </div>
            </div>
            <p v-else>
              <i>No AP Contact found for this vendor</i>
            </p>
          </div>
        </div> -->
      </div>

      <waiting-spinner :wait="wait" />
    </div>
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import dateFormat from 'dateformat'
import moment from 'moment'

export default {
  components: { WaitingSpinner },
  data() {
    return {
      wait: {
        message: ''
      },
      loadSuccess: false,
      itmCode: null,
      arItems: null,
      customer: {},
      origAmtTotal: 0,
      prevPaidTotal: 0,
      amtDueTotal: 0,
      retentionTotal: 0,
      currentDue: 0,
      total30days: 0,
      total60days: 0,
      total90days: 0,
      totalOver90days: 0,
      totalRetention: 0,
      ccSettings: {},
      defaultText: ''
    }
  },

  props: {
    authCode: {
      type: String,
      required: true
    },
    pdf: {
      type: [String, Boolean],
      default: false
    },
    manualSend: {
      type: [String, Boolean],
      default: false
    }
  },

  computed: {
    sherAuth() {
      return this.$store.getters.sherAuth
    },

    urls() {
      return this.$store.getters.urls
    },

    dateToday() {
      return dateFormat(new Date(), 'mmm dS, yyyy')
    }
  },

  methods: {
    getData() {
      if (!this.authCode) {
        return
      }
      this.wait.message = 'Fetching data'

      const params = {
        action: 'accounts_receivable_customer_get_report',
        auth_code: this.authCode
      }

      appFuncs
        .shRequest(params, 1)
        .then(data => {
          if (data.arItems) {
            this.setTableData(data.arItems)
          }
          if (data.customerData) {
            this.customer = data.customerData
          }
          if (data.ccSettings) {
            this.ccSettings = data.ccSettings
          }
          if (data.defaultText) {
            this.defaultText = data.defaultText
          }
          if (data.arItems && data.customerData && data.ccSettings) {
            this.loadSuccess = true
          }
        })
        .catch(res => {
          console.log(res)
        })
        .finally(() => {
          this.wait.message = ''
        })
    },

    setTableData(data) {
      let out = []
      ;(this.amtDueTotal = 0), (this.retentionTotal = 0), (this.prevPaidTotal = 0), (this.origAmtTotal = 0)
      ;(this.currentDue = 0), (this.total30days = 0), (this.total60days = 0), (this.total90days = 0), (this.totalOver90days = 0)

      // data = data.concat(data);
      // data = data.concat(data);

      data = data.filter(itm => {
        return itm.Transaction_Type === 'I'
      })

      for (var i = 0; i < data.length; i++) {
        let itm = {
          niceDate: this.getNiceDate(data[i].Invoice_Date),
          transNo: data[i].Invoice_Or_Transaction,
          type: data[i].Transaction_Type === 'I' ? 'Invoice' : data[i].Transaction_Type,
          jobWO: data[i].Job_WO_Ref,
          origAmt: parseFloat(data[i].Invoice_Amount),
          prevPaid: parseFloat(data[i].Amount_Paid),
          retention: parseFloat(data[i].Retention_Balance),
          amtDue: parseFloat(data[i].Invoice_Balance),
          dueDate: this.getNiceDate(data[i].Invoice_Due_Date),
          status: `Past due ${Math.round(this.getDays(data[i].Invoice_Due_Date))} days`,
          poNumber: data[i].Customer_PO,
          customerContact: data[i].Owner_Name
        }

        this.amtDueTotal += itm.amtDue
        this.retentionTotal += itm.retention
        this.prevPaidTotal += itm.prevPaid
        this.origAmtTotal += itm.origAmt

        this.totalRetention += parseFloat(itm.retention)

        let daysDue = this.getDays(data[i].Invoice_Due_Date)
        let amt = itm.amtDue - itm.retention

        if (daysDue >= 91) {
          this.totalOver90days += amt
        } else if (daysDue >= 61) {
          this.total90days += amt
        } else if (daysDue >= 31) {
          this.total60days += amt
        } else if (daysDue >= 1) {
          this.total30days += amt
        } else {
          this.currentDue += amt
        }

        // switch (true) {
        //   case daysDue >= 91:
        //     this.totalOver90days += amt;
        //     break;
        //   case daysDue >= 61:
        //     this.total90days += amt;
        //     break;
        //   case daysDue >= 31:
        //     this.total60days += amt;
        //     break;
        //   case daysDue >= 1:
        //     this.total30days += amt;
        //     break;
        //   default:
        //     this.currentDue += amt;
        // }

        // switch (true) {
        //   case daysDue < 1:
        //     this.currentDue += amt;
        //     break;
        //   case daysDue <= 31:
        //     this.total30days += amt;
        //     break;
        //   case daysDue <= 61:
        //     this.total60days += amt;
        //     break;
        //   case daysDue <= 90:
        //     this.total90days += amt;
        //     break;
        //   case daysDue > 90:
        //     this.totalOver90days += amt;
        // }

        out.push(itm)
      }
      this.arItems = out
    },

    getPlainText(text) {
      return appFuncs.getPlainText(text)
    },

    getNiceDate(str) {
      return dateFormat(Date.parse(str), 'mm/dd/yyyy')
    },

    formatNumber(val) {
      return appFuncs.formatNumber(val, 1)
    },

    pdfClearModifications() {
      let footer = document.getElementsByClassName('footer')[0]
      if (!footer) {
        return
      }
      // remove all previously added content
      let addedContent = document.getElementsByClassName('added-content')
      while (addedContent[0]) {
        addedContent[0].parentNode.removeChild(addedContent[0])
      }

      let addedSpacers = document.getElementsByClassName('added-spacer')
      while (addedSpacers[0]) {
        addedSpacers[0].parentNode.removeChild(addedSpacers[0])
      }
      let addedLines = document.getElementsByClassName('measure-line')
      while (addedLines[0]) {
        addedLines[0].parentNode.removeChild(addedLines[0])
      }
      let pageNum = footer.getElementsByClassName('page-number')
      if (pageNum && pageNum[0]) {
        pageNum[0].innerHTML = '<p>Page 1 of 1<p>'
      }
    },

    pdfSpaceSections() {
      let mainEl = document.getElementById('pdf-body')
      let sections = Array.prototype.slice.call(document.querySelectorAll('section'))

      let header = document.getElementsByClassName('header')[0]
      let footer = document.getElementsByClassName('footer')[0]
      let footerHeight = footer.offsetHeight + parseInt(footer.style.marginBottom)

      const pageHeight = 1490
      let nextPageBottom = pageHeight
      let pageCount = 1

      sections.forEach(function (itm) {
        let top = itm.getBoundingClientRect().top + document.documentElement.scrollTop
        let itmHeight = itm.offsetHeight
        let bottom = itmHeight + top + 100
        let sectionTitle = itm.querySelectorAll('.section-title')
        let sectionHeaderHeight = 0
        if (sectionTitle && sectionTitle[0]) {
          sectionHeaderHeight = sectionTitle[0].offsetHeight
        }
        let sectionHeader = null
        if (sectionTitle && sectionTitle[0]) {
          sectionHeader = document.createElement('div')
          sectionHeader.innerHTML = sectionTitle[0].outerHTML
        }

        // if page break is between top and bottom, add enough margin to get on next page
        if (top < nextPageBottom && bottom > nextPageBottom) {
          // if section is taller than page, add break between table rows
          if (itmHeight > 500) {
            let rows = itm.getElementsByTagName('tr')
            Array.from(rows).forEach(function (rowItm) {
              let rowTop = rowItm.getBoundingClientRect().top + document.documentElement.scrollTop
              let rowHeight = rowItm.offsetHeight
              let rowBottom = rowHeight + rowTop + 100
              if (rowTop < nextPageBottom && rowBottom > nextPageBottom) {
                let marginNeeded = pageCount * pageHeight - rowTop + header.offsetHeight + sectionHeaderHeight
                let spacer = document.createElement('div')
                spacer.classList.add('added-spacer')
                spacer.style.paddingTop = marginNeeded + 'px' // use padding to prevent top of margin issue from not creating space on next page.
                rowItm.insertAdjacentHTML('beforebegin', spacer.outerHTML)
                if (sectionHeader) {
                  rowItm.insertAdjacentHTML('beforebegin', sectionHeader.outerHTML)
                }

                nextPageBottom += pageHeight
                pageCount++
              }
            })
          } else {
            let marginNeeded = pageCount * pageHeight - top + header.offsetHeight + sectionHeaderHeight
            let spacer = document.createElement('div')
            spacer.classList.add('added-spacer')
            spacer.style.paddingTop = marginNeeded + 'px' // use padding to prevent top of margin issue from not creating space on next page.
            itm.insertAdjacentHTML('beforebegin', spacer.outerHTML)
            itm.insertAdjacentHTML('beforebegin', sectionHeader.outerHTML)
            nextPageBottom += pageHeight
            pageCount++
          }
        }
      })

      let newFooter = null
      let addedContent = null
      let pageNo = 0
      let topPos = 0
      let pageNum = null
      for (var i = 0; i < pageCount - 1; i++) {
        // -1 dont do last page, use original footer
        pageNo++
        newFooter = footer.cloneNode(true)
        pageNum = newFooter.getElementsByClassName('page-number')
        if (pageNum && pageNum[0]) {
          pageNum[0].innerHTML = '<p>Page ' + pageNo + ' of ' + pageCount + '<p>'
        }
        topPos = pageNo * pageHeight - footerHeight
        addedContent = '<div class="added-content" style="top:' + topPos + 'px">' + newFooter.outerHTML + '<div style="margin-top:0px">' + header.outerHTML + '</div>' + '</div>'
        mainEl.insertAdjacentHTML('afterend', addedContent)
      }

      // update original footer now at bottom of page
      let pageNumLast = footer.getElementsByClassName('page-number')
      if (pageNumLast && pageNumLast[0]) {
        pageNumLast[0].innerHTML = '<p>Page ' + pageCount + ' of ' + pageCount + '<p>'
      }

      // this.addGridLines();
    },

    addGridLines() {
      let addedContent = document.getElementsByClassName('measure-line')
      while (addedContent[0]) {
        addedContent[0].parentNode.removeChild(addedContent[0])
      }

      let mainEl = document.getElementById('pdf-body').offsetHeight
      let parts = mainEl / 10
      let line = null
      for (var i = 0; i < parts; i++) {
        line = document.createElement('div')
        line.setAttribute('class', 'measure-line')
        line.style.top = i * 10 + 'px'
        line.innerHTML = i * 10
        if ((i * 10) % 1490 == 0) {
          line.style.background = 'purple'
        }
        document.getElementsByTagName('body')[0].appendChild(line)
      }
    }
  },

  mounted() {
    this.debug = 'mounted called'
    this.getData()
  },

  updated() {
    if (this.pdf) {
      this.pdfClearModifications()
      setTimeout(() => {
        this.pdfSpaceSections()
      }, 150)
    }
  }
}
</script>

<style lang="scss">
.measure-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background: red;
}

.added-content {
  width: 100%;
  position: absolute;

  .header {
    max-width: 1100px;
    margin: 0 auto;
  }

  .footer-inner {
    padding: 0 15px;
  }
}
</style>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

section:after {
  content: '';
  display: table;
  clear: both;
}

// pdf styles
.pdf-body,
section {
  width: 1100px;
  margin: 0 auto;
}

.row {
  margin: 0;
}

.pdf-body .container {
  max-width: 1100px;
  margin: 0 auto;
}

.header {
  padding: 40px 0 10px;

  img {
    width: 100%;
    max-width: 300px;
    margin-left: -8px;
  }

  h4 {
    margin: 0;
  }

  h5 {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    > .col-xs-12 {
      text-align: center;
    }
  }
}

hr {
  border-color: $dark-gray;
}

table {
  width: 100%;
  margin-bottom: 20px;

  td,
  th {
    border: 1px solid $other-medium-gray;
    padding: 3px;
    min-width: 90px;
    word-break: break-word;
  }

  &:not(.border-bottom) {
    td,
    th {
      //  border-bottom:0;
    }
  }

  .col-narrow {
    width: 80px;
  }

  .col-medium {
    width: 150px;
  }

  .totals-row {
    td {
      border-width: 3px;
      border-left: none;
      border-right: none;
      font-weight: bold;

      &:first-of-type {
        border-left: 2px solid;
      }

      &:last-of-type {
        border-right: 2px solid;
      }
    }
  }
}

.footer {
  .footer-inner {
    max-width: 1100px;
    margin: 0 auto;
  }

  .page-number {
    text-align: right;
  }
}

.statement-info-section {
  margin-bottom: 20px;

  .border-section {
    padding: 20px;
    border: 2px dashed #000;
    min-height: 150px;

    &:first-of-type {
      border-right: none;
    }
  }

  p {
    font-size: 16px;
  }
}
</style>
