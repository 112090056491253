
<div class="sh-widget wide-large card my-jobs-widget">
  <div class="content">
    <h3>
      My Active Jobs ({{ count }})
      <router-link :to="{ name: 'Jobs' }" class="ti-new-window is-link" tag="i" />
    </h3>
    <div class="content-inner show-scrollbars">
      <bh-table :table-settings="tableSettings" :style="{ maxWidth: '650px' }" @colClick="goToItem" />
      <waiting-spinner :contained="true" />
    </div>
  </div>
</div>
