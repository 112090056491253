
<div>
  <div>
    <div class="tab-section outline white">
      <ul class="nav nav-tabs">
        <li v-if="!enterFromJobAdd" role="presentation" :class="[{ active: tab == 'Phases' }]">
          <a href="#" @click="tab = 'Phases'">Phase Listing</a>
        </li>
        <li v-if="(showRestricted && isPM) || enterFromJobAdd" role="presentation" :class="[{ active: tab == 'Details' }]">
          <a href="#" @click="tab = 'Details'">Phases</a>
        </li>
        <li v-if="isPM && showDetailsTab && showRestricted" role="presentation" :class="[{ active: tab == 'Numbers' }]">
          <a href="#" @click="tab = 'Numbers'">JTD Cost</a>
        </li>
      </ul>
      <div :class="[{ 'first-active': tab == 'Phases' }, 'tab', tab, 'tab-content']">
        <div v-show="tab === 'Phases'">
          <tabulator-table
            :table-data="phasesWithCostTypes"
            :table-columns="phasesWithCostTypeColumns"
            table-fit="fitColumns"
            :table-condensed="false"
            empty-text="(none)"
            @colClick="colClick"
            :row-formatter="rowFormatter"
          />
        </div>
        <div v-show="tab === 'Numbers'">
          <p>Note: Cost and Current return fields do not include contract labor hours, Pre-time card information, committed costs or unapproved invoices.</p>
          <tabulator-table
            :table-data="phaseDetailsWithTotals"
            :table-columns="tableColumnsNumbers"
            table-fit="fitColumns"
            :table-condensed="false"
            empty-text="(none)"
            @colClick="colClick"
            :row-formatter="rowFormatter"
            :data-tree="true"
            :data-tree-start-expanded="false"
          />
        </div>
        <div v-show="tab === 'Details' && isPM">
          <tabulator-table
            :table-data="phasesWithTotals"
            :table-columns="tableColumns"
            table-fit="fitColumns"
            :table-condensed="false"
            empty-text="(none)"
            @colClick="colClick"
            :row-formatter="rowFormatter"
            :selectable="generatedFromQuote && enableSelectCombine"
            ref="detailsTable"
            @rowSelected="detailsRowSelected"
            @rowDeselected="detailsRowDeselected"
          />
          <br />

          <div v-if="generatedFromQuote">
            <div class="row combine-phases-dialog">
              <div class="col col-sm-12 col-md-6 col-lg-3">
                <checkbox2 v-model="enableSelectCombine" :true-val="true" :false-val="false"> Enable Combine Phases</checkbox2>
              </div>
              <div v-if="enableSelectCombine" class="col col-sm-12 col-md-6 col-lg-3">
                <checkbox2 v-model="combineAllCostTypes" :true-val="true" :false-val="false"> Combine all Cost Types</checkbox2>
              </div>
            </div>

            <div class="row" v-if="enableSelectCombine">
              <div class="form-group col col-sm-12 col-md-6 col-lg-3">
                <label>Combine in Phase Code</label>
                <input type="number" class="form-control" v-model="combineAsPhaseCode" />
              </div>
              <div class="form-group col col-sm-12 col-md-6 col-lg-3">
                <label>Combine in Phase Description</label>
                <input type="text" class="form-control" v-model="combineAsPhaseDesc" />
              </div>
              <div class="col col-sm-12">
                <p><i>Note that only alike cost types will be combined.</i></p>
                <button v-if="generatedFromQuote" class="btn btn-sm" @click="combinePhases">Combine Phases</button>
                <button v-if="jobPhasesSnapshots.length" class="btn btn-sm" @click="undoLastChange">Undo</button>
              </div>
            </div>
          </div>

          <br /><br />

          <div v-if="quoteTotal">
            <div class="form-group">
              <label>Quote Cost Total</label>
              <p>{{ formatNumber(quoteTotal, 1) }}</p>
            </div>
          </div>

          <div v-if="quotePdf">
            <div class="form-group">
              <label>Quote PDF:</label>
              <a :href="quotePdf" target="_blank"><i class="ti ti-new-window" /></a>
            </div>
          </div>

          <br />
        </div>
        <br /><br />
        <div v-show="tab === 'Details' || tab === 'Phases'">
          <div>
            <div v-if="!isLocked">
              <button v-if="(!job.quote_number || jobPhases.length) && !generatedFromQuote && isPM" class="btn" @click="addPhase">Add Phase</button>
              <button v-if="job.quote_number && !jobPhases.length && isPM" class="btn" @click="generateFromQuote">Generate from Quote</button>
              <!-- <button v-if="job.quote_number" class="btn" @click="generateFromQuote">Generate from Quote</button> -->
              <button v-if="enterFromJobAdd && isPM" class="btn" @click="next()">Save and Next</button>
              <button v-else-if="jobPhases.length && isPM" class="btn" @click="savePhases()">Save all Phases</button>
            </div>
            <p v-else>
              <i>Phases cannot be added to in Locked status.</i>
            </p>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</div>
