<template>
  <div class="job-files">
    <div v-if="!jobError">
      <div>
        <h5>File Upload</h5>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="form-group">
              <label>Drawer</label>
              <select-field :options="drawers" :option-display-keys="['name']" :option-val="'val'" v-model="fileDrawer" :fake-disabled="false" @selectItem="addTransactionSimple(null, true)" />
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="form-group">
              <label>Name</label>
              <input type="text" v-model="fileDescription" class="form-control" :disabled="existingTransaction" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-sm-12">
            <div :id="dzId" class="dropzone" style="margin-bottom: 20px" />
            <button class="btn" @click="uploadFile" v-html="existingTransaction ? 'Upload' : 'Create New'" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col col-sm-12">
          <h5>Documents</h5>
          <tabulator-table :table-data="uploadedDocsFiltered" :table-columns="tableColumnsDocs" table-fit="fitColumns" empty-text="(none)" @colClick="docsTableClick" />
        </div>
      </div>
      <br /><br />

      <div class="row">
        <div class="col col-sm-12">
          <h5>All Transactions</h5>
          <tabulator-table :table-data="existingTransactions" :table-columns="tableColumns" table-fit="fitColumns" empty-text="(none)" @colClick="transactionsTableClick" />
          <p style="margin: 10px 0">Click row to select transaction</p>
        </div>
      </div>
      <br /><br />
      <button v-if="inSequence" class="btn" @click="next()" v-html="'Next'" />
    </div>
    <p v-else>Problem loading Job Data, please close and continue from Job Details pages.</p>
    <waiting-spinner />
  </div>
</template>
<script>
import NavTabs from 'mixins/navTabs'
import appFuncs from 'appFuncs'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import SelectField from 'components/UIComponents/SelectField'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import Dropzone from 'dropzone'

import 'dropzone/dist/dropzone.css'

export default {
  data() {
    return {
      jobError: false,
      tab: 'Plans',
      transactions: [],
      selectedTransactionId: null,
      newFileName: '',
      selectedTransaction: {},
      transactionSetDrawer: false,
      fileDrawer: '',
      fileDescription: '',
      dropzoneSet: false,
      dzId: '',
      dzObj: null,
      successfulUploads: 0,
      fileCount: 0,
      typeGroup: 'job',
      uploadedDocs: [],
      drawers: [
        { name: 'COMPLIANCE', val: 'COMPLIANCE' },
        { name: 'CORRESPONDENCE', val: 'CORRESPONDENCE' },
        { name: 'PROJECT DOCUMENTS', val: 'PROJECT DOCUMENTS' },
        { name: 'PURCHASE ORDER', val: 'PURCHASE ORDER' },
        { name: 'PURCHASER', val: 'PURCHASER' },
        { name: 'QUOTATION', val: 'QUOTATION' },
        { name: 'QUOTE COSTING SHEET', val: 'QUOTE COSTING SHEET' },
        { name: 'SERVICE REPORT', val: 'SERVICE REPORT' }
      ],
      drawersDev: [
        { name: 'COMPLIANCE', val: 'COMPLIANCE' },
        { name: 'CORRESPONDENCE', val: 'CORRESPONDENCE' },
        { name: 'PURCHASE ORDER', val: 'PURCHASE ORDER' },
        { name: 'TEST', val: 'TEST' },
        { name: 'TIMECARDS', val: 'TIMECARDS' }
      ],
      existingTransactions: []
    }
  },

  props: {
    jobId: {
      type: [Number, String],
      required: true
    },
    inSequence: {
      type: Boolean,
      default: false
    }
  },

  components: {
    TabulatorTable,
    SelectField,
    WaitingSpinner
  },

  mixins: [NavTabs],

  computed: {
    tableColumns() {
      return [
        {
          field: 'ProjectLogTran_Id',
          width: 100,
          sorter: false,
          visible: false
        },
        // {
        //   field: 'Topic',
        //   title: 'Name',
        //   width: 250,
        //   sorter: false,
        //   cssClass: 'is-link'
        // },
        {
          field: 'Transaction_Description',
          title: 'Transaction Description',
          width: 250,
          sorter: false,
          cssClass: 'is-link'
        },
        {
          field: 'Drawer',
          title: 'Drawer',
          width: 250,
          sorter: false,
          cssClass: 'is-link'
        },
        {
          field: 'Issue_Date',
          title: 'Issue Date',
          width: 200,
          sorter: false,
          cssClass: 'is-link'
        }
      ]
    },

    tableColumnsDocs() {
      return [
        {
          field: 'Drawer',
          width: 200,
          sorter: false,
          visible: false
        },
        {
          field: 'Image_Filename',
          title: 'Name',
          width: 200,
          sorter: false,
          cssClass: 'is-link'
        },
        {
          field: 'Image_Description',
          title: 'Description',
          width: 250,
          sorter: false,
          cssClass: 'is-link'
        },
        {
          field: 'Create_Date',
          title: 'Create Date',
          width: 250,
          sorter: false,
          cssClass: 'is-link'
        }
      ]
    },

    existingTransaction() {
      if (!this.selectedTransaction || !this.selectedTransaction.ProjectLogTran_Id) return false
      return !this.selectedTransaction.ProjectLogTran_Id.startsWith('NEW_')
    },

    transactionUploadData() {
      return {
        Job_Number: this.jobId,
        Image_Type: null, //'PDF',
        Image_Description: this.fileDescription,
        // Category: this.selectedTransaction.Category,
        Drawer: this.fileDrawer, // this.selectedTransaction.Category,
        Transaction_ID: this.selectedTransaction.ProjectLogTran_Id,
        File_Name: this.newFileName,
        Reference_Num: this.selectedTransaction.Reference_Num,
        Topic: this.selectedTransaction.Topic
      }
    },

    uploadedDocsFiltered() {
      if (this.selectedTransactionId) {
        return this.uploadedDocs.filter(d => d.Reference === this.selectedTransactionId)
      } else {
        return this.uploadedDocs
      }
    },

    selectedTransactionDisplay() {
      if (this.selectedTransactionId) {
        return `${this.selectedTransaction.Topic ? this.selectedTransaction.Topic + ' -' : ''} ${this.selectedTransaction.Drawer}`
      } else {
        return ''
      }
    }
  },

  methods: {
    next() {
      this.$bus.$emit('modalClose', 'job-files')

      // exit back to job
      this.$router.push({ path: `/app/job-details/${this.jobId}` })

      this.scrollTop()
    },

    getTransactions() {
      return new Promise((resolve, reject) => {
        if (!this.jobId) {
          reject()
          return
        }
        var data = {
          action: 'get_all_job_transactions',
          job_number: this.jobId
        }

        this.$bus.$emit('setWaiting', { name: data.action, message: 'Getting transactions' })

        appFuncs
          .shRequest(data)
          .then(data => {
            this.transactions = JSON.parse(JSON.stringify(data))
            this.existingTransactions = data.filter(itm => itm.Drawer)
            resolve()
          })
          .catch(res => {
            console.log(res)
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', data.action)
          })
      })
    },

    transactionsTableClick(obj) {
      const id = ((obj || {}).row || {}).ProjectLogTran_Id || null
      const col = ((obj || {}).cell || {}).field || null
      this.selectTransaction(id)
    },

    selectTransaction(id, keepDrawer) {
      if (!id) {
        // if no saved drawers, clear the options in order to trigger the selected value to clear
        this.selectedTransactionId = null
        this.selectedTransaction = {}
        this.fileDrawer = ''
        this.transactionSetDrawer = false
      } else {
        // when selecting a transaction id after adding a new one, the transaction will be in the list
        this.selectedTransactionId = id
        this.selectedTransaction = this.transactions.find(t => t.ProjectLogTran_Id === id)
        const selectedTransactionHasDrawer = this.selectedTransaction && this.selectedTransaction.Drawer

        if (selectedTransactionHasDrawer) {
          this.fileDrawer = this.selectedTransaction.Drawer
          this.fileDescription = this.selectedTransaction.Transaction_Description
          this.transactionSetDrawer = true
        }

        // clears drawers if transaction is new or not selected
        if ((!keepDrawer && !this.existingTransaction) || !selectedTransactionHasDrawer) {
          const savedDrawers = JSON.parse(JSON.stringify(this.drawers))
          this.drawers = []
          setTimeout(() => {
            this.drawers = savedDrawers
          }, 200)
          this.fileDrawer = ''
          this.transactionSetDrawer = false
        }
      }
    },

    docsTableClick(obj) {
      const id = ((obj || {}).row || {}).Image_Filename || null
      const col = ((obj || {}).cell || {}).field || null

      const file = this.uploadedDocs.find(d => d.Image_Filename === id)
      if (file && file.externalFileLocation) {
        this.openUrlIf200(file.externalFileLocation)
        // window.open(file.externalFileLocation, '_blank')
      }
    },

    openUrlIf200(url) {
      fetch(url)
        .then(response => {
          if (response.status === 200) {
            window.open(url, '_blank')
          } else {
            this.$snack.open('This file must be retrieved within Spectrum', 'warning')
          }
        })
        .catch(error => {
          console.error('Failed to fetch URL:', error)
          this.$snack.open('This file must be retrived within Spectrum', 'warning')
        })
    },

    setDropzone() {
      if (this.dzObj) return
      var id = 'div#' + this.dzId
      this.dzObj = new Dropzone(id, {
        url: this.$store.getters.urls.backend + 'upload-job-file.php',
        params: {
          name: this.dzId
        },
        parallelUploads: 1,
        // uploadMultiple: true, // creates server error currently
        createImageThumbnails: false,
        dictDefaultMessage: 'Drop or click to upload',
        autoProcessQueue: false,
        addRemoveLinks: true
        // clickable: this.useSimpleUpload ? true : Dropzone.defaultOptions.clickable
        // acceptedFiles: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,application/vnd.ms-powerpoint,application/pdf,image/png,application/rtf,text/plain,application/zip,text/csv,image/bmp,application/epub+zip,video/mpeg,audio/wav,audio/webm,video/webm,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-outlook,application/x-hwp,application/octet-stream,application/x-hwp,application/CDFV2-unknown,message/rfc822',
      })

      this.dzObj.on('addedfile', (file, xhr, formData) => {
        return
        this.fileCount++
        const fileName = file.name.split('.').slice(0, -1).join('.')
        if (!this.fileDescription) {
          this.fileDescription = fileName
        }
      })
      // this is called for each file added
      this.dzObj.on('sending', (file, xhr, formData) => {
        formData.append('path', 'job-uploads/' + this.jobId + '/')
        formData.append('subdir', this.selectedTransaction && this.selectedTransaction.Drawer ? this.selectedTransaction.Drawer : this.fileDrawer)
        formData.append('jwt', this.$store.getters.sherAuth.jwt)
        formData.append('user_login', this.$store.getters.sherAuth.user_login)
        formData.append('expires', this.$store.getters.sherAuth.expires)
        formData.append('type_group', this.typeGroup)

        for (let key in this.transactionUploadData) {
          formData.append(`upload_data[${key}]`, this.transactionUploadData[key])
        }
      })

      this.dzObj.on('complete', file => {
        if (this.dzObj.getFilesWithStatus(Dropzone.QUEUED).length > 0) {
          this.dzObj.processQueue() // Process the next file in queue.
        } else {
          this.$bus.$emit('stopWaiting', 'uploadFile')
          this.addTransactionSimple()
        }
      })

      this.dzObj.on('success', file => {
        // get the transaction id and ref from the response
        const resp = ((file || {}).xhr || {}).response || null
        const json = JSON.parse(resp)
        const addedTransId = json && json.data ? json.data.Transaction_ID : null
        const addedTransRef = json && json.data ? json.data.Transaction_Reference_Num : null

        // set the transaction id and ref to the selected transaction so that subsequent uploads will use the same transaction. Added to support adding new transaction with files
        if (addedTransId && addedTransRef) {
          this.selectedTransaction.ProjectLogTran_Id = addedTransId
          this.selectedTransaction.Reference_Num = addedTransRef
        }

        if (this.dzObj.getFilesWithStatus(Dropzone.QUEUED).length === 0) {
          this.getUploadedDocs()
          this.dzObj.removeAllFiles(true)
          this.$snack.open('File uploaded', 'success')
          // this.fileDescription = ''

          this.getTransactions().then(() => {
            if (addedTransId) {
              // set the dropdown field and protect from being changed

              // set this if wanting to select the newly created drawer, currently wanting to clear after uploading
              // this.selectedTransactionId = addedTransId
              this.transactionSetDrawer = true
            }
          })
        }
      })

      this.dzObj.on('error', (file, error, xhr) => {
        // error = typeof error === 'string' ? error : 'Problem uploading file, please try again';
        console.log(file, error, xhr)
        const data = JSON.parse(xhr.response)
        this.$snack.open(data.message || 'File not uploaded', 'warning')
        this.fileCount--
        this.getUploadedDocs()
        // this.dzObj.removeAllFiles(true)
        this.$bus.$emit('stopWaiting', 'uploadFile')
      })

      this.dropzoneSet = true
    },

    getUploadedDocs() {
      return new Promise((resolve, reject) => {
        if (!this.jobId) {
          return
        }
        var data = {
          action: 'get_job_documents',
          job_number: this.jobId
        }

        this.$bus.$emit('setWaiting', { name: data.action, message: 'Getting transactions' })

        appFuncs
          .shRequest(data)
          .then(data => {
            this.uploadedDocs = data
            resolve()
          })
          .catch(res => {
            console.log(res)
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', data.action)
          })
      })
    },

    uploadFile() {
      if (this.dzObj.getFilesWithStatus(Dropzone.QUEUED).length == 0) {
        this.$snack.open('Please select a file to upload', 'warning')
        return
      }

      if (!this.fileDrawer || !this.fileDescription || !this.selectedTransactionId) {
        this.$snack.open('Please select a Drawer and Name', 'warning')
        return
      }
      this.dzObj.processQueue()
      this.$bus.$emit('setWaiting', { name: 'uploadFile', message: 'Uploading file' })
    },

    addTransactionSimple(obj, keepDrawer) {
      // if already new return, prevents drawer from re-selecting .. may just want to remove trigger from drawer select field
      if (this.selectedTransaction && this.selectedTransaction.ProjectLogTran_Id && !this.existingTransaction) return

      // new id is a flag for the server to first create a new transaction
      const newId = `NEW_${this.randomCharacterString(20)}`
      const transactionTopic = '' //obj['Topic,Drawer']
      this.transactions.push({
        ProjectLogTran_Id: newId,
        Topic: transactionTopic,
        Transaction_Description: '',
        Drawer: this.fileDrawer,
        Issue_Date: null,
        Folder: null
      })
      setTimeout(() => {
        this.selectTransaction(newId, keepDrawer)
      }, 400)
    },

    load() {
      this.addTransactionSimple()
      this.getTransactions()
      this.setDropzone()
      this.getUploadedDocs()
    }
  },

  created() {
    this.dzId = 'dz-' + this.randomCharacterString(20)
  },

  mounted() {
    if (!this.jobId) {
      this.jobError = true
    } else {
      this.load()
    }
  },

  watch: {
    jobId() {
      if (this.jobId) {
        this.load()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#download-form {
  display: none;
}

.dropzone {
  padding: 30px !important;
}

.file-upload-container {
  padding: 0px 0 0;
  position: relative;
  min-height: 60px;
  z-index: 1;

  h4 {
    font-size: 16px;
    margin: 5px;
  }

  .file-upload-inner {
    padding: 0px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin: 5px 0 0;
  }

  ul {
    padding: 0;
    list-style-position: inside;
    margin: 0 0 5px;
    display: block;

    li {
      display: inline-block;
      border: 1px solid #9c9c9c;
      padding: 3px 8px;
      margin: 3px 6px 3px 0px;
      border-radius: 5px;
      position: relative;

      .file-delete {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        right: -8px;
        top: -6px;
        text-align: center;
        vertical-align: middle;
        line-height: 12px;
        cursor: pointer;
        background: #ff4343;
        // display: none;

        &:hover {
          display: block;
        }

        i {
          font-size: 7px;
          color: #fff;
        }
      }

      &:hover {
        border-color: #555;

        .file-delete {
          display: block;
        }

        a {
          color: #333;
        }
      }
    }
  }

  .label-link {
    float: right;
    font-size: 14px;
    margin-top: 4px;
    margin: 0 5px 0 10px;
  }

  a {
    cursor: pointer;
    color: #555;
  }

  .no-files-text {
    font-size: 12px;
  }
}

.dropzone {
  border: 2px dashed #ababab;
  min-height: 70px;
  border-radius: 5px;
  background: transparent;
  padding: 5px;
  margin-bottom: 5px;
  position: relative;
  z-index: 1;
}

.area-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.display-as-button {
  margin: 10px 0 !important;

  > div,
  .dz-preview {
    display: none !important;
    visibility: hidden !important;
    margin: 10px 0 !important;
  }

  &.dz-drag-hover {
    border: 2px dashed #ababab !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    display: block;
    border-radius: 5px;
  }
}
</style>
