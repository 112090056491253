<template>
  <div>
    <div class="service-request-send-container">
      <div v-if="serviceReportLog.length">
        <div v-for="(log, ltk) in serviceReportLog" :key="ltk">
          <ul v-if="log.itms && log.time">
            <p>
              <strong>{{ dateFromUnix(log.time, 'show date') }}</strong>
            </p>
            <li v-for="(logItms, li) in log.itms" :key="li">
              <span v-if="logItms.name">{{ logItms.name }}</span>
              <span v-if="logItms.email">{{ logItms.email }}</span>
            </li>
          </ul>
        </div>
      </div>
      <p v-else>(empty)</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceReportSendLog',
  data() {
    return {}
  },

  props: {
    rawLog: {
      type: Array,
      required: true
    }
  },

  computed: {
    serviceReportLog() {
      let out = [] //[{time: null, itms: []}];
      let log = Array.isArray(this.rawLog) && this.rawLog[0] ? this.rawLog : []

      // newest first
      log = log.sort((a, b) => {
        if (b[0] && a[0]) {
          return b[0].time - a[0].time
        }
      })

      for (var i = 0; i < log.length; i++) {
        let logItm = log[i]
        let timeItms = [],
          logItemTime = false
        for (var li = 0; li < logItm.length; li++) {
          logItemTime = logItm[li].time
          timeItms.push({
            email: logItm[li].email,
            name: logItm[li].name
          })
        }
        out.push({
          time: logItemTime,
          itms: timeItms
        })
      }
      return out
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.service-request-send-container {
  max-height: 140px;
  overflow-y: scroll;
  background: $gray-input-bg;
  padding: 10px;
  border-radius: $border-radius-small;
  color: $font-color;

  ul {
    list-style-position: inside;
    margin: 0;
    padding: 0 5px 5px 0;
  }

  li,
  p {
    margin-bottom: 2px;
  }
}
</style>
