
<div class="">
  <div class="row">
    <div class="col-sm-12">
      <div class="sh-widget card wide-large" style="margin-bottom: 20px !important" v-if="isPMp">
        <div class="content">
          <h4>My WOs Summary</h4>
          <div class="table-container show-scrollbar">
            <table>
              <thead>
                <tr>
                  <th><label>Under Billings</label></th>
                  <!-- <th><label>Net Cash Flow</label></th> -->
                  <!-- <th><label>Total Contract Value</label></th>
        <th><label>Billings Remaining</label></th> -->
                </tr>
              </thead>
              <tr>
                <td>{{ formatNumber(wosTotal.Under_Over_Billing, 1) }}</td>
                <!-- <td :class="wosTotal.Net_Cash_Flow > 0 ? 'green' : 'red'">{{ formatNumber(wosTotal.Net_Cash_Flow, 1) }}</td> -->
                <!-- <td>{{ formatNumber(jobsTotal.Total_Contract_Amount, 1) }}</td>
              <td>{{ formatNumber(jobsTotal.Billings_Remaining, 1) }}</td> -->
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="isEditorO || isPM">
    <button :class="'btn'" @click="addWO">Add New</button>
    <br /><br />
  </div>

  <div class="tab-section white">
    <ul class="nav nav-tabs">
      <li role="presentation" :class="[{ active: tab == 'My WOs' }]">
        <a href="#" @click="tab = 'My WOs'"> My WOs </a>
      </li>
      <li v-if="isPM" role="presentation" :class="[{ active: tab == 'All WOs' }]">
        <a href="#" @click="tab = 'All WOs'"> All WOs </a>
      </li>
      <li v-if="isPM" role="presentation" :class="[{ active: tab == 'By Customer' }]">
        <a href="#" @click="tab = 'By Customer'"> By Customer </a>
      </li>
    </ul>

    <div :class="['card', { Main: tab == 'My WOs' }]">
      <div class="content">
        <div v-if="tab == 'My WOs'">
          <span class="inline-checkbox">
            <checkbox v-model="woMissingPoShow"> Show Missing PO's</checkbox>
          </span>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3">
              <region-filter @select-region="selectRegion" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Stage</label>
              <select-field
                :options="[...projectStages, ...signOffStages]"
                :option-display-keys="['description']"
                :option-val="'id'"
                v-model="stageCode"
                :allow-clear="true"
                @selectItem="selectStage"
              />
            </div>
          </div>
          <bh-table :table-settings="tableSettings1" @colClick="colClick" />
        </div>

        <div v-if="tab === 'All WOs'">
          <span class="inline-checkbox">
            <checkbox v-model="woCompleteShow"> Show Complete</checkbox>
          </span>
          <span class="inline-checkbox">
            <checkbox v-model="woMissingPoShow"> Show Missing PO's</checkbox>
          </span>
          <span v-if="woCompleteShow" class="form-group wo-range-select">
            <select class="form-control" v-model="woSearchRange">
              <option v-for="(range, ri) in woRanges" :key="ri" v-html="range.display" :value="range.min" />
            </select>
          </span>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3">
              <region-filter @select-region="selectRegion" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Stage</label>
              <select-field
                :options="[...projectStages, ...signOffStages]"
                :option-display-keys="['description']"
                :option-val="'id'"
                v-model="stageCode"
                :allow-clear="true"
                @selectItem="selectStage"
              />
            </div>
          </div>
          <bh-table :table-settings="tableSettings2" @colClick="colClick" />
        </div>
        <div v-if="tab === 'By Customer'">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3">
              <div class="form-group">
                <customer-select-field v-model="selectByCC" :allow-add-new="false" search-text="Select Customer" />
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <region-filter @select-region="selectRegion" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Stage</label>
              <select-field
                :options="[...projectStages, ...signOffStages]"
                :option-display-keys="['description']"
                :option-val="'id'"
                v-model="stageCode"
                :allow-clear="true"
                @selectItem="selectStage"
              />
            </div>
          </div>
          <bh-table :table-settings="tableSettings3" @colClick="colClick" />
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner />
</div>
