<template>
  <div>
    <div class="row" v-if="typeFilter">
      <div class="col-sm-3">
        <button class="btn" @click="openPO()" style="margin: 0 0 20px">New PO</button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="po-table-container">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3" style="margin-bottom: 20px">
              <label>Type</label>
              <select-field
                :options="[{ name: 'Job' }, { name: 'WO' }]"
                :label-text="'Type'"
                :option-display-keys="['name']"
                :option-val="'name'"
                v-model="typeFilter"
                :allow-clear="true"
                @selectItem="getPOs()"
              />
            </div>
            <div v-if="typeFilter === 'Job'" class="col-sm-12 col-md-6 col-lg-3" style="margin-bottom: 20px">
              <label>Jobs</label>
              <select-field
                v-model="filterJob"
                :options="jobAssemble"
                :option-display-keys="['Job_Number', 'Name', 'Job_Description']"
                :option-val="'Job_Number'"
                :empty-option-text="'No items.'"
                @selectItem="getPOs()"
                :allow-clear="true"
              />
            </div>
            <div v-if="typeFilter === 'WO'" class="col-sm-12 col-md-6 col-lg-3" style="margin-bottom: 20px">
              <label>WOs</label>
              <select-field
                v-model="filterWO"
                :options="woAssemble"
                :option-display-keys="['WO_Number', 'Name', 'Summary_Description']"
                :option-val="'WO_Number'"
                :empty-option-text="'No items.'"
                @selectItem="getPOs()"
                :allow-clear="true"
              />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" style="margin-bottom: 20px">
              <label>Vendor</label>
              <select-field
                :options="vendors"
                :label-text="'Vendors'"
                :option-display-keys="['Vendor_Name']"
                :option-val="'Vendor_Code'"
                v-model="vendorFilter"
                :allow-clear="true"
                @selectItem="getPOs()"
              />
            </div>
            <!-- <div class="col-sm-12 col-md-6 col-lg-3">
              <label>Customer</label>
              <select-field :options="customers" :option-display-keys="['Name']" :option-val="'Customer_Code'" @change="val => getPOs(val)" :value="customerFilter" :allow-clear="true" />
            </div> -->
          </div>

          <div class="card">
            <div class="content">
              <h5>Purchase Orders</h5>
              <bh-table :table-settings="tableSettings" @colClick="openPO" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner :wait="wait" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from 'store'
import appFuncs from 'appFuncs'
import BhTable from 'components/UIComponents/BhTable'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import dateFormat from 'dateformat'
import SelectField from 'components/UIComponents/SelectField'

export default {
  name: 'POs',
  data() {
    return {
      wait: {
        message: ''
      },

      vendorFilter: '',
      vendorOptions: [],
      allPOs: [],
      typeFilter: '',
      customerFilter: '',
      filterJob: '',
      filterWO: ''
    }
  },

  components: {
    BhTable,
    SelectField,
    WaitingSpinner
  },

  props: {},

  computed: {
    ...mapGetters(['vendors', 'employees', 'urls', 'customers', 'jobAssemble', 'woAssemble']),

    tableSettings() {
      return {
        tableData: this.filteredPOs,
        fields: {
          niceDate: {
            name: 'Ordered Date'
          },
          fullPONumber: {
            name: 'PO Number'
          },
          Vendor_Name: {
            name: 'Vendor'
          },
          Ordered_By_Name: {
            name: 'Ordered By'
          },
          Confirmed_By_Name: {
            name: 'Confirmed By'
          },
          Status_Flag: {
            name: 'Status'
          },
          Total_Received_Amount: {
            name: 'Received Amount'
          },
          Non_Taxable_Amount: {
            name: 'Total Amount'
          },
          Last_Received_Date: {
            name: 'Last Received Date'
          }
        },
        hideSearch: true
      }
    },

    filteredPOs() {
      if (this.vendorFilter) {
        return this.allPOs.filter(po => po.Vendor_Code == this.vendorFilter)
      }
      return this.allPOs
    }
  },

  methods: {
    getPOs() {
      const itmCode = this.typeFilter === 'Job' ? this.filterJob : this.typeFilter === 'WO' ? this.filterWO : ''

      if (!itmCode && !this.vendorFilter) {
        return
      }

      const params = {
        action: 'get_purchase_orders',
        type: this.typeFilter,
        itmCode,
        status: 'all',
        daysOld: 2000,
        vendor: this.vendorFilter
      }
      this.wait.message = 'Getting Purchase Orders'
      appFuncs
        .shRequest(params, 1)
        .then((data, res) => {
          if (Array.isArray(data)) {
            for (var i = data.length - 1; i >= 0; i--) {
              data[i].id = data[i].PO_Number
              data[i].niceDate = dateFormat(Date.parse(data[i]['PO_Date_List1']), 'mmm dS, yyyy')
              data[i].Vendor_Name = this.getVendorName(data[i].Vendor_Code) || data[i].Vendor_Code || '(empty)'

              const orderedBy = this.employees.find(itm => {
                return data[i].Ordered_By == itm.Employee_Code
              })

              data[i].Ordered_By_Name = orderedBy ? orderedBy.Employee_Name : data[i].Ordered_By

              const confirmedBy = this.employees.find(itm => {
                return data[i].Confirmed_By == itm.Employee_Code
              })
              data[i].Confirmed_By_Name = confirmedBy ? confirmedBy.Employee_Name : data[i].Confirmed_By

              const phaseMatches = data[i].Comment ? data[i].Comment.match(/^\[\s*(\d+)\s*\]/) : ''
              const phase = phaseMatches ? `-${phaseMatches[1]}` : ''
              data[i].Phase = phase
              data[i].fullPONumber = `${data[i].PO_Number}-${data[i].Job_Number}${phase}`

              const existingVendor = this.vendorOptions.find(vendor => vendor.value === data[i].Vendor_Code)
              if (!existingVendor) {
                this.vendorOptions.push({ name: data[i].Vendor_Name, value: data[i].Vendor_Code })
              }

              data[i].Non_Taxable_Amount = this.formatNumber(data[i].Non_Taxable_Amount, 1)
              data[i].Total_Received_Amount = this.formatNumber(data[i].Total_Received_Amount, 1)

              data[i].Last_Received_Date = data[i]['PO_Date_List3'] ? dateFormat(Date.parse(data[i]['PO_Date_List3']), 'mmm dS, yyyy') : null
            }
            this.allPOs = data
          } else {
            this.$snack.open('Problem getting purchase orders', 'error')
          }
        })
        .catch(res => {
          console.log(res)
          this.$snack.open('Problem getting purchase orders.', 'error')
        })
        .finally(() => {
          this.wait.message = ''
        })
    },

    getVendorName(code) {
      const vendor = this.vendors.find(itm => {
        return itm.Vendor_Code == code
      })
      if (vendor) {
        return vendor.Vendor_Name
      }
      return ''
    },

    openPO(obj) {
      const poNumber = ((obj || {}).itm || {}).PO_Number || ''
      let refFlag = ((obj || {}).itm || {}).Reference_Flag || ''
      let type = this.typeFilter
      if (poNumber) {
        type = refFlag === 'J' ? 'Job' : refFlag === 'W' ? 'WO' : ''
      }
      if (!type) {
        this.$snack.open('Please select a type', 'error')
        return
      }

      this.$Modal({
        parent: this,
        name: 'post-po', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        title: '', //!poNumber ? 'New PO' : 'Edit PO',
        component: () => import('components/Dashboard/PurchaseOrders/OpenPO.vue'),
        props: {
          poNumber,
          type,
          jobNumber: this.filterJob,
          woNumber: this.filterWO
        }
      })
    }
  },

  mounted() {
    const employees = this.$store.dispatch('getEmployees')
    const vendors = this.$store.dispatch('getVendors')
    const customers = this.$store.dispatch('getCustomers')
    const jobs = this.$store.dispatch('getJobAssemble')
    const wos = this.$store.dispatch('getWoAssemble')

    this.$bus.$emit('setWaiting', { name: 'getData', message: 'Getting Data' })
    Promise.all([employees, vendors, customers, jobs, wos])
      .then(() => {
        this.getPOs()
      })
      .finally(() => {
        this.$bus.$emit('stopWaiting', 'getData')
      })

    this.$bus.$on('added-po', this.getPOs)
  },

  beforeDestroy() {
    this.$bus.$off('added-po', this.getPOs)
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';
</style>
