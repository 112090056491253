
<div>
  <div class="widget-table basic-app-widget sh-widget wide-large card" style="min-height: 410px">
    <div class="content">
      <h4>{{ this.departmentName }} Quotes</h4>
      <div class="table-legend">
        <div class="table-legend-item">
          <span class="green" />
          4 or Less
        </div>
        <div class="table-legend-item">
          <span class="yellow" />
          More than 4
        </div>
        <div class="table-legend-item">
          <span class="red" />
          More than 8
        </div>
      </div>
      <tabulator-table
        :table-data="tableDataQuotes"
        :table-columns="tableColumns"
        table-fit="fitColumns"
        :table-condensed="false"
        empty-text="(none)"
        @colClick="colClick"
        :selectable="false"
        :row-formatter="rowFormatter"
        ref="quotesTable"
        :responsive-layout="false"
      />
    </div>
    <waiting-spinner :contained="true" />
  </div>
</div>
