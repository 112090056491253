
<div class="max-width-xlarge">
  <div class="tab-section white">
    <ul class="nav nav-tabs">
      <li role="presentation" :class="[{ active: tab === 'Main' }]">
        <a href="#" @click="changeTab('Main')"> Main </a>
      </li>
      <li
        role="presentation"
        :class="[
          { active: tab === 'Information' },
          { 'requires-item': !checkMetWoRequirement(['wo_completed_notes', 'wo_extras_notes', 'wo_internal_notes', 'wo_recommendation_notes', 'wo_work_ordered_notes']) }
        ]"
      >
        <a href="#" @click="changeTab('Information')"> Info </a>
      </li>
      <li role="presentation" :class="[{ active: tab === 'Files' }, { 'requires-item': !checkMetWoRequirement(['preWorkPhotos', 'postWorkPhotos']) }]" v-if="isPM">
        <a href="#" @click="changeTab('Files')"> Doc's and Photos </a>
      </li>
      <li role="presentation" :class="[{ active: tab === 'Labor' }]">
        <a href="#" @click="changeTab('Labor')"> Labor </a>
      </li>
      <li role="presentation" :class="[{ active: tab === 'Other Charges' }]">
        <a href="#" @click="changeTab('Other Charges')"> Other Charges </a>
      </li>
      <li role="presentation" :class="[{ active: tab === 'Contacts' }]">
        <a href="#" @click="changeTab('Contacts')"> Contacts </a>
      </li>
      <li role="presentation" :class="[{ active: tab === 'Purchasing' }]">
        <a href="#" @click="changeTab('Purchasing')"> POs </a>
      </li>
      <li role="presentation" :class="[{ active: tab === 'PM' }]">
        <a href="#" @click="changeTab('PM')"> PM </a>
      </li>
      <!-- <li v-if="isAdmin || isWOPM" role="presentation" :class="[{active: tab === 'PM'}]">
        <a href="#" @click="changeTab('PM')">
          PM
        </a>
      </li> -->
    </ul>

    <div :class="['card', tab]">
      <div class="content Xtab-content">
        <div v-if="tab == 'Main'">
          <wo-add-edit v-if="woid" :woid="woid" :editable="isEditable" @selectedCustomer="woCustomer" :is-finished="isFinished" :is-locked="isLocked" :key="woid" />
        </div>

        <div v-else-if="tab == 'Information'" :class="['tab', tab]">
          <wo-info :read-only="isLocked" :woid="woid" :customer-code="wo.Bill_Customer_Code" />
          <p v-if="isLocked">
            <i>Notes cannot be changed when WO in Locked status.</i>
          </p>
        </div>

        <div v-else-if="tab == 'Files'" :class="['tab', tab]">
          <wo-files :woid="woid" />
        </div>

        <div v-else-if="tab == 'Other Charges'" :class="['tab', tab]">
          <wo-other-charges :woid="woid" :read-only="isLocked" />
        </div>

        <div v-else-if="tab == 'Labor'" :class="['tab', 'labor', 'tab-labor', tab]">
          <job-wo-labor type="WO" :itm-id="parseInt(woid)" :is-locked="Boolean(isLocked)" :allow-download="true" />
        </div>

        <div v-else-if="tab == 'Contacts'" :class="['tab', 'contacts', 'tab-contacts', tab]">
          <wo-contacts :woid="woid" />
        </div>

        <div v-else-if="tab == 'Purchasing'" :class="['tab', 'purchasing', 'tab-purchasing', tab]">
          <wo-pos :itm-code="woid" :wo="wo" :is-locked="isLocked" />
        </div>

        <div v-else-if="tab == 'PM'" :class="['tab', 'pm', 'tab-pm', tab]">
          <wo-wip-details :input-wo-id="woid" :is-locked="isLocked" ref="woWipDetails" />
        </div>

        <div v-if="tab === 'Costing'">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div :class="'detail-item'">
                <label>Labor Cost</label>
                <p>{{ formatNumber(WOPMDetails.labor_Cost, 1) }}</p>
              </div>

              <div :class="'detail-item'">
                <label>Materials Cost</label>
                <p>{{ formatNumber(WOPMDetails.materials_cost, 1) }}</p>
              </div>

              <div :class="'detail-item'">
                <label>Other Cost Total</label>
                <p>{{ formatNumber(WOPMDetails.other_cost_total, 1) }}</p>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div :class="'detail-item'">
                <label>WO Hours</label>
                <p>{{ formatNumber(WOPMDetails.WO_Hours) }}</p>
              </div>

              <div :class="'detail-item'">
                <label>Total Billed</label>
                <p>{{ formatNumber(WOPMDetails.total_billed, 1) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button v-if="(isAdmin || !isSigned) && isLoaded" class="btn" @click="getOpenWoSignOffUrl()" v-html="'WO Sign Off'" />

  <button v-if="isLocked && isLoaded && canUnlockWOs" class="btn" @click="unLockWO()" v-html="'Unlock'" />

  <a class="btn" v-if="ticketUrl" :href="ticketUrl" target="'_blank" v-html="'Customer Ticket'" />

  <!-- <p v-if="isLocked" class="text-left">
    <i>WO locked (Stage not TBS or In Progress)</i>
  </p> -->

  <waiting-spinner />
  <!-- covers all on this page -->
</div>
