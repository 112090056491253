
<div>
  <div class="">
    <div v-if="showTitle">
      <h4 v-if="jobNumber">
        <strong>Job {{ jobNumber }}</strong>
      </h4>
      <h4 v-if="woNumber">
        <strong>WO {{ woNumber }}</strong>
      </h4>
    </div>

    <div class="row" style="padding: 20px 0 0">
      <div class="col-sm-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label>Billing Task Status</label>
          <select-field :option-display-keys="['name']" :option-val="'value'" v-model="billingTaskStatus" :allow-clear="true" :options="billingTaskStatusOptions" />
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label>Billing Status</label>
          <select-field :option-display-keys="['name']" :option-val="'value'" v-model="taskStatus" :allow-clear="true" :options="statusOptions" />
        </div>
      </div>
    </div>
    <div class="row" style="">
      <div class="col-sm-12">
        <tabulator-table
          :table-data="filteredTasks"
          :table-columns="tableColumns"
          table-fit="fitColumns"
          :table-condensed="false"
          empty-text="(none)"
          @colClick="colClick"
          :rowFormatter="rowFormatter"
          :initial-sort="[
            {
              column: 'date_due',
              dir: 'desc'
            }
          ]"
        />
      </div>
    </div>
    <div class="row" style="padding: 20px 0 0">
      <div class="col-sm-12">
        <div>
          <button class="btn" @click="addTask">Add Task</button>
          <!-- <button class="btn btn-danger" @click="$bus.$emit('modalClose', 'BillingTaskAdd')">Cancel</button>
        <button class="btn" @click="viewTasks">View Tasks</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
