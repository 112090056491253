<template>
  <div>
    <div class="row">
      <div class="col-sm-12 form-column">
        <div v-if="pictures.length" class="imgs-container">
          <div :class="'img-container'" v-for="(picture, pi) in pics" :key="pi">
            <div class="rotate-container">
              <img :src="picture.a_attr.href" @load="imageLoaded">
            </div>
            <p><a target="_blank" :href="picture.a_attr.href">{{ picture.text }}</a></p>
          </div>
        </div>
        <div v-else>
          <p>No pictures added yet.</p>
        </div>
      </div>
    </div>
    <waiting-spinner/>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/exif-js"></script>
<script>

import store from 'store';
import appFuncs from 'appFuncs'
import EXIF from 'exif-js'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  components: {WaitingSpinner},
  props: {
    props: ['componentData'], // legacy

    pictures: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  computed: {
    pics() {
      let pictures = this.pictures.length ? this.pictures : this.componentData.pictures || [];
      if (pictures && pictures.length) {
        // filter out non image file types
        var imgTypes = ['png', 'jpg', 'jpeg', 'gif'];

        var pics = [];
        for (var i = pictures.length - 1; i >= 0; i--) {
          if (pictures[i]['type'] && imgTypes.indexOf(pictures[i]['type']) !== -1) {
            pics.push(pictures[i]);
          }
        }
        return pics;
      }
    }
  },

  methods: {

    imageLoaded(el) {
      if (el.target) {
        EXIF.getData(el.target, function () {
          let tags = EXIF.getAllTags(this);
          let rotate = null;

          if (tags.Orientation) {
            switch (tags.Orientation) {
              case 6:
                rotate = '90';
                break;
              case 8:
                rotate = '270';
                break;
              case 3:
                rotate = '180';
            }
          }

          if (rotate) {
            this.style.transform = 'rotate(' + rotate + 'deg)';
          }
        });
      }
    },
  }
}


</script>

<style lang="scss" scoped>

.imgs-container {
  .img-container {
    display: inline-block;
    margin: 1%;

    .rotate-container {
      overflow: hidden;
    }

    img {
      width: 98%;
      transform-origin: center;
    }

    p {
      padding: 5px;
      margin: 0;
      text-align: center;
    }

    a {
      color: #fff;
      background: #111;
      display: inline-block;
      padding: 3px 10px;
    }
  }

  @media(min-width: 1200px) {
    .img-container {
      width: 48%;
    }
  }
}

</style>
