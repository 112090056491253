<template>
  <div :class="['modal', 'fade', { 'modal-float': modalFloat }]" tabindex="-1" role="dialog" aria-labelledby="siteModalLabel" data-keyboard="false" data-backdrop="static">
    <div class="modal-underlay" v-if="!modalFloat" />
    <div :class="{ 'center-vertically': centerVertically }">
      <div :class="['modal-dialog', modalSize]" role="document" :style="{ minHeight: minHeight + 'px' }">
        <div :class="['modal-content', contentClasses]">
          <div :class="['modal-body']">
            <div>
              <div class="close-icon" v-if="!hideClose" type="button" @click="close()">
                <span aria-hidden="true">&times;</span>
              </div>
              <h4 v-if="title">{{ title }}</h4>
              <div v-if="content" v-html="content" />
              <component v-else :is="component" v-bind="props" @custom-event="customEvent" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  data() {
    return {}
  },

  props: {
    component: {
      type: [Object, Function],
      default: function () {
        return {}
      }
    },
    content: {
      type: String,
      default: ''
    },
    props: {
      type: Object,
      default: function () {
        return {}
      }
    },
    name: {
      type: String,
      required: true
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    contentClasses: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    minHeight: {
      type: Number,
      default: 0
    },
    modalFloat: {
      type: Boolean,
      default: false
    },
    warnOnEsc: {
      type: Boolean,
      default: false
    },
    closeCb: {
      type: Function,
      default: () => {
        return new Promise(resolve => {
          resolve()
        })
      }
    },
    centerVertically: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    modalSize() {
      if (this.size) {
        return 'modal-' + this.size
      }
      return ''
    }
  },

  methods: {
    async close() {
      // deletes this modal from DOM
      this.$emit('destroy')
      this.$bus.$emit('modalClose', 'saveChangesDialog') // close any other of these dialogs to avoid showing any other ones triggered by shared listener
      this.$el.remove()
    },

    customEvent(obj) {},

    closeListener(event) {
      // really need to figure out how to have this only apply to the current modal
      if (event.keyCode == 27) {
        // if hideClose prop is false, warn escape key press
        if (!this.hideClose) {
          if (this.warnOnEsc && !confirm("Are you sure you'd like to close? Any unsaved changes will be lost.")) {
            return
          }
          this.close()
        } else {
          // if hideCLose prop is true, emit close to provided name
          this.$bus.$emit('escClose', this.name)
        }
      }
    },

    listenModalClose(name) {
      if (!name || name === this.name) {
        this.close(true)
      }
    }
  },

  mounted() {
    window.addEventListener('keyup', this.closeListener)
    this.$bus.$on('navigated', () => {
      this.close()
    })
    this.$bus.$on('modalClose', this.listenModalClose)
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.closeListener)
    this.$bus.$off('modalClose', this.listenModalClose)
  }
}
</script>

<style lang="scss">
.modal-dialog {
  h4 {
    margin: 5px 0 15px;
  }
}
</style>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.modal {
  overflow-y: scroll !important;
  opacity: 1;
  display: block;
  top: 0;
  max-height: 100%;

  .modal-dialog {
    width: auto;
  }

  &.modal-float {
    position: absolute;
    bottom: auto;
    left: auto;
    right: auto;
    width: 100%;
    max-width: 1200px;
    left: 50%;
    margin-left: -600px;

    .modal-body {
      overflow-y: scroll;
    }
  }

  .modal-underlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .modal-dialog {
    transform: none !important;
  }

  .modal-body {
    padding: 20px !important;
  }

  .modal-sm {
    max-width: 400px;
    width: 96%;
  }

  .modal-md {
    max-width: 500px;
    width: 96%;
  }

  .modal-lg {
    max-width: 700px;
    width: 96%;
  }

  .modal-xl {
    max-width: 1400px;
    width: 96%;
  }

  .modal-xxl {
    max-width: 2600px;
    width: 96%;
  }

  .modal-full {
    max-width: 100%;
    width: 100%;
    margin: 0;
    height: 100%;
    border-radius: 0;

    .modal-content {
      height: 100%;
      border-radius: 0;
    }
  }

  .modal-content {
    padding: 0px;

    h4 {
      margin-bottom: 20px;
    }

    .button-container {
      margin-top: 15px;

      .btn {
        margin-right: 5px;
      }
    }
  }
}

.center-vertically {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  max-height: 90vh;
  height: 90%;
}
</style>
