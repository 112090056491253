
<div>
  <div v-if="!openedWriteUpWindow">
    <button class="btn btn-no-radius btn-pip btn-blue" @click="editSystems" v-if="!readonly">Add / Edit System</button>

    <button class="btn btn-no-radius btn-pip btn-orange" @click="openInNewTab" v-if="!readonly && showOpenInNewTab && !isOpenInDifferentTab()">Open in New Window</button>

    <br /><br />

    <h4><strong>Scope of Work</strong></h4>
    <tabulator-table :table-data="systems" :table-columns="systemsTableList" :allow-new-row="false" @colClick="systemsTableClick" :table-fit="'fitColumns'" :row-formatter="systemRowFormatter" />

    <br />

    <div class="row writeup-headers">
      <div class="col col-sm-12 col-md-6">
        <button xclass="btn btn-full-width" class="btn btn-no-radius btn-pip btn-blue btn-full-width btn-pip-lg" @click="addCustomHeader()" v-if="!readonly" style="margin-bottom: 20px">
          Add Header
        </button>
      </div>
      <div class="col col-sm-12 col-md-6">
        <button xclass="btn btn-full-width" class="btn btn-no-radius btn-pip btn-blue btn-full-width btn-pip-lg" @click="addHeader()" v-if="!readonly" style="margin-bottom: 20px">
          Add Header from Template
        </button>
      </div>
    </div>

    <div v-for="(h, hi) in currentNotes" :key="hi">
      <quote-write-up-note-header
        ref="quoteWriteUpNoteHeader"
        v-if="h.id"
        :notes="Array.isArray(h.notes) ? h.notes : []"
        :table-content="h.table"
        :header-name="h.name"
        :header-id="h.id"
        @updateNote="updateNote($event, h.id, false)"
        :show-buttons="h.id != 1"
        @addCustomItem="addNote(h.id, h.name, null, true)"
        @addCustomItemBelow="addCustomItemBelow($event, h.id)"
        @cancelNoteEdit="cancelNoteEdit($event, h.id)"
        @deleteNote="deleteNote(h.id, $event)"
        @addFileItem="addHeader(h.id)"
        :systems="systems"
        :readonly="readonly"
        @saveHeaderName="saveHeaderName(h.id, $event)"
        @deleteHeader="deleteHeader(h.id)"
        @moveUp="moveHeaderUp(h.id)"
        @moveDown="moveHeaderDown(h.id)"
        @moveNoteUp="moveNoteUp(h.id, $event)"
        @moveNoteDown="moveNoteDown(h.id, $event)"
        @saveTableNote="saveTableNote(h.id, $event)"
        :sortable="parseInt(h.sort) < 999 && parseInt(h.sort) > 1"
      />
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <file-upload
          v-if="quoteData && quoteData.quote_incr"
          label="Quote Image Files"
          :path="'quote-uploads/' + quoteData.quote_incr + '/' + quoteData.revision + '/quote-image-attachments'"
          :subdir="''"
          :zip-depth="-1"
          name="Quote Image Files"
          :type-group="'image'"
          :key="quoteData.quote_incr + quoteData.revision"
        />
      </div>
      <div class="col-sm-12 col-md-6">
        <file-upload
          v-if="quoteData && quoteData.quote_incr"
          label="Quote Attachments"
          :path="'quote-uploads/' + quoteData.quote_incr + '/' + quoteData.revision + '/quote-attachments'"
          :subdir="''"
          :zip-depth="-1"
          name="Quote Attachments"
          :key="quoteData.quote_incr + quoteData.revision"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <p>Write up has been opened in another tab, please check or re-open this quote window.</p>
  </div>
</div>
