<template>
  <div>
    <simple-header/>
    <div class="materials-admin material-lists-component">
      <br/>
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <select-field :options="[{ name: 'Pending' }, { name: 'Approved' }, { name: 'Rejected' }]" :option-display-keys="['name']" :option-val="'name'"
                          v-model="approvalStatus"
            />
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <button style="margin-right: 20px" class="btn pull-right" @click="approveAll()" v-html="'Approve Selected'"/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <tabulator-table
            ref="materialAdminTable"
            :table-data="currentMaterials"
            :table-columns="materialTableColumns"
            :allow-new-row="false"
            @colClick="materialClick"
            :xtable-height="500"
            :max-table-height="1500"
            :table-striped="true"
            :table-condensed="false"
            :selectable="true"
          />
        </div>
      </div>
    </div>
    <waiting-spinner/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import appFuncs from 'appFuncs'
import SimpleHeader from 'components/Dashboard/Misc/SimpleHeader'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import MaterialPriceFuncs from 'components/Dashboard/Materials/MaterialPriceFuncs'
import Checkbox2 from 'components/UIComponents/Checkbox'
import SelectField from 'components/UIComponents/SelectField'
import QuoteFuncs from 'mixins/quoteFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'MaterialsAdminOneTime',
  data() {
    return {
      currentMaterials: [],
      loadedMaterials: [],
      approvalStatus: 'Pending'
    }
  },

  components: {
    SimpleHeader,
    TabulatorTable,
    SelectField,
    WaitingSpinner
  },

  mixins: [QuoteFuncs],

  computed: {
    ...mapGetters(['employees', 'userAuthedData', 'materialCategories']),

    materialTableColumns() {
      return [
        {
          title: 'Edit',
          field: 'edit',
          frozen: true,
          titleFormatter: val => {
            return ''
          },
          formatter: val => {
            let newRow = (((val._cell || {}).row || {}).data || {}).id || null
            if (newRow !== 'new') {
              return '<i class="ti ti-pencil" />'
            }
          },
          width: 26,
          minWidth: 35,
          headerSort: false
        },
        {
          title: 'Brief Description',
          field: 'brief_description',
          sorter: 'string',
          editable: false,
          width: 240,
          cssClass: 'no-horo-border'
        },
        {
          title: 'SES',
          field: 'id',
          sorter: 'string'
        },
        {
          title: 'Cost',
          field: 'price',
          sorter: 'string',
          formatter: 'money',
          formatterParams: {
            symbol: '$ ',
            precision: 3
          }
        },
        {
          title: 'Status',
          field: 'requested_status',
          sorter: 'string'
        },
        {
          title: 'Requested By User',
          field: 'requested_by_user',
          sorter: 'string',
          formatter: obj => {
            let val = obj.getValue()
            let employeeObj = this.getEmployeeName(val, this.employees)
            return employeeObj.Employee_Name
          }
        },
        {
          title: 'Requested By Date',
          field: 'requested_by_date',
          sorter: 'string',
          formatter: obj => {
            let val = obj.getValue()
            return this.dateFromUnix(val)
          }
        },
        {
          title: 'Reviewer',
          field: 'reviewer',
          sorter: 'string',
          formatter: obj => {
            let val = obj.getValue()
            let employeeObj = this.getEmployeeName(val, this.employees)
            return employeeObj.Employee_Name
          }
        },
        {
          title: 'Manufacturer',
          field: 'manufacturer',
          sorter: 'string'
        },
        {
          title: 'Catalog Number',
          field: 'catalog_number',
          sorter: 'string'
        },
        {
          title: 'Category',
          field: 'parent',
          sorter: 'string',
          formatter: cell => {
            let parent = cell.getValue()
            let cat = this.materialCategories.find(itm => itm.id === parent)
            return cat ? cat.name : parent
          }
        },
        {
          title: 'Material Unit',
          field: 'material_unit',
          sorter: 'string',
          formatter: cell => {
            return this.formatMaterialUnit(cell.getValue())
          }
        },
        {
          title: 'Based On',
          field: 'based_on',
          sorter: 'string'
        }
      ]
    }
  },

  methods: {
    getMaterials(silent) {
      const params = {
        action: 'get_materials_with_prices',
        showTempItems: this.approvalStatus
      }

      if (!silent) {
        this.$bus.$emit('setWaiting', {name: params.action, message: 'Getting Materials'})
      }
      appFuncs
        .shRequest(params)
        .then(data => {
          this.loadedMaterials = data
          this.setMaterials()
        })
        .catch(data => {
          this.$snack.open(data.message || 'Problem getting materials.', 'error')
        })
        .finally(() => {
          if (!silent) {
            this.$bus.$emit('stopWaiting', params.action)
          }
        })
    },

    setMaterials() {
      // this gets called on update to be used without reloading table data
      if (!Array.isArray(this.loadedMaterials)) return
      let data = JSON.parse(JSON.stringify(this.loadedMaterials))
      for (var i = 0; i < data.length; i++) {
        // strip commas from number fields to fix problems w tabulator money formatter
        let moneyFields = ['material_price']
        for (var mi = 0; mi < moneyFields.length; mi++) {
          data[i][moneyFields[mi]] = this.stripToNumber(data[i][moneyFields[mi]], 'leaveThousandCommas')
        }
        // do on-the-fly calc for material net cost
        data[i].price_net_cost = data[i].price - ((data[i].discount_percent || 0) / 100) * data[i].price

        for (var prop in data[i]) {
          if (data[i].hasOwnProperty(prop) && typeof data[i][prop] === 'string') {
            data[i][prop] = this.decode(data[i][prop])
          }
        }
      }
      this.currentMaterials = data
      //this.$refs.materialAdminTable.updateTable();
    },

    materialClick(obj) {
      let id = ((obj || {}).cell || {}).id || 0
      let field = ((obj || {}).cell || {}).field || 0
      if (field === 'edit') {
        this.showEdit(id)
      }
    },

    showEdit(id) {
      const material = this.currentMaterials.find(itm => {
        return itm.id == id
      })

      this.$Modal({
        parent: this,
        name: 'MaterialOneTimeEdit',
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Materials/MaterialOneTimeEdit'),
        props: {
          material,
          mode: 'admin',
          showBriefItems: false,
          cb: async id => {
          }
        }
      })
    },

    approveAll() {
      let selectedRows = this.$refs.materialAdminTable.getSelectedData()
      let materialIds = selectedRows.map(itm => {
        return itm.id
      })

      const params = {
        action: 'approve_reject_one_time_materials',
        materialIds,
        approveReject: 'approve'
      }

      this.$bus.$emit('setWaiting', {name: params.action, message: 'Getting Materials'})
      appFuncs
        .shRequest(params)
        .then(data => {
          if (data.cannotApproveIds > 0) {
            this.$snack.open('Some materials could not be approved.', 'error')
          } else {
            this.$snack.open('All Materials approved.', 'success')
          }
          this.getMaterials(true)
        })
        .catch(e => {
          this.$snack.open(e.message || 'Problem approving materials', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    }
  },

  async mounted() {
    await this.$store.dispatch('getEmployees')
    this.$store.dispatch('getMaterialCategories')
    this.getMaterials()
    this.$bus.$on(['edit-one-time-material-item', 'edit-material-price-item'], () => {
      this.getMaterials()
    })
  },

  beforeDestroy() {
  },

  watch: {
    approvalStatus() {
      this.getMaterials()
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.material-lists-component {
  padding: 5px 30px;
  background: #fff; //$bg-grey;
  height: 100%;
  max-width: 3000px;
  margin: 0;
}
</style>
