<template>
  <!-- prettier-ignore -->
  <div class="pdf-template" id="pdf-template">
    <div class="">
      <div class="header" id="pdfReady">
        <div class="row row-no-gutters" style="height:107px">
          <div class="col-xs-4">
            <div>
              <img :src="`${urls.frontend}static/img/${logoFile}`">
            </div>
          </div>
          <div class="col-xs-4" style="padding-top:5px">
            <p v-html="regionAddress"/>
          </div>
        </div>
        <div class="header-bottom col-xs-12"/>
      </div>

      <section class="section has-border">
        <div class="row">
          <div class="col-xs-6">
            <h3 style="margin:5px 0"><strong>PO #: {{ formulatedPONumber }}</strong></h3>
          </div>
          <div class="col-xs-6">

          </div>

        </div>
      </section>

      <section class="section has-border">
        <div class="row">
          <div class="col-xs-6 border-right" style="min-height:120px">
            <label>To:</label><br>
            {{ data.Vendor_Name }} <br>
            Attention: <br>
            <span v-if="data.Address_1">{{ data.Address_1 }}<br></span>
            <span v-if="data.Address_2">{{ data.Address_2 }}<br></span>
            <span v-if="data.Address_3">{{ data.Address_3 }}<br></span>
          </div>
          <div class="col-xs-6" style="min-height:120px">
            <label>Ship To:</label><br>
            {{ data.Ship_Name }} <br>
            {{ data.Ship_Address_1 }} <br>
            {{ data.Ship_City }}, {{ data.Ship_State }}, {{ data.Ship_Zip_Code }} <br>
          </div>
        </div>
      </section>

      <section class="section has-border">
        <div class="row">
          <div class="col-xs-6 border-right">
            <label>Order Date:</label> <span v-html="formatDate(data.PO_Date_List1)"></span>
          </div>
          <div class="col-xs-6">
            <label>Expected Delivery Date:</label> <span v-html="formatDate(data.PO_Date_List2)"></span>
          </div>
        </div>
      </section>

      <section class="section has-border">
        <div class="row has-border">
          <div class="col-xs-6 border-right">

            <div class="row">
              <div class="col-xs-6">
                <div style="padding:0 10px">
                  <checkbox2 v-model="data.Ship_Terms" :true-val="'1'" :false-val="'2'" disabled>Ship Complete</checkbox2>
                </div>
              </div>
              <div class="col-xs-6">
                <div style="padding:0 10px">
                  <checkbox2 v-model="data.Ship_Terms" :true-val="'2'" :false-val="'1'" disabled>Ship Partial</checkbox2>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-6">
            <label>Job Desc:</label> {{ data.Job_Name }}
          </div>
        </div>
      </section>

      <p style="margin:20px 0 5px">Please supply the following goods subject to the conditions specified below:</p>

      <section class="section bom-list">
        <div class="bom-table">
          <div class="header-row table-row">
            <div>Qty</div>
            <div>Part #</div>
            <div>Desription</div>
            <div>Unit Price</div>
            <div>Extended Cost</div>
          </div>
          <div
            v-for="(s, si) in data.details"
            :key="si"
            class="table-number-content-parent table-row"
          >
            <div>{{ s.PO_Quantity_List1 }}</div>
            <div>{{ stripPart(s.Item_Code) }}</div>
            <div>{{ s.Item_Description }}</div>
            <div>{{ formatNumber(s.Item_Price, 1) }}</div>
            <div>{{ formatNumber(s.Line_Extension_List1, 1) }}</div>
          </div>
          <div>
            <div></div>
            <div></div>
            <div></div>
            <div><strong>Net</strong></div>
            <div><strong>{{ formatNumber(bomTotal, 1) }}</strong></div>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="row">
          <div class="col-xs-12" style="font-size:13px;padding:5px 15px">
            Deliver no goods without a purchase order. Acknowledge receipt of this order, specifying prices. Make no substitutions or changes without authority from {{ companyName
            }} We reserve the right to cancel this order if this shipment is not made as promised. This order must not be billed at higher prices than quoted. Electrical components
            must be CA certified.
          </div>
        </div>
      </section>

      <section class="section" style="padding:20px 0">
        <div class="row">
          <div class="col-xs-2" style="font-size:13px;padding:5px 15px">
            <label>Ordered By:</label>
          </div>
          <div class="col-xs-10">
            ____________________________________________ {{ data.Ordered_By }} ___________________________________________
          </div>
        </div>
        <div class="row">
          <div class="col-xs-2" style="font-size:13px;padding:5px 15px">
            <label>Date:</label>
          </div>
          <div class="col-xs-10">
            {{ templateDate }}
          </div>
        </div>
      </section>

      <button
        class="btn not-pdf"
        @click="sendEmail()"
        v-html="'Email to Vendor'"
      />


      <div v-if="pdf" class="footer" style="margin-bottom:1px">
        <div class="footer-inner">
          <div class="footer-left">
            {{ data.template_date }}
          </div>
          <div class="footer-center">
            {{ companyName }} Ltd.
          </div>
          <div class="footer-right">
            <div class="page-number">
              <p>Page 1 of 1</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner/>
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'
import commonFuncs from 'mixins/commonFuncs'
import {mapGetters} from 'vuex'
import Checkbox2 from 'components/UIComponents/Checkbox'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'POTemplate',
  data() {
    return {
      data: {
        PO_Number: '',
        Job_Number: ''
      },
      pageCount: '',
      existingPDF: ''
    }
  },

  components: {
    Checkbox2,
    WaitingSpinner
  },

  props: {
    authCode: {
      type: String,
      required: true
    },
    pdf: {
      type: [String, Boolean],
      default: false
    }
  },

  mixins: [commonFuncs],

  computed: {
    ...mapGetters(['userAuthedData', 'sherAuth', 'urls', 'employees']),

    authedUser() {
      return (this.userAuthedData || {}).eid || null
    },

    isAdmin() {
      let auth = this.$store.getters.userAuthedData.user_role
      return auth ? auth.indexOf('admin') !== -1 : false
    },

    companyName() {
      const vars = this.getRegionVars(this.data.region)
      return vars.companyName
    },

    logoFile() {
      const vars = this.getRegionVars(this.data.region)
      return vars.logoFile
    },

    regionAddress() {
      const vars = this.getRegionVars(this.data.region)
      return vars.address
    },

    bomTotal() {
      const items = (this.data || {}).details || []
      return items.reduce((acc, itm) => {
        return acc + parseFloat(itm.Line_Extension_List1)
      }, 0)
    },

    templateDate() {
      return this.formatDate(new Date())
    },

    formulatedPONumber() {
      if (!this.data || !this.data.PO_Number) {
        return ''
      }
      const type = this.data.Reference_Flag === 'J' || this.data.Reference_Flag === 'W' ? this.data.Reference_Flag : ''
      return `${this.data.PO_Number}-${type}${this.data.Job_Number}`
    },

    splitSections() {
      const items = (this.data || {}).details || []
      let sections = []
      let firstSectionMax = 30
      return sections
    }
  },

  methods: {
    getData() {
      if (!this.authCode) {
        this.$snack.open('Cannot fetch', 'error')
        return
      }
      const params = {
        action: 'get_purchase_order_with_details_template',
        auth_code: this.authCode
      }
      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Fetching data'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.data = data

          // const multiplier = 100 // Change this value to control the multiplier
          // const originalDetails = [...data.details] // Create a copy of the original array
          // for (let i = 1; i < multiplier; i++) {
          //   data.details.push(...originalDetails)
          // }
        })
        .catch(err => {
          console.log(err)
          this.$snack.open(err.message || 'Problem fetching PO', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    sendEmail() {
      this.$Modal({
        parent: this,
        name: 'POEmailDialog', // used for closing specific modal programmatically
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/PurchaseOrders/POEmailDialog.vue'),
        contentClasses: 'smoke-bg',
        props: {
          authCode: this.authCode,
          data: this.data
        }
      })
    },

    downloadPDF() {
      window.open(this.existingPDF)
    },

    getPlainText(text) {
      return appFuncs.getPlainText(text)
    },

    pdfClearModifications() {
      let footer = document.getElementsByClassName('footer')[0]
      if (!footer) {
        return
      }
      // remove all previously added content
      let addedContent = document.getElementsByClassName('added-content')
      while (addedContent[0]) {
        addedContent[0].parentNode.removeChild(addedContent[0])
      }

      let addedSpacers = document.getElementsByClassName('added-spacer')
      while (addedSpacers[0]) {
        addedSpacers[0].parentNode.removeChild(addedSpacers[0])
      }
      let addedLines = document.getElementsByClassName('measure-line')
      while (addedLines[0]) {
        addedLines[0].parentNode.removeChild(addedLines[0])
      }
      let pageNum = footer.getElementsByClassName('page-number')
      if (pageNum && pageNum[0]) {
        pageNum[0].innerHTML = '<p>Page 1 of 1<p>'
      }
    },

    pdfSpaceSections() {
      let mainEl = document.getElementById('pdf-template')
      let sections = Array.prototype.slice.call(document.querySelectorAll('section'))

      let header = document.getElementsByClassName('header')[0]
      let footer = document.getElementsByClassName('footer')[0]
      let footerHeight = footer.offsetHeight + parseInt(footer.style.marginBottom)

      const pageHeight = 1450
      let nextPageBottom = pageHeight
      let pageCount = 1

      sections.forEach(function (itm) {
        let top = itm.getBoundingClientRect().top + document.documentElement.scrollTop
        let itmHeight = itm.offsetHeight
        let bottom = itmHeight + top + 100

        // if page break is between top and bottom, add enough margin to get on next page
        if (top < nextPageBottom && bottom > nextPageBottom) {
          // if section is taller than page, add break between table rows
          if (itmHeight > 500) {
            let rows = itm.getElementsByClassName('table-row')
            Array.from(rows).forEach(function (rowItm) {
              let rowTop = rowItm.getBoundingClientRect().top + document.documentElement.scrollTop
              let rowHeight = rowItm.offsetHeight
              let rowBottom = rowHeight + rowTop + 150
              if (rowTop < nextPageBottom && rowBottom > nextPageBottom) {
                let marginNeeded = pageCount * pageHeight - rowTop + header.offsetHeight + 30
                let spacer = document.createElement('div')
                spacer.classList.add('added-spacer')
                spacer.style.paddingTop = marginNeeded + 'px' // use padding to prevent top of margin issue from not creating space on next page.
                rowItm.insertAdjacentHTML('beforebegin', spacer.outerHTML)
                nextPageBottom += pageHeight
                pageCount++
              }
            })
          } else {
            let marginNeeded = pageCount * pageHeight - top - 20 + header.offsetHeight + footer.offsetHeight
            let spacer = document.createElement('div')
            spacer.classList.add('added-spacer')
            spacer.style.paddingTop = marginNeeded + 'px' // use padding to prevent top of margin issue from not creating space on next page.
            itm.insertAdjacentHTML('beforebegin', spacer.outerHTML)
            nextPageBottom += pageHeight
            pageCount++
          }
        }
      })

      let newFooter = null
      let addedContent = null
      let pageNo = 0
      let topPos = 0
      let pageNum = null
      for (var i = 0; i < pageCount; i++) {
        let isLastPage = i + 1 == pageCount
        pageNo++
        newFooter = footer.cloneNode(true)
        newFooter.style.visibility = 'visible'
        pageNum = newFooter.getElementsByClassName('page-number')
        if (pageNum && pageNum[0]) {
          pageNum[0].innerHTML = '<p>Page ' + pageNo + ' of ' + pageCount + '<p>'
        }
        topPos = pageNo * pageHeight - footerHeight - 20
        addedContent = '<div class="added-content" style="top:' + topPos + 'px">' + newFooter.outerHTML
        if (!isLastPage) {
          addedContent += '<div style="margin-top:0px">' + header.outerHTML + '</div>' + '</div>'
        }
        mainEl.insertAdjacentHTML('afterend', addedContent)
      }

      // hide original footer as to not have to apply changes that disrupt re-layout
      footer.style.visibility = 'hidden'

      // update original footer now at bottom of page
      let pageNumLast = footer.getElementsByClassName('page-number')
      if (pageNumLast && pageNumLast[0]) {
        pageNumLast[0].innerHTML = '<p>Page ' + pageCount + ' of ' + pageCount + '<p>'
      }

      this.pageCount = pageCount
      // this.addGridLines()
    },

    addGridLines() {
      let addedContent = document.getElementsByClassName('measure-line')
      while (addedContent[0]) {
        addedContent[0].parentNode.removeChild(addedContent[0])
      }

      let mainEl = document.getElementById('pdf-template').offsetHeight
      let parts = mainEl / 10
      let line = null
      for (var i = 0; i < parts; i++) {
        line = document.createElement('div')
        line.setAttribute('class', 'measure-line')
        line.style.top = i * 10 + 'px'
        line.innerHTML = i * 10
        if ((i * 10) % 1450 == 0) {
          line.style.background = 'purple'
        }
        document.getElementsByTagName('body')[0].appendChild(line)
      }
    },

    handleImageLoaded(img) {
      this.pdfSpaceSections()
    },

    stripPart(part) {
      return part.replace(/^!/, '')
    }
  },

  mounted() {
    this.getData()

    if (this.authedUser) {
      this.$store.dispatch('getEmployees')
    }

    // window.addEventListener('load', () => {
    //   this.pdfSpaceSections();
    // });
  },

  updated() {
    if (this.pdf) {
      this.pdfClearModifications()
      setTimeout(() => {
        this.pdfSpaceSections()
      }, 150)
    }
  }
}
</script>

<style lang="scss">
.section {
  ul,
  ol {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    list-style-position: inside;

    li {
      margin-bottom: 5px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

body .pdf-template {
  min-width: 1020px;
  width: 1020px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  padding: 40px 0px 20px;

  .header-bottom {
    height: 1px;
    border-top: 3px solid #000;
  }

  img {
    width: auto;
    height: 90px;
  }
}

.section {
  width: 100%;

  &.main-section {
    margin-bottom: 20px;
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    font-style: italic;
  }

  h5 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0px;
    font-style: italic;
    margin-top: 10px;

    &.underline {
      border-bottom: 1px solid;
      padding: 3px 0;
      margin-bottom: 7px;
    }
  }

  &.has-border {
    border: 2px solid $text-color;
    padding: 0 15px;
    margin-top: -1px;

    .row {
      [class^='col-'] {
        padding: 10px;
        margin: 10 0 0;
      }

      h5 {
        margin: 0 0 10px;
        text-decoration: underline;
      }
    }

    .border-right {
      border-right: 2px solid $text-color;
    }

    .border-left {
      border-right: 2px solid $text-color;
    }
  }
}

.bom-table {
  width: 100%;
  margin-bottom: 5px;
  padding: 10px 0px;

  .header-row {
    font-weight: bold;
  }

  div {
    > div {
      display: inline-block;
      padding: 3px;
      vertical-align: top;

      &:nth-child(1) {
        width: 50px;
      }

      &:nth-child(2) {
        width: 140px;
      }

      &:nth-child(3) {
        width: 500px;
      }

      &:nth-child(4) {
        width: 120px;
      }

      &:nth-child(5) {
        width: 120px;
      }
    }
  }
}

.footer {
  .footer-inner {
    max-width: 1020px;
    margin: 0 auto;
    padding: 50px 0px 0 !important;
  }

  .footer-left {
    width: 33%;
    display: inline-block;
    text-align: left;
  }

  .footer-center {
    width: 33%;
    display: inline-block;
    text-align: center;
  }

  .footer-right {
    width: 33%;
    display: inline-block;
    text-align: right;
  }
}

.measure-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background: red;
}

.added-content {
  width: 100%;
  position: absolute;

  .header {
    max-width: 1020px;
    margin: 0px auto 0px;
    padding: 50px 20px 0;
  }

  .footer-inner {
    padding: 50px 20px 20px !important;
  }
}

.optional-systems-section {
  border: 1px solid $other-medium-gray;
  padding: 5px 15px;
  border-radius: 5px;
}
</style>
