
<div>
  <div class="test-section">
    <!-- <h3>Rich Text Editor Test</h3> -->
    <div class="editor-stack">
      <div class="editor-item">
        <h4>Notes</h4>
        <RichTextInline :content="notesContent" @update:content="notesContent = $event" @save="handleSave('notes', $event)" />
        <RichTextInline :content="descriptionContent" @update:content="descriptionContent = $event" @save="handleSave('description', $event)" />
        <RichTextInline :content="commentsContent" @update:content="commentsContent = $event" @save="handleSave('comments', $event)" />
      </div>
    </div>

    <!-- <div class="content-preview">
      <h4>Saved Content:</h4>
      <div class="preview-box">
        <div v-html="savedContent"></div>
      </div>
    </div> -->
  </div>

  <!-- <hr />

  <button @click="showConfirm()">Show</button>
  <DownloadMergePDF path="wo-uploads/11825/sign-off" fileName="upload_now" />

  <button @click="requestEmail">Request wo po email</button> -->
</div>
