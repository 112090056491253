<template>
  <div class="">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4">
        <button class="btn" @click="addItem($event)">Add Time Entry</button>
        <button class="btn" @click="addForemanTimeEntry" v-if="canEditOthers">Foreman Time Entry</button>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-8 pull-right">
        <div class="legend">
          <div
            @click="
              selectedApprovalStatus = 'yes'
              getData()
            "
          >
            <span class="green" />Approved
          </div>
          <div
            @click="
              selectedApprovalStatus = 'no'
              getData()
            "
          >
            <span class="red" />Rejected
          </div>
          <div
            @click="
              selectedApprovalStatus = 'unapproved'
              getData()
            "
          >
            <span class="white" />Pending
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="(isPayrollUser || isAdmin) && page === 'approval' && tab === 'approval'">
      <div class="col-sm-12">
        <WidgetPmApprovals @filter-pm="filterPm" />
      </div>
    </div>

    <br />

    <div class="tab-section white">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{ active: tab == 'Entries' }]" v-if="page === 'entry'">
          <a href="#" @click=";(tab = 'Entries'), getEntries()"> Entries </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'Instructions' }]" v-if="page === 'entry'">
          <a href="#" @click="tab = 'Instructions'"> Instructions </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'approval' }]" v-if="isPM && page === 'approval'">
          <a href="#" @click=";(tab = 'approval'), getEntries()"> Time Card Approval</a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'admin' }]" v-if="(isPayrollUser || isAdmin) && page === 'approval'">
          <a href="#" @click=";(tab = 'admin'), getEntries()">Payroll Admin</a>
        </li>
      </ul>

      <div :class="['card', { Main: tab == 'Entries' }]">
        <div class="content">
          <div v-if="tab == 'Entries'">
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="form-group">
                  <label>Select Pay Period</label>
                  <v-date-picker
                    v-model="selectedStartDate"
                    :pane-width="320"
                    :is-expanded="true"
                    :is-inline="false"
                    :popover-visibility="'focus'"
                    :show-day-popover="false"
                    :formats="{
                      input: ['DD MMM YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD']
                    }"
                    :theme-styles="datepickerStyles"
                    :attributes="dateSelectAttrs"
                    @input="selectDate"
                    :select-attribute="{
                      contentStyle: { color: '#555', highlight: '#dae0fe' }
                    }"
                    :class="['custom-week-picker']"
                  >
                    <input type="text" slot-scope="{ inputValue, updateValue }" :value="selectedRange.formatted" :class="['form-control']" />
                  </v-date-picker>
                </div>
              </div>
              <div class="col-sm-1">
                <div style="padding: 35px 0 10px; text-align: center">
                  <p>OR</p>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label>From Date</label>
                  <date-picker
                    v-model="startDate"
                    :format="'date'"
                    :is-inline="false"
                    :pre-filled="false"
                    ref="startDatePicker"
                    @selectDate="
                      selectDate(null)
                      getEntries()
                    "
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label>To Date</label>
                  <date-picker
                    v-model="endDate"
                    :format="'date'"
                    :is-inline="false"
                    :pre-filled="false"
                    ref="endDatePicker"
                    @selectDate="
                      selectDate(null)
                      getEntries()
                    "
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label>Employee</label>
                  <select-field-dynamic
                    :options="filteredEmployees"
                    :option-display-keys="['Employee_Name']"
                    :option-val="'Employee_Code'"
                    v-model="employeeCode"
                    :allow-clear="false"
                    :preselect-single-option="false"
                    :disabled="!canEditOthers"
                    :read-only="!canEditOthers"
                    :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                    label="Employee"
                    @selectItem="(getData('employee'), $refs.selectSubcontractor.selectEl({}))"
                    ref="selectEmployee"
                  />
                </div>
              </div>
              <div :class="['col-sm-12', 'col-md-3']">
                <div class="form-group">
                  <label>Subcontractor</label>
                  <select-field-dynamic
                    :options="subcontractors"
                    :option-display-keys="['Name']"
                    :option-val="'id'"
                    v-model="subcontractorCode"
                    :allow-clear="true"
                    :preselect-single-option="false"
                    :disabled="!canEditOthers"
                    :read-only="!canEditOthers"
                    label="Subcontractor"
                    @selectItem="(getData('subcontractor'), $refs.selectEmployee.selectEl({}))"
                    ref="selectSubcontractor"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <label>Work Orders</label>
                <div class="form-group">
                  <select-field-multi
                    :options="woList"
                    :option-display-keys="['WO_Number', 'customer_name', 'Summary_Description']"
                    :option-val="'WO_Number'"
                    :option-separator="' - '"
                    v-model="selectedWOs"
                    :placeholder-text="'Select WOs'"
                    :preselect-single-option="false"
                    @input="
                      selectedJobs = []
                      selectFilterItem('wo')
                    "
                    :allow-add-simple-item="true"
                    item-name="Work Orders"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Jobs</label>
                  <select-field-multi
                    :options="jobList"
                    :option-display-keys="['Job_Number', 'Name', 'Job_Description']"
                    :option-val="'Job_Number'"
                    v-model="selectedJobs"
                    :placeholder-text="'Select Jobs'"
                    :preselect-single-option="false"
                    @input="
                      selectedWOs = []
                      selectFilterItem('job')
                    "
                    :allow-add-simple-item="true"
                    item-name="Jobs"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label>Approval Status</label>
                  <select-field-dynamic
                    :options="approvalStatuses"
                    :option-display-keys="['label']"
                    :option-val="'id'"
                    v-model="selectedApprovalStatus"
                    @selectItem="getData()"
                    :allow-clear="false"
                    :preselect-single-option="false"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <region-filter v-model="region" />
              </div>
            </div>
            <div class="row" style="margin: 0px 0 20px">
              <div class="col-sm-12 text-right">
                <button class="btn" @click="clearFilters($event)">Clear Filters</button>
                <button class="btn" @click="exportResults()" v-if="canEditOthers || isPayrollUser">Export Results</button>
              </div>
            </div>

            <alert
              :alert="{
                show: entryData.length >= 200,
                message: `Note that greater than 200 entries exist for the dates selected, add more filtering to see complete results.`,
                state: 'warning',
                dismiss: 'none'
              }"
            />

            <tabulator-table
              :table-data="filteredEntries"
              :table-columns="tableColumns"
              table-fit="fitColumns"
              :table-condensed="false"
              empty-text="(none)"
              ref="jobWoLabor"
              @colClick="colClick"
              :responsive-layout="'hide'"
              :selectable="false"
              :row-formatter="rowFormatter"
            />
          </div>

          <div v-if="tab == 'Instructions'">
            <ul class="instruction-info">
              <li>
                Time entries submitted here can be edited until synced with Spectrum. Syncing occurs automatically at 4am daily or as necessary according to some Job/WO actions. Entries that can be
                edited display a small pencil icon at the far right of each row. To adjust non editable entries please ask the office to make the change for you.
              </li>

              <li>
                The final record of time shall be Spectrum and not mysheridan.ca – for example if you enter 4 hours at RT but the time gets adjust to 1.5X because of overtime that adjustment will
                appear in spectrum not in mysheridan.ca.
              </li>

              <li>You will only be able to enter hours that fall within the current payroll cycle. If you missed entering hours for a date outside this period, please consult the office.</li>
            </ul>
          </div>

          <div v-if="tab == 'approval' || tab === 'admin'">
            <div class="row" v-if="!isMobile">
              <div class="col-xs-12 col-sm-6 col-md-3" v-if="tab === 'approval'">
                <div class="form-group">
                  <label>Job/WO PM</label>
                  <select-field-dynamic
                    :options="employees"
                    :option-display-keys="['Employee_Name']"
                    :option-val="'Employee_Code'"
                    v-model="employeeCode"
                    @selectItem="getData('employee')"
                    :allow-clear="false"
                    :preselect-single-option="false"
                    :read-only="!isPayrollUser"
                    :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                    label="Employee"
                  />
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="form-group">
                  <label>Employee</label>
                  <select-field-dynamic
                    :options="employees"
                    :option-display-keys="['Employee_Name']"
                    :option-val="'Employee_Code'"
                    v-model="filterEmployee"
                    :default-value="filterEmployee"
                    :allow-clear="true"
                    :preselect-single-option="false"
                    :disabled="!canEditOthers"
                    :read-only="!canEditOthers"
                    label="Employee"
                    :Xfilter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                    @selectItem="getData('employee')"
                  />
                </div>
              </div>
              <!-- <div class="col-xs-12 col-sm-6 col-md-2">
                <div class="form-group">
                  <label>Subcontractor</label>
                  <select-field-dynamic
                    :options="subcontractors"
                    :option-display-keys="['Name']"
                    :option-val="'id'"
                    v-model="filterSubcontractor"
                    :allow-clear="true"
                    :preselect-single-option="false"
                    :disabled="!canEditOthers"
                    :read-only="!canEditOthers"
                    label="Subcontractor"
                  />
                </div>
              </div> -->
              <div class="col-xs-12 col-sm-6 col-md-3">
                <label>Work Orders</label>
                <div class="form-group">
                  <select-field-dynamic
                    :options="tab === 'approval' ? woListTimeCardApproval : woList"
                    :option-display-keys="['wo_number']"
                    :option-val="'wo_number'"
                    :option-separator="' - '"
                    v-model="filterWO"
                    :default-value="filterWO"
                    :placeholder-text="'Select WO'"
                    :preselect-single-option="false"
                    label="Work Orders"
                    :allow-clear="true"
                  />
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3">
                <div class="form-group">
                  <label>Jobs</label>
                  <select-field-dynamic
                    :options="tab === 'approval' ? jobListTimeCardApproval : jobList"
                    :option-display-keys="['job_number', 'Name', 'Job_Description']"
                    :option-val="'job_number'"
                    v-model="filterJob"
                    :default-value="filterJob"
                    :placeholder-text="'Select Job'"
                    :preselect-single-option="false"
                    label="Jobs"
                    :allow-clear="true"
                  />
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3">
                <region-filter v-model="region" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-8 col-lg-9">
                <div class="form-group">
                  <label>Filter Entries</label>
                  <input type="text" v-model="entryFilter" class="form-control" placeholder="Filter text" />
                </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-3">
                <span class="form-group">
                  <checkbox2 v-model="hideEntryComments" :true-val="'yes'" :false-val="'no'" class="hide-entry-comments-checkbox">Hide Entry Comments</checkbox2>
                </span>
              </div>
            </div>
          </div>
          <div v-if="tab == 'approval'">
            <div class="button-group button-group-mobile-sticky">
              <div class="inner">
                <div class="button-container">
                  <button class="btn btn-no-radius btn-pip btn-sm btn-green" @click="acceptEntries">Approve</button>
                  <button class="btn btn-no-radius btn-pip btn-sm btn-orange" @click="routeEntries">Route</button>
                  <button class="btn btn-no-radius btn-pip btn-sm btn-red" @click="rejectEntries">Reject</button>
                  <button class="btn btn-no-radius btn-pip btn-sm btn-red" @click="clearFilters($event)">Clear</button>
                </div>
                <div class="inline-checkbox" v-if="isMobile">
                  <checkbox2 v-model="selectAll" :true-val="'yes'" :false-val="'no'" @change="$bus.$emit('tab-tiles-select-all-toggle')" />
                </div>
              </div>
            </div>

            <alert
              :alert="{
                show: entryData.length >= 300,
                message: `Note that greater than 300 entries exist for the dates selected, add more filtering to see complete results.`,
                state: 'warning',
                dismiss: 'none'
              }"
            />

            <tabulator-tiles-dynamic
              :table-data="filteredEntries"
              :table-columns="tableColumns"
              table-fit="fitColumns"
              :table-condensed="false"
              empty-text="(none)"
              ref="approvalTable"
              :Xresponsive-layout="'hide'"
              :selectable="true"
              :selectableRollingSelection="true"
              @colClick="colClick"
              :row-formatter="rowFormatter"
            />
          </div>

          <div v-if="tab == 'admin'">
            <div class="row">
              <div class="col-sm-12">
                <div class="button-group button-group-mobile-sticky">
                  <div class="inner">
                    <div class="button-container">
                      <button class="btn btn-no-radius btn-pip btn-sm btn-green" @click="acceptEntries">Approve</button>
                      <button class="btn btn-no-radius btn-pip btn-sm btn-orange" @click="routeEntries">Route</button>
                      <button class="btn btn-no-radius btn-pip btn-sm btn-red" @click="deleteEntries">Delete</button>
                      <button class="btn btn-no-radius btn-pip btn-sm btn-red" @click="clearFilters($event)">Clear Filter</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <alert
              :alert="{
                show: entryData.length >= 300,
                message: `Note that greater than 300 entries exist for the dates selected, add more filtering to see complete results.`,
                state: 'warning',
                dismiss: 'none'
              }"
            />

            <tabulator-table
              :table-data="filteredEntries"
              :table-columns="tableColumns"
              table-fit="fitColumns"
              :table-condensed="false"
              empty-text="(none)"
              ref="adminTable"
              :Xresponsive-layout="'hide'"
              :selectable="true"
              @colClick="colClick"
              :row-formatter="rowFormatter"
            />
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>
<script>
import store from 'store'
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import RegionFilter from 'components/Dashboard/RegionFilter'

import dateFormat from 'dateformat'
import moment from 'moment'
import sanitizeHtml from 'sanitize-html'
import SelectFieldDynamic from 'components/UIComponents/SelectFieldDynamic'
import SelectFieldMulti from 'components/UIComponents/SelectFieldMulti'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import TabulatorTilesDynamic from 'components/UIComponents/TabulatorTilesDynamic'
import DatePicker from 'components/UIComponents/DatePicker'
import Checkbox2 from 'components/UIComponents/Checkbox'
import WidgetPmApprovals from 'components/Dashboard/Widgets/WidgetPmApprovals'
import navTabs from 'mixins/navTabs'
import Alert from 'components/UIComponents/Alert'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import he from 'he'

export default {
  components: {
    TabulatorTable,
    TabulatorTilesDynamic,
    DatePicker,
    SelectFieldMulti,
    SelectFieldDynamic,
    Checkbox2,
    RegionFilter,
    WidgetPmApprovals,
    Alert,
    WaitingSpinner
  },

  mixins: [navTabs],

  data() {
    return {
      tab: 'Entries',
      urls: store.getters.urls,
      tableData: [],
      jobPhaseData: [],
      entryData: [],
      selectedRange: {},
      selectedStartDate: null,
      startDate: null,
      endDate: null,
      employeeCode: '',
      subcontractorCode: '',
      selectedWOs: [],
      selectedJobs: [],
      selectedApprovalStatus: 'all',
      selectAll: 'no',
      jobList: [],
      woList: [],
      dateSelectAttrs: [
        {
          key: 'today',
          highlight: {
            height: '20px',
            color: '#555',
            backgroundColor: '#dae0fe'
          },
          contentStyle: {
            color: '#555'
            // fontWeight:'bold',
          },
          dates: [{ start: new Date(), span: 14 }]
        }
      ],
      datepickerStyles: {
        dayContent: {
          fontSize: '1.6rem',
          height: '38px'
        },
        dayCell: {
          height: '38px'
        },
        headerTitle: {
          fontSize: '1.6rem'
        }
      },
      hideEntryComments: 'no',
      approvalTableData: [],
      filterEmployee: '',
      filterSubcontractor: '',
      filterWO: '',
      filterJob: '',
      entryFilter: '',
      region: null,
      approvalStatuses: [
        { id: 'all', label: 'All Statuses' },
        { id: 'yes', label: 'Approved' },
        { id: 'unapproved', label: 'Pending' },
        { id: 'no', label: 'Rejected' }
      ]
    }
  },

  props: {
    page: {
      type: String,
      default: 'entry'
    },
    filterApproval: {
      type: [Number, String],
      default: ''
    }
  },

  computed: {
    ...mapGetters(['employees', 'subcontractors', 'sherAuth', 'customers', 'regions', 'quoteData']),

    isAdmin() {
      return false //this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPM() {
      return this.$store.getters.userAuthedData.user_role.indexOf('pm') !== -1
    },

    isEditor() {
      return this.$store.getters.userAuthedData.user_role.indexOf('editor') !== -1
    },

    isPayrollUser() {
      const employee = this.employees.find(itm => {
        return parseInt(itm.Employee_Code) == parseInt(this.sherAuth.eid)
      })
      if (!employee) return false
      return employee && employee.payroll_admin_user === 'yes'
    },

    canEditOthers() {
      return this.isPM || (this.isEditor && this.$store.getters.userAuthedData.user_role.indexOf('F') !== -1)
    },

    filteredEmployees() {
      let allEmployees = JSON.parse(JSON.stringify(this.employees))
      if (!this.canEditOthers || (this.tab === 'approval' && !this.isAdmin)) {
        let emp = this.employees.filter(itm => {
          return itm.Employee_Code == this.sherAuth.eid
        })
        return emp || []
      } else {
        allEmployees.unshift({
          Employee_Code: '',
          Employee_Name: 'All Employees',
          Employment_Status: 'A'
        })
        return allEmployees
      }
    },

    filteredEntries() {
      let tableData = JSON.parse(JSON.stringify(this.tableData))
      if (this.tab === 'approval' || this.tab === 'admin') {
        if (!this.isMobile) {
          if (this.filterEmployee) {
            tableData = tableData.filter(itm => {
              return itm.employee_code == this.filterEmployee
            })
          }

          if (this.filterSubcontractor) {
            tableData = tableData.filter(itm => {
              return itm.employee_code == `S${this.filterSubcontractor}`
            })
          }

          if (this.filterWO) {
            tableData = tableData.filter(itm => {
              return itm.wo_number == this.filterWO
            })
          }

          if (this.filterJob) {
            tableData = tableData.filter(itm => {
              return itm.job_number == this.filterJob
            })
          }
        }

        if (this.entryFilter) {
          tableData = tableData.filter(itm => {
            const employeeName = itm.employee_name ? itm.employee_name.toLowerCase() : ''
            const jobDescription = itm.Job_Description ? itm.Job_Description.toLowerCase() : ''
            const woDescription = itm.Summary_Description ? itm.Summary_Description.toLowerCase() : ''
            const message = itm.message ? itm.message.toLowerCase() : ''
            const customer = itm.customer ? itm.customer.toLowerCase() : ''
            const entryDate = itm.entry_date ? itm.entry_date.toLowerCase() : ''
            const jobNumber = itm.job_number ? itm.job_number.toLowerCase() : ''
            const approvedComments = itm.approved_comments ? itm.approved_comments.toLowerCase() : ''
            const routingComments = itm.routing_comments ? itm.routing_comments.toLowerCase() : ''
            const woNumber = itm.wo_number ? itm.wo_number.toLowerCase() : ''

            return (
              employeeName.includes(this.entryFilter.toLowerCase()) ||
              jobDescription.includes(this.entryFilter.toLowerCase()) ||
              woDescription.includes(this.entryFilter.toLowerCase()) ||
              message.includes(this.entryFilter.toLowerCase()) ||
              customer.includes(this.entryFilter.toLowerCase()) ||
              //   entryDate.includes(this.entryFilter.toLowerCase()) ||
              jobNumber.includes(this.entryFilter.toLowerCase()) ||
              approvedComments.includes(this.entryFilter.toLowerCase()) ||
              routingComments.includes(this.entryFilter.toLowerCase()) ||
              woNumber.includes(this.entryFilter.toLowerCase())
            )
          })
        }
      }

      tableData = tableData.filter(itm => {
        if (this.region && itm.default_region !== this.region) return false

        const showUnapproved = this.selectedApprovalStatus === 'unapproved' && !itm.approved
        const showApproved = this.selectedApprovalStatus === 'yes' && itm.approved === 'yes'
        const showRejected = this.selectedApprovalStatus === 'no' && itm.approved === 'no'
        if (!showUnapproved && !showApproved && !showRejected && this.selectedApprovalStatus !== 'all') return false

        return true
      })

      return tableData
    },

    jobListTimeCardApproval() {
      const jobMap = new Map()
      for (const entry of this.entryData) {
        if (entry.job_number) {
          jobMap.set(entry.job_number, entry)
        }
      }
      return Array.from(jobMap.values())
    },

    woListTimeCardApproval() {
      const woMap = new Map()
      for (const entry of this.entryData) {
        if (entry.WO_Number) {
          woMap.set(entry.WO_Number, entry)
        }
      }
      return Array.from(woMap.values())
    },

    // hideName: hidename is converted to return a function boolean to hide name in the mobile view. this can be used to do same for other columns
    // It is a required property for the table component
    tableColumns() {
      return [
        {
          title: 'Employee',
          field: 'employee_code',
          width: 140,
          responsive: 0,
          formatter: cell => {
            const val = cell.getValue()
            let employee = null
            if (val) {
              if (val.startsWith('S')) {
                employee = this.subcontractors.find(itm => `S${itm.id}` == val)
                return employee ? employee.Name : val
              } else {
                employee = this.employees.find(itm => itm.Employee_Code == val)
                return employee ? employee.Employee_Name : val
              }
            }
          },
          // Enhanced sorter function with guaranteed sort direction compliance
          sorter: (a, b, aRow, bRow, column, dir, sorterParams) => {
            const getSortableValue = val => {
              if (!val) return ''
              let employee = null

              if (val.startsWith('S')) {
                employee = this.subcontractors.find(itm => `S${itm.id}` == val)
              } else {
                employee = this.employees.find(itm => itm.Employee_Code == val)
              }

              return employee ? employee.Employee_Name.toLowerCase() : val
            }

            const valueA = getSortableValue(a)
            const valueB = getSortableValue(b)

            return valueA.localeCompare(valueB)
          },

          hideName: (field, val) => true,
          displayType: 'header'
        },
        {
          title: 'Entry Date',
          field: 'entry_date',
          width: 120,
          responsive: 0,
          hideName: (field, val) => true,
          displayType: 'lowerRight'
        },
        {
          title: 'Message',
          field: 'message',
          headerSort: false,
          minWidth: 200,
          // formatter: 'textarea',
          responsive: 0,
          formatter: function (cell, formatterParams, onRendered) {
            const value = cell.getValue()
            return value
            const encodedValue = he.decode(value)
            return encodedValue
          },
          hideName: (field, val) => true,
          visible: this.hideEntryComments === 'no'
        },
        {
          title: 'Hours Total',
          field: 'hours',
          headerSort: false,
          hideName: (field, val) => false,
          topCalc: function (values, data, calcParams) {
            // Sum only values where approved is not 'no'
            return values.reduce((sum, value, index) => {
              if (data[index].approved !== 'no') {
                return sum + parseFloat(value)
              }
              return sum
            }, 0)
          },
          width: 120,
          responsive: 0,
          displayType: 'hidden'
        },
        {
          title: 'Hours Regular',
          field: 'HoursRegular',
          headerSort: false,
          hideName: (field, val) => false,
          topCalc: function (values, data, calcParams) {
            // Sum only values where approved is not 'no'
            return values.reduce((sum, value, index) => {
              if (data[index].approved !== 'no') {
                return sum + parseFloat(value)
              }
              return sum
            }, 0)
          },
          width: 120,
          responsive: 0
        },
        {
          title: 'Hours OT',
          field: 'HoursOT',
          headerSort: false,
          hideName: (field, val) => false,
          topCalc: function (values, data, calcParams) {
            // Sum only values where approved is not 'no'
            return values.reduce((sum, value, index) => {
              if (data[index].approved !== 'no') {
                return sum + parseFloat(value)
              }
              return sum
            }, 0)
          },
          width: 80,
          responsive: 0
        },
        {
          title: 'Hours DT',
          field: 'HoursDT',
          headerSort: false,
          hideName: (field, val) => false,
          topCalc: function (values, data, calcParams) {
            // Sum only values where approved is not 'no'
            return values.reduce((sum, value, index) => {
              if (data[index].approved !== 'no') {
                return sum + parseFloat(value)
              }
              return sum
            }, 0)
          },
          width: 80,
          responsive: 0
        },
        {
          title: 'Customer',
          field: 'customer',
          width: 150,
          hideName: (field, val) => false,
          responsive: 2
        },
        {
          title: 'Type',
          field: 'type',
          width: 120,
          responsive: 0,
          hideName: (field, val) => false,
          displayType: 'hidden'
        },
        {
          title: this.isMobile ? 'Job' : 'Job/WO',
          field: 'job_number',
          width: 120,
          responsive: 0,
          hideName: (field, val) => false,
          mutator: (value, data) => {
            if (value === null || value === undefined || value === '') {
              return data.wo_number
            }
            return this.isMobile ? `${value} ${data.Job_Description}` : value
          }
        },
        {
          title: 'Job/WO Description',
          field: 'job_description',
          width: 200,
          responsive: 3,
          hideName: (field, val) => true,
          formatter: cell => {
            if (cell && cell?.getRow) {
              const rowData = cell?.getRow()?.getData()
              return rowData.Job_Description || rowData.Summary_Description
            }
          }
        },
        {
          title: 'Phase/Quote Description',
          field: 'phase_description',
          hideName: (field, val) => true,
          width: 200,
          responsive: 2,
          formatter: cell => {
            if (!cell?.getRow) {
              return ''
            }
            const rowData = cell?.getRow()?.getData()

            return rowData?.quote_description || rowData?.phase_description
          },
          displayType: 'hidden'
        },
        {
          title: 'Phase',
          field: 'phase_code',
          width: 100,
          responsive: 0,
          hideName: (field, val) => {
            return val.quote_cus_description && field === 'phase_code'
          },
          mutator: (value, data) => {
            return this.isMobile ? (data.quote_cus_description ? data.quote_cus_description : `${data.phase_code}  ${data.phase_description || ''}`) : data.phase_code
          }
        },
        {
          title: 'Posted',
          field: 'is_posted',
          width: 120,
          responsive: 3,
          displayType: 'hidden',
          hideName: (field, val) => false
        },
        {
          title: 'Approver',
          field: 'approverId',
          width: 140,
          responsive: 0,
          hideName: (field, val) => false,
          formatter: cell => {
            const approverId = cell.getValue()
            let employee = null
            if (approverId) {
              employee = this.employees.find(itm => {
                return itm.Employee_Code == approverId
              })
              return employee ? employee.Employee_Name : approverId
            }
          },
          visible: this.tab !== 'approval'
        },
        {
          title: 'Region',
          field: 'default_region',
          width: 140,
          responsive: 0,
          hideName: (field, val) => false,
          formatter: cell => {
            const default_region = cell.getValue()
            if (default_region) {
              const region = this.regions.find(region => default_region === region.id)
              if (region) {
                return region.name
              }
            }
          }
        },
        {
          title: 'Routing',
          field: 'Project_Manager',
          width: 140,
          responsive: 0,
          hideName: (field, val) => false,
          formatter: cell => {
            const routingCode = cell.getValue()
            let employee = null
            if (routingCode) {
              employee = this.employees.find(itm => {
                return itm.Employee_Code == routingCode
              })
              return employee ? employee.Employee_Name : routingCode
            }
          },
          visible: false // this.tab === 'approval'
        },
        {
          title: 'Routing Comment',
          field: 'routing_comments',
          width: 200,
          responsive: 0,
          visible: this.tab !== 'Entries',
          hideName: (field, val) => false,
          formatter: (cell, formatterParams, onRendered) => {
            let comments = cell.getValue()
            try {
              comments = JSON.parse(comments)
              if (!Array.isArray(comments)) {
                return ''
              }
            } catch (e) {
              return ''
            }
            return comments
              .map(comment => {
                let employeeName = ''
                const employee = this.employees.find(emp => emp.Employee_Code === comment.user_id)
                if (employee && employee.Employee_Name) {
                  employeeName = employee.Employee_Name
                }
                return `${employeeName}: ${comment.comments} (${new Date(comment.timestamp * 1000).toLocaleDateString()})`
              })
              .join(' ')
          }
        },
        {
          title: 'Rejected Comment',
          field: 'approved_comments',
          width: 200,
          responsive: 0,
          visible: this.tab === 'admin',
          hideName: (field, val) => false,
          formatter: (cell, formatterParams, onRendered) => {
            let comments = cell.getValue()
            try {
              comments = JSON.parse(comments)
              if (!Array.isArray(comments)) {
                // Ensure comments is an array
                return ''
              }
            } catch (e) {
              return ''
            }
            return comments
              .map(comment => {
                let employeeName = ''
                const employee = this.employees.find(emp => emp.Employee_Code === comment.user_id)
                if (employee && employee.Employee_Name) {
                  employeeName = employee.Employee_Name
                }
                return `${employeeName}: ${comment.comment} (${new Date(comment.timestamp * 1000).toLocaleDateString()})`
              })
              .join(' ')
          }
        },
        {
          field: 'selected',
          visible: false
        }
      ]
    }
  },

  methods: {
    filterPm(id) {
      this.employeeCode = id
      this.getData('employee')
      this.$router.push({ path: `/app/time-approval/${id}` })
    },

    exportResults(type) {
      return new Promise((resolve, reject) => {
        if (type === 'employee') {
          this.subcontractorCode = ''
        } else if (type === 'subcontractor') {
          this.employeeCode = ''
        }
        let employee_code =
          this.employeeCode || this.employeeCode === 0
            ? this.employeeCode
            : this.subcontractorCode
              ? this.subcontractorCode.toString().substring(0, 1) === 'S'
                ? this.subcontractorCode
                : `S${this.subcontractorCode}`
              : ''

        if (!employee_code && !this.canEditOthers) {
          reject(new Error('Problem fetching employee code'))
          return
        }

        if (!this.selectedRange?.start || !this.selectedRange?.end) {
          resolve()
          return
        }

        let approval_status = this.selectedApprovalStatus
        switch (this.selectedApprovalStatus) {
          case 'all':
            approval_status = 'all'
            break
          case 'yes':
            approval_status = 'yes'
            break
          case 'unapproved':
            approval_status = ''
            break
          case 'no':
            approval_status = 'no'
            break
        }

        var data = {
          action: 'timecard_entries_export_to_csv',
          employee_code,
          start_date: dateFormat(this.selectedRange.start, 'isoDate'),
          end_date: dateFormat(this.selectedRange.end, 'isoDate'),
          show_deleted: true,
          selected_jobs: this.selectedJobs,
          selected_wos: this.selectedWOs,
          approval_status
        }

        store.commit('waiting', { status: 1, message: 'Getting Entries CSV' })
        appFuncs.ajax_request(store.getters.sherAuth, data, result => {
          if (result.urlLocation) {
            window.open(result.urlLocation, '_blank')
            resolve()
          } else {
            reject()
            this.$snack.open(result.message || 'Problem getting csv', 'error')
          }
          store.commit('waiting', { status: 0 })
        })
      })
    },

    rowFormatter(row) {
      var data = row.getData() // Get the data from the row
      if (data.approved === 'yes') {
        row.getElement().classList.add('success-color')
      } else if (data.approved === 'no' || data.approved === 'deleted') {
        row.getElement().classList.add('danger-color')
      } else if (data.routing && this.tab !== 'Entries') {
        row.getElement().classList.add('warning-color')
      }
    },

    selectDate(date) {
      if (date) {
        var prevSunday = new Date(date)
        prevSunday.setDate(prevSunday.getDate() - ((prevSunday.getDay() + 7) % 7))

        var weekNo = moment(prevSunday).format('W')

        /*
          A bit whacky but works for year week pay period change, maybe change back next year to just:
          if (weekNo % 2 !== 0) {
              prevSunday = moment(prevSunday).subtract(1, 'week').toDate();
            }
          */

        if (date.getFullYear() === 2020 || weekNo == 53) {
          if (weekNo % 2 !== 0) {
            prevSunday = moment(prevSunday).subtract(1, 'week').toDate()
          }
        } else {
          if (weekNo % 2 === 0) {
            prevSunday = moment(prevSunday).subtract(1, 'week').toDate()
          }
        }

        var endDate = moment(prevSunday).add(13, 'days').toDate()

        this.selectedRange = {
          start: prevSunday,
          end: endDate,
          formatted: dateFormat(prevSunday, 'mediumDate') + ' - ' + dateFormat(endDate, 'mediumDate')
        }

        this.selectedStartDate = prevSunday
        this.dateSelectAttrs[0].dates[0].start = prevSunday
        this.startDate = null
        this.endDate = null
      } else {
        this.selectedRange = {
          start: this.startDate,
          end: this.endDate,
          formatted: ''
        }
        this.selectedStartDate = null
      }
    },

    selectFilterItem(type) {
      // if (type === 'job') {
      //   this.selectedWOs = [];
      // } else if (type === 'wo') {
      //   this.selectedJobs = [];
      // }
      this.getEntries()
    },

    getEntries(type) {
      return new Promise((resolve, reject) => {
        if (type === 'employee') {
          this.subcontractorCode = ''
        } else if (type === 'subcontractor') {
          this.employeeCode = ''
        }

        // Determine employee_code
        let employee_code = this.employeeCode ?? (this.subcontractorCode ? (this.subcontractorCode.startsWith('S') ? this.subcontractorCode : `S${this.subcontractorCode}`) : '')

        // Override employee_code for non-admin users on approval tab
        if (this.tab === 'approval' && !this.isPayrollUser) {
          employee_code = this.sherAuth.eid
          this.employeeCode = employee_code
        }

        // Reject if no employee_code and can't edit others
        if (!employee_code && !this.canEditOthers) {
          reject(new Error('Problem fetching employee code'))
          return
        }

        // Resolve early if no date range selected
        const startDate = this.selectedRange?.start || this.startDate
        const endDate = this.selectedRange?.end || this.endDate
        if (!startDate && !endDate) {
          console.log('No date range selected')
          resolve()
          return
        }

        // Determine action based on tab
        const actionMap = {
          Entries: 'get_timecard_entries',
          approval: 'get_timecard_entries_for_approval',
          admin: 'get_timecard_entries_for_payroll_admin'
        }
        const action = actionMap[this.tab] || ''

        // Set approval status
        const approval_status = this.selectedApprovalStatus === 'unapproved' ? '' : this.selectedApprovalStatus

        const params = {
          action,
          employee_code,
          start_date: dateFormat(this.selectedRange?.start || this.startDate, 'isoDate'),
          end_date: dateFormat(this.selectedRange?.end || this.endDate, 'isoDate'),
          show_deleted: true,
          selected_jobs: this.selectedJobs,
          selected_wos: this.selectedWOs,
          forApproval: this.tab === 'approval',
          approval_status
        }

        this.entryData = []

        this.$bus.$emit('setWaiting', { name: 'getTimeCardEntries', message: 'Getting Entries' })

        appFuncs
          .shRequest(params)
          .then(async data => {
            this.entryData = data
            const jobNumbers = [...new Set(data.map(entry => entry.Job_Number).filter(Boolean))]
            await this.getJobPhases(jobNumbers)
            this.formatEntryData()
            resolve()
          })
          .catch(err => {
            console.log(err)
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'getTimeCardEntries')
          })
      })
    },

    async getJobPhases(jobNumbers) {
      if (!jobNumbers || jobNumbers.length === 0) {
        return Promise.resolve()
      }

      let entries = [...this.entryData]
      let promises = []

      for (let jobNumber of jobNumbers) {
        const index = this.jobPhaseData.indexOf(jobNumber)
        if (index === -1) {
          this.jobPhaseData.push({ job_number: jobNumber })

          const params = {
            action: 'get_job_phase',
            job_number: jobNumber,
            status_code: 'A'
          }

          promises.push(
            appFuncs.shRequest(params).then(phaseResult => {
              const phases = phaseResult.filter(itm => {
                return itm && itm.Cost_Type === 'L'
              })

              if (phases) {
                const i = this.jobPhaseData.findIndex(item => item.job_number === jobNumber)
                if (i > -1) {
                  this.jobPhaseData[i].phases = phases
                }
              }
            })
          )
        }
      }

      await Promise.all(promises)

      // add phase data to entries
      for (let entry of entries) {
        const jobNumber = entry.job_number
        const phaseData = this.jobPhaseData.find(item => item.job_number === jobNumber)

        if (phaseData) {
          const matchingPhase = phaseData.phases.find(phase => phase.Phase_Code === entry.phase_code)
          entry.phase_description = matchingPhase?.Description
        }
      }
    },

    formatEntryData() {
      if (!Array.isArray(this.entryData)) {
        return
      }

      let entries = [...this.entryData] // copy array

      for (var i = entries.length - 1; i >= 0; i--) {
        entries[i].HoursRegular = 0
        entries[i].HoursOT = 0
        entries[i].HoursDT = 0
        let hoursQty = parseFloat(entries[i].hours)

        // if (hoursQty < 0) {
        //   entries.splice(i, 1);
        //   continue;
        // }

        switch (entries[i].pay_type) {
          case 'R':
            entries[i].HoursRegular = hoursQty
            break
          case 'O':
            entries[i].HoursOT = hoursQty
            break
          case 'D':
            entries[i].HoursDT = hoursQty
            break
        }
        // this.totalHoursRegular+= entries[i].HoursRegular;
        // this.totalHoursOT+= entries[i].HoursOT;
        // this.totalHoursDT+= entries[i].HoursDT;

        switch (entries[i].posted) {
          case 'true':
            entries[i].is_posted = 'Yes'
            break
          case 'queue':
            entries[i].is_posted = 'Queued'
            break
          case 'delete':
            entries[i].is_posted = 'Reversed'
            break
          default:
            entries[i].is_posted = 'No'
            break
        }

        var deptCode = ''
        switch (entries[i]['department_code'] + entries[i]['pay_type']) {
          case 'JOB VHV':
            deptCode = 'Vacation'
            break
          case 'JOB VHH':
            deptCode = 'Stat Holiday'
            break
          case 'SBS':
            deptCode = 'Sick'
            break
          case 'SBH':
            deptCode = 'Bereavement'
            break
          case 'WOO':
          case 'WOR':
          case 'WOD':
            deptCode = 'Work Order'
            break
          case 'JOBR':
          case 'JOBO':
          case 'JOBD':
            deptCode = 'Job'
            break
          default:
            deptCode = entries[i]['department_code']
        }
        entries[i]['type'] = deptCode

        // if (!entries[i]['timestamp']) {
        //   entries[i]['posted'] = true
        // }

        if (entries[i]['posted']) {
          // entries[i]['class'] = 'blue';
          entries[i]['noItemEdit'] = true
        }

        const messageLen = 900
        let message = entries[i].message ? entries[i].message.replace(/<\/div>/g, '\n') : ''
        message = sanitizeHtml(message, { allowedTags: [] })
        if (message.length > messageLen) {
          message = message.substring(0, messageLen) + '...'
        }
        entries[i].message = message

        entries[i].customer = entries[i].customer_name || entries[i].Name

        // quotes - this should come after setting customer
        if (entries[i].customer) {
          let quotes = this.quoteData
          quotes.sort((a, b) => b.quote_incr - a.quote_incr)
          const qt = quotes.find(quote => quote.quote_incr == entries[i].quote_incr)
          entries[i].quote_description = qt ? qt.description : ''
          entries[i].quote_cus_description = qt ? `${qt.quote_number} - ${qt.customer} - ${qt.description}` : ''
        }

        let employee = this.employees.find(itm => {
          return itm.Employee_Code == entries[i].employee_code
        })
        entries[i].employee_name = employee ? employee.Employee_Name : ''

        entries[i].approverId = null
        if (entries[i].routing) {
          entries[i].approverId = entries[i].routing
        } else if (entries[i].job_number) {
          entries[i].approverId = entries[i].Project_Manager
        } else if (entries[i].wo_number) {
          entries[i].approverId = entries[i].PM
        }
      }
      this.tableData = entries
    },

    getEntriesForApproval() {
      const pm = this.$store.getters.userAuthedData.eid
      const params = {
        action: 'get_timecard_entries_for_approval',
        pm
      }

      this.$bus.$emit('setWaiting', {
        name: 'getEntriesForApproval',
        message: 'Getting Entries'
      })
      appFuncs
        .shRequest(params, data => {
          this.approvalTableData = data
        })
        .catch(err => {
          this.$snack.open(err || 'Problem getting entries', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getEntriesForApproval')
        })
    },

    addItem(event) {
      // const componentName = event.altKey ? 'add-time-entry-duplicated' : 'add-time-entry'

      this.$bus.$emit('modalOpen', {
        title: 'Add Time Entry',
        classes: 'gray-bg',
        name: 'add-time-entry-duplicated',
        size: 'modal-l',
        component: 'add-time-entry-duplicated'
      })
    },

    addForemanTimeEntry() {
      this.$Modal({
        parent: this,
        name: 'add-foreman-time-entry',
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        title: 'Add Time Entries',
        component: () => import('components/Dashboard/TimeEntry/BulkTimeEntry.vue'),
        props: {
          department: 'JOB',
          jobNumber: '',
          wo_number: '',
          inlineSelectItems: false,
          selectedPhase: '',
          allEmployees: this.employees
          // editId: id
        }
      })
    },

    colClick(obj) {
      const col = ((obj || {}).cell || {}).field || null
      let posted = ((obj || {}).row || {}).posted || null
      let id = ((obj || {}).cell || {}).id || null

      // covers tabulator table dynamic
      id = id || obj.id

      if ((col === 'routing_comments' || col === 'approved_comments') && obj.cell.value != undefined && obj.cell.value != null && obj.cell.value != '') {
        this.showAllComments(obj.row)
        return false
      }

      const isPosted = posted && posted !== 'No' ? true : false

      if (!id || posted === 'delete' || (isPosted && !this.isAdmin)) {
        this.$snack.open('This entry cannot be edited', 'warning')
        return
      }

      this.editItem(id)
    },

    showAllComments(row) {
      this.$bus.$emit('modalOpen', {
        title: 'Routing History',
        classes: 'gray-bg',
        name: 'comment-history',
        size: 'modal-l',
        component: () => import('src/components/Dashboard/TimeEntry/TimeCardRoutingHistory.vue'),
        componentData: {
          row,
          employees: this.employees
        }
      })
    },

    editItem(id) {
      this.$bus.$emit('modalOpen', {
        title: 'Edit Time Entry',
        classes: 'gray-bg',
        name: 'add-time-entry-duplicated',
        size: 'modal-l',
        component: 'add-time-entry-duplicated',
        componentData: {
          editId: id
        }
      })
    },

    adminSyncAllEntries() {
      if (!confirm('Are you sure? This will prevent edits from being made to all employee time entries currently saved within this system.')) {
        return
      }

      store.commit('waiting', {
        status: 1,
        message: 'Requesting time entry sync'
      })
      appFuncs.ajax_request(store.getters.sherAuth, { action: 'employee_post_type_queue_all' }, result => {
        store.commit('waiting', { status: 0 })
        if (result.status === 'success') {
          this.$snack.open(result.message)
        } else {
          this.$snack.open(result.message || 'Problem syncing entries', 'error')
        }
      })
    },

    async getData(type) {
      const entryFetch = this.getEntries(type)
      const employeeFetch = this.$store.dispatch('getEmployees')
      const subcontractorFetch = this.$store.dispatch('getSubcontractors')
      const customersFetch = this.$store.dispatch('getCustomers')
      const getQuotes = this.$store.dispatch('getQuotes')

      this.$bus.$emit('setWaiting', {
        name: 'getEmployees',
        message: 'Getting Data'
      })

      Promise.all([entryFetch, employeeFetch, subcontractorFetch, customersFetch, getQuotes])
        .then(data => {
          this.formatEntryData()
        })
        .catch(err => {
          console.log(err)
          this.$snack.open('Problem fetching some data', 'error')
          const Bugsnag = this.$Bugsnag
          if (typeof Bugsnag !== 'undefined') {
            Bugsnag.metaData = {
              sherAuth: this.sherAuth,
              timeEntry: {
                err,
                type
              }
            }
            Bugsnag.notify(new Error('time entry fetch error '))
          }
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getEmployees')
        })
    },

    acceptEntries() {
      let selectedRows
      if (this.tab === 'approval') {
        selectedRows = this.$refs.approvalTable.getSelectedData()
      } else {
        selectedRows = this.$refs.adminTable.getSelectedData()
      }

      if (!selectedRows.length) {
        this.$snack.open('No entries selected', 'warning')
        return
      }

      const ids = selectedRows.map(itm => {
        return itm.id
      })

      const params = {
        action: 'approve_reject_timecard_entries',
        ids,
        approved: 'yes'
      }

      this.$bus.$emit('setWaiting', { name: 'acceptEntries', message: 'Accepting Entries' })
      appFuncs
        .shRequest(params)
        .then(() => {
          this.$snack.open('Success', 'success')
          this.getEntries()
        })
        .catch(err => {
          this.$snack.open(err || 'Problem accepting entries', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'acceptEntries')
        })
    },

    routeEntries() {
      let selectedRows

      if (this.tab === 'approval') {
        selectedRows = this.$refs.approvalTable.getSelectedData()
      } else if (this.tab === 'admin') {
        selectedRows = this.$refs.adminTable.getSelectedData()
      }

      if (!selectedRows.length) {
        this.$snack.open('No entries selected', 'warning')
        return
      }

      const ids = selectedRows.map(itm => {
        return itm.id
      })

      this.$Modal({
        parent: this,
        name: 'time-entry-route',
        size: 'sm', // sm, md, lg, xl
        hideClose: false,
        title: 'Route',
        component: () => import('components/Dashboard/TimeEntry/TimeEntryRoute.vue'),
        props: {
          ids
        }
      })
    },

    rejectEntries() {
      let selectedRows
      if (this.tab === 'approval') {
        selectedRows = this.$refs.approvalTable.getSelectedData()
      } else {
        selectedRows = this.$refs.adminTable.getSelectedData()
      }

      if (!selectedRows.length) {
        this.$snack.open('No entries selected', 'warning')
        return
      }

      const ids = selectedRows.map(itm => {
        return itm.id
      })

      this.$Modal({
        parent: this,
        name: 'time-entry-reject',
        size: 'sm', // sm, md, lg, xl
        hideClose: false,
        title: 'Reject',
        component: () => import('components/Dashboard/TimeEntry/TimeEntryReject.vue'),
        props: {
          ids
        }
      })
    },

    deleteEntries() {
      const selectedRows = this.$refs.adminTable.getSelectedData()
      if (!selectedRows.length) {
        this.$snack.open('No entries selected', 'warning')
        return
      }

      const ids = selectedRows.map(itm => {
        return itm.id
      })

      const params = {
        action: 'approve_reject_timecard_entries',
        ids,
        approved: 'deleted'
      }

      this.$bus.$emit('setWaiting', { name: 'deleting', message: 'Deleting Entries' })
      appFuncs
        .shRequest(params)
        .then(() => {
          this.$snack.open('Success', 'success')
          this.getEntries()
        })
        .catch(err => {
          this.$snack.open(err || 'Problem deleting entries', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'deleting')
        })
    },

    clearFilters(event) {
      event.preventDefault()
      this.employeeCode = this.sherAuth.eid
      this.selectDate(new Date())
      this.subcontractorCode = ''
      this.filterEmployee = ''
      this.filterWO = ''
      this.filterJob = ''
      this.entryFilter = ''
      this.selectedWOs = []
      this.selectedJobs = []
      this.region = null
      this.selectedApprovalStatus = 'all'
      this.selectAll = 'no'
    },

    getJobList() {
      const params = {
        action: 'getJobList',
        notStatus: 'X'
      }

      this.$bus.$emit('setWaiting', { name: 'getJobList', message: 'Getting Job List' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.jobList = data
        })
        .catch(err => {
          this.$snack.open(err || 'Problem getJobList entries', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getJobList')
        })
    },

    getWOList() {
      const params = {
        action: 'getWOList'
      }

      this.$bus.$emit('setWaiting', { name: 'getWOList', message: 'Getting WO List' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.woList = data
        })
        .catch(err => {
          this.$snack.open(err || 'Problem getWOList entries', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getWOList')
        })
    }
  },

  async mounted() {
    this.employeeCode = this.sherAuth.eid
    this.selectDate(new Date())
    this.getJobList()
    this.getWOList()

    if (this.page === 'approval') {
      this.tab = 'approval'
    } else {
      this.tab = 'Entries'
    }

    // this.getData()

    if (this.filterApproval) {
      this.tab = 'approval'
      this.filterPm(this.filterApproval)
    }

    this.$bus.$on('add-update-time-entry', this.getEntries)
  },

  watch: {
    selectedRange: {
      handler(newVal, oldVal) {
        const startChange = newVal?.start !== oldVal?.start
        const endChange = newVal?.end !== oldVal?.end
        if (startChange || endChange) {
          this.getData()
        }
      },
      immediate: false
    },
    page(newVal, oldVal) {
      if (this.page === 'approval') {
        this.tab = 'approval'
      } else {
        this.tab = 'Entries'
      }
      if (newVal && newVal !== oldVal) {
        this.getEntries()
      }
    }
  },

  beforeDestroy() {
    this.$bus.$off('add-update-time-entry', this.getEntries)
  }
}
</script>

<style lang="scss">
@import 'src/assets/sass/paper/_variables.scss';

.legend {
  padding: 10px;
  text-align: right;
  font-size: 0;

  > div {
    display: inline-block;
    background: #fff;
    padding: 5px 10px 5px 5px;
    font-size: 14px;
    cursor: pointer;

    span {
      width: 26px;
      height: 26px;
      display: inline-block;
      line-height: 26px;
      margin: 5px;
      vertical-align: middle;

      &.green {
        background: #dafee5;
      }

      &.yellow {
        background: #fdf1c1;
      }

      &.orange {
        background: #ffce9a;
      }

      &.red {
        background: #ffc0b5;
      }

      &.blue {
        background: #daebfe;
      }

      &.purple {
        background: #f3dafe;
      }

      &.white {
        background: #fff;
        border: 1px solid;
      }
    }
  }
}

.instruction-info {
  max-width: 1000px;
  padding: 20px 40px;

  li {
    padding: 10px 0;
  }
}

.hide-entry-comments-checkbox {
  margin-top: 33px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.button-group-mobile-sticky {
  background: #fff;
  padding: 3px 0;
  position: sticky;
  top: 0;
  z-index: 5;
  margin: 0px 0;
  font-size: 0;

  .inner {
    max-width: 700px;

    .button-container {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      width: calc(100% - 40px);
    }

    .inline-checkbox {
      display: inline-block;
      vertical-align: middle;
      margin-left: 15px;
      width: 20px;
    }
  }

  .btn,
  .btn:active,
  .btn:focus,
  .btn:hover {
    width: calc(25% - 6px);
    margin: 5px 3px;
    padding: 12px 0 !important;
    font-size: 11px;
  }
}
</style>

<style lang="scss">
[tabulator-field='message'],
[tabulator-field='routing_comments'],
[tabulator-field='approved_comments'] {
  white-space: normal !important;
  word-wrap: break-word;
  height: auto !important;
}
</style>
