
<div class="sh-widget card">
  <div class="content">
    <h4>WO Status Updates</h4>
    <table @click="gotoTable">
      <tr>
        <th>Age (Days)</th>
        <th>WOs</th>
      </tr>
      <tr>
        <td>0 - 7</td>
        <td :class="[{ green: woAge1 }]">
          {{ woAge1 }}
        </td>
      </tr>
      <tr>
        <td>7 - 14</td>
        <td :class="[{ yellow: woAge2 }]">
          {{ woAge2 }}
        </td>
      </tr>
      <tr>
        <td>14 +</td>
        <td :class="[{ red: woAge3 }]">
          {{ woAge3 }}
        </td>
      </tr>
    </table>
    <waiting-spinner :contained="true" />
  </div>
</div>
