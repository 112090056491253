<template>
  <div>
    <h4 v-if="editIdMutate">Edit Customer Contact</h4>
    <h4 v-else>Add Customer Contact</h4>

    <div class="row">
      <div class="col-sm-12">
        <customer-contacts-output-matches
          :first-name="formVals.FirstName"
          :last-name="formVals.LastName"
          :email="formVals.Email"
          :phone-direct="formVals.Phone"
          :phone-mobile="formVals.MobilePhone"
          :currently-selected-id="formVals.Id"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <customer-select-field v-model="formVals.Account_Customer_Code__c" />

        <div class="form-group">
          <label> Customer Code<span class="required-star"> * </span> </label>
          <input type="text" class="form-control" v-model="formVals.Account_Customer_Code__c" readonly />
        </div>

        <div class="form-group">
          <label>Site</label><span class="required-star">*</span>
          <select-field
            :options="woSitesFiltered"
            :option-display-keys="['Ship_To_Name', 'site_address']"
            :option-val="'sfId'"
            v-model="formVals.Account_Site__c"
            @change="checkWOSiteChanged"
            :filter-on-key-val="{ key: 'Status', val: 'A' }"
          />
          <p v-if="woSiteMissingSFId" class="text-danger" style="padding: 10px 5px">This Site cannot be assigned due to missing reference in Salesforce.</p>
        </div>

        <div class="form-group">
          <label>Status</label>
          <input type="text" class="form-control" v-model="formVals.Status" readonly />
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="form-group">
          <label> First Name<span class="required-star"> * </span> </label>
          <input type="text" class="form-control" v-model="formVals.FirstName" />
        </div>

        <div class="form-group">
          <label> Last Name<span class="required-star"> * </span> </label>
          <input type="text" class="form-control" v-model="formVals.LastName" />
        </div>

        <div class="form-group">
          <label>Title</label>
          <input type="text" class="form-control" v-model="formVals.Title" />
        </div>

        <div class="form-group">
          <label>Direct Phone</label>
          <input type="text" class="form-control" v-model="formVals.Phone" v-mask="chooseMask(formVals.Phone)" />
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="form-group">
          <label>Direct Phone Ext</label>
          <input type="text" class="form-control" v-model="formVals.Phone_Extension__c" />
        </div>

        <div class="form-group">
          <label>Mobile Phone</label>
          <input type="text" class="form-control" v-model="formVals.MobilePhone" v-mask="chooseMask(formVals.MobilePhone)" />
        </div>

        <div class="form-group">
          <label>Email</label>
          <input type="text" class="form-control" v-model="formVals.Email" />
        </div>

        <div class="form-group">
          <label>Role</label><span class="required-star">*</span>
          <select-field :options="roles" :option-display-keys="['name']" :option-val="'name'" v-model="formVals.Contact_Role__c" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <div class="form-group">
          <span class="inline-checkbox">
            <checkbox2 v-model="formVals.AR_Contact__c" :true-val="true" :false-val="false">Default AR Contact</checkbox2>
          </span>
        </div>
      </div>
    </div>

    <br /><br />

    <button type="submit" class="btn" @click="save" v-if="contactSFAccount && contactSFAccount.sfId">Save</button>
    <p v-else-if="formVals.Account_Customer_Code__c" class="text-danger text-medium">
      Cannot add/update contact due to missing reference for selected company in Salesforce. If this customer was just added, please wait a few minutes and try again.
    </p>

    <button v-if="action == 'edit' && !formVals.Inactive__c" type="submit" class="btn" @click="updateStatus(true)">Mark Inactive</button>

    <button v-if="action == 'edit' && formVals.Inactive__c" type="submit" class="btn" @click="updateStatus(false)">Mark Active</button>

    <waiting-spinner />
  </div>
</template>

<script>
import store from 'store'
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import CustomerSelectField from 'components/UIComponents/CustomerSelectField'
import SelectField from 'components/UIComponents/SelectField'
import CustomerContactsOutputMatches from 'components/Dashboard/Contacts/CustomerContactsOutputMatches'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import Checkbox2 from 'components/UIComponents/Checkbox'

export default {
  data() {
    return {
      editIdMutate: '',
      formVals: {
        FirstName: '',
        LastName: '',
        Title: '',
        Phone: '',
        Phone_Extension__c: '',
        MobilePhone: '',
        Email: '',
        Phone: '',
        Account_Customer_Code__c: '',
        Account_Site__c: '',
        AR_Contact__c: ''
      },
      woSiteMissingSFId: false,
      roles: [
        { name: 'Automation Manager' },
        { name: 'Engineering Manager' },
        { name: 'Maintenance Manager Lead' },
        { name: 'Maintenance Staff' },
        { name: 'Plant Manager' },
        { name: 'Process Improvement Person' },
        { name: 'Other' },
        { name: 'Engineering Project Lead' },
        { name: 'Owner/Operator' },
        { name: 'Continuous Improvement' },
        { name: 'Plant Admin' },
        { name: 'Purchasing Team' },
        { name: 'GC Owner' },
        { name: 'GC Estimating Manager' },
        { name: 'GC Estimating Team' },
        { name: 'GC Admin' },
        { name: 'GC Project Lead' },
        { name: 'ST Owner' },
        { name: 'ST Estimating Manager' },
        { name: 'ST Estimating Team' },
        { name: 'ST Admin' },
        { name: 'ST Project Lead' }
      ]
    }
  },

  components: {
    CustomerSelectField,
    CustomerContactsOutputMatches,
    SelectField,
    WaitingSpinner,
    Checkbox2
  },

  props: {
    preSelected: {
      type: Object,
      default() {
        return {}
      }
    },
    editId: {
      type: [String, Number],
      default: null
    },
    component: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters(['customerContacts', 'customers', 'woSites']),

    contactSFAccount() {
      return this.customers.find(itm => {
        return itm.Customer_Code == this.formVals.Account_Customer_Code__c
      })
    },

    componentName() {
      return this.component // just changes name of prop
    },

    action() {
      if (this.editIdMutate) {
        return 'edit'
      } else {
        return 'add'
      }
      return null
    },

    woSitesFiltered() {
      return this.woSites.filter(itm => {
        return itm.Ship_To_Customer_Code === this.formVals.Account_Customer_Code__c
      })
    }
  },

  methods: {
    save() {
      let fields = {
        FirstName: this.formVals.FirstName,
        LastName: this.formVals.LastName,
        Title: this.formVals.Title,
        Email: this.formVals.Email,
        Phone: this.formVals.Phone,
        Phone_Extension__c: this.formVals.Phone_Extension__c,
        MobilePhone: this.formVals.MobilePhone,
        AccountId: this.contactSFAccount.sfId,
        Account_Site__c: this.formVals.Account_Site__c,
        Contact_Role__c: this.formVals.Contact_Role__c,
        AR_Contact__c: this.formVals.AR_Contact__c
      }

      if (this.formVals.Account_Customer_Code__c && !this.contactSFAccount.sfId) {
        this.$snack.open('', 'error')
      }

      if (!fields.AccountId || !fields.FirstName || !fields.LastName) {
        this.$snack.open('Missing fields required', 'warning')
        return
      }

      if (fields.MobilePhone && this.stripToNumber(fields.MobilePhone).length < 10) {
        this.$snack.open('Mobile Phone too short', 'warning')
        return
      }

      if (fields.Phone && this.stripToNumber(fields.Phone).length < 10) {
        this.$snack.open('Phone too short', 'warning')
        return
      }

      if (this.action === 'edit') {
        const params = {
          action: 'sf_update_customer_contact',
          Id: this.formVals.sfId,
          data: fields
        }

        this.$bus.$emit('setWaiting', { name: 'sf_update_customer_contact', message: 'Updating Contact' })
        appFuncs
          .shRequest(params)
          .then(data => {
            this.getContacts(1)
            this.$snack.open('Contact updated')
            this.$bus.$emit('modalClose', this.componentName)
          })
          .catch(res => {
            this.$snack.open(res.message || 'Problem updating contact', 'warning')
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'sf_update_customer_contact')
          })
      } else if (this.action === 'add') {
        const params = {
          action: 'sf_add_customer_contact',
          data: fields
        }

        this.$bus.$emit('setWaiting', { name: 'sf_add_customer_contact', message: 'Adding Contact' })
        appFuncs
          .shRequest(params)
          .then(data => {
            this.getContacts(1)
            this.$snack.open('Contact added')
            this.$bus.$emit('modalClose', this.componentName)
          })
          .catch(res => {
            this.$snack.open(res.message || 'Problem adding contact', 'warning')
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'sf_add_customer_contact')
          })
      }
    },

    updateStatus(active) {
      const params = {
        action: 'sf_update_customer_contact',
        Id: this.formVals.sfId,
        data: {
          Inactive__c: active
        }
      }

      this.$bus.$emit('setWaiting', { name: 'sf_update_customer_contact', message: 'Updating Contact' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.getContacts(1)
          this.$snack.open('Contact updated')
          this.$bus.$emit('modalClose', this.componentName)
        })
        .catch(res => {
          this.$snack.open(res.message || 'Problem updating contact', 'warning')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'sf_update_customer_contact')
        })
    },

    getContacts(refresh) {
      this.$bus.$emit('setWaiting', { name: 'getCustomerContacts', message: 'Getting Customer Contacts' })
      this.$store
        .dispatch('getCustomerContacts', refresh)
        .then(() => {
          this.selectItem()
          this.$bus.$emit('bhUpdate', 'customer-contacts-updated')
          this.$bus.$emit('contactEdit')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getCustomerContacts')
        })
    },

    getCustomers(refresh) {
      this.$bus.$emit('setWaiting', { name: 'getCustomers', message: 'Getting Customers' })
      this.$store
        .dispatch('getCustomers', refresh)
        .then(() => {})
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getCustomers')
        })
    },

    selectItem(id) {
      id = id || this.editIdMutate
      if (id) {
        let data = this.customerContacts.find(itm => {
          return itm.id == id
        })

        if (!data) return

        // if not editId assign from selectItm
        this.editIdMutate = id

        this.formVals = JSON.parse(JSON.stringify(data))
      }
    },

    checkWOSiteChanged(sfId, siteObj) {
      if (!sfId && siteObj.Ship_To_ID) {
        this.woSiteMissingSFId = true
      } else {
        this.woSiteMissingSFId = false
      }
    }
  },

  mounted() {
    this.getCustomers()
    if (this.editIdMutate) {
      this.getContacts()
    }
    this.selectItem()

    // set from prop
    if (this.editId) {
      this.editIdMutate = this.editId
    }

    if (this.preSelected.Account_Customer_Code__c) {
      this.formVals.Account_Customer_Code__c = this.preSelected.Account_Customer_Code__c
    }

    this.$bus.$on('select-show-customer-contact', id => {
      this.selectItem(id)
    })

    this.$bus.$emit('setWaiting', { name: 'getWOSites', message: 'Getting WO Sites' })
    this.$store.dispatch('getWOSites').finally(() => {
      this.$bus.$emit('stopWaiting', 'getWOSites')
    })
  },

  watch: {
    editIdMutate() {
      this.selectItem()
    }
  },

  beforeDestroy() {
    this.$bus.$off('select-show-customer-contact')
  }
}
</script>
