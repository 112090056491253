<template>
  <component :is="selectFieldComponentToRender" v-bind="$attrs" v-on="$listeners" ref="dynamicTable"/>
</template>

<script>
import TableTiles from 'components/UIComponents/TableTiles'
import TabulatorTable from 'components/UIComponents/TabulatorTable'

export default {
  data() {
    return {}
  },

  computed: {
    selectFieldComponentToRender() {
      if (this.isMobile) {
        return TableTiles
      } else {
        return TabulatorTable
      }
    }
  },

  methods: {
    getSelectedData() {
      return this.$refs.dynamicTable.getSelectedData()
    }
  }
}
</script>
