<template>
  <div>
    <div class="row" style="margin-bottom: 40px">
      <div class="col col-sm-12">
        <tabulator-table
          :table-data="filteredRows"
          :table-columns="tableColumns"
          :allow-new-row="false"
          :max-table-height="'700px'"
          :table-condensed="false"
          :selectable="false"
          :row-formatter="rowFormatter"
          :table-fit="'fitColumns'"
          :data-tree="true"
          class="breakout-table"
        />
      </div>
    </div>

    <waiting-spinner />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'QuoteInfo',
  data() {
    return {
      data: [],
      tab: 'Systems'
    }
  },

  props: {
    type: {
      type: String,
      default: 'labor'
      // required: true
    },
    includeOptions: {
      type: Boolean,
      default: true
    },
    laborEntries: {
      type: Object,
      default: () => ({})
    }
  },

  components: {
    TabulatorTable,
    WaitingSpinner
  },

  computed: {
    ...mapGetters(['userAuthedData']),

    filteredRows() {
      const data = JSON.parse(JSON.stringify(this.data))
      return data
      let out = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].id !== 'total') {
          if (data[i]._children && data[i]._children.length > 0) {
            out.push(data[i])
          }
        } else {
          out.push(data[i])
        }
      }
      return out
    },

    tableColumns() {
      return [
        {
          field: 'id',
          visible: false
        },
        {
          title: 'Description',
          field: 'name',
          sorter: 'string',
          minWidth: 300,
          tooltip: true,
          widthGrow: 4
        },
        {
          title: 'Details',
          field: 'details',
          sorter: 'string',
          minWidth: 300,
          tooltip: true,
          widthGrow: 5
        },
        {
          title: 'Hours',
          sorter: 'number',
          field: 'qty',
          cssClass: 'no-horo-border',
          width: 120,
          sorter: (a, b) => {
            return this.stripToNumber(a) - this.stripToNumber(b)
          }
          // visible: false
        },
        {
          title: 'Hour Type',
          field: 'hourType',
          editable: false,
          formatter: function (cell, formatterParams, onRendered) {
            let value = Number(cell.getValue()) // Convert value to a number
            switch (value) {
              case 1:
                return 'Regular'
              case 1.5:
                return 'Over Time'
              case 2:
                return 'Double Time'
              default:
                return ''
            }
          },
          width: 120,
          sorter: false
        }
      ]
    }
  },

  methods: {
    rowFormatter(row) {
      //row - row component
      //row - row component
      let data = row.getData()
      if (data.id == 0) {
        row.getElement().style.backgroundColor = '#f2f2f2'
      }
      if (data.as_option === 'yes') {
        row.getElement().classList.add('as-option')
      }
      if (data.exclude === 'yes') {
        row.getElement().classList.add('exclude')
      }
      if (data.id === 'total') {
        row.getElement().classList.add('total-row')
      }
      if (data.rowType === 'group-total-row') {
        row.getElement().classList.add('group-total-row')
      }
      if (data.rowType === 'group-header-row') {
        row.getElement().classList.add('group-header-row')
      }
      if (data.rowType === 'indirect-labor-row') {
        row.getElement().classList.add('indirect-labor-row')
      }

      let qtyCell = row.getCell('qty')

      if (!data.qty) {
        qtyCell.getElement().innerHTML = '' // Clear display for falsy 'qty'
      }
    },

    formatData() {
      if (!this.laborEntries?.materialList?.id) return

      let systems = [...this.laborEntries.materialList.systems] || []
      const indirectLabor = Array.isArray(this.laborEntries.materialList.indirect_labor) ? this.laborEntries.materialList.indirect_labor : []

      let data = this.laborEntries.labor ?? []

      let out = []
      let qtyTotal = 0
      let costTotal = 0
      let rateTotal = 0

      for (let i = 0; i < systems.length; i++) {
        let sys = {
          id: systems[i].id,
          name: systems[i].name + (systems[i].exclude === 'yes' ? ' (EXCLUDED)' : systems[i].as_option === 'yes' ? ' (OPTIONAL)' : ''),
          number: systems[i].number,
          as_option: systems[i].as_option,
          exclude: systems[i].exclude,
          _children: [],
          rowType: 'group-header-row',
          qty: 0
        }
        if (this.type === 'labor') {
          let summedNested = { name: '', qty: 0, cost: 0, entryCount: 0, costTotal: 0 }

          for (let di = 0; di < data.length; di++) {
            let item = data[di]
            let itemSysId = item.si
            if (itemSysId != systems[i].id) continue

            let nested = item['l'] || []
            for (let ni = 0; ni < nested.length; ni++) {
              summedNested.qty += parseFloat(nested[ni].qty)
              summedNested.name = summedNested.name + ', ' + nested[ni].name
              summedNested.entryCount++
              const itm = Object.assign({}, nested[ni])
              itm.id = this.randomCharacterString(10)
              itm.details = itm.d
              itm.hourType = itm.ht

              sys._children.push(itm)
            } // end system-nested labor loop
          } // end labor entry loop

          // if no labor entries for this system, do not show
          if (!summedNested.entryCount) continue

          // at this point all labor entries for this system are in sys._children

          // add indirect labor items
          for (let ilt = 0; ilt < indirectLabor.length; ilt++) {
            const idlSystems = indirectLabor[ilt].systems
            for (let ils = 0; ils < idlSystems.length; ils++) {
              if (idlSystems[ils].id !== systems[i].id) continue
              const qty = parseFloat(((idlSystems[ils].allocation / 100) * summedNested.qty).toFixed(2)) // @TODO allocation percent multiplied by total system hours
              const itm = {
                id: this.randomCharacterString(10),
                name: indirectLabor[ilt].name,
                details: `[Indirect Labor]`,
                hourType: 1,
                qty,
                rowType: 'indirect-labor-row'
              }

              summedNested.qty += itm.qty

              sys._children.push(itm) // @TODO need to get to the bottom of items in this system
            }
          }

          // system total row incl indirect labor
          let ch = {
            id: this.randomCharacterString(10),
            name: '',
            details: 'Total',
            qty: summedNested.qty,
            as_option: systems[i].as_option,
            exclude: systems[i].exclude,
            rowType: 'group-total-row'
          }
          if ((this.includeOptions || systems[i].as_option !== 'yes') && systems[i].exclude !== 'yes') {
            qtyTotal += summedNested.qty
          }
          sys._children.push(ch)

          out.push(sys)
        } // end if labor condition
      } // end of outer systems loop

      let calcRow = {
        id: 'total',
        name: this.includeOptions ? 'Total Incl Options' : 'Quote Total Excl Options',
        qty: qtyTotal
      }
      out.push(calcRow)

      this.data = out
    }
  },

  watch: {
    laborEntries: {
      handler(value) {
        if (!value) return
        this.formatData()
      },
      immediate: true
    }
  },

  beforeDestroy() {}
}
</script>

<style lang="scss">
@import 'src/assets/sass/paper/_variables.scss';

.breakout-table {
  .as-option {
    background: $blue-gray-bg !important;
    color: $medium-dark-gray;
  }

  .group-total-row {
    font-weight: bold;
    // background: $lighter-gray !important;
    .tabulator-data-tree-branch {
      display: none;
    }
  }

  .exclude {
    background: $red-gray-bg !important;
    color: $medium-dark-gray;
  }

  .total-row {
    font-weight: bold;
  }
}
</style>
