<template>
  <div class="checkbox-container" :class="[classes]" @click="toggle()">
    <div class="checkbox-group" :style="checked ? color && `background-color: ${color}; border-color: ${color};` : null">
      <div class="checkbox-ripple-container">
        <input type="checkbox" :id="id || uniqueId" :name="name" :value="checkboxValue" :disabled="disabled" :required="required" :color="color" :checked="checked"/>
      </div>
    </div>
    <label class="checkbox_label" :for="id || uniqueId" @click.prevent>
      <slot/>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: undefined
    },
    // checked: Boolean,
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    name: String,
    required: Boolean,
    disabled: Boolean,
    color: String,
    trueVal: {
      type: [String, Number, Boolean],
      default: true
    },
    falseVal: {
      type: [String, Number, Boolean],
      default: false
    }
  },
  data() {
    return {
      uniqueId: '',
      checked: false,
      checkboxValue: ''
    }
  },
  computed: {
    classes() {
      return {
        'checkbox-disabled': this.disabled,
        'checkbox-active': this.checked
      }
    }
  },
  methods: {
    toggle() {
      if (this.disabled) return

      if (this.checked) {
        this.checkboxValue = this.falseVal
        this.checked = false
      } else {
        this.checkboxValue = this.trueVal
        this.checked = true
      }
      this.$emit('change', this.checkboxValue)
      this.$emit('input', this.checkboxValue)
      this.$emit('changeValue', this.checked)
    },

    genId() {
      if (this.id === undefined || typeof String) this.uniqueId = `m-checkbox--${Math.random().toString(36).substring(2, 10)}`
      else this.uniqueId = this.id
    }
  },
  mounted() {
    this.genId()
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.checked = val === this.trueVal
        this.checkboxValue = val
      }
    }
  }
}
</script>

<style lang="scss">
.__ripple__container {
  color: inherit;
  border-radius: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
  contain: strict;
}

.__ripple__animation {
  color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: currentColor;
  opacity: 0;
  transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
  pointer-events: none;
  overflow: hidden;
  will-change: transform, opacity;
}

.__ripple__animation--enter {
  transition: none;
}

.__ripple__animation--visible {
  opacity: 0.15;
}

.checkbox-ripple-container {
  box-sizing: border-box;
  z-index: 1;
  position: absolute;
  width: 48px;
  height: 48px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.checkbox-container {
  box-sizing: border-box;
  display: inline-flex;
  position: relative;
  margin: 1rem 0;
  margin-right: 1rem;
  width: 100%;
  line-height: 20px;
  cursor: pointer;

  .checkbox_label {
    position: relative;
    padding-left: 1rem;
    cursor: pointer;
  }

  .checkbox-group {
    box-sizing: border-box;
    position: relative;
    border-radius: 2px;
    border: 2px solid rgba(0, 0, 0, 0.54);
    height: 20px;
    width: 20px;
    min-width: 20px;
    transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

    input[type='checkbox'] {
      position: absolute;
      -webkit-appearance: none;
      appearance: none;
      left: -999rem;
    }

    &:after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      transition: transform 0.25s ease;
      width: 6px;
      height: 13px;
      top: 0;
      left: 5px;
      z-index: 6;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      opacity: 0;
      transform: rotate(45deg) scale3D(0.1, 0.1, 0.1);
    }
  }

  &.checkbox-active {
    .checkbox-group {
      background-color: #009688;
      border-color: #009688;

      &:after {
        opacity: 1;
        transform: rotate(45deg) scale3d(1, 1, 1);
      }
    }
  }
}

.checkbox-disabled {
  cursor: not-allowed;

  .checkbox-group {
    opacity: 0.14;
  }

  .checkbox_label {
    opacity: 0.24;
    cursor: not-allowed;
  }
}
</style>
