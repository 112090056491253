<template>
  <div class="select-field" v-click-outside="closeField">
    <input
      type="text"
      :class="[
        'form-control',
        'box-add-item',
        {
          'select-field': !inline
        },
        { 'no-bottom-radius': inline },
        fieldClasses
      ]"
      v-model="fieldVal"
      readonly
      @click="openDropdown"
      :placeholder="placeholder"
      :disabled="disabled"
    />
    <div v-if="(showOptions || inline) && !disabled" class="option-box-parent">
      <div :class="['option-box', { inline: inline }, { toggle: !inline }]">
        <input v-if="compOptions && compOptions.length > 6" type="text" :class="['form-control', 'search-field', 'has-danger']" v-model="searchFilter" placeholder="Filter" />

        <div v-if="allowClear && Object.keys(selectedObj).length > 0" class="option-items show-scrollbar" style="background: none; padding: 0">
          <div class="option-item empty-option-item" @click="doClear">
            <span><span v-html="typeof allowClear === 'string' ? allowClear : 'CLEAR'" /></span>
          </div>
        </div>

        <div class="option-items show-scrollbar">
          <div v-if="showOptionItems">
            <div v-for="(optionItem, key) in filteredItems" :key="key" class="option-item" @click="userSelectEl(optionItem)">
              <div v-if="formatOptionDisplay(optionItem)" v-html="formatOptionDisplay(optionItem)" />
            </div>
          </div>
          <div v-else-if="compOptions && compOptions.length">
            <i v-if="hideOptions && searchFilter.length < 3">Search for an item</i>
            <i v-else>No results</i>
          </div>
          <div v-else>
            <i>{{ emptyOptionText }}</i>
          </div>
        </div>
        <div v-if="allowAddSimpleItem" class="add-simple-item-container">
          <input class="form-control" type="text" v-model="addSimple" :placeholder="'Add ' + (itemName ? itemName : 'Item')" />
          <button class="btn" @click="addSimpleVal" v-html="'Add'" />
        </div>
        <span v-if="allowAddItem" class="add-new" @click="$emit('addItem')">Add New</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fieldVal: '',
      showOptions: false,
      searchFilter: '',
      selectedObj: '',
      placeholder: '',
      addSimple: '',
      loadedFieldVal: '',
      compOptions: [],
      hideOptions: true
    }
  },

  props: {
    options: Array,
    classes: {
      type: Array,
      default: function () {
        return []
      }
    },
    filterDisplayValues: {
      type: Object,
      default() {
        return {}
      }
    },
    optionDisplayKeys: {
      type: Array,
      required: true
    },
    optionVal: {
      type: [String, Array],
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    showAsDisabled: {
      type: Boolean,
      default: false
    },
    optionSeperator: {
      type: String,
      default: ' - '
    },
    emptyOptionText: {
      type: String,
      default: 'No items found'
    },
    disabled: {
      type: [Boolean, Number, String],
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    allowAddSimpleItem: {
      type: Boolean,
      default: false
    },
    allowAddItem: {
      type: Boolean,
      default: false
    },
    placeholderText: {
      type: String,
      default: ''
    },
    itemName: {
      type: String,
      default: ''
    },
    preselectSingleOption: {
      type: Boolean,
      default: true
    },
    allowClear: {
      type: [String, Boolean],
      default: false
    },
    defaultValue: {
      type: [String, Number],
      default: ''
    },
    filterOnKeyVal: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  computed: {
    filteredItems() {
      var options = this.compOptions
      if (this.searchFilter && options) {
        options = this.compOptions.filter(item => {
          var optionStr = this.formatOptionDisplay(item)
          if (optionStr) {
            return String(optionStr.toLowerCase()).indexOf(String(this.searchFilter).toLowerCase()) > -1
          } else {
            return item
          }
        })
      }

      if (this.filterOnKeyVal.key && this.filterOnKeyVal.val) {
        options = options.filter(itm => {
          return itm[this.filterOnKeyVal.key] == this.filterOnKeyVal.val
        })
      }

      if (options && options.length) {
        options.sort((a, b) => {
          return (a.sort || 0) - (b.sort || 0)
        })
      }

      return options
    },

    fieldClasses() {
      return this.classes.toString()
    },

    showOptionItems() {
      return this.filteredItems && this.filteredItems.length && !(this.hideOptions && this.searchFilter.length < 3)
    }
  },

  methods: {
    doClear() {
      this.userSelectEl({})
      this.$emit('clear')
    },

    formatOptionDisplay(optionItem) {
      let displayVal = ''
      for (var i = 0; i < this.optionDisplayKeys.length; i++) {
        if (optionItem[this.optionDisplayKeys[i]] === undefined || optionItem[this.optionDisplayKeys[i]] === null) {
          continue
        }

        let currentMatchingVal = optionItem[this.optionDisplayKeys[i]]
        // Only add the separator if displayVal is not empty and the current value is valid
        if (displayVal && currentMatchingVal) {
          displayVal += this.optionSeperator
        }
        displayVal += currentMatchingVal
      }

      // The filterDisplayValues logic remains unchanged
      if (this.filterDisplayValues) {
        for (var prop in this.filterDisplayValues) {
          if (!this.filterDisplayValues.hasOwnProperty(prop)) continue
          if (prop == displayVal) {
            displayVal = this.filterDisplayValues[prop]
          }
        }
      }

      return displayVal
    },

    setItemByVal(val) {
      if (!this.compOptions || !this.compOptions.length) return

      const foundItem = this.getOptionFromProp(val)
      if (foundItem) {
        this.selectEl(foundItem)
        if (!this.$el?.contains(document.activeElement)) {
          this.showOptions = false
        }
      }
    },

    userSelectEl(optionItem) {
      const optionVal = optionItem && optionItem[this.optionVal] ? optionItem[this.optionVal] : null
      this.selectEl(optionItem)
      this.$emit('selectItem', optionVal, optionItem)

      this.$nextTick(() => {
        const input = this.$el.querySelector('input')

        if (input) {
          input.focus()
        }
      })
    },

    selectEl(optionItem) {
      if (!optionItem) {
        this.fieldVal = JSON.parse(JSON.stringify(this.defaultValue))
        return
      }
      this.showOptions = false
      const optionVal = optionItem && optionItem[this.optionVal] ? optionItem[this.optionVal] : null
      this.fieldVal = this.formatOptionDisplay(optionItem)
      this.$emit('input', optionVal, optionItem)
      this.$emit('change', optionVal, optionItem)
      this.selectedObj = optionItem
    },

    closeField() {
      this.showOptions = false
    },

    getOptionFromProp(optionItem) {
      if (!optionItem) {
        this.selectEl('')
        return
      }

      if (this.compOptions && this.compOptions.length) {
        var val = this.compOptions.find(itm => {
          return itm[this.optionVal] == optionItem
        })

        if (val) {
          this.selectEl(val)
          return val
        } else {
          this.checkSetDefaultItem()
        }
      }
    },

    checkSetDefaultItem() {
      if (!this.fieldVal && !this.defaultValue && Array.isArray(this.compOptions)) {
        let hasDefault = this.compOptions.find(itm => {
          return itm.default === 'yes'
        })
        if (hasDefault) {
          this.userSelectEl(hasDefault)
        }
      }
    },

    addNew() {
      this.$bus.$emit('SelectFieldAddNew')
    },

    addSimpleVal() {
      if (this.addSimple) {
        let newItm = {
          [this.optionVal]: this.addSimple
        }

        // more than one optionDisplayKey, only set the first one
        if (Array.isArray(this.optionDisplayKeys)) {
          newItm[this.optionDisplayKeys[0]] = this.addSimple
        } else {
          newItm[this.optionDisplayKeys] = this.addSimple
        }

        this.compOptions.push(newItm)
        this.$emit('update:options', this.compOptions)
        this.$emit('addOption', newItm)
        this.userSelectEl(newItm)
        this.addSimple = ''
      }
    },

    openDropdown() {
      this.showOptions = this.readOnly ? false : !this.showOptions
      this.$nextTick(() => {
        const searchInput = this.$el.querySelector('.search-field')

        if (searchInput) {
          searchInput.focus()
        }
      })
    }
  },

  mounted() {
    if (this.value) {
      this.getOptionFromProp(this.value)
    }

    if (this.optionVal && Array.isArray(this.optionVal)) {
      this.optionVal === this.optionVal.join(' ')
    }

    if (!this.placeholderText) {
      this.placeholder = this.inline ? 'Select below' : 'Select'
    } else {
      this.placeholder = this.placeholderText
    }

    if (!this.fieldVal && this.defaultValue) {
      this.fieldVal = this.defaultValue
    }

    this.checkSetDefaultItem()

    this.loadedFieldVal = JSON.parse(JSON.stringify(this.fieldVal))
  },

  watch: {
    options: {
      immediate: true,
      handler() {
        this.compOptions = JSON.parse(JSON.stringify(this.options || []))
        if (this.compOptions.length <= 300) {
          this.hideOptions = false
        } else {
          this.hideOptions = true
        }
      }
    },

    // compOptions: {
    //   immediate: true,
    //   handler(newVal) {
    //     if (!newVal || !newVal.length) return

    //     if (this.value) {
    //       this.setItemByVal(this.value)
    //     }

    //     if (!this.value && this.defaultValue) {
    //       this.setItemByVal(this.defaultValue)
    //     }
    //   }
    // },

    compOptions: {
      immediate: true,
      handler() {
        if (!this.showOptions) {
          if (this.value) {
            this.setItemByVal(this.value)
          } else if (this.compOptions.length) {
            this.checkSetDefaultItem()
          }
        }
      }
    },

    value() {
      if (this.value && this.compOptions.length) {
        this.setItemByVal(this.value)
      } else if (!this.value) {
        this.doClear()
      }
    },

    defaultValue(value) {
      if (value == '') {
        this.userSelectEl({})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

/*
  - readonly will be used where the field is editable but the actual input should not work, it triggers a dropdown or contains text for copying
  - disabled will be used to actually disable the field and will show darker field colour
*/

input.form-control {
  &.no-bottom-radius {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 15px 10px 10px;
    height: auto;
  }

  &.has-error {
    background-color: $danger-input-bg !important;
  }

  &[disabled] {
    cursor: not-allowed !important;
  }
}

input[readonly]:not([disabled]) {
  background-color: #f5f5f5;
  color: #66615b;
  cursor: pointer;
}

input.select {
  -webkit-appearance: menulist-button;
  text-indent: 10px;
}

.box-add-item {
  cursor: pointer !important;

  &:not(.fake-disabled)[disabled] {
    cursor: not-allowed !important;
  }
}

.option-box-parent {
  position: relative;
  z-index: 9;

  .option-box {
    width: 100%;
    background: #fff;
    padding: 10px;
    border-radius: 0 0 5px 5px;

    &.toggle {
      position: absolute;
      top: -2px;
      box-shadow: 4px 7px 20px 2px rgba(0, 0, 0, 0.08);
    }

    &.inline {
      border: 5px solid #f5f5f5;
    }

    .search-field {
      margin-bottom: 10px;
    }

    .add-new {
      font-weight: bold;
      cursor: pointer;
      padding: 10px 0 0;
      text-decoration: underline;
      display: block;
    }

    .option-items {
      max-height: 500px;
      overflow-y: scroll;
      background: #f5f5f5;
      padding: 10px;
      border-radius: 3px;

      .option-item {
        color: #555;
        cursor: pointer;

        &:not(.empty-option-item) {
          &:hover {
            font-weight: bold;
          }
        }

        > div {
          padding: 4px 0;
        }
      }

      .empty-option-item {
        background: #eee;
        border: 1px solid #ddd;
        border-radius: 2px;
        padding: 5px;
        margin-bottom: 5px;

        span {
          font-style: italic;
          letter-spacing: 0.5px;
          font-weight: bold;
        }

        &:after {
          content: '\f057';
          font-family: 'Font Awesome 5 Free';
          float: right;
          font-weight: 900;
        }
      }
    }

    .option-box-add {
      .option-box-add-container {
        position: relative;
        margin-bottom: 10px;

        .option-box-add-input-container {
          margin-right: 70px;
        }
      }

      button {
        width: 60px;
        position: absolute;
        top: 0;
        height: 40px;
        display: block;
        right: 0;
        padding: 0;
        // border-radius:5px;
      }
    }
  }
}

.add-simple-item-container {
  margin-top: 5px;

  button {
    width: 80px;
    display: inline-block;
  }

  input {
    width: calc(100% - 90px);
    display: inline-block;
  }
}
</style>
