<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="tab-section outline white">
          <ul class="nav nav-tabs">
            <li role="presentation" :class="[{ active: tab == 'Main' }]">
              <a href="#" @click="tab = 'Main'"> Main </a>
            </li>
            <li role="presentation" :class="[{ active: tab == 'Billing' }]">
              <a href="#" @click="tab = 'Billing'"> Billing </a>
            </li>
          </ul>
          <div :class="['tab-content', { 'first-active': tab === 'Main' }]">
            <div class="tab-content-inner">
              <div v-if="tab == 'Main'">
                <div class="row">
                  <div class="col-sm-12 col-md-12" style="position: relative">
                    <!-- <div :class="'detail-item'">
                      <h3>#{{ jobData.Job_Number }}</h3>
                    </div> -->
                    <!-- <div class="job-nav">
                      <i v-if="prevJob" class="ti-angle-left" @click="loadData(prevJob)"></i>
                      <i v-if="nextJob" class="ti-angle-right" @click="loadData(nextJob)"></i>
                    </div> -->
                  </div>

                  <div class="col-sm-12 col-md-4">
                    <div :class="'detail-item'">
                      <label>Customer</label>
                      <p>{{ jobData.Name }}</p>
                    </div>
                    <div :class="'detail-item'">
                      <label>Description</label>
                      <p>{{ jobData.Job_Description }}</p>
                    </div>
                    <div :class="'detail-item'">
                      <label>Has Job Extra(s): </label><br />
                      <span v-if="jobExtras.length"> Yes</span>
                      <span v-else> No</span>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-4">
                    <!-- <div :class="'detail-item'">
                      <label>Stage</label>
                      <select-field
                        :options="filteredJobStages"
                        :label-text="'Stage'"
                        :option-display-keys="['description']"
                        :option-val="'code'"
                        v-model="stageCode"
                        :filter-on-key-val="{ key: 'enabled', val: 'yes' }"
                        disabled
                      />
                      <p v-if="isLocked" style="padding: 5px"><i>PM can unlock using button below Job Main tab</i></p>
                    </div> -->

                    <div :class="'detail-item'">
                      <label>Project Stage</label>
                      <input type="text" class="form-control fake-readonly is-link" readonly :value="projectStageName" @click="projectUpdate()" />
                      <!-- <select-field
                        :options="projectStages"
                        :label-text="'Project Stage'"
                        :option-display-keys="['description']"
                        option-val="id"
                        :value="jobData.project_stage"
                        :disabled="!editable"
                        @click="openJobStageUpdate"
                      /> -->
                      <!-- <p v-if="isLocked" style="padding: 5px"><i>PM can unlock using button below WO Main tab</i></p> -->
                    </div>

                    <div :class="'detail-item'">
                      <label>Sign Off Stage</label>
                      <select-field
                        :options="signOffStages"
                        :label-text="'Sign Off Status'"
                        :option-display-keys="['description']"
                        option-val="id"
                        v-model="jobData.sign_off_stage"
                        :read-only="jobData.project_stage !== 'finished'"
                      />
                    </div>

                    <div :class="'detail-item'">
                      <label>Last Cost Date</label>
                      <input type="text" class="form-control fake-readonly" readonly :value="lastCostDate" />
                    </div>

                    <!-- <div :class="'detail-item'">
                      <checkbox2 v-model="jobRecieved" :true-val="true" :false-val="false"> Sign Off Status</checkbox2>
                    </div> -->
                  </div>

                  <div class="col-sm-12 col-md-4">
                    <label>Service Report Send Log</label>
                    <service-report-send-log :raw-log="Array.isArray(jobData.report_send_log) ? jobData.report_send_log : []" />
                    <p style="padding: 5px">
                      <i>While in "Report Sent" stage, sign off request will be automatically re-sent once per week to all recipents the report was last sent to.</i>
                    </p>

                    <div class="detail-item" v-if="jobSignOffStatus !== 'yes' && stageCode != 4">
                      <label>Sign Off Link</label>
                      <input class="form-control fake-readonly" type="text" readonly v-model="jobData.sign_off_link" @click="(copyText(jobData.sign_off_link), $snack.open('Copied'))" />
                    </div>

                    <file-upload
                      v-if="jobData && jobData.Job_Number"
                      :readonly="true"
                      label=""
                      :path="'job-uploads/' + jobData.Job_Number + '/'"
                      :subdir="'sign-off'"
                      :zip-depth="-1"
                      name="Report Sign Off"
                      :can-delete="false"
                    />

                    <div class="detail-item" v-if="jobSignOffStatus !== 'yes' && stageCode == 5 && Array.isArray(jobData.report_send_log) && jobData.report_send_log.length >= 1">
                      <button class="btn" @click="resendSignOffLink">Resend Sign Off Link</button>
                    </div>
                  </div>
                </div>

                <alert :alert="{ show: jobData.po_request_muted_by_customer == 'yes', message: 'Customer has muted PO Email Requests for this Job', state: 'warning', dismiss: 'none' }" />
                <div class="row">
                  <div class="col-sm-4">
                    <div :class="'detail-item'">
                      <checkbox2 v-model="jobData.auto_email_request" :disabled="!isPM" true-val="yes" false-val="no"> Enable Auto PO Request Email</checkbox2>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div :class="'detail-item'">
                      <checkbox2 v-model="admin_options.other.require_detailed_notes" :disabled="!isAdmin || isLocked" :true-val="true" :false-val="false"> Require Detailed Notes</checkbox2>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div :class="'detail-item'">
                      <checkbox2 v-model="admin_options.other.using_foreman_time_entry" :disabled="!isPM || isLocked" :true-val="true" :false-val="false"> Warn Foreman Time Entry</checkbox2>
                    </div>
                  </div>
                </div>

                <h5>
                  Accounts Payable Invoice Approval Routing Codes
                  <span
                    style="padding-left: 3px; font-size: 14px"
                    class="ti-info-alt"
                    v-tooltip="{
                      content: 'If any values are blank, all will be set based on values from the Project Lead',
                      placement: 'bottom',
                      hideOnTargetClick: false,
                      delay: { show: 200, hide: 2500 },
                      offset: 5,
                      classes: ['wide']
                    }"
                  />
                </h5>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Default Routing Code</label>
                      <select-field
                        :options="selectableRoutingCodes"
                        :option-display-keys="['Routing_Desc', 'Routing_Code']"
                        :option-val="'Routing_Code'"
                        v-model="jobData.Routing_Code1"
                        placeholder-text="Assign Routing Code"
                        :label="'Routing Code'"
                        :disabled="!isPM && !isJobCaptain && !isAdmin"
                        :filter-on-key-val="{ key: 'allow_job_select', val: 'yes' }"
                      />
                    </div>
                  </div>
                  <div class="col-sm-4" v-if="isAdmin">
                    <div class="form-group">
                      <label>Dollar Invoice Limit</label>
                      <vue-numeric
                        currency="$"
                        separator=","
                        :class="['form-control']"
                        :precision="2"
                        :read-only-class="'form-control readonly'"
                        v-model="jobData.Routing_Limit"
                        :disabled="!isAdmin"
                      />
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label>Default Routing if Over Limit</label>
                      <select-field
                        :options="routingCodes"
                        :option-display-keys="['Routing_Desc', 'Routing_Code']"
                        :option-val="'Routing_Code'"
                        v-model="jobData.Routing_Code2"
                        placeholder-text="Assign Routing Code"
                        :label="'Routing Code'"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <h5>Accounts Receivable (Billing) Routing Code</h5>
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <label>Routing Code</label>
                      <select-field
                        :options="billingRoutingCodes"
                        :option-display-keys="['routing_code', 'routing_description']"
                        :option-val="'routing_code'"
                        v-model="jobData.billing_routing_code"
                        placeholder-text="Assign Routing Code"
                        :label="'Routing Code'"
                        :disabled="!isPM && !isJobCaptain && !isAdmin"
                      />
                    </div>
                  </div>
                </div>

                <br />
                <div class="buttons">
                  <button class="btn btn-blue" @click="updateJob()">Update</button>
                  <button v-if="jobSignOffStatus !== 'yes' && stageCode != 4" class="btn" v-html="'Download PDF'" @click="generatePDF" />
                  <button v-if="isAdmin" class="btn btn-outline-red" @click="deleteJob()">Delete</button>
                </div>
                <!-- <p v-if="isLocked"><i>Job is locked</i></p> -->

                <hr />
                <h5>Date Change History</h5>
                <date-change-log v-if="jobData.Job_Number" :objNumber="jobData.Job_Number" type="Job" />
                <hr />

                <h5>Status Notes</h5>
                <item-comments
                  v-if="jobData.Job_Number"
                  :table="'JobStatusNotes'"
                  :item-id="jobData.Job_Number"
                  :label-text="''"
                  :post-spectrum-name="'Status Notes'"
                  :post-spectrum-type="'job'"
                  :read-only="true"
                  ref="jobStatusNotes"
                />
              </div>

              <br />
              <button class="btn" @click="projectUpdate()">Add Project Update</button>

              <div v-if="tab == 'Billing'">
                <billing-tasks :job-number="jobData.Job_Number" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>
<script>
import appFuncs from 'appFuncs'
import { mapGetters } from 'vuex'
import SelectField from 'components/UIComponents/SelectField'
import ItemComments from 'components/GeneralViews/ItemCommentsWYSIWYG'
import ServiceReportSendLog from 'components/Dashboard/JobWo/ServiceReportSendLog'
import Checkbox2 from 'components/UIComponents/Checkbox.vue'
import JobFuncs from 'mixins/JobFuncs'
import VueNumeric from 'vue-numeric'
import FileUpload from 'components/UIComponents/FileUpload'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import BillingTasks from 'components/Dashboard/Billing/BillingTasks'
import { showConfirmDialog } from 'src/confirmDialog'
import DateChangeLog from './DateChangeLog.vue'
import Alert from 'components/UIComponents/Alert'

export default {
  data() {
    return {
      jobId: '',
      jobData: {
        project_stage: null,
        sign_off_stage: null,
        billing_routing_code: null
      },
      jobRecieved: false,
      loadedStageCode: null,
      loadedJobSignOffStatus: null,
      stageCode: '',
      jobSignOffStatus: '',
      tab: 'Main',
      loadedVals: '',
      jobExtras: [],
      admin_options: {
        other: {
          require_detailed_notes: false,
          using_foreman_time_entry: false
        }
      },
      routingCodes: [],
      billingRoutingCodes: []
    }
  },

  components: {
    SelectField,
    ItemComments,
    ServiceReportSendLog,
    Checkbox2,
    VueNumeric,
    FileUpload,
    WaitingSpinner,
    BillingTasks,
    DateChangeLog,
    Alert
  },

  props: {
    inputJobId: {
      type: [String, Number],
      required: true
    },
    setTab: {
      type: String,
      default: 'Main'
    },
    jobNav: {
      type: String,
      default: 'All Jobs'
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['jobAssemble', 'employees', 'userAuthedData']),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPM() {
      return this.$store.getters.userAuthedData.user_role.indexOf('pm') !== -1
    },

    filteredJobStages() {
      const list = JSON.parse(JSON.stringify(this.jobStages))
      const currentPriorityCode = this.loadedStageCode
      if (this.isLocked) {
        const lockedAllowed = [3, 5, 12, 13, 4, parseInt(currentPriorityCode)]
        return list.map(itm => {
          if (!lockedAllowed.includes(parseInt(itm.code))) {
            return { ...itm, enabled: 'no' }
          }
          return { ...itm, enabled: 'yes' }
        })
      }
      return list.map(itm => ({ ...itm, enabled: 'yes' }))
    },

    isJobCaptain() {
      const jobCaptain = this.jobData.Superintendent
      const uid = this.userAuthedData.eid
      return jobCaptain == uid
    },

    projectStageName() {
      return this.jobData.project_stage ? this.getProjectStage(this.jobData.project_stage)?.['description'] : ''
    },

    selectableRoutingCodes() {
      /*
      RULES:
      - if user is any PM they can assign routing codes which are assigned to the PM on the job
      - if user is a job captain or admin they can assign routing codes which are assigned to the job captain or PM on the job
      */

      // for each routing code, add a flag to use for filtering
      // flags are added according to routing codes assigned to the employee
      let availableRoutingCodes = []
      const jobPM = this.jobData.Project_Manager
      const pmEmployee = this.employees.find(itm => {
        return itm.Employee_Code == jobPM
      })

      if (pmEmployee && Array.isArray(pmEmployee.assigned_routing_codes)) {
        availableRoutingCodes.push(...pmEmployee.assigned_routing_codes)
      }

      // update NOT currently adding job captain's routing codes
      // if (this.isJobCaptain) {
      //   const jobCaptain = this.jobData.Superintendent
      //   const captainEmployee = this.employees.find(itm => {
      //     return itm.Employee_Code == jobCaptain
      //   })

      //   if (captainEmployee && Array.isArray(captainEmployee.assigned_routing_codes)) {
      //     availableRoutingCodes.push(...captainEmployee.assigned_routing_codes)
      //   }
      // }

      // add flag to routingCodes
      return this.routingCodes.map(itm => {
        if (availableRoutingCodes.indexOf(itm.Routing_Code) !== -1) {
          itm.allow_job_select = 'yes'
        }
        return itm
      })
    },

    employeeRoutingCodes() {
      const uid = this.userAuthedData.eid
      const employee = this.employees.find(itm => {
        return itm.Employee_Code == uid
      })
      return employee ? employee.assigned_routing_codes : []
    },

    employeeFilteredRoutingCodes() {
      if (!Array.isArray(this.routingCodes) || !this.routingCodes.length || !Array.isArray(this.employeeRoutingCodes) || !this.employeeRoutingCodes.length) return []
      return this.routingCodes.filter(itm => {
        return this.employeeRoutingCodes.indexOf(itm.Routing_Code) !== -1
      })
    },

    editable() {
      const isPM = ((this.$store.getters.userAuthedData || {}).user_role || '').indexOf('pm') !== -1
      return isPM && !this.isLocked
    },

    lastCostDate() {
      return this.formatDate(this.jobData.Last_Cost_Date)
    },

    prevJob() {
      let jobs = this.jobAssemble.map(itm => {
        return itm.Job_Number
      })

      if (this.jobNav === 'My Jobs') {
        jobs = this.myJobIds
      }
      if (jobs.indexOf(this.jobId) < jobs.length - 1) {
        return jobs[jobs.indexOf(this.jobId) + 1]
      } else {
        return jobs[0]
      }
    },

    nextJob() {
      let jobs = this.jobAssemble.map(itm => {
        return itm.Job_Number
      })

      if (this.jobNav === 'My Jobs') {
        jobs = this.myJobIds
      }
      if (jobs.indexOf(this.jobId) > 0) {
        return jobs[jobs.indexOf(this.jobId) - 1]
      } else {
        return jobs[jobs.length - 1]
      }
    },

    trackedFields() {
      return {
        jobSignOffStatus: this.jobSignOffStatus,
        stageCode: String(this.stageCode),
        sign_off_stage: this.jobData.sign_off_stage,
        project_stage: this.jobData.project_stage,
        auto_email_request: this.jobData.auto_email_request
      }
    }

    // signOffEditable() {
    //   const signOffStage = this.loadedVals ? JSON.parse(this.loadedVals)?.sign_off_stage : ''
    //   return signOffStage && ['ok-to-bill', 'signed'].includes(signOffStage)
    // },

    // signOffStageOptions() {
    //   return this.signOffEditable ? this.signOffStages.filter(itm => ['ok-to-bill', 'signed'].includes(itm.id)) : []
    // }
  },

  mixins: [JobFuncs],

  methods: {
    projectUpdate() {
      this.$Modal({
        parent: this,
        name: 'SetEstimatedStartDate',
        size: 'lg',
        title: 'Project Update',
        component: () => import('components/Dashboard/JobWo/SetEstimatedStartDate.vue'),
        props: {
          obj: this.jobData,
          type: 'Job'
        }
      })
    },

    loadData(jobId, refresh) {
      return new Promise(async (resolve, reject) => {
        if (this.loadedVals && this.checkDataUpdated()) {
          if (!confirm('Unsaved changes, would you like to proceed?')) {
            resolve()
            return
          }
        }

        this.jobId = jobId

        this.$bus.$emit('setWaiting', {
          name: 'getJobAssemble',
          message: 'Refreshing Jobs'
        })

        const lastFetch = this.$store.state.jobLastFetchRef
        console.log(lastFetch)
        await this.$store.dispatch('getJobAssemble', {
          refresh,
          not_status: lastFetch
        })
        this.$bus.$emit('stopWaiting', 'getJobAssemble')

        if (!this.jobAssemble || !this.jobAssemble.length) {
          this.$snack.open('Problem getting Jobs', 'error')
          resolve()
          return
        }

        let job = this.jobAssemble.find(itm => {
          return itm.Job_Number == jobId
        })

        this.jobData = job && job.Job_Number ? JSON.parse(JSON.stringify(job)) : {}

        this.stageCode = this.jobData.Stage

        this.jobSignOffStatus = this.jobData.sign_off_stage === 'signed' ? 'yes' : 'no'

        if (this.jobSignOffStatus === 'yes') {
          this.jobRecieved = true
        } else if (this.jobSignOffStatus === 'no') {
          this.jobRecieved = false
        }

        this.admin_options = this.jobData.admin_options || { other: {} }

        this.loadedStageCode = this.stageCode
        this.loadedJobSignOffStatus = this.jobSignOffStatus
        this.setLoadedVals()
        this.getJobExtras()
        resolve()
      })
    },

    updateJob(close) {
      let params = {
        action: 'job_status_update',
        data: {
          job_number: this.jobId,
          status: this.stageCode,
          sign_off: this.jobRecieved ? 'yes' : 'no',
          project_stage: this.jobData.project_stage,
          sign_off_stage: this.jobData.sign_off_stage,
          billing_routing_code: this.jobData.billing_routing_code,
          auto_email_request: this.jobData.auto_email_request ? 'yes' : 'no'
        }
      }

      // set status_change_date if marked Needs Sig and had been changed
      if (this.loadedStageCode !== this.stageCode && this.stageCode === '3') {
        params.data.status_change_date = Math.round(new Date().getTime() / 1000)
      }

      // if stage is not Needs Sig, reset date by sending "0" String
      if (this.stageCode !== '3') {
        params.data.status_change_date = '0'
      }

      params.data.admin_options = this.admin_options

      this.$bus.$emit('setWaiting', {
        name: 'job_status_update',
        message: 'Updating Job'
      })

      appFuncs.ajax_request(this.$store.getters.sherAuth, params, async result => {
        this.$bus.$emit('stopWaiting', 'job_status_update')
        if (result.status === 'success') {
          // after updating, job assemble data is refreshed using below

          try {
            await this.updateJobData()

            if (this.stageCode == 3 && this.loadedStageCode != 3 && this.enableBillingTasks) {
              // this.showCreateBillingTask()
            }
          } catch (error) {
            console.log('Error refreshing job data:', error)
          }

          // this causes refreshing the job wo report table and user will loose position
          // await this.loadData(this.jobId, 'refresh')

          if (close) {
            this.$bus.$emit('modalClose', 'JobWIPDetails')
          }
          this.loadedStageCode = this.stageCode
          this.setLoadedVals()
          this.$bus.$emit('updateJobStage', params.data)
        } else {
          this.$snack.open(result.message, 'error')
        }
      })
    },

    async deleteJob() {
      if (!(await showConfirmDialog('This does not delete the Job from Spectrum, it deletes records synced within MySheridan. Use this to delete along side deletion in Spectrum.'))) return

      return new Promise((resolve, reject) => {
        const params = {
          action: 'delete_job',
          object_id: this.jobId,
          type: 'Job'
        }

        this.$bus.$emit('setWaiting', {
          name: params.action,
          message: 'Delete Job'
        })

        appFuncs
          .shRequest(params)
          .then(async data => {
            this.$snack.open('Job has been deleted successfully', 'success')
            this.$router.push(`/app/jobs`)
          })
          .catch(res => {
            this.$snack.open(res.message || 'Problem deleting job data', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', params.action)
          })
      })
    },

    setLoadedVals() {
      this.loadedVals = JSON.stringify(this.trackedFields)
    },

    checkDataUpdated() {
      const currentVals = JSON.stringify(this.trackedFields)
      if (this.loadedVals !== currentVals) {
        return true
      }
    },

    async updateJobData() {
      return new Promise((resolve, reject) => {
        const params = {
          action: 'update_job',
          job_number: this.jobId,
          // only update fields needed, can throw errors if saving none updateable fields
          data: {
            Project_Manager: this.jobData.Project_Manager,
            Routing_Code1: this.jobData.Routing_Code1,
            Routing_Code2: this.jobData.Routing_Code2,
            Routing_Limit: this.jobData.Routing_Limit
          }
        }

        this.$bus.$emit('setWaiting', {
          name: params.action,
          message: 'Updating Job Data'
        })

        appFuncs
          .shRequest(params)
          .then(async data => {
            this.$snack.open('success', 'success')
            // refreshes main job data
            if (typeof this.$parent.getData === 'function') {
              await this.$parent.getData(true)
            }

            this.setLoadedVals()
            resolve()
          })
          .catch(res => {
            console.log(res)
            this.$snack.open(res.message || 'Problem updating job data', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', params.action)
          })
      })
    },

    async getJobExtras() {
      const params = {
        action: 'job_extra_get',
        job_number: this.jobId,
        status: null
      }

      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Getting Job Extras'
      })

      appFuncs
        .shRequest(params)
        .then(data => {
          if (data.entries) {
            this.jobExtras = data.entries
          } else {
            this.$snack.open('Problem getting Job Extras', 'error')
          }
        })
        .catch(res => {
          console.log(res)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    generatePDF() {
      if (this.jobSignOffStatus === 'yes' || this.stageCode == 4) {
        this.$snack.open('Please make sure job is not already marked as signed off.', 'error')
        return
      }

      var params = {
        action: 'job_signoff_save_pdf',
        job_number: this.jobId
      }

      this.$bus.$emit('setWaiting', {
        name: 'job_signoff_save_pdf',
        message: 'Preparing PDF... takes a while'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          if (data && data.location) {
            const content = `<p class="text-center"style="margin:30px 0"><br><br><a target="_blank" href="${data.location}" class="btn">PDF Download</a></p>`
            this.$Modal({
              parent: this,
              name: 'JobDownloadPDF',
              size: 'sm', // sm, md, lg, xl
              hideClose: false,
              content,
              props: null
            })
          }
        })
        .catch(res => {
          this.$snack.open('Problem generating PDF, please try again now or later', 'error')
          console.log(res)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'job_signoff_save_pdf')
        })
    },

    resendSignOffLink() {
      this.$Modal({
        parent: this,
        name: 'send-signoff-link', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/JobWo/SendSignOffLink.vue'),
        contentClasses: '',
        props: {
          authCode: this.jobData.sign_off_auth,
          itemType: 'Job',
          data: this.jobData,
          isResend: true
        }
      })
    },

    getRoutingCodes() {
      return new Promise((resolve, reject) => {
        var params = {
          action: 'get_employee_routing_codes'
        }

        appFuncs.shHttp(params).then(result => {
          if (result.status === 'success') {
            this.routingCodes = result.data
            resolve()
          } else {
            this.$snack.open(result.message || 'Problem fetching routing codes', 'error')
            reject()
          }
        })
      })
    },

    showCreateBillingTask() {
      this.$Modal({
        parent: this,
        name: 'BillingTaskAdd',
        size: 'md',
        title: `Add Billing Task`,
        component: () => import('src/components/Dashboard/Billing/BillingTaskAdd.vue'),
        props: {
          Job_Number: this.jobData.Job_Number,
          setTaskType: 'BC',
          setAssignedTo: this.jobData.billing_employee
        }
      })
    },

    getBillingRoutingCodes() {
      var params = {
        action: 'get_routing_codes'
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Getting Billing Routing Codes' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.billingRoutingCodes = data
        })
        .catch(err => {
          console.log(err)
          snack.open(err.message || 'Problem fetching billing routing codes', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    openJobStageUpdate() {
      this.$Modal({
        parent: this,
        name: 'JobStageUpdate',
        size: 'sm',
        component: () => import('src/components/Dashboard/JobWo/JobStageUpdate.vue'),
        title: 'Project Status',
        props: {
          inputJobId: this.jobData.Job_Number,
          jobData: this.jobData
        }
      })
    },

    listenJobUpdate() {
      this.loadData(this.jobId, 'refresh')
      if (this.$refs.jobStatusNotes) {
        this.$refs.jobStatusNotes.getNotes()
      }
    }
  },

  mounted() {
    const employees = this.$store.dispatch('getEmployees')
    const routingCodes = this.getRoutingCodes()
    this.$bus.$emit('setWaiting', { name: 'mounted', message: 'Getting data' })

    Promise.all([employees, routingCodes]).then(() => {
      this.loadData(this.inputJobId)
      this.$bus.$emit('stopWaiting', 'mounted')
    })
    this.tab = this.setTab

    this.getBillingRoutingCodes()

    this.$bus.$on('updateJobWO', this.listenJobUpdate)
  },

  watch: {
    jobRecieved() {
      this.jobSignOffStatus = this.jobRecieved ? 'yes' : 'no'
    },

    jobRequiresDetailedNotes: {
      handler() {
        // set based on logic from Job Funcs in order to consider whether job price type is T&M and value not set.  T&M Jobs should default to requiring detailed notes.
        if (this.jobRequiresDetailedNotes === 'true' || this.jobRequiresDetailedNotes === true) {
          this.admin_options.other.require_detailed_notes = true
        } else {
          this.admin_options.other.require_detailed_notes = false
        }
      },
      immediate: true
    }

    // 'jobData.project_stage': {
    //   handler(newVal, oldVal) {
    //     if (oldVal === 'stagnant' && newVal && newVal !== 'stagnant' && !this.updatedProjectStage) {
    //       // store here to allow updating when changed from the project update component
    //       this.updatedProjectStage = newVal
    //       // don't allow the stage to be changed, must go through the project update component to add note
    //       this.projectUpdate(true)
    //       this.jobData.project_stage = 'stagnant'
    //     }
    //   }
    // }
  },

  beforeDestroy() {
    this.$bus.$off('jobDataUpdated', this.listenJobUpdate)
  }
}
</script>

<style lang="scss" scoped>
.detail-item {
  margin-bottom: 15px;
}

h3 {
  font-weight: bolder;
  margin: 0;
}

.job-nav {
  right: 0;
  position: absolute;
  top: 0;

  i {
    cursor: pointer;
    padding: 5px;
  }
}
</style>
