<template>
  <!-- prettier-ignore -->
  <div class="pdf-template" id="pdf-template">
    <section v-if="!pdf && authedUser && data.showSendOptions" class="not-pdf section quote-action-section">
      <p style="font-size:18px">
        <strong>Quote Status:</strong> {{ data.quote_status }}
      </p>
      <!-- <p v-if="data.use_this_revision !== 'yes'">
        <i>Send options not available for inactive quote version</i>
      </p> -->
      <!-- <p v-if="hideBasePrice" class="text-danger">Note that Hide Base Price option is selected.</p> -->
      <br>
      <div>
        <button
          class="btn not-pdf"
          @click="generatePDF(true, null)"
          v-html="'Print Quote'"
        />
        <button
          class="btn not-pdf"
          @click="generatePDF(false, null)"
          v-html="'Print Quote Without Price'"
        />
        <button
          class="btn not-pdf"
          @click="emailQuote(true)"
          v-html="'Email Quote With Price'"
        />
        <button
          class="btn not-pdf"
          @click="emailQuote(false)"
          v-html="'Email Quote Without Price'"
        />
        <!-- <button
          v-if="quoteSent"
          class="btn not-pdf"
          @click="update('Quote in Progress')"
          v-html="'Re-Open Quote'"
        /> -->
        <button
          v-if="!quoteSent && data.use_this_revision === 'yes' && 1 === 2"
          class="btn not-pdf"
          @click="update('Sent and Pending')"
          v-html="'Mark Quote Sent'"
        />
        <button
          v-if="existingPDF"
          class="btn not-pdf"
          @click="downloadPDF()"
          v-html="'Download PDF'"
        />
      </div>
    </section>
    <div class="" v-if="data.quote_number">
      <div class="header" id="pdfReady">
        <div class=" header__row">
          <div class="header__image-container">
            <div>
              <img :src="`${urls.frontend}static/img/${logoFile}`">
            </div>
          </div>
          <div class="header__address-container">
            <p v-html="regionAddress"/>
          </div>
        </div>
        <div class="header-bottom"/>
      </div>

      <section class="section">
        Attention: {{ data.requested_by }} <br>
        Quote Number: {{ data.quote_number }} <br>
        Revision: {{ data.quote_revision }} <br>
        Sent Date: {{ data.sent_date ? formatDate(data.sent_date) : formatDate(new Date()) }} <br>
        <span v-if="data.quoted_by_name && data.quoted_by_name !== 'Unassigned'">Quoted By: {{ data.quoted_by_name }} <br></span>
        <span v-if="data.captain_name && data.captain_name !== 'Unassigned' && data.captain_name !== data.quoted_by_name">Reviewed By: {{ data.captain_name }}  <br> </span>
        # of Pages: {{ pageCount }}
      </section>

      <section class="section">
        <div class="row">
          <div class="col-xs-6">
            <label>Billing Address</label> <br>
            {{ data.customer_name }} <br>
            {{ data.billing_street }} <br>
            {{ data.billing_city }}, {{ data.billing_province }}, {{ data.billing_postal }} <br>
            {{ data.billing_country }}
          </div>
          <div class="col-xs-6">
            <label>Site Address</label><br>
            {{ data.site_name }} <br>
            {{ data.site_street }} <br>
            {{ data.site_city }}, {{ data.site_province }}, {{ data.site_postal }} <br>
            {{ data.site_country }}
          </div>
        </div>
      </section>

      <section class="section">
        <h4><strong>{{ data.quote_name }}</strong></h4>
      </section>

      <section class="section main-section">
        <h5 class="underline">Scope of Work</h5>
        <!-- <div class="nips" style="width: 100%;height:10px"></div> -->
        <div class="note-table">
          <div>
            <div
              v-for="(s, si) in data.systems"
              :key="si"
              class="table-number-content-parent"
            >
              <div><strong>{{ s.number }}</strong></div>
              <div>{{ s.name }} <span v-if="s.as_option === 'yes'" class="optional"> - <strong>[OPTIONAL PRICING PROVIDED]</strong></span></div>
            </div>
          </div>
        </div>
      </section>

      <section class="section main-section" v-if="specNotes && specNotes.length">
        <section class="section">
          <h5 class="underline">Details and Specifications:</h5>
        </section>
        <div
          class=""
          v-for="(n, ni) in specNotes"
          :key="ni"
        >
          <section class="section">
            <div class="note-table system-notes" style="margin-bottom: 20px;">
              <div class="table-number-content-parent">
                <div><strong>{{ n.sn }}</strong></div>
                <div>{{ n.name }} <span v-if="n.as_option === 'yes'" class="optional"> - <strong>[OPTIONAL PRICING PROVIDED]</strong></span></div>
              </div>
              <div>
                <div>
                  <section
                    v-for="(nn, nni) in n.notes"
                    :key="nni"
                    class="table-number-content-parent section"
                    style="padding-left:10px"
                  >
                    <div><strong>{{ nn.sl }}.</strong></div>
                    <div v-html="getSafeHtml(nn.t)"></div>
                  </section>
                </div>
              </div>
            </div>
            <div class="note-added-table">
              <div v-html="n.table"/>
            </div>
          </section>

        </div>

      </section>

      <section
        class="section main-section"
        v-for="(n, ni) in notes"
        :key="ni"
      >
        <h5 class="underline">{{ n.name }}: </h5>
        <div class="note-table">
          <div>
            <div
              v-for="(nn, nni) in n.notes"
              :key="nni"
              :class="{ newline: n.notes[nni-1] && (parseFloat(n.notes[nni].sn) > parseFloat(n.notes[nni-1].sn)) }"
              class="table-number-content-parent"
            >
              <div><strong>{{ nn.sl }}.</strong></div>
              <div v-html="getSafeHtml(nn.t)"></div>
            </div>
          </div>
        </div>

        <div class="note-added-table" v-if="n.table">
          <div v-html="n.table"/>
        </div>

      </section>

      <!--
        Price Section: It appears only if showPrice is true.
        Price Header: This is shown if either hideBasePrice is false or data.price_table is present.
        Price Row: It's displayed only if hideBasePrice is false.
      -->
      <section class="section main-section" v-if="showPrice">

        <table class="note-table fee-table" style="margin: 0 0 10px">
          <thead v-if="!hideBasePrice || data.price_table">
          <th>Our Fee to Complete the Following:</th>
          <th v-if="!hideBasePrice">Quoted Amount</th>
          </thead>
          <tbody>
          <tr v-if="!hideBasePrice">
            <td>{{ data.quote_number }} - {{ data.quote_name }}</td>
            <td style="text-align:right">{{ quoteSellingBasePrice }}</td>
            <tr/>
          </tbody>
        </table>

        <div class="note-added-table" v-if="data.price_table">
          <div v-html="data.price_table"/>
        </div>

      </section>

      <section class="section optional-systems-section main-section" v-if="optionalSystems.length && priceType === 'quote'">
        <h5 class="optional">OPTIONAL ITEMS</h5>
        <div class="note-table systems-table optional-table">
          <div class="table-number-content-parent triple-col">
            <div>Item</div>
            <div>Scope</div>
            <div>Option Price</div>
          </div>
          <div>
            <div
              v-for="(s, si) in optionalSystems"
              :key="si"
              class="table-number-content-parent triple-col"
            >
              <div>{{ s.number }}</div>
              <div>{{ s.name }}</div>
              <div style="text-align:right">
                <span v-if="showPrice && s.selling_price">+{{ formatNumber(s.selling_price, 1) }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="optional-price-terms">
          <div class="note-table">
            <div>
              <div class="table-number-content-parent">
                <div><strong>a.</strong></div>
                <div>Optional prices have <span style="text-decoration: underline">not</span> been included in the Base Bid Quoted Amount of {{ formatNumber(data.selling_price,
                  true, true) }}. These should be considered additions to the base bid.
                </div>
              </div>
              <div class="table-number-content-parent">
                <div><strong>b.</strong></div>
                <div>Main scope must be awarded for adder prices to be valid; exceptions may apply.</div>
              </div>
              <div class="table-number-content-parent">
                <div><strong>c.</strong></div>
                <div>All quote conditions apply to any optional prices unless stated otherwise.</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section main-section">
        <h5 class="underline">Terms:</h5>
        <div class="note-table">
          <div>
            <div class="table-number-content-parent">
              <div><strong>a.</strong></div>
              <div>{{ data.deposit_percent || `${data.deposit_percent}% deposit upon PO issuance` }}</div>
            </div>
            <div class="table-number-content-parent">
              <div><strong>c.</strong></div>
              <div>{{ data.terms_option || 'Remaining Net 30 with progress billings each month' }}</div>
            </div>
            <div class="table-number-content-parent">
              <div><strong>b.</strong></div>
              <div>{{ data.payment_price_type || 'Quote Fixed Price' }}</div>
            </div>
            <div v-if="data.progress_payment_text" class="table-number-content-parent">
              <div><strong>d.</strong></div>
              <div>{{ data.progress_payment_text }}</div>
            </div>
          </div>
        </div>
      </section>

      <section class="section main-section" style="margin-top:20px;">
        Sincerely, <br>
        {{ data.quoted_by_name && data.quoted_by_name !== 'Unassigned' ? data.quoted_by_name : data.captain_name }} <br>
      </section>

      <br>

      <section class="section main-section" v-if="data.enhanced_disclaimer_text">
        <h5 class="underline">Disclaimer</h5>
        <div v-html="data.enhanced_disclaimer_text"></div>
        <br>
      </section>

      <!-- <section class="section main-section" v-if="data.progress_payment_text">
        <h5 class="underline">Progress Payment Clause</h5>
        <div v-html="data.progress_payment_text"></div>
        <br>
      </section> -->

      <section class="section main-section">
        <h5 class="underline">Conditions:</h5>
        <div v-html="data.quote_conditions"></div>
      </section>

      <br>

      <section class="section main-section attachments-section" v-if="data.attachments && data.attachments.length">
        <h5 class="underline">Attachments</h5>
        <div v-for="(image, index) in data.attachments" :key="index">
          <!-- Start a new row div every 3 items -->
          <div v-if="index % 3 === 0" class="row">
            <attachment-component
              v-if="data.attachments[index]"
              :image="data.attachments[index]"
              @image-loaded="handleImageLoaded"
            />
            <attachment-component
              v-if="data.attachments[index + 1]"
              :image="data.attachments[index + 1]"
              @image-loaded="handleImageLoaded"
            />
            <attachment-component
              v-if="data.attachments[index + 2]"
              :image="data.attachments[index + 2]"
              @image-loaded="handleImageLoaded"
            />
          </div>
        </div>
      </section>

      <br>

      <div v-if="pdf" class="footer" style="display: none;">
        <div class="footer-inner">
          <div class="footer-left">
            {{ data.template_date }}
          </div>
          <div class="footer-center">
            {{ companyName }} Ltd.
          </div>
          <div class="footer-right">
            <div class="page-number">
              <p>Page 1 of 1</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner/>
  </div>
</template>
<script>
const AttachmentComponent = {
  template: `
      <div class="attachment-container">
        <span class="attachment-name">{{ image.name }}</span>
        <img :src="image.url" @load="onImageLoad" :alt="image.name" />
      </div>
    `,
  props: {
    image: {
      type: Object,
      required: true
    }
  },
  methods: {
    onImageLoad() {
      this.$emit('image-loaded', this.image)
    }
  }
}

import store from 'store'
import appFuncs from 'appFuncs'
import commonFuncs from 'mixins/commonFuncs'
import { mapGetters } from 'vuex'
import Checkbox2 from 'components/UIComponents/Checkbox'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'CustomerQuoteTemplate',
  data() {
    return {
      data: {},
      pageCount: '',
      existingPDF: ''
    }
  },

  components: {
    Checkbox2,
    AttachmentComponent,
    WaitingSpinner
  },

  props: {
    authCode: {
      type: String,
      required: true
    },
    pdf: {
      type: [String, Boolean],
      default: false
    },
    showPrice: {
      type: [String, Boolean],
      default: false
    }
  },

  mixins: [commonFuncs],

  computed: {
    ...mapGetters(['userAuthedData', 'sherAuth', 'urls', 'employees']),

    authedUser() {
      return (this.userAuthedData || {}).eid || null
    },

    isAdmin() {
      let auth = this.$store.getters.userAuthedData.user_role
      return auth ? auth.indexOf('admin') !== -1 : false
    },

    notes() {
      let notes = Array.isArray(this.data.write_up_notes) ? this.data.write_up_notes : []
      notes.sort((a, b) => {
        return a.sort - b.sort
      })
      return notes
    },

    specNotes() {
      let notes = Array.isArray(this.data.spec_notes) ? this.data.spec_notes : []
      notes.sort((a, b) => {
        return a.sn - b.sn
      })
      return notes
    },

    quoteSent() {
      return ['Quote in Progress', 'Re-Opened - Not Sent'].indexOf(this.data.quote_status) === -1
    },

    optionalSystems() {
      const systems = Array.isArray(this.data.systems) ? this.data.systems : []
      return systems.filter(s => s.as_option === 'yes')
    },

    quoteSellingBasePrice() {
      // always use main selling_price truth unless optional systems are present then use price which does not include optional systems
      let price = this.data.selling_price

      // show price without options added
      if (this.priceType === 'quote' && this.optionalSystems.length) {
        price = this.data.base_selling_price
      }

      return this.formatNumber(price, true, true)
    },

    priceType() {
      return this.data.price_type || 'manual'
    },

    hideBasePrice() {
      return this.data.hide_base_price === 'yes'
    },

    companyName() {
      const vars = this.getRegionVars(this.data.region)
      return vars.companyName
    },

    logoFile() {
      const vars = this.getRegionVars(this.data.region)
      return vars.logoFile
    },

    regionAddress() {
      const vars = this.getRegionVars(this.data.region)
      return vars.address
    }
  },

  methods: {
    getData() {
      if (!this.authCode) {
        this.$snack.open('Cannot fetch quote', 'error')
        return
      }
      const params = {
        action: 'get_quote_customer_facing_data',
        auth_code: this.authCode
      }
      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Fetching data'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.data = data
        })
        .catch(err => {
          console.log(err)
          this.$snack.open(err.message || 'Problem fetching quote', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    emailQuote(showPrice) {
      if (!this.data.requested_by_email) {
        this.$snack.open('Requested by email does not exist or is invalid', 'warning')
        return
      }

      if (!this.data.sales_lead_id || this.data.sales_lead_id === 'unassigned') {
        if (!confirm('There is no sales person assigned to this quote. Do you want to proceed?')) {
          return
        }
      }

      this.$Modal({
        parent: this,
        name: 'emailQuoteDialog', // used for closing specific modal programmatically
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Materials/Quotes/CustomerQuoteTemplateEmailOptions.vue'),
        contentClasses: 'smoke-bg',
        props: {
          showPrice,
          requestedByEmail: this.data.requested_by_email,
          quoteData: this.data,
          attachments: this.data.quote_attachments,
          logoFile: this.logoFile,
          cb: (showPrice, emails, emailNote, signature, emailCc, emailBcc) => {
            this.generatePDF(showPrice, emails, emailNote, signature, emailCc, emailBcc)
          }
        }
      })
    },

    generatePDF(showPrice, emails, emailNote, signature, emailCc, emailBcc) {
      const params = {
        action: 'quote_template_generate_pdf_email',
        quote_id: this.data.id,
        emailTo: emails,
        emailCc,
        emailBcc,
        showPrice: showPrice ? 1 : null,
        emailNote,
        signature,
        emailFrom: this.data.captain_email
      }

      this.$bus.$emit('setWaiting', {
        name: params.action,
        message: 'Generating PDF'
      })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.existingPDF = data.location || null
          this.downloadPDF()

          if (emails) {
            this.showMarkSendDialog()
          }
        })
        .catch(err => {
          console.log(err)
          this.$snack.open(err.message || 'Problem generating pdf', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    downloadPDF() {
      window.open(this.existingPDF)
    },

    getPlainText(text) {
      return appFuncs.getPlainText(text)
    },

    getSafeHtml(text) {
      return appFuncs.getSafeHtml(text)
    },

    showMarkSendDialog() {
      // return; // disabled due to problem with quote edit page not reloading data and autosave
      if (this.quoteSent) {
        return
      }

      this.$Modal({
        name: 'showMarkSendDialog', // used for closing specific modal programmatically
        size: 'sm', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/Quotes/DialogMarkQuoteSent.vue'),
        contentClasses: 'smoke-bg',
        props: {
          parent: this
        }
      })
    },

    update(status) {
      var params = {
        action: 'update_quote',
        rowId: this.data.id,
        vals: {
          status
        }
      }

      this.$bus.$emit('setWaiting', {
        name: 'update_quote',
        message: 'Updating quote'
      })
      appFuncs
        .shHttp(params)
        .then(res => {
          if (res.status === 'success') {
            this.$snack.open('Quote status updated', 'success')
            this.getData()
          } else {
            this.$snack.open(res.message || 'Problem updating quote status.', 'error')
          }
        })
        .catch(e => {
          this.$snack.open('Problem updating quote status.', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'update_quote')
        })
    },

    handleImageLoaded(img) {}
  },

  mounted() {
    this.getData()

    if (this.authedUser) {
      this.$store.dispatch('getEmployees')
    }
  }
}
</script>

<style lang="scss">
.section {
  ul,
  ol {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    list-style-position: inside;

    li {
      margin-bottom: 5px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

body .pdf-template {
  min-width: 1020px;
  font-size: 14px;
  width: 1020px;
  margin: 0 auto;
  color: $font-color;
}

.quote-action-section {
  padding: 20px;
  border: 3px dashed;
  margin: 20px 0;
  border-radius: 5px;
}

.optional {
  color: $pastel-blue;
  display: inline-block;
}

.header {
  color: #66615b;
  line-height: 1.4em;
  font-family:
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  width: 100%;
  padding-bottom: 20px;

  .header-bottom {
    height: 1px;
    border-top: 3px solid #000;
  }

  img {
    width: auto;
    height: 90px;
  }
}

.header__row {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px 0 10px 0;
}

.header__address-container {
  flex: 1;
  padding-left: 20px;
}

.header__address-container p {
  margin: 0 !important;
}

.header__underline {
  height: 1px;
  border-top: 2px solid #000;
}

.section {
  width: 100%;

  &.main-section {
    margin-bottom: 20px;
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    font-style: italic;
  }

  h5 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0px;
    font-style: italic;
    margin-top: 10px;

    &.underline {
      border-bottom: 1px solid;
      padding: 3px 0;
      margin-bottom: 7px;
    }
  }

  &.has-border {
    border: 1px solid $other-medium-gray;
    padding: 15px;

    h5 {
      margin: 0 0 10px;
      text-decoration: underline;
    }
  }
}

table.note-table {
  width: 100%;
  margin-bottom: 5px;
  table-layout: fixed;

  &:not(.fee-table) td {
    &:first-child {
      //  text-transform: uppercase;
    }
  }

  td,
  th {
    //  border:1px solid $other-medium-gray;
    padding: 3px;
    word-break: break-word;
    min-width: 90px;
    font-weight: normal;
    vertical-align: top;
    white-space: pre-line;

    &:first-child {
      width: 35px;
    }

    &:nth-child(3) {
      width: 110px;
    }
  }

  td {
    // white-space: pre-line;
  }

  &.system-notes {
    td:first-child {
      width: 50px;
      padding-left: 10px;
      display: inline-block;
      white-space: nowrap;
    }
  }

  &.fee-table {
    table-layout: fixed;
    margin: 5px 0 40px;

    td,
    th {
      &:first-child {
        width: auto;
      }

      &:nth-child(2) {
        width: 150px;
      }
    }

    th {
      border: 0;
      font-weight: bold;
    }

    tr {
      //  border: 1px solid;
      background: #eee;

      td {
        padding: 5px;
        border: 1px solid;
      }
    }
  }

  &.systems-table {
    table-layout: fixed;
    margin: 5px 0 40px;

    td,
    th {
      &:first-child {
        width: 150px;
      }

      &:nth-child(3) {
        width: 150px;
      }
    }

    th {
      border: 0;
      font-weight: bold;
    }

    tr {
      //  border: 1px solid;
      background: #eee;

      td {
        padding: 5px;
        border: 1px solid;
      }
    }
  }

  &.optional-table {
    margin-bottom: 10px;
  }
}

.footer {
  .footer-inner {
    max-width: 1020px;
    margin: 0 auto;
    padding: 50px 0px 0 !important;
  }

  .footer-left {
    width: 33%;
    display: inline-block;
    text-align: left;
  }

  .footer-center {
    width: 33%;
    display: inline-block;
    text-align: center;
  }

  .footer-right {
    width: 33%;
    display: inline-block;
    text-align: right;
  }
}

.measure-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background: red;
}

.added-content {
  width: 100%;
  position: absolute;

  .header {
    max-width: 1020px;
    margin: 50px auto 0px;
    padding: 50px 20px 0;
  }

  .footer-inner {
    padding: 50px 20px 20px !important;
  }
}

.optional-systems-section {
  border: 1px solid $other-medium-gray;
  padding: 5px 15px;
  border-radius: 5px;
}

.table-number-content-parent {
  vertical-align: top;
  display: flex;
  gap: 20px;

  > div {
    padding: 3px;
    vertical-align: top;
    word-wrap: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
    white-space: pre-line;
  }

  > :nth-child(1) {
    flex: 0;
  }

  > :nth-child(2) {
    flex: 1;
    word-wrap: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
  }

  &.triple-col {
    > :nth-child(2) {
      width: calc(100% - 370px);
    }

    > :nth-child(3) {
      width: 300px;
      display: inline-block;
      text-align: right;
    }
  }
}
</style>
<style lang="scss">
.note-added-table {
  font-size: 14px;
  max-width: 100%;
  margin-left: 0px !important;

  table {
    margin: 5px 0;
    min-width: 500px;
    border-collapse: collapse;
    max-width: 100%;
    table-layout: fixed;

    tbody,
    tr,
    td {
      word-wrap: break-word;
      overflow-wrap: break-word;
      box-sizing: border-box;
    }

    tr {
      td {
        padding: 5px;
        border: 1px solid #ccc;
        text-align: left;
      }
    }

    tr:nth-child(even) {
      background-color: #f1f1f1;
    }
  }
}

.attachments-section {
  .attachment-container {
    width: 350px;
    object-fit: contain;
    padding: 5px;
    display: inline-block;
  }

  img {
    display: block; /* Removes default inline-block spacing */
    max-width: 100%; /* Makes sure the image takes full width of the container */
    max-height: 300px; /* Set the maximum height as desired */
    object-fit: cover; /* Adjusts the size of the image within the container while preserving aspect ratio */
    width: 100%;
    margin-top: 4px;
  }
}

.note-table {
  p {
    margin: 0;
  }

  ol {
    padding-left: 20px;
  }

  @for $i from 1 through 8 {
    .ql-indent-#{$i} {
      padding-left: calc($i * 3em + 20px);
    }
    li[data-list='bullet'] {
      margin-left: 20px;
      padding-left: 0 !important;
      list-style-type: disc;
      &.ql-indent-#{$i} {
        margin-left: calc($i * 3em + 20px);
        @if $i == 1 or $i == 4 or $i == 7 {
          list-style-type: circle;
        } @else if $i == 2 or $i == 5 or $i == 8 {
          list-style-type: square;
        }
      }

      .ql-ui:before {
        content: none !important;
      }
    }
    li[data-list='ordered'] {
      margin-left: 5px;
      padding-left: 0 !important;
      list-style-type: none;
      counter-increment: list-0;
      counter-set: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      &:before {
        content: counter(list-0, decimal) '. ';
      }
      &.ql-indent-#{$i} {
        margin-left: calc($i * 3em + 5px);
        counter-increment: list-#{$i};

        counter-set: list-#{$i + 1} list-#{$i + 2} list-#{$i + 3} list-#{$i + 4} list-#{$i + 5} list-#{$i + 6} list-#{$i + 7} list-#{$i + 8} list-#{$i + 9};

        &:before {
          content: counter(list-#{$i}, #{if($i % 3 == 1, 'lower-alpha', if($i % 3 == 2, 'lower-roman', 'decimal'))}) '. ';
        }
      }
      .ql-ui:before {
        content: none !important;
      }
    }
  }
}

.ql-table-better {
  table-layout: fixed;
  td,
  th {
    border: 1px solid #ccc !important;
    padding: 5px;
    text-align: left;
  }
}
</style>
