<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <button class="btn" @click="showOptions">Report Options</button>
      </div>
    </div>
    <br />
    <div class="card" style="z-index: 50; position: relative">
      <div class="content">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="select-item">
              <label>By Work Order</label>
              <select-field :options="woAssemble" :option-display-keys="['WO_Number', 'Summary_Description']" :option-val="['WO_Number']" v-model="selectedWO" />
              <button :disabled="!selectedWO" class="btn" @click="$router.push({ name: 'PO Report Summary', params: { type: 'wo', itmCode: selectedWO } })" v-html="'View Report'" />
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="select-item">
              <label>By Job</label>
              <select-field :options="jobs" :option-display-keys="['Job_Number', 'Job_Description']" :option-val="['Job_Number']" v-model="selectedJob" />
              <button :disabled="!selectedJob" class="btn" @click="$router.push({ name: 'PO Report Summary', params: { type: 'job', itmCode: selectedJob } })" v-html="'View Report'" />
            </div>
          </div>
        </div>
        <!--  <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div class="select-item">
                    <label>By Vendor</label>
                    <select-field
                      :options="vendorList"
                      :option-display-keys="['Vendor_Name']"
                      :option-val="['Vendor_Code']"
                      v-model="selectedVendor"
                    />
                    <button
                      :disabled="!selectedVendor"
                      class="btn"
                      @click="$router.push({name: 'PO Report Summary', params: {type: 'vendor', itmCode: selectedVendor }})"
                      v-html="'View Report'"
                    />
                  </div>
                </div>
              </div> -->
      </div>
    </div>

    <div class="card">
      <div class="content">
        <div class="row">
          <div class="card">
            <div class="col-sm-12">
              <label>By Vendor</label>
              <bh-table :table-settings="tableSettings" @edit-item-obj="openVendor" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <waiting-spinner :wait="wait" />
  </div>
</template>
<script>
import store from 'store'
import { mapGetters } from 'vuex'
import DatePicker from 'components/UIComponents/DatePicker'
import SelectField from 'components/UIComponents/SelectField'
import appFuncs from 'appFuncs'
import BhTable from 'components/UIComponents/BhTable'
import POReportOptions from 'components/Dashboard/PurchaseOrders/POReportOptions.vue'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import moment from 'moment'

export default {
  name: 'POReports',
  data() {
    return {
      wait: {
        message: ''
      },
      vendorList: [],
      selectedVendor: '',
      selectedWO: '',
      jobs: [],
      selectedJob: '',
      tableSettings: {
        tableData: null,
        fields: {
          Name: {
            name: 'Vendor Name'
          },
          Address_1: {
            name: 'Address 1'
          },
          Address_2: {
            name: 'Address 2'
          },
          Address_3: {
            name: 'City'
          },
          State: {
            name: 'Province'
          },
          last_sent: {
            name: 'Last Sent'
          }
        },
        noItemEdit: false,
        hideSearch: false
      }
    }
  },

  components: {
    SelectField,
    BhTable,
    WaitingSpinner
  },

  computed: {
    ...mapGetters(['vendors', 'woAssemble', 'vendorContacts', 'vendorPOData']),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    }
  },

  methods: {
    fetchData() {
      ;(async () => {
        this.$bus.$emit('setWaiting', { name: 'fetchPOSummaryItems', message: 'Getting data' })

        // fetch all items at same time, do stuff after all fetched
        await Promise.all([
          (async () =>
            this.$store.dispatch('getVendors').catch(res => {
              this.$snack.open('Problem getting vendor info', 'error')
            }))(),
          (async () =>
            this.$store.dispatch('getVendorContacts').catch(res => {
              this.$snack.open('Problem getting vendor contacts', 'error')
              console.log(res)
            }))(),
          (async () =>
            this.$store.dispatch('getVendorPOData').catch(res => {
              this.$snack.open('Problem getting vendor PO data', 'error')
              console.log(res)
            }))()
        ])

        this.$bus.$emit('stopWaiting', 'fetchPOSummaryItems')

        let tableData = []
        for (var ci = 0; ci < this.vendors.length; ci++) {
          let vendorData = {
            Vendor_Code: this.vendors[ci].Vendor_Code,
            Name: this.vendors[ci].Vendor_Name,
            id: this.vendors[ci].id,
            Address_1: this.vendors[ci].Address_1,
            Address_2: this.vendors[ci].Address_2,
            Address_3: this.vendors[ci].Address_3,
            State: this.vendors[ci].State
          }

          // attach customer setting data
          for (var csi = 0; csi < this.vendorPOData.length; csi++) {
            if (this.vendors[ci].Vendor_Code == this.vendorPOData[csi].vendor_code) {
              vendorData.last_sent = this.getLastSent(this.vendorPOData[csi])
              vendorData.last_send_success = this.vendorPOData[csi].last_send_success
              vendorData.class = this.vendorPOData[csi].send_status == 'fail' ? 'red' : ''
              vendorData.disable_auto_send = this.vendorPOData[csi].disable_auto_send == 1 ? 1 : 0
            }
          }

          let hasVendorARContacts = false
          for (var vci = 0; vci < this.vendorContacts.length; vci++) {
            if (this.vendors[ci].Vendor_Code == this.vendorContacts[vci].Vendor_Code) {
              if (this.vendorContacts[vci].AP == 1) {
                hasVendorARContacts = true
                break
              }
            }
          }

          if (!hasVendorARContacts) {
            vendorData.class = 'orange'
          }
          tableData.push(vendorData)
        }
        this.tableSettings.tableData = tableData
      })()
    },

    openVendor(vendor) {
      this.$router.push({ name: 'PO Report Summary', params: { type: 'vendor', itmCode: vendor.Vendor_Code } })
    },

    getLastSent(itm) {
      let time = itm.last_send_success
      if (time) {
        let formatted = moment.unix(time).format('YYYY-MM-DD')
        return formatted
      }
    },

    showOptions() {
      this.$Modal({
        parent: this,
        name: 'POReportOptions', // used for closing specific modal programmatically
        title: 'Purchase Order Report Options',
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: POReportOptions
      })
    }
  },

  mounted() {
    this.fetchData()

    this.$bus.$emit('setWaiting', { name: 'getWoAssemble', message: 'Getting Work Orders' })

    // refresh data to make sure most current wos are what is being used not WOs selected using some other params eg completed WOs
    this.$store.dispatch('getWoAssemble', { refresh: true }).finally(() => {
      this.$bus.$emit('stopWaiting', 'getWoAssemble')
    })

    appFuncs.getJobInfo(res => {
      if (res.status === 'success') {
        this.jobs = Array.isArray(res.data) ? res.data : []
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.select-item {
  margin-bottom: 20px;

  > div {
    margin-bottom: 20px;
  }
}
</style>
