
<footer class="footer">
  <div class="container-fluid">
    <nav class="pull-left">
      <ul>
        <li>
          <router-link :to="{path:'/admin'}">Dashboard</router-link>
        </li>
      </ul>
    </nav>
    <div class="copyright pull-right">
    </div>
  </div>
</footer>
