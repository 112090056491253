<template>
  <div class="">
    <div class="row">
      <div class="col-sm-12">
        <div class="sh-widget card wide-large" style="margin-bottom: 20px !important" v-if="isPMp">
          <div class="content">
            <h4>My WOs Summary</h4>
            <div class="table-container show-scrollbar">
              <table>
                <thead>
                  <tr>
                    <th><label>Under Billings</label></th>
                    <!-- <th><label>Net Cash Flow</label></th> -->
                    <!-- <th><label>Total Contract Value</label></th>
          <th><label>Billings Remaining</label></th> -->
                  </tr>
                </thead>
                <tr>
                  <td>{{ formatNumber(wosTotal.Under_Over_Billing, 1) }}</td>
                  <!-- <td :class="wosTotal.Net_Cash_Flow > 0 ? 'green' : 'red'">{{ formatNumber(wosTotal.Net_Cash_Flow, 1) }}</td> -->
                  <!-- <td>{{ formatNumber(jobsTotal.Total_Contract_Amount, 1) }}</td>
                <td>{{ formatNumber(jobsTotal.Billings_Remaining, 1) }}</td> -->
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isEditorO || isPM">
      <button :class="'btn'" @click="addWO">Add New</button>
      <br /><br />
    </div>

    <div class="tab-section white">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{ active: tab == 'My WOs' }]">
          <a href="#" @click="tab = 'My WOs'"> My WOs </a>
        </li>
        <li v-if="isPM" role="presentation" :class="[{ active: tab == 'All WOs' }]">
          <a href="#" @click="tab = 'All WOs'"> All WOs </a>
        </li>
        <li v-if="isPM" role="presentation" :class="[{ active: tab == 'By Customer' }]">
          <a href="#" @click="tab = 'By Customer'"> By Customer </a>
        </li>
      </ul>

      <div :class="['card', { Main: tab == 'My WOs' }]">
        <div class="content">
          <div v-if="tab == 'My WOs'">
            <span class="inline-checkbox">
              <checkbox v-model="woMissingPoShow"> Show Missing PO's</checkbox>
            </span>
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-3">
                <region-filter @select-region="selectRegion" />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Stage</label>
                <select-field
                  :options="[...projectStages, ...signOffStages]"
                  :option-display-keys="['description']"
                  :option-val="'id'"
                  v-model="stageCode"
                  :allow-clear="true"
                  @selectItem="selectStage"
                />
              </div>
            </div>
            <bh-table :table-settings="tableSettings1" @colClick="colClick" />
          </div>

          <div v-if="tab === 'All WOs'">
            <span class="inline-checkbox">
              <checkbox v-model="woCompleteShow"> Show Complete</checkbox>
            </span>
            <span class="inline-checkbox">
              <checkbox v-model="woMissingPoShow"> Show Missing PO's</checkbox>
            </span>
            <span v-if="woCompleteShow" class="form-group wo-range-select">
              <select class="form-control" v-model="woSearchRange">
                <option v-for="(range, ri) in woRanges" :key="ri" v-html="range.display" :value="range.min" />
              </select>
            </span>
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-3">
                <region-filter @select-region="selectRegion" />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Stage</label>
                <select-field
                  :options="[...projectStages, ...signOffStages]"
                  :option-display-keys="['description']"
                  :option-val="'id'"
                  v-model="stageCode"
                  :allow-clear="true"
                  @selectItem="selectStage"
                />
              </div>
            </div>
            <bh-table :table-settings="tableSettings2" @colClick="colClick" />
          </div>
          <div v-if="tab === 'By Customer'">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <customer-select-field v-model="selectByCC" :allow-add-new="false" search-text="Select Customer" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <region-filter @select-region="selectRegion" />
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <label>Stage</label>
                <select-field
                  :options="[...projectStages, ...signOffStages]"
                  :option-display-keys="['description']"
                  :option-val="'id'"
                  v-model="stageCode"
                  :allow-clear="true"
                  @selectItem="selectStage"
                />
              </div>
            </div>
            <bh-table :table-settings="tableSettings3" @colClick="colClick" />
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>

<script>
import store from 'store'
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import BhTable from 'components/UIComponents/BhTable'
import navTabs from 'mixins/navTabs'
import WOFuncs from 'mixins/WOFuncs'
import WOWIPDetails from 'src/components/Dashboard/JobWo/WOWIPDetails.vue'
import CustomerSelectField from 'components/UIComponents/CustomerSelectField'
import RegionFilter from 'components/Dashboard/RegionFilter'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import SelectField from 'components/UIComponents/SelectField'

import dateFormat from 'dateformat'

export default {
  data() {
    var self = this
    return {
      tab: 'My WOs',
      eid: store.getters.userAuthedData.eid,
      woOpenShow: true,
      woCompleteShow: false,
      woMissingPoShow: false,
      woSearchRange: '',
      tableSettings1: {},
      tableSettings2: {},
      tableSettings3: {},
      woRanges: [],
      lastLoadParams: {},
      dataLoading: false,
      selectByCC: '',
      woBilling: [],
      wosTotal: {
        Under_Over_Billing: 0,
        Total_Contract_Amount: 0,
        Billed_JTD: 0,
        Net_Cash_Flow: 0,
        Billings_Remaining: 0
      },
      woLaborData: [],
      region: null,
      stageCode: ''
    }
  },

  props: ['filterAll'],

  components: {
    BhTable,
    CustomerSelectField,
    RegionFilter,
    SelectField,
    WaitingSpinner
  },

  mixins: [navTabs, WOFuncs],

  computed: {
    ...mapGetters(['woAssemble', 'userAuthedData', 'customers', 'employees', 'regions']),

    isAdmin() {
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPM() {
      return this.userAuthedData.user_role.indexOf('pm') !== -1
    },

    isPMp() {
      return this.isPM && this.userAuthedData.user_role.indexOf('P') !== -1
    },

    isEditor() {
      return this.$store.getters.userAuthedData.user_role.indexOf('editor') !== -1
    },

    isEditorO() {
      return this.isEditor && this.$store.getters.userAuthedData.user_role.indexOf('O') !== -1
    },

    allWOs() {
      let wos = JSON.parse(JSON.stringify(this.woAssemble))

      var timestampSeconds = Date.now() / 1000

      // only add to priority codes that make sense
      // var highlightPriorityCodes = ['1', '2', '3', '10'];
      // adding all prioirity codes here for now - controls status note last date highlighting
      var highlightPriorityCodes = ['1', '2', '3', '10', '11', '9', '4']
      for (var i = 0; i < wos.length; i++) {
        let dateTextString = ''
        if (wos[i]['last_status_note_date']) {
          let dateFormatted = dateFormat(parseInt(wos[i]['last_status_note_date']) * 1000, 'yyyy-mm-dd')
          if (parseInt(wos[i]['last_status_note_date']) + 60 * 60 * 24 * 7 > timestampSeconds) {
            // less than week old
            dateTextString = `<span class="text-success font-weight-bold">${dateFormatted}</span>`
          } else if (parseInt(wos[i]['last_status_note_date']) + 60 * 60 * 24 * 14 > timestampSeconds) {
            // less than 2 weeks old
            dateTextString = `<span class="text-warning font-weight-bold">${dateFormatted}</span>`
          } else {
            // more than 2 weeks old
            dateTextString = `<span class="text-danger font-weight-bold">${dateFormatted}</span>`
          }
        } else {
          dateTextString = `<span class="text-danger font-weight-bold">none</span>`
        }

        wos[i].customer_name = (this.customers.find(e => e.Customer_Code == wos[i].Bill_Customer_Code) || {}).Name
        wos[i].Lead_Name = (this.employees.find(e => e.Employee_Code == wos[i].lead) || {}).Employee_Name
        wos[i].Sales_Person_Name = (this.employees.find(e => e.Employee_Code == wos[i].Sales_Person) || {}).Employee_Name
        wos[i].PM_Name = (this.employees.find(e => e.Employee_Code == wos[i].PM) || {}).Employee_Name

        wos[i].ProjectStageName = this.projectStages.find(e => e.id == wos[i].project_stage)?.description || wos[i].project_stage
        wos[i].SignOffStageName = this.signOffStages.find(e => e.id == wos[i].sign_off_stage)?.description || wos[i].sig_noff_stage

        wos[i]['last_status_note_date_formatted'] = {
          action: 'openWOWipNotes',
          title: dateTextString
        }
      }
      return wos
    },

    WOFields() {
      return {
        WO_Number: {
          name: 'WO #',
          class: 'narrow'
        },
        customer_name: {
          name: 'Customer Name'
        },
        region_name: {
          name: 'Region'
        },
        Summary_Description: {
          name: 'Description',
          wide: 1
        },
        ProjectStageName: {
          name: 'Project Stage'
        },
        SignOffStageName: {
          name: 'Sign Off Stage'
        },
        Requested_Date: {
          name: 'Start Date',
          class: 'underlined'
        },
        Scheduled_Start_Date: {
          name: 'Finish Date',
          class: 'underlined'
        },
        po_number_display: {
          name: 'PO Number',
          class: 'underlined'
        },
        // Under_Over_Billing: {
        //   name: 'Under/Over Billing',
        //   hidden: !this.isPMp
        // },
        Last_Cost_Date: {
          name: 'Last Worked Date',
          hidden: !this.isPMp
        },
        PM_Name: {
          name: 'Project Lead'
        },
        Sales_Person_Name: {
          name: 'Sales Person'
        },
        Lead_Name: {
          name: 'Lead Technician'
        },
        Dispatch_Description: {
          name: 'Status'
        },
        Dispatch_Status_Code: {
          name: 'Dispatch Status Code',
          hidden: 1
        },
        ordered_date_formatted: {
          name: 'Date Ordered'
        },
        completed_date_formatted: {
          name: 'Date Finished'
        },
        // Priority_Description_Link: {
        //   name: 'Stage',
        //   noFilter: 1
        // },
        WO_Recieved: {
          name: 'Sign Off',
          noFilter: 1,
          class: 'narrow'
        }
        // last_status_note_date_formatted: {
        //   name: 'Last Status Note'
        // },
        // status_note: {
        //   name: 'Status Update Notes',
        //   wide: 1,
        //   noFilter: 1
        // },
      }
    }
  },

  methods: {
    colClick(obj) {
      const id = obj.itm.WO_Number
      if (this.isPM && obj.col == 'po_number_display') {
        const obj2 = Object.assign({}, obj.itm)
        this.editPONumber(obj2)
      } else if (obj.col == 'Requested_Date' || obj.col == 'Scheduled_Start_Date') {
        this.editDates(obj.itm)
      } else {
        this.$router.push('/app/wo-details/' + id)
      }
    },

    editDates(obj) {
      this.$Modal({
        parent: this,
        name: 'SetEstimatedStartDate',
        size: 'lg',
        title: 'Project Dates',
        component: () => import('components/Dashboard/JobWo/SetEstimatedStartDate.vue'),
        props: {
          obj,
          type: 'WO'
        }
      })
    },

    editPONumber(obj) {
      this.$bus.$emit('modalOpen', {
        title: 'Edit PO Number',
        classes: 'gray-bg',
        name: 'JobWOPOEdit',
        title: '',
        size: 'modal-md',
        component: () => import('src/components/Dashboard/JobWo/JobWoPoEdit.vue'),
        componentData: {
          obj
        }
      })
    },

    setTableData() {
      // add row links
      let wos = [...this.allWOs]

      let wosTotal = {
        Under_Over_Billing: 0,
        Total_Contract_Amount: 0,
        Billed_JTD: 0,
        Net_Cash_Flow: 0,
        Billings_Remaining: 0
      }

      for (let i = 0; i < wos.length; i++) {
        if (this.woBilling && this.woBilling.length) {
          for (var j = 0; j < this.woBilling.length; j++) {
            if (wos[i].WO_Number == this.woBilling[j].WO_Number) {
              wos[i].Under_Over_Billing = parseFloat(this.woBilling[j]['Under_Over_Billing']) ? parseFloat(this.woBilling[j]['Under_Over_Billing']) : 0
            }
          }
        }

        if (this.woLaborData && this.woLaborData.length) {
          // if already set then exit loop, since sorted by date keep latest set
          for (var j = 0; j < this.woLaborData.length; j++) {
            if (wos[i].Last_Cost_Date) break
            if (wos[i].WO_Number == this.woLaborData[j].wo_number) {
              wos[i].Last_Cost_Date = this.woLaborData[j]['entry_date']
              break
            }
          }
        }

        // 11491 should be red

        // Convert and check each property, then store in a variable
        let underOverBillingValue = isNaN(parseFloat(wos[i]['Under_Over_Billing'])) ? 0 : parseFloat(wos[i]['Under_Over_Billing'])
        let contractAmountValue = isNaN(parseFloat(wos[i]['Total_Contract_Amount'])) ? 0 : parseFloat(wos[i]['Total_Contract_Amount'])
        let billedJTDValue = isNaN(parseFloat(wos[i]['Billed_JTD'])) ? 0 : parseFloat(wos[i]['Billed_JTD'])
        let netCashFlowValue = isNaN(parseFloat(wos[i]['Net_Cash_Flow'])) ? 0 : parseFloat(wos[i]['Net_Cash_Flow'])
        let billingsRemaining = contractAmountValue - billedJTDValue

        const woNumber = wos[i].WO_Number
        // if (this.myWOIds.indexOf(woNumber) !== -1) {
        // Add the values to the wosTotal object
        wosTotal['Under_Over_Billing'] += underOverBillingValue
        wosTotal['Total_Contract_Amount'] += contractAmountValue
        wosTotal['Billed_JTD'] += billedJTDValue
        wosTotal['Net_Cash_Flow'] += netCashFlowValue
        wosTotal['Billings_Remaining'] += billingsRemaining
        //   }

        // Use the variables in the original code
        wos[i].class = netCashFlowValue > 0 ? 'green' : !netCashFlowValue ? '' : 'red'
        wos[i]['Under_Over_Billing'] =
          underOverBillingValue < 0
            ? `<span data-filterby="${underOverBillingValue}" class="text-danger font-weight-bold">${this.formatNumber(underOverBillingValue, 1)}</span>`
            : underOverBillingValue > 0
              ? `<span data-filterby="${underOverBillingValue}" class="text-success font-weight-bold">${this.formatNumber(underOverBillingValue, 1)}</span>`
              : 0
        wos[i]['Total_Contract_Amount'] = contractAmountValue ? this.formatNumber(contractAmountValue, 1) : ''
        wos[i]['Billed_JTD'] = billedJTDValue ? this.formatNumber(billedJTDValue, 1) : ''
        wos[i]['Net_Cash_Flow'] = netCashFlowValue ? this.formatNumber(netCashFlowValue, 1) : ''

        if (!wos[i].Customer_PO_Number) {
          wos[i].po_number_display = '<span class="has-link text-danger underlined">Add</span>'
        } else {
          wos[i].po_number_display = wos[i].Customer_PO_Number
        }

        let region_name = ''
        if (wos[i].region != null && wos[i].region != undefined && wos[i].region != '') {
          for (const region of this.regions) {
            if (wos[i].region == region.id) {
              region_name = region.name
            }
          }
        }
        wos[i].region_name = region_name
      }

      wos = wos
        .filter(itm => {
          if (this.region == null || this.region == '') {
            return true
          }
          return itm.region === this.region
        })
        .filter(itm => {
          if (this.stageCode == null || this.stageCode == '') {
            return true
          }
          return [itm.project_stage, itm.sign_off_stage].includes(this.stageCode)
        })
        .map(itm => {
          itm.Requested_Date = itm.Requested_Date ? itm.Requested_Date.split(' ')[0] : ''
          itm.Scheduled_Start_Date = itm.Scheduled_Start_Date ? itm.Scheduled_Start_Date.split(' ')[0] : ''
          return itm
        })

      this.wosTotal = wosTotal

      this.tableSettings1 = {
        fields: this.WOFields,
        tableData: wos,
        preFiltered: {
          Dispatch_Status_Code: [],
          WO_Number: this.myWOIds
        },
        hideItemEdit: true,
        filterAll: this.filterAll || ''
      }

      this.tableSettings2 = {
        fields: this.WOFields,
        tableData: wos,
        preFiltered: {
          Dispatch_Status_Code: []
        },
        hideItemEdit: true
      }

      this.tableSettings3 = {
        fields: this.WOFields,
        tableData: this.selectByCC ? wos : [],
        preFiltered: {
          // Dispatch_Status_Code: []
        },
        hideItemEdit: true
      }
    },

    getOverUnderBillingMin() {
      return new Promise((resolve, reject) => {
        if (!this.isPMp) {
          resolve()
          return
        }
        const params = {
          action: 'get_over_under_billing_min'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            this.woBilling = data
            resolve()
          })
          .catch(res => {
            console.log(res)
            this.$snack.open(res.message || 'Problem getting WO Billing', 'error')
            reject()
          })
      })
    },

    getWOLaborData() {
      return new Promise((resolve, reject) => {
        if (!this.isPMp) {
          resolve()
          return
        }
        const params = {
          action: 'get_timecard_entries',
          employee_code: null,
          start_date: null,
          end_date: null,
          type: 'WO',
          number: null,
          extra_id: null,
          show_deleted: null,
          selected_jobs: [],
          selected_wos: [],
          approval_status: 'all'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            // sort by latest entry date
            data.sort((a, b) => {
              return new Date(b.entry_date) - new Date(a.entry_date)
            })
            this.woLaborData = data
            resolve()
          })
          .catch(res => {
            console.log(res)
            this.$snack.open(res.message || 'Problem getting WO Labor Data', 'error')
            reject()
          })
      })
    },

    loadData(loadParams) {
      return new Promise((resolve, reject) => {
        if (this.dataLoading) {
          // since used to refresh component data
          this.setTableData()
          resolve()
          return
        }
        this.dataLoading = true

        // if not given load params object start with last ones used
        loadParams = typeof loadParams === 'object' ? loadParams : this.lastLoadParams

        if (this.tab === 'All WOs') {
          if (this.woCompleteShow) {
            loadParams = {
              min: this.woSearchRange,
              max: this.woSearchRange + 1000
            }
          } else if (loadParams.min || loadParams.max || loadParams.customerCode) {
            // if loadParams have min and max but no longer woComplete show, refresh data
            loadParams = {} // wipe out old vals
            loadParams.refresh = 1 // set refresh
            this.selectByCC = ''
          }
        } else if (this.tab === 'By Customer') {
          if (this.selectByCC) {
            loadParams = {
              customerCode: this.selectByCC
            }
          } else {
            loadParams = {}
            this.dataLoading = false
            return
          }
        }

        // remember what was last loaded
        this.lastLoadParams = loadParams

        let missing_po = 0
        if (this.woMissingPoShow) {
          missing_po = 1
        }
        loadParams.missing_po = missing_po

        this.$bus.$emit('setWaiting', { name: 'getWoAssemble', message: 'Getting Work Orders' })
        this.$store
          .dispatch('getWoAssemble', loadParams)
          .then(() => {
            this.lastLoadParams.refresh = null
            this.setTableData()
            resolve()
          })
          .catch(res => {
            this.$snack.open('Problem loading WO data', 'error')
            console.log(res)
            reject()
          })
          .finally(() => {
            // delete refresh to prevent from refreshing from lastLoadedParams
            this.$bus.$emit('stopWaiting', 'getWoAssemble')
            this.dataLoading = false
          })
      })
    },

    addWO() {
      this.$Modal({
        parent: this,
        name: 'wo-add-edit', // used for closing specific modal programmatically
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: () => import('src/components/Dashboard/JobWo/WOAddEdit.vue'),
        contentClasses: '',
        title: 'Add Work Order'
      })
    },

    setWOSortRange() {
      // get highest WO number to set wo range select to max number - do here since know getting fetch by current WOs
      let highest = this.allWOs.sort((a, b) => {
        return b.WO_Number - a.WO_Number
      })
      if (Array.isArray(highest) && highest[0] && highest[0].WO_Number) {
        highest = Math.floor(parseInt(highest[0].WO_Number) / 1000)
        if (typeof highest === 'number') {
          // format array for select field
          for (var i = 4; i <= highest; i++) {
            var num = i
            var obj = {
              display: num * 1000 + ' - ' + (num + 1) * 1000,
              min: num * 1000,
              max: (num + 1) * 1000
            }
            this.woRanges.push(obj)
          }
          this.woSearchRange = obj.min
        }
      }
    },
    listenSetStartFinishDates(startDate, finishDate, woNumber) {
      if (!woNumber) return
      for (var i = 0; i < this.allWOs.length; i++) {
        if (this.allWOs[i].WO_Number == woNumber) {
          this.allWOs[i].Requested_Date = startDate.split(' ')[0]
          this.allWOs[i].Scheduled_Start_Date = finishDate.split(' ')[0]
          break
        }
      }
    },
    listenPOEdit(obj) {
      if (!obj.WO_Number || !obj.po) return
      for (var i = 0; i < this.allWOs.length; i++) {
        if (this.allWOs[i].WO_Number == obj.WO_Number) {
          this.allWOs[i].Customer_PO_Number = obj.po
          break
        }
      }
      this.setTableData()
    },
    selectRegion(selected_region) {
      this.region = selected_region
      this.setTableData()
    },
    selectStage(selected_stage) {
      this.stageCode = selected_stage
      this.setTableData()
    }
  },

  async mounted() {
    this.$bus.$emit('setWaiting', { name: 'getData', message: 'Getting Data' })
    const billing = this.getOverUnderBillingMin()
    const customers = this.$store.dispatch('getCustomers')
    const employees = this.$store.dispatch('getEmployees')
    const contacts = this.$store.dispatch('getCustomerContacts')
    const woLaborData = this.getWOLaborData()
    const regions = this.$store.dispatch('getRegions')

    try {
      await Promise.all([billing, customers, contacts, woLaborData, employees, regions])
      await this.loadData({ refresh: true })
    } catch (e) {
      console.log(e)
    }
    this.$bus.$emit('stopWaiting', 'getData')

    this.setWOSortRange()

    this.$bus.$on(['addUpdateWO'], obj => {
      this.loadData()
    })

    this.$bus.$on(['itemCommentUpdate', 'updateWIP'], obj => {
      this.loadData({ refresh: true })
    })

    this.$bus.$on('openWOWipNotes', obj => {
      this.$Modal({
        parent: this,
        name: 'WOWIPDetails', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        component: WOWIPDetails,
        props: {
          inputWoId: obj.id,
          tab: 'Notes',
          woNav: this.tab
        }
      })
    })

    this.$bus.$on('openWOWipMain', obj => {
      this.$Modal({
        parent: this,
        name: 'WOWIPDetails', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        component: WOWIPDetails,
        props: {
          inputWoId: obj.id,
          tab: 'Main',
          woNav: this.tab
        }
      })
    })

    this.$bus.$on('JobWOPOEdit', this.listenPOEdit)
    this.$bus.$on('setStartFinishDates', this.listenSetStartFinishDates)
    this.$bus.$on('updateJobWO', this.loadData)
  },

  watch: {
    tab: function () {
      this.loadData()
    },
    woCompleteShow() {
      this.loadData()
    },
    woMissingPoShow() {
      this.loadData({ refresh: true })
    },
    selectByCC() {
      if (this.selectByCC) {
        this.loadData()
      }
    },
    woSearchRange() {
      if (this.woCompleteShow) {
        this.loadData()
      }
    }
  },

  beforeDestroy() {
    this.$bus.$off(['openWOWipMain', 'openWOWipNotes', 'updateWIP', 'itemCommentUpdate'])
    this.$bus.$off('JobWOPOEdit', this.listenPOEdit)
    this.$bus.$off('setStartFinishDates', this.listenSetStartFinishDates)
    this.$bus.$off('updateJobWO', this.loadData)
  }
}
</script>

<style lang="scss">
// @import "src/assets/sass/paper/_variables.scss";

.wo-range-select {
  display: inline-block;
  vertical-align: top;
}
</style>
