
<div class="header-notes-container">
  <div class="header-buttons">
    <div class="header-edit">
      <div class="header-input">
        <input type="text" v-model="headerNameEdit" class="header-field" :readonly="readonly || readonlyHeader" @input="resizeAllHeaderFields" />
      </div>
      <span class="control-buttons" v-if="!readonly && !readonlyHeader && sortable">
        <i class="ti-angle-down is-link" @click="$emit('moveDown')" v-if="headerNameEdit === headerName" />
        <i class="ti-angle-up is-link" @click="$emit('moveUp')" v-if="headerNameEdit === headerName" />
      </span>
      <span class="control-buttons action-buttons">
        <i class="ti-check edit-save" @click="$emit('saveHeaderName', headerNameEdit)" v-if="headerNameEdit !== headerName" />
        <i class="ti-na edit-cancel" @click="headerNameEdit = headerName" v-if="headerNameEdit !== headerName" />
      </span>
    </div>

    <button v-if="showButtons && !readonly && !readonlyHeader" class="btn btn-no-radius btn-sm btn-pip btn-blue" @click="$emit('addCustomItem')">Custom Item</button>

    <button v-if="showButtons && !readonly && !readonlyHeader" class="btn btn-no-radius btn-sm btn-pip btn-blue" @click="$emit('addFileItem')">Insert from File</button>

    <button v-if="!readonly && !readonlyHeader" class="btn btn-no-radius btn-sm btn-pip btn-blue" @click="$emit('deleteHeader')">Delete Header</button>

    <!-- <button v-if="!readonly && !readonlyHeader && !showTable" class="btn btn-no-radius btn-sm btn-pip btn-blue" @click="addTable = true">Add Table</button> -->
  </div>
  <div class="note-template-items" :data-h="headerId" ref="noteTemplateItems">
    <div class="note-template-item-section" v-for="(s, si) in noteSystems" :key="si">
      <div class="note-template-item" v-if="s.notes && s.notes.length">
        <div class="note-desc">
          <span v-if="notesHaveSystems(s)">
            <strong>{{ s.number }}: {{ s.name }}</strong>
          </span>
          <span v-else>
            <strong>Description</strong>
          </span>
        </div>
        <div class="note-control-buttons" />
      </div>

      <div v-for="(n, i) in s.notes" :key="i" class="note-template-item">
        <div class="note-add">
          {{ n.sl }}
        </div>
        <div class="note-desc">
          <!-- <input type="text" v-model="n.t" @focus="n.editing = true"> -->
          <RichTextInline
            :content="n.t"
            @update:content="n.t = $event"
            @setEditing="setEditing(n)"
            @save="doneRichTextEditing(n)"
            :readonly="readonly"
            :data-n="n.sl"
            :data-s="n.si"
            @keyup:enter="addItemBelow($event, s, n)"
          />
        </div>
        <div class="note-control-buttons">
          <span v-if="!readonly" class="control-buttons">
            <span v-if="!hasAnyEdits()">
              <i class="ti-angle-down is-link" @click="$emit('moveNoteDown', { si: n.si, sl: n.sl, index: i })" />
              <i class="ti-angle-up is-link" @click="$emit('moveNoteUp', { si: n.si, sl: n.sl, index: i })" />
            </span>
            <i class="ti-close edit-delete" @click="deleteNote(n)" />
          </span>
        </div>
      </div>
    </div>
    <table-editor v-if="showTable" ref="tableEditor" style="margin-bottom: 10px" :table-content="tableContent" />
    <div v-else-if="tableContent" class="table-content-read-only">
      <div v-html="tableContent" />
    </div>
    <button v-if="showTable && !readonlyHeader" class="btn btn-no-radius btn-sm btn-pip btn-blue" @click="saveTableNote()">Save</button>
  </div>
</div>
