<template>
  <div class="sh-widget wide-large card">
    <div class="content">
      <h3>
        My Quotes <span v-if="filter === 'overdue'">(Overdue)</span><span v-else>(In Progress)</span> ({{ count }})
        <router-link :to="{ name: 'All Quotes' }" class="ti-new-window is-link" tag="i" />
      </h3>
      <div class="content-inner show-scrollbars">
        <bh-table :table-settings="tableSettings" :style="{ width: '630px' }" @edit-item="goToItem" />
        <waiting-spinner :contained="true" />
      </div>
    </div>
  </div>
</template>

<script>
import store from 'store'
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import quoteFuncs from 'mixins/quoteFuncs'
import BhTable from 'components/UIComponents/BhTable'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {}
  },

  mixins: [quoteFuncs],

  components: {
    BhTable,
    WaitingSpinner
  },

  props: {
    showStatuses: {
      type: Array,
      required: true
    },
    filter: {
      type: String,
      default: null
    }
  },

  computed: {
    filtered() {
      let quotes = JSON.parse(JSON.stringify(this.myQuotes))
      if (this.filter === 'overdue') {
        quotes = quotes.filter(itm => {
          return itm.overdue === true
        })
      }

      for (let i = 0; i < quotes.length; i++) {
        if (quotes[i].overdue === true) {
          quotes[i].class = 'orange'
        }
      }

      return quotes
    },

    count() {
      return this.filtered && this.filtered.length ? this.filtered.length : 0
    },

    tableSettings() {
      return {
        tableData: this.filtered,
        fields: {
          quote_number: {
            name: 'Quote Number',
            class: 'bold',
            style: 'maxWidth:100px;minWidth:100px'
          },
          customer_code: {
            name: 'Customer',
            style: 'maxWidth:90px;minWidth:90px'
          },
          description: {
            name: 'Description',
            style: 'maxWidth:200px;minWidth:200px'
          },
          status: {
            name: 'Status',
            noFilter: 1,
            style: 'maxWidth:120px;minWidth:120px'
          },
          required_date: {
            name: 'Date Required',
            noFilter: 1,
            style: 'maxWidth:120px;minWidth:120px'
          }
        },
        hideAllSearch: true,
        hideTip: true,
        tableLayout: 'auto'
      }
    }
  },

  methods: {
    goToItem(id) {
      // this.$router.push('/app/all-quotes/');

      let data = this.quoteData.find(itm => {
        return parseInt(itm.id) === parseInt(id)
      })

      this.$Modal({
        parent: this,
        name: 'quote-edit', // used for closing specific modal programmatically
        size: 'xxl', // sm, md, lg, xl
        hideClose: true,
        // title: 'Edit Quote',
        component: () => import('components/Dashboard/Quotes/Quote2Edit.vue'),
        props: {
          componentData: {
            quoteData: data
          }
        }
      })
    }
  },

  mounted() {
    this.$bus.$emit('setWaiting', { name: 'getQuotes', message: 'Getting Quotes' })
    this.$store.commit('quoteFilterStatus', this.showStatuses)
    this.$store.dispatch('getQuotes').then(() => {
      this.$bus.$emit('stopWaiting', 'getQuotes')
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.sh-widget {
  width: 700px;
  max-width: 100%;
  max-height: 370px;
  overflow: hidden;

  @media (min-width: 900px) {
    height: 370px;
  }
}

h3 {
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;

  i {
    color: $primary-color;
    float: right;
  }
}

.content-inner {
  overflow: scroll !important;
  max-height: 310px;
}
</style>
