
<div>
  <div class="row">
    <div class="col-sm-12 col-lg-8">
      <router-link class="back-nav-link" :to="{ name: 'Purchase Order Reports' }"><i class="fa fa-chevron-left" />Back</router-link>

      <div class="card">
        <div class="content">
          <div class="po-summary">
            <div v-if="typeFormatted === 'vendor'" class="vendor-contact-display">
              <label><strong>Vendor:</strong> <span v-html="vendor.Vendor_Name" /></label>
              <div v-if="vendorContactName && contact.Email">
                <label><strong>Billing Contact:</strong> <span v-html="vendorContactName" /></label>
                <label><strong>Email:</strong> <span v-html="contact.Email || ''" /></label>
                <i class="ti ti-pencil vendor-contact-edit" @click="addEditBillingContactLink" />
              </div>
              <a v-else v-html="'<i><u>Add Billing Contact</u></i>'" class="link" @click="addEditBillingContactLink" style="margin-top: 30px" />
            </div>
            <div v-if="typeFormatted === 'wo'" class="po-summary">
              <label><strong>WO#: </strong> <span v-html="itmCode" /></label>
              <label><strong>Description: </strong> <span v-html="wo.Summary_Description" /></label>
            </div>
            <div v-if="typeFormatted === 'job'" class="po-summary">
              <label><strong>Job#: </strong> <span v-html="itmCode" /></label>
              <label><strong>Description: </strong> <span v-html="job.Job_Description" /></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-lg-8">
      <div v-if="typeFormatted === 'vendor'" class="row">
        <div class="col-xs-6">
          <a v-if="previewUrl" class="btn" :href="previewUrl" target="_blank"> Preview Report </a>
        </div>
        <div class="col-xs-6 text-right">
          <div style="display: inline-block">
            <checkbox v-model="disable_auto_send" @change="updateVendor"> Disable Auto Reports</checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br />

  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="content po-table-container">
          <h4 style="margin: 10px 0 20px">Open Purchase Orders</h4>
          <!-- <p><i>(Open and older than 2 weeks)</i></p> -->
          <bh-table :table-settings="tableSettings" />
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner :wait="wait" />
</div>
