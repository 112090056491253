<template>
  <div>
    <alert />

    <h4>All Items</h4>
    <div id="tree-root"></div>
    <waiting-spinner />
  </div>
</template>

<script>
import store from 'store'
import appFuncs from 'appFuncs'
import Alert from 'components/UIComponents/Alert'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import 'jstree/dist/themes/default/style.min.css'

import $ from 'jquery'
import 'jstree' // Import jstree as a side effect to attach it to jQuery

export default {
  components: {
    Alert,
    WaitingSpinner
  },

  data() {
    return {
      urls: store.getters.urls,
      dirData: []
    }
  },

  computed: {},

  methods: {
    getDirData() {
      var self = this

      var data = {
        sher_auth: store.getters.sherAuth,
        action: 'get_resource_directories'
      }

      $.ajax({
        url: self.urls.backend + 'ajax.php',
        type: 'POST',
        data: $.param(data),
        dataType: 'json',
        success: function (result) {
          //console.log(result);
          delete result.jwt_data
          result = Object.values(result)
          self.dirData = result
          self.setTree()
        },
        error: function (error, status) {
          console.log('error getting resource directories')
          console.log(status)
        }
      })
    },

    setTree() {
      var self = this

      // $.jstree.defaults.core.themes.variant = "large";
      $.jstree.defaults.core.data = self.dirData
      var elm = $('#tree-root')
      $(elm)
        .on('click', '.jstree-anchor', function (e) {
          $(elm).jstree(true).toggle_node(e.target)
        })
        .on('select_node.jstree', function (e, data) {
          var href = data.node.a_attr.href
          if (href && href !== '#') {
            window.open(href)
          }
        })
        .jstree({
          core: {
            dblclick_toggle: false,
            themes: {
              // name: 'dark',
              variant: 'large',
              dots: true,
              stripes: false,
              responsive: false
            }
          }
        })
    }
  },

  mounted() {
    this.getDirData()
  },

  watch: {}
}
</script>

<style lang="scss" scoped></style>
