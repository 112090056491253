<template>
  <div class="sh-widget card">
    <div class="content">
      <h4>WO Status Updates</h4>
      <table @click="gotoTable">
        <tr>
          <th>Age (Days)</th>
          <th>WOs</th>
        </tr>
        <tr>
          <td>0 - 7</td>
          <td :class="[{ green: woAge1 }]">
            {{ woAge1 }}
          </td>
        </tr>
        <tr>
          <td>7 - 14</td>
          <td :class="[{ yellow: woAge2 }]">
            {{ woAge2 }}
          </td>
        </tr>
        <tr>
          <td>14 +</td>
          <td :class="[{ red: woAge3 }]">
            {{ woAge3 }}
          </td>
        </tr>
      </table>
      <waiting-spinner :contained="true" />
    </div>
  </div>
</template>

<script>
import store from 'store'
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import moment from 'moment'
import dateFormat from 'dateformat'

export default {
  components: { WaitingSpinner },
  data() {
    return {
      eid: store.getters.sherAuth.eid
    }
  },

  computed: {
    ...mapGetters(['woAssemble']),

    myWOs() {
      // open statuses
      var highlightPriorityCodes = ['1', '2', '3', '10']

      // all
      var highlightPriorityCodes = ['1', '2', '3', '10', '11', '9', '4']

      let myWOs = this.woAssemble.filter(itm => {
        if (highlightPriorityCodes.indexOf(itm.Priority_Code) === -1) {
          return false
        }

        if (itm.PM == this.eid || itm.lead == this.eid || itm.tech_main == this.eid) {
          return true
        }

        if (itm.techs) {
          for (var i = itm.techs.length - 1; i >= 0; i--) {
            return itm.techs[i] == this.eid
          }
        }
      })
      return myWOs
    },

    woAge1() {
      var timestampSeconds = Date.now() / 1000
      let age = this.myWOs.filter(itm => {
        let periodStart = parseInt(itm.last_status_note_date) + 60 * 60 * 24 * 7
        if (periodStart > timestampSeconds) {
          return true
        }
      })
      return age.length
    },

    woAge2() {
      var timestampSeconds = Date.now() / 1000
      let age = this.myWOs.filter(itm => {
        let periodStart = parseInt(itm.last_status_note_date) + 60 * 60 * 24 * 7
        let periodEnd = parseInt(itm.last_status_note_date) + 60 * 60 * 24 * 14
        if (periodStart < timestampSeconds && periodEnd > timestampSeconds) {
          return true
        }
      })
      return age.length
    },

    woAge3() {
      var timestampSeconds = Date.now() / 1000
      let age = this.myWOs.filter(itm => {
        let periodStart = parseInt(itm.last_status_note_date) + 60 * 60 * 24 * 14
        if (periodStart < timestampSeconds) {
          return true
        }
      })
      return age.length
    }
  },

  methods: {
    getDays(seconds) {
      return Math.floor(seconds / (3600 * 24))
    },

    gotoTable() {
      this.$router.push({ path: 'app/wos' })
    }
  },

  mounted() {
    this.$bus.$emit('setWaiting', { name: 'getWoAssemble', message: 'Getting WO Assemble' })
    this.$store.dispatch('getWoAssemble').then(() => {
      this.$bus.$emit('stopWaiting', 'getWoAssemble')
    })
  }
}
</script>

<style lang="scss" scoped>
// @import "src/assets/sass/paper/_variables.scss";

table {
  cursor: pointer;
}
</style>
