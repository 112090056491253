
<div>
  <div class="simple-header">
    <div class="row">
      <div class="col-xs-4">
        <div class="logo">
          <span class="sidebar-logo">
            <img :src="'static/img/logo.jpg'"/>
          </span>
        </div>
      </div>
      <div class="col-xs-8 right-links">
        <router-link v-for="(link, li) in links" :key="li" :to="{ path: link.path }" class="is-link underlined text-normal" v-html="link.title"
                     v-show="hasPermission(link.role)"
        />
      </div>
    </div>
  </div>
</div>
