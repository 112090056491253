<template>
  <div class="select-field select-field-full" v-click-outside="closeField">
    <input
      v-if="!disabled || disabledInput || readOnly"
      type="text"
      :class="['form-control', 'box-add-item', { 'select-field': !inline, 'fake-disabled': disabledInput && !showAsDisabled }, { 'no-bottom-radius': inline }, fieldClasses]"
      v-model="fieldVal"
      readonly
      @click="showOptions = readOnly ? false : !showOptions"
      :placeholder="placeholder"
      :disabled="disabled && fieldVal != ''"
    />
    <span v-else v-html="fieldVal" />
    <div v-if="(showOptions || inline) && !disabled" class="option-box-parent">
      <div :class="['option-box', { inline: inline }, { toggle: !inline }]">
        <div class="row upper row-no-gutters">
          <div class="col-xs-8">
            <h4>{{ label }}</h4>
          </div>
          <div class="col-xs-4 cancel-container">
            <a @click="cancel">Cancel</a>
          </div>
        </div>
        <input v-if="options && options.length > 6" type="text" :class="['form-control', 'search-field', 'has-danger']" v-model="searchFilter" placeholder="Search" />
        <div class="option-items show-scrollbar">
          <div v-if="filteredItems && filteredItems.length">
            <div v-if="allowClear" class="option-item empty-option-item" @click="userSelectEl({})">
              <span><span v-html="typeof allowClear === 'string' ? allowClear : 'CLEAR'" /></span>
            </div>
            <div v-for="(optionItem, key) in filteredItems" :key="key" class="option-item" @click="userSelectEl(optionItem)">
              <div v-if="formatOptionDisplay(optionItem)" v-html="formatOptionDisplay(optionItem)" />
            </div>
          </div>
          <div v-else-if="options && options.length">
            <i>No matching results</i>
          </div>
          <div v-else>
            <i>{{ emptyOptionText }}</i>
          </div>
        </div>
        <div v-if="allowAddSimpleItem" class="add-simple-item-container">
          <input class="form-control" type="text" v-model="addSimple" :placeholder="'Add ' + (itemName ? itemName : 'Item')" />
          <button class="btn" @click="addSimpleVal" v-html="'Add'" />
        </div>
        <span v-if="allowAddItem" class="add-new" @click="$emit('addItem')">Add New</span>
      </div>
    </div>
  </div>
</template>
<script>
import appFuncs from 'appFuncs'

export default {
  data() {
    return {
      fieldVal: '',
      showOptions: false,
      searchFilter: '',
      selectedObj: '',
      placeholder: '',
      addSimple: '',
      loadedFieldVal: ''
    }
  },

  props: {
    options: Array,
    classes: {
      type: Array,
      default: function () {
        return []
      }
    },
    optionDisplayKeys: {
      type: Array,
      required: true
    },
    optionVal: {
      type: [String, Array],
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    showAsDisabled: {
      type: Boolean,
      default: false
    },
    optionSeperator: {
      type: String,
      default: ' - '
    },
    emptyOptionText: {
      type: String,
      default: 'No items found'
    },
    disabled: {
      type: [Boolean, Number, String],
      default: false
    },
    disabledInput: {
      type: Boolean,
      default: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    allowAddSimpleItem: {
      type: Boolean,
      default: false
    },
    allowAddItem: {
      type: Boolean,
      default: false
    },
    placeholderText: {
      type: String,
      default: ''
    },
    itemName: {
      type: String,
      default: ''
    },
    preselectSingleOption: {
      type: Boolean,
      default: true
    },
    allowClear: {
      type: [String, Boolean],
      default: false
    },
    defaultValue: {
      type: String,
      default: ''
    },
    filterOnKeyVal: {
      type: Object,
      default() {
        return {}
      }
    },
    label: {
      type: String,
      default: ''
    }
  },

  computed: {
    filteredItems() {
      var options = this.options
      if (this.searchFilter && options) {
        options = this.options.filter(item => {
          var optionStr = this.formatOptionDisplay(item)
          if (optionStr) {
            return String(optionStr.toLowerCase()).indexOf(String(this.searchFilter).toLowerCase()) > -1
          } else {
            return item
          }
        })
      }

      if (this.filterOnKeyVal.key && this.filterOnKeyVal.val) {
        options = options.filter(itm => {
          return itm[this.filterOnKeyVal.key] == this.filterOnKeyVal.val
        })
      }

      return options
    },

    fieldClasses() {
      return this.classes.toString()
    }
  },

  methods: {
    formatOptionDisplay(optionItem) {
      let displayVal = ''
      let matchingVal = ''
      for (var i = this.optionDisplayKeys.length - 1; i >= 0; i--) {
        if (optionItem[this.optionDisplayKeys[i]] === undefined || optionItem[this.optionDisplayKeys[i]] === null) {
          continue
        }

        // if no matching key, use key value - allows custom display value separators
        matchingVal = optionItem[this.optionDisplayKeys[i]]
        let lastMatchingVal = i > 0 ? optionItem[this.optionDisplayKeys[i - 1]] : ''
        displayVal = matchingVal + displayVal
        if (i && i < this.optionDisplayKeys.length) {
          displayVal = lastMatchingVal ? this.optionSeperator + displayVal : displayVal
        }
      }
      return displayVal
    },

    cancel() {
      this.closeField()
    },

    userSelectEl(optionItem) {
      const optionVal = optionItem && optionItem[this.optionVal] ? optionItem[this.optionVal] : null
      this.selectEl(optionItem)
      this.$emit('selectItem', optionVal, optionItem)
    },

    selectEl(optionItem) {
      this.showOptions = false
      const optionVal = optionItem && optionItem[this.optionVal] ? optionItem[this.optionVal] : null
      this.fieldVal = this.formatOptionDisplay(optionItem)
      this.$emit('input', optionVal, optionItem)
      this.$emit('change', optionVal, optionItem)
      this.selectedObj = optionItem
    },

    closeField() {
      this.showOptions = false
    },

    getOptionFromProp(optionItem) {
      if (!optionItem) {
        this.selectEl('')
        return
      }

      if (this.options) {
        var val = this.options.find(itm => {
          return itm[this.optionVal] == optionItem
        })

        if (val) {
          this.selectEl(val)
        }
      }
    },

    addNew() {
      this.$bus.$emit('SelectFieldAddNew')
    },

    addSimpleVal() {
      if (this.addSimple) {
        let newItm = {
          [this.optionVal]: this.addSimple,
          [this.optionDisplayKeys]: this.addSimple
        }
        this.options.push(newItm)
        this.$emit('update:options', this.options)
        this.$emit('addOption', newItm)
        this.userSelectEl(newItm)
        this.addSimple = ''
      }
    }
  },

  mounted() {
    if (this.value) {
      this.getOptionFromProp(this.value)
    }

    if (this.optionVal && Array.isArray(this.optionVal)) {
      this.optionVal === this.optionVal.join(' ')
    }

    if (!this.placeholderText) {
      this.placeholder = this.inline ? 'Select below' : 'Select'
    } else {
      this.placeholder = this.placeholderText
    }

    if (!this.fieldVal && this.defaultValue) {
      this.fieldVal = this.defaultValue
    }

    this.loadedFieldVal = JSON.parse(JSON.stringify(this.fieldVal))
  },

  watch: {
    options() {
      if (this.value) {
        this.getOptionFromProp(this.value)
      } else {
        this.fieldVal = ''
        // this.$emit('input', this.se);
      }

      if (this.preselectSingleOption && this.options && this.options.length === 1) {
        // can change selectEl() to userSelectEl() to make sure that SelectFieldMulti item gets assigned if only one item
        this.selectEl(this.options[0])
      }

      if (!this.options || !this.options.length) {
        this.fieldVal = ''
        this.$emit('input', '')
      }
    },

    value() {
      //  if (this.value) {
      this.getOptionFromProp(this.value)
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.select-field-full {
  position: relative;
}

input.form-control {
  &.no-bottom-radius {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 15px 10px 10px;
    height: auto;
  }

  &.has-error {
    background-color: $danger-input-bg !important;
  }
}

input[readonly] {
  //background-color:#f5f5f5!important;
  color: #66615b !important;
}

input[disabled] {
  // background-color:#f5f5f5!important;
  color: #9a9a9a !important;
}

input.select {
  -webkit-appearance: menulist-button;
  text-indent: 10px;
}

.box-add-item {
  cursor: pointer !important;

  &[disabled] {
    cursor: not-allowed !important;
  }
}

.option-box-parent {
  position: relative;
  z-index: 9999;

  .option-box {
    width: 100%;
    background: #fff;
    padding: 10px;
    border-radius: 0 0 5px 5px;

    &.toggle {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      box-shadow: 4px 7px 20px 2px rgba(0, 0, 0, 0.08);
    }

    &.inline {
      border: 5px solid #f5f5f5;
    }

    .search-field {
      margin-bottom: 10px;
    }

    .add-new {
      font-weight: bold;
      cursor: pointer;
      padding: 10px 0 0;
      text-decoration: underline;
      display: block;
    }

    .option-items {
      max-height: calc(100% - 150px);
      overflow-y: scroll;
      // background:#f5f5f5;
      padding: 10px;
      border-radius: 3px;

      .option-item {
        color: #555;
        cursor: pointer;

        &:not(.empty-option-item) {
          &:hover {
            font-weight: bold;
          }
        }

        > div {
          padding: 8px 0;
          border-bottom: 1px solid $light-gray;
        }
      }

      .empty-option-item {
        background: #eee;
        border: 1px solid #ddd;
        border-radius: 2px;
        padding: 5px;
        margin-bottom: 5px;

        span {
          font-style: italic;
          letter-spacing: 0.5px;
          font-weight: bold;
        }

        &:after {
          content: '\f057';
          font-family: 'Font Awesome 5 Free';
          float: right;
          font-weight: 900;
        }
      }
    }

    .option-box-add {
      .option-box-add-container {
        position: relative;
        margin-bottom: 10px;

        .option-box-add-input-container {
          margin-right: 70px;
        }
      }

      button {
        width: 60px;
        position: absolute;
        top: 0;
        height: 40px;
        display: block;
        right: 0;
        padding: 0;
        // border-radius:5px;
      }
    }
  }
}

.add-simple-item-container {
  margin-top: 5px;

  button {
    width: 80px;
    display: inline-block;
  }

  input {
    width: calc(100% - 90px);
    display: inline-block;
  }
}

.upper {
  max-width: calc(100% - 10px);
  margin: 0 auto 15px;
  padding: 15px 0 15px;
  vertical-align: bottom;
  border-bottom: 2px solid $light-gray;

  h4 {
    margin: 0;
  }

  .cancel-container {
    padding-top: 10px;
    text-align: right;
    font-weight: bold;

    a {
      color: $pastel-orange;
    }
  }
}
</style>
