
<div class="job-files">
  <div v-if="!jobError">
    <div>
      <h5>File Upload</h5>
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Drawer</label>
            <select-field :options="drawers" :option-display-keys="['name']" :option-val="'val'" v-model="fileDrawer" :fake-disabled="false" @selectItem="addTransactionSimple(null, true)" />
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Name</label>
            <input type="text" v-model="fileDescription" class="form-control" :disabled="existingTransaction" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-sm-12">
          <div :id="dzId" class="dropzone" style="margin-bottom: 20px" />
          <button class="btn" @click="uploadFile" v-html="existingTransaction ? 'Upload' : 'Create New'" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col col-sm-12">
        <h5>Documents</h5>
        <tabulator-table :table-data="uploadedDocsFiltered" :table-columns="tableColumnsDocs" table-fit="fitColumns" empty-text="(none)" @colClick="docsTableClick" />
      </div>
    </div>
    <br /><br />

    <div class="row">
      <div class="col col-sm-12">
        <h5>All Transactions</h5>
        <tabulator-table :table-data="existingTransactions" :table-columns="tableColumns" table-fit="fitColumns" empty-text="(none)" @colClick="transactionsTableClick" />
        <p style="margin: 10px 0">Click row to select transaction</p>
      </div>
    </div>
    <br /><br />
    <button v-if="inSequence" class="btn" @click="next()" v-html="'Next'" />
  </div>
  <p v-else>Problem loading Job Data, please close and continue from Job Details pages.</p>
  <waiting-spinner />
</div>
