
<div class="sh-widget wide-large card my-wos-widget">
  <div class="content">
    <h3>
      My Active Work Orders ({{ count }})
      <router-link :to="{ name: 'Work Orders' }" class="ti-new-window is-link" tag="i" />
    </h3>

    <div class="content-inner show-scrollbars">
      <bh-table :table-settings="tableSettings" :style="{ width: '630px' }" @colClick="goToItem" />
      <waiting-spinner :contained="true" />
    </div>
  </div>
</div>
