
<div>
  <button class="btn" @click="addItem">Add Contact</button>
  <br/>
  <br/>

  <div class="row" v-if="preFiltered && preFiltered.Account_Customer_Code__c">
    <div class="col-sm-12 col-md-4">
      <div class="form-group">
        <label>Filter by Site</label>
        <select-field :options="woSiteOptions" :option-display-keys="['Ship_To_Name', 'site_address']" :option-val="'sfId'" v-model="filterBySite"/>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="content">
      <bh-table :table-settings="tableSettings" @edit-item="editItem"/>
    </div>
  </div>
  <waiting-spinner/>
</div>
