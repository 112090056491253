<template>
  <div class="file-upload-container">
    <div class="row">
      <div :class="'col-sm-12'">
        <div v-for="config in updatedViewerConfig" :key="config.dzId" class="col-sm-4">
          <div v-if="showFileUpload">
            <label v-if="config.label || config.name">{{ config.label || config.name }}</label
            ><span v-if="config.required" class="required-star">*</span>

            <span v-if="existingFiles && existingFiles.length >= 1 && !readonly">
              <a href="#" class="label-link ti-download" @click="triggerDownload(config)" />
              <a v-if="hasViewable" href="#" class="label-link ti-eye" @click="showPictures" />
            </span>
            <p v-if="config.subText" style="margin: 0; padding: 0">
              <i>{{ config.subText }}</i>
            </p>
          </div>
          <div v-if="!readonly && showFileUpload" class="file-upload-inner" style="margin-bottom: 80px">
            <div :id="config.dzId" :ref="`dzref:${config.dzId}`" class="dropzone" />
            <input
              v-if="useComment"
              type="text"
              v-model="fileComment[config.dzId]"
              @input="updateButtonState(config.dzId)"
              class="form-control"
              style="margin-top: 24px"
              placeholder="Add a comment for new file"
            />
            <button class="btn btn-sm pull-right" style="margin: 10px 0" @click="processQueue(config)" :disabled="!buttonStates[config.dzId]">Upload File</button>
          </div>
        </div>
        <div style="margin: 30px 0">
          <tabulator-table
            :table-data="existingFiles"
            :table-columns="tableColumns"
            table-fit="fitColumns"
            :table-condensed="false"
            empty-text="(none)"
            @colClick="fileTableClick"
            max-table-height="300px"
          />
        </div>
      </div>

      <div class="col-sm-12">
        <component :is="portalName ? 'portal' : 'div'" :to="portalName">
          <div class="file-viewer">
            <div v-if="fileIsSelected">
              <iframe v-if="selectedFile.type === 'application/pdf'" :src="selectedFile.url" width="100%" height="600" />
              <div v-else-if="fileErrorText" class="text-danger">
                {{ fileErrorText }}
              </div>
              <img v-else-if="selectedFile.type && selectedFile.type.startsWith('image/')" :src="selectedFile.url" alt="Selected Image" />
              <div v-else-if="selectedFile.type === 'text/csv'" v-html="csvContent" />
              <div class="file-viewer-links" v-if="selectedFile && selectedFile.url">
                <span class="is-link" @click="openInNewTab">
                  <i class="ti-new-window" style="padding: 0 3px 3px 3px" />
                </span>
                <span class="is-link" @click="closePreview">
                  <i class="ti-na" style="padding: 3px" />
                </span>
              </div>
            </div>
            <div v-else class="empty-file-container">
              <p class="no-file-message">No file selected</p>
            </div>
          </div>
        </component>
      </div>
      <div v-html="downloadForm" />
      <waiting-spinner />
    </div>
  </div>
</template>

<script>
import store from 'store'
import appFuncs from 'appFuncs'
import Dropzone from 'dropzone'
import 'dropzone/dist/dropzone.css'
import GalleryPictures from 'src/components/GeneralViews/GalleryPictures.vue'
import $ from 'jquery'
import { mapGetters } from 'vuex'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

Dropzone.autoDiscover = false

export default {
  data() {
    return {
      uploadUrl: store.getters.urls.backend + 'file-uploader.php',
      existingFiles: [],
      dzObj: {},
      downloadForm: '',
      fileName: '',
      fileComment: {},
      buttonStates: {},
      dzId: '',
      dropzoneSet: false,
      hasViewable: false,
      allowDelete: false,
      selectedFile: {
        name: '',
        url: '',
        type: ''
      },
      csvContent: '',
      fileErrorText: '',
      from: 'fileuploadmultiviewer',
      updatedViewerConfig: [],
      allFilesDict: {},
      updatedItemId: null,
      hasViewedFile: false
    }
  },

  components: {
    TabulatorTable: () => import('components/UIComponents/TabulatorTable'),
    WaitingSpinner
  },

  props: {
    viewerConfig: {
      type: Array,
      default: () => []
    },
    canDelete: {
      type: [Boolean, null],
      default: null
    },
    typeGroup: {
      type: String,
      default: ''
    },
    uploadData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showPreview: {
      type: Boolean,
      default: false
    },
    showFileUpload: {
      type: Boolean,
      default: true
    },
    portalName: {
      type: String,
      default: ''
    },
    useComment: {
      type: Boolean,
      default: true
    },
    itemId: {
      type: [Number, String],
      default: ''
    }
  },

  computed: {
    ...mapGetters(['employees']),
    isAdmin() {
      return ((((store || {}).getters || {}).userAuthedData || {}).user_role || []).indexOf('admin') !== -1
    },

    fileIsSelected() {
      return this.showPreview && this.selectedFile && this.selectedFile.name !== ''
    },

    tableColumns() {
      return [
        {
          title: '',
          field: 'actions',
          sortable: false,
          width: 50,
          formatter: (cell, formatterParams, onRendered) => {
            return "<i class='fa fa-trash delete-icon text-danger' title='delete'></i>"
          },
          align: 'center',
          cellClick: (e, cell) => {
            var row = cell.getRow()
            this.fileDelete(row.getData())
            // row.delete()
          }
        },
        {
          field: 'id',
          visible: false
        },
        {
          title: 'File Name',
          field: 'text',
          width: 300,
          cssClass: 'is-link'
        },
        {
          title: 'Location',
          field: 'name',
          width: 300
        },
        {
          title: 'Drawer',
          field: 'drawer',
          width: 200
        },
        {
          title: 'Comment',
          field: 'fileComment',
          width: 300
        },
        {
          title: 'Created By',
          field: 'uploadedBy',
          width: 200,
          formatter: cell => {
            const value = cell.getValue()
            const employee = this.employees.find(emp => emp.Employee_Code === value)
            return employee ? employee.Employee_Name : value
          }
        },
        {
          title: 'Create Date',
          field: 'createdDate',
          width: 200,
          formatter: cell => {
            const val = cell.getValue()
            return this.formatDate(val)
          }
        }
      ]
    }
  },

  methods: {
    async updateButtonState(dzId) {
      const dzInstance = this.dzObj[dzId]
      await new Promise(resolve => setTimeout(resolve, 400))
      const hasFilesToUpload = dzInstance.getAcceptedFiles().length > 0
      console.log('hasFilesToUpload', hasFilesToUpload)
      const hasComment = this.fileComment[dzId]?.trim().length > 0
      // Update the state of the button based on the presence of files and comments
      this.buttonStates[dzId] = hasFilesToUpload && this.itemId && ((this.useComment && hasComment) || !this.useComment)
      this.$forceUpdate()
    },
    closePreview() {
      this.selectedFile = {
        name: '',
        url: '',
        type: ''
      }
    },

    hasRequiredFiles() {
      return this.updatedViewerConfig.some(config => {
        const dzInstance = this.dzObj[config.dzId]
        return dzInstance && dzInstance.getAcceptedFiles().length > 0 && config.required === true
      })
    },

    async triggerProcessQueueForAll() {
      console.log(this.updatedViewerConfig)
      const promises = this.updatedViewerConfig
        .filter(config => {
          const dzInstance = this.dzObj[config.dzId]
          return dzInstance && dzInstance.getAcceptedFiles().length > 0
        })
        .map(config => this.processQueue(config))

      return await Promise.all(promises)
    },

    get_uploaded_files(config) {
      if (!config.path) return

      var data = {
        action: 'get_directory_contents',
        dir: config?.path,
        subdir: config?.subdir
        //from: this.from
      }
      this.$bus.$emit('setWaiting', { name: 'get_directory_contents', message: 'Fetching uploaded files' })
      appFuncs
        .ajax_request(store.getters.sherAuth, data, result => {
          this.$bus.$emit('stopWaiting', 'get_directory_contents')
          if (result.status === 'success') {
            let data = result.data || []
            const values = data
              .filter(itm => itm.a_attr)
              .map(itm => {
                return {
                  ...itm,
                  id: this.randomCharacterString(12)
                }
              })

            let key = config.path
            if (config.subdir) key = config.path + '/' + config.subdir
            this.$set(this.allFilesDict, key, Array.isArray(values) ? values : [])
            this.updateExistingFiles()
          } else {
            this.$snack.open('Problem fetching some uploaded files', 'error')
          }
        })
        .catch(error => {
          console.error('Error fetching files:', error)
          this.$snack.open('Error fetching files. Please try again.', 'error')
        })
    },

    openInNewTab() {
      window.open(this.selectedFile.url, '_blank')
    },

    processQueue(config) {
      return new Promise((resolve, reject) => {
        var self = this
        const dzInstance = this.dzObj[config.dzId]

        if (dzInstance && dzInstance.getAcceptedFiles().length > 0) {
          dzInstance.on('sending', (file, xhr, formData) => {
            formData.append('path', config.path)
            formData.append('subdir', config.subdir || '')
            formData.append('jwt', store.getters.sherAuth.jwt)
            formData.append('user_login', store.getters.sherAuth.user_login)
            formData.append('expires', store.getters.sherAuth.expires)
            formData.append('type_group', this.typeGroup)
            formData.append('file_comment', this.fileComment[config.dzId] || '')
            formData.append('name', config.name)
            formData.append('created_date', new Date())
            formData.append('uploaded_by', store.getters.sherAuth.eid)
            formData.append('drawer', config.drawer)

            for (let key in this.uploadData) {
              formData.append(`upload_data[${key}]`, this.uploadData[key])
            }
          })

          dzInstance.on('queuecomplete', () => {
            resolve('All files have been uploaded successfully for dropzone ' + config.dzId)
          })

          dzInstance.on('error', (file, error) => {
            reject(error)
          })

          dzInstance.on('success', (file, response) => {
            console.log('process queue')
            dzInstance.removeAllFiles(true)
            self.$bus.$emit('filesUploaded', {
              name: self.name
            })
            self.$emit('updated')
            self.$emit('file-uploaded')
            const resultParse = typeof response === 'string' ? JSON.parse(response) : response
            if (resultParse.status === 'success') {
              resultParse.data.result.id = this.randomCharacterString(12)
              let key = config.path
              if (config.subdir) key = config.path + '/' + config.subdir
              this.allFilesDict[key] = Array.isArray(this.allFilesDict[key]) ? this.allFilesDict[key] : []
              this.allFilesDict[key] = [...this.allFilesDict[key], resultParse.data.result]
              this.existingFiles = Object.keys(this.allFilesDict).reduce((acc, key) => {
                return [...acc, ...this.allFilesDict[key]]
              }, [])

              this.$snack.open('File Uploaded', 'success')
            } else {
              this.$snack.open('Problem uploading file, please try again', 'warning')
            }
          })
          dzInstance.processQueue() // Manually starts the upload process.
        } else {
          resolve('No files to upload for dropzone ' + config.dzId)
        }
      })
    },

    setDropzones() {
      var self = this
      if (this.readonly || !this.showFileUpload) {
        this.dropzoneSet = true
        return
      }

      this.updatedViewerConfig.forEach(config => {
        const ref = this.$refs['dzref:' + config.dzId]
        if (!ref || (this.dzObj && this.dzObj[config.dzId])) return

        const id = 'div#' + config.dzId

        this.dzObj[config.dzId] = new Dropzone(id, {
          url: this.uploadUrl,
          params: {
            name: config.dzId,
            from: this.from
          },
          parallelUploads: 10,
          createImageThumbnails: false,
          dictDefaultMessage: 'Drop or click to upload',
          autoProcessQueue: false,
          addRemoveLinks: true
        })

        this.dzObj[config.dzId].on('addedfile', file => {
          if (Array.isArray(config.fileRestriction) && !config.fileRestriction.includes(file.type)) {
            this.dzObj[config.dzId].removeFile(file)
            self.$snack.open('File type not allowed', 'warning')
          }

          this.$emit('fileAdded', file)
          this.$emit('fileAddedObj', { file, config })
          this.updateButtonState(config.dzId)
          self.fileCount++
        })

        this.dzObj[config.dzId].on('complete', file => {
          this.buttonStates[config.dzId] = false
          this.fileComment[config.dzId] = ''
          this.dzObj[config.dzId].removeFile(file)
        })

        this.dropzoneSet = true
      })
    },

    fileDelete(obj) {
      if (obj.virtual) {
        this.$snack.open('Deletion is possible following task create', 'warning')
        return false
      }

      var self = this

      if (!this.allowDelete) {
        return false
      }

      if (!confirm('Are you sure you\'d like to delete "' + obj.text + '" ?')) {
        return
      }

      var data = {
        action: 'delete_file_aws',
        file_location: obj.location
      }

      appFuncs.ajax_request(store.getters.sherAuth, data, function (result) {
        if (result.status === 'success') {
          self.existingFiles = self.existingFiles.filter(file => file.location !== obj.location)
          self.$emit('updated')
        } else {
          self.$snack.open(result.message, 'error')
        }
      })
    },

    fileTableClick(obj) {
      const rowNum = (obj.row || {}).class
      const col = (obj.cell || {}).field
      const id = (obj.row || {}).id
      const file = this.existingFiles.find(f => f.id === id)
      if (file) {
        this.selectFile(file)
      }
    },

    showPictures() {
      this.$Modal({
        parent: this,
        name: 'gallery-pictures', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        component: GalleryPictures,
        props: {
          pictures: this.mergedExistingFiles
        }
      })
    },

    triggerDownload(config) {
      // using dynamic form to send POST vals and download in new tab
      var path = config.path + config.subdir
      var form = '<form id="download-form-' + config.dzId + '" method="POST" action="' + store.getters.urls.backend + 'download-file.php" target="_blank">'
      form += '<input type="hidden" name="action" value="zip_folder_aws">'
      form += '<input type="hidden" name="dirpath" value="' + encodeURI(path) + '">'
      form += '<input type="hidden" name="filename" value="' + encodeURI(config.fileName) + '">'
      form += '<input type="hidden" name="depth" value="' + this.zipDepth + '">' // -1 gets all nested directories, 0 files but no dirs
      form += '<input type="hidden" name="jwt" value="' + store.getters.sherAuth.jwt + '">'
      form += '</form>'
      this.downloadForm = form

      setTimeout(() => {
        $('#download-form-' + config.dzId).submit()
        this.downloadForm = null
      }, 500)
    },

    updateExistingFiles() {
      this.existingFiles = Object.keys(this.allFilesDict).reduce((acc, key) => {
        return [...acc, ...this.allFilesDict[key]]
      }, [])

      const billingTask = this.$parent?.data
      if (billingTask?.virtual_files) {
        this.existingFiles = [
          ...this.existingFiles,
          ...billingTask.virtual_files.filter(file => {
            const config = this.updatedViewerConfig.find(c => c.subdir === file.virtual_section)
            return config !== undefined
          })
        ]
      }

      this.existingFiles.sort((a, b) => {
        return new Date(b.lastModified) - new Date(a.lastModified)
      })

      this.$emit('files-updated', this.existingFiles)
    },

    getMimeType(fileName) {
      const extension = fileName.split('.').pop().toLowerCase()
      const mimeTypes = {
        pdf: 'application/pdf',
        csv: 'text/csv',
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png'
        // Add more mappings as needed
      }
      return mimeTypes[extension] || ''
    },

    selectFile(file) {
      this.hasViewedFile = true
      const type = this.getMimeType(file.text)
      const selectedFile = {
        name: file.text,
        url: file.a_attr.href,
        type
      }
      this.selectedFile = selectedFile
      this.fileErrorText = ''
      if (!type) {
        this.fileErrorText = 'Unsupported file type for preview'
        return
      }
      if (this.selectedFile.type === 'text/csv') {
        this.loadCSV(file)
      }
    },

    async loadCSV(file) {
      try {
        const response = await fetch(file.url)
        if (!response.body) {
          throw new Error('ReadableStream not supported in this environment')
        }

        const reader = response.body.getReader()
        const decoder = new TextDecoder('utf-8')
        let receivedLength = 0
        let chunks = []
        const maxLength = 100000

        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }

          chunks.push(value)
          receivedLength += value.length

          if (receivedLength > maxLength) {
            this.fileErrorText = 'File too large to display'
            console.log('Error:', this.fileErrorText) // Debugging statement
            return
          }
        }

        // If the file is not too large, download the entire file content
        const response2 = await fetch(file.url)
        const responseText = await response2.text()
        this.csvContent = this.csvToHtml(responseText)
      } catch (error) {
        console.error('Error loading CSV:', error)
        this.fileErrorText = 'Error loading CSV file'
        this.selectedFile = {
          name: '',
          url: '',
          type: ''
        }
      }
    },

    csvToHtml(csv) {
      const rows = csv.split('\n')
      let html = '<table>'
      rows.forEach((row, index) => {
        const cells = row.split(',')
        html += '<tr>'
        cells.forEach(cell => {
          html += index === 0 ? `<th>${cell}</th>` : `<td>${cell}</td>`
        })
        html += '</tr>'
      })
      html += '</table>'
      return html
    },

    getAllFiles() {
      this.updatedViewerConfig.forEach(config => {
        this.get_uploaded_files(config)
      })
    }
  },

  mounted() {
    // allow delete if admin and can delete not set OR canDelete value, if canDelete is set than use bool set val
    this.allowDelete = (this.canDelete === null && this.isAdmin) || this.canDelete

    if (this.readonly) {
      this.setDropzones()
    }

    this.updatedItemId = this.itemId

    this.updatedViewerConfig.forEach(config => {
      this.get_uploaded_files(config)
    })
  },

  updated() {
    // doing this here to ensure that template has updated dropzone dynamic ids
    this.updatedViewerConfig.forEach(config => {
      if (config.dzId && !this.dropzoneSet) {
        //(config)
        this.setDropzones()
      }
    })
  },

  watch: {
    existingFiles(files) {
      this.hasViewable = false
      var viewTypes = ['png', 'jpg', 'jpeg', 'gif']
      for (var i = files.length - 1; i >= 0; i--) {
        if (files[i]['type'] && viewTypes.indexOf(files[i]['type']) !== -1) {
          this.hasViewable = true
          break
        }
      }
    },
    showFileUpload(val) {
      if (val) {
        setTimeout(() => {
          this.setDropzones()
        }, 300)
      }
    },

    viewerConfig: {
      handler(value) {
        this.updatedViewerConfig = this.viewerConfig.map(value => {
          return {
            ...value,
            dzId: 'dz_' + this.randomCharacterString(10),
            fileName: value.path + '/' + value.subdir
          }
        })
        setTimeout(() => {
          this.setDropzones()
        }, 300)
      },
      immediate: true,
      deep: true
    },

    path: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.updatedViewerConfig.forEach(config => {
            console.log('path changed', config.path, config.subdir)
            this.get_uploaded_files(config)
          })
        }
      },
      immediate: false
    }
  },

  beforeDestroy() {
    this.updatedViewerConfig.map(config => {
      if (this.dzObj[config.dzId] && typeof this.dzObj[config.dzId] === 'object') {
        this.dzObj[config.dzId].destroy()
      }
    })
    this.selectedFile = {
      name: '',
      url: '',
      type: ''
    }
  }
}
</script>

<style lang="scss">
.dz-message {
  margin: 20px 10px !important;
  font-size: 12px;
  text-align: center;
}

.dz-progress {
  display: none !important;
}

.dz-error-mark g {
  fill: red !important;
}

.delete-icon {
  font-size: 1.2rem;
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
#download-form {
  display: none;
}

.file-upload-container {
  padding: 0px 0 0;
  position: relative;
  // min-height: 60px;
  z-index: 1;

  h4 {
    font-size: 16px;
    margin: 5px;
  }

  .file-upload-inner {
    padding: 0px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin: 5px 0 0;
  }

  ul {
    padding: 0;
    list-style-position: inside;
    margin: 0 0 5px;
    display: block;

    li {
      display: block;
      position: relative;

      .file-delete {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        right: -8px;
        top: -6px;
        text-align: center;
        vertical-align: middle;
        line-height: 12px;
        cursor: pointer;
        background: #ff4343;
        display: none;

        &:hover {
          display: block;
        }

        i {
          font-size: 7px;
          color: #fff;
        }
      }

      &:hover {
        border-color: #555;

        .file-delete {
          display: block;
        }

        a {
          color: #333;
        }
      }
    }
  }

  .label-link {
    float: right;
    font-size: 14px;
    margin-top: 4px;
    margin: 0 5px 0 10px;
  }

  a {
    cursor: pointer;
    color: #555;
  }

  .no-files-text {
    font-size: 12px;
  }
}

.dropzone {
  border: 2px dashed #ababab;
  min-height: 70px;
  border-radius: 5px;
  background: transparent;
  padding: 5px;
  margin-bottom: 5px;
}

.area-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.display-as-button {
  margin: 10px 0 !important;

  > div,
  .dz-preview {
    display: none !important;
    visibility: hidden !important;
    margin: 10px 0 !important;
  }

  &.dz-drag-hover {
    border: 2px dashed #ababab !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    display: block;
    border-radius: 5px;
  }
}
</style>

<style lang="scss">
.display-as-button {
  margin: 10px 0 !important;

  .dz-preview {
    display: none !important;
    visibility: hidden !important;
    margin: 10px 0 !important;
  }
}

.file-viewer {
  // margin-top: 30px;
  min-height: 300px;
  position: relative;

  .empty-file-container {
    height: 100%;
    border: 2px dashed #ababab !important;
    border-radius: 5px;
    min-height: 300px;
  }

  .no-file-message {
    text-align: center;
    top: 50%;
    margin-top: -10px;
    position: absolute;
    width: 100%;
  }
  img {
    width: 100%;
  }
  table {
    margin: 20px 0;
    border: 1px solid;
    border-bottom: 0;
    text-align: left;
    tr {
      border-top: 1px solid;
    }
    td,
    th {
      padding: 2px 5px;
    }
  }
}

.file-viewer-links {
  margin-top: 20px;
  span {
    display: inline-block;
  }
}
</style>
