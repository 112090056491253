
<div class="wo-info">
  <div v-if="!jobError">
    <div class="tab-section outline white">
      <ul class="nav nav-tabs">
        <li role="presentation" :class="[{ active: tab == 'Scope of Work' }, { 'requires-item': missingSignoffRequirements.indexOf('Scope of Work Notes') !== -1 }]">
          <a href="#" @click="tab = 'Scope of Work'"> Scope of Work </a>
        </li>
        <!-- <li role="presentation" :class="[{active: tab == 'Scope Notes'}]">
          <a href="#" @click="tab = 'Scope Notes'">
            Scope Notes
          </a>
        </li> -->
        <li role="presentation" :class="[{ active: tab == 'Quote Info' }]">
          <a href="#" @click="tab = 'Quote Info'"> Quote Info </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'Internal Notes' }]">
          <a href="#" @click="tab = 'Internal Notes'"> Internal Notes </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'Customer Info' }]">
          <a href="#" @click="tab = 'Customer Info'"> Customer Info </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'Time Entry Notes' }]">
          <a href="#" @click="tab = 'Time Entry Notes'"> Time Entry Notes </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'Work Completed' }, { 'requires-item': missingSignoffRequirements.indexOf('Work Completed Notes') !== -1 }]">
          <a href="#" @click="tab = 'Work Completed'"> Work Completed </a>
        </li>
        <li role="presentation" :class="[{ active: tab == 'Recommendations' }]">
          <a href="#" @click="tab = 'Recommendations'"> Recommendations </a>
        </li>
      </ul>

      <div :class="[{ 'first-active': tab == 'Scope of Work' }, 'tab', tab, 'tab-content']">
        <div v-show="tab === 'Scope of Work'">
          <item-comments
            :table="'JobDescriptionNotes'"
            :item-id="jobId"
            :label-text="''"
            :spectrum-single-note="false"
            :post-spectrum-name="'WORK DESCRIPTION'"
            :post-spectrum-type="'job'"
            :read-only="readOnly"
            @updated="$bus.$emit('checkJobSignoffRequirements')"
          />
        </div>
        <!--  <div v-show="tab === 'Scope Notes'">
           <item-comments
             :table="'JobOtherDetailsNotes'"
             :item-id="jobId"
             :label-text="''"
             :spectrum-single-note="false"
             :post-spectrum-name="'OTHER DETAILS'"
             :post-spectrum-type="'job'"
             :read-only="readOnly"
           />
         </div> -->
        <div v-show="tab === 'Quote Info'">
          <QuoteInfo :labor-entries="laborEntries" />
        </div>
        <div v-show="tab === 'Internal Notes'">
          <h5>
            <strong><i>Internal notes aren't shown to customer.</i></strong>
          </h5>
          <item-comments
            :table="'JobInternalNotes'"
            :item-id="jobId"
            :label-text="''"
            :spectrum-single-note="false"
            :post-spectrum-name="'Internal (SES Only)'"
            :post-spectrum-type="'job'"
            :read-only="readOnly"
          />
        </div>
        <div v-show="tab === 'Customer Info'">
          <item-comments
            label-text="Warnings"
            :table="'CustomerWarnings'"
            :item-id="customer_id"
            v-if="customer_id != ''"
            :show-extra-id-only="false"
            itm-type="Job"
            :single-note="true"
            :read-only="true"
          />
        </div>
        <div v-show="tab === 'Time Entry Notes'">
          <label>Notes</label>
          <div v-if="timeCardEntries.length && timeCardEntries[0].message" class="note-container">
            <ul :class="['existing-notes', 'show-scrollbar']">
              <li v-for="(note, id) in timeCardEntries" :key="id">
                <div v-if="note.message">
                  <p class="note-meta">
                    <span class="note-date" v-if="note.entry_date" v-html="note.entry_date" /> -
                    <span class="note-author" v-if="note.employee_name" v-html="note.employee_name" />
                  </p>
                  <p class="note-text" v-html="note.message" />
                </div>
              </li>
            </ul>
          </div>
          <p v-else>(Empty)</p>
        </div>
        <div v-show="tab === 'Work Completed'">
          <item-comments
            :table="'JobCompletedNotes'"
            :item-id="jobId"
            itm-type="Job"
            :label-text="''"
            :spectrum-single-note="false"
            :post-spectrum-name="'WORK COMPLETED'"
            :post-spectrum-type="'job'"
            :read-only="readOnly"
            @updated="$bus.$emit('checkJobSignoffRequirements')"
          />
        </div>
        <div v-show="tab === 'Recommendations'">
          <item-comments
            :table="'JobRecommendationsNotes'"
            :item-id="jobId"
            :label-text="''"
            :spectrum-single-note="false"
            :post-spectrum-name="'RECOMMENDATIONS'"
            :post-spectrum-type="'job'"
            :read-only="readOnly"
          />
        </div>
      </div>
    </div>
    <br />
    <button v-if="inSequence" class="btn" @click="next" v-html="'Next'" />
  </div>
  <p v-else>Problem loading WO Data, please close and continue from WO Details pages.</p>
</div>
