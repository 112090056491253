<template>
  <div class="sh-widget card">
    <div class="content">
      <h4>WOs Waiting for Signature</h4>
      <table @click="gotoTable">
        <tr>
          <th>Age (Days)</th>
          <th>WOs</th>
        </tr>
        <tr>
          <td>0 - 3</td>
          <td :class="[{ green: woAge1 }]">
            {{ woAge1 }}
          </td>
        </tr>
        <tr>
          <td>4 - 5</td>
          <td :class="[{ yellow: woAge2 }]">
            {{ woAge2 }}
          </td>
        </tr>
        <tr>
          <td>5 +</td>
          <td :class="[{ red: woAge3 }]">
            {{ woAge3 }}
          </td>
        </tr>
      </table>
      <waiting-spinner :contained="true" />
    </div>
  </div>
</template>

<script>
import store from 'store'
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import moment from 'moment'
import dateFormat from 'dateformat'

export default {
  components: { WaitingSpinner },
  data() {
    return {
      eid: store.getters.sherAuth.eid
    }
  },

  computed: {
    ...mapGetters(['woAssemble']),

    myWOs() {
      let openStatuses = ['A']

      let myWOs = this.woAssemble.filter(itm => {
        if (itm.PM == this.eid || itm.lead == this.eid || itm.tech_main == this.eid) {
          return true
        }

        if (itm.techs) {
          for (var i = itm.techs.length - 1; i >= 0; i--) {
            return itm.techs[i] == this.eid
          }
        }
      })

      for (var i = myWOs.length - 1; i >= 0; i--) {
        let timestamp = myWOs[i].signature_waiting
        if (timestamp) {
          // console.log(myWOs[i].WO_Number + ': ' + moment(timestamp, 'X').format('MMM Do, LT') );
        }
      }

      return myWOs
    },

    woAge1() {
      let age = this.myWOs.filter(itm => {
        if (itm.wo_signature_status === 'yes') {
          return
        }
        let date = itm.signature_waiting
        if (date) {
          let diff = moment().format('X') - date
          let days = this.getDays(diff)
          return days <= 3
        }
      })
      return age.length
    },

    woAge2() {
      let age = this.myWOs.filter(itm => {
        if (itm.wo_signature_status === 'yes') {
          return
        }
        let date = itm.signature_waiting
        if (date) {
          let diff = moment().format('X') - date
          let days = this.getDays(diff)
          return days >= 4 && days <= 5
        }
      })
      return age.length
    },

    woAge3() {
      let age = this.myWOs.filter(itm => {
        if (itm.wo_signature_status === 'yes') {
          return
        }
        let date = itm.signature_waiting
        if (date) {
          let diff = moment().format('X') - date
          let days = this.getDays(diff)
          return days >= 5
        }
      })
      return age.length
    }
  },

  methods: {
    getDays(seconds) {
      return Math.floor(seconds / (3600 * 24))
    },

    gotoTable() {
      this.$router.push({ path: 'app/wos?filterAll=Need%20WO' })
    }
  },

  mounted() {
    this.$bus.$emit('setWaiting', { name: 'getWoAssemble', message: 'Getting WO Assemble' })
    this.$store.dispatch('getWoAssemble').then(() => {
      this.$bus.$emit('stopWaiting', 'getWoAssemble')
    })
  }
}
</script>

<style lang="scss" scoped>
// @import "src/assets/sass/paper/_variables.scss";

table {
  cursor: pointer;
}
</style>
