<template>
  <div class="sh-widget wide-large card my-wos-widget">
    <div class="content">
      <h3>
        My Active Work Orders ({{ count }})
        <router-link :to="{ name: 'Work Orders' }" class="ti-new-window is-link" tag="i" />
      </h3>

      <div class="content-inner show-scrollbars">
        <bh-table :table-settings="tableSettings" :style="{ width: '630px' }" @colClick="goToItem" />
        <waiting-spinner :contained="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import woFuncs from 'mixins/WOFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import BhTable from '@/components/UIComponents/BhTable'

export default {
  data() {
    return {
      data: []
    }
  },

  mixins: [woFuncs],

  components: {
    BhTable,
    WaitingSpinner
  },

  computed: {
    ...mapGetters(['woAssemble', 'userAuthedData']),

    isPM() {
      return this.userAuthedData.user_role.indexOf('pm') !== -1
    },

    count() {
      return this.filtered && this.filtered.length ? this.filtered.length : 0
    },

    filtered() {
      let wos = JSON.parse(JSON.stringify(this.data))
      let filteredWOs = []

      for (let i = 0; i < wos.length; i++) {
        let code = parseInt(wos[i].Priority_Code)
        if (code === 1 || code === 2) {
          if (!wos[i].Customer_PO_Number) {
            wos[i].class = 'no-po'
          }
          filteredWOs.push(wos[i])
        }

        if (!wos[i].Customer_PO_Number) {
          wos[i].Customer_PO_Number = '<span class="has-link text-danger underlined">Add</span>'
        }
      }
      return filteredWOs
    },

    tableSettings() {
      return {
        tableData: this.filtered,
        fields: {
          WO_Number: {
            name: 'WO #',
            class: 'bold narrower'
          },
          Bill_Customer_Code: {
            name: 'Customer',
            class: 'narrow'
          },
          Summary_Description: {
            name: 'Description'
          },
          Priority_Description: {
            name: 'Stage',
            noFilter: 1
          },
          Customer_PO_Number: {
            name: 'PO Number',
            noFilter: 1
          }
        },
        hideAllSearch: true,
        hideTip: true,
        tableLayout: 'auto'
      }
    }
  },

  methods: {
    goToItem(obj) {
      const id = obj.itm.WO_Number
      if (this.isPM && obj.col == 'Customer_PO_Number') {
        const obj2 = Object.assign({}, obj.itm)
        obj2.po = obj2.Customer_PO_Number = ''
        this.editPONumber(obj2)
      } else {
        this.$router.push('/app/wo-details/' + id)
      }
    },

    listenPOEdit(obj) {
      if (!obj.WO_Number || !obj.po) return
      for (var i = 0; i < this.data.length; i++) {
        if (this.data[i].WO_Number == obj.WO_Number) {
          this.data[i].Customer_PO_Number = obj.po
          break
        }
      }
    },

    editPONumber(obj) {
      this.$bus.$emit('modalOpen', {
        title: 'Edit PO Number',
        classes: 'gray-bg',
        name: 'JobWOPOEdit',
        title: '',
        size: 'modal-md',
        component: () => import('src/components/Dashboard/JobWo/JobWoPoEdit.vue'),
        componentData: {
          obj
        }
      })
    }
  },

  mounted() {
    this.$bus.$on('JobWOPOEdit', this.listenPOEdit)
  },

  watch: {
    myWOs: {
      handler() {
        this.data = [...this.myWOs]
      },
      immediate: true
    }
  },

  beforeDestroy() {
    this.$bus.$off('JobWOPOEdit', this.listenPOEdit)
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.sh-widget {
  width: 700px;
  max-width: 100%;
  max-height: 370px;
  overflow: hidden;

  @media (min-width: 900px) {
    height: 370px;
  }
}

h3 {
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;

  i {
    color: $primary-color;
    float: right;
  }
}

.content-inner {
  overflow: scroll !important;
  max-height: 310px;
}
</style>

<style lang="scss">
@import 'src/assets/sass/paper/_variables.scss';

.my-wos-widget .no-po .Customer_PO_Number {
  // background: $input-red;
}
</style>
