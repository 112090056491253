<template>
  <div id="alert-main" class="alert-main">
    <div v-if="alerts.show" class="card">
      <div :class="classes" role="alert">
        <span v-html="alerts.message"/>
        <button v-if="!alerts.dismiss || alerts.dismiss === 'manual'" type="button" class="close" @click="closeAlert('x')" aria-label="Close">
          <i class="ti-close"/>
        </button>
      </div>
    </div>
  </div>
</template>
<script>

import store from 'store';
import {mapGetters} from 'vuex';
import appFuncs from 'appFuncs';

export default {
  data() {
    return {
      compAlert: {}
    };
  },

  props: {
    alert: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },

  computed: {
    alerts() {
      if (this.compAlert.show) {
        return this.compAlert;
      }
      return store.getters.alerts;
    },

    classes() {
      var classes = [
        'alert',
      ];

      if (this.alerts.state === 'danger') {
        classes.push('alert-danger');
      } else if (this.alerts.state === 'success') {
        classes.push('alert-success');
      } else if (this.alerts.state === 'warning') {
        classes.push('alert-warning');
      }
      return classes.join(' ');
    }

  },

  methods: {
    closeAlert(x) {
      const alert = {
        show: 0,
        message: '',
        state: '',
        dismiss: ''
      };
      if (x) { // allow closing x to close component alert
        this.compAlert = alert;
      }
      store.commit('alerts', alert);
    },
  },

  updated() {
    if (this.alerts.show) {
      appFuncs.scrollTop();
    }
  },

  watch: {
    alerts() {
      if (this.alerts.dismiss === 'auto') {
        setTimeout(() => {
          this.closeAlert();
        }, 4000);
      }
    },
    alert(obj) {
      if (obj.dismiss === 'auto') {
        setTimeout(() => {
          this.closeAlert('x');
        }, 4000);
      }
      this.compAlert = obj;
    }
  },
}

</script>

<style lang="scss">

.card .close {
  font-size: 14px;
  line-height: 21px;
}

.alert {
  a {
    color: #555 !important;
    text-decoration: underline !important;
  }
}


</style>
