<template>
  <div class="max-width-large">
    <br />
    <button class="btn" @click="addItem">Add Subcontractor</button>
    <br />
    <br />

    <alert />
    <bh-table :table-settings="tableSettings" @edit-item="editItem" />

    <waiting-spinner :wait="wait" />
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'
import BhTable from 'components/UIComponents/BhTable'
import Alert from 'components/UIComponents/Alert'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  components: {
    BhTable,
    Alert,
    WaitingSpinner
  },

  data() {
    return {
      wait: {
        message: ''
      },
      urls: store.getters.urls,
      formVals: {
        quote: ''
      },
      tableSettings: {
        tableData: [],
        fields: {
          Code: {
            type: 'text',
            hiddenView: 1
          },
          Name: {
            name: 'Name',
            required: true
          },
          Company: {
            name: 'Company',
            required: true
          },
          'Phone Number': {
            name: 'Mobile Number',
            use: 'phone'
          },
          'Direct Line': {
            use: 'phone'
          },
          'Email Address': {
            name: 'Email Address'
          },
          Position: {}
          // 'eCompliance': {},
          // 'Status': {}
        }
      },
      dbTable: 'SubcontactorList'
    }
  },

  methods: {
    getData(refresh) {
      this.wait.message = 'Getting Subcontractor data'
      appFuncs.getTableData(
        this.dbTable,
        result => {
          this.wait.message = ''
          if (result.data) {
            this.tableSettings.tableData = result.data.sort(function (a, b) {
              if (!a.Name || !b.Name) return
              if (a.Name.trim() < b.Name.trim()) return -1
              if (a.Name.trim() > b.Name.trim()) return 1
              return 0
            })
          } else {
            console.log(result)
          }
        },
        null,
        refresh
      )
    },

    editItem(id) {
      this.$Modal({
        parent: this,
        name: 'bh-table-add-edit', // used for closing specific modal programmatically
        title: 'Edit SubContractor',
        size: 'modal-xl', // sm, md, lg, xl
        component: () => import('src/components/Dashboard/Jobs/BhTableAddEdit.vue'),
        props: {
          componentData: {
            fields: this.tableSettings.fields,
            itemType: 'Employee',
            dbTable: this.dbTable,
            editId: id
          }
        }
      })
    },

    addItem() {
      this.$Modal({
        parent: this,
        name: 'bh-table-add-edit', // used for closing specific modal programmatically
        title: 'Add SubContractor',
        size: 'modal-xl', // sm, md, lg, xl
        component: () => import('src/components/Dashboard/Jobs/BhTableAddEdit.vue'),
        props: {
          componentData: {
            fields: this.tableSettings.fields,
            itemType: 'Employee',
            dbTable: this.dbTable
          }
        }
      })
    }
  },

  mounted() {
    this.getData()

    this.$bus.$on('bhUpdate', () => {
      this.getData('refresh')
    })
  }
}
</script>

<style lang="scss" scoped></style>
