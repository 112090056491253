import Vue from 'vue'
import store from 'store'
import router from './router'

import EventBus from 'eventbus'

import sanitizeHtml from 'sanitize-html'

import moment from 'moment'

import snack from './components/UIComponents/Snackbar/'

import $ from 'jquery'

let woNotificationsTriggered = false

const appFuncs = {
  triggerModalFunction(context, buttonFunc, buttonParams) {
    var self = this
    var modalData = store.getters.modal

    if (buttonFunc) {
      if (typeof context[buttonFunc] === 'function') {
        context[buttonFunc](buttonParams)
      } else if (context === 'appFuncs') {
        console.log('call appfuncs function')
        self[buttonFunc](buttonParams)
      }
    }
  },

  get_authed_userdata(callback) {
    if (!store.getters.sherAuth) {
      return
    }
    // ajax_request updates authedUserData incl jwt
    this.ajax_request(store.getters.sherAuth, { action: 'check_refresh_jwt' }, result => {
      if (result.status === 'success') {
        if (result.nice_date) {
          store.commit('appDate', result.nice_date)
        }
      }
      if (typeof callback === 'function') {
        callback(result)
      }
    })
  },

  async ajax_request(auth, data, callback, authRefresh, url) {
    var self = this

    let path = ((router.app || {})._route || {}).fullPath || null

    // adding the auth separately to help make sure it gets updated
    data = data || {}
    data = data || null
    // not using url param anymore - force to be current server
    url = store.getters.urls.backend + 'ajax.php'
    data.sher_auth = auth
    data.auth_refresh = authRefresh // option to disable auth refresh for use by non-interactive functions - not currently setup in backend

    if (!data.sher_auth) {
      await store.dispatch('setSherAuth')
      data.sher_auth = store.state.sherAuth
      if (!data.sher_auth) {
        console.log('WARNING - no auth data in request')
        return
      }
    }

    $.ajax({
      url: url,
      type: 'POST',
      data: $.param(data),
      dataType: 'json',
      success: function (result) {
        // clear any alerts before any new alerts may be shown - added to clear inactivity login expiry
        store.commit('alerts', { show: 0 })

        const savedAuth = self.storageGet('sherAuth')
        const savedJWT = savedAuth.jwt

        // update sherAuth secure login data
        if (result && result.jwt_data && result.jwt_data.status === 'success') {
          var authData = store.getters.sherAuth || {}
          authData.jwt = result.jwt_data.jwt || savedJWT
          authData.expires = result.jwt_data.expires // only really used for showing logout warnings, actual expiry based on JWT val
          authData.user_login = result.jwt_data.user_login
          authData.authenticating = false
          authData.appVersion = result.jwt_data.appVersion
          store.dispatch('setSherAuth', authData)
        }

        // update authedUserData
        if (result && result.jwt_data && result.jwt_data.data && !data.skipAuthSet) {
          // add this skipAuthSet param to avoid jump issues caused in some functionality
          // something triggered as a result of commit('userAuthedData') is triggering page jumps ie JobWOLabor table click, tbd
          store.dispatch('setUserAuthedData', {
            user_login: result.jwt_data.data.user_login,
            eid: result.jwt_data.data.eid,
            fname: result.jwt_data.data.fname,
            mname: result.jwt_data.data.mname,
            lname: result.jwt_data.data.lname,
            user_role: result.jwt_data.data.user_role,
            employee_role: result.jwt_data.data.employee_role,
            appVersion: result.jwt_data.appVersion
          })
        }

        if (typeof callback === 'function') {
          callback(result)
        }

        if (result && typeof result !== 'string') {
          // prep items for error data
          // delete result.jwt;
          // if(result.jwt_data){
          //   delete result.jwt_data.jwt;
          // }
          var requestData = data
          // delete requestData.sher_auth
          result.requestData = requestData
        }

        if (!result || (result.status && result.status === 'fail')) {
          if (data.action && data.action !== 'check_refresh_jwt') {
            const Bugsnag = Vue.prototype.$Bugsnag
            if (typeof Bugsnag !== 'undefined') {
              Bugsnag.metaData = {
                requestData: requestData,
                responseData: result
              }
              Bugsnag.notify(new Error('Ajax_request bad resp: ' + data.action))
            }
          }

          // trigger global alert if auth fail
          const requiresAuth = (((((router || {}).currentRoute || {}).matched || {})[0] || {}).meta || {}).requiresAuth || false

          if (requiresAuth && result && result.auth_fail) {
            let message = result && result.message ? result.message : 'There has been a problem with your request, please refresh the page or log in again to continue. 2332'
            snack.open(
              message,
              'error',
              () => {
                snack.removeAll()
                router.push({ path: '/login' })
              },
              'Log In',
              100 // priority
            )
            store.dispatch('clearLogin')
          }
        }
      },
      error: function (e, status) {
        console.log(e)
        console.log('ajax_request error')

        if (typeof callback === 'function') {
          var result = { status: 'fail', message: e.statusText }
          callback({ err: e.statusText })
        }

        console.log('ajax_request ' + data.action + ' error status:')
        console.log(status)
        console.log(e)

        var requestData = data
        delete requestData.sher_auth

        const Bugsnag = Vue.prototype.$Bugsnag
        if (typeof Bugsnag !== 'undefined') {
          Bugsnag.metaData = {
            requestData: requestData,
            result: e
          }
          Bugsnag.notify(new Error('Ajax_request error: ' + data.action))
        }

        console.log('ajax error cb')
        let message = 'There has been a problem with your request, please refresh the page or log in again to continue. 2332'
        snack.open(message, 'error')
      }
    })
  },

  async shRequest(data, server, authRefresh) {
    let auth = store.state.sherAuth
    return new Promise((resolve, reject) => {
      this.ajax_request(
        auth,
        data,
        res => {
          if (res && res.status === 'success') {
            resolve(res.data)
          } else {
            reject(res)
          }
        },
        authRefresh
      )
    })
  },

  // latest wrapper for simpliying app ajax requests
  shHttp(data, authRefresh) {
    return new Promise((resolve, reject) => {
      this.ajax_request(
        store.getters.sherAuth,
        data,
        res => {
          if (!res || res.err) {
            reject(res)
          } else {
            resolve(res)
          }
        },
        authRefresh
      )
    })
  },

  stdFetchStore(action, waitMessage, callback, expireMins, server, noFailAlert, data) {
    if (expireMins !== 0) {
      let saved = this.storageGet(action)
      if (saved) {
        if (typeof callback === 'function') {
          callback({ status: 'success', data: saved })
        }
        return
      }
    }

    if (waitMessage !== 'none') {
      EventBus.$emit('setWaiting', {
        name: action,
        message: waitMessage || 'Fetching data'
      })
    }

    if (data) {
      data.action = action
    } else {
      data = { action }
    }

    this.ajax_request(
      store.getters.sherAuth,
      data,
      result => {
        EventBus.$emit('stopWaiting', action)
        if (result.status === 'success') {
          // only save data if has meaningful result
          if (result.data) {
            let length = result.data.length || Object.keys(result.data).length
            if (length) {
              expireMins = expireMins || 5
              this.storageSave(action, result.data, expireMins)
            }
          }
        } else if (!noFailAlert) {
          snack.open(result.message || 'There has been a problem with your request.', 'error')
        }
        if (typeof callback === 'function') {
          callback(result)
        }
      },
      1
    )
  },

  shFetch(params) {
    /*

    - standardized function for fetching data and putting in store, store according to params sent and return if already fetching
    - the idea with this is to use for updating nested data in store properties then use map in components to computed data where updates can be made
    - this was abandoned for taking too much time to sort out complexities with reading dynamic nested props in components where used

    */

    console.log('shFetch')

    // create string of parameters for nesting fetched data
    let paramStr = ''
    let obj = params.params
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        paramStr += '_' + prop + '_' + obj[prop]
      }
    }

    let expiry = params.expiry || 5
    let storeName = params.name
    let storeData = store.getters[storeName]

    if (storeData[paramStr]) {
      // return empty if already fetching
      if (storeData[paramStr].fetching) {
        console.log('returned fetching')
        return {}
      }
      // return data if already exists
      if (storeData[paramStr].expiry && storeData[paramStr].expiry > new Date().getTime()) {
        console.log(storeData[paramStr].data)
        return storeData[paramStr].data
      }
    }

    // get existing store data, save fetching status to existing call
    let existing = store.getters[storeName]
    existing = Object.keys(existing).length ? existing : {}
    existing[paramStr] = { fetching: 1 }
    store.commit(storeName, existing)

    let data = params.params
    data.action = params.action

    EventBus.$emit('setWaiting', {
      name: data.action,
      message: 'Fetching Quote List'
    })

    appFuncs.ajax_request(store.getters.sherAuth, data, res => {
      EventBus.$emit('stopWaiting', data.action)
      if (res.status === 'success') {
        let expiryTime = new Date().getTime() + 1000 * 60 * parseInt(expiry)
        let storeData = { [paramStr]: { data: res.data, expiry: expiryTime } }
        existing[paramStr] = { data: res.data, expiry: expiryTime }
        store.commit(storeName, existing)
      } else {
        console.log(res)
      }
    })
  },

  // getJobInfo(callback, waitMessage, refresh){
  //   console.log('get job info: ' + refresh);
  //   waitMessage = waitMessage || 'Fetching Job Info';
  //   this.stdFetchStore('get_job_simple_details', waitMessage, (result)=>{
  //     if(typeof callback === 'function'){
  //       callback(result);
  //     }
  //   }, refresh ? 0 : null);
  // },

  getWOInfo(callback, waitMessage) {
    // this is deprecated, converting to store here for now
    EventBus.$emit('setWaiting', { name: 'getWOInfo', message: 'Getting WO Data' })
    store
      .dispatch('getWoAssemble')
      .then(() => {
        if (typeof callback === 'function') {
          callback({ status: 'success', data: store.getters.woAssemble })
        }
      })
      .catch(() => {
        if (typeof callback === 'function') {
          callback({ status: 'fail', message: 'Problem getting WO Data' })
        }
      })
      .finally(() => {
        EventBus.$emit('stopWaiting', 'getWOInfo')
      })

    // waitMessage = waitMessage || 'Fetching Work Order data';
    // this.stdFetchStore('get_wo_all_summary_details', waitMessage, (result)=>{
    //   if(typeof callback === 'function'){
    //     callback(result);
    //   }
    // }, null, 1);
  },

  getCustomers(callback, refresh) {
    this.stdFetchStore(
      'get_customers',
      'Fetching Customer Data',
      result => {
        if (typeof callback === 'function') {
          callback(result)
        }
      },
      refresh
    )
  },

  getEmployeeData(callback) {
    this.stdFetchStore('get_employee_data', 'Fetching Employee Data', result => {
      if (typeof callback === 'function') {
        callback(result)
      }
    })
  },

  getJobInfo(callback, refresh) {
    this.stdFetchStore(
      'get_job_info',
      'Fetching Job Info',
      result => {
        if (typeof callback === 'function') {
          callback(result)
        }
      },
      refresh ? 0 : null
    )
  },

  getUsersByRole(role, callback) {
    this.stdFetchStore(
      'get_users_by_role',
      'Fetching Users',
      result => {
        if (typeof callback === 'function') {
          callback(result)
        }
      },
      null,
      null,
      null,
      { role }
    )
  },

  updateNote(note_id, content, job_id, note_type, category, callback) {
    store.commit('waiting', { status: 1, message: 'Updating Job Note' })

    var data = {
      action: 'job_note_update',
      note_id: note_id,
      content: content,
      job_id: job_id,
      note_type: note_type,
      category: category
    }

    this.ajax_request(store.getters.sherAuth, data, result => {
      if (typeof callback == 'function') {
        callback(result)
      }
      store.commit('waiting', { status: 0 })
    })
  },

  deleteNote(note_id, job_id, note_type, category, callback) {
    var data = {
      action: 'job_note_delete',
      note_id: note_id,
      job_id: job_id,
      note_type: note_type,
      category: category
    }

    store.commit('waiting', { status: 1, message: 'Deleting Job Note' })
    this.ajax_request(store.getters.sherAuth, data, result => {
      if (typeof callback == 'function') {
        callback(result)
      }
      store.commit('waiting', { status: 0 })
    })
  },

  getTableData(table, callback, id, refresh) {
    var data = {
      action: 'get_table_data',
      table: table,
      id: id
    }

    let actionId = data.action + '_' + table

    let saved = this.storageGet(actionId)
    if (saved && !refresh) {
      if (typeof callback === 'function') {
        callback({ status: 'success', data: saved })
      }
      return
    }

    this.ajax_request(store.getters.sherAuth, data, result => {
      if (result.status === 'success') {
        EventBus.$emit('stopWaiting', data.action)
        this.storageSave(actionId, result.data)
      }
      if (typeof callback == 'function') {
        callback(result)
      }
    })
  },

  addTableData(table, data, callback) {
    var data = {
      action: 'add_table_data',
      table: table,
      data: JSON.stringify(data)
    }

    store.commit('waiting', { status: 1, message: 'Adding data' })
    this.ajax_request(store.getters.sherAuth, data, result => {
      if (typeof callback == 'function') {
        callback(result)
      }
      store.commit('waiting', { status: 0 })
    })
  },

  updateTableData(table, rowId, data, callback) {
    var data = {
      action: 'update_table_data',
      sher_auth: store.getters.sherAuth,
      table: table,
      row_id: rowId,
      data: data
    }

    store.commit('waiting', { status: 1, message: 'Updating data' })
    this.ajax_request(store.getters.sherAuth, data, result => {
      if (result.status === 'success') {
        this.storageClear('get_table_data_' + table)
      }
      if (typeof callback == 'function') {
        callback(result)
      }
      store.commit('waiting', { status: 0 })
    })
  },

  deleteTableData(table, rowId, callback) {
    var data = {
      action: 'delete_table_data',
      sher_auth: store.getters.sherAuth,
      table: table,
      row_id: rowId
    }

    store.commit('waiting', { status: 1, message: 'Deleting data' })
    this.ajax_request(store.getters.sherAuth, data, result => {
      if (typeof callback === 'function') {
        callback(result)
      }
      store.commit('waiting', { status: 0 })
    })
  },

  addNewQuote(data, callback) {
    var data = {
      action: 'add_new_quote',
      data: JSON.stringify(data)
    }

    store.commit('waiting', { status: 1, message: 'Adding New Quote' })
    this.ajax_request(store.getters.sherAuth, data, result => {
      if (typeof callback === 'function') {
        callback(result)
      }
      store.commit('waiting', { status: 0 })
    })
  },

  addQuoteRevision(data, callback) {
    var data = {
      action: 'add_quote_revision',
      data
    }

    store.commit('waiting', { status: 1, message: 'Adding quote revison' })
    this.ajax_request(store.getters.sherAuth, data, result => {
      if (typeof callback === 'function') {
        callback(result)
      }
      store.commit('waiting', { status: 0 })
    })
  },

  sendWONnotifications(wo_number, notification_name, auth_code, seconds) {
    if (woNotificationsTriggered) {
      return
    }

    woNotificationsTriggered = true

    const params = {
      action: 'notify_customer_contacts_wo_update',
      auth_code,
      wo_number,
      notification_name
    }

    setTimeout(() => {
      woNotificationsTriggered = false
      this.shRequest(params, 1)
        .catch(res => {
          console.log('problem sending WO notification')
          console.log(res)
        })
        .finally(() => {
          console.log('WO notification sent')
        })
    }, seconds * 1000)
  },

  validateFields(requiredFields, formVals, state) {
    // if required field object state prop does not match the function param provided state string, do not validate that field
    snack.removeAll()
    var missingField = ''
    for (var key in requiredFields) {
      if (!requiredFields.hasOwnProperty(key)) continue
      if (!formVals[key] || formVals[key] == 0) {
        // bypass if required field state does not match provided state
        if (requiredFields[key]['state'] && requiredFields[key]['state'] !== state) {
          continue
        }

        missingField = requiredFields[key]['name'] || key
        break
      }
    }
    if (missingField) {
      snack.open(missingField + ' is required.', 'warning')
      return false
    } else {
      return true
    }
  },

  formatPhoneLink(no) {
    if (!no) {
      return
    }
    var nopl = no.toString().replace(/\.|\ |-/g, '')
    return '<a href="tel:' + nopl + '">' + nopl + '</a>'
  },

  storageSave(name, data, expireMins) {
    if (!name) return
    expireMins = expireMins || 5
    let expiry = expireMins * 60 * 1000 + new Date().getTime()
    const save = {
      data,
      expiry
    }
    try {
      localStorage.setItem(name, JSON.stringify(save))
    } catch (e) {
      console.log('storageSave failed:')
      console.log(e)
    }
  },

  storageGet(name) {
    try {
      let data = JSON.parse(localStorage.getItem(name))
      if (data && data.expiry) {
        if (data.expiry < new Date().getTime()) {
          this.storageClear(name)
          return false
        }
        return data.data
      }
      return false // must have expiry
    } catch (e) {
      console.log('storageGet fail')
    }
  },

  storageClear(name) {
    if (name === 'all') {
      localStorage.clear()
    }
    localStorage.removeItem(name)
  },

  formatNumber(num, dollar) {
    num = parseFloat(num)
    let prefix = dollar ? '$' : ''
    if (num && typeof num === 'number') {
      return prefix + num.toLocaleString('en')
    } else {
      return prefix + 0
    }
  },

  formatCurrencyNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },

  getDays(seconds) {
    return Math.floor(seconds / (3600 * 24))
  },

  getDaysMS(ms) {
    return Math.floor(ms / (3600 * 1000 * 24))
  },

  contactNameToAppId(name, contacts, asObj) {
    if (name && contacts && contacts.length) {
      //let np = name.trim().replace(/ {1,}/g, ',').split(',');
      let obj = contacts.find(itm => {
        //let itmFn = (typeof itm['Name'] === 'string') ? itm['Name'].trim() : '';
        return itm.Name == name
      })
      if (asObj) {
        return obj ? obj : {}
      } else {
        return obj ? obj.sfId : ''
      }
    }
    return ''
  },

  contactIdToName(id, contacts) {
    if (id && contacts && contacts.length) {
      let match = contacts.find(itm => {
        return itm.sfId == id
      })
      if (!match) {
        match = contacts.find(itm => {
          return itm.MYS_ID__c == id
        })
      }
      return match ? match.Name : ''
    }
    return ''
  },

  scrollTop(el) {
    if (!el) {
      el = $('.modal-multi')
      if (!el || !el.length) {
        el = $('.main-panel')
      }
    }
    //const position = $(el).offset().top;
    //$('.main-panel')[0].scrollTo(0,0);
    $(el).animate({ scrollTop: 0 }, 500)
  },

  scrollToOK(selector) {
    // Use requestAnimationFrame to ensure the scroll happens in the next frame
    requestAnimationFrame(() => {
      // Find the element right before scrolling
      const element = document.querySelector(selector)

      if (element) {
        // Calculate the element's position relative to the viewport
        const elementRect = element.getBoundingClientRect()
        const absoluteElementTop = elementRect.top + window.pageYOffset

        // Scroll to the element
        window.scrollTo({
          top: absoluteElementTop,
          behavior: 'smooth'
        })
      }
    })
  },

  scrollTo(selector, offset = 0) {
    return new Promise((resolve, reject) => {
      const scrollToPosition = () => {
        const element = document.querySelector(selector)

        if (element) {
          const elementPosition = this.getElementPosition(element)
          const scrollPosition = elementPosition - offset

          window.scrollTo({
            top: scrollPosition
            // behavior: 'smooth' // stops scroll from going where it needs
          })

          // Check if we've reached the desired position
          const checkScrollEnd = () => {
            if (Math.abs(window.pageYOffset - scrollPosition) < 2) {
              resolve()
            } else {
              requestAnimationFrame(checkScrollEnd)
            }
          }

          checkScrollEnd()
        } else {
          reject(new Error(`Element with selector "${selector}" not found.`))
        }
      }

      // Ensure the DOM is fully loaded and rendered
      if (document.readyState === 'complete') {
        scrollToPosition()
      } else {
        window.addEventListener('load', scrollToPosition)
      }
    })
  },

  getElementPosition(element) {
    const rect = element.getBoundingClientRect()
    const pos = rect.top + document.documentElement.scrollTop
    return pos
  },

  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false
      }
    }
    return JSON.stringify(obj) === JSON.stringify({})
  },

  getPlainText(text, maxLength) {
    if (typeof text !== 'string') return

    const allowedTags = [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'blockquote',
      'p',
      'a',
      'ul',
      'ol',
      'nl',
      'li',
      'b',
      'i',
      'strong',
      'em',
      'strike',
      'hr',
      'br',
      'div',
      'span',
      'table',
      'thead',
      'tbody',
      'tfoot',
      'tr',
      'th',
      'td',
      'caption',
      'colgroup',
      'col'
    ]

    text = sanitizeHtml(text, {
      allowedTags: allowedTags,
      transformTags: {
        h1: 'p',
        h2: 'p',
        h3: 'p',
        h4: 'p',
        h5: 'p'
      }
    })

    if (maxLength) {
      text = text.substring(0, maxLength) + '...'
    }
    return text
  },

  getSafeHtml(text) {
    if (typeof text !== 'string') return
    const allowedTags = [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'blockquote',
      'p',
      'a',
      'ul',
      'ol',
      'nl',
      'li',
      'b',
      'i',
      'strong',
      'em',
      'strike',
      'hr',
      'br',
      'div',
      'span',
      'table',
      'thead',
      'tbody',
      'tfoot',
      'tr',
      'th',
      'td',
      'caption',
      'colgroup',
      'col'
    ]
    return sanitizeHtml(text, {
      allowedTags,
      allowedAttributes: {
        div: ['class', 'style'],
        span: ['class', 'style'],
        p: ['class', 'style'],
        strong: ['class', 'style'],
        li: ['class', 'style', 'data-list'],
        ol: ['class', 'style'],
        ul: ['class', 'style'],
        table: ['class', 'style'],
        thead: ['class', 'style'],
        tbody: ['class', 'style'],
        tfoot: ['class', 'style'],
        tr: ['class', 'style'],
        th: ['class', 'style'],
        td: ['class', 'style']
      }
    })
  },

  daysBetweenDates(date1, date2) {
    // Parse the dates into JavaScript Date objects
    const parsedDate1 = new Date(date1)
    const parsedDate2 = new Date(date2)

    // Check for invalid dates
    if (isNaN(parsedDate1) || isNaN(parsedDate2)) {
      return ''
    }

    // Calculate the time difference in milliseconds
    const timeDiff = Math.abs(parsedDate2 - parsedDate1)

    // Convert the time difference to days
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))

    return daysDiff
  }
}

export default appFuncs
