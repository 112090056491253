<template>
  <div class="wrapper">
    <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks"/>
    <!-- <notifications>

    </notifications> -->
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import SideBar from 'components/UIComponents/SidebarPlugin/SideBar'

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    SideBar
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  },
  mounted() {
    // this.$nextTick(function () {
    //   console.log('dashboard.js $nextTick');
    // });

    this.$bus.$on('navigated', () => {
      this.$sidebar.displaySidebar(false)
    })
  }
}
</script>
