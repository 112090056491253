<template>
  <div class="po-report" id="po-report">
    <div class="container">
      <div class="header">
        <div>
          <router-link to="/">
            <img :src="urls.frontend + 'static/img/logo.jpg'" />
          </router-link>
        </div>
        <hr />
      </div>

      <section class="row" v-if="itmCode" id="pdfReady">
        <div class="col col-xs-8 doc-title">
          <h4>ATTN: ACCOUNTS RECEIVABLE DEPARTMENT</h4>
          <h4>INVOICE COPY REQUEST</h4>
        </div>
        <div class="col col-xs-4 doc-company text-right">
          <p>
            <strong><span v-html="vendor.Vendor_Name" /></strong><br />
            <span v-html="vendor.Address_1" />, <span v-html="vendor.Address_2" /><br />
            <span v-html="vendor.Address_3" />, <span v-html="vendor.State" />, <span v-html="vendor.Zip_Code" /><br />
            <span v-if="apContact.Direct_Line">Tel: <span v-html="apContact.Direct_Line" /><br /></span>
            Email: <span v-html="apContact.Email" /><br />
          </p>
        </div>
      </section>

      <section class="row">
        <div class="col-sm-12 col-md-4 doc-date">
          <strong>{{ dateToday }}</strong>
        </div>

        <div class="col-sm-12 doc-content">
          <div v-html="reportText" style="margin-bottom: 15px" />

          <p>Please send invoice copies to:</p>

          <p>
            <strong>Email:</strong> accounts_payable@sheridanelectric.ca <br /><strong>Fax:</strong> (905) 625-0265 <br /><strong>Mail:</strong> <br />&emsp;Attn: Accounts Payable <br />&emsp;Sheridan
            Electric Services <br />&emsp;966 Pantera Dr, Unit #34 <br />&emsp;Mississauga ON, L4W 2S1
          </p>

          <br />
          <p>Thank you for your assistance.</p>
        </div>
      </section>

      <section class="row">
        <div class="col-sm-12">
          <h4 class="section-title">Outstanding PO's</h4>
          <table class="wo-item-list">
            <tr>
              <td>
                <strong>Date</strong>
              </td>
              <td>
                <strong>PO Number</strong>
              </td>
              <td>
                <strong>Status</strong>
              </td>
              <td>
                <strong>Ship Address</strong>
              </td>
            </tr>
            <tr v-for="(po, pi) in pos" :key="pi">
              <td>{{ po.niceDate }}</td>
              <td>{{ po.PO_Number }}</td>
              <td>{{ po.Status_Flag }}</td>
              <td>{{ po.Full_Address }}</td>
            </tr>
          </table>
          <p v-if="!pos || !pos.length"><i>No Open PO's found</i></p>
        </div>
      </section>

      <div v-if="pdf" class="footer" style="margin-bottom: 20px">
        <div class="footer-inner">
          <div class="page-number">
            <p>Page 1 of 1</p>
          </div>
        </div>
      </div>

      <div class="row not-pdf">
        <div class="col-sm-12" style="margin-bottom: 15px">
          <hr />
          <div v-if="apContact.Email">
            <button class="btn" @click="sendReport" v-html="'Send Report'" />
            <div style="italic">
              <strong>AP Contacts:</strong>
              <span v-for="(contact, apci) in apContacts" :key="apci">
                <span v-html="contact.Email + (apContacts.length > 1 && apci + 1 !== apContacts.length ? ', ' : '')" />
              </span>
            </div>
          </div>
          <p v-else><i>No AP Contact found for this vendor</i></p>
        </div>
      </div>
    </div>

    <waiting-spinner :wait="wait" />
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import dateFormat from 'dateformat'

export default {
  components: { WaitingSpinner },
  data() {
    return {
      wait: {
        message: ''
      },
      pos: [],
      type: '',
      itmCode: '',
      debug: 'empty',
      apContacts: [],
      vendor: {},
      reportText:
        '<p>We currently have the following open PO(s) registered to your company. Please submit invoice(s) with proof of delivery if payment is required. If you have no record of a certain PO, please advise our office at the phone number listed below at your earliest convenience.</p><p>This report is intended to ensure prompt payments to our vendors for purchases made. We will not accept invoices against PO’s after we are told they are closed or already invoiced in full.</p>'
    }
  },

  props: {
    authCode: {
      type: String,
      required: true
    },
    pdf: {
      type: [String, Boolean],
      default: false
    }
  },

  computed: {
    sherAuth() {
      return this.$store.getters.sherAuth
    },

    urls() {
      return this.$store.getters.urls
    },

    dateToday() {
      return dateFormat(new Date(), 'mmm dS, yyyy')
    },

    apContact() {
      if (!this.apContacts.length) return {}
      return this.apContacts[0]
    }
  },

  methods: {
    getData() {
      if (!this.authCode) {
        return
      }

      this.debug = 'fetching data'
      this.wait.message = 'Fetching data'

      const params = {
        action: 'get_po_report_assemble',
        auth_code: this.authCode
      }

      appFuncs
        .shRequest(params, 1)
        .then((data, res) => {
          let pos = ((data || {}).pos || {}).data || []
          if (Array.isArray(pos)) {
            for (var i = pos.length - 1; i >= 0; i--) {
              if (!pos[i]) continue
              pos[i].niceDate = dateFormat(Date.parse(pos[i]['PO_Date_List1']), 'mm/dd/yyyy')
              pos[i].Full_Address = pos[i].Ship_Address_1 + ', ' + pos[i].Ship_City + ', ' + pos[i].Ship_State
            }
          }

          this.debug = data

          this.pos = pos
          this.itmCode = data.itmCode
          this.type = data.type
          this.vendor = (((data || {}).vendor || {}).data || {})[0] || {}
          this.apContacts = data.apContacts
          this.reportText = data.reportText ? data.reportText : this.reportText
        })
        .catch(res => {
          this.debug = res
          console.log(res)
        })
        .finally(() => {
          this.wait.message = ''
        })
    },

    sendReport(emailTo) {
      appFuncs.scrollTop()

      const params = {
        action: 'send_po_report',
        type: this.type,
        itmCode: this.itmCode
        //emailTo: emailTo
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Sending report' })

      appFuncs
        .shRequest(params, 1)
        .then(data => {
          if (data.emailRes.status === 'success') {
            this.$snack.open('Report sent successfully')
          } else {
            this.$snack.open(data.emailRes.message || 'Problem sending report', 'error')
          }
          appFuncs.scrollTop()
        })
        .catch(res => {
          this.$snack.open(res.message || 'Problem sending report', 'error')
          console.log(res)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    downloadPDF() {
      window.open(this.existingPDF)
    },

    getPlainText(text) {
      return appFuncs.getPlainText(text)
    },

    pdfClearModifications() {
      let footer = document.getElementsByClassName('footer')[0]
      if (!footer) {
        return
      }
      // remove all previously added content
      let addedContent = document.getElementsByClassName('added-content')
      while (addedContent[0]) {
        addedContent[0].parentNode.removeChild(addedContent[0])
      }

      let addedSpacers = document.getElementsByClassName('added-spacer')
      while (addedSpacers[0]) {
        addedSpacers[0].parentNode.removeChild(addedSpacers[0])
      }
      let addedLines = document.getElementsByClassName('measure-line')
      while (addedLines[0]) {
        addedLines[0].parentNode.removeChild(addedLines[0])
      }
      let pageNum = footer.getElementsByClassName('page-number')
      if (pageNum && pageNum[0]) {
        pageNum[0].innerHTML = '<p>Page 1 of 1<p>'
      }
    },

    pdfSpaceSections() {
      let mainEl = document.getElementById('po-report')
      let sections = Array.prototype.slice.call(document.querySelectorAll('section'))

      let header = document.getElementsByClassName('header')[0]
      let footer = document.getElementsByClassName('footer')[0]
      let footerHeight = footer.offsetHeight + parseInt(footer.style.marginBottom)

      const pageHeight = 1490
      let nextPageBottom = pageHeight
      let pageCount = 1

      sections.forEach(function (itm) {
        let top = itm.getBoundingClientRect().top + document.documentElement.scrollTop
        let itmHeight = itm.offsetHeight
        let bottom = itmHeight + top + 100
        let sectionTitle = itm.querySelectorAll('.section-title')
        let sectionHeaderHeight = 0
        if (sectionTitle && sectionTitle[0]) {
          sectionHeaderHeight = sectionTitle[0].offsetHeight
        }
        let sectionHeader = null
        if (sectionTitle && sectionTitle[0]) {
          sectionHeader = document.createElement('div')
          sectionHeader.innerHTML = sectionTitle[0].outerHTML
        }

        // if page break is between top and bottom, add enough margin to get on next page
        if (top < nextPageBottom && bottom > nextPageBottom) {
          // if section is taller than page, add break between table rows
          if (itmHeight > 500) {
            let rows = itm.getElementsByTagName('tr')
            Array.from(rows).forEach(function (rowItm) {
              let rowTop = rowItm.getBoundingClientRect().top + document.documentElement.scrollTop
              let rowHeight = rowItm.offsetHeight
              let rowBottom = rowHeight + rowTop + 100
              if (rowTop < nextPageBottom && rowBottom > nextPageBottom) {
                let marginNeeded = pageCount * pageHeight - rowTop + header.offsetHeight + sectionHeaderHeight
                let spacer = document.createElement('div')
                spacer.classList.add('added-spacer')
                spacer.style.paddingTop = marginNeeded + 'px' // use padding to prevent top of margin issue from not creating space on next page.
                rowItm.insertAdjacentHTML('beforebegin', spacer.outerHTML)
                rowItm.insertAdjacentHTML('beforebegin', sectionHeader.outerHTML)

                nextPageBottom += pageHeight
                pageCount++
              }
            })
          } else {
            let marginNeeded = pageCount * pageHeight - top + header.offsetHeight + sectionHeaderHeight
            let spacer = document.createElement('div')
            spacer.classList.add('added-spacer')
            spacer.style.paddingTop = marginNeeded + 'px' // use padding to prevent top of margin issue from not creating space on next page.
            itm.insertAdjacentHTML('beforebegin', spacer.outerHTML)
            itm.insertAdjacentHTML('beforebegin', sectionHeader.outerHTML)
            nextPageBottom += pageHeight
            pageCount++
          }
        }
      })

      let newFooter = null
      let addedContent = null
      let pageNo = 0
      let topPos = 0
      let pageNum = null
      for (var i = 0; i < pageCount - 1; i++) {
        // -1 dont do last page, use original footer
        pageNo++
        newFooter = footer.cloneNode(true)
        pageNum = newFooter.getElementsByClassName('page-number')
        if (pageNum && pageNum[0]) {
          pageNum[0].innerHTML = '<p>Page ' + pageNo + ' of ' + pageCount + '<p>'
        }
        topPos = pageNo * pageHeight - footerHeight
        addedContent = '<div class="added-content" style="top:' + topPos + 'px">' + newFooter.outerHTML + '<div style="margin-top:0px">' + header.outerHTML + '</div>' + '</div>'
        mainEl.insertAdjacentHTML('afterend', addedContent)
      }

      // update original footer now at bottom of page
      let pageNumLast = footer.getElementsByClassName('page-number')
      if (pageNumLast && pageNumLast[0]) {
        pageNumLast[0].innerHTML = '<p>Page ' + pageCount + ' of ' + pageCount + '<p>'
      }

      // this.addGridLines();
    },

    addGridLines() {
      let addedContent = document.getElementsByClassName('measure-line')
      while (addedContent[0]) {
        addedContent[0].parentNode.removeChild(addedContent[0])
      }

      let mainEl = document.getElementById('po-report').offsetHeight
      let parts = mainEl / 10
      let line = null
      for (var i = 0; i < parts; i++) {
        line = document.createElement('div')
        line.setAttribute('class', 'measure-line')
        line.style.top = i * 10 + 'px'
        line.innerHTML = i * 10
        if ((i * 10) % 1490 == 0) {
          line.style.background = 'purple'
        }
        document.getElementsByTagName('body')[0].appendChild(line)
      }
    }
  },

  mounted() {
    this.debug = 'mounted called'
    this.getData()
  },

  updated() {
    if (this.pdf) {
      this.pdfClearModifications()
      setTimeout(() => {
        this.pdfSpaceSections()
      }, 150)
    }
  }
}
</script>

<style lang="scss">
.po-report {
  // need this non scoped to apply styles to v-html injected content
  .doc-date,
  .doc-content,
  .doc-company {
    &,
    p {
      font-size: 16px;
    }
  }

  .measure-line {
    position: absolute;
    width: 100%;
    height: 1px;
    background: red;
  }

  .added-content {
    width: 100%;
    position: absolute;

    .header {
      max-width: 1100px;
      margin: 0 auto;
    }

    .footer-inner {
      padding: 0 15px;
    }
  }
}
</style>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

// pdf styles
body.pdf .po-report {
  min-width: 1200px;
}

body.pdf .header > div {
  width: 33% !important;
  text-align: left !important;
  float: left;
}

body.pdf .wo-info > div {
  width: 50% !important;
  text-align: left !important;
  float: left;
}

body.pdf .header .col-sm-4.text-right {
  text-align: right !important;
}

body.pdf .header img {
  margin: 0;
}

.po-report .container {
  max-width: 1100px;
  margin: 0 auto;
}

.header {
  padding: 40px 0 10px;

  img {
    width: 100%;
    max-width: 300px;
    margin-left: -8px;

    @media (max-width: 767px) {
      margin: 0 auto;
      display: block;
    }
  }

  h4 {
    margin: 0;
  }

  h5 {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    > .col-xs-12 {
      text-align: center;
    }
  }
}

.doc-title {
  h4 {
    margin: 10px 0 20px;
    font-weight: bolder;
  }
}

.doc-date {
  margin-bottom: 30px;
}

hr {
  border-color: $dark-gray;
}

table.wo-item-list {
  width: 100%;
  margin-bottom: 20px;

  td,
  th {
    border: 1px solid $other-medium-gray;
    padding: 3px;
    min-width: 90px;
    word-break: break-word;
  }

  &:not(.border-bottom) {
    td,
    th {
      //  border-bottom:0;
    }
  }

  .col-narrow {
    width: 80px;
  }

  .col-medium {
    width: 150px;
  }
}

.footer {
  .footer-inner {
    max-width: 1100px;
    margin: 0 auto;
  }

  .page-number {
    text-align: right;
  }
}
</style>
