<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="tab-section outline white">
          <ul class="nav nav-tabs">
            <li role="presentation" :class="[{ active: tab == 'Main' }]">
              <a href="#" @click="tab = 'Main'"> Main </a>
            </li>
            <li role="presentation" :class="[{ active: tab == 'Billing' }]">
              <a href="#" @click="tab = 'Billing'"> Billing </a>
            </li>
          </ul>
          <div :class="['tab-content', { 'first-active': tab === 'Main' }]">
            <div class="tab-content-inner">
              <div v-if="tab == 'Main'">
                <div class="row">
                  <div class="col-sm-12 col-md-12" style="position: relative">
                    <!--
                    <div :class="'detail-item'">
                      <h3>#{{ woData.WO_Number }}</h3>
                    </div>

                    <div class="wo-nav">
                      <i v-if="prevWO" class="ti-angle-left" @click="loadData(prevWO)"></i>
                      <i v-if="nextWO" class="ti-angle-right" @click="loadData(nextWO)"></i>
                    </div> -->
                  </div>
                  <div class="col-sm-12 col-md-4">
                    <div :class="'detail-item'">
                      <label>Customer</label>
                      <p>{{ woData.customer_name }}</p>
                    </div>

                    <div :class="'detail-item'">
                      <label>Description</label>
                      <p>{{ woData.Summary_Description }}</p>
                    </div>

                    <div :class="'detail-item'" v-if="employees && employees.length">
                      <label>Techs</label>
                      <ul class="wo-techs">
                        <li v-for="tech in woData.techs">
                          <span v-html="getEmployeeName(tech)" />
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-4">
                    <div :class="'detail-item'">
                      <label>Project Stage</label>
                      <input type="text" class="form-control fake-readonly is-link" readonly :value="projectStageName" @click="projectUpdate()" />
                      <!-- <select-field
                        :options="projectStages"
                        :label-text="'Project Stage'"
                        :option-display-keys="['description']"
                        option-val="id"
                        v-model="woData.project_stage"
                        :disabled="!editable"
                      /> -->
                      <!-- <p v-if="isLocked" style="padding: 5px"><i>PM can unlock using button below WO Main tab</i></p> -->
                    </div>

                    <div :class="'detail-item'">
                      <label>Sign Off Stage</label>
                      <select-field
                        :options="signOffStages"
                        :label-text="'Sign Off Status'"
                        :option-display-keys="['description']"
                        option-val="id"
                        v-model="woData.sign_off_stage"
                        :read-only="woData.project_stage !== 'finished'"
                      />
                    </div>

                    <!-- <div :class="'detail-item'">
                      <label>Dispatch Status</label>
                      <select-field
                        :options="woDispatchStatuses"
                        :label-text="'Dispatch Status'"
                        :option-display-keys="['description']"
                        :option-val="'code'"
                        v-model="woData.Dispatch_Status_Code"
                        :Xdisabled="!editable"
                        disabled
                      />
                    </div>

                    <div :class="'detail-item'">
                      <label>Stage</label>
                      <select-field
                        :options="filteredPriorityCodes"
                        :label-text="'Stage'"
                        :option-display-keys="['description']"
                        :option-val="'code'"
                        v-model="woData.Priority_Code"
                        :filter-on-key-val="{ key: 'enabled', val: 'yes' }"
                        disabled
                      />
                      <p v-if="isLocked" style="padding: 5px"><i>PM can unlock using button below WO Main tab</i></p>
                    </div> -->

                    <div :class="'detail-item'">
                      <label
                        >Last Worked Date
                        <span
                          style="padding-left: 3px"
                          class="ti-info-alt"
                          v-tooltip="{
                            content: 'This is the last day an hour was occurred',
                            placement: 'bottom',
                            hideOnTargetClick: false,
                            delay: { show: 200, hide: 2500 },
                            offset: 5,
                            classes: ['wide']
                          }"
                        />
                      </label>
                      <input type="text" :value="formatDate(woLaborData.entry_date)" :disabled="true" class="fake-disabled form-control" />
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-4">
                    <label>Service Report Send Log</label>
                    <service-report-send-log :raw-log="Array.isArray(woData.report_send_log) ? woData.report_send_log : []" />
                    <p style="padding: 5px">
                      <i>While in "Report Sent" stage, sign off request will be automatically re-sent once per week to all recipents the report was last sent to.</i>
                    </p>

                    <div class="detail-item" v-if="!woRecieved">
                      <label>Sign Off Link</label>
                      <input class="form-control fake-readonly" type="text" readonly v-model="woData.sign_off_link" @click="(copyText(woData.sign_off_link), $snack.open('Copied'))" />
                    </div>

                    <div class="detail-item" v-if="!woRecieved && woData.Dispatch_Status_Code == 7 && Array.isArray(woData.report_send_log) && woData.report_send_log.length > 0">
                      <button class="btn" @click="resendSignOffLink">Resend Sign Off Link</button>
                    </div>
                  </div>
                </div>

                <h5>Accounts Receivable (Billing) Routing Code</h5>
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <label>Routing Code</label>
                      <select-field
                        :options="billingRoutingCodes"
                        :option-display-keys="['routing_code', 'routing_description']"
                        :option-val="'routing_code'"
                        v-model="woData.billing_routing_code"
                        placeholder-text="Assign Routing Code"
                        :label="'Routing Code'"
                        :disabled="!isPM && !isAdmin"
                      />
                    </div>
                  </div>
                </div>

                <br />
                <div class="row">
                  <div class="col-sm-12">
                    <div :class="'detail-item'">
                      <alert :alert="{ show: woData.po_request_muted_by_customer == 'yes', message: 'Customer has muted PO Email Requests for this WO', state: 'warning', dismiss: 'none' }" />
                      <checkbox2 v-model="woData.auto_email_request" true-val="yes" false-val="no" :disabled="!isPM"> Enable Auto PO Request Email </checkbox2>
                    </div>
                  </div>
                </div>
                <span>
                  <button class="btn btn-blue" @click="updateWO()">Update</button>
                  <!-- <button class="btn" @click="updateWO('close')">
                    Update & Close
                  </button> -->
                </span>

                <button v-if="!woRecieved" class="btn" v-html="'Download PDF'" @click="generatePDF" />
                <!-- <p v-if="isLocked"><i>Work Order is locked</i></p> -->

                <button v-if="isAdmin" class="btn btn-outline-red" @click="deleteWO()">Delete</button>

                <hr />
                <h5>Date Change History</h5>
                <date-change-log v-if="woData.WO_Number" :obj-number="woData.WO_Number" type="WO" />
                <hr />

                <h5>Status Notes</h5>
                <item-comments
                  v-if="woId"
                  :table="'WOStatusNotes'"
                  :item-id="woId"
                  :label-text="''"
                  :spectrum-single-note="false"
                  :post-spectrum-name="'STATUS NOTES'"
                  :post-spectrum-type="'wo'"
                  ref="woStatusNotes"
                  :read-only="true"
                />

                <br />
                <button class="btn" @click="projectUpdate()">Add Project Update</button>
              </div>
              <div v-if="tab == 'Billing'">
                <billing-tasks :wo-number="woData.WO_Number" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <waiting-spinner />
  </div>
</template>
<script>
import store from 'store'
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import SelectField from 'components/UIComponents/SelectField'
import ItemComments from 'components/GeneralViews/ItemCommentsWYSIWYG'
import WOFuncs from 'mixins/WOFuncs'
import ServiceReportSendLog from 'components/Dashboard/JobWo/ServiceReportSendLog'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import BillingTasks from 'components/Dashboard/Billing/BillingTasks'
import { showConfirmDialog } from 'src/confirmDialog'
import DateChangeLog from './DateChangeLog.vue'
import Checkbox2 from 'components/UIComponents/Checkbox.vue'
import Alert from 'components/UIComponents/Alert'

export default {
  data() {
    return {
      woId: '',
      woData: {
        billing_routing_code: ''
      },
      woRecieved: false,
      loadedPriorityCode: null,
      loadedDispatchStatus: null,
      loadedVals: '',
      woLaborData: {},
      tab: 'Main',
      billingRoutingCodes: []
    }
  },

  components: {
    SelectField,
    ItemComments,
    ServiceReportSendLog,
    WaitingSpinner,
    BillingTasks,
    DateChangeLog,
    Checkbox2,
    Alert
  },

  mixins: [WOFuncs],

  props: {
    inputWoId: {
      type: [String, Number],
      required: true
    },
    woNav: {
      type: String,
      default: 'All WOs'
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['woAssemble', 'employees', 'userAuthedData']),

    isEditor() {
      return this.userAuthedData.user_role.indexOf('editor') !== -1
    },

    isPM() {
      return this.userAuthedData.user_role.indexOf('pm') !== -1
    },

    editable() {
      if (this.isPM && !this.isLocked) {
        return true
      }
      return false
    },

    projectStageName() {
      return this.woData.project_stage ? this.getProjectStage(this.woData.project_stage)?.['description'] : ''
    },

    filteredPriorityCodes() {
      const list = JSON.parse(JSON.stringify(this.woPriorityDescriptions))
      const currentPriorityCode = this.loadedPriorityCode
      if (this.isLocked) {
        const lockedAllowed = [3, 10, 12, 13, 4, parseInt(currentPriorityCode)]
        return list.map(itm => {
          if (!lockedAllowed.includes(parseInt(itm.code))) {
            return { ...itm, enabled: 'no' }
          }
          return { ...itm, enabled: 'yes' }
        })
      }
      return list.map(itm => ({ ...itm, enabled: 'yes' }))
    },

    prevWO() {
      let wos = this.woAssemble.map(itm => {
        return itm.WO_Number
      })

      if (this.woNav === 'My WOs') {
        wos = this.myWOIds
      }

      if (wos.indexOf(this.woId) < wos.length - 1) {
        return wos[wos.indexOf(this.woId) + 1]
      } else {
        return wos[0]
      }
    },

    nextWO() {
      let wos = this.woAssemble.map(itm => {
        return itm.WO_Number
      })

      if (this.woNav === 'My WOs') {
        wos = this.myWOIds
      }
      if (wos.indexOf(this.woId) > 0) {
        return wos[wos.indexOf(this.woId) - 1]
      } else {
        return wos[wos.length - 1]
      }
    },

    trackedFields() {
      return {
        Dispatch_Status_Code: this.woData.Dispatch_Status_Code,
        Priority_Code: this.woData.Priority_Code,
        woRecieved: this.woRecieved,
        project_stage: this.woData.project_stage,
        sign_off_stage: this.woData.sign_off_stage,
        auto_email_request: this.woData.auto_email_request
      }
    }
  },

  methods: {
    projectUpdate() {
      this.$Modal({
        parent: this,
        name: 'SetEstimatedStartDate',
        size: 'lg',
        title: 'Project Update',
        component: () => import('components/Dashboard/JobWo/SetEstimatedStartDate.vue'),
        props: {
          obj: this.woData,
          type: 'WO'
        }
      })
    },

    async updateWO(close) {
      return new Promise(async (resolve, reject) => {
        const nonFinishedPriorityCodes = [1, 2, 11, 9]
        const finishedDispatchStatusCodes = ['7', 'F']

        if (!nonFinishedPriorityCodes.includes(parseInt(this.woData.Priority_Code)) && !finishedDispatchStatusCodes.includes(this.woData.Dispatch_Status_Code.toString())) {
          alert('Dispatch Status must be Finished before changing Priority Code to finished type')
          resolve()
          return
        }

        if ((this.woData.Dispatch_Status_Code == '7' || this.woData.Dispatch_Status_Code == 'F') && this.hasMissingWoRequirements) {
          alert('All WO requirements must be met before WO can be marked with Finished Dispatch Status')
          resolve()
          return
        }

        let finishingJob = false
        if (finishedDispatchStatusCodes.includes(this.woData.Dispatch_Status_Code.toString()) && !finishedDispatchStatusCodes.includes(this.loadedDispatchStatus.toString())) {
          finishingJob = true
        }

        let data = {
          action: 'wo_status_update',
          wo_number: this.woId,
          priority_code: this.woData.Priority_Code,
          dispatch_status_code: this.woData.Dispatch_Status_Code,
          project_stage: this.woData.project_stage,
          sign_off_stage: this.woData.sign_off_stage,
          billing_routing_code: this.woData.billing_routing_code,
          auto_email_request: this.woData.auto_email_request
        }

        this.$bus.$emit('setWaiting', { name: 'wo_status_update', message: 'Updating Work Order' })

        try {
          await appFuncs.shRequest(data)
          this.loadData(this.woId)
        } catch (err) {
          this.$snack.open('Failed to update Work Order.', 'error')
          console.log(err)
        }

        this.updateWORecieved()
          .then(() => {
            if (finishingJob && this.enableBillingTasks) {
              this.showCreateBillingTask()
            }

            this.setLoadedVals()
            // this causes refreshing the job wo report table and user will loose position
            // this.loadData(this.woId, 'refresh')
            this.$bus.$emit('updateWIP')
            this.$bus.$emit('updateWOStage', data)
            if (close) {
              this.$bus.$emit('modalClose', 'WOWIPDetails')
            }

            resolve()
          })
          .catch(err => {
            this.$snack.open('Failed to update Work Order.', 'error')
            console.log(err)
            reject(err)
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'wo_status_update')
          })
      })
    },

    async deleteWO() {
      if (!(await showConfirmDialog('This does not delete the WO from Spectrum, it deletes records synced within MySheridan. Use this to delete along side deletion in Spectrum.'))) return

      return new Promise((resolve, reject) => {
        const params = {
          action: 'delete_wo',
          object_id: this.woId,
          type: 'WO'
        }

        this.$bus.$emit('setWaiting', {
          name: params.action,
          message: 'Delete WO'
        })

        appFuncs
          .shRequest(params)
          .then(async data => {
            this.$snack.open('WO has been deleted successfully', 'success')
            this.$router.push(`/app/wos`)
          })
          .catch(res => {
            this.$snack.open(res.message || 'Problem deleting WO data', 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', params.action)
          })
      })
    },

    updateWORecieved() {
      return new Promise((resolve, reject) => {
        resolve()
        return
        let data = {
          action: 'update_wo_recieved_status',
          wo_number: this.woId
        }

        let finishedPriorityCodes = ['7', 'F']
        let hasFinishedStatus = finishedPriorityCodes.indexOf(this.woData.Dispatch_Status_Code) !== -1 ? true : false

        if (!hasFinishedStatus && this.woRecieved) {
          this.$snack.open('WO must be Finished prior to marking as Received.', 'warning')
          reject()
          return
        }

        // if WO is neither finished or not needing signature, clear sig date
        if (this.woData.priority_code !== '3' && !hasFinishedStatus) {
          data.status_change_date = '0' // sending '0' String will clear field
        }

        // set status_change_date if marked Needs WO and had been changed
        if (this.loadedPriorityCode != this.woData.Priority_Code && this.woData.Priority_Code === '3' && !hasFinishedStatus) {
          data.status_change_date = Math.round(new Date().getTime() / 1000)
        }

        // set status_change_date if wo is marked finished and has been changed. AND dont reset if priority code Needs SIG has already been set (previously)
        if (this.woData.Dispatch_Status_Code !== this.loadedDispatchStatus && hasFinishedStatus && this.woData.Priority_Code !== '3') {
          data.status_change_date = Math.round(new Date().getTime() / 1000)
        }

        // if both finished status and stage were set finished and complete at the same time, set the date
        if (this.loadedPriorityCode !== this.woData.Priority_Code && this.woData.Dispatch_Status_Code !== this.loadedDispatchStatus) {
          if (this.woData.Priority_Code === '3' && hasFinishedStatus) {
            data.status_change_date = Math.round(new Date().getTime() / 1000)
          }
        }

        this.$bus.$emit('setWaiting', {
          name: 'update_wo_recieved_status',
          message: 'Updating Work Order'
        })

        appFuncs.ajax_request(store.getters.sherAuth, data, result => {
          this.loadedPriorityCode = this.woData.Priority_Code
          this.$bus.$emit('stopWaiting', 'update_wo_recieved_status')
          this.$bus.$emit('updateWOAssemble') // updates WODetails Component
          this.$bus.$emit('refreshWODetailsBase', 'refresh') // updates WOAddEdit Component
          resolve()
        })
      })
    },

    getEmployeeName(id) {
      if (this.employees && this.employees.length) {
        let obj = this.employees.find(itm => {
          return itm.Employee_Code == id
        })
        if (obj) {
          return obj.Employee_Name
        }
      }
    },

    loadData(woId) {
      return new Promise(async resolve => {
        if (this.loadedVals && this.checkDataUpdated()) {
          if (!confirm('Unsaved changes, would you like to proceed?')) {
            resolve()
            return
          }
        }

        this.woId = woId
        this.$bus.$emit('setWaiting', { name: 'getWOWip', message: 'Refreshing WO Data' })

        const params = {
          action: 'assemble_wip_wo_data',
          woNumber: woId
        }

        appFuncs
          .shRequest(params)
          .then(data => {
            this.woData = data[0]
            this.woRecieved = this.woData.sign_off_stage === 'signed' ? true : false
            this.setLoadedVals()
          })
          .catch(err => {
            this.$snack.open('Problem refreshing WO data, please close and try again', 'error')
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'getWOWip')
          })
        resolve()
      })
    },

    setLoadedVals() {
      this.loadedVals = JSON.stringify(this.trackedFields)
      this.loadedPriorityCode = this.woData.Priority_Code
      this.loadedDispatchStatus = this.woData.Dispatch_Status_Code
    },

    checkDataUpdated() {
      return false
      const currentVals = JSON.stringify(this.trackedFields)
      if (this.loadedVals != currentVals) {
        return true
      }
    },

    generatePDF() {
      if (this.woRecieved) {
        this.$snack.open('Please make sure WO is not already marked as signed off.', 'error')
        return
      }

      var params = {
        action: 'wo_signoff_save_pdf',
        wo_number: this.woId
      }

      this.$bus.$emit('setWaiting', { name: 'wo_signoff_save_pdf', message: 'Preparing PDF... takes a while' })
      appFuncs
        .shRequest(params)
        .then(data => {
          if (data && data.location) {
            const content = `<p class="text-center"style="margin:30px 0"><br><br><a target="_blank" href="${data.location}" class="btn">PDF Download</a></p>`
            this.$Modal({
              parent: this,
              name: 'WODownloadPDF',
              size: 'sm', // sm, md, lg, xl
              hideClose: false,
              content,
              props: null
            })
          }
        })
        .catch(res => {
          this.$snack.open('Problem generating PDF, please try again now or later', 'error')
          console.log(res)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'wo_signoff_save_pdf')
        })
    },

    getWOLaborData() {
      return new Promise((resolve, reject) => {
        const params = {
          action: 'get_timecard_entries',
          employee_code: null,
          start_date: null,
          end_date: null,
          type: 'WO',
          number: this.woId,
          extra_id: null,
          show_deleted: null,
          selected_jobs: [],
          selected_wos: [],
          approval_status: 'all'
        }
        appFuncs
          .shRequest(params)
          .then(data => {
            // sort by latest entry date
            if (!Array.isArray(data) || !data.length) return
            data.sort((a, b) => {
              return new Date(b.entry_date) - new Date(a.entry_date)
            })
            this.woLaborData = data[0]
            resolve()
          })
          .catch(res => {
            console.log(res)
            this.$snack.open(res.message || 'Problem getting WO Labor Data', 'error')
            reject()
          })
      })
    },

    resendSignOffLink() {
      this.$Modal({
        parent: this,
        name: 'send-signoff-link', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/JobWo/SendSignOffLink.vue'),
        contentClasses: '',
        props: {
          authCode: this.woData.sign_off_auth,
          itemType: 'WO',
          data: this.woData,
          isResend: true
        }
      })
    },

    listenLoadWOData() {
      this.loadData(this.woId)
      if (this.$refs.woStatusNotes) {
        this.$refs.woStatusNotes.getNotes()
      }
    },

    showCreateBillingTask() {
      this.$Modal({
        parent: this,
        name: 'BillingTaskAdd',
        size: 'md',
        title: `Add Billing Task`,
        component: () => import('src/components/Dashboard/Billing/BillingTaskAdd.vue'),
        props: {
          WO_Number: this.woId,
          setTaskType: 'BC',
          setAssignedTo: null
        }
      })
    },

    getBillingRoutingCodes() {
      var params = {
        action: 'get_routing_codes'
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Getting Billing Routing Codes' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.billingRoutingCodes = data
        })
        .catch(err => {
          console.log(err)
          snack.open(err.message || 'Problem fetching billing routing codes', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    openWOStageUpdate() {
      this.$Modal({
        parent: this,
        name: 'WOStageUpdate',
        size: 'sm',
        component: () => import('src/components/Dashboard/JobWo/WOStageUpdate.vue'),
        title: 'Project Status',
        props: {
          woNumber: this.woData.WO_Number,
          wo: this.woData
        }
      })
    },

    listenJobUpdate() {
      this.loadData(this.woId, 'refresh')
      if (this.$refs.woStatusNotes) {
        this.$refs.woStatusNotes.getNotes()
      }
    }
  },

  mounted() {
    this.loadData(this.inputWoId)
    this.$bus.$emit('setWaiting', { name: 'getEmployees', message: 'Getting Employees' })
    this.$store.dispatch('getEmployees').then(() => {
      this.$bus.$emit('stopWaiting', 'getEmployees')
    })

    this.getWOLaborData()
    this.getBillingRoutingCodes()

    this.$bus.$on('refreshWODetailsBase', this.listenLoadWOData)
    this.$bus.$on('updateJobWO', this.listenLoadWOData)
  },

  beforeDestroy() {
    this.$bus.$off('refreshWODetailsBase', this.listenLoadWOData)
    this.$bus.$off('updateJobWO', this.listenLoadWOData)
  }
}
</script>

<style lang="scss" scoped>
.detail-item {
  margin-bottom: 15px;

  h3 {
    font-weight: bolder;
    margin: 0;
  }
}

.wo-techs {
  padding: 0 0 0 20px;

  li {
    padding: 0;
  }
}

.wo-nav {
  right: 0;
  position: absolute;
  top: 0;

  i {
    cursor: pointer;
    padding: 5px;
  }
}
</style>
