
<div>
  <div class="row">
    <div class="col-md-12">
      <div class="tab-section outline white">
        <ul class="nav nav-tabs">
          <li role="presentation" :class="[{ active: tab == 'Main' }]">
            <a href="#" @click="tab = 'Main'"> Main </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Billing' }]">
            <a href="#" @click="tab = 'Billing'"> Billing </a>
          </li>
        </ul>
        <div :class="['tab-content', { 'first-active': tab === 'Main' }]">
          <div class="tab-content-inner">
            <div v-if="tab == 'Main'">
              <div class="row">
                <div class="col-sm-12 col-md-12" style="position: relative">
                  <!-- <div :class="'detail-item'">
                    <h3>#{{ jobData.Job_Number }}</h3>
                  </div> -->
                  <!-- <div class="job-nav">
                    <i v-if="prevJob" class="ti-angle-left" @click="loadData(prevJob)"></i>
                    <i v-if="nextJob" class="ti-angle-right" @click="loadData(nextJob)"></i>
                  </div> -->
                </div>

                <div class="col-sm-12 col-md-4">
                  <div :class="'detail-item'">
                    <label>Customer</label>
                    <p>{{ jobData.Name }}</p>
                  </div>
                  <div :class="'detail-item'">
                    <label>Description</label>
                    <p>{{ jobData.Job_Description }}</p>
                  </div>
                  <div :class="'detail-item'">
                    <label>Has Job Extra(s): </label><br />
                    <span v-if="jobExtras.length"> Yes</span>
                    <span v-else> No</span>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <!-- <div :class="'detail-item'">
                    <label>Stage</label>
                    <select-field
                      :options="filteredJobStages"
                      :label-text="'Stage'"
                      :option-display-keys="['description']"
                      :option-val="'code'"
                      v-model="stageCode"
                      :filter-on-key-val="{ key: 'enabled', val: 'yes' }"
                      disabled
                    />
                    <p v-if="isLocked" style="padding: 5px"><i>PM can unlock using button below Job Main tab</i></p>
                  </div> -->

                  <div :class="'detail-item'">
                    <label>Project Stage</label>
                    <input type="text" class="form-control fake-readonly is-link" readonly :value="projectStageName" @click="projectUpdate()" />
                    <!-- <select-field
                      :options="projectStages"
                      :label-text="'Project Stage'"
                      :option-display-keys="['description']"
                      option-val="id"
                      :value="jobData.project_stage"
                      :disabled="!editable"
                      @click="openJobStageUpdate"
                    /> -->
                    <!-- <p v-if="isLocked" style="padding: 5px"><i>PM can unlock using button below WO Main tab</i></p> -->
                  </div>

                  <div :class="'detail-item'">
                    <label>Sign Off Stage</label>
                    <select-field
                      :options="signOffStages"
                      :label-text="'Sign Off Status'"
                      :option-display-keys="['description']"
                      option-val="id"
                      v-model="jobData.sign_off_stage"
                      :read-only="jobData.project_stage !== 'finished'"
                    />
                  </div>

                  <div :class="'detail-item'">
                    <label>Last Cost Date</label>
                    <input type="text" class="form-control fake-readonly" readonly :value="lastCostDate" />
                  </div>

                  <!-- <div :class="'detail-item'">
                    <checkbox2 v-model="jobRecieved" :true-val="true" :false-val="false"> Sign Off Status</checkbox2>
                  </div> -->
                </div>

                <div class="col-sm-12 col-md-4">
                  <label>Service Report Send Log</label>
                  <service-report-send-log :raw-log="Array.isArray(jobData.report_send_log) ? jobData.report_send_log : []" />
                  <p style="padding: 5px">
                    <i>While in "Report Sent" stage, sign off request will be automatically re-sent once per week to all recipents the report was last sent to.</i>
                  </p>

                  <div class="detail-item" v-if="jobSignOffStatus !== 'yes' && stageCode != 4">
                    <label>Sign Off Link</label>
                    <input class="form-control fake-readonly" type="text" readonly v-model="jobData.sign_off_link" @click="(copyText(jobData.sign_off_link), $snack.open('Copied'))" />
                  </div>

                  <file-upload
                    v-if="jobData && jobData.Job_Number"
                    :readonly="true"
                    label=""
                    :path="'job-uploads/' + jobData.Job_Number + '/'"
                    :subdir="'sign-off'"
                    :zip-depth="-1"
                    name="Report Sign Off"
                    :can-delete="false"
                  />

                  <div class="detail-item" v-if="jobSignOffStatus !== 'yes' && stageCode == 5 && Array.isArray(jobData.report_send_log) && jobData.report_send_log.length >= 1">
                    <button class="btn" @click="resendSignOffLink">Resend Sign Off Link</button>
                  </div>
                </div>
              </div>

              <alert :alert="{ show: jobData.po_request_muted_by_customer == 'yes', message: 'Customer has muted PO Email Requests for this Job', state: 'warning', dismiss: 'none' }" />
              <div class="row">
                <div class="col-sm-4">
                  <div :class="'detail-item'">
                    <checkbox2 v-model="jobData.auto_email_request" :disabled="!isPM" true-val="yes" false-val="no"> Enable Auto PO Request Email</checkbox2>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div :class="'detail-item'">
                    <checkbox2 v-model="admin_options.other.require_detailed_notes" :disabled="!isAdmin || isLocked" :true-val="true" :false-val="false"> Require Detailed Notes</checkbox2>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div :class="'detail-item'">
                    <checkbox2 v-model="admin_options.other.using_foreman_time_entry" :disabled="!isPM || isLocked" :true-val="true" :false-val="false"> Warn Foreman Time Entry</checkbox2>
                  </div>
                </div>
              </div>

              <h5>
                Accounts Payable Invoice Approval Routing Codes
                <span
                  style="padding-left: 3px; font-size: 14px"
                  class="ti-info-alt"
                  v-tooltip="{
                    content: 'If any values are blank, all will be set based on values from the Project Lead',
                    placement: 'bottom',
                    hideOnTargetClick: false,
                    delay: { show: 200, hide: 2500 },
                    offset: 5,
                    classes: ['wide']
                  }"
                />
              </h5>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Default Routing Code</label>
                    <select-field
                      :options="selectableRoutingCodes"
                      :option-display-keys="['Routing_Desc', 'Routing_Code']"
                      :option-val="'Routing_Code'"
                      v-model="jobData.Routing_Code1"
                      placeholder-text="Assign Routing Code"
                      :label="'Routing Code'"
                      :disabled="!isPM && !isJobCaptain && !isAdmin"
                      :filter-on-key-val="{ key: 'allow_job_select', val: 'yes' }"
                    />
                  </div>
                </div>
                <div class="col-sm-4" v-if="isAdmin">
                  <div class="form-group">
                    <label>Dollar Invoice Limit</label>
                    <vue-numeric
                      currency="$"
                      separator=","
                      :class="['form-control']"
                      :precision="2"
                      :read-only-class="'form-control readonly'"
                      v-model="jobData.Routing_Limit"
                      :disabled="!isAdmin"
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Default Routing if Over Limit</label>
                    <select-field
                      :options="routingCodes"
                      :option-display-keys="['Routing_Desc', 'Routing_Code']"
                      :option-val="'Routing_Code'"
                      v-model="jobData.Routing_Code2"
                      placeholder-text="Assign Routing Code"
                      :label="'Routing Code'"
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>

              <h5>Accounts Receivable (Billing) Routing Code</h5>
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="form-group">
                    <label>Routing Code</label>
                    <select-field
                      :options="billingRoutingCodes"
                      :option-display-keys="['routing_code', 'routing_description']"
                      :option-val="'routing_code'"
                      v-model="jobData.billing_routing_code"
                      placeholder-text="Assign Routing Code"
                      :label="'Routing Code'"
                      :disabled="!isPM && !isJobCaptain && !isAdmin"
                    />
                  </div>
                </div>
              </div>

              <br />
              <div class="buttons">
                <button class="btn btn-blue" @click="updateJob()">Update</button>
                <button v-if="jobSignOffStatus !== 'yes' && stageCode != 4" class="btn" v-html="'Download PDF'" @click="generatePDF" />
                <button v-if="isAdmin" class="btn btn-outline-red" @click="deleteJob()">Delete</button>
              </div>
              <!-- <p v-if="isLocked"><i>Job is locked</i></p> -->

              <hr />
              <h5>Date Change History</h5>
              <date-change-log v-if="jobData.Job_Number" :objNumber="jobData.Job_Number" type="Job" />
              <hr />

              <h5>Status Notes</h5>
              <item-comments
                v-if="jobData.Job_Number"
                :table="'JobStatusNotes'"
                :item-id="jobData.Job_Number"
                :label-text="''"
                :post-spectrum-name="'Status Notes'"
                :post-spectrum-type="'job'"
                :read-only="true"
                ref="jobStatusNotes"
              />
            </div>

            <br />
            <button class="btn" @click="projectUpdate()">Add Project Update</button>

            <div v-if="tab == 'Billing'">
              <billing-tasks :job-number="jobData.Job_Number" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner />
</div>
