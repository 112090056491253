<template>
  <div class="wo-contacts">
    <div v-if="!woError">
      <h5>Contacts Assigned to Work Order</h5>

      <br />
      <button v-if="inSequence" class="btn" @click="next" v-html="'Next'" />
      <br />

      <bh-table :table-settings="contactsAssigned" @item-checked="itemChecked" @edit-item="editContact" />

      <hr />
      <br />

      <h5>All Contacts <span class="fa fa-plus-circle is-link" @click="editContact()" /></h5>

      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="form-group">
            <label>Filter by Site</label>
            <select-field
              :options="woSiteOptions"
              :option-display-keys="['Ship_To_Name', 'site_address']"
              :option-val="'Ship_To_ID'"
              v-model="filterBySite"
              :filter-on-key-val="{ key: 'Status', val: 'A' }"
            />
          </div>
        </div>
      </div>

      <bh-table :table-settings="contactsAll" @item-checked="itemChecked" @edit-item="editContact" />

      <br />

      <!-- <button
        class="btn"
        @click="updateWO"
        v-html="'Update'"
      />
 -->
      <button v-if="inSequence" class="btn" @click="next" v-html="'Next'" />
    </div>
    <p v-else>Problem loading WO Data, please close and continue from WO Details pages.</p>

    <waiting-spinner :container="true" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import BhTable from 'components/UIComponents/BhTable'
import WOAdminOptions from 'src/components/Dashboard/JobWo/WOAdminOptions.vue'
import CustomerContactsAddEdit from 'src/components/Dashboard/Contacts/CustomerContactsAddEdit.vue'
import SelectField from 'components/UIComponents/SelectField'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {
      woError: false,
      wo: {},
      filterBySite: 'all',
      contacts: [],
      WOAssignedContacts: [],
      tableFields: {
        Name: {
          name: 'Name'
        },
        Title: {
          name: 'Title'
        },
        woSiteName: {
          name: 'Site'
        },
        Email: {
          name: 'Email'
        },
        assigned_contact: {
          name: 'Assigned',
          type: 'checkbox',
          class: 'centered'
        },
        notifications_email: {
          name: 'Email Notifications',
          type: 'checkbox',
          class: 'centered'
        },
        notifications_text: {
          name: 'Text Notifications',
          type: 'checkbox',
          class: 'centered'
        },
        MobilePhone: {
          name: 'Mobile Phone'
        },
        Phone: {
          name: 'Direct Phone'
        },
        Phone_Extension__c: {
          name: 'Direct Phone Extension'
        },
        ticket_link: {
          name: 'Ticket Preview',
          class: 'centered'
        }
      },
      contactsAllData: [],
      contactsAssignedData: []
    }
  },

  props: {
    woid: {
      type: [Number, String],
      required: true
    },

    inSequence: {
      type: Boolean,
      default: false
    }
  },

  components: {
    BhTable,
    SelectField,
    WaitingSpinner
  },

  computed: {
    ...mapGetters(['customerContacts', 'woAssemble', 'woSites']),

    contactsAll() {
      let fields = JSON.parse(JSON.stringify(this.tableFields))
      delete fields.notifications_text
      delete fields.notifications_email
      delete fields.ticket_link
      return {
        fields: fields,
        hideSearch: true,
        tableData: this.contactsAllData
      }
    },

    contactsAssigned() {
      return {
        fields: this.tableFields,
        hideSearch: true,
        tableData: this.contactsAssignedData
      }
    },

    woSiteOptions() {
      let options = [{ Ship_To_Name: 'Show All', Ship_To_ID: 'all' }]
      if (this.wo.Bill_Customer_Code) {
        let siteMatches = this.woSites.filter(itm => {
          return itm.Ship_To_Customer_Code === this.wo.Bill_Customer_Code
        })
        options = options.concat(siteMatches)
      }
      return options
    }
  },

  methods: {
    async load() {
      const getWOAssemble = this.$store.dispatch('getWoAssemble')
      const getContacts = this.$store.dispatch('getCustomerContacts')
      const getWOSites = this.$store.dispatch('getWOSites')

      this.$bus.$emit('setWaiting', { name: 'getData', message: 'Getting Data' })
      await Promise.all([getContacts, getWOAssemble, getWOSites])
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getData')
        })

      const wo = this.woAssemble.find(itm => {
        return itm.WO_Number == this.woid
      })

      if (wo) {
        this.wo = wo
        this.contacts = this.customerContacts.filter(itm => {
          return itm.Account_Customer_Code__c == this.wo.Bill_Customer_Code
        })

        // assign WO Requested By if assigned contacts list is empty and in Add WO sequence
        this.wo.assigned_contacts = Array.isArray(this.wo.assigned_contacts) ? this.wo.assigned_contacts : []
        this.wo.notifications_email = Array.isArray(this.wo.notifications_email) ? this.wo.notifications_email : []

        if (this.inSequence && this.wo.requested_by_id) {
          this.wo.assigned_contacts.push(this.wo.requested_by_id)
          this.wo.notifications_email.push(this.wo.requested_by_id)
        }

        this.setContactData()

        // enable this to filter by site selected by WO, disabling temporarily
        // this.filterBySite = this.wo.WO_Job_Number;
      } else {
        this.woError = true
      }
    },

    setContactData() {
      this.contactsAssignedData = []
      this.contactsAllData = []

      // for each contact of WO Customer
      for (var i = this.contacts.length - 1; i >= 0; i--) {
        // for initial load - add assigned contact data if not already set
        if (typeof this.contacts[i].assigned_contact === 'undefined') {
          this.contacts[i].assigned_contact = false
          if (this.wo.assigned_contacts && this.wo.assigned_contacts.length) {
            if (this.contacts[i].id && this.wo.assigned_contacts.indexOf(this.contacts[i].id.toString()) !== -1) {
              this.contacts[i].assigned_contact = true
            } else if (this.contacts[i].MYS_ID__c && this.wo.assigned_contacts.indexOf(this.contacts[i].MYS_ID__c.toString()) !== -1) {
              this.contacts[i].assigned_contact = true
            }
          }
        }

        // for initial load - add properties of notification names
        if (typeof this.contacts[i].notifications_email === 'undefined') {
          this.contacts[i].notifications_email = false
          if (this.wo.notifications_email && this.wo.notifications_email.length) {
            if (this.contacts[i].id && this.wo.notifications_email.indexOf(this.contacts[i].id.toString()) !== -1) {
              this.contacts[i].notifications_email = true
            } else if (this.contacts[i].MYS_ID__c && this.wo.notifications_email.indexOf(this.contacts[i].MYS_ID__c.toString()) !== -1) {
              this.contacts[i].notifications_email = true
            }
          }
        }

        if (typeof this.contacts[i].notifications_text === 'undefined') {
          this.contacts[i].notifications_text = false
          if (this.wo.notifications_text && this.wo.notifications_text.length) {
            if (this.contacts[i].id && this.wo.notifications_text.indexOf(this.contacts[i].id.toString()) !== -1) {
              this.contacts[i].notifications_text = true
            } else if (this.contacts[i].MYS_ID__c && this.wo.notifications_text.indexOf(this.contacts[i].MYS_ID__c.toString()) !== -1) {
              this.contacts[i].notifications_text = true
            }
          }
        }

        // update assigned contacts arr from contact array
        if (this.contacts[i].assigned_contact && this.contacts[i].assigned_contacts !== 'false') {
          this.contactsAssignedData.push(this.contacts[i])
        }

        // add link for wo ticket
        this.contacts[i].ticket_link = {
          title: '<i class="ti ti-new-window"></i>',
          action: 'getTicketLink'
        }

        for (var woi = 0; woi < this.woSites.length; woi++) {
          if (this.woSites[woi].sfId && this.woSites[woi].sfId === this.contacts[i].Account_Site__c) {
            this.contacts[i].woSiteName = this.woSites[woi].Ship_To_Name
            this.contacts[i].woSiteId = this.woSites[woi].sfId
            break
          }
        }

        if (this.filterBySite !== 'all' && this.filterBySite !== this.contacts[i].Site_ID__c) {
          continue
        }

        this.contactsAllData.push(this.contacts[i])

        this.contactsAllData = this.contactsAllData.sort((a, b) => {
          if (a.Name < b.Name) return -1
          return 1
        })
      } // end of contacts loop
    },

    updateWO() {
      let assignedContacts = this.contactsAssignedData.map(itm => {
        return itm.id
      })

      // find matches
      let notificationsEmail = this.contacts.map(itm => {
        if (itm.notifications_email == true) {
          return itm.id
        }
      })
      // remove empty array items
      notificationsEmail = notificationsEmail.filter(Boolean)

      // find matches
      let notificationsText = this.contacts.map(itm => {
        if (itm.notifications_text == true) {
          return itm.id
        }
      })
      // remove empty array items
      notificationsText = notificationsText.filter(Boolean)

      const params = {
        action: 'upsert_wos_app_data',
        id: this.woid,
        data: {
          assigned_contacts: assignedContacts.length ? assignedContacts : null,
          notifications_email: notificationsEmail.length ? notificationsEmail : null,
          notifications_text: notificationsText.length ? notificationsText : null
        }
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Updating App Data' })
      appFuncs
        .shRequest(params)
        .then(res => {
          // trigger last status notifications to get sent to whichever contacts havnet been sent
          appFuncs.sendWONnotifications(this.woid, null, null, 8)
        })
        .catch(res => {
          console.log(res)
          this.$snack.open('Problem updating some data, please try again.', 'warning')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    editContact(editId) {
      this.$Modal({
        parent: this,
        name: 'customer-contact-add-edit', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        title: '',
        component: CustomerContactsAddEdit,
        props: {
          editId,
          preSelected: {
            Account_Customer_Code__c: this.wo.Bill_Customer_Code
          }
        }
      })
    },

    next() {
      // trigger contact notifications - used for defaulting to send to WO Requested By
      this.updateWO()

      this.$bus.$emit('modalClose', 'wo-contacts')
      this.$Modal({
        parent: this,
        name: 'wo-admin-options', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        title: 'Work Order Options',
        component: WOAdminOptions,
        props: {
          woid: this.woid,
          tab: 'Main',
          inSequence: true
        }
      })
    },

    itemChecked(obj) {
      for (var i = this.contacts.length - 1; i >= 0; i--) {
        if (this.contacts[i].id == obj.id) {
          // need to change this to look at contact id in array within notifications_update_on_ticket wo field
          let notifications_updated_on_ticket = Array.isArray(this.wo.notifications_updated_on_ticket) ? this.wo.notifications_updated_on_ticket : []
          if ((obj.field === 'notifications_email' || obj.field === 'notifications_text') && notifications_updated_on_ticket.indexOf(this.contacts[i].id.toString()) !== -1) {
            if (!confirm('Warning: Notification preference may have been set by customer contact via WO ticket, do you wish to override?')) {
              return
            }
          }

          // if notification email and no email address then error
          if (obj.field === 'notifications_email' && !this.contacts[i].Email && obj.value == true) {
            this.$snack.open('Contact data does not contain email address.', 'warning')
            return
          }

          // if notification text and no mobile number then error
          if (obj.field === 'notifications_text' && !this.contacts[i].MobilePhone && obj.value == true) {
            this.$snack.open('Contact data does not include mobile phone number', 'warning')
            return
          }
          this.contacts[i][obj.field] = obj.value
        }
      }

      this.setContactData()
      this.$bus.$emit('bhUpdate')
      this.updateWO()
    },

    getCustomerTicketLink(contactId) {
      const params = {
        action: 'get_wo_ticket_code',
        wo_number: this.woid,
        customer_contact_id: contactId
      }

      appFuncs
        .shRequest(params, 1)
        .then(data => {
          const modalContent = `<p class="text-center"style="margin:30px 0">Customer ticket preview:<br><br><a target="_blank" href="${
            this.$store.getters.urls.frontend + '#/customer-ticket/wo/' + data
          }" class="btn">Customer Ticket</a></p>`
          this.$bus.$emit('modalOpen', {
            size: 'modal-sm',
            name: 'customer-contact-wo-ticket-prompt',
            title: '',
            contentClasses: 'gray-bg centered',
            content: modalContent
          })
        })
        .catch(res => {
          console.log(res)
        })
    }
  },

  mounted() {
    if (!this.woid) {
      this.woError = true
    }

    // this.$bus.$on('editCustomerNotifications', (contact)=>{
    //    this.editCustomerNotifications(contact);
    // });

    this.$bus.$on('woNotificationsUpdated', contact => {
      this.load()
    })

    this.$bus.$on('getTicketLink', contact => {
      this.getCustomerTicketLink(contact.Id)
    })

    this.$bus.$on('bhUpdate', () => {
      this.load()
    })

    this.$bus.$on('customer-contacts-updated', () => {
      this.$store.dispatch('getCustomerContacts', 'refresh').then(() => {
        this.load()
      })
    })

    this.load()
  },

  beforeDestroy() {
    // this.$bus.$off(['editCustomerNotifications']);
  }
}
</script>

<style lang="scss" scoped>
h5 {
  margin: 5px 0 10px;
}
</style>
