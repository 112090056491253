<template>
  <div>
    <div>
      <bh-table :table-settings="tableSettings" @edit-item="addOpenItem" />
      <button v-if="!isLocked" class="btn" @click="addOpenItem()" v-html="'Add New'" />
    </div>
    <waiting-spinner />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import commonFuncs from 'mixins/commonFuncs'
import JobFuncs from 'mixins/JobFuncs'
import BhTable from 'components/UIComponents/BhTable'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import dateFormat from 'dateformat'

export default {
  data() {
    return {
      tableSettings: {
        tableData: [],
        fields: {
          itemNumber: {
            name: 'Item Number'
          },
          typeName: {
            name: 'Type'
          },
          dateOpened: {
            name: 'Date'
          },
          openedBy: {
            name: 'Opened By'
          },
          description: {
            name: 'Description',
            wide: 1
          },
          statusName: {
            name: 'Status',
            wide: 1
          }
        },
        hideSearch: true
      },
      typeOptions: [
        {
          name: 'Job Extra',
          value: 'extra'
        },
        {
          name: 'Job Extra - T&M',
          value: 'extra tm'
        },
        {
          name: 'Job Extra - Quoted',
          value: 'extra quoted'
        },
        {
          name: 'T&M Job Sign Off',
          value: 'tm'
        },
        {
          name: 'Other',
          value: 'other'
        }
      ]
    }
  },

  components: {
    BhTable,
    WaitingSpinner
  },

  mixins: [commonFuncs, JobFuncs],

  props: {
    jobNumber: {
      type: Number,
      required: true
    },
    job: {
      type: Object,
      required: true
    },
    isLocked: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters(['employees']),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPM() {
      return this.$store.getters.userAuthedData.user_role.indexOf('pm') !== -1
    },

    userID() {
      return this.$store.getters.userAuthedData.eid
    }
  },

  methods: {
    async getJobExtras() {
      await this.$store.dispatch('getEmployees')

      const params = {
        action: 'job_extra_get',
        job_number: this.jobNumber,
        status: null
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Getting Job Extras' })

      appFuncs
        .shRequest(params)
        .then(data => {
          if (data.entries) {
            this.setTableData(data.entries)
          } else {
            this.$snack.open('Problem getting Job Extras', 'error')
          }
        })
        .catch(res => {
          console.log(res)
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    setTableData(data) {
      for (let i = 0; i < data.length; i++) {
        let statusDate = ''

        const status = this.jobExtraStatusOptions.find(itm => {
          return data[i].status === itm.value
        })
        let statusName = status && status.name ? status.name : ''

        switch (data[i].status) {
          case 'signed':
            statusDate = this.dateFromUnix(data[i].sign_off_date)
            break
          case 'finished':
            statusDate = this.dateFromUnix(data[i].finished_date)
            break
          default:
            statusDate = this.dateFromUnix(data[i].date_opened)
            break
        }
        data[i].itemNumber = this.formatExtraNumber(data[i].job_number, data[i].id)
        data[i].dateOpened = this.dateFromUnix(new Date(data[i].date_opened), false)
        data[i].openedBy = this.getEmployeeName(data[i].owner_employee_code, this.employees).Employee_Name
        data[i].statusName = `${statusName} - ${statusDate}`

        if (data[i].status === 'signed') {
          data[i].class = 'green'
        }

        let typeName = this.typeOptions.find(itm => {
          return itm.value === data[i].type
        })
        data[i].typeName = (typeName && typeName.name) || 'Job Extra'
      }
      this.tableSettings.tableData = data
    },

    addOpenItem(id) {
      this.$Modal({
        parent: this,
        name: 'JobExtrasAddEdit',
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        component: () => import('components/Dashboard/JobWo/JobExtrasAddEdit'),
        props: {
          loadId: id || 0,
          job: this.job
        }
      })
    }
  },

  mounted() {
    this.$bus.$on('job-extras-update', () => {
      this.getJobExtras()
    })
  },

  watch: {
    jobNumber: {
      handler() {
        if (this.jobNumber) {
          this.getJobExtras()
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';
</style>
