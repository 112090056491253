<template>
  <div>
    <div class="sh-widget wide-medium-large basic-app-widget card" style="min-height: 470px">
      <div class="">
        <div class="content">
          <div class="widget-header">
            <h4>Company Metrics</h4>
          </div>
          <div class="group-metrics">
            <div class="metrics-row">
              <div class="metrics-item">
                <span><strong>Total Labor Cost</strong></span>
                <p>{{ this.formatNumber(this.total_labor_cost, 1) }}</p>
              </div>
              <div class="metrics-item">
                <span><strong>Total Hours</strong></span>
                <p>{{ this.formatNumber(this.total_hours) }}</p>
              </div>
              <div class="metrics-item">
                <span><strong>Average Labor Burden</strong></span>
                <p>{{ this.formatNumber(this.average_labor_burden, 1) }}</p>
              </div>
            </div>

            <div class="metrics-row">
              <div class="metrics-item">
                <span><strong>Billable Costs</strong></span>
                <p>{{ this.formatNumber(this.billable_costs, 1) }}</p>
                <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.billable_costs_in_total, 2) }}% of total</p>
              </div>
              <div class="metrics-item">
                <span><strong>Billable hours</strong></span>
                <p>{{ this.formatNumber(this.billable_hours) }}</p>
                <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.billable_hours_in_total, 2) }}% of total</p>
              </div>
              <div class="metrics-item">
                <span><strong>UB Safety Quoting Costs</strong></span>
                <p>{{ this.formatNumber(this.ub_safe_quoting_cost, 1) }}</p>
                <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.ub_safe_quoting_cost_in_total, 2) }}% of total</p>
              </div>
            </div>

            <div class="metrics-row">
              <div class="metrics-item">
                <span><strong>Unbillable Costs</strong></span>
                <p>{{ this.formatNumber(this.unbillable_costs, 1) }}</p>
                <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.unbillable_costs_in_total, 2) }}% of total</p>
              </div>
              <div class="metrics-item">
                <span><strong>Quoting Costs</strong></span>
                <p>{{ this.formatNumber(this.quoting_costs, 1) }}</p>
                <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.quoting_costs_in_total, 2) }}% of total</p>
              </div>
              <div class="metrics-item">
                <span><strong>Safety Costs</strong></span>
                <p>{{ this.formatNumber(this.safety_costs, 1) }}</p>
                <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.safety_costs_in_total, 2) }}% of total</p>
              </div>
            </div>

            <div class="metrics-row">
              <div class="metrics-item">
                <span><strong>Unbillable Hours</strong></span>
                <p>{{ this.formatNumber(this.unbillable_hours, 1, true) }}</p>
                <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.unbillable_hours_in_total, 2) }}% of total</p>
              </div>
              <div class="metrics-item">
                <span><strong>Quoting Hours</strong></span>
                <p>{{ this.formatNumber(this.quoting_hours, 1, true) }}</p>
                <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.quoting_hours_in_total, 2) }}% of total</p>
              </div>
              <div class="metrics-item">
                <span><strong>Safety Hours</strong></span>
                <p>{{ this.formatNumber(this.safety_hours, 1, true) }}</p>
                <p class="compare-with-total" v-show="!!this.selectedDepartment">{{ this.formatPercentage(this.safety_hours_in_total, 2) }}% of total</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <waiting-spinner :contained="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import snack from 'components/UIComponents/Snackbar'
import appFuncs from 'appFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  components: {
    WaitingSpinner
  },
  data() {
    return {
      total_labor_cost: 0,
      total_hours: 0,
      average_labor_burden: 0,
      ub_safe_quoting_cost: 0,
      ub_safe_quoting_cost_in_total: 0,
      billable_costs: 0,
      billable_costs_in_total: 0,
      billable_hours: 0,
      billable_hours_in_total: 0,
      unbillable_costs: 0,
      unbillable_costs_in_total: 0,
      unbillable_hours: 0,
      unbillable_hours_in_total: 0,
      safety_costs: 0,
      safety_costs_in_total: 0,
      safety_hours: 0,
      safety_hours_in_total: 0,
      quoting_costs: 0,
      quoting_costs_in_total: 0,
      quoting_hours: 0,
      quoting_hours_in_total: 0
    }
  },

  computed: {
    ...mapGetters(['viewAsEmployeeId', 'fiscalYear', 'selectedDepartment']),

    isPM() {
      return this.userAuthedData.user_role.indexOf('pm') !== -1
    }
  },

  methods: {
    formatPercentage(val, n) {
      return (+val).toFixed(n)
    },

    async getHoursStats() {
      try {
        if (!this.fiscalYear) {
          return
        }
        this.$bus.$emit('setWaiting', { name: 'getGroupMetricsData', message: 'Getting group metrics data' })
        const res = await appFuncs.shHttp({
          action: 'get_group_metrics_data',
          employee_code: this.viewAsEmployeeId,
          fiscalYear: this.fiscalYear,
          cost_group_code: this.selectedDepartment
        })
        const rst = res.data
        const rst_total = res.data_total

        this.total_labor_cost = rst.total_Total_Labor_Cost
        this.total_hours = rst.total_Total_Hours
        this.average_labor_burden = rst.avg_Labour_Burden
        this.ub_safe_quoting_cost = rst.total_Unbillable_Costs + rst.total_Safety_Costs + rst.total_Quoting_Costs
        this.billable_costs = rst.total_Billable_Costs
        this.billable_hours = rst.total_Billable_Hours
        this.unbillable_costs = rst.total_Unbillable_Costs
        this.unbillable_hours = rst.total_Unbillable_Hours
        this.safety_costs = rst.total_Safety_Costs
        this.safety_hours = rst.total_Safety_Hours
        this.quoting_costs = rst.total_Quoting_Costs
        this.quoting_hours = rst.total_Quoting_Hours

        this.ub_safe_quoting_cost_in_total = (100 * this.ub_safe_quoting_cost) / (rst_total.total_Unbillable_Costs + rst_total.total_Safety_Costs + rst_total.total_Quoting_Costs)

        // between departments
        // this.billable_costs_in_total = (100 * this.billable_costs) / rst_total.total_Billable_Costs
        // this.billable_hours_in_total = (100 * this.billable_hours) / rst_total.total_Billable_Hours
        // this.unbillable_costs_in_total = (100 * this.unbillable_costs) / rst_total.total_Unbillable_Costs
        // this.unbillable_hours_in_total = (100 * this.unbillable_hours) / rst_total.total_Unbillable_Hours
        // this.safety_costs_in_total = (100 * this.safety_costs) / rst_total.total_Safety_Costs
        // this.safety_hours_in_total = (100 * this.safety_hours) / rst_total.total_Safety_Hours
        // this.quoting_costs_in_total = (100 * this.quoting_costs) / rst_total.total_Quoting_Costs
        // this.quoting_hours_in_total = (100 * this.quoting_hours) / rst_total.total_Quoting_Hours

        // within department
        this.billable_costs_in_total = (100 * this.billable_costs) / rst.total_Total_Labor_Cost
        this.billable_hours_in_total = (100 * this.billable_hours) / rst.total_Total_Hours
        this.unbillable_costs_in_total = (100 * this.unbillable_costs) / rst.total_Total_Labor_Cost
        this.unbillable_hours_in_total = (100 * this.unbillable_hours) / rst.total_Total_Hours
        this.safety_costs_in_total = (100 * this.safety_costs) / rst.total_Total_Labor_Cost
        this.safety_hours_in_total = (100 * this.safety_hours) / rst.total_Total_Hours
        this.quoting_costs_in_total = (100 * this.quoting_costs) / rst.total_Total_Labor_Cost
        this.quoting_hours_in_total = (100 * this.quoting_hours) / rst.total_Total_Hours
      } catch (error) {
        snack.open('Problem getting hours stats', 'error')
      } finally {
        this.$bus.$emit('stopWaiting', 'getGroupMetricsData')
      }
    },

    async getData() {
      this.getHoursStats()
    }
  },

  mounted() {
    this.getData()
  },

  watch: {
    viewAsEmployeeId: {
      handler() {
        this.getData()
      },
      immediate: true
    },
    fiscalYear: {
      handler() {
        this.getData()
      },
      immediate: true
    },
    selectedDepartment: {
      handler() {
        this.getData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.sh-widget {
  width: 700px;
  overflow: hidden;
  max-width: 700px !important;

  .widget-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .group-metrics {
    display: flex;
    flex-direction: column;

    .metrics-row {
      display: flex;
      flex-direction: row;
      border-width: 1px 1px 1px 4px;
      border-style: solid;
      border-color: #ebe8e8 #ebe8e8 #ebe8e8 $primary-color;
      margin-bottom: 10px;
      border-radius: 2px 4px 4px 2px;

      .metrics-item {
        width: 32%;
        padding: 8px 8px 8px 16px;
        display: flex;
        flex-direction: column;
        gap: 3px;

        span {
          color: #61605e;

          @media (max-width: 768px) {
            font-size: 10px;
          }
        }

        p {
          margin-bottom: 0px;
          font-size: 20px;
          font-weight: 500;

          @media (max-width: 768px) {
            font-size: 14px;
            font-weight: bold;
          }
        }

        .compare-with-total {
          margin-bottom: 0px;
          font-size: 14px;
          font-weight: 400;
          color: #61605e;
        }
      }
    }
  }
}
</style>
