
<div class="modal fade" id="siteModal" tabindex="-1" role="dialog" aria-labelledby="siteModalLabel" data-keyboard="false" data-backdrop="static">
  <div v-bind:class="classes" role="document">
    <div v-bind:class="contentClasses">
      <div class="modal-body">
        <h4 v-if="modal.title" class="modal-title" id="siteModalLabel">{{ modal.title }}</h4>
        {{ modal.content }}
        <div class="button-container">
          <button v-for="button in modal.buttons" v-on:click="modalFunction(button.context, button.buttonFunc, button.buttonParams)" type="button" class="btn btn-primary">
            {{ button.buttonText }}
          </button>
          <router-to v-if="modal.route" type="button" class="btn btn-primary">{{ modal.buttonText }}</router-to>
        </div>

        <div v-if="modal.component">
          <component v-bind:is="modal.component" :componentData="componentData"></component>
        </div>
      </div>
    </div>
    <button v-if="!modal.hideClose" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
  </div>
</div>
