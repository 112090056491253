
<div>
  <div class="row">
    <div class="col-sm-12">
      <div :class="['group-container', { 'separate-section': separateSection }]" v-if="singleField">
        <table>
          <thead>
            <tr>
              <th>{{ label }}</th>
              <th class="control-column"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="form-group">
                  <vue-numeric
                    v-if="options.length && valueType === 'dollar'"
                    currency="$"
                    separator=","
                    :class="['form-control']"
                    :precision="2"
                    v-model="options[0].value"
                    inputmode="decimal"
                    :read-only="detailValue"
                  />
                  <input type="text" v-else-if="options.length" v-model="options[0].value" class="form-control fake-readonly" @click="editItem(options[0])" :readonly="detailValue" />
                </div>
              </td>
              <td class="control-column">
                <div class="controls">
                  <span v-if="hasChange(options[0])" @click="saveOption(options[0])" class="edit-save is-link">
                    <i class="ti-check" />
                  </span>
                  <span v-if="hasChange(options[0])" @click="cancelChange(options[0])" class="edit-cancel is-link">
                    <i class="ti-na" />
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div :class="['group-container', { 'separate-section': separateSection }]" v-else>
        <table>
          <thead>
            <tr>
              <th>{{ label }}</th>
              <th v-if="showValue">Value</th>
              <th class="default-column" v-if="showDefault">Default</th>
              <th class="control-column"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="option in sortedOptions" :key="option.id">
              <td class="key-column">
                <div class="form-group">
                  <vue-numeric v-if="valueType === 'dollar'" currency="$" separator="," :class="['form-control']" :precision="2" v-model="option.name" inputmode="decimal" :readonly="detailValue" />
                  <input type="text" v-else v-model="option.name" class="form-control fake-readonly" @click="editItem(option)" :readonly="detailValue" />
                </div>
              </td>
              <td v-if="showValue" class="value-column">
                <div class="form-group">
                  <input type="text" v-model="option.value" class="form-control fake-readonly" @click="editItem(option)" :readonly="true" />
                </div>
              </td>
              <td class="default-column" v-if="showDefault">
                <checkbox2 v-model="option.default" :true-val="'yes'" :false-val="'no'" @change="setDefault(option)" />
              </td>
              <td class="control-column">
                <div class="controls">
                  <span v-if="hasChange(option)" @click="saveOption(option)" class="edit-save is-link">
                    <i class="ti-check" />
                  </span>
                  <span v-if="hasChange(option)">
                    <span @click="cancelChange(option)" class="edit-cancel is-link">
                      <i class="ti-na" />
                    </span>
                  </span>
                  <span v-else>
                    <span @click="moveItemUp(option)" class="edit-move is-link">
                      <i class="ti-angle-up" />
                    </span>
                    <span @click="moveItemDown(option)" class="edit-move is-link">
                      <i class="ti-angle-down" />
                    </span>
                  </span>
                  <span @click="deleteItem(option)" class="edit-delete is-link" v-if="allowDelete">
                    <i class="ti-close" />
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <button class="btn btn-sm" @click="addOption">Add Option</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <waiting-spinner />
</div>
