<template>
  <div>
    <div class="content">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <label for="po_report_send_month_day">Auto Send Month Day</label>
            <p class="sub-label">
              Number from 1-29
            </p>
            <input type="number" class="form-control" name="po_report_send_month_day" v-model="appOptions.po_report_send_month_day" rows="5">
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-group">
            <span class="inline-checkbox">
              <checkbox v-model="appOptions.po_report_enable_auto_send">
                Enable Report Auto Send
              </checkbox>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="po_report_default_text">Report Default Text</label>
            <textarea
              class="form-control"
              name="po_report_default_text"
              rows="5"
              :value="decode(appOptions.po_report_default_text)"
              @input="appOptions.po_report_default_text = encode($event.target.value, 'stripTags')"
            />
          </div>
          <div class="form-group">
            <label for="po_report_email_text">Report Email Text</label>
            <textarea
              class="form-control"
              name="po_report_email_text"
              rows="5"
              :value="decode(appOptions.po_report_email_text)"
              @input="appOptions.po_report_email_text = encode($event.target.value, 'stripTags')"
            />
          </div>
          <input type="submit" class="btn" @click="update()" value="Update">
        </div>
      </div>
      <waiting-spinner/>
    </div>
  </div>
</template>
<script>

import appFuncs from 'appFuncs';
import {mapGetters} from 'vuex';
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'POReportOptions',
  components: {WaitingSpinner},
  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      'appOptions',
    ])
  },

  methods: {

    update() {
      this.appOptions.po_report_enable_auto_send = this.appOptions.po_report_enable_auto_send == true ? 1 : 0;
      const params = {
        action: 'update_app_options',
        options: this.appOptions
      };
      this.$bus.$emit('setWaiting', {name: params.action, message: 'Getting App Options'});
      appFuncs.shRequest(params, false).then((res) => {
        if (res && res.status === 'success') {
          if (res.updated && res.updated.status === 'success') {
            this.$store.commit('appOptions', res.updated.data);
          }
          this.$snack.open('Options updated');
        } else {
          this.$snack.open('Problem updating options', 'warning');
        }
      }).catch((res) => {
        this.$snack.open(res.message || 'Problem updating options', 'warning');
        console.log(res);
      }).finally(() => {
        this.$bus.$emit('stopWaiting', params.action);
      });
    }
  },

  mounted() {
    this.$bus.$emit('setWaiting', {name: 'getAppOptions', message: 'Getting App Options'});
    this.$store.dispatch('getAppOptions', 1).finally(() => {
      this.$bus.$emit('stopWaiting', 'getAppOptions');
    });
  },

  watch: {
    'appOptions.po_report_send_month_day': function (val) {
      if (parseInt(val) > 29) {
        this.appOptions.po_report_send_month_day = 29;
      } else if (parseInt(val) < 1) {
        this.appOptions.po_report_send_month_day = 1;
      }
    },

    'appOptions.po_report_enable_auto_send': {
      handler() {
        console.log('watch')
        this.appOptions.po_report_enable_auto_send = this.appOptions.po_report_enable_auto_send == 1 ? 1 : 0;
      },
      immediate: true
    }
  }

};
</script>
