<template>
  <div>
    <div class="customer-ticket" v-if="wo.WO_Number">
      <div class="container">
        <div class="row header">
          <div class="col-xs-12 col-sm-4">
            <router-link to="/">
              <img :src="`${urls.frontend}static/img/${logoFile}`" />
            </router-link>
          </div>
          <div class="col-xs-12 col-sm-8 text-right">
            <h4>
              Work Order:<strong> #{{ wo.WO_Number }}</strong>
            </h4>
            <h5>
              Office: <strong>{{ regionTel }}</strong>
            </h5>
          </div>
        </div>

        <div class="row" v-if="contact.Id">
          <div class="col-sm-12">
            <div class="ticket-section border-left">
              <h3>
                Hello, <strong>{{ contact.Name }}</strong>
              </h3>
              <button class="btn pull-right" @click="openReviewModal">Add Feedback</button>
              <div class="row">
                <div class="col-sm-12 col-md-4">
                  <checkbox2 v-model="emailNotifications" @change="customerEdit = true" true-val="yes" false-val="no"> Email Notifications </checkbox2>
                  <div v-if="customerEdit" class="form-group">
                    <input type="text" class="form-control border-input" v-model="contact.Email" placeholder="Email Address" />
                  </div>
                  <p v-else>
                    {{ contact.Email }}
                    <span v-if="!contact.Email"><i>(Email not yet added)</i></span>
                    &nbsp;<i v-if="!customerEdit" @click="customerEdit = true" class="ti ti-pencil customerEdit" />
                  </p>
                </div>
                <div class="col-sm-12 col-md-4">
                  <checkbox2 v-model="textNotifications" @change="customerEdit = true" true-val="yes" false-val="no"> Text Notifications </checkbox2>
                  <div v-if="customerEdit" class="form-group">
                    <input type="text" class="form-control border-input" v-model="contact.MobilePhone" v-mask="chooseMask(contact.MobilePhone)" placeholder="Mobile Phone Number" />
                  </div>
                  <p v-else>
                    {{ contact.MobilePhone }}
                    <span v-if="!contact.MobilePhone"><i>(not yet added)</i></span>
                    &nbsp;<i v-if="!customerEdit" @click="customerEdit = true" class="ti ti-pencil customerEdit" />
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <button v-if="customerEdit" class="btn" @click="updateNotificationPreferences">Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="woStatus">
          <div class="col-sm-12 wo-status-container">
            <span>
              <h3 class="h5">Work Order Details</h3>
            </span>
            <span v-if="woStatus" class="wo-status">
              Status: <span :class="woStatusClass">{{ woStatus }}</span>
            </span>
          </div>
        </div>

        <div class="row" v-if="wo.customer_name">
          <div class="col-sm-12">
            <div class="ticket-section">
              <div class="ticket-section--top">
                <label><strong>General</strong></label>
              </div>
              <div class="row ticket-fields">
                <div class="col-sm-12 col-md-6">
                  <div class="ticket-field">
                    <label>Customer:</label>
                    <p>{{ wo.customer_name }}</p>
                  </div>
                  <div class="ticket-field">
                    <label>Site Address:</label>
                    <p>
                      <span v-if="wo.site_address" v-html="wo.site_address" />
                      <br />
                      <span v-if="wo.site_city" v-html="wo.site_city" /><span v-if="wo.site_province" v-html="', ' + wo.site_province" /><span
                        v-if="wo.site_postal_code"
                        v-html="', ' + wo.site_postal_code"
                      />
                    </p>
                  </div>
                  <div class="ticket-field">
                    <label>Work Description:</label>
                    <p>{{ wo.Summary_Description }}</p>
                  </div>

                  <div class="ticket-field form-group" style="margin-top: 20px">
                    <label>Upload PO file:</label>
                    <file-upload :path="`wo-uploads/${wo.WO_Number}/`" subdir="po" :auth-code="authCode" :upload-data="uploadData"/>
                  </div>
                  <div class="ticket-field form-group">
                    <label>Customer PO:</label>
                    <input type="text" v-model="wo.Customer_PO_Number" class="form-control" style="max-width: 300px" />
                  </div>
                  <div :class="'detail-item'">
                    <checkbox2 v-model="wo.po_request_muted_by_customer" :true-val="'yes'" :false-val="'no'"> Mute PO Request</checkbox2>
                  </div>
                  <button @click="updateCustomerProvidedData" class="btn btn-sm" style="margin-top:10px">Save</button>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="ticket-field">
                    <label>Project Lead:</label>
                    <p>{{ wo.PM_Name }}</p>
                  </div>
                  <div class="ticket-field">
                    <label>Lead Tech:</label>
                    <p>{{ wo.Lead_Name }}</p>
                  </div>
                  <div class="ticket-field">
                    <label>Techs:</label>
                    <p v-for="(tech, t) in wo.techs_meta" :key="t">
                      {{ tech.Employee_Name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="wo.customer_name">
          <div class="col-sm-12">
            <div class="ticket-section">
              <div class="ticket-section--top">
                <label><strong>Status Details</strong></label>
              </div>
              <div class="row ticket-fields">
                <div class="col-sm-12 col-md-6">
                  <div class="ticket-field">
                    <label>Ordered Date:</label>
                    <p v-html="wo.Ordered_Date ? formatDate(wo.Ordered_Date, 1) : 'TBD'" />
                  </div>
                  <div class="ticket-field">
                    <label>Date Requested:</label>
                    <p v-html="wo.Requested_Date ? formatDate(wo.Requested_Date, 1) : 'TBD'" />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="ticket-field">
                    <label>Finished Date:</label>
                    <p v-html="wo.Complete_Date ? formatDate(wo.Complete_Date, 1) : 'TBD'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="wo.WO_Number">
          <div class="col-sm-12" v-for="(note, k) in notes" :key="k">
            <div class="ticket-section" v-if="note.note.length">
              <div class="ticket-section--top">
                <label
                  ><strong>{{ note.title }}</strong></label
                >
              </div>
              <div class="row ticket-fields">
                <div class="col-sm-12">
                  <div class="ticket-field">
                    <ul class="ticket-notes show-scrollbar" v-if="note.note.length">
                      <li v-for="(n, i) in note.note" :key="i">
                        <p class="note-meta">
                          <strong>{{ n.author_name }} - {{ n.nice_date }}</strong>
                        </p>
                        <p v-html="getPlainText(n.note_text)" class="note-text" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="wo.WO_Number && preWorkPhotos && preWorkPhotos.length">
          <div class="col-sm-12">
            <div class="ticket-section">
              <div class="ticket-section--top">
                <label><strong>Pre-Work Photos</strong></label>
              </div>
              <div class="row ticket-fields">
                <div class="col-sm-12">
                  <ul class="photos" v-if="preWorkPhotos.length">
                    <li v-for="(photo, p) in preWorkPhotos" :key="p">
                      <a :href="photo.a_attr.href" target="_blank" class="img-container" :style="{ backgroundImage: `url('${photo.a_attr.href}')` }" />
                    </li>
                  </ul>
                  <p v-else>
                    <span class="empty">(empty)</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="wo.WO_Number && postWorkPhotos && postWorkPhotos.length">
          <div class="col-sm-12">
            <div class="ticket-section">
              <div class="ticket-section--top">
                <label><strong>Post-Work Photos</strong></label>
              </div>
              <div class="row ticket-fields">
                <div class="col-sm-12">
                  <ul class="photos" v-if="postWorkPhotos.length">
                    <li v-for="(photo, pt) in postWorkPhotos" :key="pt">
                      <a :href="photo.a_attr.href" target="_blank" class="img-container" :style="{ backgroundImage: `url('${photo.a_attr.href}')` }" />
                    </li>
                  </ul>
                  <p v-else>
                    <span class="empty">(empty)</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="wo.WO_Number && signedWOs && signedWOs.length">
          <div class="col-sm-12">
            <div class="ticket-section">
              <div class="ticket-section--top">
                <label><strong>Service Sign-Offs</strong></label>
              </div>
              <div class="row ticket-fields">
                <div class="col-sm-12">
                  <ul class="photos" v-if="signedWOs.length">
                    <li v-for="(swo, pt) in signedWOs" :key="pt">
                      <a :href="swo.a_attr.href" target="_blank" :class="'pdf-icon'">
                        <img :src="'static/img/pdf-icon.svg'" />
                        {{ swo.text }}
                      </a>
                    </li>
                  </ul>
                  <p v-else>
                    <span class="empty">(empty)</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <waiting-spinner />
  </div>
</template>
<script>
import store from 'store'
import appFuncs from 'appFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import FileUpload from 'components/UIComponents/FileUpload.vue'
import WOFuncs from 'mixins/woFuncs'
import Checkbox2 from 'components/UIComponents/Checkbox.vue'

export default {
  components: { WaitingSpinner, FileUpload, Checkbox2 },
  data() {
    return {
      wo: {},
      contact: {
        Email: '',
        PhoneMobile: ''
      },
      notes: [],
      customerEdit: false,
      preWorkPhotos: [],
      postWorkPhotos: [],
      emailNotifications: null,
      textNotifications: null,
      signedWOs: [],
      reviewData: {},
      customerPONumber: null,
      isUpload: false,
      mutePORequest: 'yes'
    }
  },

  props: {
    authCode: {
      type: String,
      required: true
    }
  },

  mixins: [WOFuncs],

  computed: {
    uploadData() {
      return {
        WO_Number: this.wo.WO_Number
      }
    },

    urls() {
      return this.$store.getters.urls
    },

    woStatus() {
      const statuses = this.woDispatchStatuses
      const statusName = statuses.find(itm => {
        return itm.code === this.wo.Dispatch_Status_Code
      })
      if (statusName) {
        return statusName.description
      }
      return ''
    },

    woStatusClass() {
      switch (this.wo.Dispatch_Status_Code) {
        case '1':
        case '2':
        case '3':
        case '4':
        case '7':
        case 'F':
          return 'green'
        case '5':
          return 'yellow'
        case '6':
          return 'red'
        default:
          return ''
      }
    },

    companyName() {
      const vars = this.getRegionVars(this.wo.region)
      return vars.companyName
    },

    logoFile() {
      const vars = this.getRegionVars(this.wo.region)
      return vars.logoFile
    },

    regionAddress() {
      const vars = this.getRegionVars(this.wo.region)
      return vars.address
    },

    regionTel() {
      const vars = this.getRegionVars(this.wo.region)
      return vars.tel
    }
  },

  methods: {
    getData() {
      if (!this.authCode) {
        return
      }

      const params = {
        action: 'get_customer_wo_ticket',
        auth_code: this.authCode,
        urldecode: 'false'
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Getting Data' })

      appFuncs
        .shRequest(params, 1)
        .then(data => {
          this.wo = data.wo
          this.contact = data.contact_data || {}
          this.notes = [] // clear notes to prevent duplicates when calling get data again.
          this.notes.push({ title: 'Scope of Work', note: ((data.notes || {}).wo_notes || {}).data || [] }) // scope of work notes
          this.notes.push({ title: 'Scope Notes', note: ((data.notes || {}).wo_work_ordered_notes || {}).data || [] })
          this.notes.push({ title: 'Work Completed', note: ((data.notes || {}).wo_completed_notes || {}).data || [] })
          this.notes.push({ title: 'Materials', note: ((data.notes || {}).wo_materials || {}).data || [] })
          this.notes.push({ title: 'Work Order Extras', note: ((data.notes || {}).wo_extras_notes || {}).data || [] })
          this.notes.push({ title: 'Recommendations', note: ((data.notes || {}).wo_recommendation_notes || {}).data || [] })
          this.preWorkPhotos = (data.preWorkPhotos || []).data || []
          this.postWorkPhotos = (data.postWorkPhotos || []).data || []
          this.signedWOs = (data.signedWOs || []).data || []
          this.reviewData = ((data.review || {}).data || {})[0] || {}
          this.setUserOptions()
        })
        .catch(e => {
          console.log(e)
          this.$snack.open('Problem retrieving Work Order data, please try again now or later.', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    setUserOptions() {
      this.emailNotifications = false
      if (this.wo.notifications_email && this.wo.notifications_email.length && this.contact.Id) {
        if (this.wo.notifications_email.indexOf(this.contact.Id.toString()) !== -1) {
          this.emailNotifications = 'yes'
        }
        if (this.contact.MYS_ID__c && this.wo.notifications_email.indexOf(this.contact.MYS_ID__c.toString()) !== -1) {
          this.emailNotifications = 'yes'
        }
      }

      this.textNotifications = false
      if (this.wo.notifications_text && this.wo.notifications_text.length && this.contact.Id) {
        if (this.wo.notifications_text.indexOf(this.contact.Id.toString()) !== -1) {
          this.textNotifications = 'yes'
        }
        if (this.contact.MYS_ID__c && this.wo.notifications_text.indexOf(this.contact.MYS_ID__c.toString()) !== -1) {
          this.textNotifications = 'yes'
        }
      }
    },

    updateNotificationPreferences() {
      if (this.emailNotifications && !this.contact.Email) {
        this.$snack.open('Email address must be added before enabling email notifications.', 'error')
        return
      }

      if (this.contact.MobilePhone && this.stripToNumber(this.contact.MobilePhone).length < 10) {
        this.$snack.open('Mobile phone number is too short', 'warning')
        return
      }

      if (this.textNotifications && !this.contact.MobilePhone) {
        this.$snack.open('Mobile phone number must be added before enabling text notifications.', 'warning')
        return
      }

      const params = {
        action: 'update_customer_contact_preferences',
        auth_code: this.authCode,
        email: this.contact.Email,
        mobile_phone: this.contact.MobilePhone,
        // set true or false for this contact, will add or remove to WO notifications array in backend
        email_notification: this.emailNotifications,
        text_notification: this.textNotifications
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Updating preferences' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.$snack.open('Preferences successfully updated')
        })
        .catch(res => {
          this.$snack.open('Problem saving preferences, please try again later', 'warning')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
          this.customerEdit = false
          this.getData()
        })
    },

    updateCustomerProvidedData() {
      const params = {
        action: 'update_customer_provided_data',
        auth_code: this.authCode,
        data: {
          wo_number: this.wo.WO_Number,
          Customer_PO_Number: this.wo.Customer_PO_Number,
          po_request_muted_by_customer: this.wo.po_request_muted_by_customer,
          from: 'customer_ticket',
          type: 'wo'
        }
      }

      this.$bus.$emit('setWaiting', { name: params.action, message: 'Updating customer provided data' })

      appFuncs
        .shRequest(params)
        .then(() => {
          this.$snack.open('Updated')
        })
        .catch(e => {
          this.$snack.open('Problem saving data, please try again later', 'warning')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', params.action)
        })
    },

    openReviewModal() {
      this.$bus.$emit('modalOpen', {
        component: () => import('src/components/Dashboard/JobWo/WOContactReview'),
        size: 'modal-md',
        name: 'wo-contact-review',
        title: 'Tell Us About Your Experience',
        componentData: {
          component: 'wo-contact-review',
          authCode: this.authCode,
          woNumber: this.wo.WO_Number,
          contactId: this.contact.Id
        }
      })
    },

    openNoteModal(title, notes) {
      this.$bus.$emit('modalOpen', {
        component: () => import('src/components/Dashboard/JobWo/WONoteAllTopic'),
        name: 'wo-note-all-topic',
        size: 'modal-md',
        title: title,
        componentData: {
          component: 'wo-note-all-topic',
          notes: notes
        }
      })
    },

    getPlainText(text) {
      return appFuncs.getPlainText(text)
    }
  },

  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.header {
  padding: 40px 0 30px;

  img {
    width: 100%;
    max-width: 300px;

    @media (max-width: 767px) {
      margin: 0 auto;
      display: block;
    }
  }

  h4 {
    margin: 20px 0 0;
  }

  h5 {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    > .col-xs-12 {
      text-align: center !important;
    }
  }
}

.customerEdit {
  cursor: pointer;
}

.wo-status-container {
  h3 {
    display: inline-block;
  }

  .wo-status {
    padding: 10px 15px;
    text-align: right;
    font-size: 16px;
    float: right;

    span {
      text-transform: uppercase;
      font-weight: bold;

      &.green {
        color: $success-color;
      }

      &.yellow {
        color: $general-yellow;
      }

      &.red {
        color: $general-red;
      }
    }
  }
}

.ticket-section {
  background: $smoke-bg;
  padding: 20px;
  margin-bottom: 20px;

  &.border-left {
    border-left: 5px solid $dark-gray;
  }

  label {
    display: block;
  }

  &--top {
    border-bottom: 1px solid $grey-border-color;
    margin-bottom: 15px;
    padding-bottom: 5px;

    h5 {
      font-size: 14px;
      margin: 0 0 5px;
    }
  }

  h3 {
    font-size: 18px;
    margin: 10px 0;
  }

  .form-control {
    border: 1px solid;
    background: #fff;
  }

  .checkbox-container .checkbox-group {
    background-color: #fff;
  }

  ul.ticket-notes {
    padding: 0;
    max-height: 300px;
    width: 100%;
    overflow-y: scroll;

    li {
      display: block;

      .note-text {
        border-left: 3px solid $grey-border-color;
        padding: 2px 5px;
        margin-left: 5px;
      }
    }
  }

  .photos {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 5px 0 0;
      width: 175px;
      position: relative;

      .img-container {
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        position: relative;
        background-size: cover;
      }

      @media (max-width: 991px) {
        width: calc(33% - 5px);
      }
      @media (max-width: 767px) {
        width: calc(50% - 5px);
      }
    }
  }

  .empty {
    font-style: italic;
  }
}

.pdf-icon {
  width: 100px;
  height: 100px;
  display: block;
  //background-image:url('/static/img/pdf-icon.svg');
  //background-size:contain;
  margin: 5px;
}
</style>

<style lang="scss">
.ticket-section {
  .checkbox-container .checkbox-group {
    background: #fff;
  }
}
</style>
