
<div>
  <div class="customer-ticket" v-if="wo.WO_Number">
    <div class="container">
      <div class="row header">
        <div class="col-xs-12 col-sm-4">
          <router-link to="/">
            <img :src="`${urls.frontend}static/img/${logoFile}`" />
          </router-link>
        </div>
        <div class="col-xs-12 col-sm-8 text-right">
          <h4>
            Work Order:<strong> #{{ wo.WO_Number }}</strong>
          </h4>
          <h5>
            Office: <strong>{{ regionTel }}</strong>
          </h5>
        </div>
      </div>

      <div class="row" v-if="contact.Id">
        <div class="col-sm-12">
          <div class="ticket-section border-left">
            <h3>
              Hello, <strong>{{ contact.Name }}</strong>
            </h3>
            <button class="btn pull-right" @click="openReviewModal">Add Feedback</button>
            <div class="row">
              <div class="col-sm-12 col-md-4">
                <checkbox2 v-model="emailNotifications" @change="customerEdit = true" true-val="yes" false-val="no"> Email Notifications </checkbox2>
                <div v-if="customerEdit" class="form-group">
                  <input type="text" class="form-control border-input" v-model="contact.Email" placeholder="Email Address" />
                </div>
                <p v-else>
                  {{ contact.Email }}
                  <span v-if="!contact.Email"><i>(Email not yet added)</i></span>
                  &nbsp;<i v-if="!customerEdit" @click="customerEdit = true" class="ti ti-pencil customerEdit" />
                </p>
              </div>
              <div class="col-sm-12 col-md-4">
                <checkbox2 v-model="textNotifications" @change="customerEdit = true" true-val="yes" false-val="no"> Text Notifications </checkbox2>
                <div v-if="customerEdit" class="form-group">
                  <input type="text" class="form-control border-input" v-model="contact.MobilePhone" v-mask="chooseMask(contact.MobilePhone)" placeholder="Mobile Phone Number" />
                </div>
                <p v-else>
                  {{ contact.MobilePhone }}
                  <span v-if="!contact.MobilePhone"><i>(not yet added)</i></span>
                  &nbsp;<i v-if="!customerEdit" @click="customerEdit = true" class="ti ti-pencil customerEdit" />
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <button v-if="customerEdit" class="btn" @click="updateNotificationPreferences">Update</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="woStatus">
        <div class="col-sm-12 wo-status-container">
          <span>
            <h3 class="h5">Work Order Details</h3>
          </span>
          <span v-if="woStatus" class="wo-status">
            Status: <span :class="woStatusClass">{{ woStatus }}</span>
          </span>
        </div>
      </div>

      <div class="row" v-if="wo.customer_name">
        <div class="col-sm-12">
          <div class="ticket-section">
            <div class="ticket-section--top">
              <label><strong>General</strong></label>
            </div>
            <div class="row ticket-fields">
              <div class="col-sm-12 col-md-6">
                <div class="ticket-field">
                  <label>Customer:</label>
                  <p>{{ wo.customer_name }}</p>
                </div>
                <div class="ticket-field">
                  <label>Site Address:</label>
                  <p>
                    <span v-if="wo.site_address" v-html="wo.site_address" />
                    <br />
                    <span v-if="wo.site_city" v-html="wo.site_city" /><span v-if="wo.site_province" v-html="', ' + wo.site_province" /><span
                      v-if="wo.site_postal_code"
                      v-html="', ' + wo.site_postal_code"
                    />
                  </p>
                </div>
                <div class="ticket-field">
                  <label>Work Description:</label>
                  <p>{{ wo.Summary_Description }}</p>
                </div>

                <div class="ticket-field form-group" style="margin-top: 20px">
                  <label>Upload PO file:</label>
                  <file-upload :path="`wo-uploads/${wo.WO_Number}/`" subdir="po" :auth-code="authCode" :upload-data="uploadData"/>
                </div>
                <div class="ticket-field form-group">
                  <label>Customer PO:</label>
                  <input type="text" v-model="wo.Customer_PO_Number" class="form-control" style="max-width: 300px" />
                </div>
                <div :class="'detail-item'">
                  <checkbox2 v-model="wo.po_request_muted_by_customer" :true-val="'yes'" :false-val="'no'"> Mute PO Request</checkbox2>
                </div>
                <button @click="updateCustomerProvidedData" class="btn btn-sm" style="margin-top:10px">Save</button>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="ticket-field">
                  <label>Project Lead:</label>
                  <p>{{ wo.PM_Name }}</p>
                </div>
                <div class="ticket-field">
                  <label>Lead Tech:</label>
                  <p>{{ wo.Lead_Name }}</p>
                </div>
                <div class="ticket-field">
                  <label>Techs:</label>
                  <p v-for="(tech, t) in wo.techs_meta" :key="t">
                    {{ tech.Employee_Name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="wo.customer_name">
        <div class="col-sm-12">
          <div class="ticket-section">
            <div class="ticket-section--top">
              <label><strong>Status Details</strong></label>
            </div>
            <div class="row ticket-fields">
              <div class="col-sm-12 col-md-6">
                <div class="ticket-field">
                  <label>Ordered Date:</label>
                  <p v-html="wo.Ordered_Date ? formatDate(wo.Ordered_Date, 1) : 'TBD'" />
                </div>
                <div class="ticket-field">
                  <label>Date Requested:</label>
                  <p v-html="wo.Requested_Date ? formatDate(wo.Requested_Date, 1) : 'TBD'" />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="ticket-field">
                  <label>Finished Date:</label>
                  <p v-html="wo.Complete_Date ? formatDate(wo.Complete_Date, 1) : 'TBD'" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="wo.WO_Number">
        <div class="col-sm-12" v-for="(note, k) in notes" :key="k">
          <div class="ticket-section" v-if="note.note.length">
            <div class="ticket-section--top">
              <label
                ><strong>{{ note.title }}</strong></label
              >
            </div>
            <div class="row ticket-fields">
              <div class="col-sm-12">
                <div class="ticket-field">
                  <ul class="ticket-notes show-scrollbar" v-if="note.note.length">
                    <li v-for="(n, i) in note.note" :key="i">
                      <p class="note-meta">
                        <strong>{{ n.author_name }} - {{ n.nice_date }}</strong>
                      </p>
                      <p v-html="getPlainText(n.note_text)" class="note-text" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="wo.WO_Number && preWorkPhotos && preWorkPhotos.length">
        <div class="col-sm-12">
          <div class="ticket-section">
            <div class="ticket-section--top">
              <label><strong>Pre-Work Photos</strong></label>
            </div>
            <div class="row ticket-fields">
              <div class="col-sm-12">
                <ul class="photos" v-if="preWorkPhotos.length">
                  <li v-for="(photo, p) in preWorkPhotos" :key="p">
                    <a :href="photo.a_attr.href" target="_blank" class="img-container" :style="{ backgroundImage: `url('${photo.a_attr.href}')` }" />
                  </li>
                </ul>
                <p v-else>
                  <span class="empty">(empty)</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="wo.WO_Number && postWorkPhotos && postWorkPhotos.length">
        <div class="col-sm-12">
          <div class="ticket-section">
            <div class="ticket-section--top">
              <label><strong>Post-Work Photos</strong></label>
            </div>
            <div class="row ticket-fields">
              <div class="col-sm-12">
                <ul class="photos" v-if="postWorkPhotos.length">
                  <li v-for="(photo, pt) in postWorkPhotos" :key="pt">
                    <a :href="photo.a_attr.href" target="_blank" class="img-container" :style="{ backgroundImage: `url('${photo.a_attr.href}')` }" />
                  </li>
                </ul>
                <p v-else>
                  <span class="empty">(empty)</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="wo.WO_Number && signedWOs && signedWOs.length">
        <div class="col-sm-12">
          <div class="ticket-section">
            <div class="ticket-section--top">
              <label><strong>Service Sign-Offs</strong></label>
            </div>
            <div class="row ticket-fields">
              <div class="col-sm-12">
                <ul class="photos" v-if="signedWOs.length">
                  <li v-for="(swo, pt) in signedWOs" :key="pt">
                    <a :href="swo.a_attr.href" target="_blank" :class="'pdf-icon'">
                      <img :src="'static/img/pdf-icon.svg'" />
                      {{ swo.text }}
                    </a>
                  </li>
                </ul>
                <p v-else>
                  <span class="empty">(empty)</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <waiting-spinner />
</div>
