import Vue from 'vue';

// define global EventBus Prop
const EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return EventBus
    }
  }
})

export default EventBus;