
<div class="wrapper">
  <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks"/>
  <!-- <notifications>

  </notifications> -->
  <div class="main-panel">
    <top-navbar></top-navbar>

    <dashboard-content @click.native="toggleSidebar"></dashboard-content>

    <content-footer></content-footer>
  </div>
</div>
