
<div>
  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-12">
      <!-- <div class="form-group">
        <label>Customer Code (optional)</label>
        <input type="text" class="form-control" v-model="formVals.customer_code">
      </div> -->

      <div class="form-group">
        <label>Company Name</label><span class="required-star">*</span>
        <input type="text" class="form-control" v-model="formVals.company_name">
      </div>

      <div class="form-group">
        <label>Address 1</label><span class="required-star">*</span>
        <input type="text" class="form-control" v-model="formVals.address_1">
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label>Address 2</label>
        <input type="text" class="form-control" v-model="formVals.address_2">
      </div>

      <div class="form-group">
        <label>Postal Code</label><span class="required-star">*</span>
        <input
          type="text"
          class="form-control"
          v-model="formVals.postal_code"
          v-mask="chooseMaskPostalZip(formVals.postal_code)"
        >
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label>City</label><span class="required-star">*</span>
        <input type="text" class="form-control" v-model="formVals.city">
      </div>
      <div class="form-group">
        <label>Province</label><span class="required-star">*</span>
        <input type="text" class="form-control" v-model="formVals.state">
      </div>
    </div>
  </div>

  <input type="hidden" class="form-control" v-model="formVals.terms_code">
  <input type="hidden" class="form-control" v-model="formVals.tax_code">
  <button class="btn" @click="addData">Add Customer</button>
  <waiting-spinner/>

</div>
