
<div>
  <simple-header/>
  <div class="materials-admin material-lists-component">
    <br/>
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <div class="form-group">
          <select-field :options="[{ name: 'Pending' }, { name: 'Approved' }, { name: 'Rejected' }]" :option-display-keys="['name']" :option-val="'name'"
                        v-model="approvalStatus"
          />
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <button style="margin-right: 20px" class="btn pull-right" @click="approveAll()" v-html="'Approve Selected'"/>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <tabulator-table
          ref="materialAdminTable"
          :table-data="currentMaterials"
          :table-columns="materialTableColumns"
          :allow-new-row="false"
          @colClick="materialClick"
          :xtable-height="500"
          :max-table-height="1500"
          :table-striped="true"
          :table-condensed="false"
          :selectable="true"
        />
      </div>
    </div>
  </div>
  <waiting-spinner/>
</div>
