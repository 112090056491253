<template>
  <div class="max-width-large">
    <alert :alert="alert"/>
    <bh-table :table-settings="tableSettings" @edit-item="editItem"/>
    <waiting-spinner :wait="wait"/>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import BhTable from 'components/UIComponents/BhTable'
import EmployeeEdit from 'src/components/Dashboard/Contacts/EmployeeEdit.vue'
import Alert from 'components/UIComponents/Alert';
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  components: {
    BhTable,
    Alert,
    WaitingSpinner
  },

  props: {
    region: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      alert: {},
      wait: {message: ''},
      employeeList: [], // component local
      allData: [],
      tableSettings: {
        tableData: [],
        fields: {
          Employee_Code: {
            type: 'text',
            hiddenView: 1
          },
          Employee_Name: {
            name: 'Employee Name'
          },
          Employee_Extension: {
            name: 'Direct Phone',
            use: 'phone'
          },
          Employee_Mobile_Phone_action: {
            name: 'Mobile Phone',
            use: 'phone'
          },
          Employee_Email_action: {
            name: 'Email Address',
            use: 'email'
          },
          Region: {
            name: 'Default Region',
            use: 'default_region'
          },
          Cost_Group_Code: {
            name: 'Default Department',
            use: 'default_department'
          },
          Hub: {
            name: 'Hub',
            use: 'assigned_hub'
          }
        }
      },
      hubs: [
        { name: 'London: 594 Newbold Street, London', val: 'london' },
        { name: 'Hamilton: 589 Barton Street, Stoney Creek', val: 'hamilton' },
        { name: 'Cambridge: 2401 Fountain Street South #901, Ayr', val: 'cambridge' },
        { name: 'Mississauga: 966 Pantera Drive, Mississauga', val: 'missisauga' },
        { name: 'Vaughan: Wonderland (Highway 400 – Major Mackenzie Drive)', val: 'vaughan' },
        { name: 'Toronto East: 401/Port Union Road Carpool Lot (MTO Carpool Lot, Toronto)', val: 'tor_east' }
      ]
    }
  },

  computed: {
    ...mapGetters(['employees', 'regions', 'emCostGroups'])
  },

  watch: {
    region() {
      this.regionFilter()
    }
  },

  methods: {
    regionFilter() {
      this.tableSettings.tableData = this.allData.filter(itm => {
        if (this.region == null || this.region == '') {
          return true
        }
        return itm.default_region === this.region
      })
    },
    async getData() {
      this.wait.message = 'Getting employee list'
      await this.$store.dispatch('getAppOptions')
      await this.$store.dispatch('getEmCostGroups')
      this.$store
        .dispatch('getEmployees')
        .then(() => {
          this.employeeList = [...this.employees].filter(itm => {
            return itm.Employment_Status === 'A'
          })
          let elements = []
          for (const element of this.employeeList) {
            let region_name = ''
            if (element.default_region != null && element.default_region != undefined && element.default_region != '') {
              for (const region of this.regions) {
                if (element.default_region == region.id) {
                  region_name = region.name
                }
              }
            }
            const hub = this.hubs.find((e) => e.val === element.assigned_hub)
            element.Hub = hub ? hub.name : ''

            const defaultDepartment = this.emCostGroups.find((e) => e.Cost_Group_Code === element.default_department)
            element.Cost_Group_Code = defaultDepartment ? defaultDepartment.Cost_Group_Description : ''

            element.Region = region_name

            element.Employee_Mobile_Phone_action = {
              title: element.Employee_Mobile_Phone,
              action: 'openNumber'
            }
            element.Employee_Email_action = {
              title: element.Employee_Email,
              action: 'openEmail'
            }

            elements.push(element)
          }
          this.allData = elements
          this.regionFilter()
        })
        .catch(res => {
          this.alert = {
            show: 1,
            message: res.message || 'Problem getting employee data',
            state: 'danger',
            dismiss: 'manual'
          }
        })
        .finally(() => {
          this.wait.message = ''
        })
    },
    editItem(employeeCode) {
      this.$Modal({
        parent: this,
        name: 'EmployeeEdit',
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        component: EmployeeEdit,
        props: {
          employeeCode
        }
      })
    }
  },

  mounted() {
    this.getData()
    this.$bus.$on('EmployeeEdit', () => {
      this.getData()
    })

    this.$bus.$on('openNumber', obj => {
      location.href = `tel:${obj.Employee_Mobile_Phone}`
    })

    this.$bus.$on('openEmail', obj => {
      location.href = `mailto:${obj.Employee_Email}`
    })
  }
}
</script>

<style lang="scss" scoped></style>
