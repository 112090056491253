
<div>
  <div class="row">
    <div class="col col-sm-12">
      <div class="tab-section white">
        <ul class="nav nav-tabs">
          <li role="presentation" :class="[{ active: tab == 'Terms' }]">
            <a href="#" @click="tab = 'Terms'"> Terms and Payment </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Costing' }]">
            <a href="#" @click="tab = 'Costing'"> Costing </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Labor' }]">
            <a href="#" @click="tab = 'Labor'"> Labor Rates </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Wage Codes' }]">
            <a href="#" @click="tab = 'Wage Codes'"> Wage Codes </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Expenses' }]">
            <a href="#" @click="tab = 'Expenses'"> Expenses </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Cost Centers' }]">
            <a href="#" @click="tab = 'Cost Centers'"> Cost Centers </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Billing System' }]">
            <a href="#" @click=";(tab = 'Billing System'), getRoutingCodes()"> Billing System </a>
          </li>
          <li role="presentation" :class="[{ active: tab == 'Job Setup' }]">
            <a href="#" @click=";(tab = 'Job Setup'), getJobSetupData()"> Job Setup </a>
          </li>
        </ul>
        <div :class="['tab-content', { 'first-active': tab === 'Terms' }]">
          <div class="tab-content-inner">
            <div class="card">
              <div class="content">
                <div v-show="tab == 'Terms'">
                  <div class="row">
                    <div class="columns col-sm-12 col-lg-6">
                      <admin-option-field :field="'terms'" :label="'Terms'" />
                      <admin-option-field :field="'deposit_amount'" :label="'Deposit Amount'" />
                    </div>
                    <div class="columns col-sm-12 col-lg-6">
                      <admin-option-field :field="'progress_payment_clause'" :label="'Progress Payment Clause'" :detail-value="true" />
                      <admin-option-field :field="'enhanced_disclaimer_text'" :label="'Enahanced Disclaimer'" :detail-value="true" />
                      <admin-option-field :field="'payment_price_type'" :label="'Price Type'" :detail-value="true" />
                    </div>
                    <div class="columns col-sm-12 col-lg-6">
                      <admin-option-field :field="'quote_conditions'" :label="'Conditions'" :single-field="true" :detail-value="true" />
                    </div>
                  </div>
                </div>
                <div v-show="tab === 'Costing'">
                  <div class="row">
                    <div class="columns col-sm-12">
                      <div class="group-container">
                        <h5>Overhead Amounts</h5>
                        <div class="row">
                          <!-- <div class="form-group">
                            <label>Job Sell Pricing Formula</label>
                            <input type="text" :class="['form-control', 'fake-disabled', 'select-field', 'custom-select']" v-model="sellingPriceOptionText" readonly @click="selectSellingPriceOption">
                          </div> -->

                          <div v-for="(dept, index) in quoteDepartments" :key="index">
                            <admin-option-field
                              class="columns col-sm-4"
                              :field="`quote_overhead_amount_${dept.name}`"
                              :label="`${dept.name}`"
                              :single-field="true"
                              :value-type="'dollar'"
                              :separate-section="false"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="tab === 'Labor'">
                  <admin-grouped-fields
                    :field="'labor_rates'"
                    :label="'Labor Rates'"
                    :fields="[
                      {
                        label: 'Name',
                        name: 'name',
                        type: 'text',
                        required: true
                      },
                      {
                        label: 'Cost',
                        name: 'cost',
                        type: 'money',
                        required: true
                      },
                      {
                        label: 'Rate',
                        name: 'rate',
                        type: 'money',
                        required: true
                      },
                      {
                        label: 'Phase',
                        name: 'phase',
                        type: 'text',
                        required: true
                      }
                    ]"
                  />
                </div>
                <div v-show="tab === 'Wage Codes'">
                  <admin-grouped-fields
                    :field="'wage_codes'"
                    :label="'Wage Codes'"
                    :fields="[
                      {
                        label: 'Name',
                        name: 'name',
                        type: 'text',
                        required: true
                      },
                      {
                        label: 'Job Wage Code',
                        name: 'job_wage_code',
                        type: 'text',
                        required: false
                      },
                      {
                        label: 'WO Wage Code',
                        name: 'wo_wage_code',
                        type: 'text',
                        required: false
                      },
                      {
                        label: 'Job Union Code',
                        name: 'job_union_code',
                        type: 'text',
                        required: false
                      },
                      {
                        label: 'WO Union Code',
                        name: 'wo_union_code',
                        type: 'text',
                        required: false
                      }
                    ]"
                  />
                </div>
                <div v-show="tab === 'Expenses'">
                  <div class="row">
                    <admin-option-field class="columns col-sm-4" :field="`per_diem_rate`" :label="`Per Diem Rate`" :single-field="true" :value-type="'dollar'" :separate-section="false" />
                  </div>
                </div>
                <div v-show="tab === 'Cost Centers'">
                  <div class="row" style="padding-top: 10px">
                    <div class="columns col-sm-12">
                      <div class="tab-section outline" style="padding: 0">
                        <ul class="nav nav-tabs">
                          <li role="presentation" :class="[{ active: costCenterTab == 'Department' }]">
                            <a href="#" @click="costCenterTab = 'Department'"> Job Department </a>
                          </li>
                          <li role="presentation" :class="[{ active: costCenterTab == 'Region' }]">
                            <a href="#" @click="costCenterTab = 'Region'"> Region </a>
                          </li>
                          <li role="presentation" :class="[{ active: costCenterTab == 'Costing' }]">
                            <a href="#" @click=";(costCenterTab = 'Costing'), getCostCenterOptions()"> Costing </a>
                          </li>
                        </ul>
                        <div :class="['tab-content', { 'first-active': costCenterTab === 'Department' }]">
                          <div class="tab-content-inner">
                            <div v-show="costCenterTab == 'Department'">
                              <p>Add ONE cost center for each region that the cost center belongs to. In the Region tab, each region will list cost centers which belong to that region.</p>
                              <admin-option-field
                                :field="'cost_centers'"
                                :label="'Cost Centers'"
                                :detail-value="true"
                                :show-value="true"
                                :edit-component="AdminOptionFieldCostCenterEditor"
                                :show-default="false"
                                :edit-component-name="'AdminOptionFieldCostCenterEditor'"
                              />
                            </div>
                            <div v-show="costCenterTab == 'Region'">
                              <p>List cost centers that belong to each region.</p>
                              <admin-option-field
                                :field="'cost_center_regions'"
                                :label="'Region'"
                                :detail-value="true"
                                :show-value="true"
                                :edit-component="RegionCostCenterEditComponent"
                                :show-default="false"
                                :allow-delete="false"
                                :edit-component-name="'RegionCostCenterEditComponent'"
                              />
                            </div>
                            <div v-show="costCenterTab == 'Costing'">
                              <div class="row">
                                <div v-for="(cc, index) in jobCostCenters" :key="index">
                                  <admin-option-field
                                    class="columns col-sm-6"
                                    :field="`quote_overhead_amount_${cc.Code}`"
                                    :label="`${cc.Name} ${cc.Code}`"
                                    :single-field="true"
                                    :value-type="'dollar'"
                                    :separate-section="false"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="tab === 'Billing System'">
                  <div class="row" style="padding-top: 10px">
                    <div class="columns col-sm-12">
                      <div class="tab-section outline" style="padding: 0">
                        <ul class="nav nav-tabs">
                          <li role="presentation" :class="[{ active: billingTab == 'Routing Codes' }]">
                            <a href="#" @click=";(billingTab = 'Routing Codes'), getRoutingCodes()"> Routing Codes </a>
                          </li>
                          <li role="presentation" :class="[{ active: billingTab == 'Maintenance' }]">
                            <a href="#" @click="billingTab = 'Maintenance'; getBillingTaskSettings()"> Maintenance </a>
                          </li>
                        </ul>
                        <div :class="['tab-content', { 'first-active': billingTab === 'Routing Codes' }]">
                          <div class="tab-content-inner">
                            <div v-show="billingTab == 'Routing Codes'">
                              <div class="row">
                                <div class="col col-sm-12">
                                  <div class="routing-code-search">
                                    <div class="form-group">
                                      <label>Search</label>
                                      <input type="text" class="form-control" v-model="routingCodeSearch" placeholder="Search" />
                                      <button @click="searchRoutingCode" class="btn">Search</button>
                                      <button v-if="routingCodeSearch" @click="getRoutingCodes()" class="btn">Clear</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col col-sm-12 col-md-6">
                                  <tabulator-table
                                    style="margin-bottom: 40px"
                                    :table-data="tableDataRoutingCodes"
                                    :table-columns="tableColumnsRoutingCodes"
                                    table-fit="fitColumns"
                                    :table-condensed="false"
                                    empty-text="(none)"
                                    @colClick="routingCodesColClick"
                                    :selectable="false"
                                    ref="routingCodesTable"
                                    :responsive-layout="false"
                                    :movable-rows="true"
                                  />
                                  <button @click="addRoutingCode" class="btn">Add Routing Code</button>
                                </div>
                                <div class="col col-sm-12"></div>
                              </div>
                            </div>
                            <div v-show="billingTab == 'Maintenance'">
                              <div class="row">
                                <div class="col col-sm-12 col-md-6">
                                  <tabulator-table
                                    ref="billingTasksTable"
                                    :table-data="tableDataBillingTasks"
                                    :table-columns="tableColumnsBillingTasks"
                                    table-fit="fitColumns"
                                    :table-condensed="false"
                                    empty-text="(none)"
                                    :selectable="false"
                                    :responsive-layout="false"
                                    style="margin-bottom: 40px"
                                  />
                                </div>
                                <div class="col col-sm-12">
                                  <button @click="saveBillingTaskSettings" class="btn">Save</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="tab === 'Job Setup'">
                  <div class="row" style="padding-top: 10px">
                    <div class="columns col-sm-12">
                      <div class="tab-section outline" style="padding: 0">
                        <ul class="nav nav-tabs">
                          <li role="presentation" :class="[{ active: jobSetupTab == 'Jobs' }]">
                            <a href="#" @click=";(jobSetupTab = 'Jobs'), getJobSetupData()"> Jobs </a>
                          </li>
                          <li role="presentation" :class="[{ active: jobSetupTab == 'Departments' }]">
                            <a href="#" @click="jobSetupTab = 'Departments'; getDepartmentsData()"> Departments </a>
                          </li>
                        </ul>
                        <div :class="['tab-content', { 'first-active': jobSetupTab === 'Jobs' }]">
                          <div class="tab-content-inner">
                            <div v-show="jobSetupTab == 'Jobs'">
                              <select-field
                                :options="Array.from({ length: new Date().getFullYear() - 2020 + 1 }, (_, i) => ({ value: (new Date().getFullYear() - i).toString() }))"
                                :option-display-keys="['value']"
                                :option-val="'value'"
                                v-model="viewFiscalYear"
                                @selectItem="val => this.getJobSetupData()"
                                style="display: inline-block; margin: 0 0 0 10px"
                              />
                              <div class="row">
                                <div class="col col-sm-12 col-md-6">
                                  <tabulator-table
                                    style="margin-bottom: 40px"
                                    :table-data="tableDataJobSetup"
                                    :table-columns="tableColumnsJobSetup"
                                    table-fit="fitColumns"
                                    :table-condensed="false"
                                    empty-text="(none)"
                                    @colClick="jobSetupColClick"
                                    :selectable="false"
                                    ref="jobSetupTable"
                                    :responsive-layout="false"
                                    :movable-rows="true"
                                  />
                                  <button @click="addJobSetup" class="btn">Click to Add</button>
                                </div>
                                <div class="col col-sm-12"></div>
                              </div>
                            </div>
                            <div v-show="jobSetupTab == 'Departments'">
                              <select-field
                                :options="Array.from({ length: new Date().getFullYear() - 2020 + 1 }, (_, i) => ({ value: (new Date().getFullYear() - i).toString() }))"
                                :option-display-keys="['value']"
                                :option-val="'value'"
                                v-model="viewFiscalYear"
                                @selectItem="val => this.getDepartmentsData()"
                                style="display: inline-block; margin: 0 0 0 10px"
                              />
                              <div class="row">
                                <div class="col col-sm-12">
                                  <tabulator-table
                                    ref="bdepartmentsTable"
                                    :table-data="tableDataDepartments"
                                    :table-columns="tableColumnsDepartments"
                                    table-fit="fitColumns"
                                    :table-condensed="false"
                                    empty-text="(none)"
                                    @colClick="departmentColClick"
                                    :selectable="false"
                                    :responsive-layout="false"
                                    style="margin-bottom: 40px"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner />
</div>
