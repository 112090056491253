
<div class="select-field select-field-full" v-click-outside="closeField">
  <input
    v-if="!disabled || disabledInput || readOnly"
    type="text"
    :class="['form-control', 'box-add-item', { 'select-field': !inline, 'fake-disabled': disabledInput && !showAsDisabled }, { 'no-bottom-radius': inline }, fieldClasses]"
    v-model="fieldVal"
    readonly
    @click="showOptions = readOnly ? false : !showOptions"
    :placeholder="placeholder"
    :disabled="disabled && fieldVal != ''"
  />
  <span v-else v-html="fieldVal" />
  <div v-if="(showOptions || inline) && !disabled" class="option-box-parent">
    <div :class="['option-box', { inline: inline }, { toggle: !inline }]">
      <div class="row upper row-no-gutters">
        <div class="col-xs-8">
          <h4>{{ label }}</h4>
        </div>
        <div class="col-xs-4 cancel-container">
          <a @click="cancel">Cancel</a>
        </div>
      </div>
      <input v-if="options && options.length > 6" type="text" :class="['form-control', 'search-field', 'has-danger']" v-model="searchFilter" placeholder="Search" />
      <div class="option-items show-scrollbar">
        <div v-if="filteredItems && filteredItems.length">
          <div v-if="allowClear" class="option-item empty-option-item" @click="userSelectEl({})">
            <span><span v-html="typeof allowClear === 'string' ? allowClear : 'CLEAR'" /></span>
          </div>
          <div v-for="(optionItem, key) in filteredItems" :key="key" class="option-item" @click="userSelectEl(optionItem)">
            <div v-if="formatOptionDisplay(optionItem)" v-html="formatOptionDisplay(optionItem)" />
          </div>
        </div>
        <div v-else-if="options && options.length">
          <i>No matching results</i>
        </div>
        <div v-else>
          <i>{{ emptyOptionText }}</i>
        </div>
      </div>
      <div v-if="allowAddSimpleItem" class="add-simple-item-container">
        <input class="form-control" type="text" v-model="addSimple" :placeholder="'Add ' + (itemName ? itemName : 'Item')" />
        <button class="btn" @click="addSimpleVal" v-html="'Add'" />
      </div>
      <span v-if="allowAddItem" class="add-new" @click="$emit('addItem')">Add New</span>
    </div>
  </div>
</div>
