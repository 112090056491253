import Sidebar from './SideBar.vue'

const SidebarStore = {
  showSidebar: false,
  sidebarLinks: [
    {
      name: 'Main',
      icon: 'ti-home',
      path: '/app'
    },
    {
      name: 'General',
      icon: 'ti-files',
      path: '/app/general',
      children: [
        {
          name: 'Policies and Procedures',
          path: '/app/policies-procedures'
        },
        {
          name: 'Overtime Availablity Form',
          path: '/app/overtime-availability'
        },
        {
          name: 'Tool Allowance Form',
          path: '/app/tool-allowance'
        }
      ]
    },
    {
      name: 'Time',
      icon: 'ti-time',
      path: '/app/time-entry',
      children: [
        {
          name: 'Time Entry',
          path: '/app/time-entry'
        },
        {
          name: 'Vacation Requests',
          path: '/app/vacation-request'
        },
        {
          name: 'Approval',
          path: '/app/time-approval',
          role: ['pm']
        },
        {
          name: 'Reports',
          path: '/app/department-reports'
        }
      ]
    },
    {
      name: 'Expenses',
      icon: 'ti-receipt',
      path: '/app/expenses'
    },
    {
      name: 'Jobs/WOs',
      icon: 'ti-files',
      path: '/app/jobs',
      children: [
        {
          name: 'My Jobs WO',
          path: '/app/job-wo-report',
          role: ['pm']
        },
        {
          name: 'Jobs',
          path: '/app/jobs'
        },
        {
          name: 'Work Orders',
          path: '/app/wos'
        }
      ]
    },
    {
      name: 'Tasks',
      icon: 'ti-check',
      path: '/app/tasks',
      role: ['pm']
    },
    {
      name: 'POs',
      icon: 'ti-package',
      path: '/app/pos',
      role: 'viewer'
    },
    {
      name: 'Project Log',
      icon: 'ti-layers',
      path: '/app/add-note',
      role: 'editor'
    },
    // {
    //   name: 'Job Board',
    //   icon: 'ti-direction',
    //   path: 'https://sheridanelectric.sharepoint.com/:x:/r/sites/sheridanelectric/Shared%20Documents/Scheduling/Job%20Board.xlsx?d=w8eaed66c36134ec6ba86a7b02ca16b62&csf=1&web=1&e=GxtXVc',
    //   role: 'viewer'
    // },
    {
      name: 'Quotes',
      icon: 'ti-agenda',
      path: '/app/quote-list',
      role: ['pm', 'Q'],
      children: [
        // {
        //   name: 'My Quotes',
        //   path: '/app/my-quotes',
        // },
        {
          name: 'All Quotes',
          path: '/app/all-quotes'
        },
        {
          name: 'Material Database',
          path: '/app/materials',
          role: ['pm', 'D']
        },
        {
          name: 'BOMs',
          path: '/assemblies'
        },
        {
          name: 'Material Lists',
          path: '/material-lists'
        },
        {
          name: 'Reports',
          path: '/app/quote-reports',
          role: ['pm', 'R']
        }
      ]
    },
    // {
    //   name: 'Purchase Orders',
    //   icon: 'ti-agenda',
    //   path: '/app/po-reports',
    //   role: 'pm',
    //   children: [{
    //     name: 'Reports',
    //     path: '/app/po-reports'
    //   }]
    // },
    {
      name: 'Contact List',
      icon: 'ti-user',
      path: '/app/contacts',
      children: [
        {
          name: 'Employees',
          path: '/app/employees'
        },
        {
          name: 'Vendors',
          path: '/app/vendors'
        },
        {
          name: 'Customers',
          path: '/app/customers',
          role: 'pm'
        },
        {
          name: 'Customer Contacts',
          path: '/app/customer-contacts',
          role: 'pm'
        }
      ]
    },
    // {
    //   name: 'Equipment',
    //   icon: 'ti-calendar',
    //   path: '/app/asset-scheduling',
    //   children: [
    //     {
    //       name: 'Schedule Equipment',
    //       path: '/app/assets/asset-scheduling'
    //     },
    //     {
    //       name: 'Manage Equipment',
    //       path: '/app/assets/asset-manage'
    //     }
    //   ]
    // },
    {
      name: 'Safety',
      icon: 'ti-shield',
      path: 'https://learninglogin.com',
      children: [
        {
          name: 'LearningLogin',
          path: 'https://learninglogin.com'
        },
        {
          name: 'Site Docs',
          path: 'https://app.sitedocs.com/home'
        }
      ]
    },
    {
      name: 'User Options',
      icon: 'ti-id-badge',
      path: '/app/user-options'
    },
    {
      name: 'Accounting',
      icon: 'ti-stamp',
      role: 'admin',
      children: [
        {
          name: 'AR Reports',
          path: '/app/accounts-receivable'
        },
        {
          name: 'AP Reports',
          path: '/app/accounts-payable'
        },
        {
          name: 'Aging',
          path: '/app/aging'
        },
        {
          name: 'PO Reports',
          path: '/app/po-reports'
        },
        {
          name: 'Billing Tasks',
          path: '/app/billing-list',
          role: ['admin']
        }
      ]
    },
    {
      name: 'Admin',
      icon: 'ti-settings',
      role: 'admin',
      children: [
        {
          name: 'App Options',
          path: '/app/admin-options'
        },
        {
          name: 'Setup',
          path: '/app/setup'
        }
        // {
        //   name: 'Employee Expenses',
        //   path: '/app/employee-expenses'
        // },
        // {
        //   name: 'Setup for Employee Expenses',
        //   path: '/app/setup-employee-expenses'
        // }
      ]
    }
  ],

  displaySidebar(value) {
    this.showSidebar = value
  }
}

const SidebarPlugin = {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          sidebarStore: SidebarStore
        }
      }
    })

    Object.defineProperty(Vue.prototype, '$sidebar', {
      get() {
        return this.$root.sidebarStore
      }
    })
    // Vue.component('side-bar', Sidebar)
  }
}

export default SidebarPlugin
