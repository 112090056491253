
<div class="">
  <div class="row">
    <div :class="[statusOptions && statusOptions.length > 1 ? 'col-sm-12 col-md-6' : 'col-sm-12']" v-if="showAuthor">
      <div class="form-group">
        <label>Author</label>
        <select-field
          :options="employees"
          :option-display-keys="['Employee_Name']"
          :option-val="'Employee_Code'"
          v-model="author"
          :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
          allow-clear="All"
          @selectItem="getLists()"
        />
      </div>
    </div>
    <div :class="[showAuthor ? 'col-sm-12 col-md-6' : 'col-sm-12']" v-if="statusOptions && statusOptions.length > 1">
      <div class="form-group">
        <label>Status</label>
        <select-field :options="statusOptions" :option-display-keys="['name']" :option-val="'val'" v-model="rfpStatus" @selectItem="getLists()" allow-clear="Any" />
      </div>
    </div>
  </div>

  <div class="">
    <tabulator-table :table-data="filteredLists" :table-columns="tableColumns" @colClick="tableClick" table-fit="fitColumns" :table-condensed="false" empty-text="(none)" :table-striped="true" />
  </div>
  <waiting-spinner :wait="wait" />
</div>
