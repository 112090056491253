<template>
  <div>
    <form id="job-filter-form" class="card">
      <div class="content">
        <h4>Filter By:</h4>
        <div class="row">
          <div class="col-md-4 form-group">
            <label for="company_filter">WO Number</label>
            <input class="form-control" type="text" name="company_filter" v-model="numberFilter">
          </div>

          <div class="col-md-4 form-group">
            <label for="customer_name">Customer Name</label>
            <input class="form-control" type="text" name="customer_name" v-model="companyFilter">
          </div>

          <div class="col-md-4 form-group">
            <label for="job_description">Job Description</label>
            <input class="form-control" type="text" name="job_description" v-model="descFilter">
          </div>
        </div>
      </div>
    </form>

    <div class="table-responsive">
      <table class="table table-border">
        <thead>
        <tr>
          <th>WO Number</th>
          <th>Customer Name</th>
          <th>Work Order Description</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="job in filteredWos">
          <td><a href="#" v-on:click="selectWO(job.WO_Number, $event)">{{ job.WO_Number }}</a></td>
          <td><span v-html="job.customer_name"></span></td>
          <td><span v-html="job.Summary_Description"></span></td>
        </tr>
        </tbody>
      </table>
    </div>

    <waiting-spinner/>

  </div>
</template>
<script>

import store from 'store';
import appFuncs from 'appFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  components: {WaitingSpinner},
  data() {
    return {
      urls: store.getters.urls,
      companyFilter: '',
      descFilter: '',
      numberFilter: '',
      WOs: [],
    }
  },

  props: [
    'componentData'
  ],

  computed: {
    userAuthedData() {
      return store.getters.userAuthedData;
    },

    filteredWos() {
      var self = this;
      var WOs = this.WOs;

      if (this.numberFilter) {
        WOs = WOs.filter(function (item) {
          return item.WO_Number.toLowerCase().indexOf(self.numberFilter.toLowerCase()) > -1
        });
      }

      if (this.companyFilter) {
        WOs = WOs.filter(function (item) {
          return item.customer_name.toLowerCase().indexOf(self.companyFilter.toLowerCase()) > -1
        });
      }

      if (this.descFilter) {
        WOs = WOs.filter(function (item) {
          return item.Summary_Description.toLowerCase().indexOf(self.descFilter.toLowerCase()) > -1
        });
      }

      return WOs;
    }

  },

  methods: {

    selectWO(number, e) {
      e.preventDefault();
      this.$bus.$emit('selectWONumber', number);
      this.$bus.$emit('modalClose', 'get-wo-info');
    },

  },

  mounted() {

    if (this.componentData && this.componentData.WOInfo) {
      this.WOs = this.componentData.WOInfo;
    }

    if (!this.WOs) {
      appFuncs.getWOInfo((result) => {
        this.WOs = result;
      });
    }
  }

}

</script>

<style lang="scss" scoped>

.table-striped tbody > tr:nth-of-type(2n) {
  /* background-color:#efefef; */
}

#job-filter-form {
  h4 {
    margin: 10px 0
  }
}

table {
  a {
    color: #555;
    text-decoration: underline;
    font-weight: 900;
  }
}


</style>
