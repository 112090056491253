<template>
  <div>
    <div class="form-group">
      <label>Region</label>
      <select-field
        :options="regions"
        :option-display-keys="['name']"
        :option-val="'id'"
        v-model="region"
        :default-value="region"
        placeholder="Select Region"
        :allow-clear="true"
        @selectItem="selectItem"
      />
    </div>
  </div>
</template>

<script>
import SelectField from 'components/UIComponents/SelectField'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      region: null
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['regions'])
  },
  mixins: [],
  components: {
    SelectField
  },

  methods: {
    selectItem(val) {
      this.$emit('input', val)
      this.$emit('select-region', val)
      this.$emit('selectItem', val)
    }
  },

  async mounted() {
    this.$bus.$emit('setWaiting', { message: 'Getting Regions', name: 'gettingRegions' })
    this.$store
      .dispatch('getRegions')
      .finally(() => {
        this.$bus.$emit('stopWaiting', 'gettingRegions')
      })
      .catch(e => {
        this.$snack.open('Problem getting regions', 'error')
      })
  },

  watch: {
    value(selected) {
      if (selected == null) {
        this.region = ''
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
