
<div>
  <div class="form-group">
    <label>Region</label>
    <select-field
      :options="regions"
      :option-display-keys="['name']"
      :option-val="'id'"
      v-model="region"
      :default-value="region"
      placeholder="Select Region"
      :allow-clear="true"
      @selectItem="selectItem"
    />
  </div>
</div>
