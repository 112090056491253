
<div class="max-width-large">
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="content">
          <h4>General</h4>

          <div class="form-group">
            <label for="expense_cycle_months">Expense Cycle months</label>
            <vue-numeric :class="['form-control']" :read-only-class="'form-control readonly'" v-model="appOptions.expense_cycle_months" />
          </div>

          <div class="form-group">
            <label for="tool_allowance">Tool Allowance</label>
                <vue-numeric currency="$" separator="," :class="['form-control']" :precision="2" :read-only-class="'form-control readonly'" v-model="appOptions.tool_allowance" />
          </div>

          <div class="form-group">
            <label for="customer_feedback_emails">Tool Allowance Groups</label>
            <select-field-multi
              :option-display-keys="['name']"
              :option-seperator="' '"
              :option-val="'id'"
              v-model="appOptions.tool_groups"
              :allow-clear="true"
              placeholder-text="Select Tool Allowance Groups"
              :options="tool_groups"
            />
          </div>

          <div class="form-group">
            <label for="tool_allowance">Boot Allowance</label>
            <vue-numeric currency="$" separator="," :class="['form-control']" :precision="2" :read-only-class="'form-control readonly'" v-model="appOptions.boot_allowance" />
          </div>

          <div class="form-group">
            <label for="customer_feedback_emails">Boot Allowance Groups</label>
            <select-field-multi
              :option-display-keys="['name']"
              :option-seperator="' '"
              :option-val="'id'"
              v-model="appOptions.boot_groups"
              :allow-clear="true"
              placeholder-text="Select Boot Allowance Groups"
              :options="tool_groups"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <input type="submit" class="btn" @click="update()" value="Update" />

  <waiting-spinner />
</div>
