
<div class="form-group" v-click-outside="closeField">
  <label>{{ label }}</label
  ><span v-if="required && !disabled" class="required-star">*</span>
  <div v-if="customerCode">
    <input type="text" class="form-control box-add-item select-field" :value="contact ? contact.Name : ''" @click="openDropdown" readonly :disabled="disabled" />
    <div v-if="showOptions" class="option-box-parent">
      <div class="option-box">
        <input type="text" class="form-control company-filter" v-model="companyFilter" placeholder="Search" />
        <div class="option-items">
          <div v-if="allowClear" class="option-item empty-option-item" @click="selectItem('')">
            <span><span v-html="typeof allowClear === 'string' ? allowClear : 'CLEAR'" /></span>
          </div>
          <div v-for="(cc, key) in filteredContacts" :class="'option-item'" @click="selectItem(cc.sfId)" :key="key">
            {{ cc.Name }}
            <i class="ti ti-pencil pull-right edit-item" @click="editContact(cc.sfId)" />
          </div>
          <div v-if="!customerContacts.length">
            <i>Fetching contacts...</i>
          </div>
          <div v-else-if="!filteredContacts.length">
            <i>No results</i>
          </div>
        </div>
        <span class="add-new" @click="addNewContact" v-if="allowAddNew"> Add New </span>
      </div>
    </div>
  </div>
  <input v-else type="text" class="form-control" value="Please select a Customer" disabled />
</div>
