
<div>
  <div class="tab-section outline white">
    <ul class="nav nav-tabs">
      <li role="presentation" :class="[{ active: tab == 'Contact Info' }]">
        <a href="#" @click="tab = 'Contact Info'"> Contact Info </a>
      </li>
      <li role="presentation" :class="[{ active: tab == 'Setup' }]" v-if="isAdmin">
        <a href="#" @click="tab = 'Setup'"> Office Setup </a>
      </li>
      <li role="presentation" :class="[{ active: tab == 'Department' }]" v-if="isAdmin">
        <a href="#" @click="tab = 'Department'"> Department Settings </a>
      </li>
      <li role="presentation" :class="[{ active: tab == 'Settings' }]" v-if="isAdmin">
        <a href="#" @click="tab = 'Settings'"> Settings </a>
      </li>
    </ul>
    <div :class="['tab-content', { 'first-active': tab === 'Contact Info' }]">
      <div class="tab-content-inner">
        <div v-if="tab == 'Contact Info'">
          <div class="row" v-if="employee.Employee_Code">
            <div class="columns col-sm-12 col-md-6">
              <div class="form-group">
                <label>Name</label>
                <input class="form-control" type="text" v-model="employee.Employee_Name" readonly />
              </div>
              <div class="form-group">
                <label>Mobile Phone</label>
                <input class="form-control" type="text" v-model="employee.Employee_Mobile_Phone" v-mask="'###-###-####'" />
              </div>
              <div class="form-group">
                <label>Email</label>
                <input class="form-control" type="text" v-model="employee.Employee_Email" />
              </div>
              <div class="form-group">
                <label>Assigned Hub</label>
                <select-field
                  :options="hubs"
                  :option-display-keys="['name']"
                  :option-val="'val'"
                  v-model="employeeDetails.assigned_hub"
                  :empty-option-text="'Fetching items...'"
                  :label="'Assigned Hub'"
                  disabled
                />
              </div>
            </div>
            <div class="columns col-sm-12 col-md-6">
              <div class="form-group">
                <label>Title</label>
                <input class="form-control" type="text" v-model="employee.Title" />
              </div>
              <div class="form-group">
                <label>Location</label>
                <input class="form-control" type="text" v-model="employee.Employee_Location" />
              </div>
              <div class="form-group">
                <label>Direct Phone</label>
                <input class="form-control" type="text" v-model="employee.Employee_Extension" v-mask="'###-###-####'" />
              </div>
              <div class="form-group">
                <label>Employee Code</label>
                <input class="form-control" type="text" v-model="employee.Employee_Code" readonly />
              </div>
            </div>
          </div>
          <div class="button-container">
            <button class="btn" @click="updateData">Update</button>
          </div>
        </div>

        <div v-if="tab == 'Setup' && employee.Employee_Code && isAdmin" class="setup-tab">
          <div class="row">
            <div class="columns col-sm-12 col-md-6">
              <h4>General</h4>
              <div class="form-group">
                <label>Default Region</label><span class="required-star">*</span>
                <select-field
                  v-model="employeeDetails.default_region"
                  :options="costCenterRegions"
                  :option-display-keys="['name']"
                  :option-val="'value'"
                  :empty-option-text="'Fetching items...'"
                  :label="'Default Region'"
                />
              </div>
              <div class="form-group">
                <label>Assigned Hub</label><span class="required-star">*</span>
                <select-field
                  :options="hubs"
                  :option-display-keys="['name']"
                  :option-val="'val'"
                  v-model="employeeDetails.assigned_hub"
                  :empty-option-text="'Fetching items...'"
                  :label="'Assigned Hub'"
                />
              </div>
              <div class="form-group">
                <label>Role</label>
                <select-field
                  v-model="employeeDetails.role"
                  :options="[
                    { Name: 'Captain', Code: 'captain' },
                    { Name: 'Project Manager', Code: 'pm' }
                  ]"
                  :option-display-keys="['Name']"
                  :option-val="'Code'"
                  :empty-option-text="'Fetching items...'"
                  :label="'User Role'"
                  :allow-clear="true"
                />
              </div>
              <div class="form-group">
                <label>Supervisior</label>
                <select-field
                  :options="employees"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="employeeDetails.supervisor"
                  :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                />
              </div>
              <h4>Fleet</h4>
              <div class="form-group">
                <label>Assigned Vehicle</label><span class="required-star">*</span>
                <select-field
                  v-model="employeeDetails.default_vehicle"
                  :options="equipmentList"
                  :option-display-keys="['Name']"
                  :option-val="'Code'"
                  :empty-option-text="'Fetching items...'"
                  :label="'Equipment'"
                />
              </div>

              <h4>Billing Module</h4>

              <div class="form-group">
                <span class="inline-checkbox">
                  <checkbox2 v-model="employeeDetails.billing_employee" true-val="yes" false-val="no">Billing Employee</checkbox2>
                </span>
              </div>

              <div class="form-group">
                <label>AR Routing Code</label>
                <select-field
                  :options="billingRoutingCodes"
                  :option-display-keys="['routing_code', 'routing_desc']"
                  :option-val="'routing_code'"
                  v-model="employeeDetails.billing_routing_code"
                  :placeholder-text="'Add Routing Code'"
                  :preselect-single-option="false"
                  item-name="Billing Routing Code"
                />
              </div>

              <div class="form-group">
                <label>Billing Task Requiring Approval</label>
                <select-field-multi
                  :options="billingTaskTypes"
                  :option-display-keys="['name']"
                  :option-val="'id'"
                  v-model="employeeDetails.billing_tasks_requiring_approval"
                  placeholder-text="Set Billing Task Requiring Approval"
                  :preselect-single-option="false"
                  item-name="Billing Task Type"
                />
              </div>

              <h4>Quoting Module</h4>
              <div class="form-group">
                <label>Quote Max Value</label>
                <vue-numeric currency="$" separator="," :class="['form-control']" :precision="2" :read-only-class="'form-control readonly'" v-model="employeeDetails.quote_max_value" />
              </div>

              <div class="form-group">
                <label>Quote Routing Code</label>
                <select-field
                  :options="billingRoutingCodes"
                  :option-display-keys="['routing_code', 'routing_desc']"
                  :option-val="'routing_code'"
                  v-model="employeeDetails.quote_routing_code"
                  :placeholder-text="'Add Routing Code'"
                  :preselect-single-option="false"
                  item-name="Quote Routing Code"
                />
              </div>
            </div>

            <div class="columns col-sm-12 col-md-6">
              <h4>Accounts Payable</h4>
              <div class="form-group">
                <label>Assigned Vendor Code</label>
                <select-field
                  :options="vendors"
                  :option-display-keys="['Vendor_Name']"
                  :option-val="'Vendor_Code'"
                  v-model="employeeDetails.default_vendor_code"
                  placeholder-text="Assign Vendor Code"
                  :label="'Vendor'"
                  :filter-on-key-val="{ key: 'Status', val: 'A' }"
                />
              </div>
              <div class="form-group">
                <label>Corp CC Account</label><span class="required-star">*</span>
                <select-field
                  v-model="employeeDetails.corp_cc_code"
                  :options="corpCreditCardAccounts"
                  :option-display-keys="['Bank_Account_Name', 'Bank_Account_Code']"
                  :option-val="'Bank_Account_Code'"
                  :empty-option-text="'Fetching items...'"
                  :label="'Corp Credit Card'"
                  @selectItem="employeeDetails.sub_cc_code = ''"
                />
              </div>
              <div class="form-group">
                <label>Corp Credit Card</label><span class="required-star">*</span>
                <select-field
                  v-model="employeeDetails.sub_cc_code"
                  :options="subCreditCardAccounts"
                  :option-display-keys="['Card_Number', 'Card_Description']"
                  :option-val="'Card_Number'"
                  :empty-option-text="'Fetching items...'"
                  :label="'Company Card'"
                />
              </div>
              <div class="form-group">
                <label>Default Routing Code</label>
                <select-field
                  :options="routingCodes"
                  :option-display-keys="['Routing_Desc', 'Routing_Code']"
                  :option-val="'Routing_Code'"
                  v-model="employeeDetails.routing_code1"
                  placeholder-text="Assign Routing Code"
                  :label="'Routing Code'"
                />
              </div>
              <div class="form-group">
                <label>Dollar Invoice Limit</label>
                <vue-numeric currency="$" separator="," :class="['form-control']" :precision="2" :read-only-class="'form-control readonly'" v-model="employeeDetails.routing_limit" />
              </div>
              <div class="form-group">
                <label>Default Routing if Over Limit</label>
                <select-field
                  :options="routingCodes"
                  :option-display-keys="['Routing_Desc', 'Routing_Code']"
                  :option-val="'Routing_Code'"
                  v-model="employeeDetails.routing_code2"
                  placeholder-text="Assign Routing Code"
                  :label="'Routing Code'"
                />
              </div>
              <div class="form-group">
                <label>AP Invoice Approval Routing Codes</label>
                <select-field-multi
                  :options="routingCodes"
                  :option-display-keys="['Routing_Desc', 'Routing_Code']"
                  :option-val="'Routing_Code'"
                  v-model="employeeDetails.assigned_routing_codes"
                  :placeholder-text="'Add Routing Code'"
                  :preselect-single-option="false"
                  item-name="Routing Code"
                />
              </div>
            </div>

            <div class="columns col-sm-12 col-md-6">
              <h4>Payroll</h4>
              <div class="form-group">
                <label>Default Department</label><span class="required-star">*</span>
                <select-field
                  :options="emCostGroups"
                  :option-display-keys="['Cost_Group_Description']"
                  :option-val="'Cost_Group_Code'"
                  v-model="employeeDetails.default_department"
                  :empty-option-text="'Fetching items...'"
                  :label="'Default Department'"
                />
              </div>
              <div v-if="employeeDetails.default_department === 'ADMIN'" class="form-group">
                <label>Department Code</label><span class="required-star">*</span>
                <select-field
                  :options="prDepartment"
                  :option-display-keys="['Description']"
                  :option-val="'Department_Code'"
                  v-model="employeeDetails.department_code"
                  :empty-option-text="'Fetching items...'"
                  :label="'Default Code'"
                />
              </div>
              <div class="form-group">
                <label>Wage Code</label><span class="required-star">*</span>
                <select-field
                  v-model="employeeDetails.wage_code"
                  :options="wageCodesDataFormatted"
                  :option-display-keys="['Name']"
                  :option-val="'id'"
                  :empty-option-text="'Fetching items...'"
                  :label="'Wage Codes'"
                />
              </div>
              <div class="form-group">
                <label>Default Cost Center</label><span class="required-star">*</span>
                <select-field
                  :options="regionCostCenters"
                  :option-display-keys="['name']"
                  :option-val="'value'"
                  v-model="employeeDetails.default_cost_center"
                  :empty-option-text="'Fetching items...'"
                  :label="'Default Cost Center'"
                  :disabled="!employeeDetails.default_region"
                />
              </div>
              <div class="form-group">
                <span class="inline-checkbox">
                  <checkbox2 v-model="employeeDetails.payroll_admin_user" true-val="yes" false-val="no"> Payroll Admin User</checkbox2>
                </span>
              </div>
            </div>
          </div>

          <div class="button-container">
            <button class="btn" @click="updateUDFData">Update</button>
          </div>
        </div>

        <div v-if="tab == 'Department'">
          <div class="row">
            <div class="columns col-sm-12 col-md-6">
              <div class="form-group">
                <label>Security</label>
                <select-field-multi
                  :options="emCostGroups"
                  :option-display-keys="['Cost_Group_Description']"
                  :option-val="'Cost_Group_Code'"
                  v-model="employeeDetails.em_cost_groups"
                  placeholder-text="Department Access"
                  :preselect-single-option="false"
                  item-name="Department Access"
                />
              </div>
            </div>
          </div>
          <div class="button-container">
            <button class="btn" @click="updateUDFData">Update</button>
          </div>
        </div>

        <div v-if="tab == 'Settings' && employee.Employee_Code && isAdmin">
          <div class="row">
            <div class="columns col-sm-12 col-md-6">
              <div class="form-group">
                <label>Hire Date</label>
                <date-picker v-model="employeeDetails.hire_date" :maxDate="todayDate" :format="'date'" :preFilled="false"></date-picker>
              </div>
            </div>
            <div class="columns col-sm-12 col-md-6">
              <div class="form-group">
                <label>Extra Boot Allowance</label>
                <vue-numeric currency="$" separator="," :class="['form-control']" :precision="2" :read-only-class="'form-control readonly'" v-model="employeeDetails.extra_boot_allowance" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="columns col-sm-12 col-md-6">
              <div class="form-group">
                <label>Extra Tool Allowance</label>
                <vue-numeric currency="$" separator="," :class="['form-control']" :precision="2" :read-only-class="'form-control readonly'" v-model="employeeDetails.extra_tool_allowance" />
              </div>
            </div>

            <div class="columns col-sm-12 col-md-6">
              <div class="form-group">
                <span class="inline-checkbox">
                  <checkbox2 v-model="employeeDetails.cba_employee" true-val="yes" false-val="no"> CBA employee</checkbox2>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="columns col-sm-12 col-md-6">
              <div class="form-group">
                <span class="inline-checkbox">
                  <checkbox2 v-model="employeeDetails.non_cba_contracting_employee" true-val="yes" false-val="no"> Non CBA Contracting Employee</checkbox2>
                </span>
              </div>
            </div>
            <div class="columns col-sm-12 col-md-6">
              <div class="form-group">
                <span class="inline-checkbox">
                  <checkbox2 v-model="employeeDetails.automation_employee" true-val="yes" false-val="no"> Automation Employee</checkbox2>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="columns col-sm-12 col-md-6">
              <div class="form-group">
                <span class="inline-checkbox">
                  <checkbox2 v-model="employeeDetails.estimating_employee" true-val="yes" false-val="no"> Estimating Employee</checkbox2>
                </span>
              </div>
            </div>
            <div class="columns col-sm-12 col-md-6">
              <div class="form-group">
                <span class="inline-checkbox">
                  <checkbox2 v-model="employeeDetails.sales_employee" true-val="yes" false-val="no"> Sales Employee</checkbox2>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="columns col-sm-12 col-md-6">
              <div class="form-group">
                <span class="inline-checkbox">
                  <checkbox2 v-model="employeeDetails.administration_employee" true-val="yes" false-val="no"> Administration Employee</checkbox2>
                </span>
              </div>
            </div>
          </div>

          <div class="button-container">
            <button class="btn" @click="updateUDFData">Update</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner :wait="wait" />
</div>
