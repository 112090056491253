
<div class="wo-info">
  <div v-if="!woError">
    <waiting-spinner/>
    <div class="tab-section outline white other-charges-section">
      <alert :alert="alert"/>
      <div class="sh-widget">
        <table>
          <label>Other Charges</label>
          <thead>
          <tr>
            <th></th>
            <!-- <th>Invoice_Extension</th>
            <th>Cost_Total</th> -->
            <th></th>
          </tr>
          </thead>
          <tbody v-if="otherCharges.length">
          <tr v-for="(row, key) in otherCharges" :key="key">
            <td v-html="row.Other_Cost_Item + ' - ' + row.Description"/>
            <!-- <td v-html="row.Invoice_Extension" />
            <td v-html="row.Cost_Total" /> -->
            <td v-if="!readOnly" class="delete" @click="deleteOtherCharge(row.System_Key, row.Other_Cost_Item)"><i class="ti ti-close"></i></td>
          </tr>
          <!-- <tr :class="'totals-row'">
            <td v-html="hourTotals.regular" :class="'not-empty'" />
            <td v-html="hourTotals.ot" :class="'not-empty'" />
            <td v-html="hourTotals.dt" :class="'not-empty'" />
            <td></td>
          </tr> -->
          </tbody>
          <tbody v-else :class="'empty-result'">
          <tr><span class="empty">None</span></tr>
          </tbody>
        </table>
      </div>

      <br><br>

      <div v-if="!readOnly" class="row">
        <div class="col-sm-12 col-md-9">
          <select-field :options="otherChargeItems" :option-display-keys="['Description']" :option-val="'Other_Cost_Item'" v-model="addChargeItem"/>
          <br>
        </div>
        <div class="col-sm-12 col-md-2">
          <button class="btn" @click="addOtherCharge">Add</button>
        </div>
      </div>
      <p v-else><i>Other Charges cannot be added to WO in Locked status.</i></p>

    </div>
  </div>
  <p v-else>Problem loading WO Data, please ti-close and continue from WO Details pages.</p>
</div>
