
<div class="">
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-4">
      <button class="btn" @click="addItem($event)">Add Time Entry</button>
      <button class="btn" @click="addForemanTimeEntry" v-if="canEditOthers">Foreman Time Entry</button>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-8 pull-right">
      <div class="legend">
        <div
          @click="
            selectedApprovalStatus = 'yes'
            getData()
          "
        >
          <span class="green" />Approved
        </div>
        <div
          @click="
            selectedApprovalStatus = 'no'
            getData()
          "
        >
          <span class="red" />Rejected
        </div>
        <div
          @click="
            selectedApprovalStatus = 'unapproved'
            getData()
          "
        >
          <span class="white" />Pending
        </div>
      </div>
    </div>
  </div>

  <div class="row" v-if="(isPayrollUser || isAdmin) && page === 'approval' && tab === 'approval'">
    <div class="col-sm-12">
      <WidgetPmApprovals @filter-pm="filterPm" />
    </div>
  </div>

  <br />

  <div class="tab-section white">
    <ul class="nav nav-tabs">
      <li role="presentation" :class="[{ active: tab == 'Entries' }]" v-if="page === 'entry'">
        <a href="#" @click=";(tab = 'Entries'), getEntries()"> Entries </a>
      </li>
      <li role="presentation" :class="[{ active: tab == 'Instructions' }]" v-if="page === 'entry'">
        <a href="#" @click="tab = 'Instructions'"> Instructions </a>
      </li>
      <li role="presentation" :class="[{ active: tab == 'approval' }]" v-if="isPM && page === 'approval'">
        <a href="#" @click=";(tab = 'approval'), getEntries()"> Time Card Approval</a>
      </li>
      <li role="presentation" :class="[{ active: tab == 'admin' }]" v-if="(isPayrollUser || isAdmin) && page === 'approval'">
        <a href="#" @click=";(tab = 'admin'), getEntries()">Payroll Admin</a>
      </li>
    </ul>

    <div :class="['card', { Main: tab == 'Entries' }]">
      <div class="content">
        <div v-if="tab == 'Entries'">
          <div class="row">
            <div class="col-sm-12 col-md-5">
              <div class="form-group">
                <label>Select Pay Period</label>
                <v-date-picker
                  v-model="selectedStartDate"
                  :pane-width="320"
                  :is-expanded="true"
                  :is-inline="false"
                  :popover-visibility="'focus'"
                  :show-day-popover="false"
                  :formats="{
                    input: ['DD MMM YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD']
                  }"
                  :theme-styles="datepickerStyles"
                  :attributes="dateSelectAttrs"
                  @input="selectDate"
                  :select-attribute="{
                    contentStyle: { color: '#555', highlight: '#dae0fe' }
                  }"
                  :class="['custom-week-picker']"
                >
                  <input type="text" slot-scope="{ inputValue, updateValue }" :value="selectedRange.formatted" :class="['form-control']" />
                </v-date-picker>
              </div>
            </div>
            <div class="col-sm-1">
              <div style="padding: 35px 0 10px; text-align: center">
                <p>OR</p>
              </div>
            </div>
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label>From Date</label>
                <date-picker
                  v-model="startDate"
                  :format="'date'"
                  :is-inline="false"
                  :pre-filled="false"
                  ref="startDatePicker"
                  @selectDate="
                    selectDate(null)
                    getEntries()
                  "
                />
              </div>
            </div>
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label>To Date</label>
                <date-picker
                  v-model="endDate"
                  :format="'date'"
                  :is-inline="false"
                  :pre-filled="false"
                  ref="endDatePicker"
                  @selectDate="
                    selectDate(null)
                    getEntries()
                  "
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label>Employee</label>
                <select-field-dynamic
                  :options="filteredEmployees"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="employeeCode"
                  :allow-clear="false"
                  :preselect-single-option="false"
                  :disabled="!canEditOthers"
                  :read-only="!canEditOthers"
                  :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                  label="Employee"
                  @selectItem="(getData('employee'), $refs.selectSubcontractor.selectEl({}))"
                  ref="selectEmployee"
                />
              </div>
            </div>
            <div :class="['col-sm-12', 'col-md-3']">
              <div class="form-group">
                <label>Subcontractor</label>
                <select-field-dynamic
                  :options="subcontractors"
                  :option-display-keys="['Name']"
                  :option-val="'id'"
                  v-model="subcontractorCode"
                  :allow-clear="true"
                  :preselect-single-option="false"
                  :disabled="!canEditOthers"
                  :read-only="!canEditOthers"
                  label="Subcontractor"
                  @selectItem="(getData('subcontractor'), $refs.selectEmployee.selectEl({}))"
                  ref="selectSubcontractor"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <label>Work Orders</label>
              <div class="form-group">
                <select-field-multi
                  :options="woList"
                  :option-display-keys="['WO_Number', 'customer_name', 'Summary_Description']"
                  :option-val="'WO_Number'"
                  :option-separator="' - '"
                  v-model="selectedWOs"
                  :placeholder-text="'Select WOs'"
                  :preselect-single-option="false"
                  @input="
                    selectedJobs = []
                    selectFilterItem('wo')
                  "
                  :allow-add-simple-item="true"
                  item-name="Work Orders"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label>Jobs</label>
                <select-field-multi
                  :options="jobList"
                  :option-display-keys="['Job_Number', 'Name', 'Job_Description']"
                  :option-val="'Job_Number'"
                  v-model="selectedJobs"
                  :placeholder-text="'Select Jobs'"
                  :preselect-single-option="false"
                  @input="
                    selectedWOs = []
                    selectFilterItem('job')
                  "
                  :allow-add-simple-item="true"
                  item-name="Jobs"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label>Approval Status</label>
                <select-field-dynamic
                  :options="approvalStatuses"
                  :option-display-keys="['label']"
                  :option-val="'id'"
                  v-model="selectedApprovalStatus"
                  @selectItem="getData()"
                  :allow-clear="false"
                  :preselect-single-option="false"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <region-filter v-model="region" />
            </div>
          </div>
          <div class="row" style="margin: 0px 0 20px">
            <div class="col-sm-12 text-right">
              <button class="btn" @click="clearFilters($event)">Clear Filters</button>
              <button class="btn" @click="exportResults()" v-if="canEditOthers || isPayrollUser">Export Results</button>
            </div>
          </div>

          <alert
            :alert="{
              show: entryData.length >= 200,
              message: `Note that greater than 200 entries exist for the dates selected, add more filtering to see complete results.`,
              state: 'warning',
              dismiss: 'none'
            }"
          />

          <tabulator-table
            :table-data="filteredEntries"
            :table-columns="tableColumns"
            table-fit="fitColumns"
            :table-condensed="false"
            empty-text="(none)"
            ref="jobWoLabor"
            @colClick="colClick"
            :responsive-layout="'hide'"
            :selectable="false"
            :row-formatter="rowFormatter"
          />
        </div>

        <div v-if="tab == 'Instructions'">
          <ul class="instruction-info">
            <li>
              Time entries submitted here can be edited until synced with Spectrum. Syncing occurs automatically at 4am daily or as necessary according to some Job/WO actions. Entries that can be
              edited display a small pencil icon at the far right of each row. To adjust non editable entries please ask the office to make the change for you.
            </li>

            <li>
              The final record of time shall be Spectrum and not mysheridan.ca – for example if you enter 4 hours at RT but the time gets adjust to 1.5X because of overtime that adjustment will
              appear in spectrum not in mysheridan.ca.
            </li>

            <li>You will only be able to enter hours that fall within the current payroll cycle. If you missed entering hours for a date outside this period, please consult the office.</li>
          </ul>
        </div>

        <div v-if="tab == 'approval' || tab === 'admin'">
          <div class="row" v-if="!isMobile">
            <div class="col-xs-12 col-sm-6 col-md-3" v-if="tab === 'approval'">
              <div class="form-group">
                <label>Job/WO PM</label>
                <select-field-dynamic
                  :options="employees"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="employeeCode"
                  @selectItem="getData('employee')"
                  :allow-clear="false"
                  :preselect-single-option="false"
                  :read-only="!isPayrollUser"
                  :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                  label="Employee"
                />
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="form-group">
                <label>Employee</label>
                <select-field-dynamic
                  :options="employees"
                  :option-display-keys="['Employee_Name']"
                  :option-val="'Employee_Code'"
                  v-model="filterEmployee"
                  :default-value="filterEmployee"
                  :allow-clear="true"
                  :preselect-single-option="false"
                  :disabled="!canEditOthers"
                  :read-only="!canEditOthers"
                  label="Employee"
                  :Xfilter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
                  @selectItem="getData('employee')"
                />
              </div>
            </div>
            <!-- <div class="col-xs-12 col-sm-6 col-md-2">
              <div class="form-group">
                <label>Subcontractor</label>
                <select-field-dynamic
                  :options="subcontractors"
                  :option-display-keys="['Name']"
                  :option-val="'id'"
                  v-model="filterSubcontractor"
                  :allow-clear="true"
                  :preselect-single-option="false"
                  :disabled="!canEditOthers"
                  :read-only="!canEditOthers"
                  label="Subcontractor"
                />
              </div>
            </div> -->
            <div class="col-xs-12 col-sm-6 col-md-3">
              <label>Work Orders</label>
              <div class="form-group">
                <select-field-dynamic
                  :options="tab === 'approval' ? woListTimeCardApproval : woList"
                  :option-display-keys="['wo_number']"
                  :option-val="'wo_number'"
                  :option-separator="' - '"
                  v-model="filterWO"
                  :default-value="filterWO"
                  :placeholder-text="'Select WO'"
                  :preselect-single-option="false"
                  label="Work Orders"
                  :allow-clear="true"
                />
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <div class="form-group">
                <label>Jobs</label>
                <select-field-dynamic
                  :options="tab === 'approval' ? jobListTimeCardApproval : jobList"
                  :option-display-keys="['job_number', 'Name', 'Job_Description']"
                  :option-val="'job_number'"
                  v-model="filterJob"
                  :default-value="filterJob"
                  :placeholder-text="'Select Job'"
                  :preselect-single-option="false"
                  label="Jobs"
                  :allow-clear="true"
                />
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <region-filter v-model="region" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-8 col-lg-9">
              <div class="form-group">
                <label>Filter Entries</label>
                <input type="text" v-model="entryFilter" class="form-control" placeholder="Filter text" />
              </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-3">
              <span class="form-group">
                <checkbox2 v-model="hideEntryComments" :true-val="'yes'" :false-val="'no'" class="hide-entry-comments-checkbox">Hide Entry Comments</checkbox2>
              </span>
            </div>
          </div>
        </div>
        <div v-if="tab == 'approval'">
          <div class="button-group button-group-mobile-sticky">
            <div class="inner">
              <div class="button-container">
                <button class="btn btn-no-radius btn-pip btn-sm btn-green" @click="acceptEntries">Approve</button>
                <button class="btn btn-no-radius btn-pip btn-sm btn-orange" @click="routeEntries">Route</button>
                <button class="btn btn-no-radius btn-pip btn-sm btn-red" @click="rejectEntries">Reject</button>
                <button class="btn btn-no-radius btn-pip btn-sm btn-red" @click="clearFilters($event)">Clear</button>
              </div>
              <div class="inline-checkbox" v-if="isMobile">
                <checkbox2 v-model="selectAll" :true-val="'yes'" :false-val="'no'" @change="$bus.$emit('tab-tiles-select-all-toggle')" />
              </div>
            </div>
          </div>

          <alert
            :alert="{
              show: entryData.length >= 300,
              message: `Note that greater than 300 entries exist for the dates selected, add more filtering to see complete results.`,
              state: 'warning',
              dismiss: 'none'
            }"
          />

          <tabulator-tiles-dynamic
            :table-data="filteredEntries"
            :table-columns="tableColumns"
            table-fit="fitColumns"
            :table-condensed="false"
            empty-text="(none)"
            ref="approvalTable"
            :Xresponsive-layout="'hide'"
            :selectable="true"
            :selectableRollingSelection="true"
            @colClick="colClick"
            :row-formatter="rowFormatter"
          />
        </div>

        <div v-if="tab == 'admin'">
          <div class="row">
            <div class="col-sm-12">
              <div class="button-group button-group-mobile-sticky">
                <div class="inner">
                  <div class="button-container">
                    <button class="btn btn-no-radius btn-pip btn-sm btn-green" @click="acceptEntries">Approve</button>
                    <button class="btn btn-no-radius btn-pip btn-sm btn-orange" @click="routeEntries">Route</button>
                    <button class="btn btn-no-radius btn-pip btn-sm btn-red" @click="deleteEntries">Delete</button>
                    <button class="btn btn-no-radius btn-pip btn-sm btn-red" @click="clearFilters($event)">Clear Filter</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <alert
            :alert="{
              show: entryData.length >= 300,
              message: `Note that greater than 300 entries exist for the dates selected, add more filtering to see complete results.`,
              state: 'warning',
              dismiss: 'none'
            }"
          />

          <tabulator-table
            :table-data="filteredEntries"
            :table-columns="tableColumns"
            table-fit="fitColumns"
            :table-condensed="false"
            empty-text="(none)"
            ref="adminTable"
            :Xresponsive-layout="'hide'"
            :selectable="true"
            @colClick="colClick"
            :row-formatter="rowFormatter"
          />
        </div>
      </div>
    </div>
  </div>
  <waiting-spinner />
</div>
