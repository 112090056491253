<template>
  <div>
    <div>
      <div class="tab-section outline white">
        <ul class="nav nav-tabs">
          <li v-if="!enterFromJobAdd" role="presentation" :class="[{ active: tab == 'Phases' }]">
            <a href="#" @click="tab = 'Phases'">Phase Listing</a>
          </li>
          <li v-if="(showRestricted && isPM) || enterFromJobAdd" role="presentation" :class="[{ active: tab == 'Details' }]">
            <a href="#" @click="tab = 'Details'">Phases</a>
          </li>
          <li v-if="isPM && showDetailsTab && showRestricted" role="presentation" :class="[{ active: tab == 'Numbers' }]">
            <a href="#" @click="tab = 'Numbers'">JTD Cost</a>
          </li>
        </ul>
        <div :class="[{ 'first-active': tab == 'Phases' }, 'tab', tab, 'tab-content']">
          <div v-show="tab === 'Phases'">
            <tabulator-table
              :table-data="phasesWithCostTypes"
              :table-columns="phasesWithCostTypeColumns"
              table-fit="fitColumns"
              :table-condensed="false"
              empty-text="(none)"
              @colClick="colClick"
              :row-formatter="rowFormatter"
            />
          </div>
          <div v-show="tab === 'Numbers'">
            <p>Note: Cost and Current return fields do not include contract labor hours, Pre-time card information, committed costs or unapproved invoices.</p>
            <tabulator-table
              :table-data="phaseDetailsWithTotals"
              :table-columns="tableColumnsNumbers"
              table-fit="fitColumns"
              :table-condensed="false"
              empty-text="(none)"
              @colClick="colClick"
              :row-formatter="rowFormatter"
              :data-tree="true"
              :data-tree-start-expanded="false"
            />
          </div>
          <div v-show="tab === 'Details' && isPM">
            <tabulator-table
              :table-data="phasesWithTotals"
              :table-columns="tableColumns"
              table-fit="fitColumns"
              :table-condensed="false"
              empty-text="(none)"
              @colClick="colClick"
              :row-formatter="rowFormatter"
              :selectable="generatedFromQuote && enableSelectCombine"
              ref="detailsTable"
              @rowSelected="detailsRowSelected"
              @rowDeselected="detailsRowDeselected"
            />
            <br />

            <div v-if="generatedFromQuote">
              <div class="row combine-phases-dialog">
                <div class="col col-sm-12 col-md-6 col-lg-3">
                  <checkbox2 v-model="enableSelectCombine" :true-val="true" :false-val="false"> Enable Combine Phases</checkbox2>
                </div>
                <div v-if="enableSelectCombine" class="col col-sm-12 col-md-6 col-lg-3">
                  <checkbox2 v-model="combineAllCostTypes" :true-val="true" :false-val="false"> Combine all Cost Types</checkbox2>
                </div>
              </div>

              <div class="row" v-if="enableSelectCombine">
                <div class="form-group col col-sm-12 col-md-6 col-lg-3">
                  <label>Combine in Phase Code</label>
                  <input type="number" class="form-control" v-model="combineAsPhaseCode" />
                </div>
                <div class="form-group col col-sm-12 col-md-6 col-lg-3">
                  <label>Combine in Phase Description</label>
                  <input type="text" class="form-control" v-model="combineAsPhaseDesc" />
                </div>
                <div class="col col-sm-12">
                  <p><i>Note that only alike cost types will be combined.</i></p>
                  <button v-if="generatedFromQuote" class="btn btn-sm" @click="combinePhases">Combine Phases</button>
                  <button v-if="jobPhasesSnapshots.length" class="btn btn-sm" @click="undoLastChange">Undo</button>
                </div>
              </div>
            </div>

            <br /><br />

            <div v-if="quoteTotal">
              <div class="form-group">
                <label>Quote Cost Total</label>
                <p>{{ formatNumber(quoteTotal, 1) }}</p>
              </div>
            </div>

            <div v-if="quotePdf">
              <div class="form-group">
                <label>Quote PDF:</label>
                <a :href="quotePdf" target="_blank"><i class="ti ti-new-window" /></a>
              </div>
            </div>

            <br />
          </div>
          <br /><br />
          <div v-show="tab === 'Details' || tab === 'Phases'">
            <div>
              <div v-if="!isLocked">
                <button v-if="(!job.quote_number || jobPhases.length) && !generatedFromQuote && isPM" class="btn" @click="addPhase">Add Phase</button>
                <button v-if="job.quote_number && !jobPhases.length && isPM" class="btn" @click="generateFromQuote">Generate from Quote</button>
                <!-- <button v-if="job.quote_number" class="btn" @click="generateFromQuote">Generate from Quote</button> -->
                <button v-if="enterFromJobAdd && isPM" class="btn" @click="next()">Save and Next</button>
                <button v-else-if="jobPhases.length && isPM" class="btn" @click="savePhases()">Save all Phases</button>
              </div>
              <p v-else>
                <i>Phases cannot be added to in Locked status.</i>
              </p>
            </div>
          </div>
        </div>
      </div>
      <waiting-spinner />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import commonFuncs from 'mixins/commonFuncs'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import JobFuncs from 'mixins/JobFuncs'
import Checkbox2 from 'components/UIComponents/Checkbox'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'JobPhasesNumbersDetails',
  data() {
    return {
      tab: 'Phases',
      generatedFromQuote: false,
      systems: [],
      editingPhaseCode: null,
      editingPhaseDesc: null,
      jobPhasesSnapshots: [],
      combineAsPhaseCode: '',
      combineAsPhaseDesc: '',
      combineAllCostTypes: true,
      enableSelectCombine: false
    }
  },

  mixins: [commonFuncs, JobFuncs],

  components: {
    TabulatorTable,
    Checkbox2,
    WaitingSpinner
  },

  props: {
    jobId: {
      required: true,
      type: [Number, String]
    },
    isLocked: {
      required: true,
      type: Boolean
    },
    job: {
      required: true,
      type: Object
    },
    enterFromJobAdd: {
      default: false,
      type: Boolean
    },
    showDetailsTab: {
      default: false,
      type: Boolean
    },
    showRestricted: {
      default: false,
      type: Boolean
    }
  },

  computed: {
    ...mapGetters(['quoteMaterialList', 'userAuthedData']),

    isPM() {
      return this.$store.getters.userAuthedData.user_role.indexOf('pm') !== -1
    },

    userID() {
      return this.$store.getters.userAuthedData.eid
    },

    costTypeAssc() {
      return this.costTypeNames.reduce((acc, cur) => {
        acc[cur.code] = cur.name
        return acc
      }, {})
    },

    //     phasesCostTypeChildren() {
    //   let out = []
    //   const phases = JSON.parse(JSON.stringify(this.phaseDetailsWithTotals))
    //   for (let i = 0; i < phases.length; i++) {
    //     const phaseCode = phases[i].Phase_Code
    //     if (!out.some(itm => itm.Phase_Code === phaseCode)) {
    //       out.push({
    //         Phase_Code: phaseCode,
    //         Description: phases[i].Description,
    //         id: this.randomCharacterString(10),
    //         originalCost: 0,
    //         currentCost: 0,
    //         laborHours: 0,
    //         currentHours: 0,
    //         _children: []
    //       })
    //     }
    //     const index = out.findIndex(itm => itm.Phase_Code === phaseCode)
    //     out[index]._children.push(phases[i])
    //   }
    //   return out
    // },

    phasesWithCostTypes() {
      return this.jobPhases.map(itm => {
        return {
          id: itm.id,
          Phase_Code: itm.Phase_Code,
          Description: itm.Description,
          Status_Code: itm.Status_Code,
          Cost_Type: itm.Cost_Type
        }
      })
    },

    tableColumns() {
      return [
        {
          field: 'id',
          width: 150,
          visible: false
        },
        {
          title: 'Phase',
          field: 'Phase_Code',
          width: 120,
          editor: 'input', // This enables editing
          editable: cell => {
            return !this.enableSelectCombine && cell.getRow().getData().proposed === 'yes'
          },
          cellEditing: cell => {
            this.editingPhaseCode = cell.getValue()
          },
          cellEdited: cell => {
            const data = cell.getRow().getData()
            if (data.Phase_Code.length > 4) {
              data.Phase_Code = data.Phase_Code.substring(0, 4)
            }
            this.updatePhaseCode(data.Phase_Code)
          }
        },
        {
          title: '',
          field: 'setDefaults',
          width: 80,
          headerSort: false,
          formatter: cell => {
            const data = cell.getRow().getData()
            return data.proposed !== 'yes' ? '' : '<button class="btn btn-link btn-xs">Defaults</button>'
          },
          visible: this.generatedFromQuote
        },
        {
          title: 'Phase Description',
          field: 'Description',
          width: 250,
          editor: 'input', // This enables editing
          editable: !this.enableSelectCombine,
          cellEditing: cell => {
            const data = cell.getRow().getData()
            this.editingPhaseCode = data.Phase_Code
          },
          cellEdited: cell => {
            const data = cell.getRow().getData()
            if (data.Description.length >= 25) {
              data.Description = data.Description.substring(0, 25)
            }
            this.updatePhaseDesc(data.Description)
          }
        },
        {
          title: 'Cost Type',
          field: 'Cost_Type',
          width: 120,
          editor: 'select',
          visible: true, // this.tab === 'Details',
          editable: function (cell) {
            return false
            return cell.getRow().getData().proposed === 'yes'
          },
          editorParams: {
            values: this.costTypeAssc
          },
          formatter: 'lookup',
          formatterParams: this.costTypeAssc,
          cellEdited: cell => {
            const data = cell.getRow().getData()
            this.incrementSetDuplicatePhase({ id: data.id, phase: data.Phase_Code })
          }
        },
        {
          title: 'Original Hours',
          field: 'laborHours',
          width: 150,
          formatter: function (cell) {
            var data = cell.getRow().getData()
            var html = parseFloat(data.laborHours).toFixed(2)
            return html > 0 ? html : ''
          },
          topCalc: () => {
            let totals = this.calculateTotals(this.jobPhases)
            return totals && totals.totalLaborHours ? this.formatNumber(totals.totalLaborHours) : 0.0
          }
        },
        {
          title: 'Original Cost',
          field: 'originalCost',
          width: 150,
          formatter: cell => {
            var data = cell.getRow().getData()
            if (!data.originalCost) return ''
            var html = this.formatNumber(data.originalCost, 1)
            return html
          },
          topCalc: () => {
            let totals = this.calculateTotals(this.jobPhases)
            return totals && totals.totalCost ? this.formatNumber(totals.totalCost, 1) : 0.0
          }
        },
        {
          title: 'Status',
          field: 'Status_Code',
          width: 100,
          editor: 'select',
          editorParams: {
            values: {
              A: 'Active',
              I: 'Inactive',
              C: 'Complete'
            }
          },
          formatter: 'lookup',
          formatterParams: {
            A: 'Active',
            I: 'Inactive',
            C: 'Complete'
          },
          editable: !this.enableSelectCombine
        }
        // {
        //   title: '',
        //   field: 'editLink',
        //   formatter: 'html',
        //   width: 80,
        //   headerSort: false
        // },
        // {
        //   title: '',
        //   field: 'saveLink',
        //   formatter: cell => {
        //     return cell.getRow().getData().proposed !== 'yes' ? '<span class="is-link underlined">Save</span>' : ''
        //   },
        //   width: 80,
        //   headerSort: false,
        //   visible: false
        // }
        // {
        //   title: '',
        //   field: 'remove',
        //   frozen: true,
        //   formatter: cell => {
        //     const proposed = cell.getRow().getData().proposed === 'yes'
        //     return proposed ? '<i class="fa fa-times is-link is-red"></i>' : null
        //   },
        //   headerSort: false,
        //   cssClass: 'no-horo-border',
        // }
      ]
    },

    tableColumnsNumbers() {
      return [
        {
          field: 'id',
          width: 150,
          visible: false
        },
        {
          title: 'Phase',
          field: 'Phase_Code',
          width: 120
        },
        {
          title: 'Phase Description',
          field: 'Description',
          width: 250
        },
        {
          title: 'Cost Type',
          field: 'Cost_Type',
          width: 120,
          formatter: cell => {
            const data = cell.getRow().getData()
            return this.costTypeAssc[data.Cost_Type] || data.Cost_Type
          }
        },
        {
          title: 'Original Hours',
          field: 'laborHours',
          width: 150,
          formatter: function (cell) {
            var data = cell.getRow().getData()
            var html = parseFloat(data.laborHours).toFixed(2)
            return html > 0 ? html : ''
          },
          topCalc: () => {
            let totals = this.calculateTotals(this.jobPhases)
            return totals && totals.totalLaborHours ? this.formatNumber(totals.totalLaborHours) : 0.0
          }
        },
        {
          title: 'Current Hours',
          field: 'currentHours',
          width: 150,
          formatter: function (cell) {
            var data = cell.getRow().getData()
            var html = parseFloat(data.currentHours).toFixed(2)
            return html > 0 ? html : ''
          },
          topCalc: () => {
            let totals = this.calculateTotals(this.jobPhases)
            return totals && totals.totalCurrentHours ? this.formatNumber(totals.totalCurrentHours) : 0.0
          }
        },
        {
          title: 'Original Cost',
          field: 'originalCost',
          width: 150,
          formatter: cell => {
            var data = cell.getRow().getData()
            if (!data.originalCost) return ''
            var html = this.formatNumber(data.originalCost, 1)
            return html
          },
          topCalc: () => {
            let totals = this.calculateTotals(this.jobPhases)
            return totals && totals.totalCost ? this.formatNumber(totals.totalCost, 1) : 0.0
          }
        },
        {
          title: 'Current Cost',
          field: 'currentCost',
          width: 150,
          formatter: cell => {
            var data = cell.getRow().getData()
            if (!data.currentCost) return ''
            var html = this.formatNumber(data.currentCost, 1)
            return html
          },
          topCalc: () => {
            let totals = this.calculateTotals(this.jobPhases)
            return totals && totals.currentCost ? this.formatNumber(totals.currentCost, 1) : 0.0
          }
        },
        {
          title: 'Cost Variance',
          field: 'costVariance',
          width: 150,
          formatter: cell => {
            var data = cell.getRow().getData()
            if (!data.costVariance) return ''
            var html = this.formatNumber(data.costVariance, 1)
            return html
          },
          topCalc: () => {
            let totals = this.calculateTotals(this.jobPhases)
            return totals && totals.costVariance ? this.formatNumber(totals.costVariance, 1) : 0.0
          }
        },
        {
          title: 'Status',
          field: 'Status_Code',
          width: 100,
          formatter: cell => {
            const data = cell.getRow().getData()
            const parentRow = data._children && data._children.length ? data : null
            if (parentRow) return ''
            switch (data.Status_Code) {
              case 'I':
                return 'Inactive'
              case 'C':
                return 'Complete'
              case 'A':
                return 'Active'
              default:
                return data.Status_Code || ''
            }
          },
          editable: !this.enableSelectCombine
        }
      ]
    },

    phasesWithCostTypeColumns() {
      return [
        {
          field: 'id',
          width: 150,
          visible: false
        },
        {
          title: 'Phase',
          field: 'Phase_Code',
          width: 120
        },
        {
          title: 'Phase Description',
          field: 'Description',
          width: 250
        },
        {
          title: 'Cost Type',
          field: 'Cost_Type',
          width: 120,
          formatter: cell => {
            const data = cell.getRow().getData()
            return this.costTypeAssc[data.Cost_Type]
          }
        },
        {
          title: 'Status',
          field: 'Status_Code',
          width: 100,
          formatter: cell => {
            const data = cell.getRow().getData()
            const parentRow = data._children && data._children.length ? data : null
            if (parentRow) return ''
            switch (data.Status_Code) {
              case 'I':
                return 'Inactive'
              case 'C':
                return 'Complete'
              case 'A':
                return 'Active'
              default:
                return data.Status_Code || ''
            }
          }
        }
      ]
    },

    phaseDetailsWithTotals() {
      let out = []

      for (let i = 0; i < this.jobPhases.length; i++) {
        let phase = JSON.parse(JSON.stringify(this.jobPhases[i]))

        // create header row if it doesnt already exist
        let index = out.findIndex(itm => itm.Phase_Code === phase.Phase_Code)
        if (index === -1) {
          index = out.length
          out.push({
            Phase_Code: phase.Phase_Code,
            Description: phase.Description,
            id: this.randomCharacterString(10),
            originalCost: 0,
            currentCost: 0,
            costVariance: 0,
            laborHours: 0,
            currentHours: 0,
            _children: []
          })
        }

        // add totals to the header row
        out[index].originalCost += parseFloat(phase.originalCost || 0) || 0
        out[index].currentCost += parseFloat(phase.currentCost || 0) || 0
        out[index].costVariance += parseFloat(phase.costVariance || 0) || 0
        out[index].laborHours += parseFloat(phase.laborHours || 0) || 0
        out[index].currentHours += parseFloat(phase.currentHours || 0) || 0

        // add phase as a child to the header row
        out[index]._children.push({
          Phase_Code: phase.Phase_Code,
          Description: phase.Description,
          Cost_Type: phase.Cost_Type,
          originalCost: phase.originalCost,
          currentCost: phase.currentCost,
          costVariance: phase.costVariance,
          laborHours: phase.laborHours,
          currentHours: phase.currentHours,
          id: this.randomCharacterString(10)
        })
      }
      return out
    },

    phasesWithTotals() {
      if (!this.jobPhases.length) return []
      const totals = this.calculateTotals(this.jobPhases)
      // return this.jobPhases.concat({
      //   Phase_Code: 'Total',
      //   originalCost: totals.totalCost,
      //   laborHours: totals.totalLaborHours,
      //   id: 'total'
      // })
      return this.jobPhases
    },

    quoteTotal() {
      if (this.job.quote_number && this.quoteMaterialList && this.quoteMaterialList.costTotal) {
        return this.quoteMaterialList.costTotal
      }
      return null
    },

    quotePdf() {
      if (this.job.quote_number && this.quoteMaterialList && this.quoteMaterialList.quoteTemplateUrlWithPrice) {
        return `${this.quoteMaterialList.quoteTemplateUrlWithPrice}?showPrice=1`
      }
      return null
    }
  },

  methods: {
    rowFormatter(row) {
      var data = row.getData()
      let rowEl = row.getElement()
      let updateRow = false
      let rowEntry = null

      const parentRow = data._children && data._children.length ? data : null
      if (data.id === 'total' || parentRow) {
        row.getElement().classList.add('bold-row')
      }
    },

    calculateTotals(data) {
      let totalCost = 0
      let totalLaborHours = 0
      let currentCost = 0
      let totalCurrentHours = 0
      let costVariance = 0

      data.forEach(item => {
        totalCost += parseFloat(Number(item.originalCost) || 0)
        totalLaborHours += parseFloat(Number(item.laborHours) || 0)
        currentCost += parseFloat(Number(item.currentCost) || 0)
        costVariance += parseFloat(Number(item.costVariance) || 0)
        totalCurrentHours += parseFloat(Number(item.currentHours) || 0)
      })

      return { totalCost, totalLaborHours, currentCost, costVariance, totalCurrentHours }
    },

    updatePhaseCode(newPhase) {
      // this ties phase codes together under the same system

      return
      const oldPhase = this.editingPhaseCode
      for (let i = 0; i < this.jobPhases.length; i++) {
        if (this.jobPhases[i].Phase_Code == oldPhase) {
          this.jobPhases[i].Phase_Code = newPhase
        }
      }
      this.editingPhaseCode = null
    },

    updatePhaseDesc(newDesc) {
      const phaseCode = this.editingPhaseCode
      for (let i = 0; i < this.jobPhases.length; i++) {
        if (this.jobPhases[i].Phase_Code == phaseCode) {
          this.jobPhases[i].Description = newDesc
        }
      }
      this.editingPhaseCode = null
    },

    removeFromSystemList(id) {
      let sld = JSON.parse(JSON.stringify(this.jobPhases))
      const i = sld.findIndex(itm => {
        return itm.id == id && itm.proposed === 'yes'
      })

      if (i !== -1) {
        sld.splice(i, 1)
      }
      this.jobPhases = sld
    },

    setPhase(itm) {
      this.$Modal({
        parent: this,
        name: 'set-phase-defaults',
        size: 'xl', // sm, md, lg, xlp
        hideClose: false,
        title: 'Set Phase Defaults',
        component: () => import('components/Dashboard/JobWo/SetPhaseDefaults.vue'),
        props: {
          id: itm.id
        }
      })
    },

    addPhase() {
      this.$Modal({
        parent: this,
        name: 'add-job-phase',
        size: 'md', // sm, md, lg, xlp
        hideClose: false,
        title: 'Add Phase',
        component: () => import('components/Dashboard/JobWo/AddJobPhase.vue'),
        props: {
          jobId: this.jobId,
          restricted: false,
          allowMultipleCostTypes: false,
          existingPhases: this.jobPhases
        }
      })
    },

    undoLastChange() {
      if (this.jobPhasesSnapshots.length === 0) {
        // No previous state to revert to
        return
      }

      // Revert to the most recent snapshot
      this.jobPhases = JSON.parse(JSON.stringify(this.jobPhasesSnapshots.pop()))
    },

    combinePhases() {
      // Get selected rows from the table
      const selectedRows = this.$refs.detailsTable.getSelectedData()

      // Check if any rows are selected; if not, display a warning and exit the function
      if (!selectedRows || !selectedRows.length) {
        this.$snack.open('No phases selected', 'warning')
        return
      }

      // Create a snapshot of the current job phases for undo functionality or tracking changes
      this.jobPhasesSnapshots.push(JSON.parse(JSON.stringify(this.jobPhases)))

      // Object to hold the combined data
      let combined = {}

      // Iterate over each selected item
      selectedRows.forEach(item => {
        const costType = item.Cost_Type

        // Initialize a new entry in the combined object if it doesn't exist
        if (!combined[costType]) {
          combined[costType] = {
            ...item,
            systemId: Array.isArray(item.systemId) ? [...new Set(item.systemId)] : [item.systemId]
          }
        } else {
          // Aggregate the values for total_cost, originalCost, and laborHours
          combined[costType].total_cost += item.total_cost
          combined[costType].originalCost += item.originalCost
          combined[costType].laborHours = (combined[costType].laborHours || 0) + (item.laborHours || 0)

          // Merge systemId arrays or add new systemId ensuring no duplicates
          let currentSystemIds = new Set(combined[costType].systemId)
          if (Array.isArray(item.systemId)) {
            item.systemId.forEach(id => currentSystemIds.add(id))
          } else {
            currentSystemIds.add(item.systemId)
          }
          combined[costType].systemId = Array.from(currentSystemIds)
        }

        // Update the Phase_Code and Description
        combined[costType].Phase_Code = this.combineAsPhaseCode
        combined[costType].Description = this.combineAsPhaseDesc
      })

      // Collect IDs of combined items to filter them out from the original list
      const combinedIds = selectedRows.map(item => item.id)

      // Remove original phases that were combined
      this.jobPhases = this.jobPhases.filter(phase => !combinedIds.includes(phase.id))

      // Add the new combined phases to the array
      this.jobPhases = [...this.jobPhases, ...Object.values(combined)]

      // Sort the phases - assuming sortPhases is a function that sorts the phases as needed
      this.jobPhases = this.sortPhases(this.jobPhases)

      this.combineAsPhaseCode = ''
      this.combineAsPhaseDesc = ''
    },
    colClick(obj) {
      const col = ((obj || {}).cell || {}).field || null
      const phaseCode = ((obj || {}).row || {}).Phase_Code || null
      const costType = ((obj || {}).row || {}).Cost_Type || null
      const id = ((obj || {}).row || {}).id || null
      const proposed = ((obj || {}).row || {}).proposed === 'yes'

      const phase = this.jobPhases.find(itm => {
        return itm.Phase_Code == phaseCode && itm.Cost_Type === costType
      })

      if (col === 'editLink' && phaseCode) {
        this.editPhase(phase)
      }
      if (col === 'setDefaults') {
        this.setPhase(phase)
      }
      if (col === 'remove' && id) {
        this.removeFromSystemList(id)
      }
      if (col === 'saveLink' && id && !proposed) {
        this.savePhases(id)
      }
    },

    editPhase(phase) {
      if (!phase || !phase.Phase_Code) {
        this.$snack.open('Problem retreiving phase, please try again.', 'error')
        return
      }
      this.$Modal({
        parent: this,
        name: 'edit-job-phase',
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        title: 'Edit Phase',
        component: () => import('components/Dashboard/JobWo/EditJobPhase.vue'),
        props: {
          phase
        }
      })
    },

    sortPhases(phases) {
      const sortOrder = { M: 1, L: 2, E: 3, Q: 4, P: 5, O: 6 }
      return phases.sort((a, b) => {
        if (a.Phase_Code < b.Phase_Code) return -1
        if (a.Phase_Code > b.Phase_Code) return 1
        return sortOrder[a.Cost_Type] - sortOrder[b.Cost_Type]
      })
    },

    async generateFromQuote() {
      if (!this.job.quote_number) {
        this.$snack.open('No quote found assigned to job', 'error')
        return
      }

      var params = {
        action: 'get_quote_items_in_cost_type',
        quote_number: this.job.quote_number
      }

      this.$bus.$emit('setWaiting', { name: 'get_quote_items_in_cost_type', message: 'Getting phases from quote' })

      let data = null

      // testing wipe exising phases
      // this.jobPhases = []

      try {
        const resData = await appFuncs.shRequest(params)
        data = resData && Array.isArray(resData.generatedPhases) ? resData.generatedPhases : []
        this.systems = resData && Array.isArray(resData.systems) ? resData.systems : []

        if (resData && resData.quoteData) {
          this.$store.commit('quoteMaterialList', resData.quoteData)
          this.$bus.$emit('updatedQuoteMaterialList')
        }
      } catch (err) {
        this.$snack.open(err.message || 'Problem getting phases from quote', 'error')
      } finally {
        this.$bus.$emit('stopWaiting', 'get_quote_items_in_cost_type')
      }

      if (!data || !data.length) {
        this.$snack.open('No phases found in quote', 'warning')
        return
      }

      let newPhases = []

      for (let i = 0; i < data.length; i++) {
        // remove the decimal and add 1000
        const sysSuffix = data[i].number.toString().replace('.', '')
        const phaseNum = parseInt(sysSuffix) + 1000

        data[i].id = this.randomCharacterString(12)
        data[i].Phase_Code = phaseNum
        data[i].Cost_Type = data[i].costType
        data[i].Cost_Type_Name = data[i].costType
        data[i].Description = data[i].systemName
        data[i].Status_Code = 'A'
        data[i].proposed = 'yes'
        data[i].Loaded_Status_Code = 'A'
        data[i].Status_Name = 'A'
        data[i].editLink = '<span class="is-link underlined">Save</span>'
        data[i]['originalCost'] = data[i]['total_cost'] || 0

        // if Cost_Type is IL, set phase to 7000 + sysSuffix, and change cost type to L
        if (data[i].Cost_Type === 'IL') {
          data[i].Phase_Code = 7000
          data[i].Cost_Type = 'L'
        }

        // if the same phase code and cost type already exists, skip
        const exists = this.jobPhases.find(itm => {
          return itm.Phase_Code == phaseNum && itm.Cost_Type === data[i].Cost_Type
        })

        if (exists) continue
        newPhases.push(data[i])
      }

      this.generatedFromQuote = true

      newPhases = this.sortPhases(newPhases)

      this.jobPhases.push(...newPhases)
    },

    next() {
      this.savePhases()
    },

    savePhases(id) {
      // @todo make sure no duplicates
      if (!this.jobPhases.length) {
        if (this.enterFromJobAdd) {
          // open job documents modal
          this.openJobDocuments()
          this.$bus.$emit('modalClose', 'job-phases-numbers-details')
        }
        // this.$snack.open('No phases to save', 'warning')
        return
      }

      let phases = this.jobPhases.map(itm => {
        return {
          id: itm.id,
          Phase_Code: itm.Phase_Code,
          Cost_Type: itm.Cost_Type === 'IL' ? 'L' : itm.Cost_Type,
          Description: itm.Description,
          Status_Code: itm.Status_Code,
          Loaded_Status_Code: itm.Status_Code,
          Projected_Cost: itm.originalCost || null,
          Projected_Hours: itm.laborHours || null,
          sourceSystemId: itm.systemId
        }
      })

      if (id) {
        phases = phases.filter(itm => {
          return itm.id === id
        })
      } else {
        // trim array for testing
        // phases = phases.slice(0, 4)
      }

      const params = {
        action: 'add_update_job_phase_multiple',
        job_number: parseInt(this.jobId),
        data: phases
      }

      console.log(params)
      // return

      this.$bus.$emit('setWaiting', { name: 'create_job_phases', message: 'Saving Job Phases' })

      appFuncs
        .shRequest(params)
        .then(data => {
          if (!id) {
            this.$snack.open('Phase successfully saved', 'success')
            // reload if saving all
            this.getJobPhases().then(() => {
              this.jobPhases = this.sortPhases(this.jobPhases)
            })
            this.jobPhasesSnapshots = []
            this.generatedFromQuote = false
          } else {
            this.$snack.open('Phases successfully saved', 'success')
            // if not saving all, update the phase status to protect from editing fields which can no longer be edited
            const savedPhase = this.jobPhases.findIndex(itm => {
              return itm.id === id
            })
          }

          if (this.enterFromJobAdd && !id) {
            // open job documents modal
            this.openJobDocuments()
            this.$bus.$emit('modalClose', 'job-phases-numbers-details')
          }

          this.scrollTop()
        })
        .catch(err => {
          this.$snack.open(err.message || 'Problem creating phases', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'create_job_phases')
        })
    },

    incrementSetDuplicatePhase(obj) {
      // update clicked on phase code and increment if duplicate phase and cost type
      const phase = this.jobPhases.findIndex(itm => itm.id === obj.id)
      if (phase !== -1) {
        let lePhaseCode = parseInt(obj.phase, 10)
        if (isNaN(lePhaseCode)) {
          return
        }

        while (this.jobPhases.some(itm => parseInt(itm.Phase_Code, 10) === lePhaseCode && itm.Cost_Type === this.jobPhases[phase].Cost_Type)) {
          lePhaseCode++
        }
        this.jobPhases[phase].Phase_Code = lePhaseCode
      }
    },

    listenPhaseSelect(obj) {
      this.incrementSetDuplicatePhase(obj)
    },

    openJobDocuments() {
      this.$Modal({
        parent: this,
        name: 'job-files',
        size: 'xl', // sm, md, lg, xlp
        hideClose: false,
        title: 'Add Job Documents',
        component: () => import('components/Dashboard/JobWo/JobFiles3.vue'),
        props: {
          jobId: this.jobId,
          inSequence: true
        }
      })
    },

    detailsRowSelected(data) {
      console.log(data)
      if (!this.combineAsPhaseCode) {
        this.combineAsPhaseCode = data.Phase_Code || ''
        this.combineAsPhaseDesc = data.Description || ''
      }

      if (this.combineAllCostTypes) {
        const phaseCode = data.Phase_Code
        const table = this.$refs.detailsTable.table
        const rows = table.getData().filter(itm => itm.Phase_Code === phaseCode)
        const rowIds = rows.map(itm => itm.id)
        table.selectRow(rowIds)
      }

      this.setSelectedRowData()
    },

    detailsRowDeselected(data) {
      if (this.combineAllCostTypes) {
        const phaseCode = data.Phase_Code
        const table = this.$refs.detailsTable.table
        const rows = table.getData().filter(itm => itm.Phase_Code === phaseCode)
        const rowIds = rows.map(itm => itm.id)
        table.deselectRow(rowIds)
      }

      this.setSelectedRowData()
    },

    setSelectedRowData() {
      const selectedRows = this.$refs.detailsTable.getSelectedData()

      if (!selectedRows.length) {
        this.combineAsPhaseCode = ''
        this.combineAsPhaseDesc = ''
      }
    }
  },

  mounted() {
    if (!this.jobPhases.length) {
      this.getJobPhases().then(() => {
        this.jobPhases = this.sortPhases(this.jobPhases)
      })
    }

    this.$bus.$on(['add-job-phase', 'edit-job-phase'], () => {
      this.getJobPhases().then(() => {
        this.jobPhases = this.sortPhases(this.jobPhases)
      })
    })

    this.$bus.$on('phaseSelected', this.listenPhaseSelect)

    if (this.job.quote_number) {
      this.$store.commit('quoteMaterialList', {})
      this.$store.dispatch('getQuoteMaterialList', { quoteNumber: this.job.quote_number }).catch(() => {
        this.$snack.open('Problem getting quote details', 'warning')
      })
    }

    if (this.enterFromJobAdd) {
      this.$bus.$emit('modalClose', 'JobDetails')
    }

    if (this.enterFromJobAdd) {
      this.tab = 'Details'
    }
  },

  beforeDestroy() {
    this.$bus.$off('phaseSelected', 'listenPhaseSelect')
  },

  watch: {
    combineAsPhaseCode(val) {
      const selected = this.$refs.detailsTable.table.getSelectedData()
      const phaseCodeExists = this.jobPhases.some(itm => parseInt(itm.Phase_Code) === parseInt(val))
      const phaseCodeInSelected = selected.some(itm => parseInt(itm.Phase_Code) === parseInt(val))

      // if phasecode exists and not in selected make it blank
      if (phaseCodeExists && !phaseCodeInSelected) {
        this.$snack.open('Phase code already in use', 'warning')
        this.combineAsPhaseCode = ''
      }

      if (val.length > 4) {
        this.combineAsPhaseCode = val.substring(0, 4)
      }
    },
    combineAsPhaseDesc(val) {
      if (val.length > 25) {
        this.combineAsPhaseDesc = val.substring(0, 25)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';
</style>

<style lang="scss">
.tabulator {
  .tabulator-cell {
    &.centered-checkbox {
      > * {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }

    // white-space: normal!important
  }
}

.bold-row .tabulator-cell {
  font-weight: bold;
}
</style>
