
<div :class="['sh-widget', 'wide-xl', 'card', { 'max-min-height': tableSettings.tableData.length > 1 }]">
  <div class="content">
    <h3>Time Card Approvals</h3>
    <div class="content-inner show-scrollbars">
      <bh-table :table-settings="tableSettings" @edit-item-obj="selectPmForFilter" />
      <waiting-spinner :contained="true" />
    </div>
  </div>
</div>
