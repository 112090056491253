
<div style="height:100%;">
  <simple-header/>
  <div class="material-lists">
    <div class="row">
      <div class="col-sm-12">
        <material-lists :list-type="listType"/>
      </div>
    </div>
  </div>
</div>
