<template>
  <div>

    <button
      class="btn btn-sm"
      @click="$refs.quoteReportList.exportCSV('quote-report-list')"
      style="margin:5px 0"
    >
      Download CSV
    </button>

    <!-- <p v-if="data.length >= 200">
      <i>Showing first 200 results</i>
    </p> -->

    <tabulator-table
      :table-data="reducedData"
      :table-columns="tableColumns"
      table-fit="fitColumns"
      :table-condensed="false"
      empty-text="(none)"
      ref="quoteReportList"
      :table-bordered="true"
      :progressive-render="true"
      @colClick="$emit('colClick', $event)"
    />

    <waiting-spinner/>
  </div>
</template>
<script>
import {mapGetters, mapState} from 'vuex';
import appFuncs from 'appFuncs';
import quoteFuncs from 'mixins/quoteFuncs';
import commonFuncs from 'mixins/commonFuncs';
import TabulatorTable from 'components/UIComponents/TabulatorTable';
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {};
  },

  props: {
    data: {
      type: Array,
      required: true
    }
  },

  mixins: [
    commonFuncs,
    quoteFuncs
  ],

  components: {
    TabulatorTable,
    WaitingSpinner
  },

  computed: {
    ...mapState([
      'quoteFilterStatus',
      'quoteFilterEmployee',
      'customers',
      'customerContacts'
    ]),

    reducedData() {
      let data = [...this.data];
      // if (data.length >= 200) {
      //   return data.slice(0,200);
      // }
      return data;
    },

    tableColumns() {
      return [
        {
          title: 'Quote Number',
          field: 'quote_number',
          width: 120
        },
        {
          title: 'Fiscal Year',
          field: 'fiscal_year',
          width: 120,
          cssClass: 'hide-col'
        },
        {
          title: 'Description',
          field: 'description',
          headerSort: true,
          width: 300
        },
        {
          title: 'Customer',
          field: 'customer_name',
          headerSort: true,
          width: 200
        },
        // {
        //   title: 'Contact',
        //   field: 'contact_name',
        //   headerSort: true,
        //   width: 200,
        //   formatter: (cell) => {
        //     const rowData = cell.getRow().getData();
        //     const contact = this.customerContacts.find(contact => contact.Id === rowData.contact_app_id);
        //     if (contact) {
        //         return `${contact.FirstName} ${contact.LastName}`;
        //     }
        //     return cell.getValue();
        //   },
        // },
        {
          title: 'Department',
          field: 'job_type',
          headerSort: true,
          width: 120
        },
        {
          title: 'Status',
          field: 'statusName',
          headerSort: true,
          width: 200
        },
        {
          title: 'Lead Estimator',
          field: 'quoted_by_name',
          headerSort: true,
          width: 220
        },
        {
          title: 'Bid Captain',
          field: 'captain_name',
          headerSort: true,
          width: 220
        },
        {
          title: 'Sales Lead',
          field: 'sales_lead_name',
          headerSort: true,
          width: 220
        },
        // {
        //   title: 'Quoted Price',
        //   field: 'price',
        //   width: 180,
        //   formatter: 'money',
        //   formatterParams: { symbol: '$', precision: 0  }
        // },
        {
          title: 'Selling Price',
          field: 'selling_price',
          width: 180,
          formatter: 'money',
          formatterParams: {symbol: '$', precision: 0}
        },
        {
          title: 'Created Date',
          field: 'date_opened',
          width: 120,
          formatter: 'date',
        },
        {
          title: 'Sent Date',
          field: 'sent_date',
          width: 120,
          formatter: 'date',
        },
        {
          title: 'Total',
          field: 'totalCountFlag',
          width: 120,
          cssClass: 'hide-col'
        },
        {
          title: 'Won',
          field: 'isWonFlag',
          width: 120,
          cssClass: 'hide-col'
        },
      ];
    },
  }
}

</script>

<style lang="scss" scoped>
@import "src/assets/sass/paper/_variables.scss";
</style>

<style lang="scss">
.tabulator {
  .tabulator-cell {
    &.centered-checkbox {
      > * {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }

    // white-space: normal!important
  }

  .hide-col {
    display: none !important;
  }
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  white-space: normal !important;
}

</style>
