<template>
  <div class="max-width-xlarge">
    <div class="row">
      <div class="col-md-3 col-lg-2" v-show="isAdmin">
        <set-view-as-employee />
      </div>
      <div class="col-md-3 col-lg-2">
        <select-department-as-employee :value="selectedDepartment" :is-show-label="true" :employee-id="viewAsEmployeeId" />
      </div>
    </div>

    <div>
      <widget-hours-tracker :employee-id="viewAsEmployeeId" :show-department-selector="true" />
      <widget-group-metrics />
    </div>

    <div class="row">
      <div class="col-md-12">
        <widget-hours-table />
      </div>
    </div>

    <!-- <div>
      <widget-quotes-by-department />
      <widget-billings-by-department />
    </div>

    <div>
      <widget-projects-by-department />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WidgetHoursTracker from 'components/Dashboard/Widgets/WidgetHoursTracker'
import SetViewAsEmployee from 'components/Dashboard/Widgets/SetViewAsEmployee'
import SelectDepartmentAsEmployee from 'components/Dashboard/Widgets/SelectDepartmentAsEmployee'
import WidgetHoursTable from 'components/Dashboard/Widgets/WidgetHoursTable.vue'
import WidgetGroupMetrics from 'components/Dashboard/Widgets/WidgetGroupMetrics.vue'
import WidgetQuotesByDepartment from '../Widgets/WidgetQuotesByDepartment.vue'
import WidgetBillingsByDepartment from '../Widgets/WidgetBillingsByDepartment.vue'
import WidgetProjectsByDepartment from '../Widgets/WidgetProjectsByDepartment.vue'

export default {
  data() {
    return {
      selectedValue: this.value,
      emCostGroupsData: []
    }
  },

  computed: {
    ...mapGetters(['emCostGroups', 'employees', 'viewAsEmployeeId', 'selectedDepartment']),

    isAdmin() {
      return this.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPM() {
      const emp = this.employees.find(emp => emp.Employee_Code == this.viewAsEmployeeId)
      return emp && (emp.role === 'pm' || emp.role === 'captain')
    },

    isCaptain() {
      const emp = this.employees.find(emp => emp.Employee_Code == this.viewAsEmployeeId)
      return emp && emp.role === 'captain'
    },

    hasRows() {
      return this.tableData.some(row => row.qty && !row.hidden)
    }
  },

  components: {
    WidgetHoursTracker,
    SetViewAsEmployee,
    SelectDepartmentAsEmployee,
    WidgetHoursTable,
    WidgetGroupMetrics,
    WidgetQuotesByDepartment,
    WidgetBillingsByDepartment,
    WidgetProjectsByDepartment
  }
}
</script>
