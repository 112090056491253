
<div>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label>Parent Category</label>
        <br>
        <span class="current-category">
          {{ currentParentName }}
        </span>
      </div>
      <materials-category-table @selectCategory="categoryCurrent = $event" label="Choose Subcategory" :init-category-current="categoryCurrent" :item-type="itemType"/>
      <br>
      <button
        class="btn"
        @click="$bus.$emit('changeMaterialCategory', categoryCurrent), $bus.$emit('modalClose', 'MaterialsChangeCategory')"
        v-html="'Set Parent Category'"
      />
    </div>
  </div>
</div>
