
<div>
  <div class="content">
    <div class="card">
      <h5>Edit Field</h5>
      <div v-if="!singleField" class="form-group">
        <label>Field Name</label>
        <input type="text" v-model="localObj.name" class="form-control"/>
      </div>
      <div class="form-group">
        <label>Field Value</label>
        <wysiwyg v-model="localObj.value"/>
      </div>
      <button class="btn btn-success" @click="saveItem">Save</button>
      <button class="btn btn-danger" @click="closeModal">Cancel</button>
    </div>
  </div>
</div>
