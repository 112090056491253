<template>
  <div></div>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    title: {
      type: String,
      required: true
    },
    html: {
      type: [String, Number],
      required: true
    },
    confirmButton: {
      type: Object,
      required: true
    },
    cancelButton: {
      type: Object,
      default: () => ({
        text: 'Cancel',
        cb: () => {}
      })
    },
    hideCancel: {
      type: Boolean,
      default: false
    },
    modalName: {
      type: String,
      required: true
    }
  },
  methods: {
    showModal() {
      const ModalComponent = {
        props: {
          htmlContent: {
            type: [String, Number],
            required: true
          },
          confirmButton: {
            type: Object,
            required: true
          },
          cancelButton: {
            type: Object,
            default: () => ({
              text: 'Cancel',
              cb: () => {}
            })
          },
          hideCancel: {
            type: Boolean,
            default: false
          }
        },
        render(h) {
          return h('div', [
            h('div', {
              domProps: { innerHTML: this.htmlContent },
              style: { marginBottom: '20px' } // Add margin here
            }),
            this.confirmButton.text
              ? h(
                  'button',
                  {
                    class: this.confirmButton.class || 'btn btn-primary',
                    on: { click: () => this.confirmAction() }
                  },
                  this.confirmButton.text
                )
              : null,
            this.hideCancel
              ? null
              : h(
                  'button',
                  {
                    class: this.cancelButton.class || 'btn btn-default',
                    on: {
                      click: () => this.closeModal()
                    }
                  },
                  this.cancelButton.text
                )
          ])
        },
        methods: {
          confirmAction: () => {
            if (typeof this.confirmButton.cb === 'function') this.confirmButton.cb()
            this.$emit('confirm')
            this.closeModal()
          },
          closeModal: () => {
            if (typeof this.cancelButton.cb === 'function') this.cancelButton.cb()
            this.$emit('cancel')
            this.$bus.$emit('modalClose', this.modalName)
          }
        }
      }

      this.$Modal({
        parent: this,
        name: this.modalName,
        size: 'md',
        title: this.title,
        component: ModalComponent,
        centerVertically: true,
        hideClose: this.hideCancel,
        props: {
          htmlContent: this.html,
          confirmButton: this.confirmButton,
          cancelButton: this.cancelButton,
          hideCancel: this.hideCancel
        }
      })
    },
    confirmAction() {
      if (typeof this.confirmButton.cb === 'function') this.confirmButton.cb()
      this.closeModal()
    },
    closeModal() {
      this.$bus.$emit('modalClose', this.modalName)
    }
  }
}

/*
// usage
async showConfirm() {
  const result = await openConfirmDialog({
    title: 'Confirm Action',
    html: 'Are you sure you want to proceed?',
    confirmButton: {
      text: 'OK',
      cb: () => {}
    },
    modalName: 'confirmDialog',
    hideCancel: true
  })

  const confirmed = result ? true : false

  if (confirmed) {
    console.log('user confirmed')
  } else {
    console.log('user cancelled')
  }
}

*/
</script>
