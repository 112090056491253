<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <h4>Summary</h4>
        <tabulator-table
          style="max-width: 330px"
          :table-data="summaryData"
          :table-columns="tableColumnsSummary"
          table-fit="fitColumns"
          :table-condensed="false"
          empty-text="(none)"
          ref="reportTable"
          :table-bordered="true"
          :header-visible="false"
        />
      </div>
    </div>
    <br/>
    <h4>Totals</h4>
    <div class="row">
      <div class="col-sm-12 col-md-3 col-lg-2">
        <div class="form-group">
          <label>Lead Estimator:</label>
          <select-field
            :options="quoteEmployeeList"
            :option-display-keys="['Employee_Name']"
            :option-val="'Employee_Code'"
            v-model="quotedByFilter"
            :allow-clear="true"
            :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
          />
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-2">
        <div class="form-group">
          <label>Bid Captain:</label>
          <select-field
            :options="quoteEmployeeList"
            :option-display-keys="['Employee_Name']"
            :option-val="'Employee_Code'"
            v-model="bidCaptainFilter"
            :allow-clear="true"
            :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
          />
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-2">
        <div class="form-group">
          <label>Sales Lead:</label>
          <select-field
            :options="quoteEmployeeList"
            :option-display-keys="['Employee_Name']"
            :option-val="'Employee_Code'"
            v-model="salesLeadFilter"
            :allow-clear="true"
            :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
          />
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-2">
        <customer-select-field v-model="filterCustomer" :allow-add-new="false" :allow-clear="true" :required="false"/>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-2">
        <customer-contact-select-field
          :customer-code="filterCustomer"
          :label="'Customer Contact'"
          v-model="filterCustomerContact"
          :required="false"
          :output-name="false"
          :allow-clear="true"
          :allow-add-new="false"
        />
      </div>
      <div class="col-sm-12 col-md-3 col-lg-2">
        <div class="form-group">
          <label>Fiscal Year:</label>
          <select-field :options="fiscalYears" v-model="showFiscalYear" :option-display-keys="['name']" :option-val="'val'" @selectItem="val => changeFiscalYear(val)"/>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-2">
        <div class="form-group">
          <label>Department</label>
          <select-field :options="departments" v-model="departmentFilter" :option-display-keys="['name']" :option-val="'name'" :allow-clear="true"/>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-3">
        <div class="form-group">
          <checkbox2 :true-val="true" :false-val="false" v-model="showPending" @change="filterData()">Show Sent and Pending Quotes</checkbox2>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-3">
        <div class="form-group">
          <checkbox2 :true-val="true" :false-val="false" v-model="showCancelled" @change="filterData()">Show Cancelled</checkbox2>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-3">
        <div class="form-group">
          <checkbox2 :true-val="true" :false-val="false" v-model="showOnHold" @change="filterData()">Show On Hold</checkbox2>
        </div>
      </div>
    </div>

    <button class="btn btn-sm" @click="$refs.reportTable.exportCSV('quote-report-summary')" style="margin: 5px 0">Download CSV</button>

    <tabulator-table :table-data="data" :table-columns="tableColumns" table-fit="fitColumns" :table-condensed="false" empty-text="(none)" ref="reportTable" :table-bordered="true"/>

    <h4>Details</h4>

    <div class="row">
      <div class="col-sm-12">
        <quote-report-list :data="resultData" @colClick="colClick"/>
      </div>
    </div>

    <waiting-spinner/>
  </div>
</template>
<script>
import {mapGetters, mapState} from 'vuex'
import appFuncs from 'appFuncs'
import quoteFuncs from 'mixins/quoteFuncs'
import commonFuncs from 'mixins/commonFuncs'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import SelectField from 'components/UIComponents/SelectField'
import CustomerSelectField from 'components/UIComponents/CustomerSelectField'
import QuoteReportList from 'components/Dashboard/Quotes/QuoteReportList'
import Checkbox2 from 'components/UIComponents/Checkbox'
import CustomerContactSelectField from 'components/UIComponents/CustomerContactSelectField'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  data() {
    return {
      showFiscalYear: '',
      filterCustomer: '',
      filterCustomerContact: '',
      data: [],
      resultData: [],
      showPending: false,
      showCancelled: false,
      showOnHold: false,
      departmentFilter: '',
      departments: [],
      quotedByFilter: '',
      bidCaptainFilter: '',
      salesLeadFilter: ''
    }
  },

  mixins: [commonFuncs, quoteFuncs],

  components: {
    TabulatorTable,
    SelectField,
    CustomerSelectField,
    QuoteReportList,
    Checkbox2,
    CustomerContactSelectField,
    WaitingSpinner
  },

  computed: {
    ...mapGetters(['employees', 'customers', 'customerContacts']),

    ...mapState(['quoteFilterStatus', 'quoteFilterEmployee']),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },

    tableColumnsSummary() {
      return [
        {
          title: '',
          field: 'title',
          width: 200,
          headerSort: false
        },
        {
          title: '',
          field: 'value',
          headerSort: false,
          width: 130,
          formatter: 'money',
          formatterParams: {symbol: '$', precision: 0}
        }
      ]
    },

    tableColumns() {
      return [
        {
          title: 'Description',
          field: 'description',
          width: 200
        },
        {
          title: 'Total Quoted',
          field: 'totalPrice',
          headerSort: false,
          width: 130,
          formatter: 'money',
          formatterParams: {symbol: '$', precision: 0}
        },
        {
          title: '% of Total',
          field: 'totalPercent',
          headerSort: false,
          width: 80,
          visible: this.filterCustomer ? false : true
        },
        {
          title: 'Total Won',
          field: 'wonAmount',
          headerSort: false,
          width: 130,
          formatter: 'money',
          formatterParams: {symbol: '$', precision: 0}
        },
        {
          title: 'Total Lost',
          field: 'lostAmount',
          headerSort: false,
          width: 130,
          formatter: 'money',
          formatterParams: {symbol: '$', precision: 0}
        },
        {
          title: 'Dollar Win %',
          field: 'wonPercent',
          width: 100
        },
        {
          title: 'Quotes Qty Win %',
          field: 'wonDepartmentPercent',
          width: 100
        },
        {
          title: '% of Total Won',
          field: 'winPercent',
          headerSort: false,
          width: 100,
          visible: this.filterCustomer ? false : true,
          headerTooltip: 'Department won in dollars divided by All Departments Total Won in Dollars',
          titleFormatter: function () {
            return `
                % of Total Won
                <span
                  style="padding-left:3px"
                  class="ti-info-alt"
                />
              `
          }
        },
        // {
        //   title: 'Department Dollar Win %>',
        //   field: 'winRatio',
        //   headerSort: false,
        //   width: 100,
        //   visible: this.filterCustomer ? false : true,
        //   headerTooltip: 'Department won in dollars divided by department quoted in dollars',
        //   titleFormatter: function () {
        //     return `
        //       Department Dollar Win %
        //       <span
        //         style="padding-left:3px"
        //         class="ti-info-alt"
        //       />
        //     `;
        //   }
        // },
        {
          title: 'Number of Quotes',
          field: 'opportunities',
          headerSort: false,
          width: 100
        },
        {
          title: 'Quotes Won Qty',
          field: 'won',
          width: 100
        },
        {
          title: 'Quotes Lost Qty',
          field: 'lost',
          width: 100
        },
        {
          title: 'Average Bid',
          field: 'bidAverage',
          width: 130,
          formatter: 'money',
          formatterParams: {symbol: '$', precision: 0}
        },
        {
          title: 'Average Bid Won',
          field: 'bidAverageWon',
          width: 130,
          formatter: 'money',
          formatterParams: {symbol: '$', precision: 0}
        },
        {
          title: 'Quotes Pending',
          field: 'pending',
          headerSort: false,
          width: 100,
          visible: this.showPending
        },
        {
          title: 'Quotes Cancelled',
          field: 'cancelledQty',
          width: 100,
          visible: this.showCancelled
        }
      ]
    },

    quoteEmployeeList() {
      if (Array.isArray(this.employees) && this.employees.length) {
        let employees = JSON.parse(JSON.stringify(this.employees))
        // employees.unshift({ Employee_Name: 'All', Employee_Code: '' });
        return employees
      }
      return this.employees
    },

    summaryData() {
      let out = {
        inProgress: 0,
        pending: 0,
        won: 0,
        lost: 0,
        total: 0,
        other: 0,
        allTotal: 0
      }

      let data = [...this.allQuotes]

      for (let i = 0; i < data.length; i++) {
        let statusLower = data[i].status.toLowerCase()
        let wonlost = data[i].won_lost.toLowerCase()
        const sellingPrice = parseFloat(data[i].selling_price) || 0
        const projectedPrice = parseFloat(data[i].price) || 0

        // apply filters
        if (this.filterCustomer && data[i].customer_code != this.filterCustomer) continue
        if (this.filterCustomerContact && data[i].contact_app_id != this.filterCustomerContact) continue
        if (this.salesLeadFilter && data[i].sales_lead_id != this.salesLeadFilter) continue
        if (this.bidCaptainFilter && data[i].captain != this.bidCaptainFilter) continue

        const quotedBy = Array.isArray(data[i].quoted_by_id) && data[i].quoted_by_id[0] ? data[i].quoted_by_id[0] : data[i].quoted_by_id
        if (this.quotedByFilter && quotedBy != this.quotedByFilter) continue

        if (this.departmentFilter && cat !== this.departmentFilter) continue

        if (statusLower === 'quote in progress' || statusLower === 're-opened not sent') {
          out.inProgress += projectedPrice
        } else if (statusLower === 'sent and pending') {
          out.pending += sellingPrice
        } else if (wonlost === 'won') {
          out.won += sellingPrice
        } else if (wonlost === 'lost') {
          out.lost += sellingPrice
        } else {
          out.other += sellingPrice || projectedPrice || 0
        }

        // out.inProgress = 0;

        out.total = out.inProgress + out.pending + out.won + out.lost
        out.allTotal = out.inProgress + out.pending + out.won + out.lost + out.other
      }

      return [
        {title: 'In Progress (Funnel)', value: this.formatNumber(out.inProgress, true)},
        {title: 'Result Pending', value: this.formatNumber(out.pending, true)},
        {title: '', value: ''},
        {title: 'Won', value: this.formatNumber(out.won, true)},
        {title: 'Lost', value: this.formatNumber(out.lost, true)},
        {title: 'Total', value: this.formatNumber(out.total, true)},
        {title: '', value: ''},
        {title: 'Cancelled or On Hold', value: this.formatNumber(out.other, true)},
        {title: 'All Total', value: this.formatNumber(out.allTotal, true)}
      ]
    }
  },

  methods: {
    changeFiscalYear(val) {
      this.$store.commit('quoteFilterFiscalYear', val)
      this.getData(0)
    },

    async getData() {
      this.$bus.$emit('setWaiting', {name: 'getQuoteData', message: 'Getting Data'})
      await this.$store.dispatch('getQuotes', {refresh: true})
      this.filterData()
      this.$bus.$emit('stopWaiting', 'getQuoteData')
    },

    filterData() {
      // copy data array not reference
      let data = [...this.allQuotes]
      const categoryObj = {
        description: '',
        total: 0,
        totalPrice: 0,
        wonAmount: 0,
        lostAmount: 0,
        cancelledAmount: 0,
        totalPercent: 0,
        winPercent: 0,
        winRatio: 0,
        lostRatio: 0,
        won: 0,
        lost: 0,
        opportunities: 0,
        wonPercent: 0,
        lostPercent: 0,
        wonDepartmentPercent: 0,
        lostDepartmentPercent: 0,
        bidAverage: 0,
        bidAverageWon: 0,
        pending: 0,
        cancelledQty: 0
      }
      let totals = Object.assign({}, categoryObj) // copy dont reference
      ;(totals.description = 'Total'), (totals.winPercent = ''), (totals.totalPercent = ''), (totals.winRatio = '')
      let catObj = {}

      data = data.sort((a, b) => {
        return a.job_type > b.job_type ? 1 : -1
      })

      let resultData = []

      for (let i = 0; i < data.length; i++) {
        let price = parseFloat(data[i].price)
        let sellingPrice = data[i].selling_price ? parseFloat(data[i].selling_price) : 0

        let cat = data[i].job_type || 'Other'

        if (!sellingPrice && !price) continue // only supporting sent quotes with price

        if (this.filterCustomer && data[i].customer_code != this.filterCustomer) continue
        if (this.filterCustomerContact && data[i].contact_app_id != this.filterCustomerContact) continue
        if (this.salesLeadFilter && data[i].sales_lead_id != this.salesLeadFilter) continue
        if (this.bidCaptainFilter && data[i].captain != this.bidCaptainFilter) continue

        const quotedBy = Array.isArray(data[i].quoted_by_id) && data[i].quoted_by_id[0] ? data[i].quoted_by_id[0] : data[i].quoted_by_id
        if (this.quotedByFilter && quotedBy != this.quotedByFilter) continue

        if (this.departmentFilter && cat !== this.departmentFilter) continue

        if (cat === 'Contracting' && sellingPrice < 100000) {
          cat = 'Small Projects <100k'
        } else if (cat === 'Contracting') {
          cat = 'Large Projects >100k'
        }

        catObj[cat] = catObj[cat] || Object.assign({}, categoryObj)
        catObj[cat].description = cat

        const inDepartments = this.departments.findIndex(itm => {
          return itm.name === cat
        })
        if (inDepartments === -1) {
          this.departments.push({name: cat})
        }

        let statusLower = data[i].status.toLowerCase()

        // Check for 'Sent and Pending' status
        if (statusLower === 'sent and pending' && !this.showPending) {
          // if status is sent and pending and showPending is false
          continue
        }

        // Check for statuses containing "cancelled"
        if (statusLower.includes('cancelled') && !this.showCancelled) {
          // if status contains cancelled and showCancelled is false
          continue
        }

        if (statusLower.includes('on hold') && !this.showOnHold) {
          // if status contains cancelled and showCancelled is false
          continue
        }

        // Process the 'Sent and Pending' status
        if (statusLower === 'sent and pending') {
          totals.pending++
          catObj[cat].pending++
        }

        // quoted price
        totals.totalPrice += sellingPrice
        catObj[cat].totalPrice += sellingPrice

        totals.opportunities++
        catObj[cat].opportunities++

        // selling price
        if (data[i].won_lost === 'Won' && data[i].selling_price) {
          totals.won++
          catObj[cat].won++
          totals.wonAmount += parseFloat(data[i].selling_price)
          catObj[cat].wonAmount += parseFloat(data[i].selling_price)
          data[i].isWonFlag = 1
        }

        if (data[i].won_lost === 'Lost' && data[i].selling_price) {
          totals.lost++
          catObj[cat].lost++
          totals.lostAmount += parseFloat(data[i].selling_price)
          catObj[cat].lostAmount += parseFloat(data[i].selling_price)
          data[i].isLostFlag = 1
        }

        if (data[i].status.toLowerCase().includes('cancelled')) {
          catObj[cat].cancelledQty++
        }

        data[i].statusName = data[i].won_lost === 'Won' || data[i].won_lost === 'Lost' ? data[i].won_lost : data[i].status

        data[i].totalCountFlag = 1

        resultData.push(data[i])
      }

      let out = []

      if (!this.departmentFilter) {
        out.push(totals)
      }

      for (var prop in catObj) {
        if (catObj.hasOwnProperty(prop)) {
          // calc total percent of category quoted price
          let catTotal = parseFloat(catObj[prop].totalPrice)
          let allTotal = totals.totalPrice
          if (allTotal && catTotal) {
            catObj[prop].totalPercent = `${parseFloat(Math.round((catTotal / allTotal) * 100))}`
          }

          // calc total quoted price
          let catPrice = catObj[prop].wonAmount
          if (catPrice && catTotal) {
            catObj[prop].winRatio = `${parseFloat(Math.round((catPrice / catTotal) * 100))}`
          }

          // calc win percent of category
          let catWin = catObj[prop].wonAmount
          let totalWin = totals.wonAmount
          if (catWin && totalWin) {
            catObj[prop].winPercent = `${parseFloat(Math.round((catWin / totalWin) * 100))}`
          }

          // calc total quoted price
          let catPriceLost = catObj[prop].lostAmount
          if (catPriceLost && catTotal) {
            catObj[prop].lostRatio = `${parseFloat(Math.round((catPriceLost / catTotal) * 100))}`
          }

          // calc win percent of category
          let catLost = catObj[prop].lostAmount
          let totalLost = totals.lostAmount
          if (catLost && totalLost) {
            catObj[prop].lostPercent = `${parseFloat(Math.round((catLost / totalLost) * 100))}`
          }

          if (totals.won && totals.opportunities) {
            totals.wonDepartmentPercent = `${parseFloat(Math.round((totals.won / totals.opportunities) * 100))}`
          }

          if (catObj[prop].won / catObj[prop].opportunities) {
            catObj[prop].wonDepartmentPercent = `${parseFloat(Math.round((catObj[prop].won / catObj[prop].opportunities) * 100))}`
          }

          if (totals.totalPrice && totals.wonAmount) {
            totals.wonPercent = `${parseFloat(Math.round((totals.wonAmount / totals.totalPrice) * 100))}`
          }

          if (catTotal && catObj[prop].wonAmount) {
            catObj[prop].wonPercent = `${parseFloat(Math.round((catObj[prop].wonAmount / catTotal) * 100))}`
          }

          totals.bidAverageWon = Math.round(totals.wonAmount / totals.won)
          if (catObj[prop].wonAmount && catObj[prop].won) {
            catObj[prop].bidAverageWon = Math.round(catObj[prop].wonAmount / catObj[prop].won)
          }

          totals.bidAverage = Math.round(totals.totalPrice / totals.opportunities)
          if (catTotal) {
            catObj[prop].bidAverage = Math.round(catTotal / catObj[prop].opportunities)
          }

          out.push(catObj[prop])
        }
      }

      this.data = out
      this.resultData = resultData
    },

    editItem(obj, quoteIncr) {
      let quoteData = this.allQuotes.find(itm => {
        if (quoteIncr) {
          return parseInt(itm.quote_incr) === parseInt(quoteIncr)
        } else {
          return parseInt(itm.id) === parseInt(obj.id)
        }
      })

      // update url
      if (quoteData && quoteData.quote_incr) {
        this.$router.push({
          params: {openQuoteIncr: quoteData.quote_incr.toString()}
        })
      }

      this.$Modal({
        parent: this,
        name: 'quote-edit', // used for closing specific modal programmatically
        size: 'xxl', // sm, md, lg, xl
        hideClose: true,
        title: '',
        warnOnEsc: true,
        component: () => import('components/Dashboard/Quotes/Quote2Edit.vue'),
        props: {
          componentData: {
            quoteData
          }
        }
      })
    },

    colClick(obj) {
      const col = ((obj || {}).cell || {}).field || null
      const id = ((obj || {}).row || {}).id || null
      this.editItem({id})
    }
  },

  mounted() {
    this.showFiscalYear = this.getSetFiscalYear()

    this.$store.commit('quoteFilterStatus', '')
    this.$store.commit('quoteFilterEmployee', '')
    this.$store.commit('quoteFilterQuotedBy', '')
    this.$store.commit('quoteFilterSalesLead', '')
    this.$store.commit('quoteFilterCustomer', '')
    this.$store.commit('quoteFilterDepartment', '')
    this.$store.commit('quoteFilterCaptain', '')
    this.$store.commit('quoteFilterEmployeeAny', [])

    this.$store.dispatch('getEmployees')
    this.getData()
  },

  watch: {
    filterCustomer() {
      this.getData()
    },

    filterCustomerContact() {
      this.getData()
    },

    allQuotes() {
      this.filterData()
    },

    departmentFilter() {
      this.filterData()
    },

    quotedByFilter() {
      this.filterData()
    },

    bidCaptainFilter() {
      this.filterData()
    },

    salesLeadFilter() {
      this.filterData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';
</style>

<style lang="scss">
.tabulator {
  .tabulator-cell {
    &.centered-checkbox {
      > * {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }

    // white-space: normal!important
  }
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  white-space: normal !important;
}
</style>
