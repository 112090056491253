
<div>
  <div class="select-field-multi-container">
    <select-field-dynamic
      :options.sync="selectableOptions"
      :option-display-keys="optionDisplayKeys"
      :option-val="optionVal"
      :disabled="disabled"
      :disabled-input="disabledInput"
      :classes="allClasses"
      :option-seperator="optionSeperator"
      :empty-option-text="emptyOptionText"
      :inline="inline"
      :placeholder-text="placeholderText"
      @selectItem="addItem"
      :allow-add-item="allowAddItem"
      @addOption="addOption"
      :allow-add-simple-item="allowAddSimpleItem"
      :item-name="itemName"
      :preselect-single-option="preselectSingleOption"
      :filter-on-key-val="filterOnKeyVal"
      :label="label"
      :show-remove-warning="true"
      :allow-clear="allowClear"
      @clear="clear"
    />

    <div class="selected-items-container" v-if="showSelected">
      <label class="assigned" v-if="selectedItems.length" v-html="itemName ? itemName + 's' : 'Assigned'" />
      <ul>
        <li v-for="(itm, i) in selectedItems" :key="i">
          -
          <span v-html="getDisplayVal(itm)"></span>
          <i class="ti ti-close" @click="removeFromSelected(itm)" v-if="!disabled"></i>
        </li>
      </ul>
    </div>
  </div>

  <waiting-spinner />
</div>
