<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <bh-table :table-settings="tableSettings" @edit-item-obj="goToContact"/>
      </div>
    </div>
    <waiting-spinner :wait="wait"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import BhTable from 'components/UIComponents/BhTable';
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

export default {
  name: 'VendorList',
  data() {
    return {
      wait: {
        message: ''
      },
      tableSettings: {
        tableData: [],
        fields: {
          'Vendor_Name': {
            name: 'Vendor Name'
          },
          'Address_1': {
            name: 'Address 1'
          },
          'Address_2': {
            name: 'Address 2'
          },
          'Address_3': {
            name: 'City'
          },
          'State': {
            name: 'Province'
          }
        },
        noItemEdit: false,
        hideSearch: false,
      },
    };
  },

  components: {
    BhTable,
    WaitingSpinner
  },

  computed: {
    ...mapGetters([
      'vendors'
    ]),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1;
    },
  },

  methods: {
    goToContact(obj) {
      this.$emit('openVendorContact', obj.Vendor_Code);
    }
  },

  mounted() {
    this.$bus.$emit('setWaiting', {name: 'getVendors', message: 'Getting Vendors'});
    this.$store.dispatch('getVendors').then(() => {
      this.$bus.$emit('stopWaiting', 'getVendors');
      this.tableSettings.tableData = this.vendors;
    });
  }
};

</script>

<style lang="scss" scoped>


</style>
