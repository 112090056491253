
<div>
  <div class="row" style="margin-bottom: 40px">
    <div class="col col-sm-12">
      <tabulator-table
        :table-data="filteredRows"
        :table-columns="tableColumns"
        :allow-new-row="false"
        :max-table-height="'700px'"
        :table-condensed="false"
        :selectable="false"
        :row-formatter="rowFormatter"
        :table-fit="'fitColumns'"
        :data-tree="true"
        class="breakout-table"
      />
    </div>
  </div>

  <waiting-spinner />
</div>
