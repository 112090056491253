<template>
  <div class="sh-widget card">
    <div class="content">
      <h4>Jobs Waiting for Signature</h4>
      <table @click="gotoTable">
        <tr>
          <th>Age (Days)</th>
          <th>Jobs</th>
        </tr>
        <tr>
          <td>0 - 3</td>
          <td :class="[{ green: jobAge1 }]">
            {{ jobAge1 }}
          </td>
        </tr>
        <tr>
          <td>4 - 5</td>
          <td :class="[{ yellow: jobAge2 }]">
            {{ jobAge2 }}
          </td>
        </tr>
        <tr>
          <td>5 +</td>
          <td :class="[{ red: jobAge3 }]">
            {{ jobAge3 }}
          </td>
        </tr>
      </table>
      <waiting-spinner :contained="true" />
    </div>
  </div>
</template>

<script>
import store from 'store'
import appFuncs from 'appFuncs'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  components: { WaitingSpinner },
  data() {
    return {
      jobData: [],
      eid: store.getters.sherAuth.eid
    }
  },

  computed: {
    ...mapGetters(['jobAssemble']),

    myJobs() {
      let openStatuses = ['A', 'I']
      let myJobs = this.jobData.filter(itm => {
        if ((itm.Project_Manager == this.eid || itm.Superintendent == this.eid) && openStatuses.indexOf(itm.status) !== -1) {
          return true
        }
      })

      for (var i = myJobs.length - 1; i >= 0; i--) {
        let timestamp = myJobs[i].signature_waiting
        if (timestamp) {
          //console.log(myJobs[i].Job_Number + ': ' + moment(timestamp, 'X').format('MMM Do, LT') );
        }
      }

      return myJobs
    },

    jobAge1() {
      let age = this.myJobs.filter(itm => {
        if (itm.Sign_Off_Status === 'yes') {
          return false
        }
        let date = itm.signature_waiting
        if (date) {
          let diff = moment().format('X') - date
          let days = this.getDays(diff)
          return days <= 3
        }
      })
      return age.length
    },

    jobAge2() {
      let age = this.myJobs.filter(itm => {
        if (itm.Sign_Off_Status === 'yes') {
          return false
        }
        let date = itm.signature_waiting
        if (date) {
          let diff = moment().format('X') - date
          let days = this.getDays(diff)
          return days >= 4 && days <= 5
        }
      })
      return age.length
    },

    jobAge3() {
      let age = this.myJobs.filter(itm => {
        if (itm.Sign_Off_Status === 'yes') {
          return false
        }
        let date = itm.signature_waiting
        if (date) {
          let diff = moment().format('X') - date
          let days = this.getDays(diff)
          return days >= 5
        }
      })
      return age.length
    }
  },

  methods: {
    loadData(refresh) {
      this.$store
        .dispatch('getJobAssemble', { refresh, not_status: 'C' })
        .then(() => {
          this.jobData = this.jobAssemble
        })
        .catch(err => {
          this.$snack.open(err.message || 'Cannot retrieve job data, please refresh to try again', 'error')
        })
    },

    getDays(seconds) {
      return Math.floor(seconds / (3600 * 24))
    },

    gotoTable() {
      this.$router.push({ path: 'app/jobs?filterAll=Need%20Signature' })
    }
  },

  mounted() {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
// @import "src/assets/sass/paper/_variables.scss";

table {
  cursor: pointer;
}
</style>
