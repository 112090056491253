
<div>
  <set-view-as-employee v-if="isAdmin" />

  <div class="card">
    <div class="content">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Sub Task</label>
            <select-field :option-display-keys="['name']" :option-val="'value'" v-model="subTaskType" :allow-clear="true" :options="subTaskOptions" @selectItem="getTasks()" />
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Billing Task</label>
            <select-field :option-display-keys="['name']" :option-val="'value'" v-model="billingTaskStatus" :allow-clear="true" :options="billingTasks" @selectItem="getTasks()" />
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Approval Status</label>
            <select-field :option-display-keys="['name']" :option-val="'value'" v-model="approvalStatus" :allow-clear="true" :options="approvalStatuses" @selectItem="getTasks()" />
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Task Status</label>
            <select-field :option-display-keys="['name']" :option-val="'value'" v-model="taskStatus" :allow-clear="true" :options="statusOptions" @selectItem="getTasks()" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-3" style="margin-bottom: 10px">
          <label>Department</label>
          <select-field
            :options="emCostGroups"
            :option-display-keys="['Cost_Group_Description']"
            :option-val="'Cost_Group_Code'"
            v-model="costGroupCode"
            :allow-clear="true"
            @selectItem="getTasks()"
          />
        </div>
      </div>
      <div class="row" style="">
        <div class="col-sm-12">
          <tabulator-table
            :table-data="filteredTasks"
            :table-columns="tableColumns"
            table-fit="fitColumns"
            :table-condensed="false"
            empty-text="(none)"
            @colClick="colClick"
            :row-formatter="rowFormatter"
            :initial-sort="[
              {
                column: 'date_due',
                dir: 'desc'
              }
            ]"
          />
        </div>
      </div>
      <div class="row" style="padding: 20px 0 0">
        <div class="col-sm-12">
          <div>
            <!-- <button class="btn" @click="addTask">Add Taskasdsdf</button> -->
            <!-- <button class="btn btn-danger" @click="$bus.$emit('modalClose', 'BillingTaskAdd')">Cancel</button>
        <button class="btn" @click="viewTasks">View Tasks</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
