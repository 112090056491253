
<div class="notices is-top not-pdf">
  <transition
    enter-active-class="fadeInUp"
    leave-active-class="fadeOut"
  >
    <div
      v-if="snack && snack.message"
      :class="['snackbar', snack.type,'is-top']"
    >
      <div class="text" v-html="snack.message"/>
      <div
        v-if="snack.actionText"
        class="action"
        @click="snackAction(snack)"
        :class="snack.type"
      >
        <button class="button">
          {{ snack.actionText }}
        </button>
      </div>
    </div>
  </transition>
</div>
