
<div :class="sidebarClasses" :data-background-color="backgroundColor">
  <!--
          Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
          Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
      -->
  <!-- -->
  <div class="sidebar-wrapper" id="style-3">
    <div class="logo">
      <a href="#" class="sidebar-logo">
        <img :src="urls.frontend + 'static/img/logo-inverted.png'" />
      </a>
    </div>
    <slot />
    <ul :class="navClasses">
      <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
      <li v-for="(link, index) in sidebarLinks" @click="showChildren(link)" :class="{ 'child-active': dropdownActive === link.name }">
        <router-link v-if="!testExternalLink(link.path) && !link.children && testPermission(link.role)" :class="'sb-link'" :to="link.path" :ref="link.name">
          <p>
            <i :class="link.icon" />
            {{ link.name }}
          </p>
        </router-link>

        <a v-else-if="!link.children && testPermission(link.role)" :href="link.path" :class="'sb-link'" :target="'_blank'">
          <p>
            <i :class="link.icon" />
            {{ link.name }}
          </p>
        </a>

        <span v-else-if="testPermission(link.role)" :class="'sb-link'">
          <p>
            <i :class="link.icon" />
            {{ link.name }}
          </p>
        </span>

        <ul v-if="dropdownActive === link.name">
          <li v-for="child in link.children" v-if="testPermission(child.role)">
            <router-link v-if="!testExternalLink(child.path)" :to="child.path" :ref="child.name">
              {{ child.name }}
            </router-link>
            <a v-else :href="child.path" :target="'_blank'">{{ child.name }}</a>
          </li>
        </ul>
      </li>

      <li>
        <a :href="'#'" :class="'sb-link'" @click="openFeedback">
          <p>
            <i :class="'ti-flag-alt'" />
            Feedback / Errors
          </p>
        </a>
      </li>

      <li class="logout-link">
        <a href="#" @click="logout()">Log Out</a>
      </li>
    </ul>
    <!-- <moving-arrow :move-y="arrowMovePx">

    </moving-arrow> -->
  </div>

  <!-- <waiting-spinner /> -->
</div>
