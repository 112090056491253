<template>
  <div>
    <div v-if="showFilterRow" class="row">
      <div class="col-sm-12 col-md-4">
        <div class="form-group">
          <label>From Date:</label>
          <date-picker v-model="filterMinDate" :format="'date'" :is-inline="false" :pre-filled="false" @input="getTimeCardEntries()" />
        </div>
        <div class="form-group">
          <label>To Date:</label>
          <date-picker v-model="filterMaxDate" :format="'date'" :is-inline="false" :pre-filled="false" @input="getTimeCardEntries()" />
        </div>
      </div>
      <div class="col-sm-12 col-md-4">
        <div class="form-group">
          <label>Select By Employee:</label>
          <select-field
            v-model="filterEmployee"
            :options="employees"
            :option-display-keys="['Employee_Name']"
            :option-val="'Employee_Code'"
            :empty-option-text="'No options'"
            :allow-clear="true"
            :preselect-single-option="false"
            :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
            @selectItem="getTimeCardEntries(), $refs.selectSubcontractor.selectEl({})"
            ref="selectEmployee"
          />
        </div>
        <div class="form-group">
          <label>Select By Subcontractor:</label>
          <select-field
            v-model="filterSubcontractor"
            :options="subcontractors"
            :option-display-keys="['Name']"
            :option-val="'id'"
            :empty-option-text="'No options'"
            :allow-clear="true"
            :preselect-single-option="false"
            @selectItem="getTimeCardEntries(), $refs.selectEmployee.selectEl({})"
            ref="selectSubcontractor"
          />
        </div>
      </div>
      <div class="col-sm-12 col-md-4">
        <div v-if="type === 'Job' || type === 'Job Extra'">
          <div class="form-group">
            <label>Select By Phase:</label>
            <select-field
              v-model="filterPhase"
              :label-text="'Phase:'"
              :options="phases"
              :option-display-keys="['Phase_Code', 'Description']"
              :option-val="'Phase_Code'"
              :empty-option-text="'Fetching items...'"
              @selectItem="getTimeCardEntries()"
            />
          </div>
        </div>
        <div v-if="type === 'Job' && extraId">
          <div class="form-group" style="margin-bottom: 0">
            <checkbox v-model="showSelected" style="margin: 5px 0"> Show Assigned to this Sign Off</checkbox>
          </div>
          <div class="form-group">
            <checkbox v-model="hideAssigned" style="margin: 5px 0"> Hide Assigned to other Sign Offs</checkbox>
          </div>
        </div>
      </div>
    </div>

    <div style="padding: 20px 0">
      <button class="btn" @click="addTimeEntry" v-if="!isLocked">Add Time Entry</button>
      <p v-else>
        <i>Labor cannot be added to in Locked status.</i>
      </p>

      <button class="btn" @click="addForemanTimeEntry" v-if="!isLocked && isPM">Foreman Time Entry</button>

      <!-- <button v-if="!extraId && isPM && timeCardEntries.entries && timeCardEntries.entries.length" class="btn" @click="syncTimeEntries">Post All to Spectrum</button> -->
      <button v-if="allowDownload" class="btn" @click="$refs.jobWoLabor.exportCSV(`Labor-List-${type}-${itemNumber}`)">Download CSV</button>

      <button class="btn" @click="assignAllFilteredTimeEntries" v-if="!isLocked">Assign All</button>
    </div>

    <p v-if="showEntriesWarning" class="large-result-warning">
      <i><strong>Large number of entries, please use add filter criteria, OR Download CSV.</strong></i>
    </p>

    <p v-else>
      <i>Showing {{ filteredTimecardEntries.length }} of {{ entryCount }} in all dates</i>
      <br /><br />
    </p>

    <tabulator-table v-show="!showEntriesWarning" :table-data="filteredTimecardEntries" :table-columns="tableColumns" table-fit="fitColumns" :table-condensed="false" empty-text="(none)" ref="jobWoLabor" @colClick="tableClick" />

    <waiting-spinner />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import commonFuncs from 'mixins/commonFuncs'
import TabulatorTable from 'components/UIComponents/TabulatorTable'
import SelectField from 'components/UIComponents/SelectField'
import DatePicker from 'components/UIComponents/DatePicker'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import sanitizeHtml from 'sanitize-html'
import dateFormat from 'dateformat'
import moment from 'moment'

export default {
  data() {
    return {
      timeCardEntries: {
        entries: []
      },
      entryCount: 0,
      phases: [],
      showFilterRow: true,
      filterPhase: '',
      showSelected: false,
      hideAssigned: false,
      selectableEmployees: [],
      selectableSubcontractors: [],
      filterEmployee: '',
      filterSubcontractor: '',
      filterMinDate: null,
      filterMaxDate: null,
      showEntriesWarning: false,
      selectedStartDate: null,
      datepickerStyles: {
        dayContent: {
          fontSize: '1.6rem',
          height: '38px'
        },
        dayCell: {
          height: '38px'
        },
        headerTitle: {
          fontSize: '1.6rem'
        }
      },
      dateSelectAttrs: [
        {
          key: 'today',
          highlight: {
            height: '20px',
            color: '#555',
            backgroundColor: '#dae0fe'
          },
          contentStyle: {
            color: '#555'
            // fontWeight:'bold',
          },
          dates: [{ start: new Date(), span: 14 }]
        }
      ],
      selectedRange: {}
    }
  },

  mixins: [commonFuncs],

  components: {
    TabulatorTable,
    SelectField,
    DatePicker,
    WaitingSpinner
  },

  props: {
    type: {
      required: true,
      type: String
    },

    itmId: {
      required: true,
      type: Number
    },

    isLocked: {
      required: true,
      type: Boolean
    },

    filterJobExtraId: {
      type: Number,
      default: 0
    },

    extraId: {
      required: false,
      type: Number,
      default: null
    },

    allowDownload: {
      type: Boolean,
      default: false
    },

    selectedPhase: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters(['employees', 'subcontractors']),

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPM() {
      return this.$store.getters.userAuthedData.user_role.indexOf('pm') !== -1
    },

    userID() {
      return this.$store.getters.userAuthedData.eid
    },

    itemNumber() {
      if (this.extraId) {
        return this.formatExtraNumber(this.itmId, this.extraId)
      }
      return this.itmId
    },

    tableColumns() {
      return [
        {
          title: 'Entry Date',
          field: 'entry_date',
          width: 120
        },
        {
          title: 'Employee',
          field: 'Employee_Name',
          headerSort: false,
          width: 200
        },
        {
          title: 'R',
          field: 'HoursRegular',
          headerSort: false,
          bottomCalc: 'sum',
          width: 80
        },
        {
          title: 'OT',
          field: 'HoursOT',
          headerSort: false,
          bottomCalc: 'sum',
          width: 80
        },
        {
          title: 'DT',
          field: 'HoursDT',
          headerSort: false,
          bottomCalc: 'sum',
          width: 80
        },
        {
          title: 'Message',
          field: 'message',
          headerSort: false,
          minWidth: 400,
          formatter: 'textarea'
          // variableHeight:true
        },
        {
          title: 'Phase',
          field: 'phase_code',
          width: 100,
          visible: this.type === 'Job'
        },
        {
          title: 'Posted',
          field: 'is_posted',
          width: 100
        },
        // {
        //   title: 'Selected',
        //   field: 'selected',
        //   width: 100,
        //   editor: !this.isLocked,
        //   formatter: 'tickCross',
        //   cssClass: 'centered-checkbox',
        //   visible: this.extraId ? true : false,
        //   cellClick: (e, cell) => {
        //     if (!this.isLocked) {
        //       this.updateAssignedTimeEntry(cell);
        //       ///cell.setValue(!cell.getValue());
        //     } else {
        //       this.$snack.open('Item appears to be locked, please unlock or reload to try again', 'warning');
        //     }
        //   }
        // },
        {
          title: 'Selected',
          field: 'selected',
          width: 100,
          formatter: cell => {
            let val = cell.getValue()
            if (val) {
              return '<svg enable-background="new 0 0 24 24" height="14" width="14" viewBox="0 0 24 24" xml:space="preserve"><path fill="#2DC214" clip-rule="evenodd" d="M21.652,3.211c-0.293-0.295-0.77-0.295-1.061,0L9.41,14.34  c-0.293,0.297-0.771,0.297-1.062,0L3.449,9.351C3.304,9.203,3.114,9.13,2.923,9.129C2.73,9.128,2.534,9.201,2.387,9.351  l-2.165,1.946C0.078,11.445,0,11.63,0,11.823c0,0.194,0.078,0.397,0.223,0.544l4.94,5.184c0.292,0.296,0.771,0.776,1.062,1.07  l2.124,2.141c0.292,0.293,0.769,0.293,1.062,0l14.366-14.34c0.293-0.294,0.293-0.777,0-1.071L21.652,3.211z" fill-rule="evenodd"></path></svg>'
            }
            return '<svg enable-background="new 0 0 24 24" height="14" width="14" viewBox="0 0 24 24" xml:space="preserve"><path fill="#CE1515" d="M22.245,4.015c0.313,0.313,0.313,0.826,0,1.139l-6.276,6.27c-0.313,0.312-0.313,0.826,0,1.14l6.273,6.272  c0.313,0.313,0.313,0.826,0,1.14l-2.285,2.277c-0.314,0.312-0.828,0.312-1.142,0l-6.271-6.271c-0.313-0.313-0.828-0.313-1.141,0  l-6.276,6.267c-0.313,0.313-0.828,0.313-1.141,0l-2.282-2.28c-0.313-0.313-0.313-0.826,0-1.14l6.278-6.269  c0.313-0.312,0.313-0.826,0-1.14L1.709,5.147c-0.314-0.313-0.314-0.827,0-1.14l2.284-2.278C4.308,1.417,4.821,1.417,5.135,1.73  L11.405,8c0.314,0.314,0.828,0.314,1.141,0.001l6.276-6.267c0.312-0.312,0.826-0.312,1.141,0L22.245,4.015z"></path></svg>'
          },
          cssClass: 'centered-checkbox',
          visible: this.extraId ? true : false,
          cellClick: (e, cell) => {
            if (!this.isLocked) {
              this.updateAssignedTimeEntry(cell)
              ///cell.setValue(!cell.getValue());
            } else {
              this.$snack.open('Item appears to be locked, please unlock or reload to try again', 'warning')
            }
          }
        },
        {
          title: 'Job Extra',
          field: 'extraNumber',
          width: 110,
          visible: this.type === 'Job'
        },
        {
          title: 'Type',
          field: 'entryType',
          width: 110
        },
        {
          title: 'Edit',
          field: 'edit',
          titleFormatter: val => {
            return ''
          },
          formatter: val => {
            return '<i class="ti ti-pencil" />'
          },
          width: 26,
          minWidth: 26,
          headerSort: false,
          cssClass: 'clickable no-horo-padding no-horo-border',
          visible: this.isPM,
          frozen: true
        }
      ]
    },

    filteredTimecardEntries() {
      let entries = Array.isArray(this.timeCardEntries.entries) ? [...this.timeCardEntries.entries] : []

      if (this.filterPhase) {
        entries = entries.filter(itm => {
          return itm.phase_code == this.filterPhase
        })
      }

      if (this.showSelected && this.extraId) {
        entries = entries.filter(itm => {
          return itm.extra_id == this.extraId
        })
      }

      if (this.hideAssigned && this.extraId) {
        entries = entries.filter(itm => {
          return !itm.extra_id || itm.extra_id == this.extraId
        })
      }

      if (this.filterEmployee) {
        entries = entries.filter(itm => {
          return itm.employee_code == this.filterEmployee
        })
      }

      if (this.filterSubcontractor) {
        entries = entries.filter(itm => {
          return itm.employee_code == `S${this.filterSubcontractor}`
        })
      }

      this.entryCount = [...entries].length

      if (this.filterMinDate) {
        const minDate = parseInt(dateFormat(this.filterMinDate, 'isoDate').replace(/-/g, ''))
        entries = entries.filter(itm => {
          let entryDate = parseInt(itm.entry_date.replace(/-/g, ''))
          return entryDate >= minDate
        })
      }

      if (this.filterMaxDate) {
        const maxDate = parseInt(dateFormat(this.filterMaxDate, 'isoDate').replace(/-/g, ''))
        entries = entries.filter(itm => {
          let entryDate = parseInt(itm.entry_date.replace(/-/g, ''))
          return entryDate <= maxDate
        })
      }

      if (entries.length > 400) {
        this.showEntriesWarning = true
        // entries = []
      } else {
        this.showEntriesWarning = false
      }

      return entries
    }
  },

  methods: {
    tableClick(obj) {
      // listen to table column clicks and trigger actions
      let id = ((obj || {}).cell || {}).id || 0
      let field = ((obj || {}).cell || {}).field || 0

      if (field === 'edit') {
        this.editTimeEntry(id)
      }
    },

    selectDate(date) {
      if (date) {
        var prevSunday = new Date(date)
        prevSunday.setDate(prevSunday.getDate() - ((prevSunday.getDay() + 7) % 7))

        var weekNo = moment(prevSunday).format('W')

        /*
          A bit whacky but works for year week pay period change, maybe change back next year to just:
          if (weekNo % 2 !== 0) {
              prevSunday = moment(prevSunday).subtract(1, 'week').toDate();
            }
          */

        if (date.getFullYear() === 2020 || weekNo == 53) {
          if (weekNo % 2 !== 0) {
            prevSunday = moment(prevSunday).subtract(1, 'week').toDate()
          }
        } else {
          if (weekNo % 2 === 0) {
            prevSunday = moment(prevSunday).subtract(1, 'week').toDate()
          }
        }

        var endDate = moment(prevSunday).add(13, 'days').toDate()

        this.selectedRange = {
          start: prevSunday,
          end: endDate,
          formatted: dateFormat(prevSunday, 'mediumDate') + ' - ' + dateFormat(endDate, 'mediumDate')
        }
        this.selectedStartDate = prevSunday
        this.dateSelectAttrs[0].dates[0].start = prevSunday
      }
    },

    async getTimeCardEntries(silent) {
      this.timeCardEntries = {
        entries: [],
        regular: 0,
        ot: 0,
        dt: 0
      }

      // should rewrite to fetch all deps at same time then do logic when promise all resolved

      await this.$store.dispatch('getEmployees').catch(() => {
        this.$snack.open('Problem getting employees', 'error')
      })
      await this.$store.dispatch('getSubcontractors').catch(() => {
        this.$snack.open('Problem getting Subcontractors', 'error')
      })

      let timeCardEntries = []
      let totalHoursRegular = 0,
        totalHoursOT = 0,
        totalHoursDT = 0

      const start_date = this.filterMinDate ? dateFormat(this.filterMinDate, 'isoDate') : null
      const end_date = this.filterMinDate ? dateFormat(this.filterMaxDate, 'isoDate') : null

      const employee_code = this.filterEmployee ? this.filterEmployee : this.filterSubcontractor ? `S${this.filterSubcontractor}` : ''

      var data = {
        action: 'get_timecard_entries',
        type: this.type,
        number: this.itmId,
        approval_status: 'all',
        start_date,
        end_date,
        job_phase: this.filterPhase,
        employee_code,
        show_all: 'yes'
        // show_deleted: true
      }

      if (!silent) {
        this.$store.commit('waiting', { status: 1, message: 'Getting Time Card Entries' })
      }
      appFuncs.ajax_request(this.$store.getters.sherAuth, data, res => {
        if (res.status === 'success') {
          let entries = res.data

          for (var i = entries.length - 1; i >= 0; i--) {
            entries[i].HoursRegular = 0
            entries[i].HoursOT = 0
            entries[i].HoursDT = 0
            let hoursQty = parseFloat(entries[i].hours)
            entries[i].hoursInt = parseInt(entries[i].entry_date.replace(/-/g, ''))

            switch (entries[i].pay_type) {
              case 'R':
                entries[i].HoursRegular = hoursQty
                break
              case 'O':
                entries[i].HoursOT = hoursQty
                break
              case 'D':
                entries[i].HoursDT = hoursQty
                break
            }
            totalHoursRegular += entries[i].HoursRegular
            totalHoursOT += entries[i].HoursOT
            totalHoursDT += entries[i].HoursDT

            let isSubcontractor = entries[i].employee_code.toString().substring(0, 1) === 'S'

            if (isSubcontractor) {
              for (let si = 0; si < this.subcontractors.length; si++) {
                // add S to id code if not already there
                let subCode = this.subcontractors[si].id.toString().substring(0, 1) === 'S' ? this.subcontractors[si].id : `S${this.subcontractors[si].id}`
                if (subCode == entries[i].employee_code) {
                  entries[i].Employee_Name = this.subcontractors[si].Name
                }
              }
              entries[i].entryType = 'Subcontractor'
            } else {
              for (let ei = 0; ei < this.employees.length; ei++) {
                if (this.employees[ei].Employee_Code == entries[i].employee_code) {
                  entries[i].Employee_Name = this.employees[ei].Employee_Name
                }
              }
              if (entries[i].non_payroll === 'true') {
                entries[i].entryType = 'Non Payroll'
              } else {
                entries[i].entryType = 'Employee'
              }
            }

            switch (entries[i].posted) {
              case 'true':
                entries[i].is_posted = 'Yes'
                break
              case 'queue':
                entries[i].is_posted = 'Queued'
                break
              case 'delete':
                entries[i].is_posted = 'Reversed'
                break
              default:
                entries[i].is_posted = 'No'
                break
            }

            entries[i].selected = entries[i].extra_id == this.extraId ? true : false
            if (entries[i].extra_id) {
              entries[i].extraNumber = this.formatExtraNumber(this.itmId, entries[i].extra_id)
            }

            const messageLen = 900
            if (entries[i].message) {
              let message = entries[i].message.replaceAll('</div>', '\n')
              message = sanitizeHtml(message, { allowedTags: [] })
              if (message.length > 900) {
                message = message.substring(0, 900) + '...'
              }
              entries[i].message = message
            }

            // let employeeInSelectable = this.selectableEmployees.findIndex(itm => {
            //   return itm.Employee_Code == entries[i].employee_code
            // })
            // if (employeeInSelectable === -1) {
            //   let employee = this.employees.find(itm => {
            //     return itm.Employee_Code == entries[i].employee_code
            //   })
            //   if (employee) {
            //     this.selectableEmployees.push(employee)
            //   }
            // }

            // let subContractorInSelectable = this.selectableSubcontractors.findIndex(itm => {
            //   return itm.id == entries[i].employee_code.replace(/^S+/, '')
            // })
            // if (subContractorInSelectable === -1) {
            //   let subcontractor = this.subcontractors.find(itm => {
            //     return entries[i].employee_code.toString().substring(0, 1) === 'S' && itm.id == entries[i].employee_code.replace(/^S+/, '')
            //   })
            //   if (subcontractor) {
            //     this.selectableSubcontractors.push(subcontractor)
            //   }
            // }
          }

          entries = entries.sort((a, b) => {
            return a.hoursInt - b.hoursInt
          })

          if (this.extraId && entries.length > 30) {
            this.hideAssigned = true
          }

          this.timeCardEntries = {
            entries,
            regular: totalHoursRegular,
            ot: totalHoursOT,
            dt: totalHoursDT
          }

          // this.$refs.jobWoLabor.updateTable();
        } else {
          this.$snack.open('Problem getting timecard entries', 'error')
        }
        this.$store.commit('waiting', { status: 0 })
      })
    },

    updateAssignedTimeEntryCell(id, newVal) {
      return
      let entries = this.timeCardEntries.entries
      for (let i = 0; i < entries.length; i++) {
        if (entries[i].id === id) {
          entries[i].selected = newVal
        }
      }
    },

    updateAssignedTimeEntry(cell) {
      let val = cell.getValue()
      let id = cell.getRow().getIndex()
      cell.setValue(!val)
      // this.updateAssignedTimeEntryCell(id, !val)
      this.updateEntryExtraId(id, val ? null : this.extraId, cell)
    },

    syncTimeEntries() {
      if (!confirm('Are you sure? Syncing entries will remove user ability to edit non-posted entries from MySheridan.')) {
        return
      }

      if (!this.itmId) {
        this.$snack.open('Problem syncing time cards please close and try again.', 'error')
      }

      const params = {
        action: 'sync_time_card_job_wo_entries',
        item_type: this.type,
        item_number: this.itmId
      }

      this.$bus.$emit('setWaiting', { name: 'sync_time_card_job_wo_entries', message: 'Queuing Time Entries for Sync' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.$bus.$emit('modalClose', 'job-sign-off-options')
          this.$snack.open('Time Card Entries are queued for sync, this could take some time depending on quantity.', 'error')
          this.getTimeCardEntries()
        })
        .catch(res => {
          console.log(res)
          this.$snack.open('Problem queing entries for sync', 'error')
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'sync_time_card_job_wo_entries')
        })
    },

    assignAllFilteredTimeEntries() {
      if (this.isLocked) {
        this.$snack.open('Item appears to be locked, please unlock or reload to try again', 'warning')
        return
      }

      let entries = this.filteredTimecardEntries

      entries = entries.filter(itm => {
        return !itm.extra_id
      })

      const ids = entries.map(itm => {
        return itm.id
      })

      const params = {
        action: 'timecard_entry_set_extra_ids',
        ids,
        extra_id: this.extraId
      }
      this.$bus.$emit('setWaiting', { name: 'timecard_entry_set_extra_ids', message: 'Updating Time Entries' })
      appFuncs
        .shRequest(params)
        .then(data => {
          this.getTimeCardEntries()
          // if (this.$refs.jobWoLabor) {
          //     this.$refs.jobWoLabor.updateTable();
          // }
        })
        .catch(res => {
          console.log(res)
          // this.updateAssignedTimeEntryCell(id, false)
          // this.$snack.open('Problem updating time entry', 'error');
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'timecard_entry_set_extra_ids')
        })
    },

    updateEntryExtraId(id, extraId, cell) {
      if (this.isLocked) {
        this.$snack.open('Item appears to be locked, please unlock or reload to try again', 'warning')
        return
      }

      // don't allow assigning entry to this extra id if entry has already been assigned
      const timeEntry = this.timeCardEntries.entries.find(itm => {
        return itm.id === id
      })

      if (!timeEntry) {
        this.$snack.open('Problem updating entry, try reloading page', 'error')
        this.getTimeCardEntries() // reset time entry checkbox
        return
      }

      if (this.type === 'Job' && extraId && timeEntry.extra_id && timeEntry.extra_id !== extraId) {
        const extraNumber = this.formatExtraNumber(this.itmId, timeEntry.extra_id)
        this.$snack.open(`This entry is assigned to Job Extra ${extraNumber}`, 'warning')
        cell.setValue(false)
        this.updateAssignedTimeEntryCell(id, false)
        return
      }

      const params = {
        action: 'timecard_entry_set_extra_id',
        id,
        extra_id: extraId,
        skipAuthSet: true
      }
      // this.$bus.$emit('setWaiting', { name: 'timecard_entry_set_extra_id', message: 'Updating Time Entry' });
      appFuncs
        .shRequest(params)
        .then(data => {
          // if (this.$refs.jobWoLabor) {
          //     this.$refs.jobWoLabor.updateTable();
          // }
        })
        .catch(res => {
          console.log(res)
          // this.updateAssignedTimeEntryCell(id, false)
          // this.$snack.open('Problem updating time entry', 'error');
        })
        .finally(() => {
          // this.$bus.$emit('stopWaiting', 'timecard_entry_set_extra_id');
        })
    },

    addTimeEntry() {
      this.$Modal({
        parent: this,
        name: 'add-time-entry',
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        title: 'Add Time Entry',
        component: () => import('components/Dashboard/TimeEntry/AddTimeEntry.vue'),
        props: {
          department: this.type === 'Job' ? 'JOB' : 'WO',
          job_number: this.itmId,
          wo_number: this.itmId,
          inlineSelectItems: false,
          selectedPhase: this.selectedPhase
          // editId: id
        }
      })
    },

    editTimeEntry(id) {
      this.$Modal({
        parent: this,
        name: 'add-time-entry',
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        title: 'Edit Time Entry',
        component: () => import('components/Dashboard/TimeEntry/AddTimeEntry.vue'),
        props: {
          // department: this.type === 'Job' ? 'JOB' : 'WO',
          // job_number: this.itmId,
          // wo_number: this.itmId,
          // inlineSelectItems: false,
          // selectedPhase: this.selectedPhase,
          editId: id
        }
      })
    },

    addForemanTimeEntry() {
      this.$Modal({
        parent: this,
        name: 'add-foreman-time-entry',
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        title: 'Add Time Entries',
        component: () => import('components/Dashboard/TimeEntry/BulkTimeEntry.vue'),
        props: {
          department: this.type === 'Job' ? 'JOB' : 'WO',
          jobNumber: this.itmId,
          wo_number: this.itmId,
          inlineSelectItems: false,
          selectedPhase: this.selectedPhase,
          allEmployees: this.employees
          // editId: id
        }
      })
    },

    getJobPhases() {
      if ((this.type === 'Job' || this.type === 'Job Extras') && this.itmId) {
        var params = {
          action: 'get_job_phases_all',
          job_number: this.itmId
        }

        appFuncs.shRequest(params).then(data => {
          // use only L cost type
          this.phases = data.filter(itm => {
            return itm && itm.Cost_Type === 'L'
          })

          this.phases.unshift({
            Description: 'All',
            Phase_Code: ''
          })

          if (!this.phases.length) {
            this.$snack.open('No Job Phase available, please notify admin to post time to this job.', 'error')
          }
        })
      }
    }
  },

  mounted() {
    this.selectDate(new Date())

    // this.filterMinDate = this.selectedRange.start;
    // this.filterMaxDate = this.selectedRange.end;

    if (this.selectedPhase) {
      this.filterPhase = this.selectedPhase == 'All' ? '' : this.selectedPhase
    }

    this.getTimeCardEntries()
    this.getJobPhases()
    this.$bus.$on('add-update-time-entry', () => {
      this.getTimeCardEntries()
    })
  },

  watch: {
    filterEmployee(val) {
      if (val) {
        this.filterSubcontractor = ''
      }
    },
    filterSubcontractor(val) {
      if (val) {
        this.filterEmployee = ''
      }
    },
    selectedRange() {
      this.getTimeCardEntries()
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

.large-result-warning {
  color: $danger-color;
  font-style: 'italic';
  font-weight: bold;
}
</style>

<style lang="scss">
.tabulator {
  .tabulator-cell {
    &.centered-checkbox {
      > * {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
  }
}
</style>
