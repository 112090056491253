
<div class="max-width-large">
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="content">
          <h4>General</h4>

          <div class="form-group">
            <label for="signoff_email_cc">WO/Job Signoff Emails</label>
            <p class="sub-label">Comma separated</p>
            <input type="email" class="form-control" name="signoff_email_cc" v-model="appOptions.signoff_email_cc" />
          </div>

          <div class="form-group">
            <label for="vacation_request_emails">Vacation Request Emails</label>
            <p class="sub-label">Comma separated</p>
            <input type="email" class="form-control" name="vacation_request_emails" v-model="appOptions.vacation_request_emails" />
          </div>

          <div class="form-group">
            <label for="request_login_emails">Request Login Emails</label>
            <p class="sub-label">Comma separated</p>
            <input type="email" class="form-control" name="request_login_emails" v-model="appOptions.request_login_emails" />
          </div>

          <div class="form-group">
            <label for="customer_feedback_emails">Customer Feedback Emails</label>
            <p class="sub-label">Comma separated</p>
            <input type="email" class="form-control" name="customer_feedback_emails" v-model="appOptions.customer_feedback_emails" />
          </div>

          <div class="form-group">
            <label for="customer_feedback_emails">Salesforce Item Update Notification Emails</label>
            <p class="sub-label">Comma separated</p>
            <input type="email" class="form-control" name="customer_feedback_emails" v-model="appOptions.sf_object_update_emails" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="content">
          <h4>Email Config</h4>

          <h5>General Account</h5>
          <div class="form-group">
            <label for="general_smtp_user">User/Email (G Suite Account)</label>
            <input type="email" class="form-control" name="general_smtp_user" v-model="appOptions.general_smtp_user" />
          </div>

          <div class="form-group">
            <label for="general_smtp_pw">Password</label>
            <input type="password" class="form-control" name="general_smtp_pw" :value="appOptions.general_smtp_pw" @input="appOptions.general_smtp_pw = $event.target.value" />
          </div>

          <h5>Accounts Receivable Account</h5>
          <div class="form-group">
            <label for="ar_smtp_user">User/Email (G Suite Account)</label>
            <input type="email" class="form-control" name="ar_smtp_user" v-model="appOptions.ar_smtp_user" />
          </div>

          <div class="form-group">
            <label for="ar_smtp_pw">Password</label>
            <input type="password" class="form-control" name="ar_smtp_pw" :value="appOptions.ar_smtp_pw" @input="appOptions.ar_smtp_pw = $event.target.value" />
          </div>

          <h5>Accounts Payable Account</h5>
          <div class="form-group">
            <label for="ap_smtp_user">User/Email (G Suite Account)</label>
            <input type="email" class="form-control" name="ap_smtp_user" v-model="appOptions.ap_smtp_user" />
          </div>

          <div class="form-group">
            <label for="ap_smtp_pw">Password</label>
            <input type="password" class="form-control" name="ap_smtp_pw" :value="appOptions.ap_smtp_pw" @input="appOptions.ap_smtp_pw = $event.target.value" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <input type="submit" class="btn" @click="update()" value="Update" />

  <waiting-spinner />
</div>
